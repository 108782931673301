import React from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Color, Grid, Text } from 'component-library';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  textRight: {
    textAlign: 'right',
  },
  borderTop: {
    borderTop: `1px solid ${Color.neutral._20}`,
  },
}));

interface SummaryContentProps {
  creditTitle: string;
  mainStreetFee: string;
  estimateTotal: string;
  netBenefit: string;
  isBogoDeal?: boolean;
  programTaxYear: number;
  mainStreetFeeInfo?: string;
}

export const SummaryContent: React.FC<SummaryContentProps> = observer(
  ({
    creditTitle,
    mainStreetFee,
    estimateTotal,
    netBenefit,
    isBogoDeal,
    programTaxYear,
    mainStreetFeeInfo,
  }) => {
    const { app } = useCommonStores();
    const fontSize = app.isMobile ? 15 : 18;
    const classes = useStyles();

    return (
      <Grid.Cell columns={12} className={classes.borderTop}>
        <Grid columns={12} padding={[16, 24]}>
          <Grid.Cell columns={6}>
            <Text
              text={`Estimated ${creditTitle}`}
              size={fontSize}
              variant='medium'
            />
          </Grid.Cell>
          <Grid.Cell columns={6} className={classes.textRight}>
            <Text
              color={Color.green._80}
              text={estimateTotal}
              size={fontSize}
              variant='medium'
            />
          </Grid.Cell>
        </Grid>

        <Grid columns={12} padding={[16, 24]}>
          <Grid.Cell columns={8}>
            <Text>
              <Text
                tag='span'
                text={`MainStreet fee ${isBogoDeal ? 'discount ' : ''}`}
                size={fontSize}
                variant='medium'
              />
              {isBogoDeal && (
                <Text
                  tag='span'
                  color={Color.neutral._60}
                  size={15}
                  variant='medium'
                  text={`- Buy ${
                    programTaxYear + 1
                  }, get all ${programTaxYear} tax credits for free.`}
                />
              )}
              {mainStreetFeeInfo && (
                <Text
                  tag='span'
                  color={Color.neutral._60}
                  size={15}
                  variant='medium'
                  text={mainStreetFeeInfo}
                />
              )}
            </Text>
          </Grid.Cell>
          <Grid.Cell columns={4} className={classes.textRight}>
            <Text
              color={Color.neutral._90}
              text={isBogoDeal ? <s>{mainStreetFee}</s> : `-${mainStreetFee}`}
              size={fontSize}
              variant='medium'
            />
          </Grid.Cell>
        </Grid>

        <Grid
          columns={12}
          backgroundColor={Color.neutral._light_20}
          padding={[16, 24]}
        >
          <Grid.Cell columns={6}>
            <Text
              text={'Estimated net benefit'}
              size={fontSize}
              variant='medium'
            />
          </Grid.Cell>
          <Grid.Cell columns={6} className={classes.textRight}>
            <Text
              color={Color.green._80}
              text={netBenefit}
              size={fontSize}
              variant='medium'
            />
          </Grid.Cell>
        </Grid>
      </Grid.Cell>
    );
  },
);
