import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { ContentContainer } from '../component';
import { observer } from 'mobx-react';
import {
  useAccountCreationStores,
  useCommonStores,
  useHistoryStore,
  useLegacyClients,
} from 'stores/useStores';
import {
  Text,
  SurveyQuestion,
  Flex,
  Spinner,
  AnswerType,
  Color,
} from 'component-library';
import { useEffectOnce } from 'lib/helpers';
import {
  ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
  SurveyNameEnum,
} from 'lib/constants';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import DOMPurify from 'dompurify';
import { AccountCreationSteps, CmsRenderTree } from 'lib/interfaces';
import { datadogLogs } from '@datadog/browser-logs';
import { CompanyContext } from 'pages/CompanyRequired';
import renderTreeJson from '../../../../../hygraph/renderTree/unifiedAccountCreation.json';

const useStyles = makeStyles(() => ({
  surveyQuestion: {
    '& button': {
      padding: '12px 15px',
    },
  },
  '@global': {
    '.SurveyQuestion__title-section___2bPTK.SurveyQuestion__no-subtitle___eX6M3':
      {
        marginBottom: 0, // fix title spacing for SurveyQuestion
      },
  },
  surveyListText: {
    '& > ul': {
      margin: 0,
      padding: '0 24px',

      '& > li': {
        paddingBottom: '8px',
        fontSize: '15px',
        lineHeight: '24px',
        color: Color.neutral._80,
      },
    },
  },
}));

interface BusinessActivitiesStepProps {
  pathTo: string;
  pathFrom: string;
  onBack: () => void;
}

const logger = datadogLogs.createLogger('BusinessActivitiesStep');

export const BusinessActivitiesStep = observer(
  ({ pathTo, pathFrom, onBack }: BusinessActivitiesStepProps) => {
    const classes = useStyles();
    const { accountCreation } = useAccountCreationStores();
    const { app } = useCommonStores();
    const history = useHistoryStore();
    const { client } = useLegacyClients();
    const { company, setCompany } = useContext(CompanyContext);
    const { saveAnswers, isLoading, questionsToRender, addSurveyAnswers } =
      useSurveyQuestions(
        SurveyNameEnum.UNIFIED_ACCOUNT_CREATION_SURVEY,
        ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
        {},
        renderTreeJson as CmsRenderTree,
      );

    const onContinue = async () => {
      accountCreation.isNextButtonLoading = true;
      await saveAnswers();
      await accountCreation.handleQualificationForProgram(
        ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
      );

      await accountCreation.updateAccount(
        {},
        AccountCreationSteps.BUSINESS_ACTIVITIES,
        true, // is unified account creation
      );

      await client.SetGeneralBusinessDetailsPrefillAnswers(
        ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
      );
      await client.CurrentLoggedInCompany().then((company) => {
        if (company) {
          setCompany(company);
        }
      });

      if (!accountCreation.errorMsg) {
        history.push(pathTo);
      }
      accountCreation.isNextButtonLoading = false;
    };

    useEffectOnce(async () => {
      accountCreation.setTaxesStepQuestions();
    });

    return (
      <ContentContainer
        onBack={onBack}
        onNext={onContinue}
        isNextButtonDisabled={
          !questionsToRender['businessActivities']?.every(
            (question) => question.answerValue,
          )
        }
        isNextLoading={accountCreation.isNextButtonLoading}
        pathFrom={pathFrom}
      >
        <Flex alignItems='start' direction='column' gap={8}>
          <Text
            text='Tell us about your business activities'
            variant='medium'
            size={app.isMobile ? 23 : 32}
          />
          <Text
            text='Your answers will help determine which tax credits you are eligible for.'
            size={15}
          />
        </Flex>
        {isLoading || !questionsToRender['businessActivities'] ? (
          <Flex justifyContent='center'>
            <Spinner />
          </Flex>
        ) : (
          <>
            {questionsToRender['businessActivities'].map((question) => {
              const subtitleList =
                question.subtitle && question.subtitle.includes('<ul>');
              return (
                <SurveyQuestion
                  className={classes.surveyQuestion}
                  key={question.id}
                  placeholder={question.placeholder}
                  text={question.text}
                  subtitle={
                    subtitleList ? (
                      <div
                        className={classes.surveyListText}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(question.subtitle),
                        }}
                      />
                    ) : (
                      question.subtitle
                    )
                  }
                  questionTextSize={15}
                  answerType={question.answerType as AnswerType}
                  answerValue={question.answerValue}
                  onChange={(answer) => {
                    question.answerValue = answer;
                    addSurveyAnswers(question.id, 'businessActivities', [
                      { questionId: question.id, answerValue: answer },
                    ]);

                    // save answers per question answered
                    if (
                      question.answerType === 'yes_or_no' ||
                      question.answerType === 'dropdown_select'
                    ) {
                      saveAnswers && saveAnswers();
                    }
                  }}
                  onBlur={() => saveAnswers && saveAnswers()}
                  answerOptions={question.answerIDs}
                  withCard={false}
                  multiSelectDropdown
                />
              );
            })}
          </>
        )}
      </ContentContainer>
    );
  },
);
