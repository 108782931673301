import React from 'react';
import { Flex, Text, Tooltip, FontAwesome, Color } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: 13,
  },
}));

const statusAndTooltip = [
  {
    status: 'Estimate your credits',
    tooltip:
      'Your company is qualified to save with tax credits. Get started with MainStreet by accepting your estimated credits.',
  },
  {
    status: 'In progress',
    tooltip: `You're all set for now. Once the year ends, it will be time to complete the next step.`,
  },
  {
    status: 'Year-end assessment',
    tooltip:
      'Complete your R&D assessment to give us specifics about your R&D activities and expenses from the last 12 months.',
  },
  {
    status: 'MainStreet review',
    tooltip:
      'Our team will review all the information you provided to carefully calculate your credits.',
  },
  {
    status: 'File forms with IRS',
    tooltip: 'Form 6765 is ready to file with your business income taxes.',
  },
  {
    status: 'Redeem your credits',
  },
  {
    status: 'Finalizing your credits',
    tooltip: `Sit tight while we're finalizing your tax credits, if there is any additional information or action needed by you we will reach out in the coming weeks.`,
  },
  {
    status: 'Completed',
  },
];

interface ProgressStatusProps {
  step: number;
  isFutureProgram: boolean;
  startDate: string | undefined;
}

export const ProgressStatus: React.FC<ProgressStatusProps> = observer(
  ({ step = 0, isFutureProgram, startDate }) => {
    const classes = useStyles();
    let formattedDateString;
    if (startDate) {
      formattedDateString = startDate.split('-');
      formattedDateString = `${formattedDateString[1]}-${formattedDateString[2]}-${formattedDateString[0]}`;
    }
    if (isFutureProgram) {
      return (
        <>
          <Flex.Cell padding={[0, 0, 0, 16]}>
            <Text
              text={`Starts on ${formattedDateString}`}
              variant='medium'
              color={Color.neutral._80}
            />
          </Flex.Cell>
          <Flex.Cell padding={[0, 0, 4, 4]}>
            <Tooltip position='bottom' text={'TBD'} className={classes.tooltip}>
              <FontAwesome name='info-circle' size={12} variant='regular' />
            </Tooltip>
          </Flex.Cell>
        </>
      );
    }

    const { status, tooltip } = statusAndTooltip[
      step > statusAndTooltip.length ? statusAndTooltip.length : step
    ];

    return (
      <>
        <Flex.Cell padding={[0, 0, 0, 16]}>
          <Text text={status} variant='medium' color={Color.neutral._80} />
        </Flex.Cell>
        <Flex.Cell padding={[0, 0, 4, 4]}>
          {tooltip && (
            <Tooltip
              position='bottom'
              text={tooltip}
              className={classes.tooltip}
            >
              <FontAwesome name='info-circle' size={12} variant='regular' />
            </Tooltip>
          )}
        </Flex.Cell>
      </>
    );
  },
);
