import { useCallback, useContext, useEffect, useState } from 'react';
import {
  ExpectedCreditTypeEnum,
  ProgramCardData,
  ProgramNameEnum,
  Programs,
  ProgramStageEnum,
  QualificationStatusEnum,
} from 'lib/constants';
import { CompanyContext } from 'pages/CompanyRequired';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { IsFirstMonthOfQuarter } from 'lib/helpers';
import { FeatureFlagProviderContext } from '@mainstreet/feature-flags-react';
import {
  FeatureFlagNameEnum,
  FeatureFlagValueEnum,
} from 'lib/constants/featureFlagConstants';
import { useTaxCreditsStores } from 'stores/useStores';

export interface StatusToShow {
  id?: number;
  display?: string;
  info?: string;
  label: string;
  link?: string;
  status: 'action-required' | 'active' | 'empty';
  taxYear?: number;
}

/**
 * TODO:
 * The data from Form8974Store in useTaxCreditsStores() is only available
 * when this hook is used inside an observable component.
 *
 * This should eventually be converted to a store of its own, or included in
 * an existing store.
 */
const useGetProgramRow = (
  programs: ProgramCardData[],
): { loading: boolean; programCards: StatusToShow[]; error: string } => {
  const [programCards, setProgramCards] = useState<StatusToShow[]>([]);
  const [errorCompanyProvider, setErrorCompanyProvider] = useState<string>('');
  const [companyPayrollProvider, setCompanyPayrollProvider] = useState<
    string | null
  >(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { company } = useContext(CompanyContext);
  const { client } = useContext(Auth0FeatureContext);
  const { form8974 } = useTaxCreditsStores();

  const programTaxFilingDate = useCallback(
    (programId: number) => {
      return company.programs.find(({ id }) => id === programId)?.taxFilingDate;
    },
    [company.programs],
  );

  const { getFlag } = useContext(FeatureFlagProviderContext);

  /**
   * Deliberately not handling missing/timeouts etc. as this is a feature gate
   *
   * If LD is down, and we fall back to not showing this we are considering that "fine"
   * for now
   *
   * GOV-2807 to remove once ramp-up complete
   */
  const show8974FeatureFlagIsOn: boolean =
    !!getFlag &&
    getFlag(FeatureFlagNameEnum.SHOW_8974) === FeatureFlagValueEnum.ON;

  const statusToShow = useCallback(
    (program: ProgramCardData, programBalanceCents?: number): StatusToShow => {
      const fedRdOnly = program.name === ProgramNameEnum.FED_RD_TAX;
      const isFirstMonth = IsFirstMonthOfQuarter(new Date().getMonth());

      if (!fedRdOnly && program.stage === ProgramStageEnum.FINISHED) {
        return {
          label: 'Complete',
          status: 'active',
        };
        ////
      } else if (
        program.stage === ProgramStageEnum.FINISHED &&
        (program.filingCreditType === ExpectedCreditTypeEnum.INCOME_TAX ||
          program.filingCreditType === ExpectedCreditTypeEnum.DEFERRED_INCOME)
      ) {
        return {
          label: 'Complete',
          status: 'active',
          info: 'Credit applied to income tax including deferred income',
        };
        ////
      } else if (program.orderForm && !program.orderForm?.acceptedAt) {
        return {
          label: 'Order ready to sign',
          status: 'action-required',
        };
        ////
      } else if (
        program.qualificationStatus ===
        QualificationStatusEnum.QUALIFICATION_IN_PROGRESS
      ) {
        return {
          label: 'Qualification incomplete',
          status: 'action-required',
        };
        ////
      } else if (
        program.qualificationStatus ===
          QualificationStatusEnum.QUALIFICATION_SUBMITTED &&
        program.orderForm?.acceptedAt
      ) {
        return {
          label: 'Qualifying',
          status: 'active',
        };
        ////
      } else if (
        program.qualificationStatus === QualificationStatusEnum.QUALIFIED &&
        program.orderForm?.acceptedAt &&
        (program.stage === ProgramStageEnum.QUALIFYING ||
          program.stage === ProgramStageEnum.DATA_COLLECTION)
      ) {
        return {
          label: 'Accruing',
          status: 'active',
        };
      } else if (
        program.qualificationStatus === QualificationStatusEnum.QUALIFIED &&
        program.orderForm?.acceptedAt &&
        (program.stage === ProgramStageEnum.EXPENSE_CLASSIFICATION ||
          program.stage === ProgramStageEnum.CLIENT_REVIEW ||
          program.stage === ProgramStageEnum.MS_REVIEW)
      ) {
        if (program.stage === ProgramStageEnum.CLIENT_REVIEW) {
          return {
            label: 'Ready to file',
            status: 'action-required',
          };
          ////
        }
        if (program.stage === ProgramStageEnum.MS_REVIEW) {
          return {
            label: 'Preparing documents',
            status: 'active',
          };
          ////
        }
        return { label: 'Classify expenses', status: 'action-required' };
        ////
      } else if (
        show8974FeatureFlagIsOn &&
        fedRdOnly &&
        companyPayrollProvider &&
        program.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX &&
        (program.stage === ProgramStageEnum.FINISHED ||
          program.stage === ProgramStageEnum.CLIENT_REVIEW) &&
        !form8974.tier1Providers.includes(companyPayrollProvider) &&
        (!programTaxFilingDate(program.id) ||
          (programBalanceCents && programBalanceCents > 0 && isFirstMonth))
      ) {
        return {
          label: 'Ready to redeem',
          status: 'action-required',
        };
        ////
      } else if (
        fedRdOnly &&
        companyPayrollProvider &&
        form8974.tier1AndTier2Providers.includes(companyPayrollProvider) &&
        program.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX &&
        program.stage === ProgramStageEnum.FINISHED
      ) {
        return {
          label: 'Redeeming',
          status: 'active',
          info: 'Check payroll for usage details',
          link:
            'https://mainstreet-help.force.com/help/s/topic/0TO5f000000I96tGAC/quarterly-filing-documents',
        };
        ////
      } else if (
        fedRdOnly &&
        companyPayrollProvider &&
        program.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX &&
        !form8974.tier1AndTier2Providers.includes(companyPayrollProvider) &&
        program.stage === ProgramStageEnum.FINISHED &&
        programTaxFilingDate(program.id) &&
        (!programBalanceCents || programBalanceCents > 0) &&
        !isFirstMonth
      ) {
        return {
          label: 'Redeeming',
          status: 'active',
          info: 'Payroll usage in progress',
        };
        ////
      } else if (
        fedRdOnly &&
        companyPayrollProvider &&
        !form8974.tier1AndTier2Providers.includes(companyPayrollProvider) &&
        program.stage === ProgramStageEnum.FINISHED &&
        programBalanceCents &&
        programBalanceCents === 0
      ) {
        return {
          label: 'Complete',
          status: 'active',
          info: 'Payroll credit fully used',
        };
        ////
      }

      return { label: 'Accruing', status: 'active' };
    },
    [
      companyPayrollProvider,
      programTaxFilingDate,
      show8974FeatureFlagIsOn,
      form8974,
    ],
  );

  useEffect(() => {
    client.GetCompanyPayrollProvider(company.id).then((res) => {
      if (res.errorMsg) {
        setErrorCompanyProvider(`Error: ${res.errorMsg}`);
        setLoading(false);
        return;
      }

      if (res.data?.provider) {
        setCompanyPayrollProvider(res.data?.provider);
      }
    });
  }, [client, company.id]);

  useEffect(() => {
    const getProgramBalance = async (program: ProgramCardData) => {
      return await client
        .GetProgramRDCreditBalance(program.id)
        .then((res) => res.data?.balanceAmountCents);
    };

    if (companyPayrollProvider !== null && loading) {
      // if no credits, stop loading
      if (programs.length === 0) {
        setLoading(false);
        return;
      }
      // wait till we find a payroll provider first
      programs.forEach(async (program) => {
        const programBalanceCents = await getProgramBalance(program);
        const { status, label, info, link } = statusToShow(
          program,
          programBalanceCents,
        );

        const display = Programs[program.name].display;

        setProgramCards((prevState) => {
          const statusToShowValues = [
            ...prevState,
            {
              id: program.id,
              display,
              status,
              label,
              info,
              link,
              taxYear: program.taxYear,
            },
          ];
          const uniqueValues = [
            ...new Map(
              statusToShowValues.map((item: StatusToShow) => {
                return [item.id, item];
              }),
            ).values(),
          ];
          return uniqueValues;
        });
        setLoading(false);
      });
    }
  }, [loading, companyPayrollProvider, client, programs, statusToShow]);

  return { loading, programCards, error: errorCompanyProvider };
};

export default useGetProgramRow;
