import { LegacyContexts } from 'LegacyContexts';
import { useUnmountEffectOnce } from 'lib/helpers';
import { observer } from 'mobx-react';
import React, { createContext, useRef } from 'react';
import { useHistory } from 'react-router';
import { RootStore } from './RootStore';

export const StoreContext = createContext<RootStore>({} as RootStore);

interface StoreProviderProps {
  store?: RootStore;
}

export const StoreProvider: React.FC<StoreProviderProps> = observer(
  ({ store, children }) => {
    const history = useHistory<History>();
    const rootStore = useRef<RootStore>();
    if (!rootStore.current) {
      if (store) {
        rootStore.current = store;
      } else {
        rootStore.current = new RootStore();
        rootStore.current.setup();
      }
    }

    rootStore.current.history.setRawHistory(history);

    // Trigger any teardown operations when store is unmounted
    useUnmountEffectOnce(() => rootStore.current?.teardown());

    return (
      <StoreContext.Provider value={rootStore.current}>
        <LegacyContexts>{children}</LegacyContexts>
      </StoreContext.Provider>
    );
  },
);
