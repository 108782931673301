import React from 'react';
import {
  Animate,
  Flex,
  Color,
  Heading,
  Text,
  Card,
  CardFooter,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import {
  useAccountCreationStores,
  useCommonStores,
  useCompany,
  useTaxCreditsStores,
} from 'stores/useStores';
import { observer } from 'mobx-react';
import { useEffectOnce } from 'component-library/_helpers/use-utils';
import { ACCOUNT_CREATION_QUALIFYING_TAX_YEAR } from 'lib/constants';

const useStyles = makeStyles(() => ({
  animate: {
    width: '100%',
    height: '100%',
    minHeight: 500,
    maxHeight: 800,
  },
  welcomeHeading: {
    textAlign: 'center',
  },
  card: {
    height: 'max-content',
    borderRadius: '8px',
    wordBreak: 'break-word',
  },
  content: {
    zIndex: 1,
    opacity: 1,
    transition: 'opacity 1s',
    width: '100%',
  },
  fadeOut: {
    opacity: 0,
  },
  image: {
    width: '100%',
  },
}));

interface OnboardingLandingProps {
  onSubmit: () => void;
}

export const OnboardingLandingPage = observer(
  ({ onSubmit }: OnboardingLandingProps) => {
    const { accountCreation } = useAccountCreationStores();
    const classes = useStyles();
    const { company } = useCompany();
    const firstName = company.adminName.first;
    const {
      app: { isMobile },
    } = useCommonStores();
    const { unifiedTaxCredits } = useTaxCreditsStores();

    useEffectOnce(async () => {
      await unifiedTaxCredits.updateAccountCreationPrefill(
        ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
      );
    });

    return (
      <Animate enter={'fade-in'} delay={1} className={classes.animate}>
        <Flex
          justifyContent={'start'}
          dataTestId={'onboarding-landing-page'}
          padding={isMobile ? 0 : [80, 0, 0, 0]}
          className={`${classes.content} ${
            accountCreation.fadeOut ? classes.fadeOut : ''
          }`}
        >
          {!isMobile && (
            <Flex justifyContent='center'>
              <Flex
                direction='column'
                padding={[0, 80]}
                justifyContent='center'
              >
                <Heading
                  className={classes.welcomeHeading}
                  variant='medium'
                  marginBottom={12}
                  tag='h3'
                >
                  <Heading tag='span' variant='medium'>
                    Welcome to&nbsp;
                  </Heading>
                  <Heading color={Color.green._70} tag='span' variant='medium'>
                    MainStreet
                  </Heading>
                </Heading>
                <Text
                  size={18}
                  text={`Understanding tax credits is our full-time job, so it doesn’t have to be yours.`}
                  textAlign='center'
                  paddingBottom={48}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/images/account-creation/unified-account.svg`}
                  alt='Unified Account Creation'
                  className={classes.image}
                />
              </Flex>
            </Flex>
          )}
          <Flex justifyContent='center' padding={isMobile ? 12 : 0}>
            <Animate
              enter={['fade-in', 'from-top']}
              delay={isMobile ? 0.5 : 1.5}
              className={classes.animate}
            >
              <Card className={classes.card} noMargin>
                <Flex
                  direction='column'
                  alignItems='start'
                  justifyContent='start'
                  padding={isMobile ? [24, 16] : 48}
                >
                  <Heading
                    variant='medium'
                    marginBottom={12}
                    tag={isMobile ? 'h4' : 'h3'}
                  >
                    {firstName.length > 0 ? (
                      <>
                        <Heading tag='span' variant='medium'>
                          Hello&nbsp;
                        </Heading>
                        <Heading
                          color={Color.blue._60}
                          tag='span'
                          variant='medium'
                        >
                          {firstName}
                        </Heading>
                      </>
                    ) : (
                      <Heading tag='span' variant='medium'>
                        Hello there!
                      </Heading>
                    )}
                  </Heading>
                  <Text
                    size={isMobile ? 18 : 23}
                    variant='medium'
                    text={`We're excited to show you how much money your business can save with MainStreet.`}
                    paddingBottom={24}
                  />
                  <Text
                    size={isMobile ? 15 : 18}
                    text={`First, we'll ask you some general questions about your business. From there, we'll be able to get a good sense of which credits work best for you.`}
                  />
                </Flex>
                <CardFooter
                  primaryCtaLabel="Let's get started"
                  primaryTestId={'account-creation-start-button'}
                  primaryOnClick={onSubmit}
                  noBgColor
                />
              </Card>
            </Animate>
          </Flex>
        </Flex>
      </Animate>
    );
  },
);
