import * as React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { CORAL, PROD_BANNER_DISMISSED_KEY } from '../../lib/constants';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  banner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    backgroundColor: CORAL,
  },
  text: {
    margin: 'auto',
    color: '#FFF',
    letterSpacing: '1.5px',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  dismissButton: {
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function ProductionBanner() {
  const classes = useStyles();

  const [environment, setEnvironment] = useState<string>('');
  useEffect(() => {
    const currentEnv = process.env.REACT_APP_CONNECTION_NAME;
    if (currentEnv) {
      if (currentEnv && currentEnv === 'prod') {
        if (sessionStorage) {
          const env = sessionStorage.getItem(PROD_BANNER_DISMISSED_KEY);
          if (env && env === 'true') return;
        }
      }

      const date = new Date();

      const timestamp = `${
        date.getMonth() + 1
      }/${date.getDate()}, ${date.getHours()}:00`;
      setEnvironment(
        `${timestamp} - Due to high traffic MainStreet is experiencing some intermittent issues. If you experience an error, please try refreshing the page or contact us at support@mainstreet.com`,
      );
      if (sessionStorage) {
        sessionStorage.setItem(PROD_BANNER_DISMISSED_KEY, 'false');
      }
    }
  }, []);

  return (
    <div>
      {environment && (
        <div className={classes.banner}>
          <Typography component='div' variant='body1' className={classes.text}>
            {environment}
          </Typography>
          <div
            className={classes.dismissButton}
            onClick={() => {
              setEnvironment('');
              if (sessionStorage) {
                sessionStorage.setItem(PROD_BANNER_DISMISSED_KEY, 'true');
              }
            }}
          >
            <CloseIcon></CloseIcon>
          </div>
        </div>
      )}
    </div>
  );
}
