import React, { useContext, useEffect, useState } from 'react';
import { Text, Button, Heading } from 'component-library';
import {
  FormHelperText,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import {
  CompanyData,
  CreditEstimateSummary,
  HighYieldSavingsData,
  MagicMoneyDataOverrides,
  ProgramCreditTotal,
} from 'lib/interfaces';
import {
  IssuedSurveyStatusEnum,
  Page,
  ProductTypeEnum,
  ProductTypeEnumToString,
  ProgramNameEnum,
  Programs,
  ProgramStageEnum,
  SurveyNameEnum,
} from 'lib/constants';
import { MagicMoneyEstimates, OptedInSelection } from 'services/server';
import { CompanyContext } from '../../CompanyRequired';
import { Loading } from 'components/util/Loading';
import { CreditsCard, GuaranteedNetBenefit, SelectionCard } from './components';
import { MagicCredits, MagicHYSIcon, MagicVendorIcon } from 'components/icons';
import { FinalMagicMoneyPage } from './components/FinalMagicMoneyPage';
import {
  GetMagicContingencyText,
  GetMagicEstimateDescription,
  GetMagicEstimateSubtitle,
} from './helpers';
import { CurrencyFormat } from '../../../components/CurrencyFormat';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    display: 'flex',
  },
  contentContainer: {
    width: '100%',
  },
  mainContent: {
    display: 'flex',
    padding: '40px 80px',
    gap: '40px',
  },
  leftColumnContent: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '850px',
    position: 'relative',
  },
  rightColumnContent: {
    height: '100%',
  },
  rightBanner: { width: 225, flexShrink: 0 },
  guaranteeBlock: {
    position: 'sticky',
    top: 48,
    zIndex: 1,
  },
  continueButtonWrapper: {
    marginTop: '60px',
    [breakpoints.down('xs')]: {
      padding: '0 18px',
      boxSizing: 'border-box',
    },
  },
  formErrorMsg: {
    marginTop: '15px',
    textAlign: 'center',
    marginLeft: '-180px',
  },
  subtitle: {
    color: '#40525E',
    marginBottom: '20px',
  },
  continueButtonContainer: {
    marginTop: 30,
  },
  expanded: {
    '& p': {
      fontSize: 14,
    },
  },
  emphasis: {
    fontWeight: 500,
  },
  textField: {
    width: '300px',
    [breakpoints.down('xs')]: {
      width: '250px',
    },
    '& p': {
      margin: '4px 0 0 0',
    },
  },
}));

interface MagicMoneySelectionProps {
  qualificationTaxYear: number;
}

export const MagicMoneySelection = ({
  qualificationTaxYear,
}: MagicMoneySelectionProps) => {
  const classes = useStyles();
  const [company] = useState<CompanyData>(useContext(CompanyContext).company);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState('');
  const [estimateData, setEstimateData] = useState<{
    creditEstimateSummary?: CreditEstimateSummary;
  }>({});

  const [optedIn, setOptedIn] = useState({
    [ProductTypeEnum.BENEFITS]: { optedIn: false },
    [ProductTypeEnum.VENDOR_MANAGEMENT]: { optedIn: true },
    [ProductTypeEnum.HIGH_YIELD_SAVINGS]: { optedIn: true },
  });

  const [hysCommitment, setHysCommitment] = useState(0);
  const [hysCommitmentOverride, setHysCommitmentOverride] = useState(0);

  const handleCreditEstimateSummary = (summary?: CreditEstimateSummary) => {
    setEstimateData({ creditEstimateSummary: summary });
    if (!summary) {
      return;
    }
    let resetOptin = false;
    summary.productTypeCreditEstimates.forEach(({ productType, high }) => {
      if (high === 0 && optedIn[productType]?.optedIn) {
        setOptedIn((optedIn) => {
          optedIn[productType].optedIn = false;
          return optedIn;
        });
        resetOptin = true;
      }
    });
    if (resetOptin) {
      optinUpdate();
    }
  };

  const optinUpdate = () => {
    const magicMoneyProgram = company.programs.find(
      (program) =>
        program.name === ProgramNameEnum.MAGIC_MONEY &&
        program.taxYear === qualificationTaxYear,
    );
    return OptedInSelection(magicMoneyProgram!.id, optedIn)
      .then(({ errorMsg, data }) => {
        if (errorMsg) {
          setError(errorMsg);
          return;
        }
        setError('');
        handleCreditEstimateSummary(data?.creditEstimateSummary);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const magicMoneyProgram = company.programs.find(
      (program) =>
        program.name === ProgramNameEnum.MAGIC_MONEY &&
        program.taxYear === qualificationTaxYear &&
        program.stage !== ProgramStageEnum.DISQUALIFIED,
    );

    if (!magicMoneyProgram) {
      return;
    }

    const hys = magicMoneyProgram.magicMoneyOverview!.products
      .high_yield_savings as HighYieldSavingsData;
    const hysCents =
      hys.amountCommittedCentsOverride || hys.amountCommittedCents || 0;

    setHysCommitment(hysCents);
    setHysCommitmentOverride(hysCents);

    OptedInSelection(
      magicMoneyProgram.id,
      Object.entries(
        magicMoneyProgram.magicMoneyOverview?.products ?? {},
      ).reduce((accum, [key, entry]) => {
        accum[key as ProductTypeEnum] = { optedIn: entry.optedIn };
        return accum;
      }, optedIn),
    )
      .then(({ errorMsg, data }) => {
        if (errorMsg) {
          setError(errorMsg);
          return;
        }
        setError('');
        handleCreditEstimateSummary(data?.creditEstimateSummary);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateWithOverride = (override: MagicMoneyDataOverrides) => {
    return MagicMoneyEstimates(qualificationTaxYear, override)
      .then(({ errorMsg, data }) => {
        if (errorMsg) {
          setError(errorMsg);
          return;
        }
        setError('');
        handleCreditEstimateSummary(data?.creditEstimateSummary);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const magicMoneySurvey = company.issuedSurveys!.find(
    (issuedSurvey) =>
      issuedSurvey.name === SurveyNameEnum.MAGIC_MONEY_QUALIFICATION,
  );

  const magicMoneyProgram = company.programs.find(
    (program) =>
      program.name === ProgramNameEnum.MAGIC_MONEY &&
      program.taxYear === qualificationTaxYear,
  );

  if (
    !magicMoneySurvey ||
    !magicMoneyProgram ||
    magicMoneyProgram?.orderForm?.acceptedAt
  ) {
    window.location.href = '/';
    return <></>;
  }

  if (magicMoneySurvey.status !== IssuedSurveyStatusEnum.SUBMITTED) {
    window.location.href = `/${Page.magicMoneyQualification}`;
    return <></>;
  }

  const submit = () => {
    setSubmitting(true);
    OptedInSelection(magicMoneyProgram.id, optedIn)
      .then(({ errorMsg }) => {
        if (errorMsg) {
          setError(errorMsg);
          return;
        }
        window.location.href = `/${Page.orderForm}/${magicMoneyProgram.id}`;
      })
      .finally(() => setSubmitting(false));
  };

  const perProductInfo: Partial<Record<
    ProductTypeEnum,
    {
      title: string;
      description: string;
      icon: JSX.Element;
      amountDescription: string;
      contingentBanner: {
        text: string;
        expanded?: JSX.Element;
        expandedHeight?: number;
        onSubmit?: () => Promise<void>;
      };
    }
  >> = {
    [ProductTypeEnum.VENDOR_MANAGEMENT]: {
      title: ProductTypeEnumToString[ProductTypeEnum.VENDOR_MANAGEMENT],
      description: GetMagicEstimateDescription(
        ProductTypeEnum.VENDOR_MANAGEMENT,
      ),
      icon: <MagicVendorIcon />,
      amountDescription: GetMagicEstimateSubtitle(
        ProductTypeEnum.VENDOR_MANAGEMENT,
      ),
      contingentBanner: {
        text: GetMagicContingencyText(ProductTypeEnum.VENDOR_MANAGEMENT),
      },
    },
    [ProductTypeEnum.HIGH_YIELD_SAVINGS]: {
      title: ProductTypeEnumToString[ProductTypeEnum.HIGH_YIELD_SAVINGS],
      description: GetMagicEstimateDescription(
        ProductTypeEnum.HIGH_YIELD_SAVINGS,
      ),
      icon: <MagicHYSIcon />,
      amountDescription: GetMagicEstimateSubtitle(
        ProductTypeEnum.HIGH_YIELD_SAVINGS,
      ),
      contingentBanner: {
        text: GetMagicContingencyText(ProductTypeEnum.HIGH_YIELD_SAVINGS, {
          amountCommittedCents: hysCommitment,
        }),
        expanded: (
          <div className={classes.expanded}>
            <p className={classes.emphasis}>
              Change your High Yield Account starting balance?
            </p>
            <p>
              This number is used to calculate the estimated yearly interest
              you&apos;ll earn on deposits.
            </p>
            <TextField
              className={classes.textField}
              variant='outlined'
              inputMode='numeric'
              label='$ Amount'
              value={hysCommitmentOverride / 100}
              InputProps={{
                inputComponent: CurrencyFormat as any,
              }}
              onChange={(event: any) => {
                const { value } = event.target;
                const numberValue = Number((value as string).replace(/,/g, ''));
                if (numberValue) {
                  setHysCommitmentOverride(numberValue * 100);
                }
              }}
            />
          </div>
        ),
        expandedHeight: 240,
        onSubmit: async () => {
          await updateWithOverride({
            amountCommittedCentsOverride: hysCommitmentOverride,
          });
          setHysCommitment(hysCommitmentOverride);
        },
      },
    },
  };

  const totalCreditAmount: number = (
    estimateData.creditEstimateSummary?.programCreditTotals ?? []
  ).reduce((acc: number, curr: ProgramCreditTotal) => {
    if (
      curr.name === ProgramNameEnum.MAGIC_MONEY ||
      curr.name === ProgramNameEnum.ERC
    ) {
      return acc;
    }
    // Need to update to value
    return curr.totalCreditAmountCents + acc;
  }, 0);

  const creditNames: string = (
    estimateData.creditEstimateSummary?.programCreditTotals ?? []
  )
    .sort((a, b) => a.totalCreditAmountCents - b.totalCreditAmountCents)
    .reduce((acc: string, curr: ProgramCreditTotal) => {
      if (
        curr.name === ProgramNameEnum.MAGIC_MONEY ||
        curr.name === ProgramNameEnum.ERC
      ) {
        return acc;
      }
      return acc
        ? `${acc}, ${Programs[curr.name].display}`
        : Programs[curr.name].display;
    }, '');

  return (
    <>
      {magicMoneyProgram.stage === ProgramStageEnum.DISQUALIFIED && (
        <div className={classes.root}>
          <div className={classes.contentContainer}>
            <div className={classes.mainContent}>
              <FinalMagicMoneyPage disqualified={true} />
            </div>
          </div>
        </div>
      )}
      {!error && magicMoneyProgram.stage !== ProgramStageEnum.DISQUALIFIED && (
        <Loading loading={loading}>
          <div className={classes.root}>
            <div className={classes.mainContent}>
              <div className={classes.leftColumnContent}>
                <Heading
                  tag='h1'
                  text='Your available MainStreet programs'
                  variant='regular'
                />
                <Text className={classes.subtitle}>
                  {`These estimates are based on what we're able to glean from accounting and payroll software, and the information you've given us. Note - numbers might change after our backend qualification process. Your total fee and savings guarantee are combined across all programs - estimates are not guaranteed for any individual product.`}
                </Text>
                <Heading
                  tag='h2'
                  text='Select your pilot programs'
                  variant='regular'
                />

                {estimateData.creditEstimateSummary?.productTypeCreditEstimates.map(
                  ({ productType, low, high }) => {
                    const info = perProductInfo[productType];
                    if (!info) {
                      return <></>;
                    }
                    return (
                      <SelectionCard
                        key={productType}
                        title={info.title}
                        amountRange={{
                          low,
                          high,
                          description: info.amountDescription,
                        }}
                        description={info.description}
                        icon={info.icon}
                        defaultValue={optedIn[productType].optedIn}
                        contingentBanner={info.contingentBanner}
                        onChange={(checked) => {
                          setOptedIn((optedIn) => {
                            optedIn[productType].optedIn = checked;
                            return optedIn;
                          });
                          return optinUpdate();
                        }}
                      />
                    );
                  },
                )}

                {!!totalCreditAmount && (
                  <>
                    <br />
                    <Heading tag='h2' text='R&D credits' variant='regular' />
                    <CreditsCard
                      title={'Credits'}
                      description={`Estimated amount for the credits that we are already working to with you to claim including the ${creditNames}`}
                      amountCents={totalCreditAmount}
                      icon={<MagicCredits />}
                    />
                  </>
                )}

                <div className={classes.continueButtonContainer}>
                  <Button
                    loading={submitting}
                    label={'Continue'}
                    onClick={submit}
                  />
                </div>
              </div>
              <div className={classes.rightColumnContent}>
                <GuaranteedNetBenefit
                  guaranteedAmountCents={
                    estimateData.creditEstimateSummary
                      ?.mainstreetGuaranteeAmountCents ?? 0
                  }
                  className={classes.guaranteeBlock}
                />
              </div>
            </div>
          </div>
        </Loading>
      )}
      {error && (
        <FormHelperText className={classes.formErrorMsg} error>
          {error}
        </FormHelperText>
      )}
    </>
  );
};

export default MagicMoneySelection;
