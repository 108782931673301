import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

// Keep all the process.env calls together to make dep tracking easier
const dataDogEnv = {
  env: window.MS_RUN_CONFIG['ENVIRONMENT'],
  site: 'datadoghq.com',
  service: 'client-dashboard',
  version: window.MS_RUN_CONFIG['GIT_SHA'],
  REACT_APP_CLIENT_DD_RUM_CLIENT_TOKEN:
    process.env.REACT_APP_CLIENT_DD_RUM_CLIENT_TOKEN,
  REACT_APP_CLIENT_DD_RUM_APP_ID: process.env.REACT_APP_CLIENT_DD_RUM_APP_ID,
};

const initializeLogs = () => {
  if (dataDogEnv.REACT_APP_CLIENT_DD_RUM_CLIENT_TOKEN) {
    datadogLogs.init({
      clientToken: dataDogEnv.REACT_APP_CLIENT_DD_RUM_CLIENT_TOKEN,
      site: dataDogEnv.site,
      service: dataDogEnv.service,
      env: dataDogEnv.env,
      forwardErrorsToLogs: true,
      sampleRate: 100,
    });
  } else {
    console.error('Failed to initialize DataDog logs due to missing params');
  }
};

const initializeRUM = () => {
  if (
    dataDogEnv.REACT_APP_CLIENT_DD_RUM_APP_ID &&
    dataDogEnv.REACT_APP_CLIENT_DD_RUM_CLIENT_TOKEN
  ) {
    datadogRum.init({
      applicationId: dataDogEnv.REACT_APP_CLIENT_DD_RUM_APP_ID,
      clientToken: dataDogEnv.REACT_APP_CLIENT_DD_RUM_CLIENT_TOKEN,
      site: dataDogEnv.site,
      service: dataDogEnv.service,
      env: dataDogEnv.env,
      version: dataDogEnv.version,
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins: [/https:\/\/.*dashboard.*\.mainstreet\.com/],
    });

    datadogRum.startSessionReplayRecording();
  } else {
    console.error('Failed to initialize DataDog RUM due to missing params');
  }
};

/**
 * Setup DataDog Real User Monitoring (RUM) and DataDog Logs. See
 * the docs for the respective tools:
 *
 * https://www.npmjs.com/package/@datadog/browser-logs
 * https://www.npmjs.com/package/@datadog/browser-rum
 */
export const initializeDataDog = () => {
  try {
    initializeLogs();
  } catch (e) {
    console.error('Failed to load DataDog logs ' + JSON.stringify(e));
  }

  try {
    initializeRUM();
  } catch (e) {
    console.error('Failed to load DataDog RUM ' + JSON.stringify(e));
  }
};
