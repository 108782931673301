import { observer } from 'mobx-react';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Heading, Link, Flex } from 'component-library';
import { makeStyles } from '@material-ui/core';
import ResourceCard from './ResourceCard';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import withIsMountedCheck from 'components/util/WithIsMountedCheck';
import { datadogLogs } from '@datadog/browser-logs';
import {
  useCommonStores,
  useCompany,
  useDashboardStores,
} from 'stores/useStores';
import { ModuleStore } from '../../../../products/dashboard/stores';
import ResourceHelpBar from './ResourceHelpBar';
import { IsDisplayableOrderForm } from 'lib/helpers';

const logger = datadogLogs.createLogger('ResourcesContainer');
export interface ResourcesContainerProps {
  title: string;
  dataTestId?: string;
}

const MAX_NUM_CHILDREN = 3;

const useStyles = makeStyles({
  root: {
    width: 'inherit',
  },
  title: {
    lineHeight: '36px',
  },
  helpCenterLink: {
    lineHeight: '24px',
  },
});

const ResourcesContainer: React.FC<ResourcesContainerProps> = observer(
  ({ title, dataTestId = 'resources-container' }) => {
    const classes = useStyles();
    const { client } = useContext(Auth0FeatureContext);
    const { company } = useCompany();
    const { modules } = useDashboardStores();
    const [hasAcceptedOrderForm, setHasAcceptedOrderForm] = useState<
      boolean | undefined
    >(false);
    const [resourceCards, setResourceCards] = useState<JSX.Element[]>([]);
    const { chatbot } = useCommonStores();

    /**
     * Renders up to MAX_NUM_CHILDREN resource cards, ordered by priority.
     */
    const getResourceCards = () => {
      const resources = modules.resourceCards.slice().sort((a, b) => {
        return a.priority - b.priority;
      });
      const resourcesToRender = resources.slice(0, MAX_NUM_CHILDREN);
      const backupGradients = ModuleStore.getRandomGradients(
        resourcesToRender.length,
      );
      return resourcesToRender.map((resource, index) => (
        <ResourceCard
          key={resource.title}
          priority={resource.priority}
          link={resource.resourceUrl}
          image={resource.imageUrl || backupGradients[index]}
          category={resource.category}
          title={resource.title}
          subtext={resource.subtext}
          dataTestId={`resource-card-${index}`}
        />
      ));
    };

    useEffect(() => {
      modules.initializeResourceCards();
      setupResourceCards();
    }, [modules.resourceCards.length]);

    const setupResourceCards = useCallback(() => {
      if (modules.resourceCards.length > 0) {
        // Storing the resource cards as state prevents the gradients from
        // changing whenever the component may be re-rendered
        setResourceCards(getResourceCards);
      }
    }, [resourceCards]);

    const emailSales = () => {
      const email = `
        mailto:sales@mainstreet.com
        ?subject=I need help!
        &body=%0D%0A%0D%0A%0D%0A---------------------------------------------------------%0D%0APlease do not delete beneath this line:%0D%0ACompany Name: ${company.name}%0D%0ACompany ID: ${company.id}
      `;

      window.location.href = email;
    };

    const startChat = () => {
      const chatButton = document.querySelector(
        '.helpButtonEnabled.uiButton',
      ) as HTMLElement;

      if (!chatButton) {
        logger.error(
          'Unable to find the chat button. Directing user to send an email instead.',
        );
        emailSales();
      } else {
        if (chatbot.isChatBotFeatureFlagOn) {
          chatbot.toggleChatWindow();
        } else {
          chatButton.click();
        }
      }
    };

    useEffect(() => {
      return withIsMountedCheck(async (isMounted) => {
        const data = (await client.GetAllOrderForms()).data;

        if (data) {
          if (isMounted() && data.orderForms) {
            const orderForms = data.orderForms.filter(IsDisplayableOrderForm);

            const hasAcceptedAt = orderForms?.some((orderForm) => {
              return orderForm.acceptedAt !== null;
            });

            setHasAcceptedOrderForm(hasAcceptedAt);
          }
        }
      });
    }, [client]);

    return (
      <Flex direction='column' className={classes.root}>
        <Flex
          direction='row'
          justifyContent='space-between'
          padding={[0, 0, 16, 0]}
          alignItems='end'
        >
          <Heading
            tag='h4'
            text={title}
            variant='medium'
            className={classes.title}
            noMargin
          />
          <Link
            text='Visit the Help Center'
            href='https://mainstreet-help.force.com/help/s/'
            target='_blank'
            omitIcon
            variant='medium'
            className={classes.helpCenterLink}
            size={15}
          />
        </Flex>
        <Flex
          direction='row'
          justifyContent='space-between'
          alignItems='flex-start'
          gap={24}
          dataTestId={`${dataTestId}-children-container`}
          padding={[0, 0, 34, 0]}
        >
          {resourceCards}
        </Flex>
        <ResourceHelpBar
          hasAcceptedOrderForm={hasAcceptedOrderForm || false}
          emailSales={emailSales}
          startChat={startChat}
        />
      </Flex>
    );
  },
);

export default ResourcesContainer;
