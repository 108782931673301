import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Color, Flex, FontAwesome, Text } from 'component-library';
import { useCommonStores, useOnboardingStores } from 'stores/useStores';
import { observer } from 'mobx-react';
import { TalkToAnExpertModal } from 'components/TalkToAnExpertModal';

const useStyles = makeStyles(() => ({
  expertBtn: {
    backgroundColor: Color.blue._20,
    borderRadius: 24,
    transition: 'background-color .2s',
    padding: '12px 16px 12px 12px',

    '&:hover': {
      backgroundColor: Color.blue._10,
      '& $expertBtnIcon': {
        backgroundColor: Color.blue._60,
      },
    },
  },
  outlined: {
    borderRadius: 0,
    backgroundColor: Color.neutral.white,

    '&:hover': {
      backgroundColor: Color.neutral._10,
      '& $expertBtnIcon': {
        backgroundColor: Color.blue._50,
      },
    },
  },
  expertBtnIcon: {
    backgroundColor: Color.blue._50,
    borderRadius: '50%',
    width: 32,
    height: 32,
    marginRight: 8,
    transition: 'background-color .2s',
  },
  iconOutlined: {
    backgroundColor: Color.blue._60,
  },
  icon: {
    lineHeight: '24px',
  },
  buttonText: {
    borderRadius: 0,
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: 'transparent',
      '& p': {
        textDecoration: 'underline',
      },
    },
  },
}));

interface TalkToAnExpertButtonProps {
  buttonOutlined?: boolean;
  buttonText?: boolean;
  className?: string;
}

const TalkToAnExpertButton: React.FC<TalkToAnExpertButtonProps> = observer(
  ({ buttonOutlined, buttonText, className }) => {
    const classes = useStyles();
    const [showCalendly, setShowCalendly] = useState<boolean>(false);
    const { initialAccountSetup } = useOnboardingStores();
    const { app } = useCommonStores();

    return (
      <>
        <Button
          className={`${classes.expertBtn} ${
            buttonOutlined ? classes.outlined : ''
          } ${buttonText ? classes.buttonText : ''} ${className}`}
          variant={buttonOutlined ? 'outlined' : 'tertiary'}
          label={
            <Flex alignItems='center' justifyContent='center'>
              {!app.isMobile && !buttonText && (
                <Flex
                  basis={40}
                  justifyContent='center'
                  alignItems='center'
                  className={`${classes.expertBtnIcon} ${
                    buttonOutlined && classes.iconOutlined
                  }`}
                >
                  <FontAwesome
                    variant='solid'
                    name='phone'
                    size={16}
                    color={Color.neutral.white}
                    className={classes.icon}
                  />
                </Flex>
              )}
              <Text variant='medium' color={Color.blue._60}>
                Talk to an expert
              </Text>
            </Flex>
          }
          onClick={() => initialAccountSetup.setShowTalkToExpertModal(true)}
        />

        <TalkToAnExpertModal
          setShowCalendly={setShowCalendly}
          showCalendly={showCalendly}
        />
      </>
    );
  },
);

export default TalkToAnExpertButton;
