import React from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  useAccountCreationStores,
  useCommonStores,
  useCompany,
  useDashboardStores,
  useFeatureFlags,
  useLegacyClients,
} from 'stores/useStores';
import {
  Text,
  Flex,
  Animate,
  Grid,
  Heading,
  Card,
  Divider,
  Button,
  Color,
  Alert,
} from 'component-library';
import { AnimatedGradient } from 'products/onboarding/components';
import {
  ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
  Page,
  SignupStageEnum,
} from 'lib/constants';
import { AdditionalCredit } from '../steps/UnifiedCreditEstimatesStep';
import { EmptyClient } from '../../../../../services/ServerClient';
import { TermAcceptanceModal } from './TermAcceptanceModal';

const useStyles = makeStyles(() => ({
  estimates: {
    opacity: 1,
    transition: 'opacity .4s',
  },
  wrapper: {
    backgroundColor: Color.neutral.white,
    position: 'relative',
  },
  fadeOutContainer: {
    opacity: 0,
  },
  surveyQuestion: {
    '& button': {
      padding: '12px 15px',
    },
  },
  container: {
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',

    '& span': {
      lineHeight: '1.4em !important',
    },
  },
  mainContent: {
    width: '100%',
    minHeight: '100vh',
    position: 'relative',
    zIndex: 1,
    display: 'flex',
  },
  creditsContainer: {
    borderTop: `1px solid ${Color.neutral._20}`,
    borderBottom: `1px solid ${Color.neutral._20}`,
  },
  estimateText: {
    width: '40%',
    minWidth: '150px',
  },
  credits: {
    padding: '12px 16px',
  },
  icon: {
    transform: 'rotate(0deg)',
    transition: 'transform .2s',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  congratsContainer: {
    position: 'sticky',
    height: '100vh',
    top: 0,
  },
  congrats: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
  },
  congratsMobile: {
    textAlign: 'center',
    paddingTop: '40px',
    zIndex: 2,
  },
  contentGrid: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  comingSoonChip: {
    backgroundColor: '#FFFFFF',
  },
  sharedSavingsCard: {
    backgroundColor: `${Color.neutral._light_20}`,
  },
  employeeBenefitsHeader: {
    alignItems: 'center',
  },
  employeeBenefitsHeaderTitle: {
    fontSize: '20px',
  },
  employeeBenefitsDescription: {
    marginBottom: '24px',
  },
}));

interface EligibleCreditEstimatesProps {
  handleAdditionalCreditsFlags: () => AdditionalCredit[];
}

interface AccountCreationProgramTable {
  name: string;
  estimate: string;
}

const employeeBenefitsCards = {
  educationalAssistance: {
    name: 'Educational Assistance',
    description:
      'Offer your team up to $5,250 annually in tax-free reimbursements for education, including student loans.',
  },
  remoteWorkReimbursements: {
    name: 'Remote Work Embursements',
    description: `Support your employees' home office needs with tax-free reimbursements for related expenses.`,
  },
  internetConnectivity: {
    name: 'WiFi & Mobile Coverage',
    description: 'Lorum Ipsum',
  },
};

export const EligibleCreditEstimates = observer(
  ({ handleAdditionalCreditsFlags }: EligibleCreditEstimatesProps) => {
    const classes = useStyles();
    const { modules } = useDashboardStores();
    const { accountCreation } = useAccountCreationStores();
    const { company, store: companyStore } = useCompany();
    const { app } = useCommonStores();
    const { client } = useLegacyClients();
    const featureFlag = useFeatureFlags();

    const onContinue = async () => {
      accountCreation.isNextButtonLoading = true;
      await client
        .MoveCompanyToNextOnboardingStep(SignupStageEnum.FINISHED)
        .then(async () => {
          await companyStore.refreshCurrentCompany();

          if (featureFlag.showSavingsAgreement) {
            if (
              accountCreation.programCreditEstimates &&
              accountCreation.programCreditEstimates.length > 0 &&
              accountCreation.totalEstimate !== 0
            ) {
              await accountCreation.handleCreateOrderForms(
                ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
              );
            }
            app.history.push(`/${Page.savingsAgreement}`);
            accountCreation.isNextButtonLoading = false;
          } else {
            if (featureFlag.showMstSubscriptionAcceptancePage) {
              modules.toggleTermAcceptanceModal(true);
              await EmptyClient.UpdateCompanyMisc({
                viewedUnifiedMainstreetTermsModal: true,
              });
              await EmptyClient.CustomerIoTrackEvent(
                'viewed_unified_mainstreet_terms_modal',
                company.id,
              );
            }
            if (!featureFlag.showMstSubscriptionAcceptancePage) {
              if (
                accountCreation.programCreditEstimates &&
                accountCreation.programCreditEstimates.length > 0 &&
                accountCreation.totalEstimate !== 0
              ) {
                await accountCreation.handleCreateOrderForms(
                  ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
                );
                app.history.push(
                  `/${Page.orderForms}/${ACCOUNT_CREATION_QUALIFYING_TAX_YEAR}`,
                );
              } else {
                app.history.push(`/${Page.taxCredits}`);
              }
              accountCreation.isNextButtonLoading = false;
            }
          }
        });
    };

    const creditDefaults = handleAdditionalCreditsFlags();
    const creditEstimates = accountCreation.programCreditEstimates ?? [];
    const combinedEstimates = creditEstimates.reduce(
      (
        acc: AccountCreationProgramTable[],
        estimate: AccountCreationProgramTable,
      ) => {
        const existingCombinedEstimate = acc.find(
          (combinedEstimate) => combinedEstimate.name === estimate.name,
        );
        if (existingCombinedEstimate) {
          existingCombinedEstimate.estimate += estimate.estimate;
        } else {
          acc.push(estimate);
        }
        return acc;
      },
      [],
    );
    const displayableCreditEstimates = combinedEstimates.filter((estimate) => {
      return creditDefaults.find(
        (creditDefault) => creditDefault.name === estimate.name,
      );
    });

    const renderNoMatchingCreditsLeft = () => (
      <>
        <Heading variant='medium' tag='span' size={app.isMobile ? 24 : 32}>
          {company.name} is not matched with any business tax credits
        </Heading>
      </>
    );

    const renderNoMatchingCreditsRight = () => (
      <Grid
        columns={12}
        alignItems='center'
        justifyContent='center'
        padding={[0, 0, 24, 0]}
      >
        <Grid.Cell columns={1} />
        <Grid.Cell columns={10} alignSelf='center'>
          <Text size={15} variant='bold' textAlign='center'>
            {company.name} is not eligible for any business tax credits
            at&nbsp;the&nbsp;moment
          </Text>
          <Text size={15} textAlign='center'>
            We couldn&apos;t identify any business tax credits for{' '}
            {company.name} at this time but you may be eligible for other
            additional savings below.
          </Text>
        </Grid.Cell>
        <Grid.Cell columns={1} />
      </Grid>
    );

    const renderSharedSavingsSection = (
      <>
        <Flex
          direction='column'
          padding={16}
          gap={8}
          className={classes.sharedSavingsCard}
        >
          <Flex
            direction='row'
            gap={8}
            className={classes.employeeBenefitsHeader}
          >
            <Text
              className={classes.employeeBenefitsHeaderTitle}
              variant='bold'
              size={18}
            >
              Shared Savings
            </Text>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '120px',
                fontSize: '12px',
                fontWeight: 'bold',
                lineHeight: '24px',
                backgroundColor: 'white',
                border: `1px solid ${Color.green._60}`,
                color: `${Color.green._60}`,
                borderRadius: '15px',
              }}
            >
              COMING SOON
            </div>
          </Flex>
          <Text className={classes.employeeBenefitsDescription}>
            Discover MainStreet&apos;s latest range of benefits—unlock hidden
            tax savings for you and your employees
          </Text>
          <Flex direction='column'>
            <Text
              size={app.isMobile ? 15 : 18}
              paddingBottom={24}
              text={employeeBenefitsCards.educationalAssistance.name}
            />
            <Divider />
            <Text
              size={app.isMobile ? 15 : 18}
              paddingBottom={24}
              text={employeeBenefitsCards.remoteWorkReimbursements.name}
            />
            <Divider />
            <Text
              size={app.isMobile ? 15 : 18}
              paddingBottom={16}
              text={employeeBenefitsCards.internetConnectivity.name}
            />
          </Flex>
        </Flex>
      </>
    );

    return (
      <Animate enter={'fade-in'} duration={0.25} className={classes.wrapper}>
        <AnimatedGradient />
        <Animate className={classes.mainContent} enter={'fade-in'} delay={0.25}>
          <Flex
            className={classes.estimates}
            alignItems='center'
            padding={app.isMobile ? [0, 0, 80, 0] : 0}
          >
            <Grid
              className={classes.container}
              columns={12}
              padding={app.isMobile ? [24, 24, 48, 24] : [0, 24, 48, 24]}
              gap={app.isMobile ? 16 : 0}
            >
              <Grid.Cell
                alignSelf='flex-start'
                columns={app.isMobile ? 12 : 5}
                className={app.isMobile ? '' : classes.congratsContainer}
              >
                <div
                  className={
                    app.isMobile ? classes.congratsMobile : classes.congrats
                  }
                >
                  {displayableCreditEstimates.length > 0 ? (
                    <>
                      <Heading
                        variant='medium'
                        tag='span'
                        size={app.isMobile ? 24 : 32}
                      >
                        Congrats! {company.name}{' '}
                      </Heading>
                      <Heading tag='span' size={app.isMobile ? 24 : 32}>
                        is matched with tax&nbsp;credits!
                      </Heading>
                    </>
                  ) : (
                    renderNoMatchingCreditsLeft()
                  )}
                </div>
              </Grid.Cell>
              <Grid.Cell columns={app.isMobile ? 12 : 1} />
              <Grid.Cell
                columns={app.isMobile ? 12 : 6}
                padding={app.isMobile ? 0 : [40, 0, 48, 0]}
                className={classes.contentGrid}
              >
                <Card noBoxShadow noMargin>
                  <Flex
                    direction='column'
                    padding={[4, 24, 0, 24]}
                    justifyContent='center'
                    alignItems='center'
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/coins.svg`}
                      alt='Coin icon'
                      width={100}
                    />
                    {/* If this customer is eligible for any credit show a positive message */}
                    {displayableCreditEstimates.length > 0 ? (
                      <Flex padding={app.isMobile ? 0 : [0, 80]}>
                        <Text
                          variant='medium'
                          size={23}
                          textAlign='center'
                          paddingBottom={32}
                        >
                          Here are the tax credits we think {company.name} is
                          eligible for:
                        </Text>
                      </Flex>
                    ) : (
                      renderNoMatchingCreditsRight()
                    )}
                  </Flex>
                  {displayableCreditEstimates.length > 0 ? (
                    <Flex
                      className={classes.creditsContainer}
                      justifyContent='space-between'
                      gap={4}
                      alignItems='center'
                      direction='column'
                    >
                      {displayableCreditEstimates.map((credit, index) => (
                        <Flex key={index} direction='column' padding={[0, 16]}>
                          <Flex
                            justifyContent='space-between'
                            alignItems='center'
                            padding={[16, 0]}
                          >
                            <Text
                              size={app.isMobile ? 15 : 18}
                              text={credit.name}
                            />
                            <Text
                              size={app.isMobile ? 15 : 18}
                              text={
                                credit.estimate === '$0'
                                  ? creditDefaults.find(
                                      (creditDefault) =>
                                        creditDefault.name === credit.name,
                                    )?.estimate
                                  : credit.estimate
                              }
                              variant='medium'
                              textAlign='right'
                              className={classes.estimateText}
                            />
                          </Flex>
                          {index !== displayableCreditEstimates.length - 1 && (
                            <Divider variant='no-bottom-margin' />
                          )}
                        </Flex>
                      ))}
                    </Flex>
                  ) : (
                    <Divider variant='no-bottom-margin' />
                  )}
                  {renderSharedSavingsSection}
                  {displayableCreditEstimates.length > 0 && (
                    <Alert
                      backgroundColor={Color.blue._10}
                      text={
                        <Flex direction='column'>
                          <Text size={15}>
                            These credit estimates are non-binding and do not
                            represent your final credit&nbsp;amount.
                          </Text>
                        </Flex>
                      }
                      type='info'
                      variant='in_card'
                    />
                  )}
                  {!featureFlag.showSavingsAgreement && (
                    <TermAcceptanceModal
                      areAllProgramsDisqualified={
                        displayableCreditEstimates.length === 0
                      }
                    />
                  )}
                  <Divider variant='no-bottom-margin' />
                  <Flex direction='column'>
                    <Flex padding={16} justifyContent='flex-end'>
                      <Button
                        label='Continue'
                        onClick={onContinue}
                        loading={accountCreation.isNextButtonLoading}
                      />
                    </Flex>
                  </Flex>
                </Card>
              </Grid.Cell>
            </Grid>
          </Flex>
        </Animate>
      </Animate>
    );
  },
);
