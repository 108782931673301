import React from 'react';
import { Page } from 'lib/constants';
import { Text, Link } from 'component-library';

export const PrivacyConcern = () => {
  return (
    <Text>
      We&apos;ll never sell your data.{' '}
      <Link href={`/${Page.privacy}`} target='_blank'>
        Here&apos;s our privacy policy.
      </Link>
    </Text>
  );
};
