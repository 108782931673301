import React, { useState, useContext } from 'react';
import {
  Button,
  Card,
  Color,
  Content,
  Flex,
  FontAwesome,
  Icon,
  IconEnum,
  Link,
  Text,
  Tooltip,
  Message,
  Toast,
} from 'component-library';
import { ConnectCard } from './ConnectCard';
import { ConnectCardHeader } from './ConnectCardHeader';
import { makeStyles } from '@material-ui/core';
import { CopyToClipboard } from 'lib/copyToClipboard';
import { CompanyContext } from 'pages/CompanyRequired';

const useStyles = makeStyles(() => ({
  connectButton: {
    width: '165px',
    marginTop: '16px',
  },
  tooltip: {
    verticalAlign: 'sub',
    color: '#67747D',
    display: 'inline-flex',
  },
  labelWithIcon: { display: 'flex', alignItems: 'center' },
  infoIcon: {
    fontSize: '18px',
    marginLeft: '2px',
  },
}));

interface TaxStatusProps {
  useCardComponent?: boolean;
}

const TaxStatus: React.FC<TaxStatusProps> = ({ useCardComponent }) => {
  const { company, setCompany } = useContext(CompanyContext);
  const title = 'Connect your TaxStatus';
  const classes = useStyles();
  const taxStatusLink = process.env.REACT_APP_CONNECTION_NAME?.includes('prod')
    ? 'https://mainst.app.tax/'
    : 'https://mainst.app.tax/?test';

  const [status, setStatus] = useState<string>('');
  const [isLinkCopied, setIsLinkCopied] = useState<boolean | undefined>(
    undefined,
  );
  const [toast, setToast] = useState<Message[]>([]);

  const onCopied = () => {
    setToast([
      {
        text: 'Link, copied!',
        status: 'success',
      },
    ]);
  };

  const header = (
    <>
      <Flex alignItems='center'>
        <Flex>
          <ConnectCardHeader title={title} />
          <Tooltip
            className={classes.tooltip}
            text={`Authorize MainStreet to review your IRS filings via TaxStatus for added accuracy assurance, ensuring proper credit claims.`}
            position='top'
          >
            <Icon
              className={classes.infoIcon}
              name={IconEnum.question_circle}
            />
          </Tooltip>
        </Flex>
        <Flex
          padding={[0, 0, 16, 0]}
          gap={4}
          alignItems='center'
          justifyContent='flex-end'
        >
          <Link
            target='_blank'
            omitIcon
            onClick={() => CopyToClipboard(taxStatusLink, onCopied)}
          >
            <FontAwesome name='link' size={14} color={Color.blue._60} />
            &nbsp;
            <Text
              text='Copy link instead'
              variant='medium'
              color={Color.blue._60}
              size={15}
            />
          </Link>
        </Flex>
      </Flex>
      {toast.length > 0 && <Toast toasts={toast} setToast={setToast} />}
    </>
  );
  const cardContent = (
    <Flex direction='column' gap={16}>
      <Text>
        Connect your TaxStatus to enable us to verify your filings and save
        time. This process streamlines your future tax processes, saving you the
        hassle of repeatedly submitting tax returns.
      </Text>
      <Text>
        Authorization from an IRS-registered company officer such as your CEO,
        CFO, or founder is required. Not the decision-maker? No worries. Simply
        share the link with the appropriate person.
      </Text>

      <Button
        className={classes.connectButton}
        label='Connect TaxStatus'
        onClick={() => window.open(taxStatusLink, '_blank')}
      />
    </Flex>
  );

  return useCardComponent ? (
    <Card>
      <Content>{header}</Content>
      <Content paddingTop={0} paddingLeftRight={32} paddingBottom={32}>
        {cardContent}
      </Content>
    </Card>
  ) : (
    <ConnectCard header={header} mainContent={cardContent} />
  );
};

export default TaxStatus;
