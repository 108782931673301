import {
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
} from 'lib/constants';

export class ResourceCardFilter {
  programName: ProgramNameEnum | undefined;
  programStage: ProgramStageEnum | undefined;
  programSubStage: ProgramSubStageEnum | undefined;
  year: number | undefined;

  /*
  Expected format for stringFilterToParse is: `{programNamEnumKey}___{programStageEnumKey}__{programSubStageEnumKey}___{year}`
  where everything is optional (but order has to be preserved). If criteria not included, it's assumed as not needed
  */
  constructor(stringFilterToParse: string) {
    const L0_SEPARATOR = '___';
    const L1_SEPARATOR = '__';

    const parsed = stringFilterToParse.split(L0_SEPARATOR);
    const [programName, programStage, year] = parsed;

    if (Number(year)) {
      this.year = Number(year);
    }

    if (programName) {
      this.programName =
        ProgramNameEnum[programName as keyof typeof ProgramNameEnum];
    }

    if (programStage) {
      const parsedProgramStage = programStage.split(L1_SEPARATOR);
      this.programStage =
        ProgramStageEnum[
          parsedProgramStage[0] as keyof typeof ProgramStageEnum
        ];

      if (parsedProgramStage.length > 1) {
        this.programSubStage =
          ProgramSubStageEnum[
            parsedProgramStage[1] as keyof typeof ProgramSubStageEnum
          ];
      }
    }
  }
}
