import { makeStyles } from '@material-ui/core';
import {
  Color,
  EstimateModule,
  StepCard,
  StepCardTitle,
  StepsContainer,
} from 'component-library';
import {
  ProgramNameEnum,
  Programs,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationStatusEnum,
} from 'lib/constants';
import { CentsToDisplayString, TitleCase, useEffectOnce } from 'lib/helpers';
import { ProgramData } from 'lib/interfaces';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useFeatureFlags, useTaxCreditsStores } from 'stores/useStores';
import { Step, TaxCreditProgramSteps } from '../TaxCreditProgramSteps';
import { ClientReviewStep } from './steps/ClientReviewStep';
import { ClientReviewStepNew } from './steps/ClientReviewStepNew';
import { ExpenseClassificationStep } from './steps/ExpenseClassificationStep';
import { ExpertReviewStep } from './steps/ExpertReviewStep';
import { FinishedStep } from './steps/FinishedStep';
import { QualifyingStep } from './steps/QualifyingStep';

const useStyles = makeStyles(() => ({
  stepContainer: {
    marginTop: '36px',
  },
}));

interface TaxCreditsProgramStepperProps {
  federalRDProgram: ProgramData;
  programs: ProgramData[];
}

export const TaxCreditsProgramStepper = observer(
  ({ federalRDProgram, programs }: TaxCreditsProgramStepperProps) => {
    const classes = useStyles();
    const { form8974, creditEstimates } = useTaxCreditsStores();
    const [complete, setComplete] = useState<boolean>(false);
    const featureFlags = useFeatureFlags();

    const {
      created,
      orderForm,
      qualificationStatus,
      stage,
      subStage,
      taxFilingDate,
      taxYear,
    } = federalRDProgram;

    const programCompleted =
      (stage === ProgramStageEnum.FINISHED &&
        subStage === ProgramSubStageEnum.REDEEMING) ||
      stage === ProgramStageEnum.COMPLETED;
    const programCanceled = stage === ProgramStageEnum.CANCELED;
    const showProgramStepper = !programCompleted && !programCanceled;

    const chipTitles = programs
      .filter(
        (program: ProgramData) => program.name === ProgramNameEnum.FED_RD_TAX,
      )
      .map((program: ProgramData) => TitleCase(Programs[program.name].geo));

    const currentAmount = creditEstimates.currentAmountByYear.get(taxYear) ?? 0;
    const estimateTitle =
      creditEstimates.estimateTitleByYear.get(taxYear) ?? '';
    const estimateTooltip =
      creditEstimates.estimateTooltipByYear.get(taxYear) ?? '';
    const activeStep: Step = TaxCreditProgramSteps[stage];
    const acceptedOrderForm = orderForm?.acceptedAt !== null;
    const showEstimatesModule =
      (stage === ProgramStageEnum.EXPENSE_CLASSIFICATION ||
        stage === ProgramStageEnum.MS_REVIEW ||
        stage === ProgramStageEnum.FINISHED ||
        stage === ProgramStageEnum.CLIENT_REVIEW) &&
      qualificationStatus === QualificationStatusEnum.QUALIFIED &&
      acceptedOrderForm &&
      featureFlags.showCreditEstimatesModule &&
      currentAmount !== 0;

    useEffectOnce(() =>
      setComplete(form8974.programIsComplete(federalRDProgram)),
    );

    return (
      <div className={classes.stepContainer}>
        <StepCard dataTestId='step-card-component'>
          <StepCardTitle
            chipTitles={chipTitles}
            dataTestId='step-card-title'
            title={`${taxYear} Filing`}
          />
          {showEstimatesModule ? (
            <EstimateModule
              title={estimateTitle}
              titleTooltip={estimateTooltip}
              currentEstimate={CentsToDisplayString(currentAmount)}
              isLoading={creditEstimates.isLoading}
              isFinalCredit={stage === ProgramStageEnum.CLIENT_REVIEW}
            />
          ) : (
            <></>
          )}
          {showProgramStepper ? (
            <>
              <StepsContainer
                activeStep={activeStep.number + 1}
                complete={complete}
                dataTestId='steps-container'
                color={Color.blue._60}
              >
                <QualifyingStep
                  federalRDProgram={federalRDProgram}
                  programs={programs}
                />
                <ExpenseClassificationStep
                  federalRDProgram={federalRDProgram}
                />
                <ExpertReviewStep federalRDProgram={federalRDProgram} />
                {featureFlags.useClientReviewSidebar ? (
                  <ClientReviewStepNew federalRDProgram={federalRDProgram} />
                ) : (
                  <ClientReviewStep federalRDProgram={federalRDProgram} />
                )}
                <FinishedStep
                  federalRDProgram={federalRDProgram}
                  complete={complete}
                  setComplete={setComplete}
                  programs={programs}
                />
              </StepsContainer>
            </>
          ) : (
            <></>
          )}
        </StepCard>
      </div>
    );
  },
);
