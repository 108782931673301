import React from 'react';
import { Dropdown } from 'component-library';
import { CountryEnum, StateEnum } from '../../../../../lib/interfaces';
import { SnakeToSentenceCase } from '../../../../../lib/helpers';
import { EmployeeSideDrawerSection } from './EmployeeSideDrawerSection';
import { IndividualExpenseClassificationData } from '../../steps/Employees';

interface CountryAndStateGroupFieldProps {
  country: CountryEnum | undefined;
  setCountry: (value: CountryEnum) => void;
  stateOrRegion: StateEnum | undefined;
  setStateOrRegion: (value: StateEnum) => void;
  showStateOrRegionError: boolean;
  setShowStateOrRegionError: (value: boolean) => void;
  selectedPerson: IndividualExpenseClassificationData | null;
  isEditMode: boolean;
}

const CountryAndStateGroupField = ({
  country,
  setCountry,
  stateOrRegion,
  setStateOrRegion,
  showStateOrRegionError,
  setShowStateOrRegionError,
  selectedPerson,
  isEditMode,
}: CountryAndStateGroupFieldProps) => {
  const stateOrRegionIsMissingInformation =
    !selectedPerson?.workingFrom && isEditMode;

  return (
    <EmployeeSideDrawerSection title='Working from'>
      <>
        <Dropdown
          label='Country'
          name='country'
          value={selectedPerson?.country ?? country}
          defaultSelected={true}
          options={Object.keys(CountryEnum).map((countryKey) => {
            return {
              name: SnakeToSentenceCase(countryKey),
              value: CountryEnum[countryKey as keyof typeof CountryEnum],
            };
          })}
          onInputChange={(e) => {
            if (typeof e === 'string') {
              return setCountry(e as CountryEnum);
            }
            return setCountry(e.value as CountryEnum);
          }}
          dataTestId='country-w2employee'
        />
        <Dropdown
          error={showStateOrRegionError}
          helperText={showStateOrRegionError ? 'State required' : ''}
          label='State'
          name='state'
          value={selectedPerson?.workingFrom ?? stateOrRegion}
          options={Object.keys(StateEnum).map((stateKey) => {
            return {
              name: SnakeToSentenceCase(stateKey),
              value: StateEnum[stateKey as keyof typeof StateEnum],
            };
          })}
          onInputChange={(e: any) => {
            setStateOrRegion(e.value);
            setShowStateOrRegionError(e.value === undefined);
          }}
          dataTestId='state-w2employee'
          secondaryLabel={
            stateOrRegionIsMissingInformation
              ? 'Missing information'
              : undefined
          }
          secondaryLabelTheme={
            stateOrRegionIsMissingInformation ? 'warning' : undefined
          }
          displayDirection='top'
        />
      </>
    </EmployeeSideDrawerSection>
  );
};

export default CountryAndStateGroupField;
