import { AT_MS_DOMAIN } from '../../lib/constants';
import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ palette }: Theme) => ({
  link: {
    color: palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:visited': {
      color: palette.primary.main,
    },
  },
}));

interface Props {
  account?: string;
}

export const SupportLink = ({ account }: Props) => {
  const classes = useStyles();

  return (
    <a
      className={classes.link}
      href={`mailto:${account || 'support'}${AT_MS_DOMAIN}`}
      target='_blank'
      rel='noopener noreferrer'
    >
      {account || 'support'}
      {AT_MS_DOMAIN}
    </a>
  );
};
