import { Step, StepProps } from '../../TaxCreditProgramSteps';
import { StepCardStep } from 'component-library';
import React from 'react';
import { ExpertReviewStepFAQ } from '../../sideDrawer/ExpertReviewStepFAQ';
import { useTaxCreditsStores } from '../../../../../stores/useStores';
import { IllustrationCard } from 'pages/dashboard/taxCredits/IllustrationCard';

export const stepConfig: Step = {
  name: 'ms_review',
  number: 2,
  title: 'MainStreet reviews your assessment',
  dataTestId: 'ms-review',
  faq: <ExpertReviewStepFAQ />,
};

export const ExpertReviewStep = ({ federalRDProgram }: StepProps) => {
  const { taxCreditsPage } = useTaxCreditsStores();

  return (
    <StepCardStep
      active={stepConfig.name === federalRDProgram.stage}
      dataTestId={stepConfig.dataTestId}
      learnMoreOnClick={() =>
        taxCreditsPage.setShowSideDrawer(true, stepConfig.name)
      }
      stepNumber={stepConfig.number}
      title={stepConfig.title}
    >
      <IllustrationCard
        imgSrc={`${process.env.PUBLIC_URL}/images/tax-credit-dashboard-module/reviewing-info.svg`}
        heading={'Reviewing your information'}
        subHeading={
          'Our R&D specialists are performing a final review, and will reach out within 3 business days with further questions or a finalized credit.'
        }
      />
    </StepCardStep>
  );
};
