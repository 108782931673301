import { Content, Text } from 'component-library';
import { observer } from 'mobx-react';
import { EmployerContributionSideDrawer } from './EmployerContributionSideDrawer';
import { MemoizedEmployeeTable } from 'pages/tax-processing/expense-classification/components/employee-step/EmployeeTable';
import React, { useState } from 'react';
import { useCommonStores, useLegacyClients } from 'stores/useStores';
import { EmployerContributionEmployeeInputData } from './util';
import { CountryEnum, EmploymentRecordData } from 'lib/interfaces';
import { JobGroupEnum } from 'lib/constants';
import { CentsToDisplayStringNoSymbol } from 'lib/helpers';

interface EmployerContributionTableProps {
  programTaxYear: number;
  employees: EmploymentRecordData[];
  setEmployees: React.Dispatch<React.SetStateAction<EmploymentRecordData[]>>;
  onError: () => void;
  isPayrollConnected: boolean;
  isPayrollPending: boolean;
  isRetirementTable?: boolean;
}

const missingDataCheckTemplate = (text: string | null | undefined) =>
  text ? (
    <Content paddingRight={48} paddingTopBottom={16}>
      <Text size={15}>{text}</Text>
    </Content>
  ) : (
    <div
      style={{
        paddingTop: '4px', // Workaround pending adding more spacing options to padding in the Content component
      }}
    >
      <Content paddingRight={48} paddingTop={8}>
        <span aria-label='No data'>-</span>
      </Content>
    </div>
  );

export const EmployerContributionTable: React.FC<EmployerContributionTableProps> =
  observer(
    ({
      programTaxYear,
      employees,
      setEmployees,
      onError,
      isPayrollConnected,
      isPayrollPending,
      isRetirementTable,
    }) => {
      // Generic State
      const { client } = useLegacyClients();
      const { companyStore } = useCommonStores();
      const [showDrawer, setShowDrawer] = useState<boolean>(false);
      const [selectedPerson, setSelectedPerson] =
        useState<EmploymentRecordData | null>(null);

      // API
      const createEmploymentRecord = async (
        employee: EmployerContributionEmployeeInputData,
      ) => {
        const req = {
          ...employee,
          countryOfResidence: CountryEnum.UNITED_STATES,
          source: 'dashboard',
          jobGroup: JobGroupEnum.OTHER,
        };

        const res = companyStore.accessToken
          ? await client.CreateEmploymentRecordForUserCompanyPublic(
              companyStore.accessToken,
              req,
            )
          : await client.CreateEmploymentRecordForUserCompany(req);

        if (res.data && !res.errorMsg) {
          const { employmentRecordData } = res.data;
          setEmployees((prevEmployees) => [
            ...prevEmployees,
            employmentRecordData,
          ]);
        } else {
          throw new Error(`W2 Employee could not be created: ${res.errorMsg}`);
        }
      };

      const updateEmploymentRecord = async (
        employee: EmployerContributionEmployeeInputData,
      ) => {
        if (!selectedPerson) {
          throw new Error('No employee selected during update attempt.');
        }

        try {
          await client.UpdateMissingEmployeeInformation(
            selectedPerson.id,
            employee,
          );

          setEmployees((prevEmployees) => {
            const index = prevEmployees.findIndex(
              (employee) => employee.id === selectedPerson.id,
            );

            if (index === -1) {
              return prevEmployees;
            }

            const updatedEmployees = [...prevEmployees];
            updatedEmployees[index] = {
              ...updatedEmployees[index],
              ...employee,
            };

            return updatedEmployees;
          });
        } catch (error) {
          throw new Error(`Failed to update employee: ${error}`);
        }
      };

      const getEmployeeById = (id: number): EmploymentRecordData => {
        return employees.find((employee) => id === employee.id)!;
      };

      // Content
      const tableColumns = [
        {
          Header: 'Name',
          accessor: 'fullName',
          width: 170,
        },
        {
          Header: `${programTaxYear} Employee Taxable Wages`,
          accessor: 'taxablePayCents',
          width: 100,
          textAlignRight: true,
          Cell: (row: { data: EmploymentRecordData }) =>
            row.data.taxablePayCents
              ? missingDataCheckTemplate(
                  '$' + CentsToDisplayStringNoSymbol(row.data.taxablePayCents),
                )
              : missingDataCheckTemplate(null),
        },
        {
          Header: `${programTaxYear} Employer Contribution`,
          accessor: 'employerContribution',
          width: 100,
          textAlignRight: true,
          Cell: (row: { data: EmploymentRecordData }) =>
            row.data.employerContribution || row.data.employerContribution === 0
              ? missingDataCheckTemplate(
                  '$' +
                    CentsToDisplayStringNoSymbol(row.data.employerContribution),
                )
              : missingDataCheckTemplate(null),
        },
      ];

      const title =
        'Make sure that all your US-based employees are included in the following table';
      const subtitle =
        'Please verify that the information in this table is complete and accurate. We will use this information to determine your eligible employer retirement plan contributions credits. Any inaccurate data could lead to a lower/incorrect credit amount.';

      const getNoDataContent = () => {
        let content;

        if (!isPayrollConnected) {
          content = (
            <>
              <Text textAlign='center' variant='medium'>
                There are no employees to display yet.
              </Text>

              <Text textAlign='center' tag='span'>
                Looks like you haven&apos;t linked your payroll with MainStreet!
                Save time by connecting your payroll in order to automatically
                sync your employee details.
              </Text>
            </>
          );
        } else if (isPayrollPending) {
          content = (
            <>
              <Text textAlign='center' variant='medium'>
                Wait - payroll data import in progress
              </Text>

              <Text textAlign='center' tag='span'>
                You will receive an email from support@mail.mainstreet.com when
                you are ready to proceed! This should take 20-30 minutes.
              </Text>
            </>
          );
        } else {
          content = (
            <>
              <Text textAlign='center' variant='medium'>
                There are no employees to display yet.
              </Text>

              <Text textAlign='center' tag='span'>
                Your payroll is connected and synced, but we didn&apos;t find
                any employee data.
              </Text>
            </>
          );
        }

        return content;
      };

      return (
        <>
          <MemoizedEmployeeTable
            tableEditMode={true}
            setTableEditMode={() => {
              console.log('setTableEditMode');
            }}
            columns={tableColumns}
            data={employees}
            rowOnClick={(value) => {
              setSelectedPerson(getEmployeeById(value.id));
              setShowDrawer(true);
            }}
            primaryOnClick={() => {
              console.log('primaryOnClick');
            }}
            secondaryOnClick={() => {
              setShowDrawer(true);
            }}
            dataType='employee'
            doesOtherJobGroupExist={false}
            customTitle={title}
            customSubtitle={subtitle}
            customNoDataElement={getNoDataContent()}
            hidePrimary={true}
            hideAddEmployee={isPayrollPending}
          />
          {/* Needs to exist in code or transitions does not exist */}
          <EmployerContributionSideDrawer
            selectedPerson={selectedPerson}
            setSelectedPerson={setSelectedPerson}
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            programTaxYear={programTaxYear}
            createEmploymentRecord={createEmploymentRecord}
            updateEmploymentRecord={updateEmploymentRecord}
            isRetirementTable={isRetirementTable}
          />
        </>
      );
    },
  );
