import React from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  Animate,
  Button,
  Color,
  Flex,
  Heading,
  Link,
  Text,
} from 'component-library';
import { Page } from 'lib/constants';
import { useDashboardStores, useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(({ breakpoints }) => ({
  welcomeStep: {
    gridColumn: '3 / span 8 !important', // in order to center grid cell
    textAlign: 'center',
    [breakpoints.down('xs')]: {
      gridColumn: '2 / span 10 !important',
    },
  },
}));

export const WelcomeToMainStreet: React.FC = observer(() => {
  const classes = useStyles();
  const { modules } = useDashboardStores();
  const { app } = useCommonStores();

  return (
    <Flex className={classes.welcomeStep}>
      <Flex direction='column' gap={app.isMobile ? 24 : 32} alignItems='center'>
        <Animate enter={'fade-in'} delay={0.15}>
          <Heading
            text='Tax Credits with MainStreet'
            variant='medium'
            size={app.isMobile ? 28 : 36}
            marginBottom={0}
          />
        </Animate>
        <Animate enter={'fade-in'} delay={0.45}>
          <Text
            text='MainStreet simplifies a process that can feel overwhelming.'
            size={app.isMobile ? 15 : 18}
          />
          <Text
            text='Learn how it works and what to expect.'
            size={app.isMobile ? 15 : 18}
          />
        </Animate>
        <Animate enter={'fade-in'} delay={0.85}>
          <Button
            label='Continue'
            onClick={() =>
              modules.setEducationalIndex(modules.educationalCurrentIndex + 1)
            }
            dataTestId='educational-module-get-started-btn'
          />
        </Animate>
        <Animate enter={'fade-in'} delay={1}>
          <Text color={Color.neutral._60} size={app.isMobile ? 13 : 15}>
            Feel free to{' '}
            <Link onClick={() => app.history.push(`${Page.dashboard}`)}>
              exit
            </Link>{' '}
            and revisit it anytime from your dashboard.
          </Text>
        </Animate>
      </Flex>
    </Flex>
  );
});
