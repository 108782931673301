import React from 'react';
import {
  Card,
  Flex,
  Icon,
  IconEnum,
  Link,
  Text,
  Divider,
  Color,
} from 'component-library';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  bulletList: {
    margin: '0',
    listStyleType: "'\\2022'",
    paddingLeft: '16px',
  },
  bulletItem: {
    paddingLeft: '8px',
  },
  greenCheck: {
    backgroundColor: Color.semantic.$success10,
    borderRadius: '50%',
    marginRight: '8px',
  },
  redX: {
    backgroundColor: Color.semantic.$error10,
    borderRadius: '50%',
    marginRight: '8px',
  },
}));

export const JobGroupFaq = () => {
  const classes = useStyles();
  return (
    <Flex direction='column'>
      <Flex direction='column' padding={[0, 0, 16, 0]}>
        <Text text='About job groups' variant='medium' paddingBottom={4} />
        <Divider />
        <Card noMargin>
          <Flex padding={24} direction='column' gap={8}>
            <Text color={Color.neutral._80}>
              We use individual employees&apos; salaries as the base for
              calculating the R&D tax credits you can claim. We&apos;ve
              organized your employees by job groups based on similar
              characteristics regarding their R&D activities.
            </Text>
            <Text color={Color.neutral._80}>
              Please indicate the percentage of time each group or individual
              spent on qualified R&D activities
            </Text>
          </Flex>
        </Card>
      </Flex>
      <Flex direction='column' padding={[0, 0, 16, 0]}>
        <Text
          text='What qualifies as R&D activities?'
          variant='medium'
          paddingBottom={4}
        />
        <Divider />

        <Card noMargin>
          <Flex padding={24} direction='column'>
            <Flex>
              <Icon
                className={classes.greenCheck}
                name={IconEnum.check}
                size={24}
                color={Color.green._50}
              />
              <Text
                text='Direct Research'
                variant='medium'
                paddingBottom={4}
                size={18}
              />
            </Flex>
            <Text color={Color.neutral._80}>
              The IRS regulations defines “engaging in direct qualified
              research” as work that directly contributes to the qualified
              research.
            </Text>
            <Flex padding={[16, 0]} direction='column'>
              <Text text='Examples' variant='medium' size={13} />
              <ul className={classes.bulletList}>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Developing functional, design, and test specifications`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Building new architecture, algorithms, or database management techniques`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Writing software programs that comprise a new or improved business component`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
              </ul>
            </Flex>
            <Link
              external
              href='https://www.irs.gov/businesses/audit-guidelines-on-the-application-of-the-process-of-experimentation-for-all-software'
              text='More details'
              variant='medium'
            />
          </Flex>
        </Card>

        <Card>
          <Flex padding={24} direction='column'>
            <Flex>
              <Icon
                className={classes.greenCheck}
                name={IconEnum.check}
                size={24}
                color={Color.green._50}
              />
              <Text
                text='Direct Supervision'
                variant='medium'
                paddingBottom={4}
                size={18}
              />
            </Flex>
            <Text
              text={`Direct supervision is "the immediate supervision (first-line management) of qualified research."`}
              color={Color.neutral._80}
            />
            <Flex padding={[16, 0]} direction='column'>
              <Text text='Examples' variant='medium' size={13} />
              <ul className={classes.bulletList}>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Team brainstorms`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Design reviews`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Running project status meetings`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Evaluating the performance of research teams`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
              </ul>
            </Flex>
          </Flex>
        </Card>

        <Card noMargin>
          <Flex padding={24} direction='column'>
            <Flex>
              <Icon
                className={classes.greenCheck}
                name={IconEnum.check}
                size={24}
                color={Color.green._50}
              />
              <Text
                text='Direct Support'
                variant='medium'
                paddingBottom={4}
                size={18}
              />
            </Flex>
            <Text color={Color.neutral._80}>
              Direct support can be direct support of qualifying research or
              direct support of those directly supervising the qualifying
              research.
            </Text>
            <Flex padding={[16, 0]} direction='column'>
              <Text text='Examples' variant='medium' size={13} />
              <ul className={classes.bulletList}>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Project management, including developing and tracking the schedule of a project`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Developing requirements or specs`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Adjusting product requirements to meet resource constraints`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Running benchmark programs to improve and experiment`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Attending technical and design review meetings`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Providing feedback to the development team about an a/b test`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
              </ul>
            </Flex>
          </Flex>
        </Card>

        <Card>
          <Flex padding={24} direction='column'>
            <Flex>
              <Icon
                className={classes.redX}
                name={IconEnum.x_mark}
                size={24}
                color={Color.semantic.$error50}
              />
              <Text
                text='Non-Qualifying R&D Activities'
                variant='medium'
                paddingBottom={4}
                size={18}
              />
            </Flex>
            <Text color={Color.neutral._80}>
              Some subset of activities may never qualify for the R&D credit
              because general administrative services or other services that
              only indirectly benefit research activities are not considered
              qualifying activities.
            </Text>
            <Flex padding={[16, 0]} direction='column'>
              <Text text='Examples' variant='medium' size={13} />

              <ul className={classes.bulletList}>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Most pure HR-type roles`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Building team job ladders, or interview rubrics, or employee training programs`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Market research, market testing, or marketing development`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Research in the social sciences`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Setting up general company foundations (eg. setting up software or bank accounts)`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
                <li className={classes.bulletItem}>
                  <Text
                    text={`Routine bug fixing, data gathering, or maintenance on existing and stable software products`}
                    color={Color.neutral._80}
                    size={13}
                  />
                </li>
              </ul>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};
