import React from 'react';
import { observer } from 'mobx-react';
import { Flex, Grid, Color } from 'component-library';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  orderSection: {
    border: `1px solid ${Color.neutral._20}`,
    boxShadow: Color.shadow.low,
    marginBottom: '32px',
  },
}));

interface SectionCardProps {
  children: React.ReactElement | JSX.Element;
  gridMinContent?: boolean;
}

export const SectionCard: React.FC<SectionCardProps> = observer(
  ({ children, gridMinContent }) => {
    const classes = useStyles();

    return (
      <Flex className={classes.orderSection}>
        <Grid columnsMinContent={gridMinContent}>{children}</Grid>
      </Flex>
    );
  },
);
