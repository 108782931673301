import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  FormHelperText,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import {
  Animate,
  Button,
  Color,
  Flex,
  Heading,
  TextField as TextFieldCL,
  Text,
  Image,
  Modal,
  Card,
  Link,
  FontAwesome,
  Spinner,
  Expandable,
} from 'component-library';
import TalkToAnExpertButton from 'components/util/TalkToAnExpertButton';
import {
  useCommonStores,
  useCompany,
  useFeatureFlags,
  useOnboardingStores,
} from 'stores/useStores';
import { AnimatedGradient } from 'products/onboarding/components';
import { ALL_PAYROLL_SYSTEMS, CreditCategoryEnum, Page } from 'lib/constants';
import { CentsToDisplayString, useEffectOnce, useIsMounted } from 'lib/helpers';
import { Formik } from 'formik';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { useLocation } from 'react-router-dom';
import { usePasswordValidation } from 'lib/usePasswordValidation';
import { CreateCompanySelfSignupRequest, SourcingData } from 'lib/interfaces';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import * as yup from 'yup';
import { PasswordChecklist } from 'pages/onboarding/components/PasswordChecklist';
import confetti from 'canvas-confetti';
import {
  CreditsSection,
  FooterSection,
  HowItWorksSection,
  IRSGuidelinesSection,
  PartnershipSection,
  StartSavingSection,
} from './sections';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  '@global': {
    '.embeddedServiceHelpButton': {
      display: 'none !important',
    },
  },
  rootContent: {
    position: 'relative',
    zIndex: 1,
  },
  heroSectionContainer: {
    width: '100%',
    padding: 24,
    position: 'relative',
    overflow: 'hidden',
  },
  heroSection: {
    position: 'relative',
    width: '100%',
    maxWidth: '1440px',
    margin: '0 auto',
    transition: 'opacity .4s',
    opacity: 1,
    pointerEvents: 'all',
  },
  heroSectionHide: {
    opacity: 0,
    pointerEvents: 'none',
  },
  heroSectionAccess: {
    width: '100%',
    maxWidth: '1440px',
    margin: '0 auto',
    transition: 'opacity .4s',
    opacity: 0,
    pointerEvents: 'none',
    marginTop: '-100px',
  },
  heroSectionAccessShow: {
    opacity: 1,
    pointerEvents: 'all',
  },
  stickyHeader: {
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    position: 'fixed',
    top: '24px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 5,
    width: 'calc(100% - 48px)',
    maxWidth: '1440px',
    margin: '0 auto',
    // border: `1px solid ${Color.neutral._20}`,
    animation: '$fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) forwards',
    [breakpoints.down('xs')]: {
      width: '100%',
      top: '0',
    },
  },
  msLogo: {
    paddingRight: 24,

    '& > img ': {
      width: '100%',
      maxWidth: 'clamp(100px, 180px, 200px)',
    },
  },
  heroContent: {
    position: 'relative',
    [breakpoints.down('xs')]: {
      paddingTop: 30,
    },
  },
  headings: {
    position: 'relative',
  },
  heroAnimation: {
    position: 'relative',
    paddingTop: 'clamp(40px, 12vw, 120px)',
    [breakpoints.down('xs')]: {
      paddingTop: 60,
    },
  },
  heroHeading: {
    maxWidth: '960px',
    margin: '0 auto',
    fontSize: 'clamp(1rem, 8vw, 6rem) !important',
    lineHeight: 'clamp(1rem, 8vw, 6rem)',
    textAlign: 'center',
    animation: '$headingShrink 1s 2s cubic-bezier(0.77, 0, 0.175, 1) forwards',
  },
  heroSubheading: {
    textAlign: 'center',
    '& > h2': {
      fontSize: 'clamp(1.2rem, 3vw, 2.4rem) !important',
      lineHeight: 'clamp(1.2rem, 3vw, 2.4rem)',
      opacity: 0,
      transform: 'translateY(-48px)',
      animation:
        '$subheadingAppear 1s 2s cubic-bezier(0.77, 0, 0.175, 1) forwards',
    },
    '& > h2:nth-child(2)': {
      animation:
        '$subheadingAppear 1s 2.2s cubic-bezier(0.77, 0, 0.175, 1) forwards',
    },
  },
  heroSubheadingAccess: {
    textAlign: 'center',
    '& > h2': {
      fontSize: 'clamp(1.4rem, 3vw, 2.4rem) !important',
      lineHeight: 'clamp(1.4rem, 3vw, 2.4rem)',
    },
  },
  goBtn: {
    height: '56px',
    padding: '24px',
  },
  mstMailer: {
    position: 'absolute',
    top: '60px',
    left: '50%',
    zIndex: 4,
    transform: 'translate3d(-50%,calc(-100% - 164px), 0)',
    animation: '$mstMailerDrop 1s 2s cubic-bezier(0.77, 0, 0.175, 1) forwards',
    maxWidth: '960px',
    width: '100%',
    padding: 24,
    [breakpoints.down('xs')]: {
      top: '30px',
    },

    '& > img': {
      width: '100%',

      [breakpoints.down('xs')]: {
        width: '130vw',
      },
    },
  },
  mstQbLogo: {
    width: '100%',
    maxWidth: '380px',

    [breakpoints.down('xs')]: {
      maxWidth: 300,
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes headingShrink': {
    '0%': {
      transform: 'translateY(0) scale(1)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateY(180px) scale(0)',
      opacity: 0,
    },
  },
  '@keyframes subheadingAppear': {
    '0%': {
      transform: 'translateY(-48px) scale(0)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0) scale(1)',
      opacity: 1,
    },
  },
  '@keyframes mstMailerDrop': {
    '0%': {
      transform: 'translate3d(-50%,calc(-100% - 164px), 0)',
    },
    '100%': {
      transform: 'translate3d(-50%, -40%, 0)',
    },
  },
  '@keyframes fadeDown': {
    '0%': {
      transform: 'translate3d(0, -40px, 0)',
      opacity: 0,
    },
    '100%': {
      transform: 'translate3d(0,0,0)',
      opacity: 1,
    },
  },
  '@keyframes pop': {
    '0%': {
      opacity: 0,
      transform: 'scale(0)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
  prefillInfo: {
    maxWidth: 'clamp(600px, 56vw, 800px)',

    [breakpoints.down('xs')]: {
      maxWidth: '100%',
    },

    '& h3': {
      textAlign: 'center',
    },
  },
  qbLogoInline: {
    verticalAlign: 'text-top',
    padding: '0 .4em',
  },
  irsGuidelines: {
    width: 'calc(100% - 48px)',
    maxWidth: '1440px',
    margin: '0 auto',
    borderRadius: '12px',
    padding: 24,
    backgroundColor: Color.green._90,
    position: 'relative',
  },
  irsGuidelinesInner: {
    maxWidth: 'clamp(600px, 90vw, 1024px)',
    position: 'relative',
    zIndex: 1,
  },
  mstStamp: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 0,
  },
  howItWorks: {
    width: 'calc(100% - 48px)',
    maxWidth: '1024px',
    margin: '0 auto',
    padding: 'clamp(40px, 90vw, 80px) 24px',
  },
  howItWorksIntro: {
    maxWidth: 680,
  },
  startSavingContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
  },
  startSavingSection: {
    maxWidth: 680,
    margin: '0 auto',
    textAlign: 'center',
    padding: '124px 0',
    borderTop: `1px solid ${Color.neutral._20}`,
    position: 'relative',
    zIndex: 2,

    [breakpoints.down('xs')]: {
      padding: '80px 0',
    },
  },
  coinAnimation: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    '& div': {
      position: 'absolute',
    },

    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  coinImage: {
    position: 'absolute',
    width: `100px !important`,
    height: `100px !important`,
    opacity: '0',
    transform: 'scale(0)',
    animation: '$pop 0.6s .25s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards',
    '&:nth-child(even)': {
      animation:
        '$pop 0.6s 1.25s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards',
    },
  },
  footerSection: {
    backgroundColor: Color.neutral._90,
    borderBottom: `3px solid ${Color.lime._60}`,
  },
  footerSectionInner: {
    width: 'calc(100% - 48px)',
    maxWidth: '1024px',
    margin: '0 auto',

    [breakpoints.down('xs')]: {
      margin: 0,
      width: '100%',
    },
  },
  footerLinks: {
    '& a': {
      color: Color.neutral._60,
      textDecoration: 'none',
    },
    '& a:hover': {
      color: Color.neutral._50,
    },
  },
  whereToFindModal: {
    position: 'relative',
    overflow: 'hidden',
    height: 'clamp(190px, 40vw, 260px)',
    borderBottom: `1px solid ${Color.neutral._20}`,
  },
  mstMailerModal: {
    position: 'absolute',
    bottom: 24,
    width: '100%',
    maxWidth: 640,
    left: '50%',
    transform: 'translateX(-50%)',

    '& img': {
      width: '100%',
    },
  },
  whereToFindModalContent: {
    maxWidth: 640,
    margin: '0 auto',
  },
  estimateContainer: {
    backgroundColor: Color.neutral._20,
    borderRadius: '24px',
    position: 'relative',
  },
  accessCodeContainer: {
    animation: '$fadeDown 1s cubic-bezier(0.77, 0, 0.175, 1) forwards',
  },
  accessCodeAnimation: {
    border: `1px solid ${Color.neutral._20}`,
    backgroundColor: Color.neutral.white,
    maxWidth: '1024px',
    width: '100%',
    margin: '0 auto !important',
    padding: '132px 0 40px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },

  codeEntered: {
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      padding: '24px 0',
      justifyContent: 'flex-start',
      gridTemplateColumns: 'minmax(0, 1fr)',
    },
  },
  signUpContainer: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '320px',
  },
  textField: {
    width: '100%',
    background: Color.neutral.white,
  },

  inputRoot: {
    '& input': {
      width: 'calc(100% - 28px)',
    },
  },
  errorMsg: {
    margin: '5px 0 0',
    fontSize: '12px',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: '20px',
      marginBottom: '-25px',
    },
  },
  linkText: {
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:visited': {
      color: palette.secondary.main,
    },
    '&:link': {
      color: palette.secondary.main,
    },
  },
  loadingContainer: {
    marginBottom: '60px',
  },
  agreementContainer: {
    fontSize: '13px',
    marginTop: '30px',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  formErrorMsg: {
    marginTop: '15px',
    textAlign: 'center',
  },
  noCodeButton: {
    '& > a': {
      padding: 0,
    },
  },
  confetti: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',

    '& > canvas': {
      width: 'clamp(400px, 70vw, 640px)',
    },
  },
  btnHover: {
    '& p': {
      transition: 'color .4s',
    },
  },
  btnHoverGrey: {
    '&:hover p': {
      color: `${Color.neutral._80} !important`,
    },
  },
  btnHoverBlue: {
    '&:hover p': {
      color: `${Color.blue._50} !important`,
    },
  },
  serviceAgreement: {
    borderTop: `1px solid ${Color.neutral._80}`,
    maxWidth: '1024px',
    margin: '0 auto',
  },
  expandableField: {
    width: '100%',
    maxWidth: '320px',
  },
  partnerLogo: {
    width: '100%',
    maxHeight: '46px',
    padding: '0 8px 0 0',
    [breakpoints.down('xs')]: {
      maxWidth: '50vw',
    },
  },
  partnerLogoLockup: {
    width: '100%',
    [breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  mstLogo: {
    width: '95%',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  centerTextMobile: {
    [breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}));

interface RedAntlerQuickBooksLandingPageProps {
  queryParams: URLSearchParams;
  code: string;
  partner: string;
}

const getUtmSourcingData = (urlParams: URLSearchParams) => {
  const sourcingData: SourcingData = {
    utmCampaign: '',
    utmMedium: '',
    utmSource: '',
    utmContent: '',
    utmTerm: '',
  };

  const urlKeyMapping: SourcingData = {
    utmCampaign: 'utm_campaign',
    utmMedium: 'utm_medium',
    utmSource: 'utm_source',
    utmContent: 'utm_content',
    utmTerm: 'utm_term',
  };

  Object.keys(sourcingData).forEach((key) => {
    if (key in urlKeyMapping) {
      const urlParamKey = urlKeyMapping[key as keyof SourcingData];
      if (urlParamKey) {
        const urlParamValue = urlParams.get(urlParamKey);
        if (urlParamValue) {
          sourcingData[key as keyof SourcingData] = urlParamValue;
        }
      }
    }
  });
  return sourcingData;
};

export const RedAntlerQuickBooksLandingPage: React.FC<RedAntlerQuickBooksLandingPageProps> =
  observer(({ queryParams, code, partner }) => {
    const classes = useStyles();
    const { app, auth } = useCommonStores();
    const featureFlag = useFeatureFlags();
    const { initialAccountSetup } = useOnboardingStores();
    const [showNoCodeModal, setShowNoCodeModal] = useState<boolean>(false);
    const [showWhereToFindModal, setShowWhereToFindModal] =
      useState<boolean>(false);
    const [fadeAccessEntry, setFadeAccessEntry] = useState<boolean>(false);

    const handleOnCodeSubmit = async (code: string) => {
      queryParams.set('code', code);
      await initialAccountSetup.getAccessCodeEstimate(
        queryParams,
        'quickbooks',
      );
      if (!initialAccountSetup.codeNotFound) {
        setFadeAccessEntry(true);
      }
    };

    const submitOnEnter =
      (runSubmit: () => void) => (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          runSubmit();
        }
      };

    // access code page
    const isMounted = useIsMounted();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [showPasswordHelpers, setShowPasswordHelpers] = useState(false);
    const [formError, setFormError] = useState('');
    const [showGetStarted, setShowGetStarted] = useState<boolean>(false);

    const { company } = useCompany();
    const { client } = useContext(Auth0FeatureContext);

    const location = useLocation();
    const { search } = location;
    const urlParams = new URLSearchParams(search);

    const sourcingData = getUtmSourcingData(urlParams);
    const pathComponents = location.pathname.toLowerCase().split('/');
    const { origin } = window.location;
    const redirectUri = origin;

    interface FormInputs {
      email: string;
      password: string;
      agreeToTerms: boolean;
    }

    const initialValues: FormInputs = {
      email: '',
      password: '',
      agreeToTerms: true,
    };

    const { validatePassword, isValidPassword, passwordValidation } =
      usePasswordValidation();

    const getPartnerReferral = () => {
      if (
        pathComponents.length > 2 &&
        pathComponents[1] === 'welcome' &&
        ALL_PAYROLL_SYSTEMS.find((p) => p.id === pathComponents[2])
      )
        return pathComponents[2];

      return '';
    };

    const submit = async (formValues: FormInputs) => {
      if (isMounted()) {
        setFormError('');
        initialAccountSetup.setFormLoading(true);
      }

      const res = await client.CreateCompanyAndLoginSelfSignup({
        primaryUserEmail: formValues.email,
        primaryUserFirstName: undefined,
        primaryUserLastName: undefined,
        adminPassword: formValues.password,
        partnerReferral: getPartnerReferral(),
        referrer: '',
        creditCategories: [] as CreditCategoryEnum[],
        sourcing: sourcingData,
        partnerAccessCode: code,
      } as CreateCompanySelfSignupRequest);

      if (res.errorMsg) {
        datadogLogs.logger.error(
          `[WELCOME]: company creation on registration failed. partner: ${partner}`,
          logContext({
            error: res.errorMsg,
            company: app.company,
          }),
        );

        setFormError(res.errorMsg);
        initialAccountSetup.setFormLoading(false);
        return;
      }

      await auth.manualLogin(
        formValues.email,
        formValues.password,
        redirectUri,
      );
      if (res.data?.company) {
        company.mergeData(res.data.company);
      }
    };

    const handleGetStartedExpanded = (bool: boolean) => {
      datadogLogs.logger.info(`
        Partner: Quickbooks - clicked "Get started" button!
      `);

      setShowGetStarted(bool);
    };

    const showGetStartedAsExpandable =
      featureFlag.showPartnerGetStartedAsExpandable;

    const renderEmailPasswordField = (isExpandable?: boolean) => {
      return (
        <Flex gap={32} direction='column'>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
              submit(values);
            }}
            validationSchema={yup.object().shape({
              email: yup
                .string()
                .email('Please enter a valid email')
                .required('Please include your work email'),
              password: yup.string().required('Please enter a password'),
              agreeToTerms: yup
                .bool()
                .oneOf([true], 'You must accept the terms of service'),
            })}
            // eslint-disable-next-line react/no-children-prop
            children={({ values, errors, handleSubmit, setFieldValue }) => (
              <Flex
                direction='column'
                alignItems='flex-start'
                className={classes.signUpContainer}
                padding={[16, 0, 0, 0]}
                gap={16}
              >
                <TextField
                  variant='outlined'
                  className={classes.textField}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                  }}
                  data-testid='welcome_email'
                  label='Enter company email'
                  value={values.email}
                  onChange={(event) => {
                    setFieldValue('email', event.target.value);
                  }}
                  onKeyDown={submitOnEnter(handleSubmit)}
                />
                {errors.email && (
                  <FormHelperText className={classes.errorMsg} error>
                    {errors.email}
                  </FormHelperText>
                )}
                <TextField
                  variant='outlined'
                  className={classes.textField}
                  type={showPassword ? 'text' : 'password'}
                  data-testid='welcome_password'
                  label='Create a password'
                  value={values.password}
                  error={!isValidPassword && showPasswordError}
                  onFocus={() => {
                    setShowPasswordHelpers(true);
                  }}
                  onBlur={(event) => {
                    validatePassword(event.target.value, true);
                    setShowPasswordError(true);
                    setShowPasswordHelpers(values.password.length > 0);
                  }}
                  onChange={(event) => {
                    setShowPasswordHelpers(true);
                    setShowPasswordError(false);
                    setFieldValue('password', event.target.value);
                    validatePassword(event.target.value);
                  }}
                  onKeyDown={submitOnEnter(handleSubmit)}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Link
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          inheritColor={true}
                        >
                          <FontAwesome
                            name={showPassword ? 'eye-slash' : 'eye'}
                            variant='solid'
                            size={20}
                            color={
                              !isValidPassword && showPasswordError
                                ? Color.semantic.$error50
                                : Color.neutral._60
                            }
                          />
                        </Link>
                      </InputAdornment>
                    ),
                  }}
                />
                {showPasswordHelpers && (
                  <PasswordChecklist passwordValidation={passwordValidation} />
                )}
                {errors.password && (
                  <FormHelperText className={classes.errorMsg} error>
                    {errors.password}
                  </FormHelperText>
                )}
                {formError && (
                  <FormHelperText className={classes.formErrorMsg} error>
                    {formError}
                  </FormHelperText>
                )}
                <Flex gap={24} justifyContent='center' alignItems='center'>
                  <Button
                    label='Create Estimate'
                    onClick={() => {
                      handleSubmit();
                    }}
                    flexAlignSelf='flex-start'
                    disabled={!isValidPassword || !values.email}
                  />
                </Flex>
              </Flex>
            )}
          />
        </Flex>
      );
    };

    useEffect(() => {
      document.title = 'MainStreet | QuickBooks Tax Credits';
      if (code.length > 0 && !initialAccountSetup.codeNotFound) {
        setFadeAccessEntry(true);
      } else {
        setFadeAccessEntry(false);
      }
    }, [code, initialAccountSetup]);

    useEffect(() => {
      if (fadeAccessEntry) {
        const confettiCanvas = document.createElement('canvas');
        const confettiPosition = document.getElementById('confetti');

        if (confettiPosition) {
          const hasCanvasChild = Array.from(confettiPosition.children).some(
            function (child) {
              return child.nodeName === 'CANVAS';
            },
          );

          // only create if canvas doesn't exist
          if (!hasCanvasChild) {
            confettiPosition.appendChild(confettiCanvas);
          }
        }

        const myConfetti = confetti.create(confettiCanvas, {
          useWorker: true,
        });
        const confettiTiming = setTimeout(() => {
          myConfetti({
            particleCount: 100,
            spread: 180,
            startVelocity: 10,
            scalar: 0.5,
            gravity: 0.5,
            origin: { y: 0.5, x: 0.5 },
          });
        }, 1200);

        // scroll page back up after code entered
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        return () => {
          clearTimeout(confettiTiming);
        };
      }
    }, [fadeAccessEntry]);

    useEffectOnce(async () => {
      if (code.length > 0) {
        await initialAccountSetup.getAccessCodeEstimate(queryParams, partner);
        if (initialAccountSetup.codeNotFound) {
          // redirect back to partner page to enter correct code
          app.history.push(`/${Page.selfSignupWelcome}/${partner}`);
          // set textfield with code not found
          initialAccountSetup.enterCode = code;
        }
      }
    });

    return (
      <Flex direction='column'>
        <div
          className={classes.heroSectionContainer}
          data-testid={'welcome-to-mainstreet'}
        >
          <AnimatedGradient position='absolute' />
          <Flex
            className={classes.stickyHeader}
            alignItems='center'
            justifyContent='space-between'
            padding={[16, 24]}
          >
            <Flex.Cell className={classes.msLogo}>
              <img
                src={`${process.env.PUBLIC_URL}/images/logo/mst-logo-dark.svg`}
              />
            </Flex.Cell>
            <Flex gap={24} justifyContent='flex-end'>
              {featureFlag.showTalkToAnExpertButton && (
                <TalkToAnExpertButton buttonOutlined />
              )}
              {!app.isMobile && (
                <Button label='Log in' onClick={() => app.history.push('/')} />
              )}
            </Flex>
          </Flex>
          <Flex
            direction='column'
            className={classes.rootContent}
            padding={app.isMobile ? [80, 0, 40, 0] : [80, 0, 80, 0]}
          >
            {code.length > 0 ? (
              <Flex
                direction='column'
                className={`${classes.heroSectionAccess} ${
                  fadeAccessEntry && classes.heroSectionAccessShow
                }`}
                justifyContent='center'
                gap={40}
              >
                <Flex className={classes.accessCodeContainer}>
                  <Card
                    noBorder
                    noMargin
                    className={classes.accessCodeAnimation}
                  >
                    <Flex
                      justifyContent='center'
                      alignItems='center'
                      direction='column'
                      gap={12}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/logo/partners/quickbooks-x-mainstreet.svg`}
                        width={app.isMobile ? 280 : 380}
                      />
                      <Text variant='medium' size={app.isTablet ? 13 : 15}>
                        Your Tax Credit Estimate:
                      </Text>
                      <Flex justifyContent='center'>
                        <Flex.Cell
                          className={classes.estimateContainer}
                          padding={[12, 40]}
                        >
                          <div id='confetti' className={classes.confetti} />
                          {initialAccountSetup.loadingEstimate ? (
                            <Flex
                              padding={[16, 0]}
                              gap={16}
                              alignItems='center'
                              direction='column'
                            >
                              <Spinner color='emerald' />
                            </Flex>
                          ) : (
                            <Heading
                              color={Color.green._70}
                              variant='medium'
                              tag={app.isSmallDesktop ? 'h3' : 'h1'}
                              marginBottom={app.isTablet ? 8 : 12}
                            >
                              {CentsToDisplayString(
                                initialAccountSetup.customEstimate,
                                0,
                                0,
                              )}
                              *
                            </Heading>
                          )}
                        </Flex.Cell>
                      </Flex>
                      <Text
                        variant='medium'
                        size={app.isTablet ? 15 : 18}
                        color={Color.neutral._60}
                      >
                        Finalize your estimate in ten minutes.
                      </Text>
                    </Flex>
                  </Card>
                </Flex>
                <Flex justifyContent='center'>
                  {initialAccountSetup.formLoading ? (
                    <Animate enter='fade-in'>
                      <Flex
                        padding={[16, 0]}
                        gap={16}
                        alignItems='center'
                        direction='column'
                      >
                        <Heading
                          tag={app.isSmallDesktop ? 'h5' : 'h4'}
                          variant='medium'
                          marginBottom={0}
                        >
                          Get ready to claim your credits!
                        </Heading>
                        <Spinner color='emerald' />
                      </Flex>
                    </Animate>
                  ) : (
                    <Animate enter={'fade-in'} delay={0.5}>
                      <Flex
                        direction='column'
                        gap={16}
                        className={classes.heroSubheadingAccess}
                      >
                        <Heading
                          tag='h2'
                          marginBottom={0}
                          color={Color.green._80}
                        >
                          Your tax credits are waiting.
                        </Heading>
                        <Heading
                          tag='h2'
                          color={Color.green._80}
                          marginBottom={0}
                        >
                          Start your claim today.
                        </Heading>

                        {showGetStartedAsExpandable ? (
                          <Flex direction='column' gap={24} alignItems='center'>
                            <Expandable
                              expand={showGetStarted}
                              className={classes.expandableField}
                            >
                              {renderEmailPasswordField(
                                showGetStartedAsExpandable,
                              )}
                            </Expandable>
                            <Expandable expand={!showGetStarted}>
                              <Button
                                dataTestId={`${partner}-partner-get-started-btn`}
                                label={
                                  <Text
                                    size={18}
                                    color={Color.neutral.white}
                                    variant='medium'
                                  >
                                    Create Estimate
                                  </Text>
                                }
                                onClick={() => handleGetStartedExpanded(true)}
                              />
                            </Expandable>
                          </Flex>
                        ) : (
                          renderEmailPasswordField()
                        )}
                      </Flex>
                    </Animate>
                  )}
                </Flex>
              </Flex>
            ) : (
              <Flex
                direction='column'
                className={`${classes.heroSection} ${
                  fadeAccessEntry && classes.heroSectionHide
                }`}
                justifyContent='center'
                gap={40}
              >
                <Flex direction='column' className={classes.heroAnimation}>
                  <div className={classes.mstMailer}>
                    <Image
                      src={`${process.env.PUBLIC_URL}/images/logo/partners/mst-mailer.png`}
                    />
                  </div>

                  <Flex
                    className={classes.heroContent}
                    direction='column'
                    alignItems='center'
                    gap={24}
                  >
                    <Flex.Cell>
                      <Animate
                        enter={'fade-in'}
                        exit={['fade-out', 'to-top']}
                        delay={0.4}
                        duration={0.6}
                      >
                        <Image
                          src={`${process.env.PUBLIC_URL}/images/logo/partners/quickbooks-x-mainstreet.svg`}
                          className={classes.mstQbLogo}
                        />
                      </Animate>
                    </Flex.Cell>
                    <div className={classes.headings}>
                      <Animate enter={['fade-in', 'from-top']}>
                        <Heading className={classes.heroHeading}>
                          Your tax credits are&nbsp;waiting.
                        </Heading>
                      </Animate>
                      <Flex
                        direction='column'
                        gap={16}
                        className={classes.heroSubheading}
                      >
                        <Heading tag='h2' marginBottom={0}>
                          Your tax credits are waiting.
                        </Heading>
                        <Heading tag='h2'>
                          Enter your code to start saving&nbsp;today.
                        </Heading>
                      </Flex>
                    </div>
                  </Flex>
                </Flex>
                <Animate enter={'fade-in'}>
                  <Flex direction='column' gap={24} alignItems='center'>
                    <Flex gap={24} justifyContent='center'>
                      <Flex.Cell basis={250}>
                        <TextFieldCL
                          value={initialAccountSetup.enterCode}
                          name='tax-credit-code'
                          data-testid='tax-credit-code-field'
                          placeholder='Enter access code'
                          onChange={(str) => {
                            initialAccountSetup.setEnterCode(str.target.value);
                            if (initialAccountSetup.codeNotFound) {
                              initialAccountSetup.codeNotFound = false;
                            }
                          }}
                          error={initialAccountSetup.codeNotFound}
                          helperText={
                            initialAccountSetup.codeNotFound && (
                              <Flex padding={[8, 0, 0, 0]}>
                                <Text color={Color.semantic.$error50}>
                                  We&apos;re sorry, this code is invalid.
                                  Please&nbsp;try&nbsp;again.
                                </Text>
                              </Flex>
                            )
                          }
                          onKeyDown={submitOnEnter(async () => {
                            initialAccountSetup.setTaxCreditCode(
                              initialAccountSetup.enterCode,
                            );

                            await handleOnCodeSubmit(
                              initialAccountSetup.enterCode,
                            );
                          })}
                        />
                      </Flex.Cell>
                      <Button
                        className={classes.goBtn}
                        label='Go'
                        onClick={async () => {
                          initialAccountSetup.setTaxCreditCode(
                            initialAccountSetup.enterCode,
                          );

                          await handleOnCodeSubmit(
                            initialAccountSetup.enterCode,
                          );
                        }}
                      />
                    </Flex>
                    <Flex gap={24} justifyContent='center'>
                      <Button
                        onClick={() => setShowWhereToFindModal(true)}
                        label='Where to find code?'
                        variant='tertiary'
                      />
                      <Button
                        className={`${classes.btnHover} ${classes.btnHoverGrey}`}
                        onClick={() => setShowNoCodeModal(true)}
                        label={
                          <Text color={Color.neutral._60} variant='medium'>
                            I don&apos;t have a code
                          </Text>
                        }
                        variant='tertiary'
                      />
                    </Flex>
                  </Flex>
                </Animate>
              </Flex>
            )}
          </Flex>
        </div>
        <CreditsSection />
        <PartnershipSection
          qbPartnershipInfo={
            <>
              <Flex
                alignItems='center'
                className={classes.partnerLogoLockup}
                padding={app.isMobile ? [24, 0, 24, 0] : [0, 0, 32, 0]}
                gap={8}
                justifyContent={'center'}
              >
                <Flex.Cell>
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/logo/partners/intuit-qb-logo.svg`}
                    className={classes.partnerLogo}
                  />
                </Flex.Cell>
                <Flex.Cell>
                  <Image
                    className={classes.mstLogo}
                    src={`${process.env.PUBLIC_URL}/images/logo/mainstreet-x-logo.svg`}
                  />
                </Flex.Cell>
              </Flex>
              <Flex.Cell className={classes.centerTextMobile}>
                <Heading color={Color.green._70} size={app.isTablet ? 15 : 18}>
                  PARTNERSHIP
                </Heading>
                <Heading
                  tag={app.isMobile ? 'h4' : 'h3'}
                  size={app.isMobile ? 32 : undefined}
                >
                  <Heading tag='span'>We partnered with</Heading>
                  <Heading
                    tag='span'
                    color={Color.semantic.$success50}
                    text={` Intuit Quickbooks `}
                  />
                  <Heading tag='span'>
                    to&nbsp;pre-fill your business data, so you have everything
                    you need to get&nbsp;started.
                  </Heading>
                </Heading>
              </Flex.Cell>
            </>
          }
        />

        <IRSGuidelinesSection />

        <HowItWorksSection
          qbContent={
            <Text>
              We&apos;ve already pre-filled a personalized initial estimate with
              <img
                src={`${process.env.PUBLIC_URL}/images/logo/partners/intuit-qb-logo.svg`}
                width={app.isMobile ? 80 : 100}
                className={classes.qbLogoInline}
              />
              . Just connect your payroll data and confirm a few&nbsp;questions.
            </Text>
          }
        />

        <StartSavingSection />

        <FooterSection
          qbServiceAgreement={
            <Flex padding={[12, 0]} className={classes.serviceAgreement}>
              <Text size={13} color={Color.neutral._60}>
                * Tax credit amounts and qualification determinations shared on
                this page are estimates based on data you have provided and are
                subject to the MainStreet Services Agreement and completion of
                the MainStreet Qualification and Year-End Assessment Process.
                Tax credit amounts are not guaranteed and may vary based on the
                type of tax credit. Terms, conditions, features, support,
                pricing, and service options subject to change
                without&nbsp;notice.
              </Text>
            </Flex>
          }
        />
        {/* No code modal */}
        <Modal
          showModal={showNoCodeModal}
          closeToggle={() => setShowNoCodeModal(false)}
          maxWidth={620}
        >
          <Flex padding={[40, 80, 40, 40]} gap={24} direction='column'>
            <Heading size={app.isTablet ? 23 : 32} marginBottom={0}>
              MainStreet has helped thousands of businesses like yours maximize
              their tax&nbsp;credits.
            </Heading>
            <Text size={18} paddingBottom={24}>
              Schedule a call with an expert or get started here if you
              don&apos;t have an access code to learn how MainStreet can
              help&nbsp;you.
            </Text>
            <Flex gap={32} alignItems='center' className={classes.noCodeButton}>
              {featureFlag.showTalkToAnExpertButton && (
                <TalkToAnExpertButton buttonOutlined />
              )}
              <Button
                className={`${classes.btnHover} ${classes.btnHoverBlue}`}
                label={
                  <Text color={Color.blue._70}>
                    Get started {app.isMobile ? '' : 'without access code'}
                  </Text>
                }
                onClick={{
                  location: `/${Page.selfSignupWelcome}/quickbooks/registration`,
                  external: true,
                }}
                variant='tertiary'
              />
            </Flex>
          </Flex>
        </Modal>
        {/* Where to find code modal */}
        <Modal
          showModal={showWhereToFindModal}
          closeToggle={() => setShowWhereToFindModal(false)}
          maxWidth={800}
        >
          <>
            <Flex className={classes.whereToFindModal}>
              <AnimatedGradient position='absolute' version='v2' />
              <div className={classes.mstMailerModal}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/logo/partners/mst-mailer.png`}
                />
              </div>
            </Flex>
            <Flex direction='column' padding={[0, 24]}>
              <Flex
                padding={[40, 0]}
                gap={16}
                direction='column'
                alignItems='center'
                justifyContent='center'
                className={classes.whereToFindModalContent}
              >
                <Heading size={app.isTablet ? 23 : 32} marginBottom={0}>
                  Your code is at the bottom of the letter you received in
                  the&nbsp;mail.
                </Heading>
                <Text size={18} paddingBottom={24}>
                  Our partnership with QuickBooks makes it easy to claim your
                  credits. Just enter your access code to get&nbsp;started.
                </Text>
                <Animate enter={'fade-in'}>
                  <Flex direction='column' gap={24} alignItems='center'>
                    <Flex gap={24} justifyContent='center'>
                      <Flex.Cell basis={320}>
                        <TextFieldCL
                          value={initialAccountSetup.enterCode}
                          name='tax-credit-code'
                          data-testid='tax-credit-code-field'
                          placeholder='Enter access code'
                          onChange={(str) => {
                            initialAccountSetup.setEnterCode(str.target.value);
                            if (initialAccountSetup.codeNotFound) {
                              initialAccountSetup.codeNotFound = false;
                            }
                          }}
                          error={initialAccountSetup.codeNotFound}
                          helperText={
                            initialAccountSetup.codeNotFound && (
                              <Flex padding={[8, 0, 0, 0]}>
                                <Text color={Color.semantic.$error50}>
                                  We&apos;re sorry, this code is invalid.
                                  Please&nbsp;try&nbsp;again.
                                </Text>
                              </Flex>
                            )
                          }
                          onKeyDown={submitOnEnter(async () => {
                            initialAccountSetup.setTaxCreditCode(
                              initialAccountSetup.enterCode,
                            );

                            await handleOnCodeSubmit(
                              initialAccountSetup.enterCode,
                            );
                          })}
                        />
                      </Flex.Cell>
                      <Button
                        className={classes.goBtn}
                        label='Go'
                        onClick={async () => {
                          initialAccountSetup.setTaxCreditCode(
                            initialAccountSetup.enterCode,
                          );

                          await handleOnCodeSubmit(
                            initialAccountSetup.enterCode,
                          );

                          setShowWhereToFindModal(false);
                        }}
                      />
                    </Flex>
                  </Flex>
                </Animate>
              </Flex>
            </Flex>
          </>
        </Modal>
      </Flex>
    );
  });
