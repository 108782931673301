import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Animate,
  Button,
  Card,
  Color,
  Divider,
  Flex,
  Grid,
  Heading,
  Spinner,
  Text,
} from 'component-library';
import { useCompany, useTaxCreditsStores } from 'stores/useStores';
import { useEffectOnce } from 'component-library/_helpers/use-utils';
import Rive from 'rive-react';
import { CentsToDisplayString } from 'lib/helpers';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    height: '100%',
    gridTemplateColumns: `45% 55%`,
    background: `url(${process.env.PUBLIC_URL}/images/dashboard/get-started/get-started-bg-gradient.png)`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: '100% 100%',
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
    opacity: 1,
    transition: 'opacity .4s',
  },
  animateContainer: {
    width: '100%',
  },
  container: {
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',

    '& span': {
      lineHeight: '1.4em !important',
    },
  },
  loading: {
    opacity: 1,
    transition: 'opacity .4s',
  },
  fadeOutContainer: {
    opacity: 0,
  },
  riveContainer: {
    '& canvas': {
      height: '300px !important',
      marginLeft: '25px',
    },
  },
}));

interface ERCCreditEstimateProps {
  pathTo: string;
  onNext: () => void;
  isPrefilled: boolean;
}

export const ERCCreditEstimate = observer(
  ({ pathTo, onNext, isPrefilled }: ERCCreditEstimateProps) => {
    const [onload, setOnload] = useState<boolean>(true);
    const [fadeOut, setFadeOut] = useState<boolean>(false);
    const classes = useStyles();
    const history = useHistory();
    const { company } = useCompany();
    const { erc } = useTaxCreditsStores();

    const handleOnContinue = () => {
      // fade out page on exit
      setFadeOut(true);

      setTimeout(() => {
        onNext();
        history.push(pathTo);
      }, 400);
    };

    useEffectOnce(async () => {
      await erc.getERCEstimate().then(() => {
        // set fade animation
        setTimeout(() => {
          setFadeOut(true);
        }, 1600);
        setTimeout(() => {
          setOnload(false);
          setFadeOut(false);
        }, 2000);
      });
    });

    return (
      <>
        {onload ? (
          <Flex
            padding={24}
            justifyContent='center'
            alignItems='center'
            direction='column'
            className={`${classes.loading} ${
              fadeOut && classes.fadeOutContainer
            }`}
          >
            <Card
              noBorder
              noBoxShadow
              noMargin
              maxWidth={540}
              className={classes.riveContainer}
            >
              <Rive
                src={`${process.env.PUBLIC_URL}/images/rive/searching-for-credits.riv`}
              />
            </Card>
            <Text
              text='Estimating credits...'
              variant='medium'
              textAlign='center'
              size={23}
            />
          </Flex>
        ) : (
          <Animate
            enter={'fade-in'}
            delay={1}
            className={classes.animateContainer}
          >
            <Flex
              className={`${classes.root} ${
                fadeOut && classes.fadeOutContainer
              }`}
              alignItems='center'
            >
              <Grid className={classes.container}>
                <Grid.Cell padding={[0, 24, 0, 24]} alignSelf='center'>
                  <Heading variant='medium' tag='span' size={40}>
                    {company.name}{' '}
                  </Heading>
                  <Heading tag='span' size={40}>
                    is eligible for{' '}
                  </Heading>
                  <Heading tag='span' size={40}>
                    Employee Retention Credits
                  </Heading>
                </Grid.Cell>
                <Grid.Cell>
                  <Card noBoxShadow noMargin>
                    <Flex
                      direction='column'
                      padding={[4, 24, 24, 24]}
                      justifyContent='center'
                      alignItems='center'
                      gap={16}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/coins.svg`}
                        alt='Form 941 Icon'
                        width={150}
                      />
                      <Flex
                        direction='column'
                        gap={8}
                        alignItems='center'
                        padding={[0, 0, 24, 0]}
                      >
                        <Text variant='medium' size={18}>
                          {company.name} is eligible for up to
                        </Text>
                        {erc.creditEstimate ? (
                          <Heading tag='h2' variant='medium' marginBottom={0}>
                            {erc.creditEstimate &&
                              CentsToDisplayString(
                                erc.creditEstimate.estimateAmount,
                              )}
                            *
                          </Heading>
                        ) : (
                          <Flex justifyContent='center' padding={16}>
                            <Spinner />
                          </Flex>
                        )}
                        {/* <Text>across X quarters of 2021</Text> */}
                      </Flex>
                      <Divider variant='middle' />
                      <Flex
                        direction='column'
                        padding={[0, 0, 24, 0]}
                        gap={4}
                        alignItems='center'
                      >
                        <Text size={18}>Employee Retention Credits</Text>
                        {erc.creditEstimate ? (
                          <Heading tag='h4' variant='medium' marginBottom={0}>
                            {erc.creditEstimate &&
                              CentsToDisplayString(
                                erc.creditEstimate.estimateAmount,
                              )}
                            *
                          </Heading>
                        ) : (
                          <Flex justifyContent='center' padding={16}>
                            <Spinner />
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                    <Flex direction='column' gap={24}>
                      <Alert
                        backgroundColor={Color.blue._10}
                        text={
                          <Flex direction='column'>
                            <Text size={15}>
                              This credit estimate is non-binding. Your final
                              credit may differ based on your company&apos;s
                              unique facts and circumstances.
                            </Text>
                          </Flex>
                        }
                        type='info'
                        variant='in_card'
                      />
                      <Flex padding={[0, 24, 24, 24]} justifyContent='flex-end'>
                        <Button label='Continue' onClick={handleOnContinue} />
                      </Flex>
                    </Flex>
                  </Card>
                </Grid.Cell>
              </Grid>
            </Flex>
          </Animate>
        )}
      </>
    );
  },
);
