import { addMonths } from 'date-fns';
import { CompanyData } from '../../../lib/interfaces';
import { datadogLogs } from '@datadog/browser-logs';

const validMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Converts company's fiscalYearEndMonth to taxFilingDeadline
 * @param company
 * @param year
 */
export const companyFyeToTaxDeadline = (company: CompanyData, year = 2021) => {
  // default to December
  let fiscalYearEndMonth = 'December';
  if (
    company.fiscalYearEndDate &&
    validMonthNames.includes(company.fiscalYearEndDate)
  ) {
    fiscalYearEndMonth = company.fiscalYearEndDate;
  } else {
    datadogLogs.logger.info(
      `Using December default for companyId ${company.id}'s fiscalYearEndMonth. (company.fiscalYearEndMonth: ${company.fiscalYearEndDate})`,
    );
  }

  // convert month string to date
  const fiscalEndDate = new Date(
    Date.parse(`${fiscalYearEndMonth} 15, ${year}`),
  );

  // if any month besides December, increment year
  if (fiscalYearEndMonth !== 'December') {
    fiscalEndDate.setFullYear(fiscalEndDate.getFullYear() + 1);
  }

  // if tax type is 1120, personal, or 990t then deadline is 4 months after fiscal year-end
  // else, keep the 3-month deadline
  let deadlineMonths = 3;

  if (
    company.taxType &&
    ['1120', 'personal', '990t'].includes(company.taxType)
  ) {
    deadlineMonths = 4;
  }

  return addMonths(fiscalEndDate, deadlineMonths);
};
