import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Route, Switch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Animate, Button, Color, Flex, Text } from 'component-library';
import {
  useCommonStores,
  useFeatureFlags,
  useTaxCreditsStores,
} from 'stores/useStores';
import { animated } from 'react-spring';
import { useSpringPageLocationTransition } from '../../../../../lib/useSpringPageTransition';
import TitleHeader from '../../../../../components/TitleHeader';
import useAssessmentSurveyRoutes from '../components/useAssessmentSurveyRoutes';
import { AssessmentSurveyStepsIndex, Page } from '../../../../../lib/constants';
import HttpErrorPage from '../../../../../pages/HttpErrorPage';
import { CentsToDisplayString } from '../../../../../lib/helpers';
import { AllSectionsSideDrawer } from '../components';
import { QualificationQuestions } from '../../../../../lib/interfaces';
import { ShareAssessmentModal } from '../shareAssessment/ShareAssessmentModal';
import { InviteeIntroModal } from '../shareAssessment/inviteeIntroModal';
import { InviteeSuccessModal } from '../shareAssessment/inviteeSuccessModal';

const useStyles = makeStyles(({ boxShadowRegular }) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  mainContent: {
    maxWidth: '1440px',
    margin: '0 auto',
    position: 'relative',
  },
  fullMainContent: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  animateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    gap: '24px',
  },

  sticky: {
    maxWidth: '1440px',
    width: '100%',
    margin: '24px auto 0',
    position: 'sticky',
    top: '0',
    backgroundColor: Color.neutral._20,
    borderRadius: '4px',
    zIndex: 3,
    boxShadow: boxShadowRegular,
  },
  viewAllBtn: {
    padding: '0 0 0 16px',
    position: 'relative',
    borderLeft: `1px solid ${Color.neutral._40}`,
    height: 'auto',
  },
}));

interface AssessmentSurveyProps {
  taxYear: number;
}

export type PreFilledAnswers = {
  healthcare: QualificationQuestions;
  retirement: QualificationQuestions;
  disabledAccess: QualificationQuestions;
};

export const AssessmentSurvey = observer(
  ({ taxYear }: AssessmentSurveyProps) => {
    const classes = useStyles();
    const location = useLocation();
    const { companyStore } = useCommonStores();
    const { unifiedTaxCredits } = useTaxCreditsStores();
    const { AssessmentSurveyRoutes } = useAssessmentSurveyRoutes(taxYear);
    const featureFlags = useFeatureFlags();

    const showTalkToExpertButton = featureFlags.yeaShowAssessmentTalkToExpert;

    const { transitions } = useSpringPageLocationTransition(
      location,
      unifiedTaxCredits.flowCurrentIndex,
      unifiedTaxCredits.flowPrevIndex,
    );

    useEffect(() => {
      unifiedTaxCredits.setCurrentStepOnLoad(location.pathname, taxYear);
      unifiedTaxCredits.getTotalCreditSofar(taxYear);
      // get the latest assessment statuses
      const updateAssessmentsStatus = async () =>
        await unifiedTaxCredits.getTaxCreditAssessments(
          taxYear,
          companyStore.accessToken,
        );

      updateAssessmentsStatus();
    }, [
      unifiedTaxCredits,
      location.pathname,
      taxYear,
      companyStore.accessToken,
    ]);

    return (
      <Flex
        direction='column'
        className={classes.root}
        padding={[0, 24]}
        data-testid={`${taxYear}-tax-credit-assessment`}
      >
        <Animate enter={['fade-in', 'from-top']}>
          <TitleHeader
            title={`${taxYear} Tax Credit Assessment`}
            showExitButton={!companyStore.accessToken}
            borderBottom
            onCancelExitText='Cancel'
            showCompanyName
            onExitLocation={`/${Page.taxCredits}`}
            onExitCustomUpdate={() => {
              unifiedTaxCredits.setLoadingAssessments(true);
              // clear to re-fetch latest
              unifiedTaxCredits.assessmentByYear = {};
            }}
            onExitText='Only the answers up to the last completed section will be saved.'
            fullWidth
            showTalkToExpertButton={showTalkToExpertButton}
          />
        </Animate>
        <Animate
          className={classes.sticky}
          enter={['fade-in', 'from-top']}
          delay={1}
        >
          {!companyStore.accessToken && (
            <Flex
              padding={[16, 24]}
              alignItems='center'
              justifyContent='space-between'
            >
              <Flex>
                <Text variant='medium' size={18}>
                  Total estimated credit so far:{' '}
                  <Text
                    tag='span'
                    color={Color.green._60}
                    variant='medium'
                    size={18}
                  >
                    {CentsToDisplayString(
                      unifiedTaxCredits.totalCreditSoFar,
                      0,
                      0,
                    )}
                  </Text>
                </Text>
              </Flex>
              <Flex justifyContent='flex-end' alignItems='center' gap={16}>
                <Text variant='medium'>
                  Section {unifiedTaxCredits.flowCurrentIndex}{' '}
                  <Text tag='span'>
                    of {AssessmentSurveyStepsIndex.DISABLED_ACCESS}
                  </Text>
                </Text>
                <Button
                  className={classes.viewAllBtn}
                  variant='tertiary'
                  label='View all sections'
                  onClick={() =>
                    unifiedTaxCredits.setShowAllSectionsDrawer(true)
                  }
                />
              </Flex>
            </Flex>
          )}
        </Animate>
        <div className={classes.fullMainContent}>
          <Flex className={classes.mainContent} direction='column'>
            <Animate
              enter={'fade-in'}
              delay={1}
              duration={0.75}
              className={classes.animateContainer}
            >
              {transitions((props, item) => {
                return (
                  <animated.div
                    style={props}
                    className={classes.animateContainer}
                  >
                    <Switch location={item}>
                      {AssessmentSurveyRoutes.map((item, key) => {
                        return (
                          <Route
                            exact={item.exact}
                            key={key}
                            path={item.path}
                            render={() => item.component}
                          />
                        );
                      })}
                      <Route
                        path={`/${Page.taxCredits}/${Page.assessment}/${taxYear}/*`}
                        component={HttpErrorPage}
                      />
                    </Switch>
                  </animated.div>
                );
              })}
            </Animate>
          </Flex>
        </div>
        {!companyStore.accessToken ? (
          <>
            <AllSectionsSideDrawer
              taxYear={taxYear}
              stepIndex={unifiedTaxCredits.flowCurrentIndex}
            />
            {featureFlags.showShareAssessment && (
              <ShareAssessmentModal taxYear={taxYear} />
            )}
          </>
        ) : (
          <>
            {companyStore.company.adminName.first && (
              <InviteeIntroModal taxYear={taxYear} />
            )}
            <InviteeSuccessModal />
          </>
        )}
      </Flex>
    );
  },
);
