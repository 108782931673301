import {
  Flex,
  Color,
  Card,
  TextField,
  FileUpload,
  FileHandle,
  Text,
} from 'component-library';
import { FileTypes } from 'lib/constants';
import { ProgramData } from 'lib/interfaces';
import { observer } from 'mobx-react';
import { CompanyContext } from 'pages/CompanyRequired';
import { FilingDateTextField } from 'products/tax-credits/features/redemption/components';
import React, { useContext } from 'react';
import { useTaxCreditsStores } from 'stores/useStores';

interface Props {
  federalRDProgram: ProgramData;
  filingDateError: boolean;
}

export const ClientReviewUploadSideDrawer = observer(
  ({ federalRDProgram, filingDateError }: Props) => {
    const { company } = useContext(CompanyContext);
    const { form8974 } = useTaxCreditsStores();

    return (
      <div>
        <Text
          size={15}
          color={Color.neutral._60}
          text={`Please provide MainStreet with your tax filing details for the ${federalRDProgram.taxYear} tax year. It’s important that you share exactly the information requested here, so we can ensure that you receive the benefit of your R&D credits.`}
          paddingBottom={16}
        />
        <FilingDateTextField
          federalRDProgram={federalRDProgram}
          taxFilingDate={form8974.updatedTaxFilingDate ?? ''}
          setTaxFilingDate={form8974.setTaxFilingDate}
          filingDateError={filingDateError}
        />
        <Flex padding={[24, 0]}>
          <TextField
            dataTestId='ein-field'
            helperText='Please confirm the EIN shown on your tax return.'
            einNumberFormat
            label='EIN'
            value={form8974.updatedCompanyTaxId || company.taxId}
            onChange={(e) => form8974.setCompanyTaxId(e.target.value)}
          />
        </Flex>
        <Card noMargin noBorder noBoxShadow maxWidth={544}>
          <FileUpload
            dataTestId={'tax-form-file-upload'}
            title='Upload your complete tax return'
            subtitle={`Upload your complete business income tax return for the ${federalRDProgram.taxYear} tax year, including Form 6765 and any additional schedules.`}
            flexDirection={'column'}
            onFileAdded={(file: FileHandle) => {
              form8974.addFileToBeUploaded(file);
              file.setUploadProgress(1, 1, 1);
            }}
            acceptedFiletypes={FileTypes.PDF}
            allowFilePassword={false}
            onFileRemoved={(file: FileHandle) =>
              form8974.removeFileToBeUploaded(file)
            }
            maxFileSize={1024 * 1024 * 30}
            error={false}
            errorText={'error'}
            alignItems='flex-start'
          />
        </Card>
      </div>
    );
  },
);
