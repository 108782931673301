import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ContentContainer } from '../component';
import { observer } from 'mobx-react';
import {
  useAccountCreationStores,
  useCommonStores,
  useHistoryStore,
  useLegacyClients,
} from 'stores/useStores';
import {
  Content,
  Dropdown,
  Text,
  SurveyQuestion,
  Flex,
  Spinner,
  AnswerType,
  AnswerValueType,
} from 'component-library';
import { AccountCreationSteps, CmsRenderTree } from 'lib/interfaces';
import { useEffectOnce } from 'lib/helpers';
import {
  ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
  TaxTypesList,
  Months,
  IndustriesList,
  CompanyDataEnum,
  SurveyNameEnum,
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
} from 'lib/constants';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import renderTreeJson from '../../../../../hygraph/renderTree/unifiedAccountCreation.json';

const useStyles = makeStyles(() => ({
  surveyQuestion: {
    '& button': {
      padding: '12px 15px',
    },
  },
  '@global': {
    '.SurveyQuestion__title-section___2bPTK.SurveyQuestion__no-subtitle___eX6M3':
      {
        marginBottom: 0, // fix title spacing for SurveyQuestion
      },
  },
}));

const IndustriesListWithNameValue = IndustriesList.map((item) => {
  return { value: item, name: item };
});

const MonthsWithNameValue = Months.map((item) => {
  return { value: item, name: item };
});

const DropdownQuestionIdToAnswerOptions: {
  [key in CompanyDataEnum]?: { value: string; name: string }[];
} = {
  [CompanyDataEnum.INDUSTRY]: IndustriesListWithNameValue,
  [CompanyDataEnum.FISCAL_YEAR_END_DATE]: MonthsWithNameValue,
  [CompanyDataEnum.TAX_TYPE]: TaxTypesList,
};

interface TaxesStepProps {
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
}

export const TaxesStep = observer(
  ({ pathTo, pathFrom, onNext, onBack }: TaxesStepProps) => {
    const { client } = useLegacyClients();
    const { accountCreation } = useAccountCreationStores();
    const { app, companyStore } = useCommonStores();
    const history = useHistoryStore();
    const { saveAnswers, isLoading, questionsToRender, addSurveyAnswers } =
      useSurveyQuestions(
        SurveyNameEnum.UNIFIED_ACCOUNT_CREATION_SURVEY,
        ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
        {},
        renderTreeJson as CmsRenderTree,
      );

    const onContinue = async () => {
      // set button loading
      accountCreation.isNextButtonLoading = true;

      const surveyAnswers: {
        [key in CompanyDataEnum]?: string;
      } = accountCreation.TaxesStepQuestions.reduce(
        (answers, item) => ({
          [item.id]: item.answer as string,
          ...answers,
        }),
        {},
      );
      await Promise.all([
        await saveAnswers(),
        await accountCreation.updateAccount(
          {
            industry: surveyAnswers[CompanyDataEnum.INDUSTRY],
            fiscalYearEndDate:
              surveyAnswers[CompanyDataEnum.FISCAL_YEAR_END_DATE],
            taxType: surveyAnswers[CompanyDataEnum.TAX_TYPE],
          },
          AccountCreationSteps.TAXES,
          true, // is unified account creation
        ),
      ]);

      if (!accountCreation.errorMsg) {
        history.push(pathTo);
        onNext();
      }
    };

    useEffectOnce(() => accountCreation.setTaxesStepQuestions());

    const handleTaxExtensionUpdate = async (
      questionId: string,
      answerValue: AnswerValueType,
    ) => {
      if (questionId === GraphCmsQuestionIdEnum.CREDIT_CLAIM_YEAR) {
        await client.UpdatePrograms({
          programIdsToUpdate: companyStore.company.programs
            .filter((p) => p.taxYear === ACCOUNT_CREATION_QUALIFYING_TAX_YEAR)
            .map((p) => p.id),
          fileTaxExtension:
            answerValue ===
            GraphCmsQuestionIdToAnswers[
              GraphCmsQuestionIdEnum.CREDIT_CLAIM_YEAR
            ][ACCOUNT_CREATION_QUALIFYING_TAX_YEAR],
        });
      }
    };

    return (
      <ContentContainer
        onBack={onBack}
        onNext={onContinue}
        isNextButtonDisabled={
          accountCreation.enableNextButton(
            accountCreation.TaxesStepQuestions,
          ) ||
          !questionsToRender['taxes']?.every((question) => question.answerValue)
        }
        isNextLoading={accountCreation.isNextButtonLoading}
        pathFrom={pathFrom}
      >
        <Flex alignItems='start' direction='column' gap={8}>
          <Text
            text='Tell us about your taxes'
            variant='medium'
            size={app.isMobile ? 23 : 32}
          />
          <Text
            text='Your answers will be used to create a personalized MainStreet savings estimate.'
            size={15}
          />
        </Flex>
        {accountCreation.TaxesStepQuestions.map((item) => {
          return (
            <Content key={item.id} paddingLeftRight={0} paddingTopBottom={0}>
              <Text variant='medium' paddingBottom={8}>
                {item.question}
              </Text>
              <Dropdown
                dataTestId={`account_creation_${item.id}`}
                value={
                  item.answer
                    ? (DropdownQuestionIdToAnswerOptions[item.id] || []).find(
                        (title) => title.value === item.answer,
                      )?.name
                    : ''
                }
                options={DropdownQuestionIdToAnswerOptions[item.id] || []}
                placeholder=''
                onInputChange={(val) => {
                  if (typeof val !== 'string') {
                    accountCreation.setInputChange(
                      accountCreation.TaxesStepQuestions,
                      item.question,
                      val.value,
                    );
                  }
                }}
                selectOnly
              />
            </Content>
          );
        })}
        {isLoading || !questionsToRender['taxes'] ? (
          <Flex justifyContent='center'>
            <Spinner />
          </Flex>
        ) : (
          <>
            {questionsToRender['taxes'].map((question) => (
              <SurveyQuestion
                key={question.id}
                placeholder={question.placeholder}
                text={question.text}
                subtitle={question.subtitle}
                questionTextSize={15}
                answerType={question.answerType as AnswerType}
                answerValue={question.answerValue}
                onChange={async (answer) => {
                  question.answerValue = answer;
                  addSurveyAnswers(question.id, 'taxes', [
                    { questionId: question.id, answerValue: answer },
                  ]);
                  await handleTaxExtensionUpdate(
                    question.id,
                    question.answerValue,
                  );
                }}
                answerOptions={question.answerIDs}
                withCard={false}
              />
            ))}
          </>
        )}
      </ContentContainer>
    );
  },
);
