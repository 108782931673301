import { SideDrawer } from 'component-library';
import React from 'react';
import { observer } from 'mobx-react';
import { useTaxCreditsStores } from '../../../../stores/useStores';
import { TaxCreditProgramSteps } from '../TaxCreditProgramSteps';

export const TaxCreditsSideDrawer = observer(() => {
  const { taxCreditsPage } = useTaxCreditsStores();
  const step = taxCreditsPage.sideDrawerStep
    ? TaxCreditProgramSteps[taxCreditsPage.sideDrawerStep]
    : undefined;

  return (
    <>
      {step && (
        <SideDrawer
          show={taxCreditsPage.showSideDrawer}
          title={`${step.number + 1}. ${step.title}`}
          closeToggle={() => taxCreditsPage.setShowSideDrawer(false)}
          drawerActions={<></>}
          drawerContent={step.faq}
        />
      )}
    </>
  );
});
