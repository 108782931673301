import React, { useContext } from 'react';
import { useState } from 'react';
import {
  Text,
  TextField,
  Content,
  Color,
  IconEnum,
  Icon,
  Link,
  Button,
  Alert,
} from 'component-library';
import PlaidConnectBtn from 'components/payments/v2/plaid/PlaidConnectBtn';
import { makeStyles } from '@material-ui/core';
import {
  CurrencyStringToCents,
  CentsToDisplayStringNoSymbol,
  CentsToDisplayString,
  IsValidDate,
} from 'lib/helpers';
import { ExpectedPaymentDirection } from 'lib/interfaces';
import { BalanceInformation } from '../AccountBalanceCard';
import AccountSelect from 'components/payments/v2/plaid/AccountSelect';
import { BankAccount } from '@mainstreet/client-models/financial/banking/bankingModels';
import { MSPlaidClientContext } from 'lib/financial/MSPlaidClientProvider';

const useStyles = makeStyles(() => ({
  errorState: {
    color: Color.semantic.$error50,
  },
  orText: {
    margin: '24px 0',
  },
  dropdown: {
    marginBottom: '24px',
  },
  spacer: {
    marginTop: '24px',
  },
  wireLink: {
    marginBottom: '8px',
  },
  wireAmountWarning: {
    width: '100%',
    marginTop: '10px',
  },
}));

interface AchTransferProps {
  balanceInfo: BalanceInformation;
  direction: ExpectedPaymentDirection;
  setCurrentAmountEntered: (amountCents: number | undefined) => void;
  beginningAmountEntered: number;
  beginManualTransfer: () => void;
  setCurrentPlaidAccount: (account: BankAccount | null) => void;
  setCurrentScheduledDate: (date: string | undefined) => void;
  beginningScheduledDate: string;
  defaultPaymentAccount: BankAccount | null;
}

export const AchTransfer = ({
  balanceInfo,
  direction,
  setCurrentAmountEntered,
  beginningAmountEntered,
  beginManualTransfer,
  setCurrentPlaidAccount,
  setCurrentScheduledDate,
  beginningScheduledDate,
  defaultPaymentAccount,
}: AchTransferProps) => {
  const { state, actions } = useContext(MSPlaidClientContext);
  const classes = useStyles();

  const [currentAccount, setCurrentAccount] = useState<BankAccount | null>(
    defaultPaymentAccount,
  );
  const [wireAmountCents, setWireAmountCents] = useState<string>(
    CentsToDisplayStringNoSymbol(beginningAmountEntered),
  );
  const [scheduledDate, setScheduledDate] = useState<string>(
    beginningScheduledDate,
  );
  const [achCta, setAchCta] = useState<JSX.Element | null>(null);

  const handleAccountSelect = (e: any) => {
    if (e?.value === 'NEW_ACCOUNT') {
      // to allow the user to connect a new account, show the Plaid Connection button
      setAchCta(
        <PlaidConnectBtn open={actions.open} isLoading={state.isLoading} />,
      );
      setCurrentPlaidAccount(null);
      setCurrentAccount(null);
    } else {
      // check for the existing account
      const account =
        state.plaidAccounts.find(
          (account: BankAccount) => account.id === e.value,
        ) || null;
      if (account?.verificationStatus === 'pending_manual_verification') {
        // if the account is pending manual verification, show a button to send the user there
        setAchCta(
          <Button label='Complete Verification' onClick={actions.open} />,
        );
      } else {
        // if the account exists and is verified, no CTA should show, just amount/date fields
        setAchCta(null);
      }
      setCurrentAccount(account);
      setCurrentPlaidAccount(account);
    }
  };

  const isCreditTransferInTransaction =
    direction === ExpectedPaymentDirection.CREDIT;
  const isDebitTransferOutTransaction =
    direction === ExpectedPaymentDirection.DEBIT;

  // balance amounts

  const totalBalance =
    balanceInfo.principalBalanceCents + balanceInfo.accruedInterestCents;

  const remainingPendingBalance =
    balanceInfo.remainingPendingBalanceCents ?? totalBalance;

  // transfer limit calculations

  const transferOutAmountOverRemainingPendingBalance = (
    amountCents: string,
  ) => {
    return (
      isDebitTransferOutTransaction &&
      CurrencyStringToCents(amountCents) > remainingPendingBalance
    );
  };

  const transferOutAmountUnderMinBalance = (amountString: string): boolean => {
    if (isCreditTransferInTransaction) {
      // no need to show warning if transferring in, only on transfer out
      return false;
    }
    if (balanceInfo.minimumAllowedBalance === undefined) {
      // there is no minimum balance requirement, so no warning should be shown
      return false;
    }

    const amountRemainingAfterPotentialTransfer =
      remainingPendingBalance - CurrencyStringToCents(amountString);
    return (
      amountRemainingAfterPotentialTransfer < balanceInfo.minimumAllowedBalance
    );
  };

  const transferInAmountOverAvailablePlaidBalance =
    isCreditTransferInTransaction &&
    currentAccount?.availableBalanceCents &&
    CurrencyStringToCents(wireAmountCents) >
      currentAccount.availableBalanceCents;

  // alert criteria

  const showUnderMinBalanceWarning =
    !achCta &&
    transferOutAmountUnderMinBalance(wireAmountCents) &&
    !transferOutAmountOverRemainingPendingBalance(wireAmountCents);

  // handler functions

  const isDateValidAndInFuture = (date: string) =>
    IsValidDate(date) && new Date(date + ' 23:59:59') >= new Date();

  const handleDateEntry = (dateInput: string) => {
    setScheduledDate(dateInput);
    setCurrentScheduledDate(
      isDateValidAndInFuture(dateInput) ? dateInput : undefined,
    );
  };

  return (
    <div>
      <Content paddingLeftRight={24} paddingTopBottom={16}>
        {isDebitTransferOutTransaction && (
          <>
            <TextField
              value='MainStreet Yield'
              label='Transfer from'
              disabled
              helperText={
                balanceInfo.remainingPendingBalanceCents &&
                `Available balance: ${CentsToDisplayString(
                  balanceInfo.remainingPendingBalanceCents,
                  2,
                  2,
                )}`
              }
            />
            <div className={classes.spacer} />
          </>
        )}
        <div className={classes.dropdown}>
          <AccountSelect
            accounts={state.plaidAccounts}
            label={isDebitTransferOutTransaction ? 'To' : 'From'}
            placeholder='Select account to transfer funds'
            selectedAccount={currentAccount}
            onAccountSelect={(e: any) => handleAccountSelect(e)}
          />
        </div>
        {achCta ? (
          achCta
        ) : (
          <>
            <TextField
              value={wireAmountCents}
              onChange={(e) => {
                setWireAmountCents(e.target.value);
                setCurrentAmountEntered(
                  transferOutAmountOverRemainingPendingBalance(e.target.value)
                    ? undefined
                    : CurrencyStringToCents(e.target.value),
                );
              }}
              currencyFormat
              error={transferOutAmountOverRemainingPendingBalance(
                wireAmountCents,
              )}
              label='Amount'
              helperText={
                transferInAmountOverAvailablePlaidBalance ? (
                  'It appears this amount exceeds your balance make sure you have sufficient funds or the transaction will fail'
                ) : transferOutAmountOverRemainingPendingBalance(
                    wireAmountCents,
                  ) ? (
                  <Content flex alignItems='center' gap={0}>
                    <Icon
                      size={18}
                      color={Color.semantic.$error50}
                      name={IconEnum.info_circle}
                    />
                    <Text
                      className={classes.errorState}
                      size={15}
                      text='Error: Amount exceeds available balance'
                    />
                  </Content>
                ) : undefined
              }
              secondaryLabel={
                transferInAmountOverAvailablePlaidBalance
                  ? 'Might exceed balance'
                  : undefined
              }
              secondaryLabelTheme='warning'
            />
            {isCreditTransferInTransaction && (
              <>
                <div className={classes.spacer} />
                <TextField
                  error={!isDateValidAndInFuture(scheduledDate)}
                  value={scheduledDate}
                  onChange={(e) => handleDateEntry(e.target.value)}
                  dateFormat
                  label='Transfer date'
                />
                <Text
                  text='— OR —'
                  color={Color.neutral._60}
                  variant='medium'
                  className={classes.orText}
                />
                <Link
                  external
                  text='Transfer in manually'
                  variant='medium'
                  onClick={() => beginManualTransfer()}
                  className={classes.wireLink}
                />
                <Text text='Instructions to send a wire transfer directly from your bank.' />
              </>
            )}
          </>
        )}
      </Content>
      {showUnderMinBalanceWarning && (
        <Alert
          className={classes.wireAmountWarning}
          type='warning'
          variant='in_card'
          inCardBorder='top-bottom'
          icon={IconEnum.exclamation_triangle}
          text='This transfer will reduce your balance below the minimum investment value of $1 million dollars. If you do not maintain the minimum balance, we reserve the right to close this account and return your funds.'
        />
      )}
    </div>
  );
};
