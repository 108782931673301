import {
  Button,
  Content,
  Divider,
  Text,
  Color,
  Icon,
  IconEnum,
} from 'component-library';
import React from 'react';
import { ProgramData } from 'lib/interfaces';

interface GeorgiaRdGrossReceiptsHelpProps {
  program?: ProgramData | null;
}

const GeorgiaRdGrossReceiptsHelpDrawer = ({
  program,
}: GeorgiaRdGrossReceiptsHelpProps) => {
  const taxYear = program ? program.taxYear : '';

  return (
    <Content flex flexDirection={'column'}>
      <Text variant={'medium'}>1. Georgia Tax Returns</Text>
      <Divider variant={'no-bottom-margin'} />
      <Content
        flex
        flexDirection={'column'}
        justifyContent={'flex-start'}
        gap={0}
      >
        <Text>Form 600 (C-Corp) - Sch. 6 Line 1A</Text>
        <Text>Form 600S (S-Corp) - Sch. 9 Line 1A</Text>
        <Text paddingBottom={24}>Form 700 (Partnership) - Sch. 6 Line 1A</Text>
      </Content>

      <Text variant={'medium'}>2. From Accounting/Bookkeeping Software</Text>
      <Divider variant={'no-bottom-margin'} />
      <Text>{`Some list that shows the "Gross sales (revenue) by State" and/or "Gross sales (revenue) by Customer" and
          filter for GA located customers.`}</Text>
      <Button
        flexAlignSelf={'flex-end'}
        label={
          <>
            {`Where to find the ${taxYear} GA gross receipts amount`}
            <Icon
              name={IconEnum.external_link}
              size={15}
              color={Color.blue._60}
            />
          </>
        }
        variant='tertiary'
        onClick={{
          location:
            'https://mainstreet-help.force.com/help/s/article/Where-can-I-find-my-Georgia-Gross-Receipts',
          external: true,
        }}
      />
    </Content>
  );
};

export default GeorgiaRdGrossReceiptsHelpDrawer;
