import React, { useState, useCallback } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { EmptyClient } from 'services/ServerClient';
import Stripe from 'stripe';
import { IPaymentMethodState } from 'components/PaymentMethod';
import { StripePaymentMethod } from 'components/payments/v2/stripe/types';

interface UseCreditCardArgs {
  stripeCustomerId: string | null | undefined;
  setDefaultPaymentMethod: (
    connectedAccountId: string | null,
    ccPaymentMethodId: string | null,
  ) => Promise<{ errorMsg: string | null }>;
  setPaymentMethodState: React.Dispatch<
    React.SetStateAction<IPaymentMethodState>
  >;
}

interface AddNewCardState {
  failedSetupIntent: Stripe.SetupIntent | null;
  formError: string;
  userMessage: string;
  cardSubmitted: boolean;
  formSuccess: string;
}

interface CreditCardState {
  connectedCards: StripePaymentMethod[];
  errorMsg: string;
  selectedCardId: string | null;
  cardsIsLoading: boolean;
}

export default function useCreditCard({
  stripeCustomerId,
  setDefaultPaymentMethod,
  setPaymentMethodState,
}: UseCreditCardArgs) {
  /* 
	  Controls for adding a new credit card
  */
  const [addNewCardState, setAddNewCardState] = useState<AddNewCardState>({
    failedSetupIntent: null,
    formError: '',
    userMessage: '',
    cardSubmitted: false,
    formSuccess: '',
  });

  /* 
	  Controls for retrieving and selecting existing credit cards
  */
  const [creditCardState, setCreditCardState] = useState<CreditCardState>({
    connectedCards: [],
    errorMsg: '',
    selectedCardId: null,
    cardsIsLoading: false,
  });

  const stripe = useStripe();
  const elements = useElements();

  /**
   * Called on load of credit card container to load all existing connected
   * credit cards.  These are populated in the CreditCardSelect drop down
   */
  const getAllPaymentCards = useCallback(async () => {
    setCreditCardState((prev) => ({ ...prev, cardsIsLoading: true }));
    if (stripeCustomerId) {
      const res = await EmptyClient.GetPaymentMethods(stripeCustomerId);
      if (res.errorMsg) {
        setCreditCardState((prev) => ({
          ...prev,
          errorMsg: res.errorMsg || '',
        }));
      } else if (res.data?.paymentMethods !== undefined) {
        setCreditCardState((prev) => ({
          ...prev,
          connectedCards: res.data?.paymentMethods || [],
        }));
      }
    }
    setCreditCardState((prev) => ({ ...prev, cardsIsLoading: false }));
  }, [stripeCustomerId]);

  /*
   * Used to create setup intent and save newly added credit card as default payment method
   */
  const handleSaveCard = async () => {
    if (!stripe || !elements) return;
    const cardElement = elements.getElement(CardElement);
    if (!cardElement || !stripeCustomerId) return;
    setAddNewCardState({
      ...addNewCardState,
      cardSubmitted: true,
      userMessage: 'Authorizing card...',
      formError: '',
      formSuccess: '',
    });

    let result;
    if (addNewCardState.failedSetupIntent?.client_secret) {
      result = await stripe.confirmCardSetup(
        addNewCardState.failedSetupIntent.client_secret,
        {
          payment_method: {
            card: cardElement,
          },
        },
      );
      if (result.error?.message) {
        setCreditCardState({
          ...creditCardState,
          errorMsg: result.error?.message,
        });
      }
    } else {
      const { data, errorMsg } = await EmptyClient.SetupPaymentIntent(
        stripeCustomerId,
      );

      if (errorMsg) {
        setAddNewCardState({
          ...addNewCardState,
          userMessage: '',
          formError: errorMsg,
          cardSubmitted: false,
        });

        return;
      }

      if (data === undefined) {
        setAddNewCardState({
          ...addNewCardState,
          formError:
            'An error occurred processing your card, please try again.',
        });
        return;
      }

      result = await stripe.confirmCardSetup(data.secret, {
        payment_method: {
          card: cardElement,
        },
      });
      if (result.error?.message) {
        setCreditCardState({
          ...creditCardState,
          errorMsg: result.error?.message,
        });
      }
    }

    if (result.error) {
      const failedIntent = result.error.setup_intent as Stripe.SetupIntent;
      setAddNewCardState({
        formSuccess: '',
        userMessage: '',
        formError: result.error.message || '',
        failedSetupIntent: failedIntent,
        cardSubmitted: false,
      });
      return;
    }

    if (addNewCardState.failedSetupIntent) {
      setAddNewCardState({ ...addNewCardState, failedSetupIntent: null });
    }

    setAddNewCardState({
      ...addNewCardState,
      userMessage: 'Saving card details...',
    });

    const res = await setDefaultPaymentMethod(
      null,
      result.setupIntent.payment_method,
    );

    if (res?.errorMsg) {
      setAddNewCardState({
        ...addNewCardState,
        userMessage: '',
        formError: res.errorMsg,
        cardSubmitted: false,
      });
      return;
    }

    setAddNewCardState({
      ...addNewCardState,
      formSuccess: 'Successfully saved card!',
      userMessage: '',
      cardSubmitted: false,
    });

    setPaymentMethodState((prev) => ({
      ...prev,
      selectionStatus: 'default',
      addNewCard: false,
    }));

    await getAllPaymentCards();
  };

  return {
    handleSaveCard,
    getAllPaymentCards,
    addNewCardState,
    stripe,
    setCreditCardState,
    setAddNewCardState,
    creditCardState,
  };
}
