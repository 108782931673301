'use client';

import React, { useRef, useEffect } from 'react';

import classnames from 'classnames/bind';

interface IntersectionObserverProps {
  className: string;
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
  inViewClassName?: string;
  children: React.ReactElement | JSX.Element;
}

const IntersectionObserverComponent: React.FC<IntersectionObserverProps> = ({
  className,
  root = null,
  rootMargin = '0px',
  threshold = 0.5,
  inViewClassName = '',
  children,
}) => {
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!targetRef.current) return;

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          targetRef.current?.classList.add(inViewClassName);
        } else {
          targetRef.current?.classList.remove(inViewClassName);
        }
      });
    };

    const observer = new (window as any).IntersectionObserver(
      observerCallback,
      {
        root,
        rootMargin,
        threshold,
      },
    );

    observer.observe(targetRef.current);
  }, [root, rootMargin, threshold, inViewClassName]);

  const composedClassnames = classnames('intersection-observer', className);

  return (
    <div className={composedClassnames} ref={targetRef}>
      {children}
    </div>
  );
};

export default IntersectionObserverComponent;
