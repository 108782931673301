import { BaseStore } from 'stores/BaseStore';
import { runInAction, toJS } from 'mobx';
import {
  makeSubclassObservable,
  MobxObservableAnnotation,
} from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import { FeatureFlags } from 'entities/FeatureFlags';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { LDClient } from 'launchdarkly-js-client-sdk';
import {
  FeatureFlagNameEnum,
  FeatureFlagSet,
} from 'lib/constants/featureFlagConstants';
import { datadogLogs } from '@datadog/browser-logs';

const LOCAL_STORAGE_KEY = 'feature_flag_overrides';
const OVERRIDE_QUERY_PARAM = 'flag_override';

export class FeatureFlagStore extends BaseStore {
  private launchDarklyClient: LDClient | null = null;
  public flags: FeatureFlags = new FeatureFlags();
  public overrides: FeatureFlagSet = {};
  public isReady = false;

  /**
   * @deprecated This state is only meant to trigger a render
   * in the legacy contexts for the getFlag function
   */
  public isReadyCounter = -1;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this, {
      // launchDarklyClient is just a reference to the LDClient library instance
      launchDarklyClient: MobxObservableAnnotation.NOT_OBSERVED,
    });
  }

  // Initializes the LD client on setup
  public async setup() {
    this.configureOverrides();

    const userIdBase = {
      key: 'notLoggedIn',
      kind: 'user',
    };

    this.launchDarklyClient = LaunchDarkly.initialize(
      process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '',
      this.company.isLoaded
        ? { ...userIdBase, companyId: this.company.id }
        : userIdBase,
    );

    try {
      await this.launchDarklyClient.waitForInitialization();

      // Update flags with overrides
      this.flags.assignFlags({
        ...(this.launchDarklyClient.allFlags() || {}),
        ...this.overrides,
      });

      // Update external flags
      this.rootStore.event.emit('FEATURE_FLAGS', {
        featureFlags: toJS(this.flags.flags),
        featureFlagOverrides: toJS(this.overrides),
      });

      // Watch for any changes to flags
      this.launchDarklyClient.on('change', this.mergeFlagChanges.bind(this));
    } catch (e) {
      datadogLogs.logger.error('LaunchDarkly Connection Error', e as object);
    }

    // Mark feature flags as ready for use
    runInAction(() => {
      this.isReady = true;
      this.isReadyCounter = 1;
    });
  }

  // Closes off the LD client on teardown
  public async teardown() {
    this.launchDarklyClient?.off('change', this.mergeFlagChanges.bind(this));
    await this.launchDarklyClient?.close();
  }

  // Change handler for any config tweaks in LD
  public mergeFlagChanges(changes: LaunchDarkly.LDFlagChangeset) {
    const flags: FeatureFlagSet = {};
    for (const key in changes) {
      flags[key as FeatureFlagNameEnum] = changes[key].current;
    }

    runInAction(() => {
      this.flags.mergeFlags({ ...flags, ...this.overrides });
      if (this.isReadyCounter > -1) {
        this.isReadyCounter++;
      }
    });

    // Update external flags
    this.rootStore.event.emit('FEATURE_FLAGS', {
      featureFlags: toJS(this.flags.flags),
      featureFlagOverrides: toJS(this.overrides),
    });
  }

  /**
   * Enables overriding feature flag values for a single browser, storing the overrides
   * in local storage such that they are persisted between hard refreshes. Behavior is
   * driven by appending the 'flag_override' query parameter. Usage:
   *
   * Add an override:
   *  - https://dashboard.mainstreet.com?flag_override=FLAG_NAME:FLAG_VALUE
   *
   * Clear all overrides:
   *  - https://dashboard.mainstreet.com?flag_override=clear
   */
  public configureOverrides() {
    let overrides = this.getFeatureFlagOverrides();

    // Fetch URL param for override
    const overrideParam: string | null = new URLSearchParams(
      window.location.search,
    ).get(OVERRIDE_QUERY_PARAM);

    // Detect if overrides need to be cleared
    if (overrideParam === 'clear') {
      overrides = {};
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      console.info('Feature flag overrides cleared');
    }
    // Detect if new param is required
    else if (overrideParam) {
      const [flagName, flagValue] = overrideParam.split(':');
      Object.assign(overrides, { [flagName]: flagValue });
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(overrides));
      console.info(
        `Feature flag override set, '${flagName}' now set to '${flagValue}'`,
      );
    }

    runInAction(() => (this.overrides = overrides));
  }

  // Fetching persisted overrides from local storage
  public getFeatureFlagOverrides(): Record<string, string> {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (value) {
      try {
        const hash = JSON.parse(value);
        console.info(`Feature flag persisted values found`, hash);
        return hash;
      } catch (e) {
        console.warn(`Unable to parse local overrides from '${value}':`, e);
      }
    }

    return {};
  }

  public setUserIdentity(companyId: number | undefined) {
    // only used in production to avoid LaunchDarkly MAU limits
    if (this.launchDarklyClient && process.env.NODE_ENV === 'production') {
      const currentLDUser = this.launchDarklyClient.getContext();
      if (currentLDUser.key && currentLDUser.key === 'notLoggedIn') {
        this.launchDarklyClient.identify({
          kind: 'user',
          key: this.rootStore.common.auth.user?.email,
          companyId: companyId ?? 0,
        });
      }
    }
  }
}
