import { configure } from 'mobx';
import { CommonNamespace } from './common/CommonNamespace';
import { TaxCreditsNamespace } from '../products/tax-credits/TaxCreditsNamespace';
import { AccountCreationNamespace } from './accountcreation/AccountCreationNamespace';
import { HistoryStore } from './HistoryStore';
import { EventEmitter } from './EventEmitter';
import { LegacyClients } from './LegacyClients';
import { BaseAPI } from 'api/BaseAPI';
import { DashboardNamespace } from 'products/dashboard';
import { OnboardingNamespace } from 'products/onboarding';

// Configures strictness of MobX warnings/errors
// https://mobx.js.org/configuration.html#linting-options
if (process.env.NODE_ENV !== 'test') {
  configure({
    // Enforce changing of observables must be done inside actions
    enforceActions: 'always',

    // Enforce accessing of computed entries only in observable arenas,
    // meaning only inside an action block or an observed component
    computedRequiresReaction: true,
  });
}

export class RootStore {
  public history = new HistoryStore(this);
  public apiClients: BaseAPI[] = [];
  public event = new EventEmitter();
  public legacyClients = new LegacyClients(this);

  // Namespaces
  public common = new CommonNamespace(this);
  public taxcredits = new TaxCreditsNamespace(this);
  public accountcreation = new AccountCreationNamespace(this);
  public dashboard = new DashboardNamespace(this);
  public onboarding = new OnboardingNamespace(this);

  /**
   * @deprecated ServerClient has been moved to the legacy clients namespace (rootStore.legacyClients.client).
   * Try 'useLegacyClients()' to get the ServerClient instance for use in components
   */
  public get client() {
    return this.legacyClients.client;
  }

  /**
   * Generalized setup handler
   */
  public async setup() {
    await this.event.emit('SETUP');
  }

  /**
   * Generalized teardown handler
   */
  public async teardown() {
    await this.event.emit('TEARDOWN');
  }
}
