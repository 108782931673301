import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Animate, Flex } from 'component-library';
import { useCommonStores } from 'stores/useStores';
import UserChatMessage from './UserChatMessage';
import BotChatMessage from './BotChatMessage';
import BotTyping from './BotTyping';
import markdownit from 'markdown-it';

const useStyles = makeStyles(() => ({
  root: {
    height: '63%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
}));

export const ChatWindowMessages = observer(() => {
  const classes = useStyles();
  const { chatbot } = useCommonStores();

  const animationDuration = 0.2;

  return (
    <div className={classes.root}>
      <Flex
        direction='column-reverse'
        justifyContent='space-between'
        padding={8}
        key={chatbot.orderedChatMessages.length}
      >
        {chatbot.botMessageLoading && (
          <Animate
            key={'bot-typing'}
            enter={['from-bottom']}
            duration={animationDuration}
          >
            <BotTyping />
          </Animate>
        )}
        {chatbot.orderedChatMessages.map((message, i) => {
          const text = markdownit({ linkify: true }).render(message.text);

          if (message.isBot) {
            return (
              <Animate
                key={message.createdAt.toString() + 'bot'}
                enter={['from-bottom']}
                duration={animationDuration}
                unmountOnExit
              >
                <BotChatMessage
                  key={i}
                  text={text}
                  createdAt={message.createdAt}
                />
              </Animate>
            );
          } else {
            return (
              <Animate
                key={message.createdAt.toString()}
                enter={['from-bottom']}
                duration={animationDuration}
                unmountOnExit
              >
                <UserChatMessage text={text} createdAt={message.createdAt} />
              </Animate>
            );
          }
        })}
      </Flex>
    </div>
  );
});
