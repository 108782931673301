import { runInAction } from 'mobx';
import { makeSubclassObservable } from '../lib/mobx-utils';
import { BaseEntity } from './BaseEntity';
import { CountryEnum, StateEnum } from '../lib/interfaces';

export enum AddressTypeEnum {
  BUSINESS = 'business',
  MAILING = 'mailing',
}

export interface AddressEntityData {
  id: string;
  companyId: number;
  streetLine1?: string;
  streetLine2: string;
  city: string;
  state: StateEnum | null;
  country?: CountryEnum;
  zipcode: string;
  addressType: AddressTypeEnum;
}

export class AddressEntity extends BaseEntity {
  public id = '';
  public companyId = 0;
  public streetLine1 = '';
  public streetLine2?: string;
  public city = '';
  public state: StateEnum | null = null;
  public country?: CountryEnum | null = null;
  public zipcode = '';
  public addressType?: AddressTypeEnum | null = null;

  constructor(data?: AddressEntityData) {
    super();
    makeSubclassObservable(this);

    if (data) {
      this.mergeData(data);
    }
  }

  public mergeData(data: AddressEntityData) {
    runInAction(() => {
      this.id = data.id || '';
      this.companyId = data?.companyId || 0;
      this.streetLine1 = data.streetLine1 || '';
      this.streetLine2 = data.streetLine2 || '';
      this.city = data.city || '';
      this.state = data.state || null;
      this.country = data.country || null;
      this.zipcode = data.zipcode || '';
      this.addressType = data.addressType || null;
    });
  }
}
