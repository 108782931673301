import { Button } from 'component-library';
import CardPayment from 'components/payments/CardPayment';
import React from 'react';

interface AddNewCardProps {
  userMessage: string;
  stripe: boolean;
  cardSubmitted: boolean;
  handleSaveCard: () => Promise<void>;
  saveBtnStyle: string;
  setDefaultIsLoading: boolean;
}

export default function AddNewCard(props: AddNewCardProps) {
  return (
    <>
      <CardPayment />
      <Button
        variant='contained'
        label={props.userMessage || 'Save and set as default'}
        className={props.saveBtnStyle}
        disabled={!props.stripe || props.cardSubmitted}
        onClick={props.handleSaveCard}
        loading={props.setDefaultIsLoading}
        dataTestId='save-new-credit-card'
      />
    </>
  );
}
