import { BaseAPI } from 'api/BaseAPI';
import {
  APIV1,
  ORDER,
  PROMOTIONS,
  OrderFormPromotionNameEnum,
  OrderFormPromotionMappingResponse,
  COMPANY,
  CarryoverAndAdjustStatusEnum,
  ROOT_8974,
  PayrollTierEnum,
  LEDGERS,
  TRANSACTIONS,
  ProgramLedgerTxnUpdateRequest,
  AddProgramLedgerTxnRequest,
  DASHBOARD,
  API,
  CompanyAccessToken,
} from 'lib/constants';
import {
  AcceptedByData,
  CreditEstimate,
  DocumentResponse,
  MSClientResponse,
  OrderForm,
  QuarterlyInputData,
  TaxCreditAssessments,
} from 'lib/interfaces';

export class TaxCreditsAPI extends BaseAPI {
  public async GetOrderFormData(programId: number) {
    return this.GET<{ order: OrderForm }>(
      `/${APIV1}/${ORDER}?program_id=${programId}`,
    );
  }

  public async AcceptOrderForm(
    programId: number,
    acceptedBy: AcceptedByData,
  ): Promise<MSClientResponse<{ acceptedOrder: OrderForm }>> {
    return await this.POST(`/${APIV1}/${ORDER}/accept`, {
      programId,
      acceptedBy,
    });
  }

  public async AcceptOrderFormPromotion(
    orderFormId: number,
    promotion: OrderFormPromotionNameEnum,
  ) {
    return await this.POST(`/${APIV1}/${ORDER}/${orderFormId}/${PROMOTIONS}`, {
      orderFormId,
      promotionName: promotion,
      source: 'client',
    });
  }

  public async GetPromotionsForOrderForm(orderFormId: number) {
    return await this.GET<{
      promotion: OrderFormPromotionMappingResponse | undefined;
    }>(`/${APIV1}/${ORDER}/${orderFormId}/${PROMOTIONS}`);
  }

  public async GetCreditEstimates(programId: number) {
    return await this.GET<{ creditEstimates: CreditEstimate[] }>(
      `/${APIV1}/${COMPANY}/credit-estimates/history/${programId}`,
    );
  }

  public async GetLatestCreditEstimate(programId: number) {
    return await this.GET<{ creditEstimate: CreditEstimate }>(
      `/${APIV1}/${COMPANY}/credit-estimates/latest/${programId}`,
    );
  }

  public async GetFilingStatus(tier: PayrollTierEnum) {
    return await this.GET<{ filingStatus: CarryoverAndAdjustStatusEnum }>(
      `/${APIV1}/${ROOT_8974}/filing-status/${tier}`,
    );
  }

  public async UpdateProgramLedgerTxn(
    programId: number,
    updateReq: ProgramLedgerTxnUpdateRequest,
  ) {
    return await this.PUT(
      `/${APIV1}/${ROOT_8974}/${LEDGERS}/${TRANSACTIONS}/update/${programId}`,
      updateReq,
    );
  }

  public async AddProgramLedgerTxn(
    programId: number,
    updateReq: AddProgramLedgerTxnRequest,
  ) {
    return await this.POST(
      `/${APIV1}/${ROOT_8974}/${LEDGERS}/program/fund/${programId}`,
      updateReq,
    );
  }

  public async EnterTaxValuesForMissedDeadline(
    quarterlyPayrollTaxesForm941: QuarterlyInputData,
  ): Promise<MSClientResponse<{ quarters: DocumentResponse }>> {
    return await this.POST(
      `/${APIV1}/${ROOT_8974}/filing-status/submit-missed-filings`,
      quarterlyPayrollTaxesForm941,
    );
  }

  public async GetTaxCreditAssessments(accessToken?: CompanyAccessToken) {
    if (accessToken) {
      return await this.GET<{
        taxCredits: TaxCreditAssessments[];
      }>(`/${APIV1}/company-access-token/tax-credit-assessments`);
    }

    return await this.GET<{
      taxCredits: TaxCreditAssessments[];
    }>(`/${API}/${DASHBOARD}/tax-credit-assessments`);
  }
}
