import React, { useCallback, useEffect, useState } from 'react';
import {
  CardHeader,
  Content,
  Text,
  TextField,
  Color,
  Button,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { Address, PersonApplicationDataDraft } from 'lib/interfaces';
import { GetDateInfo, IsValidDate, IsDateInTheFuture } from 'lib/helpers';
import { AddressInput } from '.';
import {
  getPersonApplicationDataErrors,
  PersonApplicationDataIsComplete,
} from './helpers';

const useStyles = makeStyles(() => ({
  textButtons: {
    height: '0px',
  },
}));

type PersonalInfoApplicationProps = {
  beneficiaries: PersonApplicationDataDraft[];
  personInfo: PersonApplicationDataDraft;
  previouslySubmitted: boolean;
  setPersonInfo:
    | React.Dispatch<React.SetStateAction<PersonApplicationDataDraft>>
    | ((newOwnerInfo: PersonApplicationDataDraft) => void);
  removeBeneficiary: () => void;
  certify: boolean;
  saveBeneficiary: () => void;
};

export const PersonalInfoApplication = ({
  beneficiaries,
  personInfo,
  previouslySubmitted,
  setPersonInfo,
  removeBeneficiary,
  certify,
  saveBeneficiary,
}: PersonalInfoApplicationProps) => {
  const classes = useStyles();
  const { dateOfBirth } = personInfo;

  const hasInvalidBirthDate =
    Boolean(dateOfBirth) &&
    (!IsValidDate(dateOfBirth) || IsDateInTheFuture(new Date(dateOfBirth)));

  const [errorMessage, setErrorMessage] = useState('');

  const getFormErrorsOnSubmission = useCallback((): boolean => {
    const missingPersonInfo = getPersonApplicationDataErrors({
      personApplicationData: personInfo,
      requireSocialSecurity: !previouslySubmitted,
    });
    if (missingPersonInfo.length > 0) {
      const missingInformation = missingPersonInfo.join(', ');
      setErrorMessage(
        `Please fill out missing personal information: ${missingInformation}`,
      );
      return true;
    }

    setErrorMessage('');
    return false;
  }, [personInfo, previouslySubmitted]);

  useEffect(() => {
    if (certify) {
      getFormErrorsOnSubmission();
    }
  }, [certify, getFormErrorsOnSubmission]);

  const onSave = () => {
    setErrorMessage('');

    if (!PersonApplicationDataIsComplete(personInfo)) {
      getFormErrorsOnSubmission();
      return;
    }
    saveBeneficiary();
  };

  return (
    <>
      <CardHeader>
        <Content>
          <Text text='Company owner information' size={18} variant='medium' />
        </Content>

        {beneficiaries.length > 1 && (
          <Button
            className={classes.textButtons}
            label={
              <Text text='Delete' variant='medium' color={Color.pink._70} />
            }
            onClick={() => {
              if (beneficiaries.length > 1) {
                removeBeneficiary();
              }
            }}
            variant='tertiary'
          />
        )}
      </CardHeader>

      <Content paddingBottom={16} paddingLeftRight={24}>
        <Content paddingTopBottom={16}>
          <Text text='Personal information' size={15} variant='medium' />
        </Content>

        <Content flex paddingLeftRight={0} paddingBottom={16}>
          <TextField
            label='First name'
            small
            value={personInfo.firstName}
            onChange={(e) => {
              setPersonInfo({
                ...personInfo,
                firstName: e.target.value,
              });
            }}
          />
          <TextField
            label='Last name'
            small
            value={personInfo.lastName}
            onChange={(e) => {
              setPersonInfo({
                ...personInfo,
                lastName: e.target.value,
              });
            }}
          />
        </Content>

        <Content flex paddingLeftRight={0} paddingBottom={16}>
          <div style={{ flex: 1 }}>
            <TextField
              label='Date of birth'
              placeholder='MM/DD/YYYY'
              small
              error={hasInvalidBirthDate}
              helperText={hasInvalidBirthDate && 'Please enter a valid date'}
              value={
                IsValidDate(dateOfBirth) // TypeScript is getting confused, so I had to add a check again here
                  ? GetDateInfo(dateOfBirth).monthDayYear
                  : undefined
              }
              onChange={(e) => {
                const dateEntry = e.target.value;
                // we will set the date to the state once it is completely filled out
                if (dateEntry && !dateEntry.includes('_')) {
                  setPersonInfo({
                    ...personInfo,
                    dateOfBirth: new Date(dateEntry),
                  });
                }
              }}
              dateFormat
            />
          </div>
          <div style={{ flex: 1 }}></div>
        </Content>

        <Content flex paddingLeftRight={0} paddingBottom={16}>
          <TextField
            label='Phone number'
            placeholder='(000) 000-0000'
            small
            value={personInfo?.phoneNumber}
            phoneNumber={true}
            onChange={(e) => {
              setPersonInfo({
                ...personInfo,
                phoneNumber: e.target.value,
              });
            }}
          />
          <TextField
            label='Email'
            small
            value={personInfo?.emailAddress}
            onChange={(e) => {
              setPersonInfo({
                ...personInfo,
                emailAddress: e.target.value,
              });
            }}
          />
        </Content>

        <Content flex paddingLeftRight={0} paddingBottom={16}>
          <div style={{ flex: 1 }}>
            <TextField
              label='Title'
              small
              value={personInfo.title}
              onChange={(e) => {
                setPersonInfo({
                  ...personInfo,
                  title: e.target.value,
                });
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TextField
              label='Social Security Number'
              placeholder='*** - ** - ****'
              small
              value={previouslySubmitted ? '*** - ** - ****' : personInfo.tin}
              onChange={(e) => {
                setPersonInfo({
                  ...personInfo,
                  tin: e.target.value,
                });
              }}
              socialSecurityFormat={true}
              disabled={previouslySubmitted}
            />
          </div>
        </Content>

        <Content flex paddingBottom={16} paddingLeftRight={0}>
          <div style={{ flex: 1 }}>
            <TextField
              label='Ownership percentage'
              percentFormat={true}
              placeholder='%'
              small
              value={personInfo.ownershipPercentage}
              onChange={(e) => {
                const newPercent = parseInt(e.target.value, 10) ?? undefined;
                setPersonInfo({
                  ...personInfo,
                  ownershipPercentage: Number.isNaN(newPercent)
                    ? undefined
                    : newPercent,
                });
              }}
            />
          </div>
          <div style={{ flex: 1 }} />
        </Content>

        <Content paddingTopBottom={16} paddingLeftRight={0}>
          <Text text='Home address' size={15} variant='medium' />
        </Content>

        <AddressInput
          currentAddressInfo={{ ...personInfo.physicalAddress }}
          setNewAddress={({
            field,
            newValue,
          }: {
            field: keyof Address;
            newValue: Address[keyof Address];
          }) => {
            setPersonInfo({
              ...personInfo,
              physicalAddress: {
                ...personInfo.physicalAddress,
                [field]: newValue,
              },
            });
          }}
        />

        {errorMessage && (
          <Content paddingLeftRight={0} paddingTopBottom={16}>
            <Text size={15} status='failed'>
              {errorMessage}
            </Text>
          </Content>
        )}

        <Button
          label={'Save'}
          onClick={() => {
            onSave();
          }}
        />
      </Content>
    </>
  );
};
