import * as React from 'react';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { Paper, makeStyles } from '@material-ui/core';
import { ValidateResetPassToken } from 'services/server';
import SetNewPassword from 'components/SetNewPassword';
import { ValidatedToken } from 'lib/interfaces';
import { GetQueryValue, Cancelable } from 'lib/helpers';
import { Page, GRAY } from 'lib/constants';
import { useCompany } from 'stores/useStores';
import { observer } from 'mobx-react';
import { useFeatureFlags } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  root: {
    background: GRAY,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '72px 0',
  },
  paper: {
    padding: '48px',
    textAlign: 'center',
  },
  userMessage: {
    margin: '8px',
  },
}));

interface ResetPasswordPageProps {
  search: string;
}

export const ResetPasswordPage = observer(
  ({ search }: ResetPasswordPageProps) => {
    const classes = useStyles();
    const { company } = useCompany();
    const [redirect, setRedirect] = useState<string>('');
    const [validatedToken, setValidatedToken] = useState<ValidatedToken>(
      {} as ValidatedToken,
    );
    const [userMessage, setUserMessage] = useState<string>(
      'Confirming this is a valid reset password link...',
    );

    useEffect(() => {
      if (company.isLoaded) {
        setRedirect(`/${Page.settings}`);
      }
      const parsedSearch = queryString.parse(search);

      if (!parsedSearch.token || !parsedSearch.email) {
        setRedirect('/');
      }

      const queryToken = GetQueryValue(parsedSearch.token!);
      const queryEmail = GetQueryValue(parsedSearch.email!);
      const tokenObj: ValidatedToken = {
        token: queryToken,
        email: queryEmail,
      };

      return Cancelable(ValidateResetPassToken(tokenObj), (res) => {
        const { valid } = res.data!;
        if (!valid) {
          setUserMessage(res.errorMsg!);
        } else {
          setUserMessage('');
          setValidatedToken(tokenObj);
        }
      });
    }, [company.isLoaded, search]);

    const passwordUpdated = () => {
      setUserMessage('Password updated, redirecting you to login');
      setTimeout(() => {
        setRedirect(`/`);
      }, 1500);
    };
    const featureFlags = useFeatureFlags();
    const showNewMSLogo = featureFlags.showNewMainStreetLogo;

    const logoUrl = showNewMSLogo
      ? `${process.env.PUBLIC_URL}/images/logo/mst-logo-green.svg`
      : `${process.env.PUBLIC_URL}/images/MainSt_WordmarkGreen.svg`;

    return (
      <div className={classes.root}>
        <Paper elevation={3} className={classes.paper}>
          <img src={logoUrl} alt='MainStreet watermark' width='177px' />
          <div>
            {validatedToken.token && (
              <SetNewPassword
                tokenObj={validatedToken}
                onComplete={passwordUpdated}
              />
            )}
          </div>
          <div>
            <div className={classes.userMessage}>{userMessage}</div>
            {redirect ? <Redirect to={redirect} /> : undefined}
          </div>
        </Paper>
      </div>
    );
  },
);
