import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: spacing(3),
    width: '80%',
  },
}));

export default function CenterTextBox({ children }: any) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const classes = useStyles();
  return <div className={`${classes.root} content-text`}>{children}</div>;
}
