import React from 'react';
import { Content, Dropdown, TextField } from 'component-library';
import { Address, CountryEnum, StateWithNoneEnum } from 'lib/interfaces';
import { SnakeToSentenceCase } from 'lib/helpers';
import { GetCountryNameUsingAbbreviation } from './helpers';

type AddressInputProps = {
  setNewAddress: ({
    field,
    newValue,
  }: {
    field: keyof Address;
    newValue: any;
  }) => void;
  currentAddressInfo: Partial<Address>;
};

export const AddressInput = ({
  currentAddressInfo,
  setNewAddress,
}: AddressInputProps) => {
  return (
    <>
      <Content flex paddingLeftRight={0} paddingBottom={16}>
        <TextField
          label='Street'
          placeholder='123 Main Street'
          small
          value={currentAddressInfo?.streetLine1}
          onChange={(e) => {
            setNewAddress({ field: 'streetLine1', newValue: e.target.value });
          }}
        />
        <TextField
          label='Apt/Unit/Suite #'
          small
          value={currentAddressInfo?.streetLine2}
          onChange={(e) => {
            setNewAddress({ field: 'streetLine2', newValue: e.target.value });
          }}
        />
      </Content>

      <Content flex paddingLeftRight={0} paddingBottom={16}>
        <div style={{ flex: '1' }}>
          <TextField
            label='City'
            placeholder='City name'
            small
            value={currentAddressInfo?.city}
            onChange={(e) => {
              setNewAddress({ field: 'city', newValue: e.target.value });
            }}
          />
        </div>
        <div style={{ flex: '1' }}>
          <Dropdown
            label='State'
            placeholder='Select State'
            small
            disableBrowserAutofill={true}
            value={SnakeToSentenceCase(
              Object.keys(StateWithNoneEnum).find((stateKey) => {
                return (
                  StateWithNoneEnum[
                    stateKey as keyof typeof StateWithNoneEnum
                  ] === currentAddressInfo?.state
                );
              }) || '',
            )}
            options={Object.keys(StateWithNoneEnum).map((stateKey) => {
              return {
                name: SnakeToSentenceCase(stateKey),
                value:
                  StateWithNoneEnum[stateKey as keyof typeof StateWithNoneEnum],
              };
            })}
            onInputChange={(e: any) => {
              if (e.value) {
                setNewAddress({
                  field: 'state',
                  newValue: e.value as StateWithNoneEnum,
                });
              } else {
                setNewAddress({
                  field: 'state',
                  newValue: undefined,
                });
              }
            }}
          />
        </div>
      </Content>

      <Content flex paddingLeftRight={0} paddingBottom={16}>
        <div style={{ flex: '1' }}>
          <TextField
            label='Postal Code'
            placeholder='12345'
            small
            type='number'
            value={currentAddressInfo?.postalCode}
            onChange={(e) => {
              setNewAddress({ field: 'postalCode', newValue: e.target.value });
            }}
          />
        </div>

        <div style={{ flex: '1' }}>
          <Dropdown
            small
            label='Country'
            placeholder='Select Country'
            disableBrowserAutofill={true}
            value={GetCountryNameUsingAbbreviation(
              currentAddressInfo?.country as CountryEnum,
            )}
            options={Object.keys(CountryEnum).map((countryKey) => {
              return {
                name: SnakeToSentenceCase(countryKey) || '',
                value: CountryEnum[countryKey as keyof typeof CountryEnum],
              };
            })}
            onInputChange={(e: any) => {
              if (e.value) {
                setNewAddress({
                  field: 'country',
                  newValue: e.value as CountryEnum,
                });
              } else {
                setNewAddress({
                  field: 'country',
                  newValue: undefined,
                });
              }
            }}
          />
        </div>
      </Content>
    </>
  );
};
