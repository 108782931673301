import { Text, Card, Content, Heading } from 'component-library';
import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ProductTypeEnum } from '../../../lib/constants';
import {
  MagicBenefitsIcon,
  MagicCredits,
  MagicHYSIcon,
} from '../../../components/icons';

const useStyles = makeStyles(() => ({
  card: {
    padding: '16px 32px',
    maxWidth: '1200px',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    width: 24,
    marginRight: 20,
  },
  gridItem: {
    display: 'flex',
    gap: '16px',
  },
  leftPadding: {
    paddingLeft: '16px',
  },
  rightPadding: {
    paddingRight: '16px',
  },
  circle: {
    width: '100%',
    minWidth: '240px',
  },
  content: {
    display: 'flex',
    gap: '32px',
    alignItems: 'center',
  },
}));

export interface SplashPageCardProps {
  productType: ProductTypeEnum | 'credits';
}

export const SplashPageCard = ({ productType }: SplashPageCardProps) => {
  const classes = useStyles();

  interface SplashCardProps {
    icon: JSX.Element;
    title: string;
    circlePlacement: 'left' | 'right';
    circleImage: JSX.Element;
    body: string;
  }

  const getSplashCardProps = (
    productType: ProductTypeEnum | 'credits',
  ): SplashCardProps => {
    switch (productType) {
      case ProductTypeEnum.HIGH_YIELD_SAVINGS:
        return {
          icon: <MagicHYSIcon color={'gray'} />,
          title: 'High yield accounts',
          circlePlacement: 'left',
          circleImage: getCircle(productType),
          body: getBodyText(productType),
        };
      case ProductTypeEnum.BENEFITS:
        return {
          icon: <MagicBenefitsIcon color={'gray'} />,
          title: 'Benefits',
          circlePlacement: 'left',
          circleImage: getCircle(productType),
          body: getBodyText(productType),
        };
      default:
        return {
          icon: <MagicCredits color={'gray'} />,
          title: 'Government credits',
          circlePlacement: 'right',
          circleImage: getCircle(productType),
          body: getBodyText(productType),
        };
    }
  };

  const getCircle = (productType: ProductTypeEnum | 'credits') => {
    let circlePath = '';
    switch (productType) {
      case ProductTypeEnum.HIGH_YIELD_SAVINGS:
        circlePath = 'high-yield-circle.svg';
        break;
      case ProductTypeEnum.BENEFITS:
        circlePath = 'employee-benefits-circle.svg';
        break;
      default:
        circlePath = 'government-credits-circle.svg';
        break;
    }

    return (
      <img
        className={classes.circle}
        src={`${process.env.PUBLIC_URL}/images/${circlePath}`}
        alt='Icon'
      />
    );
  };

  const getBodyText = (productType: ProductTypeEnum | 'credits') => {
    switch (productType) {
      case ProductTypeEnum.HIGH_YIELD_SAVINGS:
        return `Transfer funds, and you'll accrue returns on your balance of up to 3.02% APY . Earnings are paid out monthly. Your balance is withdrawable anytime. Perfect for that idle cash you’ve got parked in a checking account.`;
      case ProductTypeEnum.VENDOR_MANAGEMENT:
        return `We’ll audit your software spend to find discounts and savings opportunities and even handle any new software negotiations to make sure you’re not overpaying for the tools you need to do your job.`;
      case ProductTypeEnum.BENEFITS:
        return `We'll work with your People or HR team to implement cost-saving and tax-efficient benefits and perks for your team as part of your overall annual compensation process. Your employees save on income tax; you'll save on payroll taxes.`;
      default:
        return `We plug right into your payroll to hunt for federal & state tax credits. We’ll do all the paperwork and you get cash.`;
    }
  };

  const {
    icon,
    title,
    circlePlacement,
    circleImage,
    body,
  } = getSplashCardProps(productType);

  return (
    <Card className={classes.card}>
      <Content>
        <Grid container>
          <Grid item xs={12} className={classes.center}>
            <div className={classes.icon}>{icon}</div>
          </Grid>

          <Grid item xs={12} className={classes.center}>
            <Heading tag='h2' text={title} />
          </Grid>
          <div className={classes.content}>
            <Grid item className={classes.gridItem}>
              {circlePlacement === 'left' ? (
                <Box alignItems='center' justifyContent='left'>
                  {circleImage}
                </Box>
              ) : (
                <Text size={18} className={classes.rightPadding}>
                  {body}
                </Text>
              )}
            </Grid>

            <Grid item className={classes.gridItem}>
              {circlePlacement === 'left' ? (
                <Text size={18} className={classes.leftPadding}>
                  {body}
                </Text>
              ) : (
                <Box alignItems='center' justifyContent='right'>
                  {circleImage}
                </Box>
              )}
            </Grid>
          </div>
        </Grid>
      </Content>
    </Card>
  );
};
