import React from 'react';
import { Text, Dollar } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { CentsToDisplayStringNoSymbol } from 'lib/helpers';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  creditsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '8px',
    '& > img': {
      marginTop: '8px',
      marginRight: '16px',
    },
  },
  creditsIncentives: {
    marginBottom: '15px',
    '& > p': {
      fontSize: '18px !important',
    },
    '& > p:nth-child(2)': {
      fontSize: '16px !important',
    },
  },
  topLevel: {
    '& > p': {
      fontSize: '22px !important',
    },
    '& > p:nth-child(2)': {
      fontSize: '18px !important',
    },
  },
}));

interface IncentiveEstimateProps {
  estimateLow?: number;
  estimateHigh?: number;
  displayName: string | JSX.Element;
  icon: JSX.Element;
  nonEstimateTitle?: string;
  topLevel?: boolean;
  employees?: boolean;
}

export const IncentiveEstimate = ({
  estimateLow,
  estimateHigh,
  displayName,
  icon,
  nonEstimateTitle,
  topLevel,
}: IncentiveEstimateProps) => {
  const classes = useStyles();
  const lowEstimate = estimateLow && CentsToDisplayStringNoSymbol(estimateLow);
  const highEstimate =
    estimateHigh && CentsToDisplayStringNoSymbol(estimateHigh);

  return (
    <div
      className={classes.creditsContainer}
      data-testid={'incentive-estimate'}
    >
      {icon}
      <div
        className={clsx(
          classes.creditsIncentives,
          topLevel && classes.topLevel,
        )}
      >
        <Text size={23} variant='medium'>
          {lowEstimate && highEstimate && (
            <>
              <Dollar variant='medium' value={lowEstimate} tag='span' />–
              <Dollar variant='medium' value={highEstimate} tag='span' />
            </>
          )}
          {nonEstimateTitle && nonEstimateTitle}
        </Text>
        <Text variant='regular-all-caps' size={15}>
          {displayName}
        </Text>
      </div>
    </div>
  );
};
