import { ParsedQuery } from 'query-string';
import { HistoryStore } from 'stores/HistoryStore';

export interface RouteConfiguration {
  /**
   * Uniquely defined path of the route
   */
  path: string | string[];

  /**
   * Function that renders component for the route
   */
  render: (
    matchParams: Record<string, string>,
    query: ParsedQuery,
    history: HistoryStore,
  ) => React.ReactNode;

  /**
   * Determines if path should match exactly with
   * the route defined, defaults to true
   */
  exact?: false;

  /**
   * Determines if the user nav bar should be hidden for
   * the component, default to false (nav bar shown by default)
   */
  hideTopNav?: true;

  /**
   * Determines if the side nav bar should be hidden for the
   * component, defaults to false (sidenav shown by default)
   */
  hideSideNav?: true;

  /**
   * Determines if auth connection should be completed for the
   * component, defaults to false (rarely needed, use authRequired instead)
   */
  authSetupRequired?: true;

  /**
   * Determines if user should be authorized for
   * the component, defaults to true
   */
  authRequired?: false;

  /**
   * Determines if CSRF api tokens should be used for
   * the component, defaults to false
   */
  csrfRequired?: true;

  /**
   * Determines if company should be loaded
   * for the component, defaults to true
   */
  companyRequired?: false;

  /**
   * Determines if feature flags should be pre-loaded
   * for the component, defaults to true
   */
  featureFlagsRequired?: false;

  /**
   * Indicates if the user should be redirected away
   * from this route, defaults to undefined
   */
  redirectPath?: string;

  /**
   * Determines if the component should be allowed to render in small/print
   * screens, defaults to false (browser must be a minimum width to render)
   */
  bypassMobilePrint?: true;

  /**
   * Provide document.title for each route path
   */
  documentPageTitle?: string;
}

/**
 * Defaults for marking a route as not needing any type of authentication (usually landing pages)
 */
export const UNAUTH_ROUTE_CONFIG: Partial<RouteConfiguration> = {
  hideTopNav: true,
  hideSideNav: true,
  authRequired: false,
  companyRequired: false,
  featureFlagsRequired: false,
  bypassMobilePrint: true,
};
