import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from './logging';

export type ErrorReporter = (error: Error, errorInfo: React.ErrorInfo) => void;

const logger = datadogLogs.createLogger('AppErrorBoundary');
const datadogReporter: ErrorReporter = (
  error: Error,
  errorInfo: React.ErrorInfo,
) => {
  logger.error('Unhandled error rendering component', {
    ...logContext({ error }),
    reactErrorInfo: errorInfo,
  });
};

export interface AppErrorBoundaryState {
  errorReporter: ErrorReporter;
}

export interface AppErrorBoundaryProps {
  // Expose the error reporting hook for testing
  errorReporter?: ErrorReporter;
}

/**
 * The top level error boundary for our application. At the top of the application
 * there is not much good we can do here other than report exceptions
 *
 * See https://reactjs.org/docs/error-boundaries.html
 */
export class AppErrorBoundary extends React.Component<
  AppErrorBoundaryProps,
  AppErrorBoundaryState
> {
  constructor(props: AppErrorBoundaryProps) {
    super(props);
    this.state = { errorReporter: this.props.errorReporter || datadogReporter };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.state.errorReporter(error, errorInfo);
    throw errorInfo;
  }

  render() {
    return this.props.children;
  }
}
