import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Text } from 'component-library';
import {
  AccountVerificationStatus,
  BankAccount,
} from '@mainstreet/client-models/financial/banking/bankingModels';
import PlaidConnectBtn from './PlaidConnectBtn';

const useStyles = makeStyles({
  connectedDisplay: {
    display: 'flex',
  },
  connectedIcon: {
    display: 'inline-block',
  },
  connectedDetails: {
    paddingLeft: `8px`,
  },
});

interface ACHAccountDisplayProps {
  account: BankAccount | null;
  isLoading: boolean;
  open: () => void;
}

/**
 * Displays the default bank information in different formats depending
 * on it's verificationStatus.
 *
 * Display Variations:
 *  - Verified:                              Show account info
 *  - No default/expired/failed:             Show Plaid Connect button
 */
export default function ACHAccountDisplay(props: ACHAccountDisplayProps) {
  const classes = useStyles();

  const { account, isLoading, open } = props;

  if (account === null) {
    return <PlaidConnectBtn isLoading={isLoading} open={open} />;
  }

  const createAccountDisplay = (status: AccountVerificationStatus | null) => {
    switch (status) {
      case 'automatically_verified':
      case 'manually_verified':
        return (
          <div
            className={classes.connectedDisplay}
            data-testid='connected-bank-display'
          >
            <img
              src='/images/bank.png'
              className={classes.connectedIcon}
              width={48}
              alt='Bank Icon'
            />
            <div className={classes.connectedDetails}>
              <Text>{account.institutionName}</Text>
              <Text>{`${account.name} •••• ${account.mask}`}</Text>
            </div>
          </div>
        );
      case 'verification_expired':
      case 'verification_failed':
      default:
        return <PlaidConnectBtn isLoading={isLoading} open={open} />;
    }
  };

  return <>{createAccountDisplay(account.verificationStatus)}</>;
}
