import React from 'react';
import { Page } from 'lib/constants';

import { BoirBusinessDetails } from './BoirBusinessDetails';
import { BoirNextSteps } from './BoirNextSteps';

interface AssessmentSurveyRoutesInterface {
  exact?: boolean;
  path?: string;
  component: React.ReactElement | JSX.Element;
}

const useBoirSurveyRoutes = (): {
  AssessmentSurveyRoutes: AssessmentSurveyRoutesInterface[];
} => {
  const boirSurveyRootPath = `/${Page.assessments}/${Page.beneficialOwnershipInformationReport}`;

  return {
    AssessmentSurveyRoutes: [
      {
        path: `${boirSurveyRootPath}/business-details`,
        component: <BoirBusinessDetails />,
      },
      {
        path: `${boirSurveyRootPath}/review`,
        component: <BoirNextSteps />,
      },
    ],
  };
};

export default useBoirSurveyRoutes;
