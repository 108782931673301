import React, { useEffect } from 'react';
import Lottie from 'lottie-web';
import { Animate, Content, Text } from 'component-library';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import { Page } from 'lib/constants';
import { delayAction, FormRedirects } from 'lib/helpers';
import { CompanyContext } from 'pages/CompanyRequired';
import animationData from './Kyc_Success_Animation.json';

const useStyles = makeStyles(() => ({
  page: { height: '100vh' },
}));

export const KycSuccessPage = () => {
  const classes = useStyles();
  const { company } = React.useContext(CompanyContext);
  const [animationLoaded, setAnimationLoaded] = React.useState(false);

  const redirects = new FormRedirects(
    useHistory,
    Page.treasuryManagement,
    Page.dashboard,
  );

  const animationContainer = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    Lottie.loadAnimation({
      container: animationContainer.current as HTMLDivElement,
      animationData,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      name: 'Success',
    });
    setAnimationLoaded(true);
  }, []);

  useEffect(() => {
    if (company.treasuryManagementEnabled && animationLoaded) {
      return delayAction(5000, () => {
        redirects.Forward();
      });
    }
  }, [animationLoaded, company.treasuryManagementEnabled, redirects]);

  return (
    <Content
      alignItems='center'
      className={classes.page}
      flex
      flexDirection='column'
      justifyContent='center'
    >
      <div
        ref={animationContainer}
        style={{ paddingBottom: '208px', paddingLeft: '8px', zIndex: 0 }}
      />

      <div style={{ position: 'absolute', zIndex: 1 }}>
        <Content flex flexDirection='column' alignItems='center' gap={24}>
          <Animate enter={'fade-in'} delay={1.5}>
            <Text text={`We'll email you once everything is verified.`} />
          </Animate>
        </Content>
      </div>
    </Content>
  );
};
