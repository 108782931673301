import { Button, Heading, Text, Color } from 'component-library';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Color.neutral.white,
  },
  logoutButton: {
    marginTop: '18px',
  },
}));

export const AuthenticationFailure = observer(() => {
  const { auth } = useCommonStores();
  const classes = useStyles();

  return (
    <section className={classes.container} data-testid='authentication-failure'>
      <Heading tag='h2' variant='regular'>
        Something went wrong logging in to this email
      </Heading>
      <Text>
        Please log out and make sure you are using the correct email or contact
        support@mainstreet.com for assistance.
      </Text>
      <Button
        label='Logout'
        dataTestId='logout'
        onClick={() => auth.logout()}
        className={classes.logoutButton}
      />
    </section>
  );
});
