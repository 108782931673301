const CardInfo: { [key: string]: CardProps } = {
  visa: {
    display: 'VISA',
    icon: `${process.env.PUBLIC_URL}/images/credit-cards/visa.svg`,
  },
  mastercard: {
    display: 'MasterCard',
    icon: `${process.env.PUBLIC_URL}/images/credit-cards/mastercard.svg`,
  },
  amex: {
    display: 'American Express',
    icon: `${process.env.PUBLIC_URL}/images/credit-cards/amex.svg`,
  },
  discover: {
    display: 'Discover',
    icon: `${process.env.PUBLIC_URL}/images/credit-cards/discover.svg`,
  },
  unionpay: {
    display: 'UnionPay',
    icon: `${process.env.PUBLIC_URL}/images/credit-cards/unionpay.svg`,
  },
  jcb: {
    display: 'JCB',
    icon: `${process.env.PUBLIC_URL}/images/credit-cards/jcb.svg`,
  },
  diners: {
    display: 'Diners Club',
    icon: `${process.env.PUBLIC_URL}/images/credit-cards/diners.svg`,
  },
  unknown: {
    display: '',
    icon: `${process.env.PUBLIC_URL}/images/credit-cards/generic.svg`,
  },
};

export interface CardProps {
  display: string;
  icon: string;
}

export const GetCardInfo = (brand: string): CardProps => {
  return CardInfo[brand];
};
