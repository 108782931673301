import { Text } from 'component-library';
import React from 'react';
import { ExpectedCreditTypeEnum } from '../../../../../../lib/constants';
import { observer } from 'mobx-react';

interface RdRedemptionInfo {
  expectedCreditType: ExpectedCreditTypeEnum;
}

export const RdRedemptionInfo = observer(
  ({ expectedCreditType }: RdRedemptionInfo) => {
    switch (expectedCreditType) {
      case ExpectedCreditTypeEnum.INCOME_TAX:
        return (
          <>
            <Text variant={'medium'} dataTestId='income-tax-text'>
              Redeem your credit when you file income taxes.
            </Text>
            <Text dataTestId='income-tax-text-info'>
              We believe you are eligible for an income tax credit. Your credit
              will reduce your income taxes when you file your annual return.
            </Text>
          </>
        );
      case ExpectedCreditTypeEnum.PAYROLL_TAX:
        return (
          <>
            <Text variant={'medium'} dataTestId='payroll-tax-text'>
              Start redeeming credit one quarter after filing your income tax
              return.
            </Text>
            <Text dataTestId='payroll-tax-text-info'>
              We believe you are eligible for a payroll tax credit. Your credit
              will reduce or refund your payroll taxes.
            </Text>
          </>
        );
      case ExpectedCreditTypeEnum.DEFERRED_INCOME:
        return (
          <>
            <Text variant={'medium'} dataTestId='deferred-income-text'>
              Redeem your credit when you owe income taxes.
            </Text>
            <Text dataTestId='deferred-income-text-info'>
              We believe you are eligible for a deferred income tax credit. Your
              credit will reduce future income taxes, and is an asset for
              fundraising or acquisition purposes in the meantime.
            </Text>
          </>
        );
      default:
        return <></>;
    }
  },
);
