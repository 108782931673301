import { useMediaQuery } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useCommonStores } from 'stores/useStores';

export const MediaQueries = observer(() => {
  const { app } = useCommonStores();

  const isLargeDesktop = useMediaQuery('(max-width:1440px)', { noSsr: true });
  const isDesktop = useMediaQuery('(max-width:1280px)', { noSsr: true });
  const isSmallDesktop = useMediaQuery('(max-width:1024px)', { noSsr: true });
  const isTablet = useMediaQuery('(max-width:960px)', { noSsr: true });
  const isMobile = useMediaQuery('(max-width:600px)', { noSsr: true });
  const isPrint = useMediaQuery('@media print', { noSsr: true });

  // media query by width
  const isDesktop1440 = useMediaQuery('(max-width:1440px)', { noSsr: true });
  const isDesktop1280 = useMediaQuery('(max-width:1280px)', { noSsr: true });
  const isDesktop1024 = useMediaQuery('(max-width:1024px)', { noSsr: true });
  const isTablet960 = useMediaQuery('(max-width:960px)', { noSsr: true });
  const isTablet800 = useMediaQuery('(max-width:800px)', { noSsr: true });
  const isMobile640 = useMediaQuery('(max-width:640px)', { noSsr: true });
  const isMobile520 = useMediaQuery('(max-width:520px)', { noSsr: true });
  const isMobile400 = useMediaQuery('(max-width:400px)', { noSsr: true });

  useEffect(() => {
    app.toggleMediaQueries(
      isLargeDesktop,
      isDesktop,
      isSmallDesktop,
      isTablet,
      isMobile,
      isPrint,
      isDesktop1440,
      isDesktop1280,
      isDesktop1024,
      isTablet960,
      isTablet800,
      isMobile640,
      isMobile520,
      isMobile400,
    );
  }, [
    isLargeDesktop,
    isDesktop,
    isSmallDesktop,
    isTablet,
    isMobile,
    isPrint,
    isDesktop1440,
    isDesktop1280,
    isDesktop1024,
    isTablet960,
    isTablet800,
    isMobile640,
    isMobile520,
    isMobile400,
    app,
  ]);

  return <></>;
});
