import React, { useEffect, useContext, Dispatch, SetStateAction } from 'react';
import {
  Text,
  Color,
  DropdownOption,
  Toast,
  Divider,
  Spinner,
  Content,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { IPaymentMethodState, SelectionStatus } from 'components/PaymentMethod';
import { MSPlaidClientContext } from 'lib/financial/MSPlaidClientProvider';
import CreditCardSelect from './CreditCardSelect';
import CardDisplay from './CardDisplay';
import useCreditCard from './useCreditCard';
import AddNewCard from './AddNewCard';
import CCButtons from './CCButtons';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
  },
  saveButton: {
    padding: '8px 24px',
    minHeight: '50px',
    fontSize: '18px',
    fontWeight: 500,
    textTransform: 'initial',
  },
  error: {
    color: Color.semantic.$error50,
  },
  userMessage: {
    color: Color.blue._20,
  },
});

interface CreditCardContainerProps {
  stripeCustomerId: string | null | undefined;
  selectionStatus: SelectionStatus;
  setPaymentMethodState: Dispatch<SetStateAction<IPaymentMethodState>>;
  addNewCard: boolean;
  setDefaultIsLoading: boolean;
}

export default function CreditCardContainer(props: CreditCardContainerProps) {
  const classes = useStyles();
  /* Required actions to set default payment method and check if card is current default payment method */
  const { state: plaidClientState, actions: plaidClientActions } = useContext(
    MSPlaidClientContext,
  );

  /* Required setter to swap between default and selecting views while viewing credit cards */
  const { setPaymentMethodState } = props;

  /* State and methods required for saving new cards and managing existing cards*/
  const {
    handleSaveCard,
    addNewCardState,
    stripe,
    setCreditCardState,
    setAddNewCardState,
    creditCardState,
    getAllPaymentCards,
  } = useCreditCard({
    stripeCustomerId: props.stripeCustomerId,
    setDefaultPaymentMethod: plaidClientActions.setDefaultPaymentMethod,
    setPaymentMethodState,
  });

  /* Saves selected card ID in state, used by CreditCardSelect component */
  const handleSelectCard = (account: string | DropdownOption) => {
    if (typeof account !== 'string' && account?.value) {
      setCreditCardState({ ...creditCardState, selectedCardId: account.value });
    }
  };

  /* Get all existing connected cards from stripe on load */
  useEffect(() => {
    getAllPaymentCards();
  }, [getAllPaymentCards]);

  /* 
    If no card is set as default payment method and connected cards exist,
    render the select dropdown view by default, otherwise render the default
    view.
  */
  const associatedCard =
    plaidClientState.defaultPaymentMethod?.associatedCard || null;
  useEffect(() => {
    if (
      creditCardState.connectedCards.length > 0 &&
      !plaidClientState.defaultPaymentMethod?.associatedCard
    ) {
      setPaymentMethodState((prev) => ({
        ...prev,
        selectionStatus: 'selecting',
      }));
    } else {
      setPaymentMethodState((prev) => ({
        ...prev,
        selectionStatus: 'default',
      }));
    }
  }, [
    creditCardState.connectedCards.length,
    associatedCard,
    plaidClientState.defaultPaymentMethod,
    setPaymentMethodState,
  ]);

  if (creditCardState.cardsIsLoading) {
    return (
      <Content flex justifyContent='center'>
        <Spinner />
      </Content>
    );
  }

  /* Get current default payment method if it's a card */
  const defaultPaymentCard =
    plaidClientState.defaultPaymentMethod?.associatedCard;

  const stripeIsSet = stripe !== null;

  const saveDefaultPaymentMethod = async () => {
    setPaymentMethodState((prev) => ({
      ...prev,
      setDefaultIsLoading: true,
    }));
    const res = await plaidClientActions.setDefaultPaymentMethod(
      null,
      creditCardState.selectedCardId || null,
    );

    if (res?.errorMsg) {
      setAddNewCardState({ ...addNewCardState, formError: res.errorMsg });
      return;
    }
    setPaymentMethodState((prev) => ({
      ...prev,
      selectionStatus: 'default',
      setDefaultIsLoading: false,
    }));
  };

  return (
    <>
      <Text color={Color.neutral._80} paddingBottom={16}>
        This card will be used for all billing purposes.
      </Text>

      {/* If status is default and no current credit cards */}
      {props.selectionStatus === 'default' && !defaultPaymentCard && (
        <AddNewCard
          userMessage={addNewCardState.userMessage}
          stripe={stripeIsSet}
          cardSubmitted={addNewCardState.cardSubmitted}
          handleSaveCard={handleSaveCard}
          saveBtnStyle={classes.saveButton}
          setDefaultIsLoading={props.setDefaultIsLoading}
        />
      )}

      {/* Show default payment card if it is currently assigned as default payment method */}
      {props.selectionStatus === 'default' && defaultPaymentCard && (
        <CardDisplay card={defaultPaymentCard} />
      )}

      {props.selectionStatus === 'selecting' && (
        <>
          {props.addNewCard ? (
            <AddNewCard
              userMessage={addNewCardState.userMessage}
              stripe={stripeIsSet}
              cardSubmitted={addNewCardState.cardSubmitted}
              handleSaveCard={handleSaveCard}
              saveBtnStyle={classes.saveButton}
              setDefaultIsLoading={props.setDefaultIsLoading}
            />
          ) : (
            <CreditCardSelect
              connectedCards={creditCardState.connectedCards}
              handleSelectCard={handleSelectCard}
            />
          )}
          <Divider color='white' />
          <div className={classes.buttonContainer}>
            {!props.addNewCard && (
              <CCButtons
                selectedCardId={creditCardState.selectedCardId}
                saveDefaultPaymentMethod={saveDefaultPaymentMethod}
                setPaymentMethodState={setPaymentMethodState}
                setDefaultIsLoading={props.setDefaultIsLoading}
              />
            )}
          </div>
        </>
      )}

      {creditCardState.errorMsg && (
        <Toast
          setToast={() => []}
          toasts={[{ text: creditCardState.errorMsg, status: 'error' }]}
        />
      )}
      {addNewCardState.formSuccess && (
        <Toast
          setToast={() => []}
          toasts={[{ text: addNewCardState.formSuccess, status: 'success' }]}
        />
      )}
    </>
  );
}
