import React from 'react';
import { Text, Color, Flex, Image, Link } from 'component-library';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    borderLeft: `1px solid ${Color.neutral._20}`,
  },
});

export const Disqualified = () => {
  const classes = useStyles();
  return (
    <Flex direction='row' padding={[0, 8]} className={classes.container}>
      <Flex.Cell alignSelf={'center'} padding={16}>
        <Image
          src={`${process.env.PUBLIC_URL}/images/dashboard/savings-module/disqualified.svg`}
          alt='savings-disqualified'
          dataTestId='savings-disqualified-image'
        />
      </Flex.Cell>
      <Flex.Cell padding={24} alignSelf={'center'}>
        <Text
          variant='medium'
          size={18}
          color={Color.neutral._90}
          text='Your business does not qualify for our Tax Credits service'
        />
        <Text paddingBottom={16} size={15}>
          <Text
            color={Color.neutral._60}
            tag={'span'}
            text='We will keep looking for opportunities and we’ll let you know if anything changes. If you think this was a mistake, please contact our team at '
          />
          <Link omitIcon target='_blank' href='mailto:sales@mainstreet.com'>
            sales@mainstreet.com.
          </Link>
        </Text>
      </Flex.Cell>
    </Flex>
  );
};
