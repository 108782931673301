import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  makeStyles,
  Button,
  Theme,
  TextField,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import { useCompany } from 'stores/useStores';
import { ValidateEmail } from 'lib/validation';
import {
  Login,
  GenerateResetPasswordEmail,
  LoginWithGoogle,
} from 'services/server';
import { Page } from 'lib/constants';
import { DividerWithText } from 'components/DividerWithText';
import GoogleButton from 'react-google-button';
import { Color } from 'component-library';
import { useFeatureFlags } from 'stores/useStores';
import { observer } from 'mobx-react';

const useStyles = makeStyles(({ breakpoints, helpText }: Theme) => ({
  root: {
    background: Color.neutral.white,
  },
  box: {
    boxShadow: Color.shadow.low,
    background: Color.neutral.white,
    border: `1px solid ${Color.neutral._20}`,
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    alignItems: 'center',
  },
  watermark: {
    width: '250px',
    height: '34px',
    textAlign: 'center',
    color: '#888',
    marginTop: '40px',
    marginBottom: '20px',
  },
  textField: {
    marginTop: '20px',
    width: '320px',
    [breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  cta: {
    marginTop: '20px',
    width: '320px',
    height: '45px',
    textTransform: 'initial',
    fontWeight: 500,
    fontSize: '18px',
    [breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  backToLoginCta: {
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  toggleTextContainer: {
    width: '80%',
  },
  toggleText: {
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bottomTextBox: {
    color: helpText.color,
    fontWeight: helpText.weight,
    marginTop: '80px',
    fontSize: '14px',
    letterSpacing: '.50px',
    textAlign: 'center',
  },
  bottomLinkText: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:visited': {
      color: helpText.color,
    },
    '&:link': {
      color: helpText.color,
    },
  },
  resetPassConfText: {
    marginTop: '10px',
  },
  error: {
    marginTop: '10px',
  },
  googleButton: {
    margin: '40px auto',
    backgroundColor: '#1F6AB0 !important',
  },
}));

interface LoginWidgetProps {
  error: string | null;
}

export const LoginWidget = observer(({ error }: LoginWidgetProps) => {
  const classes = useStyles();
  const { company } = useCompany();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [resetPassConfText, setResetPassConfText] = useState<string>('');
  const [formError, setFormError] = useState<string>(error || '');
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [referrer, setReferrer] = useState<string>('');
  const location = useLocation();
  const featureFlags = useFeatureFlags();
  const showNewMSLogo = featureFlags.showNewMainStreetLogo;

  const logoUrl = showNewMSLogo
    ? `${process.env.PUBLIC_URL}/images/logo/mst-logo-green.svg`
    : `${process.env.PUBLIC_URL}/images/MainSt_WordmarkGreen.svg`;

  useEffect(() => {
    const referrer = location.state && (location.state as any).referrer;
    if (referrer) setReferrer(referrer);
  }, [location.state]);

  const clearMessages = () => {
    setFormError('');
    setResetPassConfText('');
  };

  const submit = () => {
    if (!email || !ValidateEmail(email) || !password) {
      setFormError('Please enter a valid email and password.');
      return;
    }
    setLoading(true);
    Login(email, password).then((res) => {
      setLoading(false);
      clearMessages();
      if (res.errorMsg) {
        setFormError(res.errorMsg);
        return;
      }

      if (referrer && !referrer.includes(Page.logout)) {
        window.location.href = referrer;
        return;
      }

      if (res.data?.company) {
        company.mergeData(res.data.company);
      }
    });
  };

  const forgotPassword = async () => {
    setLoading(true);
    GenerateResetPasswordEmail(email).then((res) => {
      setLoading(false);
      clearMessages();
      setEmail('');
      if (res.errorMsg) {
        setFormError(res.errorMsg);
        return;
      }

      setResetPassConfText(
        'Please check your email for a link to reset your password.',
      );
    });
  };

  const backToLogin = () => {
    setShowForgotPassword(false);
    setResetPassConfText('');
  };

  const submitOnEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (showForgotPassword) {
        forgotPassword();
      } else {
        submit();
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <img
          className={classes.watermark}
          src={logoUrl}
          alt='MainStreet watermark'
          width='160px'
        />

        {resetPassConfText ? (
          <div>
            <Typography
              component='div'
              variant='body1'
              className={classes.resetPassConfText}
            >
              {resetPassConfText}
            </Typography>
            <Typography
              component='div'
              variant='caption'
              className={classes.backToLoginCta}
              onClick={backToLogin}
            >
              Back to Login
            </Typography>
          </div>
        ) : (
          <>
            <GoogleButton
              className={classes.googleButton}
              onClick={LoginWithGoogle}
            />
            <DividerWithText>or</DividerWithText>
            <TextField
              variant='outlined'
              className={classes.textField}
              label='Email'
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              onKeyDown={submitOnEnter}
            />
            {!showForgotPassword && (
              <TextField
                variant='outlined'
                className={classes.textField}
                label='Password'
                value={password}
                type='password'
                onChange={(event) => setPassword(event.target.value)}
                onKeyDown={submitOnEnter}
              />
            )}
            {showForgotPassword ? (
              <Button
                color='secondary'
                variant='contained'
                className={classes.cta}
                onClick={forgotPassword}
                disabled={loading}
              >
                {loading ? 'Getting Link...' : 'Get reset link'}
              </Button>
            ) : (
              <Button
                color='secondary'
                variant='contained'
                className={classes.cta}
                onClick={submit}
                disabled={loading}
              >
                {loading ? 'Signing in...' : 'Sign in'}
              </Button>
            )}
            {formError && (
              <Typography component='div' variant='caption'>
                <FormHelperText error className={classes.error}>
                  {formError}
                </FormHelperText>
              </Typography>
            )}

            <div className={classes.toggleTextContainer}>
              <Typography
                component='div'
                variant='caption'
                className={classes.toggleText}
                onClick={() => {
                  clearMessages();
                  setPassword('');
                  setShowForgotPassword(!showForgotPassword);
                }}
              >
                {showForgotPassword
                  ? 'Back to sign in'
                  : 'Forgot your password?'}
              </Typography>
            </div>
          </>
        )}
      </div>
      <div className={classes.bottomTextBox}>
        <a
          className={classes.bottomLinkText}
          href='/terms-of-service'
          target='_blank'
          rel='noopener noreferrer'
        >
          Terms
        </a>{' '}
        and{' '}
        <a
          className={classes.bottomLinkText}
          href='/privacy'
          target='_blank'
          rel='noopener noreferrer'
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
});
