/**
 * If the REACT_APP_AUTH0_ENABLED env is set, parse it as a flag
 */
const checkAuth0ProcessEnv = () => {
  const toLowerCase = process.env.REACT_APP_AUTH0_ENABLED?.trim().toLowerCase();
  switch (toLowerCase) {
    // default to enabled
    case undefined:
    case 'true':
      return true;

    case 'false':
      return false;

    default:
      throw new Error(
        `Invalid value for env REACT_APP_AUTH0_ENABLED: ${toLowerCase}. Can be true | false`,
      );
  }
};

/**
 * @deprecated these constants should not be used globally, instead
 * reference the AuthStore in the common namespace
 */
export default {
  auth0Enabled: checkAuth0ProcessEnv,
  logoutUrl: process.env.REACT_APP_AUTH0_CUSTOMER_LOGOUT_URL,
  redirectUri: process.env.REACT_APP_AUTH0_CUSTOMER_REDIRECT_URI,
  audience: process.env.REACT_APP_AUTH0_CUSTOMER_AUDIENCE,
  auth0Domain: process.env.REACT_APP_AUTH0_CUSTOMER_DOMAIN,
  auth0ClientId: process.env.REACT_APP_AUTH0_CUSTOMER_CLIENT_ID,

  // TODO: this *must* be the same in all environments
  realm: 'Auth0-Hosted-DB',

  // TODO: this will need to change once we have more complex permissions
  scope: 'openid name email nickname read:all write:all',
};
