import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Color, Content, Text } from 'component-library';
import {
  CmsAnswerData,
  CmsQuestionData,
  GraphCMSAnswerTypeEnum,
  SurveyResponsesType,
} from 'lib/interfaces';

const useStyles = makeStyles(() => ({
  button: {
    width: '88px',
    marginRight: '16px',
  },
}));

type SurveyQuestionProps = {
  questionData?: CmsQuestionData;
  graphCMSAnswers: SurveyResponsesType;
  setGraphCMSAnswers: React.Dispatch<React.SetStateAction<SurveyResponsesType>>;
};

export const SurveyQuestion = ({
  questionData,
  graphCMSAnswers,
  setGraphCMSAnswers,
}: SurveyQuestionProps) => {
  const classes = useStyles();
  const { answerIDs, answerType, id = '', subtitle, text } = questionData || {};

  return (
    <Content flex flexDirection='column' gap={8} paddingLeftRight={24}>
      <Content>
        <Text size={15} variant='medium'>
          {text}
        </Text>
        <Text size={15} color={Color.neutral._80}>
          {subtitle}
        </Text>
      </Content>
      {answerType === GraphCMSAnswerTypeEnum.YES_OR_NO && (
        <Content flex paddingTopBottom={0} paddingLeftRight={0}>
          {answerIDs?.map((answerDatum) => (
            <Button
              label={answerDatum.text}
              onClick={() => {
                setGraphCMSAnswers({
                  ...graphCMSAnswers,
                  [id]: answerDatum,
                });
              }}
              variant='outlined'
              key={answerDatum.id}
              className={classes.button}
              selected={
                (graphCMSAnswers?.[id] as CmsAnswerData)?.id === answerDatum?.id
              }
            />
          ))}
        </Content>
      )}
    </Content>
  );
};
