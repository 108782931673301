import { CompanyAccessToken } from './constants';

export const getAccessTokenFromUrl = (): CompanyAccessToken | undefined => {
  const queryParams: URLSearchParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const adminEmail = queryParams.get('company');
  const scope = location.pathname;
  if (token && adminEmail && scope) {
    return {
      token,
      adminEmail,
      scope,
    };
  }
};

// update the getToken to make a call to the db to check if the token is expired
