import React from 'react';
import { Button, Flex, Link, Text } from 'component-library';
import DeskCalendar from 'components/icons/DeskCalendar';

interface Props {
  onComplete: () => void;
}

export const FinishedStepPayrollTax = ({ onComplete }: Props) => {
  return (
    <Flex direction='column' alignItems='center' padding={[16, 48, 48, 48]}>
      <Flex direction='column' alignItems='center' padding={[0, 0, 16, 0]}>
        <DeskCalendar />
      </Flex>
      <Text
        variant='medium'
        textAlign='center'
        text='Start redeeming your credits next quarter with your payroll taxes!'
        paddingBottom={8}
      />
      <Text
        textAlign='center'
        text={`We’ll notify you to download and file Form 8974, 2-4 weeks before it's due.`}
        paddingBottom={8}
      />
      <Flex direction='column' alignItems='center' padding={16}>
        <Button
          small
          label={'Next steps'}
          onClick={() => onComplete()}
          dataTestId='redeeming-done-button'
        />
      </Flex>
      <Link
        size={13}
        external
        text={'How do payroll tax credits work'}
        href={
          'https://mainstreet-help.force.com/help/s/article/Final-Steps-To-Claim-the-R-D-Tax-Credit-Toward-Payroll'
        }
        dataTestId='redeeming-link'
      />
    </Flex>
  );
};
