import React, { useEffect, useMemo, useState } from 'react';
import {
  Animate,
  AnswerType,
  AnswerValueType,
  Flex,
  Spinner,
  SurveyQuestion,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import {
  AttestationEventTypeEnum,
  Page,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationModalStatusEnum,
  SurveyNameEnum,
} from 'lib/constants';
import { CmsQuestionData } from 'lib/interfaces';
import {
  useCommonStores,
  useCompany,
  useDashboardStores,
  useFeatureFlags,
  useLegacyClients,
  useTaxCreditsStores,
} from 'stores/useStores';
import _ from 'lodash';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import { QualificationStatusModal } from '../../../retirement-plan/components';
import { useEffectOnce } from 'component-library/_helpers/use-utils';
import { PrefillAlert } from '../../components/PrefillAlert';
import {
  AccumulatedModal,
  IncompleteProgramSurveyModal,
  SubmitForReviewModal,
} from '../../components';
import { SurveyAttestation } from 'pages/tax-processing/expense-classification/components/';
import { useExpenseClassificationAnswerPrefill } from 'lib/useExpenseClassificationAnswerPrefill';
import { UnifiedCreditPaymentModal } from '../../../../../../components/payments/UnifiedCreditPaymentModal';
import { buildCommonHandlers, handleContinueText } from '../commonHandlers';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    margin: '0 auto',
    position: 'relative',
  },
}));

interface UnifiedDisabledAccessCreditProps {
  onNext: () => void;
  onBack: () => void;
  taxYear: number;
}

export const UnifiedDisabledAccessCredit: React.FC<UnifiedDisabledAccessCreditProps> =
  observer(({ onNext, onBack, taxYear }) => {
    const classes = useStyles();
    const [showDqModal, setShowDqModal] = useState<boolean>(false);
    const { app, auth, chatbot, companyStore } = useCommonStores();
    const { surveyFlow, unifiedTaxCredits } = useTaxCreditsStores();
    const { company } = useCompany();
    const [hasConfirmedAnswers, setHasConfirmedAnswers] =
      useState<boolean>(false);
    const [validAnswer, setValidAnswer] = useState<boolean>(false);
    const featureFlags = useFeatureFlags();
    const { client } = useLegacyClients();
    const { modules } = useDashboardStores();
    const program =
      company.programs.find(
        (item) =>
          item.taxYear === taxYear && item.name === ProgramNameEnum.FED_RD_TAX,
      ) || null;
    const surveyProgram = company.programs.find(
      (p) =>
        p.taxYear === taxYear && p.name === ProgramNameEnum.FED_DISABLED_ACCESS,
    );
    const allSurveysViewed = useMemo(() => {
      return companyStore.company.programs
        .filter((program) => program.taxYear === taxYear)
        .every(
          (program) =>
            program.subStage ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED ||
            program.subStage ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT ||
            (program.subStage === null &&
              program.stage === ProgramStageEnum.DISQUALIFIED),
        );
    }, [companyStore.company.programs, taxYear]);

    const surveyName: SurveyNameEnum =
      SurveyNameEnum.UNIFIED_DISABLED_ACCESS_CREDIT_SURVEY;

    const [reInitCompanyQualQuestions, setReInitCompanyQualQuestions] =
      useState(0);

    const {
      isSurveyComplete,
      isLoading,
      surveyAnswers,
      questionsToRender,
      addSurveyAnswers,
      saveAnswers,
      addSurveyAnswersWithoutRerender,
    } = useSurveyQuestions(
      surveyName,
      taxYear,
      undefined,
      undefined,
      reInitCompanyQualQuestions,
    );

    // Prefill answers from other EC/Qual questions
    useExpenseClassificationAnswerPrefill(
      surveyName,
      program,
      addSurveyAnswersWithoutRerender,
    );

    useEffectOnce(async () => {
      // create program if doesn't exist
      await surveyFlow.createTaxCreditProgram(
        taxYear,
        ProgramNameEnum.FED_DISABLED_ACCESS,
      );
      // update program stage to expense_classification onload
      await unifiedTaxCredits.updateProgramStageOnSurveyEntry(
        taxYear,
        ProgramNameEnum.FED_DISABLED_ACCESS,
      );
    });

    useEffect(() => {
      // scroll page down as user answers each question
      scrollIntoViewOnKeyDown();
      // checking survey answers to display qualification modal
      surveyFlow.checkQualificationStatus(surveyAnswers);

      const hasDisableAccessDQ = surveyFlow.allQualificationStatuses.find(
        (status) =>
          status.status === QualificationModalStatusEnum.DISABLED_ACCESS_DQ,
      );

      if (hasDisableAccessDQ) {
        setShowDqModal(true);
      } else {
        setShowDqModal(false);
      }
    }, [surveyFlow, surveyAnswers]);

    useEffectOnce(async () => {
      if (featureFlags.showShareAssessment) {
        unifiedTaxCredits.setShareAssessmentOnNext(onNext);
      }

      await client.SetYeaPrefill();
      await app.common.companyStore.refreshCurrentCompany();
      setReInitCompanyQualQuestions(
        (reInitCompanyQualQuestions) => reInitCompanyQualQuestions + 1,
      );
    });

    const handleShowSubmitForReview = () => {
      unifiedTaxCredits.handleShowSubmitForReview(
        taxYear,
        companyStore.company,
        () => modules.toggleShowUnifiedCreditPaymentModal(true),
        true,
      );
    };

    const { handleOnContinue, disqualifyProgram } = buildCommonHandlers(
      surveyFlow,
      unifiedTaxCredits,
      companyStore,
      auth,
      modules,
      app,
      client,
      taxYear,
      surveyProgram,
      QualificationModalStatusEnum.DISABLED_ACCESS_DQ,
      AttestationEventTypeEnum.YEAR_END_ASSESSMENT_DISABLED_ACCESS,
      hasConfirmedAnswers,
      featureFlags.showSurveyReviewPage,
      featureFlags.saveYeaSurveyAttestation,
      onNext,
    );

    const handleAnswerChange = (
      question: CmsQuestionData,
      answerValue: AnswerValueType,
      key: string,
    ) => {
      setValidAnswer(answerValue > 0);
      question.answerValue = answerValue;
      addSurveyAnswers(question.id, key, [
        { questionId: question.id, answerValue: answerValue },
      ]);
      // save answers per question answered
      if (
        question.answerType === 'yes_or_no' ||
        question.answerType === 'multiple_choice'
      ) {
        saveAnswers && saveAnswers();
      }
    };

    const handleOnDisqualified = async () => {
      surveyFlow.setModalContinueIsLoading(true);

      await disqualifyProgram();

      if (featureFlags.showSurveyReviewPage) {
        onNext();
      } else {
        await unifiedTaxCredits.checkLatestEstimatesInSection(
          taxYear,
          ProgramNameEnum.FED_DISABLED_ACCESS,
        );

        handleShowSubmitForReview();
        surveyFlow.setShowQualificationStatusModal(false);
        surveyFlow.setModalContinueIsLoading(false);
        // clear qualification statuses
        surveyFlow.qualificationStatus = null;
        if (unifiedTaxCredits.areAllAssessmentsDisqualified(taxYear)) {
          app.history.push(`/${Page.taxCredits}`);
        }
      }
    };

    const continueText = handleContinueText(
      companyStore.accessToken,
      allSurveysViewed,
      'Continue',
    );

    const scrollIntoViewOnKeyDown = () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    };

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => {
          return (
            <Animate
              key={question.id}
              enter={['fade-in', 'from-bottom']}
              duration={0.5}
            >
              <SurveyQuestion
                dataTestId={question.id}
                answerType={question.answerType as AnswerType}
                text={question.text}
                subtitle={question.subtitle}
                answerOptions={question.answerIDs}
                onChange={(answer) => handleAnswerChange(question, answer, key)}
                onBlur={() => saveAnswers && saveAnswers()}
                placeholder={question.placeholder}
                answerValue={question.answerValue}
                tooltip={question.tooltip}
                questionTextSize={app.isMobile ? 15 : 18}
                questionSubtitleSize={app.isMobile ? 13 : 15}
                withCardMargin={false}
                helpLinkText={question.helperText}
                chatBotQuestion={chatbot.isAiQuestionEnabled(
                  question.aiQuestion,
                )}
                chatBotPrompt={() =>
                  chatbot.autoSendAiMessage(question.aiQuestion)
                }
                onHelpLinkClick='https://mainstreet1.my.site.com/help/s/article/Eligible-Costs-for-Disabled-Access-Tax-Credit'
              />
            </Animate>
          );
        });
      });

    return (
      <Flex
        direction='column'
        className={classes.root}
        data-testid={'unified-disabled-access-credit'}
      >
        <Flex className={classes.mainContent} direction='column'>
          <Animate enter={'fade-in'}>
            <SurveyFlowContainer
              title='Disabled Access Tax Credit'
              onContinue={handleOnContinue}
              isDisabled={
                !isSurveyComplete ||
                (featureFlags.saveYeaSurveyAttestation &&
                  !hasConfirmedAnswers &&
                  !validAnswer)
              }
              isLoading={surveyFlow.surveyContinueLoading}
              continueText={continueText}
              currentPage={Page.disabledAccess}
              onBack={companyStore.accessToken ? undefined : onBack}
              onSkip={() =>
                surveyFlow.skipSurveyStep(
                  taxYear,
                  program?.id ?? -1,
                  ProgramNameEnum.FED_DISABLED_ACCESS,
                  onNext,
                )
              }
            >
              <Flex direction='column' gap={24}>
                <PrefillAlert />
                {isLoading ? (
                  <Spinner size='small' />
                ) : (
                  !_.isEmpty(questionsToRender) && renderQuestions()
                )}
                {showDqModal && (
                  <QualificationStatusModal
                    showModal={surveyFlow.showQualificationStatusModal}
                    modalOnClick={async () => {
                      await handleOnDisqualified();
                    }}
                    programName={ProgramNameEnum.FED_DISABLED_ACCESS}
                  />
                )}
                {featureFlags.saveYeaSurveyAttestation && (
                  <SurveyAttestation
                    checked={hasConfirmedAnswers}
                    onAttestation={() =>
                      setHasConfirmedAnswers(!hasConfirmedAnswers)
                    }
                  />
                )}
              </Flex>
            </SurveyFlowContainer>
          </Animate>
        </Flex>
        {!featureFlags.showSurveyReviewPage && (
          <>
            <SubmitForReviewModal taxYear={taxYear} onNext={onNext} />
            <UnifiedCreditPaymentModal
              taxYear={taxYear}
              onNext={() => unifiedTaxCredits.setShowSubmitReviewModal(true)}
            />
            {unifiedTaxCredits.assessmentByYear[taxYear] && (
              <IncompleteProgramSurveyModal
                taxYear={taxYear}
                assessments={unifiedTaxCredits.assessmentByYear[taxYear]}
              />
            )}
          </>
        )}
        <AccumulatedModal
          programName='Disabled Access'
          subtitle="Let's see how much you stand to claim across all your credits."
        />
      </Flex>
    );
  });
