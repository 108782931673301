import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Content, Text, Modal } from 'component-library';
import * as React from 'react';
import { ImportType, PayrollSystem } from '../../../../../../lib/interfaces';
import { useLocation } from 'react-router-dom';
import {
  RIPPLING_IMPORT_TYPE_KEY,
  RIPPLING_REDIRECT_PATHNAME,
  RIPPLING_REDIRECT_SEARCH,
  RIPPLING_TAX_YEAR_KEY,
} from '../../../../../../lib/constants';

const useStyles = makeStyles(() => ({
  connectContent: {
    padding: '24px 0 0',
    display: 'flex',
    gap: '16px',
  },
}));

interface RipplingConnectProps {
  moveToNextStep?: (option?: PayrollSystem) => Promise<void>;
  importType: ImportType;
  taxYear?: number;
}

export const RipplingConnect = ({
  moveToNextStep,
  importType,
  taxYear,
}: RipplingConnectProps) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState<boolean>(false);

  const ripplingAppListing =
    process.env.REACT_APP_RIPPLING_APP_LISTING ||
    'https://app.ripplingsandbox.com/apps/PLATFORM/MainStreet';

  const location = useLocation();

  useEffect(() => {
    // Set type and taxYear, which then get loaded in PayrollRipplingIntegration after the user is redirected to Rippling's website then returns to MainStreet.
    localStorage.setItem(RIPPLING_IMPORT_TYPE_KEY, importType);
    localStorage.setItem(
      RIPPLING_TAX_YEAR_KEY,
      taxYear ? taxYear.toString() : '',
    );
    // Set the redirect URL, so we know where to bring the user back to after they connect rippling.
    localStorage.setItem(RIPPLING_REDIRECT_PATHNAME, location.pathname);
    localStorage.setItem(RIPPLING_REDIRECT_SEARCH, location.search);
  }, [importType, taxYear, location.pathname, location.search]);

  return (
    <div className={classes.connectContent} data-testid='rippling-connect'>
      <Button label={`Connect`} onClick={() => setShowModal(true)} />
      {moveToNextStep && (
        <Button
          label='Do this later'
          variant={'outlined'}
          data-testid='payroll_connect_later_rippling'
          onClick={() =>
            moveToNextStep({ id: 'rippling', displayName: 'Rippling' })
          }
        />
      )}
      <Modal
        showModal={showModal}
        closeToggle={() => setShowModal(false)}
        backdrop={'static'}
      >
        <>
          <Content paddingTopBottom={24} paddingLeftRight={24}>
            <Text
              variant='medium'
              size={18}
              text="Finish the process on Rippling's platform"
            />
            <Text text="You'll need to authenticate as an administrator within the Rippling" />
            <Text text="platform and grant permissions to Mainstreet. You'll be redirected" />
            <Text text='back to MainStreet after this.' />
          </Content>
          <Content gap={16} flex paddingLeftRight={24}>
            <Button
              label="Finish process on Rippling's platform"
              onClick={{
                location: ripplingAppListing,
                external: true,
                target: '_self',
              }}
            />
            <Button
              variant='outlined'
              label='Cancel'
              onClick={() => setShowModal(false)}
            />
          </Content>
        </>
      </Modal>
    </div>
  );
};
