import React, { useEffect, useState } from 'react';
import { DashboardCard } from 'pages/dashboard/components/DashboardCard';
import { Loading } from 'components/util/Loading';
import { FormHelperText, makeStyles } from '@material-ui/core';
import { GetOrdersByYear } from 'services/server';
import { OrderForm } from 'lib/interfaces';
import { Page, ProgramNameEnum, Programs } from 'lib/constants';
import {
  CentsToDisplayStringNoSymbol,
  IsDisplayableOrderForm,
  IsStateRDCreditProgram,
} from 'lib/helpers';
import { Heading } from 'component-library';
import { useCommonStores, useHistoryStore } from 'stores/useStores';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';

const useStyles = makeStyles(({ breakpoints }) => ({
  '@global': {
    [breakpoints.down('xs')]: {
      '.embeddedServiceHelpButton': {
        display: 'none',
      },
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 80px',
    maxWidth: '1050px',
    [breakpoints.down('xs')]: {
      padding: '24px',
    },
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 80px',
    maxWidth: '1050px',
  },
  pageErrorMsg: {
    textAlign: 'center',
  },
}));

interface MultiOrderFormPageProps {
  qualificationTaxYear: number;
}

export const MultiOrderFormPage = ({
  qualificationTaxYear,
}: MultiOrderFormPageProps) => {
  const classes = useStyles();
  const { app } = useCommonStores();
  const history = useHistoryStore();
  const [orderFormsFetched, setOrderFormsFetched] = useState<boolean>(false);
  const [orderForms, setOrderForms] = useState<OrderForm[]>();
  const [pageError, setPageError] = useState<string>('');
  const [fedRdProgramAccepted, setFedRdProgramAccepted] =
    useState<boolean>(false);

  useEffect(() => {
    if (qualificationTaxYear) {
      GetOrdersByYear(qualificationTaxYear).then((res) => {
        if (res.errorMsg) {
          datadogLogs.logger.error(
            `[ORDER_FORM]: Error retrieving order forms for qualificationTaxYear: ${qualificationTaxYear}`,
            logContext({ company: app.company, error: res.errorMsg }),
          );
          setPageError(res.errorMsg);
        }

        if (res.data && res.data.orderForms) {
          const displayableOrderForms = res.data.orderForms.filter(
            IsDisplayableOrderForm,
          );
          setOrderForms(displayableOrderForms);

          const fedRdProgram = res.data.orderForms.find(
            (form: OrderForm) =>
              form.programName === ProgramNameEnum.FED_RD_TAX,
          );
          if (fedRdProgram && fedRdProgram.acceptedAt) {
            setFedRdProgramAccepted(true);
          }
        }
        setOrderFormsFetched(true);
      });
    }
  }, [qualificationTaxYear]);

  // Redirect user based on number of order forms.
  useEffect(() => {
    if (orderForms && orderForms.length >= 0) {
      switch (orderForms.length) {
        case 0:
          history.push(`/`);
          break;
        case 1:
          if (orderForms[0].estimatedTotalCreditCents <= 4999998_00) {
            const programId = orderForms[0].programId;
            history.push(`/${Page.orderForm}/${programId}`);
          }
          break;
        default:
          break;
      }
    }
  }, [orderForms, orderFormsFetched]);

  const renderOrderFormCard = (orderForm: OrderForm) => {
    const {
      programId,
      programName,
      estimatedTotalCreditCents,
      acceptedAt,
      misc,
    } = orderForm;
    const program = programName && Programs[programName];
    const { display, description, iconLocation } = program;

    const section139Program = misc?.disasterReliefData !== undefined;
    const estimateCents = section139Program
      ? misc?.disasterReliefData?.taxSavings
      : estimatedTotalCreditCents;
    const estimateDollars =
      estimateCents && CentsToDisplayStringNoSymbol(estimateCents);
    const orderFormAccepted = !!acceptedAt;
    const cardDisabled =
      IsStateRDCreditProgram(programName) && !fedRdProgramAccepted;
    const ctaDisabled = cardDisabled || orderFormAccepted;
    return (
      <DashboardCard
        key={display}
        header={
          cardDisabled
            ? {
                text: 'Your company must first be enrolled in the Federal R&D Tax Credit',
                iconLocation: `${process.env.PUBLIC_URL}/images/warning-icon.svg`,
              }
            : null
        }
        iconLocation={`${process.env.PUBLIC_URL}${iconLocation}`}
        title={display}
        description={description || ''}
        estimate={estimateDollars}
        cta={{
          display: orderFormAccepted ? 'Accepted' : 'Review and Accept',
          path: `/${Page.orderForm}/${programId}`,
        }}
        ctaDisabled={ctaDisabled}
        estimateDescription={
          section139Program
            ? 'Estimated tax savings across employees'
            : 'Estimated credit amount'
        }
        dataTestId={`order-form-card-${programName}`}
      />
    );
  };

  return (
    <div className={classes.root}>
      <Heading
        tag={app.isMobile ? 'h4' : 'h4'}
        text='Review and accept your credits and incentives'
      />

      {orderForms && orderForms.map(renderOrderFormCard)}

      <Loading loading={!orderFormsFetched}></Loading>

      {pageError && (
        <FormHelperText className={classes.pageErrorMsg} error>
          {pageError}
        </FormHelperText>
      )}
    </div>
  );
};

export default MultiOrderFormPage;
