import React from 'react';
import {
  Animate,
  Button,
  Color,
  Content,
  GradientBackground,
  Heading,
  Text,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import {
  useAccountCreationStores,
  useCommonStores,
  useCompany,
  useFeatureFlags,
} from 'stores/useStores';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  container: {
    border: `1px solid ${Color.neutral._20}`,
    boxShadow: Color.shadow.medium,
    height: '100%',
    boxSizing: 'border-box',
    position: 'relative',
  },
  unifiedContainer: {
    background: 'transparent',
    height: '100%',
    boxSizing: 'border-box',
    position: 'relative',
  },
  animate: {
    width: '100%',
    height: '100%',
    minHeight: 500,
    maxHeight: 800,
  },
  content: {
    textAlign: 'center',
    boxSizing: 'border-box',
    maxWidth: 880,
    zIndex: 1,
    opacity: 1,
    transition: 'opacity 1s',
  },
  fadeOut: {
    opacity: 0,
  },
  button: {
    display: 'inline-block',
  },
}));

interface OnboardingLandingProps {
  onSubmit: () => void;
}

export const OnboardingLandingPage = observer(
  ({ onSubmit }: OnboardingLandingProps) => {
    const classes = useStyles();
    const { company } = useCompany();
    const featureFlags = useFeatureFlags();
    const { accountCreation } = useAccountCreationStores();
    const firstName = company.adminName.first;
    const {
      app: { isMobile },
    } = useCommonStores();

    return (
      <Animate enter={'fade-in'} delay={1} className={classes.animate}>
        <Content
          flex
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          className={`${
            !featureFlags.showNewUnifiedAccountCreation
              ? classes.container
              : classes.unifiedContainer
          }`}
          dataTestId={'onboarding-landing-page'}
          paddingLeftRight={40}
        >
          <Content
            className={`${classes.content} ${
              accountCreation.fadeOut ? classes.fadeOut : ''
            }`}
            justifyContent='center'
          >
            <Animate enter={['fade-in', 'from-top']} delay={1.5}>
              <Heading
                variant='medium'
                marginBottom={40}
                tag={isMobile ? 'h4' : 'h3'}
              >
                <Heading color={Color.blue._60} tag='span' variant='medium'>
                  Hi {firstName},&nbsp;
                </Heading>
                <Heading tag='span' variant='medium'>
                  answer a few questions to help us better understand your
                  business needs.
                </Heading>
              </Heading>
            </Animate>
            <Animate enter={'fade-in'} delay={2}>
              <Text size={isMobile ? 18 : 23} paddingBottom={24}>
                This should take no more than 5 minutes.
              </Text>
            </Animate>
            <Animate enter={'fade-in'} delay={3}>
              <Button
                label={"Let's get started"}
                onClick={onSubmit}
                className={classes.button}
                dataTestId={'account-creation-start-button'}
              />
            </Animate>
          </Content>
          {!featureFlags.showNewUnifiedAccountCreation && (
            <GradientBackground
              gradientOpacity={0.65}
              gradient={Color.gradient._3}
              gradientOffsetTop={20}
            />
          )}
        </Content>
      </Animate>
    );
  },
);
