import { createContext } from 'react';
import { CompanyData } from 'lib/interfaces';

export interface CompanyContextInterface {
  company: CompanyData;
  setCompany: (company: CompanyData | null) => void;
}

// TODO: Deprecate CompanyContext as the Company entity is used more
export const CompanyContext = createContext({} as CompanyContextInterface);
