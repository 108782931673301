import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Route, Switch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Animate, Color, Flex } from 'component-library';
import {
  useCommonStores,
  useFeatureFlags,
  useTaxCreditsStores,
} from 'stores/useStores';
import { animated } from 'react-spring';
import { useSpringPageLocationTransition } from '../../../../../lib/useSpringPageTransition';
import TitleHeader from '../../../../../components/TitleHeader';
import { Page } from '../../../../../lib/constants';
import HttpErrorPage from '../../../../../pages/HttpErrorPage';
import useBoirSurveyRoutes from './useBoirSurveyRoutes';

const useStyles = makeStyles(({ boxShadowRegular }) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  mainContent: {
    maxWidth: '1440px',
    margin: '0 auto',
    position: 'relative',
  },
  fullMainContent: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  animateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    gap: '24px',
  },

  sticky: {
    maxWidth: '1440px',
    width: '100%',
    margin: '24px auto 0',
    position: 'sticky',
    top: '0',
    backgroundColor: Color.neutral._20,
    borderRadius: '4px',
    zIndex: 3,
    boxShadow: boxShadowRegular,
  },
  viewAllBtn: {
    padding: '0 0 0 16px',
    position: 'relative',
    borderLeft: `1px solid ${Color.neutral._40}`,
    height: 'auto',
  },
}));

export const BoirSurvey = observer(() => {
  const classes = useStyles();
  const location = useLocation();
  const { companyStore } = useCommonStores();
  const { unifiedTaxCredits } = useTaxCreditsStores();
  const { AssessmentSurveyRoutes } = useBoirSurveyRoutes();
  const featureFlags = useFeatureFlags();

  const { transitions } = useSpringPageLocationTransition(
    location,
    unifiedTaxCredits.flowCurrentIndex,
    unifiedTaxCredits.flowPrevIndex,
  );

  return (
    <Flex
      direction='column'
      className={classes.root}
      padding={[0, 24]}
      data-testid={`beneficial-ownership-assessment`}
    >
      <Animate enter={['fade-in', 'from-top']}>
        <TitleHeader
          title={``}
          showExitButton={!companyStore.accessToken}
          borderBottom
          onCancelExitText='Cancel'
          showCompanyName
          onExitLocation={`/${Page.taxCredits}`}
          onExitText='Only the answers up to the last completed section will be saved.'
          fullWidth
          showTalkToExpertButton={false}
        />
      </Animate>
      <div className={classes.fullMainContent}>
        <Flex className={classes.mainContent} direction='column'>
          <Animate
            enter={'fade-in'}
            delay={1}
            duration={0.75}
            className={classes.animateContainer}
          >
            {transitions((props, item) => {
              return (
                <animated.div
                  style={props}
                  className={classes.animateContainer}
                >
                  <Switch location={item}>
                    {AssessmentSurveyRoutes.map((item, key) => {
                      return (
                        <Route
                          exact={item.exact}
                          key={key}
                          path={item.path}
                          render={() => item.component}
                        />
                      );
                    })}
                    <Route
                      path={`/${Page.taxCredits}/`}
                      component={HttpErrorPage}
                    />
                  </Switch>
                </animated.div>
              );
            })}
          </Animate>
        </Flex>
      </div>
    </Flex>
  );
});
