import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Button,
  Card,
  Color,
  Flex,
  Icon,
  IconEnum,
  Image,
  Text,
} from 'component-library';
import {
  useCommonStores,
  useFeatureFlags,
  useTaxCreditsStores,
} from 'stores/useStores';
import { ActivityTimelineSideDrawer } from './ActivityTimelineSideDrawer';
import { ProgramData } from 'lib/interfaces';
import {
  ExpectedCreditTypeEnum,
  Page,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  StatePrograms,
} from 'lib/constants';
import { datadogLogs } from '@datadog/browser-logs';

const useStyles = makeStyles(() => ({
  title: {
    whiteSpace: 'nowrap',
  },
  timelineBtn: {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
  },
  alertInfo: {
    border: `1px solid ${Color.blue._50}`,
    backgroundColor: Color.blue._10,
    borderRadius: '4px',
  },
  instructions: {
    border: `1px solid ${Color.neutral._40}`,
    backgroundColor: Color.neutral._light_20,
    borderRadius: '4px',

    '& > ul': {
      margin: 0,
      paddingLeft: 16,

      '& li': {
        paddingBottom: 16,

        '&:last-child': {
          paddingBottom: 0,
        },
      },
    },
  },
}));

interface TaxCreditClaimCompleteProps {
  taxYear: number;
  isPayrollType?: boolean;
  programs: ProgramData[];
  completed8974?: boolean;
}

export const TaxCreditClaimComplete: React.FC<TaxCreditClaimCompleteProps> =
  observer(({ taxYear, isPayrollType, programs, completed8974 }) => {
    const classes = useStyles();
    const [payrollContinueLoading, setPayrollContinueLoading] =
      useState<boolean>(false);
    const { unifiedTaxCredits, form8974 } = useTaxCreditsStores();
    const { app } = useCommonStores();
    const taxYearPrograms = programs?.filter(
      (p) =>
        p.taxYear === taxYear &&
        !StatePrograms.includes(p.name) &&
        p.stage === ProgramStageEnum.FINISHED,
    );
    const featureFlags = useFeatureFlags();
    const showCreditRedemption = featureFlags.showUpdatedCreditsRedemption;
    const hasOtherCredits = taxYearPrograms.length > 1;

    const handleOnContinuePayrollType = async () => {
      setPayrollContinueLoading(true);

      for (const program of taxYearPrograms) {
        try {
          await form8974.updateProgramSubStage(
            program.id,
            ProgramSubStageEnum.REDEEMING,
          );
        } catch (error) {
          datadogLogs.logger.error(
            `Failed to update program sub-stage for program ID ${program.id} to redeeming:`,
            error,
          );
        }
      }

      setPayrollContinueLoading(false);
      if (showCreditRedemption) {
        app.history.push(`/${Page.creditsRedemption}`);
      } else {
        app.history.push(`/${Page.redemption}`);
      }
    };

    const hasSomeProgramIncomeType = programs.filter(
      (p) =>
        p.stage !== ProgramStageEnum.DISQUALIFIED &&
        p.filingCreditType === ExpectedCreditTypeEnum.INCOME_TAX,
    );

    return (
      <>
        <Card noMargin key={`${taxYear}-assessments-credit-claimed`}>
          {isPayrollType ? (
            <Flex direction='column' gap={16} padding={24}>
              <Flex
                justifyContent='space-between'
                alignItems='center'
                wrap='wrap'
              >
                <Flex
                  gap={4}
                  alignItems='center'
                  justifyContent='flex-start'
                  className={classes.title}
                  basis={315}
                >
                  <Text variant='medium' size={18}>
                    Apply your {taxYear} tax credits
                  </Text>
                </Flex>
                <Button
                  className={classes.timelineBtn}
                  label={
                    <Flex gap={4}>
                      <Icon
                        name={IconEnum.clock_circle}
                        color={Color.blue._60}
                      />{' '}
                      <Text color={Color.blue._60}>View activity timeline</Text>
                    </Flex>
                  }
                  flexAlignSelf='flex-end'
                  onClick={() =>
                    unifiedTaxCredits.setShowTimelineSideDrawer(true)
                  }
                  variant='tertiary'
                  small
                />
              </Flex>
              <Text>
                Congratulations on filing your income taxes. You still need to
                perform some additional actions so we can set up your credit the
                right way.
              </Text>
              <Text>
                Follow the instructions below to apply your credits and reduce
                your taxes:
              </Text>
              <Flex className={classes.instructions} padding={24} gap={12}>
                <ul>
                  {taxYearPrograms.filter(
                    (p) =>
                      p.name === ProgramNameEnum.FED_RD_TAX &&
                      p.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX,
                  ).length > 0 && (
                    <li>
                      <Text variant='medium'>Federal R&D credits</Text>
                      <Text>
                        The following quarter after filing your income taxes,
                        you will need to file Form 8974 along with your payroll
                        taxes. Click &apos;Continue&apos; to get notified when
                        it&apos;s time to download the form.
                      </Text>
                    </li>
                  )}

                  {hasOtherCredits && (
                    <li>
                      <Text variant='medium'>
                        {taxYearPrograms.filter(
                          (p) =>
                            p.name === ProgramNameEnum.FED_RD_TAX &&
                            p.stage === ProgramStageEnum.FINISHED,
                        ).length > 0
                          ? 'Additional income '
                          : 'Income'}{' '}
                        tax credits
                      </Text>
                      <Text>
                        No further action required! When you filed your taxes,
                        you redeemed your credits to reduce your income tax
                        liability. If there was any balance, it will offset
                        future income taxes
                      </Text>
                    </li>
                  )}
                </ul>
              </Flex>
              <Button
                label='Continue'
                onClick={() => handleOnContinuePayrollType()}
                flexAlignSelf='flex-start'
                loading={payrollContinueLoading}
              />
            </Flex>
          ) : (
            <Flex direction='column' gap={16} padding={24}>
              <Flex
                justifyContent='space-between'
                alignItems='center'
                wrap='wrap'
              >
                <Flex
                  gap={4}
                  alignItems='center'
                  justifyContent='flex-start'
                  className={classes.title}
                  basis={315}
                >
                  <Text variant='medium' size={18}>
                    Successfully claimed {taxYear} credits! 🎉
                  </Text>
                </Flex>
                <Button
                  className={classes.timelineBtn}
                  label={
                    <Flex gap={4}>
                      <Icon
                        name={IconEnum.clock_circle}
                        color={Color.blue._60}
                      />{' '}
                      <Text color={Color.blue._60}>View activity timeline</Text>
                    </Flex>
                  }
                  flexAlignSelf='flex-end'
                  onClick={() =>
                    unifiedTaxCredits.setShowTimelineSideDrawer(true)
                  }
                  variant='tertiary'
                  small
                />
              </Flex>
              <Flex className={classes.alertInfo} padding={[16, 12]} gap={12}>
                <Flex.Cell>
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/tax-credit-dashboard-module/all-set.svg`}
                  />
                </Flex.Cell>
                <Flex direction='column' gap={4}>
                  <Text variant='medium' size={18}>
                    Give yourself some (tax) credit!
                  </Text>
                  {completed8974 ? (
                    <>
                      {hasSomeProgramIncomeType.length > 0 && (
                        <Text size={13}>
                          Some of your tax credits were used to reduce your
                          income taxes. Your Federal R&D credit is being used to
                          reduce your payroll taxes.
                        </Text>
                      )}
                      <Text size={13}>
                        Any remaining credit will be applied to future income
                        and payroll taxes!
                      </Text>
                    </>
                  ) : (
                    <Text size={13}>
                      When you filed your taxes, you redeemed your credits to
                      reduce your income tax liability. If there was any
                      balance, it will offset future income taxes.
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Flex>
          )}
        </Card>

        <ActivityTimelineSideDrawer
          taxYear={taxYear.toString()}
          stepIndex={6}
        />
      </>
    );
  });
