import React, { useCallback, useContext, useEffect, useState } from 'react';
import StepsContainer from '../../component/StepsContainer';
import { Button, Content, Spinner } from 'component-library';
import { PrivacyConcern } from '../component/PrivacyConcern';
import {
  ConnectPayrollSystem,
  PayrollSystem,
  ImportType,
} from 'lib/interfaces';
import {
  ConnectPayrollCard,
  PayrollConnectedCard,
} from 'pages/dashboard/integrations';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import { TokenContext } from 'pages/TokenRequired';
import { useCompany, useLegacyClients } from 'stores/useStores';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { setDocumentPageTitle, useEffectOnce } from 'lib/helpers';

interface ConnectPayrollProps {
  taxYear: number;
  pathTo: string;
  onNext: () => void;
}

export const ConnectPayroll = observer(
  ({ pathTo, onNext, taxYear }: ConnectPayrollProps) => {
    const [loading, setLoading] = useState(true);
    const [payrollSystems, setPayrollSystems] = useState<
      ConnectPayrollSystem[]
    >([]);
    const history = useHistory();
    const { client } = useLegacyClients();
    const { company } = useCompany();
    const { token, email } = useContext(TokenContext);
    const loggedIn = !!company;

    const loadPayrollProviders = useCallback(
      (token?: string, email?: string) => {
        setLoading(true);
        client.FinchLinkedServices(token, email).then((providers) => {
          if (providers.errorMsg) {
            datadogLogs.logger.error(
              '[QUALIFICATION]: CONNECT_PAYROLL loadPayrollProviders FinchLinkedServices error',
              logContext({
                error: providers.errorMsg,
                company,
              }),
            );
          } else {
            setPayrollSystems([
              ...providers!.data!.services.map((service) => {
                return {
                  serviceName: service,
                  expired: false,
                };
              }),
              ...providers!.data!.expiredServices.map((service) => {
                return {
                  serviceName: service,
                  expired: true,
                };
              }),
            ]);
          }
          setLoading(false);
        });
      },
      [client, company],
    );

    const refreshCompany = () => {
      return client.CurrentLoggedInCompany().then((companyData) => {
        if (companyData) {
          company.mergeData(companyData);
        }
      });
    };

    const onPayrollConnected = async () => {
      loadPayrollProviders(token, email);

      if (loggedIn) {
        await refreshCompany();
      }
      setLoading(false);
    };

    useEffect(() => {
      setLoading(true);
      loadPayrollProviders(token, email);
    }, [email, token, loadPayrollProviders]);

    const moveToNextStep = async (option?: PayrollSystem) => {
      if (option) {
        await client.UpdateCompanyPayrollSystem({ payrollSystemId: option.id });
        await refreshCompany();
      }
      onNext();
      history.push(pathTo);
    };

    const renderPayrollConnect = () => {
      return (
        <>
          {loading ? (
            <Content
              justifyContent='center'
              paddingLeftRight={24}
              paddingTopBottom={24}
            >
              <Spinner size='small' />
            </Content>
          ) : (
            <>
              {payrollSystems.length > 0 ? (
                payrollSystems.map((system, index) => (
                  <React.Fragment key={index}>
                    <PayrollConnectedCard
                      payrollSystem={system.serviceName}
                      onPayrollConnected={onPayrollConnected}
                      expiredPayrollSystem={system.expired}
                      connectAnother={false}
                      useCardComponent
                      importType={ImportType.Qualification}
                      taxYear={taxYear}
                    />
                    <Button
                      label={'Continue'}
                      onClick={() => history.push(pathTo)}
                      dataTestId='connected-payroll-continue-btn'
                    />
                  </React.Fragment>
                ))
              ) : (
                <Content paddingTop={24}>
                  <ConnectPayrollCard
                    onPayrollConnected={async () => moveToNextStep()}
                    moveToNextStep={moveToNextStep}
                    storeDropdownSelection={true}
                    required={false}
                    importType={ImportType.Qualification}
                    taxYear={taxYear}
                  />
                </Content>
              )}
            </>
          )}
        </>
      );
    };

    useEffectOnce(() =>
      setDocumentPageTitle('Qualification - Connect Payroll'),
    );

    return (
      <StepsContainer
        showContinueButton={false}
        onContinueLabel='Continue'
        stepTitle='First, please connect your payroll&nbsp;information'
        subtitle='Most credits depend highly on your payroll information so it is
      important that we get it accurately. This integration is made through
      Finch and it is completely&nbsp;secure.'
      >
        <div>
          {renderPayrollConnect()}
          <Content paddingLeftRight={0} paddingTopBottom={24}>
            <PrivacyConcern />
          </Content>
        </div>
      </StepsContainer>
    );
  },
);
