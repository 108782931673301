import React from 'react';
import { Button } from 'component-library';
import { IPaymentMethodState } from 'components/PaymentMethod';

interface CCButtonsProps {
  selectedCardId: null | string;
  saveDefaultPaymentMethod: () => Promise<void>;
  setPaymentMethodState: React.Dispatch<
    React.SetStateAction<IPaymentMethodState>
  >;
  setDefaultIsLoading: boolean;
}

export default function CCButtons(props: CCButtonsProps) {
  return (
    <>
      {' '}
      <Button
        label='Set as default'
        onClick={props.saveDefaultPaymentMethod}
        disabled={props.selectedCardId === null}
        loading={props.setDefaultIsLoading}
        dataTestId='set-default-card-btn'
      />
      <Button
        variant='tertiary'
        label='Add another credit card'
        dataTestId='add-new-card-btn'
        onClick={() =>
          props.setPaymentMethodState((prev) => ({
            ...prev,
            addNewCard: true,
          }))
        }
      />
    </>
  );
}
