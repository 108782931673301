import React from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useCompany, useDashboardStores } from 'stores/useStores';
import { AccountModuleSection } from './AccountModule';
import {
  Color,
  Flex,
  Grid,
  Icon,
  IconEnum,
  Link,
  Modal,
  Spinner,
  Text,
  Tooltip,
} from 'component-library';
import { Program } from 'entities/Program';
import { CentsToDisplayString, useEffectOnce } from 'lib/helpers';
import {
  Page,
  ProgramNameEnum,
  ProgramStageEnum,
  Programs,
  QualificationStatusEnum,
} from 'lib/constants';
import { CreditEstimate, PayrollDataSourceEnum } from 'lib/interfaces';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  modalContent: {
    minWidth: 560,
    borderBottom: `1px solid ${Color.neutral._20}`,
  },
}));

const CreditEstimateHistory = observer(() => {
  const classes = useStyles();
  const { company } = useCompany();
  const { modules } = useDashboardStores();

  useEffectOnce(async () => {
    /**
     * Previously, this removed programs that wer in canceled or disqualified.
     * However, that assumption is not valid for the concept of Order Forms,
     * which adds some tension around displaying Order Forms and Credit Estimates
     * together.
     *
     * For now, we're removing the filtering in BUG-1080. We may have to add it
     * back (or handle Credit Estimate cases) in the future.
     */

    const availablePrograms = company.programs;

    if (availablePrograms.length > 0) {
      availablePrograms.sort((a, b) => b.taxYear - a.taxYear);
      await modules.setEstimateHistory(availablePrograms as Program[]);
    }
  });

  const renderModal = (
    program: Program,
    programName: string,
    estimateHistory: CreditEstimate[],
    showModal: boolean,
  ) => {
    const orderFormLink = `/${Page.orderForm}/${program.id}`;
    const initialEstimate = estimateHistory[estimateHistory.length - 1];
    const initialEstimateDate = initialEstimate
      ? moment(initialEstimate.created).format('MMM DD, YYYY')
      : undefined;

    const dataSource = (status: PayrollDataSourceEnum | null) => {
      switch (status) {
        case PayrollDataSourceEnum.MANUAL_PAYROLL_ESTIMATE:
          return {
            creditType: 'Initial estimate',
          };
        case PayrollDataSourceEnum.MANUAL_VIA_MS_EMPLOYEE:
          return {
            creditType: 'Updated estimate',
            status: 'MainStreet update',
          };
        case PayrollDataSourceEnum.PAYROLL_IMPORT:
          return {
            creditType: 'Updated estimate',
            status: 'Payroll data',
          };
        case PayrollDataSourceEnum.FORM_6765_ESTIMATE:
          return {
            creditType: 'Final credit',
            status: 'MainStreet review',
          };
      }
    };

    return (
      <Modal
        showModal={showModal}
        closeToggle={() => modules.setShowEstimateModalIndex(null)}
        dataTestId={`${programName}-modal`}
      >
        <Flex padding={24} direction='column'>
          <Text text='Credits' variant='medium' size={23} />
          <Text
            text={programName}
            paddingBottom={16}
            color={Color.neutral._60}
            variant='medium'
          />
          <Flex gap={4}>
            <Text text='Estimate history' variant='medium' paddingBottom={8} />
            <Tooltip
              text='Estimate history logged since December 2022'
              position='top'
            >
              <Icon
                name={IconEnum.info_circle_outline}
                color={Color.neutral._60}
              />
            </Tooltip>
          </Flex>
          {estimateHistory.map((entry, index) => {
            const estimateDate = moment(entry.created).format('MMM DD, YYYY');
            const statusText = dataSource(entry.payrollDataSource);
            const creditEstimate = CentsToDisplayString(entry.estimateCents);

            return (
              <Grid
                columns={12}
                key={index}
                className={classes.modalContent}
                gap={24}
              >
                <Grid.Cell columns={6}>
                  <Flex justifyContent='space-between' padding={[8, 0]} gap={8}>
                    <Text
                      text={
                        index === estimateHistory.length - 1
                          ? 'Initial estimate'
                          : statusText?.creditType
                      }
                    />
                    <Text text={estimateDate} />
                  </Flex>
                </Grid.Cell>
                <Grid.Cell columns={6}>
                  <Flex justifyContent='space-between' padding={[8, 0]}>
                    <Text text={statusText?.status} color={Color.neutral._60} />
                    <Text text={creditEstimate} variant='medium' />
                  </Flex>
                </Grid.Cell>
              </Grid>
            );
          })}
          {initialEstimateDate && (
            <Flex padding={[24, 0]} direction='column' gap={8}>
              <Text text='To view your initial contract, click on the link below:' />
              <Text>
                <Link
                  external
                  variant='medium'
                  href={orderFormLink}
                  text={`Initial contract estimate - ${initialEstimateDate}`}
                />
              </Text>
            </Flex>
          )}
        </Flex>
      </Modal>
    );
  };

  const renderEmptyState = () => {
    const fedPrograms = company.programs.filter(
      (p) => p.name === ProgramNameEnum.FED_RD_TAX,
    );
    const stateCAProgram = company.programs.filter(
      (p) => p.name === ProgramNameEnum.STATE_RD_CA,
    );

    const DATA_TEST_ID_PREFIX = 'dashboard-account-module-credits-history';

    return (
      <>
        {fedPrograms.length > 0 &&
          fedPrograms.map((p, index) => {
            if (
              p.qualificationStatus ===
                QualificationStatusEnum.QUALIFICATION_IN_PROGRESS &&
              p.stage === ProgramStageEnum.QUALIFYING
            ) {
              return (
                <Text key={`${p.name}-${index}-${p.taxYear}`}>
                  <Link
                    variant='medium'
                    href={`/${Page.taxCredits}`}
                    text={`Get started with ${p.taxYear}`}
                    dataTestId={`${DATA_TEST_ID_PREFIX}-qualification-link`}
                  />
                </Text>
              );
            } else if (p.orderForm?.acceptedAt === null) {
              return (
                <Text key={`${p.name}-${index}-${p.taxYear}`}>
                  <Link
                    variant='medium'
                    href={`${Page.orderForms}/${p.taxYear}`}
                    text={`Accept ${p.taxYear} credits`}
                    dataTestId={`${DATA_TEST_ID_PREFIX}-orderform-link`}
                  />
                </Text>
              );
            }
          })}
        {stateCAProgram.length > 0 &&
          stateCAProgram.map((p, index) => {
            if (p.orderForm?.acceptedAt === null) {
              return (
                <Text key={`${p.name}-${index}-${p.taxYear}`}>
                  <Link
                    variant='medium'
                    href={`${Page.orderForms}/${p.taxYear}`}
                    text={`Accept ${p.taxYear} credits`}
                    dataTestId={`${DATA_TEST_ID_PREFIX}-orderform-link`}
                  />
                </Text>
              );
            }
          })}
      </>
    );
  };

  return (
    <AccountModuleSection title={'Order Forms'}>
      {modules.loadingEstimateHistory ? (
        <Spinner size='small' />
      ) : (
        <>
          {modules.estimateHistory.length !== 0 &&
            modules.estimateHistory.map((entry, index) => {
              const programName = `${entry.program.taxYear} ${
                Programs[entry.program.name].display
              }`;
              const estimateHistory = entry.creditHistory;
              const showModal = modules.showEstimateModalIndex === index;

              if (
                entry.program.orderForm?.acceptedAt !== null &&
                !entry.program.orderForm?.autoAcceptedTermsAndAgreements
              ) {
                return (
                  <React.Fragment
                    key={`${index}-${entry.program.taxYear}-${entry.program.name}`}
                  >
                    <Text>
                      <Link
                        variant='medium'
                        onClick={() => modules.setShowEstimateModalIndex(index)}
                        text={programName}
                        dataTestId={`${entry.program.taxYear}-${entry.program.name}-entry`}
                      />
                    </Text>
                    {renderModal(
                      entry.program,
                      programName,
                      estimateHistory,
                      showModal,
                    )}
                  </React.Fragment>
                );
              }
            })}
          {modules.hasNotAcceptedAllOrderForms && <>{renderEmptyState()}</>}
        </>
      )}
    </AccountModuleSection>
  );
});

export default CreditEstimateHistory;
