import React, { useState } from 'react';
import {
  Button,
  Text,
  Link,
  Divider,
  Flex,
  Icon,
  IconEnum,
  Color,
} from 'component-library';
import { useTaxCreditsStores } from '../../../../stores/useStores';
import { makeStyles } from '@material-ui/core';
import { formatPayrollString } from 'lib/helpers';
import { ALL_PAYROLL_SYSTEMS } from 'lib/constants';

const useStyles = makeStyles(() => ({
  list: {
    padding: '0 8px',
  },
}));

const Tier2SideDrawerContent = () => {
  const classes = useStyles();
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const { form8974 } = useTaxCreditsStores();
  const payrollProviderId =
    ALL_PAYROLL_SYSTEMS.find((p) => p.id === form8974.payrollProvider)?.id ||
    form8974.payrollProvider;
  const payrollProvider =
    ALL_PAYROLL_SYSTEMS.find((p) => p.id === form8974.payrollProvider)
      ?.displayName || formatPayrollString(form8974.payrollProvider);

  const helpCenterUrls: { [key: string]: string } = {
    justworks:
      'https://mainstreet1.my.site.com/help/s/article/How-to-File-Your-Form-8974-with-Justworks',
    sequoia_one:
      'https://mainstreet1.my.site.com/help/s/article/How-to-File-Your-Form-8974-with-Sequoia-One',
    trinet:
      'https://mainstreet1.my.site.com/help/s/article/How-to-File-Your-Form-8974-with-TriNet',
    insperity:
      'https://mainstreet1.my.site.com/help/s/article/How-to-File-Your-Form-8974-with-Insperity',
  };

  return (
    <>
      <Text
        variant='medium'
        paddingBottom={8}
        text='How to use credits towards payroll taxes'
      />
      <Divider />
      <Text>
        You must complete these steps as soon as possible. If you do not
        complete these steps in time, your funds may be delayed by 9+ months.
      </Text>
      <ol className={classes.list}>
        <li>
          <Text>Download the partially-filled Form 8974 below.</Text>
        </li>
        <br />
        <li>
          <Text>
            Share Form 8974 and all requested information with {payrollProvider}
            .
            <ul>
              <li>
                Don’t use {payrollProvider} anymore?&nbsp;
                <Link href='/connections'>Click here&nbsp;</Link>
                to update your provider and get new instructions.
              </li>
            </ul>
          </Text>
        </li>
        <br />
        <li>
          <Text>
            Return here to confirm that you’ve completed these steps. This is
            essential in order to properly track your credit.
          </Text>
        </li>
        <li>
          <Text>
            {payrollProvider} will apply your credits to your quarterly payroll
            taxes, then provide your tax refund in the form of a line item
            credit on your {payrollProvider} invoice.
          </Text>
        </li>
      </ol>
      <Flex direction='column' padding={[0, 8]} gap={24}>
        <Text>
          Details on setting up credits with&nbsp;
          <Link
            external
            href={
              helpCenterUrls[payrollProviderId] ||
              'https://mainstreet-help.force.com/help/s/topic/0TO5f000000I96tGAC/quarterly-filing-documents'
            }
            target='_blank'
          >
            {payrollProvider}
          </Link>
        </Text>
        <Button
          dataTestId='button--generate-form'
          label={
            <Flex justifyContent='center'>
              <Icon name={IconEnum.download} color={Color.blue._60} />
              <Text text='Download Form 8974' color={Color.blue._60} />
            </Flex>
          }
          loading={isGenerating}
          onClick={async () => {
            setIsGenerating(true);
            await form8974.GetForm8974URL();
            setIsGenerating(false);
            window.open(form8974.form8974GeneratedDocumentUrl, '_blank');
          }}
          variant='outlined'
        />
      </Flex>
    </>
  );
};

export default Tier2SideDrawerContent;
