import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { CompanyContext } from '../../pages/CompanyRequired';
import { useCommonStores } from '../../stores/useStores';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default function Analytics() {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<string>('');
  const [init, setInit] = useState<boolean>(false);
  const { company } = useContext(CompanyContext);
  const { auth } = useCommonStores();

  useEffect(() => {
    if (!init) {
      const gaId = process.env.REACT_APP_GA_ID || '';
      if (gaId) {
        ReactGA.initialize(gaId);
        setInit(true);
      } else {
        return;
      }
    }
    const page = location.pathname + location.search;
    if (!init || page === currentPage) {
      return;
    }

    ReactGA.pageview(page);
    setCurrentPage(page);
  }, [location.pathname, location.search, currentPage, init]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    if (company) {
      window.dataLayer.push({
        event: 'userData',
        companyId: company.id,
        userId: auth.user?.userId,
      });
    }
  }, [company?.id, window.dataLayer, auth.user?.userId]);

  return <></>;
}
