import React from 'react';
import { PlaidProvider } from './PlaidProvider';
import { PlaidConnectButton } from './PlaidConnectButton';

interface ConnectToPlaidProps {
  buttonVariant?: 'outlined' | 'contained' | 'tertiary';
  loading: boolean;
  labelName: string;
  plaidToken: string;
  onConnectSuccess: React.Dispatch<void>;
  onConnectFail: React.Dispatch<string>;
}

export const ConnectToPlaid = (props: ConnectToPlaidProps) => {
  return (
    <PlaidProvider
      plaidToken={props.plaidToken}
      onPlaidLinkSuccess={() => props.onConnectSuccess()}
      onPlaidLinkFail={(errorMsg: string) => props.onConnectFail(errorMsg)}
    >
      <PlaidConnectButton
        buttonVariant={props.buttonVariant}
        labelName={props.labelName}
        loading={props.loading}
      />
    </PlaidProvider>
  );
};
