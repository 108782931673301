import React, { useContext } from 'react';
import { Button } from 'component-library';
import { PlaidContext } from './PlaidProvider';

interface Props {
  buttonVariant?: 'outlined' | 'contained' | 'tertiary';
  className?: string;
  loading?: boolean;
  labelName?: string;
}

export const PlaidConnectButton: React.FC<Props> = ({
  buttonVariant,
  className,
  loading,
  labelName,
}: Props) => {
  const { open } = useContext(PlaidContext);

  return (
    <Button
      label={labelName ?? 'Connect'}
      className={className}
      loading={loading}
      onClick={() => open()}
      variant={buttonVariant || 'contained'}
    />
  );
};
