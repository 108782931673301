import { RootStore } from './RootStore';
import { BaseAPI } from 'api/BaseAPI';

export interface BaseStore {
  api?: BaseAPI;
  setup?(): Promise<void>;
  teardown?(): Promise<void>;
}

export abstract class BaseStore {
  public rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    if (this.setup) {
      this.rootStore.event.on('SETUP', this.setup.bind(this));
    }

    if (this.teardown) {
      this.rootStore.event.on('TEARDOWN', this.teardown.bind(this));
    }
  }

  /**
   * Shortcut to the common namespace
   */
  public get common() {
    return this.rootStore.common;
  }

  /**
   * Shortcut to the server client
   */
  public get client() {
    return this.rootStore.legacyClients.client;
  }

  /**
   * Shortcut to the payments client
   */
  public get paymentsClient() {
    return this.rootStore.legacyClients.paymentsClient;
  }

  /**
   * Shortcut to the history object
   */
  public get history() {
    return this.rootStore.history;
  }

  /**
   * Shortcut to the global company entity
   */
  public get company() {
    return this.common.companyStore.currentCompany;
  }

  /**
   * Shortcut to the common app store
   */
  public get app() {
    return this.rootStore.common.app;
  }

  /**
   * Shortcut to configured feature flags for the app
   */
  public get featureFlags() {
    return this.common.featureFlags.flags;
  }
}
