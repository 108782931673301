import { makeStyles } from '@material-ui/core';
import {
  Flex,
  Color,
  Divider,
  Text,
  Button,
  Icon,
  IconEnum,
  Dropdown,
  Callout,
  Link,
  FontAwesome,
} from 'component-library';
import { Months } from 'lib/constants';
import { CopyToClipboard } from 'lib/copyToClipboard';
import { Month, ProgramData } from 'lib/interfaces';
import { useDocumentAccessLink } from 'lib/useDocumentAccessLink';
import { observer } from 'mobx-react';
import { CompanyContext } from 'pages/CompanyRequired';
import React, { useContext, useState } from 'react';
import { useTaxCreditsStores } from 'stores/useStores';
import { ClientReviewStepDocumentList } from './ClientReviewStepDocumentList';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  monthContainer: {
    border: '1px solid',
    borderColor: Color.neutral._20,
    backgroundColor: Color.neutral._light_20,
    borderRadius: '8px',
  },
  documentsContainer: {
    border: '1px solid',
    borderColor: Color.neutral._20,
    borderRadius: '8px',
  },
  linkContainer: {
    width: '250px',
  },
}));

interface Props {
  federalRDProgram: ProgramData;
}

export const ClientReviewDownloadSideDrawer = observer(
  ({ federalRDProgram }: Props) => {
    const classes = useStyles();
    const { form8974 } = useTaxCreditsStores();
    const { company } = useContext(CompanyContext);
    const { accessLink, accessLinkError } = useDocumentAccessLink(
      company.id,
      federalRDProgram.id,
    );

    const [isLinkCopied, setIsLinkCopied] = useState<boolean | undefined>(
      undefined,
    );
    const [edit, setEdit] = useState(false);

    const taxFilingDate = new Date(federalRDProgram.taxFilingDate ?? '');
    const filingMonth =
      federalRDProgram.taxFilingDate && Months[taxFilingDate.getMonth()];
    const [month, setMonth] = useState<Month>(
      Months.find((m) => m === filingMonth) ?? Months[0],
    );

    const handleMonthChange = (month: Month) => {
      setMonth(month);
      taxFilingDate.setMonth(Months.indexOf(month));
      form8974.setTaxFilingDate(
        moment(taxFilingDate).utc().format('MM/DD/YYYY'),
      );
    };

    const handleOnCopyLink = () => {
      if (accessLinkError) {
        setIsLinkCopied(false);
      }
      if (accessLink) {
        const onCopied = () => {
          setIsLinkCopied(true);
        };
        CopyToClipboard(accessLink, onCopied);
      }
    };

    const showDeadlineCallout =
      company.taxFilingDeadline &&
      new Date(company.taxFilingDeadline).getMonth() < Months.indexOf(month);

    return (
      <div>
        <Text size={15} variant='medium' text='Confirm tax filing plans' />
        <Flex padding={[8, 0]}>
          <Divider variant='no-bottom-margin' />
        </Flex>
        <Text
          size={15}
          color={Color.neutral._60}
          text='Please verify when you intend to file business income taxes. You can edit this month if your plans change.'
          paddingBottom={16}
        />
        {edit ? (
          <>
            <Flex padding={[0, 0, 16, 0]} direction='column'>
              <Text
                paddingBottom={4}
                size={13}
                variant='medium'
                text='Your tax filing month'
              />
              <Dropdown
                options={Months}
                value={month}
                onInputChange={(month) => handleMonthChange(month as Month)}
              />
            </Flex>
            {showDeadlineCallout && (
              <Callout
                color={Color.blue._60}
                title={`${month} is after your regular tax filing deadline, so filing in ${month} may require an extension.`}
              >
                <Link
                  external
                  text='File an extension with the IRS'
                  href='https://www.irs.gov/e-file-providers/e-filing-form-7004-application-for-automatic-extension-to-file-certain-business-income-tax-information-and-other-returns'
                />
              </Callout>
            )}
          </>
        ) : (
          <Flex
            className={classes.monthContainer}
            padding={16}
            justifyContent='space-between'
            alignItems='center'
          >
            <Flex direction='column' justifyContent='flex-end'>
              <Text
                size={13}
                color={Color.neutral._80}
                text='Your tax filing month'
              />
              <Text size={15} variant='medium' text={filingMonth} />
            </Flex>
            <Flex justifyContent='flex-end' alignItems='center'>
              <Button
                variant='tertiary'
                onClick={() => setEdit(true)}
                label={
                  <>
                    <Icon
                      size={17}
                      name={IconEnum.pen}
                      color={Color.blue._60}
                    />
                    <Text color={Color.blue._60} text='Edit' />
                  </>
                }
                dataTestId='edit-month-button'
              />
            </Flex>
          </Flex>
        )}
        <Flex direction='column' padding={[16, 0]}>
          <Text size={15} variant='medium' text='Download R&D credit forms' />
          <Flex padding={[8, 0]}>
            <Divider variant='no-bottom-margin' />
          </Flex>
          <Text
            size={15}
            color={Color.neutral._60}
            text='Your tax preparer can securely download these forms with the following magic link:'
            paddingBottom={16}
          />
          <Flex gap={4} alignItems='start' justifyContent='start'>
            <Flex className={classes.linkContainer} alignItems='center'>
              <Link
                onClick={() => CopyToClipboard(accessLink, handleOnCopyLink)}
                target='_blank'
                omitIcon
              >
                <Flex padding={[0, 0, 16, 0]} gap={4} alignItems='center'>
                  <FontAwesome name='link' size={14} color={Color.blue._60} />
                  <Text
                    text='Copy link for CPA'
                    variant='medium'
                    color={Color.blue._60}
                  />
                </Flex>
              </Link>
            </Flex>
            {isLinkCopied && (
              <Flex alignItems='center'>
                <Icon
                  name={IconEnum.check_circle_filled}
                  size={16}
                  color={Color.semantic.$success50}
                />
                <Text text='Link copied' variant='medium' />
              </Flex>
            )}
            {isLinkCopied === false && (
              <Flex alignItems='center'>
                <Text
                  color={Color.semantic.$error50}
                  text='Error copying link'
                  variant='medium'
                />
              </Flex>
            )}
          </Flex>
          <Flex className={classes.documentsContainer} alignItems='center'>
            <ClientReviewStepDocumentList
              programId={federalRDProgram.id.toString()}
            />
          </Flex>
        </Flex>
      </div>
    );
  },
);
