import React from 'react';
import {
  AccountModuleLinkItem,
  AccountModuleLinkItemProps,
  AccountModuleSection,
} from './AccountModule';
import { SectionType } from 'services/financial/types';
import { useCommonStores } from 'stores/useStores';

const BillingSection = () => {
  // TODO: Handle error off of pending designs
  const { chargeBee } = useCommonStores();

  const mapSectionToProps: Record<SectionType, AccountModuleLinkItemProps> = {
    [SectionType.SubscriptionDetails]: {
      text: 'Manage Subscriptions',
      onClick: async () => {
        await chargeBee.openPortalSession(SectionType.SubscriptionDetails);
      },
    },
    [SectionType.PaymentSources]: {
      text: 'Payment methods',
      onClick: async () => {
        await chargeBee.openPortalSession(SectionType.PaymentSources);
      },
    },
    [SectionType.BillingHistory]: {
      text: 'Billing history',
      onClick: async () => {
        await chargeBee.openPortalSession(SectionType.BillingHistory);
      },
    },
    [SectionType.AccountDetails]: {
      text: 'Billing resources',
      onClick: () => {
        return;
      },
      external: true,
    },
  };

  const billingLinkItems = Object.keys(mapSectionToProps)
    // excluding this for now, until we're ready to implement this link
    .filter((item) => item !== SectionType.AccountDetails)
    .map((item) => {
      const { text, onClick, external } = mapSectionToProps[
        item as SectionType
      ];
      return (
        <AccountModuleLinkItem
          key={item}
          text={text}
          onClick={onClick}
          external={external}
        />
      );
    });

  return (
    <AccountModuleSection title={'Billing'}>
      {billingLinkItems}
    </AccountModuleSection>
  );
};

export default BillingSection;
