import { useContext } from 'react';
import { CommonNamespace } from './common/CommonNamespace';
import { TaxCreditsNamespace } from '../products/tax-credits/TaxCreditsNamespace';
import { AccountCreationNamespace } from './accountcreation/AccountCreationNamespace';
import { RootStore } from './RootStore';
import { StoreContext } from './StoreContext';
import { HistoryStore } from './HistoryStore';
import { LegacyClients } from './LegacyClients';
import { DashboardNamespace } from 'products/dashboard';
import { OnboardingNamespace } from 'products/onboarding';

/**
 * @deprecated Direct rootStore access is no longer allowed, please use
 * stores directly. If you need to access the ServerClient, it can be
 * found in the "useLegacyClients()" hook
 */
export const useRootStore = (): RootStore => useContext(StoreContext);

/**
 * Fetches early ServerClient classes
 */
export const useLegacyClients = (): LegacyClients =>
  useContext(StoreContext).legacyClients;

/**
 * Shortcut for access to the company entity and it's corresponding store
 */
export const useCompany = () => {
  const companyStore = useContext(StoreContext).common.companyStore;
  return { company: companyStore.currentCompany, store: companyStore };
};

/**
 * Shortcut for the history store
 */
export const useHistoryStore = (): HistoryStore =>
  useContext(StoreContext).history;

/**
 * Shortcut for common namespace
 */
export const useCommonStores = (): CommonNamespace =>
  useContext(StoreContext).common;

/**
 * Shortcut for featureFlags
 */
export const useFeatureFlags = () =>
  useContext(StoreContext).common.featureFlags.flags;

/**
 * Shortcut to the taxcredits namespace
 */
export const useTaxCreditsStores = (): TaxCreditsNamespace =>
  useContext(StoreContext).taxcredits;

/**
 * Shortcut to the accountcreation namespace
 */
export const useAccountCreationStores = (): AccountCreationNamespace =>
  useContext(StoreContext).accountcreation;

/**
 * Shortcut to the Dashboard namespace
 */
export const useDashboardStores = (): DashboardNamespace =>
  useContext(StoreContext).dashboard;

/**
 * Shortcut to the Onboarding namespace
 */
export const useOnboardingStores = (): OnboardingNamespace =>
  useContext(StoreContext).onboarding;
