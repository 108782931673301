import * as React from 'react';
import { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { CompanyContext } from 'pages/CompanyRequired';
import { usePayrollInviteLink } from 'lib/usePayrollInviteLink';
import { CopyToClipboardButton } from 'components/util/CopyToClipboardButton';
import { CopiedCheckmark } from 'components/util/CopiedCheckmark';
import { ConnectSystemError } from 'components/onboarding/ConnectSystemError';

const useStyles = makeStyles(() => ({
  root: {
    margin: '30px 40px 0 40px',
    border: '1px #ccc solid',
    padding: '20px',
  },
  inviteLink: {
    backgroundColor: '#EEE',
    color: 'rgba(0,0,0,0.7)',
  },
}));

export const PayrollInvite = () => {
  const classes = useStyles();

  const [contentCopied, setContentCopied] = useState(false);
  const { company } = useContext(CompanyContext);

  const { inviteLink, inviteLinkError } = usePayrollInviteLink(company!);
  const loading = inviteLink === '' && inviteLinkError === '';

  if (loading) {
    return <CircularProgress />;
  }

  if (inviteLinkError !== '') {
    return <ConnectSystemError systemType='payroll' />;
  }

  return (
    <Paper variant='outlined' square className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component='div' variant='body2'>
            No worries. Here&apos;s a secure link that your payroll admin can
            use to connect your payroll software:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{ classes: { disabled: classes.inviteLink } }}
            variant='outlined'
            fullWidth
            disabled
            value={inviteLink}
            className={classes.inviteLink}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CopyToClipboardButton
            textToCopy={inviteLink}
            onTextCopied={() => setContentCopied(true)}
          />
        </Grid>
        <Grid item xs={12} sm container alignContent='center'>
          {contentCopied && <CopiedCheckmark />}
        </Grid>
      </Grid>
    </Paper>
  );
};
