import React, { ChangeEvent, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Alert,
  Button,
  Flex,
  Modal,
  Text,
  TextField,
  Color,
  Link,
  FontAwesome,
} from 'component-library';
import {
  useCompany,
  useTaxCreditsStores,
} from '../../../../../stores/useStores';
import { observer } from 'mobx-react';
import { z } from 'zod';
import { Page } from '../../../../../lib/constants';
import { datadogLogs } from '@datadog/browser-logs';
import { pageToProgramNameMap, surveyNameMapping } from './util';

const useStyles = makeStyles(() => ({
  addAdditionalEmailLink: {
    paddingTop: '6px',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  card: {
    border: `1px solid ${Color.neutral._20}`,
    borderRadius: '4px',
    boxShadow: '0px 0px 4px 0px #0D0D0D14',
  },
  emailAddressTextbox: {
    padding: '4px 0px 4px 0px',
  },
  plusIcon: {
    marginRight: '4px',
  },
}));

interface ShareAssessmentModalProps {
  taxYear: number;
}

export const ShareAssessmentModal = observer(
  ({ taxYear }: ShareAssessmentModalProps) => {
    const { unifiedTaxCredits, surveyFlow } = useTaxCreditsStores();
    const classes = useStyles();
    const { company } = useCompany();
    const [isProcessingAssessmentShare, setIsProcessingAssessmentShare] =
      useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [emailAddressErrors, setEmailAddressErrors] = useState<number[]>([]);
    const [emailAddresses, setEmailAddresses] = useState<string[]>(['']);
    const [showMore, setShowMore] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const logger = datadogLogs.createLogger('share-assessment-modal');

    const handleCloseModal = () => {
      unifiedTaxCredits.setShowShareAssessmentModal(false);

      setEmailAddressErrors([]);
      setError(false);
      setEmailAddresses(['']);
      setShowMore(false);
      setShowSuccessModal(false);
    };

    const handleShowMore = () => setShowMore(!showMore);

    const handleUpdateEmail = (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number,
    ) => {
      const newEmailAddresses = [...emailAddresses];
      newEmailAddresses[index] = event.target.value;
      setEmailAddresses(newEmailAddresses);
    };

    const handleShareAssessment = async () => {
      setIsProcessingAssessmentShare(true);

      const invalidEmails = emailAddresses.filter(
        (emailAddress) => emailAddress.length > 1,
      );

      const EmailAddressSchema = z.array(z.string().email('Invalid Email'));
      const parsedEmailAddresses = EmailAddressSchema.safeParse(invalidEmails);

      if (!parsedEmailAddresses.success) {
        const errorIndexes: number[] = parsedEmailAddresses.error.errors.map(
          (e) => {
            const errorIndex = e.path[0];
            if (typeof errorIndex === 'string') {
              return parseInt(errorIndex);
            } else {
              return errorIndex;
            }
          },
        );
        setEmailAddressErrors(errorIndexes);
      } else if (!unifiedTaxCredits.sourcePage) {
        logger.error(
          `No sourcePage available. Can't share assessment to ${invalidEmails.join(
            ', ',
          )} for companyId: ${company.id}.`,
        );
        setError(true);
      } else {
        await unifiedTaxCredits.shareAssessmentPage(
          taxYear,
          unifiedTaxCredits.sourcePage,
          invalidEmails,
        );

        setShowSuccessModal(true);
      }

      setIsProcessingAssessmentShare(false);
    };

    const handleSuccessModalOnContinue = async () => {
      if (
        unifiedTaxCredits.sourcePage &&
        unifiedTaxCredits.sourcePage !== Page.assessmentBusinessDetails &&
        unifiedTaxCredits.shareAssessmentOnNext
      ) {
        const programName = pageToProgramNameMap[unifiedTaxCredits.sourcePage];
        const programId = company.programs.find(
          (p) => p.name === programName && p.taxYear === taxYear,
        )?.id;

        await surveyFlow.onNextShareAssessment(
          taxYear,
          programId,
          programName,
          unifiedTaxCredits.shareAssessmentOnNext,
        );
      }

      handleCloseModal();
    };

    const hasAtLeastOneEmail = !emailAddresses.some(
      (emailAddress) => emailAddress.length > 0,
    );

    const surveyName = unifiedTaxCredits.sourcePage
      ? surveyNameMapping[unifiedTaxCredits.sourcePage]
      : '';

    const renderEmailEntryModal = (
      <>
        <Flex direction='column' padding={[0, 0, 12, 0]}>
          <Text size={18} variant='bold'>
            Share &lsquo;{surveyName}&rsquo; Assessment
          </Text>
          <Text size={15}>
            {`Invite someone else to ${
              unifiedTaxCredits.sourcePage === Page.assessmentBusinessDetails
                ? 'review'
                : 'help with'
            } your ${surveyName} assessment. They
              don’t need an account, and will only be able to see the information
              relating to this assessment.`}
          </Text>
          <Flex padding={[8, 0, 0, 0]} direction='column'>
            {showMore && (
              <Text size={15}>
                If you have already provided information and/or connected your
                payroll system, anyone you invite may see:
                <ul>
                  <li>Access will expire in 7 days</li>
                  <li>
                    Information about your spend on increasing accessibility
                  </li>
                  <li>High-level employment details</li>
                  <li>High-level financial details</li>
                </ul>
              </Text>
            )}
            <Link
              size={15}
              text={!showMore ? 'Show more' : 'Show Less'}
              onClick={handleShowMore}
            />
          </Flex>
        </Flex>
        <Flex
          direction={'column'}
          className={classes.card}
          padding={[24, 24, 32, 24]}
          gap={4}
        >
          <Flex direction={'column'}>
            <Text size={18} variant={'medium'}>
              Enter the email address of the person you would like to invite.
            </Text>
            <Text size={15}>
              We will email them with a link to access the &lsquo;{surveyName}
              &rsquo; only.
            </Text>
          </Flex>
          <Flex direction={'column'} padding={[4, 0, 0, 0]}>
            {emailAddresses.map((emailAddress, index) => (
              <TextField
                error={emailAddressErrors.includes(index)}
                className={classes.emailAddressTextbox}
                key={index}
                placeholder={'Enter email'}
                type={'text'}
                onChange={(event) => handleUpdateEmail(event, index)}
                value={emailAddress}
              />
            ))}
            <Link
              size={13}
              underline={false}
              className={classes.addAdditionalEmailLink}
              onClick={() => setEmailAddresses([...emailAddresses, ''])}
            >
              <Flex>
                <FontAwesome
                  name='plus'
                  color='#1A45DB'
                  size={16}
                  className={classes.plusIcon}
                />
                Add another email
              </Flex>
            </Link>
          </Flex>
        </Flex>
        {emailAddressErrors.length > 0 && (
          <Flex padding={[12, 0, 0, 0]}>
            <Alert
              text={`Please correct the incorrect emails before sharing assessment.`}
              variant={'in_card'}
              type={'caution'}
              inCardBorder={'left'}
            />
          </Flex>
        )}
        {error && (
          <Flex padding={[12, 0, 0, 0]}>
            <Alert
              text={`Unable to share assessment. Please contact support@mainstreet.com.`}
              variant={'in_card'}
              type={'danger'}
              inCardBorder={'left'}
            />
          </Flex>
        )}
        <Flex justifyContent='right' gap={12} padding={[32, 0, 0, 0]}>
          <Button
            label='Cancel'
            onClick={handleCloseModal}
            variant='outlined'
          />
          <Button
            label='Share Assessment'
            onClick={handleShareAssessment}
            loading={isProcessingAssessmentShare}
            disabled={hasAtLeastOneEmail}
          />
        </Flex>
      </>
    );

    const renderSuccessModal = () => {
      const validEmails = emailAddresses.filter(
        (emailAddress) => emailAddress.length > 1,
      );

      return (
        <>
          <Flex direction={'column'} alignItems='center' gap={16}>
            <img
              src={`${process.env.PUBLIC_URL}/images/unifiedYEA/check-list.svg`}
            />
            <Flex direction='column' alignItems='center' gap={8}>
              <Text size={23} variant={'medium'} textAlign='center'>
                Your assessment has been shared successfully!
              </Text>
              <Text textAlign={'center'}>
                An email invitation from{' '}
                <Text
                  tag='span'
                  text='support@mainstreet.com'
                  color={Color.blue._60}
                />{' '}
                should have
                <br />
                been sent to:{' '}
                <Text
                  tag='span'
                  text={validEmails.join(', ')}
                  color={Color.blue._60}
                />
              </Text>
            </Flex>
            <Flex gap={16} justifyContent={'center'}>
              <Button
                label='Keep editing'
                variant='outlined'
                onClick={handleCloseModal}
              />
              <Button
                label='Next step'
                onClick={handleSuccessModalOnContinue}
                loading={surveyFlow.shareAssessmentOnNextLoading}
              />
            </Flex>
          </Flex>
        </>
      );
    };

    return (
      <Modal
        showModal={unifiedTaxCredits.showShareAssessmentModal}
        closeToggle={handleCloseModal}
        maxWidth={640}
        rounded
      >
        <>
          <Flex direction='column' padding={24}>
            {!showSuccessModal ? renderEmailEntryModal : renderSuccessModal()}
          </Flex>
        </>
      </Modal>
    );
  },
);
