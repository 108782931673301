import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from 'component-library';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { ConnectCard } from '../ConnectCard';
import { QuestionCard } from '../QuestionCard';
import { ConnectCardHeader } from '../ConnectCardHeader';
import { CardSuccessMessage } from '../CardSuccessMessage';
import { ConnectToPlaid } from './ConnectToPlaid';
import { ManualMicroDepositVerification } from './ManualMicroDepositVerification';
import { ErrorMessage } from '../../../../../components/util/ErrorMessage';

export interface BankingInformation {
  accountName: string;
  lastFourDigits: string;
  status?: string;
}

interface PlaidIntegrationCardProps {
  suggestAccountSwitch?: boolean; // allow user to swap out current connected account
  connectedToPlaid?: boolean;
  description?: string;
  formattedAsQuestionCard?: boolean;
  setConnectedToPlaid?: React.Dispatch<React.SetStateAction<boolean>>;
  confirmationDescription?: string;
  setConnectedBankInfo?: (
    value: React.SetStateAction<BankingInformation | undefined>,
  ) => void;
  setErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
}

export const SwapConnectionButtonText = 'Swap connected account';

export const PlaidIntegrationCard = (props: PlaidIntegrationCardProps) => {
  const auth0Context = useContext(Auth0FeatureContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [setupOrReconnectRequired, setSetupOrReconnectRequired] = useState<
    undefined | 'SETUP' | 'RECONNECT'
  >(undefined);
  const [fetchBankingInfo, setFetchBankingInfo] = useState<boolean>(false);
  const [bankingInformation, setBankingInformation] = useState<
    BankingInformation[]
  >([]);
  const [plaidToken, setPlaidToken] = useState<string>('');
  const [plaidError, setPlaidError] = useState<string>('');

  const deletePlaidLinkToken = () => {
    auth0Context.client.DeletePlaidLinkToken().then((res) => {
      if (res.errorMsg) {
        props.setErrorMessage &&
          props.setErrorMessage(
            'There was an issue deleting connection to Plaid',
          );
        return;
      }
      setBankingInformation([]);
      setPlaidToken('');
      props.setConnectedToPlaid && props.setConnectedToPlaid(false);
    });
  };

  const generatePlaidLinkToken = useCallback(() => {
    auth0Context.client.GeneratePlaidLinkToken().then((res) => {
      if (res.errorMsg) {
        props.setErrorMessage &&
          props.setErrorMessage('There was an issue connecting to Plaid');
        return;
      }
      const { link_token } = res?.data || {};
      if (link_token) {
        setPlaidToken(link_token);
      }
    });
  }, [auth0Context.client, props]);

  useEffect(() => {
    const activeBankingInfo = bankingInformation?.filter(
      ({ status }) => status === 'ACTIVE',
    );
    if (activeBankingInfo?.length) {
      props.setConnectedBankInfo &&
        props.setConnectedBankInfo(activeBankingInfo?.[0]);
      props.setConnectedToPlaid && props.setConnectedToPlaid(true);
    }
  }, [bankingInformation, props]);

  useEffect(() => {
    if (bankingInformation.length === 0 || fetchBankingInfo) {
      auth0Context.client.FetchBankingInformation().then((res) => {
        if (res.errorMsg) {
          setPlaidError(
            'Failed to fetch account info. Please try again or reach out to support@mainstreet.com for help',
          );
        } else {
          const { accounts, setupOrReconnectRequired } = res?.data || {};
          if (setupOrReconnectRequired) {
            generatePlaidLinkToken();
            setSetupOrReconnectRequired(res?.data?.setupOrReconnectRequired);
            props.setConnectedBankInfo && props.setConnectedBankInfo(undefined);
            setLoading(false);
          } else {
            const bankInfo = accounts?.map((account) => {
              return {
                status: account.status,
                accountName: account.name,
                lastFourDigits: account.lastFourDigits,
              };
            });

            if (bankInfo && bankInfo.length !== 0) {
              setFetchBankingInfo(false);
              setBankingInformation(bankInfo);
              setSetupOrReconnectRequired(undefined);
              setLoading(false);
            }

            if (
              bankInfo?.find(
                (b) => b.status === 'MANUAL_MICRO_DEPOSIT_VERIFICATION_PENDING',
              )
            ) {
              setSetupOrReconnectRequired('SETUP');
            }
          }
        }
      });
    }
  }, [
    auth0Context.client,
    bankingInformation,
    fetchBankingInfo,
    generatePlaidLinkToken,
    props,
  ]);

  const header = (
    <ConnectCardHeader
      title={'Connect your bank account'}
      actionRequired={false}
    />
  );

  const getButtonOnActiveConnection = ({
    bankInfo,
  }: {
    bankInfo: BankingInformation;
  }) => {
    if (props.suggestAccountSwitch && bankingInformation.length) {
      return (
        <Button
          label={SwapConnectionButtonText}
          onClick={() => {
            deletePlaidLinkToken();
          }}
          variant='outlined'
        />
      );
    }

    if (props.formattedAsQuestionCard && bankInfo?.accountName) {
      return `Connected`;
    }

    return (
      <CardSuccessMessage
        message={`Successfully connected to ${bankInfo?.accountName} ${bankInfo?.lastFourDigits}`}
      />
    );
  };

  const setupOrReconnectLabelName = (): string => {
    if (setupOrReconnectRequired && setupOrReconnectRequired === 'RECONNECT') {
      return 'Reconnect';
    } else {
      return 'Connect';
    }
  };

  const mainContent = (
    <>
      {bankingInformation.length === 0 && !plaidError ? (
        <ConnectToPlaid
          buttonVariant={props.formattedAsQuestionCard ? 'outlined' : undefined}
          loading={loading}
          labelName={setupOrReconnectLabelName()}
          plaidToken={plaidToken}
          onConnectSuccess={() => setFetchBankingInfo(true)}
          onConnectFail={setPlaidError}
        />
      ) : (
        bankingInformation.map((bankInfo, index) => {
          switch (bankInfo.status) {
            case 'MANUAL_MICRO_DEPOSIT_VERIFICATION_PENDING':
              return (
                <ManualMicroDepositVerification
                  key={`MANUAL_MICRO_DEPOSIT_VERIFICATION_PENDING-${index}`}
                  loading={loading}
                  plaidToken={plaidToken}
                  bankInfo={bankInfo}
                  onSuccess={() => setFetchBankingInfo(true)}
                  onError={setPlaidError}
                />
              );
            case 'AUTOMATED_MICRO_DEPOSIT_VERIFICATION_PENDING':
              return (
                <React.Fragment
                  key={`AUTOMATED_MICRO_DEPOSIT_VERIFICATION_PENDING-${index}`}
                >
                  {props.confirmationDescription || (
                    <CardSuccessMessage
                      message={`Awaiting automated micro-deposit verification for ${bankInfo.accountName} ${bankInfo.lastFourDigits}`}
                    />
                  )}
                </React.Fragment>
              );
            case 'ACTIVE':
              return (
                <React.Fragment key={`ACTIVE-${index}`}>
                  {getButtonOnActiveConnection({ bankInfo })}
                </React.Fragment>
              );

            default:
              return (
                <ConnectToPlaid
                  key={`default-${index}`}
                  loading={loading}
                  labelName={setupOrReconnectLabelName()}
                  plaidToken={plaidToken}
                  onConnectSuccess={() => setFetchBankingInfo(true)}
                  onConnectFail={setPlaidError}
                />
              );
          }
        })
      )}
      {plaidError && (
        <>
          <ErrorMessage error={plaidError} />
        </>
      )}
    </>
  );

  return props.formattedAsQuestionCard ? (
    <QuestionCard
      confirmed={props.connectedToPlaid && !props.suggestAccountSwitch}
      description={props.description}
      confirmedMessage={props.confirmationDescription}
      content={mainContent}
    />
  ) : (
    <ConnectCard header={header} mainContent={mainContent} />
  );
};

export default PlaidIntegrationCard;
