import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Divider,
  FileUpload,
  Flex,
  Grid,
  SideDrawer,
  Table,
  Text,
  TextField,
} from 'component-library';
import { CentsToDisplayString } from 'lib/helpers';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import { FileTypes } from 'lib/constants';

const useStyles = makeStyles(() => ({
  uploader: {
    maxWidth: '600px',
  },
}));

interface EnterPayrollInfoProps {
  pathTo: string;
  onNext: () => void;
  isPrefilled: boolean;
}

interface TableData {
  [key: string]: string | number;
}

const initialData: TableData[] = [
  {
    fullname: 'Adrain Mitchell',
    q2_2020: CentsToDisplayString(1500000),
    q3_2020: CentsToDisplayString(1250000),
    q4_2020: CentsToDisplayString(5230000),
    q1_2021: CentsToDisplayString(1590000),
    q2_2021: CentsToDisplayString(2510000),
    q3_2021: CentsToDisplayString(3650000),
  },
  {
    fullname: 'Price Hackett',
    q2_2020: CentsToDisplayString(1522000),
    q3_2020: CentsToDisplayString(1422000),
    q4_2020: CentsToDisplayString(3652000),
    q1_2021: CentsToDisplayString(1452000),
    q2_2021: CentsToDisplayString(4125000),
    q3_2021: CentsToDisplayString(4521000),
  },
  {
    fullname: 'June Goodwin',
    q2_2020: CentsToDisplayString(1524000),
    q3_2020: CentsToDisplayString(1635000),
    q4_2020: CentsToDisplayString(1524000),
    q1_2021: CentsToDisplayString(1365000),
    q2_2021: CentsToDisplayString(1652000),
    q3_2021: CentsToDisplayString(3652000),
  },
];

const initialDataEmpty: TableData[] = [
  {
    fullname: 'Adrain Mitchell',
    q2_2020: CentsToDisplayString(0),
    q3_2020: CentsToDisplayString(0),
    q4_2020: CentsToDisplayString(0),
    q1_2021: CentsToDisplayString(0),
    q2_2021: CentsToDisplayString(0),
    q3_2021: CentsToDisplayString(0),
  },
  {
    fullname: 'Price Hackett',
    q2_2020: CentsToDisplayString(0),
    q3_2020: CentsToDisplayString(0),
    q4_2020: CentsToDisplayString(0),
    q1_2021: CentsToDisplayString(0),
    q2_2021: CentsToDisplayString(0),
    q3_2021: CentsToDisplayString(0),
  },
  {
    fullname: 'June Goodwin',
    q2_2020: CentsToDisplayString(0),
    q3_2020: CentsToDisplayString(0),
    q4_2020: CentsToDisplayString(0),
    q1_2021: CentsToDisplayString(0),
    q2_2021: CentsToDisplayString(0),
    q3_2021: CentsToDisplayString(0),
  },
];

export const EnterPayrollInfo = observer(
  ({ pathTo, onNext, isPrefilled }: EnterPayrollInfoProps) => {
    const classes = useStyles();
    const history = useHistory();
    const [showDrawer, setShowDrawer] = useState<string>('');
    const [data, setData] = useState<TableData[]>(
      isPrefilled ? initialData : initialDataEmpty,
    );
    const [updateData, setUpdateData] = useState<TableData[]>(
      isPrefilled ? initialData : initialDataEmpty,
    );

    const handleOnContinue = () => {
      onNext();
      history.push(pathTo);
    };

    const handleOnTableChange = (
      index: number,
      key: string,
      newValue: number,
    ): void => {
      const updatedArray = updateData.map((obj, i) =>
        i === index ? { ...obj, [key]: newValue } : obj,
      );
      setUpdateData(updatedArray);
    };

    const onCloseEdit = () => {
      const formatData = updateData.map((obj) => {
        const updatedObj: TableData = {};
        for (const key in obj) {
          if (typeof obj[key] === 'number') {
            const convert = (obj[key] as number) * 100;
            updatedObj[key] = CentsToDisplayString(convert);
          } else {
            updatedObj[key] = obj[key];
          }
        }
        return updatedObj;
      });

      setData(formatData);
    };

    return (
      <SurveyFlowContainer
        title='Enter payroll information'
        subtitle="We used your payroll integration wherever possible to pre-fill your employees' taxable pay for each quarter. Please confirm or edit these values."
        onContinue={handleOnContinue}
        onBack={() => console.log('Clicked back')}
        dataTestId='erc-qualify-survey'
      >
        <>
          <Card noMargin>
            <Table
              data={data}
              columns={[
                {
                  Header: 'Full name',
                  accessor: 'fullname', // accessor is the "key" in the data
                  textBold: true,
                  width: 250,
                },
                {
                  Header: 'Q2 2020',
                  accessor: 'q2_2020',
                  width: 100,
                  textAlignRight: true,
                  disableSortBy: true,
                },
                {
                  Header: 'Q3 2020',
                  accessor: 'q3_2020',
                  width: 100,
                  textAlignRight: true,
                  disableSortBy: true,
                },
                {
                  Header: 'Q4 2020',
                  accessor: 'q4_2020',
                  width: 100,
                  textAlignRight: true,
                  disableSortBy: true,
                },
                {
                  Header: 'Q1 2021',
                  accessor: 'q1_2021',
                  width: 100,
                  textAlignRight: true,
                  disableSortBy: true,
                },
                {
                  Header: 'Q2 2021',
                  accessor: 'q2_2021',
                  width: 100,
                  textAlignRight: true,
                  disableSortBy: true,
                },
                {
                  Header: 'Q3 2021',
                  accessor: 'q3_2021',
                  width: 100,
                  textAlignRight: true,
                  disableSortBy: true,
                },
              ]}
              rowOnClick={(values) => setShowDrawer(values.fullname)}
            />
            {updateData.map((item, i) => {
              return (
                <SideDrawer
                  key={i}
                  show={item.fullname === showDrawer}
                  closeToggle={() => {
                    setShowDrawer('');
                    onCloseEdit();
                  }}
                  title='Edit payroll information'
                  drawerActions={
                    <>
                      <Button
                        label='Close'
                        variant='outlined'
                        onClick={() => {
                          setShowDrawer('');
                          onCloseEdit();
                        }}
                      />
                    </>
                  }
                  drawerContent={
                    <Grid gap={24}>
                      <Grid.Cell columns={3}>
                        <Text text={'Name: '} />
                      </Grid.Cell>
                      <Grid.Cell columns={8}>
                        <Text variant='medium' text={item.fullname} />
                      </Grid.Cell>
                      <Grid.Cell columns={3}>
                        <Text text='Q2 2020:' />
                      </Grid.Cell>
                      <Grid.Cell columns={8}>
                        <TextField
                          key={`${i}-q2-2020`}
                          value={item.q2_2020}
                          onChange={(e) => {
                            handleOnTableChange(
                              i,
                              'q2_2020',
                              Number(e.target.value.replace(/,/g, '')),
                            );
                          }}
                          small
                          type='number'
                          currencyFormat
                        />
                      </Grid.Cell>
                      <Grid.Cell columns={3}>
                        <Text text='Q3 2020:' />
                      </Grid.Cell>
                      <Grid.Cell columns={8}>
                        <TextField
                          key={`${i}-q3-2020`}
                          value={item.q3_2020}
                          onChange={(e) => {
                            handleOnTableChange(
                              i,
                              'q3_2020',
                              Number(e.target.value.replace(/,/g, '')),
                            );
                          }}
                          small
                          type='number'
                          currencyFormat
                        />
                      </Grid.Cell>
                      <Grid.Cell columns={3}>
                        <Text text='Q4 2020:' />
                      </Grid.Cell>
                      <Grid.Cell columns={8}>
                        <TextField
                          key={`${i}-q4-2020`}
                          value={item.q4_2020}
                          onChange={(e) => {
                            handleOnTableChange(
                              i,
                              'q4_2020',
                              Number(e.target.value.replace(/,/g, '')),
                            );
                          }}
                          small
                          type='number'
                          currencyFormat
                        />
                      </Grid.Cell>
                      <Grid.Cell columns={3}>
                        <Text text='Q1 2021:' />
                      </Grid.Cell>
                      <Grid.Cell columns={8}>
                        <TextField
                          key={`${i}-q1-2021`}
                          value={item.q1_2021}
                          onChange={(e) => {
                            handleOnTableChange(
                              i,
                              'q1_2021',
                              Number(e.target.value.replace(/,/g, '')),
                            );
                          }}
                          small
                          type='number'
                          currencyFormat
                        />
                      </Grid.Cell>
                      <Grid.Cell columns={3}>
                        <Text text='Q2 2021:' />
                      </Grid.Cell>
                      <Grid.Cell columns={8}>
                        <TextField
                          key={`${i}-q2-2021`}
                          value={item.q2_2021}
                          onChange={(e) => {
                            handleOnTableChange(
                              i,
                              'q2_2021',
                              Number(e.target.value.replace(/,/g, '')),
                            );
                          }}
                          small
                          type='number'
                          currencyFormat
                        />
                      </Grid.Cell>
                      <Grid.Cell columns={3}>
                        <Text text='Q3 2021:' />
                      </Grid.Cell>
                      <Grid.Cell columns={8}>
                        <TextField
                          key={`${i}-q3-2021`}
                          value={item.q3_2021}
                          onChange={(e) => {
                            handleOnTableChange(
                              i,
                              'q3_2021',
                              Number(e.target.value.replace(/,/g, '')),
                            );
                          }}
                          small
                          type='number'
                          currencyFormat
                        />
                      </Grid.Cell>
                    </Grid>
                  }
                />
              );
            })}
          </Card>
          <Flex className={classes.uploader} direction='column' gap={24}>
            <Divider variant='no-bottom-margin' />
            <Text>
              If you prefer, you may instead upload a CSV formatted exactly as
              shown above.
            </Text>
            <FileUpload
              flexDirection='column'
              acceptedFiletypes={FileTypes.CSV}
              title={
                <Flex direction='column' gap={8}>
                  <Text variant='medium' size={18}>
                    Upload your employees&apos; taxable pay
                  </Text>
                  <Text>
                    Upload a CSV containing one column listing your
                    employees&apos; names, then one column for each
                    quarter&apos;s taxable wages.
                  </Text>
                </Flex>
              }
              onFileAdded={(file, allFiles) => {
                console.log(`New File Added!`, file, allFiles);

                let timerId: NodeJS.Timeout | undefined = setInterval(() => {
                  file.setUploadProgress(
                    file.visualProgress + Math.random() * 0.5,
                    0,
                    0,
                  );
                  if (file.uploadCompletedAt && timerId) {
                    clearInterval(timerId);
                    timerId = undefined;
                  }
                }, 1000);

                file.abortController?.signal.addEventListener(`abort`, () => {
                  if (timerId) {
                    clearInterval(timerId);
                    timerId = undefined;
                  }
                });
              }}
              onFileCancelled={(file, allFiles) =>
                console.log(`File Cancelled!`, file, allFiles)
              }
              onFileRemoved={(file, allFiles) =>
                console.log(`File Removed!`, file, allFiles)
              }
              onFileProgressCompleted={(file, allFiles) => {
                console.log(`File Progress Completed!`, file, allFiles);
              }}
            />
          </Flex>
        </>
      </SurveyFlowContainer>
    );
  },
);
