import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Content,
  Color,
  Dollar,
  Heading,
  Icon,
  IconEnum,
} from 'component-library';
import { CompanyContext } from 'pages/CompanyRequired';
import { ProgramData } from 'lib/interfaces';
import {
  ProgramNameEnum,
  QualificationStatusEnum,
  Programs,
} from 'lib/constants';
import { CentsToDisplayStringNoSymbol } from 'lib/helpers';

const useStyles = makeStyles(() => ({
  twoRows: {
    flexWrap: 'wrap',

    '& > div:first-child': {
      flex: '0 0 100%',
      fontSize: '24px',
    },

    '& > div:nth-child(2)': {
      display: 'none',
    },
  },
  dollar: {
    fontSize: '40px !important',
    lineHeight: '1.4 !important',
  },
  programCredit: {
    fontSize: '16px',
    '& > h1': {
      fontSize: '1em !important',
      lineHeight: '1.6 !important',
    },
    '& h1:nth-child(2)': {
      fontSize: '1.5em !important',
    },
  },
  creditTitle: {
    whiteSpace: 'nowrap',
  },
  additional: {
    marginTop: '28px',
    '&:last-child': {
      display: 'none',
    },
  },
  plus: {
    transform: 'rotate(45deg)',
  },
}));

interface ProgramCreditProps {
  programId: string;
}

export const ProgramCredits = ({ programId }: ProgramCreditProps) => {
  const classes = useStyles();
  const { company } = useContext(CompanyContext);

  const currentProgramYear = company?.programs.find(
    (program) => program.id.toString() === programId,
  )?.taxYear;

  const currentTaxYearPrograms = company?.programs.filter(
    (program: ProgramData) => {
      const isStateRDProgram =
        program.name === ProgramNameEnum.FED_RD_TAX ||
        program.name === ProgramNameEnum.STATE_RD_CA ||
        program.name === ProgramNameEnum.STATE_RD_AZ ||
        program.name === ProgramNameEnum.STATE_RD_GA ||
        program.name === ProgramNameEnum.STATE_RD_MA;
      return program.taxYear === currentProgramYear && isStateRDProgram;
    },
  );

  const availableCredits = currentTaxYearPrograms.filter((program) => {
    return (
      program.qualificationStatus !== QualificationStatusEnum.DISQUALIFIED &&
      program.creditAmountCents > 0
    );
  });

  const renderProgramCredit = (program: ProgramData, key: number) => {
    return (
      <React.Fragment key={`${key}-${program.id}`}>
        <Content
          flex
          gap={0}
          flexDirection='column'
          alignItems='center'
          paddingTopBottom={0}
          paddingLeftRight={0}
          className={availableCredits.length === 5 ? classes.programCredit : ''}
        >
          <Heading
            className={classes.creditTitle}
            text={Programs[program.name].display}
            variant='italic'
            size={availableCredits.length === 4 ? 16 : 24}
            marginBottom={0}
            color={Color.neutral.white}
          />
          <Dollar
            className={availableCredits.length !== 4 ? classes.dollar : ''}
            tag={availableCredits.length === 4 ? 'h2' : 'h1'}
            value={CentsToDisplayStringNoSymbol(program.creditAmountCents)}
            variant='medium'
            color={Color.neutral.white}
          />
        </Content>
        {availableCredits.length > 1 && (
          <div className={classes.additional}>
            <Icon
              className={classes.plus}
              name={IconEnum.x_mark}
              color={Color.neutral.white}
              size={32}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <Content
      flex
      gap={8}
      justifyContent='center'
      paddingTopBottom={availableCredits.length === 5 ? 24 : 40}
      paddingLeftRight={8}
      bgColor={Color.green._70}
      className={availableCredits.length === 5 ? classes.twoRows : ''}
    >
      {availableCredits.map((program: ProgramData, key: number) =>
        renderProgramCredit(program, key),
      )}
    </Content>
  );
};
