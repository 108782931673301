import React from 'react';
import { observer } from 'mobx-react';
import { TextField } from 'component-library';
import { useTaxCreditsStores } from 'stores/useStores';
import { ProgramData } from 'lib/interfaces';

export interface FilingDateTextFieldProps {
  federalRDProgram: ProgramData;
  taxFilingDate: string;
  setTaxFilingDate?: (str: string) => void;
  handleTaxFilingDateInput?: (str: string, taxYear: number) => void;
  filingDateError: boolean;
  hasFiledCheck?: boolean;
}

export const FilingDateTextField = observer(
  ({
    federalRDProgram,
    taxFilingDate,
    setTaxFilingDate,
    handleTaxFilingDateInput,
    filingDateError,
    hasFiledCheck,
  }: FilingDateTextFieldProps) => {
    const { form8974 } = useTaxCreditsStores();
    const { taxYear } = federalRDProgram;

    const handleUpdateTaxFilingDate = (value: string) => {
      if (taxYear && handleTaxFilingDateInput) {
        handleTaxFilingDateInput(value, taxYear);
      } else if (setTaxFilingDate) {
        setTaxFilingDate(value);
      }

      form8974.setTaxFilingDate(value);
    };

    const filingDateFilledOut =
      !!taxFilingDate &&
      taxFilingDate.length !== 0 &&
      !taxFilingDate.includes('_');

    return (
      <TextField
        dataTestId='tax-filing-date'
        label={taxYear ? `${taxYear} tax filing date` : 'Filing date'}
        dateFormat
        placeholder={'MM/DD/YYYY'}
        value={taxFilingDate}
        onChange={(e) => handleUpdateTaxFilingDate(e.target.value)}
        error={filingDateError && filingDateFilledOut}
        helperText={
          filingDateError && filingDateFilledOut
            ? `Your ${taxYear} filing date must be in or after ${
                taxYear + 1
              }, but cannot be in the future.`
            : `Please confirm the date you submitted your ${taxYear} tax return to the IRS. This date must be in ${
                taxYear + 1
              } or later.`
        }
        disabled={hasFiledCheck}
      />
    );
  },
);
