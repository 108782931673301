import React from 'react';
import { Text, Content, Card, Color, Button } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { BankAccount } from '@mainstreet/client-models/financial/banking/bankingModels';
import { CapitalizeFirstLetter, CentsToDisplayString } from 'lib/helpers';
import { Days } from 'lib/constants';

const useStyles = makeStyles(() => ({
  transferTableCell: {
    height: '4rem',
  },
  reviewCard: {
    backgroundColor: Color.neutral._light_20,
  },
}));

interface TransferSummaryProps {
  account: BankAccount;
  amountCents: number;
  onEditClick: () => void;
  remainingBalance: number | null;
  scheduledDateString: string;
  transferIn: boolean;
}

export const TransferSummary = ({
  account,
  amountCents,
  onEditClick,
  remainingBalance,
  scheduledDateString,
  transferIn,
}: TransferSummaryProps) => {
  const classes = useStyles();

  const accountText = `${
    account?.institutionName || ''
  } - ${CapitalizeFirstLetter(account.accountSubType)} •••${account.mask}`;
  const scheduledDate = new Date(scheduledDateString);
  const scheduledDateStringFormatted = `${
    Days[scheduledDate.getDay()]
  }, ${scheduledDate.toLocaleDateString()}`;

  return (
    <div>
      <Content paddingLeftRight={24}>
        <Card className={classes.reviewCard}>
          <Content
            paddingLeftRight={24}
            paddingTopBottom={16}
            flex
            justifyContent='space-between'
          >
            <div>
              <Text text='Amount' variant='medium' />
              <Text text={CentsToDisplayString(amountCents, 2, 2)} />
            </div>
            <Button
              label='Edit'
              onClick={() => onEditClick()}
              variant='tertiary'
            />
          </Content>
        </Card>
        <Text text='Transfer summary' variant='medium' paddingBottom={8} />
        <Card noMargin>
          <Content
            className={classes.transferTableCell}
            paddingLeftRight={16}
            paddingTopBottom={0}
            flex
            justifyContent='space-between'
            alignItems='center'
          >
            <Text text='To' />
            <Text
              variant='medium'
              text={transferIn ? 'MainStreet Yield' : accountText}
            />
          </Content>
        </Card>
        <Card noMargin>
          <Content
            className={classes.transferTableCell}
            alignItems='center'
            flex
            justifyContent='space-between'
            paddingLeftRight={16}
            paddingTopBottom={0}
          >
            <Text text='From' />
            <Text
              variant='medium'
              text={!transferIn ? 'MainStreet Yield' : accountText}
            />
          </Content>
        </Card>
        <Card noMargin>
          <Content
            className={classes.transferTableCell}
            alignItems='center'
            flex
            justifyContent='space-between'
            paddingLeftRight={16}
            paddingTopBottom={0}
          >
            <Text text='Scheduled' />
            <Text text={scheduledDateStringFormatted} />
          </Content>
        </Card>
        <Card noMargin>
          <Content
            className={classes.transferTableCell}
            alignItems='center'
            flex
            justifyContent='space-between'
            paddingLeftRight={16}
            paddingTopBottom={0}
          >
            <Text text={transferIn ? 'Amount' : 'Transfer amount'} />
            <Text text={CentsToDisplayString(amountCents, 2, 2)} />
          </Content>
        </Card>
        {!transferIn && remainingBalance && (
          <Card noMargin>
            <Content
              className={classes.transferTableCell}
              paddingLeftRight={16}
              paddingTopBottom={0}
              flex
              justifyContent='space-between'
              alignItems='center'
            >
              <Text text='Balance after transfer' />
              <Text text={CentsToDisplayString(remainingBalance, 2, 2)} />
            </Content>
          </Card>
        )}
      </Content>
    </div>
  );
};
