import * as React from 'react';
import { useEffect, createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles, Typography } from '@material-ui/core';

import { GRAY } from '../lib/constants';
import { ValidatePayrollAdminLink } from 'services/server';
import StagingBanner from 'components/util/StagingBanner';
import LoadingWidget from 'components/util/LoadingWidget';
import { useFeatureFlags } from 'stores/useStores';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  loading: {
    background: GRAY,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '72px 0',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  bottomBar: {
    borderBottom: 'solid 1px rgba(151, 151, 151, 33%)',
    margin: '0 80px',
  },
  watermark: {
    margin: '40px 0 40px 80px',
    height: '26px',
    width: '150px',
  },
  emptyState: {
    margin: '0 auto',
    marginTop: '50px',
  },
  expireMessage: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export interface TokenContextInterface {
  token: string;
  email: string;
  payrollSystem: string;
  companyName: string;
}

export const TokenContext = createContext({} as TokenContextInterface);

const BasicHeader = observer(() => {
  const classes = useStyles();
  const featureFlags = useFeatureFlags();
  const showNewMSLogo = featureFlags.showNewMainStreetLogo;

  const logoUrl = showNewMSLogo
    ? `${process.env.PUBLIC_URL}/images/logo/mst-logo-green.svg`
    : `${process.env.PUBLIC_URL}/images/MainSt_WordmarkGreen.svg`;

  return (
    <div>
      <StagingBanner />
      <div className={classes.root}>
        <img
          className={classes.watermark}
          src={logoUrl}
          alt='MainStreet watermark'
        />
      </div>
      <div className={classes.bottomBar} />
    </div>
  );
});
const TokenRequired: React.FC = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token') || '';
  const email = urlParams.get('company') || '';

  const [valid, setValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [payrollSystem, setPayrollSystem] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    ValidatePayrollAdminLink(token, email).then((res) => {
      if (res.errorMsg) {
        setLoading(false);
        return;
      }

      setPayrollSystem(res.data?.payrollSystem || '');
      setCompanyName(res.data?.companyName || '');

      setValid(true);
      setLoading(false);
    });
  }, [token, email]);

  return (
    <TokenContext.Provider value={{ token, email, payrollSystem, companyName }}>
      <div className={classes.root}>
        <BasicHeader />
        {loading ? (
          <div className={classes.loading}>
            <LoadingWidget />
          </div>
        ) : valid ? (
          <>{children}</>
        ) : (
          <div className={classes.expireMessage}>
            <Typography
              className={classes.emptyState}
              component='div'
              variant='h1'
            >
              It looks like this link has expired.
            </Typography>
            <Typography component='div' variant='body1'>
              {`Please reach out to your company's account holder to get a new
              link.`}
            </Typography>
          </div>
        )}
      </div>
    </TokenContext.Provider>
  );
};

export default TokenRequired;
