import React from 'react';
import {
  Button,
  Color,
  Flex,
  SideDrawer,
  Spinner,
  Text,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useDashboardStores, useCommonStores } from 'stores/useStores';
import { Heading } from 'component-library';
import { DismissModal, EmptyStateToDo, ToDoCardsContainer } from './components';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  length: {
    width: 24,
    height: 24,
    borderRadius: '12px',
    backgroundColor: Color.neutral._20,
  },
  spinnerContainer: {
    height: 278,
  },
  viewAll: {
    paddingRight: 0,
  },
}));

export const ToDoCardsModule: React.FC = observer(() => {
  const classes = useStyles();
  const { app } = useCommonStores();
  const { modules } = useDashboardStores();

  const toDoCardsLength = modules.toDoCards.length;
  const displayNumberOfToDos = app.isDesktop ? 2 : 3;
  const showMoreToDoCards = toDoCardsLength > displayNumberOfToDos;

  const renderToDoModule = () => {
    return (
      <Flex direction='column' gap={16}>
        <Flex direction='row' alignItems='center'>
          <Flex gap={16}>
            <Heading
              size={23}
              variant='medium'
              text={modules.getStartedString}
              marginBottom={0}
            />
            {showMoreToDoCards && (
              <Flex
                className={classes.length}
                justifyContent='center'
                alignItems='center'
              >
                <Text text={toDoCardsLength} size={13} />
              </Flex>
            )}
          </Flex>
          {showMoreToDoCards && (
            <Button
              label='View all'
              onClick={() => modules.toggleSideDrawer(true)}
              variant='tertiary'
              flexAlignSelf='flex-end'
              className={classes.viewAll}
              dataTestId='view-all-to-do-cards'
            />
          )}
        </Flex>
        <ToDoCardsContainer displayNumberOfToDos={displayNumberOfToDos} />
        <DismissModal />
        <SideDrawer
          show={modules.showSideDrawer}
          title='To-dos'
          closeToggle={() => modules.toggleSideDrawer(false)}
          drawerContent={
            <ToDoCardsContainer
              displayNumberOfToDos={toDoCardsLength}
              showAll
            />
          }
        />
      </Flex>
    );
  };

  return (
    <div className={classes.root} data-testid={'to-do-cards-module'}>
      {modules.loadingCards ? (
        <Flex
          justifyContent='center'
          alignItems='center'
          className={classes.spinnerContainer}
        >
          <Spinner size='small' />
        </Flex>
      ) : (
        <>{toDoCardsLength === 0 ? <EmptyStateToDo /> : renderToDoModule()}</>
      )}
    </div>
  );
});
