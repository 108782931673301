import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ActionRequiredCircle } from 'pages/dashboard/integrations';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #FFA800',
    borderRadius: '20px',
    padding: '8px 10px',
    width: 'fit-content',
  },
  label: {
    textTransform: 'uppercase',
    margin: '0 10px',
    fontSize: '12px',
    letterSpacing: '0.05em',
    color: '#333333',
  },
}));

export const ActionRequiredPill = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ActionRequiredCircle />
      <div className={classes.label}>Required</div>
    </div>
  );
};
