import React, { useState } from 'react';
import { Box, Theme, Typography, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import { JobGroupEnum, JobGroupEnumToString } from 'lib/constants';
import { ButtonLink } from 'components/util/ButtonLink';
import { SectionHeader } from 'components/SectionHeader';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '30px 25px 30px 0',
    display: 'flex',
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  mainBox: {
    paddingRight: '20px',
  },
  rightBox: {
    padding: '30px',
    flex: 5,
  },
  title: {
    fontWeight: 500,
    fontSize: '20px',
    alignContent: 'center',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '16px',
    alignContent: 'center',
  },
  percent: {
    fontWeight: 300,
    fontSize: '20px',
    textAlign: 'center',
    alignContent: 'center',
    color: '#333',
  },
  green: {
    color: theme.emerald,
    fontWeight: 500,
  },
  rightSubHeading: {
    fontWeight: 300,
    fontSize: '12px',
    alignContent: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  jobCategory: {
    marginBottom: '-6px',
  },
  categoryText: {
    fontSize: '14px',
  },
}));

export interface EmployeeHeaderProps {
  name: string;
  jobTitle: string;
  jobType: string;
  jobCategory: string;
  percent: number;
  showRightSide: boolean;
  setJobGroup?: (jobGroup: JobGroupEnum) => void;
}

interface DropdownProps {
  setJobGroup: (jobGroup: JobGroupEnum) => void;
}

export const Dropdown = ({ setJobGroup }: DropdownProps) => {
  const classes = useStyles();

  const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);

  return (
    <Box className={classes.jobCategory}>
      {showAutocomplete ? (
        <Autocomplete
          options={Object.values(JobGroupEnum)}
          getOptionLabel={(option) => JobGroupEnumToString[option]}
          style={{ width: 180 }}
          onChange={(event, value: JobGroupEnum | null) => {
            if (value) {
              setJobGroup(value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.categoryText}
              label='Job category'
              variant='outlined'
              size='small'
            />
          )}
        />
      ) : (
        <ButtonLink
          text={'assign job category'}
          onClick={() => setShowAutocomplete(true)}
        />
      )}
    </Box>
  );
};

export const EmployeeHeader = (props: EmployeeHeaderProps) => {
  const classes = useStyles();

  const rightSideComponent = () => {
    const percentClasses = [classes.percent];

    if (props.percent > 0) {
      percentClasses.push(classes.green);
    }
    return (
      <>
        <Box className={classes.rightBox}>
          <Typography className={percentClasses.join(' ')}>
            {props.percent}%
          </Typography>
          <Typography className={classes.rightSubHeading}>
            Time spent on R&D
          </Typography>
        </Box>
      </>
    );
  };

  const title = () => {
    return (
      <Box className={classes.container}>
        <Box className={classes.mainBox}>
          <Box>
            <Typography className={classes.title}>{props.name}</Typography>
          </Box>
          <Box>
            <Typography className={classes.subtitle}>
              {props.jobType}
              {props.jobTitle && <>&nbsp;&nbsp;•&nbsp;&nbsp;{props.jobTitle}</>}
            </Typography>
          </Box>
        </Box>
        {props.jobCategory === JobGroupEnum.OTHER &&
          !props.showRightSide &&
          props.setJobGroup && <Dropdown setJobGroup={props.setJobGroup} />}
      </Box>
    );
  };

  const rightSide = props.showRightSide ? rightSideComponent : undefined;

  return <SectionHeader titleElement={title} rightSide={rightSide} />;
};
