import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Color, Content, Text, Button, Heading } from 'component-library';
import { useContext } from 'react';
import { CompanyContext } from 'pages/CompanyRequired';
import { Page, Programs, ProgramNameEnum, AT_MS_DOMAIN } from 'lib/constants';
import {
  CreditsEstimateMultiple,
  CreditsEstimateTotalBenefits,
  IncentiveEstimate,
  LoadingCreditEstimate,
} from 'pages/tax-processing/qualify/credit-estimate';
import { ProgramCreditEstimate } from 'lib/interfaces';
import { useHistory } from 'react-router-dom';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { useCommonStores } from 'stores/useStores';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';

const useStyles = makeStyles(
  ({ breakpoints, dividerBorderLight, boxShadowRegular }: Theme) => ({
    container: {
      padding: '24px 0',
    },
    estimateContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: Color.neutral.white,
      boxShadow: boxShadowRegular,
      marginTop: 32,
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: 0,
      },
    },
    availableCredits: {
      display: 'flex',
      flexDirection: 'column',
      flex: 3,
      '& > div:not(:first-child)': {
        borderTop: dividerBorderLight,
        padding: '40px 60px',
        [breakpoints.down('sm')]: {
          padding: '30px 40px',
        },
      },
    },
    credits: {
      padding: '48px',
      [breakpoints.down('sm')]: {
        padding: '24px',
      },
    },
    disclaimer: {
      padding: '40px 60px',
      color: Color.neutral._90,
      marginTop: 'auto',
      '& > p': {
        position: 'relative',
        fontSize: '18px',
      },
      '& > p::before': {
        content: '"*"',
        position: 'absolute',
        left: '-20px',
      },
    },
    creditsTitle: {
      fontStyle: 'italic',
      color: Color.green._70,
    },
    diamondIcon: {
      width: '24px',
    },
  }),
);

interface CreditsEstimateProps {
  qualificationTaxYear: number;
  programCreditEstimates: Array<ProgramCreditEstimate>;
}

export const CreditsEstimate = ({
  qualificationTaxYear,
  programCreditEstimates,
}: CreditsEstimateProps) => {
  const classes = useStyles();
  const { company } = useContext(CompanyContext);
  const { client } = useContext(Auth0FeatureContext);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { app } = useCommonStores();
  const [totalHighEstimate, setTotalHighEstimate] = useState(0);
  const [totalLowEstimate, setTotalLowEstimate] = useState(0);
  const [estimateInitialized, setEstimateInitialized] = useState(false);

  useEffect(() => {
    let highTotalEstimate = 0;
    let lowTotalEstimate = 0;
    setEstimateInitialized(false);

    programCreditEstimates
      .filter((c) => c.name !== ProgramNameEnum.DISASTER_RELIEF)
      .forEach((creditEstimate) => {
        lowTotalEstimate += creditEstimate?.low;
        highTotalEstimate += creditEstimate?.high;
      });

    setTotalHighEstimate(highTotalEstimate);
    setTotalLowEstimate(lowTotalEstimate);
    setEstimateInitialized(true);
  }, [programCreditEstimates]);

  // Disaster relief employee count
  const taxYearString = String(qualificationTaxYear);
  const employeeCount =
    company.misc!.yearlyData &&
    taxYearString in company.misc!.yearlyData &&
    company.misc!.yearlyData[taxYearString].yearStartEmployeeCount;

  // To display disaster relief estimates
  const hasDisasterRelief = programCreditEstimates.find(
    (credit) => credit.name === ProgramNameEnum.DISASTER_RELIEF,
  );
  const ifOnlyDisasterRelief =
    hasDisasterRelief && programCreditEstimates.length === 1;

  // Add all total estimates
  const totalBenefitsLow =
    (employeeCount && hasDisasterRelief
      ? hasDisasterRelief.low * employeeCount
      : 0) + totalLowEstimate;
  const totalBenefitsHigh =
    (employeeCount && hasDisasterRelief
      ? hasDisasterRelief.high * employeeCount
      : 0) + totalHighEstimate;

  // If only Fed R&D credit
  const ifOnlyFedRDCredit =
    programCreditEstimates.find(
      (credit) => credit.name === ProgramNameEnum.FED_RD_TAX,
    ) && programCreditEstimates.length === 1;

  const allCreditsNotDisasterRelief = programCreditEstimates.filter(
    (credit) => credit.name !== ProgramNameEnum.DISASTER_RELIEF,
  );

  // If only one credit/incentive
  const ifOnlyOneCreditEstimate = programCreditEstimates?.length === 1;

  const submit = () => {
    setLoading(true);
    client
      .UpdateCreditEstimate({ programTaxYear: qualificationTaxYear })
      .then((res) => {
        if (res && res.errorMsg) {
          setLoading(false);
          setError(
            `Error generating order form, please reach out to support${AT_MS_DOMAIN} for assistance.`,
          );

          datadogLogs.logger.error(
            '[CREDIT_ESTIMATE]: Error generating order form',
            logContext({ company, error }),
          );
          return;
        }
        history.push(`/${Page.orderForms}/${qualificationTaxYear}`);
        setLoading(false);
      });
  };

  return !estimateInitialized ? (
    <LoadingCreditEstimate />
  ) : (
    <>
      <div className={classes.container}>
        <div className={classes.estimateContainer}>
          <CreditsEstimateTotalBenefits
            ifOnlyDisasterRelief={
              !!ifOnlyDisasterRelief && ifOnlyDisasterRelief
            }
            hasDisasterRelief={!!hasDisasterRelief && true}
            totalBenefitsLow={totalBenefitsLow}
            totalBenefitsHigh={totalBenefitsHigh}
          />
          <div className={classes.availableCredits}>
            {!ifOnlyDisasterRelief && (
              <div
                className={classes.credits}
                data-testid='available-credit-estimate'
              >
                <Heading
                  className={classes.creditsTitle}
                  tag={app.isMobile ? 'h3' : 'h2'}
                  text={`for ${company?.legalName}`}
                />

                {ifOnlyFedRDCredit ? (
                  <IncentiveEstimate
                    estimateHigh={totalHighEstimate}
                    estimateLow={totalLowEstimate}
                    displayName={Programs[ProgramNameEnum.FED_RD_TAX].display}
                    icon={
                      <img
                        className={classes.diamondIcon}
                        src={`${process.env.PUBLIC_URL}/images/diamond.svg`}
                        alt='MainStreet Diamond'
                      />
                    }
                    topLevel
                  />
                ) : (
                  <CreditsEstimateMultiple
                    creditsEstimates={allCreditsNotDisasterRelief}
                    totalCreditsLow={totalLowEstimate}
                    totalCreditsHigh={totalHighEstimate}
                  />
                )}
              </div>
            )}
            {hasDisasterRelief && (
              <div
                className={classes.credits}
                data-testid='disaster-relief-estimate'
              >
                <Heading
                  className={classes.creditsTitle}
                  tag='h2'
                  text={`for your ${employeeCount} employees`}
                />
                <IncentiveEstimate
                  estimateHigh={hasDisasterRelief.high}
                  estimateLow={hasDisasterRelief.low}
                  displayName={
                    Programs[ProgramNameEnum.DISASTER_RELIEF].display
                  }
                  icon={
                    <img
                      className={classes.diamondIcon}
                      src={`${process.env.PUBLIC_URL}/images/diamond.svg`}
                      alt='MainStreet Diamond'
                    />
                  }
                  topLevel
                  employees
                />
              </div>
            )}

            <div className={classes.disclaimer}>
              <Text>
                {ifOnlyOneCreditEstimate
                  ? 'This estimate is '
                  : 'These estimates are '}{' '}
                non-binding. Our team will review your information and email you
                once complete.
              </Text>
            </div>
          </div>
        </div>
        <Content
          flex
          paddingLeftRight={0}
          paddingTopBottom={40}
          alignItems='center'
        >
          <Button
            label='Continue'
            dataTestId='credit_estimate_continue'
            onClick={submit}
            loading={loading}
            disabled={error.length > 0}
          />
          {error && (
            <>
              <Button
                label='Back to dashboard'
                dataTestId='error-back-to-dashboard'
                onClick={() => history.push(`/${Page.dashboard}`)}
              />
              <Text text={error} status='failed' />
            </>
          )}
        </Content>
      </div>
    </>
  );
};
