import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Callout,
  Color,
  Content,
  Grid,
  Link,
  Text,
  Spacing,
  FontAwesome,
} from 'component-library';
import { CmsQuestionData } from 'lib/interfaces';
import { useFeatureFlags } from '../../../../../stores/useStores';

const useStyles = makeStyles(() => ({
  bulletPoint: {
    display: 'flex',
    justifyContent: 'space-around',
    '& svg': {
      flexBasis: '32px',
      stroke: '#f6f7f2', // info100
      strokeWidth: '1px',
    },
    '& p': {
      flex: 1,
    },
    '& > div': {
      flex: 1,
    },
  },
  bulletPointGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '16px',
    padding: '16px 0',
    '& svg': {
      flexBasis: '32px',
      stroke: '#f6f7f2', // info100
      strokeWidth: '1px',
    },
    '& p': {
      flex: 1,
    },
    '& > div': {
      flex: 1,
    },
  },
  contentWithIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    '& i': {
      marginRight: '11px',
    },
  },
  rdProjectsContainer: {
    '& ul': {
      padding: 0,
    },
  },
}));

interface PrependNonSurveyElementProps {
  question: CmsQuestionData;
  columnPadding: Spacing;
}

const PrependNonSurveyElement = ({
  question,
  columnPadding,
}: PrependNonSurveyElementProps) => {
  const classes = useStyles();
  const featureFlags = useFeatureFlags();
  const [showMoreCloudCallout, setShowMoreCloudCallout] =
    useState<boolean>(true);
  const [showMoreSuppliesCallout, setShowMoreSuppliesCallout] =
    useState<boolean>(true);
  const [showMoreRdBusinessCallout, setShowMoreRdBusinessCallout] =
    useState<boolean>(true);

  const ContentWithIcon: React.FC<{
    text: React.ReactElement;
    isSuccess?: boolean;
  }> = ({ text, isSuccess }) => {
    return (
      <div className={classes.contentWithIcon}>
        <FontAwesome
          name={isSuccess ? 'circle-check sharp' : 'circle-xmark sharp'}
          variant='solid'
          color={
            isSuccess ? Color.semantic.$success50 : Color.semantic.$error50
          }
          size={18}
        />
        {text}
      </div>
    );
  };

  const cloudExpensesTitle = 'Cloud Computing';
  const cloudExpensesSubTitle =
    'On average, our customers claim an additional $5,000 in R&D credit by including their cloud computing (rented server) expenses. We will use your data from this section to make sure you get every credit dollar you deserve.';
  const renderCloudEligibilitySection = () => {
    return (
      <Grid columns={12} padding={[0, 0, 16, 0]}>
        <Grid.Cell columns={4} padding={[0, columnPadding, 0, 0]}>
          <Text
            text={cloudExpensesTitle}
            size={23}
            variant={'medium'}
            paddingBottom={16}
          />
          <Text text={cloudExpensesSubTitle} paddingBottom={40} />
        </Grid.Cell>
        <Grid.Cell columns={8}>{renderCloudEligibility()}</Grid.Cell>
      </Grid>
    );
  };

  const renderCloudEligibility = () => {
    return (
      <Callout
        title='R&D eligibility of Cloud Expenses'
        hasShowMore
        showMore={showMoreCloudCallout}
        showMoreOnClick={() => setShowMoreCloudCallout(!showMoreCloudCallout)}
        color={Color.blue._60}
        cta={
          <Link
            external
            href={`https://mainstreet1.my.site.com/help/s/article/Cloud-Computing-Expenses`}
            variant='medium'
            text='More about cloud expenses'
          />
        }
      >
        <>
          <Text variant='medium' paddingBottom={16}>
            Cloud expenses that may be eligible for R&D credits
          </Text>
          <div className={classes.bulletPoint}>
            <ContentWithIcon
              isSuccess
              text={
                <Text variant='medium'>
                  Servers rented in the US
                  <br />
                  <Text tag='span'>Eg: AWS, GCP, Microsoft Azure</Text>
                </Text>
              }
            />
            <ContentWithIcon
              isSuccess
              text={
                <Text variant='medium'>
                  {`Servers are off your company's premises`}
                </Text>
              }
            />
            <ContentWithIcon
              isSuccess
              text={
                <Text variant='medium'>
                  Servers for which you are the primary user
                </Text>
              }
            />
          </div>
          <Text variant='medium' paddingBottom={16}>
            Cloud expenses that are not eligible for R&D credits
          </Text>

          <div className={classes.bulletPoint}>
            <ContentWithIcon
              text={
                <Text variant='medium'>
                  Cloud-based softwares
                  <br />
                  <Text tag='span'>Eg: Heroku, Salesforce, Oracle</Text>
                </Text>
              }
            />
            <ContentWithIcon
              text={<Text variant='medium'>Servers rented overseas</Text>}
            />
            <ContentWithIcon
              text={<Text variant='medium'>Computers you own</Text>}
            />
          </div>
        </>
      </Callout>
    );
  };

  const supplyExpensesTitle = 'Supplies (tangible items)';
  const supplyExpensesSubTitle =
    'Most companies that develop physical goods use supplies that qualify towards the R&D credit. If your company has these expenses, including them here could increase your final credit amount by over 15%.';
  const renderSupplyEligibilitySection = () => {
    return (
      <Grid columns={12} padding={[0, 0, 16, 0]}>
        <Grid.Cell columns={4} padding={[0, columnPadding, 0, 0]}>
          <Text
            text={supplyExpensesTitle}
            size={23}
            variant={'medium'}
            paddingBottom={16}
          />
          <Text text={supplyExpensesSubTitle} paddingBottom={40} />
        </Grid.Cell>
        <Grid.Cell columns={8}>{renderSupplyEligibility()}</Grid.Cell>
      </Grid>
    );
  };

  const renderSupplyEligibility = () => {
    return (
      <Callout
        title='R&D eligibility of supplies (tangible items)'
        hasShowMore
        showMore={showMoreSuppliesCallout}
        showMoreOnClick={() =>
          setShowMoreSuppliesCallout(!showMoreSuppliesCallout)
        }
        color={Color.blue._60}
        cta={
          <Link
            external
            href={`https://mainstreet1.my.site.com/help/s/article/What-do-you-mean-by-supplies`}
            variant='medium'
            text='More about qualifying supplies'
          />
        }
      >
        <>
          <Text variant='medium'>
            Common supplies that are eligible for R&D
            <br />
            <Text tag='span'>
              These are tangible items used for experimenting, prototyping, and
              researching. For example:
            </Text>
          </Text>
          <Content
            flex
            paddingTopBottom={16}
            justifyContent='space-around'
            className={classes.bulletPoint}
          >
            <ContentWithIcon
              isSuccess
              text={
                <Text variant='medium'>
                  Materials for Prototyping
                  <br />
                  <Text tag='span'>Eg: 3D printer filament</Text>
                </Text>
              }
            />
            <ContentWithIcon
              isSuccess
              text={
                <Text variant='medium'>
                  Lab Supplies
                  <br />
                  <Text tag='span'>
                    As long as they are not used for production
                  </Text>
                </Text>
              }
            />
          </Content>
          <Text variant='medium'>
            Common supplies expenses that are not eligible for R&D
            <br />
            <Text tag='span'>
              Anything intangible, used for production, or with a useful longer
              life
            </Text>
          </Text>

          <div className={classes.bulletPointGrid}>
            <ContentWithIcon
              text={
                <Text variant='medium'>
                  Office Supplies
                  <br />
                  <Text tag='span'>Eg: Pens, Chairs, Etc.</Text>
                </Text>
              }
            />
            <ContentWithIcon
              text={
                <Text variant='medium'>
                  Depreciable assets
                  <br />
                  <Text tag='span'>Eg: 3D Printers, Forklifts, Etc.</Text>
                </Text>
              }
            />
            <ContentWithIcon
              text={<Text variant='medium'>Software licenses</Text>}
            />

            <ContentWithIcon
              text={<Text variant='medium'>Computers or Laptops</Text>}
            />

            <ContentWithIcon
              text={<Text variant='medium'>Materials Used for Production</Text>}
            />
          </div>
        </>
      </Callout>
    );
  };

  const projectExpensesTitle = 'R&D Business Components';
  const projectExpensesSubTitle =
    'Both MainStreet and your tax preparer may use this information to perform our due diligence, but it won’t be included in the forms you’ll submit to the IRS.';
  const renderRDProjectEligibilitySection = () => {
    return (
      <Grid columns={12} padding={[0, 0, 16, 0]}>
        <Grid.Cell columns={4} padding={[0, columnPadding, 0, 0]}>
          <Text
            text={projectExpensesTitle}
            size={23}
            variant={'medium'}
            paddingBottom={16}
          />
          <Text text={projectExpensesSubTitle} paddingBottom={40} />
        </Grid.Cell>
        <Grid.Cell columns={8}>{renderRDProjectEligibility()}</Grid.Cell>
      </Grid>
    );
  };

  const renderRDProjectEligibility = () => {
    return (
      <Callout
        title='Eligibility of R&D business components or projects'
        color={Color.blue._60}
        hasShowMore
        showMore={showMoreRdBusinessCallout}
        showMoreOnClick={() =>
          setShowMoreRdBusinessCallout(!showMoreRdBusinessCallout)
        }
        className={classes.rdProjectsContainer}
      >
        <Text tag='span'>
          <ul>
            <li>
              An R&D business component is a distinct business effort where the
              majority of activities constitute a process of experimentation.
            </li>
            <li>
              Many businesses have one major business component. The IRS defines
              a business component as a product, process, computer software,
              technique, formula, or invention.
            </li>
          </ul>
        </Text>
      </Callout>
    );
  };

  const renderSwitch = (question: CmsQuestionData) => {
    switch (question.id) {
      case 'ckx9dblqw87bd0b717f2xjng7':
        return featureFlags.showRevampedSuppliesAndServices
          ? renderCloudEligibilitySection()
          : renderCloudEligibility();
      case 'ckx9e9xuw8rf50d712xnzuove':
        return featureFlags.showRevampedSuppliesAndServices
          ? renderSupplyEligibilitySection()
          : renderSupplyEligibility();
      case 'ckxgbenbc349s0e286inhai0w':
        return featureFlags.showRevampedSuppliesAndServices
          ? renderRDProjectEligibilitySection()
          : renderRDProjectEligibility();
      default:
        return <></>;
    }
  };
  return <>{renderSwitch(question)}</>;
};

export default PrependNonSurveyElement;
