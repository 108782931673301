import CheckIcon from '@material-ui/icons/Check';
import { Typography, Theme } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints, green }: Theme) => ({
  contentCopied: {
    color: green,
    fontWeight: 700,
    fontSize: '18px',
    width: '100%',
    [breakpoints.down('xs')]: {
      fontSize: '16px',
      textAlign: 'center',
    },
  },
  checkIcon: {
    verticalAlign: 'middle',
  },
}));

export const CopiedCheckmark = () => {
  const classes = useStyles();

  return (
    <Typography
      component='div'
      variant='body1'
      className={classes.contentCopied}
    >
      <CheckIcon className={classes.checkIcon} /> Copied
    </Typography>
  );
};
