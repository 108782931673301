import { runInAction } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import { BaseDashboardStore } from '../../dashboard/BaseDashboardStore';
import {
  ERCQualificationStatusEnum,
  ERCQualifyingQuestions,
  ERCQualifyingQuestionsToAnswers,
  ERCStepsIndex,
  Page,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationStatusEnum,
} from 'lib/constants';
import {
  ERCCreditEstimateResponse,
  QualificationQuestions,
} from 'lib/interfaces';
import { logContext } from 'logging';
import { datadogLogs } from '@datadog/browser-logs';

export class ERCStore extends BaseDashboardStore {
  public ERCCurrentIndex = 0;
  public ERCPrevIndex = 0;
  public loadingEligibility = false;
  public hasUploadedTaxReturn = false;
  public hasUploadedPayrollTaxReturn = false;

  public showQualificationStatusModal = false;
  public qualificationStatus: ERCQualificationStatusEnum | null = null;

  public creditEstimate: ERCCreditEstimateResponse | null = null;
  public modalContinueIsLoading = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public setERCFlowIndex(step: number) {
    runInAction(() => {
      if (this.ERCCurrentIndex !== this.ERCPrevIndex) {
        this.ERCPrevIndex = this.ERCCurrentIndex;
      }
      this.ERCCurrentIndex = step;
    });
  }

  public setCurrentStepOnLoad(pathname: string) {
    runInAction(() => {
      switch (pathname) {
        case `/${Page.taxCredits}/${Page.erc}`:
          this.setERCFlowIndex(ERCStepsIndex.QUALIFY_FOR_ERC);
          break;
        case `/${Page.taxCredits}/${Page.erc}/${Page.ercUploadTaxReturn}`:
          this.setERCFlowIndex(ERCStepsIndex.UPLOAD_TAX_RETURN);
          break;
        case `/${Page.taxCredits}/${Page.erc}/${Page.ercConnectToPayroll}`:
          this.setERCFlowIndex(ERCStepsIndex.CONNECT_TO_PAYROLL);
          break;
        case `/${Page.taxCredits}/${Page.erc}/${Page.ercUploadPayrollTaxReturn}`:
          this.setERCFlowIndex(ERCStepsIndex.UPLOAD_PAYROLL_RETURNS);
          break;
        case `/${Page.taxCredits}/${Page.erc}/${Page.ercConfirmAddress}`:
          this.setERCFlowIndex(ERCStepsIndex.CONFIRM_ADDRESS);
          break;
        case `/${Page.taxCredits}/${Page.erc}/${Page.ercPayrollDetails}`:
          this.setERCFlowIndex(ERCStepsIndex.PAYROLL_DETAILS);
          break;
        case `/${Page.taxCredits}/${Page.erc}/${Page.ercCreditEstimate}`:
          this.setERCFlowIndex(ERCStepsIndex.CREDIT_ESTIMATE);
          break;
      }
    });
  }

  public setHasUploadedTaxReturn(bool: boolean) {
    runInAction(() => (this.hasUploadedTaxReturn = bool));
  }

  public setHasUploadedPayrollTaxReturn(bool: boolean) {
    runInAction(() => (this.hasUploadedPayrollTaxReturn = bool));
  }

  public setShowLoadingEligibility(bool: boolean) {
    runInAction(() => {
      this.loadingEligibility = bool;
      this.showQualificationStatusModal = bool;
    });

    // TODO: update this to real time api call response
    setTimeout(() => {
      runInAction(() => (this.loadingEligibility = false));
    }, 4000);
  }

  public setShowQualificationStatusModal(bool: boolean) {
    runInAction(() => (this.showQualificationStatusModal = bool));
  }

  public updateQualificationStatus(status: ERCQualificationStatusEnum) {
    runInAction(() => (this.qualificationStatus = status));
  }

  public checkQualificationStatus(surveyAnswers: QualificationQuestions) {
    const getQualificationStatus = () => {
      const status: { priority: number; status: ERCQualificationStatusEnum }[] =
        [];
      Object.entries(surveyAnswers).forEach((question) => {
        const questionId = question[0];
        const answerId = question[1];

        if (
          questionId === ERCQualifyingQuestions.CONFIRM_FOUNDED_YEAR &&
          answerId > 2021
        ) {
          status.push({
            priority: 2,
            status: ERCQualificationStatusEnum.DQ_COMPANY_FOUNDED_AFTER_2021,
          });
          return;
        }

        if (Array.isArray(answerId)) {
          const hasClaimedERC = answerId.some(
            (ans) =>
              ans ===
              ERCQualifyingQuestionsToAnswers[
                ERCQualifyingQuestions.ALL_GOVERNMENT_PROGRAMS_UTILIZED
              ].ERC,
          );

          if (hasClaimedERC) {
            status.push({
              priority: 1,
              status: ERCQualificationStatusEnum.DQ_COMPANY_CLAIMED_ERC,
            });
            return;
          }

          const programsUtilizedAnswerId = [
            ERCQualifyingQuestionsToAnswers[
              ERCQualifyingQuestions.ALL_GOVERNMENT_PROGRAMS_UTILIZED
            ].RD,
            ERCQualifyingQuestionsToAnswers[
              ERCQualifyingQuestions.ALL_GOVERNMENT_PROGRAMS_UTILIZED
            ].SICK_LEAVE,
            ERCQualifyingQuestionsToAnswers[
              ERCQualifyingQuestions.ALL_GOVERNMENT_PROGRAMS_UTILIZED
            ].WOTC,
            ERCQualifyingQuestionsToAnswers[
              ERCQualifyingQuestions.ALL_GOVERNMENT_PROGRAMS_UTILIZED
            ].PPP,
          ];

          const hasUtilizedPrograms = answerId.some((ans) =>
            programsUtilizedAnswerId.includes(ans),
          );

          if (hasUtilizedPrograms) {
            status.push({
              priority: 5,
              status:
                ERCQualificationStatusEnum.NOTIFICATION_COMPANY_TOOK_OUTSIDE_CREDIT,
            });
            return;
          }
        }

        if (
          questionId === ERCQualifyingQuestions.ORIGINAL_ENTITY_FOUNDED ||
          questionId ===
            ERCQualifyingQuestions.OLDEST_SIMILARITY_OWNED_BUSINESS_FOUNDED ||
          questionId === ERCQualifyingQuestions.OLDEST_ACQUIRED_COMPANY_FOUNDED
        ) {
          if (answerId === '' || answerId > 2021) {
            return;
          } else if (answerId < 2020) {
            status.push({
              priority: 3,
              status: ERCQualificationStatusEnum.DQ_COMPANY_FOUNDED_PRIOR_2020,
            });
            return;
          } else if (answerId === 2020 || answerId === 2021) {
            status.push({
              priority: 4,
              status:
                ERCQualificationStatusEnum.NOTIFICATION_RELATED_ENTITY_FOUNDED_IN_2021,
            });
            return;
          }
        }
      });

      return status;
    };

    const getPriorityStatus = (): ERCQualificationStatusEnum | null => {
      const qualificationStatuses = getQualificationStatus();

      if (qualificationStatuses.length === 0) {
        return null;
      }

      qualificationStatuses.sort((a, b) => a.priority - b.priority);

      return qualificationStatuses[0].status;
    };

    runInAction(() =>
      this.updateQualificationStatus(
        getPriorityStatus() as ERCQualificationStatusEnum,
      ),
    );
  }

  public checkQualification(yearFounded: number) {
    if (yearFounded < 2019) {
      this.updateQualificationStatus(
        ERCQualificationStatusEnum.QUALIFIED_FOUNDED_BEFORE_2019,
      );
    } else if (yearFounded === 2019) {
      this.updateQualificationStatus(
        ERCQualificationStatusEnum.QUALIFIED_FOUNDED_IN_2019,
      );
    } else if (yearFounded === 2020) {
      this.updateQualificationStatus(
        ERCQualificationStatusEnum.QUALIFIED_FOUNDED_IN_2020,
      );
    } else if (yearFounded === 2021) {
      this.updateQualificationStatus(
        ERCQualificationStatusEnum.QUALIFIED_FOUNDED_IN_2021,
      );
    } else {
      this.updateQualificationStatus(
        ERCQualificationStatusEnum.QUALIFIED_MAYBE_QUALIFIED,
      );
    }
  }

  public async createERCProgramCredit(taxYear: number) {
    const { programs } = this.company;
    const hasERCProgram = programs.some(
      (p) => p.name === ProgramNameEnum.ERC && p.taxYear === taxYear,
    );

    // if erc program credit has not been created
    if (!hasERCProgram) {
      await this.client.CreateProgramV1({
        name: ProgramNameEnum.ERC,
        taxYear,
      });

      datadogLogs.logger.info(
        `[COMPANY_CREATION]: Created ${taxYear} ${ProgramNameEnum.ERC} program for company ${this.company.id}.`,
        logContext({ company: this.company }),
      );
    }
  }

  public async getERCEstimate() {
    const { programs } = this.company;
    const ercProgram = programs.find((p) => p.name === ProgramNameEnum.ERC);

    if (ercProgram) {
      const res = await this.client.CalculateEstimate(ercProgram.id);

      if (res.data) {
        const creditEstimate = res.data?.estimateAmount;

        if (!ercProgram.creditAmountCents) {
          await this.client.UpdateProgramV1(ercProgram.id, {
            creditAmountCents: creditEstimate,
          });
        }

        runInAction(() => (this.creditEstimate = res.data));
      }

      if (res.errorMsg) {
        datadogLogs.logger.error(
          `[ERC CREDIT_ESTIMATE]: Error retreiving credit estimates for program_id: ${ercProgram.id}`,
          logContext({
            company: this.company,
            error: res.errorMsg,
          }),
        );
      }
    }
  }

  public setBillingSummaryModal(status: ERCQualificationStatusEnum) {
    runInAction(() => this.updateQualificationStatus(status));
  }

  public async updateProgramStageStatus(
    taxYear: number,
    stage: ProgramStageEnum,
    qualificationStatus?: QualificationStatusEnum,
    subStage?: ProgramSubStageEnum,
    skipRefresh = false,
  ) {
    const { programs } = this.company;
    const ercProgram = programs.find(
      (p) => p.name === ProgramNameEnum.ERC && p.taxYear === taxYear,
    );

    if (ercProgram) {
      await this.client.UpdateProgramV1(ercProgram.id, {
        stage,
        subStage,
        qualificationStatus,
      });
    }

    if (!skipRefresh) {
      // refresh current company after program updates
      await this.rootStore.common.companyStore.refreshCurrentCompany();
    }
  }

  public async moveAllProgramsToMsReview() {
    const { programs } = this.company;
    const ercPrograms = programs.filter((p) => {
      return (
        p.name === ProgramNameEnum.ERC &&
        p.stage !== ProgramStageEnum.DISQUALIFIED &&
        p.stage !== ProgramStageEnum.CANCELED
      );
    });

    await Promise.all(
      ercPrograms.map((program) => {
        this.updateProgramStageStatus(
          program.taxYear,
          ProgramStageEnum.MS_REVIEW,
          QualificationStatusEnum.QUALIFIED,
          ProgramSubStageEnum.REVIEW_IN_PROGRESS,
          true,
        );
      }),
    );

    // refresh current company after program updates
    await this.rootStore.common.companyStore.refreshCurrentCompany();
  }

  public async qualifyAllPrograms() {
    const { programs } = this.company;
    const ercPrograms = programs.filter((p) => p.name === ProgramNameEnum.ERC);

    await Promise.all(
      ercPrograms.map((program) => {
        this.updateProgramStageStatus(
          program.taxYear,
          ProgramStageEnum.QUALIFYING,
          QualificationStatusEnum.QUALIFIED,
          undefined,
          true,
        );
      }),
    );

    // refresh current company after program updates
    await this.rootStore.common.companyStore.refreshCurrentCompany();
  }

  public async disqualifyAllPrograms() {
    const { programs } = this.company;
    const ercPrograms = programs.filter((p) => p.name === ProgramNameEnum.ERC);

    await Promise.all(
      ercPrograms.map((program) => {
        this.updateProgramStageStatus(
          program.taxYear,
          ProgramStageEnum.DISQUALIFIED,
          QualificationStatusEnum.DISQUALIFIED,
          undefined,
          true,
        );
      }),
    );

    // refresh current company after program updates
    await this.rootStore.common.companyStore.refreshCurrentCompany();
  }

  public setModalContinueIsLoading(bool: boolean) {
    runInAction(() => (this.modalContinueIsLoading = bool));
  }
}
