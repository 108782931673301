import React from 'react';

export type ValidationState = 'valid' | 'invalid' | 'default';

export const usePasswordValidation = () => {
  const [passwordValidation, setPasswordValidation] = React.useState<
    Record<string, ValidationState>
  >({
    minimum: 'default',
    uppercase: 'default',
    lowercase: 'default',
    number: 'default',
  });

  const validatePassword = (password: string, isBlur?: boolean) => {
    const validation = { ...passwordValidation };
    if (isBlur) {
      validation.lowercase =
        validation.lowercase === 'default' ? 'invalid' : validation.lowercase;
      validation.uppercase =
        validation.uppercase === 'default' ? 'invalid' : validation.uppercase;
      validation.number =
        validation.number === 'default' ? 'invalid' : validation.number;
      validation.minimum =
        validation.minimum === 'default' ? 'invalid' : validation.minimum;
    } else {
      validation.lowercase = password.match(/[a-z]/g) ? 'valid' : 'default';
      validation.uppercase = password.match(/[A-Z]/g) ? 'valid' : 'default';
      validation.number = password.match(/[0-9]/g) ? 'valid' : 'default';
      validation.minimum = password.length >= 8 ? 'valid' : 'default';
    }
    setPasswordValidation(validation);
  };

  const isValidPassword = Object.values(passwordValidation).every(
    (status) => status === 'valid',
  );

  return {
    validatePassword,
    isValidPassword,
    passwordValidation,
  };
};
