import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import {
  AnswerType,
  AnswerValueType,
  Button,
  Card,
  Color,
  FileHandle,
  Flex,
  Link,
  Modal,
  SurveyQuestion,
  Text,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import {
  CmsQuestionData,
  Document,
  MSClientResponse,
  ProgramData,
} from 'lib/interfaces';
import { FileTypes, Programs } from 'lib/constants';
import {
  isFileEncrypted,
  isFileSizeWithinLimits,
  useEffectOnce,
} from 'lib/helpers';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { CompanyContext } from 'pages/CompanyRequired';
import { SaveAnswersResponse } from 'lib/useSurveyQuestions';
import AttentionNeeded from 'components/icons/AttentionNeeded';

const GENERIC_VISUAL_ERROR = `Something went wrong, please try again`;
const UPLOAD_FILE_SIZE_LIMIT = 1024 * 1024 * 20;

const useStyles = makeStyles(() => ({
  documentUploadInfo: {
    '& ol': {
      margin: 0,
      padding: '0 0 0 16px',
      '& > li': {
        padding: '4px 0',
      },
    },
  },
  uploadedDocs: {
    borderLeft: `1px solid ${Color.neutral._20}`,
    borderRight: `1px solid ${Color.neutral._20}`,
    borderTop: `1px solid ${Color.neutral._20}`,
    '&:nth-child(2)': {
      borderTop: 'none',
    },
  },
}));

interface EncryptedFileProps {
  questionId: string | null;
  fileHandle: FileHandle | null;
}

interface ProofOfShopUploadedProps {
  question: CmsQuestionData;
  program: ProgramData;
  saveAnswers?: () => Promise<SaveAnswersResponse>;
  handleAnswerChange: (value: AnswerValueType) => void;
  missingProofDocument: boolean;
}

export const ProofOfShopUploaded: React.FC<ProofOfShopUploadedProps> = observer(
  ({
    question,
    program,
    saveAnswers,
    handleAnswerChange,
    missingProofDocument,
  }) => {
    const classes = useStyles();
    const { client } = useContext(Auth0FeatureContext);
    const { company } = useContext(CompanyContext);
    const [files, setFiles] = useState<
      Record<string, (FileHandle | Document)[]>
    >({});
    const [uploadedDocuments, setUploadedDocuments] = useState<
      Record<string, Document[]>
    >({});
    const [showEncryptedModal, setShowEncryptedModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState<{
      [id: number]: boolean;
    }>({});
    const [currentEncryptedFile, setCurrentEncryptedFile] =
      useState<EncryptedFileProps>({ questionId: null, fileHandle: null });

    const getUploadedDocuments = async () => {
      if (program && question) {
        const res = await client.GetProgramDocuments(program.id, 'client');
        const documents = res.data?.documents;
        const uploadedDocs: { [key: string]: Document[] } = {};

        if (documents) {
          if (Array.isArray(question.answerValue)) {
            question.answerValue.forEach((answerId) => {
              documents.forEach((doc) => {
                const docIdString = doc.id.toString();

                if (docIdString === answerId) {
                  if (uploadedDocs[question.id]) {
                    uploadedDocs[question.id] = [
                      ...uploadedDocs[question.id],
                      doc,
                    ];
                  } else {
                    uploadedDocs[question.id] = [doc];
                  }
                }
              });
            });
          }

          setUploadedDocuments(uploadedDocs);
          setFiles(uploadedDocs);
        }
      }
    };

    const uploadProofOfShop = async (
      questionId: string,
      fileHandler: FileHandle,
    ) => {
      const filePromise: Promise<{
        questionId: string;
        fileHandler: FileHandle;
        response: MSClientResponse<{ document: Document }>;
      }> = client
        .UploadCompanyDocument(
          company.id,
          fileHandler,
          fileHandler.name,
          program
            ? `${Programs[program?.name].display} ${
                program?.taxYear
              } Expense Classification Document`
            : '',
          program !== null ? String(program?.id) : undefined,
        )
        .then((response) => ({ questionId, fileHandler, response }))
        .catch((error) => error);

      return filePromise.then((result) => {
        let hasUploaded = true;
        let documentId;

        if (result.response.data) {
          const { document } = result.response.data;
          if (question) {
            if (Array.isArray(question.answerValue)) {
              question.answerValue.push(document.id.toString());
              handleAnswerChange(question.answerValue);
            } else {
              question.answerValue = [];
              question.answerValue.push(document.id.toString());
              handleAnswerChange(question.answerValue);
            }

            documentId = document.id;
          } else {
            fileHandler.setError(
              result.response.errorMsg || GENERIC_VISUAL_ERROR,
            );
            hasUploaded = false;
          }
        }

        return { hasUploaded, documentId };
      });
    };

    const onFileAdded = async (
      questionId: string,
      fileHandle: FileHandle,
      bypassFileEncryptionValidation: boolean,
    ) => {
      let promise: Promise<void> = Promise.resolve();
      const fileSizeWithinLimits = isFileSizeWithinLimits(fileHandle.file);
      const fileEncrypted = await isFileEncrypted(fileHandle);

      if (!fileSizeWithinLimits) {
        return;
      }

      if (!bypassFileEncryptionValidation && fileEncrypted) {
        setCurrentEncryptedFile({ questionId, fileHandle });
        setShowEncryptedModal(true);
        return;
      } else {
        fileHandle.setError('');
      }

      promise = uploadProofOfShop(questionId, fileHandle).then((success) => {
        // store db document id to fileHandle
        fileHandle.documentId = success.documentId;
      });

      promise.then(() => {
        const questionFileList = files[questionId]
          ? [...files[questionId]]
          : [];
        const updatedFiles = {
          ...files,
          [questionId]: [...questionFileList, fileHandle],
        };
        setFiles(updatedFiles);

        // update answers if file added
        if (saveAnswers) {
          saveAnswers();
        }
      });
    };

    const onFileRemoved = (questionId: string, file: FileHandle) => {
      const questionFileList = files[questionId] ? [...files[questionId]] : [];
      const fileIndex = questionFileList.findIndex(
        (f) => (f as FileHandle).vid === file.vid,
      );
      if (fileIndex >= 0) {
        questionFileList.splice(fileIndex, 1);
      }
      const updatedFiles = {
        ...files,
        [questionId]: questionFileList,
      };
      setFiles(updatedFiles);

      // remove documentId from answerValue array
      if (question && Array.isArray(question?.answerValue)) {
        if (file.documentId) {
          const documentId = file.documentId;
          const removeId = question.answerValue.findIndex(
            (id) => Number(id) === documentId,
          );

          if (removeId !== -1) {
            question.answerValue.splice(removeId, 1);
            handleAnswerChange(question.answerValue);
          }

          client.DeleteDocument(file.documentId);
        }
      }

      // update answers if file removed
      if (saveAnswers) {
        saveAnswers();
      }
    };

    // this is to delete documents that were already uploaded
    // if customer had already previously visited the company details step
    const deleteDocument = async (
      docId: number,
      questionId: string,
      index: number,
    ) => {
      setDeleteLoading((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));

      await client.DeleteDocument(docId).then(() => {
        const updateDocumentList = uploadedDocuments[questionId].filter(
          (doc) => doc.id !== docId,
        );

        const updatedDocList = {
          ...uploadedDocuments,
          [questionId]: [...updateDocumentList],
        };

        setUploadedDocuments(updatedDocList);
        setDeleteLoading((prev) => ({
          ...prev,
          [index]: !prev[index],
        }));

        // remove documentId from answerValue array
        if (question && Array.isArray(question?.answerValue)) {
          const removeId = question.answerValue.findIndex(
            (id) => Number(id) === docId,
          );

          if (removeId !== -1) {
            question.answerValue.splice(removeId, 1);
            handleAnswerChange(question.answerValue);
          }
        }

        // update answers if file removed
        if (saveAnswers) {
          saveAnswers();
        }
      });
    };

    // get uploaded documents to show customers
    useEffectOnce(() => getUploadedDocuments());

    return (
      <Card noMargin>
        <Flex direction='column' gap={24} padding={24}>
          <Flex
            direction='column'
            className={classes.documentUploadInfo}
            gap={8}
          >
            <Text variant='medium' size={18}>
              {question.text}
            </Text>
            <Text>{question.subtitle}</Text>
            <ol>
              <li>
                <Text>
                  A screenshot/document from your healthcare insurance
                  plan&apos;s website or{' '}
                  <Link href='https://www.healthcare.gov/' target='_blank'>
                    healthcare.gov
                  </Link>{' '}
                  displaying:
                </Text>
                <Flex direction='column'>
                  <Text tag='span'>• The name of your plan</Text>
                  <Text tag='span'>• A detailed plan information</Text>
                </Flex>
              </li>
              <li>
                <Text>
                  Official approval documents from a certified SHOP plan broker
                  or agency.
                </Text>
              </li>
            </ol>
          </Flex>
          <Flex direction='column' gap={24}>
            <Flex>
              <Flex.Cell basis={600}>
                <SurveyQuestion
                  answerType={question.answerType as AnswerType}
                  placeholder={question.placeholder}
                  answerValue={question.answerValue}
                  subtitle={
                    <Text>
                      These documents are necessary to confirm the validity of
                      your SHOP plan enrollment. Please ensure that all
                      submitted proof clearly shows the required details for
                      verification&nbsp;purposes.
                    </Text>
                  }
                  text={'Upload your healthcare plan document'}
                  withCard={false}
                  acceptedFiletypes={[
                    FileTypes.CSV,
                    FileTypes.PDF,
                    FileTypes.XLS,
                    FileTypes.XLSX,
                  ]}
                  maxFileSize={UPLOAD_FILE_SIZE_LIMIT}
                  fileUploadFlexDirection='column'
                  onFileAdded={async (file: FileHandle) => {
                    await onFileAdded(question.id, file, false);
                  }}
                  onFileRemoved={(file: FileHandle) =>
                    onFileRemoved(question.id, file)
                  }
                  onFileCancelled={(file: FileHandle) => {
                    onFileRemoved(question.id, file);
                  }}
                />
                {uploadedDocuments &&
                  uploadedDocuments[question.id] &&
                  uploadedDocuments[question.id].map((doc, index) => {
                    return (
                      <Flex
                        padding={24}
                        key={`${doc.id}-${index}`}
                        className={classes.uploadedDocs}
                        alignItems='center'
                        justifyContent='space-between'
                        gap={24}
                      >
                        <Flex.Cell>
                          <Text text={doc.name} color={Color.neutral._80} />
                          <Text
                            size={11}
                            text='(Previously uploaded)'
                            variant='italic'
                            color={Color.neutral._60}
                          />
                        </Flex.Cell>
                        <Button
                          onClick={() =>
                            deleteDocument(doc.id, question.id, doc.id)
                          }
                          label={
                            <Text
                              color={Color.semantic.$error50}
                              size={13}
                              variant='medium'
                              text='Remove'
                            />
                          }
                          variant='tertiary'
                          small
                          loading={deleteLoading[doc.id]}
                        />
                      </Flex>
                    );
                  })}
              </Flex.Cell>
            </Flex>
            {missingProofDocument && (
              <Flex>
                <Text color={Color.semantic.$error50}>
                  Please add proof of SHOP plan documentation.
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Modal
          showModal={showEncryptedModal}
          closeToggle={() => setShowEncryptedModal(false)}
          maxWidth={536}
        >
          <Flex direction='column' alignItems='center' gap={16} padding={24}>
            <AttentionNeeded />
            <Text
              variant='bold'
              size={23}
              text='Does the file you uploaded have a password?'
            />
            <Text variant='regular' size={15} tag='span'>
              <ul>
                <li>
                  If Yes - please remove the password protection and re-upload
                </li>
                <li>If No - you may proceed</li>
              </ul>
            </Text>
            <Flex gap={16} justifyContent='flex-end'>
              <Button
                variant='outlined'
                label='Remove Password'
                onClick={() => setShowEncryptedModal(false)}
                dataTestId={'encrypted-modal-secondary-action'}
              />
              <Button
                label={'Proceed with Upload'}
                onClick={() => {
                  if (
                    currentEncryptedFile &&
                    currentEncryptedFile.questionId &&
                    currentEncryptedFile.fileHandle
                  ) {
                    onFileAdded(
                      currentEncryptedFile.questionId,
                      currentEncryptedFile.fileHandle,
                      true,
                    );
                    setShowEncryptedModal(false);
                  }
                }}
                dataTestId={'encryoted-modal-primary-action'}
              />
            </Flex>
          </Flex>
        </Modal>
      </Card>
    );
  },
);
