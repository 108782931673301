import { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  CompanyData,
  QualificationQuestions,
} from 'lib/interfaces/globalInterfaces';
import {
  SurveyNameEnum,
  CompanyDetailsGraphCmsQuestionIds,
  AutoqualificationStatusEnum,
} from 'lib/constants';
import { useQuestionGroup } from 'lib/useQuestionGroup';
import {
  CmsQuestionData,
  CmsRenderTree,
  CmsRenderTreeQuestion,
  CmsRenderTreeSubgroup,
  GraphCMSAnswerTypeEnum,
  RenderTreeShowCondition,
} from 'lib/interfaces';
import { AnswerValueType } from 'component-library/dashboard/surveyquestion/SurveyQuestion';
import {
  CustomShowCondition,
  evaluateShowCondition,
} from 'lib/surveyQuestionShowConditionEvaluator';
import { useCompany, useLegacyClients } from 'stores/useStores';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';

export interface SurveyAnswer {
  questionId: string;
  answerValue: AnswerValueType | undefined;
}

export interface QuestionsToRender {
  [key: string]: CmsQuestionData[];
}

export interface UseSurveyQuestionsOptions {
  showConditionOverrides?: ShowConditionOverrides;
  shortCircuitConditions?: CustomShowCondition[];
}

export interface SaveAnswersResponse {
  error?: string;
  autoqualificationStatus?: AutoqualificationStatusEnum;
}

/**
 * identifier refers to either a question id or a subgroup name
 */
export interface ShowConditionOverrides {
  [identifier: string]: () => boolean;
}

export const isAnswerValid = (
  answerValue: AnswerValueType | undefined,
): boolean =>
  Array.isArray(answerValue)
    ? !_.isEmpty(answerValue)
    : !!answerValue || answerValue === 0;

export const hasSubQuestions = (question: CmsQuestionData): boolean =>
  !_.isEmpty(question.subQuestions);

export const questionHasAnswer = (question: CmsQuestionData): boolean => {
  if (
    question.answerType === GraphCMSAnswerTypeEnum.SUBQUESTIONS &&
    hasSubQuestions(question)
  ) {
    return question.subQuestions!.some((subQuestion) =>
      isQuestionAnswered(subQuestion),
    );
  }

  return isQuestionAnswered(question);
};

export const isQuestionAnswered = (question: CmsQuestionData): boolean => {
  if (
    question.answerType === GraphCMSAnswerTypeEnum.SUBQUESTIONS &&
    hasSubQuestions(question)
  ) {
    return question.subQuestions!.every((subQuestion) =>
      isQuestionAnswered(subQuestion),
    );
  }
  return isAnswerValid(question.answerValue);
};

export const allQuestionsAnswered = (
  renderTree: QuestionsToRender,
): boolean => {
  if (_.isEmpty(renderTree)) {
    return true;
  }
  const questions: CmsQuestionData[] = _.flatMap(renderTree);
  return _.every(questions, (question) => questionHasAnswer(question));
};

export function useSurveyQuestions(
  surveyNameEnum: SurveyNameEnum,
  qualificationTaxYear: number,
  options?: UseSurveyQuestionsOptions,
  localRenderTree?: CmsRenderTree,
  reInitCompanyQualQuestions?: number,
) {
  const { client } = useLegacyClients();
  const { company, store: companyStore } = useCompany();

  const [surveyAnswers, setSurveyAnswers] = useState<QualificationQuestions>(
    {},
  );

  const [currentSubGroup, setCurrentSubGroup] = useState<string | null>(null);
  const [lastQuestionAnsweredId, setLastQuestionAnsweredId] = useState<
    string | null
  >(null);
  const [questionsToRender, setQuestionsToRender] = useState<QuestionsToRender>(
    {},
  );
  const [isSurveyComplete, setIsSurveyComplete] = useState<boolean>(false);
  const [isSurveyShortCircuited, setIsSurveyShortCircuited] =
    useState<boolean>(false);
  const [
    isQuestionRenderTreeSubGroupsPresent,
    setIsQuestionRenderTreeSubGroupsPresent,
  ] = useState<boolean>(true);

  const { questions, graphCmsRenderTree, isLoading, setQuestionAnswers } =
    useQuestionGroup(surveyNameEnum, qualificationTaxYear, surveyAnswers);

  // overriding local renderTree
  const graphCmsRenderTreeSource =
    localRenderTree && process.env.NODE_ENV === 'development'
      ? localRenderTree
      : graphCmsRenderTree;

  useEffect(() => {
    if (!isLoading) {
      updateRenderTree();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyAnswers, isLoading]);

  useEffect(() => {
    if (
      !isLoading &&
      Object.values(questionsToRender).some((subGroup) => subGroup.length > 0)
    ) {
      checkIfAnySubGroupAreUnderRenderTree();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyAnswers, isLoading, questionsToRender]);

  useEffect(() => {
    if (company?.qualificationQuestionsByYear?.[qualificationTaxYear]) {
      setSurveyAnswers(
        company.qualificationQuestionsByYear[qualificationTaxYear],
      );
    }
  }, [company, qualificationTaxYear, reInitCompanyQualQuestions]);

  const shouldShow = (
    identifier: string,
    showCondition: RenderTreeShowCondition,
  ): boolean => {
    if (
      options?.showConditionOverrides &&
      identifier in options.showConditionOverrides
    ) {
      return options.showConditionOverrides[identifier]();
    }

    return evaluateShowCondition(
      surveyAnswers,
      company.rawData!,
      showCondition,
    );
  };

  const getNextQuestion = (
    subGroupName: string,
    currentQuestionId: string | null,
  ): CmsQuestionData | null => {
    const { subGroups } = graphCmsRenderTreeSource;
    const subGroupQuestions = subGroups[subGroupName].questions;
    const currentQuestionIndex = _.findIndex(
      subGroupQuestions,
      (question) => question.id === currentQuestionId,
    );
    const isLastQuestionInSubGroup =
      currentQuestionIndex === subGroupQuestions.length - 1;

    if (isLastQuestionInSubGroup) {
      return null;
    }

    const { id: nextQuestionId, showCondition: nextQuestionShowCondition } =
      subGroupQuestions[currentQuestionIndex + 1];
    const nextQuestion = _.find(questions, { id: nextQuestionId });

    if (!nextQuestion) {
      return null;
    }

    if (shouldShow(nextQuestion.id, nextQuestionShowCondition)) {
      if (hasSubQuestions(nextQuestion)) {
        const nextQuestionWithSubQuestions = evaluateSubQuestionConditions(
          subGroupName,
          nextQuestion,
        );
        if (
          nextQuestionWithSubQuestions?.subQuestions &&
          nextQuestionWithSubQuestions?.subQuestions.length > 0
        ) {
          return nextQuestionWithSubQuestions;
        }
      } else {
        return nextQuestion;
      }
    }

    return getNextQuestion(subGroupName, nextQuestion.id);
  };

  // Recursively find the next subGroup that fulfills its showCondition(s)
  const getNextSubGroup = (currentSubGroup: string | null): string | null => {
    const { order, subGroups } = graphCmsRenderTreeSource;
    const subGroupIndex =
      currentSubGroup !== null ? order.indexOf(currentSubGroup) : -1;

    if (subGroupIndex < order.length) {
      const nextSubGroupIndex = subGroupIndex + 1;
      const nextSubGroupName = order[nextSubGroupIndex];
      const nextSubGroup = subGroups[nextSubGroupName];

      if (nextSubGroup) {
        return shouldShow(nextSubGroupName, nextSubGroup.showCondition)
          ? nextSubGroupName
          : getNextSubGroup(nextSubGroupName);
      }
    }

    return null;
  };

  const addSurveyAnswers = (
    questionId: string,
    subGroupName: string,
    answers: SurveyAnswer[],
  ) => {
    const newState = Object.assign({}, surveyAnswers);
    answers.forEach((answer) => {
      newState[answer.questionId] = answer.answerValue;
    });
    setLastQuestionAnsweredId(questionId);
    setCurrentSubGroup(subGroupName);
    setSurveyAnswers(newState);
    setQuestionAnswers(answers);
  };

  const addSurveyAnswersWithoutRerender = (answers: SurveyAnswer[]) => {
    const newState = Object.assign({}, surveyAnswers);
    answers.forEach((answer) => {
      newState[answer.questionId] = answer.answerValue;
    });
    setSurveyAnswers(newState);
    setQuestionAnswers(answers);
  };

  const getQuestionIds = (question: CmsQuestionData): string[] => {
    return question.subQuestions
      ? [question.id, ...question.subQuestions.map((sq) => sq.id)]
      : [question.id];
  };

  const saveAnswers = (): Promise<SaveAnswersResponse> => {
    let renderedQuestionIds = Object.values(questionsToRender).reduce(
      (arr: string[], subGroupQuestions: CmsQuestionData[]) => {
        const questionIds = _.flatten(subGroupQuestions.map(getQuestionIds));
        return arr.concat(questionIds);
      },
      [],
    );

    // HACK - the TAX_INFO_MANUAL question is set to be hidden by default so we can
    // manually hide/show it. We still want to save the answers if they have changed, however.
    if (
      renderedQuestionIds.includes(
        CompanyDetailsGraphCmsQuestionIds.TAX_INFO_FILE_UPLOAD,
      )
    ) {
      const question = getQuestionData(
        CompanyDetailsGraphCmsQuestionIds.TAX_INFO_MANUAL,
      );
      if (question) {
        renderedQuestionIds = renderedQuestionIds.concat(
          getQuestionIds(question),
        );
      }
    }

    const currentSurveyAnswers = questions.reduce(
      (currentSurveyAnswers: { [key: string]: AnswerValueType }, question) => {
        // only save questions with answers and that are actively rendered
        const addAnswer = (question: CmsQuestionData) => {
          if (
            question.id &&
            question.answerValue != null &&
            _.includes(renderedQuestionIds, question.id)
          ) {
            currentSurveyAnswers[question.id] = question.answerValue;
          }
        };
        addAnswer(question);

        if (hasSubQuestions(question)) {
          question.subQuestions?.forEach((subQuestion) => {
            addAnswer(subQuestion);
          });
        }
        return currentSurveyAnswers;
      },
      {},
    );

    // spread existing answers within the tax years in qualification_questions_by_year
    const existingAnswers =
      company.qualificationQuestionsByYear &&
      company.qualificationQuestionsByYear[qualificationTaxYear];

    company.mergeData({
      ...company,
      qualificationQuestionsByYear: {
        ...company.qualificationQuestionsByYear,
        [qualificationTaxYear]: { ...existingAnswers, ...currentSurveyAnswers },
      },
    } as CompanyData);

    const updateCompanyWithSurveyResponse = companyStore.accessToken
      ? client.UpdateCompanyWithSurveyResponsePublic(
          // @ts-ignore TS not correctly handling that value exists within ternery
          companyStore.accessToken,
          surveyNameEnum,
          {
            taxYear: qualificationTaxYear,
            qualificationQuestions: currentSurveyAnswers,
          },
        )
      : client.UpdateCompanyWithSurveyResponse(company.id, surveyNameEnum, {
          taxYear: qualificationTaxYear,
          qualificationQuestions: currentSurveyAnswers,
        });

    return updateCompanyWithSurveyResponse.then(({ errorMsg, data }) => {
      if (errorMsg) {
        datadogLogs.logger.error(
          `Failed to save survey answers for survey ${surveyNameEnum}`,
          logContext({
            company,
            error: errorMsg,
          }),
        );
        return Promise.reject({ error: errorMsg });
      }

      return data?.autoqualificationStatus
        ? { autoqualificationStatus: data.autoqualificationStatus }
        : {};
    });
  };

  const initializeSubGroupToRender = (
    subGroup: string | null,
    renderTree: {
      [key: string]: CmsQuestionData[];
    },
  ): string | null => {
    const subGroupName = getNextSubGroup(subGroup);
    if (subGroupName !== null) {
      renderTree[subGroupName] = [];
    }
    setLastQuestionAnsweredId(null);
    return subGroupName;
  };

  const addQuestionToRenderTree = (
    currentQuestions: CmsQuestionData[],
    questionToAdd: CmsQuestionData,
  ) => {
    if (!_.find(currentQuestions, { id: questionToAdd.id })) {
      currentQuestions.push(questionToAdd);
    }
  };

  const shouldShortCircuit = () => {
    if (
      options?.shortCircuitConditions &&
      options.shortCircuitConditions.length > 0
    ) {
      return _.some(options.shortCircuitConditions, (showCondition) =>
        evaluateShowCondition(surveyAnswers, company.rawData!, showCondition),
      );
    }
    return false;
  };

  const updateRenderTree = () => {
    const renderTree = { ...questionsToRender };
    reevaluateRenderTree(renderTree);

    const shortCircuitResult = shouldShortCircuit();
    setIsSurveyShortCircuited(shortCircuitResult);
    if (shouldShortCircuit()) {
      setIsSurveyComplete(true);
      setQuestionsToRender(renderTree);
      return;
    } else {
      setIsSurveyComplete(false);
      let subGroupName: string | null =
        currentSubGroup ??
        initializeSubGroupToRender(currentSubGroup, renderTree);
      let nextQuestion: CmsQuestionData | null = null;

      while (!nextQuestion && subGroupName !== null) {
        let lastQuestionId: string | null = lastQuestionAnsweredId;
        if (renderTree[subGroupName]) {
          let subGroupRenderedQuestions = renderTree[subGroupName];
          const lastQuestionIndex = subGroupRenderedQuestions.findIndex(
            (question) => question.id === lastQuestionId,
          );

          if (lastQuestionIndex >= 0) {
            // We remove all questions following the last answered question in
            // order to validate that they should be shown based on the new changes.
            subGroupRenderedQuestions = subGroupRenderedQuestions.slice(
              0,
              lastQuestionIndex + 1,
            );
            renderTree[subGroupName] = subGroupRenderedQuestions;
          }
          nextQuestion = getNextQuestion(subGroupName, lastQuestionId);

          // Add previously answered questions until we find one that hasn't been answered yet
          while (nextQuestion && isQuestionAnswered(nextQuestion)) {
            addQuestionToRenderTree(subGroupRenderedQuestions, nextQuestion);
            lastQuestionId = nextQuestion.id;
            nextQuestion = getNextQuestion(subGroupName, lastQuestionId);
          }

          if (nextQuestion) {
            addQuestionToRenderTree(subGroupRenderedQuestions, nextQuestion);
          } else {
            subGroupName = initializeSubGroupToRender(subGroupName, renderTree);
          }
        } else {
          nextQuestion = getNextQuestion(subGroupName, lastQuestionId);
        }
      }

      setQuestionsToRender(renderTree);

      if (subGroupName === null && allQuestionsAnswered(renderTree)) {
        setIsSurveyComplete(true);
        return;
      }

      setCurrentSubGroup(subGroupName);
    }
  };

  // remove any subgroups / questions that should no longer render based on answers added
  const reevaluateRenderTree = (renderTree: QuestionsToRender) => {
    const { subGroups } = graphCmsRenderTreeSource;
    Object.keys(renderTree).forEach((subGroupName) => {
      const subGroup = subGroups[subGroupName];
      // remove any subgroups that should no longer render
      if (!shouldShow(subGroupName, subGroup.showCondition)) {
        _.unset(renderTree, subGroupName);
      } else {
        // remove any individual questions that should no longer render
        renderTree[subGroupName].forEach((renderedQuestion) => {
          const cmsQuestion = subGroup.questions.find(
            (subGroupQuestion) => subGroupQuestion.id === renderedQuestion.id,
          );
          if (
            cmsQuestion &&
            // this ensures that questions that only appear if not answered before, are not removed after answered in the same session
            cmsQuestion.showCondition &&
            Object.keys(cmsQuestion.showCondition)[0] !== 'notAnswered' &&
            !shouldShow(cmsQuestion.id, cmsQuestion.showCondition)
          ) {
            _.remove(
              renderTree[subGroupName],
              (question) => question && question.id === cmsQuestion.id,
            );
          }
        });
      }
    });
  };

  const evaluateSubQuestionConditions = (
    subGroupName: string,
    questionProps: CmsQuestionData | null,
  ) => {
    if (!questionProps) {
      return questionProps;
    }
    if (!subGroupName) {
      // determine the subgroup based on the question
      const subGroups = Object.keys(graphCmsRenderTreeSource.subGroups);
      const subGroupsContainingQuestion = subGroups.filter((subGroup) =>
        graphCmsRenderTreeSource.subGroups[subGroup].questions.find(
          (q) => q.id === questionProps.id,
        ),
      );
      if (subGroupsContainingQuestion) {
        subGroupName = subGroupsContainingQuestion[0];
      }
    }
    const renderData = getQuestionRenderData(subGroupName, questionProps.id);
    if (renderData && renderData.subQuestions) {
      const subQuestionsToRender = questionProps.subQuestions?.reduce(
        (arr: CmsQuestionData[], item: CmsQuestionData) => {
          const subQuestionRenderData = renderData.subQuestions?.find(
            (sq) => sq.id === item.id,
          );
          if (
            !subQuestionRenderData ||
            (subQuestionRenderData &&
              shouldShow(
                subQuestionRenderData.id,
                subQuestionRenderData.showCondition,
              ))
          ) {
            return arr.concat([item]);
          }
          return arr;
        },
        [],
      );
      return { ...questionProps, subQuestions: subQuestionsToRender };
    }
    return questionProps;
  };

  const getQuestionData = (questionId: string): CmsQuestionData | undefined => {
    const question = questions.find((question) => question.id === questionId);
    if (question && hasSubQuestions(question)) {
      return evaluateSubQuestionConditions('', question) ?? undefined;
    }
    return question;
  };

  const getQuestionRenderData = (
    subGroupName: string,
    questionId: string,
  ): CmsRenderTreeQuestion | undefined => {
    const subGroup = getSubGroupRenderData(subGroupName);
    if (subGroup) {
      return subGroup.questions.find((question) => question.id === questionId);
    }
  };

  const getSubGroupRenderData = (
    subGroupName: string,
  ): CmsRenderTreeSubgroup | undefined =>
    graphCmsRenderTreeSource.subGroups[subGroupName];

  // Check when taxYear is valid and log when questions are missing when they are suppose to show
  const checkIfAnySubGroupAreUnderRenderTree = () => {
    if (qualificationTaxYear > 0) {
      for (const [key, value] of Object.entries(questionsToRender)) {
        if (value.length === 0) {
          setIsQuestionRenderTreeSubGroupsPresent(false);

          datadogLogs.logger.error(
            `Question render tree is not present for surveyName ${surveyNameEnum} for company id: ${company.id} for tax year: ${qualificationTaxYear}`,
            {
              companyId: company.id,
            },
          );

          return;
        }
      }
      return;
    }
  };

  return {
    isLoading,
    isSurveyComplete,
    isSurveyShortCircuited,
    questionsToRender,
    surveyAnswers,
    addSurveyAnswers,
    addSurveyAnswersWithoutRerender,
    getQuestionData,
    getSubGroupRenderData,
    getQuestionRenderData,
    saveAnswers,
    isQuestionRenderTreeSubGroupsPresent,
  };
}
