import { KycQuestionGraphCMSIdEnum } from '../constants';
import { CmsQuestionData, CountryEnum, StateEnum } from '.';
import { PromissoryNote } from './globalInterfaces';

export interface Address {
  streetLine1: string;
  streetLine2?: string;
  city: string;
  state?: StateEnum;
  country?: CountryEnum;
  postalCode: string;
}

export interface BusinessApplicationData {
  applicationId?: string | null; // if pre-existing
  entityId?: string | null; // if pre-existing
  dba?: string;
  description?: string;
  mailingAddress?: Address;
  name: string;
  phoneNumber: string;
  physicalAddress: Address;
  tin: string; // EIN or other tax ID
  urls?: string[];
}

export type BusinessApplicationDataDraft = Omit<
  Partial<BusinessApplicationData>,
  'physicalAddress' | 'mailingAddress'
> & {
  physicalAddress?: Partial<Address>;
  mailingAddress?: Partial<Address>;
};

export enum KycApplicationStage {
  NONE_COMPLETED = 'none_completed',
  BUSINESS_INFORMATION_APPLICATION_COMPLETED = 'business_information_application_completed',
  BUSINESS_ACCREDITATION_APPLICATION_COMPLETED = 'business_accreditation_application_completed',
  OWNER_INFORMATION_APPLICATION_COMPLETED = 'owner_information_application_completed',
}

export interface GetKycApplicationStatusResponse {
  kycApplicationId: number;
  kycApplicationStatus: KycApplicationStatus;
  kycApplicationStage: KycApplicationStage;
  promissoryNote?: PromissoryNote;
  fixedReturnPercent?: number;
}

export interface PersonApplicationData {
  applicationId?: string | null; // if pre-existing
  entityId?: string | null; // if pre-existing
  citizenship: CountryEnum;
  dateOfBirth: Date | string;
  emailAddress: string;
  firstName: string;
  middleName: string;
  lastName: string;
  physicalAddress: Address;
  mailingAddress?: Address;
  occupation?: string;
  ownershipPercentage: number;
  phoneNumber: string;
  tin: string; // SSN
  title: string;
}

export interface SupportingDocumentData {
  approved?: boolean;
  approvalTimestamp?: number;
  approverEmail?: string;
  documentId: string;
  documentType: string;
  fileName: string;
  fileExtension: string;
  uploadedFileName?: string; // we may store the file with an updated name
}

export interface PersonApplicationResponse {
  personApplicationId?: string | null;
  personEntityId?: string | null;
  personTin?: string;
}

export interface BusinessApplicationResponse {
  businessApplicationId?: string | null;
  businessEntityId?: string | null;
}

export interface BeneficiariesResponse {
  kycApplicationId: number;
  personApplicationResponses: PersonApplicationResponse[];
}

export interface BusinessResponse {
  kycApplicationId: number;
  businessApplicationResponse: BusinessApplicationResponse;
}

export enum AccreditationStatusEnum {
  IN_REVIEW = 'in_review',
  ACCREDITED = 'accredited',
  NOT_ACCREDITED = 'not_accredited',
}

export interface SubmitAccreditationResponse {
  kycApplicationId: number;
  plaidBalanceInThousands?: number;
  accreditationApplicationId: string | null;
  accreditationApplicationStatus: AccreditationStatusEnum;
}

export type PersonApplicationDataDraft = Omit<
  Partial<PersonApplicationData>,
  'physicalAddress' | 'mailingAddress'
> & {
  physicalAddress?: Partial<Address>;
  mailingAddress?: Partial<Address>;
  completed?: boolean;
  temporaryId?: string; // we use this to avoid using index keys as prop keys
};

export enum KycApplicationStatus {
  IN_PROGRESS = 'in_progress',
  IN_REVIEW = 'in_review',
  MANUAL_REVIEW = 'manual_review',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export interface SubmitKycApplicationRequest {
  companyId: number;
  peopleApplicationData: PersonApplicationData[];
  businessApplicationData: BusinessApplicationData;
}

export type KycGraphCMSQuestionsDataType = {
  [key in KycQuestionGraphCMSIdEnum]?: CmsQuestionData;
};
export interface SubmitBusinessDataRequest {
  companyId: number;
  businessApplicationData: BusinessApplicationData;
}

export interface SubmitPersonDataRequest {
  companyId: number;
  personApplicationData: PersonApplicationData;
}

export interface SubmitKycBeneficiariesData {
  personApplications: PersonApplicationData[];
}

export interface UpdateKycBeneficiariesData {
  updatedBeneficiaryApplications: SubmitKycBeneficiariesData;
}

export interface KycApplicationData {
  id: number;
  companyId: number;
  filerPersonApplicationId?: string | null;
  beneficialOwner1ApplicationId?: string | null;
  beneficialOwner1EntityId?: string | null;
  beneficialOwner2ApplicationId?: string | null;
  beneficialOwner2EntityId?: string | null;
  beneficialOwner3ApplicationId?: string | null;
  beneficialOwner3EntityId?: string | null;
  beneficialOwner4ApplicationId?: string | null;
  beneficialOwner4EntityId?: string | null;
  numberOfBeneficialOwners?: number | null;
  businessApplicationId?: string | null;
  accreditationApplicationId?: string | null;
  applicationStatus?: KycApplicationStatus;
  archived: Date | null;
  created: Date;
  lastUpdated: Date;
}
