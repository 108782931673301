export const GetFromSessionStorage = (key: string): string | null => {
  if (sessionStorage) {
    return sessionStorage.getItem(key);
  }
  return null;
};

export const SetInSessionStorage = (key: string, value: string): void => {
  if (sessionStorage) {
    sessionStorage.setItem(key, value);
  }
};

export const RemoveFromSessionStorage = (key: string): void => {
  if (sessionStorage) {
    sessionStorage.removeItem(key);
  }
};
