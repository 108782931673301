import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Button, Color, Flex, Heading, Text } from 'component-library';
import {
  useCommonStores,
  useDashboardStores,
  useFeatureFlags,
} from 'stores/useStores';
import TalkToAnExpertButton from 'components/util/TalkToAnExpertButton';

const useStyles = makeStyles(({ breakpoints }) => ({
  startSavingContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
  },
  startSavingSection: {
    maxWidth: 680,
    margin: '0 auto',
    textAlign: 'center',
    padding: '124px 0',
    borderTop: `1px solid ${Color.neutral._20}`,
    position: 'relative',
    zIndex: 2,

    [breakpoints.down('xs')]: {
      padding: '80px 0',
    },
  },
  coinAnimation: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    '& div': {
      position: 'absolute',
    },

    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  coinImage: {
    position: 'absolute',
    width: `100px !important`,
    height: `100px !important`,
    opacity: '0',
    transform: 'scale(0)',
    animation: '$pop 0.6s .25s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards',
    '&:nth-child(even)': {
      animation:
        '$pop 0.6s 1.25s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards',
    },
  },
  '@keyframes pop': {
    '0%': {
      opacity: 0,
      transform: 'scale(0)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
}));

const coinAnimationCoordinates = [
  { top: '20%', left: '5vw' },
  { top: '71%', left: '12vw' },
  { top: '59%', left: '23vw' },
  { top: '10%', left: '18vw' },
  { top: '87%', left: '39vw' },
  { top: '20%', left: '95vw' },
  { top: '71%', left: '82vw' },
  { top: '45%', left: '73vw' },
  { top: '10%', left: '68vw' },
  { top: '87%', left: '90vw' },
];

export const StartSavingSection: React.FC = observer(() => {
  const classes = useStyles();
  const featureFlags = useFeatureFlags();
  const { app } = useCommonStores();
  const { modules } = useDashboardStores();
  const [showCoins, setShowCoins] = useState<boolean>(false);

  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observerOptions: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust the threshold as needed (from 0 to 1)
    };

    const handleIntersection: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShowCoins(true);
        } else {
          setShowCoins(false);
        }
      });
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions,
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <Flex className={classes.startSavingContainer}>
      <div className={classes.coinAnimation} ref={targetRef}>
        {showCoins &&
          coinAnimationCoordinates.map((coin, index) => {
            return (
              <img
                key={`${coin.top}-${index}`}
                className={classes.coinImage}
                src={`${process.env.PUBLIC_URL}/images/logo/partners/start-saving-coin.svg`}
                style={{
                  top: coin.top,
                  left: coin.left,
                }}
              />
            );
          })}
      </div>
      <Flex justifyContent='center' className={classes.startSavingSection}>
        <Flex direction='column' gap={app.isTablet ? 16 : 24}>
          <Heading size={app.isTablet ? 32 : 40} marginBottom={0}>
            Start saving
          </Heading>
          <Text size={app.isTablet ? 15 : 18}>
            It&apos;s free to get started. Uncover your tax credits&nbsp;today.
          </Text>
          <Flex gap={24} alignItems='center' justifyContent='center'>
            <Button
              label='Create Estimate'
              onClick={() => {
                modules.toggleSignUpModal(true);
              }}
            />
            {featureFlags.showTalkToAnExpertButton && (
              <TalkToAnExpertButton buttonText />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
});
