import { Text } from 'component-library';
import React from 'react';
import { CentsToDisplayStringNoZeros } from '../../../../../../lib/helpers';

export interface SubscriptionContentProps {
  monthlyFeeCents: number;
  numFeeMonths: number;
}

export const SubscriptionContent = ({
  monthlyFeeCents,
  numFeeMonths,
}: SubscriptionContentProps) => {
  return (
    <Text>
      {`We’ll charge you ${CentsToDisplayStringNoZeros(
        monthlyFeeCents,
      )} each month, for the next ${numFeeMonths} months.`}
    </Text>
  );
};
