import { CompanyDataEnum } from '../constants';

export enum SurveyAnswerTypeEnum {
  STRING = 'string',
  NUMERIC = 'number',
  YEAR = 'year',
  PHONE_NUMBER = 'phone_number',
  CURRENCY = 'currency',
  BUTTON_SELECT = 'button_select',
}

// TODO: This duplicate CmsQuestionData from client/src/lib/interfaces/graphCMS.ts
//  Decide which one is the source of truth and deprecate the other
export interface SurveyQuestionInterface {
  id: CompanyDataEnum;
  question: string;
  answer: string | number;
  answerType: SurveyAnswerTypeEnum;
  required: boolean;
  placeholder?: string;
}

export enum AccountCreationSteps {
  BUSINESS = 'business',
  CONTACTS = 'contacts',
  TAXES = 'taxes',
  PROGRAMS = 'programs',
  PAYROLL_DATA = 'payroll',
  BUSINESS_ACTIVITIES = 'business_activities',
  ESTIMATES = 'estimates',
}

export interface AccountCreationRequest {
  [CompanyDataEnum.LEGAL_NAME]?: string;
  [CompanyDataEnum.ADMIN_FIRST_NAME]?: string;
  [CompanyDataEnum.ADMIN_LAST_NAME]?: string;
  [CompanyDataEnum.NAME]?: string;
  [CompanyDataEnum.YEAR_FOUNDED]?: number;
  [CompanyDataEnum.WEBSITE]?: string;
  [CompanyDataEnum.ADMIN_JOB_TITLE]?: string;
  [CompanyDataEnum.SECONDARY_EMAIL]?: string;
  [CompanyDataEnum.BUSINESS_PHONE_NUMBER]?: string;
  [CompanyDataEnum.INDUSTRY]?: string;
  [CompanyDataEnum.TAX_TYPE]?: string;
  [CompanyDataEnum.FISCAL_YEAR_END_DATE]?: string;
  unified?: boolean;
}

export enum ProgramTaxYearSelectEnum {
  DISASTER_OR_EXTENSION_2022 = 'I have an extension for income taxes because my business is located in an area that is eligible for a disaster-area relief.',
  EXTENSION_2022 = 'My business has filed for an extension to file in the Fall (September/October).',
  FILING_FOR_2023 = 'I am filing for 2023',
}

export interface ProgramStepQuestion {
  text: ProgramTaxYearSelectEnum;
  taxYear: 2022 | 2023;
  selected: boolean;
}
