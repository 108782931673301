import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Text, Button, Heading } from 'component-library';
import { makeStyles } from '@material-ui/core/styles';
import { SplashPageCard } from '../components/SplashPageCard';
import { Page, ProductTypeEnum, SurveyNameEnum } from '../../../lib/constants';
import { Loading } from '../../../components/util/Loading';
import { CompanyContext } from '../../CompanyRequired';
import { CompanyData } from '../../../lib/interfaces';
import { FinalMagicMoneyPage } from './components/FinalMagicMoneyPage';

const useStyles = makeStyles(() => ({
  root: {
    padding: '64px 160px 64px 64px',
    maxWidth: '1200px',
  },
  buttonBox: {
    paddingTop: '32px',
    position: 'relative',
    zIndex: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 1,
  },
  headerCircles: {
    width: '100%',
    position: 'relative',
    zIndex: 1,
  },
  mainWrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  headerArrow: {
    position: 'absolute',
    top: '-60px',
    right: '-275px',
    zIndex: 0,
  },
  headerText: {
    position: 'relative',
  },
  leftArrow: {
    position: 'absolute',
    transform: 'rotate(-90deg)',
    left: '-360px',
    top: '180px',
    zIndex: 0,
  },
  cardGridItem: {
    position: 'relative',
    zIndex: 1,
  },
  disqualified: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    maxWidth: '850px',
    position: 'relative',
    padding: '40px 80px',
  },
}));

export const MagicMoneySplashPage = () => {
  const classes = useStyles();

  const [company] = useState<CompanyData>(useContext(CompanyContext).company);

  const [loading, setLoading] = useState<boolean>(true);
  const [disqualified, setDisqualified] = useState<boolean>(false);

  useEffect(() => {
    const hasMagicMoneySurvey = !!company.issuedSurveys?.find(
      (issuedSurvey) =>
        issuedSurvey.name === SurveyNameEnum.MAGIC_MONEY_QUALIFICATION,
    );

    if (!hasMagicMoneySurvey) {
      setDisqualified(true);
    }

    setLoading(false);
  }, [company]);

  return (
    <Loading loading={loading}>
      {disqualified && (
        <div className={classes.disqualified}>
          <FinalMagicMoneyPage disqualified={true} />
        </div>
      )}
      {!disqualified && (
        <div className={classes.mainWrapper}>
          <img
            className={classes.headerArrow}
            src={`${process.env.PUBLIC_URL}/images/yellow-arrow.svg`}
            alt='Icon'
          />

          <Grid container className={classes.root}>
            <Grid item xs={6} className={classes.headerText}>
              <img
                className={classes.leftArrow}
                src={`${process.env.PUBLIC_URL}/images/yellow-arrow.svg`}
                alt='Icon'
              />
              <Box className={classes.header}>
                <Heading
                  tag='h1'
                  text={"There's more cash to find than just credits [Pilot]"}
                />
                <Text size={18}>
                  Early access to our newest tools designed to make sure you’re
                  not leaving any cash on the table. Same promise: we’ll do all
                  the legwork and only get paid if you do.
                </Text>
              </Box>
              <Box className={classes.buttonBox}>
                <Button
                  label={'UNLOCK GUARANTEED SAVINGS'}
                  onClick={() => {
                    window.location.href = `/${Page.magicMoneyQualification}`;
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box display='flex' alignItems={'right'}>
                <img
                  className={classes.headerCircles}
                  src={`${process.env.PUBLIC_URL}/images/header-circles.svg`}
                  alt='Icon'
                />
              </Box>
            </Grid>

            <Grid item xs={12} className={classes.cardGridItem}>
              <Box>
                <SplashPageCard
                  productType={ProductTypeEnum.HIGH_YIELD_SAVINGS}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <SplashPageCard
                  productType={ProductTypeEnum.VENDOR_MANAGEMENT}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <SplashPageCard productType={'credits'} />
              </Box>
            </Grid>
          </Grid>
        </div>
      )}
    </Loading>
  );
};
