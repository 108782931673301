import { Content, Divider, Link, Text } from 'component-library';
import React from 'react';

const TotalIncomeGrossReceiptsHelpDrawer = () => {
  return (
    <Content flex flexDirection={'column'}>
      <Text variant={'medium'}>Form 1120</Text>
      <Divider variant={'no-bottom-margin'} />
      <Text>Page 1 Lines 1c, 4, 5, 6, 7, and 10</Text>

      <Text variant={'medium'}>Form 1120C</Text>
      <Divider variant={'no-bottom-margin'} />
      <Text>Page 1 Lines 1c, 5; Page 3 Lines 3a, 4, 5a, 6, 10 </Text>

      <Text variant={'medium'}>Form 1065</Text>
      <Divider variant={'no-bottom-margin'} />
      <Text>Page 1 - 1c, 7; Page 4 - Sch. K, line 3c, 5, 6a, 7, 11</Text>

      <Text variant={'medium'}>Form 1040</Text>
      <Divider variant={'no-bottom-margin'} />
      <Text paddingBottom={32}>Schedule C - Part 1, Line 3</Text>

      <Link
        external
        href='https://mainstreet-help.force.com/help/s/article/How-do-I-find-Gross-Receipts-from-previous-years'
        target='_blank'
      >
        More information about gross receipts
      </Link>
    </Content>
  );
};

export default TotalIncomeGrossReceiptsHelpDrawer;
