import React from 'react';
import { Button, Color, Flex, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Page } from 'lib/constants';

const useStyles = makeStyles(() => ({
  footer: {
    backgroundColor: Color.neutral._light_20,
    position: 'relative',

    '& > p, & > a': {
      position: 'relative',
      zIndex: 2,
    },

    '&:hover > div': {
      opacity: 1,
    },
  },
  hoverGradient: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/dashboard/savings-module/savings-footer-gradient.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    position: 'absolute',
    right: '100px',
    bottom: 0,
    width: '50%',
    height: '100%',
    zIndex: 1,
    opacity: 0,
    transition: 'opacity .4s',
  },
}));

export const SavingsFooter: React.FC = observer(() => {
  const classes = useStyles();

  return (
    <Flex
      className={classes.footer}
      padding={16}
      justifyContent='space-between'
      alignItems='center'
      dataTestId={'savings-footer'}
    >
      <div className={classes.hoverGradient} />
      <Text
        size={15}
        text='Learn more about the process and what to expect from MainStreet.'
      />
      <Button
        variant='inline'
        label='How it works'
        onClick={{
          location: `/${Page.educationalOnboarding}`,
          target: '_self',
        }}
        dataTestId='savings-footer-how-it-works'
      />
    </Flex>
  );
});
