import { CmsQuestionData, CompanyData } from 'lib/interfaces';
import { GraphCmsQuestionIdEnum } from 'lib/constants';
import { Company } from 'entities/Company';

export const showQuestion = (
  taxYear: number,
  programTaxYear: number,
  company: CompanyData | Company,
): boolean => {
  const yearFounded = company?.yearFounded;
  const qualificationQuestions =
    company?.qualificationQuestionsByYear?.[programTaxYear];
  const yearAcquiredCompanyOrSubsidiaryFounded =
    qualificationQuestions?.[
      GraphCmsQuestionIdEnum.YEAR_ACQUIRED_COMPANY_FOUNDED
    ];
  const yearOriginalEntityFounded =
    qualificationQuestions?.[
      GraphCmsQuestionIdEnum.ORIGINAL_ENTITY_FOUNDING_YEAR
    ];
  const yearControlGroupCompanyFounded =
    qualificationQuestions?.[
      GraphCmsQuestionIdEnum.YEAR_CONTROL_GROUP_COMPANY_FOUNDED
    ];

  return [
    yearFounded,
    yearAcquiredCompanyOrSubsidiaryFounded,
    yearOriginalEntityFounded,
    yearControlGroupCompanyFounded,
  ]
    .filter((year) => year !== undefined && year !== null)
    .some((year) => year <= taxYear);
};

/**
 * For taxYear 2023 and prior taxYear we have specific questions hardcoded to taxYear
 * with hard coded show conditions (e.g. id - ckxot708ote3r0b69c58w0e2y of unifiedRdCompanyDetails.json)
 *
 * Going forward we will as a generic question that will handle any taxYear going forward
 * and will not require additional hardcoding for each subsequent taxYear.
 */
export const taxYearFilteringTaxReturnUpload = (
  subQuestion: CmsQuestionData,
  taxYear: number,
  company: CompanyData | Company,
  hardcodedSubQuestionIds?: GraphCmsQuestionIdEnum[],
): boolean => {
  const { id } = subQuestion;

  if (hardcodedSubQuestionIds !== undefined && taxYear < 2024) {
    if (hardcodedSubQuestionIds.includes(id as GraphCmsQuestionIdEnum)) {
      return true;
    }

    return false;
  }

  if (
    showQuestion(taxYear - 1, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.GROSS_RECEIPTS_TAXYEAR_MINUS_1,
      GraphCmsQuestionIdEnum.FEDERAL_INCOME_TAX_RETURN_TAXYEAR_MINUS_1,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  if (
    showQuestion(taxYear - 2, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.GROSS_RECEIPTS_TAXYEAR_MINUS_2,
      GraphCmsQuestionIdEnum.FEDERAL_INCOME_TAX_RETURN_TAXYEAR_MINUS_2,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  if (
    showQuestion(taxYear - 3, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.GROSS_RECEIPTS_TAXYEAR_MINUS_3,
      GraphCmsQuestionIdEnum.FEDERAL_INCOME_TAX_RETURN_TAXYEAR_MINUS_3,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  if (
    showQuestion(taxYear - 4, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.GROSS_RECEIPTS_TAXYEAR_MINUS_4,
      GraphCmsQuestionIdEnum.FEDERAL_INCOME_TAX_RETURN_TAXYEAR_MINUS_4,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  if (
    showQuestion(taxYear - 5, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.GROSS_RECEIPTS_TAXYEAR_MINUS_5,
      GraphCmsQuestionIdEnum.FEDERAL_INCOME_TAX_RETURN_TAXYEAR_MINUS_5,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  return false;
};

export const taxYearFilteringSubsidiaryQRE = (
  subQuestion: CmsQuestionData,
  taxYear: number,
  company: CompanyData | Company,
): boolean => {
  const { id } = subQuestion;

  if (
    showQuestion(taxYear - 1, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.SUBSIDIARY_TAXYEAR_MINUS_1_QRE_PERCENT_OF_TAXYEAR,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  if (
    showQuestion(taxYear - 2, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.SUBSIDIARY_TAXYEAR_MINUS_2_QRE_PERCENT_OF_TAXYEAR,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  if (
    showQuestion(taxYear - 3, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.SUBSIDIARY_TAXYEAR_MINUS_3_QRE_PERCENT_OF_TAXYEAR,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  if (
    showQuestion(taxYear - 4, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.SUBSIDIARY_TAXYEAR_MINUS_4_QRE_PERCENT_OF_TAXYEAR,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  if (
    showQuestion(taxYear - 5, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.SUBSIDIARY_TAXYEAR_MINUS_5_QRE_PERCENT_OF_TAXYEAR,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  return false;
};

export const taxYearFilteringAcquireeQRE = (
  subQuestion: CmsQuestionData,
  taxYear: number,
  company: CompanyData | Company,
): boolean => {
  const { id } = subQuestion;

  if (
    showQuestion(taxYear - 1, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.ACQUIREE_TAXYEAR_MINUS_1_QRE_PERCENT_OF_TAXYEAR,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  if (
    showQuestion(taxYear - 2, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.ACQUIREE_TAXYEAR_MINUS_2_QRE_PERCENT_OF_TAXYEAR,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  if (
    showQuestion(taxYear - 3, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.ACQUIREE_TAXYEAR_MINUS_3_QRE_PERCENT_OF_TAXYEAR,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  if (
    showQuestion(taxYear - 4, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.ACQUIREE_TAXYEAR_MINUS_4_QRE_PERCENT_OF_TAXYEAR,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  if (
    showQuestion(taxYear - 5, taxYear, company) &&
    [
      GraphCmsQuestionIdEnum.ACQUIREE_TAXYEAR_MINUS_5_QRE_PERCENT_OF_TAXYEAR,
    ].includes(id as GraphCmsQuestionIdEnum)
  ) {
    return true;
  }

  return false;
};
