import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import {
  Alert,
  Card,
  Color,
  Heading,
  Link,
  Spinner,
  Text,
} from 'component-library';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import {
  AccountBalanceCard,
  OnboardingCard,
  TransactionsTable,
} from 'pages/dashboard/highYieldSavings/components';
import { BalanceInformation } from 'pages/dashboard/highYieldSavings/components/AccountBalanceCard';
import { makeStyles } from '@material-ui/core';
import {
  ExpectedPaymentDirection,
  ModernTreasuryExpectedPayment,
  TransactionsData,
} from 'lib/interfaces';
import { TMAccount } from '../../../lib/interfaces';
import { PendingTransactionsTable } from './components/PendingTransactionsTable';
import { useTransition } from 'react-spring';
import { datadogLogs } from '@datadog/browser-logs';
import { LedgerTransactionDirection } from '@mainstreet/client-models/financial/accounting/yieldAccountLedger';
import { TransferHandler } from './components/Transfers/TransferHandler';
import MSPlaidClientProvider from 'lib/financial/MSPlaidClientProvider';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 80px',
    maxWidth: '900px',
    minHeight: '1100px',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 80px',
    maxWidth: '1050px',
  },
  pageErrorMsg: {
    color: Color.semantic.$error50,
    marginBottom: '16px',
  },
  header: {
    marginBottom: '48px',
  },
  alert: {
    marginBottom: '24px',
  },
  spinner: {
    margin: '0 auto',
  },
  noTransactionsCard: {
    textAlign: 'center',
    margin: '86px 0px',
  },
  noTransactionsTitle: {
    color: Color.neutral._90,
  },
  cardContainer: {
    border: `1px solid ${Color.neutral._20}`,
    margin: '0 0 48px 0',
  },
  transferContainer: {
    padding: '16px 24px 16px',
    backgroundColor: Color.neutral._light_20,
    borderTop: `1px solid ${Color.neutral._20}`,
  },
  contactUs: {
    margin: '20px 0px',
  },
  horizontalLine: {
    width: '100%',
    height: '1px',
    backgroundColor: Color.neutral._20,
    margin: '25px 0',
  },
  transferLowerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  transferButtons: {
    display: 'flex',

    '& button:last-child': {
      marginLeft: '16px',
    },
  },
  alertContainer: {
    position: 'absolute',
    bottom: 16,
    right: 100,
  },
}));

export const TreasuryManagementAccountPage = () => {
  const classes = useStyles();
  const { client } = useContext(Auth0FeatureContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState<boolean>(
    true,
  );
  const [accountInfo, setAccountInfo] = useState<TMAccount | undefined>(
    undefined,
  );
  const [balanceInfo, setBalanceInfo] = useState<
    BalanceInformation | undefined
  >(undefined);

  const [transferStatus, setTransferStatus] = useState<string>('');
  const [successMessage] = useState<string>('');
  const [pageError, setPageError] = useState<string>('');
  const [transactions, setTransactions] = useState<TransactionsData[]>([]);
  const [pendingTransactions, setPendingTransactions] = useState<
    ModernTreasuryExpectedPayment[]
  >([]);
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState<boolean>(
    false,
  );
  const [transferSideDrawerOpen, setTransferSideDrawerOpen] = useState<boolean>(
    false,
  );
  const [
    transferSideDrawerDirection,
    setTransferSideDrawerDirection,
  ] = useState<ExpectedPaymentDirection>(ExpectedPaymentDirection.CREDIT);
  const [fetchPaymentsLoading, setFetchPaymentsLoading] = useState(false);

  const [showPaymentDeletedAlert, setShowPaymentDeletedAlert] = useState<
    boolean
  >(false);

  const onWithdrawalConfirmation = async (amount: number, account?: string) => {
    setFetchPaymentsLoading(true);
    client
      .TransferFunds({
        amount,
        currency: 'USD',
        description: 'Withdrawal',
        direction: LedgerTransactionDirection.DEBIT,
        financialAccountId: account,
      })
      .then(async ({ data }) => {
        if (data?.createdIds?.expectedPayment) {
          fetchPayments();
          const balanceResponse = await client.GetHysaBalanceInformation();
          if (balanceResponse?.data) {
            setBalanceInfo(balanceResponse.data);
          }
        } else {
          setFetchPaymentsLoading(false);
        }
      });
  };

  const onDepositConfirmation = async (
    amount: number,
    account?: string,
    effectiveDate?: string,
  ) => {
    setFetchPaymentsLoading(true);
    client
      .TransferFunds({
        amount,
        currency: 'USD',
        description: 'Additional contribution',
        direction: LedgerTransactionDirection.CREDIT,
        financialAccountId: account,
        effectiveDate: effectiveDate,
      })
      .then(({ data }) => {
        if (data?.createdIds?.expectedPayment) {
          fetchPayments();
        } else {
          setFetchPaymentsLoading(false);
        }
      });
  };

  const deleteExpectedPayment = async (transactionId: string) => {
    setFetchPaymentsLoading(true);
    const { data } = await client.DeleteExpectedPayment(transactionId);
    if (!data) {
      setFetchPaymentsLoading(false);
      console.error(
        'Error deleting expected withdrawal with id: ',
        transactionId,
      );
      return;
    }
    fetchPayments();
    setShowPaymentDeletedAlert(true);
    const balanceResponse = await client.GetHysaBalanceInformation();
    if (balanceResponse?.data) {
      setBalanceInfo(balanceResponse.data);
    }
    setTimeout(() => {
      setShowPaymentDeletedAlert(false);
    }, 3000);
  };

  const fetchTransactions = useCallback(() => {
    client.GetAccountTransactions().then((res) => {
      setIsTransactionsLoading(false);
      if (res.errorMsg) {
        setPageError(
          'Failed to load transaction history. Please refresh the page to try again or contact support if the problem persists.',
        );
        return;
      }

      const transactionsData = res.data?.txnData;
      if (transactionsData) {
        setTransactions(transactionsData);
      }
      setPageError('');
    });
  }, [client]);

  const fetchPayments = useCallback(() => {
    setFetchPaymentsLoading(true);
    client
      .ListExpectedPayments()
      .then((res) => {
        if (res.errorMsg || !res.data) {
          setPageError(
            'Failed to load pending transactions. Please refresh the page to try again or contact support if the problem persists.',
          );
          return;
        }

        const expectedPayments = res.data;
        if (expectedPayments) {
          setPendingTransactions(
            expectedPayments
              .filter((p) => p.status === 'unreconciled')
              .sort((a, b) => {
                return (
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
                );
              }),
          );
        }
        setPageError('');
      })
      .finally(() => setFetchPaymentsLoading(false));
  }, [client]);

  useEffect(() => {
    if (!accountInfo) {
      setIsLoading(true);
      client.GetTreasuryManagementOnboardingStatus().then(({ data }) => {
        setIsLoading(false);
        if (!data || data.status === 'error') {
          datadogLogs.logger.error(
            'Error retrieving TM step status information',
          );
          return;
        }
        const stepStatus = data.status;
        setBalanceInfo(data.balanceInfo);
        setHasCompletedOnboarding(
          stepStatus === 'funded' || stepStatus === 'ready_to_fund',
        );
      });
      client.GetHysaAccount().then((res) => {
        if (res.errorMsg) {
          setPageError(res.errorMsg);
          return;
        }

        const account = _.merge(new TMAccount(), res.data);
        setAccountInfo(account);
        fetchTransactions();
        fetchPayments();
      });
    }
  }, [
    accountInfo,
    client,
    fetchPayments,
    fetchTransactions,
    isTransactionsLoading,
    transactions,
  ]);

  const alertTransitions = useTransition(showPaymentDeletedAlert, {
    from: { opacity: 0, y: 100 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 100 },
  });

  const renderAlertMessage = (status: string) => {
    if (status === 'fail') {
      return (
        <Text>
          Transfer has failed, please try again. If problem persists, please
          email us at{' '}
          <Link href='mailto:yieldteam@mainstreet.com.'>
            high-yield-support@mainstreet.com.
          </Link>
        </Text>
      );
    }

    return <Text>{successMessage}</Text>;
  };
  const pendingTransactionsTitle = `Pending transactions ${
    !fetchPaymentsLoading ? `(${pendingTransactions.length})` : ''
  }`;

  return (
    <div className={classes.root}>
      {isLoading || !accountInfo ? (
        <Spinner className={classes.spinner} />
      ) : (
        <>
          {!hasCompletedOnboarding ? (
            <>
              <Heading
                className={classes.header}
                tag='h1'
                text='Welcome to MainStreet Treasury Management!'
                size={36}
              />
              {(transferStatus === 'success' || transferStatus === 'fail') && (
                <Alert
                  className={classes.alert}
                  actions={{
                    onClick: () => {
                      setTransferStatus('');
                    },
                    text: 'Dismiss',
                  }}
                  type={transferStatus === 'fail' ? 'alert' : 'success'}
                  text={renderAlertMessage(transferStatus)}
                />
              )}
              <OnboardingCard
                onFinalCompletion={() => setHasCompletedOnboarding(true)}
              />
            </>
          ) : (
            <>
              <Heading
                className={classes.header}
                tag='h1'
                text='High Yield Account'
                size={36}
              />
              {(transferStatus === 'success' || transferStatus === 'fail') && (
                <Alert
                  className={classes.alert}
                  actions={{
                    onClick: () => {
                      setTransferStatus('');
                    },
                    text: 'Dismiss',
                  }}
                  type={transferStatus === 'fail' ? 'alert' : 'success'}
                  text={renderAlertMessage(transferStatus)}
                />
              )}
              <AccountBalanceCard
                alertTransitions={alertTransitions}
                balanceInfo={balanceInfo}
                companyId={accountInfo.company_id}
                setShowPaymentDeletedAlert={setShowPaymentDeletedAlert}
                openSidedrawer={(direction: ExpectedPaymentDirection) => {
                  setTransferSideDrawerDirection(direction);
                  setTransferSideDrawerOpen(true);
                }}
              />
              <Text
                text={pendingTransactionsTitle}
                variant='medium'
                size={23}
              />
              {pendingTransactions.length > 0 && !fetchPaymentsLoading ? (
                <PendingTransactionsTable
                  pendingTransactions={pendingTransactions}
                  handleCancel={(id: string) => deleteExpectedPayment(id)}
                />
              ) : fetchPaymentsLoading ? (
                <Spinner className={classes.spinner} />
              ) : (
                <Card>
                  <div>
                    <Text
                      variant='medium'
                      size={23}
                      className={classes.noTransactionsCard}
                    >
                      <span className={classes.noTransactionsTitle}>
                        There are no pending transactions to show
                      </span>
                    </Text>
                  </div>
                </Card>
              )}
              <Text variant='medium' size={23}>
                Transactions
              </Text>
              {transactions.length > 0 ? (
                <TransactionsTable transactionsList={transactions} />
              ) : isTransactionsLoading ? (
                <Spinner className={classes.spinner} />
              ) : (
                <Card>
                  <div>
                    <Text
                      variant='medium'
                      size={23}
                      className={classes.noTransactionsCard}
                    >
                      <span className={classes.noTransactionsTitle}>
                        There are no transactions to show
                      </span>
                    </Text>
                  </div>
                </Card>
              )}
              {balanceInfo && (
                <MSPlaidClientProvider
                  companyId={accountInfo.company_id}
                  includeBalances
                >
                  <TransferHandler
                    open={transferSideDrawerOpen}
                    setOpen={(open: boolean) => setTransferSideDrawerOpen(open)}
                    direction={transferSideDrawerDirection}
                    balanceInfo={balanceInfo}
                    companyId={accountInfo.company_id}
                    handleConfirm={(
                      amountCents: number,
                      account?: string,
                      effectiveDate?: string,
                    ) =>
                      transferSideDrawerDirection ===
                      ExpectedPaymentDirection.DEBIT
                        ? onWithdrawalConfirmation(amountCents, account)
                        : onDepositConfirmation(
                            amountCents,
                            account,
                            effectiveDate,
                          )
                    }
                  />
                </MSPlaidClientProvider>
              )}
              {pageError && (
                <Text className={classes.pageErrorMsg}>{pageError}</Text>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
