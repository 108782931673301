import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Content, Card, Text, Alert, IconEnum } from 'component-library';
import { MSPlaidClientContext } from 'lib/financial/MSPlaidClientProvider';
import { ACHCard } from './payments/v2/plaid/ACHCard';
import DisplayControl from './payments/v2/plaid/DisplayControl';
import CreditCardContainer from './payments/v2/stripe/CreditCardContainer';
import { BillingDefaultPaymentMethod } from '@mainstreet/client-models/financial/payments/paymentsModels';
import { useCommonStores } from 'stores/useStores';

export type PaymentMethodType = 'bank' | 'card';
export type SelectionStatus = 'default' | 'selecting';

export interface IPaymentMethodState {
  /* Whether to show bank or credit card payment options */
  paymentType: PaymentMethodType;
  /* Whether to show default controls or change/add payment method controls */
  selectionStatus: SelectionStatus;
  /* Whether user is attempting to add new credit card to swap into alternate view*/
  addNewCard: boolean;
  /* To render loading effects when default payment methods are being set */
  setDefaultIsLoading: boolean;
  /* Show ACH mandate text if customer is viewing or adding bank accounts */
  showACHMandate: boolean;
}

const useStyles = makeStyles({
  alert: (props: IPaymentMethodProps) => ({
    marginTop: 24,
    maxWidth: props.maxWidth ?? 900,
  }),
});

interface IPaymentMethodProps {
  companyId: number;
  stripeCustomerId: string | null | undefined;
  onChange?: (
    paymentMethod: BillingDefaultPaymentMethod | null | undefined,
  ) => Promise<void>;
  maxWidth?: number;
  showBankNotice?: boolean;
}

export default function PaymentMethod(props: IPaymentMethodProps) {
  const { state, actions } = useContext(MSPlaidClientContext);
  const classes = useStyles(props);
  const { app } = useCommonStores();
  const [paymentMethodState, setPaymentMethodState] =
    useState<IPaymentMethodState>({
      paymentType: 'bank',
      selectionStatus: 'default',
      addNewCard: false,
      setDefaultIsLoading: false,
      showACHMandate: true,
    });

  const handleChangeSelection = () => {
    setPaymentMethodState({
      ...paymentMethodState,
      selectionStatus:
        paymentMethodState.selectionStatus === 'default'
          ? 'selecting'
          : 'default',
    });
  };

  const handleChangePaymentType = () => {
    setPaymentMethodState((prev) => ({
      ...paymentMethodState,
      selectionStatus: 'default',
      paymentType: prev.paymentType === 'bank' ? 'card' : 'bank',
      showACHMandate: prev.paymentType === 'card',
    }));
  };

  const cancelAddNewCard = () => {
    setPaymentMethodState({
      ...paymentMethodState,
      addNewCard: false,
    });
  };

  /**
   * Get the current default payment method to know which view to load by default.
   * Bank, Card types are returned, bank is shown by default.
   */
  const { getDefaultPaymentMethod } = actions;
  useEffect(() => {
    const getPaymentMethodType = async () => {
      const res = await getDefaultPaymentMethod();

      if (res.type === 'bank') {
        setPaymentMethodState((prev) => ({
          ...prev,
          paymentType: 'bank',
          showACHMandate: true,
        }));
      } else if (res.type === 'card') {
        setPaymentMethodState((prev) => ({
          ...prev,
          paymentType: 'card',
          showACHMandate: false,
        }));
      }
    };
    getPaymentMethodType();
  }, [getDefaultPaymentMethod]);

  const { onChange, showBankNotice = true } = props;

  /**
   * This effect is specifically for the OrderForm page so that it can get
   * updated results of whether or not a default payment method is set and
   * enable the 'Accept' button
   */
  useEffect(() => {
    if (onChange) {
      onChange(state.defaultPaymentMethod);
    }
  }, [state.defaultPaymentMethod, onChange]);

  return (
    <>
      <Card noMargin noBoxShadow noBorder maxWidth={props.maxWidth}>
        <Content paddingTop={16} paddingBottom={24}>
          <Content
            flex
            justifyContent={app.isMobile ? 'flex-start' : 'space-between'}
            alignItems={app.isMobile ? 'flex-start' : 'center'}
            paddingBottom={0}
            gap={app.isMobile ? 0 : 16}
            flexDirection={app.isMobile ? 'column' : 'row'}
          >
            <Text size={18} variant='medium'>
              Payment Method
            </Text>
            <DisplayControl
              defaultPaymentMethod={state.defaultPaymentMethod}
              paymentMethodState={paymentMethodState}
              handleChangePaymentType={handleChangePaymentType}
              handleChangeSelection={handleChangeSelection}
              cancelAddNewCard={cancelAddNewCard}
              plaidAccounts={state.plaidAccounts}
            />
          </Content>

          {/* BANK ACCOUNT SECTION */}
          {paymentMethodState.paymentType === 'bank' && (
            <ACHCard
              selectionStatus={paymentMethodState.selectionStatus}
              setDefaultIsLoading={paymentMethodState.setDefaultIsLoading}
              setPaymentMethodState={setPaymentMethodState}
              companyId={props.companyId}
            />
          )}

          {/* CREDIT CARD SECTION */}
          {paymentMethodState.paymentType === 'card' && (
            <CreditCardContainer
              stripeCustomerId={props.stripeCustomerId}
              selectionStatus={paymentMethodState.selectionStatus}
              setPaymentMethodState={setPaymentMethodState}
              addNewCard={paymentMethodState.addNewCard}
              setDefaultIsLoading={paymentMethodState.setDefaultIsLoading}
            />
          )}
        </Content>
      </Card>
      {paymentMethodState.paymentType === 'bank' &&
        paymentMethodState.showACHMandate &&
        showBankNotice && (
          <Alert
            type='warning'
            icon={IconEnum.exclamation_triangle}
            className={classes.alert}
            text={
              <Text>
                {`By linking your account(s), you authorize MainStreet to debit the
      bank account(s) specified above for any amount owed for charges
      arising from your use of MainStreet's services and/or purchase of
      products from MainStreet, pursuant to MainStreet's website and
      terms, until this authorization is revoked. You may amend or cancel
      this authorization at any time by providing notice to MainStreet
      with 30 (thirty) days notice.`}
                <br />
                <br />
                {`If you use MainStreet's services or purchase additional products periodically pursuant to MainStreet's
      terms, you authorize MainStreet to debit your bank account
      periodically. Payments that fall outside of the regular debits
      authorized above will only be debited after your authorization is
      obtained.`}
              </Text>
            }
          ></Alert>
        )}
    </>
  );
}
