import React from 'react';
import Lottie from 'lottie-web';
import { Color, Flex, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import animationData from './EmptyStateAnimation.json';
import { useEffectOnce } from 'lib/helpers';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '278px',
    backgroundColor: Color.neutral._light_20,
    border: `1px solid ${Color.neutral._20}`,
    borderRadius: '4px',
  },
  content: {
    textAlign: 'center',
  },
}));

export const EmptyStateToDo: React.FC = observer(() => {
  const classes = useStyles();
  const animationContainer = React.useRef<HTMLDivElement>(null);

  useEffectOnce(() => {
    Lottie.loadAnimation({
      container: animationContainer.current as HTMLDivElement,
      animationData,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      name: 'todo-empty-state',
    });
  });

  return (
    <Flex
      className={classes.root}
      dataTestId={'empty-state-to-do'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <div className={classes.content}>
        <div
          ref={animationContainer}
          style={{ width: '120px', margin: 'auto', padding: '18.5px 0px' }}
        />
        <Text
          text={'Nothing to do for now'}
          color={Color.neutral._90}
          size={18}
          variant={'medium'}
        />
        <Text text={'We’ll email you when there is.'} />
      </div>
    </Flex>
  );
});
