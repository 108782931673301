import React from 'react';
import { observer } from 'mobx-react';
import { Color, FontAwesome, Grid, Text } from 'component-library';
import { ProgramNameEnum } from 'lib/constants';
import { RdRedemptionInfo } from './taxCreditsContent/RdRedemptionInfo';
import { IncomeReductionInfo } from './taxCreditsContent/IncomeReductionInfo';
import { ProgramData } from '../../../../../lib/interfaces';

interface TaxCreditsContentProps {
  program: ProgramData;
}

export const TaxCreditsContent: React.FC<TaxCreditsContentProps> = observer(
  ({ program }: TaxCreditsContentProps) => {
    const contentConfig: {
      [key: string]: React.ReactElement;
    } = {
      [ProgramNameEnum.FED_RD_TAX]: (
        <RdRedemptionInfo expectedCreditType={program.expectedCreditType} />
      ),
      [ProgramNameEnum.FED_DISABLED_ACCESS]: <IncomeReductionInfo />,
      [ProgramNameEnum.FED_EMPLOYER_HEALTHCARE]: <IncomeReductionInfo />,
    };

    return (
      <Grid.Cell
        columns={12}
        padding={24}
        alignSelf='center'
        style={{ borderTop: `1px solid ${Color.neutral._20}` }}
      >
        <Grid columns={12} alignItems='center' gap={24}>
          <Grid.Cell columns={12}>
            <Text>
              The following estimate is the amount of credit you could be
              eligible for. The final number will be calculated when preparing
              tax forms.
            </Text>
          </Grid.Cell>
          <Grid.Cell columns={12}>
            <Grid columns={12}>
              <Grid.Cell
                columns={1}
                alignSelf='center'
                justifySelf='center'
                padding={[0, 16]}
              >
                <FontAwesome name='calendar' variant='regular' size={24} />
              </Grid.Cell>
              <Grid.Cell columns={11}>
                {contentConfig[program.name] || <IncomeReductionInfo />}
              </Grid.Cell>
            </Grid>
          </Grid.Cell>
        </Grid>
      </Grid.Cell>
    );
  },
);
