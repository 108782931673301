import React from 'react';
import { observer } from 'mobx-react';
import { Chip, Flex, Text, TextField } from 'component-library';
import { useTaxCreditsStores } from 'stores/useStores';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { TaxInputValueEnum } from 'lib/constants';
import { Quarters } from '../../../../../lib/interfaces';

export interface Form941QuarterlyInputsProps {
  taxYear: number;
  quarter: number;
  classes: ClassNameMap<'container' | 'instructions'>;
  current?: boolean;
}

export const Form941QuarterlyInputs = observer(
  ({ taxYear, quarter, classes, current }: Form941QuarterlyInputsProps) => {
    const { redemption } = useTaxCreditsStores();

    return (
      <Flex padding={24}>
        <Flex direction='column' gap={24} className={classes.container}>
          <Flex
            className={classes.instructions}
            direction='column'
            padding={24}
          >
            <Flex gap={16}>
              <Text variant='medium' size={23}>
                {`Q${quarter} ${taxYear} - Form 941`}
              </Text>
              {current && (
                <Chip
                  text='Current quarter to redeem'
                  variant='warning'
                  showIcon={false}
                />
              )}{' '}
            </Flex>
          </Flex>
          <Flex direction='column' padding={[0, 24, 24, 24]} gap={24}>
            <Flex direction='column'>
              <Text
                variant='medium'
                size={15}
                text='Please enter your payroll taxes below.'
              />
              <Text
                size={15}
                text={`Please refer to your Form 941 for Q${quarter} ${taxYear}`}
              />
            </Flex>
            <TextField
              label={`Social security taxes for Q${quarter}`}
              value={''}
              currencyFormat={true}
              type='number'
              onChange={(e) =>
                redemption.handleInputValueChange(
                  e,
                  taxYear,
                  `q${quarter}` as Quarters,
                  TaxInputValueEnum.SOCIAL_SECURITY,
                  current,
                )
              }
              dataTestId={`${taxYear}-q${quarter}-social-security-taxes`}
              helperText='Find this information on line 5A, column 2 of your Form 941.'
            />
            {taxYear > 2022 && (
              <TextField
                label={`Medicare taxes for Q${quarter}`}
                value={''}
                currencyFormat={true}
                type='number'
                onChange={(e) =>
                  redemption.handleInputValueChange(
                    e,
                    taxYear,
                    `q${quarter}` as Quarters,
                    TaxInputValueEnum.MEDICARE,
                    current,
                  )
                }
                dataTestId={`${taxYear}-q${quarter}-medicare-taxes`}
                helperText='Find this information on Line 5C, Column 2 of your Form 941.'
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    );
  },
);
