import { observer } from 'mobx-react';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Color, Flex, Text } from 'component-library';

const useStyles = makeStyles(() => ({
  button: {
    border: `1px solid ${Color.neutral._50}`,
    display: 'flex',
    backgroundColor: `${Color.neutral.white}`,
    padding: '8px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
    height: '44px',
    margin: '0px auto 8px auto',
  },
  logo: {
    width: '27px',
    marginRight: '10px',
  },
}));

export interface SsoButtonProps {
  onClick: () => Promise<void>;
  src: string;
  text: string;
  dataTestId: string;
}

export const SsoButton = observer(
  ({ onClick, src, text, dataTestId }: SsoButtonProps) => {
    const classes = useStyles();
    return (
      <button
        className={classes.button}
        onClick={onClick}
        data-testid={dataTestId}
      >
        <Flex justifyContent={'center'}>
          <img src={src} alt={text} className={classes.logo} />
          <Text text={text} />
        </Flex>
      </button>
    );
  },
);
