import React, { useContext, useEffect, useState } from 'react';
import { CompanyContext } from 'pages/CompanyRequired';
import { useLocation } from 'react-router-dom';
import { ConnectSystemError } from 'components/onboarding/ConnectSystemError';
import {
  CardSuccessMessage,
  ConnectCard,
  ConnectCardHeader,
} from 'pages/dashboard/integrations';
import { Flex, Button, Card, Content } from 'component-library';
import { EmptyClient } from 'services/ServerClient';
import { useFeatureFlags } from 'stores/useStores';
import { CompanyInfo } from '../../../../../lib/interfaces';
import LoadingWidget from '../../../../../components/util/LoadingWidget';

interface ConnectAccountingProps {
  onAccountingConnected: () => Promise<void>;
  returnPage: string;
  doThisLater?: any;
  accountingLinked?: boolean;
  accountingSystemName?: string;
  isRequired?: boolean;
  useCardComponent?: boolean;
  title?: string;
  subtitle?: string;
}

export const ConnectAccounting = ({
  onAccountingConnected,
  returnPage,
  doThisLater,
  accountingLinked,
  accountingSystemName,
  isRequired = true,
  useCardComponent,
  title = 'Connect your accounting software',
  subtitle = 'This access lets us find you more savings',
}: ConnectAccountingProps) => {
  const featureFlags = useFeatureFlags();
  const [loading, setLoading] = useState(false);
  const { company, setCompany } = useContext(CompanyContext);
  const query = new URLSearchParams(useLocation().search);
  const [codatError, setCodatError] = useState('');
  const [codatCompanyInfo, setCodatCompanyInfo] = useState<
    CompanyInfo | undefined
  >(undefined);

  const accountSystemConnected =
    company.misc.connectedAccountingSystems || accountingSystemName;
  const accountSystemConnectedLower = accountSystemConnected
    ? accountSystemConnected.toString().toLocaleLowerCase()
    : '';
  const xeroConnected = accountSystemConnectedLower === 'xero';
  const [xeroLoading, setXeroLoading] = useState(xeroConnected);
  const displayConnectedCodatInfoMessage = codatCompanyInfo
    ? `${codatCompanyInfo.companyName} is connected to ${accountSystemConnected}`
    : 'Connected to ' + accountSystemConnected;

  const connectWithCodat = () => {
    setLoading(true);

    // returnPage defines the redirect page codat sends the user back to after an attempted connection
    EmptyClient.CreateCodatCompany().then((res) => {
      if (res && res.data && res.data.redirectUrl) {
        window.location.href = `${res.data.redirectUrl}/link?client_host=${
          window.MS_RUN_CONFIG['CODAT_LINK_CLIENT_HOST'] ||
          window.location.origin
        }&return_page=${
          window.MS_RUN_CONFIG['CODAT_LINK_RETURN_PAGE_PREFIX'] || ''
        }${returnPage}`;
      } else if (res && res.errorMsg) {
        setCodatError(res.errorMsg);
        setLoading(false);
      }
    });
  };

  const unlinkCodat = async () => {
    setLoading(true);

    const res = await EmptyClient.UnlinkCodatConnections();
    if (res?.errorMsg) {
      setCodatError(res.errorMsg);
    } else {
      const refreshedCompany = await EmptyClient.CurrentLoggedInCompany();
      if (refreshedCompany) {
        setCompany(refreshedCompany);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (query.get('linked') !== 'true' || company.linkedAccountingSystem) {
      return;
    }

    setLoading(true);

    EmptyClient.AddCodatConnection().then(() => {
      onAccountingConnected().finally(() => setLoading(false));
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // This can later be used to display any accounting system info but is only required for Xero at the moment
    if (xeroConnected) {
      EmptyClient.GetCompanyCodatInfo(company.id).then((res) => {
        if (res.data) {
          setCodatCompanyInfo(res.data.companyInfo);
        }
        setXeroLoading(false);
      });
    }
  }, [company.linkedAccountingSystem, accountingLinked]);

  const header = (
    <ConnectCardHeader
      title={title}
      subtitle={subtitle}
      actionRequired={isRequired && !company.linkedAccountingSystem}
    />
  );

  const cardContent =
    company.linkedAccountingSystem || accountingLinked ? (
      <Flex alignItems='center' gap={16}>
        {xeroLoading ? (
          <div>
            <LoadingWidget />
          </div>
        ) : (
          <CardSuccessMessage
            message={displayConnectedCodatInfoMessage}
            outline={featureFlags.showCodatDisconnectButton}
          />
        )}

        {featureFlags.showCodatDisconnectButton && (
          <Button
            label='Disconnect'
            variant='tertiary'
            type='error'
            loading={loading}
            onClick={unlinkCodat}
          />
        )}
      </Flex>
    ) : (
      <Content paddingLeftRight={0} paddingTopBottom={0} flex>
        <Button
          label={`Connect`}
          onClick={connectWithCodat}
          loading={loading}
        />
        {doThisLater && (
          <Button
            label='Do this later'
            variant='outlined'
            data-testid='payroll_connect_later'
            onClick={() => doThisLater()}
          />
        )}
        {codatError && <ConnectSystemError systemType='accounting' />}
      </Content>
    );

  return useCardComponent ? (
    <Card>
      <Content>{header}</Content>
      <Content paddingTop={0} paddingLeftRight={32} paddingBottom={32}>
        {cardContent}
      </Content>
    </Card>
  ) : (
    <ConnectCard header={header} mainContent={cardContent} />
  );
};
