import React from 'react';
import { observer } from 'mobx-react';
import { Flex, Heading, Text, TextProps } from 'component-library';
import { ContentFrame } from '../../components';

interface UseYourCreditsProps {
  headingSize: number;
  textFontSize: TextProps['size'];
}

export const UseYourCredits: React.FC<UseYourCreditsProps> = observer(
  ({ headingSize, textFontSize }) => {
    return (
      <ContentFrame>
        <Flex direction='column' gap={16}>
          <Heading
            text='Use your credits'
            variant='medium'
            size={headingSize}
            marginBottom={0}
          />
          <Text
            size={textFontSize}
            text="Based on your businesses situation you'll either be qualified for an income or a payroll tax credit."
          />
          <Text
            size={textFontSize}
            text="If income tax: your credits will be applied to your quarterly income taxes. You won't need to do anything else after filing your tax return with the IRS."
          />
          <Text
            size={textFontSize}
            text="If payroll tax: you'll need to file a Form 8974 with your quarterly payroll taxes to redeem your credits."
          />
        </Flex>
        <Flex direction='column' gap={16}>
          <img
            src={`${process.env.PUBLIC_URL}/images/dashboard/educational-module/images/Apply-Credits.svg`}
            title='Use Your Tax Credits'
          />
        </Flex>
      </ContentFrame>
    );
  },
);
