import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Text,
  Color,
  Flex,
  IconEnum,
  TextField,
  Icon,
  Link,
} from 'component-library';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: Color.neutral._light_40,
    borderRadius: '0 0 8px 8px',
    height: '15%',
  },
  textField: {
    background: Color.neutral.white,
    borderRadius: '6px',
  },
  textFieldIcon: {
    marginRight: '4px',
    '&.canSend:hover': {
      filter: 'brightness(1.5)',
    },
  },
  textFieldInput: {
    boxShadow: 'none !important',
    borderRadius: '6px',
    maxWidth: '90%',
  },
  textRows: {
    marginTop: '-4px',
  },
  legalText: {
    color: Color.neutral._60,
  },
}));

export const ChatWindowFooter = observer(() => {
  const classes = useStyles();
  const { chatbot } = useCommonStores();

  const sendMessage = () => {
    if (chatbot.userCanSendMessage) {
      chatbot.sendMessage();
    }
  };

  const submitOnEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  const iconColor = chatbot.userCanSendMessage
    ? Color.blue._60
    : Color.neutral._40;

  return (
    <Flex className={classes.root} direction='column' padding={4}>
      <Flex direction='column' padding={8}>
        <TextField
          className={classes.textField}
          inputClassName={classes.textFieldInput}
          value={chatbot.currentChatMessage}
          onChange={(e) => chatbot.updateCurrentChatMessage(e.target.value)}
          placeholder='Type your message here...'
          trailingContent={
            <Icon
              className={`${classes.textFieldIcon} ${
                chatbot.userCanSendMessage && 'canSend'
              }`}
              name={IconEnum.send}
              color={iconColor}
              onClick={sendMessage}
            />
          }
          small
          onKeyDown={submitOnEnter}
        />
      </Flex>
      <Flex
        gap={4}
        alignItems='center'
        justifyContent='center'
        className={classes.textRows}
      >
        <Text size={13} text='Powered By' />
        <Text size={13} text='MainStreet AI' variant='bold' />
        <>
          <Text size={13} text='|' />
          <Link
            text='Learn more'
            size={13}
            underline
            href='https://mainstreet-help.force.com/help/s/article/What-is-MainStreet-AI'
            target='_blank'
          />
        </>
      </Flex>
      <Flex
        gap={4}
        padding={4}
        alignItems='center'
        justifyContent='center'
        className={classes.textRows}
      >
        <Text
          size={11}
          text='MainStreet AI is not for legal or tax advice'
          className={classes.legalText}
        />
      </Flex>
    </Flex>
  );
});
