import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Color,
  Flex,
  FontAwesome,
  Grid,
  Icon,
  IconEnum,
  Link,
  Text,
} from 'component-library';
import { Page } from 'lib/constants';
import { GradientBorder } from 'products/dashboard/features/savings-module/components';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  msGuarantee: {
    position: 'relative',
    borderRadius: '8px',
  },
  msGuaranteeContent: {
    position: 'relative',
    backgroundColor: Color.neutral.white,
    zIndex: 2,
  },
}));

export const IncludedServicesContent: React.FC = observer(() => {
  const classes = useStyles();
  const { app } = useCommonStores();
  const services = [
    {
      title: 'Credit calculations',
      detail: `We'll calculate your Federal R&D Tax Credit amount and provide a completed IRS form 6765 to file with your annual tax return.`,
    },
    {
      title: 'Detailed research report',
      detail: `We'll provide a breakdown of all Qualified Research Expenses and how they contributed to your overall credit amount.`,
    },
    {
      title: 'Ongoing Support',
      detail: `We'll help you work with your payroll provider to apply the credit (if applicable) and jump in to support you in the unlikely event of an audit.`,
    },
  ];

  return (
    <Grid.Cell
      columns={12}
      padding={24}
      style={{ borderTop: `1px solid ${Color.neutral._20}` }}
    >
      <Grid columns={12} alignItems='flex-start' gap={24}>
        <Grid.Cell columns={app.isMobile ? 12 : 7}>
          {services.map((service, index) => {
            return (
              <Grid key={index} columns={12} gap={8} padding={[8, 0]}>
                <Grid.Cell
                  padding={[0, 8]}
                  columns={1}
                  style={{ textAlign: 'center' }}
                >
                  <FontAwesome
                    name='check'
                    variant='regular'
                    color={Color.semantic.$success50}
                    size={15}
                  />
                </Grid.Cell>
                <Grid.Cell columns={11}>
                  <Text variant='medium' text={service.title} />
                  <Text text={service.detail} />
                </Grid.Cell>
              </Grid>
            );
          })}
        </Grid.Cell>
        <Grid.Cell
          columns={app.isMobile ? 12 : 5}
          className={classes.msGuarantee}
        >
          <Flex
            gap={16}
            direction='column'
            className={classes.msGuaranteeContent}
            padding={16}
          >
            <Flex gap={4}>
              <Icon
                name={IconEnum.mainstreet_guarantee}
                color={Color.neutral._90}
              />{' '}
              <Text tag='span' text='MainStreet Guarantee' variant='medium' />
            </Flex>
            <Text
              text={`In the unlikely event of an audit, we'll reimburse you up to $1,000,000 for any accountant fees, penalties or interest you have to pay to the government due to a MainStreet error.`}
            />
            <Text variant='medium'>
              <Link
                text='See the MainStreet Guarantee terms'
                href={`/${Page.mstos}`}
                target='_blank'
                rel='noreferrer'
              />
            </Text>
          </Flex>
          <GradientBorder />
        </Grid.Cell>
      </Grid>
    </Grid.Cell>
  );
});
