import React from 'react';
import { Animate, Color, Flex, Link, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import TitleHeader from 'components/TitleHeader';
import { useCommonStores } from 'stores/useStores';
import { HygraphDisqualQuestions } from 'lib/constants';
import { QualificationQuestions } from 'lib/interfaces';

const useStyles = makeStyles(() => ({
  '@global': {
    '.embeddedServiceHelpButton': {
      display: 'none',
    },
  },
  root: {
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    background: `url(${process.env.PUBLIC_URL}/images/dashboard/get-started/get-started-bg-gradient.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center',
  },
  content: {
    height: '100%',
    maxWidth: '787px',
    margin: '0 auto',
  },
  reasons: {
    border: `1px solid ${Color.neutral._20}`,
    backgroundColor: 'white',
    maxWidth: 680,
    margin: '0 auto',

    '& ul': {
      margin: 0,
      paddingLeft: 24,
      color: Color.neutral._90,
    },

    '& li': {
      paddingBottom: 8,
    },
  },
  reasonsFooter: {
    backgroundColor: Color.neutral._light_20,
  },
}));

interface DisqualifiedProps {
  taxYear: number;
}

export const Disqualified: React.FC<DisqualifiedProps> = observer(
  ({ taxYear }) => {
    const classes = useStyles();
    const { app, companyStore } = useCommonStores();
    const companyQualificationAnswers: QualificationQuestions = companyStore
      .currentCompany.qualificationQuestionsByYear
      ? companyStore.currentCompany.qualificationQuestionsByYear[taxYear]
      : {};

    const getDQReasons = () => {
      const reasons: string[] = [];

      if (companyQualificationAnswers === null) {
        return [];
      }

      HygraphDisqualQuestions.forEach(({ question, answer, customer }) => {
        if (Array.isArray(question) && Array.isArray(answer)) {
          // if question and answers are an array
          question.forEach((str) => {
            const fourPartTest = companyQualificationAnswers[str];
            if (fourPartTest) {
              const failedFourPartTest = answer.some((id) =>
                fourPartTest.includes(id),
              );
              if (failedFourPartTest) {
                if (reasons.indexOf(customer) === -1) {
                  reasons.push(customer);
                }
              }
            }
          });
        } else if (
          // if answers are an array but question is not
          !Array.isArray(question) &&
          Array.isArray(answer) &&
          companyQualificationAnswers[question]?.length > 0
        ) {
          const grantsReceived = companyQualificationAnswers[question];
          const hasGrants = answer.some((id) => grantsReceived.includes(id));
          if (hasGrants) {
            reasons.push(customer);
          }
        } else if (
          !Array.isArray(question) &&
          !Array.isArray(answer) &&
          companyQualificationAnswers[question] === answer
        ) {
          reasons.push(customer);
        }
      });
      return reasons.length > 0 ? reasons : ['Manual disqualification'];
    };

    return (
      <Flex
        className={classes.root}
        data-testid={'disqualified'}
        direction='column'
      >
        <Animate enter={['fade-in', 'from-top']}>
          <TitleHeader title='Estimate your credits' borderBottom />
        </Animate>
        <Animate enter={'fade-in'} duration={1} className={classes.content}>
          <Flex
            justifyContent='center'
            className={classes.content}
            direction='column'
            gap={24}
          >
            <Text
              variant='medium'
              textAlign='center'
              size={app.isMobile ? 32 : 40}
              text={`Your company doesn't qualify to work with MainStreet for ${taxYear} R&D tax credits.`}
            />
            <Text textAlign='center'>
              If you think this was a mistake, please contact our team at{' '}
              <Link href='mailto:sales@mainstreet.com' external>
                sales@mainstreet.com
              </Link>
            </Text>
            {getDQReasons().length > 0 && (
              <Flex direction='column' className={classes.reasons}>
                <Flex.Cell padding={24}>
                  <Text
                    variant='medium'
                    text='Why we have come to this conclusion:'
                    size={23}
                    paddingBottom={24}
                  />
                  <ul>
                    {getDQReasons().map((text, index) => {
                      return (
                        <li key={index}>
                          <Text text={text} tag='span' />
                        </li>
                      );
                    })}
                  </ul>
                </Flex.Cell>
                <Flex.Cell padding={[16, 24]} className={classes.reasonsFooter}>
                  <Text>
                    A member of our team will reach out shortly to gather more
                    information and see if we can assist. Feel free to explore{' '}
                    <Link variant='medium' href='/'>
                      MainStreet
                    </Link>{' '}
                    while waiting.
                  </Text>
                </Flex.Cell>
              </Flex>
            )}
          </Flex>
        </Animate>
      </Flex>
    );
  },
);
