import React from 'react';
import { observer } from 'mobx-react';
import { Flex, Link, Modal, Text } from 'component-library';
import Form8974 from 'components/icons/Form8974';
import { useTaxCreditsStores } from 'stores/useStores';

export const PleaseWaitModal = observer(() => {
  const { redemption } = useTaxCreditsStores();
  const errorGeneratingDocuments = redemption.errorGeneratingDocuments;

  return (
    <Modal showModal maxWidth={520}>
      {errorGeneratingDocuments ? (
        <Flex padding={40} direction='column' gap={16} alignItems='center'>
          <Text variant='medium' textAlign='center' size={15}>
            There was an issue generating your forms, please contact us at{' '}
            <Link
              external
              href='mailto:support@mainstreet.com'
              variant='medium'
            >
              support@mainstreet.com
            </Link>{' '}
            for further assistance.
          </Text>
          <Text textAlign='center' size={15}>
            <Link href='/' variant='medium'>
              Return to dashboard
            </Link>
          </Text>
        </Flex>
      ) : (
        <Flex padding={40} direction='column' gap={16} alignItems='center'>
          <Form8974 />
          <Text variant='medium' textAlign='center' size={23}>
            We are generating your missing forms
            <br />
            Please wait...
          </Text>
        </Flex>
      )}
    </Modal>
  );
});
