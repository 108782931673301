import * as React from 'react';
import { useContext } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';

import { Button, Card } from 'component-library';
import { CompanyContext } from './CompanyRequired';
import { useHistory, useParams } from 'react-router-dom';

// Customized switches from https://material-ui.com/components/switches/#customized-switches
const useStyles = makeStyles(
  ({ boxShadowRegular, boxBackgroundColor, darkBlue, cream, grey }: Theme) => ({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: '40px 80px',
      maxWidth: '1050px',
    },
    tableContainer: {
      boxShadow: boxShadowRegular,
      maxWidth: '900px',
      backgroundColor: boxBackgroundColor,
      marginTop: 16,
    },
    tableHeader: {
      backgroundColor: 'rgba(207, 212, 215, 0.1)',
    },
    textBubble: {
      borderRadius: '100px',
      width: '56px',
      height: '32px',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      backgroundColor: 'rgba(207, 212, 215, 0.5)',
    },
    header: {
      fontFamily: "'Alliance Platt', serif",
      margin: '20px 0',
      fontSize: '50px',
    },
    title: {
      fontSize: 24,
      marginTop: 40,
    },
    description: {
      marginTop: '8px',
      lineHeight: '1.4em',
    },
    switchRoot: {
      width: 46,
      height: 26,
      padding: 0,
      margin: 1,
    },
    switchBase: {
      padding: 1,
      color: grey,
      '&.Mui-checked': {
        color: darkBlue,
      },
      '&.Mui-checked + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: darkBlue,
      },
    },
    switchThumb: {
      width: 24,
      height: 24,
      backgroundColor: cream,
    },
    switchTrack: {
      borderRadius: 13,
      opacity: 1,
      backgroundColor: grey,
    },
    payrollCard: {
      flexDirection: 'row',
      padding: '30px',
    },
    payrollCardLeftContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: '4px 0',
    },
    payrollCardProvider: {
      fontSize: '14px',
    },
    payrollCardRightContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '14px',
    },
    nextPageButton: {
      margin: '20px 0',
      width: '180px',
      padding: '0 40px',
      height: '50px',
      fontSize: '18px',
      fontWeight: 500,
      textTransform: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
  }),
);

export default function DisasterReliefQualifyingBusinessFlow() {
  const classes = useStyles();
  const { company } = useContext(CompanyContext);
  const history = useHistory();
  const { tax_year } = useParams<{ tax_year: string; page: string }>();
  const { linkedPayrollSystem } = company;

  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        <Typography variant='h1' className={classes.header}>
          Reimburse employees tax-free with Section 139
        </Typography>
        <Typography className={classes.description}>
          The Section 139 Covid-19 Relief Reimbursements allows you to reimburse
          pandemic related expenses accrued by employees.
        </Typography>
        <Typography className={classes.title} variant='h2'>
          Connect Payroll
        </Typography>
        <Card className={classes.payrollCard}>
          <div className={classes.payrollCardLeftContainer}>
            {linkedPayrollSystem && (
              <img
                alt=''
                src={`${process.env.PUBLIC_URL}/images/checkmark.svg`}
              />
            )}
          </div>
          <div className={classes.payrollCardRightContainer}>
            <Typography component='div' variant='h3'>
              Payroll Provider
            </Typography>
            <Typography className={classes.payrollCardProvider}>
              {linkedPayrollSystem
                ? company.misc.payrollSystem.toUpperCase()
                : 'None Provided'}
            </Typography>
          </div>
        </Card>
        <Typography className={classes.title} variant='h2'>
          1. Add MainStreet as an admin
        </Typography>
        <Typography className={classes.description}>
          Adding hradmin@mainstreet.com as administrator to your payroll
          platform for MainStreet to reimburse your employees. See how it works
          with your payroll provider.
        </Typography>
        <Typography className={classes.title} variant='h2'>
          2. Define budget and invite employees for reimbursement
        </Typography>
        <Typography className={classes.description}>
          Allocate a budget for each qualifying question across your employees,
          invite them to MainStreet, and we’ll handle the rest.
        </Typography>

        <Button
          className={classes.nextPageButton}
          label='CONTINUE'
          onClick={() => {
            history.push(`/qualifying/${tax_year}/disaster-relief/2`);
          }}
        />
      </div>
    </div>
  );
}
