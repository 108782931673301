import { OtherAccountState } from 'lib/constants';
import { Card, Content } from 'component-library';
import { SupportLink } from 'components/util/SupportLink';
import * as React from 'react';

interface OtherAccountStateCardProps {
  reason: OtherAccountState;
}

export const OtherAccountStateCard = ({
  reason,
}: OtherAccountStateCardProps) => {
  return (
    <Card>
      <Content>
        <ExplanationText reason={reason} />
      </Content>
    </Card>
  );
};

const ExplanationText = ({ reason }: OtherAccountStateCardProps) => {
  switch (reason) {
    case 'OTHER_PROBLEM':
      return (
        <>
          There was a problem loading your information. Please contact{' '}
          <SupportLink />
        </>
      );
    case 'DISQUALIFIED':
      return (
        <>
          <p>
            Unfortunately, it looks like you are not qualified to claim any
            credits through MainStreet at this time. We&apos;ll continue to look
            for credits for you and let you know when we find some.
          </p>
          <p>
            If you qualify for other credits, such as the Section 139 plan,
            check back here once one of our account executives has helped you
            out.
          </p>
          <p>
            Please contact us at <SupportLink /> if you have any questions.
          </p>
        </>
      );

    case 'BILLING_FAILED':
      return (
        <>
          <p>Your most recent payment has failed.</p>
          <p>
            Please check that your billing information is up to date and then
            contact us at <SupportLink /> to get access to your cash advance.
          </p>
        </>
      );
    case 'ON_HOLD':
      return (
        <>
          <p>
            Your MainStreet account is currently on hold as our team was unable
            to verify your account.
          </p>
          <p>
            Please contact us at <SupportLink /> if you have any questions.
          </p>
        </>
      );
  }
};
