import { Grid, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/styles/withStyles';

const useStyles = makeStyles(() => {
  const jobGroup: ClassNameMap = {
    textTransform: 'uppercase',
    height: '30px',
    verticalAlign: 'middle',
  };

  return {
    iconContainer: {
      width: '30px',
      textAlign: 'center',
    },
    icon: {
      transform: 'rotate(90deg)',
    },
    jobGroup,
    jobGroupSelected: {
      ...jobGroup,
      fontWeight: 500,
    },
    clickable: {
      cursor: 'pointer',
    },
  };
});

export interface JobGroupSideBarItemProps {
  isSelected: boolean;
  item: string;
  isClickable: boolean;
}

export const JobGroupSideBarItem = ({
  item,
  isSelected,
  isClickable,
}: JobGroupSideBarItemProps) => {
  const classes = useStyles();
  const classNames: string[] = [];

  if (isSelected) {
    classNames.push(classes.jobGroupSelected);
  } else {
    classNames.push(classes.jobGroup);
  }

  if (isClickable) {
    classNames.push(classes.clickable);
  }

  return (
    <Grid container>
      <Grid item className={classes.iconContainer}>
        {isSelected && <ArrowDropDownIcon className={classes.icon} />}
      </Grid>
      <Grid item xs>
        <Typography
          component='div'
          variant='body1'
          className={classNames.join(' ')}
        >
          {item}
        </Typography>
      </Grid>
    </Grid>
  );
};
