import React from 'react';
import { Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import {
  wireInstructions,
  instructionsLabels,
} from 'lib/constants/treasuryManagementConstants';
import { WireInstructionsItems } from 'lib/interfaces';

const useStyles = makeStyles(() => ({
  instructionsContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    gridColumnGap: '24px',
  },
  instructionsLeft: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    rowGap: '24px',
  },
  instructionsRight: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    gridRowGap: '12px',
    padding: '10px',
    border: '1px solid #CFD4D7',
    background: '#FFFDF2',
  },
  instructions: {
    display: 'grid',
    gridTemplateColumns: 2,
    gridTemplateRows: 'auto',
    rowGap: '5px',
  },
  textBottom: {
    paddingBottom: '28px',
  },
  strong: {
    fontWeight: 500,
  },
}));

interface WireInstructionsProps {
  companyId: number;
}

export const WireInstructions = ({ companyId }: WireInstructionsProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.instructionsContainer}>
        <div className={classes.instructionsLeft}>
          <Text variant='regular' size={23}>
            <strong className={classes.strong}>
              Deposit through wire transfer
            </strong>
          </Text>
          <div>
            <Text
              variant='regular'
              text='To transfer funds into your account, please complete a wire transfer through your bank with the information provided.'
              size={15}
              className={classes.textBottom}
            />
            <Text
              variant='regular'
              text='This is the only place these wire instructions will appear. They will never be emailed or sent to you outside of your dashboard.'
              size={15}
            />
          </div>
        </div>
        <div className={classes.instructionsRight}>
          {Object.keys(instructionsLabels).map((key, index) => {
            return (
              <div key={index}>
                <Text size={15}>
                  <>{instructionsLabels[key]}:</>
                </Text>
                <Text size={15}>
                  <>
                    <strong className={classes.strong}>
                      {`${key === 'reference_code' ? companyId : ''}${
                        wireInstructions[key as keyof WireInstructionsItems]
                      }`}
                    </strong>
                  </>
                </Text>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
