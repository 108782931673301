import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { uuid } from 'short-uuid';
import { makeStyles } from '@material-ui/core';
import {
  Button,
  Card,
  Checkbox,
  Color,
  Content,
  Heading,
  Text,
} from 'component-library';
import {
  PersonApplicationDataDraft,
  // CountryEnum,
  // StateEnum,
  PersonApplicationData,
} from 'lib/interfaces';
import LoadingWidget from 'components/util/LoadingWidget';
import { BackButton } from './BackButton';
import { FormRedirects } from 'lib/helpers';
import { KycBeneficiaryInfoSummary } from './KYCBeneficiaryInfoSummary';
import {
  // IsProd,
  PersonApplicationDataIsComplete,
} from './helpers';
import { Page } from 'lib/constants';
import { PersonalInfoApplication } from './BeneficiaryApplication';
import { Auth0FeatureContext } from '../../../components/util/Auth0Feature';

// const mockedPersonData = {
//   firstName: 'Jane',
//   lastName: 'Doe',
//   dateOfBirth: '02/20/1990',
//   phoneNumber: '(408) 221-2321',
//   emailAddress: 'janedoe@mainstreet.com',
//   citizenship: CountryEnum.UNITED_STATES,
//   occupation: undefined,
//   title: 'CSO',
//   physicalAddress: {
//     streetLine1: '123 Main Street',
//     streetLine2: undefined,
//     city: 'San Jose',
//     state: StateEnum.CALIFORNIA,
//     country: CountryEnum.UNITED_STATES,
//     postalCode: '134223',
//   },
//   ownershipPercentage: 26,
//   isControlPerson: true,
//   tin: '123-12-1233',
//   completed: true,
//   temporaryId: uuid(),
// };
// const mockedSecondPersonData = {
//   firstName: 'John',
//   lastName: 'Doe',
//   dateOfBirth: '02/27/1990',
//   phoneNumber: '(408) 221-2322',
//   emailAddress: 'johndoe@mainstreet.com',
//   citizenship: CountryEnum.UNITED_STATES,
//   occupation: undefined,
//   title: 'CFO',
//   physicalAddress: {
//     streetLine1: '1 Rock Street',
//     streetLine2: undefined,
//     city: 'Boulder',
//     state: StateEnum.COLORADO,
//     country: CountryEnum.UNITED_STATES,
//     postalCode: '434223',
//   },
//   ownershipPercentage: 30,
//   isControlPerson: true,
//   tin: '123-12-1213',
//   completed: true,
//   temporaryId: uuid(),
// };

const useStyles = makeStyles(() => ({
  root: {
    padding: '32px 80px',
    maxWidth: '824px',
  },
  zeroMargin: {
    margin: '0',
  },
  button: { width: 184 },
}));

export const KycBeneficiariesApplication = () => {
  const classes = useStyles();
  const auth0Context = useContext(Auth0FeatureContext);
  const [certify, setCertify] = useState(false);

  const redirects = new FormRedirects(
    useHistory,
    Page.kycSuccessPage,
    Page.kycCompanyDetails,
  );

  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [previouslySubmitted, setPreviouslySubmitted] = useState(false);

  // we use {temporaryId: uuid()} to avoid using index keys when rendering multiple applications (causes state issues)
  const [beneficiaries, setBeneficiaries] = useState<
    PersonApplicationDataDraft[]
  >([{ completed: false, temporaryId: uuid() }]);

  useEffect(() => {
    auth0Context.client.GetKycBeneficiariesData().then(({ data, errorMsg }) => {
      if (!data) {
        setContentLoading(false);
        return;
      }

      // A non-404 error has occured
      if (errorMsg) {
        setErrorMessage('Something went wrong loading your previous answers.');
        setContentLoading(false);
        return;
      }

      const { personApplications } = data;

      setBeneficiaries(
        personApplications.map((pa) => ({
          ...pa,
          isPerson: true,
        })),
      ); // Will contain IDs that allow us to differentiate updates
      setPreviouslySubmitted(true);
      setContentLoading(false);
    });
  }, [auth0Context]);

  const onFinish = () => {
    setErrorMessage('');
    if (!beneficiaries.every(PersonApplicationDataIsComplete)) {
      setErrorMessage('Please complete the missing information');
      return;
    }

    const totalOwnership = beneficiaries
      .map((b) => b.ownershipPercentage)
      .reduce((prev, curr) => prev + curr);
    if (totalOwnership > 100) {
      setErrorMessage('Ownership percentages must sum to 100 or less');
      return;
    }

    const beneficiaryApplications: {
      personApplications: PersonApplicationData[];
    } = {
      personApplications: beneficiaries.map(
        (beneficiary: PersonApplicationDataDraft) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { temporaryId, ...beneficiaryInfo } = beneficiary;
          return beneficiaryInfo as PersonApplicationData;
        },
      ),
    };

    setContentLoading(true);
    auth0Context.client
      .SubmitKycBeneficiariesData({
        beneficiaryApplications,
      })
      .then(({ errorMsg, data }) => {
        setContentLoading(false);
        if (errorMsg || !data?.personApplicationResponses) {
          setErrorMessage('Something went wrong with saving your application.');
          return;
        }

        redirects.Forward();
      });
  };

  if (contentLoading) {
    return <LoadingWidget />;
  }
  return (
    <>
      <Content className={classes.root} paddingBottom={16}>
        {/* Back button will either set all applications back into edit mode or go back to the application landing page*/}
        <BackButton
          redirect={
            beneficiaries.every((beneficiary) => !beneficiary.completed)
              ? () => redirects.Backward()
              : () =>
                  setBeneficiaries(
                    beneficiaries.map((beneficiary) => {
                      return { ...beneficiary, completed: false };
                    }),
                  )
          }
        />

        {/* {!IsProd() && (
          <Content paddingLeftRight={0} paddingTopBottom={16}>
            <Button
              label='Mock Data'
              onClick={() => {
                setBeneficiaries([mockedPersonData, mockedSecondPersonData]);
              }}
            />
          </Content>
        )} */}

        <Heading size={36} text='Company owners' tag='h1' />

        <Text
          text='Please complete this form for each individual who owns at least 25% of your company. If no one owns 25% or more, add someone with financial control over the company (for example, the CEO or CFO).'
          size={15}
        />
        {beneficiaries.map((beneficiary, ownerIndex) => (
          <Card key={beneficiary?.temporaryId}>
            {beneficiary?.completed ? (
              <>
                <KycBeneficiaryInfoSummary
                  beneficiaries={beneficiaries}
                  personInfo={beneficiary as PersonApplicationData}
                  previouslySubmitted={previouslySubmitted}
                  removeBeneficiary={() => {
                    const newBeneficiariesList = [...beneficiaries];
                    newBeneficiariesList.splice(ownerIndex, 1);
                    setBeneficiaries(newBeneficiariesList);
                  }}
                  setEditMode={() => {
                    const updatedOwnersArray = [...beneficiaries];
                    updatedOwnersArray[ownerIndex] = {
                      ...beneficiary,
                      completed: false,
                    };
                    setBeneficiaries(updatedOwnersArray);
                  }}
                />
              </>
            ) : (
              <>
                <PersonalInfoApplication
                  personInfo={beneficiary}
                  setPersonInfo={(newOwnerInfo: PersonApplicationDataDraft) => {
                    const updatedOwnersArray = [...beneficiaries];
                    updatedOwnersArray[ownerIndex] = newOwnerInfo;
                    setBeneficiaries(updatedOwnersArray);
                  }}
                  previouslySubmitted={previouslySubmitted}
                  beneficiaries={beneficiaries}
                  removeBeneficiary={() => {
                    const newBeneficiariesList = [...beneficiaries];
                    newBeneficiariesList.splice(ownerIndex, 1);
                    setBeneficiaries(newBeneficiariesList);
                  }}
                  certify={certify}
                  saveBeneficiary={() => {
                    const updatedOwnersArray = [...beneficiaries];
                    updatedOwnersArray[ownerIndex] = {
                      ...beneficiary,
                      completed: true,
                    };
                    setBeneficiaries(updatedOwnersArray);
                  }}
                />
              </>
            )}
          </Card>
        ))}

        <Content flex flexDirection='column' gap={48}>
          <Content>
            {/* Limit to four, since the minimum amount is 25% */}
            {beneficiaries.length < 4 && (
              <Button
                label={
                  <Text
                    text='Add more company owners'
                    color={Color.blue._60}
                    size={15}
                    variant='medium'
                  />
                }
                onClick={() =>
                  setBeneficiaries([
                    ...beneficiaries,
                    { completed: false, temporaryId: uuid() },
                  ])
                }
                variant='outlined'
              />
            )}
          </Content>

          <Checkbox
            options={[
              {
                checked: certify,
                text:
                  'I certify that all individuals who own 25% or more of the company have been included in this application. If there are no such individuals, I certify that this application has been completed with information of a person who has financial control over the company.',
                value: 'certify',
              },
            ]}
            onChange={() => {
              setCertify(!certify);
            }}
          />

          {errorMessage && (
            <Text size={15} status='failed'>
              {errorMessage}
            </Text>
          )}
        </Content>

        <Content paddingLeftRight={0} paddingBottom={16} paddingTop={32}>
          <Button disabled={!certify} label={'Finish'} onClick={onFinish} />
        </Content>
      </Content>
    </>
  );
};
