import React from 'react';
import { observer } from 'mobx-react';
import { Button, Flex, Modal, Text } from 'component-library';
import { useTaxCreditsStores } from 'stores/useStores';
import { ProgramNameEnum, QualificationModalStatusEnum } from 'lib/constants';

interface QualificationStatusModalProps {
  modalOnClick: () => void;
  setModalWidth?: number;
  showModal: boolean;
  programName: ProgramNameEnum;
}

export const QualificationStatusModal = observer(
  ({
    modalOnClick,
    setModalWidth = 624,
    showModal,
    programName,
  }: QualificationStatusModalProps) => {
    const { surveyFlow } = useTaxCreditsStores();
    const filterStatuses = surveyFlow.allQualificationStatuses.filter(
      (status) => status.programName === programName,
    );

    const renderQualStatus = (status: QualificationModalStatusEnum) => {
      const renderStatusContent = ({
        title,
        subtitle,
        cta,
      }: StatusCopyInterface) => {
        return (
          <>
            <img
              src={`${process.env.PUBLIC_URL}/images/coins.svg`}
              alt='Qualification Icon'
              width={100}
            />
            <Text text={title} variant='medium' textAlign='center' size={23} />
            <Text textAlign='center' text={subtitle} paddingBottom={24} />
            <Button
              label={cta}
              onClick={modalOnClick}
              loading={surveyFlow.modalContinueBtnIsLoading}
            />
          </>
        );
      };

      switch (status) {
        case QualificationModalStatusEnum.RETIREMENT_DQ_INELIGIBLE_1:
          return renderStatusContent(statusCopy.ineligible_1);
        case QualificationModalStatusEnum.RETIREMENT_DQ_INELIGIBLE_2:
          return renderStatusContent(statusCopy.ineligible_2);
        case QualificationModalStatusEnum.RETIREMENT_ELIGIBLE_1:
          return renderStatusContent(statusCopy.eligible_1);
        case QualificationModalStatusEnum.BILLING_SUMMARY:
          return renderStatusContent(statusCopy.billingSummary);
        case QualificationModalStatusEnum.DISABLED_ACCESS_DQ:
          return renderStatusContent(statusCopy.disabled_ineligible);
        case QualificationModalStatusEnum.HEALTHCARE_DQ:
          return renderStatusContent(statusCopy.healthcare_ineligible);
        case QualificationModalStatusEnum.UNIFIED_RETIREMENT_DQ:
          return renderStatusContent(statusCopy.unified_retirement_ineligible);
        case QualificationModalStatusEnum.UNIFIED_RD_DQ:
          return renderStatusContent(statusCopy.unified_rd_ineligible);
      }
    };

    return (
      <Modal
        showModal={showModal}
        maxWidth={setModalWidth}
        dataTestId='retirement-qualification-status-modal'
      >
        <Flex
          padding={[24, 24, 40, 24]}
          direction='column'
          gap={16}
          alignItems='center'
        >
          {filterStatuses.length > 0 &&
            filterStatuses[0].status &&
            renderQualStatus(filterStatuses[0].status)}
        </Flex>
      </Modal>
    );
  },
);

interface StatusCopyInterface {
  title: string;
  subtitle: string | JSX.Element;
  cta: string;
}

const statusCopy = {
  ineligible_1: {
    title: `Unlock future savings!`,
    subtitle: (
      <>
        <Text tag='span'>
          While your current details don&apos;t qualify for the 2022 retirement
          plan startup cost and auto-enrollment credits, there&apos;s good news!
          Starting 2023, you may benefit from the Employer Contribution Tax
          Credit , offering significant tax savings on retirement contributions.
          We&apos;ll help you tap into future savings and opportunities.
        </Text>
        <br />
        <Text tag='span'>Stay informed, stay ahead!</Text>
      </>
    ),
    cta: 'Ok, got it',
  },
  ineligible_2: {
    title: `Thanks for letting us know`,
    subtitle: `We will help you claim retirement tax credits next year.`,
    cta: 'Ok, got it',
  },
  eligible_1: {
    title: `Congrats! You are likely eligible for the Retirement Plan Credits`,
    subtitle: `To properly determine your eligibility for the 2023 retirement plan tax credits, we need to identify any Non-Highly Compensated Employees (NHCE) in your company. These are generally employees who don't have significant ownership in the company and earn below a certain threshold.`,
    cta: 'Ok, got it',
  },
  billingSummary: {
    title: 'You are all set!',
    subtitle:
      'We will review all the provided information and subsequently send you Form 8881 along with filing instructions to your email.',
    cta: 'Ok, got it',
  },
  disabled_ineligible: {
    title: `Unlock additional savings!`,
    subtitle: `Unfortunately, you do not meet the requirements for this credit due the disqualified expenses, employee size, or gross receipts. Please continue to explore more opportunities for savings!`,
    cta: 'Ok, got it',
  },
  healthcare_ineligible: {
    title: `Unlock other tax savings!`,
    subtitle: `Unfortunately, you do not meet the requirements for this credit due to the healthcare plan, employee size, or average annual wages. Please continue to explore more opportunities for savings!`,
    cta: 'Ok, got it',
  },
  unified_retirement_ineligible: {
    title: `Unlock other tax savings!`,
    subtitle: `Unfortunately, you do not meet the requirements for this credit due to the retirement plan, employee size, or wage amount. Please continue to explore more opportunities for savings!`,
    cta: 'Ok, got it',
  },
  unified_rd_ineligible: {
    title: `Unlock other tax savings!`,
    subtitle: `Unfortunately, you do not meet the requirements for this credit based on the information you provided. Please continue to explore more opportunities for savings!`,
    cta: 'Ok, got it',
  },
};
