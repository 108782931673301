import { loadStripe } from '@stripe/stripe-js';
import App from 'App';
import * as React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';
import './index.css';
import { initializeDataDog } from './initializeDataDog';

const pubKey = process.env.REACT_APP_STRIPE_PUB_KEY;
if (!pubKey) {
  throw new Error('No stripe public key');
}
const stripePromise = loadStripe(pubKey);

initializeDataDog();

ReactDOM.render(
  /**
   * DO NOT add additional logic here. <App> is designed to be our
   * "top level" component & thus the highest level we are easily able to test.
   *
   * Additional code added inside this render function is not easily tested.
   */
  <App stripePromise={stripePromise} />,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
