import React from 'react';
import { observer } from 'mobx-react';
import { Color, Grid, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  bogoDealText: {
    lineHeight: '1 !important',
  },
}));

interface BogoDealSectionCardHeaderProps {
  mainStreetFee: string;
  isSmallDesktop: boolean;
}

export const BogoDealSectionCardHeader: React.FC<BogoDealSectionCardHeaderProps> = observer(
  ({ mainStreetFee, isSmallDesktop }) => {
    const classes = useStyles();

    return (
      <>
        <Grid.Cell
          columns={9}
          padding={[16, 24, 16, 24]}
          alignSelf='center'
          style={{
            borderRight: `1px solid ${Color.neutral._20}`,
          }}
        >
          <Text
            variant='medium'
            text={'MainStreet fee'}
            size={isSmallDesktop ? 18 : 23}
          />
        </Grid.Cell>
        <Grid.Cell
          columns={3}
          alignSelf='center'
          style={{
            textAlign: 'right',
            backgroundColor: Color.neutral._light_20,
          }}
          padding={[16, 24, 16, 24]}
        >
          <Text
            color={Color.neutral._90}
            size={isSmallDesktop ? 18 : 23}
            variant='medium'
            className={classes.bogoDealText}
          >
            $0 <br />
            <Text tag='span' size={15}>
              Was <s>{mainStreetFee}</s>{' '}
            </Text>
            <Text
              tag='span'
              color={Color.green._70}
              text={`Save ${mainStreetFee}`}
            />
          </Text>
        </Grid.Cell>
      </>
    );
  },
);
