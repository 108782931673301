import { runInAction } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import { BaseOnboardingStore } from '../BaseOnboardingStore';
import { datadogLogs } from '@datadog/browser-logs';
import { Page } from 'lib/constants';
import { PartnerInfo } from 'lib/interfaces';

export class InitialAccountSetupStore extends BaseOnboardingStore {
  public enterCode = '';
  public taxCreditCode = '';
  public getStartedEmail = '';
  public formLoading = false;
  public customEstimate = 0;
  public loadingEstimate = false;
  public codeNotFound = false;
  public showTalkToExpertModal = false;
  public referralInfo: PartnerInfo | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public setTaxCreditCode(str: string) {
    runInAction(() => (this.taxCreditCode = str));
  }

  public setEnterCode(str: string) {
    runInAction(() => (this.enterCode = str));
  }

  public setGetStartedEmail(str: string) {
    runInAction(() => (this.getStartedEmail = str));
  }

  public setFormLoading(bool: boolean) {
    runInAction(() => (this.formLoading = bool));
  }

  public setLoadingEstimate(bool: boolean) {
    runInAction(() => (this.loadingEstimate = bool));
  }

  public setShowTalkToExpertModal(bool: boolean) {
    runInAction(() => (this.showTalkToExpertModal = bool));
  }

  public setReferralInfo(partner: PartnerInfo) {
    runInAction(() => (this.referralInfo = partner));
  }

  public async getAccessCodeEstimate(
    queryParams: URLSearchParams,
    partner: string,
  ) {
    const code = queryParams.get('code') || '';
    runInAction(() => this.setLoadingEstimate(true));
    const res = await this.client.GetAccessCodeEstimate(code);

    if (res.data) {
      const estimate = res.data.sumOfCreditCents;

      runInAction(() => (this.customEstimate = estimate));
      this.app.history.push(
        `/${Page.selfSignupWelcome}/${partner}?${queryParams}`,
      );
    }

    if (res.errorMsg) {
      datadogLogs.logger.error(
        `[PARTNER SIGNUP]: Error fetching estimate with access code: ${code}`,
      );
      runInAction(() => (this.codeNotFound = true));
    }
    runInAction(() => this.setLoadingEstimate(false));
  }

  public async getPartnerReferralInfo(partner: string) {
    const res = await this.client.GetPartnerReferral(partner);
    if (res.errorMsg) {
      datadogLogs.logger.error(
        `[PARTNER LANDING PAGE]: Error getting partner referral info for ${partner} from hygraph`,
      );
    }
    if (res.data && res.data.referral) {
      const partnerInfo = res.data.referral[0];
      this.setReferralInfo(partnerInfo);
    }
  }
}
