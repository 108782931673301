import { Grid, Divider, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles(({ palette }: Theme) => ({
  text: {
    color: palette.grey[600],
  },
}));

interface Props {
  children: React.ReactNode;
}

export const DividerWithText = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <Grid container alignItems='center'>
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item xs={1} className={classes.text}>
        {children}
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  );
};
