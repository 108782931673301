import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Color,
  Flex,
  Icon,
  IconEnum,
  SideDrawer,
  Text,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useTaxCreditsStores } from 'stores/useStores';
import { ClientReviewTaxForms, Document } from 'lib/interfaces';
import { Programs } from 'lib/constants';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';

const useStyles = makeStyles(() => ({
  downloadBtn: {
    marginLeft: 'auto',
  },
  fileIcon: {
    border: `1px solid ${Color.neutral._40}`,
    backgroundColor: Color.neutral._20,
    borderRadius: '50%',
    padding: 4,
    width: 32,
    height: 32,
  },
  fileName: {
    whiteSpace: 'nowrap',
    maxWidth: '270px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

interface ViewAllTaxFormsSideDrawerProps {
  taxYear: string;
  documents: ClientReviewTaxForms[] | null;
}

export const ViewAllTaxFormsSideDrawer = observer(
  ({ taxYear, documents }: ViewAllTaxFormsSideDrawerProps) => {
    const classes = useStyles();
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [documentUrls, setDocumentUrls] = useState<string[]>([]);
    const { unifiedTaxCredits } = useTaxCreditsStores();
    const { client } = useContext(Auth0FeatureContext);

    const getDocumentUrls = useCallback(
      async (allTaxYearDocuments: Document[]) => {
        const docUrls: string[] = [];
        for (const doc of allTaxYearDocuments) {
          await client.GetDocumentUrl(doc.id).then((res) => {
            if (res.errorMsg) {
              setErrorMsg(res.errorMsg);
              return;
            }

            if (res.data) {
              docUrls.push(res.data.url);
            }
          });
        }

        setDocumentUrls(docUrls);
      },
      [client],
    );

    const handleDownloadAll = () => {
      documentUrls.forEach((doc) => {
        window.open(doc, '_blank');
      });
    };

    const onClick = (id: number) => {
      client.GetDocumentUrl(id).then((res) => {
        if (res.errorMsg) {
          setErrorMsg(res.errorMsg);
          return;
        }

        if (res.data) {
          window.open(res.data.url, '_blank');
        }
      });
    };

    useEffect(() => {
      const getDocuments = async () =>
        await getDocumentUrls(unifiedTaxCredits.allTaxYearDocuments);
      getDocuments();
    }, [getDocumentUrls, unifiedTaxCredits.allTaxYearDocuments]);

    return (
      <SideDrawer
        show={unifiedTaxCredits.showTaxFormsSideDrawer}
        closeToggle={() => unifiedTaxCredits.setShowTaxFormsSideDrawer(false)}
        alertMessage={errorMsg}
        alertMessagePosition='bottom'
        alertType='danger'
        drawerContent={
          <Flex direction='column' gap={24}>
            {documents &&
              documents.length > 0 &&
              documents.map((forms, index) => {
                if (forms.documents.length > 0) {
                  return (
                    <Flex key={`${forms.programName}-${index}`} gap={8}>
                      <Flex.Cell basis={32}>
                        <Icon
                          name={IconEnum.file_contract}
                          className={classes.fileIcon}
                          size={24}
                          color={Color.neutral._50}
                        />
                      </Flex.Cell>
                      <Flex direction='column'>
                        <Text variant='medium'>
                          {Programs[forms.programName].displayShort} forms:
                        </Text>
                        {forms.documents.map((doc, index) => {
                          return (
                            <Flex
                              key={`${doc.name}-${index}`}
                              alignItems='center'
                            >
                              <Text size={13} className={classes.fileName}>
                                {doc.name}
                              </Text>
                              <Icon
                                name={IconEnum.download}
                                className={classes.downloadBtn}
                                size={32}
                                color={Color.blue._70}
                                onClick={() => onClick(doc.id)}
                              />
                            </Flex>
                          );
                        })}
                      </Flex>
                    </Flex>
                  );
                } else {
                  <></>;
                }
              })}
            {/* Hiding the download all button for now */}
            {/* {unifiedTaxCredits.allTaxYearDocuments.length > 0 && (
              <Button
                label='Download all'
                flexAlignSelf='flex-end'
                onClick={() => handleDownloadAll()}
              />
            )} */}
          </Flex>
        }
        subtitle='Please find all the tax forms you need to file with the IRS below.'
        title={`Download all ${taxYear} tax forms`}
      />
    );
  },
);
