import React from 'react';
import { Grid, Typography, makeStyles, Theme } from '@material-ui/core';
import LoadingWidget from 'components/util/LoadingWidget';

const useStyles = makeStyles(({ green, grey }: Theme) => ({
  loadingProgress: {
    color: green,
    marginBottom: '20px',
  },
  loadingTitle: {
    fontWeight: 500,
  },
  loadingSubtitle: {
    fontWeight: 400,
    color: grey,
  },
}));

export const LoadingCreditEstimate = () => {
  const classes = useStyles();
  return (
    <Grid container direction='column' alignItems='center' spacing={1}>
      <Grid item xs={12}>
        <LoadingWidget
          circularProgressProps={{ className: classes.loadingProgress }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          component='div'
          variant='h3'
          className={classes.loadingTitle}
        >
          Finding all of your money
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          component='div'
          variant='h3'
          className={classes.loadingSubtitle}
        >
          (this may take a couple minutes)
        </Typography>
      </Grid>
    </Grid>
  );
};
