import { datadogLogs } from '@datadog/browser-logs';
import { FeatureFlagSet } from 'lib/constants/featureFlagConstants';

const logger = datadogLogs.createLogger('EventEmitter');

type EventListener = (params?: EventParamsArgument) => Promise<void>;

export type EventNameType =
  | 'SETUP'
  | 'TEARDOWN'
  | 'ORGANIZATION_ID'
  | 'AUTH_ACCESS_TOKEN'
  | 'CSRF_TOKEN'
  | 'FEATURE_FLAGS';

export interface EventParamsArgument {
  id?: string;
  token?: string;
  featureFlags?: FeatureFlagSet;
  featureFlagOverrides?: FeatureFlagSet;
}

export class EventEmitter {
  private events: Record<string, EventListener[]> = {};

  /**
   * Adds a listener for the event name provided
   * @param name Name of the event to listen for
   * @param listener Callback that gets triggered each time the event is emitted
   */
  public on(name: EventNameType, listener: EventListener): void {
    this.events[name] = this.events[name] || [];
    this.events[name].push(listener);
  }

  /**
   * Adds a one time listener for the event name provided. Listener callback will only be triggered once
   * @param name Name of the event to listen for
   * @param listener Callback that gets triggered one time the event is emitted
   */
  public once(name: EventNameType, listener: EventListener): void;

  /**
   * Waits for the next time the event name is triggered
   * @param name Name of the event to wait for
   */
  public once(name: EventNameType): Promise<void>;

  // Combined method to support overloaded methods above
  public async once(
    name: EventNameType,
    listener?: EventListener,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const wrappedCallback: EventListener = async (params) => {
        try {
          this.off(name, wrappedCallback);
          if (listener) {
            await listener(params);
          }
          resolve();
        } catch (e) {
          reject(e);
        }
      };

      this.on(name, wrappedCallback);
    });
  }

  /**
   * Removes listener from event stack for the event name provided
   * @param name Name of the event to inspect for removal
   * @param listener Callback to be removed
   */
  public off(name: EventNameType, listener: EventListener): void {
    const stack = this.events[name];

    if (stack) {
      const index = stack.indexOf(listener);

      if (index > -1) {
        stack.splice(index, 1);
      }
    }
  }

  /**
   * Triggers all listeners for event name provided
   * @param name Name of the event to trigger
   */
  public async emit(
    name: EventNameType,
    params?: EventParamsArgument,
  ): Promise<void> {
    if (this.events[name]) {
      const results = await Promise.allSettled(
        this.events[name].map((callback) => callback(params)),
      );

      results.forEach((result) => {
        if (result.status === 'rejected') {
          logger.error(
            `Error detected during event emittion for '${name}'`,
            result.reason,
          );
        }
      });
    }
  }
}
