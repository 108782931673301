import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  DocumentStep,
  ProgramCredits,
  SendDocumentsStep,
  ReviewProcessStep,
} from './components';
import {
  Alert,
  Animate,
  Button,
  Card,
  Content,
  Expandable,
  Heading,
  Message,
  Stepper,
  Text,
  Toast,
} from 'component-library';
import { useHistory } from 'react-router-dom';
import { Page, ProgramStageEnum, ProgramSubStageEnum } from 'lib/constants';
import { Document } from 'lib/interfaces';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import TitleHeader from 'components/TitleHeader';
import { CompanyContext } from 'pages/CompanyRequired';
import { useFeatureFlags } from '../../../stores/useStores';

const useStyles = makeStyles(() => ({
  cardPosition: {
    margin: '40px auto 24px',
  },
  alertPosition: {
    margin: '0 auto 80px',
  },
  stepper: {
    padding: '24px 24px 0',
  },
}));

interface ClientReviewProps {
  programId: string;
}

export const ClientReview = ({ programId }: ClientReviewProps) => {
  const classes = useStyles();
  const history = useHistory();
  const featureFlags = useFeatureFlags();

  const [step, setStep] = useState<number>(0);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const [toast, setToast] = useState<Message[]>([]);

  const { company } = useContext(CompanyContext);
  const currentProgramCreditType = company?.programs.find(
    (program) => program.id.toString() === programId,
  )?.filingCreditType;

  const { client } = useContext(Auth0FeatureContext);
  const handleStep = () => setStep(step + 1);
  const onBackToDashboard = () => {
    setLoading(true);
    client
      .UpdateProgramStage({
        programId,
        programStage: ProgramStageEnum.FINISHED,
      })
      .then(({ errorMsg }) => {
        if (errorMsg) {
          return Promise.reject();
        }
        return client.UpdateProgramSubStage({
          programId,
          programSubStage: ProgramSubStageEnum.READY_TO_REDEEM,
        });
      })
      .then(() => {
        setLoading(false);
        window.location.href = `/${Page.taxCredits}`;
      });
  };

  useEffect(() => {
    client
      .GetProgramDocuments(parseInt(programId, 10), 'mainstreet')
      .then((res) => {
        if (res.errorMsg) {
          setErrorMsg(errorMsg);
        }
        if (res.data) {
          const { documents } = res.data;
          if (documents.length > 0) {
            setDocuments(documents);
          }
          setStep(1); // set to step 1 after document fetch
        }
      });
  }, [client, programId, errorMsg]);

  return (
    <>
      <TitleHeader
        title='Final review'
        showExitButton
        onExitText='Only the answers up to the last completed step has been saved'
      />
      <Animate duration={0.5} enter={['fade-in', 'from-bottom']}>
        <Card className={classes.cardPosition} maxWidth={896} noBoxShadow>
          <Content
            paddingTopBottom={0}
            flex
            flexDirection='column'
            alignItems='center'
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/client-review.svg`}
              alt='Client review Illustration'
            />
            <Heading
              text='Good news! You will be able to claim:'
              size={36}
              marginBottom={24}
            />
          </Content>
          <ProgramCredits programId={programId} />
          <Content
            paddingTopBottom={40}
            paddingLeftRight={40}
            flex
            flexDirection='column'
          >
            <Text text='What you should do now:' size={23} />
            <Stepper
              className={classes.stepper}
              orientation='vertical'
              fullWidth
              hideInactiveDescriptions
              animateDescription
              showPastDescriptions
              currentStep={step}
              steps={[
                {
                  step: 'Keep record of your forms',
                  description: (
                    <DocumentStep
                      currentStep={step}
                      handleStep={handleStep}
                      documents={documents}
                      documentsErrorMsg={errorMsg}
                    />
                  ),
                },
                {
                  step: 'Send documents to your tax preparer',
                  description: (
                    <SendDocumentsStep
                      currentStep={step}
                      handleStep={handleStep}
                      setShowAlert={setShowAlert}
                      programId={parseInt(programId, 10)}
                      setToast={setToast}
                      setIsLinkCopied={setIsLinkCopied}
                    />
                  ),
                },
                {
                  step: 'Review the process to redeem your credits',
                  description: (
                    <ReviewProcessStep creditType={currentProgramCreditType} />
                  ),
                },
              ]}
            />
          </Content>
        </Card>
      </Animate>

      <Card
        className={classes.alertPosition}
        maxWidth={896}
        bgColor='transparent'
        noBorder
        noBoxShadow
      >
        <Expandable expand={showAlert}>
          <Content
            paddingLeftRight={0}
            paddingTopBottom={0}
            flex
            flexDirection='column'
            gap={32}
          >
            <Alert
              type='warning'
              text='Please note that your CPA still needs to attach these documents to your timely filed 2021 business income tax return. Otherwise, you will not be able to redeem your credits.'
            />
            <Button
              flexAlignSelf='flex-start'
              label='Back to Dashboard'
              onClick={onBackToDashboard}
              loading={loading}
            />
          </Content>
        </Expandable>
      </Card>
      {isLinkCopied && <Toast toasts={toast} setToast={setToast} />}
    </>
  );
};
