import React from 'react';
import { observer } from 'mobx-react';
import { Button, Flex, Modal, Text } from 'component-library';
import { useTaxCreditsStores } from 'stores/useStores';
import {
  CarryoverAndAdjustStatusEnum,
  FINCH_PAYROLL_SYSTEMS,
  PayrollTierEnum,
} from 'lib/constants';
import { formatPayrollString } from 'lib/helpers';

export const RedemptionFlowResultModal = observer(() => {
  const { form8974, redemption } = useTaxCreditsStores();
  const { payrollTier, payrollProvider } = form8974;
  const payrollProviderDisplay = payrollProvider
    ? FINCH_PAYROLL_SYSTEMS.find((p) => p.id === payrollProvider)
        ?.displayName || formatPayrollString(payrollProvider)
    : 'payroll software ';

  const isTier1or2Provider =
    payrollTier === PayrollTierEnum.TIER_1 ||
    payrollTier === PayrollTierEnum.TIER_2;

  const onTimeAndAvailable =
    redemption.filingStatus === CarryoverAndAdjustStatusEnum.AVAILABLE ||
    redemption.filingStatus === CarryoverAndAdjustStatusEnum.NO_MISSING_FILINGS;

  const renderPayrollProviderContent = () => {
    return (
      <>
        <Text text="You're all set!" variant='medium' size={23} />
        <Text
          textAlign='center'
          text={`Because you've already set up credit redemption with ${payrollProviderDisplay},  you don't need to take any further actions at this time. Just sit back, relax, and enjoy your credits!`}
          paddingBottom={24}
        />
      </>
    );
  };

  return (
    <Modal
      showModal={redemption.showOnTimeToRedeemYourCreditModal}
      closeToggle={async () => await redemption.onTimeToRedeemContinueUpdates()}
      maxWidth={600}
    >
      <Flex padding={24} direction='column' gap={16} alignItems='center'>
        <img
          src={`${process.env.PUBLIC_URL}/images/tax-credit-dashboard-module/tasks-complete.svg`}
          alt='Form 941 Icon'
          width={100}
        />
        {isTier1or2Provider ? (
          renderPayrollProviderContent()
        ) : (
          <>
            {onTimeAndAvailable ? (
              <>
                <Text text='Congratulations!' variant='medium' size={23} />
                <Text
                  textAlign='center'
                  text='You are on time to redeem your credits. You now need to set up your credit redemption for this quarter. If you do not do this on time, you will have to file an amended quarterly payroll tax return for each quarterly deadline you miss.'
                  paddingBottom={24}
                />
              </>
            ) : (
              <>
                <Text text="You're all set!" variant='medium' size={23} />
                <Text
                  textAlign='center'
                  text='There are no further actions to complete at this time. Be sure to keep an eye out for emails from MainStreet, so you can complete any next steps on time!'
                  paddingBottom={24}
                />
              </>
            )}
          </>
        )}
        <Button
          label='Ok, got it!'
          onClick={async () => await redemption.onTimeToRedeemContinueUpdates()}
          loading={redemption.loadingOnContinue}
        />
      </Flex>
    </Modal>
  );
});
