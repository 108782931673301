import { runInAction } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import { BaseOnboardingStore } from '../BaseOnboardingStore';
import { ConnectPayrollSystem } from 'lib/interfaces';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import { GraphCmsQuestionIdEnum } from 'lib/constants';

export class QualificationStore extends BaseOnboardingStore {
  public connectPayrollDoThisLater = false;
  public payrollSystems: ConnectPayrollSystem[] = [];
  public onSubmitLoading = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public setConnectPayrollDoThisLater(bool: boolean) {
    runInAction(() => (this.connectPayrollDoThisLater = bool));
  }

  public async setPayrollSystems(
    email: string,
    token: string,
    taxYear: number,
  ) {
    const companyId = this.company.id;
    const hasQualificationQuestion =
      this.rootStore.common.companyStore.company.qualificationQuestionsByYear;

    await this.client
      .FinchLinkedServices(token, email)
      .then((providers) => {
        if (providers.errorMsg) {
          datadogLogs.logger.error(
            `Error getting payroll provider for company:${companyId}`,
            logContext({ company: this.company }),
          );
        } else {
          runInAction(() => {
            if (
              providers?.data &&
              providers.data.services &&
              providers.data.expiredServices
            ) {
              this.payrollSystems = [
                ...providers.data.services.map((service) => {
                  return {
                    serviceName: service,
                    expired: false,
                  };
                }),
                ...providers.data.expiredServices.map((service) => {
                  return {
                    serviceName: service,
                    expired: true,
                  };
                }),
              ];
            }
          });
        }
      })
      .then(() => {
        const hasQualificationQuestionAnswered = hasQualificationQuestion
          ? !!hasQualificationQuestion?.[taxYear]?.[
              GraphCmsQuestionIdEnum.HOW_MUCH_SPEND_IN_FISCAL_TAX_YEAR
            ]
          : false;

        if (
          this.payrollSystems.length > 0 ||
          hasQualificationQuestionAnswered
        ) {
          runInAction(() => (this.connectPayrollDoThisLater = true));
        }
      });
  }

  public setOnSubmitLoading(bool: boolean) {
    runInAction(() => (this.onSubmitLoading = bool));
  }
}
