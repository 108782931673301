import { Text } from 'component-library';
import React from 'react';
import moment from 'moment/moment';
import { makeStyles } from '@material-ui/core';
import { OrderForm, ProgramData } from '../../../../../../lib/interfaces';

interface OrderFormHeaderContent {
  isBogoDeal: boolean;
  order: OrderForm;
  currentProgram: ProgramData;
}

const useStyles = makeStyles(() => ({
  subtitle: {
    display: 'inline-block',
    marginBottom: '8px',
  },
}));

export const OrderFormHeaderContent = ({
  isBogoDeal,
  order,
  currentProgram,
}: OrderFormHeaderContent) => {
  const classes = useStyles();

  return (
    <Text paddingBottom={8}>
      <Text tag='span' variant='medium' className={classes.subtitle}>
        {isBogoDeal ? 'Program start date' : 'Start date'}:
      </Text>{' '}
      {moment(order?.termStartDate).format('MMMM D, YYYY')}
      <br />
      {isBogoDeal && currentProgram.taxYear === 2023 && (
        <>
          <Text tag='span' variant='medium' className={classes.subtitle}>
            Payment start date:&nbsp;
          </Text>
          January 2024
          <br />
        </>
      )}
      <Text tag='span' variant='medium' className={classes.subtitle}>
        {isBogoDeal ? 'Program duration' : 'Duration'}:
      </Text>{' '}
      {`${order.numFeeMonths} Months`}
    </Text>
  );
};
