export const vaccineCreditFaqs = [
  {
    question:
      "Should I still report hours taken off for the vaccine if I haven't logged it in my payroll account?",
    answerBody: [
      {
        type: 'paragraph',
        content:
          "Yes, this time still qualifies as paid sick leave and doesn't have to be logged in your payroll system.",
      },
    ],
    active: false,
  },
  {
    question:
      "Should I still report hours taken off for the vaccine if I've already reported is as paid sick leave in my payroll account?",
    answerBody: [
      {
        type: 'paragraph',
        content:
          'Yes, this time qualifies as paid sick leave so you should report it.',
      },
    ],
    active: false,
  },
  {
    question:
      'Should I still report hours taken off for the vaccine that I already logged as PTO?',
    answerBody: [
      {
        type: 'paragraph',
        content:
          'If your company has open PTO policy, you should report any time off for the vaccine regardless of whether you logged under your Open PTO policy or under Sick Leave.',
      },
      {
        type: 'paragraph',
        content:
          'If your company has a finite PTO policy, do not report any PTO you used for the vaccine on your survey.',
      },
    ],
    active: false,
  },
  {
    question:
      "What if I haven't taken time off to receive and recover from the vaccine?",
    answerBody: [
      {
        type: 'paragraph',
        content:
          "If you have not taken any time off for the COVID-19 vaccine and subsequent recovery, you can enter 0 hours now and come back to this survey at any time if you've now taken time off!",
      },
    ],
    active: false,
  },
  {
    question:
      "Will answering MainStreet's survey for the Credit for Paid Sick Leave impact my personal taxes or health insurance?",
    answerBody: [
      {
        type: 'paragraph',
        content:
          'No - the survey that your employer is asking you to complete will not have any impact on your personal taxes or health insurance.',
      },
      {
        type: 'paragraph',
        content:
          'The survey is purely designed to allow MainStreet assist your company with claiming this credit in an accurate and compliant manner.',
      },
    ],
    active: false,
  },
  {
    question:
      'I started working here after April 1. Should I only include time I may have taken off while I was employed at my current company? Or should I document time I took off at my previous company?',
    answerBody: [
      {
        type: 'paragraph',
        content:
          "Only include time off you've taken during the period you were employed at your current company.",
      },
    ],
    active: false,
  },
  {
    question:
      "I took time off for the first vaccine, but haven't had my second appointment yet. What should I do?",
    answerBody: [
      {
        type: 'paragraph',
        content:
          "If you are between your first and second doses of your COVID-19 Vaccine and are told to complete this survey, report the time you've taken off so far.",
      },
      {
        type: 'paragraph',
        content:
          "If/when you take more time off related to the vaccine/recovery, come back to this same survey! Each time you fill out this survey, we'll add to the time you've already logged.",
      },
    ],
    active: false,
  },
  {
    question: 'What expenses are meant to be covered under 139 Plans?',
    answerBody: [
      {
        type: 'paragraph',
        content:
          'No, claiming the Credit for Paid Sick Leave does not have an impact on your salary.',
      },
    ],
    active: false,
  },
];

export const s139Faqs = [
  {
    question: 'What expenses are meant to be covered under 139 Plans?',
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'Section 139 plans are designed to help alleviate expenses incurred related to the burden of qualified disasters (ie. the COVID-19 pandemic). Some approved expenses are the following:',
        ],
      },
      {
        type: 'list',
        content: [
          'Additional Rent/Mortgage Paid due to working at home',
          'Utilities - Electricity, Natural gas, Heating oil, Water, Sewer, Garbage, Cleaning Security',
          'Internet Service',
          'Landline telephone',
          'Mobile telephone',
          'Home repairs due to increased wear & tear',
          'Office, PPE, and sanitary supplies',
          'Food and beverage consumed while working',
          'Homeschool and tutoring costs',
          'Non-reimbursed COVID-19 medical expenses',
        ],
      },
    ],
    active: false,
  },
  {
    question: 'What is a 139 Plan?',
    answerBody: [
      {
        type: 'paragraph',
        content: [
          '139 Plans are benefit plans that allow employers to pay their employees tax-free every month for expenses due to “Qualified Disasters”.',
        ],
      },
      {
        type: 'paragraph',
        content: ['A qualified disaster include the following:'],
      },
      {
        type: 'list',
        content: [
          'Results of terrorist or military actions',
          'Disasters declared by the federal government',
          'Catastrophic accidents involving a common carrier',
          'Disasters that warrant federal, state, or local government assistance',
        ],
      },
      {
        type: 'paragraph',
        content: [
          'The California Wildfires, Hurricane Katrina, and the COVID-19 Pandemic are all examples of Qualified Disasters that are covered under these plans.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      'Do these programs ever end? What happens if the government changes their decision on a national disaster?',
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'Once a national disaster has been declared a national disaster, they are rarely ever declared “undone”. That being said, if your program is changed for any reason, we will be the first ones to inform you.',
        ],
      },
      {
        type: 'paragraph',
        content: [
          'The United States has not seen a national emergency with such broad implications in our lifetimes. If other much smaller national emergencies are any guide, the state of emergency will remain in place for years.',
        ],
      },
      {
        type: 'paragraph',
        content: [
          'Once the COVID-19 state of emergency is declared over, the provisions of Section 139 no longer apply and the plan will automatically be converted into an IRS “Accountable Plan,” which achieves many of the same goals as the Section 139 plan but requires slightly different documentation. As always, employees can opt-out at any time.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      "How does this affect an employee's social security payments down the line?",
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'It is likely an employee’s Social Security payments at retirement are reduced. However, the reduction should be relatively insignificant. Social Security benefits are determined based on a 35-year salary history (or the actual number of years a person is a paid employee contributing to the Social Security program, whichever is shorter). Reduced Social Security contributions over the relatively brief period that the Disaster Relief Plan may cover will likely have an immaterial impact on a participant’s Social Security benefit at retirement.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      "How does this affect an employee's social security payments down the line?",
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'It is likely an employee’s Social Security payments at retirement are reduced. However, the reduction should be relatively insignificant. Social Security benefits are determined based on a 35-year salary history (or the actual number of years a person is a paid employee contributing to the Social Security program, whichever is shorter). Reduced Social Security contributions over the relatively brief period that the Disaster Relief Plan may cover will likely have an immaterial impact on a participant’s Social Security benefit at retirement.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      'What happens if I leave my company and I am owed severance? The Severance is typically a percentage of the salary.',
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'MainStreet recommends you review any severance benefits available with your employer. Severance benefits can vary significantly from employer to employer and from employee to employee. Severance benefits can be based on an individual’s time in employment, salary over the term of employment, or a combination of both.',
        ],
      },
      {
        type: 'paragraph',
        content: [
          'If your available severance benefit is a direct percentage of your salary, your severance will likely be reduced if your salary is reduced. However, reduced salary amounts over the relatively brief period that the Disaster Relief Plan may cover will likely have an immaterial impact on a participant’s potential severance benefit. A detailed review of your severance benefit terms is recommended to determine the full impact.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      "I'm allowed to set aside up to $20,000 or 10% of my salary towards my 401k. Do I need to adjust my amount if I sign up for a Section 139 Plan?",
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'MainStreet recommends you review your 401(k) plan details closely. 401(k) plans can vary significantly from employer to employer. There is a dollar limit on how much an individual can contribute to their 401(k) plan per year.',
        ],
      },
      {
        type: 'paragraph',
        content: [
          'The individual contribution limit for 2021 is $19,500. You will likely be able to meet your maximum contribution even if your salary is reduced (assuming you are employed for the entire year). Whether to adjust your 401(k) contribution amount is a personal decision based on each individual’s plan provisions and circumstances.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      'I have 401K Employer Match, up to 5% of my salary. What happens if my salary lowers?',
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'MainStreet recommends you review your 401(k) plan details closely. 401(k) plans can vary significantly from employer to employer. There are dollar limits on how much an individual can contribute to their 401(k) plan per year, and limits on how much an employer can match. The individual contribution limit for 2021 is $19,500. You will likely be able to meet your maximum contribution even if your salary is reduced (assuming you are employed for the entire year).',
        ],
      },
      {
        type: 'paragraph',
        content: [
          'Employer matches are typically a percentage of your contribution. Thus, if you are able to achieve your contribution limit, you will also maximize your employer match. In the event your employer match is actually a percentage of your full salary, your employer match will likely be reduced by the applicable match percentage to the same extent your salary is reduced.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      'Is there any guidance on how much of my rent or mortgage that I’m able to pay with as part of these Section 139 Plans?',
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'Since your monthly shift amount is deemed reasonable based on the approved expenses, there is no further guidance on how each employee should allocate the funds. Due to the state of emergency, there is no requirement to save receipts or track these expenses.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      'So my gross pay will decrease by $1,500 each month, but then I’ll get it back on the same paystub? Does it continue in perpetuity?',
    answerBody: [
      {
        type: 'paragraph',
        content: ['Yes!'],
      },
      {
        type: 'paragraph',
        content: ['Your pre-tax payroll would reduce by $1,500 per month.'],
      },
      {
        type: 'list',
        content: [
          'You would see an additional line on your paystub adding that $1,500 as a “Section 139 Disaster Relief Payment.”.',
          'However, because these funds are deemed non-taxable, you’ll save, on average, $500 a month!',
        ],
      },
      {
        type: 'paragraph',
        content: [
          'This will continue as long as you are participating in the product.',
        ],
      },
    ],
    active: false,
  },
  {
    question: 'Do I have to keep track of everything I spent the money on?',
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'No. Unlike other areas of the Internal Revenue Code, there is not a requirement for formal plan documentation or expense tracking.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      'What happens if I don’t use up the full amount each month on approved expenses?',
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'Most people will, but utilizing the full amount of the monthly distribution is not a requirement of Section 139. This is why the plan amount is not adjustable by the employee - it is deemed to be “reasonable” for an employee like you under IRS guidelines.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      'How does being on a Section 139 Plan impact my ability to secure a mortgage?',
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'In our previous discussions with multiple mortgage lenders, they mentioned that they will typically look at both your pre-tax AND take-home pay. Typically, mortgage lenders take these plan distributions into account during your mortgage application process. So, these plans should not have an impact on your mortgage application.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      'I am a 1099 contractor for a company that is enrolled in a Section 139 Plan through MainStreet. Am I qualified for one of these plans as well?',
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'Unfortunately, as of now, we are only able to assist salaried, W2, US based employees. We are  unable to assist 1099 contractors or any part-time/hourly employees, but are actively working to find ways to support every type of employee.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      'As an employee, what documentation do I need to keep track of throughout the year for Section 139 plans?',
    answerBody: [
      {
        type: 'paragraph',
        content: ['Short answer - no additional documentation is required!'],
      },
      {
        type: 'paragraph',
        content: [
          'Throughout the course of the year, you are not required to keep receipts or any proof of “hardship”, however, you are more than welcome to do so if you would like.',
        ],
      },
      {
        type: 'paragraph',
        content: [
          'There are also no additional forms that you need to file along with your personal taxes.',
        ],
      },
      {
        type: 'paragraph',
        content: [
          'We will provide you with documentation to provide your CPA with, should they have questions regarding your 139 plan distributions.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      'Do I need to document or provide documentation on all of my expenses that are qualified under Section 139 Plans through MainStreet?',
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'Section 139 plans do not require receipts or proof that the extra income you received from these plans were spent on a specific item.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      'What happens to my employer-provided Disability Insurance if I sign up for a Section 139 Plan through MainStreet?',
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'Because your available disability insurance benefit is a direct percentage of your salary, your disability insurance benefit will likely be reduced if your salary is reduced.',
        ],
      },
      {
        type: 'paragraph',
        content: [
          'However, reduced salary amounts over the relatively brief period that the Disaster Relief Plan may cover will likely have an immaterial impact on a participant’s potential disability insurance benefit.',
        ],
      },
    ],
    active: false,
  },
  {
    question:
      'What happens to my employer-provided Life Insurance if I sign up for a Section 139 Plan through MainStreet?',
    answerBody: [
      {
        type: 'paragraph',
        content: [
          'MainStreet recommends you review any insurance benefits available with your employer. Insurance benefits can vary significantly from employer to employer and from employee to employee.',
        ],
      },
      {
        type: 'paragraph',
        content: [
          'If your available insurance benefit is a direct percentage of your salary, your insurance benefit will likely be reduced if your salary is reduced. However, reduced salary amounts over the relatively brief period that the Disaster Relief Plan may cover will likely have an immaterial impact on a participant’s potential insurance benefit. A detailed review of your benefit terms is recommended to determine the full impact.',
        ],
      },
    ],
    active: false,
  },
];
