import React, { useState } from 'react';
import { makeStyles, Theme, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Programs } from 'lib/constants';
import { ProgramName } from 'lib/interfaces';
import { ProgramNameEnum } from 'lib/constants';
import { IncentiveEstimate } from 'pages/tax-processing/qualify/credit-estimate';

const useStyles = makeStyles(({ palette }: Theme) => ({
  underline: {
    borderBottom: `1px dashed ${palette.primary.main}`,
  },
  creditsEstimateArrow: {
    width: '20px',
    marginTop: '5px',
    marginRight: '14px',
    transition: 'transform .25s cubic-bezier(0.77, 0, 0.175, 1)',
    transform: 'rotate(-90deg)',
    transformOrigin: 'center',
  },
  active: {
    transform: 'rotate(0deg)',
  },
  accordion: {
    boxShadow: 'none',
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  creditsIncentiveDiamond: {
    width: '16px',
  },
  diamondIcon: {
    width: '24px',
  },
}));

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: '0',
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: '0 0 0 34px',
    marginTop: '10px',
  },
}))(MuiAccordionDetails);

interface CreditEstimates {
  name: ProgramNameEnum;
  high: number;
  low: number;
}

interface CreditsEstimateMultipleProps {
  creditsEstimates: Array<CreditEstimates>;
  totalCreditsLow: number;
  totalCreditsHigh: number;
}

export const CreditsEstimateMultiple = ({
  creditsEstimates,
  totalCreditsLow,
  totalCreditsHigh,
}: CreditsEstimateMultipleProps) => {
  const [expand, setExpand] = useState<boolean>(true);
  const classes = useStyles();

  // Total available credits and incentives
  let availableCreditSize = 0;
  creditsEstimates.forEach((credit) => {
    if (credit?.name !== ProgramNameEnum.DISASTER_RELIEF) {
      availableCreditSize++;
    }
  });

  return (
    <>
      {availableCreditSize === 1 ? (
        <>
          {creditsEstimates.map((credit: CreditEstimates, key) => {
            const { display } = Programs[credit?.name as ProgramName];

            return (
              <IncentiveEstimate
                key={key}
                estimateHigh={credit.high}
                estimateLow={credit.low}
                displayName={display}
                icon={
                  <img
                    className={classes.diamondIcon}
                    src={`${process.env.PUBLIC_URL}/images/diamond.svg`}
                    alt='MainStreet Diamond'
                  />
                }
                topLevel
              />
            );
          })}
        </>
      ) : (
        <Accordion className={classes.accordion} expanded={expand}>
          <AccordionSummary onClick={() => setExpand(!expand)}>
            <IncentiveEstimate
              estimateHigh={totalCreditsHigh}
              estimateLow={totalCreditsLow}
              displayName={
                <>
                  Across{' '}
                  <span className={classes.underline}>
                    {availableCreditSize} credits.
                  </span>
                </>
              }
              icon={
                <img
                  className={clsx(
                    classes.creditsEstimateArrow,
                    expand && classes.active,
                  )}
                  src={`${process.env.PUBLIC_URL}/images/arrow.svg`}
                  alt='MainStreet Arrow'
                />
              }
              topLevel
            />
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {creditsEstimates.map((credit, key) => {
              const { display } = Programs[credit.name as ProgramNameEnum];

              return (
                <IncentiveEstimate
                  key={key}
                  estimateHigh={credit.high}
                  estimateLow={credit.low}
                  displayName={display}
                  icon={
                    <img
                      className={classes.creditsIncentiveDiamond}
                      src={`${process.env.PUBLIC_URL}/images/diamond.svg`}
                      alt='MainStreet Diamond'
                    />
                  }
                />
              );
            })}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};
