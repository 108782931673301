import React, { useState } from 'react';
import { Flex, Spinner } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { LandingPage } from './components';
import { useEffectOnce } from 'component-library/_helpers/use-utils';
import {
  useCommonStores,
  useDashboardStores,
} from '../../../../stores/useStores';
import { PARTNER_REFERRAL_SSU_KEY, Partners } from '../../../../lib/constants';

const useStyles = makeStyles(() => ({
  '@global': {
    '.embeddedServiceHelpButton': {
      display: 'none !important',
    },
  },
  loading: {
    height: '100%',
  },
}));

export const WelcomePage: React.FC = observer(() => {
  const classes = useStyles();
  const [pageLoad, setPageLoad] = useState<boolean>(true);
  const { search } = location;
  const { auth } = useCommonStores();
  const { modules } = useDashboardStores();
  const urlParams = new URLSearchParams(search);
  const isSignupXero = urlParams.get('signupxero');
  const isSignupOpen = urlParams.get('sign-up');

  useEffectOnce(async () => {
    if (isSignupXero?.toLowerCase() === 'true') {
      const { origin } = window.location;
      localStorage.setItem(PARTNER_REFERRAL_SSU_KEY, Partners.XERO);
      await auth.socialLogin('Xero', origin);
    } else {
      setPageLoad(false);
    }

    if (isSignupOpen === 'true') {
      modules.toggleSignUpModal(true);
    }
  });

  const renderPageLoading = () => (
    <Flex
      justifyContent='center'
      padding={16}
      alignItems='center'
      className={classes.loading}
    >
      <Spinner />
    </Flex>
  );

  return pageLoad ? renderPageLoading() : <LandingPage />;
});
