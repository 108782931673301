import { getCurrentYear } from './helpers';

export const ValidateEmail = (email: string) => {
  const emailTokens = email.split('@').filter((item) => item);
  if (emailTokens.length !== 2) return false;
  const domainTokens = emailTokens[1].split('.').filter((item) => item);
  if (domainTokens.length <= 1 || domainTokens[0].length === 0) return false;
  return true;
};

export const ValidateAndFormatPhone = (phoneNumber: string) => {
  const digitsOnly = phoneNumber?.replace(/\D/g, '');
  const match = digitsOnly?.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!match) return '';
  return `(${match[1]}) ${match[2]}-${match[3]}`;
};

export const ValidatePhone = (phoneNumber: string) => {
  return !!ValidateAndFormatPhone(phoneNumber);
};

export const MIN_PASS_REQS = [
  '8 characters',
  '1 upper case',
  '1 lowercase',
  '1 digit',
];

export const MIN_PASS_REQS_STR =
  'Min 8 characters, 1 uppercase, 1 lowercase, 1 digit';

/**
 * Checks passwords exist and match. Server side handles additional requirement validations.
 */
export const ValidatePassword = (
  password: string,
  confirmation: string,
): { valid: boolean; errorMsg?: string } => {
  if (!password || !confirmation) {
    return {
      valid: false,
      errorMsg: 'Please enter a password.',
    };
  } else if (password !== confirmation) {
    return {
      valid: false,
      errorMsg: 'Passwords do not match.',
    };
  }

  return {
    valid: true,
  };
};

export const isValidYearFounded = (yearFounded: number): boolean => {
  return !isFutureYear(yearFounded) && yearFounded > 1900;
};

export const isFutureYear = (year: number): boolean => {
  return year > getCurrentYear();
};
