import { FormHelperText, Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
  },
}));

export const AccountantDocumentsError = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <FormHelperText error className={classes.error}>
          There was an error loading this link - please confirm with the sender
          that this is a valid link
        </FormHelperText>
      </Grid>
    </Grid>
  );
};
