import { Company } from 'entities/Company';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { setDatadogUserContext } from 'logging/datadogContext';
import { HasManuallyAcceptedOrderForm } from 'lib/helpers';
import { runInAction } from 'mobx';
import { BaseStore } from 'stores/BaseStore';
import { RootStore } from '../RootStore';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import { setDatadogCompanyContext } from 'logging/datadogContext';
import { AddressEntity, AddressTypeEnum } from 'entities/AddressEntity';
import { CompanyAddress } from 'lib/interfaces';
import { CompanyAccessToken } from 'lib/constants';
import { getAccessTokenFromUrl } from '../../lib/authUtils';

export class CompanyStore extends BaseStore {
  private logger = datadogLogs.createLogger('company-store');
  public currentCompany: Company = new Company();
  public isLoadingCurrentCompany = false;
  public isFirstLoadingCompany = true;
  public isMigratedToChargeBee: boolean | null = null;

  public companyAddress: AddressEntity | null = null;
  public mailingAddress: AddressEntity | null = null;

  public accessToken: CompanyAccessToken | undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);

    this.validateAndSetAccessToken();
  }

  private async validateAndSetAccessToken(): Promise<void> {
    runInAction(() => (this.accessToken = getAccessTokenFromUrl()));

    if (!this.accessToken?.token) return;

    const { data } = await this.client.IsTokenExpired(this.accessToken);

    if (data?.isTokenExpired) {
      runInAction(() => (this.accessToken = undefined));
    }
  }

  public removeAccessToken() {
    if (this.accessToken) {
      runInAction(() => (this.accessToken = undefined));
    }
  }

  public async refreshCurrentCompany() {
    runInAction(() => (this.isLoadingCurrentCompany = true));

    // Fetch company data and toggle loading state on response
    const company = this.accessToken
      ? await this.client.CurrentCompanyByToken(this.accessToken)
      : await this.client.CurrentLoggedInCompany();
    const hasPayrollConnected = company?.linkedPayrollSystem;
    const partnerReferral = company?.misc?.partnerReferral;
    const existingCustomer =
      company?.orderForms && HasManuallyAcceptedOrderForm(company?.orderForms);
    const datadogContextOptions = {
      hasPayrollConnected,
      partnerReferral,
      existingCustomer,
      newCustomer: !existingCustomer,
    };
    setDatadogCompanyContext(company?.id, datadogContextOptions);
    this.rootStore.common.featureFlags.setUserIdentity(company?.id);

    runInAction(async () => {
      this.isLoadingCurrentCompany = false;
      this.isFirstLoadingCompany = false;
      this.currentCompany.mergeData(company);

      /**
       * TODO: If Auth0 is disabled we do not really have a "user" and must instead
       * use the admin email of the company. This is not ideal but at the time
       * of this writing only affects preview branches and local development.
       */
      if (
        !this.rootStore.common.auth.isAuth0Enabled &&
        this.currentCompany.adminEmail
      ) {
        setDatadogUserContext(this.currentCompany.adminEmail);
      }

      /**
       * Overrides the boolean 'paymentMethodOnFile' set from the DB since the DB refers
       * to the old payments system and this needs to be set off the new system which uses
       * the paymentsClient method.
       */
      this.featureFlags.paymentsV2UIEnabled && this.setPaymentMethodOnFile();

      if (this.isMigratedToChargeBee === null) {
        await this.getAndUpdateCompanyMigrationStatus();
      }
    });

    // Signal out organization id if it hasn't been set yet
    if (company?.auth0OrganizationId) {
      this.common.auth.maybeSetOrganizationId(company.auth0OrganizationId);
    }
  }

  /**
   * Sets paymentMethodOnFile boolean in company depending on a call to the
   * new payments getDefaultPaymentMethod.
   */
  private async setPaymentMethodOnFile() {
    const paymentMethods = await this.paymentsClient.getDefaultPaymentMethod();
    runInAction(() => {
      if (
        paymentMethods.data?.associatedAccount ||
        paymentMethods.data?.associatedCard
      ) {
        this.currentCompany.paymentMethodOnFile = true;
      } else {
        this.currentCompany.paymentMethodOnFile = false;
      }
    });
  }

  /**
   * Returns migration status to chargebee of logged in company.
   * @deprecated - can be removed once all customers migrated to chargebee
   */
  public async getAndUpdateCompanyMigrationStatus() {
    try {
      const res = await this.paymentsClient.getLucaCustomer(this.company.id);
      const migrated = !!res.data?.lucaCustomer.externalId;
      runInAction(() => {
        this.isMigratedToChargeBee = migrated;
      });
    } catch (error) {
      datadogLogs.logger.error(
        `Error fetching chargebee migration status for companyId ${this.company.id}: ${error}`,
        logContext({ company: this.company }),
      );
    }
  }

  /**
   * Get saved addresses that exist for current company
   */
  public async getCompanyAddresses() {
    const res = await this.client.GetCompanyAddresses();

    if (res.data) {
      const companyAddresses = res.data.addresses;

      const companyAddress = companyAddresses.find(
        (address) => address.addressType === AddressTypeEnum.BUSINESS,
      );

      const mailingAddress = companyAddresses.find(
        (address) => address.addressType === AddressTypeEnum.MAILING,
      );

      if (companyAddress) {
        runInAction(() => (this.companyAddress = companyAddress));
      }

      if (mailingAddress) {
        runInAction(() => (this.mailingAddress = mailingAddress));
      }
    }

    if (res.errorMsg) {
      datadogLogs.logger.error(
        `Error fetching company address for companyId ${this.company.id}: ${res.errorMsg}`,
        logContext({ company: this.company }),
      );
    }
  }

  public async SaveCompanyAddress(req: CompanyAddress) {
    const res = await this.client.SaveCompanyAddress(req);

    if (res.errorMsg) {
      datadogLogs.logger.error(
        `Error saving address for companyId ${this.company.id}: ${res.errorMsg}`,
        logContext({ company: this.company }),
      );
    }
  }

  public async DeleteCompanyAddress(addressType: AddressTypeEnum) {
    const res = await this.client.DeleteCompanyAddress(addressType);

    if (res.errorMsg) {
      datadogLogs.logger.error(
        `Error removing address for companyId ${this.company.id}: ${res.errorMsg}`,
        logContext({ company: this.company }),
      );
    }
  }
}
