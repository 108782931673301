import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Color, Flex } from 'component-library';
import { ChatWindowHeader } from './ChatWindowHeader';
import { ChatWindowFooter } from './ChatWindowFooter';
import { ChatWindowMessages } from './ChatWindowMessages';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    right: '1rem',
    bottom: '1rem',
    borderRadius: '8px',
    width: '400px',
    maxHeight: '716px',
    minHeight: '465px',
    height: 'calc(100vh - 2rem)',
    maxWidth: '90vw',
    backgroundColor: Color.neutral.white,
    boxShadow: Color.shadow.high,
    // Temporary z-index to show on top of existing "Need help?" button which
    // is z-index 999 and outside of our codebase.
    // After AI-38 we'll only show one or the other.
    zIndex: 1000,
  },
}));

export const ChatWindow = observer(() => {
  const classes = useStyles();

  return (
    <Flex className={classes.root} direction='column'>
      <ChatWindowHeader />
      <ChatWindowMessages />
      <ChatWindowFooter />
    </Flex>
  );
});
