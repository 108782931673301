import React from 'react';
import { Card, Color } from 'component-library';
import { makeStyles, Theme } from '@material-ui/core';
import { CentsToDisplayString } from 'lib/helpers';

const useStyles = makeStyles(({ emerald, color }: Theme) => ({
  root: {
    border: `1px solid rgba(0,0,0,0)`,
    margin: '15px 0',
  },
  iconTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 24,
    marginRight: 20,
  },
  header: {
    background: Color.neutral.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 30px',
    borderBottom: `1px solid ${Color.neutral._40}`,
  },
  body: {
    padding: '30px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1.25,
  },
  amountRangeContainer: {
    marginRight: 20,
    width: 175,
    flexShrink: 0,
  },
  amountRangeText: {
    color: emerald,
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 5,
  },
}));

interface CreditsCardProps {
  amountCents: number;
  icon: JSX.Element;
  title: string;
  description: string;
}

export const CreditsCard = ({
  amountCents,
  icon,
  title,
  description,
}: CreditsCardProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.header}>
        <div className={classes.iconTitleContainer}>
          <div className={classes.icon}>{icon}</div>
          <div>{title}</div>
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.amountRangeContainer}>
          <div className={classes.amountRangeText}>
            {CentsToDisplayString(Math.floor(amountCents / 1000_00) * 1000_00)}
          </div>
          <div>Estimated credit</div>
        </div>
        {description}
      </div>
    </Card>
  );
};

export default CreditsCard;
