import { runInAction } from 'mobx';
import { BaseStore } from 'stores/BaseStore';
import { makeSubclassObservable } from 'lib/mobx-utils';
import {
  AccountCreationStepsIndex,
  CompanyDataEnum,
  CommonEmailClients,
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
  Page,
  ProgramNameEnum,
  ProgramStageEnum,
  Programs,
  QualificationStatusEnum,
  StatePrograms,
  UnifiedAccountCreationStepsIndex,
  ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
  RetirementPlanCreditQuestions,
  RetirementPlanCreditQuestionsToAnswers,
} from 'lib/constants';
import {
  SurveyQuestionInterface,
  SurveyAnswerTypeEnum,
  AccountCreationSteps,
  AccountCreationRequest,
  ProgramTaxYearSelectEnum,
  ProgramStepQuestion,
  AccountCreationProgramTable,
} from 'lib/interfaces';
import { RootStore } from 'stores/RootStore';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import {
  GetFromSessionStorage,
  RemoveFromSessionStorage,
} from 'lib/sessionStorage';
import { FloatToDollarString } from 'lib/helpers';

export const UTM_SOURCING_DATA_KEY = 'mainstreet-utm-sourcing-data';

export class AccountCreationStore extends BaseStore {
  public path = `/${Page.accountCreationRoot}`;
  public currentStep = 0;
  public previousStep = 0;
  public fadeOut = false;
  public isNextButtonLoading = false;
  public BusinessStepQuestions: SurveyQuestionInterface[] = [];
  public ContactsStepQuestions: SurveyQuestionInterface[] = [];
  public TaxesStepQuestions: SurveyQuestionInterface[] = [];
  public ProgramStepQuestions: ProgramStepQuestion[] = [];
  public errorMsg = '';

  public showEstimates = false;
  public programCreditEstimates: AccountCreationProgramTable[] | null = null;
  public totalEstimate = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);

    rootStore.event.on('AUTH_ACCESS_TOKEN', async () => {
      const sourcingData = GetFromSessionStorage(UTM_SOURCING_DATA_KEY);
      if (sourcingData) {
        datadogLogs.logger.info(
          `Saving sourcing data for company: ${sourcingData}`,
          logContext({ company: this.company }),
        );
        await this.client.UpdateCompanySourcingData(JSON.parse(sourcingData));
        RemoveFromSessionStorage(UTM_SOURCING_DATA_KEY);
      }
    });
  }

  public setCurrentStep(
    step: AccountCreationStepsIndex | UnifiedAccountCreationStepsIndex,
  ) {
    runInAction(() => {
      if (this.currentStep !== this.previousStep) {
        this.previousStep = this.currentStep;
      }
      this.currentStep = step;
    });
  }

  public getStarted() {
    runInAction(() => {
      this.fadeOut = true;
      setTimeout(() => {
        runInAction(
          () => (this.currentStep = AccountCreationStepsIndex.BUSINESS),
        );
        this.history.push(`/${Page.accountCreationBusiness}`);
      }, 1000);
    });
  }

  public setCurrentStepOnLoad(pathname: string) {
    runInAction(() => {
      switch (pathname) {
        case `/${Page.accountCreationRoot}`:
          this.setCurrentStep(AccountCreationStepsIndex.LANDING_PAGE);
          break;
        case `/${Page.accountCreationBusiness}`:
          this.setCurrentStep(AccountCreationStepsIndex.BUSINESS);
          break;
        case `/${Page.accountCreationContacts}`:
          this.setCurrentStep(AccountCreationStepsIndex.CONTACTS);
          break;
        case `/${Page.accountCreationTaxes}`:
          this.setCurrentStep(AccountCreationStepsIndex.TAXES);
          break;
        case `/${Page.accountCreationPayrollData}`:
          this.setCurrentStep(AccountCreationStepsIndex.PAYROLL_DATA);
          break;
        case `/${Page.accountCreationBusinessActivities}`:
          this.setCurrentStep(AccountCreationStepsIndex.BUSINESS_ACTIVITIES);
          break;
        case `/${Page.accountCreationPrograms}`:
          this.setCurrentStep(AccountCreationStepsIndex.PROGRAMS);
          break;
      }
    });
  }

  public setUnifiedCurrentStepOnLoad(pathname: string) {
    runInAction(() => {
      switch (pathname) {
        case `/${Page.accountCreationRoot}`:
          this.setCurrentStep(UnifiedAccountCreationStepsIndex.LANDING_PAGE);
          break;
        case `/${Page.accountCreationBusiness}`:
          this.setCurrentStep(UnifiedAccountCreationStepsIndex.BUSINESS);
          break;
        case `/${Page.accountCreationTaxes}`:
          this.setCurrentStep(UnifiedAccountCreationStepsIndex.TAXES);
          break;
        case `/${Page.accountCreationPayrollData}`:
          this.setCurrentStep(UnifiedAccountCreationStepsIndex.PAYROLL_DATA);
          break;
        case `/${Page.accountCreationBusinessActivities}`:
          this.setCurrentStep(
            UnifiedAccountCreationStepsIndex.BUSINESS_ACTIVITIES,
          );
          break;
        case `/${Page.accountCreationPrograms}`:
          this.setCurrentStep(UnifiedAccountCreationStepsIndex.PROGRAMS);
          break;
      }
    });
  }

  public setBusinessStepQuestions() {
    const { legalName, name, yearFounded, website, adminEmail } = this.company;
    const emailDomain = adminEmail.split('@')[1];
    const isCommonMailClient = CommonEmailClients.includes(emailDomain);
    const prefillWebsite = !isCommonMailClient ? emailDomain : '';

    runInAction(() => {
      this.BusinessStepQuestions = [
        {
          id: CompanyDataEnum.LEGAL_NAME,
          question: 'Legal business name',
          answer: legalName || '',
          answerType: SurveyAnswerTypeEnum.STRING,
          required: true,
        },
        {
          id: CompanyDataEnum.NAME,
          question: 'Doing business as (DBA)',
          answer: name || '',
          answerType: SurveyAnswerTypeEnum.STRING,
          placeholder: 'Optional',
          required: false,
        },
        {
          id: CompanyDataEnum.YEAR_FOUNDED,
          question: 'Year founded',
          answer: yearFounded || '',
          answerType: SurveyAnswerTypeEnum.YEAR,
          required: true,
        },
        {
          id: CompanyDataEnum.WEBSITE,
          question: 'Website',
          answer: website || prefillWebsite,
          answerType: SurveyAnswerTypeEnum.STRING,
          required: true,
        },
      ];
    });
  }

  public setUnifiedBusinessStepQuestions() {
    const { adminName, legalName, website, adminEmail, businessPhoneNumber } =
      this.company;
    const emailDomain = adminEmail.split('@')[1];
    const isCommonMailClient = CommonEmailClients.includes(emailDomain);
    const prefillWebsite = !isCommonMailClient ? emailDomain : '';

    runInAction(() => {
      this.BusinessStepQuestions = [
        {
          id: CompanyDataEnum.ADMIN_FIRST_NAME,
          question: 'First name',
          answer: adminName.first || '',
          answerType: SurveyAnswerTypeEnum.STRING,
          required: true,
        },
        {
          id: CompanyDataEnum.ADMIN_LAST_NAME,
          question: 'Last name',
          answer: adminName.last || '',
          answerType: SurveyAnswerTypeEnum.STRING,
          required: true,
        },
        {
          id: CompanyDataEnum.LEGAL_NAME,
          question: 'Legal business name',
          answer: legalName || '',
          answerType: SurveyAnswerTypeEnum.STRING,
          required: true,
        },
        {
          id: CompanyDataEnum.WEBSITE,
          question: 'Website',
          answer: website || prefillWebsite,
          answerType: SurveyAnswerTypeEnum.STRING,
          required: false,
          placeholder: 'Optional',
        },
        {
          id: CompanyDataEnum.BUSINESS_PHONE_NUMBER,
          question:
            'What is the right phone number to contact about your MainStreet account?',
          answer: businessPhoneNumber || '',
          answerType: SurveyAnswerTypeEnum.PHONE_NUMBER,
          required: false,
          placeholder: 'Optional',
        },
      ];
    });
  }

  public setContactsStepQuestions() {
    const { adminJobTitle, secondaryEmail, businessPhoneNumber } = this.company;

    runInAction(() => {
      this.ContactsStepQuestions = [
        {
          id: CompanyDataEnum.ADMIN_JOB_TITLE,
          question: 'What is your role within the company?',
          answer: adminJobTitle || '',
          answerType: SurveyAnswerTypeEnum.STRING,
          required: true,
        },
        {
          id: CompanyDataEnum.BUSINESS_PHONE_NUMBER,
          question:
            'What is the right phone number to contact about your MainStreet account?',
          answer: businessPhoneNumber || '',
          answerType: SurveyAnswerTypeEnum.PHONE_NUMBER,
          required: true,
        },
        {
          id: CompanyDataEnum.SECONDARY_EMAIL,
          question:
            'What is a secondary email to contact about your MainStreet account?',
          answer: secondaryEmail || '',
          answerType: SurveyAnswerTypeEnum.STRING,
          placeholder: 'Optional',
          required: false,
        },
      ];
    });
  }

  public setTaxesStepQuestions() {
    const { taxType, fiscalYearEndDate } =
      this.rootStore.common.companyStore.company;

    runInAction(() => {
      this.TaxesStepQuestions = [
        {
          id: CompanyDataEnum.TAX_TYPE,
          question: 'What is your entity tax type?',
          answer: taxType || '',
          answerType: SurveyAnswerTypeEnum.STRING,
          required: true,
        },
        {
          id: CompanyDataEnum.FISCAL_YEAR_END_DATE,
          question: 'What month do you end your tax year?',
          answer:
            fiscalYearEndDate && fiscalYearEndDate !== 'N/A'
              ? fiscalYearEndDate
              : 'December',
          answerType: SurveyAnswerTypeEnum.STRING,
          required: true,
        },
      ];
    });
  }

  public setInputChange(
    questionGroup: SurveyQuestionInterface[],
    question: string,
    answer: string | number,
  ) {
    const updateQuestion = questionGroup.find(
      (item) => item.question === question,
    );
    if (updateQuestion) {
      updateQuestion.answer = answer;
    }
  }

  public enableNextButton(questionGroup: SurveyQuestionInterface[]) {
    const allRequiredQuestionsAnswered = questionGroup
      .filter((question) => question.required)
      .some((question) => question.answer === '');

    return allRequiredQuestionsAnswered;
  }

  public setErrorMsg(message: string) {
    runInAction(() => {
      this.errorMsg = message;
    });
  }

  // Generic handling of account updating
  public async updateAccount(
    accountRequest: AccountCreationRequest,
    step: AccountCreationSteps,
    isUnified?: boolean,
  ) {
    runInAction(() => (this.isNextButtonLoading = true));

    // Send off actual update request, and handle the response
    const res = await this.client.UpdateAccountCreation(
      accountRequest,
      step,
      isUnified,
    );
    if (res.errorMsg) {
      datadogLogs.logger.error(
        `[COMPANY_CREATION]: Unable to save '${step}' step during account creation`,
        logContext({ company: this.company }),
      );

      runInAction(() => {
        this.isNextButtonLoading = false;
        this.errorMsg = res.errorMsg as string;
      });
    } else if (res.data) {
      const refreshPromise =
        this.rootStore.common.companyStore.refreshCurrentCompany();

      // Only wait for company refresh on the final step
      if (
        step === AccountCreationSteps.TAXES ||
        step === AccountCreationSteps.BUSINESS_ACTIVITIES
      ) {
        await refreshPromise;
      }

      runInAction(() => {
        this.isNextButtonLoading = false;
        this.errorMsg = '';
      });
    }
  }

  public setProgramStepQuestions() {
    // TODO: Add ProgramTaxYearSelectEnums to Hygraph
    runInAction(() => {
      this.ProgramStepQuestions = [
        {
          text: ProgramTaxYearSelectEnum.DISASTER_OR_EXTENSION_2022,
          taxYear: 2022,
          selected: false,
        },
        {
          text: ProgramTaxYearSelectEnum.EXTENSION_2022,
          taxYear: 2022,
          selected: false,
        },
        {
          text: ProgramTaxYearSelectEnum.FILING_FOR_2023,
          taxYear: 2023,
          selected: false,
        },
      ];
    });
  }

  public updateProgramStepQuestion(id: string) {
    runInAction(() => {
      this.ProgramStepQuestions = this.ProgramStepQuestions.map((item) =>
        item.text === id
          ? { ...item, selected: true }
          : { ...item, selected: false },
      );
    });
  }

  public async handleTaxYearProgramSelect(
    text: ProgramTaxYearSelectEnum,
    taxYear: number,
  ) {
    const program2022 = this.company.programs.find(
      (program) =>
        program.taxYear === 2022 && program.name === ProgramNameEnum.FED_RD_TAX,
    );

    if (!program2022?.id) {
      datadogLogs.logger.error(
        `[COMPANY_CREATION]: No ${taxYear} ${ProgramNameEnum.FED_RD_TAX} found on program tax filing selection.`,
        logContext({ company: this.company }),
      );
      return;
    }

    if (taxYear === 2022) {
      // if 2022: Update program.taxFileExtension to true and save the reason text on program.
      await this.client.UpdateProgramV1(program2022.id, {
        fileTaxExtension: true,
        taxExtensionReason: text,
        stage: program2022.stage,
      });
    } else if (taxYear === 2023) {
      // if 2023: Create 2023 program and update 2022 program to be cancelled
      await this.client.UpdateProgramV1(program2022.id, {
        stage: ProgramStageEnum.CANCELED,
      });

      await this.client.CreateProgramV1({
        name: ProgramNameEnum.FED_RD_TAX,
        taxYear,
      });

      datadogLogs.logger.info(
        `[COMPANY_CREATION]: Created 2023 ${ProgramNameEnum.FED_RD_TAX} program for company ${this.company.id}.`,
        logContext({ company: this.company }),
      );
    }
  }

  public async handleProgramCreation(taxYear: number) {
    runInAction(() => (this.isNextButtonLoading = true));

    const program2022 = this.company.programs.find(
      (program) =>
        program.taxYear === 2022 && program.name === ProgramNameEnum.FED_RD_TAX,
    );

    if (program2022) {
      await this.client.UpdateProgramV1(program2022.id, {
        stage: ProgramStageEnum.CANCELED,
      });

      datadogLogs.logger.error(
        `[UNIFIED_ACCOUNT_CREATION]: Cancel existing 2022 ${ProgramNameEnum.FED_RD_TAX}`,
        logContext({ company: this.company }),
      );
    }

    await this.client.CreateProgramV1({
      name: ProgramNameEnum.FED_RD_TAX,
      taxYear,
    });

    datadogLogs.logger.info(
      `[UNIFIED_ACCOUNT_CREATION]: Created ${taxYear} ${ProgramNameEnum.FED_RD_TAX} program for company ${this.company.id}.`,
      logContext({ company: this.company }),
    );
  }

  public async handleQualificationForProgram(taxYear: number) {
    for (const program of this.company.programs) {
      // DQ 2022 program
      if (
        program.taxYear === taxYear - 1 &&
        program.name === ProgramNameEnum.FED_RD_TAX
      ) {
        await this.client.UpdateProgramV1(program.id, {
          stage: ProgramStageEnum.CANCELED,
        });

        datadogLogs.logger.error(
          `[UNIFIED_ACCOUNT_CREATION]: Cancel existing ${taxYear - 1} ${
            ProgramNameEnum.FED_RD_TAX
          }`,
          logContext({ company: this.company }),
        );
      }

      if (program.taxYear === taxYear) {
        switch (program.name) {
          case ProgramNameEnum.FED_RD_TAX:
            await this.handleRDQualification(taxYear, program.id);
            break;
          // TODO: Consolidate the retirement, disable, and healthcare here qualification here
          //   currently happens in a useEffect on UnitedCreditEstimatesStep.tsx
        }
      }
    }

    // refresh company
    await this.rootStore.common.companyStore.refreshCurrentCompany();
  }

  private async handleRDQualification(taxYear: number, fedProgramId: number) {
    // check 4-part test hygraph question
    const noTo4PartTest = this.hasHygraphAnswerForQuestionId(
      taxYear,
      GraphCmsQuestionIdEnum.DO_ALL_STATEMENTS_APPLY,
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.DO_ALL_STATEMENTS_APPLY
      ].NO,
    );

    const stateProgramIds = this.company.programs
      .filter((p) => p.taxYear === taxYear && StatePrograms.includes(p.name))
      .map((p) => p.id);

    if (noTo4PartTest) {
      await this.updateProgramDQ([fedProgramId, ...stateProgramIds]);

      runInAction(() => {
        this.programCreditEstimates = null;
        this.totalEstimate = 0;
      });

      datadogLogs.logger.error(
        `[UNIFIED_ACCOUNT_CREATION]: [DISQUALIFIED] - ${taxYear} ${ProgramNameEnum.FED_RD_TAX}: ${fedProgramId}`,
        logContext({ company: this.company }),
      );
    } else {
      await this.updateProgramQualified([fedProgramId, ...stateProgramIds]);

      datadogLogs.logger.error(
        `[UNIFIED_ACCOUNT_CREATION]: [QUALIFICATION_SUBMITTED] - ${taxYear} ${ProgramNameEnum.FED_RD_TAX}: ${fedProgramId}`,
        logContext({ company: this.company }),
      );
    }
  }

  private async updateProgramDQ(programIdsToUpdate: number[]) {
    await this.client.UpdatePrograms({
      programIdsToUpdate,
      qualificationStatus: QualificationStatusEnum.DISQUALIFIED,
      stage: ProgramStageEnum.DISQUALIFIED,
      subStage: undefined,
    });
  }

  private async updateProgramQualified(programIdsToUpdate: number[]) {
    await this.client.UpdatePrograms({
      programIdsToUpdate,
      qualificationStatus: QualificationStatusEnum.QUALIFICATION_SUBMITTED,
      stage: ProgramStageEnum.QUALIFYING,
      subStage: undefined,
    });
  }

  public async GetDisplayableCreditEstimates(taxYear: number) {
    const company = this.company;
    const res = await this.client.GetDisplayableCreditEstimates(taxYear);

    if (res.errorMsg || !res.data) {
      datadogLogs.logger.error(
        '[CREDIT_ESTIMATE]: failed to retrieve displayable credit estimate',
        logContext({ company, error: res.errorMsg }),
      );
      return;
    }

    let { programCreditEstimates } = res.data;
    const ercEstimates = await this.client.GetDisplayableErcEstimates();

    if (ercEstimates.errorMsg || !ercEstimates.data) {
      datadogLogs.logger.error(
        '[CREDIT_ESTIMATE]: failed to retrieve displayable ERC estimates',
        logContext({ company, error: res.errorMsg }),
      );
      return;
    }

    const { programCreditEstimates: ercCreditEstimates } = ercEstimates.data;

    if (ercCreditEstimates && ercCreditEstimates.length) {
      programCreditEstimates =
        programCreditEstimates.concat(ercCreditEstimates);
    }

    if (programCreditEstimates && programCreditEstimates.length > 0) {
      const totalCreditEstimate = programCreditEstimates.reduce(
        (result, current) => result + current.high,
        0,
      );

      const reshapeForTable = programCreditEstimates
        .sort((a, b) => b.high - a.high)
        .map((credit) => ({
          name: Programs[credit.name].display,
          estimate: FloatToDollarString(credit.high / 100),
        }));

      runInAction(() => {
        this.programCreditEstimates = reshapeForTable;
        this.totalEstimate = totalCreditEstimate;
      });
    } else {
      datadogLogs.logger.error(
        `[CREDIT_ESTIMATE]: retrieved no displayable credit estimates for ${taxYear} taxYear`,
        logContext({ company, error: res.errorMsg }),
      );
    }
  }

  public setShowEstimates(bool: boolean) {
    runInAction(() => (this.showEstimates = bool));
  }

  public async handleCreateStatePrograms(taxYear: number) {
    const company = this.company;

    if (
      company.qualificationQuestionsByYear &&
      company.qualificationQuestionsByYear[taxYear]
    ) {
      const qualificationByYear = company.qualificationQuestionsByYear[taxYear];
      const statesSelected: string[] =
        qualificationByYear[GraphCmsQuestionIdEnum.MOST_EMPLOYEE_STATES];

      for (const state of statesSelected) {
        switch (state) {
          case GraphCmsQuestionIdToAnswers[
            GraphCmsQuestionIdEnum.MOST_EMPLOYEE_STATES
          ].CALIFORNIA:
            await this.client.CreateProgramV1({
              name: ProgramNameEnum.STATE_RD_CA,
              taxYear,
            });
            break;
          case GraphCmsQuestionIdToAnswers[
            GraphCmsQuestionIdEnum.MOST_EMPLOYEE_STATES
          ].ARIZONA:
            if (this.featureFlags.enableStateAzTaxCredit) {
              await this.client.CreateProgramV1({
                name: ProgramNameEnum.STATE_RD_AZ,
                taxYear,
              });
            }
            break;
          case GraphCmsQuestionIdToAnswers[
            GraphCmsQuestionIdEnum.MOST_EMPLOYEE_STATES
          ].MASSACHUSETTS:
            if (this.featureFlags.enableStateMaTaxCredit) {
              await this.client.CreateProgramV1({
                name: ProgramNameEnum.STATE_RD_MA,
                taxYear,
              });
            }
            break;
          case GraphCmsQuestionIdToAnswers[
            GraphCmsQuestionIdEnum.MOST_EMPLOYEE_STATES
          ].GEORGIA:
            if (this.featureFlags.enableStateGaTaxCredit) {
              await this.client.CreateProgramV1({
                name: ProgramNameEnum.STATE_RD_GA,
                taxYear,
              });
            }
            break;
        }
      }
    }
  }

  public async handleCreateOrderForms(taxYear: number) {
    const company = this.company;
    const res = await this.client.UpdateCreditEstimate({
      programTaxYear: taxYear,
    });

    if (res && res.errorMsg) {
      datadogLogs.logger.error(
        '[CREDIT_ESTIMATE]: Error generating order form',
        logContext({ company, error: res.errorMsg }),
      );
      return;
    }

    // refresh company
    await this.rootStore.common.companyStore.refreshCurrentCompany();
  }

  public hasHygraphAnswerForQuestionId(
    taxYear: number,
    questionId: string,
    answerId: string,
  ) {
    const company = this.company;
    if (
      company.qualificationQuestionsByYear &&
      company.qualificationQuestionsByYear[taxYear][questionId] === answerId
    ) {
      return true;
    }

    return false;
  }

  public dqRetirementEmployeeCount() {
    const company = this.company;

    if (
      company.qualificationQuestionsByYear &&
      company.qualificationQuestionsByYear[ACCOUNT_CREATION_QUALIFYING_TAX_YEAR]
    ) {
      const qualificationByYear =
        company.qualificationQuestionsByYear[
          ACCOUNT_CREATION_QUALIFYING_TAX_YEAR
        ];

      if (
        qualificationByYear[
          GraphCmsQuestionIdEnum.NEXT_YEAR_EXPECTED_EMPLOYEE_COUNT
        ] <= 0 ||
        qualificationByYear[
          GraphCmsQuestionIdEnum.NEXT_YEAR_EXPECTED_EMPLOYEE_COUNT
        ] > 100 ||
        qualificationByYear[
          RetirementPlanCreditQuestions.RETIREMENT_PLAN_OFFERED_FOR_TAX_YEAR
        ] ==
          RetirementPlanCreditQuestionsToAnswers[
            RetirementPlanCreditQuestions.RETIREMENT_PLAN_OFFERED_FOR_TAX_YEAR
          ].NO
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public eligibleForDisabledAccessHygraphQuestion(taxYear: number) {
    const company = this.company;

    if (
      company.qualificationQuestionsByYear &&
      company.qualificationQuestionsByYear[taxYear]
    ) {
      const qualificationByYear = company.qualificationQuestionsByYear[taxYear];

      if (
        qualificationByYear[
          GraphCmsQuestionIdEnum.NEXT_YEAR_EXPECTED_EMPLOYEE_COUNT
        ] <= 30
      ) {
        return true;
      }
    }

    return false;
  }

  public eligibleForErc(taxYear: number): boolean {
    const company = this.company;

    // DQ if they aren't a valid QuickBooks referral
    if (!company.misc?.partnerAccessCode) return false;
    if (!company.yearFounded) return false;

    // DQ companies founded after 2021
    if (company.yearFounded > 2021) return false;

    // DQ 2020 ERC for companies founded in 2020 or 2021
    if (taxYear === 2020) {
      if (company.yearFounded >= 2020) {
        return false;
      }
    }

    // Look at qualification questions for DQ criteria
    if (
      company.qualificationQuestionsByYear &&
      company.qualificationQuestionsByYear[ACCOUNT_CREATION_QUALIFYING_TAX_YEAR]
    ) {
      const qualificationByYear =
        company.qualificationQuestionsByYear[
          ACCOUNT_CREATION_QUALIFYING_TAX_YEAR
        ];

      // DQ if they already claimed ERC
      if (
        qualificationByYear[GraphCmsQuestionIdEnum.ALREADY_CLAIMED_ERC] ===
        GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.ALREADY_CLAIMED_ERC]
          .YES
      ) {
        return false;
      }

      // DQ if they didn't experience a decrease in revenue
      if (
        qualificationByYear[
          GraphCmsQuestionIdEnum.DECREASE_IN_REVENUE_DURING_PANDEMIC
        ] ===
        GraphCmsQuestionIdToAnswers[
          GraphCmsQuestionIdEnum.DECREASE_IN_REVENUE_DURING_PANDEMIC
        ].NO
      ) {
        return false;
      }
    }

    // Otherwise, they can be pre-qualified
    return true;
  }

  public async handleCreationAdditionalPrograms(
    programName: ProgramNameEnum,
    taxYear: number,
  ) {
    const company = this.company;
    const res = await this.client.CreateProgramV1({
      name: programName,
      taxYear,
    });

    if (res && res.errorMsg) {
      datadogLogs.logger.error(
        `[PROGRAM_CREATION]: Error creating programs for: ${company.id} - ${taxYear} ${programName}`,
        logContext({ company, error: res.errorMsg }),
      );
      return;
    }
  }
}
