import React from 'react';
import styles from './spinner.module.scss';

const Spinner = () => {
  return (
    <svg className={styles.spinner} width='30' height='30' viewBox='0 0 50 50'>
      <circle
        className={styles.path}
        cx='25'
        cy='25'
        r='20'
        fill='none'
        strokeWidth='5'
      />
    </svg>
  );
};

export default Spinner;
