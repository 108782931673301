import React, { useState } from 'react';
import {
  Paper,
  Divider,
  Typography,
  Box,
  Theme,
  Grid,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDocumentAccessLink } from 'lib/useDocumentAccessLink';
import { ProgramData } from 'lib/interfaces';
import { CopyToClipboardButton } from 'components/util/CopyToClipboardButton';
import { CopiedCheckmark } from 'components/util/CopiedCheckmark';
import { SUPPORT_EMAIL_ADDRESS } from 'lib/constants';

export interface DocumentAccessLinkProps {
  program: ProgramData;
}

export const DocumentAccessLink = ({ program }: DocumentAccessLinkProps) => {
  const { accessLink, accessLinkError } = useDocumentAccessLink(
    program.companyId,
    program.id,
  );
  const [accessLinkCopied, setAccessLinkCopied] = useState(false);

  return (
    <DocumentAccessLinkDisplay
      onAccessLinkCopied={() => setAccessLinkCopied(true)}
      accessLink={accessLink}
      accessLinkCopied={accessLinkCopied}
      accessLinkError={accessLinkError}
    />
  );
};

const useStyles = makeStyles(({ boxShadowRegular }: Theme) => {
  return {
    paper: {
      boxSizing: 'border-box',
      boxShadow: boxShadowRegular,
    },
    accessLink: {
      background: 'rgba(26, 115, 232, 0.05)',
      color: '#365073',
    },
    accessLinkBorder: {
      borderColor: 'rgba(26, 115, 232, 0.7) !important',
    },
  };
});

interface DisplayProps {
  onAccessLinkCopied: () => void;
  accessLink: string;
  accessLinkError: string;
  accessLinkCopied: boolean;
}

const DocumentAccessLinkDisplay = ({
  onAccessLinkCopied,
  accessLink,
  accessLinkError,
  accessLinkCopied,
}: DisplayProps) => {
  const classes = useStyles();

  return (
    <Paper square className={classes.paper}>
      <Box paddingX={5} paddingY={4}>
        <Typography variant='h2'>
          Send this secure link to your tax preparer and you’re done
        </Typography>
      </Box>
      <Divider />
      <Box paddingX={5} paddingY={4}>
        <Grid container justify={'space-between'}>
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'>
                    Your tax preparer can use this link to download all the
                    documents needed to claim your benefit. Don&apos;t worry,
                    they won&apos;t be able to access anything else in your
                    account
                  </Typography>
                </Grid>
              </Grid>
              {!accessLinkError && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      fullWidth
                      value={accessLink}
                      disabled
                      InputProps={{
                        classes: {
                          input: classes.accessLink,
                          notchedOutline: classes.accessLinkBorder,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} container alignItems='center'>
                    <Grid item>
                      <Box marginRight={3}>
                        <CopyToClipboardButton
                          textToCopy={accessLink}
                          onTextCopied={onAccessLinkCopied}
                        />
                      </Box>
                    </Grid>
                    <Grid item>{accessLinkCopied && <CopiedCheckmark />}</Grid>
                  </Grid>
                </>
              )}
              {accessLinkError && (
                <Grid item xs={12}>
                  <AccessLinkError />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={4} justify={'flex-end'}>
            <img
              src={`${process.env.PUBLIC_URL}/images/tax-preparer.svg`}
              alt='Send to Tax preparer'
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const useLinkStyles = makeStyles(({ palette }: Theme) => ({
  mailTo: {
    color: palette.primary.main,
    '&:visited': {
      color: palette.primary.main,
    },
    '&:link': {
      color: palette.primary.main,
    },
  },
}));

const AccessLinkError = () => {
  const classes = useLinkStyles();

  return (
    <FormHelperText error>
      An error occurred please reach out to{' '}
      <a
        className={classes.mailTo}
        href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        {SUPPORT_EMAIL_ADDRESS}
      </a>{' '}
      if you need a link for your accountant
    </FormHelperText>
  );
};
