import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { FormHelperText, makeStyles } from '@material-ui/core';
import { Page, ProgramNameEnum, ProgramStageEnum } from 'lib/constants';
import LoadingWidget from 'components/util/LoadingWidget';
import { ProgramData } from 'lib/interfaces';
import { ProducingDocumentation } from 'pages/dashboard/fed-rd-program';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';

import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  contentContainer: {
    width: '100%',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 80px',
    maxWidth: '1000px',
  },
}));

interface FedRdPageProps {
  programId: string;
}

export const FedRdPage = observer(({ programId }: FedRdPageProps) => {
  const classes = useStyles();
  const { client } = useContext(Auth0FeatureContext);
  const history = useHistory();

  const [program, setProgram] = useState<ProgramData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [programLoadError, setProgramLoadError] = useState<string>('');

  useEffect(() => {
    const AllowedStages = [
      ProgramStageEnum.EXPENSE_CLASSIFICATION,
      ProgramStageEnum.CLIENT_REVIEW,
      ProgramStageEnum.MS_REVIEW,
      ProgramStageEnum.PRODUCING_DOCS,
    ];
    const shouldRedirect = (program: ProgramData | null) => {
      if (!program) {
        return true;
      }
      if (program.name !== ProgramNameEnum.FED_RD_TAX) {
        return true;
      }

      return AllowedStages.indexOf(program.stage) === -1;
    };

    client.GetProgram(programId).then((res) => {
      if (res.errorMsg) {
        setLoading(false);
        setProgramLoadError(res.errorMsg);
        return;
      }
      setProgramLoadError('');

      const { program } = res.data!;
      setProgram(program);

      // Redirect to dashboard
      const redirecting = shouldRedirect(program);
      setLoading(false);
      if (redirecting) {
        history.push(`/${Page.dashboard}`);
      }
    });
  }, [programId, client, history]);

  const isRdProgram = () => {
    if (loading || programLoadError || !program) {
      return false;
    }
    return program.name === ProgramNameEnum.FED_RD_TAX;
  };

  const renderFedRdPage = (program: ProgramData) => {
    switch (program.stage) {
      case ProgramStageEnum.EXPENSE_CLASSIFICATION: {
        return (
          <Redirect
            to={`/${Page.expenseClassificationOverview}/${programId}`}
          />
        );
      }
      case ProgramStageEnum.CLIENT_REVIEW: {
        return <Redirect to={`/${Page.reviewTaxProcessing}/${programId}`} />;
      }
      case ProgramStageEnum.MS_REVIEW: {
        return (
          <Redirect
            to={`/${Page.expenseClassificationOverview}/${programId}`}
          />
        );
      }
      case ProgramStageEnum.PRODUCING_DOCS: {
        return <ProducingDocumentation />;
      }
      default:
        return <></>;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.mainContent}>
          {loading && <LoadingWidget />}
          {programLoadError && (
            <FormHelperText error>{programLoadError}</FormHelperText>
          )}
          {program && isRdProgram() && <>{renderFedRdPage(program)}</>}
        </div>
      </div>
    </div>
  );
});

export default FedRdPage;
