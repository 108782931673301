import { SUPPORT_EMAIL_ADDRESS } from '../../lib/constants';
import { FormHelperText, Theme } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }: Theme) => ({
  mailTo: {
    color: palette.primary.main,
    '&:visited': {
      color: palette.primary.main,
    },
    '&:link': {
      color: palette.primary.main,
    },
  },
}));

interface Props {
  systemType: string;
}

export const ConnectSystemError = ({ systemType }: Props) => {
  const classes = useStyles();

  return (
    <FormHelperText error>
      An error occurred please reach out to{' '}
      <a
        className={classes.mailTo}
        href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        {SUPPORT_EMAIL_ADDRESS}
      </a>{' '}
      to help get your {systemType} system linked
    </FormHelperText>
  );
};
