import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Color, Heading, Text } from 'component-library';
import {
  JobGroupEnumToEducationTitle,
  JobGroupEnum,
  JobGroupToPercentVersions,
  JobGroupEnumToEducationText,
  JobGroupEnumToString,
  JobGroupVersion,
} from 'lib/constants';
import {
  IndividualExpenseClassificationData,
  RdActivitiesDisplayModeEnum,
} from '../../steps/Employees';
import { JobGroupCard } from './rd-percentage/JobGroupCard';

interface RdActivitiesContainerProps {
  employees: IndividualExpenseClassificationData[];
  setJobGroupsComplete: (val: boolean) => void;
  setPercent: (id: number, newPercentage: number) => void;
  hideTitle?: boolean;
  jobGroupVersion?: JobGroupVersion;
}

const useStyles = makeStyles(() => ({
  bulletPoint: {
    '& > svg': {
      flexBasis: '40px',
      stroke: `${Color.neutral._light_20}`,
      strokeWidth: '1px',
    },
    '& > p': {
      flex: 1,
    },
    '& > svg > path': {
      strokeWidth: '1px',
    },
  },
  rd_list: {
    margin: 0,
    paddingLeft: '16px',
    '& > li': {
      paddingBottom: '8px',
    },
  },
  remainingEmployeesInJobGroupText: {
    textAlign: 'center',
    '&:before, &:after': {
      content: '""',
      borderBottom: '1px solid',
      verticalAlign: 'middle',
      width: '40%',
      display: 'inline-block',
      borderColor: `${Color.neutral._20}`,
    },
    '&:before': {
      margin: '0 .5em 0 -55%',
    },
    '&:after': {
      margin: '0 -55% 0 .5em',
    },
  },
  td: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Color.neutral._90,
  },
  reviseContributionsTitle: {
    paddingTop: 16,
  },
}));

export const IsProgressiveDisclosure = (
  displayMode: RdActivitiesDisplayModeEnum,
): boolean => {
  return displayMode === RdActivitiesDisplayModeEnum.PROGRESSIVE_DISCLOSURE;
};

export const RdActivitiesContainer = ({
  employees,
  setJobGroupsComplete,
  setPercent,
  hideTitle,
  jobGroupVersion = JobGroupVersion.V1,
}: RdActivitiesContainerProps) => {
  const classes = useStyles();

  const jobGroupToPercents = JobGroupToPercentVersions[jobGroupVersion];

  const groupedEmployees = EmployeesByJobGroup(employees);

  // TODO: This throws an error in the console
  setJobGroupsComplete(true);

  const jobGroupToTitle = (jobGroup: string) => {
    return JobGroupEnumToString[jobGroup as JobGroupEnum];
  };

  return (
    <>
      {!hideTitle && (
        <>
          <Heading
            tag='h5'
            color={Color.neutral._90}
            variant='medium'
            className={classes.reviseContributionsTitle}
          >
            Revise your employee&apos;s contributions
          </Heading>
          <Text color={Color.neutral._80}>
            Early-stage technology startups often spend 75-100% of their time on
            R&D activities. Please adjust these values to reflect your
            company&apos;s R&D work.
          </Text>
        </>
      )}
      {Object.keys(groupedEmployees)
        .filter((key) => !!key && key !== 'other')
        .sort(
          (a, b) =>
            jobGroupToPercents[a as JobGroupEnum].percent +
            jobGroupToPercents[b as JobGroupEnum].percent,
        )
        .map((key) => {
          return (
            <JobGroupCard
              className={'job-group-card'}
              title={jobGroupToTitle(key)}
              educationTitle={JobGroupEnumToEducationTitle[key as JobGroupEnum]}
              educationText={
                JobGroupEnumToEducationText[key as JobGroupEnum].summary
              }
              educationBullets={
                JobGroupEnumToEducationText[key as JobGroupEnum].bullets
              }
              employees={groupedEmployees[key]}
              key={key}
              lowPercentage={jobGroupToPercents[key as JobGroupEnum].low}
              highPercentage={jobGroupToPercents[key as JobGroupEnum].high}
              defaultPercentage={
                jobGroupToPercents[key as JobGroupEnum].percent
              }
              setPercent={setPercent}
              dataTestId={key}
            />
          );
        })}
      {Object.keys(groupedEmployees).includes('other') && (
        <JobGroupCard
          className={'job-group-card'}
          title={'Other'}
          educationTitle={JobGroupEnumToEducationTitle[JobGroupEnum.OTHER]}
          educationText={
            JobGroupEnumToEducationText[JobGroupEnum.OTHER].summary
          }
          educationBullets={
            JobGroupEnumToEducationText[JobGroupEnum.OTHER].bullets
          }
          employees={groupedEmployees[JobGroupEnum.OTHER]}
          key={JobGroupEnum.OTHER}
          lowPercentage={jobGroupToPercents[JobGroupEnum.OTHER].low}
          highPercentage={jobGroupToPercents[JobGroupEnum.OTHER].high}
          defaultPercentage={jobGroupToPercents[JobGroupEnum.OTHER].percent}
          setPercent={setPercent}
          defaultShowEmploymentRecords={true}
          dataTestId={JobGroupEnum.OTHER}
        />
      )}
    </>
  );
};

/**
 * Returns a 2d array where each element is an array of employees
 * belonging to the same job group.
 */
export const GroupEmployeesByJobGroup = (
  employees: IndividualExpenseClassificationData[],
): IndividualExpenseClassificationData[][] => {
  const jobGroupToEmployees: {
    [key: string]: IndividualExpenseClassificationData[];
  } = {};
  employees.forEach((employee) => {
    const { jobGroup } = employee;
    if (jobGroup! in jobGroupToEmployees) {
      jobGroupToEmployees[jobGroup!].push(employee);
    } else {
      jobGroupToEmployees[jobGroup!] = [employee];
    }
  });

  return Object.keys(jobGroupToEmployees).map(
    (key) => jobGroupToEmployees[key],
  );
};

interface EmployeesByJobGroup {
  [key: string]: IndividualExpenseClassificationData[];
}

// Duplicates GroupEmployeesByJobGroup (above) but with a different return value.
// TODO: Remove GroupEmployeesByJobGroup when deleting old Employee step code.
export const EmployeesByJobGroup = (
  employees: IndividualExpenseClassificationData[],
): EmployeesByJobGroup => {
  const jobGroupToEmployees: {
    [key: string]: IndividualExpenseClassificationData[];
  } = {};
  employees.forEach((employee) => {
    const { jobGroup } = employee;
    if (jobGroup! in jobGroupToEmployees) {
      jobGroupToEmployees[jobGroup!].push(employee);
    } else {
      jobGroupToEmployees[jobGroup!] = [employee];
    }
  });

  return jobGroupToEmployees;
};
