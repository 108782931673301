import { observer } from 'mobx-react';
import { useCommonStores, useCompany } from 'stores/useStores';
import _ from 'lodash';
import {
  Page,
  ProgramNameEnum,
  Programs,
  QualificationStatusEnum,
} from 'lib/constants';
import { Link, Text } from 'component-library';
import React from 'react';
import { AccountModuleLinkItem, AccountModuleSection } from './AccountModule';

const CreditsSection = observer(() => {
  const { company } = useCompany();
  const { app } = useCommonStores();

  const DATA_TEST_ID_PREFIX = 'dashboard-account-module-credits-section';

  const getLink = () => {
    let href = '';
    let text = '';
    const fedRdProgram = company.programs.find(
      (p) =>
        p.name === ProgramNameEnum.FED_RD_TAX &&
        p.taxYear === app.qualificationTaxYear,
    );
    if (fedRdProgram) {
      if (
        fedRdProgram.qualificationStatus ===
        QualificationStatusEnum.QUALIFICATION_IN_PROGRESS
      ) {
        text = 'Get started';
        href = `/${Page.taxCreditQualificationRoot}/${fedRdProgram.taxYear}`;
      } else if (fedRdProgram.orderForm?.acceptedAt === null) {
        text = 'Accept credits';
        href = `${Page.orderForms}/${fedRdProgram.taxYear}`;
      }
    }

    if (href) {
      return (
        <Link
          text={text}
          size={15}
          variant={'medium'}
          href={href}
          dataTestId={`${DATA_TEST_ID_PREFIX}-credits-link`}
        />
      );
    }
  };

  const emptyState = (
    <div>
      <Text size={15}>Nothing yet</Text>
      {getLink()}
    </div>
  );

  const signedAcceptedOrderForms = company.orderForms.filter(
    (of) => of.acceptedAt && !of.autoAcceptedTermsAndAgreements,
  );
  const orders = signedAcceptedOrderForms.length
    ? _.sortBy(signedAcceptedOrderForms, (orderForm) => orderForm.taxYear).map(
        (orderForm) => (
          <AccountModuleLinkItem
            key={orderForm.id}
            dataTestId={`${DATA_TEST_ID_PREFIX}-order-form-${orderForm.id}`}
            text={`${orderForm.taxYear} ${
              Programs[orderForm.programName].displayShort
            }`}
            href={`/${Page.orderForm}/${orderForm.programId}`}
            external
          />
        ),
      )
    : emptyState;

  return (
    <AccountModuleSection title={'Order Forms'}>{orders}</AccountModuleSection>
  );
});

export default CreditsSection;
