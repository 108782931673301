import * as React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props: any, ref: any) => <RouterLink innerRef={ref as any} {...props} />,
);

AdapterLink.displayName = 'AdapterLink';

export default AdapterLink;
