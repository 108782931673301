import React, { useContext, useEffect, useState } from 'react';
import { Text, Button, Heading } from 'component-library';
import { FormHelperText, makeStyles, Theme } from '@material-ui/core';
import {
  CompanyData,
  QualificationQuestions,
  QuestionProps,
} from 'lib/interfaces';
import { Loading } from 'components/util/Loading';
import {
  InputQuestion,
  MultiSelectQuestion,
  RadioQuestion,
} from 'components/questions';
import {
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
  InputFormatTypeEnum,
  IssuedSurveyStatusEnum,
  Page,
  QuestionTypeEnum,
  SurveyNameEnum,
} from 'lib/constants';
import {
  CurrentLoggedInCompany,
  GetCompanyIssuedSurvey,
  UpdateCompanyIssuedSurvey,
  UpdateCompanyWithSurveyResponse,
} from 'services/server';
import { CompanyContext } from '../../CompanyRequired';
import useGetSurveyQuestionsBySurveyName from 'lib/useGetSurveyQuestionsBySurveyName';
import { ConnectAccounting } from '../integrations/components/accountingIntegration/ConnectAccounting';
import { useLocation } from 'react-router-dom';

const magicMoneyQualSurveyQuestions = [
  GraphCmsQuestionIdEnum.DEPOSIT_INTO_HIGH_YIELD,
  GraphCmsQuestionIdEnum.SAAS_SPEND,
  GraphCmsQuestionIdEnum.NEW_SOFTWARE_TO_BUY_NEXT_YEAR,
  GraphCmsQuestionIdEnum.GREATER_THAN_FIVE_MIL_IN_ASSETS,
  GraphCmsQuestionIdEnum.NAME_OF_ACCOUNTANT,
  GraphCmsQuestionIdEnum.ACCOUNTANT_EMAIL,
];

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 80px',
    maxWidth: '1050px',
  },
  continueButtonWrapper: {
    marginTop: '60px',
    [breakpoints.down('xs')]: {
      padding: '0 18px',
      boxSizing: 'border-box',
    },
  },
  formErrorMsg: {
    marginTop: '15px',
    textAlign: 'center',
    marginLeft: '-180px',
  },
  subtitle: {
    color: '#40525E',
    marginBottom: '32px',
  },
}));

interface MagicMoneyQualificationProps {
  qualificationTaxYear: number;
}

export const MagicMoneyQualification = ({
  qualificationTaxYear,
}: MagicMoneyQualificationProps) => {
  const classes = useStyles();
  const [company, setCompany] = useState<CompanyData>(
    useContext(CompanyContext).company,
  );

  const companyQualificationQuestions = company.qualificationQuestionsByYear
    ? company.qualificationQuestionsByYear[qualificationTaxYear] || {}
    : {};

  const initialFormValues: QualificationQuestions = magicMoneyQualSurveyQuestions
    .map((id) => ({
      [id]: companyQualificationQuestions[id],
    }))
    .reduce((prev, curr) => ({ ...prev, ...curr }));

  const [formValues, setFormValues] = useState<QualificationQuestions>(
    initialFormValues,
  );

  const magicMoneySurvey = company.issuedSurveys!.find(
    (issuedSurvey) =>
      issuedSurvey.name === SurveyNameEnum.MAGIC_MONEY_QUALIFICATION,
  );

  const [questionBank, setQuestionBank] = useState<QuestionProps[]>([]);

  const [formError, setFormError] = useState<string>('');

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const query = new URLSearchParams(useLocation().search);
  const [finishedAccounting, setFinishedAccounting] = useState<boolean>(
    company.linkedAccountingSystem || !!query.get('linked'),
  );

  const [accountingLinked, setAccountingLinked] = useState<boolean>(
    company.linkedAccountingSystem,
  );

  const [accountingName, setAccountingName] = useState<string>('');

  const {
    errorMsg,
    isLoading,
    qualificationQuestions,
  } = useGetSurveyQuestionsBySurveyName(
    SurveyNameEnum.MAGIC_MONEY_QUALIFICATION,
  );

  if (errorMsg) {
    setFormError(errorMsg);
  }

  useEffect(() => {
    if (!magicMoneySurvey) {
      window.location.href = '/';
      return;
    }

    GetCompanyIssuedSurvey(magicMoneySurvey.id).then((res) => {
      if (res.errorMsg || !res.data) {
        setFormError(res.errorMsg ?? 'Something went wrong.');
        return;
      }

      const { issuedSurvey } = res.data;

      if (issuedSurvey.status === 'submitted') {
        window.location.href = '/';
        return;
      }

      UpdateCompanyIssuedSurvey(
        company.id,
        SurveyNameEnum.MAGIC_MONEY_QUALIFICATION,
        IssuedSurveyStatusEnum.IN_PROGRESS,
      );
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateQuestions();
    // Ignore the updateQuestions dependency
    // eslint-disable-next-line
  }, [qualificationQuestions, finishedAccounting]);

  const updateQuestions = () => {
    const highYieldDeposit =
      formValues[GraphCmsQuestionIdEnum.DEPOSIT_INTO_HIGH_YIELD];
    const saasSpend = formValues[GraphCmsQuestionIdEnum.SAAS_SPEND];
    const newSoftwareToBuyNextYear =
      formValues[GraphCmsQuestionIdEnum.NEW_SOFTWARE_TO_BUY_NEXT_YEAR];
    const greaterThanFiveMilInAssets =
      formValues[GraphCmsQuestionIdEnum.GREATER_THAN_FIVE_MIL_IN_ASSETS];

    const accountantName =
      formValues[GraphCmsQuestionIdEnum.NAME_OF_ACCOUNTANT];
    const accountantEmail = formValues[GraphCmsQuestionIdEnum.ACCOUNTANT_EMAIL];

    const initialQuestionBank = [
      {
        id: 0,
        cmsId: GraphCmsQuestionIdEnum.DEPOSIT_INTO_HIGH_YIELD,
        type: 'number',
        valueFormat: InputFormatTypeEnum.CURRENCY,
        text: '',
        label: '$ Deposit',
        nextQuestionId: 1,
        value: highYieldDeposit,
        active: true,
      },
      {
        id: 1,
        cmsId: GraphCmsQuestionIdEnum.SAAS_SPEND,
        type: 'number',
        valueFormat: InputFormatTypeEnum.CURRENCY,
        text: '',
        label: '$ Spend',
        nextQuestionId: 2,
        value: saasSpend,
        active: saasSpend || highYieldDeposit,
      },
      {
        id: 2,
        cmsId: GraphCmsQuestionIdEnum.NEW_SOFTWARE_TO_BUY_NEXT_YEAR,
        type: 'textarea',
        text: '',
        nextQuestionId: 3,
        value: newSoftwareToBuyNextYear,
        active: newSoftwareToBuyNextYear || saasSpend,
      },
      {
        id: 3,
        cmsId: GraphCmsQuestionIdEnum.GREATER_THAN_FIVE_MIL_IN_ASSETS,
        type: 'radio',
        text: '',
        options: [
          {
            label: 'Yes',
            value:
              GraphCmsQuestionIdToAnswers[
                GraphCmsQuestionIdEnum.GREATER_THAN_FIVE_MIL_IN_ASSETS
              ].YES,
            nextQuestionId: 6,
          },
          {
            label: 'No',
            value:
              GraphCmsQuestionIdToAnswers[
                GraphCmsQuestionIdEnum.GREATER_THAN_FIVE_MIL_IN_ASSETS
              ].NO,
            nextQuestionId: 6,
          },
        ],
        value: greaterThanFiveMilInAssets,
        active: greaterThanFiveMilInAssets || newSoftwareToBuyNextYear,
      },
      {
        id: 6,
        cmsId: GraphCmsQuestionIdEnum.NAME_OF_ACCOUNTANT,
        type: 'input-text',
        text: '',
        label: 'Accountant name',
        value: accountantName,
        subtitle:
          'If you’ll be preparing your taxes yourself, leave this blank.',
        active: accountantName || greaterThanFiveMilInAssets,
        nextQuestionId: 7,
      },
      {
        id: 7,
        cmsId: GraphCmsQuestionIdEnum.ACCOUNTANT_EMAIL,
        type: 'input-text',
        text: '',
        label: 'Accountant email',
        value: accountantEmail,
        subtitle:
          "We'll need to send your CPA your R&D credit paperwork to be included in your corporate tax return. If you'd prefer to send it to them personally, feel free to leave this blank. We'll let you know before we reach out to them.",
        active: accountantEmail || greaterThanFiveMilInAssets,
      },
    ];

    if (Object.keys(qualificationQuestions).length > 0) {
      const enrichedQuestionBank = initialQuestionBank.map((question) => {
        const cmsQuestion = qualificationQuestions[question.cmsId];
        const cmsQuestionAnswers = cmsQuestion && cmsQuestion.answerIDs;
        const cmsQuestionSubtitle = cmsQuestion && cmsQuestion.subtitle;

        if (cmsQuestion && cmsQuestionAnswers) {
          question.text = String(cmsQuestion?.text);
        }

        if (cmsQuestionSubtitle) {
          question.subtitle = String(cmsQuestionSubtitle);
        }

        return question;
      });
      setQuestionBank(enrichedQuestionBank || []);
    }
  };

  const getNextQuestion = (
    nextQuestionId: number | undefined,
    questionBank: QuestionProps[],
  ) => {
    const nextQuestion = questionBank.find(
      (question) => question.id === nextQuestionId,
    );
    return nextQuestion;
  };

  const renderNextQuestion = (nextQuestionId: number | undefined) => {
    updateQuestions();
    const nextQuestion = getNextQuestion(nextQuestionId, questionBank);

    if (nextQuestion) {
      nextQuestion.active = true;

      if (
        (typeof nextQuestion.value === 'string' && !!nextQuestion.value) ||
        (Array.isArray(nextQuestion.value) && nextQuestion.value.length > 1)
      ) {
        renderNextQuestion(nextQuestion.nextQuestionId);
      }
    }

    setQuestionBank([...questionBank]);
  };

  const handleRadioQuestionChange = (event: any, question: QuestionProps) => {
    const value = event.target.value;
    setFormValues({ ...formValues, [question.cmsId!]: value });
    const option = question.options?.find((option) => option.value === value);

    question.callback && question.callback(value);
    question.value = value;

    const nextQuestionId = option?.nextQuestionId;
    renderNextQuestion(nextQuestionId);
  };

  const handleInputQuestionChange = (event: any, question: QuestionProps) => {
    const value = event.target.value;
    setFormValues({ ...formValues, [question.cmsId!]: value });
    question.callback && question.callback(value);
    question.value = value;

    const nextQuestionId = question.nextQuestionId;
    renderNextQuestion(nextQuestionId);
  };

  const handleMultiSelectChipsChange = (
    newValue: any,
    question: QuestionProps,
  ) => {
    const value = newValue;

    if (newValue && newValue.length <= 3) {
      question.callback && question.callback(value);
      question.value = value;
    }

    const nextQuestionId = question.nextQuestionId;
    renderNextQuestion(nextQuestionId);
  };

  const renderQuestion = (question: QuestionProps) => {
    if (!question.active) {
      return;
    }

    if (question.type === 'radio') {
      return (
        <RadioQuestion
          key={`question-${question.text}`}
          question={question}
          withCard
          onChange={(event) => {
            handleRadioQuestionChange(event, question);
          }}
        />
      );
    }

    if (question.type === 'number') {
      return (
        <InputQuestion
          key={`question-${question.cmsId}`}
          question={question}
          format={question.valueFormat}
          type={QuestionTypeEnum.NUMBER}
          withCard
          onChange={(event) => {
            handleInputQuestionChange(event, question);
          }}
        />
      );
    }

    if (question.type === 'textarea') {
      return (
        <InputQuestion
          key={`question-${question.cmsId}`}
          question={question}
          type={QuestionTypeEnum.TEXTAREA}
          withCard
          onChange={(event) => {
            handleInputQuestionChange(event, question);
          }}
        />
      );
    }

    if (question.type === 'input-text') {
      return (
        <InputQuestion
          key={`question-${question.cmsId}`}
          question={question}
          type={QuestionTypeEnum.TEXT}
          withCard
          onChange={(event) => {
            handleInputQuestionChange(event, question);
          }}
        />
      );
    }

    if (question.type === 'multi-select-chips') {
      return (
        <MultiSelectQuestion
          key={`question-${question.text}`}
          question={question}
          type={QuestionTypeEnum.CHIP}
          onChange={(newValue) => {
            handleMultiSelectChipsChange(newValue, question);
          }}
        />
      );
    }
  };

  const submit = () => {
    setButtonLoading(true);

    const req = {
      taxYear: qualificationTaxYear,
      qualificationQuestions: formValues,
    };

    UpdateCompanyWithSurveyResponse(
      company.id,
      SurveyNameEnum.MAGIC_MONEY_QUALIFICATION,
      req,
    ).then((res) => {
      setButtonLoading(false);

      if (res.errorMsg) {
        setFormError('Something went wrong');
        return;
      }

      if (!res.data || !res.data.updatedCompany) {
        setFormError('Something went wrong');
        return;
      }

      UpdateCompanyIssuedSurvey(
        company.id,
        SurveyNameEnum.MAGIC_MONEY_QUALIFICATION,
        IssuedSurveyStatusEnum.SUBMITTED,
      );

      setCompany(res.data.updatedCompany);

      window.location.href = `/${Page.magicMoneySelection}`;
    });
  };

  const showContinueButton: boolean =
    finishedAccounting &&
    magicMoneyQualSurveyQuestions
      .filter(
        (questionId) =>
          ![
            GraphCmsQuestionIdEnum.NAME_OF_ACCOUNTANT,
            GraphCmsQuestionIdEnum.ACCOUNTANT_EMAIL,
          ].includes(questionId),
      )
      .every((questionId) => formValues[questionId] !== undefined);

  const refreshCompany = async () => {
    return CurrentLoggedInCompany().then((company) => {
      if (company) {
        setCompany(company);
        setAccountingLinked(company.linkedAccountingSystem);
        setAccountingName(company.misc.connectedAccountingSystems);
      }
    });
  };

  return (
    <div className={classes.root}>
      <Heading
        tag='h1'
        text='No more leaving cash on the table 💫'
        variant='regular'
      />
      <Text className={classes.subtitle}>
        You&apos;re minutes away from potentially doubling your MainStreet
        savings.
      </Text>

      <Loading loading={isLoading}>
        <ConnectAccounting
          onAccountingConnected={refreshCompany}
          returnPage={'magic-qualification'}
          doThisLater={() => {
            setFinishedAccounting(true);
          }}
          accountingLinked={accountingLinked}
          accountingSystemName={accountingName}
        />
        {finishedAccounting && questionBank.map(renderQuestion)}

        {showContinueButton && (
          <div className={classes.continueButtonWrapper}>
            <Button label='Continue' loading={buttonLoading} onClick={submit} />
          </div>
        )}
      </Loading>

      {formError && (
        <FormHelperText className={classes.formErrorMsg} error>
          {formError}
        </FormHelperText>
      )}
    </div>
  );
};

export default MagicMoneyQualification;
