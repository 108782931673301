import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Alert,
  Button,
  Divider,
  Flex,
  Message,
  Modal,
  Text,
} from 'component-library';
import { useCompany, useLegacyClients } from 'stores/useStores';
import {
  ProgramNameEnum,
  ProgramStageEnum,
  QualificationStatusEnum,
} from 'lib/constants';
import { ProgramData, ProgramName } from 'lib/interfaces';
import { OptOutCard } from './OptOutCard';
import { isEqual } from 'lodash';
import { useEffectOnce } from 'lib/helpers';

const useStyles = makeStyles(() => ({
  divider: {
    marginBottom: 0,
  },
}));

interface OptOutModalProps {
  showModal: boolean;
  taxYear: number;
  closeModal: () => void;
  triggerServices: () => void;
  setToast: (toast: Message[]) => void;
}

type ProgramOptInState = Record<number, boolean>;

const programOrder: Partial<Record<ProgramName, number>> = {
  [ProgramNameEnum.FED_RD_TAX]: 1,
  [ProgramNameEnum.STATE_RD_AZ]: 2,
  [ProgramNameEnum.STATE_RD_CA]: 3,
  [ProgramNameEnum.STATE_RD_GA]: 4,
  [ProgramNameEnum.STATE_RD_MA]: 5,
  [ProgramNameEnum.FED_RETIREMENT_CREDIT]: 6,
  [ProgramNameEnum.FED_EMPLOYER_HEALTHCARE]: 7,
  [ProgramNameEnum.FED_DISABLED_ACCESS]: 8,
};

export const OptOutModal = ({
  showModal,
  taxYear,
  closeModal,
  triggerServices,
  setToast,
}: OptOutModalProps) => {
  const classes = useStyles();
  const { company } = useCompany();
  const { client } = useLegacyClients();

  const [qualifiedPrograms, setQualifiedPrograms] = useState<ProgramData[]>([]);
  const [pristineProgramOptIns, setPristineProgramOptIns] =
    useState<ProgramOptInState>({});
  const [programOptIns, setProgramOptIns] = useState<ProgramOptInState>({});
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [dirtyModalIsOpen, setDirtyModalIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitDidError, setSubmitDidError] = useState(false);

  useEffectOnce(() => {
    const initialProgramOptIns: ProgramOptInState = {};
    const qualifiedPrograms = company.programs
      .filter((program) => {
        return (
          program.taxYear === taxYear &&
          program.qualificationStatus === QualificationStatusEnum.QUALIFIED
        );
      })
      .sort((a, b) => {
        const programA = programOrder[a.name] || 99;
        const programB = programOrder[b.name] || 99;
        return programA - programB;
      });
    qualifiedPrograms.forEach((program) => {
      initialProgramOptIns[program.id] = !(
        program.stage === ProgramStageEnum.CANCELED
      );
    });

    setQualifiedPrograms(qualifiedPrograms);
    setPristineProgramOptIns(initialProgramOptIns);
    setProgramOptIns(initialProgramOptIns);
  });

  const handleClose = () => {
    if (isDirty) {
      setDirtyModalIsOpen(true);
    } else {
      closeModal();
    }
  };

  const handleConfirm = () => {
    setConfirmationModalIsOpen(true);
  };

  const handleDirtyModalConfirmExit = () => {
    setDirtyModalIsOpen(false);
    closeModal();
    setProgramOptIns(pristineProgramOptIns);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const changedPrograms = Object.keys(programOptIns)
      .map((key) => parseInt(key, 10))
      .filter(
        (key) => !isEqual(pristineProgramOptIns[key], programOptIns[key]),
      );

    try {
      const changedProgramRequests = changedPrograms.map((programId) => {
        const programStage = programOptIns[programId]
          ? ProgramStageEnum.EXPENSE_CLASSIFICATION
          : ProgramStageEnum.CANCELED;

        return client.UpdateProgramStage({
          programId,
          programStage: programStage,
        });
      });

      await Promise.all(changedProgramRequests);

      setIsSubmitting(false); // Resolve Submitting State
      setPristineProgramOptIns(programOptIns); // Update Pristine to current Program Opt In States
      triggerServices(); // Trigger a reset of the program data for the Payment Page
      setConfirmationModalIsOpen(false); // Close the confirmation modal
      closeModal(); // Close the opt out modal
      setToast([
        {
          text: 'Opt out selections updated successfully',
          status: 'success',
        },
      ]);
    } catch (error) {
      // Failed program requests are not being captured properly.
      setIsSubmitting(false);
      setSubmitDidError(true);
    }
  };

  const handleToggleOptIn = (programId: number) => {
    setProgramOptIns((prev) => ({
      ...prev,
      [programId]: !prev[programId],
    }));
  };

  const getConfirmationContent = () => {
    const isAllOptIn = qualifiedPrograms.every((program) => {
      return programOptIns[program.id] === true;
    });

    if (isAllOptIn) {
      return (
        <>
          <Text size={15} variant='bold'>
            Congrats on the savings!
          </Text>
          <br />
          <Text size={13}>
            You will receive all the tax credits you have selected, so you are
            saving big this tax season.
          </Text>
        </>
      );
    }

    return (
      <>
        <Text size={15} variant='bold'>
          Are you sure?
        </Text>
        <br />
        <Text size={13}>
          By opting out, you will miss out on a reduction or refund on your
          taxes. Tax credits are essentially a way to keep more money in your
          business by lowering your current or future tax bills. Do you still
          want to skip this opportunity to save?
        </Text>
      </>
    );
  };

  const programOptOutCards = qualifiedPrograms.map((program, index) => (
    <Fragment key={program.id}>
      {index > 0 && <Divider className={classes.divider} />}
      <OptOutCard
        key={program.id}
        program={program}
        isOptedIn={programOptIns[program.id]}
        toggleOptIn={handleToggleOptIn}
      />
    </Fragment>
  ));

  const isDirty = !isEqual(pristineProgramOptIns, programOptIns);

  return (
    <Modal
      showModal={showModal}
      closeToggle={() => handleClose()}
      maxWidth={560}
      rounded
    >
      <>
        <Flex direction='column' padding={24}>
          <Flex direction='column' padding={[0, 0, 12, 0]}>
            <Text size={18} variant='bold'>
              Tax credit opt out
            </Text>
            <Text size={13}>
              To opt out, toggle the icon. You can simply toggle the icon again
              to opt back in.
              <br />
              You will not receive forms, nor will you be charged for credits
              you opt out of.
            </Text>
          </Flex>
          <>{programOptOutCards}</>
          <Flex
            direction='row'
            padding={[0, 0, 0, 0]}
            justifyContent='space-between'
          >
            <Button label='Cancel' onClick={handleClose} variant='outlined' />
            <Button
              disabled={isDirty === false}
              label='Confirm selections'
              onClick={handleConfirm}
            />
          </Flex>
        </Flex>
        <Modal // Confirmation Modal
          showModal={confirmationModalIsOpen}
          closeToggle={() => setConfirmationModalIsOpen(false)}
          maxWidth={560}
          rounded
        >
          <Flex direction='column' padding={24}>
            <Flex direction='column' padding={[0, 0, 12, 0]}>
              {getConfirmationContent()}
            </Flex>

            {submitDidError && (
              <Alert
                inCardBorder='left'
                text={
                  <>
                    <Text>
                      There was a problem submitting your changes. Please try
                      again.
                    </Text>
                  </>
                }
                type='caution'
                variant='in_card'
              />
            )}
            <Flex direction='row' justifyContent='space-between'>
              <Button
                label='Dismiss'
                onClick={() => setConfirmationModalIsOpen(false)}
                variant='outlined'
              />
              <Button
                label={isSubmitting ? 'Submitting...' : 'Confirm'}
                disabled={isSubmitting}
                onClick={handleSubmit}
              />
            </Flex>
          </Flex>
        </Modal>
        <Modal // Dirty Modal
          showModal={dirtyModalIsOpen}
          closeToggle={() => setDirtyModalIsOpen(false)}
          maxWidth={560}
          rounded
        >
          <Flex direction='column' padding={24}>
            <Flex direction='column' padding={[0, 0, 12, 0]}>
              <Text size={15} variant='bold'>
                Do you want to adjust your selections?
              </Text>
              <br />
              <Text size={13}>
                If you would like to adjust your tax credit selections, your
                choices will only be saved upon clicking the &apos;Confirm
                selections&apos; button.
              </Text>
            </Flex>
            <Flex direction='row' justifyContent='space-between'>
              <Button
                label='Keep editing'
                onClick={() => setDirtyModalIsOpen(false)}
                variant='outlined'
              />
              <Button
                label='Yes, exit'
                onClick={() => handleDirtyModalConfirmExit()}
              />
            </Flex>
          </Flex>
        </Modal>
      </>
    </Modal>
  );
};
