import React, { useState, useContext } from 'react';
import { CompanyContext } from 'pages/CompanyRequired';
import { makeStyles } from '@material-ui/core';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import SendInvitationDrawer from './components/SendInvitationDrawer';
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Content,
  Link,
  Spinner,
  Table,
  Text,
  AlertModal,
  Flex,
} from 'component-library';
import { observer } from 'mobx-react';
import {
  useCommonStores,
  useFeatureFlags,
  useHistoryStore,
} from 'stores/useStores';
import { datadogLogs } from '@datadog/browser-logs';
import { useEffectOnce, useIsMounted } from 'lib/helpers';
import { ChatBotSettings } from 'components/chatbot/ChatBotSettings';
import TalkToAnExpertButton from 'components/util/TalkToAnExpertButton';

const useStyles = makeStyles(() => ({
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    boxSizing: 'border-box',
    maxWidth: '1024px',
  },
  infoCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    flexBasis: 'calc(50% - 16px)',
    marginBottom: '24px',
    '& > p:first-child': {
      textTransform: 'capitalize',
    },
  },
  alertCard: {
    marginTop: '24px',
  },
}));

const NewSettingsPage = observer(() => {
  const classes = useStyles();
  const { auth } = useCommonStores();
  const { company } = useContext(CompanyContext);
  const [isChangePasswordSuccessful, setIsChangePasswordSuccessful] =
    useState<boolean>(false);

  const history = useHistoryStore();
  const hasInviteQueryParam = !!history.query.invite;

  const [showInviteDrawer, setShowInviteDrawer] =
    useState<boolean>(hasInviteQueryParam);
  const [isInvitationError, setIsInvitationError] = useState<boolean>(false);
  const [isInvitationSuccessful, setIsInvitationSuccessful] =
    useState<boolean>(false);
  const [inviteeName, setInviteeName] = useState<string>('');
  const [loadingAdmins, setLoadingAdmins] = useState<boolean>(false);
  const [loadOrgError, setLoadOrgError] = useState<string>('');

  const { client } = useContext(Auth0FeatureContext);
  const isMounted = useIsMounted();

  useEffectOnce(async () => {
    setLoadingAdmins(true);
    await auth.fetchOrgMembers();
    setLoadingAdmins(false);
    if (auth.fetchOrgMembersError) {
      setLoadOrgError('Retrieving account administrators failed.');
    }
  });

  const featureFlags = useFeatureFlags();

  const personalDetails = {
    name:
      auth.user?.displayName ||
      `${company.adminName.first} ${company.adminName.last}`,
    email: auth.user?.email || company?.adminEmail,
    company: company?.name,
    role: 'Admin',
  };

  const handleSendChangePasswordEmail = async () => {
    try {
      await auth.changePassword();

      // TODO: set up error handling that shows an error alert
      if (isMounted()) {
        setIsChangePasswordSuccessful(true);
      }
    } catch (e: any) {
      datadogLogs.logger.error(`Failed to change password`, e);
    }
  };

  // Setting up table schema for the <Table> component
  const adminTableColumns = [
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
    },
    {
      Header: 'Email',
      accessor: 'email',
      width: 200,
    },
    {
      Header: 'Role',
      accessor: 'role',
    },
  ];

  const InfoCard = (label: string, value: string, i: number) => {
    const classes = useStyles();

    return (
      <div className={classes.infoCard} key={i}>
        <Text text={label} size={15} />
        <Text text={value} variant='medium' />
      </div>
    );
  };

  const InviteSuccessfulAlert = () => {
    return (
      <Alert
        className={classes.alertCard}
        actions={{
          onClick: () => {
            setIsInvitationSuccessful(false);
          },
          text: 'Dismiss',
        }}
        text={`Invite successful! ${inviteeName} will receive an email with your invitation shortly.`}
        type='success'
        variant='primary'
      />
    );
  };

  const ChangePasswordSuccessAlert = () => {
    return (
      <Alert
        dataTestId='change-password-success-alert'
        className={classes.alertCard}
        actions={{
          onClick: () => {
            if (isMounted()) {
              setIsChangePasswordSuccessful(false);
            }
          },
          text: 'Dismiss',
        }}
        text={`An email with a link to change your password has been sent.`}
        type='success'
        variant='primary'
      />
    );
  };

  return (
    <div className={classes.mainContent}>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        gap={24}
        padding={[0, 0, 24, 0]}
      >
        <Text size={40} variant='medium'>
          Settings
        </Text>
        {featureFlags.showTalkToAnExpertButton && <TalkToAnExpertButton />}
      </Flex>
      <Text>
        If you need to make any changes to this information, please contact us
        at{' '}
        <Link external href='mailto:support@mainstreet.com' variant='medium'>
          support@mainstreet.com
        </Link>
      </Text>
      {isInvitationSuccessful && <InviteSuccessfulAlert />}
      {isChangePasswordSuccessful && <ChangePasswordSuccessAlert />}
      <Card>
        <CardHeader title='Personal details' noBgColor />
        <Content
          flex
          flexWrap='wrap'
          paddingLeftRight={24}
          paddingTop={24}
          paddingBottom={8}
        >
          {Object.entries(personalDetails).map((item, i) =>
            InfoCard(item[0], item[1], i),
          )}
        </Content>
        <CardFooter
          primaryCtaLabel='Change password'
          primaryOnClick={() => handleSendChangePasswordEmail()}
          variant='tertiary'
          primaryTestId='change-password-button'
          noBgColor
        />
      </Card>
      <Card>
        <CardHeader title='Account administrators' noBgColor />
        {loadingAdmins ? (
          <Content flex justifyContent='center' paddingTopBottom={32}>
            <Spinner size='default' color='emerald' />
          </Content>
        ) : loadOrgError ? (
          <Content flex justifyContent='center' paddingTopBottom={32}>
            <Text textAlign='center' status='failed'>
              loadOrgError
            </Text>
          </Content>
        ) : auth.members && auth.members.length > 0 ? (
          <>
            <Table
              hideTableHeader
              data={auth.members}
              columns={adminTableColumns}
            />
            {featureFlags.enableAuth0Invites && (
              <CardFooter
                variant='tertiary'
                primaryCtaLabel='Invite'
                primaryOnClick={() => setShowInviteDrawer(true)}
                primaryTestId='footer-invite-button'
                noBgColor
              />
            )}
          </>
        ) : (
          <Content flex paddingTopBottom={32} flexDirection='column'>
            {featureFlags.enableAuth0Invites && (
              <>
                <Text textAlign='center' variant='medium'>
                  Invite people to help you manage your MainStreet account{' '}
                  <br />
                  <Text textAlign='center' tag='span'>
                    All the people you invite will have access to the same
                    information as you
                  </Text>
                </Text>
                <Button
                  label='Invite'
                  variant='outlined'
                  data-testid='invite-button'
                  flexAlignSelf='center'
                  onClick={() => setShowInviteDrawer(true)}
                />
              </>
            )}
          </Content>
        )}
        {featureFlags.enableAuth0Invites && (
          <SendInvitationDrawer
            client={client}
            openSidebar={showInviteDrawer}
            setOpenSidebar={(v) => isMounted() && setShowInviteDrawer(v)}
            company={company}
            setIsInvitationSuccessful={(v) =>
              isMounted() && setIsInvitationSuccessful(v)
            }
            setIsInvitationError={(v) => isMounted() && setIsInvitationError(v)}
            setInviteeName={(v) => isMounted() && setInviteeName(v)}
          />
        )}
      </Card>
      <AlertModal
        dataTestId='user-has-org-alert'
        showModal={isInvitationError}
        title='Cannot Invite This Member'
        maxWidth={450}
        message={
          <Text>
            This member cannot be invited at this time. Please contact{' '}
            <Link
              text='support@mainstreet.com'
              href='mailto:support@mainstreet.com'
            />{' '}
            if you need further help.
          </Text>
        }
        closeToggle={() => setIsInvitationError(false)}
      />
      <ChatBotSettings />
    </div>
  );
});

export default NewSettingsPage;
