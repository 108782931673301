import { IsEmailValid, SnakeToSentenceCase } from 'lib/helpers';
import {
  BusinessApplicationData,
  CountryEnum,
  BusinessApplicationDataDraft,
  PersonApplicationDataDraft,
  PersonApplicationData,
  SurveyResponsesType,
} from 'lib/interfaces';
import { KYCQuestionToAnswers, KycQuestionGraphCMSIdEnum } from 'lib/constants';
import { GetAddressErrors } from './validations';

export const GetCountryNameUsingAbbreviation = (
  abbreviation: CountryEnum,
): string | undefined => {
  const countryName = Object.keys(CountryEnum).find((countrykey) => {
    return CountryEnum[countrykey as keyof typeof CountryEnum] === abbreviation;
  });
  if (countryName) {
    return SnakeToSentenceCase(countryName);
  }
};

export const IsValidEinNumber = (tin?: string) => {
  const einWithoutSeparators = tin
    ?.split('')
    .filter((char) => !['-', '_'].includes(char))
    .join('');

  return einWithoutSeparators?.length === 9 && Number(einWithoutSeparators);
};

export function BusinessApplicationDataIsComplete(
  companyInfo?: BusinessApplicationDataDraft,
  accreditationConnected?: boolean,
): companyInfo is BusinessApplicationData {
  return Boolean(
    accreditationConnected &&
      companyInfo?.name &&
      companyInfo?.phoneNumber &&
      !companyInfo?.phoneNumber?.includes('_') &&
      companyInfo?.physicalAddress &&
      IsValidEinNumber(companyInfo?.tin) &&
      !GetAddressErrors(companyInfo?.physicalAddress),
  );
}

export function PersonApplicationDataIsComplete(
  personInfo?: PersonApplicationDataDraft,
): personInfo is PersonApplicationData {
  return Boolean(
    personInfo?.dateOfBirth &&
    IsEmailValid(personInfo?.emailAddress || '') &&
    personInfo?.firstName &&
    personInfo?.lastName &&
    personInfo?.physicalAddress &&
    personInfo?.ownershipPercentage !== undefined && // Check explicitly for undefined since 0 should be truthy
      personInfo?.phoneNumber &&
      !personInfo?.phoneNumber?.includes('_') &&
      personInfo?.tin &&
      !GetAddressErrors(personInfo?.physicalAddress),
  );
}

const OTHERS_WHO_OWN_MORE_THAN_25_PERCENT_YES_RESPONSE =
  KYCQuestionToAnswers[
    KycQuestionGraphCMSIdEnum.OTHERS_WHO_OWN_MORE_THAN_25_PERCENT
  ].YES;

export function HasAdditionalOwners(
  surveyResponse: SurveyResponsesType,
): boolean {
  const answerOrAnswers =
    surveyResponse[
      KycQuestionGraphCMSIdEnum.OTHERS_WHO_OWN_MORE_THAN_25_PERCENT
    ];

  if (!answerOrAnswers) {
    return false;
  }

  if (Array.isArray(answerOrAnswers)) {
    return answerOrAnswers.some(
      (answer) =>
        answer.id === OTHERS_WHO_OWN_MORE_THAN_25_PERCENT_YES_RESPONSE,
    );
  }

  return (
    answerOrAnswers.id === OTHERS_WHO_OWN_MORE_THAN_25_PERCENT_YES_RESPONSE
  );
}

export const IsProd = () => {
  return process.env.REACT_APP_CONNECTION_NAME?.includes('prod');
};
