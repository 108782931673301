import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Animate,
  Button,
  Color,
  Flex,
  Heading,
  Text,
  TextField,
} from 'component-library';
import { useCommonStores, useOnboardingStores } from 'stores/useStores';

const useStyles = makeStyles(({ breakpoints }) => ({
  grid: {
    display: 'grid',
    height: 'calc(100% - 64px)',
    width: '100%',
    maxWidth: '1440px',
    margin: '0 auto',
    gridTemplateColumns: `50% 50%`,
    boxSizing: 'border-box',
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      gap: '24px',
      padding: '24px 0',
      justifyContent: 'flex-end',
      gridTemplateColumns: 'minmax(0, 1fr)',
    },
  },
  mailerCode: {
    width: '100%',

    '& > img': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  rightCol: {
    backgroundColor: 'white',
  },
  goBtn: {
    height: '56px',
    padding: '24px',
  },
}));

interface PartnerAccessCodeEntryProps {
  queryParams: URLSearchParams;
  partner: string;
  partnerCompany: string;
}

export const PartnerAccessCodeEntry: React.FC<PartnerAccessCodeEntryProps> =
  observer(({ queryParams, partner, partnerCompany }) => {
    const classes = useStyles();
    const { app } = useCommonStores();
    const { initialAccountSetup } = useOnboardingStores();

    const handleOnCodeSubmit = async (code: string) => {
      queryParams.set('code', code);
      await initialAccountSetup.getAccessCodeEstimate(queryParams, partner);
    };

    const submitOnEnter =
      (runSubmit: () => void) => (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          runSubmit();
        }
      };

    return (
      <div className={classes.grid}>
        <Flex
          padding={[0, 24]}
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Animate enter='fade-in' delay={0.25}>
            <div className={classes.mailerCode}>
              <img
                src={`${process.env.PUBLIC_URL}/images/mst-mailer-code.png`}
                alt={`MainStreet & ${partner} mailer code'`}
              />
            </div>
          </Animate>
        </Flex>

        <Flex
          className={classes.rightCol}
          direction='column'
          justifyContent='center'
          padding={app.isTablet ? [0, 24] : [0, 24, 0, 80]}
        >
          <Animate enter={['fade-in', 'from-top']}>
            <Flex direction='column' gap={24}>
              <Heading
                tag={app.isSmallDesktop ? 'h4' : 'h3'}
                variant='medium'
                marginBottom={0}
              >
                Welcome to MainStreet, {partnerCompany} customer!
              </Heading>
              <Text size={app.isSmallDesktop ? 15 : 18}>
                Please enter the {partnerCompany} access code you received in
                the&nbsp;mail below.
              </Text>
              <Flex gap={32}>
                <Flex.Cell basis={200}>
                  <TextField
                    value={initialAccountSetup.enterCode}
                    name='tax-credit-code'
                    data-testid='tax-credit-code-field'
                    placeholder='Enter access code'
                    onChange={(str) => {
                      initialAccountSetup.setEnterCode(str.target.value);
                      if (initialAccountSetup.codeNotFound) {
                        initialAccountSetup.codeNotFound = false;
                      }
                    }}
                    error={initialAccountSetup.codeNotFound}
                    helperText={
                      initialAccountSetup.codeNotFound && (
                        <Flex padding={[8, 0, 0, 0]}>
                          <Text color={Color.semantic.$error50}>
                            We&apos;re sorry, this code is invalid.
                            Please&nbsp;try&nbsp;again.
                          </Text>
                        </Flex>
                      )
                    }
                    onKeyDown={submitOnEnter(async () => {
                      initialAccountSetup.setTaxCreditCode(
                        initialAccountSetup.enterCode,
                      );

                      await handleOnCodeSubmit(initialAccountSetup.enterCode);
                    })}
                  />
                </Flex.Cell>
                <Button
                  className={classes.goBtn}
                  label='Go'
                  onClick={async () => {
                    initialAccountSetup.setTaxCreditCode(
                      initialAccountSetup.enterCode,
                    );

                    await handleOnCodeSubmit(initialAccountSetup.enterCode);
                  }}
                />
              </Flex>
            </Flex>
          </Animate>
        </Flex>
      </div>
    );
  });
