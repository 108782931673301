import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Color, Flex, Heading, Image, Text } from 'component-library';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  deadlinesContainer: {
    maxWidth: '1440px',
    margin: '0 auto',
    backgroundColor: Color.blue._10,
    width: 'calc(100% - 48px)',
    borderRadius: '48px',
    padding: 24,
    position: 'relative',
    border: '24px solid #FFFFFF',
  },
  textContainer: {
    maxWidth: '1024px',
    margin: '0 auto',
    alignItems: 'center',
  },
  grayText: {
    color: Color.neutral._60,
  },
  textWithIncreasedLineSpacing: {
    lineHeight: '3',
  },
}));

export const DeadlinesSection: React.FC = observer(() => {
  const classes = useStyles();
  const { app } = useCommonStores();

  return (
    <Flex
      direction={app.isTablet ? 'column' : 'row'}
      className={classes.deadlinesContainer}
      gap={app.isTablet ? 24 : 40}
    >
      <Flex
        direction={app.isTablet ? 'column' : 'row'}
        className={classes.textContainer}
        gap={app.isTablet ? 24 : 40}
      >
        <Flex direction='column' gap={app.isTablet ? 12 : 24} basis={'33%'}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/calendar.svg`}
            width={72}
          />
          <Heading size={app.isTablet ? 24 : 32} marginBottom={0}>
            Business Tax Credit Deadlines
          </Heading>
          <Text className={classes.grayText}>
            Don’t miss out on your tax credits! Unclaimed credits won&apos;t
            wait – they expire with the tax year.
          </Text>
        </Flex>
        <Flex direction='column' gap={app.isTablet ? 12 : 24} basis={'66%'}>
          <Flex
            direction={app.isTablet ? 'column' : 'row'}
            gap={app.isTablet ? 12 : 24}
          >
            <Flex direction='column'>
              <Heading size={24} marginBottom={5}>
                C Corporations
              </Heading>
              <Text paddingBottom={4}>Deadline: April 15th</Text>
              <Text paddingBottom={4}>
                Deadline with extension: October 15th
              </Text>
            </Flex>
            <Flex direction='column'>
              <Heading size={24} marginBottom={5}>
                Sole Proprietorships
              </Heading>
              <Text paddingBottom={4}>Deadline: April 15th</Text>
              <Text paddingBottom={4}>
                Deadline with extension: October 15th
              </Text>
            </Flex>
          </Flex>
          <Flex
            direction={app.isTablet ? 'column' : 'row'}
            gap={app.isTablet ? 12 : 24}
          >
            <Flex direction='column'>
              <Heading size={24} marginBottom={5}>
                S Corporations
              </Heading>
              <Text paddingBottom={4}>Deadline: March 15th</Text>
              <Text paddingBottom={4}>
                Deadline with extension: September 15th
              </Text>
            </Flex>
            <Flex direction='column'>
              <Heading size={24} marginBottom={5}>
                Partnerships
              </Heading>
              <Text paddingBottom={4}>Deadline: March 15th</Text>
              <Text paddingBottom={4}>
                Deadline with extension: September 15th
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
});

export default DeadlinesSection;
