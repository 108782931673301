import React from 'react';

interface Props {
  className?: string;
}

const ExclamationMark = ({ className }: Props) => (
  <svg
    className={className}
    width='24'
    height='21'
    viewBox='0 0 24 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 15C11.8022 15 11.6089 15.0586 11.4444 15.1685C11.28 15.2784 11.1518 15.4346 11.0761 15.6173C11.0004 15.8 10.9806 16.0011 11.0192 16.1951C11.0578 16.3891 11.153 16.5673 11.2929 16.7071C11.4327 16.847 11.6109 16.9422 11.8049 16.9808C11.9989 17.0194 12.2 16.9996 12.3827 16.9239C12.5654 16.8482 12.7216 16.72 12.8315 16.5556C12.9413 16.3911 13 16.1978 13 16C13 15.7348 12.8946 15.4804 12.7071 15.2929C12.5196 15.1054 12.2652 15 12 15ZM22.67 16.47L14.62 2.47C14.3598 2.00351 13.9798 1.61495 13.5192 1.34447C13.0586 1.07398 12.5341 0.931366 12 0.931366C11.4659 0.931366 10.9414 1.07398 10.4808 1.34447C10.0202 1.61495 9.64019 2.00351 9.38 2.47L1.38 16.47C1.11079 16.924 0.966141 17.441 0.960643 17.9688C0.955144 18.4966 1.089 19.0165 1.34868 19.476C1.60837 19.9356 1.9847 20.3185 2.43968 20.586C2.89466 20.8536 3.41218 20.9964 3.94 21H20.06C20.5921 21.0052 21.1159 20.8689 21.5779 20.6049C22.0399 20.3409 22.4234 19.9588 22.689 19.4978C22.9546 19.0368 23.0928 18.5134 23.0895 17.9813C23.0862 17.4493 22.9414 16.9277 22.67 16.47ZM20.94 18.47C20.8523 18.6259 20.7245 18.7555 20.5697 18.8452C20.4149 18.9349 20.2389 18.9815 20.06 18.98H3.94C3.76111 18.9815 3.5851 18.9349 3.43032 18.8452C3.27553 18.7555 3.14765 18.6259 3.06 18.47C2.97223 18.318 2.92602 18.1455 2.92602 17.97C2.92602 17.7945 2.97223 17.622 3.06 17.47L11.06 3.47C11.1439 3.3062 11.2714 3.16873 11.4284 3.07274C11.5855 2.97675 11.766 2.92596 11.95 2.92596C12.134 2.92596 12.3145 2.97675 12.4716 3.07274C12.6286 3.16873 12.7561 3.3062 12.84 3.47L20.89 17.47C20.9892 17.6198 21.0462 17.7936 21.055 17.9731C21.0638 18.1526 21.0241 18.3312 20.94 18.49V18.47ZM12 7C11.7348 7 11.4804 7.10535 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8V12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12V8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10535 12.2652 7 12 7Z'
      fill='#B04C1F'
    />
  </svg>
);

export default ExclamationMark;
