import {
  ProgressiveStepper,
  StepOrElement,
} from '../../../dashboard/fed-rd-program';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { DocumentList } from '../../../dashboard/documents';
import React from 'react';
import { Document, ProgramData } from '../../../../lib/interfaces';
import { MainStreetDocumentsColumns } from '../../../dashboard/documents/components/documentList/MainStreetDocumentsColumns';

const useStyles = makeStyles(({ spacing, emerald }: Theme) => ({
  steps: {
    maxWidth: '900px',
    gap: '40px',
  },
  moneyText: {
    color: emerald,
    fontWeight: 500,
  },
  title: {
    fontSize: '28px',
  },
  cell: {
    padding: '16px',
    whiteSpace: 'unset',
  },
  iconSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: spacing(5, 0, 5),
  },
}));

interface DisplayProps {
  program: ProgramData;
  documents: Document[];
  companyName: string;
  token?: string;
  companyEmail?: string;
}

export const AccountantDocuments = ({
  program,
  companyName,
  documents,
  token,
  companyEmail,
}: DisplayProps) => {
  const classes = useStyles();

  const steps: StepOrElement[] = [
    {
      step: {
        icon: '📚',
        title: 'Keep the R&D report for your records',
        description: `We have prepared a document that details all your Qualifying Research Expenditures (QRE) for tax year ${program.taxYear}.`,
      },
    },
    {
      step: {
        icon: '📬',
        title: 'File Form 6765 along with the corporate tax return',
        description: `You will also need to make some updates to other tax forms:\n
          • Add your client's company name and identifying number to Form 6765
          • Add the numbers from line 38 on Form 6765 to Form 3800
          • Add the information from Form 3800 to line 43 in Form 6765
          • Reduce deductions to account for the R&D credit (if applicable)\n
          Please see the documents below for more detailed instructions.`,
      },
    },
  ];

  return (
    <Grid container className={classes.steps}>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          We have determined that {companyName} can claim R&D&nbsp;credits.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ProgressiveStepper
          title='Next steps'
          steps={steps}
          hideButtonRow={true}
        />
      </Grid>
      <Grid item xs={12}>
        <DocumentList
          dataTestId={'accountant-documents'}
          title={'Created by MainStreet'}
          documents={documents}
          token={token}
          companyEmail={companyEmail}
          loading={false}
          columns={MainStreetDocumentsColumns(
            classes,
            [program],
            token,
            companyEmail,
          )}
        />
      </Grid>
    </Grid>
  );
};
