import { Gap, Padding } from '../../components/_helpers/constants';
import _styles from './flex.module.scss';
import React, { forwardRef } from 'react';

import classnames from 'classnames/bind';
const styles = classnames.bind(_styles);

export interface FlexCellProps {
  children: React.ReactNode | JSX.Element;
  className?: string;
  dataTestId?: string;
  alignSelf?: React.CSSProperties['alignSelf'];
  justifySelf?: React.CSSProperties['justifySelf'];
  shrink?: React.CSSProperties['flexShrink'];
  grow?: React.CSSProperties['flexGrow'];
  basis?: React.CSSProperties['flexBasis'];
  order?: React.CSSProperties['order'];
  padding?: Padding;
  style?: React.CSSProperties;
  onClick?: () => void;
  ref?: React.Ref<HTMLDivElement> | undefined;
}

const formatToCSS = (value: Padding | Gap) => {
  return Array.isArray(value)
    ? value.map((val) => `${val}px`).join(' ')
    : `${value}px`;
};

export const FlexCell = forwardRef<HTMLDivElement, FlexCellProps>(
  (
    {
      children,
      className,
      dataTestId,
      alignSelf,
      justifySelf,
      shrink,
      grow,
      basis,
      order,
      padding,
      style,
      onClick,
    },
    ref,
  ) => {
    const composedClassnames = classnames(styles('flex-cell'), className);

    const composedStyles: React.CSSProperties = {
      alignSelf,
      justifySelf,
      flexShrink: shrink,
      flexGrow: grow,
      flexBasis: basis,
      order,
      ...style,
    };

    if (padding) {
      composedStyles.padding = formatToCSS(padding);
    }

    return (
      <div
        data-testid={dataTestId}
        className={composedClassnames}
        style={composedStyles}
        onClick={onClick}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);

FlexCell.displayName = 'Flex Cell';

export interface FlexProps {
  children: React.ReactNode | JSX.Element;
  className?: string;
  dataTestId?: string;
  inline?: boolean;
  wrap?: React.CSSProperties['flexWrap'];
  direction?: React.CSSProperties['flexDirection'];
  alignItems?: React.CSSProperties['alignItems'];
  justifyItems?: React.CSSProperties['justifyItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
  basis?: React.CSSProperties['flexBasis'];
  gap?: Gap;
  padding?: Padding;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Flex = forwardRef<HTMLDivElement, FlexProps>(
  (
    {
      children,
      className,
      dataTestId,
      inline,
      wrap,
      direction,
      alignItems,
      justifyItems,
      justifyContent,
      basis,
      gap,
      padding = 0,
      style,
      onClick,
      ...props
    },
    ref,
  ) => {
    const composedClassname = classnames(styles('flex', { inline }), className);

    const composedStyles: React.CSSProperties = {
      ...style,
      flexWrap: wrap,
      flexDirection: direction,
      alignItems,
      justifyItems,
      justifyContent,
      flexBasis: basis,
    };

    if (padding) {
      composedStyles.padding = formatToCSS(padding);
    }

    if (gap) {
      composedStyles.gap = formatToCSS(gap);
    }

    return (
      <div
        data-testid={dataTestId}
        className={composedClassname}
        style={composedStyles}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    );
  },
);

Flex.displayName = 'Flex';

export default Object.assign(Flex, {
  Cell: FlexCell,
});
