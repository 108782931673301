import React, { useState } from 'react';
import {
  EmployeeForEmployeeTable,
  EmployeeMagicLinkStatusEnum,
} from '../../../lib/interfaces';
import { Card } from 'component-library';
import { makeStyles, TextField, Theme } from '@material-ui/core';
import { SaveEmployeePrimaryEmailAddress } from '../../../services/server';

const useStyles = makeStyles(
  ({
    boxBackgroundColor,
    lighterGrey,
    darkBlue,
    emerald,
    yellow,
    lightBlue,
  }: Theme) => ({
    employeesTable: {
      backgroundColor: boxBackgroundColor,
      borderSpacing: 0,

      '& thead': {
        backgroundColor: '#fcfcf5',
      },

      '& tr': {
        display: 'grid',
        gridTemplateColumns: '1.5fr 1.75fr 1fr 1fr 1fr 1fr',
        borderBottom: '1px solid' + lighterGrey,
      },

      '& thead th': {
        padding: '28px 20px 15px',
        fontSize: '16px',
        fontWeight: 500,
        display: 'block',
        textAlign: 'left',
        '&:not(:last-child)': {
          borderRight: '1px solid ' + lighterGrey,
        },
      },
      // Employee icon colors
      '& tbody tr:nth-child(4n+1) td:first-child div div': {
        backgroundColor: yellow,
      },
      '& tbody tr:nth-child(4n+2) td:first-child div div': {
        backgroundColor: emerald,
        color: '#FFFFFF',
      },
      '& tbody tr:nth-child(4n+3) td:first-child div div': {
        backgroundColor: darkBlue,
        color: '#FFFFFF',
      },
      '& tbody tr:nth-child(4n+4) td:first-child div div': {
        backgroundColor: lightBlue,
      },
      '& tbody td': {
        display: 'flex',
        alignItems: 'center',
        minWidth: 0,
        padding: '30px 20px',

        '& > div': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    nameCell: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
    },
    employeeIcon: {
      width: '44px',
      height: '44px',
      borderRadius: '22px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      marginRight: '10px',
    },
    employeeName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    pill: {
      padding: '7px 20px',
      borderRadius: '100px',
      backgroundColor: '#E5E8E5',
    },
    reimbursementPill: {
      color: 'white',
      backgroundColor: darkBlue,
    },
    status: {
      textTransform: 'capitalize',
    },
    green: {
      color: '#FFFFFF',
      backgroundColor: emerald,
    },
    yellow: {
      backgroundColor: yellow,
    },
    grey: {
      backgroundColor: '#E5E8E5',
    },
    link: {
      color: darkBlue,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    bold: {
      fontWeight: 500,
    },
    editButton: {
      width: '12px',
      cursor: 'pointer',
    },
    emailCell: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    email: {
      marginRight: '20px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    textField: {
      marginRight: '20px',
    },
  }),
);
export default function EmployeeDashboardTable(props: {
  employees: EmployeeForEmployeeTable[];
}) {
  const classes = useStyles();
  const [currentlyEditingEmail, setCurrentlyEditingEmail] = useState<boolean[]>(
    [],
  );

  props.employees.map(() => currentlyEditingEmail.push(false));

  const getStatusColor = (status: EmployeeMagicLinkStatusEnum) => {
    switch (status) {
      case 'active':
        return 'green';
      case 'incomplete':
        return 'yellow';
      default:
        return 'grey';
    }
  };

  const handleAddEmailClick = (index: number) => {
    currentlyEditingEmail[index] = true;
    setCurrentlyEditingEmail([...currentlyEditingEmail]);
  };

  const handleAddEmailChange = (
    email: string,
    employee: EmployeeForEmployeeTable,
  ) => {
    employee.email = email;
  };

  const handleSaveEmailClick = (
    employee: EmployeeForEmployeeTable,
    index: number,
  ) => {
    props.employees[index] = employee;
    SaveEmployeePrimaryEmailAddress(
      employee.employmentRecordId,
      employee.email,
    ).then(() => {
      currentlyEditingEmail[index] = false;
      setCurrentlyEditingEmail([...currentlyEditingEmail]);
    });
  };

  const centsToDollars = (cents?: number): string => {
    if (!cents || cents === 0) {
      return '-';
    }
    const dollars = cents / 100;
    return dollars.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const renderEmployeeRow = (
    employee: EmployeeForEmployeeTable,
    index: number,
  ) => {
    const employeeName = employee.name;
    const firstInitial = employeeName![0];
    const lastInitialMatch = employeeName!.match(/^(\w+).*\s(\w)\w+$/);
    const lastInitial = lastInitialMatch && lastInitialMatch[2];
    const email = employee.email;
    const reimbursement = centsToDollars(employee?.reimbursementAmountCents);
    const status = employee.status;
    const statusColor = getStatusColor(status);
    const state = employee.stateOfResidence || '-';
    const filing = employee.employmentType || '-';

    return (
      <tr data-testid={`employee-${index}`} key={`employee-${index}`}>
        <td>
          <div className={classes.nameCell}>
            <div className={classes.employeeIcon}>
              {firstInitial}
              {lastInitial}
            </div>
            <span className={classes.employeeName}>{employeeName}</span>
          </div>
        </td>
        {email && !currentlyEditingEmail[index] && (
          <td>
            <div className={classes.emailCell}>
              <a
                className={`${classes.email} ${classes.link}`}
                href={`mailto:${email}`}
              >
                {email}
              </a>
              <img
                className={classes.editButton}
                src={`${process.env.PUBLIC_URL}/images/pen-icon.svg`}
                alt='Edit Icon'
                onClick={() => {
                  handleAddEmailClick(index);
                }}
              />
            </div>
          </td>
        )}
        {!email && !currentlyEditingEmail[index] && (
          <td>
            <div className={classes.emailCell}>
              <div
                className={`${classes.link} ${classes.bold}`}
                onClick={() => {
                  handleAddEmailClick(index);
                }}
              >
                Add Email
              </div>
            </div>
          </td>
        )}
        {currentlyEditingEmail[index] && (
          <td>
            <div className={classes.emailCell}>
              <TextField
                className={classes.textField}
                variant='outlined'
                inputMode='email'
                placeholder='Email'
                defaultValue={email}
                size='small'
                onChange={(event) => {
                  const email = event.target.value;
                  handleAddEmailChange(email, employee);
                }}
              />
              <div
                className={`${classes.link} ${classes.bold}`}
                onClick={() => {
                  handleSaveEmailClick(employee, index);
                }}
              >
                Save
              </div>
            </div>
          </td>
        )}
        <td>
          <div className={classes.pill}>{filing}</div>
        </td>
        <td>
          <div className={classes.pill}>{state}</div>
        </td>
        <td>
          <div className={`${classes.pill} ${classes.reimbursementPill}`}>
            {reimbursement}
          </div>
        </td>
        <td>
          <div
            className={`${classes.pill} ${classes[statusColor]} ${classes.status}`}
          >
            {status}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <Card>
      <table className={classes.employeesTable}>
        <thead>
          <tr>
            <th>Employees ({props.employees.length})</th>
            <th>Email</th>
            <th>Filing</th>
            <th>State</th>
            <th>Reimbursement</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {props.employees.length > 0 && props.employees.map(renderEmployeeRow)}
        </tbody>
      </table>
    </Card>
  );
}
