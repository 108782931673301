import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Text, Flex, Animate, Card } from 'component-library';
import { AnimatedGradient } from 'products/onboarding/components';
import Rive from 'rive-react';
import { useCommonStores } from 'stores/useStores';
import { observer } from 'mobx-react';

const useStyles = makeStyles(({ breakpoints }) => ({
  transitionContainer: {
    position: 'relative',
    minHeight: '100vh',
  },
  transition: {
    width: '100%',
    height: '100%',
    position: 'relative',
    zIndex: 3,
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  riveContainer: {
    backgroundColor: 'transparent',

    '& > div': {
      overflow: 'hidden',
    },

    '& canvas': {
      width: '100% !important',
      maxWidth: '540px',
      height: '400px !important',
      marginLeft: '35px',
      [breakpoints.down('sm')]: {
        height: '200px !important',
        marginLeft: '25px',
      },
    },
  },
  animatedText: {
    position: 'relative',
  },
  text: {
    position: 'absolute',
    width: '100%',
    padding: '0 24px',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

interface TransitionAnimationProps {
  transitionDuration: number;
}

export const TransitionAnimation = observer(
  ({ transitionDuration }: TransitionAnimationProps) => {
    const classes = useStyles();
    const { app } = useCommonStores();

    return (
      <Animate
        enter={'fade-in'}
        exit={'fade-out'}
        exitDelay={transitionDuration - 2}
        className={classes.transitionContainer}
      >
        <AnimatedGradient version='v2' />
        <Flex
          alignItems='center'
          className={classes.transition}
          direction='column'
          padding={[app.isMobile ? 80 : 24, 24, 24, 24]}
        >
          <Card
            noBorder
            noBoxShadow
            noMargin
            maxWidth={540}
            className={classes.riveContainer}
          >
            <Rive
              src={`${process.env.PUBLIC_URL}/images/rive/searching-for-credits.riv`}
            />
          </Card>
          <Flex
            direction='column'
            className={classes.animatedText}
            padding={[0, 24]}
          >
            <Animate
              enter={'fade-in'}
              exit={'fade-out'}
              duration={1}
              className={classes.text}
            >
              <Text
                size={app.isMobile ? 18 : 23}
                variant='medium'
                textAlign='center'
              >
                Analyzing everything we know about your&nbsp;company...
              </Text>
            </Animate>

            <Animate
              enter={'fade-in'}
              exit={'fade-out'}
              delay={transitionDuration - 7}
              duration={1}
              className={classes.text}
            >
              <Text
                size={app.isMobile ? 18 : 23}
                variant='medium'
                textAlign='center'
              >
                Checking eligibility against MainStreet&apos;s
                savings&nbsp;database...
              </Text>
            </Animate>
            <Animate
              enter={'fade-in'}
              exit={'fade-out'}
              delay={transitionDuration - 4}
              duration={1}
              className={classes.text}
            >
              <Text
                size={app.isMobile ? 18 : 23}
                variant='medium'
                textAlign='center'
              >
                Preparing your personalized&nbsp;report...
              </Text>
            </Animate>
          </Flex>
        </Flex>
      </Animate>
    );
  },
);
