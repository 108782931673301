import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ContentContainer } from '../component/ContentContainer';
import { observer } from 'mobx-react';
import {
  useAccountCreationStores,
  useFeatureFlags,
  useHistoryStore,
} from 'stores/useStores';
import { Content, Dropdown, Text, SurveyQuestion } from 'component-library';
import { AccountCreationSteps } from 'lib/interfaces';
import { useEffectOnce } from 'lib/helpers';
import {
  TaxTypesList,
  Months,
  IndustriesList,
  CompanyDataEnum,
  ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
} from 'lib/constants';

const useStyles = makeStyles(() => ({
  surveyQuestion: {
    '& button': {
      padding: '12px 15px',
    },
  },
}));

const DropdownQuestionIdToAnswerOptions: {
  [key in CompanyDataEnum]?: string[];
} = {
  [CompanyDataEnum.INDUSTRY]: IndustriesList,
  [CompanyDataEnum.FISCAL_YEAR_END_DATE]: Months,
};

interface TaxesStepProps {
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
}

// Deprecated in place of the TaxStep.tsx in the unified-account-creation
export const TaxesStep = observer(
  ({ pathTo, pathFrom, onNext, onBack }: TaxesStepProps) => {
    const classes = useStyles();
    const { accountCreation } = useAccountCreationStores();
    const history = useHistoryStore();
    const featureFlags = useFeatureFlags();

    const onContinue = async () => {
      const surveyAnswers: {
        [key in CompanyDataEnum]?: string;
      } = accountCreation.TaxesStepQuestions.reduce(
        (answers, item) => ({
          [item.id]: item.answer as string,
          ...answers,
        }),
        {},
      );

      // Create current tax year program
      if (!featureFlags.showOnboardingProgramTaxYearPage) {
        await accountCreation.handleProgramCreation(
          ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
        );
      }

      await accountCreation.updateAccount(
        {
          industry: surveyAnswers[CompanyDataEnum.INDUSTRY],
          fiscalYearEndDate:
            surveyAnswers[CompanyDataEnum.FISCAL_YEAR_END_DATE],
          taxType: surveyAnswers[CompanyDataEnum.TAX_TYPE],
        },
        AccountCreationSteps.TAXES,
      );

      if (!accountCreation.errorMsg) {
        history.push(pathTo);
        onNext();
      }
    };

    useEffectOnce(() => accountCreation.setTaxesStepQuestions());

    return (
      <ContentContainer
        onBack={onBack}
        onNext={onContinue}
        isNextButtonDisabled={accountCreation.enableNextButton(
          accountCreation.TaxesStepQuestions,
        )}
        isNextLoading={accountCreation.isNextButtonLoading}
        pathFrom={pathFrom}
        lastStep={!featureFlags.showOnboardingProgramTaxYearPage}
      >
        {accountCreation.TaxesStepQuestions.map((item) => {
          return (
            <Content key={item.id} paddingLeftRight={0} paddingTopBottom={0}>
              <Text variant='medium' paddingBottom={8}>
                {item.question}
              </Text>
              {DropdownQuestionIdToAnswerOptions[item.id] ? (
                <Dropdown
                  dataTestId={`account_creation_${item.id}`}
                  value={
                    item.answer
                      ? (DropdownQuestionIdToAnswerOptions[item.id] || []).find(
                          (title) => title === item.answer,
                        )
                      : ''
                  }
                  options={DropdownQuestionIdToAnswerOptions[item.id] || []}
                  placeholder=''
                  onInputChange={(val) => {
                    if (typeof val === 'string') {
                      accountCreation.setInputChange(
                        accountCreation.TaxesStepQuestions,
                        item.question,
                        val,
                      );
                    }
                  }}
                />
              ) : (
                <Content flex flexDirection='column' gap={8}>
                  <SurveyQuestion
                    className={classes.surveyQuestion}
                    dataTestId={`account_creation_${item.id}`}
                    withCard={false}
                    answerType={'button_select'}
                    answerOptions={TaxTypesList.map((item) => ({
                      text: item.name,
                      id: item.value,
                    }))}
                    answerValue={item.answer}
                    onChange={(value) => {
                      if (typeof value === 'string') {
                        accountCreation.setInputChange(
                          accountCreation.TaxesStepQuestions,
                          item.question,
                          value,
                        );
                      }
                    }}
                    buttonGroupNoMinWidth
                    buttonGroupWrap
                  />
                </Content>
              )}
            </Content>
          );
        })}
      </ContentContainer>
    );
  },
);
