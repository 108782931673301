import React from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Alert, Color, Flex, Spinner, Text } from 'component-library';
import {
  useCompany,
  useFeatureFlags,
  useTaxCreditsStores,
} from 'stores/useStores';
import moment from 'moment';
import {
  SectionCard,
  SectionCardWithHeader,
} from 'products/tax-credits/features/order-form/components/SectionCard';
import {
  AddPaymentMethod,
  AutorenewalNoticeContent,
  SummaryContent,
} from 'products/tax-credits/features/order-form/components';
import { CentsToDisplayString } from 'lib/helpers';
import { OrderFormAttestation } from 'products/tax-credits/features/order-form/components/OrderFormFooter/OrderFormAttestation';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '900px',
    width: '100%',
  },
  date: {
    display: 'inline-block',
    marginBottom: '8px',
  },
}));

interface SurveyFlowBillingSummaryProps {
  creditTitle: string;
  msFee: number;
  creditEstimate: number;
  netAmount: number;
  handleFormAttestation: () => void;
  taxYear: number;
  nonBindingCopy: string;
  startDate?: Date;
  durationDate?: string;
  paymentAlertCopy?: string;
}

export const SurveyFlowBillingSummary = observer(
  ({
    creditTitle,
    msFee,
    creditEstimate,
    netAmount,
    handleFormAttestation,
    taxYear,
    nonBindingCopy = `This credit estimate is non-binding. Your final credit may differ based on your company's unique facts and circumstances.`,
    startDate,
    durationDate,
    paymentAlertCopy = 'You will not be charged yet. After supplying payment information, we will generate your final credit. Charges will be applied four months later, based on your final credit amount.',
  }: SurveyFlowBillingSummaryProps) => {
    const classes = useStyles();
    const { company } = useCompany();
    const featureFlags = useFeatureFlags();
    const { orderForms } = useTaxCreditsStores();

    const msFeeAmount = CentsToDisplayString(msFee, 0, 0);
    const estimateTotalAmount = CentsToDisplayString(creditEstimate, 0, 0);
    const netTotalAmount = CentsToDisplayString(netAmount, 0, 0);

    return (
      <Flex className={classes.container} direction='column' gap={24}>
        {(startDate || durationDate) && (
          <Text>
            {startDate && (
              <>
                <Text tag='span' variant='medium' className={classes.date}>
                  Start date:
                </Text>{' '}
                {moment(startDate).format('MMMM D, YYYY')}
              </>
            )}
            {durationDate && (
              <>
                <br />
                <Text tag='span' variant='medium' className={classes.date}>
                  Duration:
                </Text>{' '}
                {durationDate}
              </>
            )}
          </Text>
        )}
        {nonBindingCopy && (
          <Alert
            backgroundColor={Color.blue._10}
            text={
              <Flex direction='column'>
                <Text size={15}>{nonBindingCopy}</Text>
              </Flex>
            }
            type='info'
            variant='in_card'
          />
        )}
        {creditEstimate !== 0 ? (
          <SectionCardWithHeader sectionTitle='Summary'>
            <SummaryContent
              creditTitle={creditTitle}
              mainStreetFee={msFeeAmount}
              estimateTotal={estimateTotalAmount}
              netBenefit={netTotalAmount}
              programTaxYear={taxYear}
              mainStreetFeeInfo='- We charge 20% of the final tax credits we help you claim.'
            />
          </SectionCardWithHeader>
        ) : (
          <Flex justifyContent='center' padding={24}>
            <Spinner />
          </Flex>
        )}
        <SectionCard>
          <>
            <AddPaymentMethod />
            <Alert
              backgroundColor={Color.blue._10}
              text={
                <Flex direction='column'>
                  <Text size={15} text={paymentAlertCopy} />
                </Flex>
              }
              type='info'
              variant='in_card'
            />
          </>
        </SectionCard>
        <SectionCardWithHeader sectionTitle='Auto-renewal notice'>
          <AutorenewalNoticeContent autoRenewalCopy='Your MainStreet subscription will automatically renew every year if you are eligible for the tax credits with no action needed on your end. Within 30 days of your renewal, you will be notified of any changes.' />
        </SectionCardWithHeader>
        <OrderFormAttestation
          companyName={company.legalName}
          checked={orderForms.attestationAccepted}
          onChange={handleFormAttestation}
          showAttestationCheckbox={featureFlags.showOrderFormAttestation}
        />
      </Flex>
    );
  },
);
