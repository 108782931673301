import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Color, Flex, Heading, Image } from 'component-library';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  irsGuidelines: {
    width: 'calc(100% - 48px)',
    maxWidth: '1440px',
    margin: '0 auto',
    borderRadius: '12px',
    padding: 24,
    backgroundColor: Color.green._90,
    position: 'relative',
  },
  irsGuidelinesInner: {
    maxWidth: 'clamp(600px, 90vw, 1024px)',
    position: 'relative',
    zIndex: 1,
  },
  mstStamp: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 0,
  },
}));

export const IRSGuidelinesSection: React.FC = observer(() => {
  const classes = useStyles();
  const { app } = useCommonStores();

  return (
    <Flex className={classes.irsGuidelines} justifyContent='center'>
      <div className={classes.mstStamp}>
        <Image
          src={`${process.env.PUBLIC_URL}/images/logo/partners/mst-logo-top-right-corner.svg`}
          width={app.isTablet ? 280 : 373}
        />
      </div>
      <Flex
        gap={app.isTablet ? 24 : 48}
        padding={app.isTablet ? 32 : [80, 24]}
        className={classes.irsGuidelinesInner}
        direction={app.isTablet ? 'column' : 'row'}
      >
        <Flex.Cell basis={!app.isTablet ? 400 : undefined} padding={[12, 0]}>
          <Heading
            color={Color.lime._60}
            variant='medium'
            size={app.isTablet ? 15 : 18}
          >
            The MainStreet Guarantee
          </Heading>
        </Flex.Cell>
        <Flex direction='column' gap={app.isTablet ? 24 : 40}>
          <Heading size={app.isTablet ? 32 : 40} color={Color.neutral.white}>
            We stick to IRS guidelines to ensure that your tax credit claim is
            always accurate and&nbsp;compliant.
          </Heading>
          <Heading size={app.isTablet ? 32 : 40} color={Color.neutral.white}>
            But in the event of an error, we pledge up to{' '}
            <Heading tag='span' color={Color.lime._60}>
              $1&nbsp;Million
            </Heading>{' '}
            to help you with any resulting&nbsp;penalties.
          </Heading>
        </Flex>
      </Flex>
    </Flex>
  );
});
