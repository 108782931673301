import { AnswerValueType } from 'component-library';
import { useEffect, useRef } from 'react';
import { useCompany } from 'stores/useStores';
import { ProgramData, QualificationQuestions } from './interfaces';
import { SurveyAnswer } from './useSurveyQuestions';
import { useQuestionGroup } from './useQuestionGroup';
import {
  ManualTaxInfoGraphCmsQuestionIds,
  SurveyNameEnum,
  taxInfoQuestionIds,
} from './constants';

/**
 * Data store for transitioning previously answered questions,
 * leverages year diff'ing to remove hardcoding of tax years
 *
 * "yearDiff: 0" = Use the current program.taxYear
 * "yearDiff: -1" = Use [program.taxYear - 1]
 * "yearDiff: -2" = Use [program.taxYear - 2]
 *
 * For Multiple choice answers, a transitionListedAnswers prop is
 * used for direct translation of older ids to new ids.
 */
const TRANSITION_QUESTIONS: Record<
  string,
  Array<{
    yearDiff: number;
    id: string;
    transitionDirectAnswer?: Record<string, string>;
    transitionListedAnswers?: Record<string, string>;
  }>
> = {
  // Did anyone else more than 50% of your business
  cktj27lqg1zw40b705t0n2zrk: [
    /* Note there are two mappings with yearDiff: 0
     * The first mapping will take priority because
     * the question id matches the parent question id
     */
    { yearDiff: 0, id: 'cktj27lqg1zw40b705t0n2zrk' },
    {
      yearDiff: 0,
      // new qual flow question and answer mapping
      id: 'cldattuc090710amvm32k691q',
      transitionDirectAnswer: {
        cldattky68vpv0an1pqnci0yy: 'cktj296281xyv0b80nmv7at7i',
        cldattq3u8vsw0an1doue099j: 'cktj29bgo205d0b70ojmmdbuv',
      },
    },
    { yearDiff: -1, id: 'cktj27lqg1zw40b705t0n2zrk' },
  ],

  // Did anyone else more than 50% of your business
  ckwv6cv3kj6a30a73w6wqa8zl: [
    {
      // new qual flow question and answer mapping
      yearDiff: 0,
      id: 'cldattuc090710amvm32k691q',
      transitionDirectAnswer: {
        cldattky68vpv0an1pqnci0yy: 'ckwv6clu8j77d0d25oqvxfddh',
        cldattq3u8vsw0an1doue099j: 'ckwv6fis0jafu0e19d7cnepiv',
      },
    },
  ],

  // Has your company transitioned entity type
  ckre0sk74ibur0a29rl3kr0ce: [
    { yearDiff: 0, id: 'ckre0sk74ibur0a29rl3kr0ce' },
    { yearDiff: -1, id: 'ckre0sk74ibur0a29rl3kr0ce' },
  ],

  // Has your company received government grants for R&D
  ckgijgo6g10xa0a74oocdk38r: [
    { yearDiff: 0, id: 'ckgijgo6g10xa0a74oocdk38r' },
    {
      yearDiff: 0,
      id: 'cldatr3eq8ww10bmyc8y0kx1o',
      transitionDirectAnswer: {
        cldatqz1k8ujq0an1orlezxec: 'ckgs2ph343a2m09796luptk1m',
        cldatrm818x410bmy74npq935: 'ckgs2pk683a360b27gwd514jq',
      },
    },
    { yearDiff: -1, id: 'ckgijgo6g10xa0a74oocdk38r' },
  ],

  // Do you use Quicktime for hourly tracking R&D
  ckrl4a7xst6890c654vcqq3h7: [
    { yearDiff: 0, id: 'ckrl4a7xst6890c654vcqq3h7' },
    { yearDiff: -1, id: 'ckrl4a7xst6890c654vcqq3h7' },
  ],

  // $5 million dollar question

  // Did you make more than $5 million in gross receipts this year?
  ckxqfn4p45e1a0b77ck6jik48: [
    { yearDiff: 0, id: 'ckxqfn4p45e1a0b77ck6jik48' },
    {
      yearDiff: 0,
      id: 'ckgij1soo0zrv0a74xmmxz35s',
      transitionDirectAnswer: {
        ckgs2o0m839zw0b27rpguf8d9: 'ckwv5851sitc50a738utv5xm0',
        ckgs2o2xk3a060b25djyo3yrd: 'ckwv58ag8iw8x0e19ymql80xp',
      },
    },
    {
      yearDiff: -1,
      id: 'ckif2zhwg01ny0a251aqjn88a',
      transitionDirectAnswer: {
        ckif2zv0o01ou0a14jrtrvzgz: 'ckwv5851sitc50a738utv5xm0',
        ckif300f401p60a141v5cvzxy: 'ckwv58ag8iw8x0e19ymql80xp',
      },
    },
  ],

  // Right side is the answer ids for the id in context
  // Do you expect to make more than $5 million in gross receipts next tax year?
  ckgij1soo0zrv0a74xmmxz35s: [
    {
      yearDiff: 0,
      id: 'ckxqfn4p45e1a0b77ck6jik48',
      transitionDirectAnswer: {
        ckwv5851sitc50a738utv5xm0: 'ckgs2o0m839zw0b27rpguf8d9',
        ckwv58ag8iw8x0e19ymql80xp: 'ckgs2o2xk3a060b25djyo3yrd',
      },
    },
    { yearDiff: 0, id: 'ckgij1soo0zrv0a74xmmxz35s' },
    {
      yearDiff: -1,
      id: 'ckif2zhwg01ny0a251aqjn88a',
      transitionDirectAnswer: {
        ckif2zv0o01ou0a14jrtrvzgz: 'ckgs2o0m839zw0b27rpguf8d9',
        ckif300f401p60a141v5cvzxy: 'ckgs2o2xk3a060b25djyo3yrd',
      },
    },
  ],

  // Do you expect to make more than $5 million in gross receipts this tax year?
  ckif2zhwg01ny0a251aqjn88a: [
    {
      yearDiff: 0,
      id: 'ckxqfn4p45e1a0b77ck6jik48',
      transitionDirectAnswer: {
        ckwv5851sitc50a738utv5xm0: 'ckif2zv0o01ou0a14jrtrvzgz',
        ckwv58ag8iw8x0e19ymql80xp: 'ckif300f401p60a141v5cvzxy',
      },
    },
    {
      yearDiff: 0,
      id: 'ckgij1soo0zrv0a74xmmxz35s',
      transitionDirectAnswer: {
        ckgs2o0m839zw0b27rpguf8d9: 'ckif2zv0o01ou0a14jrtrvzgz',
        ckgs2o2xk3a060b25djyo3yrd: 'ckif300f401p60a141v5cvzxy',
      },
    },
    { yearDiff: -1, id: 'ckif2zhwg01ny0a251aqjn88a' },
  ],

  // Outside Credits

  // Did you claim any of the following credits outside of MainStreet this year?
  ckwwiaik0wkxy0b2510fkt903: [
    {
      yearDiff: 0,
      id: 'ckwwiaik0wkxy0b2510fkt903',
    },
    {
      yearDiff: -1,
      id: 'ckwwiaik0wkxy0b2510fkt903',
    },
    {
      yearDiff: 0,
      id: 'cknqfynbsiwir0d17bszdl980',
      transitionListedAnswers: {
        cknqfxdswityc0c32djssy42e: 'ckwwia8iwwlv30d25lowhq3ig',
        cknqfxrowiq600c74f35k0h26: 'ckzg00ea07igx0d69e0qgylqf',
        cknqfyh5kiu4k0c328jngiq46: 'ckwwiad5kwlwh0d25m1ri2n8n',
      },
    },
    {
      yearDiff: -1,
      id: 'cknqfynbsiwir0d17bszdl980',
      transitionListedAnswers: {
        cknqfxdswityc0c32djssy42e: 'ckwwia8iwwlv30d25lowhq3ig',
        cknqfxrowiq600c74f35k0h26: 'ckzg00ea07igx0d69e0qgylqf',
        cknqfyh5kiu4k0c328jngiq46: 'ckwwiad5kwlwh0d25m1ri2n8n',
      },
    },
  ],

  cknqfynbsiwir0d17bszdl980: [
    {
      yearDiff: 0,
      id: 'ckwwiaik0wkxy0b2510fkt903',
      transitionListedAnswers: {
        ckwwia8iwwlv30d25lowhq3ig: 'cknqfxdswityc0c32djssy42e',
        ckzg00ea07igx0d69e0qgylqf: 'cknqfxrowiq600c74f35k0h26',
        ckwwiad5kwlwh0d25m1ri2n8n: 'cknqfyh5kiu4k0c328jngiq46',
      },
    },
    {
      yearDiff: -1,
      id: 'ckwwiaik0wkxy0b2510fkt903',
      transitionListedAnswers: {
        ckwwia8iwwlv30d25lowhq3ig: 'cknqfxdswityc0c32djssy42e',
        ckzg00ea07igx0d69e0qgylqf: 'cknqfxrowiq600c74f35k0h26',
        ckwwiad5kwlwh0d25m1ri2n8n: 'cknqfyh5kiu4k0c328jngiq46',
      },
    },
    {
      yearDiff: 0,
      id: 'cknqfynbsiwir0d17bszdl980',
    },
    {
      yearDiff: -1,
      id: 'cknqfynbsiwir0d17bszdl980',
    },
  ],

  // Who is going to prepare your taxes this year
  ckxgdp1d4445z0e289lqp2x4x: [
    { yearDiff: 0, id: 'ckxgdp1d4445z0e289lqp2x4x' },
    { yearDiff: -1, id: 'ckxgdp1d4445z0e289lqp2x4x' },
  ],

  // What is the email address of who will prepare your taxes
  ckxgdqbns48nh0g29l4jukcrs: [
    { yearDiff: 0, id: 'ckxgdqbns48nh0g29l4jukcrs' },
    { yearDiff: -1, id: 'ckxgdqbns48nh0g29l4jukcrs' },
  ],

  // Did you sell property in California
  ckwv9tew8k7ed0d25ng54okxd: [
    { yearDiff: 0, id: 'ckwv9tew8k7ed0d25ng54okxd' },
    { yearDiff: -1, id: 'ckwv9tew8k7ed0d25ng54okxd' },
  ],

  // Georgia Gross Receipts 2021
  cl0khr7amhev309n0svgd9c2c: [
    { yearDiff: 0, id: 'cl0khr7amhev309n0svgd9c2c' },
    { yearDiff: -1, id: 'cl0khr7amhev309n0svgd9c2c' },
  ],

  // Georgia Gross Receipts 2020
  ckxhyx5psnc110b69q01yovax: [
    { yearDiff: 0, id: 'ckxhyx5psnc110b69q01yovax' },
    { yearDiff: -1, id: 'ckxhyx5psnc110b69q01yovax' },
  ],

  // Georgia Gross Receipts 2019
  ckxhywvoonca20b76vq3sbv9w: [
    { yearDiff: 0, id: 'ckxhywvoonca20b76vq3sbv9w' },
    { yearDiff: -1, id: 'ckxhywvoonca20b76vq3sbv9w' },
  ],

  // Arizona Gross Receipts 2020
  ckxj5gux4xqef0e28a0iczypi: [
    { yearDiff: 0, id: 'ckxj5gux4xqef0e28a0iczypi' },
    { yearDiff: -1, id: 'ckxj5gux4xqef0e28a0iczypi' },
  ],

  // Arizona Gross Receipts 2019
  ckxj5gmfkxqmw0b69yrtjcps0: [
    { yearDiff: 0, id: 'ckxj5gmfkxqmw0b69yrtjcps0' },
    { yearDiff: -1, id: 'ckxj5gmfkxqmw0b69yrtjcps0' },
  ],

  // Arizona Gross Receipts 2018
  ckxj5gbmoxpa00b76uxhbz36p: [
    { yearDiff: 0, id: 'ckxj5gbmoxpa00b76uxhbz36p' },
    { yearDiff: -1, id: 'ckxj5gbmoxpa00b76uxhbz36p' },
  ],

  // What year was the acquired company founded?
  ckrmlifqg70ci0e76timljstg: [
    { yearDiff: 0, id: 'ckrmlifqg70ci0e76timljstg' },
    { yearDiff: -1, id: 'ckrmlifqg70ci0e76timljstg' },
  ],

  // When did the most recent acquisition occur?
  ckx5cwjuo0lui0b73v0g86q6v: [
    { yearDiff: 0, id: 'ckx5cwjuo0lui0b73v0g86q6v' },
    { yearDiff: -1, id: 'ckx5cwjuo0lui0b73v0g86q6v' },
  ],

  // Do any of the acquired companies do R&D?
  ckx6f657scbew0b7334ac1xsk: [
    { yearDiff: 0, id: 'ckx6f657scbew0b7334ac1xsk' },
    { yearDiff: -1, id: 'ckx6f657scbew0b7334ac1xsk' },
  ],

  // What type of acquisition was it?
  ckwv7ikn4jj8u0e19wdpfehfi: [
    { yearDiff: 0, id: 'ckwv7ikn4jj8u0e19wdpfehfi' },
    { yearDiff: -1, id: 'ckwv7ikn4jj8u0e19wdpfehfi' },
  ],

  // Did you spend any amount on cloud computing services
  ckx9dblqw87bd0b717f2xjng7: [
    { yearDiff: 0, id: 'ckx9dblqw87bd0b717f2xjng7' },
    { yearDiff: -1, id: 'ckx9dblqw87bd0b717f2xjng7' },
  ],

  // Did you spend any amount on R&D eligible supplies (tangible items)
  ckx9e9xuw8rf50d712xnzuove: [
    { yearDiff: 0, id: 'ckx9e9xuw8rf50d712xnzuove' },
    { yearDiff: -1, id: 'ckx9e9xuw8rf50d712xnzuove' },
  ],

  // Does your business have one major business component (R&D project), or are you large enough to have multiple projects
  ckxgbenbc349s0e286inhai0w: [
    { yearDiff: 0, id: 'ckxgbenbc349s0e286inhai0w' },
    { yearDiff: -1, id: 'ckxgbenbc349s0e286inhai0w' },
  ],

  // Which of the following options best describes your main R&D project?
  ckxgbjhp437r30g29gert0k7e: [
    { yearDiff: 0, id: 'ckxgbjhp437r30g29gert0k7e' },
    { yearDiff: -1, id: 'ckxgbjhp437r30g29gert0k7e' },
  ],

  // What name would you give to your main project?
  ckxgcu3xs3sn60b76hzgr66q1: [
    { yearDiff: 0, id: 'ckxgcu3xs3sn60b76hzgr66q1' },
    { yearDiff: -1, id: 'ckxgcu3xs3sn60b76hzgr66q1' },
  ],
};

export const useExpenseClassificationAnswerPrefill = (
  surveyName: SurveyNameEnum,
  program: ProgramData | null,
  updateSurveyAnswers: (newAnswers: SurveyAnswer[]) => void,
) => {
  const { company } = useCompany();
  const programRef = useRef<number>(-1);

  const { questions } = useQuestionGroup(
    surveyName,
    program ? program?.taxYear : 0,
    {},
  );

  const questionsToRenderRef = useRef<number>(-1);

  // Add answer prefill logic for missing questionIds in TRANSITION_QUESTIONS
  const addOtherSurveyQuestions = () => {
    if (questions) {
      // Exclude QREs, Gross Incomes and Tax Upload Forms
      const EXCLUDED_IDS: string[] = [];
      taxInfoQuestionIds.forEach((q) => {
        EXCLUDED_IDS.push(
          q.manualQuestionId,
          q.uploadQuestionId,
          q.researchQuestionId,
        );
      });

      questions.map((question) => {
        if (
          TRANSITION_QUESTIONS[question.id] === undefined &&
          !EXCLUDED_IDS.includes(question.id)
        ) {
          TRANSITION_QUESTIONS[question.id] = [
            { yearDiff: 0, id: question.id },
            { yearDiff: -1, id: question.id },
          ];
        }
      });
    }
  };

  useEffect(() => {
    const questionLength = questions ? questions.length : 0;
    if (questions && questionsToRenderRef.current === questionLength) {
      return;
    }

    if (!program) return;
    if (!questions && programRef.current === program.id) return;

    questionsToRenderRef.current = questionLength;
    programRef.current = program.id;

    const transitionAnswers: SurveyAnswer[] = [];
    const yearlyQuestions = company.qualificationQuestionsByYear;

    if (yearlyQuestions) {
      addOtherSurveyQuestions();
      const currentYear = yearlyQuestions[program.taxYear] || {};
      const idsTransitioned: string[] = [];

      // only pre-populate component questions from previous years if user answers they have 1 major component
      if (
        currentYear['ckxgbenbc349s0e286inhai0w'] &&
        currentYear['ckxgbenbc349s0e286inhai0w'] != 'ckxgbdvjc35v40g29xayydjx0'
      ) {
        delete TRANSITION_QUESTIONS['ckxgbenbc349s0e286inhai0w'];
        delete TRANSITION_QUESTIONS['ckxgbjhp437r30g29gert0k7e'];
        delete TRANSITION_QUESTIONS['ckxgcu3xs3sn60b76hzgr66q1'];
      }

      // Check if we received data from codat for 2021 gross receipts and input the value
      const getCodat2021Amount =
        company.financialInformation.grossReceipts.find(
          (grossReceipt) =>
            grossReceipt.taxYear === 2021 &&
            grossReceipt.usesCodat === true &&
            grossReceipt.amountCents > 0,
        )?.amountCents;

      if (getCodat2021Amount) {
        transitionAnswers.push({
          questionId: ManualTaxInfoGraphCmsQuestionIds.GROSS_RECEIPTS_2021,
          answerValue: getCodat2021Amount,
        });
      }

      for (const questionId in TRANSITION_QUESTIONS) {
        // Skip if the current tax year already has these questions answered
        if (currentYear[questionId]) {
          transitionAnswers.push({
            questionId,
            answerValue: currentYear[questionId],
          });
          continue;
        }

        // Iterate through transition props to pull over current/previous years data
        TRANSITION_QUESTIONS[questionId].forEach((row) => {
          // Skip if this question has already be transitioned
          if (idsTransitioned.includes(questionId)) {
            return;
          }

          // Dynamic year targeting (using diff from the current program year in focus)
          // This prevents hard coding of tax years
          const targetYear =
            yearlyQuestions[program.taxYear + row.yearDiff] || {};

          if (targetYear[row.id]) {
            // Array of strings that need to be translated
            if (row.transitionListedAnswers) {
              const targetValues = targetYear[row.id];
              if (Array.isArray(targetValues)) {
                const answerValue = targetValues
                  .map((id) => {
                    return row.transitionListedAnswers?.[id];
                  })
                  .filter(Boolean) as AnswerValueType;

                idsTransitioned.push(questionId);
                transitionAnswers.push({
                  questionId,
                  answerValue,
                });
              }
            } else if (row.transitionDirectAnswer) {
              idsTransitioned.push(questionId);
              transitionAnswers.push({
                questionId,
                answerValue:
                  row.transitionDirectAnswer[targetYear[row.id]] ||
                  targetYear[row.id],
              });
            }
            // Direct value translation
            else if (!skipBusinessComponentPrefill(targetYear, questionId)) {
              idsTransitioned.push(questionId);
              transitionAnswers.push({
                questionId,
                answerValue: targetYear[row.id],
              });
            }
          }
        });
      }

      /**
       * Ensure answers already submitted for the existing year get added
       * to the in-memory survey answers for prefilling
       */
      for (const id in currentYear) {
        if (!idsTransitioned.includes(id)) {
          idsTransitioned.push(id);
          transitionAnswers.push({
            questionId: id,
            answerValue: currentYear[id],
          });
        }
      }

      // Only update if there is something to change
      if (transitionAnswers.length) {
        updateSurveyAnswers(transitionAnswers);
      }
    }
  }, [questions, program, company, updateSurveyAnswers]);
};

const skipBusinessComponentPrefill = (
  targetYear: QualificationQuestions,
  questionId: string,
): boolean => {
  // this first case should never return true, but adding this code for safety
  if (!targetYear['ckxgbenbc349s0e286inhai0w']) {
    switch (questionId) {
      case 'ckxgbjhp437r30g29gert0k7e':
      case 'ckxgcu3xs3sn60b76hzgr66q1':
        return true;
    }
  }
  // if customer has multiple business major business components, skip prefill
  else if (
    targetYear['ckxgbenbc349s0e286inhai0w'] !== 'ckxgbdvjc35v40g29xayydjx0'
  ) {
    switch (questionId) {
      case 'ckxgbenbc349s0e286inhai0w':
      case 'ckxgbjhp437r30g29gert0k7e':
      case 'ckxgcu3xs3sn60b76hzgr66q1':
        return true;
    }
  }
  return false;
};
