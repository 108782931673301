import React, { useState, useContext, useEffect } from 'react';
import { FeatureFlagProviderContext } from '@mainstreet/feature-flags-react';
import { FeatureFlagNameEnum } from 'lib/constants/featureFlagConstants';

import { Button, Content, SideDrawer, Text } from 'component-library';
import { ExpectedPaymentDirection } from 'lib/interfaces';
import { ManualTransfer } from './ManualTransfer';
import { AchTransfer } from './AchTransfer';
import { TransferOptions } from './TransferOptions';
import { BalanceInformation } from 'pages/dashboard/highYieldSavings/components/AccountBalanceCard';
import { MSPlaidClientContext } from 'lib/financial/MSPlaidClientProvider';
import { BankAccount } from '@mainstreet/client-models/financial/banking/bankingModels';
import { Loading } from 'components/util/Loading';
import { TransferSummary } from './TransferSummary';
import { datadogLogs } from '@datadog/browser-logs';

interface TransferHandlerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  balanceInfo: BalanceInformation;
  companyId: number;
  direction: ExpectedPaymentDirection;
  handleConfirm: (
    amountCents: number,
    account?: string,
    effectiveDate?: string,
  ) => void;
}

export const TransferHandler = ({
  open,
  setOpen,
  balanceInfo,
  companyId,
  direction,
  handleConfirm,
}: TransferHandlerProps) => {
  const today = new Date();
  const todayString = `${today.getMonth() < 9 ? '0' : ''}${
    today.getMonth() + 1
  }/${
    today.getDate() < 10 ? '0' : ''
  }${today.getDate()}/${today.getFullYear()}`;
  const { state, actions } = useContext(MSPlaidClientContext);
  // Get the default payment method account from plaid accounts if it is set.
  const defaultPaymentAccount =
    state.plaidAccounts.find(
      (acc) => acc.id === state.defaultPaymentMethod?.associatedAccount?.id,
    ) || null;

  const { getFlag } = useContext(FeatureFlagProviderContext);
  const [currentAmountEntered, setCurrentAmountEntered] = useState<
    number | undefined
  >(undefined);
  const [currentScheduledDate, setCurrentScheduledDate] = useState<
    string | undefined
  >(todayString);
  const [achTransfer, setAchTransfer] = useState<boolean | undefined>(
    undefined,
  );
  const [transferSummary, setTransferSummary] = useState(false);
  const [detailsFetched, setDetailsFetched] = useState(false);
  const [
    currentPlaidAccount,
    setCurrentPlaidAccount,
  ] = useState<BankAccount | null>(defaultPaymentAccount);

  const transferIn = direction === ExpectedPaymentDirection.CREDIT;
  const isAchEnabled = getFlag?.(FeatureFlagNameEnum.TM_ACH_ENABLED);

  useEffect(() => {
    if (open && !detailsFetched && isAchEnabled) {
      setDetailsFetched(true);
      actions.fetchConnectionDetails();
    }
  }, [detailsFetched, open, actions, isAchEnabled]);
  const handleClose = () => {
    setCurrentAmountEntered(undefined);
    setCurrentScheduledDate(todayString);
    setOpen(false);
    setTimeout(() => {
      setCurrentPlaidAccount(null);
      setTransferSummary(false);
      setAchTransfer(undefined);
    }, 500);
  };

  const achTransferMode = achTransfer || state.plaidAccounts.length > 0;

  const manualWireAlertMessage =
    !achTransferMode && (currentAmountEntered !== undefined || transferSummary);
  const transferAlertMessage = (
    manual: boolean,
    achIn: boolean,
    achOut: boolean,
  ) => {
    if (manual) {
      return 'As a precaution against wire fraud, MainStreet will reach out to you directly via email to collect bank details before transferring any funds.';
    }
    if (achIn) {
      return 'MainStreet will initiate an ACH transfer. Your funds will start generating yield once the transaction is cleared approximately 3 business days after being scheduled.';
    }
    if (achOut) {
      return 'MainStreet will initiate an ACH transfer immediately after confirmation. Funds will appear in the chosen account, approximately 3 business days after being scheduled.';
    }
    return undefined;
  };

  const sidedrawerContent = () => {
    if (state.isLoading && isAchEnabled) {
      return <Loading loading={true} />;
    }
    if (
      achTransfer === undefined &&
      isAchEnabled &&
      state.plaidAccounts.length <= 0
    ) {
      // let user choose ACH or Manual transfer
      return (
        <TransferOptions
          direction={direction}
          setAchTransfer={(enabled: boolean) => setAchTransfer(enabled)}
        />
      );
    }
    if (
      (!isAchEnabled && !transferSummary) ||
      (achTransfer === false && !transferSummary)
    ) {
      // ACH is disabled, or achTransfer deliberately set to false, so show manual transfer
      return (
        <ManualTransfer
          companyId={companyId}
          direction={direction}
          balanceInfo={balanceInfo}
          setCurrentAmountEntered={(amountCents) =>
            setCurrentAmountEntered(amountCents)
          }
          onPaymentConfirmation={(amountCents) => handleConfirm(amountCents)}
          handleGoBack={isAchEnabled ? () => setAchTransfer(undefined) : null}
        />
      );
    }
    if (achTransferMode && !transferSummary) {
      // connect Plaid for ACH
      return (
        <AchTransfer
          direction={direction}
          balanceInfo={balanceInfo}
          setCurrentAmountEntered={(amountCents) =>
            setCurrentAmountEntered(amountCents)
          }
          beginningAmountEntered={Number(currentAmountEntered)}
          setCurrentPlaidAccount={(account: BankAccount | null) =>
            setCurrentPlaidAccount(account)
          }
          setCurrentScheduledDate={(date: string | undefined) =>
            setCurrentScheduledDate(date)
          }
          beginningScheduledDate={currentScheduledDate || ''}
          beginManualTransfer={() => setAchTransfer(false)}
          defaultPaymentAccount={defaultPaymentAccount}
        />
      );
    }
    if (transferSummary) {
      // show the summary of a transfer after it completes
      const remainingBalance =
        balanceInfo.remainingPendingBalanceCents &&
        !transferIn &&
        !!currentAmountEntered
          ? balanceInfo.remainingPendingBalanceCents - currentAmountEntered
          : null;
      return achTransferMode &&
        !!currentPlaidAccount &&
        !!currentScheduledDate &&
        !!currentAmountEntered ? (
        <TransferSummary
          account={currentPlaidAccount}
          transferIn={transferIn}
          scheduledDateString={currentScheduledDate}
          amountCents={currentAmountEntered}
          remainingBalance={remainingBalance}
          onEditClick={() => setTransferSummary(false)}
        />
      ) : (
        <Content paddingLeftRight={24} paddingTop={24}>
          <Text
            text='Your transfer is now pending.'
            variant='medium'
            paddingBottom={24}
          />
          <Text text='Our team will confirm your bank account information and remit funds within 10 days.' />
        </Content>
      );
    }
  };
  const transferActionButtons = (amountCents?: number | null) => {
    if (achTransfer === false && transferIn) {
      return;
    }
    if (!achTransferMode && (transferIn || !amountCents || transferSummary)) {
      return (
        <Button label='Close' small={true} onClick={() => handleClose()} />
      );
    }
    if (achTransferMode) {
      return (
        <Content flex>
          <Button
            label={transferSummary ? 'Confirm transfer' : 'Continue'}
            disabled={
              !currentPlaidAccount ||
              !amountCents ||
              (transferIn ? !currentScheduledDate : false)
            }
            small={true}
            onClick={() => {
              if (!amountCents) {
                datadogLogs.logger.error(
                  'User attempted to initiate ACH transfer without entering an amount',
                );
                return;
              }
              if (!transferSummary) {
                setTransferSummary(true);
              } else {
                handleConfirm(
                  amountCents,
                  currentPlaidAccount?.id,
                  currentScheduledDate,
                );
                handleClose();
              }
            }}
          />
          <Button
            label='Cancel'
            small={true}
            variant='outlined'
            onClick={() => handleClose()}
          />
        </Content>
      );
    }
    return (
      <Content flex>
        <Button
          label='Confirm transfer'
          small={true}
          onClick={() => {
            if (!amountCents) {
              datadogLogs.logger.error(
                'User attempted to initiate manual transfer without entering an amount',
              );
              return;
            }
            setTransferSummary(true);
            handleConfirm(amountCents);
          }}
        />
        <Button
          label='Cancel'
          small={true}
          variant='outlined'
          onClick={() => handleClose()}
        />
      </Content>
    );
  };

  return (
    <SideDrawer
      show={open}
      title={`Transfer ${transferIn ? 'in' : 'out'}`}
      closeToggle={() => handleClose()}
      drawerActions={transferActionButtons(currentAmountEntered)}
      drawerActionsBorder={false}
      mainContentPadding={false}
      drawerContent={sidedrawerContent()}
      alertMessage={transferAlertMessage(
        manualWireAlertMessage,
        transferSummary && transferIn,
        transferSummary && !transferIn,
      )}
      alertMessagePosition='bottom'
    />
  );
};
