import { CompanyData } from 'lib/interfaces';
import { useState, useEffect } from 'react';
import { GeneratePayrollAdminLink, GetPayrollAdminLink } from 'services/server';
import { handleErrorOrDataCallback } from 'lib/helpers';

export const usePayrollInviteLink = (company: CompanyData) => {
  const [inviteLink, setInviteLink] = useState('');
  const [inviteLinkError, setInviteLinkError] = useState('');

  useEffect(() => {
    if (!company) return;

    const dataCallback = (data?: any) => {
      if (data) {
        setInviteLink(data.link);
        return;
      }

      GeneratePayrollAdminLink(company!.id).then((generateRes) =>
        handleErrorOrDataCallback(
          generateRes,
          (data) => setInviteLink(data!.link),
          setInviteLinkError,
        ),
      );
    };

    GetPayrollAdminLink(company!.id).then((res) =>
      handleErrorOrDataCallback(res, dataCallback, setInviteLinkError),
    );

    // eslint-disable-next-line
  }, []);

  return { inviteLink, inviteLinkError };
};
