import React from 'react';
import _styles from './heading.module.scss';
import { Spacing } from '../_helpers/constants';

import classnames from 'classnames/bind';
const styles = classnames.bind(_styles);

export interface HeadingProps {
  className?: string;
  children?: React.ReactNode | JSX.Element;
  variant?: 'regular' | 'medium' | 'italic';
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'span';
  textAlign?: 'left' | 'right' | 'center';
  size?: Spacing;
  text?: string;
  marginBottom?: Spacing;
  color?: string;
  dataTestId?: string;
}

export function Heading({
  className,
  children,
  variant = 'regular',
  tag: Tag = 'h1',
  textAlign,
  size,
  text,
  marginBottom,
  color,
  dataTestId,
  ...props
}: HeadingProps) {
  const composed_classname = classnames(
    styles('heading', variant, Tag),
    className,
  );

  // If we need to override heading font size
  const override_styles = {
    fontSize: `${size}px`,
    lineHeight: `${size && '1.2em'}`,
    marginBottom: `${marginBottom}px`,
    color: color && color,
    textAlign,
  };

  return (
    <Tag
      className={composed_classname}
      style={override_styles}
      data-size={size}
      data-testid={dataTestId}
      {...props}
    >
      {children || text}
    </Tag>
  );
}

export default Heading;
