import React from 'react';
import _styles from './text.module.scss';
import { Spacing } from '../_helpers/constants';

import classnames from 'classnames/bind';
const styles = classnames.bind(_styles);

export interface TextProps {
  className?: string;
  children?: React.ReactNode | JSX.Element;
  variant?:
    | 'regular'
    | 'medium'
    | 'bold'
    | 'italic'
    | 'regular-all-caps'
    | 'medium-all-caps'
    | 'italic-all-caps';
  tag?: 'p' | 'small' | 'span';
  size?: Spacing;
  status?: 'success' | 'failed' | 'warning' | 'in_process';
  textAlign?: 'left' | 'right' | 'center';
  disabled?: boolean;
  color?: string;
  text?: string | React.ReactNode;
  dataTestId?: string;
  paddingBottom?: Spacing;
}
export function Text({
  className,
  children,
  variant = 'regular',
  tag: Tag = 'p',
  size,
  status,
  textAlign,
  disabled,
  color,
  text,
  dataTestId,
  paddingBottom,
  ...props
}: TextProps) {
  const composed_classname = classnames(
    styles('text', variant, `text-${size}`, status, Tag, {
      disabled: disabled,
    }),
    className,
  );

  const composed_styles: React.CSSProperties = {
    textAlign,
    color,
    paddingBottom: paddingBottom && `${paddingBottom}px`,
    fontSize: `${size}px`,
  };

  return (
    <Tag
      className={composed_classname}
      style={composed_styles}
      data-testid={dataTestId}
      {...props}
    >
      {children || text}
    </Tag>
  );
}

export default Text;
