import * as React from 'react';
import queryString from 'query-string';
import { Link, makeStyles, Theme, Typography } from '@material-ui/core';
import { SupportLink } from 'components/util/SupportLink';

const useStyles = makeStyles(
  ({ breakpoints, boxShadowRegular, palette, pageBackgroundColor }: Theme) => ({
    root: {
      background: pageBackgroundColor,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '72px 0',
    },
    box: {
      background: '#FFF',
      boxShadow: boxShadowRegular,
      [breakpoints.down('xs')]: {
        width: '80%',
      },
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '42px 128px',
    },
    text: {
      margin: '10px 0',
      display: 'flex',
      justifyContent: 'center',
    },
    textNoFlex: {
      margin: '10px 0',
    },
    backToLinkDiv: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    supportMailto: {
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
      '&:visited': {
        color: palette.primary.main,
      },
      '&:link': {
        color: palette.primary.main,
      },
    },
  }),
);

interface Props {
  search: string;
}

export default function ConnectedAccountRedirect({ search }: Props) {
  const classes = useStyles();
  const { error } = queryString.parse(search);

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <div className={classes.textContainer}>
          {error ? (
            <>
              <Typography
                component='div'
                variant='body1'
                className={classes.text}
              >
                An error occurred setting up your payout account.
              </Typography>
              <Typography
                component='div'
                variant='body1'
                className={classes.textNoFlex}
              >
                Please contact us at <SupportLink /> so we can assist you.
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component='div'
                variant='body1'
                className={classes.text}
              >
                You&apos;re all set!
              </Typography>
              <Typography
                component='div'
                variant='body1'
                className={classes.text}
              >
                Your payout account is now connected to MainStreet and ready to
                receive funds.
              </Typography>
            </>
          )}
          <div className={classes.backToLinkDiv}>
            <Link href={`/`}>Back to Dashboard</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
