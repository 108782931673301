import React from 'react';
import CenterTextBox from 'components/interface/CenterTextBox';
import { SupportLink } from 'components/util/SupportLink';
import { MS_DOMAIN } from '../lib/constants';

export default function MainStreetGuaranteeTOS() {
  return (
    <CenterTextBox>
      <div>
        <p>
          <b>MainStreet Guarantee Terms and Conditions</b>
        </p>
        <p>
          <b>Last updated:</b> November 30, 2020
        </p>

        <p>
          If MainStreet makes a mistake or error in an application we prepare
          for you for any tax credit, tax refund or rebate, incentive program or
          other program and if you are required to pay the IRS or a state tax
          authority any penalties or interest as a result of our mistake or
          error, MainStreet will reimburse you, after you pay the IRS or
          applicable state authority, up to a maximum of one million dollars
          ($1,000,000), for (i) the amount of the penalty and interest paid by
          you that you would otherwise not have been required to pay, and (ii)
          the amount reasonable fees you paid to an accountant to review and/or
          correct such error (the “<b>Reimbursement</b>”).
        </p>

        <p>
          If you receive an audit letter from the IRS or applicable state
          authority relating to any tax credit, tax refund or rebate, incentive
          program or other program we helped you apply for, then MainStreet will
          provide free audit guidance from a MainStreet professional, to help
          understand what to expect and how to prepare for the audit and to
          answer your audit questions until December 31 three years following
          the applicable tax filing season (“<b>Audit Support</b>”). MainStreet
          and its audit professionals will not represent you or provide legal
          advice with respect to any audit.
        </p>

        <p>
          The Reimbursement and Audit Support are subject to your compliance
          with all terms and conditions of the{' '}
          <a href={`https://dashboard.${MS_DOMAIN}/terms-of-service`}>
            MainStreet Service Agreement
          </a>
          . MainStreet will not reimburse you for any penalties or interest that
          are due as a result of any of the following:
          <ol type='i'>
            <li>
              false, inaccurate, or incomplete information you provide to
              MainStreet;
            </li>
            <li>
              your failure to correct and resolve errors in the applications
              prepared by MainStreet;
            </li>
            <li>your failure to follow instructions provided by MainStreet;</li>
            <li>your choice not to claim a deduction or credit; or</li>
            <li>
              your failure or delay in filing a complete and accurate tax
              return.
            </li>
          </ol>
        </p>
        <p>
          To qualify for the Reimbursement, you must provide notice to
          MainStreet by emailing us at <SupportLink /> within 90 days after the
          applicable penalties or interest are assessed.
        </p>
        <p>
          In no event will MainStreet reimburse you for more than an aggregate
          of one million dollars ($1,000,000) for interest and penalties owed to
          the IRS and any state tax authorities and related accountant fees in
          connection with all tax credits, tax refunds, rebates, incentive
          programs and other programs you apply for through MainStreet. THIS
          PROVISION STATES MAINSTREET’S ENTIRE OBLIGATION AND LIABILITY, AND
          YOUR SOLE AND EXCLUSIVE REMEDY, FOR ANY ERRORS IN YOUR APPLICATIONS
          CAUSED BY THE MAINSTREET. The rights and benefits afforded by these
          MainStreet Guarantee Terms and Conditions are personal to you and may
          not be assigned or otherwise transferred to any other party.
        </p>
      </div>
    </CenterTextBox>
  );
}
