import { RadioGroup, Text, TextField, Callout } from 'component-library';
import React from 'react';
import {
  EmploymentDisplayType,
  ContractorExpenseClassificationData,
} from '../../steps/Employees';
import { EmployeeSideDrawerSection } from './EmployeeSideDrawerSection';
import { PayTypeEnum } from '../../../../../lib/interfaces';

interface AmountPayFieldProps {
  errorAmountPaid: boolean;
  setErrorAmountPaid: (value: boolean) => void;
  setAmountPaid: (value: number | undefined) => void;
  amountPaid: number | undefined;
  drawerType: EmploymentDisplayType;
  isContractorForm: boolean;
  contractorPayType: string | undefined;
  setContractorPayType: (value: PayTypeEnum) => void;
  selectedPerson: ContractorExpenseClassificationData | null;
  isEditMode: boolean;
  programTaxYear: number;
}

export const AmountPayField = ({
  errorAmountPaid,
  setErrorAmountPaid,
  setAmountPaid,
  amountPaid,
  drawerType,
  isContractorForm,
  contractorPayType,
  setContractorPayType,
  selectedPerson,
  isEditMode,
  programTaxYear,
}: AmountPayFieldProps) => {
  const amountPaidIsMissingInformation =
    selectedPerson?.paidInCents === 0 && isEditMode;

  const payTypeDisplayValue = contractorPayType ?? '';

  const renderAmountPaidErrorText = (): string => {
    const employeeType = isContractorForm ? '1099' : 'W2';
    if (errorAmountPaid) {
      return 'Amount paid is required';
    }
    return `Total amount you paid this person this fiscal year (${employeeType})`;
  };

  return (
    <EmployeeSideDrawerSection title='Payment details'>
      <>
        <TextField
          error={errorAmountPaid}
          label={`Amount paid in ${programTaxYear}`}
          currencyFormat={true}
          helperText={renderAmountPaidErrorText()}
          name='amount-paid'
          onChange={(num) => {
            const value = num.target.value.replace(/,/g, '');
            const valueIsEmpty = value === '';
            setErrorAmountPaid(valueIsEmpty);
            if (valueIsEmpty) {
              return setAmountPaid(undefined);
            }
            return setAmountPaid(Number(value) * 100);
          }}
          value={(amountPaid || 0) / 100}
          dataTestId={`paid-${drawerType}`}
          secondaryLabel={
            amountPaidIsMissingInformation ? 'Missing information' : undefined
          }
          secondaryLabelTheme={
            amountPaidIsMissingInformation ? 'warning' : undefined
          }
        />
        {isContractorForm && (
          <>
            <Text variant='medium' size={15} text='Pay type' />
            <RadioGroup
              name='Pay type'
              options={[
                {
                  name: 'Variable (hourly, monthly, etc)',
                  value: PayTypeEnum.VARIABLE,
                },
                {
                  name: 'Fixed (fix scope and cost)',
                  value: PayTypeEnum.FIXED,
                },
              ]}
              value={payTypeDisplayValue}
              variant={'regular'}
              size={16}
              onChange={(e) => {
                setContractorPayType(e.target.value as PayTypeEnum);
              }}
            />
            {payTypeDisplayValue === PayTypeEnum.FIXED && (
              <Callout
                title={`Setting the pay type to 'Fixed' will make this person's salary not count as a Qualified Research Expenditure (QRE) and will not count toward your final R&D credits.`}
              />
            )}
          </>
        )}
      </>
    </EmployeeSideDrawerSection>
  );
};
