import {
  Card,
  Color,
  Expandable,
  Table,
  Flex,
  Text,
  TextField,
  Icon,
  IconEnum,
  Animate,
  Tooltip,
  Button,
  Link,
  SideDrawer,
} from 'component-library';
import React, { ChangeEvent, useState } from 'react';
import {
  ContractorExpenseClassificationData,
  IndividualExpenseClassificationData,
} from '../../../steps/Employees';
import { RdPercentageSlider } from './RdPercentageSlider';
import { makeStyles } from '@material-ui/core';
import { useEffectOnce, beginsWithVowel } from '../../../../../../lib/helpers';
import TaxCreditsIcon from '../../../../../../components/icons/TaxCreditsIcon';
import { useFeatureFlags } from '../../../../../../stores/useStores';
import { JobGroupEnum, JobGroupEnumToEducationTitle } from 'lib/constants';

const useStyles = makeStyles(() => ({
  subtitle: {
    color: Color.neutral._50,
    fontWeight: 'normal',
  },
  bulkEditContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
  },
  bulkEditPercentage: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  bulkRange: {
    color: Color.neutral._60,
  },
  percentageField: {
    width: '80px',
    marginLeft: '12px',
    marginRight: '16px',
    backgroundColor: Color.neutral.white,
    border: `1px solid ${Color.neutral._20}`,
  },
  percentInput: {
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: '2px',
    border: `1px solid ${Color.neutral._20} !important`,
    boxShadow: 'none !important',
  },
  textFieldPercentageStatus: {
    marginLeft: '12px',
    marginRight: '16px',
    width: '80px',
  },
  textFieldInputPercentageStatus: {
    backgroundColor: `${Color.neutral.white} !important`,
    color: 'black !important',
    textAlign: 'center',
    fontWeight: 'bold',
    border: `1px solid ${Color.neutral._20} !important`,
    boxShadow: 'none !important',
  },
  defaultPercentageLabel: {
    padding: '4px 0',
    fontSize: 13,
  },
  collapseToggleRow: {
    backgroundColor: Color.neutral.white,
    zIndex: 1,
  },
  boundaryMessage: {
    backgroundColor: Color.blue._20,
  },
  collapseToggleContainer: {
    cursor: 'pointer',
  },
  collapseToggle: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: Color.blue._60,
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  jobGroupTableBlur: {
    border: `1px solid ${Color.neutral._20}`,
    '& tr': {
      borderTop: `1px solid ${Color.neutral._20}`,
      width: '100%',
    },
    '& tbody:before': {
      display: 'none',
    },
    filter: 'blur(8px)',
    '-webkit-filter': 'blur(8px)',
    minHeight: '275px',
  },
  jobGroupTable: {
    border: `1px solid ${Color.neutral._20}`,
    '& tr': {
      borderTop: `1px solid ${Color.neutral._20}`,
      width: '100%',
    },
    '& tbody:before': {
      display: 'none',
    },
    '& tbody:after': {
      display: 'none',
    },
    '& th': {
      padding: 8,
      flex: '1 1 auto !important',
    },
  },
  iconInline: {
    display: 'inline !important',
    marginTop: '5px',
    position: 'relative',
    top: '5px',
  },
  infoIcon: {
    marginRight: 8,
  },
  buttonPercentageStatus: {
    border: `2px solid ${Color.blue._20}`,
    marginLeft: '16px',
    color: Color.blue._60,
    backgroundColor: Color.neutral.white,
  },
  educationLink: {
    fontSize: 13,
  },
  mainHeader: {
    backgroundColor: Color.neutral._light_20,
  },
  educationText: { fontWeight: 500, lineHeight: 1.6, marginBottom: '10px' },
  overlayText: {
    textAlign: 'center',
    alignItems: 'center',
    paddingLeft: '15%',
    paddingRight: '15%',
    position: 'absolute',
    top: '5%',
    marginBottom: '20px',
    zIndex: 1,
  },
  tableBlur: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '50px',
  },
  iconSize: {
    width: '84px',
    height: '84px',
  },
  exitIcon: {
    position: 'absolute',
    top: '10%',
    right: '0%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
    '&:hover, &:focus': {
      cursor: 'pointer',
    },
  },
}));

interface JobGroupCardProps {
  employees: IndividualExpenseClassificationData[];
  title: string;
  educationTitle: string;
  educationText: string;
  educationBullets: string[];
  lowPercentage: number;
  highPercentage: number;
  defaultPercentage: number;
  setPercent: (id: number, newPercentage: number) => void;
  dataTestId: string; // Is the job group enum... JobGroupCard should probably have a jobGroup param...
  className: string;
  defaultShowEmploymentRecords?: boolean;
}

export const JobGroupCard = ({
  employees,
  title,
  educationTitle,
  educationText,
  educationBullets,
  lowPercentage,
  highPercentage,
  defaultPercentage,
  setPercent,
  dataTestId,
  className,
  defaultShowEmploymentRecords,
}: JobGroupCardProps) => {
  const classes = useStyles();
  const featureFlags = useFeatureFlags();

  const [educationSideDrawerOpen, setEducationSideDrawerOpen] = useState(false);
  const [bulkPercentage, setBulkPercentage] =
    useState<number>(defaultPercentage);

  const [recommendMessage, setRecommendMessage] = useState<string>('');
  const [percentagesAreTheSame, setPercentagesAreTheSame] =
    useState<boolean>(false);
  const [employeePercentagesMatchDefault, setEmployeePercentagesMatchDefault] =
    useState<boolean>(false);
  const [showEmploymentRecords, setShowEmploymentRecords] = useState<boolean>(
    defaultShowEmploymentRecords || false,
  );
  const [showEducationPopUp, setShowEducationPopUp] = useState<boolean>(false);
  const jobGroup = dataTestId as JobGroupEnum; // hack to get job group from existing value
  const titleToJobGroup = title.toLowerCase().replace(' ', '_'); // Also a hack to get job group...

  // Get message for use in out of bounds display
  let jobGroupEmployees = JobGroupEnumToEducationTitle[jobGroup];
  jobGroupEmployees =
    jobGroupEmployees[0].toUpperCase() + jobGroupEmployees.slice(1) + 's';
  const message = `${jobGroupEmployees} at companies like yours often spend ${lowPercentage}-${highPercentage}% of their time on qualifying activities.`;

  useEffectOnce(async () => {
    checkPercentages();
  });

  const singularEmployee = employees.length === 1;

  const checkPercentages = () => {
    checkOutOfBounds();
    checkIfEmployeePercentagesAreTheSame();
    checkIfEmployeePercentagesMatchDefault();
  };

  const changeEducationTitleToKey = () => {
    const key = titleToJobGroup + '-education_popup';
    return key;
  };

  const setLocalStorageForEducationPopUp = () => {
    const key = changeEducationTitleToKey();
    localStorage.setItem(key, 'true');
  };

  const getLocalStorageForEducationPopUp = () => {
    const key = changeEducationTitleToKey();
    return localStorage.getItem(key);
  };

  const educationPopUpForFirstTimeViewers = () => {
    if (featureFlags.isEducationPopUpEnabled) {
      if (!getLocalStorageForEducationPopUp()) {
        setShowEducationPopUp(true);
        setLocalStorageForEducationPopUp();
      } else {
        setShowEducationPopUp(false);
      }
    }
  };

  const checkOutOfBounds = () => {
    const showOutOfBoundMessage = employees.map((employee) => {
      const percentage = employee?.percentage ? employee.percentage : 0;
      if (percentage < lowPercentage || percentage > highPercentage) {
        return true;
      } else {
        return false;
      }
    });

    setRecommendMessage(showOutOfBoundMessage.includes(true) ? message : '');
  };

  const checkIfEmployeePercentagesAreTheSame = () => {
    const employeePercentages = new Set(
      employees.map((employee) => {
        return employee.percentage;
      }),
    );
    const arePercentagesTheSame = employeePercentages.size === 1;
    setPercentagesAreTheSame(arePercentagesTheSame);

    if (arePercentagesTheSame) {
      setBulkPercentage(employeePercentages.values().next().value);
    }
  };

  const checkIfEmployeePercentagesMatchDefault = () => {
    const employeesWithNonDefaultPercentage = [];
    employees.forEach((employee) => {
      if (employee.percentage != defaultPercentage) {
        employeesWithNonDefaultPercentage.push(employee);
      }
    });

    if (employeesWithNonDefaultPercentage.length) {
      setEmployeePercentagesMatchDefault(false);
    } else {
      setEmployeePercentagesMatchDefault(true);
    }
  };

  const handlePercentInput = (
    inputEvent:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const percentInput: number = parseInt(
      inputEvent.target.value.replace(/\D/g, ''),
    );

    if (percentInput < 0 || percentInput > 100) {
      setBulkPercentage(0);
      // Propagate value to all employees in the job group
      employees.forEach((employee) => setPercent(employee.id, 0));
    } else {
      setBulkPercentage(percentInput);
      // Propagate value to all employees in the job group
      employees.forEach((employee) => setPercent(employee.id, percentInput));
    }
    checkOutOfBounds();
  };

  const educationTitleString = () => {
    let formattedTitle = educationTitle;
    if (singularEmployee) {
      if (educationTitle[0] !== 'u' && beginsWithVowel(educationTitle)) {
        formattedTitle = `an ${educationTitle}`;
      } else {
        formattedTitle = `a ${educationTitle}`;
      }
    } else {
      formattedTitle = `${educationTitle}s`;
    }

    return `Confirm how ${formattedTitle} contribute${
      singularEmployee ? 's' : ''
    } to R&D efforts`;
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      width: 50,
      Cell: (row: { data: ContractorExpenseClassificationData }) => (
        <Flex.Cell
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '0 8px',
          }}
        >
          <Text text={row.data.name} />
        </Flex.Cell>
      ),
    },
    {
      Header: () => (
        <Flex direction='column' alignItems='flex-end'>
          <Text color={Color.neutral._90} variant='medium' tag='span'>
            Percentage of time spent on R&D
          </Text>
          <Text
            color={Color.neutral._60}
            size={13}
            tag='span'
            textAlign='right'
          >
            Often{' '}
            {lowPercentage === highPercentage
              ? lowPercentage
              : `${lowPercentage}-${highPercentage}`}
            % for MainStreet customers
          </Text>
        </Flex>
      ),
      // Header: '',
      accessor: 'slider',
      Cell: (row: { data: ContractorExpenseClassificationData }) => (
        <RdPercentageSlider
          percent={row.data.percentage || 0}
          setPercent={setPercent}
          lowPercentEstimate={lowPercentage || 0}
          highPercentEstimate={highPercentage || 0}
          id={row.data.id}
          checkPercentages={checkPercentages}
        />
      ),
      disableSortBy: true,
    },
  ];

  return (
    <Card dataTestId={dataTestId} className={className}>
      <Flex padding={[12, 16]} className={classes.mainHeader}>
        <Flex direction='column'>
          <Text variant='medium' size={15} paddingBottom={4}>
            {title}{' '}
            <span className={classes.subtitle}>
              ({employees.length} Contributor{singularEmployee ? '' : 's'})
            </span>
          </Text>
          <Link
            onClick={() => {
              setLocalStorageForEducationPopUp();
              setEducationSideDrawerOpen(true);
            }}
            external
            omitIcon
            text={educationTitleString()}
            dataTestId={`education-sidedrawer-link`}
            className={classes.educationLink}
          />
        </Flex>
        {!showEmploymentRecords && (
          <Flex
            justifyContent='end'
            alignItems='center'
            className={classes.bulkEditContainer}
            data-testid={`${dataTestId}-header`}
          >
            <div>
              <div className={classes.defaultPercentageLabel}>
                Percentage of time spent on R&D
              </div>
              <div className={classes.bulkRange}>
                {lowPercentage === highPercentage
                  ? `Often ${lowPercentage}% for MainStreet customers`
                  : `Often ${lowPercentage}–${highPercentage}% for MainStreet customers`}
                <Tooltip
                  text='This range represents the time employees typically spend directly engaging in, supporting, and/or supervising R&D activities.'
                  position={'top'}
                >
                  <Icon
                    className={classes.iconInline}
                    name={IconEnum.question_circle}
                    color={Color.neutral._60}
                    size={24}
                  />
                </Tooltip>
              </div>
            </div>
            <div className={classes.bulkEditPercentage}>
              {percentagesAreTheSame ? (
                <TextField
                  value={bulkPercentage || 0}
                  onChange={handlePercentInput}
                  percentFormat
                  className={classes.percentageField}
                  inputClassName={classes.percentInput}
                  dataTestId={`${dataTestId}-input`}
                />
              ) : (
                <TextField
                  value={'Mixed'}
                  className={classes.percentageField}
                  inputClassName={classes.textFieldInputPercentageStatus}
                  dataTestId={`${dataTestId}-mixed-input`}
                  disabled={true}
                />
              )}
            </div>
          </Flex>
        )}
        {showEmploymentRecords && !employeePercentagesMatchDefault && (
          <div>
            <Button
              className={classes.buttonPercentageStatus}
              label={`Auto assign ${defaultPercentage}% to this group`}
              variant='outlined'
              onClick={() => {
                setBulkPercentage(defaultPercentage);
                employees.forEach((employee) => {
                  setPercent(employee.id, defaultPercentage);
                });
                checkPercentages();
              }}
              dataTestId={`${dataTestId}-assign-percentage-button`}
            />
          </div>
        )}
      </Flex>
      {employees.length > 0 && (
        <>
          <Expandable expand={showEmploymentRecords}>
            <>
              <Flex
                className={showEducationPopUp ? classes.tableBlur : undefined}
                data-testid={`${dataTestId}-education-pop-up-section`}
              >
                {showEducationPopUp && (
                  <>
                    <Icon
                      size={50}
                      className={classes.exitIcon}
                      name={IconEnum.x_mark}
                      onClick={() => setShowEducationPopUp(false)}
                    />
                    <Flex
                      className={classes.overlayText}
                      direction={'column'}
                      justifyContent={'center'}
                      data-testid={`${dataTestId}-overlay-text`}
                    >
                      <Flex justifyContent={'center'}>
                        <TaxCreditsIcon className={classes.iconSize} />
                      </Flex>
                      <Flex justifyContent={'center'}>
                        <Text
                          className={classes.educationText}
                          text={`${educationTitle}s may
                        spend more of their time performing qualifying R&D
                        activities than you think.`}
                        ></Text>
                      </Flex>
                      <Flex justifyContent={'center'}>
                        <Button
                          label={`View common R&D activities ${educationTitle}s perform`}
                          onClick={() => {
                            setLocalStorageForEducationPopUp();
                            setShowEducationPopUp(false);
                            setEducationSideDrawerOpen(true);
                          }}
                          variant={'outlined'}
                          dataTestId={`${dataTestId}-side-drawer-link-button`}
                        ></Button>
                      </Flex>
                    </Flex>
                  </>
                )}
                <Flex direction='column'>
                  {recommendMessage.length > 0 && (
                    <Animate enter={'from-bottom'} duration={0}>
                      <Flex
                        className={classes.boundaryMessage}
                        padding={[16, 16, 16, 16]}
                        justifyContent='left'
                      >
                        <Icon
                          name={IconEnum.info_circle}
                          color={Color.blue._70}
                          size={24}
                          className={classes.infoIcon}
                        />
                        <Text
                          data-testid={`${dataTestId}-recommend-message`}
                          className={classes.alert}
                          size={13}
                          text={recommendMessage}
                        />
                      </Flex>
                    </Animate>
                  )}
                  <Table
                    className={
                      showEducationPopUp
                        ? classes.jobGroupTableBlur
                        : classes.jobGroupTable
                    }
                    columns={columns}
                    data={employees.sort((a, b) =>
                      (a?.name ?? 0) > (b?.name ?? 0) ? 1 : -1,
                    )}
                    paginationSizePerPage={1000} // arbitrarily high to prevent pagination
                    paginationHideSinglePage={true}
                    headerColor={Color.neutral.white}
                    sortBy={[{ id: 'name', desc: false }]}
                    dataTestId={`${dataTestId}-table`}
                  />
                </Flex>
              </Flex>
            </>
          </Expandable>
          <Flex
            className={classes.collapseToggleRow}
            padding={[8, 0]}
            justifyContent='center'
          >
            {showEmploymentRecords ? (
              <div
                data-testid={`${dataTestId}-hide-employees`}
                className={classes.collapseToggleContainer}
                onClick={() => setShowEmploymentRecords(!showEmploymentRecords)}
              >
                <Text
                  className={classes.collapseToggle}
                  color={Color.blue._60}
                  text={
                    <>
                      <Icon
                        name={IconEnum.circle_minus}
                        color={Color.blue._60}
                        size={16}
                      />
                      Collapse job group
                    </>
                  }
                />
              </div>
            ) : (
              <div
                data-testid={`${dataTestId}-show-employees`}
                className={classes.collapseToggleContainer}
                onClick={() => {
                  setShowEmploymentRecords(!showEmploymentRecords);
                  educationPopUpForFirstTimeViewers();
                }}
              >
                <Text
                  className={classes.collapseToggle}
                  color={Color.blue._60}
                  text={
                    <>
                      <Icon
                        name={IconEnum.circle_plus}
                        color={Color.blue._60}
                        size={16}
                      />
                      Show all employees
                    </>
                  }
                />
              </div>
            )}
          </Flex>
        </>
      )}
      <SideDrawer
        show={educationSideDrawerOpen}
        title={`R&D Activities - ${title}`}
        backgroundColor={Color.neutral._light_20}
        mainContentFullHeight
        drawerContent={
          <Card noMargin>
            <Flex padding={16} direction='column'>
              <Text text={educationText} paddingBottom={16} />
              <Text text='Examples' variant='bold' />
              <ul>
                {educationBullets.map((b, index) => {
                  if (b.includes('would not qualify.')) {
                    return (
                      <React.Fragment key={`${b}-${index}`}>
                        <li>
                          <Text>
                            {b.split('would not qualify.')[0]}
                            <Text variant='bold'>{' would not qualify.'}</Text>
                          </Text>
                        </li>
                        <br />
                      </React.Fragment>
                    );
                  }

                  return (
                    <React.Fragment key={`${b}-${index}`}>
                      <li>
                        <Text text={b} />
                      </li>
                      <br />
                    </React.Fragment>
                  );
                })}
              </ul>
            </Flex>
          </Card>
        }
        drawerActions={<></>}
        closeToggle={() => setEducationSideDrawerOpen(false)}
      />
    </Card>
  );
};
