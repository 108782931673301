import React from 'react';
import { RouteConfiguration } from 'routes/RouteConfiguration';
import { Page } from 'lib/constants';
import { TreasuryManagementAccountPage } from 'pages/dashboard/highYieldSavings';
import { useCompany } from 'stores/useStores';
import HttpErrorPage from 'pages/HttpErrorPage';

export const useTreasuryManagementRoutes = (): RouteConfiguration[] => {
  const { company } = useCompany();

  return [
    {
      path: `/${Page.treasuryManagement}`,
      render: () => {
        if (company.treasuryManagementEnabled) {
          return <TreasuryManagementAccountPage />;
        }
        return <HttpErrorPage errorCode={404} />;
      },
      csrfRequired: true,
    },
  ];
};
