import React, { useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ContinueButton } from 'components/ContinueButton';
import { Loading } from 'components/util/Loading';

const useStyles = makeStyles(
  ({ typography, boxShadowRegular, darkerNavy }) => ({
    root: {
      marginTop: '30px',
    },
    paper: {
      boxShadow: boxShadowRegular,
    },
    mainText: {
      fontSize: '30px',
      fontWeight: 'normal',
    },
    lowerText: {
      fontWeight: 'normal',
      color: 'rgba(51, 51, 51, 0.8)',
    },
    icon: {
      ...typography.h1,
    },
    coupon: {
      backgroundColor: 'rgba(229, 229, 229, 0.2);',
    },
    meanTimeText: {
      fontStyle: 'italic',
      color: darkerNavy,
    },
    iceCreamClaimButton: {
      minWidth: '180px',
      padding: '0 40px',
      height: '50px',
      borderRadius: '4px',
      textTransform: 'initial',
      fontSize: '18px',
      fontWeight: 500,
      marginTop: 40,
      marginBottom: 10,
      '&:hover': {
        boxShadow: 'none',
      },
    },
    couponRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    couponIcon: {
      marginBottom: '20px',
      fontSize: 32,
    },
    couponTitle: {
      fontWeight: 500,
      lineHeight: '28px',
      color: darkerNavy,
    },
    couponDescription: {
      lineHeight: '28px',
      color: darkerNavy,
    },
    viaDoorDash: {
      color: darkerNavy,
      opacity: '.5',
      fontSize: 12,
      textAlign: 'center',
    },
    iceCreamSuccess: {
      padding: '27px 7px 22px',
      background: 'rgba(6,123,39,0.05)',
      border: '1px solid rgb(6,123,39)',
      width: '740px',
      textAlign: 'center',
      marginBottom: 40,
    },
    errorMessaging: {
      marginTop: '10px',
      textAlign: 'center',
    },
    content: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '1.8',
      color: darkerNavy,
    },
  }),
);

const moveToDashboard = () => {
  window.location.href = '/';
};

export interface ExpenseReviewSuccessProps {
  hasCoupon: boolean;
  emailCoupon: () => Promise<{ errorMsg?: string }>;
}

export const ExpenseReviewSuccess = ({
  hasCoupon,
  emailCoupon,
}: ExpenseReviewSuccessProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <SuccessPaperCard hasCoupon={hasCoupon} />
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12} container justify='center'>
        {hasCoupon ? (
          <ClaimIceCreamCoupon emailCoupon={emailCoupon} />
        ) : (
          <ContinueButton
            label='Go back to dashboard'
            onClick={moveToDashboard}
          />
        )}
      </Grid>
      <Grid item xs={12} container justify='center'>
        <Typography variant='subtitle1' className={classes.lowerText}>
          {!hasCoupon &&
            'Or close this page and get some ice cream. You deserve it.'}
        </Typography>
      </Grid>
    </Grid>
  );
};

const IceCreamSuccess = () => {
  const classes = useStyles();

  return (
    <div className={classes.iceCreamSuccess}>
      <div className={classes.couponTitle}>Your ice cream is on its way!</div>
      <div className={classes.couponDescription}>
        You should receive an email with a redeemable code within a few minutes.
      </div>
    </div>
  );
};

interface ClaimProps {
  emailCoupon: () => Promise<{ errorMsg?: string }>;
}

const ClaimIceCreamCoupon = ({ emailCoupon }: ClaimProps) => {
  const classes = useStyles();
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  return (
    <div className={classes.couponRoot}>
      <div className={classes.couponIcon}>
        <span role='img' aria-label='ice cream'>
          🍦
        </span>
      </div>
      {success ? (
        <>
          <IceCreamSuccess />
          <ContinueButton
            label='Go back to dashboard'
            onClick={moveToDashboard}
          />
        </>
      ) : (
        <>
          <div className={classes.couponTitle}>Get some ice cream, on us.</div>
          <div className={classes.couponDescription}>
            We know how tedious this process can be, so we want you to treat
            yourself.
          </div>

          <Loading loading={loading}>
            <Button
              variant='contained'
              color='secondary'
              className={classes.iceCreamClaimButton}
              onClick={() => {
                setLoading(true);
                emailCoupon()
                  .then(({ errorMsg }) => {
                    if (errorMsg) {
                      setError(errorMsg);
                      return;
                    }

                    setSuccess(true);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            >
              Claim your free ice cream
            </Button>
            {error && (
              <Typography component='div' variant='body1'>
                <FormHelperText className={classes.errorMessaging} error>
                  {error}
                </FormHelperText>
              </Typography>
            )}
            <div className={classes.viaDoorDash}>via Doordash</div>
          </Loading>
        </>
      )}
    </div>
  );
};

interface SuccessProps {
  hasCoupon: boolean;
}

const SuccessPaperCard = ({ hasCoupon }: SuccessProps) => {
  const classes = useStyles();

  return (
    <Paper square className={classes.paper}>
      <Grid container>
        <Grid item xs={12}>
          <Box paddingX={8} paddingY={5} textAlign='center'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <span
                  className={classes.icon}
                  role='img'
                  aria-label='smooth sailing'
                >
                  ⛵️
                </span>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h1' className={classes.mainText}>
                  It&apos;s all smooth sailing from here on.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          container
          justify='center'
          className={hasCoupon ? classes.coupon : ''}
        >
          <Box paddingX={8} paddingY={5} textAlign='center'>
            <Typography variant='h6' className={classes.content}>
              Our accountants will now double check all of your information.
              Keep an eye out for an email with more instructions from us in
              about a week. This will include documentation for your tax
              preparer to include with your annual tax return.
            </Typography>
          </Box>
        </Grid>
        {hasCoupon && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} container justify='center'>
              <Box paddingX={8} paddingY={5} textAlign='center'>
                <Typography variant='h6' className={classes.meanTimeText}>
                  In the meantime...
                </Typography>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};
