import React from 'react';
import { makeStyles } from '@material-ui/core';

const duration = '10s';
const useStyles = makeStyles(() => ({
  gradientContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    backgroundColor: 'white',
    pointerEvents: 'none',
    zIndex: 0,
  },
  noise: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: `url(${process.env.PUBLIC_URL}/images/img-noise-1000x1000.png)`,
    backgroundRepeat: 'repeat',
    zIndex: 2,
    mixBlendMode: 'lighten',
    opacity: 0.5,
  },
  circle: {
    zIndex: 0,
    width: '56vw',
    height: '56vw',
    filter: 'blur(10vh)',
    borderRadius: '100%',
    position: 'absolute',
    bottom: '-45vw',
    opacity: 1,
  },
  light1: {
    backgroundColor: '#DDFA38', // green
    right: '-25vw',
    animation: `$light1 ${duration} linear 0s infinite`,
  },
  light2: {
    backgroundColor: '#fea50c', // orange
    left: '-25vw',
    animation: `$light2 ${duration} linear 0s infinite`,
  },
  light3: {
    backgroundColor: '#08F4F2', // blue
    bottom: '-50vw',
    left: '10vw',
    animation: `$light3 ${duration} linear 0s infinite`,
  },
  light1_v2: {
    backgroundColor: '#13006f', // purple
    right: '-25vw',
    animation: `$light1 ${duration} linear 0s infinite`,
  },
  light2_v2: {
    backgroundColor: '#08F4F2', // blue
    left: '-10vw',
    animation: `$light2 ${duration} linear 0s infinite`,
  },
  light3_v2: {
    backgroundColor: '#C99BC9', // pink
    bottom: '-50vw',
    left: '10vw',
    animation: `$light3 ${duration} linear 0s infinite`,
  },
  '@keyframes light1': {
    '0%': {
      transform: 'translate3d(0,0,0)',
      transformStyle: 'preserve-3d',
      willChange: 'transform',
    },
    '50%': {
      transform: 'translate3d(-10vw,-10vw,0)',
      transformStyle: 'preserve-3d',
      willChange: 'transform',
    },
    '100%': {
      transform: 'translate3d(0,0,0)',
      transformStyle: 'preserve-3d',
      willChange: 'transform',
    },
  },
  '@keyframes light2': {
    '0%': {
      transform: 'translate3d(0,0,0)',
      transformStyle: 'preserve-3d',
      willChange: 'transform',
    },
    '50%': {
      transform: 'translate3d(-10vw,5vw,0)',
      transformStyle: 'preserve-3d',
      willChange: 'transform',
    },
    '100%': {
      transform: 'translate3d(0,0,0)',
      transformStyle: 'preserve-3d',
      willChange: 'transform',
    },
  },
  '@keyframes light3': {
    '0%': {
      transform: 'translate3d(0,0,0)',
      transformStyle: 'preserve-3d',
      opacity: 0.4,
      willChange: 'transform',
    },
    '50%': {
      transform: 'translate3d(30vw,0,0)',
      transformStyle: 'preserve-3d',
      opacity: 1,
      willChange: 'transform',
    },
    '100%': {
      transform: 'translate3d(0,0,0)',
      transformStyle: 'preserve-3d',
      opacity: 0.4,
      willChange: 'transform',
    },
  },
}));

interface AnimatedGradientProps {
  version?: 'v1' | 'v2';
  position?: 'fixed' | 'absolute';
}

export const AnimatedGradient = ({
  version = 'v1',
  position = 'fixed',
}: AnimatedGradientProps) => {
  const classes = useStyles();

  return (
    <div className={classes.gradientContainer} style={{ position }}>
      <div className={classes.noise} style={{ position }} />
      {version === 'v1' && (
        <>
          <div className={`${classes.circle} ${classes.light1}`} />
          <div className={`${classes.circle} ${classes.light2}`} />
          <div className={`${classes.circle} ${classes.light3}`} />
        </>
      )}
      {version === 'v2' && (
        <>
          <div className={`${classes.circle} ${classes.light1_v2}`} />
          <div className={`${classes.circle} ${classes.light2_v2}`} />
          <div className={`${classes.circle} ${classes.light3_v2}`} />
        </>
      )}
    </div>
  );
};
