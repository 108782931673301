import React from 'react';
import { observer } from 'mobx-react';
import { Alert, Button, Chip, Color, Flex, Text } from 'component-library';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

export interface Form8974QuarterlyDownloadsProps {
  taxYear: number;
  quarter: number;
  form8974Url: string;
  form941XUrl?: string;
  classes: ClassNameMap<'container' | 'instructions'>;
  current?: boolean;
}

export const Form8974QuarterlyDownloads = observer(
  ({
    taxYear,
    quarter,
    classes,
    form8974Url,
    form941XUrl,
    current,
  }: Form8974QuarterlyDownloadsProps) => {
    return (
      <Flex padding={24}>
        <Flex direction='column' gap={24} className={classes.container}>
          <Flex
            className={classes.instructions}
            direction='column'
            padding={24}
          >
            <Flex gap={16}>
              <Text variant='medium' size={23}>
                {`${taxYear} Q${quarter} - Form 8974`}
              </Text>
              {current && (
                <Chip
                  text='Current quarter to redeem'
                  variant='warning'
                  showIcon={false}
                />
              )}{' '}
            </Flex>
          </Flex>
          <Flex padding={[0, 24, 24, 24]} gap={24}>
            <Button
              label='Download Form 8974'
              onClick={{
                external: true,
                location: form8974Url,
              }}
              variant='outlined'
              dataTestId={`${taxYear}-q${quarter}-form8974-download-button`}
            />
            {!current && form941XUrl && (
              <Button
                label='Download Draft Form 941-X'
                onClick={{
                  external: true,
                  location: form941XUrl,
                }}
                variant='outlined'
                dataTestId={`${taxYear}-q${quarter}-form941x-download-button`}
              />
            )}
          </Flex>
          {current && (
            <Alert
              variant='in_card'
              type='info'
              text="Be sure to complete this step as soon as possible. If you do not do this on time, you'll have to file another amended quarterly payroll tax return for each quarterly deadline you miss."
              backgroundColor={Color.blue._10}
            />
          )}
        </Flex>
      </Flex>
    );
  },
);
