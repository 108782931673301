import * as mobx from 'mobx';

// Counter for unique view identifier
let UNIQUE_VIEW_ID = 0;

/**
 * Generates a unique numeric identifier that can be used as the
 * key argument when iterating through a collection
 */
export const generateUniqueViewId = () => UNIQUE_VIEW_ID++;

/**
 * Override annotation types that may be used with makeSubclassObservable
 */
export const MobxObservableAnnotation = {
  /**
   * Skip all annotations, unobserved value
   */
  NOT_OBSERVED: false,

  /**
   * The default for all class properties, auto infer the annotation type
   */
  INFER_OBSERVATION_TYPE: true,

  /**
   * Marks property as an observed state value
   */
  OBSERVED_STATE: mobx.observable,

  /**
   * For nested objects, marks property as an observed state value,
   * and all of it's properties as well
   */
  OBSERVED_STATE_DEEP: mobx.observable.deep,

  /**
   * Marks function as an action, where 'this' references the object passed
   * in the first parameter of makeSubclassObservable
   */
  ACTION: mobx.action.bound,

  /**
   * Marks function as an action
   */
  LOOSE_ACTION: mobx.action,

  /**
   * Marks function as a computed method, caching the result and only rerunning
   * in cases where the observed states used inside this method change.
   *
   * Note*: This method will only triggered a rerender on observers if the actual
   * value of the computation changes
   */
  COMPUTED: mobx.computed.struct,

  /**
   * Marks function as a computed method, caching the result and only rerunning
   * in cases where the observed states used inside this method change.
   */
  LOOSE_COMPUTED: mobx.computed,

  /**
   * Advanced use only, for overriding an action/computed method of
   * a parent class in a child class. Should rarely be used
   */
  OVERRIDE: mobx.override,
};

type MobxObservableAnnotation = typeof MobxObservableAnnotation[keyof typeof MobxObservableAnnotation];

// Cached referece to the object prototype
const objectPrototype = Object.prototype;

/**
 * Modified from https://gist.github.com/stephenh/77f62941913203a871d0e284ea779fe9
 *
 * Detects all properties/methods on an object/class and converts them into an observables
 *
 * @param target Target object for converting properties into observables
 * @param overrides Override annotation type for specific properties
 * @param options Custom observable options
 * @throws Exception if passing a target that has already been observed
 * @throws Exception if passing a class that overrides one of it's parents properties/methods
 */
export function makeSubclassObservable<
  T extends object,
  AdditionalKeys extends PropertyKey = never
>(
  target: T,
  overrides?: {
    [P in Exclude<keyof T, 'toString'>]?: MobxObservableAnnotation;
  } &
    Record<AdditionalKeys, MobxObservableAnnotation>,
  options?: mobx.CreateObservableOptions,
): T {
  // Make sure nobody called makeObservable/etc. previously (eg in parent constructor)
  if (mobx.isObservable(target)) {
    throw new Error('Target has already been observed');
  }

  // Iterate over the prototype chain and build list of annotations
  const annotations: Record<string | symbol, boolean> = {};
  let current = target;
  while (current && current !== objectPrototype) {
    Reflect.ownKeys(current).forEach((key) => {
      // Skip over constructor and mobx indicator key
      if (key === mobx.$mobx || key === 'constructor') {
        return;
      }
      // Block overrides when using subclassing
      else if (annotations[key as string]) {
        if (
          !overrides ||
          !(key in overrides) ||
          (overrides as any)[key] !== MobxObservableAnnotation.OVERRIDE
        ) {
          throw new Error(
            `Invalid use of '${String(
              key,
            )}', subclasses can not override parent methods/properties`,
          );
        }
      }

      // Default to using auto inference if no override is provided
      annotations[key as string] =
        overrides && key in overrides ? (overrides as any)[key] : true;
    });

    // Go up the prototype chain
    current = Object.getPrototypeOf(current);
  }

  return mobx.makeObservable(target, annotations, options);
}

export function delayRunInAction(milliseconds: number, callback: () => void) {
  setTimeout(() => {
    mobx.runInAction(() => callback());
  }, milliseconds);
}
