import { Dimond } from '../../../../components/icons';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Text } from 'component-library';

const useStyles = makeStyles(() => ({
  icon: {
    width: 24,
    minWidth: 24,
    marginRight: 20,
  },
  footerTextAndIcon: {
    display: 'flex',
  },
}));

export interface ContingencyTextProps {
  text: string | JSX.Element;
  showIcon: boolean;
  className: string;
}

export const ContingencyText = ({
  text,
  showIcon,
  className,
}: ContingencyTextProps) => {
  const classes = useStyles();

  return (
    <div className={classes.footerTextAndIcon}>
      {showIcon ? <Dimond className={classes.icon} /> : <></>}
      {typeof text === 'string' ? (
        <Text size={15} className={`${className}`}>
          {text}
        </Text>
      ) : (
        text
      )}
    </div>
  );
};
