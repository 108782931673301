import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { CardElement } from '@stripe/react-stripe-js';

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #CBCED1',
    borderRadius: '4px',
    margin: '24px 0',
    padding: '19px 24px',
  },
}));

const CARD_OPTIONS = {
  classes: {
    focus: 'focused',
    empty: 'empty',
    invalid: 'invalid',
  },
  style: {
    base: {
      color: '#444',
      fontWeight: '400',
      fontFamily: "'Open Sans', sans-serif",
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#888',
      },
      ':-webkit-autofill': {
        color: '#888',
      },
    },
    invalid: {
      color: '#E25950',

      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
};

export default function CardPayment() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CardElement options={CARD_OPTIONS} />
    </div>
  );
}
