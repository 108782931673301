import React from 'react';
import { Card, Color, Content, Text, Icon, IconEnum } from 'component-library';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, QuestionCardProps>(() => ({
  container: {
    backgroundColor: Color.neutral._light_20,
  },
}));

export interface QuestionCardProps {
  confirmed?: boolean;
  description?: string;
  confirmedMessage?: string | JSX.Element;
  content?: JSX.Element;
}

export const QuestionCard = ({
  confirmed,
  description,
  confirmedMessage,
  content,
}: QuestionCardProps) => {
  const classes = useStyles({});

  return (
    <Card className={classes.container} noMargin={true}>
      <Content
        flex
        justifyContent='space-between'
        paddingTopBottom={16}
        paddingLeftRight={24}
      >
        <Content flex alignItems='center'>
          {confirmed && confirmedMessage ? (
            <Text size={15} color={Color.neutral._80}>
              {confirmedMessage}
            </Text>
          ) : (
            <Text size={15} variant='medium' color={Color.neutral._90}>
              {description}
            </Text>
          )}
        </Content>

        {confirmed ? (
          <Content
            flex
            alignItems='center'
            paddingLeftRight={0}
            paddingTopBottom={0}
            gap={8}
          >
            <Icon color={Color.semantic.$success50} name={IconEnum.check} />
            <Text color={Color.semantic.$success50} size={15} variant='medium'>
              {content}
            </Text>
          </Content>
        ) : (
          content
        )}
      </Content>
    </Card>
  );
};

export default QuestionCard;
