import { Text } from 'component-library';
import { format } from 'date-fns';
import { DocumentDownloadLink } from '../DocumentDownloadLink';
import * as React from 'react';
import { ProgramData } from '../../../../../lib/interfaces';
import { AccountantScope, Programs } from '../../../../../lib/constants';

export const MainStreetDocumentsColumns = (
  classes: {
    cell: string;
    iconSection: string;
  },
  programs: ProgramData[],
  token?: string,
  companyEmail?: string,
  scopeLevel?: AccountantScope,
) => {
  return [
    {
      Header: 'Title',
      accessor: 'name',
      textBold: true,
      Cell: (cell: any) => {
        const program = programs.filter(
          (program) => program.id === cell.data.program_id,
        )[0];

        const programName = program
          ? `${program.taxYear} ${Programs[program.name].display}`
          : 'Tax Credit Document';

        return (
          <div className={classes.cell}>
            <Text
              text={programName}
              size={13}
              textAlign={`left`}
              variant={'medium'}
            />
            <Text
              text={cell.data.name}
              size={13}
              textAlign={`left`}
              variant={'regular'}
            />
          </div>
        );
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
      Cell: (cell: any) => {
        const description =
          cell.data.description.length > 105
            ? `${cell.data.description.substring(0, 105)} ...`
            : cell.data.description;
        return (
          <Text
            className={classes.cell}
            text={description}
            size={13}
            textAlign={`left`}
            variant={'regular'}
          />
        );
      },
    },

    {
      Header: 'Date Created',
      accessor: 'created',
      width: 100,
      Cell: (cell: any) => {
        return (
          <Text
            className={classes.cell}
            text={format(new Date(cell.data.created), 'P')}
            size={13}
            textAlign={`left`}
            variant={'regular'}
          />
        );
      },
    },
    {
      Header: '',
      accessor: 'downloadLink',
      textBold: true,
      width: 25,
      disableSortBy: true,
      Cell: (cell: any) => {
        const programId = cell.data.programId || cell.data.program_id; // Camelcase is desired but snake case is the older convention we still support
        return (
          <DocumentDownloadLink
            id={cell.data.id}
            token={token}
            companyEmail={companyEmail}
            programId={
              scopeLevel === AccountantScope.taxYear ? null : programId
            }
          />
        );
      },
    },
  ];
};
