import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '780px',
    border: '1px solid #FFA800',
  },
  title: {
    background: 'rgba(255, 168, 0, 0.1)',
    padding: '15px',
    fontWeight: 500,
    size: '14px',
  },
  message: {
    padding: '15px 15px 10px 15px',
    size: '14px',
  },
}));

interface ActionRequiredBoxProps {
  title: string;
  message: string;
  children?: React.ReactNode;
}

export const ActionRequiredBox = ({
  title,
  message,
  children,
}: ActionRequiredBoxProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      <div className={classes.message}>{message}</div>
      {children}
    </div>
  );
};
