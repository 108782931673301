import React from 'react';
import { Content, Divider, Text } from 'component-library';

interface EmployeeSideDrawerSectionProps {
  title: string;
  children: JSX.Element;
  gap?: 0 | 8 | 16 | 24 | 32 | 40 | 48;
}

export const EmployeeSideDrawerSection = ({
  title,
  children,
  gap = 16,
}: EmployeeSideDrawerSectionProps) => {
  return (
    <div>
      <Text variant='medium' size={15} text={title} paddingBottom={8} />
      <Divider />
      <Content flexDirection='column' flex gap={gap}>
        {children}
      </Content>
    </div>
  );
};
