import React from 'react';

interface Props {
  className?: string;
}

const TaxCreditsIcon = ({ className }: Props) => (
  <svg
    className={className}
    width='144'
    height='144'
    viewBox='0 0 144 144'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M61.6699 22.77H22.1799V77.39H61.6699V22.77Z' fill='#0A190C' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.6799 22.77C21.6799 22.4938 21.9038 22.27 22.1799 22.27H61.6699C61.9461 22.27 62.1699 22.4938 62.1699 22.77V77.39C62.1699 77.6661 61.9461 77.89 61.6699 77.89H22.1799C21.9038 77.89 21.6799 77.6661 21.6799 77.39V22.77ZM22.6799 23.27V76.89H61.1699V23.27H22.6799Z'
      fill='#0A190C'
    />
    <path d='M27.73 17.22V71.84H67.22V27.54L56.9 17.22H27.73Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.23 17.22C27.23 16.9439 27.4538 16.72 27.73 16.72H56.9C57.0326 16.72 57.1598 16.7727 57.2535 16.8664L67.5735 27.1864C67.6673 27.2802 67.72 27.4074 67.72 27.54V71.84C67.72 72.1161 67.4961 72.34 67.22 72.34H27.73C27.4538 72.34 27.23 72.1161 27.23 71.84V17.22ZM28.23 17.72V71.34H66.72V27.7471L56.6929 17.72H28.23Z'
      fill='#0A190C'
    />
    <path d='M57.54 17.86V26.91L66.58 26.9L57.54 17.86Z' fill='#ACE119' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.3487 17.3981C57.5355 17.3207 57.7506 17.3634 57.8936 17.5064L66.9336 26.5464C67.0765 26.6894 67.1193 26.9043 67.0421 27.0911C66.9648 27.2779 66.7827 27.3998 66.5806 27.4L57.5406 27.41C57.4079 27.4101 57.2806 27.3575 57.1867 27.2637C57.0928 27.17 57.04 27.0427 57.04 26.91V17.86C57.04 17.6578 57.1619 17.4755 57.3487 17.3981ZM58.04 19.0671V26.4094L65.3743 26.4013L58.04 19.0671Z'
      fill='#0A190C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.5399 39.89H37.4099V37.89H57.5399V39.89Z'
      fill='#0A190C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.4699 48.61H37.4099V46.61H47.4699V48.61Z'
      fill='#0A190C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.4699 57.34H37.4099V55.34H47.4699V57.34Z'
      fill='#0A190C'
    />
    <path
      d='M64.7005 53.722L61.9004 56.5221L80.568 75.1897L83.3682 72.3896L64.7005 53.722Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.5469 56.8757C61.3517 56.6804 61.3517 56.3638 61.5469 56.1686L64.3471 53.3684C64.5423 53.1732 64.8589 53.1732 65.0542 53.3684L83.7218 72.0361C83.917 72.2313 83.917 72.5479 83.7218 72.7432L80.9216 75.5433C80.7264 75.7386 80.4098 75.7386 80.2145 75.5433L61.5469 56.8757ZM62.6076 56.5221L80.5681 74.4826L82.6611 72.3896L64.7006 54.4291L62.6076 56.5221Z'
      fill='#0A190C'
    />
    <path
      d='M63.27 65.65C69.1021 65.65 73.83 60.9221 73.83 55.09C73.83 49.2579 69.1021 44.53 63.27 44.53C57.4378 44.53 52.71 49.2579 52.71 55.09C52.71 60.9221 57.4378 65.65 63.27 65.65Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63.27 45.03C57.714 45.03 53.21 49.534 53.21 55.09C53.21 60.646 57.714 65.15 63.27 65.15C68.8259 65.15 73.33 60.646 73.33 55.09C73.33 49.534 68.8259 45.03 63.27 45.03ZM52.21 55.09C52.21 48.9817 57.1617 44.03 63.27 44.03C69.3782 44.03 74.33 48.9817 74.33 55.09C74.33 61.1983 69.3782 66.15 63.27 66.15C57.1617 66.15 52.21 61.1983 52.21 55.09Z'
      fill='#0A190C'
    />
    <path
      d='M76.3749 64.4588L72.6414 68.1924L83.8419 79.3929L87.5754 75.6594L76.3749 64.4588Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M72.2879 68.5459C72.0926 68.3506 72.0926 68.0341 72.2879 67.8388L76.0214 64.1053C76.2167 63.91 76.5332 63.91 76.7285 64.1053L87.9291 75.3058C88.1243 75.5011 88.1243 75.8177 87.9291 76.013L84.1956 79.7465C84.0003 79.9417 83.6837 79.9417 83.4884 79.7465L72.2879 68.5459ZM73.3485 68.1924L83.842 78.6858L86.8684 75.6594L76.375 65.1659L73.3485 68.1924Z'
      fill='#0A190C'
    />
    <path
      d='M62.8599 59.04C61.1699 58.89 60.3699 57.87 60.3499 56.55H61.6299C61.6599 57.57 62.2599 58.08 63.3199 58.08C64.3099 58.08 64.8699 57.57 64.8699 56.83C64.8699 55.97 64.1299 55.81 63.8299 55.73L62.6899 55.47C61.9499 55.3 60.4999 54.96 60.4999 53.35C60.4999 52.11 61.4199 51.26 62.8499 51.15V50.11H63.7299V51.17C65.1299 51.33 65.8999 52.18 65.9199 53.4H64.6599C64.6099 52.56 64.0699 52.12 63.1499 52.12C62.3099 52.12 61.7899 52.55 61.7899 53.2C61.7899 54 62.5199 54.2 62.9499 54.3L64.1899 54.56C65.1799 54.77 66.1699 55.33 66.1699 56.74C66.1699 58.07 65.2299 58.92 63.7199 59.05V60.11H62.8399V59.07L62.8599 59.04Z'
      fill='black'
    />
  </svg>
);

export default TaxCreditsIcon;
