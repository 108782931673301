import React from 'react';
import { observer } from 'mobx-react';
import { Button, Flex, Text } from 'component-library';
import Coins from 'components/icons/Coins';

interface Props {
  onComplete: () => void;
}

export const FinishedStepIncomeTax = observer(({ onComplete }: Props) => {
  return (
    <Flex direction='column' alignItems='center' padding={[16, 48, 48, 48]}>
      <Flex direction='column' alignItems='center' padding={[0, 0, 16, 0]}>
        <Coins smallerCoinPlus />
      </Flex>
      <Text
        variant='medium'
        textAlign='center'
        text={'Give yourself some income credit!'}
        paddingBottom={8}
      />
      <Text
        textAlign='center'
        text={`When you filed your taxes, your credits were redeemed reducing your income tax liability. If there was any balance, it will be carried forward.`}
        paddingBottom={8}
      />
      <Flex direction='column' alignItems='center' padding={16}>
        <Button
          small
          label={'Done'}
          onClick={() => onComplete()}
          dataTestId='redeeming-done-button'
        />
      </Flex>
    </Flex>
  );
});
