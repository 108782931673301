import React, { useContext, useEffect, useState } from 'react';
import { FormHelperText, makeStyles, Theme } from '@material-ui/core';
import { Button, Card, Color, Content } from 'component-library';
import { EmployeeForEmployeeTable } from 'lib/interfaces';
import { GetEmployeeTableData } from 'services/server';
import { Loading } from 'components/util/Loading';
import { EmployeeTokenPurposeEnum } from '../../../lib/constants';
import EmployeeDashboardTable from './EmployeeDashboardTable';
import { CompanyContext } from '../../CompanyRequired';

const useStyles = makeStyles(
  ({
    darkerNavy,
    boxBackgroundColor,
    lighterGrey,
    darkBlue,
    emerald,
    yellow,
    lightBlue,
  }: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: '40px 80px',
      maxWidth: '1050px',
    },
    title: {
      fontFamily: "'Alliance Platt', serif",
      margin: '20px 0 30px',
      fontSize: '50px',
      color: Color.neutral._80,
    },
    subtitle: {
      fontSize: '20px',
      lineHeight: '30px',
      marginBottom: '30px',
      color: darkerNavy,
      maxWidth: '800px',
    },
    notification: {
      marginBottom: '30px',
    },
    instructionCard: {
      width: '33%',
      '& a': {
        color: darkerNavy,
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
    cardCircle: {
      width: '48px',
      marginBottom: '20px',
    },
    cardTitle: {
      marginBottom: '10px',
      fontWeight: 450,
    },
    cardSubtitle: {
      marginBottom: '0',
    },
    reimbursementTotal: {
      height: '100px',
      verticalAlign: 'middle',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      fontSize: '32px',
      fontWeight: 450,
    },
    reimbursementSubcardContainer: {
      display: 'flex',
    },
    reimbursementAverage: {
      flex: 1,
      marginRight: '7px',
      height: '123px',
      border: '1.5px #9E9E9E solid',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      fontWeight: 450,
    },
    reimbursementSavings: {
      flex: 1,
      marginLeft: '7px',
      height: '123px',
      border: '1px #9E9E9E solid',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      fontWeight: 450,
    },
    subcardCopy: {
      marginTop: '5px',
      fontSize: '14px',
      fontWeight: 300,
    },
    employeesTable: {
      backgroundColor: boxBackgroundColor,
      borderSpacing: 0,

      '& thead': {
        backgroundColor: '#fcfcf5',
      },

      '& tr': {
        display: 'grid',
        gridTemplateColumns: '1.5fr 1.75fr 1fr 1fr 1fr 1fr',
        borderBottom: '1px solid' + lighterGrey,
      },

      '& thead th': {
        padding: '28px 20px 15px',
        fontSize: '16px',
        fontWeight: 500,
        display: 'block',
        textAlign: 'left',
        '&:not(:last-child)': {
          borderRight: '1px solid ' + lighterGrey,
        },
      },
      // Employee icon colors
      '& tbody tr:nth-child(4n+1) td:first-child div div': {
        backgroundColor: yellow,
      },
      '& tbody tr:nth-child(4n+2) td:first-child div div': {
        backgroundColor: emerald,
        color: '#FFFFFF',
      },
      '& tbody tr:nth-child(4n+3) td:first-child div div': {
        backgroundColor: darkBlue,
        color: '#FFFFFF',
      },
      '& tbody tr:nth-child(4n+4) td:first-child div div': {
        backgroundColor: lightBlue,
      },
      '& tbody td': {
        display: 'flex',
        alignItems: 'center',
        minWidth: 0,
        padding: '30px 20px',

        '& > div': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    subheader: {
      fontSize: '10px',
      textTransform: 'uppercase',
      marginTop: '10px',
    },
    faqContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    faqIcon: {
      width: '64px',
      marginRight: '20px',
    },
    faqCopyContainer: {
      marginRight: '20px',
    },
    faqTitle: {
      fontSize: '20px',
      fontWeight: 500,
      marginBottom: '10px',
    },
    faqDescription: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    faqButton: {
      flexShrink: 0,
    },
    inviteButton: {
      textAlign: 'center',
      margin: '20px 0',
      marginRight: '20px',
      width: '180px',
      padding: '0 40px',
      height: '50px',
      fontSize: '18px',
      fontWeight: 500,
      textTransform: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
  }),
);

// Copy
const pageTitle = 'Section 139 employee reimbursements';
const pageSubtitle =
  'Reimbursements per employee will be applied based on the budget you’ve provided. There is no detailed receipt tracking required.';

interface PageProps {
  qualificationTaxYear: number;
}

export const EmployeesS139Dashboard = ({ qualificationTaxYear }: PageProps) => {
  const { company } = useContext(CompanyContext);
  const classes = useStyles();

  const [employeesTableData, setEmployeesTableData] = useState<
    EmployeeForEmployeeTable[]
  >([]);
  const [employeesTableDataFetched, setEmployeesTableDataFetched] = useState<
    boolean
  >(false);
  const [pageError, setPageError] = useState<string>('');

  useEffect(() => {
    GetEmployeeTableData(
      company.id,
      qualificationTaxYear,
      EmployeeTokenPurposeEnum.S139,
    ).then((res) => {
      if (!res.data) {
        setPageError(res.errorMsg || 'Something went wrong');
        setEmployeesTableDataFetched(true);
        return;
      }
      const employees = res.data.employees;
      setEmployeesTableData(employees);

      setEmployeesTableDataFetched(true);
    });
  }, [company.id, qualificationTaxYear]);

  return (
    <div className={classes.root}>
      <div className={classes.title}>{pageTitle}</div>
      <div className={classes.subtitle}>{pageSubtitle}</div>
      <Loading loading={!employeesTableDataFetched}>
        <EmployeeDashboardTable employees={employeesTableData} />
        <Button
          className={classes.inviteButton}
          label={`Invite (${employeesTableData.length})`}
          onClick={() => {
            console.log('invite clicked'); // ToDo: Hook up to same service as SendOptInEmailToEmployees
          }}
        />
      </Loading>

      {pageError && <FormHelperText error>{pageError}</FormHelperText>}

      <Card>
        <Content className={classes.faqContainer}>
          <img
            className={classes.faqIcon}
            src={`${process.env.PUBLIC_URL}/images/paper-plane-icon.svg`}
            alt='Paper Plane Icon'
          />
          <div className={classes.faqCopyContainer}>
            <div className={classes.faqTitle}>Need help?</div>
            <div className={classes.faqDescription}>
              Learn more about the Section 139 benefit from MainStreet
            </div>
          </div>
          <Button
            className={classes.faqButton}
            label='View FAQs'
            onClick={() => {
              window.location.href =
                'https://mainstreet-help.force.com/help/s/';
            }}
          />
        </Content>
      </Card>
    </div>
  );
};
