import {
  ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
  AccreditationStatusEnum,
  GraphCmsQuestionIdEnum,
  ProgramNameEnum,
  SignupStageEnum,
  SignupTypeEnum,
} from 'lib/constants';
import {
  AccountCreationSteps,
  AdminName,
  BillingScheduleData,
  CompanyData,
  EmploymentRecordData,
  ExpectedFilingDates,
  FinancialInformation,
  IssuedSurvey,
  MiscCompanyInformation,
  OrderForm,
  Plan,
  ProgramData,
  QualificationQuestionsByYear,
  SignupStage,
  SignupType,
} from 'lib/interfaces';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { runInAction } from 'mobx';
import { BaseEntity } from './BaseEntity';
import { IsDisplayableOrderForm } from 'lib/helpers';

export class Company extends BaseEntity {
  public isLoaded = false;
  public rawData: CompanyData | null = null;

  public accreditationStatus: AccreditationStatusEnum | null = null;
  public adminEmail = '';
  public adminJobTitle = '';
  public adminName: AdminName = { first: '', last: '' };
  public auth0OrganizationId = '';

  // PLAT-18 TODO: Refactor Or Delete - should separate dependency on ORM Entities
  /**
   * @deprecated
   * added/last touched in https://github.com/MainStreet-Work/monorepo/issues/6043
   */
  public billingSchedules: BillingScheduleData[] = [];
  public businessType = '';
  public businessPhoneNumber = '';
  public codatCustomerId: string | null = null;
  public created: string | null = null;
  public description: string | null = null;
  public emailVerified = false;
  public employmentRecords: EmploymentRecordData[] = [];
  public expectedFilingDates: ExpectedFilingDates | null = null;
  public financialInformation: FinancialInformation = {
    grossReceipts: [],
    qualifiedResearchExpenses: [],
  };
  public fiscalYearEndDate: string | null = null;
  public id = 0;
  public industry = '';
  public issuedSurveys: IssuedSurvey[] | null = null;
  public lastUpdated: number | null = null;
  public legalName = '';
  public linkedAccountingSystem = false;
  public linkedPayrollSystem = false;
  public logoFileName: string | null = null;
  public logoUrl = '';
  public misc: MiscCompanyInformation | null = null;
  public name = '';
  public numEmployees = 0;
  public orderForms: OrderForm[] = [];
  public paymentMethodOnFile = false;
  public plan: Plan = 'rd_percentage';
  public planPercentage = 0;
  public programs: ProgramData[] = [];
  public qualificationQuestionsByYear: QualificationQuestionsByYear | null =
    null;
  public secondaryEmail = '';
  public signupStage: SignupStage = SignupStageEnum.NA;
  public signupType: SignupType = SignupTypeEnum.SELF_SIGNUP;
  public stripeConnectedAccountId: string | null = null;
  public stripeCustomerId: string | null = null;
  public taxId: string | null = null;
  public taxType = '';
  public testAccount = false;
  public treasuryManagementEnabled = false;
  public website = '';
  public yearFounded: number | null = null;

  constructor(company?: CompanyData) {
    super();
    makeSubclassObservable(this);

    if (company) {
      this.mergeData(company);
    }
  }

  public clearData() {
    this.mergeData(null);
  }

  public mergeData(company: CompanyData | null) {
    runInAction(() => {
      this.isLoaded = !!company;
      this.rawData = company || null;
      this.accreditationStatus = company?.accreditationStatus || null;
      this.adminEmail = company?.adminEmail || '';
      this.adminJobTitle = company?.adminJobTitle || '';
      this.adminName = company?.adminName || { first: '', last: '' };
      this.auth0OrganizationId = company?.auth0OrganizationId || '';
      this.billingSchedules = company?.billingSchedules || [];
      this.businessType = company?.businessType || '';
      this.businessPhoneNumber = company?.businessPhoneNumber || '';
      this.created = company?.created || null;
      this.codatCustomerId = company?.codatCustomerId || '';
      this.description = company?.description || null;
      this.emailVerified = company?.emailVerified || false;
      this.employmentRecords = company?.employmentRecords || [];
      this.expectedFilingDates = company?.expectedFilingDates || null;
      this.financialInformation = company?.financialInformation || {
        grossReceipts: [],
        qualifiedResearchExpenses: [],
      };
      this.fiscalYearEndDate = company?.fiscalYearEndDate || null;
      this.id = company?.id || 0;
      this.industry = company?.industry || '';
      this.issuedSurveys = company?.issuedSurveys || null;
      this.lastUpdated = company?.lastUpdated || null;
      this.legalName = company?.legalName || '';
      this.linkedAccountingSystem = company?.linkedAccountingSystem || false;
      this.linkedPayrollSystem = company?.linkedPayrollSystem || false;
      this.logoFileName = company?.logoFileName || null;
      this.logoUrl = company?.logoUrl || '';
      this.misc = company?.misc || null;
      this.name = company?.name || '';
      this.numEmployees = company?.numEmployees || 0;
      this.orderForms =
        company?.orderForms?.filter(IsDisplayableOrderForm) || [];
      this.paymentMethodOnFile = company?.paymentMethodOnFile || false;
      this.plan = company?.plan || 'rd_percentage';
      this.planPercentage = company?.planPercentage || 0;
      this.programs = company?.programs || [];
      this.qualificationQuestionsByYear =
        company?.qualificationQuestionsByYear || null;
      this.secondaryEmail = company?.secondaryEmail || '';
      this.signupStage = company?.signupStage || SignupStageEnum.NA;
      this.signupType = company?.signupType || SignupTypeEnum.SELF_SIGNUP;
      this.stripeConnectedAccountId = company?.stripeConnectedAccountId || null;
      this.stripeCustomerId = company?.stripeCustomerId || null;
      this.taxId = company?.taxId || null;
      this.taxType = company?.taxType || '';
      this.testAccount = company?.testAccount || false;
      this.treasuryManagementEnabled =
        company?.treasuryManagementEnabled || false;
      this.website = company?.website || '';
      this.yearFounded = company?.yearFounded || null;
    });
  }

  public get shouldShowEmployeeNav() {
    return !!this.orderForms?.find(
      (program) => program.programName === ProgramNameEnum.PAID_SICK_LEAVE,
    );
  }

  public accountCreationStep(unified?: boolean): AccountCreationSteps | null {
    const answeredBusinessStep =
      this.qualificationQuestionsByYear &&
      this.qualificationQuestionsByYear[ACCOUNT_CREATION_QUALIFYING_TAX_YEAR][
        GraphCmsQuestionIdEnum.EXPECT_SOMEONE_TO_OWN_ANOTHER_COMPANY
      ];

    const answeredTaxesStep =
      this.qualificationQuestionsByYear &&
      this.qualificationQuestionsByYear[ACCOUNT_CREATION_QUALIFYING_TAX_YEAR][
        GraphCmsQuestionIdEnum.WHAT_MONTH_FILE_TAXES
      ];

    const answeredPayrollStep =
      this.qualificationQuestionsByYear &&
      this.qualificationQuestionsByYear[ACCOUNT_CREATION_QUALIFYING_TAX_YEAR][
        GraphCmsQuestionIdEnum.OWE_FED_TAXES_THIS_YEAR
      ];

    const answeredEstimatesStep =
      this.qualificationQuestionsByYear &&
      this.qualificationQuestionsByYear[ACCOUNT_CREATION_QUALIFYING_TAX_YEAR][
        GraphCmsQuestionIdEnum.DO_ALL_STATEMENTS_APPLY
      ];

    if (unified) {
      if (answeredEstimatesStep) {
        return AccountCreationSteps.ESTIMATES;
      } else if (answeredPayrollStep) {
        return AccountCreationSteps.BUSINESS_ACTIVITIES;
      } else if (answeredTaxesStep) {
        return AccountCreationSteps.PAYROLL_DATA;
      } else if (answeredBusinessStep) {
        return AccountCreationSteps.TAXES;
      }
    } else {
      if (
        this.signupStage === SignupStageEnum.FINISHED ||
        this.businessPhoneNumber
      ) {
        return AccountCreationSteps.TAXES;
      } else if (this.legalName) {
        return AccountCreationSteps.CONTACTS;
      }
    }

    return null;
  }
}
