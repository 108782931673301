import { Alert } from 'component-library';
import { Page } from 'lib/constants';
import * as React from 'react';
import { PayrollGapReason } from 'pages/dashboard/integrations/components/payrollIntegration/PayrollGap';
import { makeStyles } from '@material-ui/core';

const getAlert = (
  payrollGapReason: PayrollGapReason,
  programId: number,
): JSX.Element | null => {
  switch (payrollGapReason) {
    case PayrollGapReason.CHANGED_PROVIDERS:
      return (
        <Alert
          text={
            'We are connecting your payroll system. This process may take up to 20 minutes and you will not be notified when it is complete; enjoy a coffee break then return to your dashboard to complete expense classification!'
          }
        />
      );
    case PayrollGapReason.DIDNT_ISSUE_PAYMENTS:
      return (
        <Alert
          text={'You can now resume the expense classification process'}
          actions={{
            href: `/${Page.expenseClassificationOverview}/${programId}`,
            text: 'Resume expense classification',
          }}
          type={'success'}
        />
      );
    case PayrollGapReason.DIDNT_USE_A_PAYROLL_SYSTEM:
      return (
        <Alert
          text={
            'You can now resume the expense classification process and add your missing data manually.'
          }
          actions={{
            href: `/${Page.expenseClassificationOverview}/${programId}`,
            text: 'Resume expense classification',
          }}
          type={'success'}
        />
      );
    // case PayrollGapReason.OTHER:
    //   return (
    //     <Alert
    //       text={
    //         'You can resume the expense classification process. If there are missing employees, please add them manually or reach out to support@mainstreet.com for assistance.'
    //       }
    //       actions={{
    //         href: `/${Page.expenseClassificationOverview}/${programId}`,
    //         text: 'Resume expense classification',
    //       }}
    //       type={'success'}
    //     />
    //   );
    // COREX-572: Remove 'Other' keeping this as commented out so we have a reference for answer_id if we need to revert
    default:
      return null;
  }
};

const useStyles = makeStyles(() => ({
  root: {
    width: '780px',
  },
}));

interface PayrollGapPostAnswerAlertMessageProps {
  payrollGapReason: PayrollGapReason;
  programId: number;
}

export const PayrollGapPostAnswerAlertMessage = ({
  payrollGapReason,
  programId,
}: PayrollGapPostAnswerAlertMessageProps) => {
  const classes = useStyles();
  const alert = getAlert(payrollGapReason, programId);

  return alert ? <div className={classes.root}>{alert}</div> : <></>;
};
