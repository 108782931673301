import queryString, { ParsedQuery } from 'query-string';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from './RootStore';
import { History, Path, UnregisterCallback } from 'history';
import { runInAction } from 'mobx';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HistoryState {}

export class HistoryStore {
  private unregisterListener?: UnregisterCallback;
  public rawHistory?: History<HistoryState>;
  public untrackedQuery: ParsedQuery = {};

  public rootStore: RootStore;
  public matchedParams: Record<string, string> = {};
  public pathname = '';
  public search = '';
  public query: ParsedQuery = {};
  public state: HistoryState = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeSubclassObservable(this, {
      unregisterListener: false,
      rawHistory: false,
      untrackedQuery: false,
    });
  }

  public push(path: Path, state?: HistoryState) {
    this.rawHistory?.push(path, state);
    this.updateLocationRefs();
  }

  public replace(path: Path, state?: HistoryState) {
    this.rawHistory?.replace(path, state);
    this.updateLocationRefs();
  }

  public setMatchedParams(matchedParams: Record<string, string>) {
    runInAction(() => (this.matchedParams = matchedParams));
  }

  public updateLocationRefs() {
    // Safety net to prevent double parsing
    if (
      this.rawHistory &&
      this.pathname === this.rawHistory.location.pathname &&
      this.search === this.rawHistory.location.search
    ) {
      return;
    }

    // Need an untracked query state to prevent repeated renders in RouteRenderer
    this.untrackedQuery = queryString.parse(
      this.rawHistory
        ? this.rawHistory.location.search
        : window.location.search,
    );

    runInAction(() => {
      this.query = this.untrackedQuery;

      if (this.rawHistory) {
        this.pathname = this.rawHistory.location.pathname;
        this.search = this.rawHistory.location.search;
        this.state = this.rawHistory.location.state || {};
      } else {
        this.pathname = window.location.pathname;
        this.search = window.location.search;
        this.state = {};
      }
    });
  }

  /**
   * @deprecated Should only be used within the store context,
   * for updating reference to the current history object
   */
  public setRawHistory(rawHistory: History<HistoryState>) {
    if (this.unregisterListener) {
      this.unregisterListener();
    }

    this.rawHistory = rawHistory;
    this.updateLocationRefs();
    this.unregisterListener = this.rawHistory.listen(() =>
      this.updateLocationRefs(),
    );
  }
}
