import React, { createRef, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { scrollToRef } from 'components/util/ScrollUtils';

const useStyles = makeStyles(() => ({
  ref: {
    padding: '20px 0',
    maxWidth: '900px',
  },
  title: {
    textTransform: 'uppercase',
    color: '#333',
    border: '1px dashed rgba(0, 0, 0, 0.2)',
    padding: '20px 0',
    borderLeft: 'none',
    borderRight: 'none',
    letterSpacing: '0.08em',
  },
}));

export interface JobGroupHeaderProps {
  jobGroup: string;
  scrollOnInitialRender: boolean;
}

export const JobGroupHeader = ({
  jobGroup,
  scrollOnInitialRender,
}: JobGroupHeaderProps) => {
  const classes = useStyles();

  const ref = createRef<HTMLDivElement>();
  useEffect(() => {
    if (scrollOnInitialRender) {
      scrollToRef(ref);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={ref} className={classes.ref}>
      <Typography component='div' className={classes.title}>
        {jobGroup}
      </Typography>
    </div>
  );
};
