import { createContext } from 'react';
import { ServerClient } from '../../services/ServerClient';
import MSPlaidClient from '../../services/financial/clients/MSPlaidClient';
import MSPaymentsClient from '../../services/financial/clients/MSPaymentsClient';

export interface Auth0Feature {
  client: ServerClient;
  paymentsClient: MSPaymentsClient;
  plaidClient: MSPlaidClient;
}

// TODO: Deprecate Auth0FeatureContext once more modules are converted to stores
export const Auth0FeatureContext = createContext<Auth0Feature>({
  client: new ServerClient(),
  paymentsClient: new MSPaymentsClient(),
  plaidClient: new MSPlaidClient(),
} as Auth0Feature);
