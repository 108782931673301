import * as React from 'react';
import { makeStyles, CircularProgress, Typography } from '@material-ui/core';
import { CircularProgressProps } from '@material-ui/core/CircularProgress/CircularProgress';

const useStyles = makeStyles(() => ({
  iconSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '60px',
  },
  iconTitle: {
    margin: '10px 0',
  },
}));

interface Props {
  loadingText?: string;
  circularProgressProps?: Partial<CircularProgressProps>;
}

export default function LoadingWidget({
  loadingText,
  circularProgressProps,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.iconSection} data-testid='loading-widget'>
      <CircularProgress color='primary' {...circularProgressProps} />
      {loadingText && (
        <Typography component='div' variant='h3' className={classes.iconTitle}>
          {loadingText}
        </Typography>
      )}
    </div>
  );
}
