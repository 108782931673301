import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { useDashboardStores, useCommonStores } from 'stores/useStores';
import { Animate, Button, Color, Flex, Text } from 'component-library';
import { NextButton } from './NextButton';
import { easeInOutQuart } from './BannerCarousel';
import { EducationalOnboardingSteps } from 'lib/constants';

const useStyles = makeStyles(({ breakpoints }) => ({
  footer: {
    marginTop: 'auto',
    borderTop: `2px solid ${Color.neutral._20}`,
    backgroundColor: Color.neutral.white,
    [breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
    },
  },
  inner: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '12px 24px',
  },
  progressiveBorder: (activeIndex: number) => ({
    width: `${(100 / 5) * activeIndex}%`,
    height: '2px',
    position: 'absolute',
    top: '-2px',
    left: 0,
    backgroundColor: Color.lime._60,
    transition: `width .6s ${easeInOutQuart}`,
  }),
}));

export const FooterNav: React.FC = observer(() => {
  const { modules } = useDashboardStores();
  const { app } = useCommonStores();
  const activeIndex = modules.educationalCurrentIndex;
  const classes = useStyles(activeIndex);
  const isLastStep =
    modules.educationalCurrentIndex === EducationalOnboardingSteps.USE_CREDITS;

  return (
    <Animate className={classes.footer} enter={['fade-in', 'from-bottom']}>
      <div className={classes.progressiveBorder} />
      <Flex
        className={classes.inner}
        justifyContent='space-between'
        alignItems='center'
      >
        <Button
          variant='outlined'
          label='Back'
          onClick={() =>
            modules.setEducationalIndex(modules.educationalCurrentIndex - 1)
          }
          dataTestId='educational-module-back-btn'
        />
        {!app.isMobile && (
          <Text
            text={`Step ${activeIndex} of ${EducationalOnboardingSteps.USE_CREDITS}`}
          />
        )}
        <NextButton isFinishedRedirectUrl={isLastStep ? '/' : undefined} />
      </Flex>
    </Animate>
  );
});
