import { SurveyNameEnum } from '../lib/constants';
import { useState, useEffect, useContext } from 'react';
import { CmsQuestionData } from './interfaces';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';

export default function useGetSurveyQuestionsBySurveyName(
  surveyName: SurveyNameEnum,
) {
  const [qualificationQuestions, setQualificationQuestions] = useState<
    Record<string, CmsQuestionData>
  >({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  const { client } = useContext(Auth0FeatureContext);

  useEffect(() => {
    client
      .GetSurveyQuestionsBySurveyName(surveyName)
      .then((res) => {
        if (res.errorMsg) {
          setErrorMsg(res.errorMsg);
          return;
        }
        const questionData = res.data?.questionData;

        if (questionData) {
          setQualificationQuestions(questionData);
        }
      })
      .finally(() => setIsLoading(false));
  }, [surveyName, client]);

  return { qualificationQuestions, isLoading, errorMsg };
}
