import { Text } from 'component-library';
import { format } from 'date-fns';
import { DocumentDownloadLink } from '../DocumentDownloadLink';
import * as React from 'react';

export const ClientDocumentsColumns = (
  classes: {
    cell: string;
    iconSection: string;
  },
  token?: string,
  companyEmail?: string,
) => {
  return [
    {
      Header: 'Title',
      accessor: 'name',
      textBold: true,
      Cell: (cell: any) => {
        return (
          <Text
            className={classes.cell}
            text={cell.data.name}
            size={15}
            textAlign={`left`}
            variant={'medium'}
          />
        );
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
      Cell: (cell: any) => {
        const description =
          cell.data.description?.length > 105
            ? `${cell.data.description.substring(0, 105)} ...`
            : cell.data.description;
        return (
          <Text
            className={classes.cell}
            text={description}
            size={15}
            textAlign={`left`}
            variant={'regular'}
          />
        );
      },
    },
    {
      Header: 'Date Uploaded',
      accessor: 'created',
      width: 100,
      Cell: (cell: any) => {
        return (
          <Text
            className={classes.cell}
            text={format(new Date(cell.data.created), 'P')}
            size={15}
            textAlign={`left`}
            variant={'regular'}
          />
        );
      },
    },
    {
      Header: '',
      accessor: 'downloadLink',
      textBold: true,
      width: 25,
      disableSortBy: true,
      Cell: (cell: any) => {
        return (
          <DocumentDownloadLink
            id={cell.data.id}
            token={token}
            companyEmail={companyEmail}
            programId={cell.data.programId}
          />
        );
      },
    },
  ];
};
