import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Color, Flex, Heading, Image, Text } from 'component-library';
import { useCommonStores } from 'stores/useStores';
import { Page } from 'lib/constants';

const useStyles = makeStyles(({ breakpoints }) => ({
  footerSection: {
    backgroundColor: Color.neutral._90,
    borderBottom: `3px solid ${Color.lime._60}`,
  },
  footerSectionInner: {
    width: 'calc(100% - 48px)',
    maxWidth: '1024px',
    margin: '0 auto',

    [breakpoints.down('xs')]: {
      margin: 0,
      width: '100%',
    },
  },
  footerLinks: {
    '& a': {
      color: Color.neutral._60,
      textDecoration: 'none',
    },
    '& a:hover': {
      color: Color.neutral._50,
    },
  },
}));

interface FooterSectionProps {
  qbServiceAgreement?: JSX.Element;
}

export const FooterSection: React.FC<FooterSectionProps> = observer(
  ({ qbServiceAgreement }) => {
    const classes = useStyles();
    const { app } = useCommonStores();

    return (
      <Flex
        justifyContent={app.isTablet ? 'flex-start' : 'center'}
        className={classes.footerSection}
        padding={app.isTablet ? [40, 24, 0, 24] : [80, 24, 0, 24]}
      >
        <Flex
          className={classes.footerSectionInner}
          direction='column'
          gap={app.isTablet ? 40 : 80}
        >
          <Flex justifyContent='space-between' gap={24}>
            <Flex direction='column' basis={440} gap={32}>
              <Flex.Cell>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/logo/mst-logomark-light.svg`}
                  width={app.isTablet ? 64 : 100}
                />
              </Flex.Cell>
              <Heading
                size={app.isTablet ? 32 : 40}
                marginBottom={0}
                color={Color.neutral.white}
                variant='medium'
              >
                We&apos;ve got the time to save you&nbsp;money.
              </Heading>
            </Flex>
          </Flex>
          <Flex
            padding={app.isTablet ? [24, 0] : [32, 0]}
            alignItems='center'
            justifyContent={app.isTablet ? 'center' : 'space-between'}
            direction={app.isTablet ? 'column' : 'row'}
            gap={app.isTablet ? 8 : 0}
          >
            <Flex justifyContent='flex-start'>
              <Text size={11} color={Color.neutral._60}>
                &#169; 2024 MainStreet Work, Inc. All rights reserved.
              </Text>
            </Flex>
            <Flex
              gap={app.isTablet ? 12 : 24}
              alignItems='center'
              justifyContent={app.isTablet ? 'flex-start' : 'flex-end'}
              className={classes.footerLinks}
            >
              <Text size={app.isTablet ? 11 : 13}>
                <a href={`/${Page.privacy}`} target='_blank' rel='noreferrer'>
                  Privacy Policy
                </a>
              </Text>
              <Text size={app.isTablet ? 11 : 13}>
                <a href={`/${Page.tos}`} target='_blank' rel='noreferrer'>
                  Terms of Service
                </a>
              </Text>
            </Flex>
          </Flex>
          {qbServiceAgreement && qbServiceAgreement}
        </Flex>
      </Flex>
    );
  },
);
