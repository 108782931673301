import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Animate,
  Button,
  Card,
  Checkbox,
  Color,
  Divider,
  Expandable,
  FileHandle,
  FileUpload,
  Flex,
  Heading,
  Icon,
  IconEnum,
  Image,
  Message,
  Modal,
  SideDrawer,
  Spinner,
  Text,
  TextField,
  Toast,
} from 'component-library';
import { ActivityTimelineSideDrawer } from './ActivityTimelineSideDrawer';
import { useFeatureFlags, useTaxCreditsStores } from 'stores/useStores';
import CheckmarkAnimated from 'components/icons/CheckmarkAnimated';
import { IsValidDate, useEffectOnce } from 'lib/helpers';
import { FilingDateTextField } from '../../redemption/components';
import { CompanyContext } from 'pages/CompanyRequired';
import {
  FileTypes,
  ProgramNameEnum,
  ProgramStageEnum,
  StatePrograms,
} from 'lib/constants';
import { IsValidEinNumber } from 'pages/kyc/components/helpers/util';
import { useDocumentAccessLink } from 'lib/useDocumentAccessLink';
import { CopyToClipboard } from 'lib/copyToClipboard';
import { ViewAllTaxFormsSideDrawer } from './ViewAllTaxFormsSideDrawer';
import moment from 'moment';
import { Loading } from '../../../../../components/util/Loading';

const useStyles = makeStyles(() => ({
  title: {
    whiteSpace: 'nowrap',
  },
  timelineBtn: {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
  },
  filingInstructions: {
    backgroundColor: Color.neutral._10,

    '& ol': {
      paddingLeft: 12,
      fontSize: '13px',
    },
    '& li': {
      paddingBottom: '8px',
    },
  },
  checkmark: {
    width: 20,
    height: 20,
  },
  confirmCheckbox: {
    maxWidth: '95%',
  },
  filingDateContainer: {
    border: `1px solid ${Color.neutral._20}`,
  },
  copiedLink: {
    border: `1px solid ${Color.green._60}`,
  },
  copyLink: {},
}));

interface TimeToFileStageProps {
  taxYear: number;
}

export const TimeToFileStage = observer(({ taxYear }: TimeToFileStageProps) => {
  const classes = useStyles();
  const { company } = useContext(CompanyContext);
  const { unifiedTaxCredits, form8974 } = useTaxCreditsStores();
  const [taxFilingDate, setTaxFilingDate] = useState('');
  const [EIN, setEIN] = useState(company.taxId);
  const [toast, setToast] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const featureFlags = useFeatureFlags();

  const otherCreditPrograms = company.programs.filter(
    (p) =>
      p.name !== ProgramNameEnum.FED_RD_TAX &&
      p.stage !== ProgramStageEnum.DISQUALIFIED &&
      !StatePrograms.includes(p.name) &&
      p.taxYear === taxYear,
  );

  const federalRDPrograms = company.programs.filter(
    (p) => p.name === ProgramNameEnum.FED_RD_TAX,
  );

  const taxYearFedRDProgram = federalRDPrograms.find(
    (p) => p.taxYear === taxYear,
  );

  useEffectOnce(async () => {
    await form8974.fetchCompanyPayrollProvider(company.id);
    await form8974.getRDCreditSummary();
    await form8974.refreshPrograms();

    await unifiedTaxCredits.getTaxYearDocuments(taxYear);

    const filingDate = taxYearFedRDProgram?.taxFilingDate
      ? taxYearFedRDProgram.taxFilingDate
      : '';

    if (filingDate.length > 0) {
      const timezoneFix = new Date(filingDate)
        .toISOString()
        .replace('.000Z', '');

      const formattedFilingDate = moment(timezoneFix).format('MM/DD/YYYY');
      setTaxFilingDate(formattedFilingDate);
      form8974.setTaxFilingDate(formattedFilingDate); // Set tax filing date on form8974
    }

    if (company.taxId) {
      setEIN(company.taxId);
      form8974.setCompanyTaxId(company.taxId); // Set EIN on form8974
    }

    setIsLoading(false);
  });

  if (taxYearFedRDProgram) {
    const { accessLink, accessLinkError } = useDocumentAccessLink(
      company.id,
      taxYearFedRDProgram.id,
      taxYear,
    );

    const handleUpdateEIN = (value: string) => {
      setEIN(value);
      form8974.setCompanyTaxId(value);
    };

    const handleOnCopyLink = () => {
      if (accessLinkError) {
        setToast([
          {
            text: 'There was an error while copying your link. Please try again.',
            status: 'error',
          },
        ]);
      }
      if (accessLink) {
        const onCopied = () => {
          unifiedTaxCredits.setLinkCopied(true);
          setToast([
            {
              text: 'Link, copied!',
              status: 'success',
            },
          ]);
        };
        CopyToClipboard(accessLink, onCopied);
      }
    };

    const filingDate = new Date(taxFilingDate);
    const currentDate = new Date();

    const filingDateError =
      !IsValidDate(taxFilingDate) ||
      !taxFilingDate ||
      ![
        taxYearFedRDProgram.taxYear + 1,
        taxYearFedRDProgram.taxYear + 2,
      ].includes(filingDate.getFullYear()) ||
      filingDate > currentDate;

    const disableContinue =
      (!featureFlags.filingIgnoreTimeCheck ? filingDateError : false) ||
      !IsValidEinNumber(EIN ?? '') ||
      !form8974.filesExistInUploadQueue;

    const handleContinue = async () => {
      unifiedTaxCredits.setLoadingClientReview(true);

      // if fed r&d program is disqualified, use next qualifying program.id
      const isFedRDprogramDq =
        taxYearFedRDProgram.stage === ProgramStageEnum.DISQUALIFIED;
      if (isFedRDprogramDq) {
        if (otherCreditPrograms.length > 0) {
          const firstOtherProgram = otherCreditPrograms[0];

          // second optional param is unified assessment boolean
          await form8974.uploadTaxDocsFor8974(
            firstOtherProgram.id,
            taxYear,
            true,
          );
        }
      } else {
        await form8974.uploadTaxDocsFor8974(
          taxYearFedRDProgram.id,
          taxYear,
          true,
        );
      }
      await unifiedTaxCredits.setConfirmFiling(taxYear);

      if (!form8974.requestError && form8974.taxInfoSubmitted) {
        // update all client_review programs to finished stage
        await unifiedTaxCredits.updateAllProgramStageToFinished(taxYear);
      } else {
        setToast([
          {
            text: 'There was an error while uploading your documents. Please try again.',
            status: 'error',
          },
        ]);
      }

      unifiedTaxCredits.setLoadingClientReview(false);
      unifiedTaxCredits.setShowCongratsModal(false, taxYear);

      // load the assessment page to update after filings
      unifiedTaxCredits.setLoadingAssessments(true);
      await unifiedTaxCredits.getTaxCreditAssessments(taxYear);
    };

    useEffectOnce(async () => {
      await unifiedTaxCredits.getTaxYearDocuments(taxYear);

      // if filing date exist
      const filingDate = taxYearFedRDProgram.taxFilingDate
        ? taxYearFedRDProgram.taxFilingDate
        : '';

      if (filingDate.length > 0) {
        const timezoneFix = new Date(filingDate)
          .toISOString()
          .replace('.000Z', '');

        setTaxFilingDate(moment(timezoneFix).format('MM/DD/YYYY'));
      }
    });

    if (isLoading) {
      return <Loading loading={true} />;
    } else {
      return (
        <>
          <Card noMargin key={`${taxYear}-assessments-client-review`}>
            <Flex direction='column' gap={16} padding={24}>
              <Flex
                justifyContent='space-between'
                alignItems='center'
                wrap='wrap'
              >
                <Flex
                  gap={4}
                  alignItems='center'
                  justifyContent='flex-start'
                  className={classes.title}
                  basis={315}
                >
                  <Text variant='medium' size={18}>
                    Time to file your forms
                  </Text>

                  <Icon
                    name={IconEnum.question_circle}
                    onClick={() =>
                      unifiedTaxCredits.setShowClientTitleSideDrawer(true)
                    }
                  />
                </Flex>
                <Button
                  className={classes.timelineBtn}
                  label={
                    <Flex gap={4}>
                      <Icon
                        name={IconEnum.clock_circle}
                        color={Color.blue._60}
                      />{' '}
                      <Text color={Color.blue._60}>View activity timeline</Text>
                    </Flex>
                  }
                  flexAlignSelf='flex-end'
                  onClick={() =>
                    unifiedTaxCredits.setShowTimelineSideDrawer(true)
                  }
                  variant='tertiary'
                  small
                />
              </Flex>
              <Flex gap={24} direction='column'>
                <Text>
                  Your credits have been finalized. After filing your taxes,
                  return to this page to confirm your filing details and upload
                  your tax returns by clicking the attestation below.
                </Text>
                <Flex
                  padding={[24, 24, 0, 24]}
                  direction='column'
                  className={classes.filingInstructions}
                >
                  <Text variant='medium'>Filing instructions</Text>
                  <ol>
                    <li>
                      <Text size={13}>
                        Click on the “Copy link for CPA” button below to copy
                        the magic link.
                      </Text>
                    </li>
                    <li>
                      <Text size={13}>
                        Send the link to your CPA who will then file all
                        corresponding tax forms with the IRS.
                      </Text>
                    </li>
                    <li>
                      <Text size={13}>
                        After filing your taxes, return to this page to confirm
                        your filing details and upload your tax returns by
                        clicking the attestation.
                      </Text>
                    </li>
                  </ol>
                </Flex>
                <Flex gap={24}>
                  <Button
                    small
                    className={
                      unifiedTaxCredits.linkCopied
                        ? classes.copiedLink
                        : classes.copyLink
                    }
                    onClick={() =>
                      CopyToClipboard(accessLink, handleOnCopyLink)
                    }
                    variant={
                      unifiedTaxCredits.linkCopied ? 'outlined' : 'contained'
                    }
                    label={
                      <Flex alignItems='center' gap={8}>
                        {unifiedTaxCredits.linkCopied && (
                          <Animate enter='fade-in'>
                            <CheckmarkAnimated className={classes.checkmark} />
                          </Animate>
                        )}
                        <Text
                          tag='span'
                          color={
                            unifiedTaxCredits.linkCopied
                              ? Color.green._60
                              : Color.neutral.white
                          }
                          size={13}
                          variant='medium'
                        >
                          {unifiedTaxCredits.linkCopied
                            ? 'Copied'
                            : 'Copy link for CPA'}
                        </Text>
                      </Flex>
                    }
                  />
                  <Button
                    small
                    onClick={() =>
                      unifiedTaxCredits.setShowTaxFormsSideDrawer(true)
                    }
                    label='View all tax forms'
                    variant='outlined'
                  />
                </Flex>
                <Divider variant='no-bottom-margin' />
              </Flex>
              <Checkbox
                className={classes.confirmCheckbox}
                options={[
                  {
                    checked: unifiedTaxCredits.confirmedFilingCheckbox,
                    value: 'checkbox-confirmed-filing',
                    text: 'I hereby confirm that I have filed my taxes, including all corresponding income tax forms.',
                  },
                ]}
                onChange={() =>
                  (unifiedTaxCredits.confirmedFilingCheckbox =
                    !unifiedTaxCredits.confirmedFilingCheckbox)
                }
                dataTestId={'confirmed-filing-attestation'}
              />
            </Flex>
            <Expandable expand={unifiedTaxCredits.confirmedFilingCheckbox}>
              <Flex padding={[0, 24, 24, 24]} gap={24} direction='column'>
                <Flex
                  direction='column'
                  padding={24}
                  className={classes.filingDateContainer}
                >
                  <Text
                    variant='medium'
                    size={15}
                    text='Please enter your tax filing date and upload your tax return below.'
                    paddingBottom={24}
                  />
                  <FilingDateTextField
                    federalRDProgram={taxYearFedRDProgram}
                    taxFilingDate={taxFilingDate}
                    setTaxFilingDate={setTaxFilingDate}
                    filingDateError={
                      !featureFlags.filingIgnoreTimeCheck
                        ? filingDateError
                        : false
                    }
                  />
                  <Flex padding={[24, 0]}>
                    <TextField
                      dataTestId={'ein-field'}
                      helperText='Please confirm the EIN shown on your tax return.'
                      einNumberFormat
                      label='EIN'
                      value={EIN}
                      onChange={(e) => handleUpdateEIN(e.target.value)}
                    />
                  </Flex>
                  <Card noMargin noBorder noBoxShadow maxWidth={544}>
                    <FileUpload
                      dataTestId={'tax-form-file-upload'}
                      title='Upload your complete tax return'
                      subtitle='It should also include your Form 6765'
                      flexDirection={'column'}
                      onFileAdded={(file: FileHandle) => {
                        form8974.addFileToBeUploaded(file);
                        file.setUploadProgress(1, 1, 1);
                      }}
                      acceptedFiletypes={FileTypes.PDF}
                      allowFilePassword={false}
                      onFileRemoved={(file: FileHandle) =>
                        form8974.removeFileToBeUploaded(file)
                      }
                      maxFileSize={1024 * 1024 * 30}
                      error={false}
                      errorText={'error'}
                      alignItems='flex-start'
                    />
                  </Card>
                </Flex>
                <Button
                  flexAlignSelf='flex-start'
                  label='Continue'
                  dataTestId='continue-button'
                  loading={unifiedTaxCredits.showCongratsModal}
                  disabled={disableContinue}
                  onClick={() =>
                    unifiedTaxCredits.setShowCongratsModal(true, taxYear)
                  }
                />
              </Flex>
            </Expandable>
          </Card>
          <ActivityTimelineSideDrawer
            taxYear={taxYear.toString()}
            stepIndex={unifiedTaxCredits.confirmedFilingCheckbox ? 5 : 4}
          />
          <ViewAllTaxFormsSideDrawer
            taxYear={taxYear.toString()}
            documents={unifiedTaxCredits.viewTaxForms}
          />

          <Modal
            showModal={unifiedTaxCredits.showCongratsModal}
            maxWidth={500}
            dataTestId='congrats-modal'
          >
            <Flex padding={24} direction='column' alignItems='center' gap={16}>
              <Image
                src={`${process.env.PUBLIC_URL}/images/tax-credit-dashboard-module/all-set.svg`}
                alt='all set'
                width={100}
              />
              <Heading size={23} variant='medium' marginBottom={0}>
                Congrats on filing your tax return!
              </Heading>
              <Text
                paddingBottom={16}
                textAlign='center'
                text={unifiedTaxCredits.congratsModalText}
              />
              <Button
                onClick={async () => handleContinue()}
                label='Okay'
                loading={unifiedTaxCredits.loadingClientReview}
              />
            </Flex>
          </Modal>

          <SideDrawer
            show={unifiedTaxCredits.showClientTitleSideDrawer}
            title='File your forms with the IRS'
            closeToggle={() =>
              unifiedTaxCredits.setShowClientTitleSideDrawer(false)
            }
            drawerContent={
              <Flex direction='column' gap={16}>
                <Text variant='medium'>What&apos;s this step?</Text>
                <Divider variant='no-bottom-margin' />
                <Text>
                  After receiving your credit forms from MainStreet, you must
                  file them with your business income taxes.
                </Text>
                <Text>There are two ways to redeem the credits:</Text>
                <ul>
                  <li>
                    <Text paddingBottom={16}>
                      <strong>Income tax credit:</strong> Your credit will
                      instantly reduce your business income tax liability. You
                      don&apos;t need to do anything else after filing the form
                      that we sent to you alongside your business income taxes.
                    </Text>
                  </li>

                  <li>
                    <Text>
                      <strong>Payroll tax credit:</strong> In the quarter after
                      you&apos;ve filed Form 6765, you&apos;ll need to file a
                      Form 8974 with your quarterly payroll taxes in order to
                      redeem your credits.
                    </Text>
                  </li>
                </ul>
                <Text>
                  Don&apos;t worry, we&apos;ll let you know exactly what to do
                  once we get to this step.
                </Text>
              </Flex>
            }
          />
          {toast.length > 0 && <Toast toasts={toast} setToast={setToast} />}
        </>
      );
    }
  } else {
    return (
      <Flex alignItems='center' justifyContent='center' padding={16}>
        <Spinner size='small' />
      </Flex>
    );
  }
});
