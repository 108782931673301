import {
  Card,
  Color,
  Flex,
  FontAwesome,
  Icon,
  IconEnum,
  Link,
  Text,
} from 'component-library';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  AssessmentProgressStatus,
  TaxCreditAssessments,
} from '../../../../../../lib/interfaces';
import { Page } from '../../../../../../lib/constants';
import { RdStatusExpandable } from './RdStatusExpandable';

const useStyles = makeStyles(() => ({
  card: {
    borderTop: 'none',
    borderBottom: `1px solid ${Color.neutral._20}`,
    borderLeft: 'none',
    borderRight: 'none',
  },
  icon: {
    marginRight: '4px',
  },
  warningIcon: {
    margin: '4px 8px 0 6px',
  },
}));

interface AssessmentStatusCardProps {
  assessment: TaxCreditAssessments;
  taxYear: number;
}

const assessmentConfig = (
  assessment: TaxCreditAssessments,
  classes: {
    icon: string;
    warningIcon: string;
  },
): {
  color: string;
  statusIcon: JSX.Element;
  editMessage: string;
  assessmentBody: JSX.Element;
} => {
  const status = assessment.status;

  switch (status) {
    case AssessmentProgressStatus.READY_TO_SUBMIT:
    case AssessmentProgressStatus.MS_REVIEW:
      return {
        color: Color.green._60,
        statusIcon: (
          <Icon
            name={IconEnum.check_circle}
            color={Color.green._60}
            className={classes.icon}
            size={30}
          />
        ),
        editMessage: 'Edit',
        assessmentBody:
          assessment.creditAmount &&
          assessment.status === AssessmentProgressStatus.MS_REVIEW ? (
            <Flex direction='column'>
              <Text variant='regular' size={15} color={Color.neutral._80}>
                Estimated credit amount
              </Text>
              <Text variant='medium' size={18}>
                {assessment.creditAmount}
              </Text>
            </Flex>
          ) : (
            <></>
          ),
      };
    case AssessmentProgressStatus.IN_PROGRESS:
    case AssessmentProgressStatus.SKIPPED:
    case AssessmentProgressStatus.NOT_STARTED:
      return {
        color: Color.semantic.$warning70,
        statusIcon: (
          <FontAwesome
            name='triangle-exclamation'
            variant='regular'
            color={Color.semantic.$warning70}
            className={classes.warningIcon}
            size={20}
          />
        ),
        editMessage: 'Finish',
        assessmentBody: (
          <Text variant='regular' size={15} color={Color.neutral._80}>
            Complete this section to determine your credit amount.
          </Text>
        ),
      };
    case AssessmentProgressStatus.DQ:
    default:
      return {
        color: Color.neutral._80,
        statusIcon: (
          <Icon
            name={IconEnum.times_circle}
            color={Color.neutral._80}
            className={classes.icon}
            size={30}
          />
        ),
        editMessage: 'Edit',
        assessmentBody: (
          <Text variant='regular' size={15} color={Color.neutral._80}>
            Based on the details you provided, you are ineligible for this
            credit. If you believe otherwise, click ‘Edit’ to adjust your
            answers.
          </Text>
        ),
      };
  }
};

const editLink = (assessmentName: string, taxYear: number) => {
  const assessmentSurveyRootPath = `/${Page.taxCredits}/${Page.assessment}/${taxYear}`;

  switch (assessmentName) {
    case 'Connect Payroll':
      return `${assessmentSurveyRootPath}/${Page.emailPayrollCredentials}`;
    case 'General Business Details':
      return assessmentSurveyRootPath;
    case 'Retirement Plan Tax Credit':
      return `${assessmentSurveyRootPath}/${Page.retirementPlan}`;
    case 'Small Business Healthcare Tax Credit':
      return `${assessmentSurveyRootPath}/${Page.healthcare}`;
    case 'Research and Development Tax Credit':
      return `${assessmentSurveyRootPath}/${Page.expenseClassificationCompanyDetails}`;
    case 'Disabled Access Tax Credit':
      return `${assessmentSurveyRootPath}/${Page.disabledAccess}`;
    default:
      return assessmentSurveyRootPath;
  }
};

export const AssessmentStatusCard = ({
  assessment,
  taxYear,
}: AssessmentStatusCardProps) => {
  const classes = useStyles();

  if (!assessment.status) {
    return <></>;
  }

  const { color, statusIcon, editMessage, assessmentBody } = assessmentConfig(
    assessment,
    classes,
  );

  return (
    <Card noBoxShadow noMargin className={classes.card}>
      <Flex direction='column' gap={16} padding={[24, 0]}>
        <Flex justifyContent='space-between' alignItems='center' wrap='wrap'>
          <Flex gap={4} alignItems='flex-start' justifyContent='space-between'>
            <Flex alignItems='center' direction='column'>
              <Flex>
                <div>{statusIcon}</div>
                <Flex direction='column'>
                  <Flex alignItems='flex-start' justifyContent='space-between'>
                    <div>
                      <Text variant='medium' size={18} color={color}>
                        {assessment.assessmentTitle}
                      </Text>
                      {assessmentBody}
                    </div>
                    <Flex
                      alignItems='center'
                      justifyContent='end'
                      basis='content'
                    >
                      <Icon
                        name={IconEnum.pen}
                        color={Color.blue._60}
                        className={classes.icon}
                      />
                      <Link
                        href={editLink(assessment.assessmentTitle, taxYear)}
                      >
                        {editMessage}
                      </Link>
                    </Flex>
                  </Flex>
                  {assessment.assessmentTitle ===
                    'Research and Development Tax Credit' && (
                    <RdStatusExpandable taxYear={taxYear} />
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
