import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Route, Switch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Animate, Flex, Stepper } from 'component-library';
import { useTaxCreditsStores } from 'stores/useStores';
import { animated } from 'react-spring';
import { useSpringPageLocationTransition } from 'lib/useSpringPageTransition';
import TitleHeader from 'components/TitleHeader';
import useERCRoutes from './components/useERCRoutes';
import { Page } from 'lib/constants';
import HttpErrorPage from 'pages/HttpErrorPage';

const useStyles = makeStyles(() => ({
  '@global': {
    '.embeddedServiceHelpButton': {
      display: 'none !important',
    },
  },
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    maxWidth: '1200px',
    margin: '0 auto',
    position: 'relative',
  },
  fullMainContent: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  animateContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}));

export const EmployeeRetention: React.FC = observer(() => {
  const classes = useStyles();
  const location = useLocation();
  const { erc } = useTaxCreditsStores();
  const { ERCRoutes } = useERCRoutes();

  const { transitions } = useSpringPageLocationTransition(
    location,
    erc.ERCCurrentIndex,
    erc.ERCPrevIndex,
  );

  useEffect(() => {
    erc.setCurrentStepOnLoad(location.pathname);
  }, [erc, location.pathname]);

  const hideStepperPages = [
    `${Page.ercCreditEstimate}`,
    `${Page.ercBillingSummary}`,
  ];
  const hideStepper = hideStepperPages.some((page) =>
    location.pathname.includes(page),
  );
  const fullWidth = location.pathname.includes(`${Page.ercCreditEstimate}`);

  const EmployeeRetentionSteps = [
    'Business details',
    'Income tax returns',
    'Payroll connection',
    'Payroll tax returns',
    'Mailing details',
    'Employee details',
  ];

  return (
    <Flex
      direction='column'
      className={classes.root}
      data-testid={'employee-retention'}
    >
      <Animate enter={['fade-in', 'from-top']}>
        <TitleHeader
          title='Employee retention credit'
          showExitButton
          borderBottom
          onCancelExitText='Cancel'
          showCompanyName
          onExitLocation={`/${Page.employeeRetentionCredits}`}
        />
      </Animate>
      <Flex
        className={fullWidth ? classes.fullMainContent : classes.mainContent}
        direction='column'
      >
        {!hideStepper && (
          <Animate enter={['fade-in', 'from-top']} delay={0.5} duration={0.75}>
            <Stepper
              steps={EmployeeRetentionSteps}
              currentStep={erc.ERCCurrentIndex}
            />
          </Animate>
        )}

        <Animate
          enter={'fade-in'}
          delay={1}
          duration={0.75}
          className={classes.animateContainer}
        >
          {transitions((props, item) => {
            return (
              <animated.div style={props} className={classes.animateContainer}>
                <Switch location={item}>
                  {ERCRoutes.map((item, key) => {
                    return (
                      <Route
                        exact={item.exact}
                        key={key}
                        path={item.path}
                        render={() => item.component}
                      />
                    );
                  })}
                  <Route
                    path={`/${Page.taxCredits}/${Page.erc}/*`}
                    component={HttpErrorPage}
                  />
                </Switch>
              </animated.div>
            );
          })}
        </Animate>
      </Flex>
    </Flex>
  );
});
