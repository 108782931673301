import React from 'react';
import { observer } from 'mobx-react';
import { Button, Heading, Link, Text } from 'component-library';
import { Page } from 'lib/constants';
import { useCommonStores } from 'stores/useStores';

export const ZeroAvgMonthlyPayroll: React.FC = observer(() => {
  const { app } = useCommonStores();

  return (
    <>
      <Heading
        size={app.isMobile ? 24 : 36}
        text='There was an issue with your order form.'
        marginBottom={0}
      />
      <Text>
        Your payroll either is not connected or showing $0 of average monthly
        payroll. Please connect your payroll system or reach out to{' '}
        <Link
          href='mailto:support@mainstreet.com'
          text='support@mainstreet.com'
        />{' '}
        if it is already connected.
      </Text>
      <Button
        onClick={() => app.history.push(`/${Page.connections}`)}
        label='Update payroll connection'
        flexAlignSelf='flex-start'
      />
    </>
  );
});
