import { ProductTypeEnum } from 'lib/constants';
import { CentsToDisplayString } from 'lib/helpers';

export const GetMagicContingencyText = (
  productType: ProductTypeEnum,
  options?: {
    // Caller passes in override if applicable
    annualEmployeeBenefitSpendCents?: number;
    amountCommittedCents?: number;
  },
) => {
  switch (productType) {
    case ProductTypeEnum.BENEFITS:
      return `Net savings guarantee is contingent on rolling out an average of ${CentsToDisplayString(
        options?.annualEmployeeBenefitSpendCents ?? 0,
      )} per year in cost-neutral & cost-plus benefits per employee.`;
    case ProductTypeEnum.VENDOR_MANAGEMENT:
      return `Net savings guarantee is contingent on you providing MainStreet with information to audit your spending, applying our savings suggestions, and giving us the ability to negotiate procurement on your behalf.`;
    case ProductTypeEnum.HIGH_YIELD_SAVINGS:
      return `Net savings guarantee is contingent on a ${
        options?.amountCommittedCents
          ? `${CentsToDisplayString(options.amountCommittedCents)} `
          : ''
      }minimum balance.`;
    default:
      return '';
  }
};

export const GetMagicEstimateDescription = (
  productType: ProductTypeEnum,
): string => {
  switch (productType) {
    case ProductTypeEnum.BENEFITS:
      return `We'll work with your People or HR team to implement cost-saving and tax-efficient benefits and perks for your team as part of your overall annual compensation process. Your employees save on income tax, you'll save on payroll taxes.\nSavings are the sum between avoided payroll taxes by optimizing future wages into benefits, and spending less on future employee wages with tax-efficient compensation tools.`;
    case ProductTypeEnum.VENDOR_MANAGEMENT:
      return `We’ll audit your current SaaS spend and handle any new software negotiations to make sure you’re not overpaying for the tools you need to do your job. Savings are calculated as the total amount negotiated on current and future contracts and subscriptions.`;
    case ProductTypeEnum.HIGH_YIELD_SAVINGS:
      return `Transfer funds and you'll accrue returns on your balance of 3.02% APY. Earnings are paid out monthly. Your balance is withdrawable anytime. Perfect for that idle cash you’ve got parked in a checking account.`;
    default:
      return '';
  }
};

export const GetMagicEstimateSubtitle = (productType: ProductTypeEnum) => {
  switch (productType) {
    case ProductTypeEnum.BENEFITS:
      return 'Estimated savings';
    case ProductTypeEnum.VENDOR_MANAGEMENT:
      return 'Estimated savings';
    case ProductTypeEnum.HIGH_YIELD_SAVINGS:
      return 'In interest paid';
    default:
      return '';
  }
};
