import React, { memo, Dispatch, SetStateAction } from 'react';
import {
  Alert,
  Button,
  Card,
  CardFooter,
  Color,
  Content,
  Expandable,
  ExpandableHeader,
  IconEnum,
  Table,
  Text,
} from 'component-library';
import { CentsToDisplayStringNoSymbol } from '../../../../../lib/helpers';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  grayCaution: {
    borderLeft: `solid ${Color.neutral._50} !important`,
    backgroundColor: `${Color.neutral._light_20} !important`,
  },
}));

export interface EmployeeTableProps {
  tableEditMode: boolean;
  setTableEditMode: Dispatch<SetStateAction<boolean>>;
  columns: any[];
  data: any[];
  rowOnClick: (values: { name: string; id: number }) => void;
  primaryOnClick: () => void;
  secondaryOnClick: () => void;
  dataType: 'employee' | 'contractor';
  doesOtherJobGroupExist?: boolean;
  countInTitle?: boolean;
  customTitle?: string;
  customSubtitle?: string;
  customNoDataElement?: JSX.Element;
  hidePrimary?: boolean;
  hideAddEmployee?: boolean;
}

const MISSING_DATA_VALUE = undefined;

export const EmployeeTable = ({
  tableEditMode,
  setTableEditMode,
  columns,
  data,
  rowOnClick,
  primaryOnClick,
  secondaryOnClick,
  dataType,
  doesOtherJobGroupExist,
  countInTitle,
  customTitle,
  customSubtitle,
  customNoDataElement,
  hidePrimary,
  hideAddEmployee,
}: EmployeeTableProps) => {
  const classes = useStyles();
  const noDataTextContent = customNoDataElement ? (
    customNoDataElement
  ) : dataType === 'employee' ? (
    <Text textAlign='center' variant='medium'>
      There are no employees listed in your payroll yet
    </Text>
  ) : (
    <Text textAlign='center' variant='medium'>
      There are no contractors in your payroll yet <br />
      <Text textAlign='center' tag='span'>
        If you pay contractors outside your payroll system, please add them
        manually
      </Text>
    </Text>
  );

  let secondaryCtaLabel = undefined;
  if (data.length) {
    secondaryCtaLabel = `Add ${dataType}`;
  }
  const formatPayAndRole = () => {
    return data.map((personData) => {
      const changedPersonData = { ...personData };

      // format paidInCents into currency display string
      if (changedPersonData.paidInCents === 0) {
        changedPersonData.paidInCents = MISSING_DATA_VALUE;
      } else {
        changedPersonData.paidInCents =
          '$' + CentsToDisplayStringNoSymbol(changedPersonData.paidInCents);
      }

      // replace missing role with - instead of "Missing data" icon
      // use || instead of ?? to catch empty string
      changedPersonData.role = personData.role || '-';

      return changedPersonData;
    });
  };

  let title =
    customTitle ||
    `Make sure that all your US-based ${dataType}s are included in the following table`;
  let subtitle =
    customSubtitle ||
    `Please verify that the information in this table is complete and accurate. We will use this information to determine the R&D credits you've earned from your payroll expenses, and any inaccurate data could lead to a lower final credit amount.`;

  if (countInTitle) {
    const plural = data.length === 1 ? '' : 's';
    const capitalizedDataType =
      dataType.charAt(0).toUpperCase() + dataType.slice(1);
    title = `${capitalizedDataType}s Record Table`;
    subtitle = `${data.length} ${dataType}${plural} confirmed`;
  }

  return (
    <Card noMargin>
      <ExpandableHeader
        editMode={tableEditMode}
        setEditMode={setTableEditMode}
        title={title}
        subtitle={subtitle}
        dataTestId={`${dataType}-header`}
      />
      <Expandable expand={tableEditMode}>
        <>
          {data.length > 0 ? (
            <Table
              columns={columns}
              data={formatPayAndRole()}
              paginationSizePerPage={20}
              paginationHideSinglePage
              rowOnClick={rowOnClick}
            />
          ) : (
            <Content flex flexDirection='column' paddingTopBottom={32}>
              {noDataTextContent}

              {!hideAddEmployee && (
                <Button
                  label={`Add ${dataType}`}
                  flexAlignSelf='center'
                  variant='tertiary'
                  onClick={secondaryOnClick}
                />
              )}
            </Content>
          )}
          {doesOtherJobGroupExist ? (
            <Alert
              className={classes.grayCaution}
              type='caution'
              variant={'in_card'}
              inCardBorder={'left'}
              icon={IconEnum.info_circle}
              text={`To streamline your next steps, we recommend selecting an option that at least partially describes this employee’s activities`}
              iconColor={Color.neutral._90}
              dataTestId={`${dataType}-job-group-other-alert`}
            />
          ) : (
            <></>
          )}

          <CardFooter
            primaryCtaLabel={hidePrimary ? undefined : 'Confirm'}
            primaryOnClick={primaryOnClick}
            primaryTestId={`${dataType}-primary-action`}
            secondaryCtaLabel={secondaryCtaLabel}
            secondaryOnClick={secondaryOnClick}
            secondaryTestId={`${dataType}-secondary-action`}
            variant={'quaternary'}
          />
        </>
      </Expandable>
    </Card>
  );
};

// memo automatically compares old and new props. No need to pass comparator
export const MemoizedEmployeeTable = memo(EmployeeTable);
MemoizedEmployeeTable.displayName = 'EmployeeTable';
