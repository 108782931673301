import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  Alert,
  Animate,
  Button,
  Card,
  Color,
  Divider,
  Flex,
  Grid,
  Heading,
  Spinner,
  Text,
} from 'component-library';
import { useCompany } from 'stores/useStores';
import Rive from 'rive-react';
import { CentsToDisplayString } from 'lib/helpers';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    height: '100%',
    gridTemplateColumns: `45% 55%`,
    background: `url(${process.env.PUBLIC_URL}/images/dashboard/get-started/get-started-bg-gradient.png)`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: '100% 100%',
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
    opacity: 1,
    transition: 'opacity .4s',
  },
  animateContainer: {
    width: '100%',
  },
  container: {
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',

    '& span': {
      lineHeight: '1.4em !important',
    },
  },
  loading: {
    opacity: 1,
    transition: 'opacity .4s',
  },
  fadeOutContainer: {
    opacity: 0,
  },
  riveContainer: {
    '& canvas': {
      height: '300px !important',
      marginLeft: '25px',
    },
  },
}));

export interface CreditEstimateDisplay {
  name: string;
  estimateAmount: number;
}

interface SurveyFlowCreditEstimateProps {
  creditName: string;
  credits: CreditEstimateDisplay[];
  eligibleCreditSubtitle: string;
  handleOnContinue: () => void;
  showNonBindingAlert: boolean;
}

export const SurveyFlowCreditEstimate = observer(
  ({
    creditName,
    credits,
    eligibleCreditSubtitle,
    handleOnContinue,
    showNonBindingAlert,
  }: SurveyFlowCreditEstimateProps) => {
    const [onload, setOnload] = useState<boolean>(true);
    const [fadeOut, setFadeOut] = useState<boolean>(false);
    const classes = useStyles();
    const { company } = useCompany();

    useEffect(() => {
      if (credits.length > 0) {
        // set fade animation
        setTimeout(() => {
          setFadeOut(true);
        }, 1600);
        setTimeout(() => {
          setOnload(false);
          setFadeOut(false);
        }, 2000);
      }
    }, [credits]);

    const determineEstimateTotal = (
      credits: CreditEstimateDisplay[],
    ): string => {
      const totalEstimate = credits.reduce(
        (acc, curr) => acc + curr.estimateAmount,
        0,
      );
      return CentsToDisplayString(totalEstimate, 0, 0);
    };

    return (
      <>
        {onload ? (
          <Flex
            padding={24}
            justifyContent='center'
            alignItems='center'
            direction='column'
            className={`${classes.loading} ${
              fadeOut && classes.fadeOutContainer
            }`}
          >
            <Card
              noBorder
              noBoxShadow
              noMargin
              maxWidth={540}
              className={classes.riveContainer}
            >
              <Rive
                src={`${process.env.PUBLIC_URL}/images/rive/searching-for-credits.riv`}
              />
            </Card>
            <Text
              text='Estimating credits...'
              variant='medium'
              textAlign='center'
              size={23}
            />
          </Flex>
        ) : (
          <Animate
            enter={'fade-in'}
            delay={1}
            className={classes.animateContainer}
          >
            <Flex
              className={`${classes.root} ${
                fadeOut && classes.fadeOutContainer
              }`}
              alignItems='center'
            >
              <Grid className={classes.container}>
                <Grid.Cell padding={[0, 24, 0, 24]} alignSelf='center'>
                  <Heading variant='medium' tag='span' size={40}>
                    {company.name}{' '}
                  </Heading>
                  <Heading tag='span' size={40}>
                    is eligible for{' '}
                  </Heading>
                  <Heading tag='span' size={40}>
                    {creditName}
                  </Heading>
                </Grid.Cell>
                <Grid.Cell>
                  <Card noBoxShadow noMargin>
                    <Flex
                      direction='column'
                      padding={[4, 24, 24, 24]}
                      justifyContent='center'
                      alignItems='center'
                      gap={16}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/coins.svg`}
                        alt='Form 941 Icon'
                        width={150}
                      />
                      <Flex
                        direction='column'
                        gap={8}
                        alignItems='center'
                        padding={[0, 0, 24, 0]}
                      >
                        <Text variant='medium' size={18}>
                          {company.name} is eligible for up to
                        </Text>
                        {credits.length > 0 ? (
                          <Heading tag='h2' variant='medium' marginBottom={0}>
                            {determineEstimateTotal(credits)}
                          </Heading>
                        ) : (
                          <Flex justifyContent='center' padding={16}>
                            <Spinner />
                          </Flex>
                        )}
                        <Text text={eligibleCreditSubtitle} />
                      </Flex>
                      <Divider variant='middle' />
                      <Flex
                        padding={[0, 0, 24, 0]}
                        justifyContent='space-between'
                        gap={4}
                        alignItems='center'
                      >
                        {credits.length > 0 ? (
                          credits.map((credit, index) => (
                            <React.Fragment key={`${credit.name}-${index}`}>
                              {index > 0 && <Flex.Cell>+</Flex.Cell>}
                              <Flex direction='column' alignItems='center'>
                                <Text size={18} textAlign='center'>
                                  {credit.name} tax&nbsp;credit
                                </Text>
                                <Heading
                                  tag='h4'
                                  variant='medium'
                                  marginBottom={0}
                                >
                                  {CentsToDisplayString(credit.estimateAmount)}
                                </Heading>
                              </Flex>
                            </React.Fragment>
                          ))
                        ) : (
                          <Flex justifyContent='center' padding={16}>
                            <Spinner />
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                    <Flex direction='column' gap={24}>
                      {showNonBindingAlert && (
                        <Alert
                          backgroundColor={Color.blue._10}
                          text={
                            <Flex direction='column'>
                              <Text size={15}>
                                This credit estimate is non-binding. Your final
                                credit may differ based on your company&apos;s
                                unique facts and circumstances.
                              </Text>
                            </Flex>
                          }
                          type='info'
                          variant='in_card'
                        />
                      )}
                      <Flex padding={[0, 24, 24, 24]} justifyContent='flex-end'>
                        <Button label='Continue' onClick={handleOnContinue} />
                      </Flex>
                    </Flex>
                  </Card>
                </Grid.Cell>
              </Grid>
            </Flex>
          </Animate>
        )}
      </>
    );
  },
);
