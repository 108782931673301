import React from 'react';
import { Text, Card, Content } from 'component-library';
import {
  makeStyles,
  Theme,
  TextField,
  Chip,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { QuestionProps, QuestionOptionProps } from 'lib/interfaces';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  textField: {
    marginTop: '24px',
    width: '544px',
    [breakpoints.down('xs')]: {
      width: '250px',
    },
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: '24px',
  },
}));

interface Props {
  question: QuestionProps;
  type: 'chip' | 'checkbox';
  className?: string;
  onChange: (value: any) => void;
  subtitle?: string;
  storeOptionLabel?: boolean;
}

export const MultiSelectQuestion = ({
  question,
  type,
  className,
  onChange,
  storeOptionLabel = false,
}: Props) => {
  const classes = useStyles();

  const values = Array.isArray(question.value) ? question.value : [];

  const renderCheckbox = (
    optionsSelected: Array<string>,
    option: QuestionOptionProps,
  ) => {
    const selected = storeOptionLabel
      ? optionsSelected.indexOf(option.label) > -1
      : optionsSelected.indexOf(option.value) > -1;

    if (option) {
      return (
        <FormControlLabel
          key={`radio-${option.label}`}
          value={option.value}
          label={option.label}
          control={
            <Checkbox
              color='primary'
              checked={selected}
              name={option.label}
              onChange={onChange}
            />
          }
        />
      );
    }
  };

  return (
    <Card className={className}>
      <Content>
        <Text size={18} variant='medium'>
          {question.text}
        </Text>
        {question.subtitle && <Text size={15}>{question.subtitle}</Text>}
        {type === 'chip' && (
          <Autocomplete
            multiple
            value={values}
            onChange={(event, newValue) => {
              onChange(newValue);
            }}
            options={question.optionsList || []}
            getOptionLabel={(option) => option}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                //eslint-disable-next-line
                <Chip label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textField}
                variant='outlined'
                label={question.label}
                autoComplete='off'
              />
            )}
          />
        )}
        {type === 'checkbox' && (
          <div className={classes.checkboxContainer}>
            {question.options?.map(renderCheckbox.bind(null, values))}
          </div>
        )}
      </Content>
    </Card>
  );
};
