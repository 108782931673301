import React from 'react';
import { Color, Dollar, Flex, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { CentsToDisplayStringNoSymbol } from 'lib/helpers';

const useStyles = makeStyles(() => ({
  savingsSection: {
    alignSelf: 'center',
  },
}));

interface SavingsCreditSectionProps {
  estimate: number;
}

export const SavingsCreditSection: React.FC<SavingsCreditSectionProps> = observer(
  ({ estimate }) => {
    const classes = useStyles();
    const hasCreditsInProgress = estimate > 0;

    return (
      <Flex.Cell grow={1} padding={[24, 32]} className={classes.savingsSection}>
        <Text
          color={Color.neutral._90}
          paddingBottom={4}
          variant='medium'
          text={
            hasCreditsInProgress ? 'On track to save' : 'No credits in progress'
          }
        />
        <Dollar
          dataTestId='savings-credit-estimate'
          tag='h2'
          value={`${CentsToDisplayStringNoSymbol(estimate)}`}
        />
        <Text
          color={Color.neutral._90}
          size={13}
          text={'Total credits in progress'}
        />
      </Flex.Cell>
    );
  },
);
