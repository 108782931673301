import { Flex } from 'component-library';
import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
  deadline: Date; // The deadline as a Date object
  textColor?: string;
  fontWeight?: any;
  className?: string;
}

export const CountdownTimer: React.FC<CountdownTimerProps> = ({
  deadline,
  textColor = 'black',
  fontWeight = 'bold',
  className,
}) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const remainingTime = deadline.getTime() - now.getTime();

      if (remainingTime <= 0) {
        clearInterval(intervalId);
        setTimeLeft('Time is up!');
        return;
      }

      const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);

      setTimeLeft(`${days} days ${hours} hours left!`);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [deadline]);

  return (
    <Flex
      className={className}
      style={{
        color: textColor,
        fontWeight: fontWeight,
      }}
    >
      {timeLeft}
    </Flex>
  );
};
