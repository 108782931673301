import { runInAction } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import { BaseTaxCreditsStore } from '../BaseTaxCreditsStore';
import {
  AcceptedByData,
  OrderForm,
  ProgramData,
  ProgramName,
} from 'lib/interfaces';
import {
  ProgramNameEnum,
  Programs,
  OrderFormPromotionNameEnum,
} from 'lib/constants';
import { AcceptButtonStatus } from 'products/tax-credits/entities/OrderFormEntity';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import {
  IsExperimentalProgramWithNoQualFlow,
  IsRDCreditProgram,
} from 'lib/helpers';

export class OrderFormStore extends BaseTaxCreditsStore {
  public orderForm: OrderForm | null = null;
  public currentProgram: ProgramData | null = null;
  public acceptButtonStatus: AcceptButtonStatus = AcceptButtonStatus.ENABLED;
  public acceptErrorRes = '';
  public acceptOrderFormModal = false;
  public formTitle = '';
  // update to false to not auto opt in
  // this needs to be updated to true in order to show the bogo section
  // when feature flags are turned on
  public optInBOGO = false;
  public defaultPaymentMethodV2 = false;
  public federalOrderFormId = 0;
  public hasAcceptedFedOrderForm = false;
  public isFederalOrderForm = true;
  public pendingOrderForms: OrderForm[] = [];
  public hasAcceptedBOGO = false;
  public isReceipt = false;
  public getOrderFormError = false;
  public acceptedBy: AcceptedByData | null = null;
  public acceptedAt: Date | number | null = null;
  public attestationAccepted = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public async initializeOrderForm(programId: number) {
    await this.rootStore.common.companyStore.refreshCurrentCompany();
    const { programs, orderForms } =
      this.rootStore.common.companyStore.currentCompany;
    const currentProgram = programs.find((program) => program.id === programId);
    const res = await this.api.GetOrderFormData(programId);

    if (res.errorMsg) {
      datadogLogs.logger.error(
        `[ORDER_FORM]: Error retrieving order form id: ${this.orderForm?.id}, programId: ${this.orderForm?.programId}`,
        logContext({
          company: this.rootStore.common.companyStore.currentCompany,
          error: res.errorMsg,
        }),
      );

      runInAction(() => (this.getOrderFormError = true));
      return;
    }

    if (res.data) {
      const orderForm = res.data.order;

      const programName =
        orderForm?.programName && Programs[orderForm?.programName].display;
      const orderFormTitle = `${orderForm?.taxYear} ${programName}`;

      const federalOrderForm = orderForms.find(
        (order) =>
          order.programName === ProgramNameEnum.FED_RD_TAX &&
          order.taxYear === orderForm?.taxYear,
      );

      if (federalOrderForm) {
        const fedOrderFormId = federalOrderForm.id;
        // check if fed r&d has accepted order form when viewing state order form
        if (orderForm?.programName !== ProgramNameEnum.FED_RD_TAX) {
          // check if federal order form has accepted a promotion
          await this.getPromotionsForOrder(fedOrderFormId);

          runInAction(() => {
            this.hasAcceptedFedOrderForm =
              federalOrderForm?.acceptedAt !== null;
            this.federalOrderFormId = federalOrderForm?.programId;
            this.isFederalOrderForm = false;
          });

          if (
            !this.hasAcceptedFedOrderForm &&
            !IsExperimentalProgramWithNoQualFlow(programName as ProgramName)
          ) {
            runInAction(() => {
              (this.acceptButtonStatus = AcceptButtonStatus.DISABLED),
                (this.attestationAccepted = false);
            });
          }
        }

        if (
          federalOrderForm?.acceptedAt !== null ||
          orderForm.acceptedAt !== null
        ) {
          await this.getPromotionsForOrder(fedOrderFormId);
          runInAction(() => (this.attestationAccepted = true));
        }
      }

      if (orderForms) {
        const pendingOrders = orderForms.filter(
          (order) =>
            order.acceptedAt === null && IsRDCreditProgram(order.programName),
        );

        runInAction(() => (this.pendingOrderForms = pendingOrders));
      }

      if (currentProgram && orderForm) {
        runInAction(() => {
          this.isReceipt = orderForm.acceptedAt !== null;
          this.orderForm = orderForm;
          this.formTitle = orderFormTitle;
          this.currentProgram = currentProgram;
        });

        if (orderForm.acceptedAt || orderForm.acceptedBy) {
          const acceptedBy: AcceptedByData = {
            firstName: orderForm?.acceptedBy?.firstName || '',
            lastName: orderForm?.acceptedBy?.lastName || '',
            email: orderForm?.acceptedBy?.email || '',
          };

          const emptyEntry =
            orderForm?.acceptedBy?.firstName.length === 0 &&
            orderForm?.acceptedBy?.lastName.length === 0 &&
            orderForm?.acceptedBy?.email.length === 0;

          runInAction(() => {
            this.acceptedBy =
              !orderForm?.acceptedBy || emptyEntry ? null : acceptedBy;
            this.acceptedAt = orderForm?.acceptedAt;
          });
        }
      }
    }
  }

  public toggleOptInBOGO() {
    runInAction(() => (this.optInBOGO = !this.optInBOGO));
  }

  public acceptOrderFormLoading() {
    runInAction(() => {
      this.acceptButtonStatus = AcceptButtonStatus.LOADING;
    });
  }

  public async acceptOrderForm(programId: number, acceptedBy: AcceptedByData) {
    const res = await this.api.AcceptOrderForm(programId, acceptedBy);

    if (res.errorMsg) {
      runInAction(() => {
        datadogLogs.logger.error(
          `[ORDER_FORM]: Error accepting order form id: ${this.orderForm?.id}, programId: ${this.orderForm?.programId}`,
          logContext({
            company: this.rootStore.common.companyStore.currentCompany,
            error: res.errorMsg,
          }),
        );

        this.acceptErrorRes =
          res.errorMsg ||
          `There was an error accepting your order. Please refresh the page to reflect any recent changes to your account. If this persists, please reach out to support@mainstreet.com for assistance.`;
        return;
      });
    } else if (res.data) {
      await this.rootStore.common.companyStore.refreshCurrentCompany();

      const { acceptedOrder } = res.data;

      runInAction(() => {
        if (acceptedOrder) {
          if (acceptedOrder.acceptedBy) {
            const acceptedBy: AcceptedByData = {
              firstName: acceptedOrder.acceptedBy.firstName,
              lastName: acceptedOrder.acceptedBy.lastName,
              email: acceptedOrder.acceptedBy.email,
            };

            const emptyEntry =
              acceptedOrder.acceptedBy.firstName.length === 0 &&
              acceptedOrder.acceptedBy.lastName.length === 0 &&
              acceptedOrder.acceptedBy.email.length === 0;

            this.acceptedBy =
              !acceptedOrder.acceptedBy || emptyEntry ? null : acceptedBy;
          }
          if (acceptedOrder.acceptedAt) {
            this.acceptedAt = acceptedOrder.acceptedAt;
          }
        }
      });

      this.acceptButtonStatus = AcceptButtonStatus.ENABLED;
      this.acceptOrderFormModal = true;
      this.isReceipt = true;
    }
  }

  public setDefaultPaymentMethodV2(bool: boolean) {
    runInAction(() => (this.defaultPaymentMethodV2 = bool));
  }

  public setAcceptOrderFormModal(bool: boolean) {
    runInAction(() => (this.acceptOrderFormModal = bool));
  }

  public async acceptOrderFormPromotion(
    orderFormId: number,
    promotion: OrderFormPromotionNameEnum,
  ) {
    const res = await this.api.AcceptOrderFormPromotion(orderFormId, promotion);

    if (res.errorMsg) {
      datadogLogs.logger.error(
        `[ORDER_FORM]: Error accepting promotion with order form id: ${this.orderForm?.id}, programId: ${this.orderForm?.programId}`,
        logContext({
          company: this.rootStore.common.companyStore.currentCompany,
          error: res.errorMsg,
        }),
      );
      this.acceptErrorRes =
        res.errorMsg ||
        `There was an error accepting your order. Please refresh the page to reflect any recent changes to your account. If this persists, please reach out to support@mainstreet.com for assistance.`;
      return;
    }
  }

  public async getPromotionsForOrder(fedOrderFormId: number) {
    const res = await this.api.GetPromotionsForOrderForm(fedOrderFormId);
    if (res.errorMsg) {
      datadogLogs.logger.error(
        `[ORDER_FORM]: Error getting promotions for with order form id: ${fedOrderFormId}, programId: ${this.orderForm?.programId}`,
        logContext({
          company: this.rootStore.common.companyStore.currentCompany,
          error: res.errorMsg,
        }),
      );
      return;
    }

    if (res.data?.promotion) {
      const hasBOGOPromo =
        res.data?.promotion.promotionName === OrderFormPromotionNameEnum.BOGO;

      if (hasBOGOPromo) {
        runInAction(() => {
          this.hasAcceptedBOGO = true;
        });
      }
    } else {
      runInAction(() => {
        this.optInBOGO = false;
      });
    }
  }

  public setAttestationAccepted = () => {
    runInAction(() => {
      this.attestationAccepted = !this.attestationAccepted;
    });
  };
}
