import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Text, Link, Button } from 'component-library';
import {
  useTaxCreditsStores,
  useCommonStores,
  useFeatureFlags,
} from 'stores/useStores';
import {
  OrderForm,
  ProgramData,
  AcceptedByData,
  ProgramName,
} from 'lib/interfaces';
import { Page, Programs, OrderFormPromotionNameEnum } from 'lib/constants';
import { OrderFormAttestation } from './OrderFormAttestation';
import { AcceptButtonStatus } from 'products/tax-credits/entities/OrderFormEntity';
import { IsExperimentalProgramWithNoQualFlow } from '../../../../../../lib/helpers';

const useStyles = makeStyles(() => ({
  subtitles: {
    marginBottom: '8px',
  },
  subtitle: {
    display: 'inline-block',
    marginBottom: '8px',
  },
}));

interface OrderFormFooterProps {
  order: OrderForm;
  currentProgram: ProgramData;
  takeScreenshot: () => Promise<void>;
}

export const OrderFormFooter: React.FC<OrderFormFooterProps> = observer(
  ({ order, currentProgram, takeScreenshot }) => {
    const classes = useStyles();
    const { app, companyStore } = useCommonStores();
    const company = companyStore.currentCompany;
    const { orderForms } = useTaxCreditsStores();
    const featureFlags = useFeatureFlags();

    const acceptedBy: AcceptedByData = {
      firstName: app.common.auth.user?.givenName || '',
      lastName: app.common.auth.user?.familyName || '',
      email: app.common.auth.user?.email || '',
    };

    if (orderForms.isReceipt) {
      return (
        <Button
          label='Back to dashboard'
          onClick={() => app.history.push(Page.dashboard)}
          flexAlignSelf='flex-start'
        />
      );
    }

    return (
      <>
        <OrderFormAttestation
          companyName={company.legalName}
          checked={orderForms.attestationAccepted}
          onChange={orderForms.setAttestationAccepted}
          showAttestationCheckbox={featureFlags.showOrderFormAttestation}
        />

        {!orderForms.hasAcceptedFedOrderForm &&
          !orderForms.isFederalOrderForm &&
          !IsExperimentalProgramWithNoQualFlow(
            orderForms.orderForm?.programName as ProgramName,
          ) && (
            <Text status='in_process' dataTestId='unaccepted-order-link'>
              You must accept your{' '}
              <Link
                href={`/${Page.orderForm}/${orderForms.federalOrderFormId}`}
              >
                {' '}
                {currentProgram.taxYear}{' '}
                {Programs.federal_rd_tax_program.display} order form
              </Link>{' '}
              to be eligible for the {Programs[currentProgram.name].display}.
            </Text>
          )}

        <Button
          label='Accept'
          loading={orderForms.acceptButtonStatus === AcceptButtonStatus.LOADING}
          disabled={
            !orderForms.defaultPaymentMethodV2 ||
            orderForms.acceptButtonStatus === AcceptButtonStatus.DISABLED ||
            (featureFlags.showOrderFormAttestation &&
              !orderForms.attestationAccepted)
          }
          onClick={async () => {
            orderForms.acceptOrderFormLoading();
            await takeScreenshot();
            if (orderForms.optInBOGO) {
              await orderForms.acceptOrderFormPromotion(
                order.id,
                OrderFormPromotionNameEnum.BOGO,
              );
            }
            await orderForms.acceptOrderForm(order.programId, acceptedBy);
          }}
          flexAlignSelf='flex-start'
          dataTestId='accept-order-form-button'
        />
        {orderForms.acceptErrorRes && (
          <Text text={orderForms.acceptErrorRes} status='failed' />
        )}
      </>
    );
  },
);
