import * as React from 'react';
import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FileSelector } from 'components/util/upload/FileSelector';
import File from 'components/icons/File';
import { UploadInstructions } from 'components/util/upload/UploadInstructions';
import { Button } from 'components/util/Button';
import { Dialog, Divider } from '@material-ui/core';
import { Loading } from 'components/util/Loading';
import { UploadCompanyDocument } from 'services/server';
import { SupportLink } from 'components/util/SupportLink';
import { FileTypes } from 'lib/constants';

const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    marginTop: '20px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 40px',
  },
  error: {
    color: palette.error.main,
    padding: '20px 40px',
    fontSize: '14px',
    lineHeight: '1.4em',
  },
}));

const dialogUseStyles = makeStyles(() => ({
  paper: {
    borderRadius: 0,
    overflowY: (loading) => (loading ? 'hidden' : 'auto'),
  },
}));

export interface MissingEmployeeUploadProps {
  showModal: boolean;
  closeModal: () => void;
  companyId: number;
  onUploadComplete: () => Promise<void>;
}

export const MissingEmployeeUpload = ({
  showModal,
  closeModal,
  onUploadComplete,
  companyId,
}: MissingEmployeeUploadProps) => {
  const classes = useStyles();

  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const dialogClasses = dialogUseStyles(loading);

  const submit = async () => {
    setLoading(true);
    for (const file of files) {
      await UploadCompanyDocument(
        companyId,
        file,
        file.name,
        'Payroll document',
      );
    }
    onUploadComplete().catch(() => {
      setLoading(false);
      setError(true);
    });
  };

  return (
    <Dialog open={showModal} onClose={closeModal} classes={dialogClasses}>
      <Loading loading={loading}>
        <div className={classes.root}>
          <UploadInstructions>
            <p>Please upload one or more documents containing</p>
            <ul>
              <li>
                all the missing employees and contractors you’ve paid in 2020
              </li>
              <li>their work location</li>
              <li>their total amount paid this year</li>
              <li>their job title.</li>
            </ul>
          </UploadInstructions>
          <FileSelector
            acceptedFiletypes={[
              FileTypes.CSV,
              FileTypes.PDF,
              FileTypes.XLS,
              FileTypes.XLSX,
            ]}
            setFiles={setFiles}
            files={files}
          />
          <Divider className={classes.divider} />
          <div className={classes.buttons}>
            <Button disabled={files.length === 0} onClick={submit}>
              Submit
            </Button>
            <Button variant={'link'} onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </div>
        {error && (
          <div className={classes.error}>
            Oops! Something went wrong. Try again, and if the error persists,
            contact <SupportLink />
          </div>
        )}
      </Loading>
    </Dialog>
  );
};
