import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Checkbox, Content, Spinner, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { TokenContext } from 'pages/TokenRequired';
import {
  FinchPayrollProvider,
  ImportType,
  PayrollSystem,
} from 'lib/interfaces';
import { CompanyContext } from 'pages/CompanyRequired';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import {
  ConnectAccounting,
  ConnectPayrollCard,
  PayrollConnectedCard,
} from 'pages/dashboard/integrations';
import { Page } from 'lib/constants';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';

const useStyles = makeStyles(() => ({
  connectPayroll: {
    opacity: 0.5,
    cursor: 'not-allowed',
    '& > div > div': {
      pointerEvents: 'none',
    },
  },
}));

interface ConnectPayrollSystem {
  serviceName: FinchPayrollProvider | 'Manual';
  expired: boolean;
}

interface ConnectToPayrollProps {
  doNotUsePayroll: boolean;
  confirmDoThisLater: boolean;
  setConfirmDoThisLater: (bool: boolean) => void;
  setDoNotUsePayroll: (bool: boolean) => void;
  setShowContinueButton: (bool: boolean) => void;
  hasPayrollGap: boolean;
  programTaxYear: number;
  onAfterManual?: () => void;
}

const ConnectToPayroll = ({
  doNotUsePayroll,
  confirmDoThisLater,
  setConfirmDoThisLater,
  setDoNotUsePayroll,
  setShowContinueButton,
  hasPayrollGap,
  programTaxYear,
  onAfterManual,
}: ConnectToPayrollProps) => {
  const classes = useStyles();
  const { client } = useContext(Auth0FeatureContext);
  const { company, setCompany } = useContext(CompanyContext);
  const loggedIn = !!company;
  const [loading, setLoading] = useState(true);
  const { token, email } = useContext(TokenContext);
  const [payrollSystems, setPayrollSystems] = useState<ConnectPayrollSystem[]>(
    [],
  );
  const hasLinkedPayrollSystem = payrollSystems.length > 0;
  const [payrollConnectInProgress, setPayrollConnectInProgress] =
    useState<boolean>(false);

  const showConnectNewPayroll =
    !hasLinkedPayrollSystem ||
    (hasLinkedPayrollSystem && payrollConnectInProgress);

  const loadPayrollProviders = useCallback(
    (token?: string, email?: string) => {
      setLoading(true);
      client.FinchLinkedServices(token, email).then((providers) => {
        if (providers.errorMsg) {
          datadogLogs.logger.error(
            'loadPayrollProviders FinchLinkedServices error',
            logContext({
              error: providers.errorMsg,
              company,
            }),
          );
        } else {
          setPayrollSystems([
            ...providers!.data!.services.map((service) => {
              return {
                serviceName: service,
                expired: false,
              };
            }),
            ...providers!.data!.expiredServices.map((service) => {
              return {
                serviceName: service,
                expired: true,
              };
            }),
          ]);
        }
        setLoading(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client],
  );

  const onPayrollConnected = async () => {
    setLoading(true);
    setPayrollConnectInProgress(false);
    loadPayrollProviders(token, email);

    // For logged-in users, we need to refresh the company context so that the "payrollConnected" bit is set in the dashboard
    if (loggedIn) {
      await refreshCompany();
    }
    setLoading(false);
  };

  const refreshCompany = () => {
    return client.CurrentLoggedInCompany().then((company) => {
      if (company) {
        setCompany(company);
      }
    });
  };

  const moveToNextStep = async (option?: PayrollSystem) => {
    if (option) {
      await client.UpdateCompanyPayrollSystem({ payrollSystemId: option.id });
    }
    setConfirmDoThisLater(true);
  };

  useEffect(() => {
    loadPayrollProviders(token, email);
    if (hasLinkedPayrollSystem) {
      setShowContinueButton(true);
    }
  }, [
    email,
    token,
    loadPayrollProviders,
    hasLinkedPayrollSystem,
    setShowContinueButton,
  ]);

  return (
    <>
      <Text
        text='Connect your payroll to make this process more efficient'
        variant='medium'
        size={23}
        paddingBottom={8}
      />
      <Text text='We automatically pull data from your payroll provider to pre-fill the most time-consuming part of this process.' />
      {hasPayrollGap && (
        <Content paddingTop={24} paddingBottom={0}>
          <Alert
            actions={{
              href: `/${Page.connections}`,
              text: 'Fix payroll data gap',
            }}
            text="We noticed there's a gap in your payroll data. To get an accurate estimate, please fix this issue before continuing."
            type='warning'
          />
        </Content>
      )}

      {loading ? (
        <Content paddingTopBottom={24} flex justifyContent='center'>
          <Spinner size='small' color='emerald' />
        </Content>
      ) : (
        <>
          {payrollSystems.map((system, index) => (
            <PayrollConnectedCard
              key={index}
              payrollSystem={system.serviceName}
              onPayrollConnected={onPayrollConnected}
              expiredPayrollSystem={system.expired}
              connectAnother={
                !showConnectNewPayroll && index === payrollSystems.length - 1
              }
              setPayrollConnectInProgress={setPayrollConnectInProgress}
              useCardComponent
              importType={ImportType.ExpenseClassification}
              taxYear={programTaxYear}
            />
          ))}
          {showConnectNewPayroll && (
            <>
              <div
                className={
                  doNotUsePayroll || confirmDoThisLater
                    ? classes.connectPayroll
                    : ''
                }
              >
                <ConnectPayrollCard
                  onPayrollConnected={onPayrollConnected}
                  required={!hasLinkedPayrollSystem}
                  moveToNextStep={moveToNextStep}
                  showDoThisLaterModal
                  storeDropdownSelection
                  useCardComponent
                  importType={ImportType.ExpenseClassification}
                  taxYear={programTaxYear}
                  onAfterManual={onAfterManual}
                />
              </div>
              {payrollSystems.length === 0 && (
                <Content paddingTop={0} paddingBottom={24}>
                  <Checkbox
                    fontWeight='regular'
                    options={[
                      {
                        text: 'I do not use any payroll system listed above',
                        checked: doNotUsePayroll,
                        value: 'do_not_use',
                      },
                    ]}
                    onChange={() => {
                      setDoNotUsePayroll(!doNotUsePayroll);
                    }}
                  />
                </Content>
              )}
            </>
          )}

          {loggedIn &&
            false && ( // false to hide for now
              <ConnectAccounting
                onAccountingConnected={refreshCompany}
                returnPage={'connections'}
                useCardComponent
              />
            )}
        </>
      )}
    </>
  );
};

export default ConnectToPayroll;
