import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, Collapse, FormHelperText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
  Card,
  Content,
  Button,
  Divider,
  Notification,
} from 'component-library';
import { GraphCmsQuestionIdEnum } from 'lib/constants';
import { QuestionProps } from 'lib/interfaces';
import { InputQuestion, RadioQuestion } from 'components/questions';
import { vaccineCreditFaqs } from './faqs';
import {
  GetEmployeeMagicLinkPageData,
  SaveEmployeeMagicLinkResponse,
} from 'services/server';
import { Loading } from 'components/util/Loading';

const useStyles = makeStyles(
  ({ breakpoints, pageBackgroundColor, palette, darkerNavy }: Theme) => ({
    root: {
      background: pageBackgroundColor,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      minHeight: '100vh',
      padding: '50px 130px',
      boxSizing: 'border-box',
      [breakpoints.down('xs')]: {
        padding: '60px 24px',
      },
    },
    container: {
      width: '100%',
      maxWidth: 980,
      display: 'flex',
      flexDirection: 'column',
    },
    upperContent: {
      paddingBottom: '30px',
      [breakpoints.down('xs')]: {
        padding: '26px 20px',
      },
    },
    lowerContent: {
      paddingTop: '30px',
      [breakpoints.down('xs')]: {
        padding: '26px 20px',
      },
    },
    cardContent: {
      [breakpoints.down('xs')]: {
        padding: '26px 20px',
      },
    },
    header: {
      width: '100%',
      marginBottom: '36px',
    },
    partnership: {
      fontSize: '16px',
      margin: '10px 0 0 0',
      color: palette.primary.main,

      '& b': {
        fontWeight: 500,
      },
    },
    logo: {
      width: '200px',
    },
    title: {
      fontFamily: "'Alliance Platt', serif",
      fontSize: '48px',
      lineHeight: '60px',
      marginBottom: '24px',
      color: darkerNavy,
      [breakpoints.down('xs')]: {
        fontSize: '38px',
        lineHeight: '45px',
      },
    },
    subtitle: {
      fontSize: '20px',
      lineHeight: '30px',
      marginBottom: '24px',
      color: darkerNavy,
      [breakpoints.down('xs')]: {
        fontSize: '18px',
        lineHeight: '25px',
      },
    },
    description: {
      fontSize: '14px',
      lineHeight: '16px',

      '& p': {
        margin: '0 0 20px 0',
      },
    },
    inputQuestion: {
      marginBottom: '40px',
    },
    radioQuestion: {
      '& > div': {
        [breakpoints.down('xs')]: {
          flexDirection: 'column',
        },
      },
    },
    faqContainer: {
      maxWidth: '660px',
      marginTop: '60px',
    },
    faqTitle: {
      fontSize: '25px',
      lineHeight: '50px',
      marginBottom: '32px',
      color: darkerNavy,
      [breakpoints.down('xs')]: {
        fontSize: '18px',
        lineHeight: '25px',
      },
    },
    faqItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    faqQuestion: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    faqDivider: {
      margin: '20px 0',
    },
    faqAnswer: {
      fontSize: '16px',
      lineHeight: '22px',
      marginBottom: '20px',
    },
    arrow: {
      marginLeft: '20px',
      cursor: 'pointer',

      '& svg': {
        fill: '#CFD4D7',
      },
    },
    notification: {
      marginBottom: '30px',
    },
    pageError: {
      fontSize: '18px',
    },
  }),
);

interface PageProps {
  companyId: number;
  employeeId: number;
  tokenPurpose: string;
  token: string;
  faqs?: FaqData[];
}
interface EmployeeData {
  companyName: string;
  name: string;
}
interface FaqData {
  question: string;
  answerBody: AnswerBody[];
  active: boolean;
}

interface AnswerBody {
  type: string;
  content: string;
}

export const EmployeeSickLeavePage = ({
  companyId,
  employeeId,
  tokenPurpose,
  token,
}: PageProps) => {
  const classes = useStyles();

  const hoursOffQuestionData = {
    id: 0,
    type: 'input',
    label: 'Hours',
    text:
      'Since April 1, how many hours did you take off to receive and/or recover from the COVID-19 vaccine?',
    active: true,
  };
  const daysOffQuestionData = {
    id: 1,
    type: 'radio',
    text: 'Over how many days was this time off?',
    active: true,
    options: [
      {
        label: '1',
        value: '1',
      },
      {
        label: '2',
        value: '2',
      },
      {
        label: '3',
        value: '3',
      },
      {
        label: '4',
        value: '4',
      },
      {
        label: '5',
        value: '5',
      },
      {
        label: '6',
        value: '6',
      },
      {
        label: '7',
        value: '7',
      },
    ],
  };

  const [employeeData, setEmployeeData] = useState<EmployeeData>({
    companyName: '',
    name: '',
  });
  const [employeeDataFetched, setEmployeeDataFetched] = useState<boolean>(
    false,
  );
  const [faqBank, setFaqBank] = useState(vaccineCreditFaqs);
  const [hoursOffQuestion, setHoursOffQuestion] = useState<QuestionProps>(
    hoursOffQuestionData,
  );
  const [daysOffQuestion, setDaysOffQuestion] = useState<QuestionProps>(
    daysOffQuestionData,
  );
  const [hoursOff, setHoursOff] = useState<number | null>(null);
  const [daysOff, setDaysOff] = useState<number | null>(0);
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [inputError, setInputError] = useState<string | null>(null);
  const [radioError, setRadioError] = useState<string | null>(null);
  const [pageError, setPageError] = useState<string>('');
  const [formError, setFormError] = useState<string>('');

  useEffect(() => {
    GetEmployeeMagicLinkPageData(
      companyId,
      employeeId,
      tokenPurpose,
      token,
    ).then((res) => {
      if (!res.data) {
        setPageError(res.errorMsg ? res.errorMsg : 'Something went wrong');
        setEmployeeDataFetched(true);
        return;
      }

      const { employeeData, questionData } = res.data;
      if (employeeData) {
        setEmployeeData(res.data.employeeData);
      }
      if (questionData) {
        const hoursOffQuestionData =
          questionData[GraphCmsQuestionIdEnum.HOURS_OFF_FOR_VACCINE];
        const daysOffQuestionData =
          questionData[GraphCmsQuestionIdEnum.DAYS_OFF_FOR_VACCINE];
        if (hoursOffQuestionData) {
          hoursOffQuestion.text =
            questionData[GraphCmsQuestionIdEnum.HOURS_OFF_FOR_VACCINE].text;
          setHoursOffQuestion(hoursOffQuestion);
        }
        if (daysOffQuestionData) {
          daysOffQuestion.text =
            questionData[GraphCmsQuestionIdEnum.DAYS_OFF_FOR_VACCINE].text;
          setDaysOffQuestion(daysOffQuestion);
        }
      }
      setEmployeeDataFetched(true);
    });
    // eslint-disable-next-line
  }, [employeeDataFetched]);

  const handleArrowClick = (index: number) => {
    const faqClicked = faqBank[index];
    if (faqClicked) {
      faqClicked.active = !faqClicked.active;
      setFaqBank([...faqBank]);
    }
  };

  const renderAnswer = (answer: AnswerBody) => {
    return (
      <p key={`${answer.content}`} className={classes.faqAnswer}>
        {answer.content}
      </p>
    );
  };

  const renderFaq = (faq: FaqData, index: number) => {
    return (
      <Card key={`faq-${index}`}>
        <Content className={classes.cardContent}>
          <div className={classes.faqItem}>
            <div className={classes.faqQuestion}>{faq.question}</div>
            <div
              className={classes.arrow}
              onClick={() => handleArrowClick(index)}
            >
              {faq.active ? <ExpandLess /> : <ExpandMore />}
            </div>
          </div>
          <Collapse in={faq.active} timeout='auto' unmountOnExit>
            <Divider className={classes.faqDivider} variant='full-width' />
            {faq.answerBody && faq.answerBody.map(renderAnswer)}
          </Collapse>
        </Content>
      </Card>
    );
  };

  const submitForm = () => {
    setFormError('');
    setInputError('');
    setRadioError('');
    const invalidHoursOff = !hoursOff && hoursOff !== 0;
    const invalidDaysOff = hoursOff && !daysOff;
    if (invalidHoursOff) {
      setInputError('This field cannot be left blank');
      return;
    }
    if (invalidDaysOff) {
      setRadioError('This field cannot be left blank');
      return;
    }

    const formValues = {
      [GraphCmsQuestionIdEnum.HOURS_OFF_FOR_VACCINE]: hoursOff,
      [GraphCmsQuestionIdEnum.DAYS_OFF_FOR_VACCINE]: daysOff,
    };
    setButtonLoading(true);
    SaveEmployeeMagicLinkResponse(
      companyId,
      employeeId,
      tokenPurpose,
      token,
      formValues,
    ).then((res) => {
      setButtonLoading(false);

      if (res.errorMsg) {
        setFormError('Something went wrong');
        return;
      }

      setFormSuccess(true);
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          <img
            className={classes.logo}
            src={`${process.env.PUBLIC_URL}/images/MainSt_WordmarkGreen.svg`}
            alt='MainStreet logo'
          />
          {employeeData.companyName && (
            <div className={classes.partnership}>
              In partnership with <b>{employeeData.companyName}</b>
            </div>
          )}
        </div>
        {pageError && (
          <FormHelperText className={classes.pageError} error>
            {pageError}
            <div>Please reach out to your account admin for assistance</div>
          </FormHelperText>
        )}

        <Loading loading={!employeeDataFetched}>
          {employeeData.name && (
            <>
              {!formSuccess && (
                <div>
                  <div className={classes.title}>
                    {employeeData.name}, did you take time off for the vaccine?
                  </div>
                  <div className={classes.subtitle}>
                    {employeeData.companyName} is requesting some information
                    about your paid sick leave.
                  </div>
                  <div className={classes.description}>
                    <p>
                      This information will be used so that your company can
                      claim a credit for the time you used to receive and
                      recover from the vaccine. Your answers will not impact
                      your time off, personal taxes or health insurance in any
                      way and they will only be visible to a select few
                      MainStreet admins and our payroll system.
                    </p>
                    <p>Questions? See our FAQ section below.</p>
                  </div>

                  <Card>
                    <Content className={classes.upperContent}>
                      <InputQuestion
                        className={classes.inputQuestion}
                        question={hoursOffQuestion}
                        type='number'
                        errorMessage={inputError}
                        onChange={(event) => {
                          setHoursOff(parseInt(event.target.value));
                        }}
                      />
                      {hoursOff !== 0 && (
                        <RadioQuestion
                          className={classes.radioQuestion}
                          question={daysOffQuestion}
                          optionDirection='row'
                          errorMessage={radioError}
                          onChange={(event) => {
                            setDaysOff(parseInt(event.target.value));
                          }}
                        />
                      )}
                    </Content>
                    <Divider variant='full-width' />
                    <Content className={classes.lowerContent}>
                      <Button
                        label='Submit'
                        loading={buttonLoading}
                        onClick={submitForm}
                      />
                      {formError && (
                        <FormHelperText error>{formError}</FormHelperText>
                      )}
                    </Content>
                  </Card>
                </div>
              )}
            </>
          )}
          {formSuccess && (
            <div>
              <Notification
                className={classes.notification}
                label='success'
                text='We received your information'
              />
              <div className={classes.title}>
                Thank you {employeeData.name}!
              </div>
              <div className={classes.subtitle}>
                Feel free to navigate away or close this tab.
              </div>
              <div className={classes.subtitle}>
                If you take more time in the future please come back and log any
                additional time.
              </div>
            </div>
          )}

          {faqBank.length > 0 && (
            <div className={classes.faqContainer}>
              <div className={classes.faqTitle}>
                Frequently Asked Questions - Vaccine Credit
              </div>
              {faqBank.map(renderFaq)}
            </div>
          )}
        </Loading>
      </div>
    </div>
  );
};
