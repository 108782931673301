import { makeSubclassObservable } from 'lib/mobx-utils';
import { BaseEntity } from 'entities/BaseEntity';
import { runInAction } from 'mobx';
import { ResourceCardFilter } from 'products/dashboard/entities/ResourceCardFilter';

export interface ResourceCardEntityData {
  title: string;
  subtext: string;
  category: string;
  imageUrl: string;
  resourceUrl: string;
  priority: number;
  availableProgramStages: string[];
}

export class ResourceCardEntity extends BaseEntity {
  title = '';
  subtext = '';
  category = '';
  imageUrl = '';
  resourceUrl = '';
  priority = 0;
  applicableFilters: ResourceCardFilter[] = [];

  constructor(data: ResourceCardEntityData) {
    super();
    makeSubclassObservable(this);

    this.mergeData(data);
  }

  public mergeData(data: Partial<ResourceCardEntityData>) {
    runInAction(() => {
      this.title = data.title || '';
      this.subtext = data.subtext || '';
      this.priority = data.priority || 0;
      this.category = data.category || '';
      this.imageUrl = data.imageUrl || '';
      this.resourceUrl = data.resourceUrl || '';
      if (data?.availableProgramStages?.length) {
        this.applicableFilters = data.availableProgramStages.map(
          (item) => new ResourceCardFilter(item),
        );
      }
    });
  }
}

export enum GradientImage {
  GRADIENT_1 = 1,
  GRADIENT_2 = 2,
  GRADIENT_3 = 3,
  GRADIENT_4 = 4,
  GRADIENT_5 = 5,
  GRADIENT_6 = 6,
  GRADIENT_7 = 7,
  GRADIENT_8 = 8,
  GRADIENT_9 = 9,
  GRADIENT_10 = 10,
  GRADIENT_11 = 11,
  GRADIENT_12 = 12,
  GRADIENT_13 = 13,
  GRADIENT_14 = 14,
  GRADIENT_15 = 15,
}
