import React from 'react';
import { observer } from 'mobx-react';
import { Flex } from 'component-library';
import {
  FooterSection,
  HeroSection,
  HowItWorksSection,
  IRSGuidelinesSection,
  StartSavingSection,
  CreditsSection,
  ComingSoon,
} from './sections';
import { SignUpModal } from './SignUpModal';
import { AlertSection } from './sections/AlertSection';
import DeadlinesSection from './sections/DeadlinesSection';
import { useFeatureFlags } from '../../../../../stores/useStores';

export const LandingPage: React.FC = observer(() => {
  const featureFlags = useFeatureFlags();

  return (
    <Flex direction='column'>
      {featureFlags.getShowUrgencyBanner && <AlertSection />}
      <HeroSection />
      <CreditsSection />
      {featureFlags.getShowTaxDeadlines && <DeadlinesSection />}
      <HowItWorksSection />
      <IRSGuidelinesSection />
      <ComingSoon />
      <StartSavingSection />
      <FooterSection />
      <SignUpModal />
    </Flex>
  );
});
