import React from 'react';
import _styles from './grid.module.scss';
import { Gap, Padding } from '../_helpers/constants';

import classnames from 'classnames/bind';
const styles = classnames.bind(_styles);

const formatToCSS = (value: Padding | Gap) => {
  return Array.isArray(value)
    ? value.map((val) => `${val}px`).join(' ')
    : `${value}px`;
};

export interface GridCellProps {
  children?: React.ReactElement | JSX.Element;
  className?: string;
  dataTestId?: string;
  columns?: number; // Defaults to 1 for inference, with max of 12
  rows?: number; // Defaults to 1 for inference
  padding?: Padding;
  alignSelf?: React.CSSProperties['alignSelf'];
  justifySelf?: React.CSSProperties['justifySelf'];
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const GridCell: React.FC<GridCellProps> = ({
  children,
  className,
  dataTestId,
  columns = 1,
  rows,
  padding,
  alignSelf,
  justifySelf,
  style,
  onClick,
}) => {
  const composedClassname = classnames(
    styles('grid-cell', `span-${columns}-columns`),
    className,
  );

  const composedStyles: React.CSSProperties = {
    alignSelf,
    justifySelf,
    ...style,
  };

  if (rows && rows > 1) {
    composedStyles.gridRow = `span ${rows}`;
  }

  if (padding) {
    composedStyles.padding = formatToCSS(padding);
  }

  return (
    <div
      className={composedClassname}
      style={composedStyles}
      data-testid={dataTestId}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export interface GridProps {
  children: React.ReactNode | JSX.Element;
  className?: string;
  dataTestId?: string;
  columns?: 2 | 3 | 4 | 8 | 12; // Defaults to inferring (with max of 12)
  rows?: number; // Defaults to inferring
  gap?: Gap;
  padding?: Padding;
  alignItems?: React.CSSProperties['alignItems'];
  justifyItems?: React.CSSProperties['justifyItems'];
  alignContent?: React.CSSProperties['alignContent'];
  justifyContent?: React.CSSProperties['justifyContent'];
  style?: React.CSSProperties;
  columnsMinContent?: boolean;
  backgroundColor?: React.CSSProperties['backgroundColor'];
  onClick?: () => void;
}

export const Grid: React.FC<GridProps> = ({
  children,
  className,
  dataTestId,
  columns,
  rows,
  gap,
  padding,
  alignItems,
  alignContent,
  justifyContent,
  justifyItems,
  columnsMinContent,
  backgroundColor,
  style,
  onClick,
}) => {
  let cellRows = 0;
  let cellColumns = 0;
  React.Children.toArray(children).forEach((child) => {
    if (React.isValidElement(child) && child.type === GridCell) {
      const props = child.props as GridCellProps;
      cellColumns += props.columns || 1;

      if (props.rows) {
        cellRows += props.rows;
      }
    } else {
      cellColumns++;
    }
  });

  const composedStyles: React.CSSProperties = {
    alignItems,
    justifyItems,
    alignContent,
    justifyContent,
    backgroundColor,
    ...style,
  };

  const composedClassname = classnames(
    styles(
      'grid',
      `columns-${
        (columnsMinContent && 'min-content') ||
        columns ||
        Math.min(cellColumns, 12)
      }`,
    ),
    className,
  );

  if (rows || cellRows) {
    composedStyles.gridTemplateRows = `repeat(${rows || cellRows}, 1fr)`;
  }

  if (padding) {
    composedStyles.padding = formatToCSS(padding);
  }

  if (gap) {
    composedStyles.gap = formatToCSS(gap);
  }

  return (
    <div
      className={composedClassname}
      style={composedStyles}
      data-testid={dataTestId}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Object.assign(Grid, {
  Cell: GridCell,
});
