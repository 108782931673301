import * as z from 'zod';
import { DateStringSchema } from './globalInterfaces';

export enum PayrollState {
  SEMI_AUTOMATIC = 'SEMI_AUTOMATIC',
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

export const ImportDataSchema = z.object({
  lastPayStatementOfTaxYear: DateStringSchema.optional(),
  firstPayStatementOfTaxYear: DateStringSchema.optional(),

  inProgress: z
    .object({
      startedAt: DateStringSchema,
    })
    .optional(),

  lastSuccess: z
    .object({
      startedAt: DateStringSchema,
      endedAt: DateStringSchema.optional(),
    })
    .optional(),

  possiblePayrollMigration: z.boolean(),
  payrollState: z.nativeEnum(PayrollState),
  isConnected: z.boolean(),
});
export type PayrollImportData = z.infer<typeof ImportDataSchema>;
