import React, { LegacyRef, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useFeatureFlags, useTaxCreditsStores } from 'stores/useStores';
import { OrderForm, ProgramData } from 'lib/interfaces';
import { ProgramNameEnum } from 'lib/constants';
import { useTakeScreenshot } from 'lib/useTakeScreenshot';
import { CentsToDisplayString } from 'lib/helpers';
import {
  CashAdvanceContent,
  IncludedServicesContent,
  MainStreetFeeContent,
  SummaryContent,
  TaxCreditsContent,
  ZeroAvgMonthlyPayroll,
} from '../components';
import { Auth0FeatureContext } from '../../../../../components/util/Auth0Feature';
import { Color } from 'component-library';
import { BogoDealSectionCard } from './BogoDeal';
import { SectionCardWithHeader, SectionCard } from './SectionCard';
import { AddPaymentMethod } from './AddPaymentMethod';
import { AcceptedOrderFormModal } from './AcceptedOrderFormModal';
import { AcceptedByAlert } from './AcceptedByAlert';
import { OrderFormHeader } from './OrderFormHeader';
import { OrderFormFooter } from './OrderFormFooter';
import { AutorenewalNoticeContent } from './AutorenewalNoticeContent';

interface OrderFormContentProps {
  order: OrderForm;
  currentProgram: ProgramData;
}

export const OrderFormContent: React.FC<OrderFormContentProps> = observer(
  ({ order, currentProgram }) => {
    const { orderForms } = useTaxCreditsStores();
    const featureFlags = useFeatureFlags();
    const { client } = useContext(Auth0FeatureContext);
    const { takeScreenshotAndSave, ref } = useTakeScreenshot(
      client,
      'orderForm screenshot',
      'jpg',
      'orderForm screenshot',
      currentProgram.id,
    );

    // Programs that are eligible for BOGO
    const bogoConfig: {
      [key: string]: boolean;
    } = {
      [ProgramNameEnum.FED_RD_TAX]: true,
      [ProgramNameEnum.FED_DISABLED_ACCESS]: false,
      [ProgramNameEnum.FED_EMPLOYER_HEALTHCARE]: false,
      [ProgramNameEnum.FED_RETIREMENT_CREDIT]: false,
    };

    // Whether BOGO is available for a given year
    const showBogoDealByTaxYear: { [key: number]: boolean } = {
      2022: featureFlags.show2022BOGODeal,
      2023: featureFlags.show2023BOGODeal,
    };

    // Should we show BOGO on the order form?
    const showBogoDeal =
      bogoConfig[currentProgram.name] &&
      (showBogoDealByTaxYear[currentProgram.taxYear] ||
        (orderForms.isReceipt && orderForms.hasAcceptedBOGO));

    useEffect(() => {
      takeScreenshotAndSave().then();
      // Note: Given that the screenshot needs to capture all orderform components, any dependencies need to be added below so that the screenshot recaptures when that dependency has loaded.
    }, [showBogoDeal, orderForms.acceptedAt, orderForms.isReceipt]);

    /// carried over from existing order form page
    const CA_RD_MIN_ACV_FLOOR_IN_CENTS = 10000;

    let hasMinAcvFloor = false;
    if (order) {
      hasMinAcvFloor =
        order.programName === ProgramNameEnum.STATE_RD_CA &&
        order.totalFeeCents === CA_RD_MIN_ACV_FLOOR_IN_CENTS;

      // if hasMinAcvFloor is true here, double check that the fee is actually greater
      // than the total credit * plan percentage (e.g. the fee has been raised)
      hasMinAcvFloor =
        hasMinAcvFloor &&
        Math.floor(
          (order.totalFeeCents / order.estimatedTotalCreditCents) * 100,
        ) > order.planPercentage;
    }

    const estimateTotal = CentsToDisplayString(
      order.estimatedTotalCreditCents,
      0,
      0,
    );
    const mainStreetFee = CentsToDisplayString(order.totalFeeCents, 0, 0);
    const totalBenefitsCents =
      showBogoDeal && orderForms.optInBOGO
        ? CentsToDisplayString(order.estimatedTotalCreditCents, 0, 0)
        : CentsToDisplayString(
            order.estimatedTotalCreditCents - order.totalFeeCents,
            0,
            0,
          );

    if (order.estimatedTotalCreditCents === 0) {
      return (
        <div ref={ref as LegacyRef<HTMLDivElement>}>
          <ZeroAvgMonthlyPayroll />
        </div>
      );
    }

    return (
      <div ref={ref as LegacyRef<HTMLDivElement>}>
        {orderForms.isReceipt && orderForms.acceptedAt && <AcceptedByAlert />}
        <OrderFormHeader
          order={order}
          currentProgram={currentProgram}
          isBogoDeal={showBogoDeal && orderForms.optInBOGO}
        />

        <SectionCardWithHeader
          sectionTitle={`Estimated ${orderForms.formTitle}`}
          estimateTotal={estimateTotal}
          gridMinContent
        >
          <TaxCreditsContent program={currentProgram} />
        </SectionCardWithHeader>

        {showBogoDeal ? (
          <BogoDealSectionCard
            mainStreetFee={mainStreetFee}
            programTaxYear={currentProgram.taxYear}
          >
            <MainStreetFeeContent
              expandSchedule={!orderForms.optInBOGO}
              hasMinAcvFloor={hasMinAcvFloor}
              termStartDate={order.termStartDate}
              monthlyFeeCents={order.monthlyFeeCents}
              numFeeMonths={order.numFeeMonths}
              program={currentProgram}
            />
          </BogoDealSectionCard>
        ) : (
          <SectionCardWithHeader
            sectionTitle='Mainstreet fee'
            estimateTotal={mainStreetFee}
            estimateColor={Color.neutral._90}
            gridMinContent
          >
            <MainStreetFeeContent
              expandSchedule
              hasMinAcvFloor={hasMinAcvFloor}
              termStartDate={order.termStartDate}
              monthlyFeeCents={order.monthlyFeeCents}
              numFeeMonths={order.numFeeMonths}
              program={currentProgram}
            />
          </SectionCardWithHeader>
        )}

        {order.totalAdvanceCents > 0 && order.numAdvanceMonths > 0 && (
          <SectionCardWithHeader
            sectionTitle='Cash advance'
            estimateTotal={CentsToDisplayString(order.totalAdvanceCents, 0, 0)}
            gridMinContent
          >
            <CashAdvanceContent order={order} />
          </SectionCardWithHeader>
        )}

        {currentProgram.name === ProgramNameEnum.FED_RD_TAX && (
          <SectionCardWithHeader sectionTitle='Included services'>
            <IncludedServicesContent />
          </SectionCardWithHeader>
        )}

        <SectionCardWithHeader sectionTitle='Summary'>
          <SummaryContent
            creditTitle={orderForms.formTitle}
            mainStreetFee={mainStreetFee}
            estimateTotal={estimateTotal}
            netBenefit={totalBenefitsCents}
            isBogoDeal={showBogoDeal && orderForms.optInBOGO}
            programTaxYear={currentProgram.taxYear}
          />
        </SectionCardWithHeader>

        {!orderForms.isReceipt && (
          <SectionCard>
            <AddPaymentMethod />
          </SectionCard>
        )}

        {featureFlags.showOrderFormAutorenewalNotice && (
          <SectionCardWithHeader sectionTitle='Auto-renewal notice'>
            <AutorenewalNoticeContent />
          </SectionCardWithHeader>
        )}

        <OrderFormFooter
          order={order}
          currentProgram={currentProgram}
          takeScreenshot={takeScreenshotAndSave}
        />

        <AcceptedOrderFormModal
          showModal={orderForms.acceptOrderFormModal}
          onClose={() => orderForms.setAcceptOrderFormModal(false)}
          multiplePendingOrders={orderForms.pendingOrderForms?.length > 1}
          programTaxYear={currentProgram.taxYear}
        />
      </div>
    );
  },
);
