import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Link,
  SideDrawer,
  Text,
} from 'component-library';
import { ProgramData } from 'lib/interfaces';
import { observer } from 'mobx-react';
import { useTaxCreditsStores } from 'stores/useStores';
import { makeStyles } from '@material-ui/core';
import { CentsToDisplayString } from '../../../../lib/helpers';

interface RipplingForm8974SideDrawerProps {
  program: ProgramData;
  show: boolean;
  onCancel: () => void;
  onComplete: (programId: number) => void;
}

const useStyles = makeStyles(() => ({
  checkbox: {
    '& label': {
      'margin-bottom': 0,
    },
  },
}));

export const RipplingForm8974SideDrawer = observer(
  ({
    program,
    show,
    onCancel,
    onComplete,
  }: RipplingForm8974SideDrawerProps) => {
    const classes = useStyles();
    const { form8974 } = useTaxCreditsStores();
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const DATA_TEST_ID_PREFIX = 'rippling-8974-side-drawer';

    const updateProgram8974SetupConfirmation = async () => {
      await form8974.handleCompletePayrollProviderSetup(program.id);
      if (!form8974.requestError) {
        onComplete(program.id);
      }
    };

    return (
      <SideDrawer
        dataTestId={DATA_TEST_ID_PREFIX}
        show={show}
        title='Set up credit redemptions'
        alertMessage={
          'Complete these steps today to receive a timely tax refund.'
        }
        alertType={'warning'}
        alertMessagePosition={'bottom'}
        closeToggle={onCancel}
        drawerActions={
          <>
            <Button
              label='Finish'
              disabled={!confirmed}
              loading={form8974.payrollProviderConfirmationProcessing}
              onClick={() => updateProgram8974SetupConfirmation()}
              dataTestId={`${DATA_TEST_ID_PREFIX}-confirm`}
            />
            <Button
              label='Back'
              variant='outlined'
              onClick={onCancel}
              dataTestId={`${DATA_TEST_ID_PREFIX}-cancel`}
            />
            {form8974.requestError && (
              <Text color={'red'}>{form8974.requestError}</Text>
            )}
          </>
        }
        drawerContent={
          <>
            <Text variant='medium' paddingBottom={8}>
              How to use credits to get your tax refunds
            </Text>
            <Divider />
            <Text>
              You must complete these steps as soon as possible. If you do not
              complete these steps in time, your refund may be delayed by 9+
              months.
            </Text>
            <ol>
              <li>
                <Text>
                  Go to&nbsp;
                  <Link
                    external
                    href='https://app.rippling.com/payroll/research-and-development-credits'
                    target='_blank'
                  >
                    your Rippling dashboard&nbsp;
                  </Link>
                  to enter your credit information.
                  <ul>
                    <li>
                      Don’t use Rippling anymore?&nbsp;
                      <Link href='/connections'>Click here&nbsp;</Link>
                      to update your provider and get new instructions.
                    </li>
                  </ul>
                </Text>
              </li>
              <br />
              <li>
                <Text>
                  If you did not make any changes to the Form 6765 that
                  MainStreet generated, enter your credit amount as&nbsp;
                  {program.formData?.box44
                    ? CentsToDisplayString(program.formData?.box44 * 100)
                    : 'the amount found on Line 44.'}
                  .
                </Text>
              </li>
              <br />
              <li>
                <Text>
                  Return here to confirm that you’ve completed these steps. This
                  is essential in order to properly track your credit.
                </Text>
              </li>
            </ol>
            <br />
            <Text variant='medium' paddingBottom={8}>
              What happens next?
            </Text>
            <Divider />
            <ul>
              <li>
                <Text>Rippling will automatically apply your credits.</Text>
              </li>
              <br />
              <li>
                <Text>
                  After the IRS processes your payroll tax return each quarter,
                  you’ll receive a tax refund!
                  <ul>
                    <li>
                      For PEO users: Rippling will send your refunds to your
                      bank account
                    </li>
                    <li>
                      For non-PEO users: The IRS will directly send you a refund
                      check
                    </li>
                  </ul>
                </Text>
              </li>
              <br />
              <li>
                <Text>
                  You can track your credit usage in your &nbsp;
                  <Link
                    external
                    href='https://app.rippling.com/payroll/dashboard/settings?section=federal+r%26d+credit'
                    target='_blank'
                  >
                    Rippling R&D portal
                  </Link>
                  .
                </Text>
              </li>
              <br />
            </ul>
          </>
        }
        drawerFooterContent={
          <Checkbox
            dataTestId='confirmation-checkbox'
            className={classes.checkbox}
            onChange={() => setConfirmed(!confirmed)}
            options={[
              {
                checked: confirmed,
                text: 'I have completed the setup in Rippling',
                value: 'confirmed',
              },
            ]}
          />
        }
      />
    );
  },
);
