import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Flex, Spinner } from 'component-library';
import {
  ConnectPayrollSystem,
  PayrollSystem,
  ImportType,
} from 'lib/interfaces';
import {
  ConnectPayrollCard,
  PayrollConnectedCard,
} from 'pages/dashboard/integrations';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import { TokenContext } from 'pages/TokenRequired';
import { useCompany, useLegacyClients } from 'stores/useStores';
import { observer } from 'mobx-react';

interface ConnectPayrollProps {
  taxYear: number;
  setDoThisLater: () => void;
  subtitle?: string;
  doThisLaterModalTitle?: string;
  doThisLaterModalContent?: string;
  importType: ImportType;
}

export const ConnectPayroll: React.FC<ConnectPayrollProps> = observer(
  ({
    taxYear,
    setDoThisLater,
    subtitle,
    doThisLaterModalTitle = 'Are you sure?',
    doThisLaterModalContent = 'Connecting your payroll will help MainStreet provide you with the most accurate estimate for the credits you can earn.',
    importType,
  }) => {
    const [loading, setLoading] = useState(true);
    const [payrollSystems, setPayrollSystems] = useState<
      ConnectPayrollSystem[]
    >([]);

    const { client } = useLegacyClients();
    const { company } = useCompany();
    const { token, email } = useContext(TokenContext);
    const loggedIn = !!company;

    const loadPayrollProviders = useCallback(
      (token?: string, email?: string) => {
        setLoading(true);
        client.FinchLinkedServices(token, email).then((providers) => {
          if (providers.errorMsg) {
            datadogLogs.logger.error(
              'loadPayrollProviders FinchLinkedServices error',
              logContext({
                error: providers.errorMsg,
                company,
              }),
            );
          } else {
            if (
              providers?.data &&
              providers.data.services &&
              providers.data.expiredServices
            ) {
              setPayrollSystems([
                ...providers.data.services.map((service) => {
                  return {
                    serviceName: service,
                    expired: false,
                  };
                }),
                ...providers.data.expiredServices.map((service) => {
                  return {
                    serviceName: service,
                    expired: true,
                  };
                }),
              ]);
            }
          }
        });
        setLoading(false);
      },
      [client, company],
    );

    const refreshCompany = () => {
      return client.CurrentLoggedInCompany().then((companyData) => {
        if (companyData) {
          company.mergeData(companyData);
        }
      });
    };

    const onPayrollConnected = async () => {
      setLoading(true);
      loadPayrollProviders(token, email);

      if (loggedIn) {
        await refreshCompany();
      }
      setLoading(false);
      setDoThisLater(); // after connection, move to next question
    };

    useEffect(() => {
      loadPayrollProviders(token, email);
    }, [email, token, loadPayrollProviders]);

    const moveToNextStep = async (option?: PayrollSystem) => {
      if (option) {
        await client.UpdateCompanyPayrollSystem({ payrollSystemId: option.id });
        refreshCompany();
      }
    };

    const renderPayrollConnect = () => {
      return (
        <>
          {loading ? (
            <Flex justifyContent='center' padding={24}>
              <Spinner size='small' />
            </Flex>
          ) : (
            <Flex direction='column'>
              {payrollSystems.length > 0 ? (
                payrollSystems.map((system, index) => (
                  <React.Fragment key={index}>
                    <PayrollConnectedCard
                      payrollSystem={system.serviceName}
                      onPayrollConnected={onPayrollConnected}
                      expiredPayrollSystem={system.expired}
                      connectAnother={false}
                      useCardComponent
                      importType={importType}
                      taxYear={taxYear}
                      noMargin
                    />
                  </React.Fragment>
                ))
              ) : (
                <ConnectPayrollCard
                  importType={importType}
                  moveToNextStep={moveToNextStep}
                  onPayrollConnected={onPayrollConnected}
                  required={false}
                  showDoThisLaterModal
                  storeDropdownSelection={true}
                  taxYear={taxYear}
                  useCardComponent
                  onDoThisLater={() => {
                    moveToNextStep();
                    setDoThisLater();
                  }}
                  doThisLaterModalTitle={doThisLaterModalTitle}
                  doThisLaterModalContent={doThisLaterModalContent}
                  noMargin
                  subtitle={subtitle}
                />
              )}
            </Flex>
          )}
        </>
      );
    };

    return renderPayrollConnect();
  },
);
