import { Grid, Link, Text } from 'component-library';
import React from 'react';
import BillingSection from './BillingSection';
import SettingsSection from './SettingsSection';
import ConnectionsSection from './ConnectionsSection';
import { useCompany, useFeatureFlags } from 'stores/useStores';
import { observer } from 'mobx-react';
import CreditsSection from './CreditsSection';
import CreditEstimateHistory from './CreditEstimateHistory';

type AccountModuleSectionProps = {
  title: string;
  children: JSX.Element | JSX.Element[];
};

const DATA_TEST_ID_PREFIX = 'dashboard-account-module';

export const AccountModuleSection = ({
  title,
  children,
}: AccountModuleSectionProps) => {
  return (
    <Grid.Cell
      padding={0}
      dataTestId={`${DATA_TEST_ID_PREFIX}-${title.toLowerCase()}-section`}
    >
      <Text variant={'medium'} size={18} paddingBottom={8}>
        {title}
      </Text>
      {children}
    </Grid.Cell>
  );
};

export type AccountModuleLinkItemProps = {
  text: string;
  onClick?: () => void;
  external?: boolean;
  href?: string;
  dataTestId?: string;
};

export const AccountModuleLinkItem = ({
  text,
  onClick,
  external,
  href,
  dataTestId,
}: AccountModuleLinkItemProps) => {
  return (
    <Grid.Cell padding={[0, 0, 4, 0]}>
      <Link
        external={external}
        href={href}
        onClick={onClick}
        text={text}
        size={15}
        variant={'medium'}
        dataTestId={dataTestId}
      />
    </Grid.Cell>
  );
};

const AccountModule = observer(() => {
  const featureFlags = useFeatureFlags();
  const { company } = useCompany();
  const showAccountModuleCreditHistory =
    featureFlags.showAccountModuleCreditHistory;

  // Only show OrderForms that are manually signed
  const signedAcceptedOrderForms = company.orderForms.filter(
    (of) => of.acceptedAt && !of.autoAcceptedTermsAndAgreements,
  );

  const creditSection = () => {
    if (signedAcceptedOrderForms.length > 0) {
      if (showAccountModuleCreditHistory) {
        return <CreditEstimateHistory />;
      } else {
        return <CreditsSection />;
      }
    } else {
      return <></>;
    }
  };

  return (
    <Grid columns={4} gap={24} padding={0} dataTestId={DATA_TEST_ID_PREFIX}>
      <BillingSection />
      {/* Removing as we do not want to show anything associated with order forms anymore */}
      {creditSection()}
      <SettingsSection />
      <ConnectionsSection />
    </Grid>
  );
});

export default AccountModule;
