import { datadogLogs } from '@datadog/browser-logs';
import { FileHandle } from 'component-library';
import { ABORT_ERROR, GENERIC_VISUAL_ERROR } from 'lib/constants';
import { MSClientResponse } from 'lib/interfaces';
import {
  makeSubclassObservable,
  MobxObservableAnnotation,
} from 'lib/mobx-utils';
import { runInAction } from 'mobx';
import { RootStore } from 'stores/RootStore';
import * as queryString from 'query-string';
import { buildFlagOverrideHeader } from '../services/featureFlagOverride';
import { FeatureFlagSet } from '../lib/constants/featureFlagConstants';

const logger = datadogLogs.createLogger('BaseAPI');
const REQUEST_TIMED_OUT = 'Request timed out.';

const isFailedToFetch = (e: Error) =>
  e.name === 'TypeError' && e.message === 'Failed to fetch';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryParams = Record<string, any>;

type UploadParam = File | FileHandle | string | Blob;
export type RequestUploadParams = Record<string, UploadParam | UploadParam[]>;

export interface RequestOptions extends RequestInit {
  /**
   * Time in milliseconds before request expires
   */
  timeout?: number;

  /**
   * For the rare cases where a specific status code response
   * is required to determine sucecss of a request
   */
  expectedStatusCode?: number | number[];

  /**
   * Query params object to be encoded into the url
   */
  queryParams?: QueryParams;
}

export type RequestFilterFunction = (
  transaction: RequestTransaction,
) => boolean;

export type RequestMethod = 'get' | 'post' | 'put' | 'delete';

export interface RequestTransaction {
  method: RequestMethod;
  path: string;
  abortController: AbortController;
}

export abstract class BaseAPI {
  private organizationId: string | null = null;
  private accessToken: string | null = null;
  private csrfToken: string | null = null;
  private flagOverrides: FeatureFlagSet = {};
  public activeRequests: RequestTransaction[] = [];

  /**
   * @param rootStore RootStore used to generate the api client
   */
  constructor(rootStore?: RootStore) {
    if (rootStore) {
      rootStore.apiClients.push(this);

      rootStore.event.on('FEATURE_FLAGS', async (params) => {
        this.flagOverrides = params?.featureFlagOverrides || {};
      });

      rootStore.event.on('ORGANIZATION_ID', async (params) => {
        this.organizationId = params?.id || '';
      });

      rootStore.event.on('AUTH_ACCESS_TOKEN', async (params) => {
        this.accessToken = params?.token || '';
      });

      rootStore.event.on('CSRF_TOKEN', async (params) => {
        this.csrfToken = params?.token || '';
      });
    }

    /**
     * Making BaseAPI observable for 'activeRequests' tracking. This is done
     * at this base class level to block child classes from adding any
     * observable state. That should belong in stores & entities only
     */
    makeSubclassObservable(this, {
      organizationId: MobxObservableAnnotation.NOT_OBSERVED,
      accessToken: MobxObservableAnnotation.NOT_OBSERVED,
      csrfToken: MobxObservableAnnotation.NOT_OBSERVED,
      flagOverrides: MobxObservableAnnotation.NOT_OBSERVED,
    });
  }

  /**
   * Aborts all active requests
   */
  public cancelAllActiveRequests() {
    this.activeRequests.slice(0).forEach((request) => {
      if (!request.abortController.signal.aborted) {
        request.abortController.abort();
      }

      this.removeActiveRequest(request);
    });
  }

  /**
   * Cancels requests with pathname that matches the one provided
   * @param match string pathname to match against
   */
  public cancelRequest(match: string): void;

  /**
   * Cancels requests with pathname that match the regular expression provided
   * @param match Regular expression to match with
   */
  public cancelRequest(match: RegExp): void;

  /**
   * Cancels requests that match the filter function provided
   * @param match Filter function for matching transaction requests against
   */
  public cancelRequest(match: RequestFilterFunction): void;

  // Combined cancelRequest method to support overloaded methods above
  public cancelRequest(match: string | RegExp | RequestFilterFunction) {
    let filterFunction: RequestFilterFunction;

    if (typeof match === 'string') {
      filterFunction = (transaction) => transaction.path === match;
    } else if (match instanceof RegExp) {
      filterFunction = (transaction) => match.test(transaction.path);
    } else {
      filterFunction = match;
    }

    const requests = this.activeRequests.filter(filterFunction);

    requests.forEach((request) => {
      if (!request.abortController.signal.aborted) {
        request.abortController.abort();
      }

      this.removeActiveRequest(request);
    });
  }

  // Util for removing active requests
  private removeActiveRequest(request: RequestTransaction) {
    const index = this.activeRequests.indexOf(request);
    if (index > -1) {
      runInAction(() => this.activeRequests.splice(index, 1));
    }
  }

  // Combines required options, and merges request headers with auth/csrf tokens
  private mergeRequestOptions(
    defaultOptions: RequestOptions,
    requested: RequestOptions,
  ) {
    const merged: RequestOptions = { ...defaultOptions, ...requested };
    merged.headers = {
      ...(defaultOptions.headers || {}),
      ...(requested.headers || {}),
      ...(this.accessToken && { Authorization: `Bearer ${this.accessToken}` }),
      ...(this.csrfToken && { 'CSRF-Token': this.csrfToken }),
      ...(this.organizationId && { 'X-Organization-Id': this.organizationId }),
      ...buildFlagOverrideHeader(this.flagOverrides),
    };
    return merged;
  }

  // Centralized fetch implementation
  private async REQUEST<ResponseType>(
    method: RequestMethod,
    path: string,
    body?: BodyInit | null,
    requestOptions?: RequestOptions | AbortController,
  ): Promise<MSClientResponse<ResponseType>> {
    let abortController = new AbortController();

    // Leverage user defined abort controller
    if (requestOptions instanceof AbortController) {
      abortController = requestOptions;
      requestOptions = { signal: abortController.signal };
    }
    // Proxy existing abort signal to assigned controller for this request
    else if (requestOptions?.signal) {
      requestOptions.signal.addEventListener('abort', () =>
        abortController.abort(),
      );
      requestOptions.signal = abortController.signal;
    }
    // Assign new abort controller
    else {
      requestOptions = requestOptions || {};
      requestOptions.signal = abortController.signal;
    }

    // Build combined fetch options
    const { timeout, expectedStatusCode, queryParams, ...options } =
      this.mergeRequestOptions(
        {
          method,
          credentials: 'include',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        requestOptions,
      );

    // Attach body to fetch options
    if (body) {
      options.body = body;
    }

    // Custom query params
    if (queryParams) {
      // Sanity check to prevent query param clashing
      if (path.indexOf(`?`) > -1) {
        logger.error(
          `${path} request failed, query params passed for URL that already contains a search query`,
        );
        return { errorMsg: GENERIC_VISUAL_ERROR };
      }

      // Attach query params to the path
      path += `?` + queryString.stringify(queryParams);
    }

    // Add transaction to list of active requests
    const transaction: RequestTransaction = {
      method,
      path,
      abortController,
    };
    runInAction(() => this.activeRequests.push(transaction));

    // Wrap fetch API in a promise so that aborted requests are outright ignored
    return new Promise<MSClientResponse<ResponseType>>((resolve) => {
      let status = -1;
      let completed = false;

      // Timeout support
      if (timeout && timeout > 0) {
        setTimeout(() => {
          if (!completed && !transaction.abortController.signal.aborted) {
            resolve({ errorMsg: REQUEST_TIMED_OUT });
            transaction.abortController.abort();
          }
        }, timeout);
      }

      // Trigger actual fetch
      fetch(path, options)
        .then((res) => {
          completed = true;
          status = res.status;
          return res.json();
        })
        .then((json) =>
          this.resolveResponseStatus<ResponseType>(
            path,
            options,
            expectedStatusCode,
            json,
            status,
            resolve,
          ),
        )
        .catch((e) => {
          completed = true;
          const error = e instanceof Error ? e : new Error(e as string);

          // Ignore aborted requests
          if (error.name !== ABORT_ERROR) {
            if (!isFailedToFetch(error)) {
              logger.error(
                `Fetch Error - ${options.method} ${path} failed: ${error}`,
              );
            }

            resolve({
              errorMsg: GENERIC_VISUAL_ERROR,
            });
          }
        })
        .finally(() => this.removeActiveRequest(transaction));
    });
  }

  /**
   * Makes a GET request to the specified endpoint
   * @param path URL path for the endpoint
   * @returns API response
   */
  protected async GET<ResponseType = unknown>(
    path: string,
  ): Promise<MSClientResponse<ResponseType>>;

  /**
   * Makes a GET request to the specified endpoint
   * @param path URL path for the endpoint
   * @param requestOptions Fetch request options
   * @returns API response
   */
  protected async GET<ResponseType = unknown>(
    path: string,
    requestOptions: RequestOptions,
  ): Promise<MSClientResponse<ResponseType>>;

  /**
   * Makes a GET request to the specified endpoint
   * @param path URL path for the endpoint
   * @param abortController Abort controller for canceling this request while it's active
   * @returns API response
   */
  protected async GET<ResponseType = unknown>(
    path: string,
    abortController: AbortController,
  ): Promise<MSClientResponse<ResponseType>>;

  // Combined GET method to support overloaded methods above
  protected async GET<ResponseType = unknown>(
    path: string,
    requestOptions?: RequestOptions | AbortController,
  ): Promise<MSClientResponse<ResponseType>> {
    return await this.REQUEST<ResponseType>('get', path, null, requestOptions);
  }

  /**
   * Makes a POST request to the specified endpoint with and empty body
   * @param path URL path for the endpoint
   * @returns API response
   */
  protected async POST<ResponseType = unknown>(
    path: string,
  ): Promise<MSClientResponse<ResponseType>>;

  /**
   * Makes a POST request with a body to the specified endpoint
   * @param path URL path for the endpoint
   * @param body Object to send as the body of the request, will get converted with JSON.stringify
   * @returns API response
   */
  protected async POST<RequestType, ResponseType = unknown>(
    path: string,
    body: RequestType,
  ): Promise<MSClientResponse<ResponseType>>;

  /**
   * Makes a POST request with a body to the specified endpoint
   * @param path URL path for the endpoint
   * @param body Object to send as the body of the request, will get converted with JSON.stringify
   * @param requestOptions Fetch request options
   * @returns API response
   */
  protected async POST<RequestType, ResponseType = unknown>(
    path: string,
    body: RequestType,
    requestOptions: RequestOptions,
  ): Promise<MSClientResponse<ResponseType>>;

  /**
   * Makes a POST request with a body to the specified endpoint
   * @param path URL path for the endpoint
   * @param body Object to send as the body of the request, will get converted with JSON.stringify
   * @param abortController Abort controller for canceling this request while it's active
   * @returns API response
   */
  protected async POST<RequestType, ResponseType = unknown>(
    path: string,
    body: RequestType,
    abortController: AbortController,
  ): Promise<MSClientResponse<ResponseType>>;

  // Combined POST method to support overloaded methods above
  protected async POST<RequestType, ResponseType = unknown>(
    path: string,
    body?: RequestType,
    requestOptions?: RequestOptions | AbortController,
  ): Promise<MSClientResponse<ResponseType>> {
    let stringified = '';

    if (body) {
      try {
        stringified = JSON.stringify(body);
      } catch (e) {
        logger.error(`POST '${path}' Body Stringify Error:`, e as Error);
        return {
          errorMsg: GENERIC_VISUAL_ERROR,
        };
      }
    }

    return await this.REQUEST<ResponseType>(
      'post',
      path,
      stringified,
      requestOptions,
    );
  }

  /**
   * Makes a PUT request to the specified endpoint with an empty body
   * @param path URL path for the endpoint
   * @returns API response
   */
  protected async PUT<ResponseType = unknown>(
    path: string,
  ): Promise<MSClientResponse<ResponseType>>;

  /**
   * Makes a PUT request with a body to the specified endpoint
   * @param path URL path for the endpoint
   * @param body Object to send as the body of the request, will get converted with JSON.stringify
   * @returns API response
   */
  protected async PUT<RequestType, ResponseType = unknown>(
    path: string,
    body: RequestType,
  ): Promise<MSClientResponse<ResponseType>>;

  /**
   * Makes a PUT request with a body to the specified endpoint
   * @param path URL path for the endpoint
   * @param body Object to send as the body of the request, will get converted with JSON.stringify
   * @param requestOptions Fetch request options
   * @returns API response
   */
  protected async PUT<RequestType, ResponseType = unknown>(
    path: string,
    body: RequestType,
    requestOptions: RequestOptions,
  ): Promise<MSClientResponse<ResponseType>>;

  /**
   * Makes a PUT request with a body to the specified endpoint
   * @param path URL path for the endpoint
   * @param body Object to send as the body of the request, will get converted with JSON.stringify
   * @param abortController Abort controller for canceling this request while it's active
   * @returns API response
   */
  protected async PUT<RequestType, ResponseType = unknown>(
    path: string,
    body: RequestType,
    abortController: AbortController,
  ): Promise<MSClientResponse<ResponseType>>;

  // Combined PUT method to support overloaded methods above
  protected async PUT<RequestType, ResponseType = unknown>(
    path: string,
    body?: RequestType,
    requestOptions?: RequestOptions | AbortController,
  ): Promise<MSClientResponse<ResponseType>> {
    let stringified = '';

    if (body) {
      try {
        stringified = JSON.stringify(body);
      } catch (e) {
        logger.error(`PUT '${path}' Body Stringify Error:`, e as Error);
        return {
          errorMsg: GENERIC_VISUAL_ERROR,
        };
      }
    }

    return await this.REQUEST<ResponseType>(
      'put',
      path,
      stringified,
      requestOptions,
    );
  }

  /**
   * Makes a DELETE request to the specified endpoint
   * @param path URL path for the endpoint
   * @returns API response
   */
  protected async DELETE<ResponseType = unknown>(
    path: string,
  ): Promise<MSClientResponse<ResponseType>>;

  /**
   * Makes a DELETE request to the specified endpoint
   * @param path URL path for the endpoint
   * @param requestOptions Fetch request options
   * @returns API response
   */
  protected async DELETE<ResponseType = unknown>(
    path: string,
    requestOptions: RequestOptions,
  ): Promise<MSClientResponse<ResponseType>>;

  /**
   * Makes a DELETE request to the specified endpoint
   * @param path URL path for the endpoint
   * @param abortController Abort controller for canceling this request while it's active
   * @returns API response
   */
  protected async DELETE<ResponseType = unknown>(
    path: string,
    abortController: AbortController,
  ): Promise<MSClientResponse<ResponseType>>;

  // Combined DELETE method to support overloaded methods above
  protected async DELETE<ResponseType = unknown>(
    path: string,
    options?: RequestOptions | AbortController,
  ): Promise<MSClientResponse<ResponseType>> {
    return this.REQUEST<ResponseType>('delete', path, null, options);
  }

  /**
   * Conerts reqParams into a FormData object for uploading data blocks
   * to an endpoint using the 'method' action
   * @param method Action to take on the endpoint
   * @param path URL path for the endpoint
   * @param requestParams Parameters to encode into FormData for upload
   * @returns API response
   */
  protected async UPLOAD<ResponseType = unknown>(
    method: 'post' | 'put',
    path: string,
    requestParams: RequestUploadParams,
  ): Promise<MSClientResponse<ResponseType>>;

  /**
   * Conerts reqParams into a FormData object for uploading data blocks
   * to an endpoint using the 'method' action
   * @param method Action to take on the endpoint
   * @param path URL path for the endpoint
   * @param requestParams Parameters to encode into FormData for upload
   * @param requestOptions Fetch request options
   * @returns API response
   */
  protected async UPLOAD<ResponseType = unknown>(
    method: 'post' | 'put',
    path: string,
    requestParams: RequestUploadParams,
    requestOptions: RequestOptions,
  ): Promise<MSClientResponse<ResponseType>>;

  /**
   * Conerts reqParams into a FormData object for uploading data blocks
   * to an endpoint using the 'method' action
   * @param method Action to take on the endpoint
   * @param path URL path for the endpoint
   * @param requestParams Parameters to encode into FormData for upload
   * @param abortController Abort controller for canceling this request while it's active
   * @returns API response
   */
  protected async UPLOAD<ResponseType = unknown>(
    method: 'post' | 'put',
    path: string,
    requestParams: RequestUploadParams,
    abortController: AbortController,
  ): Promise<MSClientResponse<ResponseType>>;

  // Combined UPLOAD method to support overloaded methods above
  protected async UPLOAD<ResponseType = unknown>(
    method: 'post' | 'put',
    path: string,
    requestParams: RequestUploadParams,
    requestOptions?: RequestOptions | AbortController,
  ): Promise<MSClientResponse<ResponseType>> {
    let abortController: AbortController | undefined = new AbortController();

    // Handle abort controller passed into request options
    if (requestOptions instanceof AbortController) {
      abortController = requestOptions;
      requestOptions = undefined;
    }

    // Local meta
    const fileHandles: FileHandle[] = [];
    const { timeout, expectedStatusCode, queryParams, ...options } =
      this.mergeRequestOptions(
        {
          headers: {
            'Cache-Control': 'no-cache, no-store, max-age=0',
          },
        },
        requestOptions || {},
      );

    // Custom query params
    if (queryParams) {
      // Sanity check to prevent query param clashing
      if (path.indexOf(`?`) > -1) {
        logger.error(
          `${path} request failed, query params passed for URL that already contains a search query`,
        );
        return { errorMsg: GENERIC_VISUAL_ERROR };
      }

      // Attach query params to the path
      path += `?` + queryString.stringify(queryParams);
    }

    // Build the form data
    const formData = new FormData();
    for (const key in requestParams) {
      const param = requestParams[key];

      if (Array.isArray(param)) {
        param.forEach((value) => {
          if (value instanceof FileHandle) {
            fileHandles.push(value);
            formData.append(key, value.file, value.name);
          } else {
            formData.append(key, value);
          }
        });
      } else {
        if (param instanceof FileHandle) {
          fileHandles.push(param);
          formData.append(key, param.file, param.name);
        } else {
          formData.append(key, param);
        }
      }
    }

    // Proxy existing abort signal that may have been passed to the request options
    options.signal?.addEventListener('abort', () => abortController?.abort());

    // Add transaction to list of active requests
    const transaction: RequestTransaction = {
      method,
      path,
      abortController,
    };
    runInAction(() => this.activeRequests.push(transaction));

    // Make the actual request
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      let completed = false;
      let uploadProgress = 0;
      let downloadProgress = 0;
      let aborted = false;

      // Timeout support
      if (timeout && timeout > 0) {
        setTimeout(() => {
          if (!completed && !transaction.abortController.signal.aborted) {
            resolve({ errorMsg: REQUEST_TIMED_OUT });
            transaction.abortController.abort();
          }
        }, timeout);
      }

      // Progress updates
      const updateProgress = () => {
        /**
         * Visual progress is a combination of the upload & download progress,
         * with the idea being the user experience is not finished until the
         * request completes. This also assumes that in general, more time
         * will be spent on the actual upload (80%) than waiting for a
         * response from the server (20%)
         */
        const visualUpload = uploadProgress * 80;
        const visualDownload = downloadProgress * 20;
        const visualProgress = completed
          ? 1
          : Math.min(
              Math.max((visualUpload + visualDownload) / 100, 0.001),
              0.99,
            );

        fileHandles.forEach((file) =>
          file.setUploadProgress(
            visualProgress,
            uploadProgress,
            downloadProgress,
          ),
        );
      };

      // Listen for user based aborting
      [
        abortController,
        ...fileHandles.map((file) => file.abortController),
      ].forEach((controller) => {
        controller?.signal.addEventListener(`abort`, () => {
          if (!completed) {
            this.removeActiveRequest(transaction);

            aborted = true;
            xhr.abort();
          }
        });
      });

      // Clear all controllers when abort is triggered
      xhr.addEventListener(`abort`, () => {
        this.removeActiveRequest(transaction);

        abortController = undefined;
        fileHandles.forEach((file) => file.setAbortController(null));
      });

      // Progress tracking
      xhr.upload.addEventListener(`progress`, (e) => {
        uploadProgress = e.lengthComputable ? e.loaded / e.total : 0;
        updateProgress();
      });
      xhr.addEventListener(`progress`, (e) => {
        downloadProgress = e.lengthComputable ? e.loaded / e.total : 0;
        updateProgress();
      });

      // Response handling
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4 || completed || aborted) {
          return;
        }

        completed = true;
        updateProgress();
        try {
          const json = JSON.parse(xhr.responseText);
          this.resolveResponseStatus<ResponseType>(
            path,
            options,
            expectedStatusCode,
            json,
            xhr.status,
            resolve,
          );
        } catch (e) {
          const error = e instanceof Error ? e : new Error(e as string);
          logger.error(
            `Upload Error: ${options.method} ${path} failed: ${error}`,
          );

          resolve({
            errorMsg: GENERIC_VISUAL_ERROR,
          });
        } finally {
          this.removeActiveRequest(transaction);
        }
      };

      // Open the request
      xhr.open(method, path, true);

      // Attach the headers (using fetch based header params)
      if (options.headers) {
        if (Array.isArray(options.headers)) {
          options.headers.forEach((pair) =>
            xhr.setRequestHeader(pair[0], pair[1]),
          );
        } else if (options.headers instanceof Headers) {
          for (const pair of options.headers.entries()) {
            xhr.setRequestHeader(pair[0], pair[1]);
          }
        } else {
          for (const key in options.headers) {
            xhr.setRequestHeader(key, options.headers[key]);
          }
        }
      }

      // Send the request
      xhr.withCredentials = options.credentials !== `omit`;
      xhr.send(formData);
      updateProgress();
    });
  }

  // Normalized response handling
  private resolveResponseStatus<ResponseType>(
    path: string,
    options: RequestInit,
    expectedStatusCode: RequestOptions['expectedStatusCode'],
    json: MSClientResponse<ResponseType>,
    status: number,
    resolve: (value: MSClientResponse<ResponseType>) => void,
  ) {
    const methodDisplayName = options.method?.toUpperCase() || 'UNKNOWN';

    // Log response error
    if (json?.errorMsg) {
      logger.error(`Error on ${methodDisplayName} ${path}: ${json.errorMsg}`);

      resolve(json);
    }
    // Check for expected status code
    else if (
      typeof expectedStatusCode === 'number' &&
      expectedStatusCode !== status
    ) {
      logger.error(
        `Status code [${status}] does not match expected status code [${expectedStatusCode}]: ${methodDisplayName} ${path}`,
      );

      resolve({
        errorMsg: GENERIC_VISUAL_ERROR,
      });
    }
    // Check for list of expected status codes
    else if (
      Array.isArray(expectedStatusCode) &&
      !expectedStatusCode.includes(status)
    ) {
      logger.error(
        `Status code [${status}] does not exist in expected status code list [${expectedStatusCode.join(
          ', ',
        )}]: ${methodDisplayName} ${path}`,
      );

      resolve({
        errorMsg: GENERIC_VISUAL_ERROR,
      });
    }
    // By default, any non 200 or 304 code is considered an error
    else if (status < 200 || (status > 299 && status !== 304)) {
      logger.error(
        `Unsuccessful Status Code [${status}]: ${methodDisplayName} ${path}`,
      );

      resolve({
        errorMsg: GENERIC_VISUAL_ERROR,
      });
    }
    // Successful response
    else {
      resolve(json || {});
    }
  }
}
