import { Button as MuiButton, CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

type Variant = 'normal' | 'outlined' | 'link';

const useStyles = makeStyles(({ darkBlue }: Theme) => ({
  normal: {
    padding: '8px 25px',
    minHeight: '50px',
    fontSize: '18px',
    fontWeight: 500,
    textTransform: 'initial',
    '&:hover': {
      boxShadow: 'none',
    },
    border: 'none',
  },
  outline: {},
  link: {
    color: darkBlue,
    textDecoration: 'none',
    textTransform: 'none',
    fontWeight: 400,
    '&:hover': {
      textDecoration: 'underline',
      background: 'none',
    },
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export interface ButtonProps {
  onClick: () => void;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  variant?: Variant;
  loading?: boolean;
}

interface Attributes {
  className: string;
  muiVariant?: 'text' | 'outlined' | 'contained';
}

const attributes = (variant: Variant | undefined, classes: any): Attributes => {
  switch (variant) {
    case 'link':
      return {
        className: classes.link,
        muiVariant: 'text',
      };
    case 'outlined':
      return {
        muiVariant: 'outlined',
        className: classes.outline,
      };
    default:
      return {
        muiVariant: 'contained',
        className: classes.normal,
      };
  }
};

export const Button = ({
  onClick,
  children,
  className: classNameProp,
  disabled,
  loading,
  variant,
}: ButtonProps) => {
  const classes = useStyles();
  const { muiVariant, className } = attributes(variant, classes);

  return (
    <MuiButton
      variant={muiVariant}
      color='secondary'
      className={`${className} ${classNameProp}`}
      onClick={onClick}
      disabled={!!disabled || !!loading}
    >
      {children && children}
      {loading && <CircularProgress size={24} className={classes.progress} />}
    </MuiButton>
  );
};
