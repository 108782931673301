import React from 'react';
import { observer } from 'mobx-react';
import { Heading, Text, Link } from 'component-library';
import { useTaxCreditsStores, useCommonStores } from 'stores/useStores';
import { OrderForm, ProgramData } from 'lib/interfaces';
import { Page, ProgramNameEnum, QualificationStatusEnum } from 'lib/constants';
import { OrderFormHeaderContent } from './orderFormHeaderContent/OrderFormHeaderContent';

interface OrderFormHeaderProps {
  order: OrderForm;
  currentProgram: ProgramData;
  isBogoDeal: boolean;
}

export const OrderFormHeader: React.FC<OrderFormHeaderProps> = observer(
  ({ order, currentProgram, isBogoDeal }) => {
    const { app } = useCommonStores();
    const { orderForms } = useTaxCreditsStores();

    const showHeaderDatesConfig: {
      [key: string]: boolean;
    } = {
      [ProgramNameEnum.FED_RD_TAX]: true,
      [ProgramNameEnum.FED_DISABLED_ACCESS]: false,
      [ProgramNameEnum.FED_EMPLOYER_HEALTHCARE]: false,
      [ProgramNameEnum.FED_RETIREMENT_CREDIT]: false,
    };

    return (
      <>
        <Heading text={orderForms.formTitle} size={app.isMobile ? 24 : 36} />
        {showHeaderDatesConfig[currentProgram.name] && (
          <OrderFormHeaderContent
            isBogoDeal={isBogoDeal}
            order={order}
            currentProgram={currentProgram}
          />
        )}

        {!order.acceptedAt &&
          currentProgram.qualificationStatus !==
            QualificationStatusEnum.QUALIFIED && (
            <Text paddingBottom={16}>
              These quotes are non-binding estimates. MainStreet&apos;s final
              qualification estimate of your credits and MainStreet&apos;s fee
              will be provided after completion of our qualification process.
              See{' '}
              <Link target='_blank' href={`/${Page.tos}`}>
                Services Agreement
              </Link>{' '}
              for details.
            </Text>
          )}
      </>
    );
  },
);
