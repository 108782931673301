import React from 'react';
import { Text } from 'component-library';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((colors: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  sectionHeader: {
    borderBottom: '1px solid',
    borderBottomColor: colors.lighterGrey,
  },
}));

export const ExpertReviewStepFAQ = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Text
        variant={'medium'}
        className={classes.sectionHeader}
        paddingBottom={8}
      >
        What&apos;s this step?
      </Text>
      <Text paddingBottom={8}>
        Our team will review all the information you provided to carefully
        calculate your credits.
      </Text>
      <Text paddingBottom={8}>
        It&apos;s not uncommon for a MainStreet specialist to request additional
        details during this time. We&apos;ll alert you if we need more
        information. Please respond quickly to these requests so we can complete
        our review.
      </Text>

      <Text
        variant={'medium'}
        className={classes.sectionHeader}
        paddingBottom={8}
      >
        Timeline
      </Text>
      <Text>
        This process typically takes between 2 and 4 weeks. You can speed this
        along by promptly responding to any requests for further information
        from our R&D specialists.
      </Text>
    </div>
  );
};
