import _ from 'lodash';
import { runInAction } from 'mobx';
import { BaseStore } from 'stores/BaseStore';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import {
  DocumentForUpload,
  ProgramData,
  QuarterDescription,
} from 'lib/interfaces';
import {
  AT_MS_DOMAIN,
  CarryoverAndAdjustStatusEnum,
  ExpectedCreditTypeEnum,
  Form8974SideDrawerTypes,
  NegativeCarryoverStatuses,
  PayrollTierEnum,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  TaxInputValueEnum,
} from 'lib/constants';
import { FileHandle } from 'component-library';
import { isProgramWaitingFor8974UserConfirmations } from 'pages/dashboard/fed-rd-program/8974/helpers';
import { datadogLogs } from '@datadog/browser-logs';
import {
  PreviousQuarterFromDate,
  FirstDayOfNextQuarter,
  NextQuarterFromDate,
} from 'lib/helpers';

const logger = datadogLogs.createLogger('Form8974Store.tsx');

export class Form8974Store extends BaseStore {
  public payrollProvider = '';
  public programs: ProgramData[] = [];
  public nonActionableProgramIds: number[] = [];
  public creditBalanceCents = 0;
  public totalRdCreditWithdrawnCents = 0;
  public carryoverWageTaxCents: number | null = null;
  public carryoverQuarter?: QuarterDescription;
  public carryoverStatus: CarryoverAndAdjustStatusEnum =
    CarryoverAndAdjustStatusEnum.NOT_ELIGIBLE;
  public sideDrawerOpen = false;
  public shouldShowSuccessAlert = false;
  public balanceCardEnabled = false;
  public requestError?: string;
  public generate8974FormEnabled = false;
  public updatedCompanyTaxId: string | null = null;
  public updatedTaxFilingDate: string | null = null;
  public filesToBeUploaded: FileHandle[] = [];
  public taxInfoSubmitted = true;
  public taxInfoSubmitting = false;
  public form8974Generating: boolean | null = null;
  public form8974GeneratedDocumentUrl = '';
  public payrollProviderConfirmationProcessing = false;
  public medicareTaxCents: number | null = null;
  public payrollTier?: PayrollTierEnum;
  public isLoading?: boolean = true;

  private GENERIC_ERROR_MESSAGE = `An error has occurred. Please try again, or reach out to support${AT_MS_DOMAIN} for assistance`;

  /**
   * Tiered providers should be matched with those defined in /server/src/serverConstants.ts
   * Please update sever constants when these are changed, and vica versa.
   */
  public tier1Providers = ['gusto', 'rippling', 'adp_totalsource', 'justworks'];
  public tier2Providers = ['insperity', 'sequoia_one', 'trinet'];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public setPayrollProvider(payrollProvider: string) {
    runInAction(() => {
      this.payrollProvider = payrollProvider;
    });
  }

  public setCarryoverStatus(status: CarryoverAndAdjustStatusEnum) {
    runInAction(() => {
      this.carryoverStatus = status;
    });
  }

  public get actionablePrograms() {
    if (this.payrollProvider === 'adp_totalsource') {
      // Since there are so few ADP Totalsource customers, and due to time constraints,
      // Ops will process these customers manually. In the dashboard then, we should never
      // show CTAs for them to take action.
      logger.info(
        `Skipping addActionablePrograms since form8974store.payrollProvider = adp_totalsource`,
      );
      return [];
    } else {
      const validPrograms = this.programs.filter((p) =>
        this.programNeeds8974Setup(p),
      );

      return _.sortBy<ProgramData>(
        _.uniqBy(validPrograms, ({ id }: ProgramData) => id),
        ({ taxYear }: ProgramData) => taxYear,
      );
    }
  }

  /**
   * Returns true if the program is in the correct stage/substage to collect
   * 8974 information AND is missing the required customer inputs for 8974
   * generation.
   */
  public programNeeds8974Setup(program: ProgramData): boolean {
    return (
      program.taxYear >= 2021 &&
      program.name === ProgramNameEnum.FED_RD_TAX &&
      isProgramWaitingFor8974UserConfirmations(program) &&
      program.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX &&
      this.nonActionableProgramIds.indexOf(program.id) < 0
    );
  }

  public addPrograms(programs: ProgramData[]) {
    runInAction(() => {
      this.programs = programs;
    });
  }

  public async refreshPrograms() {
    const res = await this.client.GetPrograms();
    const programs = res?.data;
    if (programs) {
      runInAction(() => {
        this.programs = programs;
      });
    }
  }

  public removeActionableProgram(programId: number) {
    runInAction(() => {
      const nonActionableProgram = this.actionablePrograms.find(
        (p) => p.id === programId,
      );
      if (nonActionableProgram) {
        this.nonActionableProgramIds.push(nonActionableProgram.id);
      }
    });
  }

  /**
   * Gets the carryover information and credit balance for a company.
   * Sets state to determine if we should show the CreditBalanceCard
   */
  public async getRDCreditSummary(currentDate: Date = new Date()) {
    const companyId = this.rootStore.common.companyStore.currentCompany.id;
    const res = await this.client.GetCarryoverAndAdjustForQuarter(
      companyId,
      currentDate,
    );

    if (res.errorMsg) {
      this.requestError = res.errorMsg;
      datadogLogs.logger.error(`error fetching carryover and adjust data`);
      return;
    }

    const carryover = res.data?.carryover;
    const status = res.data?.status;

    // Check for at least 1 program in finished/redeeming
    // This ensures we account for Tier 3 programs which could,
    // for example, still require access to 2021 forms in Q1 2023
    const showCreditBalanceCard = this.programs.some(
      (p) =>
        p.stage === ProgramStageEnum.FINISHED &&
        (p.subStage === ProgramSubStageEnum.REDEEMING ||
          p.subStage === ProgramSubStageEnum.FILING_UPLOADED),
    );

    const programLedgerSetupRequired =
      status === CarryoverAndAdjustStatusEnum.PROGRAM_LEDGER_SETUP_REQUIRED;

    runInAction(() => {
      // add current or missing filing to Redemption store
      this.rootStore.taxcredits.redemption.currentFiling =
        res.data?.currentQuarter;
      this.rootStore.taxcredits.redemption.missedFilings =
        res.data?.missedFilings;
    });

    await runInAction(async () => {
      if (status) {
        this.carryoverQuarter = carryover;
        this.carryoverStatus = status;
        await this.refreshCompanyCreditBalance();
      }
      this.balanceCardEnabled = showCreditBalanceCard;
      this.taxInfoSubmitted = !programLedgerSetupRequired;
    });
  }

  public toggleSideDrawer(toggle: boolean) {
    runInAction(() => (this.sideDrawerOpen = toggle));
  }

  public toggleShouldShowSuccessAlert(toggle: boolean) {
    runInAction(() => (this.shouldShowSuccessAlert = toggle));
  }

  public sideDrawerOnComplete = (programId: number): void => {
    runInAction(() => {
      this.removeActionableProgram(programId);
      this.sideDrawerOpen = false;
      this.shouldShowSuccessAlert = true;
    });
  };

  public updateProgramStageInState(
    programId: number,
    programStage: ProgramStageEnum,
  ) {
    const program = this.programs.find((p) => p.id === programId);
    if (program) {
      program.stage = programStage;
    }
  }

  public updateProgramSubStageInState(
    programId: number,
    programSubStage: ProgramSubStageEnum,
  ) {
    const program = this.programs.find((p) => p.id === programId);
    if (program) {
      program.subStage = programSubStage;
    }
  }

  public handleCurrencyInputValue(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    name: TaxInputValueEnum,
  ) {
    const amount = Number(e.target.value.replace(/,/g, ''));
    const amountToCents = amount * 100;

    runInAction(() => {
      if (name === TaxInputValueEnum.SOCIAL_SECURITY) {
        this.carryoverWageTaxCents = amountToCents;
      }
      if (name === TaxInputValueEnum.MEDICARE) {
        this.medicareTaxCents = amountToCents;
      }
    });

    this.toggleGenerate8974FormEnabled();
  }

  public toggleGenerate8974FormEnabled = () => {
    if (
      this.carryoverWageTaxCents &&
      this.medicareTaxCents &&
      this.carryoverWageTaxCents > 0 &&
      this.medicareTaxCents > 0
    ) {
      runInAction(() => {
        this.generate8974FormEnabled = true;
      });
    } else {
      runInAction(() => {
        this.generate8974FormEnabled = false;
      });
    }
  };

  public setCompanyTaxId = (taxId: string) => {
    runInAction(() => {
      this.updatedCompanyTaxId = taxId.replace('-', '');
    });
  };

  public async updateCompanyTaxInfo(taxId: string) {
    const res = await this.client.UpdateCompanyTaxInfo({ taxId });

    if (res.errorMsg) {
      this.requestError = res.errorMsg;
      logger.error('error updating Company tax_id');
    }
  }

  public setTaxFilingDate = (filingDate: string) => {
    runInAction(() => {
      this.updatedTaxFilingDate = filingDate;
    });
  };

  public async updateProgramTaxFilingDate(
    programId: number,
    taxFilingDate?: string,
  ) {
    if (!taxFilingDate && !this.updatedTaxFilingDate) {
      logger.error(`error updating Program tax_filing_date: no date supplied`);
      return;
    }

    const date = new Date(taxFilingDate ?? this.updatedTaxFilingDate ?? '');
    const res = await this.client.UpdateProgramTaxFilingDate({
      programId: programId,
      taxFilingDate: date,
    });
    if (res.errorMsg) {
      runInAction(() => (this.requestError = res.errorMsg));
      logger.error(`error updating Program tax_filing_date`);
    }
  }

  public async addFileToBeUploaded(file: FileHandle) {
    if (this.filesToBeUploaded.indexOf(file) === -1) {
      runInAction(() => {
        this.filesToBeUploaded.push(file);
      });
    }
  }

  public removeFileToBeUploaded = (file: FileHandle) => {
    runInAction(() => {
      this.filesToBeUploaded = this.filesToBeUploaded.filter(
        (f) => f.name !== file.name,
      );
    });
  };

  /**
   * Tier 3 balance should be calculated by summing the amounts of each ledger entry.
   * Tier 1 and 2 should simply use the credit balance attached to the current program.
   * Current program is defined as the program with tax year equal to (current year - 1).
   */
  public refreshCompanyCreditBalance = async () => {
    const companyId = this.rootStore.common.companyStore.currentCompany.id;
    await this.fetchCompanyPayrollProvider(companyId);

    if (
      this.payrollTier === PayrollTierEnum.TIER_1 ||
      this.payrollTier === PayrollTierEnum.TIER_2
    ) {
      await this.refreshPrograms();
      if (!this.mostRecentFinishedProgram) {
        datadogLogs.logger.info(
          `No current program found for company ${companyId} when attempting to calculate credit balance`,
        );
        return;
      }

      const programBalance = this.mostRecentFinishedProgram.creditAmountCents;

      runInAction(() => {
        this.creditBalanceCents = programBalance;
      });
      return;
    }

    const res = await this.rootStore.client.GetCompanyRDCreditBalance(
      companyId,
    );

    if (res.errorMsg) {
      runInAction(() => {
        this.requestError = this.GENERIC_ERROR_MESSAGE;
        datadogLogs.logger.error(
          `Could not recalculate company credit balance`,
        );
      });
      return;
    }

    const balanceCents = res.data?.balanceInfo?.balanceAmountCents;
    const withdrawnAmountCents = res.data?.balanceInfo?.totalWithdrawnCents;
    if (balanceCents === undefined || withdrawnAmountCents === undefined) {
      // This could be valid if the company has no prior ledger and we're refreshing the balance before they've
      // set up a program for redemptions. But if they have ever even set up 1 program for
      // redemptions then this should not be the case.
      datadogLogs.logger.info(
        `Did not get a balance amount or total withdrawals for company=${companyId}`,
      );
    }

    runInAction(() => {
      this.creditBalanceCents = balanceCents || 0;
      this.totalRdCreditWithdrawnCents = Math.abs(withdrawnAmountCents || 0);
    });
  };

  /**
   * Generates a quarterly 8974 form for the customer.
   * Presumes that all eligible programs' ledgers are already set up with funding,
   * and that the required info (EIN, filing date, wage tax) are already available.
   *
   * NOTE: Only used for T3 customers.
   */
  public async generateForm8974() {
    runInAction(() => {
      this.form8974Generating = true;
    });

    const companyId = this.rootStore.common.companyStore.currentCompany.id;

    if (this.carryoverWageTaxCents && this.medicareTaxCents) {
      this.client
        .Finalize8974Form(
          companyId,
          this.carryoverWageTaxCents,
          this.medicareTaxCents,
        )
        .then(async (res) => {
          if (res.errorMsg) {
            runInAction(() => {
              this.requestError = `Error generating 8974 form: ${res.errorMsg}`;
            });
            this.form8974Generating = false;
            return;
          }

          if (this.carryoverQuarter) {
            const getDocumentUrlRes = await this.client.GetForm8974DocumentUrl(
              companyId,
              this.carryoverQuarter.year,
              this.carryoverQuarter.quarter,
            );

            runInAction(() => {
              if (getDocumentUrlRes.errorMsg) {
                this.requestError = getDocumentUrlRes.errorMsg;
                logger.error(`error generating Form 8974 document url`);
                return;
              }

              this.form8974GeneratedDocumentUrl = getDocumentUrlRes.data
                ?.documentUrl as string;
            });
          } else {
            const currentDate = new Date();
            const res = await this.client.GetCarryoverAndAdjustForQuarter(
              companyId,
              currentDate,
            );

            if (res.errorMsg) {
              this.requestError = res.errorMsg;
              datadogLogs.logger.error(
                `error fetching carryover and adjust data`,
              );
              return;
            }

            const status = res.data?.status;

            runInAction(async () => {
              if (status) {
                this.carryoverStatus = status;
              }
            });
            await this.GetForm8974URL();
          }

          const companyCreditCents =
            res.data?.finalization?.calculatedCredit?.companyCreditCents;
          runInAction(() => {
            if (companyCreditCents) {
              this.creditBalanceCents =
                this.creditBalanceCents - companyCreditCents;
              this.totalRdCreditWithdrawnCents =
                this.totalRdCreditWithdrawnCents + companyCreditCents;
            }
            this.form8974Generating = false;
          });
        });
    }
  }

  /**
   * Persists the EIN, filing date, and tax documents. Then establishes the
   * ledger for the given program. This will NOT generate a Form 8974; that should
   * be handled by generateForm8974().
   *
   * This will also refresh the credit balance and total withdrawn amounts in the store.
   *
   * This is called when the customer is setting up a new program for credit
   * redemptions.
   */
  public async uploadTaxDocsFor8974(
    programId: number,
    taxYear?: number,
    isUnifiedAssessment?: boolean,
  ) {
    const company = this.company;

    this.taxInfoSubmitting = true;

    // ensure we have a file to upload
    if (this.filesToBeUploaded.length === 0) {
      datadogLogs.logger.error(
        `Attempted to set up ledger without tax documents`,
      );
      return;
    }

    // ensure a tax filing date has been entered
    if (!this.updatedTaxFilingDate) {
      datadogLogs.logger.error(
        `Attempted to set up ledger without filing date`,
      );
      return;
    }

    const program = this.programs.find((p) => p.id === programId);
    // update the program's tax filing date in client-side state
    runInAction(() => {
      if (program && this.updatedTaxFilingDate) {
        program.taxFilingDate = this.updatedTaxFilingDate;
      }
    });

    // if the user entered a new EIN, update it on the server
    if (this.updatedCompanyTaxId) {
      await this.updateCompanyTaxInfo(this.updatedCompanyTaxId);
    }

    // format the file to upload (should only be 1)
    const docsToUpload: DocumentForUpload[] = this.filesToBeUploaded.map(
      (fileHandle) => {
        return {
          name: fileHandle.name,
          description: '',
          file: fileHandle.file,
        };
      },
    );

    const shouldGenerate8974 =
      this.payrollTier !== PayrollTierEnum.TIER_3 &&
      program?.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX;

    // upload tax documents
    const uploadFilingDocsRes = await this.client.UploadTaxFilingDocuments(
      programId,
      docsToUpload,
      this.formatFilingDateToISO(this.updatedTaxFilingDate),
      // True here indicates we should generate a Form 8974 in this call.
      // For tier 3, we'll wait and make a subsequent call to the /finalize endpoint to generate the form.
      // For income tax type, we don't generate an 8974 at all.
      // Otherwise, we want to generate the form now.
      shouldGenerate8974,
    );

    // if unified assessment - update all other credit program filing dates
    if (isUnifiedAssessment) {
      const allProgramsInClientReview = company.programs.filter(
        (p) =>
          p.taxYear === taxYear &&
          p.stage !== ProgramStageEnum.DISQUALIFIED &&
          p.stage === ProgramStageEnum.CLIENT_REVIEW,
      );
      if (allProgramsInClientReview.length > 0) {
        for (const otherProgram of allProgramsInClientReview) {
          await this.updateProgramTaxFilingDate(otherProgram.id);
        }
      }
    }

    runInAction(() => {
      this.taxInfoSubmitting = false;

      if (uploadFilingDocsRes.errorMsg) {
        datadogLogs.logger.error(`Error uploading tax documents for 8974`, {
          companyId: this.rootStore.common.companyStore.currentCompany.id,
          errorMsg: uploadFilingDocsRes.errorMsg,
        });
        this.requestError = uploadFilingDocsRes.errorMsg;
        this.sideDrawerOpen = false;
        return;
      } else {
        this.requestError = undefined;
        this.taxInfoSubmitted = true;
      }
    });

    // if not tier 3, get generated form 8974 from db
    if (shouldGenerate8974 && this.payrollTier) {
      await this.rootStore.taxcredits.redemption.checkFilingStatus(
        this.payrollTier,
      );

      if (
        this.rootStore.taxcredits.redemption.filingStatus !==
        CarryoverAndAdjustStatusEnum.MISSED_FILING
      ) {
        await this.GetForm8974URL(programId);
      }
    }
  }

  /**
   * Return 8974 form URL.
   */
  public async GetForm8974URL(programId?: number) {
    const companyId = this.rootStore.common.companyStore.currentCompany.id;

    // Non-tier 3 customers need step 4 to locate generated document url during uploadTaxDocsFor8974 function call in case mostRecentFinishProgram is empty
    const programFilingDate = this.programs.find(
      (p) => p.id === programId,
    )?.taxFilingDate;
    const mostRecentFinishedFilingDate =
      this.mostRecentFinishedProgram?.taxFilingDate;
    const taxFilingDate = mostRecentFinishedFilingDate || programFilingDate;

    if (taxFilingDate) {
      const { quarter, year } =
        // target tier 3 specific to get previous quarter from date, other tier uses next quarter from filing date
        this.payrollTier === PayrollTierEnum.TIER_3
          ? PreviousQuarterFromDate(new Date())
          : NextQuarterFromDate(new Date(taxFilingDate));
      const getDocumentUrlRes = await this.client.GetForm8974DocumentUrl(
        companyId,
        year,
        quarter,
      );

      runInAction(() => {
        if (getDocumentUrlRes.errorMsg) {
          this.requestError = getDocumentUrlRes.errorMsg;
          logger.error(`error generating Form 8974 document url`);
          return;
        }

        if (getDocumentUrlRes.data?.documentUrl) {
          this.form8974GeneratedDocumentUrl = getDocumentUrlRes.data
            ?.documentUrl as string;
        }
      });
    }

    runInAction(() => (this.isLoading = false));
  }

  // Computed values
  public get tier1AndTier2Providers(): string[] {
    return [...this.tier1Providers, ...this.tier2Providers];
  }

  public get filesExistInUploadQueue(): boolean {
    return this.filesToBeUploaded.length > 0;
  }

  /**
   * Computed value used to determine if the CTA that triggers the redemption
   * flow is enabled. An example can be found in Form8974Tier3SideDrawer.tsx
   */
  public get canRedeemCredits(): boolean {
    return !NegativeCarryoverStatuses.includes(this.carryoverStatus);
  }

  public get mostRecentFinishedProgram(): ProgramData | undefined {
    return this.programs
      .filter(
        (program: ProgramData) =>
          program.name === ProgramNameEnum.FED_RD_TAX &&
          program.stage === ProgramStageEnum.FINISHED,
      )
      .sort((a, b) => b.taxYear - a.taxYear)[0];
  }

  public get shouldUseDocumentsPageExperience(): boolean {
    return (
      this.tier2Providers.includes(this.payrollProvider) ||
      (this.payrollTier === PayrollTierEnum.TIER_3 && this.canRedeemCredits)
    );
  }

  public get sideDrawerType(): Form8974SideDrawerTypes | null {
    if (this.payrollProvider === 'gusto') {
      return Form8974SideDrawerTypes.TIER1_GUSTO;
    }
    if (this.payrollProvider === 'rippling') {
      return Form8974SideDrawerTypes.TIER1_RIPPLING;
    }
    if (this.payrollProvider === 'justworks') {
      return Form8974SideDrawerTypes.TIER1_JUSTWORKS;
    }
    if (this.tier2Providers.includes(this.payrollProvider)) {
      return Form8974SideDrawerTypes.TIER2;
    }
    return null;
  }

  public get payrollProviderIsSupportedFor2021Form8974Experience(): boolean {
    if (this.payrollProvider === 'adp_run') {
      // adp_run is not considered a valid payroll provider for any tier
      return false;
    }
    const includeTier3Providers =
      this.rootStore.common.featureFlags.flags.is8974MultiYearSupportEnabled;
    if (includeTier3Providers) {
      return !_.isEmpty(this.payrollProvider);
    }

    const supportedProviders = [...this.tier1Providers, ...this.tier2Providers];
    return supportedProviders.includes(this.payrollProvider);
  }

  public get show8974CreditRedemptionsCard(): boolean {
    return (
      (this.actionablePrograms.length > 0 &&
        this.payrollProviderIsSupportedFor2021Form8974Experience) ||
      (this.payrollTier === PayrollTierEnum.TIER_3 && this.canRedeemCredits)
    );
  }

  public get shouldShow8974CreditRedemptionsCta(): boolean {
    return (
      this.show8974CreditRedemptionsCard ||
      (this.payrollTier === PayrollTierEnum.TIER_3 && this.canRedeemCredits)
    );
  }

  public formatFilingDateToISO = (filingDate: string): string => {
    const tokens = filingDate.split('/'); // MM/DD/YYYY
    return `${tokens[2]}-${tokens[0]}-${tokens[1]}`; // YYYY-MM-DD
  };

  /**
   * Returns true if the program could have redemptions made against it,
   * meaning 8974 setup is complete. Does NOT consider anything about the program
   * balance or whether it's the right time to generate 8974s.
   */
  public programIsComplete = (program: ProgramData): boolean => {
    return (
      program.stage === ProgramStageEnum.FINISHED &&
      (program.subStage === ProgramSubStageEnum.FILING_UPLOADED ||
        program.subStage === ProgramSubStageEnum.REDEEMING)
    );
  };

  public get isNotTimeForTier2Or3ToGenerate8974(): boolean {
    if (this.payrollTier === PayrollTierEnum.TIER_3) {
      /**
       * Tier 3 customers can generate their 8974 on or after the first day of the
       * ~second~ quarter following the quarter in which they filed their taxes.
       * This is why we use FirstDayOfNextQuarter twice.
       **/

      if (!this.mostRecentFinishedProgram?.taxFilingDate) {
        return false;
      }

      const convertDate = new Date(
        `${new Date(this.mostRecentFinishedProgram.taxFilingDate)} UTC`,
      );
      const nextQuarter = FirstDayOfNextQuarter(convertDate);

      return new Date() < FirstDayOfNextQuarter(nextQuarter);
    }

    if (
      !this.mostRecentFinishedProgram?.taxFilingDate ||
      this.mostRecentFinishedProgram?.stage !== ProgramStageEnum.FINISHED
    ) {
      return false;
    }

    if (this.payrollTier === PayrollTierEnum.TIER_2) {
      /**
       * Tier 2 customers can generate their 8974 on or after the first day of the
       * quarter following the quarter in which they filed their taxes.
       **/
      return (
        new Date() <
        FirstDayOfNextQuarter(
          new Date(this.mostRecentFinishedProgram.taxFilingDate),
        )
      );
    }

    return false;
  }

  public async fetchCompanyPayrollProvider(companyId: number) {
    const res = await this.client.GetCompanyPayrollProvider(companyId);

    if (res.errorMsg) {
      datadogLogs.logger.error(
        `Failed to fetch company payroll provider with company id: ${companyId}`,
        {
          companyId,
        },
      );
    }

    runInAction(() => {
      this.payrollTier = res.data?.payrollTier || PayrollTierEnum.TIER_3;
      this.payrollProvider = res.data?.provider.toLowerCase() || '';
    });

    // check filing status
    if (res.data?.payrollTier) {
      await this.rootStore.taxcredits.redemption.checkFilingStatus(
        res.data?.payrollTier,
      );
    }
  }

  public async updateProgramSubStage(
    programId: number,
    programSubStage: ProgramSubStageEnum,
  ) {
    await this.client.UpdateProgramSubStage({ programId, programSubStage });

    runInAction(() => {
      this.updateProgramSubStageInState(programId, programSubStage);
    });
  }

  public async updateProgramStage(
    programId: number,
    programStage: ProgramStageEnum,
    programSubStage?: ProgramSubStageEnum,
  ) {
    const programUpdateCalls = [
      this.client.UpdateProgramStage({ programId, programStage }),
    ];

    if (programSubStage) {
      programUpdateCalls.push(
        this.client.UpdateProgramSubStage({ programId, programSubStage }),
      );
    }

    await Promise.all(programUpdateCalls);

    runInAction(() => {
      this.updateProgramStageInState(programId, programStage);
      if (programSubStage) {
        this.updateProgramSubStageInState(programId, programSubStage);
      }
    });
  }

  /**
   * Used by all tiers from their respective side drawer to confirm
   * payroll provider setup.
   **/
  public async handleCompletePayrollProviderSetup(programId: number) {
    runInAction(() => {
      this.payrollProviderConfirmationProcessing = true;
    });

    const program = this.programs.find((p) => p.id === programId);
    if (!program) {
      runInAction(() => {
        this.requestError = this.GENERIC_ERROR_MESSAGE;
        datadogLogs.logger.error(
          `Attempted to update program not found in client store with id: ${programId}`,
          {
            companyId: this.rootStore.common.companyStore.currentCompany.id,
          },
        );
        this.payrollProviderConfirmationProcessing = false;
      });
      return;
    }

    const updateProgramResponse = await this.client.UpdateProgram(programId, {
      payrollProviderSetupCompleted8974: true,
      stage: ProgramStageEnum.FINISHED,
      subStage: program.subStage ?? ProgramSubStageEnum.REDEEMING,
    });

    if (updateProgramResponse.errorMsg) {
      runInAction(() => {
        this.requestError = updateProgramResponse.errorMsg;
        datadogLogs.logger.error(
          `There was an issue saving the 8974 filing confirmation for programId: ${programId}`,
          {
            companyId: this.rootStore.common.companyStore.currentCompany.id,
            error: updateProgramResponse.errorMsg,
          },
        );
        this.payrollProviderConfirmationProcessing = false;
      });
      return;
    }

    this.updateProgramStageInState(programId, ProgramStageEnum.FINISHED);
    runInAction(() => {
      this.payrollProviderConfirmationProcessing = false;
      program.payrollProviderSetupCompleted8974 = true;
    });
    this.toggleSideDrawer(false);
  }
}
