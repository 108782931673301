import React from 'react';
import { observer } from 'mobx-react';
import { Flex, Heading, Text, TextProps } from 'component-library';
import { ContentFrame } from '../../components';

interface FileFormsIRSProps {
  headingSize: number;
  textFontSize: TextProps['size'];
}

export const FileFormsIRS: React.FC<FileFormsIRSProps> = observer(
  ({ headingSize, textFontSize }) => {
    return (
      <ContentFrame>
        <Flex direction='column' gap={16}>
          <Heading
            text='File forms with IRS'
            variant='medium'
            size={headingSize}
            marginBottom={0}
          />
          <Text size={textFontSize}>
            After an R&D specialist from MainStreet reviews your inputs and
            generates Form 6765 for you, you <strong>*must*</strong> file it
            with your business income taxes.
          </Text>
          <Text
            size={textFontSize}
            text='Be sure to give your tax preparer a heads-up that this form is coming!'
          />
        </Flex>
        <Flex direction='column' gap={16}>
          <img
            src={`${process.env.PUBLIC_URL}/images/dashboard/educational-module/images/File-with-IRS.svg`}
            title='File Forms'
          />
        </Flex>
      </ContentFrame>
    );
  },
);
