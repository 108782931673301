import { Color, Text, Flex, FontAwesome } from 'component-library';
import { makeStyles, Theme } from '@material-ui/core';
import { ValidationState } from 'lib/usePasswordValidation';
import React from 'react';

enum PasswordKeysEnum {
  MINIMUM = 'minimum',
  UPPERCASE = 'uppercase',
  LOWERCASE = 'lowercase',
  NUMBER = 'number',
}

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  container: {
    [breakpoints.down('sm')]: {
      marginTop: '16px',
    },
  },
  bullet: {
    width: 24,
  },
}));

interface PasswordChecklistProps {
  passwordValidation: Record<string, ValidationState>;
}

const passwordRequirements = [
  { key: PasswordKeysEnum.MINIMUM, text: 'Minimum of 8 characters' },
  {
    key: PasswordKeysEnum.UPPERCASE,
    text: 'At least one uppercase character',
  },
  {
    key: PasswordKeysEnum.LOWERCASE,
    text: 'At least one lowercase character',
  },
  { key: PasswordKeysEnum.NUMBER, text: 'At least one number' },
];

export const PasswordChecklist = ({
  passwordValidation,
}: PasswordChecklistProps) => {
  const classes = useStyles();

  const getRequirementStyle = (key: string) => {
    switch (passwordValidation[key]) {
      case 'valid':
        return {
          icon: (
            <span data-testid='valid_password_icon'>
              <FontAwesome name={'check'} color={Color.neutral._90} size={15} />
            </span>
          ),
          color: Color.neutral._90,
        };
      case 'invalid':
        return {
          icon: (
            <span data-testid='invalid_password_icon'>
              <FontAwesome
                name={'exclamation-circle'}
                color={Color.semantic.$error50}
                size={15}
              />
            </span>
          ),
          color: Color.semantic.$error50,
        };
      case 'default':
        return {
          icon: (
            <FontAwesome
              name={'circle-small'}
              color={Color.neutral._60}
              dataTestId='default_password_icon'
              size={6}
              variant='solid'
            />
          ),
          color: Color.neutral._60,
        };
    }
  };
  return (
    <Flex.Cell
      alignSelf='flex-start'
      padding={[8, 0, 0, 0]}
      className={classes.container}
      dataTestId='signup_password_checklist'
    >
      {passwordRequirements.map(({ key, text }) => {
        const { icon, color } = getRequirementStyle(key);
        return (
          <Flex key={key} alignItems='center' gap={8}>
            {icon}
            <Text text={text} tag='span' size={13} color={color} />
          </Flex>
        );
      })}
    </Flex.Cell>
  );
};
