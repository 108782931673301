import React from 'react';
import { Animate, Button, Color, Heading, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useCommonStores } from 'stores/useStores';
import { Page } from 'lib/constants';

const useStyles = makeStyles(() => ({
  '@global': {
    '.embeddedServiceHelpButton': {
      display: 'none',
    },
  },
  root: {
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    background: `url(${process.env.PUBLIC_URL}/images/dashboard/get-started/get-started-bg-gradient.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center',
  },
  animateContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    maxWidth: '696px',
    width: '100%',
    textAlign: 'center',
    padding: 24,
  },
  getStartedButton: {
    display: 'inline-block',
  },
}));

export const GetStarted: React.FC = observer(() => {
  const classes = useStyles();
  const { app, companyStore } = useCommonStores();

  return (
    <Animate
      className={classes.root}
      data-testid={'onboarding-get-started-screen'}
      enter={'fade-in'}
    >
      <Animate
        enter={'fade-in'}
        duration={1}
        exitDelay={1}
        exit={'fade-out'}
        delay={0.5}
        className={classes.animateContent}
      >
        <Heading variant='medium' size={app.isMobile ? 24 : 36}>
          <Heading tag='span'>Welcome to MainStreet</Heading>
          <br />
          <Heading tag='span' color={Color.blue._60}>
            {companyStore.currentCompany.adminName.first}
          </Heading>
        </Heading>
      </Animate>
      <Animate
        enter={'fade-in'}
        duration={1}
        delay={4}
        className={classes.animateContent}
      >
        <Heading variant='medium' size={app.isMobile ? 24 : 36}>
          Let&apos;s see if your company qualifies for R&D tax credits with
          MainStreet
        </Heading>
        <Text paddingBottom={32}>
          If qualified, you&apos;ll receive an estimate of your company&apos;s
          potential tax savings.
        </Text>
        <Animate enter={'fade-in'} delay={4.8}>
          <Button
            className={classes.getStartedButton}
            dataTestId='get-started-button'
            label='Get started'
            onClick={() => app.history.push(`/${Page.universalOnboarding}`)}
          />
        </Animate>
      </Animate>
    </Animate>
  );
});
