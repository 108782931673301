import React from 'react';
import { Text } from 'component-library';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((colors: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  sectionHeader: {
    borderBottom: '1px solid',
    borderBottomColor: colors.lighterGrey,
  },
  list: {
    margin: 0,
    paddingLeft: 24,
    paddingBottom: 8,
  },
}));

export const FinishedStepFAQ = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Text
        variant={'medium'}
        className={classes.sectionHeader}
        paddingBottom={8}
      >
        What&apos;s this step?
      </Text>
      <Text paddingBottom={8}>
        After filing your R&D credit forms alongside your business income taxes,
        there may be one final step to redeem your credits!
      </Text>
      <Text paddingBottom={8}>
        There are two ways to redeem the R&D credit:
      </Text>
      <ul className={classes.list}>
        <li>
          <Text paddingBottom={16}>
            <strong>Income tax credit:</strong> Your credit will instantly
            reduce your business income tax liability. You don’t need to do
            anything else after filing Form 6765 alongside your business income
            taxes.
          </Text>
        </li>
        <li>
          <Text>
            <strong>Payroll tax credit:</strong> In the quarter after you’ve
            filed Form 6765, you’ll need to file a Form 8974 with your quarterly
            payroll taxes in order to redeem your credits.
          </Text>
        </li>
      </ul>
      <Text>
        Don’t worry, we’ll let you know exactly what to do once we get to this
        step.
      </Text>
    </div>
  );
};
