import { WireInstructionsItems } from 'lib/interfaces';
import { AT_MS_DOMAIN } from '.';

export const YIELD_TEAM_EMAIL = `yieldteam${AT_MS_DOMAIN}`;

export const wireInstructions: WireInstructionsItems = {
  account_number: '6777648148',
  routing_number: '053112929',
  reference_code: '-PN1',
  depository_bank_name: 'Blue Ridge Bank, N.A.',
  credit_to: 'MainStreet Yield LLC',
  bank_phone_number: '',
  bank_address: '1 East Market Street, Martinsville, VA 24112',
  beneficiary_address: '320 N 3700 W, Unit 22690, Salt Lake City, UT 84122',
};

export const instructionsLabels: any = {
  depository_bank_name: 'Bank Name',
  account_number: 'Account Number',
  routing_number: 'Routing Number',
  bank_address: 'Bank Address',
  credit_to: 'Credit To',
  beneficiary_address: 'Beneficiary Address',
  reference_code: 'Reference',
};
