import React from 'react';
import { makeStyles } from '@material-ui/core';

interface Props {
  className?: string;
}

const useStyles = makeStyles(() => ({
  '@keyframes draw': {
    to: {
      strokeDashoffset: 0,
    },
  },
  documentImage: {
    '& > .line-1': {
      fill: 'none',
      stroke: '#0A190C',
      strokeWidth: 4,
      strokeDasharray: 28,
      strokeDashoffset: 28,
      animation: '$draw 1.4s ease-out 1s infinite alternate',
    },
    '& > .line-2': {
      fill: 'none',
      stroke: '#0A190C',
      strokeWidth: 4,
      strokeDasharray: 28,
      strokeDashoffset: 28,
      animation: '$draw 1.4s ease-out 1.4s infinite alternate',
    },
    '& > .line-3': {
      fill: 'none',
      stroke: '#0A190C',
      strokeWidth: 4,
      strokeDasharray: 28,
      strokeDashoffset: 28,
      animation: '$draw 1.4s ease-out 1.8s infinite alternate',
    },
  },
}));

const Form8974 = ({ className }: Props) => {
  const classes = useStyles();

  return (
    <svg
      className={`${classes.documentImage} ${className}`}
      width='112'
      height='148'
      viewBox='0 0 112 148'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M96.9167 14.8333H1.62506V146.625H96.9167V14.8333Z'
        fill='#0A190C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.583374 14.8333C0.583374 14.258 1.04974 13.7917 1.62504 13.7917H96.9167C97.492 13.7917 97.9584 14.258 97.9584 14.8333V146.625C97.9584 147.2 97.492 147.667 96.9167 147.667H1.62504C1.04974 147.667 0.583374 147.2 0.583374 146.625V14.8333ZM2.66671 15.875V145.583H95.875V15.875H2.66671Z'
        fill='#0A190C'
      />
      <path
        d='M15.0834 1.37497V133.167H110.375V26.2708L85.4792 1.37497H15.0834Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.0417 1.37498C14.0417 0.799683 14.5081 0.333313 15.0834 0.333313H85.4792C85.7554 0.333313 86.0204 0.44306 86.2157 0.63841L111.112 25.5342C111.307 25.7296 111.417 25.9945 111.417 26.2708V133.167C111.417 133.742 110.95 134.208 110.375 134.208H15.0834C14.5081 134.208 14.0417 133.742 14.0417 133.167V1.37498ZM16.125 2.41665V132.125H109.333V26.7023L85.0477 2.41665H16.125Z'
        fill='#0A190C'
      />
      <path
        d='M87.0209 2.91666V24.75L108.833 24.7292L87.0209 2.91666Z'
        fill='#ACE119'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M86.6222 1.95426C87.0115 1.79303 87.4595 1.88215 87.7574 2.18007L109.57 23.9926C109.868 24.2904 109.957 24.7381 109.796 25.1273C109.635 25.5165 109.255 25.7704 108.834 25.7708L87.0218 25.7916C86.7454 25.7919 86.4802 25.6823 86.2846 25.4869C86.0891 25.2915 85.9792 25.0264 85.9792 24.75V2.91664C85.9792 2.49532 86.233 2.11549 86.6222 1.95426ZM88.0625 5.43144V23.7073L106.321 23.6899L88.0625 5.43144Z'
        fill='#0A190C'
      />
      <path
        d='M89.6459 55.625H35.8126V66.3958H89.6459V55.625Z'
        fill='#ACE119'
      />
      <path d='M63 79H90' stroke='none' className='line-1' />
      <path d='M63 92H90' stroke='none' className='line-2' />
      <path d='M63 105H90' stroke='none' className='line-3' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.7709 55.625C34.7709 55.0497 35.2372 54.5833 35.8125 54.5833H89.6459C90.2212 54.5833 90.6875 55.0497 90.6875 55.625V66.3958C90.6875 66.9711 90.2212 67.4375 89.6459 67.4375H35.8125C35.2372 67.4375 34.7709 66.9711 34.7709 66.3958V55.625ZM36.8542 56.6666V65.3541H88.6042V56.6666H36.8542Z'
        fill='#0A190C'
      />
      <path
        d='M43.3959 100.833C39.5834 100.5 37.7709 98.1875 37.7501 95.2083H40.6251C40.7084 97.5 42.0417 98.6666 44.4376 98.6666C46.6667 98.6666 47.9376 97.5208 47.9376 95.8541C47.9376 93.9166 46.2709 93.5625 45.5834 93.375L43.0209 92.7916C41.3542 92.3958 38.0834 91.6458 38.0834 88C38.0834 85.2083 40.1459 83.2916 43.3959 83.0416V80.6875H45.3751V83.0625C48.5417 83.4375 50.2709 85.3333 50.3126 88.0833H47.4584C47.3542 86.2083 46.1251 85.2083 44.0626 85.2083C42.1876 85.2083 41.0001 86.1666 41.0001 87.6458C41.0001 89.4583 42.6459 89.8958 43.6042 90.125L46.3959 90.7083C48.6251 91.1875 50.8751 92.4375 50.8751 95.625C50.8751 98.625 48.7501 100.542 45.3542 100.833V103.208H43.3751V100.854L43.3959 100.833Z'
        fill='black'
      />
      <path
        d='M29.5626 32.7291C29.5626 31.1041 30.5001 29.7708 32.0209 29.375V29.2291C30.6042 28.75 29.8126 27.5208 29.8126 26C29.8126 23.5416 31.7292 22.0625 34.7292 22.0625C37.7292 22.0625 39.6667 23.6041 39.6667 26.0625C39.6667 27.5416 38.8334 28.8125 37.4792 29.2291V29.375C38.9792 29.6875 39.9167 31.0208 39.9167 32.7083C39.9167 35.2083 37.8334 36.6666 34.7292 36.6666C31.6251 36.6666 29.5626 35.1666 29.5626 32.7291ZM37.2084 32.6458V32.1458C37.2084 30.9375 36.2501 30.3125 34.7084 30.3125C33.1667 30.3125 32.2292 30.9166 32.2292 32.1458V32.6458C32.2292 33.8125 33.2084 34.4583 34.7084 34.4583C36.2709 34.4583 37.2084 33.75 37.2084 32.6458ZM37.0834 26.5208V26.0208C37.0834 24.8125 36.1667 24.2083 34.7084 24.2083C33.2501 24.2083 32.3751 24.8125 32.3751 26.0208V26.5208C32.3751 27.8333 33.5001 28.3333 34.7084 28.3333C35.9167 28.3333 37.0834 27.7916 37.0834 26.5208Z'
        fill='black'
      />
      <path
        d='M42.6459 32.9166H45.2917C45.4792 34.0208 46.4167 34.4583 47.3959 34.4583C49.3751 34.4583 50.2084 32.9375 50.1251 29.9375H49.9584C49.4584 30.9375 48.2292 31.5625 46.8126 31.5625C44.1667 31.5625 42.3542 30.0208 42.3542 26.9375C42.3542 23.8541 44.2292 22.0833 47.4376 22.0833C51.3959 22.0833 52.7709 25.1041 52.7709 29.3125C52.7709 34.0833 50.9792 36.6666 47.3334 36.6666C44.7501 36.6666 42.9168 35.375 42.6251 32.9375L42.6459 32.9166ZM49.7084 27.3541V26.6041C49.7084 25.2291 48.8126 24.375 47.3751 24.375C45.9376 24.375 45.0626 25.2291 45.0626 26.4375V27.5416C45.0626 28.8541 46.0001 29.5625 47.3751 29.5625C48.8334 29.5625 49.7084 28.7083 49.7084 27.375V27.3541Z'
        fill='black'
      />
      <path
        d='M54.9584 22.25H65V24.0833C61.4792 30.2083 60.9375 31.625 60.8334 36.5H58.0834C58.1667 31.125 59.0625 29.75 62.0834 24.75V24.5H57.0417V26.9375H54.9584V22.2708V22.25Z'
        fill='black'
      />
      <path
        d='M73.5209 33.2708H66.8959V30.9583L72.6042 22.25H76.0625V31.1666H78.0834V33.2708H76.0625V36.5H73.5209V33.2708V33.2708ZM73.5209 31.1666V24.9375H73.3334L69.4167 30.9791V31.1458H73.5L73.5209 31.1666Z'
        fill='black'
      />
    </svg>
  );
};

export default Form8974;
