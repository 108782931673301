import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    margin: '48px',
    maxWidth: '800px',
  },
  title: {
    fontSize: '32px',
    textAlign: 'center',
  },
  spacedContent: {
    margin: '16px 0',
  },
  subHeader: {
    margin: '16px 0 10px',
  },
  list: {
    margin: 0,
  },
});

export default function QreGuide() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant='h2' gutterBottom className={classes.title}>
        Guide to Qualifying Research Expenditures for the Federal R&amp;D Tax
        Credit
      </Typography>
      <Typography variant='body1' component='div'>
        <div className={classes.spacedContent}>
          The Federal R&amp;D Tax Credit allows companies to receive a tax
          credit for their qualified R&D expenditures. Determining what does and
          does qualify is critical to properly determining the amount of credit
          a company can take.
        </div>
        <div className={classes.spacedContent}>
          Companies can determine whether a portion of their work is classified
          as R&D by evaluating it against the IRS’s four-part R&amp;D test:
          <ul className={classes.list}>
            {[
              {
                item: 'Section 174 test',
                subItem:
                  'The activities involve the elimination of uncertainty concerning the development or improvement of a product',
              },
              {
                item: 'Business Component Test',
                subItem:
                  'The activities are useful in the development of a new or improved business component (e.g. a product, formula, or software that will be licensed or sold or used by the company in their business)',
              },
              {
                item: 'Discovery Test',
                subItem:
                  'The activities discover information that is technological in nature, relying on principles of the physical or biological sciences, engineering, or computer science',
              },
              {
                item: 'Process of Experimentation Test',
                subItem:
                  'Substantially, the activities are experimental in nature and conducted for a permitted purpose (being used for the development of a new or improved business component)',
              },
            ].map((testItem) => {
              return (
                <li key={testItem.item}>
                  {testItem.item}
                  <ul>
                    <li>{testItem.subItem}</li>
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={classes.spacedContent}>
          For employees, time spent both engaging in qualified research and
          directly supervising employees engaging in qualifying research can be
          counted for the credit.
        </div>
        <div className={classes.spacedContent}>
          Depending on the kind of work the company does, different activities
          may constitute R&amp;D. Below are some common examples of activities
          that do and don’t qualify.
        </div>
        <Typography variant='h3' className={classes.subHeader}>
          Common Examples of R&amp;D activities that qualify
        </Typography>
        <div>
          Writing specs, doing design reviews, writing code on core products,
          writing tests, writing documentation, creating architecture designs,
          having meetings on project progress, having meetings to make project
          decisions, writing emails to clarify decisions, creating user
          experience designs affecting user flow
        </div>
        <Typography variant='h3' className={classes.subHeader}>
          Common Examples of R&amp;D activities that don&apos;t qualify
        </Typography>
        <div>
          fundraising, marketing, building the marketing website, setting up
          other software, forming partnerships, working on production issues,
          working with customers, creating designs only for visual improvement
        </div>
      </Typography>
    </div>
  );
}
