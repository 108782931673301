import React from 'react';
import {
  RouteConfiguration,
  UNAUTH_ROUTE_CONFIG,
} from 'routes/RouteConfiguration';
import { Page } from 'lib/constants';
import { GoogleLoginCompletePage } from 'pages/login/GoogleLoginCompletePage';
import { LogoutPage } from 'pages/LogoutPage';
import { useCompany, useFeatureFlags } from 'stores/useStores';
import NewSettingsPage from 'pages/dashboard/settings';
import HappyHappyJoyJoy from 'pages/HappyHappyJoyJoy';
import { SelfSignInMobileWarningPage, ResetPasswordPage } from 'pages/login';
import Refer from 'pages/Refer';
// import { WelcomePage } from 'products/onboarding';
import { WelcomePage as LegacyWelcomePage } from 'pages/signup/WelcomePage';
import { WelcomePagePartner } from 'pages/signup/WelcomePagePartner';
import { WelcomePartnerPage } from 'products/onboarding';
import { datadogLogs } from '@datadog/browser-logs';
import { DiscoverPage } from 'products/dashboard';
import TermsPage from '../products/onboarding/features/terms/TermsPage';

export const useAuthRoutes = (): RouteConfiguration[] => {
  const { company } = useCompany();
  const featureFlags = useFeatureFlags();
  const logger = datadogLogs.createLogger(__filename);

  const renderComponent = () => {
    // Check the value of showNewWelcomePage and log accordingly
    if (featureFlags.showNewWelcomePage) {
      logger.info('Rendering the new Welcome Page for Company ' + company.id);
      // return <WelcomePage />;
      return <DiscoverPage />;
    } else {
      logger.info(
        'Rendering the legacy Welcome Page for Company ' + company.id,
      );
      return <LegacyWelcomePage />;
    }
  };

  return [
    {
      path: `/${Page.logout}`,
      render: () => <LogoutPage />,
      ...UNAUTH_ROUTE_CONFIG,
    },
    {
      path: `/${Page.selfSignupCreateAccount}`,
      render: () => <LegacyWelcomePage />,
      hideTopNav: true,
      hideSideNav: true,
      authRequired: false,
      companyRequired: false,
      bypassMobilePrint: true,
      redirectPath: company.isLoaded ? '/' : undefined,
      documentPageTitle: 'Sign up',
    },
    {
      path: [
        `/${Page.selfSignupWelcome}`,
        `/${Page.selfSignupDisqualify}`,
        `/${Page.selfSignupOnboarding}`,
        `/${Page.selfSignupPersonInformation}`,
      ],
      render: () => renderComponent(),
      hideTopNav: true,
      hideSideNav: true,
      authRequired: false,
      companyRequired: false,
      bypassMobilePrint: true,
      redirectPath: company.isLoaded ? '/' : undefined,
      documentPageTitle: 'Save thousands on business taxes',
    },
    {
      path: `/${Page.selfSignupWelcome}/:partner/registration`,
      render: (params) => <WelcomePagePartner partner={params.partner} />,
      hideTopNav: true,
      hideSideNav: true,
      authRequired: false,
      companyRequired: false,
      bypassMobilePrint: true,
      redirectPath: company.isLoaded ? '/' : undefined,
      documentPageTitle: 'Sign up',
    },
    {
      path: `/${Page.selfSignupWelcome}/:partner`,
      render: (params) => <WelcomePartnerPage partner={params.partner} />,
      hideTopNav: true,
      hideSideNav: true,
      authRequired: false,
      companyRequired: false,
      bypassMobilePrint: true,
      redirectPath: company.isLoaded ? '/' : undefined,
    },
    {
      path: `/${Page.discover}`,
      render: () => <DiscoverPage />,
      hideTopNav: true,
      hideSideNav: true,
      authRequired: false,
      companyRequired: false,
      bypassMobilePrint: true,
      redirectPath: company.isLoaded ? '/' : undefined,
      documentPageTitle: 'Save thousands on business taxes',
    },
    {
      path: `/${Page.googleLoginSuccess}`,
      render: (params, query) => (
        <GoogleLoginCompletePage signup={query.signup as string} />
      ),
      hideTopNav: true,
      hideSideNav: true,
      authRequired: false,
      companyRequired: false,
      bypassMobilePrint: true,
    },
    {
      path: `/${Page.warningMobile}`,
      render: () => <SelfSignInMobileWarningPage />,
      ...UNAUTH_ROUTE_CONFIG,
    },
    {
      path: `/${Page.settings}`,
      render: () => <NewSettingsPage />,
      documentPageTitle: 'Settings',
    },
    {
      path: `/${Page.refer}`,
      render: () => <Refer />,
      documentPageTitle: 'Referrals',
    },
    {
      path: `/${Page.resetPassword}`,
      render: (params, query, history) => (
        <ResetPasswordPage search={history.search} />
      ),
      companyRequired: false,
      documentPageTitle: 'Reset Password',
    },
    {
      path: '/happy-happy-joy-joy',
      render: (props, query, history) => (
        <HappyHappyJoyJoy search={history.search} />
      ),
    },
    {
      path: `/${Page.terms}/:termsName`,
      render: (params) => <TermsPage termsName={params.termsName} />,
      hideTopNav: true,
      hideSideNav: true,
      authRequired: false,
      companyRequired: false,
      bypassMobilePrint: true,
    },
  ];
};
