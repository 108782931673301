import { Button, Color, Flex } from 'component-library';
import { ClaimBanner } from '../../../tax-credits/features/unified-tax-credits/components';
import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  claimBanner: {
    backgroundColor: '#e4f6e9',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 8,
  },
  claimBgAccent: {
    position: 'absolute',
    bottom: '-100px',
    left: '620px',
    backgroundColor: Color.green._60,
    borderRadius: '50%',
    height: '400px',
    width: '400px',
    zIndex: 0,
  },
  claimIllustration: {
    width: '28%',
  },
  claimButton: {},
  claimButtonLink: {
    color: 'white',
  },
}));
export const SharedSavingsBanner: React.FC = observer(() => {
  const classes = useStyles();

  const onClick = () => {
    window.location.href = `${window.location.origin}/shared-savings`;
  };

  return (
    <Flex>
      <ClaimBanner
        title={<>Save more money, all year long</>}
        subtitle={
          <>
            Discover MainStreet&apos;s latest range of
            savings&nbsp;—&nbsp;unlock hidden tax savings for you and your
            employees.
          </>
        }
        claimBannerClassName={classes.claimBanner}
        claimBgAccentClassName={classes.claimBgAccent}
        illustrationClassName={classes.claimIllustration}
        buttonCta={
          <>
            <Button
              label={`Learn More`}
              onClick={onClick}
              flexAlignSelf={'flex-start'}
              variant={'outlined'}
            ></Button>
          </>
        }
      />
    </Flex>
  );
});
