import * as _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Card,
  CardFooter,
  Expandable,
  ExpandableHeader,
  Flex,
  Text,
  Alert,
  Color,
} from 'component-library';
import RdVendorsAndExpensesTable from './RdVendorsAndExpensesTable';
import { Auth0FeatureContext } from '../../../../../components/util/Auth0Feature';
import {
  DocumentForUpload,
  ProgramData,
  RdVendorExpense,
  RdVendorExpenseReceipt,
} from '../../../../../lib/interfaces';
import RdSideDrawerVendor from './RdSideDrawerVendor';
import { RdVendorExpenseType } from '../../../../../lib/constants';
import { datadogLogs } from '@datadog/browser-logs';
import { makeStyles } from '@material-ui/core';
import { useCommonStores, useFeatureFlags } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  mainContent: {
    marginTop: 0,
    marginBottom: 16,
  },
}));

const generateAmountLabel = (
  expenseType: RdVendorExpenseType,
  program: ProgramData,
): string => {
  const label =
    expenseType === RdVendorExpenseType.CLOUD
      ? 'Total cloud computing spend'
      : 'Total supply spend';
  if (!program.taxYear) {
    return label;
  }
  return label + ' in ' + program.taxYear.toString() + ' tax year';
};

interface VendorsAndExpensesProps {
  program: ProgramData;
  expenseType: RdVendorExpenseType;
  onRdVendorExpensesFinished: () => void;
}

const RdVendorsAndExpenses = ({
  program,
  expenseType,
  onRdVendorExpensesFinished,
}: VendorsAndExpensesProps) => {
  const emptyVendor = {
    id: 0,
    name: '',
    programId: 0,
    amountCents: 0,
    expenseType: expenseType,
    receipts: [],
  } as RdVendorExpense;
  const classes = useStyles();
  const { client } = useContext(Auth0FeatureContext);
  const { companyStore } = useCommonStores();
  const featureFlags = useFeatureFlags();
  const [isVendorDrawerOpen, setIsVendorDrawerOpen] = useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] =
    useState<RdVendorExpense>(emptyVendor);
  const [vendorExpenses, setVendorExpenses] = useState<RdVendorExpense[]>([]);
  const [isEditMode, setIsEditMode] = useState(true);
  const [alertError, setAlertError] = useState<string>('');
  const [attachedReceipts, setAttachedReceipts] = useState<
    RdVendorExpenseReceipt[]
  >([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isEditingRow, setIsEditingRow] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [attachmentsToDelete, setAttachmentsToDelete] = useState<number[]>([]);
  const isSuppliesType = expenseType !== RdVendorExpenseType.CLOUD;

  const suppliesExpensesTitle = `Add all vendors you used to purchase these supplies and the total amount spent during the ${program.taxYear} tax year with each of them`;
  const cloudExpensesTitle = `Add your ${program.taxYear} tax year cloud computing vendors and expenses`;
  const title = isSuppliesType ? suppliesExpensesTitle : cloudExpensesTitle;

  const onRdVendorExpensesFinishedCallback = useCallback(() => {
    onRdVendorExpensesFinished();
  }, [onRdVendorExpensesFinished]);

  useEffect(() => {
    onRdVendorExpensesFinished();
  }, [vendorExpenses]);

  useEffect(() => {
    const getRdVendorExpenses = companyStore.accessToken
      ? client.GetRdVendorExpensesPublic(companyStore.accessToken, program.id)
      : client.GetRdVendorExpenses(program.id);
    getRdVendorExpenses.then((response) => {
      if (response.data && !response.errorMsg) {
        const expensesForCurrentType = response.data.filter(
          (expense) => expense.expenseType === expenseType,
        );
        setVendorExpenses(expensesForCurrentType);

        if (expensesForCurrentType.length > 0) {
          setIsEditMode(false);
          onRdVendorExpensesFinishedCallback();
        }
      }
    });
  }, [program, client, expenseType, onRdVendorExpensesFinishedCallback]);

  function onContinue() {
    setIsEditMode(false);
    onRdVendorExpensesFinished();
  }

  const addVendor = (vendor: RdVendorExpense) => {
    const postRdVendorExpenses = companyStore.accessToken
      ? client.PostRdVendorExpensesPublic(
          companyStore.accessToken,
          program.id,
          vendor,
        )
      : client.PostRdVendorExpenses(program.id, vendor);

    postRdVendorExpenses.then((response) => {
      if (response.data && !response.errorMsg) {
        const newVendor: RdVendorExpense[] = [];
        let newVendorsList: RdVendorExpense[] = [];
        newVendor.push(response.data);
        setIsSaving(false);
        setIsVendorDrawerOpen(false);
        setFiles([]);
        setVendorExpenses((previousState) => {
          newVendorsList = [...previousState, ...newVendor];
          return newVendorsList;
        });
      }
    });
  };

  const removeVendor = (vendor: RdVendorExpense) => {
    const deleteErrorMsg =
      "We couldn't complete your request. Please try again.";

    const deleteRdVendorExpense = companyStore.accessToken
      ? client.DeleteRdVendorExpensePublic(
          companyStore.accessToken,
          program.id,
          vendor,
        )
      : client.DeleteRdVendorExpense(program.id, vendor);
    deleteRdVendorExpense
      .then((res) => {
        if (res.errorMsg) {
          console.error(res.errorMsg);
          setAlertError(deleteErrorMsg);
        } else {
          const removeVendor = vendorExpenses.filter(
            (item) => item.id !== vendor.id,
          );
          setVendorExpenses([...removeVendor]);
          setSelectedVendor(emptyVendor);
          setAttachedReceipts([]);
          setIsVendorDrawerOpen(false);
        }
      })
      .catch(setAlertError);
  };

  const editVendor = async (
    vendor: RdVendorExpense,
    attachedToDelete: number[],
    newUploadedFiles: RdVendorExpenseReceipt[],
  ): Promise<{ errorMsg?: string }> => {
    const putRdVendorExpenses = companyStore.accessToken
      ? client.PutRdVendorExpensesPublic(
          companyStore.accessToken,
          program.id,
          vendor,
        )
      : client.PutRdVendorExpenses(program.id, vendor);

    const response = await putRdVendorExpenses;
    if (response.errorMsg) {
      return { errorMsg: response.errorMsg };
    }
    if (!response.data) {
      return { errorMsg: `writing vendor expense returned no data` };
    }
    const changedVendor: RdVendorExpense = response.data;
    const changedIndex = vendorExpenses.findIndex(
      (expense) => expense.id === vendor.id,
    );

    if (newUploadedFiles.length > 0) {
      const docIds = newUploadedFiles.map((receipt) => receipt.documentId);

      const putRdVendorExpensesReceipts = companyStore.accessToken
        ? client.PutRdVendorExpenseReceiptsPublic(
            companyStore.accessToken,
            program.id,
            vendor,
            docIds,
          )
        : client.PutRdVendorExpenseReceipts(program.id, vendor, docIds);
      const { errorMsg } = await putRdVendorExpensesReceipts;
      if (errorMsg) {
        return { errorMsg };
      }

      changedVendor.receipts = changedVendor.receipts.concat(newUploadedFiles);
    }

    if (attachedToDelete) setIsSaving(false);
    setIsVendorDrawerOpen(false);
    setFiles([]);

    setVendorExpenses((prevState) => {
      const before = prevState.slice(0, changedIndex);
      const after = prevState.slice(changedIndex + 1);
      const tmp = [...before, changedVendor, ...after];
      return tmp;
    });

    return {};
  };

  // save the vendor receipt files to the document table
  const saveVendorReceipts = async (
    files: File[],
  ): Promise<{ documentIds?: number[]; errorMsg?: string }> => {
    const toUploadDocs = files.map(
      (file: File): DocumentForUpload => ({
        file: file,
        description: `${selectedVendor.name} - Vendor Expense Receipt`,
        name: file.name,
      }),
    );

    const uploadCompanyDocuments = companyStore.accessToken
      ? client.UploadCompanyDocumentsPublic(companyStore.accessToken, {
          documents: toUploadDocs,
          programId: program.id.toString(),
        })
      : client.UploadCompanyDocuments({
          documents: toUploadDocs,
          programId: program.id.toString(),
        });

    const { errorMsg, data } = await uploadCompanyDocuments;
    if (errorMsg) {
      return { errorMsg };
    }
    const documentIds: number[] = data ? data.documents.map((d) => d.id) : [];
    return { documentIds };
  };

  const deleteAttachmentsFromExpense = async (
    programId: number,
    vendorId: number,
    docIds: number[],
  ): Promise<{ errorMsg?: string }> => {
    const deleteAttachmentsFromVendorExpense = companyStore.accessToken
      ? client.DeleteAttachmentsFromVendorExpensePublic(
          companyStore.accessToken,
          programId,
          vendorId,
          docIds,
        )
      : client.DeleteAttachmentsFromVendorExpense(programId, vendorId, docIds);

    const response = await deleteAttachmentsFromVendorExpense;
    if (response.errorMsg) {
      return { errorMsg: response.errorMsg };
    }
    if (!response.data) {
      return { errorMsg: `delete attachments from expense returned no data` };
    }
    if (!response.data.deletedIds) {
      return {
        errorMsg: `null array of deleted objects returned from deleting attachments`,
      };
    }

    if (_.isEqual(response.data.deletedIds.sort(), docIds.sort())) {
      return {}; // success!
    }
    const s1 = new Set(response.data.deletedIds);
    const s2 = new Set(docIds);
    const diff = [...s2].filter((id) => !s1.has(id));
    // perhaps this is warning because the operation did actually remove some attachments from the expense?
    return { errorMsg: `could not remove attachments with ids ${diff}` };
  };

  const addOrUpdateVendor = async (vendor: RdVendorExpense, files: File[]) => {
    let errorString = '';
    let newUploadedFiles: RdVendorExpenseReceipt[] = [];

    if (files.length > 0) {
      // note, i'm considering saveVendorReceipts a transaction -- all or nothing. If any single file fails
      // then all the files failed to upload. There's no partial upload.
      const { documentIds, errorMsg } = await saveVendorReceipts(files);
      if (errorMsg) {
        errorString = `R&D Vendor Expenses: error saving receipts, errorMsg: ${errorMsg}`;
      } else if (!documentIds || documentIds.length === 0) {
        errorString = 'Error: no documents were saved';
      } else {
        // this shouldn't happen, because upload is considered transactional, but just in case...
        if (documentIds.length !== files.length) {
          errorString = `Error: there were errors uploading files, no files were saved.`;
        } else if (!vendor.receipts) {
          // this shouldn't happen because receipts are required.
          errorString = `Error: rejecting because no receipts were saved`;
          vendor.receipts = [];
        } else {
          // FINALLY! We reach the point we can actually save the expense itself.
          newUploadedFiles = files.map(
            (file, index) =>
              ({
                fileName: file.name,
                documentId: documentIds[index],
              } as RdVendorExpenseReceipt),
          );
        }
      }
    }

    if (errorString === '' && attachmentsToDelete.length > 0) {
      const { errorMsg } = await deleteAttachmentsFromExpense(
        program.id,
        vendor.id,
        attachmentsToDelete,
      );
      if (errorMsg) {
        errorString = errorMsg;
      } else {
        setAttachmentsToDelete([]);
      }
    }

    if (errorString === '') {
      // The primary key is defined mysql type 'int (auto increment)' which means a value of id = 0 implies create a
      // new unique key. This prevents 0 from being used as a Pk and can therefore be used to indicate adding a new row.
      // See: https://dev.mysql.com/doc/refman/8.0/en/example-auto-increment.html
      if (selectedVendor.id === 0) {
        // there has to be uploaded files otherwise you can't save a new receipt.
        vendor.receipts = newUploadedFiles;
        addVendor(vendor);
      } else {
        // There are three kinds of changes and any or all of them could happen.
        // - changed expense name or amount
        // - removed attached file
        //   - ACTION: delete the file from join table and from GCP
        // - add new files
        //   - ACTION: add to join table and add to GCP
        const deleted: number[] = [];
        const { errorMsg } = await editVendor(
          vendor,
          deleted,
          newUploadedFiles,
        );
        if (errorMsg) {
          errorString = errorMsg;
        }
      }
    }
    if (errorString) {
      setSelectedVendor(emptyVendor);
      setAttachedReceipts([]);
      datadogLogs.logger.error(`R&D Vendor Expenses: ${errorString}`);
      setAlertError(errorString);
    } else {
      setAlertError('');
    }
  };

  const configureVendorExpensesSubtitle = () => {
    if (vendorExpenses.length > 1) {
      return `${vendorExpenses.length} Vendors added`;
    }
    if (vendorExpenses.length > 0) {
      return `${vendorExpenses.length} Vendor added`;
    }
    return '';
  };

  const alertText = isSuppliesType
    ? `Be sure to double-check your R&D supply expenses!`
    : `Be sure to double-check your cloud computing expenses!`;
  const alertSubtext = isSuppliesType
    ? `Use this table to add new vendors, or click on an inaccurate expense to edit or delete any incorrect data.`
    : `You can add new vendors, or click on an inaccurate expense to edit or delete any incorrect data.`;

  return (
    <>
      <Flex
        padding={[
          0,
          0,
          featureFlags.showRevampedSuppliesAndServices ? 0 : 16,
          0,
        ]}
      >
        {!featureFlags.showRevampedSuppliesAndServices && (
          <Alert
            text={
              <Flex direction='column'>
                <Text text={alertText} variant='medium' />
                <Text text={alertSubtext} size={13} color={Color.neutral._80} />
              </Flex>
            }
            type='caution'
          />
        )}
      </Flex>
      <Card className={classes.mainContent}>
        <ExpandableHeader
          editMode={isEditMode}
          setEditMode={() => setIsEditMode(true)}
          title={title}
          subtitle={configureVendorExpensesSubtitle()}
          fontSize={15}
        />
        <Expandable expand={isEditMode}>
          <>
            <RdVendorsAndExpensesTable
              expenseType={expenseType}
              rdVendorExpenses={vendorExpenses}
              openVendorDrawer={setIsVendorDrawerOpen}
              setSelectedVendor={setSelectedVendor}
              setAttachedReceipts={setAttachedReceipts}
              setIsEditingRow={setIsEditingRow}
              program={program}
            />
            <CardFooter
              primaryCtaLabel='Confirm Expenses'
              primaryOnClick={() => onContinue()}
              primaryCtaDisabled={vendorExpenses.length === 0}
              secondaryCtaLabel='Add more'
              secondaryOnClick={() => {
                setAttachedReceipts([]);
                setIsVendorDrawerOpen(true);
              }}
              secondaryCtaDisabled={vendorExpenses.length === 0}
              variant='secondary'
            />
          </>
        </Expandable>
      </Card>
      <RdSideDrawerVendor
        setIsVendorDrawerOpen={setIsVendorDrawerOpen}
        isVendorDrawerOpen={isVendorDrawerOpen}
        onSubmit={addOrUpdateVendor}
        onDeleteExpense={removeVendor}
        alertError={alertError}
        selectedVendor={selectedVendor}
        setSelectedVendor={setSelectedVendor}
        amountLabel={generateAmountLabel(expenseType, program)}
        attachedReceipts={attachedReceipts}
        setAttachedReceipts={setAttachedReceipts}
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        isEditingRow={isEditingRow}
        setIsEditingRow={setIsEditingRow}
        files={files}
        setFiles={setFiles}
        attachmentsToDelete={attachmentsToDelete}
        setAttachmentsToDelete={setAttachmentsToDelete}
        expenseType={expenseType}
      />
    </>
  );
};

export default RdVendorsAndExpenses;
