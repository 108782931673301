import * as React from 'react';
import { FunctionComponent } from 'react';
import { Color } from 'component-library';
import { CircularProgress, Fade, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles(() => ({
  loading: {
    backgroundColor: Color.neutral.white,
    zIndex: 2,
  },
}));

interface LoadingProps {
  dataTestId?: string;
  loading: boolean;
}

export const Loading: FunctionComponent<LoadingProps> = ({
  dataTestId,
  loading,
  children,
}) => {
  const classes = useStyles();

  return (
    <Box position={'relative'} width={'auto'} data-testid={dataTestId}>
      <Collapse
        collapsedHeight={!loading ? 0 : '160px'} // this is to deal with content larger than the loading indicator
        in={!loading}
      >
        <Fade in={loading}>
          <Box
            textAlign={'center'}
            padding={'64px 0'}
            height={'32px'}
            position={'absolute'}
            width={'100%'}
            className={classes.loading}
          >
            <CircularProgress size={32} />
          </Box>
        </Fade>
        {children}
      </Collapse>
    </Box>
  );
};
