import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  useCommonStores,
  useFeatureFlags,
  useOnboardingStores,
} from 'stores/useStores';
import {
  Button,
  Color,
  Expandable,
  Flex,
  FontAwesome,
  Heading,
  Link,
  Modal,
  Text,
} from 'component-library';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';
import { datadogLogs } from '@datadog/browser-logs';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  '@global': {
    '.calendly-badge-widget': {
      position: 'relative',
      right: 'auto',
      bottom: 'auto',

      '& .calendly-badge-content': {
        borderRadius: '4px',
        backgroundColor: `${Color.blue._60} !important`,
        border: 'none',
        color: 'white !important',
        fontWeight: 500,
        boxShadow: 'none',
        transition: 'box-shadow .2s',
        padding: '12px 16px',
        fontSize: '16px',

        '&:hover': {
          boxShadow: 'rgba(0,0,0,0.25) 0 2px 5px',
        },
      },
    },
  },
  expertModalOptions: {
    backgroundColor: Color.neutral._10,
    borderRadius: '4px',
  },
  icon: {
    lineHeight: '24px',
    marginRight: '16px',
  },
}));

interface TalkToAnExpertModalProps {
  showCalendly: boolean;
  setShowCalendly: (bool: boolean) => void;
}

export const TalkToAnExpertModal: React.FC<TalkToAnExpertModalProps> = observer(
  ({ showCalendly, setShowCalendly }) => {
    const classes = useStyles();
    const location = useLocation();
    const { initialAccountSetup } = useOnboardingStores();
    const { app, companyStore } = useCommonStores();
    const { id, adminEmail, adminName } = companyStore.currentCompany;
    const featureFlags = useFeatureFlags();

    useCalendlyEventListener({
      onEventScheduled(e) {
        datadogLogs.logger.info(
          `${
            id !== 0
              ? `Company : ${id} - ${adminName.first} ${adminName.last} : ${adminEmail}`
              : `[PRE-SIGN_UP]`
          } scheduled a Zoom call on ${moment(new Date()).format(
            'MM/DD/YYYY - h:mm a z',
          )} from ${
            location.pathname
          } - Calendly event payload : ${JSON.stringify(e.data.payload)}`,
        );
      },
    });

    return (
      <Modal
        showModal={initialAccountSetup.showTalkToExpertModal}
        closeToggle={() => initialAccountSetup.setShowTalkToExpertModal(false)}
        maxWidth={1024}
        mobilePositionTop
      >
        <Flex direction='column' padding={24} gap={24}>
          <Flex.Cell>
            <Heading variant='medium' size={23} marginBottom={8}>
              Talk to an expert
            </Heading>
            <Text>
              Let’s talk tax savings! Enjoy a free estimate, book a demo, or get
              expert answers at no cost to you.
            </Text>
          </Flex.Cell>
          <Flex direction={app.isMobile ? 'column' : 'row'}>
            <Flex direction='column' gap={app.isMobile ? 0 : 24}>
              <Flex
                className={classes.expertModalOptions}
                padding={app.isMobile ? 24 : [24, 24, 32, 24]}
                direction='column'
                gap={16}
              >
                <Flex>
                  <Flex.Cell basis={40}>
                    <FontAwesome
                      variant='regular'
                      name='video'
                      size={24}
                      className={classes.icon}
                    />
                  </Flex.Cell>

                  <Flex direction='column' gap={12}>
                    <Heading size={18} variant='medium' marginBottom={0}>
                      Schedule a Zoom call
                    </Heading>
                    <Text>
                      Schedule a call with our experts to help answer
                      any&nbsp;questions.
                    </Text>
                  </Flex>
                </Flex>
                {app.isMobile && (
                  <Flex direction='column'>
                    <Button
                      onClick={() => setShowCalendly(!showCalendly)}
                      label={!showCalendly ? 'Schedule a Zoom call' : 'Close'}
                    />
                    <Expandable expand={showCalendly}>
                      <Flex className={classes.expertModalOptions}>
                        <Flex direction='column' gap={12}>
                          <InlineWidget
                            url='https://calendly.com/d/cp2k-2cm-mt7/talk-with-a-mainstreet-expert'
                            pageSettings={{
                              hideEventTypeDetails: true,
                              hideGdprBanner: true,
                              hideLandingPageDetails: true,
                            }}
                            styles={{ height: '500px', paddingBottom: '120px' }}
                          />
                        </Flex>
                      </Flex>
                    </Expandable>
                  </Flex>
                )}
              </Flex>
              <Flex padding={app.isMobile ? [24, 0, 0, 0] : [0, 24, 24, 0]}>
                <Flex className={classes.expertModalOptions} padding={24}>
                  {featureFlags.showCallUs ? (
                    <>
                      <Flex.Cell basis={40}>
                        <FontAwesome
                          variant='regular'
                          name='phone'
                          size={24}
                          className={classes.icon}
                        />
                      </Flex.Cell>
                      <Flex direction='column' gap={12}>
                        <Heading size={18} variant='medium' marginBottom={0}>
                          Call us
                        </Heading>
                        <Text>
                          You can also choose to give us a phone call using the
                          number&nbsp;below.
                        </Text>
                        <Flex.Cell>
                          <Text size={13} color={Color.neutral._60}>
                            Monday - Friday
                          </Text>
                          <Text size={13} color={Color.neutral._60}>
                            6:00AM - 6:00PM PT (Pacific time)
                          </Text>
                        </Flex.Cell>
                        <Text variant='medium' color={Color.neutral._80}>
                          <Link href='tel:1-833-394-2428'>1-833-394-2428</Link>
                        </Text>
                      </Flex>
                    </>
                  ) : (
                    <>
                      <Flex.Cell basis={40}>
                        <FontAwesome
                          variant='regular'
                          name='envelope'
                          size={24}
                          className={classes.icon}
                        />
                      </Flex.Cell>
                      <Flex direction='column' gap={12}>
                        <Heading size={18} variant='medium' marginBottom={0}>
                          Email us
                        </Heading>
                        <Text>
                          You can also contact us at{' '}
                          <a href='mailto:support@mainstreet.com'>
                            support@mainstreet.com
                          </a>
                          .
                        </Text>
                      </Flex>
                    </>
                  )}
                </Flex>
              </Flex>
            </Flex>

            {!app.isMobile && (
              <Flex className={classes.expertModalOptions} padding={24}>
                <Flex direction='column' gap={12}>
                  <InlineWidget
                    url='https://calendly.com/d/42w-6p5-szz/introduction-to-mainstreet'
                    pageSettings={{
                      hideEventTypeDetails: true,
                      hideGdprBanner: true,
                      hideLandingPageDetails: true,
                    }}
                    styles={{ height: '500px' }}
                  />
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Modal>
    );
  },
);
