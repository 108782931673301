export interface CreditsOption {
  checked: boolean;
  disabled?: boolean;
  text: string;
  value: string | number;
  info: string;
  estimateCents: number;
  autoEnrolled?: boolean;
}

export enum CreditsName {
  RD = 'R&D Tax Credit',
  DISABLED_ACCESS = 'Disabled Access Credit',
  HEALTHCARE = 'Healthcare Credit',
  RETIREMENT_PLAN = 'Retirement Plan Credit',
  STUDENT_LOAN = 'Student Loan Savings',
  WORK_FROM_HOME = 'Work from Home Savings',
}

export const Credits = [
  {
    text: CreditsName.RD,
    icon: '/images/logo/credits/rd-tax-credit.svg',
    checked: true,
    value: 'rd-tax-credit',
    info: "We've estimated how much your innovation efforts could translate into tax savings.",
    estimateCents: 12500000,
  },
  {
    text: CreditsName.HEALTHCARE,
    icon: '/images/logo/credits/healthcare.svg',
    checked: true,
    value: 'healthcare-credit',
    info: "We've evaluated how much your healthcare benefits could save in taxes.",
    estimateCents: 1020000,
  },
  {
    text: CreditsName.RETIREMENT_PLAN,
    icon: '/images/logo/credits/retirement.svg',
    checked: true,
    value: 'retirement-plan-credit',
    info: "Determined your eligibility, we've assessed your credit estimates.",
    estimateCents: 2500000,
  },
  {
    text: CreditsName.WORK_FROM_HOME,
    icon: '/images/logo/credits/work-from-home.svg',
    checked: true,
    value: 'work-from-home-credit',
    info: 'Eligible expenses incurred by employees for remote work setups.',
    estimateCents: 0,
  },
  {
    text: CreditsName.DISABLED_ACCESS,
    icon: '/images/logo/credits/disabled-access.svg',
    checked: true,
    value: 'disabled-access-credit',
    info: "We've calculated potential savings for enhancing your business's accessibility.",
    autoEnrolled: true,
    estimateCents: 500000,
  },
  {
    text: CreditsName.STUDENT_LOAN,
    icon: '/images/logo/credits/student-loan.svg',
    checked: true,
    value: 'student-loan-credit',
    info: 'Estimated total amount of eligible student loan payments made by your employees.',
    autoEnrolled: true,
    estimateCents: 0,
  },
];

export enum Page {
  root = '/',
  authenticate = 'authenticate',
  signin = 'signin',
  discover = 'discover',
  connections = 'connections',
  forms = 'forms',
  employees = 'employees',
  settings = 'settings',
}

export const Nav = [
  {
    title: 'Overview',
    url: '/',
    icon: 'house-blank',
  },
  {
    title: 'Connections',
    url: `/${Page.connections}`,
    icon: 'link',
  },
  {
    title: 'Forms',
    url: `/${Page.forms}`,
    icon: 'folders',
  },
  {
    title: 'Employees',
    url: `/${Page.employees}`,
    icon: 'user-group',
  },
  {
    title: 'Settings',
    url: `/${Page.settings}`,
    icon: 'sliders',
  },
];

export const CentsToDisplayString = (
  amountCents: number,
  min = 0,
  max = 2,
): string => {
  const dollars = amountCents / 100;
  return dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
};

export const CalculateRetirementEstimate = (employeeCount: number) => {
  const part1 = Math.min((employeeCount - 1) * 250, 500000);
  const fixedAmount = 50000;
  const part2 = (employeeCount - 1) * 100000;

  const totalRetirementEstimate = part1 + fixedAmount + part2;

  return totalRetirementEstimate;
};

export const CurrencyStringToCents = (dollars: string): number => {
  const dollarFloat = parseFloat(dollars.replace(/,/g, '').replace('$', ''));
  if (isNaN(dollarFloat)) {
    return NaN;
  }
  return Math.floor(dollarFloat * 100);
};
