import React, { useState } from 'react';
import { InvitationForm } from './InvitationForm';
import { Button, SideDrawer } from 'component-library';
import { ServerClient } from 'services/ServerClient';
import { IsEmailValid } from 'lib/helpers';
import { CompanyData } from 'lib/interfaces';

interface SendInvitationDrawerProps {
  setOpenSidebar: (value: boolean) => void;
  openSidebar: boolean;
  client: ServerClient;
  company: CompanyData;
  setIsInvitationSuccessful: (value: boolean) => void;
  setIsInvitationError: (value: boolean) => void;
  setInviteeName: (value: string) => void;
}

const SendInvitationDrawer = ({
  setOpenSidebar,
  openSidebar,
  client,
  company,
  setIsInvitationSuccessful,
  setIsInvitationError,
  setInviteeName,
}: SendInvitationDrawerProps) => {
  const [showEmailError, setShowEmailError] = useState(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [inviteeEmail, setInviteeEmail] = useState<string>('');

  const sendInvite = () => {
    // TODO
    if (IsEmailValid(inviteeEmail)) {
      const inviterName =
        company.adminName.first + ' ' + company.adminName.last;
      client
        .SendInvitation(inviteeEmail, firstName, lastName, inviterName)
        .then((res) => {
          if (res.errorMsg) {
            setIsInvitationError(true);
          } else {
            setInviteeName(firstName + ' ' + lastName);
            setIsInvitationSuccessful(true);
            // reset form
            setFirstName('');
            setLastName('');
            setInviteeEmail('');
          }
        });
      setOpenSidebar(false);
    } else {
      setShowEmailError(true);
    }
  };

  const alertMessage =
    'For now, every user you invite will have the same permissions as you and will be able to access the same sections and information as you.';

  return (
    <SideDrawer
      closeToggle={() => {
        setOpenSidebar(false);
      }}
      alertMessage={alertMessage}
      drawerActions={
        <Button
          label='Invite'
          dataTestId='sidebar-invite-button'
          onClick={() => sendInvite()}
        />
      }
      title='Invite user'
      show={openSidebar}
      drawerContent={
        <InvitationForm
          firstName={firstName}
          lastName={lastName}
          email={inviteeEmail}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setEmail={setInviteeEmail}
          showEmailError={showEmailError}
        />
      }
    />
  );
};

export default SendInvitationDrawer;
