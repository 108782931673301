import React, { useState } from 'react';
import { Grid, makeStyles, TextField, Button } from '@material-ui/core';
import { RdActivityEnum, RdActivityEnumToString } from 'lib/constants';
import { Autocomplete } from '@material-ui/lab';
import { ClassNameMap } from '@material-ui/styles/withStyles';

const useStyles = makeStyles(() => {
  const button: ClassNameMap = {
    textTransform: 'capitalize',
  };

  return {
    addActivityLink: {
      paddingTop: '20px',
      cursor: 'pointer',
    },
    add: {
      ...button,
    },
    cancel: {
      ...button,
      fontWeight: 'normal',
    },
  };
});

interface Props {
  onAddClick: (activityToAdd: RdActivityEnum) => void;
  onCancelClick: () => void;
  existingActivities: RdActivityEnum[];
}

export const AddActivity = ({
  onAddClick,
  onCancelClick,
  existingActivities,
}: Props) => {
  const [activityToAdd, setActivityToAdd] = useState<RdActivityEnum | null>(
    null,
  );
  const classes = useStyles();

  const addActivity = () => {
    if (activityToAdd) {
      onAddClick(activityToAdd);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          id='activity_select'
          options={Object.values(RdActivityEnum).filter(
            (activity) => !existingActivities.includes(activity),
          )}
          getOptionLabel={(option: RdActivityEnum) =>
            RdActivityEnumToString[option]
          }
          getOptionSelected={(option: RdActivityEnum, value: RdActivityEnum) =>
            option === value
          }
          value={activityToAdd}
          onChange={(event: any, value: any | null) => {
            setActivityToAdd(value);
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              label='Activity description'
              variant='outlined'
            />
          )}
        />
      </Grid>
      <Grid item>
        <Button
          variant='outlined'
          color='primary'
          onClick={addActivity}
          disabled={!activityToAdd}
          className={classes.add}
        >
          Add
        </Button>
      </Grid>
      <Grid item>
        <Button
          color='primary'
          onClick={onCancelClick}
          className={classes.cancel}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};
