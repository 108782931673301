import React, { useEffect } from 'react';
import {
  Animate,
  AnswerType,
  AnswerValueType,
  Flex,
  Spinner,
  SurveyQuestion,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import {
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationModalStatusEnum,
  QualificationStatusEnum,
  SurveyNameEnum,
} from 'lib/constants';
import { CmsQuestionData } from 'lib/interfaces';
import { useCommonStores, useTaxCreditsStores } from 'stores/useStores';
import _ from 'lodash';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import TitleHeader from 'components/TitleHeader';
import { QualificationStatusModal } from '../retirement-plan/components';
import { useEffectOnce } from 'component-library/_helpers/use-utils';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    maxWidth: '1200px',
    margin: '0 auto',
    position: 'relative',
  },
  container: {
    maxWidth: '80%',
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}));

interface DisabledAccessProps {
  taxYear: number;
}
export const DisabledAccess: React.FC<DisabledAccessProps> = observer(
  ({ taxYear }) => {
    const classes = useStyles();
    const { app, chatbot } = useCommonStores();
    const { surveyFlow } = useTaxCreditsStores();

    const handleOnContinue = async () => {
      if (
        surveyFlow.qualificationStatus &&
        surveyFlow.qualificationStatus ===
          QualificationModalStatusEnum.DISABLED_ACCESS_DQ
      ) {
        surveyFlow.setShowQualificationStatusModal(true);
      } else {
        surveyFlow.setSurveyContinueLoading(true);

        // get program estimate
        await surveyFlow.getProgramEstimates(
          taxYear,
          ProgramNameEnum.FED_DISABLED_ACCESS,
        );

        // update program stage
        await surveyFlow
          .updateProgramStageStatus(
            ProgramNameEnum.FED_DISABLED_ACCESS,
            taxYear,
            ProgramStageEnum.MS_REVIEW,
            QualificationStatusEnum.QUALIFIED,
            ProgramSubStageEnum.REVIEW_IN_PROGRESS,
          )
          .then(() => {
            app.history.push('/');
            surveyFlow.setSurveyContinueLoading(false);
            // clear qualification statuses
            surveyFlow.qualificationStatus = null;
          });
      }
    };

    const {
      isSurveyComplete,
      isLoading,
      surveyAnswers,
      questionsToRender,
      addSurveyAnswers,
      saveAnswers,
    } = useSurveyQuestions(
      SurveyNameEnum.DISABLED_ACCESS_CREDIT_SURVEY,
      taxYear,
    );

    const handleAnswerChange = (
      question: CmsQuestionData,
      answerValue: AnswerValueType,
      key: string,
    ) => {
      question.answerValue = answerValue;
      addSurveyAnswers(question.id, key, [
        { questionId: question.id, answerValue: answerValue },
      ]);
      // save answers per question answered
      if (question.answerType === 'yes_or_no') {
        saveAnswers && saveAnswers();
      }
    };

    const handleOnDisqualified = async () => {
      surveyFlow.setModalContinueIsLoading(true);

      // update program stage
      await surveyFlow
        .updateProgramStageStatus(
          ProgramNameEnum.FED_DISABLED_ACCESS,
          taxYear,
          ProgramStageEnum.DISQUALIFIED,
          QualificationStatusEnum.DISQUALIFIED,
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT,
        )
        .then(() => {
          app.history.push('/');
          surveyFlow.setShowQualificationStatusModal(false);
          surveyFlow.setModalContinueIsLoading(false);
          // clear qualification statuses
          surveyFlow.qualificationStatus = null;
        });
    };

    useEffect(() => {
      // checking survey answers to display qualification modal
      surveyFlow.checkQualificationStatus(surveyAnswers);
    }, [surveyFlow, surveyAnswers]);

    useEffectOnce(
      async () =>
        await surveyFlow.createTaxCreditProgram(
          taxYear,
          ProgramNameEnum.FED_DISABLED_ACCESS,
        ),
    );

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => {
          return (
            <Animate
              key={question.id}
              enter={['fade-in', 'from-bottom']}
              duration={0.5}
            >
              <SurveyQuestion
                dataTestId={question.id}
                answerType={question.answerType as AnswerType}
                text={question.text}
                subtitle={question.subtitle}
                answerOptions={question.answerIDs}
                onChange={(answer) => handleAnswerChange(question, answer, key)}
                onBlur={() => saveAnswers && saveAnswers()}
                placeholder={question.placeholder}
                answerValue={question.answerValue}
                tooltip={question.tooltip}
                questionTextSize={app.isMobile ? 15 : 18}
                questionSubtitleSize={app.isMobile ? 13 : 15}
                withCardMargin={false}
                helpLinkText={question.helperText}
                chatBotQuestion={chatbot.isAiQuestionEnabled(
                  question.aiQuestion,
                )}
                chatBotPrompt={() =>
                  chatbot.autoSendAiMessage(question.aiQuestion)
                }
                onHelpLinkClick='https://mainstreet1.my.site.com/help/s/article/Qualified-Retirement-Plans-for-Start-up-Cost-and-Auto-Enrollment-Tax-Credits'
              />
            </Animate>
          );
        });
      });

    return (
      <Flex
        direction='column'
        className={classes.root}
        data-testid={'disabled-access-credit'}
      >
        <Animate enter={['fade-in', 'from-top']}>
          <TitleHeader
            title='Disabled Access Tax Credit'
            showExitButton
            borderBottom
            onCancelExitText='Cancel'
            showCompanyName
          />
        </Animate>

        <Flex className={classes.mainContent} direction='column'>
          <Animate enter={'fade-in'}>
            <SurveyFlowContainer
              title='Qualify your company for Disabled Access Tax Credit'
              onContinue={async () => handleOnContinue()}
              isDisabled={!isSurveyComplete}
              isLoading={surveyFlow.surveyContinueLoading}
            >
              <Flex className={classes.container} direction='column' gap={24}>
                {isLoading ? (
                  <Spinner size='small' />
                ) : (
                  !_.isEmpty(questionsToRender) && renderQuestions()
                )}
                {surveyFlow.qualificationStatus &&
                  surveyFlow.qualificationStatus ===
                    QualificationModalStatusEnum.DISABLED_ACCESS_DQ && (
                    <QualificationStatusModal
                      showModal={surveyFlow.showQualificationStatusModal}
                      modalOnClick={async () => {
                        await handleOnDisqualified();
                      }}
                      programName={ProgramNameEnum.FED_DISABLED_ACCESS}
                    />
                  )}
              </Flex>
            </SurveyFlowContainer>
          </Animate>
        </Flex>
      </Flex>
    );
  },
);
