import React, { useState } from 'react';
import StepsContainer from '../../component/StepsContainer';
import {
  AutoqualificationStatusEnum,
  GraphCmsQuestionIdEnum,
} from 'lib/constants';
import { SurveyNameEnum, Page } from 'lib/constants';
import { SurveyAnswer, useSurveyQuestions } from 'lib/useSurveyQuestions';
import { Loading } from 'components/util/Loading';
import ExpandableSurveyQuestion from 'components/ExpandableSurveyQuestion';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Button, Heading, Text, Flex } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useCompany, useLegacyClients } from '../../../../stores/useStores';
import { useFeatureFlags, useCommonStores } from 'stores/useStores';
import { setDocumentPageTitle, useEffectOnce } from 'lib/helpers';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';

const useStyles = makeStyles(() => ({
  question: {
    marginTop: '16px',
  },
  continueButton: {
    marginTop: '48px',
  },
}));

interface RDFourPartTestProps {
  pathFrom: string;
  onBack: () => void;
  programTaxYear: number;
}

export const RDFourPartTest = observer(
  ({ pathFrom, onBack, programTaxYear }: RDFourPartTestProps) => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const { client } = useLegacyClients();
    const [error, setError] = useState<string>('');
    const { app } = useCommonStores();
    const history = useHistory();
    const { store: companyStore } = useCompany();
    const classes = useStyles();
    const [expandedQuestionIds, setExpandedQuestionIds] = useState<Set<string>>(
      new Set(),
    );
    const featureFlags = useFeatureFlags();
    const showQualDqExperience = featureFlags.showQualificationDqExperience;

    const {
      isLoading,
      questionsToRender,
      surveyAnswers,
      isSurveyComplete,
      addSurveyAnswers,
      saveAnswers,
    } = useSurveyQuestions(
      SurveyNameEnum.RD_CREDIT_AUTOQUAL_FOUR_PART_TEST,
      programTaxYear,
    );

    const onBackStep = () => {
      onBack();
      history.push(pathFrom);
    };

    const handleQuestionExpansion = (
      questionId: string,
      expanded: boolean,
    ): void => {
      if (expandedQuestionIds.has(questionId) && !expanded) {
        setExpandedQuestionIds((prev) => {
          const newState = new Set(prev);
          newState.delete(questionId);
          return newState;
        });
      } else if (!expandedQuestionIds.has(questionId) && expanded) {
        setExpandedQuestionIds((prev) => new Set(prev.add(questionId)));
      }
    };

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question, i) => {
          const questionCategory = question.tooltip;
          const howIRSPutsIt = question.placeholder;
          const simplified = question.helperText;

          return (
            <div key={question.id} className={classes.question}>
              <Heading
                tag={app.isMobile ? 'h4' : 'h3'}
                variant='medium'
                text={questionCategory}
              />
              {expandedQuestionIds.has(question.id) && (
                <>
                  <Text paddingBottom={8}>
                    <>
                      <Text
                        tag='span'
                        text='How the IRS puts it: '
                        variant='medium'
                      />
                      <Text tag='span' text={howIRSPutsIt} />
                    </>
                  </Text>
                  <Text paddingBottom={8}>
                    <>
                      <Text tag='span' text='Simplified: ' variant='medium' />
                      <Text tag='span' text={simplified} />
                    </>
                  </Text>
                </>
              )}
              <ExpandableSurveyQuestion
                questionProps={question}
                onQuestionPropsChange={(answers: SurveyAnswer[]) => {
                  question.answerValue = answers.find(
                    (q) => q.questionId === question.id,
                  )?.answerValue;
                  addSurveyAnswers(question.id, key, answers);
                }}
                onEditModeToggle={handleQuestionExpansion}
                dataTestId={`question-${question.id}`}
              />
            </div>
          );
        });
      });

    const determinePathTo = async () => {
      const lastQuestionId =
        GraphCmsQuestionIdEnum.WHAT_KINDS_OF_TECHNOLOGICAL_PROJECTS;
      const lastAnswerId: string[] = surveyAnswers[lastQuestionId];
      const lastQuestionAnswer: any = { [lastQuestionId]: lastAnswerId };
      const res = await client.UpdateIrsTestPartFour({
        ...lastQuestionAnswer,
        programTaxYear,
      });

      if (res.errorMsg) {
        datadogLogs.logger.error(
          '[QUALIFICATION]: updating four part test responses failed',
          logContext({
            error: res.errorMsg,
            company: app.company,
          }),
        );
        setError(res.errorMsg);
      } else {
        if (res.data?.companyData) {
          await companyStore.currentCompany.mergeData(res.data!.companyData);
        }
        const autoqualificationStatus = res.data?.autoqualificationStatus;
        if (
          autoqualificationStatus === AutoqualificationStatusEnum.DISQUALIFIED
        ) {
          if (showQualDqExperience) {
            app.history.push(
              `/${Page.taxCredits}/${Page.disqualified}/${programTaxYear}`,
            );
          } else {
            app.history.push(`/`);
          }
        } else {
          app.history.push(`/${Page.taxCreditEstimate}/${programTaxYear}`);
        }
      }
    };

    const onContinue = async () => {
      setButtonLoading(true);
      try {
        await saveAnswers();
        setError('');
        await determinePathTo();
        setButtonLoading(false);
      } catch {
        setError('Something went wrong.');
      }
    };

    useEffectOnce(() => setDocumentPageTitle('Qualification - 4-Part Test'));

    return (
      <StepsContainer
        onBackStep={onBackStep}
        showContinueButton={false}
        onContinueLabel='Continue'
        isLoading={buttonLoading}
        stepTitle='R&D Four part test'
        subtitle="To qualify for R&D tax credits, companies must pass the IRS's qualifying 4-part test. This section allows us to quickly confirm whether your business activities qualify."
        errorMsg={error}
      >
        <Loading loading={isLoading}>
          {!_.isEmpty(questionsToRender) && renderQuestions()}
          {isSurveyComplete && (
            <Flex justifyContent='flex-end'>
              <Button
                className={classes.continueButton}
                dataTestId='continue-button'
                label={'Continue'}
                onClick={onContinue}
                loading={buttonLoading}
                variant={'contained'}
              />
            </Flex>
          )}
        </Loading>
      </StepsContainer>
    );
  },
);
