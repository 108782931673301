import React from 'react';
import { ContentContainer } from '../component/ContentContainer';
import { observer } from 'mobx-react';
import { useAccountCreationStores, useHistoryStore } from 'stores/useStores';
import { Content, Text, TextField } from 'component-library';
import { useEffectOnce } from 'lib/helpers';
import {
  AccountCreationRequest,
  AccountCreationSteps,
  SurveyQuestionInterface,
  SurveyAnswerTypeEnum,
} from 'lib/interfaces';
import { CompanyDataEnum } from 'lib/constants';

interface BusinessStepProps {
  pathTo: string;
  onNext: () => void;
}

export const BusinessStep = observer(
  ({ pathTo, onNext }: BusinessStepProps) => {
    const { accountCreation } = useAccountCreationStores();
    const history = useHistoryStore();

    const isNextButtonDisabled = accountCreation.enableNextButton(
      accountCreation.BusinessStepQuestions,
    );

    const onContinue = async () => {
      const { legalName, name, yearFounded, website } = getStepAnswers(
        accountCreation.BusinessStepQuestions,
      );

      await accountCreation.updateAccount(
        {
          legalName: legalName as string,
          name: name as string,
          yearFounded: yearFounded as number,
          website: website as string,
        },
        AccountCreationSteps.BUSINESS,
      );
      if (!accountCreation.errorMsg) {
        history.push(pathTo);
        onNext();
      }
    };

    useEffectOnce(() => accountCreation.setBusinessStepQuestions());

    return (
      <ContentContainer
        onNext={onContinue}
        isNextButtonDisabled={isNextButtonDisabled}
        isNextLoading={accountCreation.isNextButtonLoading}
      >
        {accountCreation.BusinessStepQuestions.map((item, index) => {
          return (
            <Content key={item.id} paddingLeftRight={0} paddingTopBottom={0}>
              <Text variant='medium' paddingBottom={8}>
                {item.question}
              </Text>
              <TextField
                name={`account_creation_${item.id}`}
                dataTestId={`account_creation_${item.id}`}
                placeholder={item.placeholder}
                value={item.answer ?? ''}
                autoFocus={index === 0}
                yearFormat={item.answerType === SurveyAnswerTypeEnum.YEAR}
                onChange={(event) => {
                  const value = event.target.value;
                  if (item.answerType === SurveyAnswerTypeEnum.YEAR) {
                    accountCreation.setInputChange(
                      accountCreation.BusinessStepQuestions,
                      item.question,
                      !value ? '' : Number(value),
                    );
                  } else {
                    accountCreation.setInputChange(
                      accountCreation.BusinessStepQuestions,
                      item.question,
                      value,
                    );
                  }
                }}
                error={
                  item.answerType === SurveyAnswerTypeEnum.YEAR &&
                  accountCreation.errorMsg.length > 0
                }
                helperText={
                  item.answerType === SurveyAnswerTypeEnum.YEAR &&
                  accountCreation.errorMsg &&
                  'Please enter a valid founded year.'
                }
              />
            </Content>
          );
        })}
      </ContentContainer>
    );
  },
);

const getStepAnswers = (
  stepQuestions: SurveyQuestionInterface[],
): AccountCreationRequest => {
  const legalName = stepQuestions.find(
    (item) => item.id === CompanyDataEnum.LEGAL_NAME,
  )?.answer as string;
  const name = stepQuestions.find((item) => item.id === CompanyDataEnum.NAME)
    ?.answer as string;
  const yearFounded = stepQuestions.find(
    (item) => item.id === CompanyDataEnum.YEAR_FOUNDED,
  )?.answer as number;
  const website = stepQuestions.find(
    (item) => item.id === CompanyDataEnum.WEBSITE,
  )?.answer as string;

  return {
    legalName,
    name,
    yearFounded,
    website,
  };
};
