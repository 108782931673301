import { ClaimBanner } from '../../unified-tax-credits/components';
import { Card, Flex, Link, Text } from 'component-library';
import { Page } from '../../../../../lib/constants';
import React from 'react';
import { Company } from '../../../../../entities/Company';

interface ErcComingSoonProps {
  classes: any;
  company: Company;
}

export const ErcComingSoon = ({ classes, company }: ErcComingSoonProps) => {
  return (
    <>
      <ClaimBanner
        title={'ERC: Coming soon!'}
        subtitle={
          <>
            Based on what we know about {company.legalName}, we believe
            you&apos;re eligible for Employee Retention Credits. Keep an eye on
            your email inbox for next steps to&nbsp;claim.
          </>
        }
      />
      <Card noMargin>
        <Flex direction='column' gap={16} padding={24}>
          <Flex justifyContent='space-between' alignItems='center' wrap='wrap'>
            <Flex
              gap={4}
              alignItems='center'
              justifyContent='flex-start'
              className={classes.title}
              basis={315}
            >
              <Text variant='medium' size={23}>
                Prepare to claim credits
              </Text>
            </Flex>
          </Flex>
          <Text paddingBottom={16}>
            We&apos;re excited to help you claim your Employee Retention
            credits. While we work diligently to ensure your credits are
            compliant with all relevant regulations, here&apos;s what you can do
            to get started&nbsp;today!
          </Text>

          <div className={classes.assessmentContainer}>
            <Flex direction='column' gap={8} padding={24}>
              <Text variant='medium' size={18}>
                Verify payroll&nbsp;connection
              </Text>
              <Text>
                Visit the <Link href={`/${Page.connections}`}>Connections</Link>{' '}
                page to connect to all payroll accounts you used during 2020 and
                2021. This can save you 30+ minutes when it&apos;s time
                to&nbsp;claim!
              </Text>
            </Flex>
            <Flex direction='column' gap={8} padding={24}>
              <Text variant='medium' size={18}>
                Invite your accountant to&nbsp;MainStreet
              </Text>
              <Text>
                Get a head start on filing your final tax credits. Invite your
                tax preparer from the{' '}
                <Link href={`/${Page.settings}`}>Settings</Link> page, so they
                can interact with us on your&nbsp;behalf.
              </Text>
            </Flex>
            <Flex direction='column' gap={8} padding={24}>
              <Text variant='medium' size={18}>
                Add us as an email&nbsp;contact
              </Text>
              <Text>
                The Employee Retention credit is only available for a limited
                time. To make sure you don&apos;t miss out, add{' '}
                <Link href='mailto:support@main.mainstreet.com'>
                  support@mail.mainstreet.com
                </Link>{' '}
                as&nbsp;a&nbsp;contact.
              </Text>
            </Flex>
          </div>
        </Flex>
      </Card>
    </>
  );
};
