import { useContext, useState } from 'react';
import { useFinchConnect } from 'react-finch-connect';
import { StoreFinchServiceToken } from 'services/server';
import { TokenContext } from 'pages/TokenRequired';
import { ImportType } from '../lib/interfaces';
import { datadogLogs } from '@datadog/browser-logs';

const logger = datadogLogs.createLogger('ConnectFinch');

export interface ConnectPayrollDisplayProps {
  openFinch: () => void;
  loading: boolean;
  finchError: string;
}

interface ConnectFinchProps {
  onPayrollConnected: () => Promise<void>;
  connectPayrollDisplay: (props: ConnectPayrollDisplayProps) => JSX.Element;
  payrollSystem?: string;
  importType: ImportType;
  taxYear?: number;
}

export const ConnectFinch = ({
  connectPayrollDisplay,
  onPayrollConnected,
  payrollSystem,
  importType,
  taxYear,
}: ConnectFinchProps) => {
  const tokenContext = useContext(TokenContext);

  const [finchError, setFinchError] = useState('');
  const [finchOpen, setFinchOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onSuccess = ({ code }: { code: string }) => {
    // this is because the finch open() function is a global hook, and is
    // hitting all instances of the component at once
    if (!finchOpen) {
      return;
    }
    setFinchOpen(false);

    const email = tokenContext.email || undefined;
    const token = tokenContext.token || undefined;

    StoreFinchServiceToken(code, importType, token, email, taxYear).then(
      ({ errorMsg }) => {
        if (errorMsg) {
          logger.error(
            `Error storing Finch token - ${
              payrollSystem ? payrollSystem.toLowerCase() : ''
            } : ${errorMsg}`,
          );
          setFinchError(errorMsg);
          return;
        }

        return onPayrollConnected().finally(() => setLoading(false));
      },
    );
  };
  const onError = ({ errorMessage }: { errorMessage: string }) => {
    setFinchError(errorMessage);
    setLoading(false);
    logger.error(
      `Error connecting Finch - ${payrollProvider} : ${errorMessage}`,
    );
  };
  const onClose = () => {
    setLoading(false);
    setFinchOpen(false); // ensure we don't run StoreFinchServiceToken for every 'Reconnect' button that has been pressed on the screen.
  };

  const clientId = process.env.REACT_APP_FINCH_CLIENT_ID;
  const payrollProvider = payrollSystem ? payrollSystem.toLowerCase() : '';
  const mode = 'employer';
  const manual = true;

  const products = [
    'directory',
    'company',
    'individual',
    'employment',
    'payment',
    'pay_statement',
  ];

  const sandbox = process.env.REACT_APP_CONNECTION_NAME !== 'prod';

  // pending some arbitrary deadline / condition i'm not sure about attm
  // products.push('deduction');

  const { open } = useFinchConnect({
    clientId,
    // When using the sandbox you need to select it using the finch selector
    payrollProvider: sandbox ? '' : payrollProvider,
    mode,
    manual,
    products,
    onSuccess,
    onError,
    onClose,
    sandbox,
  });

  const connectWithFinch = () => {
    setLoading(true);
    setFinchOpen(true);
    open();
  };

  return connectPayrollDisplay({
    loading: loading,
    openFinch: connectWithFinch,
    finchError: finchError,
  });
};
