import React, { useState } from 'react';
import {
  Animate,
  Button,
  Card,
  Color,
  Divider,
  Expandable,
  Flex,
  Icon,
  IconEnum,
  Text,
} from 'component-library';
import {
  useCommonStores,
  useTaxCreditsStores,
} from '../../../../../stores/useStores';
import { makeStyles } from '@material-ui/core';
import {
  ProgramNameEnum,
  QualificationModalStatusEnum,
} from '../../../../../lib/constants';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    maxWidth: '720px',
    margin: 'auto',
  },
  mainContent: {
    margin: '0 auto',
    position: 'relative',
  },
  alert: {
    backgroundColor: Color.blue._10,
    border: `0.8px solid ${Color.blue._60}`,
  },
  title: {
    whiteSpace: 'nowrap',
  },
  iconContainer: {
    width: '55px',
    height: '55px',
    flexShrink: 0,
    flexGrow: 0,
    backgroundColor: Color.neutral._10,
    borderRadius: '50%',
  },
  showAssessmentsBtn: {
    width: '25px',
    padding: '0',
  },
  showAssessmentsIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform .2s',
  },
  assessmentsIconClose: {
    transform: 'rotate(-180deg)',
  },
  assessmentContainer: {
    backgroundColor: Color.neutral._10,
    border: `1px solid ${Color.neutral._20}`,
    borderRadius: '4px',
    margin: '1rem 0',
  },
  sectionStart: {
    marginTop: '1rem',
  },
  requiredChip: {
    marginLeft: '1rem',
  },
  downloadExtensionButton: {
    width: '250px',
    padding: '0',
  },
  ul: {
    margin: '8px 0 0',
  },
}));

export const BoirNotNow = observer(() => {
  const classes = useStyles();
  const { app, companyStore } = useCommonStores();
  const { surveyFlow } = useTaxCreditsStores();
  const [expandedCards, setExpandedCards] = useState(new Set());

  const hasBoirDQ =
    surveyFlow.qualificationStatus &&
    surveyFlow.qualificationStatus ===
      QualificationModalStatusEnum.BENEFICIAL_OWNERSHIP_DQ;

  let dqMessage;

  if (hasBoirDQ) {
    const boirStatus = surveyFlow.allQualificationStatuses.find(
      (program) =>
        program.programName ===
        ProgramNameEnum.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT,
    );

    if (boirStatus) {
      switch (boirStatus.reason) {
        case 'largeOperatingBusiness':
          dqMessage = `
            ${companyStore.company.name} is considered a large operating company for the purposes of this report, so you
            are exempt from filing!\n\n
            If you someday have fewer than 21 full-time employees, or you stop renting/owning a physical office space,
            you would need to file this report.`;
          break;
        case 'exemptBusiness':
          dqMessage = `
            You indicated that ${companyStore.company.name} meets the criteria to be exempt from filing the Beneficial
            Ownership Information Report. You may need to file in the future if your business stops meeting those criteria.
          `;
          break;
        case 'alreadyFiled':
          dqMessage = `
            You’ve already filed your report, so you’re all set for now!\n\n
            Remember: if any information on your original report changes, you’ll need to file an updated report.
          `;
          break;
        default:
          dqMessage = '';
          break;
      }
    }
  }

  const toggleCard = (cardId: number) => {
    setExpandedCards((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(cardId)) {
        newSet.delete(cardId);
      } else {
        newSet.add(cardId);
      }
      return newSet;
    });
  };

  return (
    <Flex
      direction='column'
      className={classes.root}
      data-testid={'beneficial-ownership-not-now'}
    >
      <Flex className={classes.mainContent} direction='column'>
        <Animate enter={'fade-in'}>
          <Flex
            direction='column'
            padding={[40, 24, 80, 24]}
            gap={24}
            alignItems='center'
            dataTestId={'beneficial-ownership-next-steps'}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/tax-credit-dashboard-module/tasks-complete.svg`}
              alt='Form 941 Icon'
              width={100}
            />
            <Flex direction='column' gap={8} alignItems={'center'}>
              <Text
                text={`You don't need to file this report, but we can help in other ways!`}
                variant='medium'
                textAlign={'center'}
                size={app.isMobile ? 23 : 32}
              />
              <Text textAlign={'center'}>{dqMessage}</Text>
            </Flex>

            <Divider variant='no-bottom-margin' />

            <Flex direction='column' gap={40} padding={[0, 0, 40, 0]}>
              <Flex direction='column' gap={24}>
                <Card noMargin key={`beneficial-ownership-not-now-card-one`}>
                  <Flex direction='column' padding={24}>
                    <Flex direction='row' gap={16}>
                      <Flex alignItems={'center'}>
                        <Text variant={'bold'}>
                          What is the Beneficial Ownership Information Report
                          (BOIR)?
                        </Text>
                      </Flex>
                      <Flex
                        className={classes.showAssessmentsBtn}
                        justifyContent='center'
                        alignItems='center'
                        wrap='wrap'
                      >
                        <Button
                          className={classes.showAssessmentsBtn}
                          variant='tertiary'
                          onClick={() => toggleCard(1)}
                          label={
                            <>
                              <Icon
                                className={`${classes.showAssessmentsIcon} ${
                                  expandedCards.has(1)
                                    ? classes.assessmentsIconClose
                                    : ''
                                }`}
                                name={IconEnum.chevron_down}
                                color={Color.neutral._60}
                              />
                            </>
                          }
                        />
                      </Flex>
                    </Flex>
                    <Expandable expand={expandedCards.has(1)}>
                      <>
                        <Flex
                          direction={'column'}
                          alignItems='start'
                          justifyContent='center'
                          gap={8}
                        >
                          <Text color={Color.neutral._60}>
                            BOIR is a form that most small businesses must file
                            with the FinCEN to inform them of their ownership
                            details. It is a new requirement this year. You
                            don&apos;t need to file this form now, but may need
                            to do so in the future if your business changes.
                          </Text>
                        </Flex>
                      </>
                    </Expandable>
                  </Flex>
                </Card>
                <Card noMargin key={`beneficial-ownership-card-two`}>
                  <Flex direction='column' padding={24}>
                    <Flex direction='row' gap={16}>
                      <Flex alignItems={'center'}>
                        <Text variant={'bold'}>
                          How can MainStreet help you?
                        </Text>
                      </Flex>
                      <Flex
                        className={classes.showAssessmentsBtn}
                        justifyContent='center'
                        alignItems='center'
                        wrap='wrap'
                      >
                        <Button
                          className={classes.showAssessmentsBtn}
                          variant='tertiary'
                          onClick={() => toggleCard(2)}
                          label={
                            <>
                              <Icon
                                className={`${classes.showAssessmentsIcon} ${
                                  expandedCards.has(2)
                                    ? classes.assessmentsIconClose
                                    : ''
                                }`}
                                name={IconEnum.chevron_down}
                                color={Color.neutral._60}
                              />
                            </>
                          }
                        />
                      </Flex>
                    </Flex>
                    <Expandable expand={expandedCards.has(2)}>
                      <>
                        <Flex
                          direction={'column'}
                          alignItems='start'
                          justifyContent='center'
                          gap={8}
                        >
                          <Text color={Color.neutral._60}>
                            MainStreet is always looking for new ways to save
                            more time and money for your business. Let us know
                            what you’d like to see from us here!
                          </Text>
                          <Flex justifyContent='end'>
                            <Button
                              label='Share feedback'
                              onClick={() =>
                                window.open(
                                  'https://mainstreet-work.typeform.com/feedback-2024',
                                  '_blank',
                                )
                              }
                              dataTestId='boir-questions-btn'
                            />
                          </Flex>
                        </Flex>
                      </>
                    </Expandable>
                  </Flex>
                </Card>
                <Card noMargin key={`beneficial-ownership-card-three`}>
                  <Flex direction='column' padding={24}>
                    <Flex direction='row' gap={16}>
                      <Flex alignItems={'center'}>
                        <Text variant={'bold'}>
                          Questions about your 2024 tax credits?
                        </Text>
                      </Flex>
                      <Flex
                        className={classes.showAssessmentsBtn}
                        justifyContent='center'
                        alignItems='center'
                        wrap='wrap'
                      >
                        <Button
                          className={classes.showAssessmentsBtn}
                          variant='tertiary'
                          onClick={() => toggleCard(3)}
                          label={
                            <>
                              <Icon
                                className={`${classes.showAssessmentsIcon} ${
                                  expandedCards.has(3)
                                    ? classes.assessmentsIconClose
                                    : ''
                                }`}
                                name={IconEnum.chevron_down}
                                color={Color.neutral._60}
                              />
                            </>
                          }
                        />
                      </Flex>
                    </Flex>
                    <Expandable expand={expandedCards.has(3)}>
                      <>
                        <Flex
                          direction={'column'}
                          alignItems='start'
                          justifyContent='center'
                          gap={8}
                        >
                          <Text color={Color.neutral._60}>
                            It’s almost time to start your 2024 tax credits!
                            Book a call with one of our tax credits experts to
                            learn how you can prepare today.
                          </Text>
                          <Flex justifyContent='end'>
                            <Button
                              label='Schedule call'
                              onClick={() =>
                                window.open(
                                  'https://calendly.com/d/cp2k-2cm-mt7/talk-with-a-mainstreet-expert?month=2024-11',
                                  '_blank',
                                )
                              }
                              dataTestId='boir-questions-btn'
                            />
                          </Flex>
                        </Flex>
                      </>
                    </Expandable>
                  </Flex>
                </Card>
              </Flex>
              <Flex justifyContent='space-between' gap={40}>
                <Button
                  label='Back'
                  variant='outlined'
                  onClick={() =>
                    app.history.push(
                      '/assessments/beneficial-ownership/business-details',
                    )
                  }
                  dataTestId='boir-review-back-btn'
                />
              </Flex>
            </Flex>
          </Flex>
        </Animate>
      </Flex>
    </Flex>
  );
});
