import { ChatMessage } from 'lib/interfaces';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { runInAction } from 'mobx';
import { BaseStore } from 'stores/BaseStore';
import { RootStore } from '../RootStore';
import { datadogLogs } from '@datadog/browser-logs';
const logger = datadogLogs.createLogger('ChatBotStore.ts');

export const initialMessage = {
  text: `Hello! I’m Friday, MainStreet’s AI chat assistant. How can I help you today?`,
  isBot: true,
  createdAt: new Date(),
};

const errorMessage = `There was an error receiving your message. Please try again. If the problem persists, please contact support@mainstreet.com`;

const chatMessageCharLimit = 500;

export class ChatBotStore extends BaseStore {
  public chatWindowOpen = false;
  public botMessageLoading = false;
  public currentChatMessage = '';
  public chatMessages: ChatMessage[] = [initialMessage];

  public chatBotSettingEnabled: boolean | null = null;
  public chatBotSettingError = '';
  private chatBotSettingLoading = true;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public get hideChatBot() {
    return (
      !this.rootStore.common.companyStore.currentCompany.id ||
      this.chatBotSettingEnabled === false ||
      !this.rootStore.common.featureFlags.flags.showAIChatbot
    );
  }

  public get isChatBotFeatureFlagOn() {
    return this.rootStore.common.featureFlags.flags.showAIChatbot;
  }

  public get isChatBotSettingsFeatureFlagOn() {
    return this.rootStore.common.featureFlags.flags.showAIChatbotSettings;
  }

  public get orderedChatMessages() {
    return this.chatMessages
      .slice()
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
  }

  public get userCanSendMessage() {
    return !(
      this.botMessageLoading || this.currentChatMessage.trim().length === 0
    );
  }

  public get showFirstTimeUserModal() {
    if (
      this.chatBotSettingError ||
      !this.rootStore.common.companyStore.currentCompany?.created
    ) {
      return false;
    }

    return this.chatBotSettingEnabled === null && !this.chatBotSettingLoading;
  }

  public toggleChatWindow() {
    runInAction(() => (this.chatWindowOpen = !this.chatWindowOpen));
  }

  public openChatWindow() {
    runInAction(() => (this.chatWindowOpen = true));
  }

  public setChatBotSetting(chatBotEnabled: boolean) {
    runInAction(() => (this.chatBotSettingEnabled = chatBotEnabled));
  }

  public async getCompanyChatBotSettings() {
    runInAction(() => {
      this.chatBotSettingLoading = true;
    });

    const { data, errorMsg } = await this.client.GetCompanyChatBotSettings();

    runInAction(() => {
      this.chatBotSettingError = errorMsg ?? '';
      this.chatBotSettingLoading = false;
      this.chatBotSettingEnabled = data?.aiChatBotEnabled ?? null;
    });

    if (errorMsg) {
      logger.error(errorMsg);
    }
  }

  public async toggleChatBotSetting(enabled: boolean) {
    if (this.chatBotSettingLoading) {
      return;
    }

    runInAction(() => {
      this.chatBotSettingEnabled = enabled;
      this.chatBotSettingLoading = true;
    });
    const { errorMsg } = await this.client.UpdateChatBotAIEnabled(enabled);

    const error = errorMsg ? errorMsg : '';
    runInAction(() => {
      this.chatBotSettingError = error;
      this.chatBotSettingLoading = false;
    });
  }

  public updateCurrentChatMessage(text: string) {
    if (text.length <= chatMessageCharLimit) {
      runInAction(() => (this.currentChatMessage = text));
    }
  }

  private addMessage(text: string, isBot: boolean) {
    this.chatMessages.push({
      text,
      isBot,
      createdAt: new Date(),
    });
  }

  public isAiQuestionEnabled(aiQuestion: string | undefined) {
    return this.isChatBotFeatureFlagOn && aiQuestion && aiQuestion.length > 0
      ? aiQuestion
      : undefined;
  }

  public autoSendAiMessage(aiQuestion: string | undefined): void {
    const aiQuestionString = this.isAiQuestionEnabled(aiQuestion);
    if (aiQuestionString) {
      this.openChatWindow();
      this.updateCurrentChatMessage(aiQuestionString);
      this.sendMessage();
    }
  }

  public async sendMessage() {
    // Make sure the bot isn't currently loading a response
    if (!this.userCanSendMessage) {
      return;
    }

    runInAction(() => (this.botMessageLoading = true));
    this.addMessage(this.currentChatMessage, false);

    // save chat message in a variable, so we can send it to the API
    const chatMessage = this.currentChatMessage;
    this.updateCurrentChatMessage('');

    const response = await this.client.SendAIChatbotMessage(chatMessage);

    if (response.errorMsg || !response.data) {
      this.addMessage(errorMessage, true);
      logger.error(`Error sending chatBot message: ${response.errorMsg}`);
    } else {
      this.addMessage(response.data.result, true);
    }

    runInAction(() => (this.botMessageLoading = false));
  }
}
