import React from 'react';
import { RouteConfiguration } from 'routes/RouteConfiguration';
import { Page } from 'lib/constants';
import { useFeatureFlags } from 'stores/useStores';
import { EducationalModule } from 'products/dashboard/features/educational-module';
import HttpErrorPage from 'pages/HttpErrorPage';
import { TransitionToDashboard } from 'pages/dashboard/TransitionToDashboard';
import SavingsAgreement from 'pages/dashboard/components/SavingsAgreement';

export const useDashboardRoutes = (): RouteConfiguration[] => {
  const featureFlags = useFeatureFlags();

  return [
    {
      path: `/${Page.educationalOnboarding}`,
      render: () =>
        featureFlags.showEducationalOnboarding ? (
          <EducationalModule />
        ) : (
          <HttpErrorPage errorCode={404} />
        ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
      documentPageTitle: 'Welcome to MainStreet',
    },
    {
      path: `/${Page.transitionToDashboard}`,
      render: () => <TransitionToDashboard />,
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
    },
    {
      path: `/${Page.savingsAgreement}`,
      render: () => <SavingsAgreement />,
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
      documentPageTitle: 'Pay-AS-You-Save',
    },
  ];
};
