import React from 'react';

interface Props {
  className?: string;
  color?: 'gray' | 'green';
}

const MagicHYS = ({ className, color }: Props) => {
  if (color === 'gray') {
    return (
      <svg
        className={className || ''}
        width='34'
        height='20'
        viewBox='0 0 34 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17 4.16667C14.2386 4.16667 12 6.77812 12 10C12 13.2214 14.2386 15.8333 17 15.8333C19.7605 15.8333 22 13.2224 22 10C22 6.77812 19.7615 4.16667 17 4.16667ZM19.0834 12.9167C19.0834 13.1469 18.8969 13.3333 18.6667 13.3333H15.3334C15.1032 13.3333 14.9167 13.1469 14.9167 12.9167V12.0833C14.9167 11.8531 15.1032 11.6667 15.3334 11.6667H16.1667V8.77917L16.1422 8.79531C16.0967 8.82568 16.0457 8.84678 15.992 8.85741C15.9383 8.86804 15.8831 8.86798 15.8295 8.85724C15.7758 8.8465 15.7248 8.82529 15.6794 8.79483C15.6339 8.76436 15.5949 8.72523 15.5646 8.67969L15.1021 7.98646C15.0718 7.94095 15.0507 7.8899 15.04 7.83624C15.0294 7.78257 15.0295 7.72733 15.0402 7.67369C15.0509 7.62004 15.0721 7.56904 15.1026 7.5236C15.1331 7.47815 15.1722 7.43916 15.2177 7.40885L16.0162 6.87656C16.2215 6.73969 16.4627 6.66665 16.7094 6.66667H17.4167C17.6469 6.66667 17.8334 6.85312 17.8334 7.08333V11.6667H18.6667C18.8969 11.6667 19.0834 11.8531 19.0834 12.0833V12.9167ZM32 0H2.00004C1.07973 0 0.333374 0.746354 0.333374 1.66667V18.3333C0.333374 19.2536 1.07973 20 2.00004 20H32C32.9204 20 33.6667 19.2536 33.6667 18.3333V1.66667C33.6667 0.746354 32.9204 0 32 0ZM31.1667 14.1667C29.3256 14.1667 27.8334 15.6589 27.8334 17.5H6.16671C6.16671 15.6589 4.67452 14.1667 2.83337 14.1667V5.83333C4.67452 5.83333 6.16671 4.34115 6.16671 2.5H27.8334C27.8334 4.34115 29.3256 5.83333 31.1667 5.83333V14.1667Z'
          fill='#102736'
        />
      </svg>
    );
  }

  return (
    <svg
      className={className || ''}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 11.3333C13.7909 11.3333 12 13.4225 12 16C12 18.5771 13.7909 20.6667 16 20.6667C18.2084 20.6667 20 18.5779 20 16C20 13.4225 18.2092 11.3333 16 11.3333ZM17.6667 18.3333C17.6667 18.5175 17.5175 18.6667 17.3334 18.6667H14.6667C14.4825 18.6667 14.3334 18.5175 14.3334 18.3333V17.6667C14.3334 17.4825 14.4825 17.3333 14.6667 17.3333H15.3334V15.0233L15.3138 15.0363C15.2774 15.0605 15.2365 15.0774 15.1936 15.0859C15.1507 15.0944 15.1065 15.0944 15.0636 15.0858C15.0206 15.0772 14.9798 15.0602 14.9435 15.0359C14.9071 15.0115 14.8759 14.9802 14.8517 14.9437L14.4817 14.3892C14.4574 14.3528 14.4405 14.3119 14.432 14.269C14.4235 14.2261 14.4236 14.1819 14.4321 14.1389C14.4407 14.096 14.4577 14.0552 14.4821 14.0189C14.5064 13.9825 14.5377 13.9513 14.5742 13.9271L15.2129 13.5013C15.3772 13.3917 15.5701 13.3333 15.7675 13.3333H16.3334C16.5175 13.3333 16.6667 13.4825 16.6667 13.6667V17.3333H17.3334C17.5175 17.3333 17.6667 17.4825 17.6667 17.6667V18.3333ZM28 8H4.00002C3.26377 8 2.66669 8.59708 2.66669 9.33333V22.6667C2.66669 23.4029 3.26377 24 4.00002 24H28C28.7363 24 29.3334 23.4029 29.3334 22.6667V9.33333C29.3334 8.59708 28.7363 8 28 8ZM27.3334 19.3333C25.8604 19.3333 24.6667 20.5271 24.6667 22H7.33335C7.33335 20.5271 6.1396 19.3333 4.66669 19.3333V12.6667C6.1396 12.6667 7.33335 11.4729 7.33335 10H24.6667C24.6667 11.4729 25.8604 12.6667 27.3334 12.6667V19.3333Z'
        fill='#056037'
      />
    </svg>
  );
};

export default MagicHYS;
