import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import {
  Animate,
  Color,
  Content,
  GradientBackground,
  Heading,
} from 'component-library';
import { Page, QualificationStatusEnum, ProgramStageEnum } from 'lib/constants';
import { observer } from 'mobx-react';
import { useCompany, useHistoryStore, useCommonStores } from 'stores/useStores';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.embeddedServiceHelpButton': {
      display: 'none',
    },
  },
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 99,
    top: 0,
    left: 0,
  },
  backgroundCover: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: Color.neutral.white,
  },
  container: {
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    height: `100%`,
    animation: `5s ${theme.transitions.easing.easeInOut} 2s 1 normal forwards running $dashboardTransition`,
  },
  contentContainer: {
    zIndex: 1,
  },
  content: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  '@keyframes dashboardTransition': {
    '0%': {
      opacity: 1,
    },
    '75%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
}));

export const TransitionToDashboard = observer(() => {
  const classes = useStyles();
  const history = useHistoryStore();
  const { company } = useCompany();
  const { app } = useCommonStores();

  const programTaxYear = app.company.programs.find(
    (program) =>
      program.qualificationStatus ===
        QualificationStatusEnum.QUALIFICATION_IN_PROGRESS &&
      program.stage === ProgramStageEnum.QUALIFYING,
  )?.taxYear;

  setTimeout(() => {
    if (app.isMobile) {
      history.push(`/${Page.taxCreditQualificationRoot}/${programTaxYear}`);
    } else {
      history.replace(`/${Page.dashboard}`);
    }
  }, 7000);

  return (
    <>
      <div className={classes.backgroundCover} />
      <Animate enter={'fade-in'} duration={0.25} className={classes.root}>
        <Content
          flex
          justifyContent='center'
          alignItems='center'
          bgColor={Color.neutral.white}
          className={classes.container}
          gap={0}
        >
          <Animate
            enter={'fade-in'}
            exit={'fade-out'}
            duration={1}
            className={classes.contentContainer}
          >
            <Content
              flex
              justifyContent='center'
              alignItems='center'
              className={classes.content}
              paddingLeftRight={24}
              gap={0}
            >
              <Heading tag='h4' variant='medium'>
                <>
                  <Heading
                    tag='span'
                    text='Welcome to MainStreet, '
                    size={app.isMobile ? 24 : 40}
                    color={Color.neutral._90}
                  />
                  <Heading
                    tag='span'
                    text={company.adminName.first}
                    size={app.isMobile ? 24 : 40}
                    color={Color.blue._60}
                  />
                </>
              </Heading>
            </Content>
          </Animate>
          <Animate
            delay={3}
            enter={'fade-in'}
            exit={'fade-out'}
            duration={1}
            className={classes.contentContainer}
          >
            <Heading
              tag={app.isMobile ? 'h4' : 'h3'}
              text={`Let’s explore some savings opportunities for`}
              variant='medium'
              color={Color.neutral._90}
            />
            <Heading
              tag={app.isMobile ? 'h4' : 'h3'}
              text={company.name}
              variant='medium'
              color={Color.blue._60}
            />
          </Animate>
          <GradientBackground
            gradient={Color.gradient._3}
            gradientOpacity={0.5}
          />
        </Content>
      </Animate>
    </>
  );
});
