import { Button, Content, Table, Text } from 'component-library';
import {
  ProgramData,
  RdVendorExpense,
  RdVendorExpenseReceipt,
} from 'lib/interfaces';
import React from 'react';
import { CentsToDisplayStringNoZeros } from '../../../../../lib/helpers';
import { RdVendorExpenseType } from 'lib/constants';

const vendorsColumns = (taxYear: number) => {
  return [
    {
      Header: 'Vendor',
      accessor: 'name',
    },
    {
      Header: `Total spending in ${taxYear}`,
      accessor: 'amountCents',
      Cell: ({ data }: any) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '4px 16px',
            height: '100%',
          }}
        >
          <Text size={15}>{CentsToDisplayStringNoZeros(data.amountCents)}</Text>
        </div>
      ),
    },
  ];
};

export interface VendorsTableProps {
  expenseType: RdVendorExpenseType;
  rdVendorExpenses: RdVendorExpense[];
  openVendorDrawer: (value: boolean) => void;
  setSelectedVendor: (value: RdVendorExpense) => void;
  setAttachedReceipts: (value: RdVendorExpenseReceipt[]) => void;
  setIsEditingRow: (val: boolean) => void;
  program: ProgramData | null;
}

const RdVendorsAndExpensesTable = ({
  expenseType,
  rdVendorExpenses,
  openVendorDrawer,
  setSelectedVendor,
  setAttachedReceipts,
  setIsEditingRow,
  program,
}: VendorsTableProps) => {
  const taxYear = program?.taxYear
    ? program.taxYear
    : new Date().getUTCFullYear();
  return (
    <>
      {!!rdVendorExpenses.length && (
        <Table
          columns={vendorsColumns(taxYear)}
          data={rdVendorExpenses}
          paginationHideSinglePage
          rowOnClick={(value) => {
            setSelectedVendor(value);
            setAttachedReceipts(value.receipts);
            setIsEditingRow(true);
            openVendorDrawer(true);
          }}
        />
      )}
      {!rdVendorExpenses.length && (
        <Content
          flex
          flexDirection='column'
          alignItems='center'
          gap={0}
          paddingTopBottom={32}
        >
          <Text variant='medium' tag='span'>
            There are no vendors or expenses registered yet
          </Text>
          <Text>
            {expenseType === RdVendorExpenseType.CLOUD
              ? `AWS and Azure are the most common vendors`
              : `Prototyping materials and lab supplies vendors are the most common`}
          </Text>
          <Button
            label={'Add vendor and expenses'}
            variant={'tertiary'}
            onClick={() => {
              openVendorDrawer(true);
            }}
          />
        </Content>
      )}
    </>
  );
};

export default RdVendorsAndExpensesTable;
