import { Button, Content, Modal, Text } from 'component-library';
import React from 'react';

interface confirmationModalProps {
  displayType: string;
  closeSideDrawer: () => void;
  modalIsOpen: boolean;
  setModalIsOpen: (isShowing: boolean) => void;
}

const ConfirmationModal = ({
  displayType,
  closeSideDrawer,
  modalIsOpen,
  setModalIsOpen,
}: confirmationModalProps) => {
  return (
    <Modal showModal={modalIsOpen} closeToggle={() => setModalIsOpen(false)}>
      <Content
        paddingTopBottom={24}
        paddingLeftRight={24}
        flex
        flexDirection={'column'}
        gap={8}
      >
        <Text size={18} variant='medium' text='Unsaved changes' />
        <Text
          size={15}
          text={`Any changes you made to this ${displayType} will be lost`}
          paddingBottom={24}
        />
        <Content flex>
          <Button
            small={true}
            label='Back to editing'
            onClick={() => {
              setModalIsOpen(false);
            }}
            dataTestId={'modal-back-edit-button'}
          />
          <Button
            small={true}
            label='Discard Changes'
            onClick={() => {
              setModalIsOpen(false);
              closeSideDrawer();
            }}
            dataTestId={'modal-close-button'}
            variant='outlined'
          />
        </Content>
      </Content>
    </Modal>
  );
};
export default ConfirmationModal;
