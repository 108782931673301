import { Color, Flex, Heading, Text } from 'component-library';
import React from 'react';
import { makeStyles } from '@material-ui/core';

export interface IllustrationCardProps {
  imgSrc: string;
  heading: string;
  subHeading: string;
}

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid',
    borderRadius: '4px',
    borderColor: Color.blue._70,
    backgroundColor: Color.blue._10,
  },
}));

export const IllustrationCard = ({
  imgSrc,
  heading,
  subHeading,
}: IllustrationCardProps) => {
  const classes = useStyles();

  return (
    <Flex alignItems={'center'} className={classes.root} padding={16} gap={8}>
      <Flex.Cell>
        <img src={imgSrc} />
      </Flex.Cell>
      <Flex.Cell>
        <Heading variant='medium' size={18} noMargin text={heading} />
        <Text paddingBottom={8} text={subHeading} />
      </Flex.Cell>
    </Flex>
  );
};
