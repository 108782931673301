import { IsEmailValid } from 'lib/helpers';
import { KycQuestionGraphCMSIdEnum } from 'lib/constants';
import {
  KycGraphCMSQuestionsDataType,
  SurveyResponsesType,
  Address,
  PersonApplicationDataDraft,
  BusinessApplicationDataDraft,
} from 'lib/interfaces';
import { IsValidEinNumber } from '.';

export const GetAddressErrors = (address: Partial<Address>) => {
  const missingFields: string[] = [];
  const { streetLine1, city, state, country, postalCode } = address || {};
  if (!streetLine1) missingFields.push('street');
  // need to check if city and state are valid enum
  if (!city) missingFields.push('city');
  if (!state) missingFields.push('state');
  if (!country) missingFields.push('country');
  if (!postalCode) missingFields.push('postal code');
  if (missingFields.length) {
    return `for address - ${missingFields.join(', ')}`;
  }
};

type GetMissingGraphCMSQuestionsProps = {
  graphCMSAnswers: SurveyResponsesType;
  graphCMSQuestions: KycGraphCMSQuestionsDataType;
  requiresAnswer: KycQuestionGraphCMSIdEnum[];
};

export const getMissingGraphCMSQuestionsErrors = ({
  graphCMSAnswers,
  graphCMSQuestions,
  requiresAnswer,
}: GetMissingGraphCMSQuestionsProps): string[] | void => {
  const missingGraphCmsAnswers: string[] = requiresAnswer
    .filter((questionId) => !graphCMSAnswers[questionId])
    .map((questionId) => graphCMSQuestions[questionId]?.text as string);

  if (missingGraphCmsAnswers.length) {
    return missingGraphCmsAnswers;
  }
};

type GetPersonApplicationDataErrorsProps = {
  personApplicationData: PersonApplicationDataDraft;
  requireSocialSecurity?: boolean;
};

export const getPersonApplicationDataErrors = ({
  personApplicationData,
  requireSocialSecurity,
}: GetPersonApplicationDataErrorsProps) => {
  const missingFields: string[] = [];

  const {
    firstName,
    lastName,
    dateOfBirth,
    phoneNumber,
    ownershipPercentage,
    physicalAddress,
    emailAddress,
    tin,
  } = personApplicationData;

  if (!firstName) missingFields.push('first name');
  if (!lastName) missingFields.push('last name');
  if (!dateOfBirth) missingFields.push('date of birth');
  if (!phoneNumber || phoneNumber?.includes('_')) {
    missingFields.push('phone number');
  }
  if (!IsEmailValid(emailAddress || '')) {
    missingFields.push('valid email address');
  }
  if (requireSocialSecurity && (tin?.includes('_') || !tin)) {
    missingFields.push('social security');
  }

  const addressError = GetAddressErrors({ ...physicalAddress });
  if (addressError) missingFields.push(addressError);
  if (!ownershipPercentage) {
    missingFields.push('ownership percentage');
  }
  return missingFields;
};

type GetBusinessApplicationDataErrorsProps = {
  accreditationConnected: boolean;
  businessApplicationData: BusinessApplicationDataDraft;
};

export const getBusinessApplicationDataErrors = ({
  accreditationConnected,
  businessApplicationData,
}: GetBusinessApplicationDataErrorsProps) => {
  const missingFields: string[] = [];

  const { name, phoneNumber, tin, physicalAddress } = businessApplicationData;

  if (!name) missingFields.push('company legal name');
  if (!IsValidEinNumber(tin)) {
    missingFields.push('ein number');
  }
  if (!phoneNumber || phoneNumber?.includes('_')) {
    missingFields.push('valid phone number');
  }
  const addressError = GetAddressErrors({ ...physicalAddress });
  if (addressError) missingFields.push(addressError);
  if (!accreditationConnected) {
    missingFields.push('investment accreditation verification');
  }

  return missingFields;
};
