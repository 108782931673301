import { observer } from 'mobx-react';
import { Button, Color, Flex, Heading, Modal, Text } from 'component-library';
import React, { useEffect, useState } from 'react';
import {
  useAccountCreationStores,
  useCommonStores,
  useCompany,
  useDashboardStores,
} from '../../../../../stores/useStores';
import { makeStyles } from '@material-ui/core';
import {
  ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
  Page,
} from '../../../../../lib/constants';
import { EmptyClient } from '../../../../../services/ServerClient';
import { reaction } from 'mobx';

const useStyles = makeStyles(() => ({
  modalContainer: {
    zIndex: 99999,
  },
  imageCard: {
    width: 256,
    backgroundColor: Color.blue._20,
    justifyContent: 'center' /* Center horizontally */,
    alignItems: 'center' /* Center vertically */,
  },
  contentCard: {
    width: 460,
    padding: '56px 24px 40px 24px',
    gap: '16px',
  },
  customList: {
    listStyleType: 'none', // Remove default list bullets
    padding: 0, // Remove default padding
    margin: 0, // Remove default margin
    marginBottom: '10px', // Adjust for space between items

    '& li': {
      background: `url(${process.env.PUBLIC_URL}/images/guarantee-icon.png) no-repeat left top`,
      paddingLeft: '30px', // Adjust based on the size of your bullet image
      lineHeight: '20px', // Match line-height of your text
      fontSize: '14px',
      fontWeight: 200,
      paddingBottom: '10px',
    },

    '&:last-child': {
      marginBottom: 0, // Remove bottom margin for the last item
    },
  },
  img: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

interface TermAcceptanceProps {
  areAllProgramsDisqualified: boolean;
}

export const TermAcceptanceModal = observer(
  ({ areAllProgramsDisqualified }: TermAcceptanceProps) => {
    const { modules } = useDashboardStores();
    const { app } = useCommonStores();
    const { company } = useCompany();
    const classes = useStyles();
    const { accountCreation } = useAccountCreationStores();
    const [acceptLoading, setAcceptLoading] = useState<boolean>(false);

    useEffect(() => {
      // Mob X Reaction to show the congratulations page after the user accepts the terms
      // This was executed due to some weird race condition that was happening with showing this
      // modal even after executing the function to close out the modal on the congratulations page
      // This waits for it to properly close out the perform a redirect to the congratulations page
      const dispose = reaction(
        () => modules.showTermAcceptanceModal,
        async (showModal) => {
          if (!showModal) {
            if (
              accountCreation.programCreditEstimates &&
              accountCreation.programCreditEstimates.length > 0
            ) {
              await accountCreation.handleCreateOrderForms(
                ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
              );
            }

            app.history.push(`/${Page.taxCredits}`);
            accountCreation.isNextButtonLoading = false;
            setAcceptLoading(false);
          }
        },
      );

      // Cleanup the reaction when the component unmounts
      return () => dispose();
    }, []); // Empty dependency array ensures this effect runs only once

    return (
      <Modal
        dataTestId='term-acceptance-modal'
        showModal={modules.showTermAcceptanceModal}
        maxWidth={716}
        className={classes.modalContainer}
      >
        <Flex direction='row'>
          <Flex className={classes.imageCard}>
            <img
              className={classes.img}
              src={`${process.env.PUBLIC_URL}/images/tax-credit-dashboard-module/document.png`}
              alt='Document Image'
            />
          </Flex>
          <Flex direction='column' className={classes.contentCard}>
            <Heading tag='h4' variant='medium'>
              {areAllProgramsDisqualified
                ? `We want to find you more credits!`
                : `Next, we will help you finalize these credits`}
            </Heading>
            <Text>
              {areAllProgramsDisqualified
                ? `Although we couldn't find credits that match you right now, we’ll keep finding more credits on your behalf. Here's what you’ll get with MainStreet:`
                : `You're a great fit for MainStreet. Here's what you get when you work with us:`}
            </Text>
            <ul className={classes.customList}>
              <li>
                {areAllProgramsDisqualified
                  ? `You only get charged when you get tax forms delivered to you.`
                  : `You will only be charged when your tax forms are delivered.`}
              </li>
              <li>
                {areAllProgramsDisqualified
                  ? `We will scan and get you every eligible credit we support in the future.`
                  : `We will get you every eligible credit we support.`}
              </li>
              <li>
                You will get the MainStreet Guarantee &mdash; $1M in case of an
                IRS audit from the work we do.
              </li>
            </ul>

            <Button
              label={'Accept'}
              loading={acceptLoading}
              dataTestId={'terms-acceptance-button'}
              onClick={async () => {
                setAcceptLoading(true);
                await EmptyClient.AcceptTerms(
                  'accepted_unified_mainstreet_terms',
                );
                // Need to refresh current company grab new account signup stage
                await app.common.companyStore.refreshCurrentCompany();
                modules.toggleTermAcceptanceModal(false);
              }}
            />
            <Text size={11}>
              By selecting &quot;Accept&quot;, {company.name} is entering into
              an agreement with MainStreet Work, Inc governed by these{' '}
              <a href={'/terms-of-service'} target='_blank' rel='noreferrer'>
                terms
              </a>
              , and you confirm you are authorized to do so on behalf of{' '}
              {company.name}.
            </Text>
          </Flex>
        </Flex>
      </Modal>
    );
  },
);
