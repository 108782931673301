import { BaseNamespace } from 'stores/BaseNamespace';
import { CompanyStore } from './CompanyStore';
import { FeatureFlagStore } from './FeatureFlagStore';
import { AuthStore } from './AuthStore';
import { AppStore } from './AppStore';
import { ChargeBeeStore } from './ChargeBeeStore';
import { ChatBotStore } from './ChatBotStore';
import { WorkDoneStore } from './WorkDoneStore';

export class CommonNamespace extends BaseNamespace {
  public featureFlags = new FeatureFlagStore(this.rootStore);
  public app = new AppStore(this.rootStore);
  public auth = new AuthStore(this.rootStore);
  public companyStore = new CompanyStore(this.rootStore);
  public chargeBee = new ChargeBeeStore(this.rootStore);
  public chatbot = new ChatBotStore(this.rootStore);
  public workDoneStore = new WorkDoneStore(this.rootStore);
}
