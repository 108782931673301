import React, { useState } from 'react';
import {
  useCommonStores,
  useLegacyClients,
} from '../../../../stores/useStores';
import { useEffectOnce } from '../../../../lib/helpers';
import DOMPurify from 'dompurify';

interface Props {
  termsName: string;
}

export default function TermsPage({ termsName }: Props) {
  const { client: legacyClient } = useLegacyClients();
  const { app } = useCommonStores();

  const [termsHTML, setTermsHTML] = useState<string>('');

  useEffectOnce(() => {
    legacyClient.GetTermsByNames([termsName]).then((res) => {
      if (
        res.data.terms &&
        res.data.terms.length == 1 &&
        res.data.terms[0].termsVersions &&
        res.data.terms[0].termsVersions.length == 1
      ) {
        setTermsHTML(res.data.terms[0].termsVersions[0].termsAndConditions);
      }
    });
  });

  const padding = app.isMobile ? '20px' : '100px';

  return (
    <div style={{ padding }}>
      <div
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(termsHTML) }}
      />
    </div>
  );
}
