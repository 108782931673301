import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Text, Flex, Chip, Button, Color } from 'component-library';
import { CentsToDisplayString } from 'lib/helpers';

interface GustoRipplingRedemptionDownloadStepProps {
  isGusto?: boolean;
  isRippling?: boolean;
  creditBalance: number;
}

const useStyles = makeStyles(() => ({
  container: {
    border: `1px solid ${Color.neutral._20}`,
  },
  instructions: {
    backgroundColor: Color.neutral._light_20,
  },
  list: {
    paddingLeft: '16px',
    margin: 0,
    lineHeight: '1.8',
  },
}));

export const GustoRipplingRedemptionDownloadStep = observer(
  ({
    isGusto,
    isRippling,
    creditBalance,
  }: GustoRipplingRedemptionDownloadStepProps) => {
    const classes = useStyles();

    const renderGustoActions = () => {
      return (
        <Flex padding={24}>
          <Flex direction='column' gap={24} className={classes.container}>
            <Flex
              className={classes.instructions}
              direction='column'
              padding={24}
            >
              <Flex gap={16}>
                <Text variant='medium' size={23}>
                  Set up future redemption
                </Text>
                <Chip
                  text='Current quarter'
                  variant='warning'
                  showIcon={false}
                />
              </Flex>
            </Flex>
            <Flex padding={[0, 24, 24, 24]} gap={24}>
              <Button
                label='Set up credits in Gusto'
                onClick={{
                  external: true,
                  location: 'https://app.gusto.com/login',
                }}
                dataTestId='redemption-setup-in-gusto'
              />
              <Button
                label='How to set up with Gusto'
                onClick={{
                  external: true,
                  location:
                    'https://mainstreet1.my.site.com/help/s/article/How-to-File-Your-Form-8974-with-Gusto',
                }}
                dataTestId='redemption-how-to-setup-gusto'
                variant='outlined'
              />
            </Flex>
          </Flex>
        </Flex>
      );
    };

    const renderRipplingActions = () => {
      return (
        <Flex padding={24}>
          <Flex direction='column' gap={24} className={classes.container}>
            <Flex
              className={classes.instructions}
              direction='column'
              padding={24}
            >
              <Flex gap={16}>
                <Text variant='medium' size={23}>
                  Set up future redemption
                </Text>
                <Chip
                  text='Current quarter'
                  variant='warning'
                  showIcon={false}
                />
              </Flex>
            </Flex>
            <Flex padding={[0, 24, 24, 24]} gap={24}>
              <Button
                label='Set up credits in Rippling'
                onClick={{
                  external: true,
                  location:
                    'http://app.rippling.com/payroll/research-and-development-credits',
                }}
                dataTestId='redemption-setup-in-rippling'
              />
            </Flex>
          </Flex>
        </Flex>
      );
    };

    return (
      <Flex direction='column' className={classes.container}>
        <Flex
          className={classes.instructions}
          direction='column'
          padding={24}
          gap={8}
        >
          {isGusto && (
            <>
              <Text
                variant='medium'
                size={23}
                text='Current quarter filing instructions:'
              />

              <ol className={classes.list}>
                <li>
                  <Text text='Log into your Gusto dashboard using an accountant admin account.' />
                </li>
                <li>
                  <Text
                    text={`Navigate to "Redeem third-party R&D tax credits".`}
                  />
                </li>
                <li>
                  <Text
                    text={`Provide Gusto with the requested information, using the  Form 6765 you filed with your income tax return.`}
                  />
                  <Text size={13}>
                    Under “Previous credit claims”, enter the amount of credit
                    already redeemed as {CentsToDisplayString(creditBalance)}.
                  </Text>
                </li>
              </ol>
              <Text>
                You&apos;ll be able to view your credit redemption status at any
                time in the Reports section of your Gusto dashboard.
              </Text>
            </>
          )}
          {isRippling && (
            <>
              <Text
                variant='medium'
                size={23}
                text='Current quarter filing instructions:'
              />
              <ol className={classes.list}>
                <li>
                  <Text text='Go to your Rippling dashboard to enter your credit information.' />
                </li>
                <li>
                  <Text
                    text={`Select the option "Yes, we have used up part of this credit."`}
                  />
                </li>
                <li>
                  <Text
                    text={`Enter the amount of credit already claimed as ${CentsToDisplayString(
                      creditBalance,
                    )}, then complete the remainder of the form.`}
                  />
                </li>
                <li>
                  <Text text='Rippling will apply all remaining credit on your behalf! You can view your redemption status at any time from the MainStreet app within Rippling.' />
                </li>
              </ol>
              <Text size={13}>
                <strong>Note:</strong> If you use Rippling as a PEO, please work
                with them to file your previous quarters&apos; forms before
                completing the step below.
              </Text>
            </>
          )}
        </Flex>
        {isGusto && renderGustoActions()}
        {isRippling && renderRipplingActions()}
      </Flex>
    );
  },
);
