import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { Color, Flex, LoadingDots } from 'component-library';

const useStyles = makeStyles(() => ({
  textArea: {
    width: '20%',
    background: Color.neutral._light_40,
    borderRadius: '80px',
  },
  botImage: {
    width: '32px',
    height: '32px',
    borderRadius: '32px',
    background: Color.green._80,
  },
  img: {
    width: '16px',
  },
}));

export default function BotTyping() {
  const classes = useStyles();

  return (
    <Flex gap={4} padding={[4, 0]} alignItems='end' justifyContent='start'>
      <Flex
        className={classes.botImage}
        alignItems='center'
        justifyContent='center'
      >
        <img
          className={classes.img}
          src={`${process.env.PUBLIC_URL}/images/logo/mst-logomark-light.svg`}
        />
      </Flex>
      <Flex
        className={classes.textArea}
        direction='column'
        alignItems='center'
        padding={[12, 16]}
      >
        <LoadingDots />
      </Flex>
    </Flex>
  );
}
