import {
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  makeStyles,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CheckSharp } from '@material-ui/icons';
import { RdActivityEnum, RdActivityEnumToString } from 'lib/constants';
import {
  AddActivityContainer,
  CheckableItem,
} from 'pages/dashboard/fed-rd-program';
import * as React from 'react';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '20px 30px',
  },
  formLabel: {
    fontWeight: 500,
    fontSize: 14,
    '&.Mui-focused': {
      color: '#333333',
    },
  },
  icon: {
    padding: 0,
    margin: '3px 10px 0 0',
    fontSize: 16,
  },
  savedItem: {
    padding: '8px 0',
  },
  savedItemLabel: {
    fontSize: 14,
  },
  addActivity: {
    paddingTop: '20px',
  },
}));

export interface Activity {
  name: RdActivityEnum;
  checked: boolean;
}

export interface ActivityListProps {
  activities: Activity[];
  setActivities: (activities: Activity[]) => void;
  prompt: string;
  editMode: boolean;
}

export const ActivityList = ({
  activities,
  setActivities,
  editMode,
  prompt,
}: ActivityListProps) => {
  const classes = useStyles();
  const existingActivities = activities.map((activity) => activity.name);

  const updateActivity = (index: number) => {
    return (checked: boolean) => {
      setActivities(
        [...activities].map((activity, activityIndex) => {
          return index === activityIndex
            ? {
                ...activity,
                checked,
              }
            : activity;
        }),
      );
    };
  };

  const renderActivity = (activity: Activity, index: number) => {
    if (editMode) {
      return (
        <Box key={activity.name}>
          <CheckableItem
            name={RdActivityEnumToString[activity.name]}
            checked={activity.checked}
            handleChange={updateActivity(index)}
          />
          <Divider />
        </Box>
      );
    } else {
      return (
        <Box
          display='flex'
          alignItems='top'
          className={classes.savedItem}
          key={activity.name}
        >
          <CheckSharp className={classes.icon} />
          <Typography className={classes.savedItemLabel}>
            {RdActivityEnumToString[activity.name]}
          </Typography>
        </Box>
      );
    }
  };

  const addActivity = (activityToAdd: RdActivityEnum) => {
    setActivities([...activities, { name: activityToAdd, checked: true }]);
  };

  if (
    !editMode &&
    activities.filter((activity) => activity.checked).length === 0
  ) {
    return <></>;
  }

  return (
    <FormControl component='fieldset' className={classes.formControl}>
      {editMode && (
        <FormLabel component='legend' classes={{ root: classes.formLabel }}>
          {prompt}
        </FormLabel>
      )}
      <FormGroup>
        {activities
          .filter((activity) => editMode || activity.checked)
          .map(renderActivity)}
        {editMode && (
          <div className={classes.addActivity}>
            <AddActivityContainer
              existingActivities={existingActivities}
              onAddClick={addActivity}
            />
          </div>
        )}
      </FormGroup>
    </FormControl>
  );
};
