import { runInAction } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import { BaseStore } from 'stores/BaseStore';
import { datadogLogs } from '@datadog/browser-logs';
import {
  SectionType,
  ChargebeeInstance,
  ChargebeePortalInstance,
  ProductSubscription,
} from 'services/financial/types';

declare global {
  interface Window {
    Chargebee: {
      init: (options: {
        site: string | undefined;
        publishableKey: string | undefined;
        enableGTMTracking: boolean;
      }) => ChargebeeInstance;
      createChargebeePortal: () => ChargebeePortalInstance;
      getInstance: () => ChargebeeInstance;
      getPortalSections: () => Record<string, string>;
    };
  }
}

export class ChargeBeeStore extends BaseStore {
  public site = '';
  public publicKey = '';
  public portalSessionError = '';
  public companySubscriptions: ProductSubscription[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public async initialize() {
    await this.getPaymentsEnvironment();

    /* 
      Initialize Chargebee JS Browser Module 
    */
    if (window && window.Chargebee) {
      window.Chargebee.init({
        site: this.site,
        publishableKey: this.publicKey,
        enableGTMTracking: true,
      });
    }
  }

  /**
   * Returns current operating environment variables from payments for client to configure
   * Chargebee JS browser module with
   */
  private async getPaymentsEnvironment() {
    const res = await this.paymentsClient.getPaymentsEnvironment();
    if (!res.errorMsg && res.data) {
      runInAction(() => {
        this.site = res.data?.site || '';
        this.publicKey = res.data?.publicKey || '';
      });
    }
  }

  /**
   * Callback handed to portal session instance to be called on close
   */
  private logoutPortalSession() {
    const chargeBeeInstance = window.Chargebee.getInstance();
    chargeBeeInstance.logout();
  }

  /**
   * Given a portal section type, opens a chargebee portal using Luca and chargebee
   * JS browser module.
   * @param sectionType
   */
  public async openPortalSession(sectionType: SectionType) {
    runInAction(() => (this.portalSessionError = ''));
    const chargeBeeInstance = window.Chargebee.getInstance();
    const { data, errorMsg } = await this.paymentsClient.createCBPortalSession(
      this.company.id,
    );

    if (errorMsg || !data) {
      datadogLogs.logger.error(
        `Error fetching chargebee migration status for companyId ${this.company.id}: ${errorMsg}`,
      );
      // TODO: Update error message and add display, pending designs.
      runInAction(
        () =>
          (this.portalSessionError =
            'Something went wrong while loading your billing information.  Please refresh and try again'),
      );
      return;
    }

    chargeBeeInstance.setPortalSession(() => new Promise((res) => res(data)));

    if (sectionType === SectionType.SubscriptionDetails) {
      chargeBeeInstance.createChargebeePortal().open({
        close: this.logoutPortalSession,
      });
    } else {
      chargeBeeInstance
        .createChargebeePortal()
        .openSection(
          { sectionType: window.Chargebee.getPortalSections()[sectionType] },
          { close: this.logoutPortalSession },
        );
    }
  }
}
