import React from 'react';
import { useTaxCreditsStores } from 'stores/useStores';
import { Page, ERCStepsIndex } from 'lib/constants';
import {
  QualifyForERC,
  ConnectToPayroll,
  ConfirmCompanyAddress,
  EmployeeDetails,
  UploadTaxReturn,
  UploadPayrollReturns,
  ERCCreditEstimate,
  BillingSummary,
} from './steps';
import { useLocation } from 'react-router-dom';

interface ERCRoutesInterface {
  exact?: boolean;
  path?: string;
  component: React.ReactElement | JSX.Element;
}

const useERCRoutes = (): { ERCRoutes: ERCRoutesInterface[] } => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { erc } = useTaxCreditsStores();

  const isPrefilled = queryParams.has('prefilled');

  return {
    ERCRoutes: [
      {
        exact: true,
        path: `/${Page.taxCredits}/${Page.erc}`,
        component: (
          <QualifyForERC
            pathTo={`/${Page.taxCredits}/${Page.erc}/${
              Page.ercUploadTaxReturn
            }${isPrefilled ? '/?prefilled=true' : ''}`}
            onNext={() => erc.setERCFlowIndex(ERCStepsIndex.UPLOAD_TAX_RETURN)}
            isPrefilled={isPrefilled}
          />
        ),
      },
      {
        path: `/${Page.taxCredits}/${Page.erc}/${Page.ercUploadTaxReturn}`,
        component: (
          <UploadTaxReturn
            pathTo={`/${Page.taxCredits}/${Page.erc}/${
              Page.ercConnectToPayroll
            }${isPrefilled ? '/?prefilled=true' : ''}`}
            pathFrom={`/${Page.taxCredits}/${Page.erc}`}
            onNext={() => erc.setERCFlowIndex(ERCStepsIndex.CONNECT_TO_PAYROLL)}
            onBack={() => erc.setERCFlowIndex(ERCStepsIndex.QUALIFY_FOR_ERC)}
            isPrefilled={isPrefilled}
          />
        ),
      },
      {
        path: `/${Page.taxCredits}/${Page.erc}/${Page.ercConnectToPayroll}`,
        component: (
          <ConnectToPayroll
            pathTo={`/${Page.taxCredits}/${Page.erc}/${
              Page.ercUploadPayrollTaxReturn
            }${isPrefilled ? '/?prefilled=true' : ''}`}
            pathFrom={`/${Page.taxCredits}/${Page.erc}/${Page.ercUploadTaxReturn}`}
            onNext={() =>
              erc.setERCFlowIndex(ERCStepsIndex.UPLOAD_PAYROLL_RETURNS)
            }
            onBack={() => erc.setERCFlowIndex(ERCStepsIndex.UPLOAD_TAX_RETURN)}
            isPrefilled={isPrefilled}
          />
        ),
      },
      {
        path: `/${Page.taxCredits}/${Page.erc}/${Page.ercUploadPayrollTaxReturn}`,
        component: (
          <UploadPayrollReturns
            pathTo={`/${Page.taxCredits}/${Page.erc}/${Page.ercConfirmAddress}${
              isPrefilled ? '/?prefilled=true' : ''
            }`}
            pathFrom={`/${Page.taxCredits}/${Page.erc}/${Page.ercConnectToPayroll}`}
            onNext={() => erc.setERCFlowIndex(ERCStepsIndex.CONFIRM_ADDRESS)}
            onBack={() => erc.setERCFlowIndex(ERCStepsIndex.CONNECT_TO_PAYROLL)}
            isPrefilled={isPrefilled}
          />
        ),
      },
      {
        path: `/${Page.taxCredits}/${Page.erc}/${Page.ercConfirmAddress}`,
        component: (
          <ConfirmCompanyAddress
            pathTo={`/${Page.taxCredits}/${Page.erc}/${Page.ercPayrollDetails}${
              isPrefilled ? '/?prefilled=true' : ''
            }`}
            pathFrom={`/${Page.taxCredits}/${Page.erc}/${Page.ercUploadPayrollTaxReturn}`}
            onNext={() => erc.setERCFlowIndex(ERCStepsIndex.PAYROLL_DETAILS)}
            onBack={() =>
              erc.setERCFlowIndex(ERCStepsIndex.UPLOAD_PAYROLL_RETURNS)
            }
            isPrefilled={isPrefilled}
          />
        ),
      },
      {
        path: `/${Page.taxCredits}/${Page.erc}/${Page.ercPayrollDetails}`,
        component: (
          <EmployeeDetails
            pathTo={`/${Page.taxCredits}/${Page.erc}/${Page.ercCreditEstimate}${
              isPrefilled ? '/?prefilled=true' : ''
            }`}
            pathFrom={`/${Page.taxCredits}/${Page.erc}/${Page.ercConfirmAddress}`}
            onNext={() => erc.setERCFlowIndex(ERCStepsIndex.CREDIT_ESTIMATE)}
            onBack={() => erc.setERCFlowIndex(ERCStepsIndex.CONFIRM_ADDRESS)}
            isPrefilled={isPrefilled}
          />
        ),
      },
      {
        path: `/${Page.taxCredits}/${Page.erc}/${Page.ercCreditEstimate}`,
        component: (
          <ERCCreditEstimate
            pathTo={`/${Page.taxCredits}/${Page.erc}/${Page.ercBillingSummary}${
              isPrefilled ? '/?prefilled=true' : ''
            }`}
            isPrefilled={isPrefilled}
            onNext={() => erc.setERCFlowIndex(ERCStepsIndex.ERC_ORDER_FORM)}
          />
        ),
      },
      {
        path: `/${Page.taxCredits}/${Page.erc}/${Page.ercBillingSummary}`,
        component: (
          <BillingSummary
            pathTo='https://calendly.com/d/5hw-ww5-y6v/finalize-your-employee-retention-credit'
            isPrefilled={isPrefilled}
          />
        ),
      },
    ],
  };
};

export default useERCRoutes;
