import {
  Color,
  Flex,
  TextField,
  IconEnum,
  Slider,
  Icon,
} from 'component-library';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  slider: {
    maxWidth: 400,
  },
  percentTextField: {
    minWidth: '80px',
    marginRight: '8px',
  },
  inputContainer: {
    width: 80,
    marginRight: 8,
  },
  percentInput: {
    textAlign: 'center',
    border: `1px solid ${Color.neutral._20} !important`,
    boxShadow: 'none !important',
    height: 48,
  },
  outOfBounds: {
    textAlign: 'left',
  },
}));

interface RdActivitySliderProps {
  highPercentEstimate: number;
  lowPercentEstimate: number;
  percent: number;
  setPercent: (id: number, newPercentage: number) => void;
  id: string | number;
  checkPercentages: () => void;
}

export const RdPercentageSlider = ({
  highPercentEstimate,
  lowPercentEstimate,
  percent,
  setPercent,
  id,
  checkPercentages,
}: RdActivitySliderProps) => {
  const classes = useStyles();
  const [percentage, setPercentage] = useState<number>(percent);
  const [percentOutOfBounds, setPercentOutOfBounds] = useState<boolean>(false);

  useEffect(() => {
    setPercentOutOfBounds(
      percentage < lowPercentEstimate || percentage > highPercentEstimate,
    );
  }, [percentage, lowPercentEstimate, highPercentEstimate]);

  const handlePercentInput = (
    inputEvent:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const percentInput: number = parseInt(
      inputEvent.target.value.replace(/\D/g, ''),
    );
    if (percentInput < 0 || percentInput > 100) {
      setPercentage(0);
      setPercent(Number(id), 0);
    } else {
      setPercentage(percentInput);
      setPercent(Number(id), percentInput);
    }
    checkPercentages();
  };

  return (
    <Flex
      alignItems='center'
      justifyContent='flex-end'
      padding={[8, 0, 8, 16]}
      gap={16}
    >
      {percentOutOfBounds && (
        <Icon name={IconEnum.info_circle} color={Color.blue._70} />
      )}
      <Slider
        dataTestId='rd-percentage-slider'
        value={percentage || 0}
        onChange={(value) => {
          setPercent(Number(id), value);
          setPercentage(value);
        }}
        onCommitChange={() => checkPercentages()}
        aria-label='R&D activities percentage'
        className={classes.slider}
      />
      <Flex.Cell className={classes.inputContainer}>
        <TextField
          value={percentage || 0}
          onBlur={handlePercentInput}
          percentFormat
          className={classes.percentTextField}
          inputClassName={`${classes.percentInput} ${
            percentOutOfBounds ? classes.outOfBounds : ''
          }`}
        />
      </Flex.Cell>
    </Flex>
  );
};
