import React from 'react';
import { Page, AccountCreationStepsIndex } from 'lib/constants';
import { useAccountCreationStores, useFeatureFlags } from 'stores/useStores';
import { BusinessStep, ContactsStep, TaxesStep } from '../steps';

interface AccountCreationRoutesInterface {
  exact?: boolean;
  path?: string;
  component: React.ReactElement | JSX.Element;
}

export const useAccountCreationRoutes = (): {
  accountCreationRoutes: AccountCreationRoutesInterface[];
} => {
  const { accountCreation } = useAccountCreationStores();
  const featureFlags = useFeatureFlags();

  const showProgramTaxYearPage = featureFlags.showOnboardingProgramTaxYearPage;

  return {
    accountCreationRoutes: [
      {
        exact: true,
        path: `/${Page.accountCreationBusiness}`,
        component: (
          <BusinessStep
            pathTo={`/${Page.accountCreationContacts}`}
            onNext={() =>
              accountCreation.setCurrentStep(AccountCreationStepsIndex.CONTACTS)
            }
          />
        ),
      },
      {
        path: `/${Page.accountCreationContacts}`,
        component: (
          <ContactsStep
            pathTo={`/${Page.accountCreationTaxes}`}
            pathFrom={`/${Page.accountCreationBusiness}`}
            onNext={() =>
              accountCreation.setCurrentStep(AccountCreationStepsIndex.TAXES)
            }
            onBack={() =>
              accountCreation.setCurrentStep(AccountCreationStepsIndex.BUSINESS)
            }
          />
        ),
      },
      {
        path: `/${Page.accountCreationTaxes}`,
        component: (
          <TaxesStep
            pathTo={
              showProgramTaxYearPage
                ? `/${Page.accountCreationPrograms}`
                : `/${Page.transitionToDashboard}`
            }
            pathFrom={`/${Page.accountCreationContacts}`}
            onNext={() =>
              showProgramTaxYearPage
                ? accountCreation.setCurrentStep(
                    AccountCreationStepsIndex.PROGRAMS,
                  )
                : accountCreation.setCurrentStep(
                    AccountCreationStepsIndex.TAXES,
                  )
            }
            onBack={() =>
              accountCreation.setCurrentStep(AccountCreationStepsIndex.CONTACTS)
            }
          />
        ),
      },
    ],
  };
};
