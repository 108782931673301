import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Button,
  Card,
  CardHeader,
  Color,
  Content,
  Icon,
  IconEnum,
  Text,
} from 'component-library';
import { Address, BusinessApplicationData, CountryEnum } from 'lib/interfaces';
import { ValidateAndFormatPhone } from 'lib/validation';
import { BankingInformation } from 'pages/dashboard/integrations/components/plaidIntegration';
import { GetCountryNameUsingAbbreviation } from './helpers';

const useStyles = makeStyles(() => ({
  textButtons: {
    height: '0px',
    paddingLeft: '0px',
  },
}));

type KycCompanyInfoSummaryProps = {
  accreditationDocs?: File[];
  accredited: boolean;
  bankingInfo?: BankingInformation;
  companyInfo: BusinessApplicationData;
  connectedToPlaid?: boolean;
  onSubmit: () => void;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const KycCompanyInfoSummary = ({
  accreditationDocs,
  accredited,
  bankingInfo,
  companyInfo,
  connectedToPlaid,
  onSubmit,
  setEditMode,
}: KycCompanyInfoSummaryProps) => {
  const classes = useStyles();
  const { name, phoneNumber, tin, physicalAddress } = companyInfo;

  const {
    streetLine1,
    streetLine2,
    city,
    state,
    postalCode,
    country,
  } = physicalAddress as Address;

  const formattedTinNumber = tin.includes('-')
    ? tin
    : tin.slice(0, 2) + '-' + tin.slice(2);

  const formattedAddress = streetLine1
    ? [
        streetLine1,
        [streetLine2, city, state].join(', '),
        postalCode,
        GetCountryNameUsingAbbreviation(country as CountryEnum),
      ].join(' ')
    : undefined;

  const accredidationDocNames = accreditationDocs?.length && (
    <Content flex flexWrap='wrap'>
      {accreditationDocs?.map((doc) => (
        <Text color={Color.neutral._80} key={doc.name}>
          {doc.name}
        </Text>
      ))}
    </Content>
  );

  const accredidationType =
    connectedToPlaid &&
    accredited &&
    bankingInfo?.accountName &&
    `Bank account connected`;

  const summaryData = [
    ['Legal name', name],
    ['Company phone', ValidateAndFormatPhone(phoneNumber)],
    ['Tax ID / EIN', formattedTinNumber],
    ['Business address', formattedAddress],
    [
      'Proof of accreditation',
      accredidationDocNames || accredidationType || '',
    ],
  ];
  return (
    <Content paddingBottom={16}>
      <Text text={`Confirm that everything looks good to go.`} size={15} />
      <Card>
        <CardHeader title={companyInfo.name}>
          <Content flex>
            <Content flex gap={8}>
              <Icon name={IconEnum.check} color={Color.semantic.$success50} />
              <Text color={Color.semantic.$success50}>Done</Text>
              <Content flex paddingLeft={8} paddingRight={0}>
                <Text color={Color.neutral._20}>|</Text>
              </Content>
            </Content>
            <Button
              className={classes.textButtons}
              label={
                <Text text='Edit' color={Color.blue._60} variant='medium' />
              }
              onClick={() => setEditMode(true)}
              variant='tertiary'
            />
          </Content>
        </CardHeader>

        <div style={{ paddingBottom: 20 }} />
        {summaryData.map((datum) => (
          <Content flex key={datum[0] as string}>
            <div style={{ minWidth: '20vw' }}>
              <Text text={datum[0]} variant='medium' />
            </div>
            <Text text={datum[1]} />
          </Content>
        ))}
      </Card>

      <Button
        label='Save and Continue'
        onClick={() => {
          onSubmit();
        }}
      />
    </Content>
  );
};
