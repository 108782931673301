import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Padding, Spinner } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  useCommonStores,
  useHistoryStore,
  useTaxCreditsStores,
} from 'stores/useStores';
import { IsDisplayableOrderForm, useEffectOnce } from 'lib/helpers';
import { OrderFormContent } from './components';
import HttpErrorPage from 'pages/HttpErrorPage';

const useStyles = makeStyles(({ breakpoints }) => ({
  '@global': {
    [breakpoints.down('xs')]: {
      '.embeddedServiceHelpButton': {
        display: 'none',
      },
    },
  },
  container: {
    maxWidth: '984px',
  },
}));

interface PageParamType {
  programId: string;
}

export const OrderForm: React.FC = observer(() => {
  const classes = useStyles();
  const { programId: programIdString } = useParams<PageParamType>();
  const programId = parseInt(programIdString, 10);

  const { app } = useCommonStores();
  const { orderForms } = useTaxCreditsStores();
  const history = useHistoryStore();
  const order = orderForms.orderForm;
  const currentProgram = orderForms.currentProgram;
  const containerPadding: Padding = app.isMobile ? [32, 24] : [40, 80, 80, 48];

  useEffectOnce(async () => await orderForms.initializeOrderForm(programId));

  useEffect(() => {
    if (order && !IsDisplayableOrderForm(order)) {
      history.push('/');
    }
  }, [order]);

  if (orderForms.getOrderFormError) {
    return <HttpErrorPage errorCode={404} />;
  }

  return (
    <Flex
      className={classes.container}
      padding={containerPadding}
      direction='column'
      data-testid={'order-form'}
      gap={32}
    >
      {!order || !currentProgram ? (
        <Spinner size='small' dataTestId='order-form-page-loading-spinner' />
      ) : (
        <OrderFormContent order={order} currentProgram={currentProgram} />
      )}
    </Flex>
  );
});
