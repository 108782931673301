import React from 'react';
import { Button } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles((theme) => ({
  connectButton: {
    width: '100%',
  },
}));
interface IPlaidConnectBtnProps {
  open: () => void;
  isLoading: boolean;
  btnText?: string;
}

export default function PlaidConnectBtn(props: IPlaidConnectBtnProps) {
  const classes = useStyles();
  const { app } = useCommonStores();
  return (
    <Button
      className={app.isMobile ? classes.connectButton : undefined}
      onClick={props.open}
      label={props.btnText || 'Connect bank account'}
      loading={props.isLoading}
      dataTestId='plaid-connect-button'
    />
  );
}
