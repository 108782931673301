import React, { Children } from 'react';
import { observer } from 'mobx-react';
import { Animate, Grid } from 'component-library';
import { useCommonStores } from 'stores/useStores';

interface ContentFrameProps {
  children: React.ReactElement[] | JSX.Element[];
}

export const ContentFrame: React.FC<ContentFrameProps> = observer(
  ({ children }) => {
    const { app } = useCommonStores();

    return (
      <>
        <Grid.Cell columns={app.isMobile ? 12 : 6}>
          <Animate enter={'fade-in'} delay={0.25}>
            {Children.toArray(children)[0]}
          </Animate>
        </Grid.Cell>
        <Grid.Cell columns={app.isMobile ? 0 : 1} />
        <Grid.Cell columns={app.isMobile ? 12 : 5}>
          <Animate enter={'fade-in'} delay={0.65}>
            {Children.toArray(children)[1]}
          </Animate>
        </Grid.Cell>
      </>
    );
  },
);
