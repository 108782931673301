import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Animate, Button, Flex, Spinner, Text } from 'component-library';
import TitleHeader from '../../../../../../components/TitleHeader';
import { makeStyles } from '@material-ui/core';
import { Page, TermNames } from '../../../../../../lib/constants';
import { AssessmentStatusCard } from './AssessmentStatusCard';
import {
  useCommonStores,
  useFeatureFlags,
  useLegacyClients,
  useTaxCreditsStores,
} from '../../../../../../stores/useStores';
import {
  AssessmentProgressStatus,
  TaxCreditAssessments,
} from '../../../../../../lib/interfaces';
import {
  HasManuallyAcceptedOrderForm,
  useEffectOnce,
} from '../../../../../../lib/helpers';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    maxWidth: '1200px',
    margin: '0 auto',
    position: 'relative',
    padding: '24px 0',
  },
  creditList: {
    margin: '24px 0 48px',
  },
}));

interface AssessmentReviewPageProps {
  onNext: () => void;
  onBack: () => void;
  taxYear: number;
}

export const AssessmentReviewPage = observer(
  ({ onNext, taxYear }: AssessmentReviewPageProps) => {
    const classes = useStyles();

    const { unifiedTaxCredits, surveyFlow } = useTaxCreditsStores();
    const { companyStore } = useCommonStores();

    const [assessments, setAssessments] = useState<TaxCreditAssessments[]>();
    const [disableContinue, setDisableContinue] = useState<boolean>(false);

    const company = companyStore.currentCompany;
    const isExistingCustomer = HasManuallyAcceptedOrderForm(company.orderForms);
    const featureFlag = useFeatureFlags();
    const termsIncluded: string[] = [
      // Names of terms to be included in the savings agreement
      TermNames.CORE,
      TermNames.PROMOTIONAL,
    ];

    const hasAcceptedSavingsAgreement = async () => {
      let numberTermsAcceptances = 0;
      client.GetTermsByNames(termsIncluded).then(async (res) => {
        if (res.data.terms.length > 0) {
          for (const term in res.data.terms) {
            client
              .GetRecentTermsAcceptanceByCompanyAndTermsId(term)
              .then(async (res) => {
                if (res.data.termsAcceptance) {
                  numberTermsAcceptances++;
                }
              });
          }
        }
      });
      return numberTermsAcceptances == 2;
    };
    const shouldShowPaymentsPage = featureFlag.showSavingsAgreement
      ? !isExistingCustomer || !hasAcceptedSavingsAgreement
      : !isExistingCustomer;
    const { client } = useLegacyClients();

    useEffectOnce(async () => {
      async function setPageData(): Promise<void> {
        await unifiedTaxCredits.getTaxCreditAssessments(taxYear);

        const taxCreditAssessments =
          unifiedTaxCredits.assessmentByYear[taxYear];
        setAssessments(taxCreditAssessments);

        if (!taxCreditAssessments) return;

        // Disable continue if any assessment is incomplete
        for (const assessment of taxCreditAssessments) {
          if (
            assessment.status !== AssessmentProgressStatus.READY_TO_SUBMIT &&
            assessment.status !== AssessmentProgressStatus.MS_REVIEW &&
            assessment.status !== AssessmentProgressStatus.DQ
          ) {
            setDisableContinue(true);
            break;
          }
        }
      }

      await setPageData();
    });

    const handleOnClick = async () => {
      // Checks if a token exist with a yea assessment scope and expires the token if it does
      const yeaPages = [
        Page.assessmentBusinessDetails,
        Page.retirementPlan,
        Page.healthcare,
        Page.expenseClassificationCompanyDetails,
        Page.expenseClassificationSuppliesServices,
        Page.expenseClassificationEmployees,
        Page.disabledAccess,
      ];
      await client.ExpireAccessToken(taxYear, yeaPages);

      if (!shouldShowPaymentsPage) {
        // For existing customers or customers who have accepted the Savings Agreements,
        // move all programs to client review and send them back to the tax credits page
        await surveyFlow.completeAssessment(taxYear);
        window.location.href = `/${Page.taxCredits}`;
      } else {
        // For new customers, send them to the payments page
        onNext();
      }
    };

    const connectPayrollAssessment: TaxCreditAssessments = {
      id: '1',
      assessmentTitle: 'Connect Payroll',
      assessmentTooltip: '',
      estimatedCreditContext: '',
      priority: 1,
      status: AssessmentProgressStatus.MS_REVIEW,
    };

    return (
      <Flex
        direction='column'
        className={classes.root}
        data-testid={'unified-assessment-review'}
      >
        <Animate enter={['fade-in', 'from-top']}>
          <TitleHeader
            title='Assessment Review'
            showExitButton
            borderBottom
            onCancelExitText='Cancel'
            showCompanyName
            showTalkToExpertButton
          />
        </Animate>

        {assessments ? (
          <Flex className={classes.mainContent} direction='column'>
            <Animate enter={'fade-in'}>
              <Text variant='medium' size={32} paddingBottom={8}>
                Review your assessment
              </Text>
              {isExistingCustomer ? (
                <Text>
                  Please ensure that all sections have been completed, then
                  submit for a final review. As a loyal MainStreet customer, we
                  are offering you any non-R&D credits for free!
                </Text>
              ) : (
                <Text>
                  Please ensure that all sections have been completed, then
                  continue to payment. You may opt out of any credit(s) before
                  finalizing.
                </Text>
              )}
              <Flex className={classes.creditList} direction='column'>
                {/* Connect Payroll is not included in the assessments, so we must manually add it here */}
                <AssessmentStatusCard
                  assessment={connectPayrollAssessment}
                  taxYear={taxYear}
                  key={connectPayrollAssessment.id}
                />
                {assessments.map((assessment) => (
                  <AssessmentStatusCard
                    assessment={assessment}
                    taxYear={taxYear}
                    key={assessment.id}
                  />
                ))}
              </Flex>
              <Flex justifyContent='end'>
                <Button
                  label={
                    shouldShowPaymentsPage
                      ? 'Continue to payment'
                      : 'Finalize assessment'
                  }
                  disabled={disableContinue}
                  onClick={handleOnClick}
                />
              </Flex>
            </Animate>
          </Flex>
        ) : (
          <Flex
            className={classes.mainContent}
            direction='column'
            alignItems={'center'}
          >
            <Spinner size='default' />
          </Flex>
        )}
      </Flex>
    );
  },
);
