import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Color } from 'component-library';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(() => ({
  root: {
    color: Color.semantic.$success50,
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 500,
  },
  outline: {
    color: Color.semantic.$success50,
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 500,
    border: `1px solid ${Color.semantic.$success50}`,
    padding: '12px 20px',
    borderRadius: '4px',
  },
  icon: {
    marginRight: '10px',
  },
}));

export interface CardSuccessMessageProps {
  message: string;
  outline?: boolean;
}

export const CardSuccessMessage = ({
  message,
  outline,
}: CardSuccessMessageProps) => {
  const classes = useStyles();

  return (
    <div className={outline ? classes.outline : classes.root}>
      <CheckIcon className={classes.icon} />
      {message}
    </div>
  );
};
