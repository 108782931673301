import { BaseStore } from '../BaseStore';
import { RootStore } from '../RootStore';
import { makeSubclassObservable } from '../../lib/mobx-utils';
import { EmploymentRecordWithWorkDoneData } from '../../pages/tax-processing/expense-classification/steps/Employees';
import { CompanyAccessToken } from 'lib/constants';

export class WorkDoneStore extends BaseStore {
  public employmentRecords: EmploymentRecordWithWorkDoneData[] | undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  /**
   * Backfills and missing WorkDone records for the given tax year, then
   * prefills the WorkDone.percentage when appropriate.
   * @param taxYear
   * @param accessToken
   */
  public async backfillAndPrefill(
    taxYear: number,
    accessToken?: CompanyAccessToken,
  ) {
    try {
      const prefillWorkDone = accessToken
        ? this.client.PrefillWorkDonePublic(accessToken, taxYear)
        : this.client.PrefillWorkDone(taxYear);

      const res = await prefillWorkDone;

      this.employmentRecords =
        res?.data?.employmentRecords || this.employmentRecords;

      return this.employmentRecords;
    } catch (error) {
      throw new Error(error);
    }
  }
}
