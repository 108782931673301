import React from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Color } from 'component-library';
import { useDashboardStores } from 'stores/useStores';

export const easeInOutQuart = 'cubic-bezier(0.76, 0, 0.24, 1)';
const useStyles = makeStyles(() => ({
  banner: (activeIndex: number) => ({
    backgroundImage: `url(${
      process.env.PUBLIC_URL
    }/images/dashboard/educational-module/banner-gradient-${
      activeIndex % 2 === 0 ? '1' : '2'
    }.png)`,
    height: 'clamp(150px, 20vw, 220px)',
    width: '100%',
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'background-image .6s',
  }),
  bannerInner: {
    height: '100%',
    position: 'relative',
    overflow: 'hidden',

    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '1px',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      backgroundImage: `linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)`,
    },
  },
  bannerSection: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: `all .6s ${easeInOutQuart}`,
  },
  sectionIcon: {
    backgroundColor: Color.neutral.white,
    width: 'clamp(74px, 8vw, 94px)',
    height: 'clamp(74px, 8vw, 94px)',
    display: 'flex',
    alignItems: 'center',
    padding: '18px',
    borderRadius: '47px',
    position: 'relative',
    transform: 'scale(1)',
    transition: `transform .6s ${easeInOutQuart}`,

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: Color.neutral._light_20,
    },

    '&::before': {
      width: 'clamp(94px, 11vw, 124px)',
      height: 'clamp(94px, 11vw, 124px)',
      borderRadius: '62px',
      opacity: 0.5,
    },

    '&::after': {
      width: 'clamp(114px, 13vw, 154px)',
      height: 'clamp(114px, 13vw, 154px)',
      borderRadius: '92px',
      opacity: 0.25,
    },

    '& > img': {
      width: '100%',
      position: 'relative',
      opacity: 0,
      zIndex: 3,
      transition: 'opacity .4s',
    },
  },
  activeStep: {
    transform: 'translateX(0)',
    opacity: 1,

    '& img': {
      opacity: 1,
    },
  },
  nextStep: {
    transform: 'translateX(50%)',
    opacity: 0.5,
    zIndex: 5,

    '& > div': {
      transform: 'scale(.6)',
    },
  },
  previousStep: {
    transform: 'translateX(-50%)',
    opacity: 0.5,
    zIndex: 5,
    cursor: 'pointer',
    width: '94px',

    '& > div': {
      transform: 'scale(.6)',
    },

    '&:hover img': {
      opacity: 0.75,
    },
  },
  fadeOutStep: {
    transform: 'translateX(-100%)',
    opacity: 0,

    '& > div': {
      transform: 'scale(.4)',
    },
  },
  fadeInStep: {
    transform: 'translateX(100%)',
    opacity: 0,

    '& > div': {
      transform: 'scale(.4)',
    },
  },
  dots: (activeIndex: number) => ({
    position: 'absolute',
    display: 'flex',
    gap: '8px',
    bottom: '-32px',
    left: '50%',
    transform: 'translateX(-50%)',
    transition: 'opacity .4s',
    opacity: activeIndex === 0 ? 0 : 1,
  }),
  dot: {
    width: '8px',
    height: '8px',
    backgroundColor: Color.neutral._light_60,
    borderRadius: '4px',
    padding: 0,
  },
  dotActive: {
    backgroundColor: Color.neutral._60,
  },
}));

const sectionIcons = [
  `${process.env.PUBLIC_URL}/images/dashboard/educational-module/icons/logo.svg`,
  `${process.env.PUBLIC_URL}/images/dashboard/educational-module/icons/qualify.svg`,
  `${process.env.PUBLIC_URL}/images/dashboard/educational-module/icons/rd-assessment.svg`,
  `${process.env.PUBLIC_URL}/images/dashboard/educational-module/icons/review.svg`,
  `${process.env.PUBLIC_URL}/images/dashboard/educational-module/icons/file-forms.svg`,
  `${process.env.PUBLIC_URL}/images/dashboard/educational-module/icons/use-credits.svg`,
];

export const BannerCarousel: React.FC = observer(() => {
  const { modules } = useDashboardStores();
  const activeIndex = modules.educationalCurrentIndex;
  const classes = useStyles(activeIndex);

  return (
    <div className={classes.banner}>
      <div className={classes.bannerInner}>
        {sectionIcons.map((section, index) => {
          const renderSection = () => {
            return (
              <div className={classes.sectionIcon}>
                <img src={section} />
              </div>
            );
          };

          const switchClassName = () => {
            switch (activeIndex) {
              case index + 2:
                return `${classes.bannerSection} ${classes.fadeOutStep}`;
              case index - 2:
                return `${classes.bannerSection} ${classes.fadeInStep}`;
              case index:
                return `${classes.bannerSection} ${classes.activeStep}`;
              case index - 1:
                return `${classes.bannerSection} ${classes.nextStep}`;
              case index + 1:
                return `${classes.bannerSection} ${classes.previousStep}`;
            }
          };

          if (switchClassName()) {
            return (
              <div
                key={index}
                className={switchClassName()}
                onClick={
                  index + 1
                    ? () => modules.setEducationalIndex(index)
                    : undefined
                }
              >
                {renderSection()}
              </div>
            );
          }
        })}
      </div>
      <div className={classes.dots}>
        {sectionIcons.map((i, index) => (
          <div
            key={index}
            className={`${classes.dot} ${
              activeIndex === index ? classes.dotActive : ''
            }`}
          />
        ))}
      </div>
    </div>
  );
});
