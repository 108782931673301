import { MSClientResponse } from 'lib/interfaces';
import { BankingInstitution } from '@mainstreet/client-models/financial/banking/bankingModels';
import { BillingDefaultPaymentMethod } from '@mainstreet/client-models/financial/payments/paymentsModels';
import { z } from 'zod';

// PAYMENT CLIENT TYPES //
export interface IPaymentsClient {
  getDefaultPaymentMethod: () => Promise<
    MSClientResponse<BillingDefaultPaymentMethod>
  >;
  setDefaultPaymentMethod: (
    connectedAccountId: string | null,
    ccPaymentMethodId: string | null,
  ) => Promise<MSClientResponse<BillingDefaultPaymentMethod>>;
}

// PLAID CLIENT TYPES
export interface IPlaidClient {
  exchangePublicToken: (
    publicToken: string,
    companyId: number,
  ) => Promise<MSClientResponse<void>>;
  generatePlaidLinkToken: (
    companyId: number,
  ) => Promise<MSClientResponse<LinkToken>>;
  generatePlaidLinkTokenForExistingCustomer: (
    companyId: number,
  ) => Promise<MSClientResponse<LinkToken>>;
  getConnectedInstitutions: (
    includeBalances?: boolean,
    includePermissionRevokedInstitutions?: boolean,
  ) => Promise<MSClientResponse<BankingInstitution[]>>;
  disconnectInstitution: () => Promise<MSClientResponse<void>>;
}

// PLAID INTERFACE TYPES //
export interface LinkToken {
  linkToken: string;
}

// CHARGEBEE TYPES //
export interface ChargebeeInstance {
  setPortalSession: (setter: () => Promise<PortalSession>) => void;
  createChargebeePortal: () => ChargebeePortalInstance;
  logout: () => void;
  closeAll: () => void;
  setPortalCallbacks: (callbacks: SessionCallbacks) => void;
}

export interface ChargebeePortalInstance {
  openSection: (options: SessionOptions, callbacks?: SessionCallbacks) => void;
  open: (options?: OpenOptions) => void;
}

export interface PortalSession {
  id: string;
  token: string;
  access_url: string;
  redirect_url?: string;
  status: string;
  created_at: number;
  expires_at?: number;
  customer_id: string;
  login_at?: number;
  logout_at?: number;
  login_ipaddress?: string;
  logout_ipaddress?: string;
  linked_customers?: LinkedCustomer[];
}

export interface LinkedCustomer {
  customer_id: string;
  email?: string;
  has_billing_address: boolean;
  has_payment_method: boolean;
  has_active_subscription: boolean;
}

export enum SectionType {
  SubscriptionDetails = 'SUBSCRIPTION_DETAILS',
  PaymentSources = 'PAYMENT_SOURCES',
  BillingHistory = 'BILLING_HISTORY',
  AccountDetails = 'ACCOUNT_DETAILS',
}

export interface SessionOptions {
  sectionType: string;
  params?: {
    subscriptionId: string;
  };
}

export interface OpenOptions {
  close: () => void;
}

export interface SessionCallbacks {
  loaded?: () => void;
  close?: () => void;
  visit?: (sectionType: SectionType) => void;
  paymentSourceAdd?: () => void;
  paymentSourceUpdate?: () => void;
  paymentSourceRemove?: () => void;
  subscriptionChanged?: (data: SubscriptionArgs) => void;
  subscriptionCustomFields?: (data: SubscriptionArgs) => void;
  subscriptionCancelled?: (data: SubscriptionArgs) => void;
  subscriptionResumed?: (data: SubscriptionArgs) => void;
  subscriptionPaused?: (data: SubscriptionArgs) => void;
  subscriptionPauseRemoved?: (data: SubscriptionArgs) => void;
  subscriptionReactivated?: (data: SubscriptionArgs) => void;
}

export interface SubscriptionArgs {
  subscription: { id: string };
}

export interface GetLucaCustomerResponse {
  lucaCustomer: LucaCustomer;
}
export interface LucaCustomer {
  externalId: string;
  internalId: number;
}

export interface PaymentsEnvironment {
  site: string;
  publicKey: string;
}

/**
 * @deprecated
 * Client version of a ChargeBee Subscriptions
 */
type SubscriptionStatus =
  | 'future'
  | 'in_trial'
  | 'active'
  | 'non_renewing'
  | 'paused'
  | 'cancelled';

/**
 * @deprecated
 */
type ProductSubscriptionLineItemType = 'plan' | 'addon' | 'charge';

/**
 * @deprecated
 */
interface ProductSubscriptionLineItem {
  amount: number | undefined;
  billingCycles: number | undefined;
  itemType: ProductSubscriptionLineItemType;
  itemPriceId: string; // Possibly Invoice ID?
}

/**
 * @deprecated
 */
export interface ProductSubscription {
  startedAt: number | null; // When subscription starts, null if future subscription
  status: SubscriptionStatus;
  subscriptionItems: ProductSubscriptionLineItem[];
  taxYear?: number;
  latestTaxCreditType?: string;
}

export const getSubscriptionsResponseSchema = z.object({
  data: z.array(
    z.object({
      startedAt: z.number().nullable(),
      status: z.string(),
      taxYear: z.number().optional(),
      latestTaxCreditType: z.string().optional(),
      subscriptionItems: z.array(
        z.object({
          amount: z.number().optional(),
          billingCycles: z.number().optional(),
          itemType: z.string(),
          itemPriceId: z.string(),
        }),
      ),
    }),
  ),
  errorMsg: z.string().optional(),
});
