import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  gradientBorder: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/dashboard/savings-module/savings-module-gradient-border.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    filter: 'blur(5px)',
    height: 'calc(100% + 10px)',
    left: '-5px',
    pointerEvents: 'none',
    position: 'absolute',
    top: '-5px',
    width: 'calc(100% + 10px)',
    zIndex: 1,
  },
}));

export const GradientBorder: React.FC = () => {
  const classes = useStyles();
  return <div className={classes.gradientBorder} />;
};
