import React from 'react';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { CompanyData } from 'lib/interfaces';
import { observer } from 'mobx-react';
import { CompanyContext } from 'pages/CompanyRequired';
import {
  useCommonStores,
  useCompany,
  useFeatureFlags,
  useLegacyClients,
} from 'stores/useStores';
import { FeatureFlagProviderContext } from '@mainstreet/feature-flags-react';
import { FeatureFlagNameEnum } from 'lib/constants/featureFlagConstants';

export const LegacyContexts: React.FC = observer(({ children }) => {
  const { client, plaidClient, paymentsClient } = useLegacyClients();
  const { company } = useCompany();
  const { featureFlags } = useCommonStores();
  const flags = useFeatureFlags();

  return (
    <Auth0FeatureContext.Provider
      value={{
        client: client,
        plaidClient: plaidClient,
        paymentsClient: paymentsClient,
      }}
    >
      <CompanyContext.Provider
        value={{
          company: company.rawData as CompanyData,
          setCompany: (companyData) => company.mergeData(companyData || null),
        }}
      >
        <FeatureFlagProviderContext.Provider
          value={{
            getFlag:
              featureFlags.isReady && featureFlags.isReadyCounter > 0
                ? (key) => flags.flags[key as FeatureFlagNameEnum]
                : undefined,
            state: featureFlags.isReady ? 'READY' : 'LOADING',
          }}
        >
          {children}
        </FeatureFlagProviderContext.Provider>
      </CompanyContext.Provider>
    </Auth0FeatureContext.Provider>
  );
});
