import React from 'react';
import { LoadingPage } from './LoadingPage';
import { useCommonStores } from 'stores/useStores';
import { useEffectOnce } from 'lib/helpers';
import { observer } from 'mobx-react';

export const LogoutPage = observer(() => {
  const { auth } = useCommonStores();

  useEffectOnce(() => auth.fullLogout());

  return <LoadingPage />;
});
