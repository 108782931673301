import { makeStyles } from '@material-ui/core';
import {
  Color,
  Flex,
  StepCardStep,
  Toast,
  Text,
  Button,
  SideDrawer,
  Checkbox,
  Modal,
  Message,
} from 'component-library';
import {
  AttestationEventTypeEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
} from 'lib/constants';
import { IsValidDate } from 'lib/helpers';
import { observer } from 'mobx-react';
import { CompanyContext } from 'pages/CompanyRequired';
import { IsValidEinNumber } from 'pages/kyc/components/helpers';
import { SaveSurveyAttestation } from 'pages/tax-processing/expense-classification/components/expense-classification/SurveyAttestation';
import React, { useContext, useState } from 'react';
import {
  useCommonStores,
  useLegacyClients,
  useTaxCreditsStores,
} from '../../../../../stores/useStores';
import { ClientReviewStepFAQ } from '../../sideDrawer/ClientReviewStepFAQ';
import { Step, StepProps } from '../../TaxCreditProgramSteps';
import { ClientReviewDownloadSideDrawer } from './ClientReviewDownloadSideDrawer';
import { ClientReviewSubStep } from './ClientReviewSubStep';
import { ClientReviewUploadSideDrawer } from './ClientReviewUploadSideDrawer';

const useStyles = makeStyles(() => ({
  ecStepsContainer: {
    border: '1px solid',
    borderColor: Color.neutral._20,
    borderRadius: '8px',
  },
}));

export const stepConfig: Step = {
  name: 'client_review',
  number: 3,
  title: 'File your forms with the IRS',
  dataTestId: 'client-review',
  faq: <ClientReviewStepFAQ />,
};

export const ClientReviewStepNew = observer(
  ({ federalRDProgram }: StepProps) => {
    const classes = useStyles();
    const { client } = useLegacyClients();
    const { auth } = useCommonStores();
    const { company } = useContext(CompanyContext);
    const { taxCreditsPage, form8974 } = useTaxCreditsStores();

    const [toast, setToast] = useState<Message[]>([]);
    const [subStep, setSubStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [downloadConfirmLoading, setDownloadConfirmLoading] = useState(false);
    const [downloadConfirmed, setDownloadConfirmed] = useState(false);
    const [downloadSideDrawer, setDownloadSideDrawer] = useState(false);

    const [uploadSideDrawer, setUploadSideDrawer] = useState(false);
    const [confirmCloseUpload, setConfirmCloseUpload] = useState(false);
    const taxFilingDate = new Date(
      form8974.updatedTaxFilingDate ?? federalRDProgram.taxFilingDate ?? '',
    );
    const currentDate = new Date();

    const filingDateError =
      !IsValidDate(taxFilingDate) ||
      !taxFilingDate ||
      ![federalRDProgram.taxYear + 1, federalRDProgram.taxYear + 2].includes(
        taxFilingDate.getFullYear(),
      ) ||
      taxFilingDate > currentDate;

    const disableUploadSave =
      filingDateError ||
      !IsValidEinNumber(form8974.updatedCompanyTaxId || company.taxId || '') ||
      !form8974.filesExistInUploadQueue;

    const getStepStatus = (step: number) =>
      subStep === step ? 'active' : subStep < step ? 'upcoming' : 'completed';

    const handleFormsDownloaded = async () => {
      setDownloadConfirmLoading(true);

      // Create survey attestation and update tax filing date in parallel
      await Promise.allSettled([
        SaveSurveyAttestation({
          hasConfirmedAnswers: true,
          eventType:
            AttestationEventTypeEnum.CLIENT_REVIEW_DOCUMENTS_DOWNLOADED,
          userEmail: auth.user?.email || company?.adminEmail,
          taxYear: federalRDProgram.taxYear,
          companyId: company.id,
          client,
        }),
        form8974.updateProgramTaxFilingDate(federalRDProgram.id),
      ]);

      setSubStep(2);
      setDownloadSideDrawer(false);
      setDownloadConfirmLoading(false);
    };

    const handleFormUpload = async () => {
      await form8974.uploadTaxDocsFor8974(federalRDProgram.id);

      if (!form8974.requestError && form8974.taxInfoSubmitted) {
        setUploadSideDrawer(false);
        setSubStep(3);
      } else {
        setToast([
          {
            text: 'There was an error while uploading your documents. Please try again.',
            status: 'error',
          },
        ]);
      }
    };

    return (
      <StepCardStep
        active={stepConfig.name === federalRDProgram.stage}
        dataTestId={stepConfig.dataTestId}
        learnMoreOnClick={() =>
          taxCreditsPage.setShowSideDrawer(true, stepConfig.name)
        }
        stepNumber={stepConfig.number}
        title={stepConfig.title}
      >
        <>
          <Text
            paddingBottom={8}
            text='Download your R&D credit forms, file them with your income tax return, then provide MainStreet with your complete tax return.'
          />
          <Flex
            direction='column'
            padding={[16, 24]}
            className={classes.ecStepsContainer}
          >
            <ClientReviewSubStep
              title='Download R&D credit forms'
              text='Verify that you’ve downloaded your final credit forms.'
              status={getStepStatus(1)}
              onComplete={() => setDownloadSideDrawer(true)}
              dataTestId={'cr-download'}
            />
            <SideDrawer
              title='Download R&D credit forms'
              show={downloadSideDrawer}
              closeToggle={() => setDownloadSideDrawer(false)}
              drawerContent={
                <ClientReviewDownloadSideDrawer
                  federalRDProgram={federalRDProgram}
                />
              }
              drawerFooterContent={
                <Checkbox
                  options={[
                    {
                      checked: downloadConfirmed,
                      text: `I hereby confirm that ${company.name} will file these documents with the relevant tax authorities, alongside our business income tax return(s).`,
                      value: 'checked',
                    },
                  ]}
                  onChange={() => setDownloadConfirmed(!downloadConfirmed)}
                  dataTestId={'download-drawer-checkbox'}
                />
              }
              drawerActions={
                <Button
                  label='Continue'
                  disabled={!downloadConfirmed}
                  loading={downloadConfirmLoading}
                  onClick={handleFormsDownloaded}
                  dataTestId={'download-drawer-continue-button'}
                />
              }
            />

            <ClientReviewSubStep
              title='Upload tax return and confirm filing details'
              text='Provide your complete income tax return and filing date.'
              status={getStepStatus(2)}
              dataTestId={'cr-upload'}
              onComplete={() => setUploadSideDrawer(true)}
              callout='Ensure that your business income tax return includes your final credit forms.'
            />
            <SideDrawer
              title='Upload tax return'
              show={uploadSideDrawer}
              closeToggle={() => setConfirmCloseUpload(true)}
              drawerContent={
                <ClientReviewUploadSideDrawer
                  federalRDProgram={federalRDProgram}
                  filingDateError={filingDateError}
                />
              }
              drawerActions={
                <Button
                  label='Save'
                  disabled={disableUploadSave}
                  loading={form8974.taxInfoSubmitting}
                  onClick={handleFormUpload}
                  dataTestId={'upload-drawer-save-button'}
                />
              }
            />
            <Modal
              showModal={confirmCloseUpload}
              closeToggle={() => setConfirmCloseUpload(false)}
              maxWidth={470}
            >
              <Flex direction='column' padding={24} gap={8}>
                <Text size={18} variant='medium' text='Unsaved changes' />
                <Text
                  size={15}
                  text='Any changes made within this section will be lost'
                  paddingBottom={16}
                />
                <Flex justifyContent='end'>
                  <Flex justifyContent='end' padding={[0, 8]}>
                    <Button
                      small={true}
                      label='Cancel'
                      onClick={() => setConfirmCloseUpload(false)}
                      dataTestId={'modal-confirm-cancel-button'}
                      variant='outlined'
                    />
                  </Flex>
                  <Button
                    small={true}
                    label='Discard changes'
                    onClick={() => {
                      setConfirmCloseUpload(false);
                      setUploadSideDrawer(false);

                      // These only reset the "to-be-updated" form values
                      form8974.setTaxFilingDate('');
                      form8974.setCompanyTaxId('');
                      console.log(form8974.updatedTaxFilingDate);
                    }}
                    dataTestId={'modal-cancel-button'}
                  />
                </Flex>
              </Flex>
            </Modal>
          </Flex>
          {getStepStatus(3) === 'active' && (
            <Flex direction='row' padding={[4, 0]}>
              <Button
                label='Continue'
                onClick={async () => {
                  setLoading(true);
                  await form8974.updateProgramStage(
                    federalRDProgram.id,
                    ProgramStageEnum.FINISHED,
                    ProgramSubStageEnum.READY_TO_REDEEM,
                  );
                  setLoading(false);
                }}
                small
                dataTestId='client-review-continue'
                loading={loading}
              />
            </Flex>
          )}
        </>
        <Toast toasts={toast} setToast={setToast} />
      </StepCardStep>
    );
  },
);
