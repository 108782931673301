import React from 'react';
import { Text, Card, Heading } from 'component-library';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { CentsToDisplayStringNoSymbol } from 'lib/helpers';

const useStyles = makeStyles(({ emerald }: Theme) => ({
  root: {
    width: 240,
  },
  guaranteeBox: {
    background: emerald,
    padding: '24px 32px',
  },
  whiteText: {
    color: '#FFFFFF',
    paddingBottom: '0px',
  },
  guaranteeDescription: {
    color: '#102736',
    padding: 30,
  },
}));

interface GuaranteedNetBenefitProps {
  guaranteedAmountCents: number;
  className?: string;
}

export const GuaranteedNetBenefit = ({
  guaranteedAmountCents,
  className,
}: GuaranteedNetBenefitProps) => {
  const classes = useStyles();

  return (
    <Card className={`${className ?? ''} ${className ? classes.root : ''}`}>
      <Box className={classes.guaranteeBox}>
        <Heading
          tag='h2'
          text='Your guaranteed net benefit'
          variant='italic'
          className={classes.whiteText}
        />
        <Box display={'flex'}>
          <Heading
            tag='h2'
            text='$ '
            variant='italic'
            className={classes.whiteText}
          />
          <Text
            size={32}
            tag='p'
            variant='medium'
            className={classes.whiteText}
          >
            {` ${CentsToDisplayStringNoSymbol(guaranteedAmountCents)}*`}
          </Text>
        </Box>
      </Box>
      <Text className={classes.guaranteeDescription}>
        *Guaranteed based on meeting the contingency requirements for your
        selected programs. At the end of your subscription period, we&apos;ll
        calculate your savings and if we find you haven&apos;t met our
        guaranteed minimum savings we&apos;ll pay you out the remainder{' '}
        <i>
          (savings guarantee subject to change for renewals and only applies to
          orders signed before Sept 30).
        </i>
      </Text>
    </Card>
  );
};

export default GuaranteedNetBenefit;
