import React from 'react';
import { observer } from 'mobx-react';
import { Color, Flex, Heading, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';

export interface ResourceCardProps {
  priority: number;
  link: string;
  image: string;
  category: string;
  title: string;
  subtext: string;
  dataTestId?: string;
}

const DRAWER_TRANSITION_DURATION = '0.5s';
const MORE_INFO_TRANSITION_DURATION = '0.25s';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    width: '312px',
    height: '247px',
    borderRadius: '4px',
    boxShadow: Color.shadow.high,
    '&:hover': {
      '& $resourceInfo': {
        transition: `height ${DRAWER_TRANSITION_DURATION}`,
        height: '190px',
      },
      '& $moreInfo': {
        transitionDelay: DRAWER_TRANSITION_DURATION,
        transition: `opacity ${MORE_INFO_TRANSITION_DURATION} ${DRAWER_TRANSITION_DURATION}`,
        opacity: 1,
      },
    },
  },
  gradient: {
    position: 'absolute',
    backgroundImage: (props: { image: string }) => `url("${props.image}")`,
    backgroundSize: 'cover',
    height: '135px',
    borderRadius: '4px',
    width: '312px',
    top: '0px',
  },
  resourceInfo: {
    transition: `height ${DRAWER_TRANSITION_DURATION} 0.3s`, // hover off
    height: '122px',
    position: 'relative',
    backgroundColor: Color.neutral.white,
    borderRadius: '4px',
    padding: '16px 24px',
  },
  categoryAndTitle: {
    overflow: 'visible',
  },
  clamp: {
    lineClamp: 2,
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflow: 'hidden',
  },
  moreInfo: {
    opacity: 0,
    transition: `opacity ${MORE_INFO_TRANSITION_DURATION}`, // hover off
    gap: '4px',
  },
  link: {
    textDecoration: 'none',
  },
  readText: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const ResourceCard: React.FC<ResourceCardProps> = observer(
  ({
    link,
    image,
    category,
    title,
    subtext,
    dataTestId = 'resource-card',
  }: ResourceCardProps) => {
    const classes = useStyles({ image });

    return (
      <a
        href={link}
        target='_blank'
        rel='noreferrer noopener'
        className={classes.link}
      >
        <Flex
          direction='column'
          className={classes.root}
          dataTestId={`${dataTestId}-root`}
          justifyContent='flex-end'
        >
          <div className={classes.gradient} />
          <div className={classes.resourceInfo}>
            <div className={classes.categoryAndTitle}>
              <Text size={13} paddingBottom={4}>
                {category}
              </Text>
              <Heading
                size={18}
                className={classes.clamp}
                marginBottom={8}
                dataTestId={`${dataTestId}-title`}
              >
                {title}
              </Heading>
            </div>
            <Flex
              direction='column'
              className={classes.moreInfo}
              dataTestId={`${dataTestId}-more-info`}
            >
              <Text
                size={13}
                className={classes.clamp}
                dataTestId={`${dataTestId}-subtext`}
              >
                {subtext}
              </Text>
              <Text
                color={Color.blue._70}
                text='Read'
                className={classes.readText}
              />
            </Flex>
          </div>
        </Flex>
      </a>
    );
  },
);

export default ResourceCard;
