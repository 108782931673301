import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useCommonStores, useTaxCreditsStores } from 'stores/useStores';
import { MSPlaidClientProvider } from 'lib/financial';
import PaymentMethod from 'components/PaymentMethod';
import { BillingDefaultPaymentMethod } from '@mainstreet/client-models/financial/payments/paymentsModels';

interface AddPaymentMethodProps {
  showBankNotice?: boolean;
  setPaymentMethod?: (value: boolean) => void;
  isModal?: boolean;
}

export const AddPaymentMethod: React.FC<AddPaymentMethodProps> = observer(
  ({ showBankNotice = true, setPaymentMethod, isModal }) => {
    const { app, companyStore } = useCommonStores();
    const company = companyStore.currentCompany;
    const { orderForms } = useTaxCreditsStores();

    const paymentMethodOnChangeCB = useCallback(
      async (
        defaultPaymentMethod: BillingDefaultPaymentMethod | null | undefined,
      ) => {
        orderForms.setDefaultPaymentMethodV2(
          !!(
            defaultPaymentMethod?.associatedAccount ||
            defaultPaymentMethod?.associatedCard
          ),
        );
        if (setPaymentMethod) {
          setPaymentMethod(
            !!(
              defaultPaymentMethod?.associatedAccount ||
              defaultPaymentMethod?.associatedCard
            ),
          );
        }
      },
      [orderForms],
    );

    return (
      <MSPlaidClientProvider
        includeBalances={false}
        companyId={company.id}
        isModal={isModal}
      >
        <PaymentMethod
          companyId={company.id}
          stripeCustomerId={company.stripeCustomerId}
          onChange={paymentMethodOnChangeCB}
          showBankNotice={showBankNotice}
        />
      </MSPlaidClientProvider>
    );
  },
);
