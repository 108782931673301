import React from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Flex, ToDoCard, ToDoAlertTypeEnum } from 'component-library';
import { useDashboardStores, useCommonStores } from 'stores/useStores';
import { ToDoCardEntity } from 'products/dashboard/entities/ToDoCardEntity';

const useStyles = makeStyles(() => ({
  showAllCard: {
    minHeight: 'auto',
  },
}));

export interface ToDoCardsContainerProps {
  displayNumberOfToDos: number;
  showAll?: boolean;
}

export const ToDoCardsContainer: React.FC<ToDoCardsContainerProps> = observer(
  ({ displayNumberOfToDos, showAll }) => {
    const classes = useStyles();
    const { app } = useCommonStores();
    const { modules } = useDashboardStores();
    const cardFlexBasis = app.isDesktop ? '50%' : '33.33%';

    const getCardBackground = (type: string) => {
      switch (type) {
        case ToDoAlertTypeEnum.SUCCESS:
          return `${process.env.PUBLIC_URL}/images/dashboard/todo-cards/success.svg`;
        case ToDoAlertTypeEnum.PAST_DUE:
          return `${process.env.PUBLIC_URL}/images/dashboard/todo-cards/past-due.svg`;
        case ToDoAlertTypeEnum.TIME_SENSITIVE:
          return `${process.env.PUBLIC_URL}/images/dashboard/todo-cards/time-sensitive.svg`;
      }
    };

    return (
      <Flex
        gap={24}
        justifyContent={app.isDesktop ? 'space-between' : 'flex-start'}
        direction={showAll ? 'column' : 'row'}
      >
        {modules.toDoCards.slice(0, displayNumberOfToDos).map((card, index) => {
          const {
            alertTypes,
            cardTypeId,
            description,
            label,
            primaryCtaLink,
            primaryCtaText,
            secondaryCtaLink,
            secondaryCtaText,
            secondaryCtaAsDismissible,
            title,
            id,
          } = card as ToDoCardEntity;

          return (
            <ToDoCard
              key={`${index}-${cardTypeId}`}
              label={label}
              dataTestId={cardTypeId}
              title={title}
              description={description}
              primaryCtaText={primaryCtaText}
              primaryCtaLink={primaryCtaLink}
              secondaryCtaText={secondaryCtaText}
              secondaryCtaLink={secondaryCtaLink}
              secondaryCtaAsDismissible={secondaryCtaAsDismissible}
              setDismiss={() => {
                modules.assignDismissId(id);
                modules.toggleDismissModal(true);
              }}
              priority={index + 1}
              alertType={alertTypes}
              backgroundImagePath={getCardBackground(alertTypes)}
              flexBasis={cardFlexBasis}
              className={showAll ? classes.showAllCard : ''}
            />
          );
        })}
      </Flex>
    );
  },
);
