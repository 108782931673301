import { Card } from 'component-library';
import {
  makeStyles,
  TextField,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';
import { QuestionProps } from '../lib/interfaces';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const useStyles = makeStyles(({ breakpoints, copper }: Theme) => ({
  cardTitle: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  errorCardTitle: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: copper,
  },
  errorText: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: copper,
    marginBottom: '20px',
  },
  cardDetails: {
    margin: '14px 0',
    fontSize: '14px',
    fontWeight: 400,
  },
  questionCard: {
    minHeight: '180px',
    flexDirection: 'column',
    padding: '30px',
  },
  questionCardDescription: {
    fontSize: '16px',
  },
  textField: {
    width: '80%',
    margin: '20px 0',
    [breakpoints.down('sm')]: {
      marginBottom: '20px',
      marginTop: '30px',
    },
    '& textarea': {
      height: '5px',
      fontSize: '14px',
    },
  },
}));

const StyledToggleButtonGroup = withStyles(() => ({
  grouped: {
    width: 100,
    marginRight: 40,
    textTransform: 'none',
  },
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles((theme) => ({
  root: {
    width: '130px',
    height: '44px',
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 500,
    '&:not(:first-child)': {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 0,
      color: `${theme.palette.secondary.main}`,
    },
    '&:first-child': {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 0,
      color: `${theme.palette.secondary.main}`,
    },
    '&:hover': {
      backgroundColor: '#CFD4D7',
    },
  },
  selected: {
    border: 'none !important',
    color: 'white!important',
    backgroundColor: '#102736!important',
  },
}))(ToggleButton);

interface SurveyQuestionsProps {
  questionBank: QuestionProps[];
  setAnswer: (newValue: string, id: number) => void;
}

export const SurveyQuestions = ({
  questionBank,
  setAnswer,
}: SurveyQuestionsProps) => {
  const classes = useStyles();

  return (
    <>
      {questionBank.map((question) => {
        return (
          <Card key={question.id} className={classes.questionCard}>
            {question.errorText && (
              <Typography variant='h2' className={classes.errorText}>
                {question.errorText}
              </Typography>
            )}
            <Typography
              variant='h2'
              className={
                question.errorText ? classes.errorCardTitle : classes.cardTitle
              }
            >
              {question.text}
            </Typography>
            <Typography variant='h3' className={classes.cardDetails}>
              {question.subtitle}
            </Typography>
            {question.type === 'text_area' && (
              <TextField
                variant='outlined'
                className={classes.textField}
                label={question.label}
                value={question.value}
                onChange={(event) => {
                  setAnswer(event.target.value, question.id);
                }}
              />
            )}
            {question.type === 'yes_or_no' &&
              question.cmsAnswerData?.length === 2 && (
                <StyledToggleButtonGroup
                  exclusive
                  value={question.value}
                  onChange={(event, newValue) => {
                    if (newValue != null) {
                      setAnswer(newValue, question.id);
                    }
                  }}
                >
                  <StyledToggleButton value={question.cmsAnswerData[0].id}>
                    {question.cmsAnswerData[0].text}
                  </StyledToggleButton>
                  <StyledToggleButton value={question.cmsAnswerData[1].id}>
                    {question.cmsAnswerData[1].text}
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
              )}
          </Card>
        );
      })}
    </>
  );
};
