import React from 'react';
import { Gap, Padding, Spacing } from '../_helpers/constants';
import Spinner from '../spinner/spinner';
import _styles from './button.module.scss';

import classnames from 'classnames/bind';
const styles = classnames.bind(_styles);

/* eslint-disable-next-line */
export interface ButtonProps {
  className?: string;
  label: string | JSX.Element;
  dataTestId: string;
  padding?: Padding;
  fontSize?: Spacing;
  style?: React.CSSProperties;
  variant?:
    | 'contained'
    | 'outlined'
    | 'tertiary'
    | 'tertiary-dark'
    | 'outlined-dark';
  flexAlignSelf?: 'flex-start' | 'center' | 'flex-end';
  rounded?: boolean;
  lime?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
}

const formatToCSS = (value: Padding | Gap) => {
  return Array.isArray(value)
    ? value.map((val) => `${val}px`).join(' ')
    : `${value}px`;
};

export function Button({
  className,
  label,
  dataTestId,
  padding = [12, 24],
  fontSize = 16,
  variant = 'contained',
  flexAlignSelf,
  rounded,
  lime,
  disabled,
  loading,
  onClick,
  style,
}: ButtonProps) {
  const composed_classname = classnames(
    styles('button', variant, { rounded, lime, loading }),
    className,
  );

  const composedStyles: React.CSSProperties = {
    fontSize: `${fontSize}px`,
    alignSelf: flexAlignSelf,
    ...style,
  };

  if (padding) {
    composedStyles.padding = formatToCSS(padding);
  }
  return (
    <button
      type='button'
      aria-label={`${dataTestId}-button`}
      data-test-id={`${dataTestId}-button-test-id`}
      className={composed_classname}
      style={composedStyles}
      onClick={onClick}
      tabIndex={0}
      disabled={!!disabled || !!loading}
    >
      {label}
      {loading && (
        <span>
          <Spinner />
        </span>
      )}
    </button>
  );
}

export default Button;
