import React, { useContext, useEffect, Dispatch, SetStateAction } from 'react';
import { MSPlaidClientContext } from 'lib/financial/MSPlaidClientProvider';
import { Text, Color, Divider, Toast } from 'component-library';
import { IPaymentMethodState, SelectionStatus } from 'components/PaymentMethod';
import ACHAccountDisplay from './ACHAccountDisplay';
import AccountSelect from './AccountSelect';
import ACHButtons from './ACHButtons';

interface IACHCardProps {
  selectionStatus: SelectionStatus;
  setPaymentMethodState: Dispatch<SetStateAction<IPaymentMethodState>>;
  companyId: number;
  setDefaultIsLoading: boolean;
}

export const ACHCard = (props: IACHCardProps) => {
  const { state, actions } = useContext(MSPlaidClientContext);

  const { setPaymentMethodState } = props;
  const { fetchConnectionDetails } = actions;
  const { companyId } = props;
  useEffect(() => {
    /**
     * Load all connected bank institutions and plaid accounts details and
     * populate them into `state`
     */
    fetchConnectionDetails(companyId);
  }, [fetchConnectionDetails, companyId]);

  useEffect(() => {
    /* If accounts are linked but none are set as default, show the account select
       dropdown instead of the Connect a bank account button. */
    if (
      !state.defaultPaymentMethod?.associatedAccount &&
      state.plaidAccounts.length > 0
    ) {
      setPaymentMethodState((prev) => ({
        ...prev,
        selectionStatus: 'selecting',
      }));
    }
  }, [
    state.defaultPaymentMethod,
    state.plaidAccounts.length,
    setPaymentMethodState,
  ]);

  // Get account from plaidAccounts once account ID is selected
  const selectedAccount =
    state.plaidAccounts.find(
      (account) => account.id === state.selectedAccountId,
    ) || null;

  // Get the default payment method account from plaid accounts if it is set.
  const defaultPaymentAccount =
    state.plaidAccounts.find(
      (acc) => acc.id === state.defaultPaymentMethod?.associatedAccount?.id,
    ) || null;

  // enable set as default button if account is verified
  const enableSaveButton =
    selectedAccount?.verificationStatus === 'automatically_verified' ||
    selectedAccount?.verificationStatus === 'manually_verified';

  return (
    <>
      <Text
        color={Color.neutral._80}
        paddingBottom={24}
        dataTestId='ach-subtitle-text'
      >
        {state.defaultPaymentMethod?.associatedAccount ||
        state.plaidAccounts.length > 0
          ? 'This account will be used for all billing purposes.'
          : 'MainStreet uses Plaid to safely connect to your banking institution and all associated accounts.'}
      </Text>

      {/* 
        Displays default payment method 
      */}
      {props.selectionStatus === 'default' && (
        <ACHAccountDisplay
          account={defaultPaymentAccount}
          isLoading={state.isLoading}
          open={actions.open}
        />
      )}

      {/* 
        Displays account select and triggers different verification flows
        depending on the selected account 
      */}
      {props.selectionStatus === 'selecting' && (
        <>
          <AccountSelect
            accounts={state.plaidAccounts}
            selectedAccount={selectedAccount}
            onAccountSelect={actions.handleAccountSelect}
            label='Please select a bank account'
            placeholder='Select account'
          />
          <Divider color='white' />
          <ACHButtons
            selectedAccount={selectedAccount}
            enableSaveButton={enableSaveButton}
            setPaymentMethodState={setPaymentMethodState}
            setDefaultIsLoading={props.setDefaultIsLoading}
          />
        </>
      )}

      {state.plaidError && (
        <Toast
          setToast={() => []}
          toasts={[{ text: state.plaidError, status: 'error' }]}
        />
      )}
    </>
  );
};
