import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Button, Color, Text } from 'component-library';
import DollarSign from '../../../../components/icons/DollarSign';
import { ProgramData } from '../../../../lib/interfaces';
import { ProgramNameEnum, ProgramStageEnum } from '../../../../lib/constants';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: spacing(5, 0, 5),
    border: '1px solid',
    borderColor: Color.neutral._20,
    backgroundColor: Color.neutral._light_20,
    padding: 24,
  },
  icon: {
    marginBottom: 16,
  },
}));

interface EmptyTaxProgramStepperProps {
  programs: ProgramData[];
}

export const EmptyTaxCreditsProgramStepper = ({
  programs,
}: EmptyTaxProgramStepperProps) => {
  const classes = useStyles();

  const federalRDPrograms = programs
    .filter((program) => program.name === ProgramNameEnum.FED_RD_TAX)
    .sort((a, b) => b.taxYear - a.taxYear);

  // We want to know if this company has a program for the current year and if
  // that program has already been disqualified.
  const latestFederalRDProgram = federalRDPrograms[0];
  const currentYear = new Date().getFullYear();
  const hasProgramForCurrentYear =
    latestFederalRDProgram?.taxYear === currentYear;
  const hasDisqualifiedProgramForCurrentYear =
    hasProgramForCurrentYear &&
    latestFederalRDProgram?.stage === ProgramStageEnum.DISQUALIFIED;

  return (
    <div className={classes.root}>
      <DollarSign className={classes.icon} />
      <Text paddingBottom={16}>There are no active programs.</Text>
      {!hasProgramForCurrentYear && !hasDisqualifiedProgramForCurrentYear && (
        <Button
          label='Qualify Now'
          onClick={{ location: '#', external: true, target: '_self' }}
          variant={'outlined'}
        />
      )}
    </div>
  );
};
