import { makeSubclassObservable } from 'lib/mobx-utils';
import { runInAction } from 'mobx';
import { BaseStore } from 'stores/BaseStore';
import { RootStore } from '../RootStore';

export class AppStore extends BaseStore {
  public showSideNav = false;
  public showTopNav = false;

  public isLargeDesktop = false;
  public isDesktop = false;
  public isSmallDesktop = false;
  public isTablet = false;
  public isMobile = false;
  public isPrint = false;

  public isDesktop1440 = false;
  public isDesktop1280 = false;
  public isDesktop1024 = false;
  public isTablet960 = false;
  public isTablet800 = false;
  public isMobile640 = false;
  public isMobile520 = false;
  public isMobile400 = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public get qualificationTaxYear() {
    return this.featureFlags.use2022TaxYear ? 2022 : 2021;
  }

  public toggleNavBars(showSideNav: boolean, showTopNav: boolean) {
    runInAction(() => {
      this.showSideNav = showSideNav;
      this.showTopNav = showTopNav;
    });
  }

  public toggleMediaQueries(
    isLargeDesktop: boolean,
    isDesktop: boolean,
    isSmallDesktop: boolean,
    isTablet: boolean,
    isMobile: boolean,
    isPrint: boolean,
    isDesktop1440: boolean,
    isDesktop1280: boolean,
    isDesktop1024: boolean,
    isTablet960: boolean,
    isTablet800: boolean,
    isMobile640: boolean,
    isMobile520: boolean,
    isMobile400: boolean,
  ) {
    runInAction(() => {
      this.isLargeDesktop = isLargeDesktop;
      this.isDesktop = isDesktop;
      this.isSmallDesktop = isSmallDesktop;
      this.isTablet = isTablet;
      this.isMobile = isMobile;
      this.isPrint = isPrint;
      this.isDesktop1440 = isDesktop1440;
      this.isDesktop1280 = isDesktop1280;
      this.isDesktop1024 = isDesktop1024;
      this.isTablet960 = isTablet960;
      this.isTablet800 = isTablet800;
      this.isMobile640 = isMobile640;
      this.isMobile520 = isMobile520;
      this.isMobile400 = isMobile400;
    });
  }
}
