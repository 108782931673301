import React from 'react';
import { Redirect } from 'react-router';
import { Page, TermNames } from '../lib/constants';

export default function TermsOfService() {
  const redirect = `${Page.terms}/${TermNames.CORE}`;

  return <div>{<Redirect to={redirect} />}</div>;
  // return (
  // <CenterTextBox>
  //   <div style={{ padding: '40px 80px' }}>
  //     <p>
  //       <b>MAINSTREET</b>
  //     </p>
  //     <p>
  //       <b>SERVICES AGREEMENT</b>
  //     </p>
  //     <p>Last updated: Jan 22, 2024</p>
  //     <p>
  //       <b>
  //         PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY BEFORE
  //         ACCESSING OR USING THE SERVICES (DEFINED BELOW).
  //       </b>
  //     </p>
  //     <p>
  //       MainStreet Work, Inc. and its affiliates (collectively,
  //       &ldquo;MainStreet&rdquo;) are willing to provide the Services to you
  //       only upon the condition that you accept all the terms contained in
  //       this Services Agreement (the &ldquo;Agreement&rdquo;). By mutually
  //       executing one or more Program Orders with MainStreet or by accessing
  //       or using the Services, you have indicated that you understand this
  //       Agreement and accept all of its terms. If you are accepting the terms
  //       of this Agreement on behalf of a company or other legal entity, you
  //       represent and warrant that you have the authority to bind that company
  //       or other legal entity to the terms of this Agreement, and, in such
  //       event, &ldquo;you&rdquo; and &ldquo;your&rdquo; will refer to that
  //       company or other legal entity. If you do not accept all the terms of
  //       this Agreement, then you must not accept this Agreement and you may
  //       not use the Services.
  //     </p>
  //     <p>
  //       <b>1. Definitions.</b>
  //     </p>
  //     <p>
  //       &ldquo;<b>Actual Program Value</b>&rdquo; means the actual total value
  //       of the tax credits or refunds, tax liability reductions, and other
  //       benefits and/or savings you receive from any Program, as reflected in
  //       the tax documents provided to you to assist you with your tax filings
  //       or in any benefits programs or other savings plans provided to you.
  //     </p>
  //     <p>
  //       &ldquo;<b>Agreement</b>&rdquo; means this Services Agreement, together
  //       with the Data Processing Addendum attached as Exhibit A.
  //     </p>
  //     <p>
  //       &ldquo;<b>Customer Data</b>&rdquo; means any and all data provided and
  //       used by you in connection with the Services.
  //     </p>
  //     <p>
  //       &ldquo;<b>Estimated Program Value</b>&rdquo; means MainStreet&rsquo;s
  //       estimate of the total value of tax credits or refunds, tax liability
  //       reductions, and other benefits and/or savings you will receive from
  //       any Program provided from time to time by MainStreet.
  //     </p>
  //     <p>
  //       &ldquo;<b>Order Form</b>&rdquo; means the order form presented to you
  //       upon enrollment, that governs all Programs that are made available to
  //       Customer by MainStreet.
  //     </p>
  //     <p>
  //       &ldquo;<b>Program Description Page</b>&rdquo; means the website
  //       available at{' '}
  //       <a href='https://mainstreet.com/program-details/'>
  //         https://mainstreet.com/program-details/
  //       </a>{' '}
  //       which may be updated by MainStreet from time to time and which
  //       includes descriptions of each Program, the associated fees, and when
  //       such fees are due.
  //     </p>
  //     <p>
  //       &ldquo;<b>Program</b>&rdquo; means a tax credit, incentive, benefit,
  //       or any other program you are enrolled in as part of the Services.
  //     </p>
  //     <p>
  //       &ldquo;<b>Services</b>&rdquo; means MainStreet&rsquo;s cloud-based
  //       services and analytics platform, as well as the associated time of
  //       MainStreet personnel who assist you in reviewing and applying for tax
  //       credits and incentive programs, grants, employee benefit programs, tax
  //       benefits and advantages and other benefits and savings opportunities.
  //     </p>
  //     <p>
  //       <b>2. Services.</b>
  //     </p>
  //     <p>
  //       2.1 <u>Services</u>. Subject to your compliance with the terms and
  //       conditions of this Agreement, during the Term (as defined below): (i)
  //       MainStreet will provide you with the Services, and you may access and
  //       use the Services solely for your internal business purposes within the
  //       usage limits specified for each the applicable Program Order; and (ii)
  //       MainStreet will make available to you at no additional cost the
  //       standard support that is made generally available to paying
  //       subscribers, in accordance with MainStreet&rsquo;s policies as the
  //       same may be amended or supplemented from time to time.
  //     </p>
  //     <p>
  //       2.2 <u>Authorized Users</u>. The Services may only be accessed and
  //       used by your employees and independent contractors, who may only
  //       access and use the Services for the sole purpose of performing their
  //       job functions or services (as applicable) for you (&ldquo;
  //       <b>Authorized Users</b>
  //       &rdquo;). The log-in credentials for each Authorized User are for a
  //       single individual only and cannot be shared or used by more than 1
  //       person. You are responsible for all actions taken under an Authorized
  //       User&rsquo;s account, whether or not such action was taken or
  //       authorized by the Authorized User. You may from time to time replace
  //       an Authorized User who no longer requires use of the Services by
  //       notifying MainStreet of the requested change (in which case new log-in
  //       credentials will be established).
  //     </p>
  //     <p>
  //       2.3 <u>Restrictions</u>. You, on behalf of yourself and your
  //       Authorized Users, agree not to: (1) copy, modify, alter, decompile or
  //       reverse engineer the Services (including the business practices,
  //       source code, object code, and underlying structure and algorithms
  //       thereof); (2) resell or otherwise make the Services available to any
  //       third party; (3) use the Services either directly or indirectly to
  //       support any activity that is illegal or that violates the proprietary
  //       rights of others; (4) interfere with or disrupt the Services or
  //       attempt to gain access to any systems or networks that connect thereto
  //       (except as required to access and use the Services); (5) deactivate,
  //       impair, or circumvent any security or authentication measures of the
  //       Services; (6) use the Services or their outputs to train, calibrate,
  //       or validate, in whole or in part any other systems, programs or
  //       platforms, or for benchmarking, software-development, or other
  //       competitive purposes; or (7) permit any third parties to do any of the
  //       above. You are responsible for the use of the Services by your
  //       Authorized Users and their compliance with this Agreement, and agree
  //       that any breach of this provision would result in irreparable harm to
  //       MainStreet.
  //     </p>
  //     <p>
  //       2.4 <u>Your Responsibilities</u>. You are responsible for providing
  //       MainStreet with accurate and complete information. As part of the
  //       Services, MainStreet may suggest tax credits, refunds or rebates,
  //       incentive programs, promotions, benefits, or other Programs you may
  //       qualify for and wish to apply for, and/or participate in. You
  //       acknowledge and agree that it is your decision, in your sole
  //       discretion, whether to apply for, and/or participate in, any such
  //       programs, and you are solely responsible for any impact your
  //       application for, or participation in, such Programs may have on your
  //       business, employees, customers, or partners. You must cooperate with
  //       MainStreet and follow the instructions and timelines provided by
  //       MainStreet to take advantage of any of the foregoing, and participate
  //       in good faith in the processes necessary to secure the credits,
  //       savings, and other benefits that have been identified to you. Failure
  //       to do so will void the MainStreet Guarantee that may be applicable to
  //       your Program Order. MainStreet retains sole discretion in determining
  //       whether you have participated in good faith in any of the
  //       aforementioned processes. You must also file your complete,
  //       independently reviewed tax returns and forms on or prior to the filing
  //       deadlines applicable to your company. MainStreet is not responsible
  //       for your failure to receive a deduction, credit, discount (including
  //       any failure on your part to identify a referrer during the sign up
  //       process), promotional rate, or refund due to your non-compliance with
  //       the above requirements, or late or incomplete tax filings, and any
  //       such failure does not relieve you of your payment obligations to
  //       MainStreet. If you choose to extend your tax filing deadline, you will
  //       still be obligated to abide by any deadlines provided by MainStreet
  //       for completion of our services regardless of when you receive tax
  //       credits or other benefits from the applicable Program. Any information
  //       or documentation provided to you for a tax credit Program is only to
  //       be used to establish such tax credits on a timely-filed original
  //       income tax return, and should not be used for purposes of claiming a
  //       refund or credit on an amended return.{' '}
  //       <b>
  //         Any advice or guidance given by or on behalf of MainStreet in
  //         connection with the Services or a particular Program is given solely
  //         for informational purposes and nothing provided by MainStreet should
  //         be construed as investment, legal, accounting, or tax advice.
  //       </b>{' '}
  //       You should consult your lawyer, accountant, or tax professional should
  //       you need such advice.
  //     </p>
  //     <p>
  //       <b>3. Customer Data.</b>
  //     </p>
  //     <p>
  //       3.1 <u>Customer Data</u>. As between you and MainStreet, you own all
  //       right, title and interest in and to your Customer Data. You hereby
  //       grant to MainStreet a non-exclusive license to access, process, use,
  //       copy, modify, create derivative works based on, and distribute your
  //       Customer Data to: (a) provide the Services, including through
  //       affiliated entities and third-party service providers, to you and your
  //       Authorized Users, and (b) to create Analytics Data (defined below).
  //       You are solely responsible for the content of your Customer Data. You
  //       represent and warrant that (1) you have, and will continue to have,
  //       during the term of this Agreement, all necessary rights, authority and
  //       licenses for the access to and use of your Customer Data as
  //       contemplated by this Agreement and the software and systems on or
  //       through which you have requested us to provide services; and (2)
  //       MainStreet&rsquo;s use of the Customer Data in accordance with this
  //       Agreement will not violate any applicable laws or regulations or cause
  //       a breach of any agreement or obligation between you and any third
  //       party. The Data Processing Addendum set forth in Exhibit A is hereby
  //       incorporated herein by reference.
  //     </p>
  //     <p>
  //       3.2 <u>Security; Backup</u>. MainStreet will maintain (and will
  //       require its third-party service providers to maintain) reasonable
  //       administrative, physical and technical safeguards intended to protect
  //       the Customer Data against accidental loss and unauthorized access or
  //       disclosure, in accordance with applicable industry standards.
  //       MainStreet will follow its standard archival procedures for Customer
  //       Data. In the event of any loss or corruption of Customer Data,
  //       MainStreet will use its commercially reasonable efforts to restore the
  //       lost or corrupted Customer Data from the latest backup of such
  //       Customer Data maintained by MainStreet. MainStreet will not be
  //       responsible for any loss, destruction, alteration, unauthorized
  //       disclosure or corruption of Customer Data caused by you or by any
  //       Authorized User or third party. MAINSTREET&rsquo;S EFFORTS TO RESTORE
  //       LOST OR CORRUPTED CUSTOMER DATA PURSUANT TO THIS SECTION 3.2 WILL
  //       CONSTITUTE MAINSTREET&rsquo;S SOLE LIABILITY AND YOUR SOLE AND
  //       EXCLUSIVE REMEDY IN THE EVENT OF ANY LOSS OR CORRUPTION OF CUSTOMER
  //       DATA IN CONNECTION WITH THE SERVICES.
  //     </p>
  //     <p>
  //       <b>4. Fees &amp; Payment.</b>
  //     </p>
  //     <p>
  //       4.1 <u>Fees</u>. For customers who have an Order Form that commenced
  //       prior to January 10, 2024, you will pay the fees and charges stated in
  //       each Program Order for use of the Services with respect to each
  //       Program (&ldquo;
  //       <b>Program Fees</b>&rdquo;). The Program Fee for each Program will be
  //       specified in the applicable Program Order and is payable and charged
  //       each month as specified in the Program Order for the duration of the
  //       applicable Program Order Term (defined below).
  //     </p>
  //
  //     <p>
  //       For customers who have an Order Form that commenced on or after
  //       January 10, 2024, You will pay the fees and charges stated in the
  //       Program Description Page for use of the Services (&ldquo;
  //       <b>Program Fees</b>&rdquo;). The Program Fees are payable in
  //       accordance with the payment schedule set forth on the Program
  //       Description Page for the duration of the Order Form Term. The Order
  //       Form may also include success fees or supplemental fees for additional
  //       services provided during the Term (&ldquo;
  //       <b>Supplemental Fees</b>&rdquo;). The Program Order may also include
  //       success fees or supplemental fees for additional services provided
  //       during the Term (&ldquo;
  //       <b>Supplemental Fees</b>&rdquo;).
  //     </p>
  //     <p>
  //       4.2 <u>Payment Terms</u>. Unless otherwise specified in a Program
  //       Order, you agree to pay all Program Fees by credit/debit card or by
  //       bank transfer (ACH/wire). If you have provided us with credit/debit
  //       card details, we will charge that credit/debit card each month as
  //       specified in your Program Order for the duration of the Term, for the
  //       Program Fees and the portion due for any applicable Supplemental Fees.
  //       If we issue an invoice to you, all invoices are payable as specified
  //       in the Payment Terms section of the applicable Program Order (or if
  //       not so specified, within thirty (30) days of issuance). All amounts
  //       are stated and shall be paid in U.S. dollars and are exclusive of
  //       taxes, duties, levies, tariffs, and other governmental charges
  //       (collectively, &ldquo;
  //       <b>Taxes</b>&rdquo;). You are responsible for payment of all Taxes and
  //       any related interest and/or penalties resulting from any payments made
  //       to us, other than any taxes based on MainStreet&rsquo;s net income.
  //       All past due amounts will incur interest at a rate of 1% per month or
  //       the maximum rate permitted by law, whichever is less. Except as
  //       expressly set forth in this Agreement, all payments, once paid, are
  //       non-refundable. Additionally, you agree to pay or reimburse any costs
  //       (including attorney&rsquo;s fees) MainStreet incurs to collect overdue
  //       payments (including Program Fees and Supplemental Fees) from you.
  //     </p>
  //     <p>
  //       4.3 <u>Preliminary Non-Binding Estimates</u>. Upon your signing up for
  //       a Program, MainStreet may present to you a preliminary estimate or
  //       target of the credits, refunds, saving benefits you may be eligible
  //       for. Any such estimates are non-binding and subject to revision.
  //     </p>
  //     <p>
  //       4.4 <u>Fee Calculation and Reconciliation for Tax Credit Programs</u>.
  //       The Program Fees for each tax credit Program will be calculated as a
  //       percentage of the &ldquo;<b>Actual Program Value</b>&rdquo;.
  //     </p>
  //     <p>
  //       For customers who have an Order Form that commenced prior to January
  //       10, 2024, the applicable percentage for calculating the Program Fees
  //       for each Program will be set forth on the applicable Program Order
  //       (the &ldquo;
  //       <b>Fee Percentage</b>&rdquo;). Upon entering into a Program Order for
  //       a Program, you agree to pay Program Fees based on the then-current
  //       Estimated Program Value. MainStreet will provide you with an initial
  //       Estimated Program Value during or after the Qualification Process and
  //       may provide an updated Estimated Program Value as often as monthly.
  //       MainStreet will undertake a process, as applicable, to ensure the
  //       total Program Fees you owe are calculated based on the Actual Program
  //       Value.
  //     </p>
  //     <p>
  //       For customers who have an Order Form that commenced prior to January
  //       10, 2024, the Program Order for each Program may specify additional
  //       reconciliation events (&ldquo;<b>Reconciliation Event</b>&rdquo;), in
  //       addition to those below, that trigger the following reconciliation
  //       process. Upon the occurrence of a Reconciliation Event, MainStreet
  //       will calculate the Actual Program Value or an updated Estimated
  //       Program Value. For most Programs, the Reconciliation Event will be
  //       when your tax documents reflecting the tax refunds and tax liability
  //       reductions from the Program are presented to you for inclusion in your
  //       filing.
  //     </p>
  //     <p>
  //       Notwithstanding any other provision of this Agreement: (i) any
  //       adjustments to, or denials of, any tax refund or tax liability
  //       reduction by any applicable tax authority, court, or other
  //       governmental/regulatory body shall not constitute a Reconciliation
  //       Event; (ii) no Reconciliation Event with respect to any Program shall
  //       occur after the date on which your relevant tax return is filed with
  //       the IRS; and (iii) in no event will you have any right to a full or
  //       partial refund of any Program Fees if all or part of the Actual
  //       Program Value is not sustained by any applicable tax authority, court,
  //       or other governmental/regulatory body.
  //     </p>
  //     <p>
  //       <em>Reconciliation for Overpayment of Program Fees</em>
  //     </p>
  //     <p>
  //       For customers who have an Order Form that commenced prior to January
  //       10, 2024, if the Reconciliation Event occurs during the Program Order
  //       Term and the Estimated Program Value is greater than the Actual
  //       Program Value, then the Program Fees will be proportionally reduced
  //       for the remainder of the Program Order Term so that the total Program
  //       Fees you pay during such Program Order Term will equal the Fee
  //       Percentage times the Actual Program Value or Estimated Program Value
  //       for such Program Order Term. If the amount of Program Fees paid prior
  //       to the Reconciliation Event exceeds the Fee Percentage times the
  //       Actual Program Value, then MainStreet will credit the excess Program
  //       Fees previously paid to your MainStreet account.
  //     </p>
  //     <p>
  //       <em>Exceptions</em>
  //     </p>
  //     <p>
  //       The above terms shall not apply and your Program Fees will be
  //       calculated based on the Estimated Program Value if the Actual Program
  //       Value is unavailable or is less than the Estimated Program Value due
  //       to any of the following:
  //     </p>
  //     <ol>
  //       <li>
  //         false, inaccurate, or incomplete information you provide to
  //         MainStreet;
  //       </li>
  //       <li>
  //         your failure to correct and resolve errors in the applications
  //         prepared by MainStreet;
  //       </li>
  //       <li>
  //         your failure to follow instructions provided by MainStreet or to
  //         cooperate in the process to obtain credits or other benefits for
  //         you;
  //       </li>
  //       <li>
  //         your choice not to claim the full amount of a deduction or credit or
  //         other benefit for which you are eligible; or
  //       </li>
  //       <li>
  //         your failure or delay in filing a complete and accurate tax return.
  //       </li>
  //     </ol>
  //     <p>
  //       <em>Reconciliation for Underpayment of Program Fees</em>
  //     </p>
  //     <p>
  //       For customers who have an Order Form that commenced prior to January
  //       10, 2024, if the Reconciliation Event occurs during the Program Order
  //       Term and the Actual Program Value is greater than the Estimated
  //       Program Value, then the Program Fees will be proportionally increased
  //       for the remainder of the Program Order Term and additional Program Fee
  //       invoices may be issued so that the total Program Fees you pay during
  //       such Program Order Term will equal the Fee Percentage times the Actual
  //       Program Value for such Program Order Term.
  //     </p>
  //     <p>
  //       If the Reconciliation Event occurs after the expiration of the Program
  //       Order Term and the amount of Program Fees you paid during the Program
  //       Order Term was less than the Fee Percentage times the Actual Program
  //       Value, then MainStreet will notify you of the additional Program Fees
  //       due and you agree to pay such fees in accordance with this Agreement.
  //     </p>
  //     <p>
  //       <b>5. Term and Termination.</b>
  //     </p>
  //     <p>
  //       5.1 <u>Term</u>. For each Program Order created prior to January 10,
  //       2024 and each renewed Program Order, the &ldquo;
  //       <b>Program Order Term</b>&rdquo; shall begin as of the effective date
  //       set forth on such Program Order, and unless earlier terminated as set
  //       forth herein, (i) shall continue for the initial term specified on the
  //       Program Order (the &ldquo;
  //       <b>Initial Program Order Term</b>&rdquo;), and (ii) following the
  //       Initial Program Order Term, shall automatically renew for additional
  //       successive periods of one year each without the application of any
  //       promotions or incentives applied to the Initial Program Order Term
  //       (each, a &ldquo;<b>Renewal Program Order Term</b>&rdquo;) unless
  //       either party notifies the other party of its intention not to renew no
  //       later than thirty (30) days prior to the expiration of the Initial
  //       Program Order Term or then-current Renewal Program Order Term, as
  //       applicable. This Agreement shall commence upon the effective date set
  //       forth in the first Program Order, and, unless earlier terminated in
  //       accordance herewith, shall last until the expiration of all Program
  //       Order Terms (the &ldquo;<b>Term</b>&rdquo;).
  //     </p>
  //     <p>
  //       For customers who have an Order Form that commenced after January 10,
  //       2024, the &ldquo;<b>Program Order Term</b>&rdquo; shall begin as of
  //       the date the user adds their payment method to MainStreet, and shall
  //       continue until the end of the payment schedule for any applicable
  //       Programs.
  //     </p>
  //     <p>
  //       5.2 <u>Changes</u>. At any time during the Term, MainStreet may adjust
  //       your Fees and Program Advances based on changes to estimated future
  //       benefits or target savings. If you choose not to continue using the
  //       Services after the change goes into effect, your right to use the
  //       Services will end and you will be required to pay any incurred Program
  //       Fees and Supplemental Fees and pay back any Program Advances as per
  //       the terms in each original Program Order.
  //     </p>
  //     <p>
  //       5.3 <u>Termination for Breach</u>. Each party will have the right to
  //       terminate this Agreement if the other party breaches this Agreement
  //       and fails to cure such breach within 10 business days after written
  //       notice thereof.
  //     </p>
  //     <p>
  //       5.4 <u>Cancellation Policy</u>. (a) For customers who have an Order
  //       Form that commenced prior to January 10, 2024, you may cancel any tax
  //       credit Program Order as outlined herein, but you will be required to
  //       pay in full the amount of any Program Advance provided in such Program
  //       Order within 30 days after cancellation. If you cancel a tax credit
  //       Program Order up to five (5) days after MainStreet provides you with a
  //       notice of commencement of the expense classification process, you
  //       agree to pay any incurred Program Fees and Supplemental Fees through
  //       the month of cancellation. If you cancel after that time, you agree to
  //       pay the full Program Fees and Supplemental Fees as set forth in your
  //       Program Order.
  //     </p>
  //     <p>
  //       (b) For customers who have an Order Form that commenced prior to
  //       January 10, 2024, You may not cancel the Order Form prior to the
  //       expiration of the then current Initial or Renewal Term. If you attempt
  //       to cancel the Order Form, you will be required to pay in full the
  //       amount of the Program Fees and Supplemental Fees as set forth in your
  //       Order Form within 30 days after such attempted cancellation. Upon
  //       cancellation, Program Fees will be due for any Programs for which
  //       MainStreet has delivered you the applicable tax forms, even if you
  //       have not yet received the full value or benefit of such Program.
  //     </p>
  //     <p>
  //       You may opt out of any given Program at any point when it is presented
  //       to you and before final forms for such Program have been provided by
  //       MainStreet. By opting out of a particular Program, you are not
  //       automatically canceling the entire Order Form unless expressly request
  //       to cancel the entire Order Form. When opting out of a Program, you
  //       will no longer be responsible for future Program Fees for such
  //       Program, but you will be responsible for Program Fees based on the
  //       Actual Program Value delivered prior to your request to opt out of
  //       such Program.
  //     </p>
  //     <p>
  //       5.5 <u>Additional Remedies</u>. Without limiting other available
  //       remedies, MainStreet reserves the right to suspend or disable your and
  //       your Authorized Users&rsquo; access to the Services if any undisputed
  //       amounts payable under this Agreement become more than 30 days past
  //       due. MainStreet also reserves the right to suspend or disable access
  //       to the Services if MainStreet determines (in its sole discretion)
  //       that: (1) your or any Authorized User&rsquo;s use of the Services
  //       disrupts, harms, or poses a security risk, or may cause harm, in each
  //       case to MainStreet, the Services or any third party; or (2) you or any
  //       Authorized User has used, or is using, the Services in breach of this
  //       Agreement.
  //     </p>
  //     <p>
  //       5.6 <u>Effect of Termination</u>. Upon any expiration or termination
  //       of this Agreement, your (and your Authorized Users&rsquo;) right to
  //       access and use the Services will automatically terminate. MainStreet
  //       will have no liability for any costs, losses, damages, or liabilities
  //       arising out of or related to MainStreet&rsquo;s exercise of its
  //       termination rights under this Agreement. Any payment obligations as of
  //       the expiration or termination will remain in effect. The obligations
  //       and provisions of Sections 2.3, 3, 4, 5, and Sections 6 through 13
  //       (inclusive) will survive any expiration or termination of this
  //       Agreement.
  //     </p>
  //     <p>
  //       <b>6. Confidentiality.</b>
  //     </p>
  //     <p>
  //       6.1 <u>General</u>. Each party understands that the other party may
  //       need to disclose certain non-public information relating to the
  //       disclosing party&rsquo;s business that is marked or identified as
  //       &ldquo;confidential&rdquo; at the time of disclosure, or that is
  //       described in this Agreement as confidential (&ldquo;
  //       <b>Confidential Information</b>&rdquo;) in connection with the use
  //       and/or performance of the Services. Customer Data is your Confidential
  //       Information. MainStreet Confidential Information includes the
  //       non-public portions of the Services and any related documentation and
  //       pricing information of MainStreet. During the term of this Agreement
  //       and for three (3) years thereafter, each party agrees to take
  //       reasonable precautions to protect the disclosing party&rsquo;s
  //       Confidential Information from unauthorized disclosure, not to use such
  //       Confidential Information except as authorized or as necessary to
  //       perform its obligations under this Agreement, and to not disclose
  //       (without the disclosing party&rsquo;s prior authorization, including
  //       any such authorization given under this Agreement) to any third person
  //       any such Confidential Information (other than on a need to know basis
  //       to the receiving party&rsquo;s employees, consultants and service
  //       providers who are subject to confidentiality obligations that are at
  //       least as protective of the disclosing party&rsquo;s Confidential
  //       Information as this Agreement), or as specifically permitted under
  //       this Agreement. Confidential Information does not include any
  //       information that the receiving party can show: (1) through no fault of
  //       the receiving party, is or becomes generally available to the public,
  //       (2) was in its possession or was known prior to receipt from the
  //       disclosing party, (3) was rightfully disclosed to it without
  //       restriction by a third party, or (4) was independently developed
  //       without use of any Confidential Information of the disclosing party.
  //       For the avoidance of doubt, Confidential Information does not include
  //       any information regarding the tax treatment or tax structure of any
  //       transaction. The receiving party may disclose Confidential Information
  //       if the disclosure is necessary to comply with a valid court order or
  //       subpoena (in which case the receiving party will, unless prohibited by
  //       law or legal process, promptly notify the disclosing party and
  //       cooperate with the disclosing party if the disclosing party chooses to
  //       contest the disclosure requirement, seek confidential treatment of the
  //       information to be disclosed, or limit the nature or scope of the
  //       information to be disclosed). Upon request following termination of
  //       this Agreement, the receiving party will promptly return to the
  //       disclosing party or destroy all copies of the disclosing party&rsquo;s
  //       Confidential Information in its possession or control, except that the
  //       receiving party may retain a&nbsp; copy of the disclosing
  //       party&rsquo;s Confidential Information to monitor its compliance under
  //       this Agreement and to comply with applicable regulatory requirements.
  //       For purposes of clarity, MainStreet may retain any de-identified
  //       information or Analytics Data generated using Customer Data.
  //     </p>
  //     <p>
  //       6.2 <u>Consent</u>. To Use or Disclose Customer Data. If we are
  //       considered a return preparer under federal tax law, we cannot disclose
  //       your tax return information to third parties, or use that information
  //       for purposes other than in the preparation of your tax returns, unless
  //       such disclosure or use is otherwise authorized by law or you consent
  //       to such disclosure or use. Likewise, if we are considered a return
  //       preparer, federal tax law precludes our disclosing your tax return
  //       information to service providers outside the United States without
  //       your consent.
  //     </p>
  //     <p>
  //       6.3 <u>Disclosure of Tax Return Information Outside the U.S.</u>. In
  //       the course of providing services to you under this Agreement,
  //       MainStreet may engage one or more independent contractors to assist
  //       MainStreet in providing such services, and any administrative,
  //       clerical or analytical functions related thereto.&nbsp;
  //     </p>
  //     <p>
  //       <b>7. Ownership.</b>
  //     </p>
  //     <p>
  //       7.1 <u>MainStreet Intellectual Property</u>. As between MainStreet and
  //       you, MainStreet owns all worldwide right, title and interest in and to
  //       the Services, the Usage Data, and Analytics Data, including all
  //       Intellectual Property Rights therein. For purposes of this Agreement,
  //       &ldquo;<b>Intellectual Property Rights</b>
  //       &rdquo; means patent rights (including patent applications and
  //       disclosures), trademarks, copyrights, trade secrets, know-how and any
  //       other intellectual property rights recognized in any country or
  //       jurisdiction in the world.
  //     </p>
  //     <p>
  //       7.2 <u>Feedback.</u> If you provide any ideas, suggestions, or
  //       recommendations regarding the Services (&ldquo;<b>Feedback</b>
  //       &rdquo;), MainStreet will be free to use, disclose, reproduce, license
  //       or otherwise distribute, and exploit such Feedback as it sees fit,
  //       entirely without obligation or restriction of any kind. By providing
  //       Feedback, you grant to MainStreet a worldwide, perpetual, irrevocable,
  //       fully-paid up, royalty-free, nonexclusive license to use and exploit
  //       in any manner such Feedback.
  //     </p>
  //     <p>
  //       7.3 <u>Usage Data.</u> You acknowledge and agree that MainStreet may
  //       generate data with respect to the use and performance of the Services
  //       (&ldquo;Usage Data&rdquo;) and may retain and use such Usage Data for
  //       its internal business purposes, such as developing and improving
  //       MainStreet&rsquo;s products and services (including the Services).
  //     </p>
  //     <p>
  //       7.4 <u>Analytics Data.</u> You acknowledge and agree that MainStreet
  //       may use the Usage Data and the Customer Data to create analytics,
  //       analyses, statistics, benchmarks, or other aggregated data and
  //       derivative works.
  //     </p>
  //     <p>
  //       8. <b>NO WARRANTY.</b> THE SERVICES ARE PROVIDED AS &ldquo;AS
  //       IS,&rdquo; WITHOUT WARRANTY OF ANY KIND. MAINSTREET DISCLAIMS ALL
  //       WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY
  //       IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
  //       PURPOSE AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE
  //       OF DEALING OR USAGE OF TRADE. NO ADVICE OR INFORMATION, WHETHER ORAL
  //       OR WRITTEN, OBTAINED FROM MAINSTREET OR ELSEWHERE WILL CREATE ANY
  //       WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT. MAINSTREET DISCLAIMS
  //       ANY WARRANTY THAT THE SERVICES WILL BE ERROR-FREE OR UNINTERRUPTED OR
  //       THAT ALL ERRORS WILL BE CORRECTED. You assume sole responsibility and
  //       liability for results obtained from the use of the Services and for
  //       conclusions drawn from such use. MainStreet will have no liability for
  //       any claims, losses, or damages caused by errors or omissions in any
  //       Customer Data or any results produced by the Services based upon
  //       Customer Data.
  //     </p>
  //     <p>
  //       9. <b>Limitation of Liability.</b> IN NO EVENT WILL MAINSTREET BE
  //       LIABLE FOR ANY SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE OR
  //       CONSEQUENTIAL DAMAGES, OR FOR ANY LOSS OF USE, LOSS OF DATA, LOSS OF
  //       PROFITS OR LOSS OF GOODWILL, OR THE COSTS OF PROCURING SUBSTITUTE
  //       PRODUCTS, WHETHER OR NOT FORESEEABLE, ARISING OUT OF OR IN CONNECTION
  //       WITH THIS AGREEMENT OR THE USE OR PERFORMANCE OF THE SERVICES, WHETHER
  //       SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY,
  //       TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, AND
  //       WHETHER OR NOT MAINSTREET HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
  //       LOSS OR DAMAGE. MAINSTREET&rsquo;S TOTAL AGGREGATE LIABILITY ARISING
  //       UNDER THIS AGREEMENT, FROM ALL CAUSES OF ACTION AND ALL THEORIES OF
  //       LIABILITY, WILL NOT EXCEED THE AMOUNTS PAID TO MAINSTREET BY YOU FOR
  //       THE SERVICES DURING THE 12 MONTH PERIOD PRIOR TO THE FIRST CLAIM FOR
  //       LIABILITY HEREUNDER. The parties agree that the limitations and
  //       exclusions contained in this Section 9 and elsewhere in this Agreement
  //       will survive and apply even if any exclusive remedy specified in this
  //       Agreement is found to have failed of its essential purpose.
  //     </p>
  //     <p>
  //       10. <b>U.S. Government End Users.</b> The Services are
  //       &ldquo;commercial computer software&rdquo; and &ldquo;commercial
  //       computer software documentation,&rdquo; respectively, as such terms
  //       are used in FAR 12.212 and DFARS 227.7202. If access to the Services
  //       is being acquired by or on behalf of the U.S. Government, then, as
  //       provided in FAR 12.212 and DFARS 227.7202-1 through 227.7202-4, as
  //       applicable, the U.S. Government&rsquo;s rights in the Services will be
  //       only those specified in this Agreement.
  //     </p>
  //     <p>
  //       11. <b>Indemnification.</b> You agree to indemnify and hold MainStreet
  //       and its licensors, suppliers, vendors, partners, and affiliated
  //       entities, and each of their respective officers, directors, members,
  //       employees, consultants, contract employees, representatives and agents
  //       harmless from and against any and all claims, liabilities, damages
  //       (actual and consequential), losses and expenses (including
  //       attorneys&rsquo; fees) arising from or in any way related to any
  //       claims relating to (a) your violation of this Agreement, (b) any liens
  //       by third parties on your account, and (c) your use of the Services in
  //       any manner inconsistent with this Agreement.
  //     </p>
  //     <p>
  //       12. <b>Force Majeure.</b> MainStreet will not be in breach of this
  //       Agreement if its performance is prevented or delayed for circumstances
  //       beyond its reasonable control, including but not limited to acts of
  //       God, inclement weather, flood, lightning or fire, strikes or other
  //       labor disputes or industrial action, act or omission of government or
  //       other competent authority, terrorism, war, riot, or civil commotion,
  //       pandemic, unavailability of supply or power outage, hackers, viruses,
  //       disruption in transmission, or disruption in telecommunications
  //       services.
  //     </p>
  //     <p>
  //       13. <b>General.</b> This Agreement will be governed by and construed
  //       in accordance with the laws of the State of California, without regard
  //       to or application of conflict of laws rules or principles. The United
  //       Nations Convention on Contracts for the International Sale of Goods
  //       will not apply. Any controversy or claim relating to the Agreement
  //       shall be settled by arbitration in San Francisco, California under
  //       JAMS&rsquo; Streamlined Arbitration Rules. The arbitrator(s) may award
  //       costs and attorneys&rsquo; fees to the prevailing party, and judgment
  //       on any award rendered by the arbitrator(s) may be entered in any court
  //       having jurisdiction thereof. You may not assign or transfer this
  //       Agreement, or any rights granted hereunder, by operation of law or
  //       otherwise, without MainStreet&rsquo;s prior written consent, and any
  //       attempt by you to do so, without such consent, will be void.
  //       MainStreet may freely assign this Agreement. Except as expressly set
  //       forth in this Agreement, the exercise by either party of any of its
  //       remedies under this Agreement will be without prejudice to its other
  //       remedies under this Agreement or otherwise. All notices or approvals
  //       required or permitted under this Agreement will be in writing and
  //       delivered by confirmed email transmission, by overnight delivery
  //       service, or by certified mail, and in each instance will be deemed
  //       given upon receipt. All notices or approvals will be sent to the
  //       addresses set forth in the applicable Program Order or to such other
  //       address as may be specified by either party to the other in accordance
  //       with this Section. The failure by either party to enforce any
  //       provision of this Agreement will not constitute a waiver of future
  //       enforcement of that or any other provision. Any waiver, modification
  //       or amendment of any provision of this Agreement will be effective only
  //       if in writing and signed by authorized representatives of both
  //       parties. If any provision of this Agreement is held to be
  //       unenforceable or invalid, that provision will be enforced to the
  //       maximum extent possible, and the other provisions will remain in full
  //       force and effect. This Agreement, together with the Program Orders, is
  //       the complete and exclusive understanding and agreement between the
  //       parties regarding its subject matter, and supersedes any and all prior
  //       or contemporaneous agreements, proposals, understandings or
  //       communications between the parties, oral or written, regarding its
  //       subject matter, unless you and MainStreet have executed a separate
  //       agreement governing use of the Services. Any terms or conditions
  //       contained in any purchase order or other ordering document that are
  //       inconsistent with or in addition to the terms and conditions of this
  //       Agreement are hereby rejected by MainStreet and will be deemed null.
  //       The parties to this Agreement are independent contractors and this
  //       Agreement will not establish any relationship of partnership, joint
  //       venture, employment, franchise, or agency between the parties. Neither
  //       party will have the power to bind the other or incur obligations on
  //       the other&rsquo;s behalf without the other&rsquo;s prior written
  //       consent.
  //     </p>
  //     <p>
  //       14. <b>Contact Information.</b> If you have any questions regarding
  //       this Agreement, you may contact MainStreet at {SUPPORT_EMAIL_ADDRESS}.
  //     </p>
  //     <p>
  //       15. <b>MainStreet Guarantee</b>
  //     </p>
  //     <p>
  //       If MainStreet makes a mistake or error in an application we prepare
  //       for you for any tax credit, tax refund or rebate, incentive program or
  //       other program and if you are required to pay the IRS or a state tax
  //       authority any penalties or interest as a result of our mistake or
  //       error, MainStreet will reimburse you, after you pay the IRS or
  //       applicable state authority, up to a maximum of one million dollars
  //       ($1,000,000), for (i) the amount of the penalty and interest paid by
  //       you that you would otherwise not have been required to pay, and (ii)
  //       the amount reasonable fees you paid to an accountant to review and/or
  //       correct such error (the &ldquo;Reimbursement&rdquo;).
  //     </p>
  //     <p>
  //       If you receive an audit letter from the IRS or applicable state
  //       authority relating to any tax credit, tax refund or rebate, incentive
  //       program or other program we helped you apply for, then MainStreet will
  //       provide free audit guidance from a MainStreet professional, to help
  //       understand what to expect and how to prepare for the audit and to
  //       answer your audit questions until December 31 three years following
  //       the applicable tax filing season (&ldquo;Audit Support&rdquo;).
  //       MainStreet and its audit professionals will not represent you or
  //       provide legal advice with respect to any audit.
  //     </p>
  //     <p>
  //       The Reimbursement and Audit Support are subject to your compliance
  //       with all terms and conditions of this MainStreet Service Agreement.
  //       MainStreet will not reimburse you for any penalties or interest that
  //       are due as a result of any of the following:
  //     </p>
  //     <ol>
  //       <li>
  //         false, inaccurate, or incomplete information you provide to
  //         MainStreet;
  //       </li>
  //       <li>
  //         your failure to correct and resolve errors in the applications
  //         prepared by MainStreet;
  //       </li>
  //       <li>your failure to follow instructions provided by MainStreet;</li>
  //       <li>your choice not to claim a deduction or credit; or</li>
  //       <li>
  //         your failure or delay in filing a complete and accurate tax return.
  //       </li>
  //     </ol>
  //     <p>
  //       To qualify for the Reimbursement, you must provide notice to
  //       MainStreet by emailing us at
  //       {SUPPORT_EMAIL_ADDRESS} within 90 days after the applicable penalties
  //       or interest are assessed.
  //     </p>
  //     <p>
  //       In no event will MainStreet reimburse you for more than an aggregate
  //       of one million dollars ($1,000,000) for interest and penalties owed to
  //       the IRS and any state tax authorities and related accountant fees in
  //       connection with all tax credits, tax refunds, rebates, incentive
  //       programs and other programs you apply for through MainStreet. THIS
  //       PROVISION STATES MAINSTREET&lsquo;S ENTIRE OBLIGATION AND LIABILITY,
  //       AND YOUR SOLE AND EXCLUSIVE REMEDY, FOR ANY ERRORS IN YOUR
  //       APPLICATIONS CAUSED BY THE MAINSTREET. The rights and benefits
  //       afforded by these MainStreet Guarantee Terms and Conditions are
  //       personal to you and may not be assigned or otherwise transferred to
  //       any other party.
  //     </p>
  //     <hr />
  //     <p>
  //       <b>Exhibit A</b>
  //     </p>
  //     <p>
  //       <b>Data Processing Addendum</b>
  //     </p>
  //     <p>
  //       This Data Processing Addendum (&ldquo;<b>DPA</b>&rdquo;) forms part of
  //       the attached Services Agreement (the &ldquo;
  //       <b>Agreement</b>&rdquo;) between you (&ldquo;
  //       <b>Customer</b>&rdquo;) and MainStreet.
  //     </p>
  //     <p>
  //       <b>1. Definitions</b>
  //     </p>
  //     <p>
  //       1.1 &ldquo;<u>Consumer</u>&rdquo; means a natural person who is a
  //       resident of, as applicable: (1) California, however identified,
  //       including by any unique identifier; or (2) Virginia acting only in an
  //       individual or household context.
  //     </p>
  //     <p>
  //       1.2 &ldquo;<u>Controller</u>&rdquo; means the natural or legal person
  //       that, alone or jointly with others, determines the purpose and means
  //       of Processing Personal Data. &ldquo;Controller&rdquo; includes a
  //       &ldquo;Business&rdquo; as defined by the CCPA.
  //     </p>
  //     <p>
  //       1.3 &ldquo;<u>Personal Data</u>&rdquo; means any information that is
  //       linked or reasonably linkable to an identified or identifiable
  //       Consumer that is processed by MainStreet on behalf of the Customer
  //       pursuant to the Agreement. &ldquo;Personal Data&rdquo; includes
  //       &ldquo;Personal Information&rdquo; as defined by the CCPA. the CCPA.
  //     </p>
  //     <p>
  //       1.4 &ldquo;<u>Privacy Laws</u>&rdquo; means (i) the California
  //       Consumer Privacy Act of 2018, as amended by the California Privacy
  //       Rights Act of 2020 (Cal. Civ. Code §§ 1798.100 et seq.)
  //       (&ldquo;CCPA&rdquo;), and (ii) the Virginia Consumer Data Protection
  //       Act (VA. Code §§ 59.1-575 et seq.) (&ldquo;VCDPA&rdquo;), in each case
  //       as updated, amended or replaced from time to time.
  //     </p>
  //     <p>
  //       1.5 &ldquo;<u>Process</u>&rdquo; or &ldquo;<u>Processing</u>&rdquo;
  //       means any operation or set of operations that are performed on
  //       Personal Data or on sets of Personal Data, whether or not by automated
  //       means.
  //     </p>
  //     <p>
  //       1.6 &ldquo;<u>Processor</u>&rdquo; means a natural or legal entity
  //       that Processes Personal Data on behalf of a Controller or a Business.
  //       &ldquo;Processor&rdquo; includes &ldquo;Service Provider&rdquo; as
  //       defined by the CCPA.
  //     </p>
  //     <p>
  //       <b>2. Relationship of the Parties; Processing of Data</b>
  //     </p>
  //     <p>
  //       The Parties acknowledge and agree that Customer is a Controller and
  //       MainStreet is a Processor for purposes of the CCPA and the VCDPA, each
  //       to the extent applicable, and MainStreet is receiving Personal Data
  //       from Customer in order to provide the Services pursuant to the
  //       Agreement. MainStreet shall adhere to Customer&rsquo;s lawful
  //       instructions with respect to the Processing of Personal Data to be
  //       performed by MainStreet pursuant to the Agreement.
  //     </p>
  //     <p>
  //       <b>3. Nature and Purpose of Processing</b>
  //     </p>
  //     <p>
  //       3.1 <u>Nature and Purpose of Processing</u>: MainStreet shall Process
  //       Personal Data provided by Customer under the Agreement as necessary to
  //       provide the Services under the Agreement, for the purposes specified
  //       in the Agreement and this Addendum, and in accordance with
  //       Customer&rsquo;s instructions as set forth in this Addendum. Such
  //       purposes shall include generating estimates of benefit value,
  //       providing tax services, and assisting with tax audits.
  //     </p>
  //     <p>
  //       3.2 <u>Duration of Processing</u>: MainStreet shall Process Personal
  //       Data provided by Customer as long as required (i) to provide the
  //       Services to Customer under the Agreement, or (ii) by applicable law or
  //       regulation.
  //     </p>
  //     <p>
  //       3.3 <u>Categories of Consumers</u>: MainStreet may Process the
  //       following categories of Personal Data provided by Customer: Customer
  //       employees.
  //     </p>
  //     <p>
  //       3.4 <u>Categories of Personal Data</u>: MainStreet may Process the
  //       following categories of Personal Data provided by Customer: name,
  //       location, email address, phone number, address, occupation, title,
  //       employment status, employment dates, compensation and pay statements.
  //     </p>
  //     <p>
  //       <b>4. California-Specific Terms</b>
  //     </p>
  //     <p>
  //       <u>4.1 Additional Definitions</u>
  //     </p>
  //     <p>
  //       &ldquo;<u>Commercial Purpose</u>&rdquo;, &ldquo;
  //       <u>Personal Information</u>&rdquo;, &ldquo;
  //       <u>Sell</u>&rdquo;, &ldquo;<u>Service Provider</u>&rdquo;, and &ldquo;
  //       <u>Share</u>&rdquo; shall have the meanings set forth in the CCPA.
  //     </p>
  //     <p>
  //       <u>4.2 Obligations</u>
  //     </p>
  //     <p>
  //       4.2.1 MainStreet shall not Sell or Share Personal Information provided
  //       by Customer under the Agreement.
  //     </p>
  //     <p>
  //       4.2.2 MainStreet shall not retain, use, or disclose Personal
  //       Information provided by Customer pursuant to the Agreement outside of
  //       the direct business relationship with Customer or for any purpose,
  //       including a Commercial Purpose, other than as necessary for the
  //       specific purpose of performing the Services for Customer pursuant to
  //       the Agreement, or as otherwise set forth in the Agreement or as
  //       permitted by the CCPA.
  //     </p>
  //     <p>
  //       4.2.3 MainStreet shall notify Customer if MainStreet makes a
  //       determination that it can no longer meet its obligations under the
  //       CCPA.
  //     </p>
  //     <p>
  //       4.2.4 MainStreet shall comply with all obligations applicable to
  //       Service Providers under the CCPA, including by providing Personal
  //       Information provided by Customer under the Agreement the level of
  //       privacy protection required by the CCPA.
  //     </p>
  //     <p>
  //       4.2.5 MainStreet will not combine Personal Information received from,
  //       or on behalf of, Customer with other Personal Information except to
  //       the extent a Service Provider is permitted to do so under the CCPA.
  //     </p>
  //     <p>
  //       4.2.6 Customer shall promptly notify MainStreet upon receipt of any
  //       consumer request made pursuant to the CCPA that requires MainStreet to
  //       take any action with respect to a consumer&rsquo;s personal
  //       information.
  //     </p>
  //     <p>
  //       4.2.7 Customer may, upon written notice to MainStreet, (1) take such
  //       reasonable and appropriate steps as may be necessary to ensure that
  //       MainStreet&rsquo;s collection and use of Personal Information is
  //       consistent with requirements under the CCPA, and (2) take reasonable
  //       and appropriate steps to stop and remediate any unauthorized use of
  //       Personal Information by MainStreet. Any measures implemented by
  //       Customer under this Section 4.2.7 shall be limited to Personal
  //       Information relevant to Customer
  //     </p>
  //     <p>
  //       <b>5. Virginia-Specific Terms</b>
  //     </p>
  //     <p>
  //       <u>5.1 Obligations</u>
  //     </p>
  //     <p>
  //       5.1.1 MainStreet shall maintain the confidentiality of Personal Data
  //       provided by Customer under the Agreement and require that each person
  //       Processing such Personal Data be subject to a duty of confidentiality
  //       with respect to such Processing.
  //     </p>
  //     <p>
  //       5.1.2 Upon Customer&rsquo;s written request, MainStreet shall delete
  //       or return all Personal Data provided by Customer under the Agreement,
  //       unless retention of such Personal Data is required or authorized by
  //       law or the Addendum and/or Agreement. If return or destruction is
  //       impracticable or prohibited by law, rule or regulation, MainStreet
  //       shall take measures to block such Personal Data from any further
  //       Processing (except to the extent necessary for its continued hosting
  //       or Processing required by law, rule or regulation) and shall continue
  //       to appropriately protect such Personal Data remaining in its
  //       possession, custody, or control.
  //     </p>
  //     <p>
  //       5.1.3 In the event that MainStreet engages a new Processor to assist
  //       MainStreet in providing the Services to Customer under the Agreement
  //       (&lsquo;Sub-Processor&rsquo;), MainStreet shall enter into a written
  //       contract with the Sub-Processor requiring Sub-Processor to meet the
  //       obligations of a Processor with respect to the Personal Data.
  //     </p>
  //     <p>
  //       5.1.4 Upon Customer&rsquo;s written request at reasonable intervals,
  //       and subject to reasonable confidentiality controls, MainStreet shall
  //       either (1) make available for Customer&rsquo;s review copies of
  //       certifications or reports demonstrating MainStreet&rsquo;s compliance
  //       with prevailing data security standards applicable to the Processing
  //       of Personal Data provided by Customer under the Agreement, or (2) if
  //       the provision of reports or certifications pursuant to (1) is not
  //       reasonably sufficient under the VCDPA, MainStreet shall arrange for an
  //       independent third party to conduct an assessment of the
  //       Processor&rsquo;s policies and technical and organizational measures
  //       using an appropriate and accepted control standard or framework and
  //       assessment procedure for such assessments. In such event, the report
  //       produced by the independent third party shall be provided to the
  //       controller upon request. Customer shall be responsible for the costs
  //       of any such audits or inspections, including without limitation a
  //       reimbursement to MainStreet for any time expended for on-site audits.
  //     </p>
  //   </div>
  // </CenterTextBox>
  //
  // );
}
