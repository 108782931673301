import React from 'react';
import { RouteConfiguration } from 'routes/RouteConfiguration';
import { Page } from 'lib/constants';
import { useFeatureFlags } from 'stores/useStores';
import {
  KycApplicationLandingPage,
  KycCompanyApplication,
  KycBeneficiariesApplication,
  KycSuccessPage,
} from 'pages/kyc/components';

export const useKycRoutes = (): RouteConfiguration[] => {
  const featureFlags = useFeatureFlags();

  return [
    {
      path: `/${Page.kycApplication}`,
      render: () => {
        return <KycApplicationLandingPage />;
      },
      hideSideNav: true,
    },
    {
      path: `/${Page.kycCompanyDetails}`,
      render: () => {
        return <KycCompanyApplication />;
      },
      hideSideNav: true,
    },
    {
      path: `/${Page.kycBeneficiaryDetails}`,
      render: () => {
        return <KycBeneficiariesApplication />;
      },
      hideSideNav: true,
    },
    {
      path: `/${Page.kycSuccessPage}`,
      render: () => {
        return <KycSuccessPage />;
      },
      hideSideNav: true,
    },
  ];
};
