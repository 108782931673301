import { observer } from 'mobx-react';
import { SetInSessionStorage } from '../../../lib/sessionStorage';
import { UTM_SOURCING_DATA_KEY } from '../../../stores/accountcreation/AccountCreationStore';
import { SsoButton } from './SsoButton';
import React from 'react';
import { SsoProps } from './SsoProps';

export const XeroSso = observer(
  ({ referrer, sourcing, auth, redirectUri }: SsoProps) => {
    const onXeroSignup = async () => {
      SetInSessionStorage(
        UTM_SOURCING_DATA_KEY,
        JSON.stringify({
          referrer,
          sourcing,
        }),
      );
      await auth.socialLogin('Xero', redirectUri);
    };

    return (
      <SsoButton
        onClick={onXeroSignup}
        src={`${process.env.PUBLIC_URL}/images/sso_logos/xero_logo.png`}
        text={'Sign up with Xero'}
        dataTestId={'xero-sso'}
      />
    );
  },
);
