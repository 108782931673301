import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Button,
  Checkbox,
  Content,
  Color,
  Message,
  Text,
} from 'component-library';
import { useDocumentAccessLink } from 'lib/useDocumentAccessLink';
import { CompanyContext } from 'pages/CompanyRequired';
import { CopyToClipboard } from 'lib/copyToClipboard';

const useStyles = makeStyles(() => ({
  copyLink: {
    padding: 0,
    height: 'auto',
    paddingRight: '24px',
    marginRight: '24px',
    borderRight: `1px solid ${Color.neutral._20}`,
  },
  checkbox: {
    '& label': {
      marginBottom: 0,
    },
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
    '& label': {
      marginBottom: 0,
    },
  },
}));

interface SendDocumentsStepProps {
  currentStep: number;
  handleStep: () => void;
  setShowAlert: (bool: boolean) => void;
  programId: number;
  setToast: (toast: Message[]) => void;
  setIsLinkCopied: (bool: boolean) => void;
}

export const SendDocumentsStep = ({
  currentStep,
  handleStep,
  setShowAlert,
  programId,
  setToast,
  setIsLinkCopied,
}: SendDocumentsStepProps) => {
  const classes = useStyles();
  const { company } = useContext(CompanyContext);
  const [checkboxAnswered, setCheckboxAnswered] = useState<boolean>(false);

  const { accessLink, accessLinkError } = useDocumentAccessLink(
    company.id,
    programId,
  );

  const handleOnCopyLink = () => {
    if (accessLinkError) {
      setToast([
        {
          text: 'There was an error while copying your link. Please try again.',
          status: 'error',
        },
      ]);
    }
    if (accessLink) {
      const onCopied = () => {
        setToast([
          {
            text: 'Link copied to your clipboard.',
            status: 'success',
          },
        ]);
        setIsLinkCopied(true);
      };
      CopyToClipboard(accessLink, onCopied);
    }
  };

  return (
    <Content flex flexDirection='column' gap={24}>
      <Text text="To simplify your work, we've generated a link where your CPA can find all the documents they will need. We recommend you copy this link and email it to your CPA now." />
      <Content flex gap={0} alignItems='center'>
        <Button
          className={classes.copyLink}
          onClick={handleOnCopyLink}
          variant='tertiary'
          label='Copy link to clipboard'
          dataTestId='copy-link-to-clipboard'
        />
        <Checkbox
          className={currentStep === 2 ? classes.checkbox : classes.disabled}
          options={[
            {
              text: "I've sent the link to my CPA",
              checked: checkboxAnswered,
              value: "I've sent the link to my CPA",
            },
          ]}
          onChange={(e) => {
            const checked = e.target.checked;
            if (checked) {
              setCheckboxAnswered(checked);
            }

            setCheckboxAnswered(checked);
          }}
          dataTestId='sent-to-cpa-checkbox'
        />
      </Content>
      {currentStep === 2 && (
        <Button
          dataTestId='send-docs-continue-button'
          label='Continue'
          flexAlignSelf='flex-start'
          onClick={() => {
            handleStep();
            setShowAlert(true);
          }}
          disabled={!checkboxAnswered ?? accessLinkError.length === 0}
          small
        />
      )}
    </Content>
  );
};
