import MSPaymentsClient from 'services/financial/clients/MSPaymentsClient';
import MSPlaidClient from 'services/financial/clients/MSPlaidClient';
import { EmptyClient, ServerClient } from 'services/ServerClient';
import { RootStore } from './RootStore';

export class LegacyClients {
  public client = new ServerClient();
  public paymentsClient = new MSPaymentsClient();
  public plaidClient = new MSPlaidClient();

  constructor(rootStore: RootStore) {
    rootStore.event.on('AUTH_ACCESS_TOKEN', async (params) => {
      const accessToken = params?.token || '';

      EmptyClient.useAccessToken(accessToken);
      this.client.useAccessToken(accessToken);
      this.paymentsClient.useAccessToken(accessToken);
      this.plaidClient.useAccessToken(accessToken);
    });

    rootStore.event.on('FEATURE_FLAGS', async (params) => {
      const featureFlagOverrides = params?.featureFlagOverrides;

      EmptyClient.useFlagOverrides(featureFlagOverrides);
      this.client.useFlagOverrides(featureFlagOverrides);
      this.paymentsClient.useFlagOverrides(featureFlagOverrides);
      this.plaidClient.useFlagOverrides(featureFlagOverrides);
    });

    rootStore.event.on('CSRF_TOKEN', async (params) => {
      const csrfToken = params?.token || '';

      EmptyClient.useCsrfToken(csrfToken);
      this.client.useCsrfToken(csrfToken);
      this.paymentsClient.useCsrfToken(csrfToken);
      this.plaidClient.useCsrfToken(csrfToken);
    });

    rootStore.event.on('ORGANIZATION_ID', async (params) => {
      const organizationId = params?.id || '';

      EmptyClient.useOrganizationId(organizationId);
      this.client.useOrganizationId(organizationId);
      this.paymentsClient.useOrganizationId(organizationId);
      this.plaidClient.useOrganizationId(organizationId);
    });
  }
}
