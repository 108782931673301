import { runInAction } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { BaseEntity } from './BaseEntity';
import { User } from '@auth0/auth0-spa-js';

export class AuthUser extends BaseEntity {
  public userId = '';
  public email = '';
  public emailVerified = false;
  public username = '';
  public name = '';
  public nickname = '';
  public givenName = '';
  public familyName = '';

  constructor(data?: User) {
    super();
    makeSubclassObservable(this);

    if (data) {
      this.mergeData(data);
    }
  }

  public get displayName() {
    if (this.name) {
      return this.name;
    } else if (this.givenName && this.familyName) {
      return `${this.givenName} ${this.familyName}`;
    } else if (this.givenName) {
      return this.givenName;
    } else if (this.email) {
      return this.email;
    } else {
      return 'Unknown User';
    }
  }

  public mergeData(data: User) {
    runInAction(() => {
      this.userId = data.user_id || '';
      this.email = data.email || '';
      this.emailVerified = data.email_verified || false;
      this.username = data.username || '';
      this.name = data.name || '';
      this.nickname = data.nickname || '';
      this.givenName = data.given_name || '';
      this.familyName = data.family_name || '';
    });
  }
}
