import {
  GetLucaCustomerResponse,
  IPaymentsClient,
  PaymentsEnvironment,
  PortalSession,
} from '../types';
import { MSClientResponse } from 'lib/interfaces';
import { AbstractWebClient } from 'services/AbstractWebClient';
import { BillingDefaultPaymentMethod } from '@mainstreet/client-models/financial/payments/paymentsModels';
import { APIV1, COMPANY } from 'lib/constants';
import { datadogLogs } from '@datadog/browser-logs';
import { Get, Post } from 'services/serverGenerics';

const PAYMENTS = 'payments';

/**
 * MSPaymentsClient
 *
 * Used for all interaction with v1 financial paymentsRouter.
 * Recieves `this.addSharedHeaders()` from AbstractWebClient to enable
 * Auth token.
 *
 * Accessed by consuming Auth0Feature context and calling
 * it with `auth0Context.paymentsClient.SomePaymentsMethod`.
 */
export default class MSPaymentsClient extends AbstractWebClient
  implements IPaymentsClient {
  /**
   * @returns BillingDefaultPaymentMethod
   */
  public async getDefaultPaymentMethod(): Promise<
    MSClientResponse<BillingDefaultPaymentMethod>
  > {
    try {
      const res = await Get<BillingDefaultPaymentMethod>(
        `/${APIV1}/${PAYMENTS}/default-payment-method`,
        [200],
        this.addSharedHeaders({
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      );

      return res;
    } catch (err) {
      return {
        errorMsg:
          'Failed to fetch account info. Please try again or reach out to support@mainstreet.com for help',
      };
    }
  }

  /**
   * @returns BillingDefaultPaymentMethod
   */
  public async setDefaultPaymentMethod(
    connectedAccountId: string | null,
    ccPaymentMethodId: string | null,
  ): Promise<MSClientResponse<BillingDefaultPaymentMethod>> {
    const requestBody: Record<string, string> = { processorType: 'stripe' };
    if (connectedAccountId) {
      requestBody.connectedAccountId = connectedAccountId;
    } else if (ccPaymentMethodId) {
      requestBody.ccPaymentMethodId = ccPaymentMethodId;
    }
    try {
      const { res } = await Post<BillingDefaultPaymentMethod>(
        `/${APIV1}/${PAYMENTS}/default-payment-method`,
        [200],
        JSON.stringify(requestBody),
        this.addSharedHeaders({
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      );

      return res;
    } catch (err) {
      return {
        errorMsg:
          'Failed to save payment information. Please try again or reach out to support@mainstreet.com for help',
      };
    }
  }

  public async createCBPortalSession(
    companyId: number,
  ): Promise<MSClientResponse<PortalSession>> {
    try {
      const res = await Get<PortalSession>(
        `${APIV1}/${COMPANY}/${companyId}/cb-portal-session`,
        [200],
        this.addSharedHeaders({
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      );
      return res;
    } catch (err) {
      datadogLogs.logger.error(
        `Error creating chargebee portal session for companyId ${companyId}: ${err}`,
      );
      return {
        errorMsg: `Error occurred creating portal session for companyId: ${companyId}`,
      };
    }
  }

  /**
   * Given a company Id, returns whether or not company has been migrated to chargebee
   * @param companyId
   * @returns
   * @deprecated - can be removed once all customers migrated to chargebee an no UI
   * depends on migration status.
   */
  public async getLucaCustomer(
    companyId: number,
  ): Promise<MSClientResponse<GetLucaCustomerResponse>> {
    try {
      const res = await Get<GetLucaCustomerResponse>(
        `${APIV1}/${COMPANY}/${companyId}/luca-customer`,
        [200],
        this.addSharedHeaders({
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      );
      return res;
    } catch (err) {
      datadogLogs.logger.error(
        `Error fetching chargebee migration status for companyId ${companyId}: ${err}`,
      );
      return {
        errorMsg: `Error occurred fetching migration status for companyId: ${companyId}`,
      };
    }
  }

  /**
   * Retreive operating environment variables from Payments to configure
   * the Chargebee JS browser module
   * @returns PaymentsEnvironment
   */
  public async getPaymentsEnvironment(): Promise<
    MSClientResponse<PaymentsEnvironment>
  > {
    try {
      const res = await Get<PaymentsEnvironment>(
        `${APIV1}/${PAYMENTS}/payments-env`,
        [200],
        this.addSharedHeaders({
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      );
      return res;
    } catch (err) {
      datadogLogs.logger.error(`Error fetching payments environment`);
      return {
        errorMsg: 'Failed to retrieve payments environment',
      };
    }
  }
}
