import React from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ContinueButton } from 'components/ContinueButton';

const useStyles = makeStyles(
  ({ typography, boxShadowRegular, darkerNavy }) => ({
    root: {
      marginTop: '30px',
      maxWidth: '750px',
    },
    paper: {
      boxShadow: boxShadowRegular,
    },
    mainText: {
      fontSize: '30px',
      fontWeight: 'normal',
    },
    lowerText: {
      fontWeight: 'normal',
      color: 'rgba(51, 51, 51, 0.8)',
    },
    icon: {
      ...typography.h1,
    },
    coupon: {
      backgroundColor: 'rgba(229, 229, 229, 0.2);',
    },
    meanTimeText: {
      fontStyle: 'italic',
      color: darkerNavy,
    },
    content: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '1.8',
      color: darkerNavy,
    },
  }),
);

const moveToDashboard = () => {
  window.location.href = '/';
};

export interface FinalMagicMoneyPageProps {
  disqualified: boolean;
}

export const FinalMagicMoneyPage = ({
  disqualified,
}: FinalMagicMoneyPageProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <SummaryCard
          title={
            disqualified ? `Got it! Stay tuned.` : `Savings coming your way`
          }
          description={
            disqualified
              ? `Based on your responses, we won't be able to support you in this pilot quite yet. We'll make sure to reach out ASAP when we're able to support you. If you think this was a mistake, please reach out to your sales team contact and they'll help you get sorted.`
              : `As a next step, your MainStreet team will reach out to schedule onboarding calls to start realizing your savings.`
          }
        />
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12} container justify='center'>
        <ContinueButton
          label='Go back to dashboard'
          onClick={moveToDashboard}
        />
      </Grid>
    </Grid>
  );
};

interface SummaryCardProps {
  title: string;
  description: string;
}

const SummaryCard = ({ title, description }: SummaryCardProps) => {
  const classes = useStyles();

  return (
    <Paper square className={classes.paper}>
      <Grid container>
        <Grid item xs={12}>
          <Box paddingX={8} paddingY={5} textAlign='center'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <span
                  className={classes.icon}
                  role='img'
                  aria-label='stay tuned'
                >
                  👨‍💻
                </span>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h1' className={classes.mainText}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          container
          justify='center'
          className={classes.coupon}
        >
          <Box paddingX={8} paddingY={5} textAlign='center'>
            <Typography variant='h6' className={classes.content}>
              {description}
            </Typography>
          </Box>
        </Grid>
        {
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} container justify='center'>
              <Box paddingX={8} paddingY={5} textAlign='center'>
                <Typography variant='h6' className={classes.meanTimeText}>
                  In the meantime...
                </Typography>
              </Box>
            </Grid>
          </>
        }
      </Grid>
    </Paper>
  );
};
