import React from 'react';
import { SideDrawer, Stepper } from 'component-library';
import { observer } from 'mobx-react';
import { useTaxCreditsStores } from 'stores/useStores';

interface ActivityTimelineSideDrawerProps {
  taxYear: string;
  stepIndex: number;
}

export const ActivityTimelineSideDrawer = observer(
  ({ taxYear, stepIndex }: ActivityTimelineSideDrawerProps) => {
    const { unifiedTaxCredits } = useTaxCreditsStores();

    return (
      <SideDrawer
        show={unifiedTaxCredits.showTimelineSideDrawer}
        closeToggle={() => unifiedTaxCredits.setShowTimelineSideDrawer(false)}
        drawerContent={
          <Stepper
            currentStep={stepIndex}
            orientation='vertical'
            steps={[
              {
                step: 'Estimate your credits',
                description: 'Estimate your tax credit value at a high level.',
              },
              {
                step: 'Complete all assessments',
                description:
                  'Provide detailed information about your business and expenses so we can calculate your actual tax credits.',
              },
              {
                step: 'MainStreet assessment review',
                description:
                  'Our specialists will review your information and work with you on further details to generate your tax credit forms.',
              },
              {
                step: 'We generate the tax forms for you',
                description:
                  'You will need to send these forms to your CPA before filing taxes.',
              },
              {
                step: 'Provide filing details and apply your credits',
                description:
                  'After filing your taxes, you will need to provide your filing details and upload your complete tax return. Payroll tax credits will need to be reported to your payroll provider.',
              },
              {
                step: 'Receive your credits',
                description:
                  'Income credits see the benefit at the time of filing your annual return. Unused credits carry forward. The payroll tax credit benefit begins the quarter after you file. Timing varies depending on provider.',
              },
            ]}
            noVerticalContainerPadding
          />
        }
        subtitle={`This is a timeline of all activities relating to your ${taxYear} tax credits.`}
        title={`${taxYear} Filing Activity Timeline`}
      />
    );
  },
);
