import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  Animate,
  AnswerType,
  AnswerValueType,
  Color,
  Flex,
  Spinner,
  SurveyQuestion,
} from 'component-library';
import { SurveyAnswer, useSurveyQuestions } from 'lib/useSurveyQuestions';
import {
  ERCQualificationStatusEnum,
  ERCQualifyingQuestions,
  ERCQualifyingQuestionsToAnswers,
  SurveyNameEnum,
} from 'lib/constants';
import {
  useCommonStores,
  useCompany,
  useTaxCreditsStores,
} from 'stores/useStores';
import _ from 'lodash';
import { CmsQuestionData } from 'lib/interfaces';
import { useEffectOnce } from 'lib/helpers';
import { ERCQualificationModal } from '../ERCQualificationModal';
import { SurveyFlowContainer } from 'products/tax-credits/components';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    maxWidth: '66%',
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  surveyListText: {
    '& > ul': {
      margin: 0,
      padding: '16px 24px',

      '& > li': {
        paddingBottom: '8px',
        fontSize: '15px',
        lineHeight: '24px',
        color: Color.neutral._80,
      },
    },
  },
  animateIndex: {
    '&:nth-child(2)': {
      zIndex: 1,
    },
  },
}));

interface QualifyForERCProps {
  pathTo: string;
  onNext: () => void;
  isPrefilled: boolean;
}

export const QualifyForERC = observer(
  ({ pathTo, onNext, isPrefilled }: QualifyForERCProps) => {
    const classes = useStyles();
    const { app } = useCommonStores();
    const { company } = useCompany();
    const history = useHistory();
    const { erc } = useTaxCreditsStores();

    const {
      isSurveyComplete,
      isLoading,
      surveyAnswers,
      questionsToRender,
      addSurveyAnswers,
      addSurveyAnswersWithoutRerender,
      saveAnswers,
    } = useSurveyQuestions(
      SurveyNameEnum.ERC_QUALIFYING_QUESTIONS_SURVEY,
      2021,
    );

    const handleOnContinue = () => {
      if (erc.qualificationStatus) {
        erc.setShowQualificationStatusModal(true);
      } else {
        onNext();
        history.push(pathTo);
      }
    };

    const scrollIntoViewOnKeyDown = () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    };

    const handleAnswerChange = (
      question: CmsQuestionData,
      answerValue: AnswerValueType,
      key: string,
    ) => {
      // in case user changes answer after answering follow-up questions
      // remove follow-up question answer ids
      if (
        Array.isArray(answerValue) &&
        question.id === ERCQualifyingQuestions.RELATED_DURING_COVID19_CRISIS
      ) {
        const hasNoRelatedCovid19Business = answerValue.includes(
          ERCQualifyingQuestionsToAnswers[
            ERCQualifyingQuestions.RELATED_DURING_COVID19_CRISIS
          ].NONE_OF_THE_ABOVE,
        );

        if (hasNoRelatedCovid19Business) {
          const removeFollowUpQuestionAnswers: SurveyAnswer[] = [
            {
              questionId: ERCQualifyingQuestions.ORIGINAL_ENTITY_FOUNDED,
              answerValue: '',
            },
            {
              questionId:
                ERCQualifyingQuestions.OLDEST_SIMILARITY_OWNED_BUSINESS_FOUNDED,
              answerValue: '',
            },
            {
              questionId:
                ERCQualifyingQuestions.OLDEST_ACQUIRED_COMPANY_FOUNDED,
              answerValue: '',
            },
          ];

          addSurveyAnswersWithoutRerender(removeFollowUpQuestionAnswers);

          // update surveyAnswers to retain empty follow-up answers
          surveyAnswers[ERCQualifyingQuestions.ORIGINAL_ENTITY_FOUNDED] = '';
          surveyAnswers[
            ERCQualifyingQuestions.OLDEST_SIMILARITY_OWNED_BUSINESS_FOUNDED
          ] = '';
          surveyAnswers[
            ERCQualifyingQuestions.OLDEST_ACQUIRED_COMPANY_FOUNDED
          ] = '';
        }

        saveAnswers && saveAnswers();
      }

      question.answerValue = answerValue;
      addSurveyAnswers(question.id, key, [
        { questionId: question.id, answerValue: answerValue },
      ]);
      // save answers per question answered
      if (
        question.answerType === 'multiple_select' ||
        question.answerType === 'multiple_choice' ||
        question.answerType === 'yes_or_no'
      ) {
        saveAnswers && saveAnswers();
      }
    };

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => (
          <Animate
            key={question.id}
            enter={['fade-in', 'from-bottom']}
            duration={0.5}
            className={classes.animateIndex}
          >
            <SurveyQuestion
              dataTestId={question.id}
              answerType={question.answerType as AnswerType}
              text={question.text}
              subtitle={question.subtitle}
              answerOptions={question.answerIDs}
              onChange={(answer) => handleAnswerChange(question, answer, key)}
              onBlur={() => saveAnswers && saveAnswers()}
              placeholder={question.placeholder}
              answerValue={question.answerValue}
              tooltip={question.tooltip}
              questionTextSize={app.isMobile ? 15 : 18}
              questionSubtitleSize={app.isMobile ? 13 : 15}
              withCardMargin={false}
            />
          </Animate>
        ));
      });

    // prefill confirm founded year question based on company.yearFounded
    useEffectOnce(async () => {
      // Fallback to ensure that the proper ERC estimates are created
      await erc.createERCProgramCredit(2020);
      await erc.createERCProgramCredit(2021);
      const foundedYear = company.yearFounded;

      if (
        foundedYear &&
        !surveyAnswers[ERCQualifyingQuestions.CONFIRM_FOUNDED_YEAR]
      ) {
        surveyAnswers[ERCQualifyingQuestions.CONFIRM_FOUNDED_YEAR] =
          foundedYear;
      }
    });

    useEffect(() => {
      // scroll page down as user answers each question
      scrollIntoViewOnKeyDown();

      // checking survey answers to display qualification modal
      erc.checkQualificationStatus(surveyAnswers);
    }, [surveyAnswers, erc]);

    return (
      <SurveyFlowContainer
        title='Qualify your company for Employee Retention Credits'
        onContinue={handleOnContinue}
        isDisabled={!isSurveyComplete}
        dataTestId='erc-qualify-survey'
      >
        <Flex className={classes.container} direction='column' gap={24}>
          {isLoading ? (
            <Spinner size='small' />
          ) : (
            !_.isEmpty(questionsToRender) && renderQuestions()
          )}
          {erc.qualificationStatus && (
            <ERCQualificationModal
              onClick={async () => {
                erc.setModalContinueIsLoading(true);

                const dqStatuses = [
                  ERCQualificationStatusEnum.DQ_COMPANY_CLAIMED_ERC,
                  ERCQualificationStatusEnum.DQ_COMPANY_FOUNDED_AFTER_2021,
                ];

                if (erc.qualificationStatus) {
                  // TODO: Separate out 2020 and 2021
                  const isDisqualified = dqStatuses.includes(
                    erc.qualificationStatus,
                  );

                  if (isDisqualified) {
                    await erc
                      .disqualifyAllPrograms()
                      .then(() => history.push('/'));
                  } else {
                    onNext();
                    history.push(pathTo);
                  }
                } else {
                  onNext();
                  history.push(pathTo);
                }

                erc.setModalContinueIsLoading(false);
                erc.setShowQualificationStatusModal(false);
              }}
            />
          )}
        </Flex>
      </SurveyFlowContainer>
    );
  },
);
