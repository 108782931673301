export const getPartnerReferralFromCookie = (cookieValue: string) => {
  switch (cookieValue) {
    case 'qb':
      return 'quickbooks';
    case 'op':
      return 'onpay';
    case 'fc':
      return 'forecastr';
    case 'ss':
      return 'startup-stack';
    case 'gl':
      return 'guideline';
    case 'dg':
      return 'digits';
    default:
      return null;
  }
};
