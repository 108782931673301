import React from 'react';
import { Card, Content, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';

interface OnboardingQuestionsProps {
  flex?: boolean;
  description?: string;
  question: string;
  renderAction: React.ReactNode | JSX.Element;
}

const useStyles = makeStyles(() => ({
  cardMargin: {
    margin: 0,
  },
  cardTitleMargin: {
    margin: '8px 0',
  },
}));

export const OnboardingQuestions = ({
  flex,
  description,
  question,
  renderAction,
}: OnboardingQuestionsProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.cardMargin}>
      <Content>
        <Text size={18} variant='medium'>
          {question}
        </Text>
        {description && (
          <Text className={classes.cardTitleMargin}>{description}</Text>
        )}
      </Content>
      <Content flex={flex}>{renderAction}</Content>
    </Card>
  );
};
