import { Callout, Content, Link, Text } from 'component-library';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Page } from 'lib/constants';

const useStyles = makeStyles(() => ({
  iconPadding: {
    paddingTop: '6px',
  },
  bullet: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > img': {
      marginRight: '16px',
    },
  },
}));

const OverviewCallout = () => {
  const classes = useStyles();
  const [isCalloutShowingMore, setIsCalloutShowingMore] = useState<boolean>(
    true,
  );

  return (
    <Content paddingTopBottom={32}>
      <Callout
        hasShowMore
        showMore={isCalloutShowingMore}
        showMoreOnClick={(value: boolean) => setIsCalloutShowingMore(value)}
        title='Some things to have in mind'
      >
        <Content flex flexDirection='column' gap={16}>
          <div className={classes.bullet}>
            <img
              className={classes.iconPadding}
              src={`${process.env.PUBLIC_URL}/images/diamond.svg`}
              alt='MainStreet Diamond'
            />
            <Text
              text='This process may take between 3 and 6 minutes to complete.'
              variant='medium'
            />
          </div>
          <div className={classes.bullet}>
            <img
              className={classes.iconPadding}
              src={`${process.env.PUBLIC_URL}/images/diamond.svg`}
              alt='MainStreet Diamond'
            />
            <Text
              text='Each step is distinct, so feel free to take a break between steps!'
              variant='medium'
            />
          </div>
          <div className={classes.bullet}>
            <img
              className={classes.iconPadding}
              src={`${process.env.PUBLIC_URL}/images/diamond.svg`}
              alt='MainStreet Diamond'
            />
            <div>
              <Text
                text='You will need to have some information handy:'
                variant='medium'
              />
              <Text text='Your payroll, R&D supplies, business structure, ownership, and previous years’ tax returns.' />
            </div>
          </div>

          <div className={classes.bullet}>
            <img
              className={classes.iconPadding}
              src={`${process.env.PUBLIC_URL}/images/diamond.svg`}
              alt='MainStreet Diamond'
            />
            <Text variant='medium'>
              If you&apos;d like help from your CPA or someone else at your
              company, &nbsp;
              <Link
                text='invite them to complete a step or two.'
                variant='medium'
                href={`/${Page.settings}`}
                external
              />
            </Text>
          </div>
        </Content>
      </Callout>
    </Content>
  );
};
export default OverviewCallout;
