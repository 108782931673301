import React from 'react';
import { Flex, Color } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  progressBar: {
    width: '100px',
    height: '6px',
    backgroundColor: Color.neutral._20,
    '& > div:last-child': {
      borderRight: 'none',
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    '& > div:first-child': {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    '@media only screen and (max-width: 1025px)': {
      display: 'none',
    },
  },
  progressCellUnfilled: {
    width: '20px',
    borderRight: `1px solid ${Color.neutral._40}`,
  },
  progressCellFilled: {
    width: '20px',
    backgroundColor: Color.green._50,
    borderRight: `1px solid ${Color.green._70}`,
  },
}));

interface ProgressBarProps {
  step: number;
  isFutureProgram: boolean;
}
export const ProgressBar: React.FC<ProgressBarProps> = observer(
  ({ step, isFutureProgram }) => {
    const classes = useStyles();

    if (isFutureProgram) {
      return (
        <Flex className={classes.progressBar}>
          <div className={classes.progressCellUnfilled}></div>
          <div className={classes.progressCellUnfilled}></div>
          <div className={classes.progressCellUnfilled}></div>
          <div className={classes.progressCellUnfilled}></div>
          <div className={classes.progressCellUnfilled}></div>
        </Flex>
      );
    }

    return (
      <Flex className={classes.progressBar}>
        <div
          className={
            step > 0 ? classes.progressCellFilled : classes.progressCellUnfilled
          }
        ></div>
        <div
          className={
            step > 1 ? classes.progressCellFilled : classes.progressCellUnfilled
          }
        ></div>
        <div
          className={
            step > 2 ? classes.progressCellFilled : classes.progressCellUnfilled
          }
        ></div>
        <div
          className={
            step > 3 ? classes.progressCellFilled : classes.progressCellUnfilled
          }
        ></div>
        <div
          className={
            step > 4 ? classes.progressCellFilled : classes.progressCellUnfilled
          }
        ></div>
      </Flex>
    );
  },
);
