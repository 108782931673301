import { RouteConfiguration } from './RouteConfiguration';
import { useLegalRoutes } from './LegalRoutes';
import { useAuthRoutes } from './AuthRoutes';
import { useTaxCreditsRoutes } from './TaxCreditsRoutes';
import { useCoreExperienceRoutes } from './CoreExperienceRoutes';
import { useIntegrationRoutes } from './IntegrationRoutes';
import { useTreasuryManagementRoutes } from './TreasuryManagementRoutes';
import { useKycRoutes } from './KycRoutes';
import { useDashboardRoutes } from './DashboardRoutes';
import { useOnboardingRoutes } from './OnboardingRoutes';

export const useMainstreetRoutes = (): RouteConfiguration[] => {
  return [
    ...useLegalRoutes(),
    ...useAuthRoutes(),
    ...useCoreExperienceRoutes(),
    ...useTaxCreditsRoutes(),
    ...useIntegrationRoutes(),
    ...useTreasuryManagementRoutes(),
    ...useKycRoutes(),
    ...useDashboardRoutes(),
    ...useOnboardingRoutes(),
  ];
};
