import React from 'react';
import { ContentContainer } from '../component';
import { observer } from 'mobx-react';
import {
  useAccountCreationStores,
  useCommonStores,
  useHistoryStore,
} from 'stores/useStores';
import { Content, Dropdown, Flex, Text, TextField } from 'component-library';
import { useEffectOnce } from 'lib/helpers';
import {
  AccountCreationRequest,
  AccountCreationSteps,
  SurveyAnswerTypeEnum,
  SurveyQuestionInterface,
} from 'lib/interfaces';
import {
  AdminJobTitleOptionsBusinessStep,
  CompanyDataEnum,
} from 'lib/constants';

interface BusinessStepProps {
  pathTo: string;
  onNext: () => void;
}

export const BusinessStep = observer(
  ({ pathTo, onNext }: BusinessStepProps) => {
    const { accountCreation } = useAccountCreationStores();
    const { app } = useCommonStores();
    const history = useHistoryStore();

    const isNextButtonDisabled = accountCreation.enableNextButton(
      accountCreation.BusinessStepQuestions,
    );

    const onContinue = async () => {
      // set button loading
      accountCreation.isNextButtonLoading = true;
      const {
        adminFirstName,
        adminLastName,
        legalName,
        name,
        website,
        businessPhoneNumber,
        adminJobTitle,
      } = getStepAnswers(accountCreation.BusinessStepQuestions);

      await accountCreation.updateAccount(
        {
          [CompanyDataEnum.ADMIN_FIRST_NAME]: adminFirstName as string,
          [CompanyDataEnum.ADMIN_LAST_NAME]: adminLastName as string,
          [CompanyDataEnum.LEGAL_NAME]: legalName as string,
          [CompanyDataEnum.NAME]: name as string,
          [CompanyDataEnum.WEBSITE]: website as string,
          [CompanyDataEnum.BUSINESS_PHONE_NUMBER]:
            businessPhoneNumber as string,
          [CompanyDataEnum.ADMIN_JOB_TITLE]: adminJobTitle as string,
        },
        AccountCreationSteps.BUSINESS,
        true, // is unified account creation
      );

      if (!accountCreation.errorMsg) {
        history.push(pathTo);
        onNext();
      }
    };

    useEffectOnce(() => accountCreation.setUnifiedBusinessStepQuestions());

    const renderQuestion = (item: SurveyQuestionInterface, index: number) => {
      switch (item.id) {
        case CompanyDataEnum.BUSINESS_PHONE_NUMBER:
          return (
            <Content flex flexDirection='column' gap={8}>
              <TextField
                dataTestId={`account_creation_${item.id}`}
                value={item.answer}
                phoneNumber={
                  item.answerType === SurveyAnswerTypeEnum.PHONE_NUMBER
                }
                placeholder={item.placeholder}
                onChange={(event) => {
                  const value = event.target.value;
                  accountCreation.setInputChange(
                    accountCreation.BusinessStepQuestions,
                    item.question,
                    value,
                  );
                }}
              />
            </Content>
          );
        case CompanyDataEnum.ADMIN_JOB_TITLE:
          return (
            <Dropdown
              dataTestId={`account_creation_${CompanyDataEnum.ADMIN_JOB_TITLE}`}
              value={
                item.answer
                  ? AdminJobTitleOptionsBusinessStep.find(
                      (title) => title.value === item.answer,
                    )?.name
                  : ''
              }
              options={AdminJobTitleOptionsBusinessStep}
              placeholder='Please select a role'
              onInputChange={(str) => {
                if (typeof str !== 'string') {
                  accountCreation.setInputChange(
                    accountCreation.BusinessStepQuestions,
                    item.question,
                    str.value,
                  );
                }
              }}
              selectOnly
            />
          );
        default:
          return (
            <TextField
              name={`account_creation_${item.id}`}
              dataTestId={`account_creation_${item.id}`}
              placeholder={item.placeholder}
              value={item.answer ?? ''}
              autoFocus={index === 0}
              yearFormat={item.answerType === SurveyAnswerTypeEnum.YEAR}
              onChange={(event) => {
                const value = event.target.value;
                if (item.answerType === SurveyAnswerTypeEnum.YEAR) {
                  accountCreation.setInputChange(
                    accountCreation.BusinessStepQuestions,
                    item.question,
                    !value ? '' : Number(value),
                  );
                } else {
                  accountCreation.setInputChange(
                    accountCreation.BusinessStepQuestions,
                    item.question,
                    value,
                  );
                }
              }}
              error={
                item.answerType === SurveyAnswerTypeEnum.YEAR &&
                accountCreation.errorMsg.length > 0
              }
              helperText={
                item.answerType === SurveyAnswerTypeEnum.YEAR &&
                accountCreation.errorMsg &&
                'Please enter a valid founded year.'
              }
            />
          );
      }
    };

    return (
      <ContentContainer
        onNext={onContinue}
        isNextButtonDisabled={isNextButtonDisabled}
        isNextLoading={accountCreation.isNextButtonLoading}
      >
        <Flex alignItems='start' direction='column' gap={8}>
          <Text
            text='Tell us about your business'
            variant='medium'
            size={app.isMobile ? 23 : 32}
          />
          <Text
            text='Your answers will be used to create a personalized MainStreet savings estimate.'
            size={15}
          />
        </Flex>
        {accountCreation.BusinessStepQuestions.map((item, index) => {
          return (
            <Content key={item.id} paddingLeftRight={0} paddingTopBottom={0}>
              <Text variant='medium' paddingBottom={8}>
                {item.question}
              </Text>
              {renderQuestion(item, index)}
            </Content>
          );
        })}
      </ContentContainer>
    );
  },
);

const getStepAnswers = (
  stepQuestions: SurveyQuestionInterface[],
): AccountCreationRequest => {
  const legalName = stepQuestions.find(
    (item) => item.id === CompanyDataEnum.LEGAL_NAME,
  )?.answer as string;
  const adminFirstName = stepQuestions.find(
    (item) => item.id === CompanyDataEnum.ADMIN_FIRST_NAME,
  )?.answer as string;
  const adminLastName = stepQuestions.find(
    (item) => item.id === CompanyDataEnum.ADMIN_LAST_NAME,
  )?.answer as string;
  const name = stepQuestions.find((item) => item.id === CompanyDataEnum.NAME)
    ?.answer as string;
  const website = stepQuestions.find(
    (item) => item.id === CompanyDataEnum.WEBSITE,
  )?.answer as string;
  const businessPhoneNumber = stepQuestions.find(
    (item) => item.id === CompanyDataEnum.BUSINESS_PHONE_NUMBER,
  )?.answer as string;
  const adminJobTitle = stepQuestions.find(
    (item) => item.id === CompanyDataEnum.ADMIN_JOB_TITLE,
  )?.answer as string;

  return {
    legalName,
    adminFirstName,
    adminLastName,
    name,
    website,
    businessPhoneNumber,
    adminJobTitle,
  };
};
