import React, { useContext, useEffect } from 'react';
import {
  Animate,
  Button,
  Color,
  Flex,
  Grid,
  Heading,
  Spinner,
  SurveyQuestion,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import TitleHeader from 'components/TitleHeader';
import {
  useCommonStores,
  useCompany,
  useFeatureFlags,
  useOnboardingStores,
} from 'stores/useStores';
import { ConnectPayroll } from './components';
import {
  AutoqualificationStatusEnum,
  Page,
  SurveyNameEnum,
} from 'lib/constants';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import { AnswerType } from 'component-library/dashboard/surveyquestion/SurveyQuestion';
import _ from 'lodash';
import { useEffectOnce } from 'lib/helpers';
import { TokenContext } from 'pages/TokenRequired';
import DOMPurify from 'dompurify';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { datadogLogs } from '@datadog/browser-logs';
import { ImportType } from 'lib/interfaces';

const useStyles = makeStyles(() => ({
  '@global': {
    '.embeddedServiceHelpButton': {
      display: 'none',
    },
  },
  root: {
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    maxWidth: '982px',
    margin: '0 auto',
  },
  contentArea: {
    position: 'relative',
  },
  footer: {
    marginTop: 'auto',
  },
  connectPayroll: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },
  surveyListText: {
    '& > ul': {
      margin: 0,
      padding: '16px 24px',

      '& > li': {
        paddingBottom: '8px',
        fontSize: '15px',
        lineHeight: '24px',
        color: Color.neutral._80,
      },
    },
  },
}));

interface QualificationProps {
  taxYear: number;
}

export const Qualification: React.FC<QualificationProps> = observer(
  ({ taxYear }) => {
    const classes = useStyles();
    const { qualification } = useOnboardingStores();
    const { app, chatbot } = useCommonStores();
    const { company } = useCompany();
    const store = qualification;
    const { token, email } = useContext(TokenContext);
    const featureFlags = useFeatureFlags();
    const hidePayrollStep = featureFlags.hidePayrollStepInQualification;
    const showQualDqExperience = featureFlags.showQualificationDqExperience;
    const { client } = useContext(Auth0FeatureContext);

    const {
      isSurveyComplete,
      isLoading,
      questionsToRender,
      addSurveyAnswers,
      saveAnswers,
    } = useSurveyQuestions(
      SurveyNameEnum.UNIFIED_QUALIFICATION_SURVEY,
      taxYear,
    );

    const scrollIntoViewOnKeyDown = () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    };

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => {
          const subtitleList =
            question.subtitle && question.subtitle.includes('<ul>');

          return (
            <Animate
              key={question.id}
              enter={['fade-in', 'from-bottom']}
              duration={0.5}
            >
              <SurveyQuestion
                dataTestId={question.id}
                answerType={question.answerType as AnswerType}
                text={question.text}
                subtitle={
                  subtitleList ? (
                    <div
                      className={classes.surveyListText}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(question.subtitle),
                      }}
                    />
                  ) : (
                    question.subtitle
                  )
                }
                answerOptions={question.answerIDs}
                onChange={(answer) => {
                  question.answerValue = answer;
                  addSurveyAnswers(question.id, key, [
                    { questionId: question.id, answerValue: answer },
                  ]);
                }}
                placeholder={question.placeholder}
                answerValue={question.answerValue}
                tooltip={question.tooltip}
                questionTextSize={app.isMobile ? 15 : 18}
                questionSubtitleSize={app.isMobile ? 13 : 15}
                chatBotQuestion={chatbot.isAiQuestionEnabled(
                  question.aiQuestion,
                )}
                chatBotPrompt={() =>
                  chatbot.autoSendAiMessage(question.aiQuestion)
                }
                withCardMargin={false}
              />
            </Animate>
          );
        });
      });

    const onContinue = async () => {
      store.setOnSubmitLoading(true);
      const res = await saveAnswers();

      if (
        res.autoqualificationStatus === AutoqualificationStatusEnum.DISQUALIFIED
      ) {
        if (showQualDqExperience) {
          app.history.push(
            `/${Page.taxCredits}/${Page.disqualified}/${taxYear}`,
          );
        } else {
          app.history.push(`/`);
        }
      } else {
        app.history.push(`/${Page.taxCreditEstimate}/${taxYear}`);
      }
      store.setOnSubmitLoading(false);
    };

    // scroll page down as user answers each question
    useEffect(() => scrollIntoViewOnKeyDown(), [addSurveyAnswers]);
    useEffectOnce(async () => {
      await store.setPayrollSystems(email, token, taxYear);
      if (hidePayrollStep) {
        store.setConnectPayrollDoThisLater(true);
      }
    });

    useEffectOnce(async () => {
      if (company.misc && !company.misc.autoQualificationLoaded) {
        company.misc = {
          ...company.misc,
          autoQualificationLoaded: true,
        };
        datadogLogs.logger.info(
          `[QUALIFICATION]: Company loaded auto qualification for the first time`,
          {
            companyId: company.id,
          },
        );
        await client.UpdateCompanyMisc({ autoQualificationLoaded: true });
      }
    });

    return (
      <Flex
        className={classes.root}
        direction='column'
        data-testid={'unified-qualification'}
      >
        <Animate enter={['fade-in', 'from-top']}>
          <TitleHeader title='Estimate your credits' borderBottom />
        </Animate>
        <Animate enter={'fade-in'}>
          <Grid
            columns={12}
            className={classes.mainContent}
            padding={app.isMobile ? 24 : [24, 24, 48, 24]}
          >
            <Grid.Cell columns={12}>
              <Flex direction='column' gap={32}>
                <Flex
                  className={classes.contentArea}
                  direction='column'
                  gap={24}
                >
                  <Heading
                    text='Qualify your company for R&D Tax Credits'
                    size={32}
                  />

                  {!hidePayrollStep && (
                    <ConnectPayroll
                      taxYear={taxYear}
                      importType={ImportType.Qualification}
                      setDoThisLater={() =>
                        store.setConnectPayrollDoThisLater(true)
                      }
                    />
                  )}
                  {store.connectPayrollDoThisLater && (
                    <>
                      {isLoading ? (
                        <Spinner size='small' />
                      ) : (
                        !_.isEmpty(questionsToRender) && renderQuestions()
                      )}
                    </>
                  )}
                  {isSurveyComplete && (
                    <Button
                      label='Continue'
                      onClick={onContinue}
                      loading={store.onSubmitLoading}
                      flexAlignSelf='flex-start'
                    />
                  )}
                </Flex>
              </Flex>
            </Grid.Cell>
          </Grid>
        </Animate>
      </Flex>
    );
  },
);
