import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Button, Flex, Modal, Text } from 'component-library';
import { ToDoCardIdEnum } from 'lib/constants';
import { useDashboardStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  modalContainer: {
    zIndex: 99999,
  },
}));

export const DismissModal: React.FC = observer(() => {
  const { modules } = useDashboardStores();
  const classes = useStyles();

  const getDismissCardText = (id: ToDoCardIdEnum) => {
    switch (id) {
      case ToDoCardIdEnum.CONNECT_YOUR_ACCOUNTING:
        return 'You can connect your accounting later by visiting Connections.';
      case ToDoCardIdEnum.CONNECT_YOUR_PAYROLL:
        return 'You can connect your payroll later by visiting Connections.';
      case ToDoCardIdEnum.INVITE_YOUR_TEAM:
        return 'You can add team members later by visiting Settings.';
    }
  };

  return (
    <Modal
      dataTestId='dismiss-to-do-card-modal'
      showModal={modules.showDismissModal}
      closeToggle={() => modules.toggleDismissModal(false)}
      maxWidth={536}
      className={classes.modalContainer}
    >
      <>
        <Flex padding={[24, 24, 0, 24]} direction='column'>
          <Text
            variant='medium'
            size={18}
            text='Are you sure you want to dismiss this card?'
            paddingBottom={16}
          />
          <Text text={getDismissCardText(modules.idToDismiss)} />
        </Flex>
        <Flex gap={16} padding={24}>
          <Button
            label='Dismiss'
            onClick={() => {
              modules.dismissCard(modules.idToDismiss);
              modules.toggleDismissModal(false);
            }}
            dataTestId='dismiss_modal_button'
          />
          <Button
            variant='outlined'
            label='Cancel'
            onClick={() => modules.toggleDismissModal(false)}
            dataTestId='cancel_modal_button'
          />
        </Flex>
      </>
    </Modal>
  );
});
