import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Text, Color, Flex, Icon, IconEnum } from 'component-library';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: Color.green._90,
    borderRadius: '8px 8px 0 0',
    minHeight: '22%',
  },
  newUserRoot: {
    backgroundColor: Color.green._90,
    borderRadius: '8px 8px 0 0',
    height: '90%',
  },
  img: {
    width: '40px',
  },
  betaBadge: {
    border: `1px solid ${Color.lime._60}`,
    borderRadius: '4px',
    width: '3rem',
    marginLeft: '0.5rem',
    height: '20px',
    paddingTop: '1px',
  },
  closeButton: {
    borderRadius: '50%',
    '&:hover': {
      background: Color.green._80,
    },
  },
}));

type ChatWindowHeaderProps = {
  newUserModal?: boolean;
};

export const ChatWindowHeader = observer(
  ({ newUserModal }: ChatWindowHeaderProps) => {
    const classes = useStyles();
    const { chatbot } = useCommonStores();

    return (
      <Flex
        className={newUserModal ? classes.newUserRoot : classes.root}
        direction='column'
        padding={[24, 16]}
      >
        <Flex
          justifyContent='space-between'
          alignItems='start'
          padding={[0, 0, 24, 0]}
        >
          <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/images/logo/mst-logomark-light.svg`}
          />
          <Icon
            name={IconEnum.x_mark}
            color={Color.neutral.white}
            size={32}
            onClick={() => chatbot.toggleChatWindow()}
            className={classes.closeButton}
          />
        </Flex>
        <Flex justifyContent='start' alignItems='center'>
          <Text
            size={23}
            variant='medium'
            text='MainStreet Support'
            color={Color.neutral.white}
          />
          <Flex
            className={classes.betaBadge}
            justifyContent='center'
            alignItems='center'
          >
            <Text
              size={13}
              variant='medium'
              text='BETA'
              color={Color.lime._60}
            />
          </Flex>
        </Flex>
        <Text
          size={15}
          text={`Let’s get your questions answered.`}
          color={Color.neutral._40}
        />
      </Flex>
    );
  },
);
