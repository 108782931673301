import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  Card,
  Content,
  Divider,
  Text,
  Link,
  LinkProps,
  Color,
} from 'component-library';
import * as React from 'react';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(({ darkerNavy, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '25px',
  },
  headerContent: {
    fontSize: '14px',
    color: darkerNavy,
    padding: '20px 40px',
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    height: '15px',
    width: '15px',
    marginRight: '16px',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '24px',
    [breakpoints.down('xs')]: {
      gap: '16px',
    },
  },
  lowerContainer: {
    marginTop: '28px',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '25px',
    width: '100%',
  },
  estimate: {
    color: Color.green._70,
    fontSize: '28px',
    fontWeight: 500,
    marginBottom: '10px',
    [breakpoints.down('xs')]: {
      fontSize: '24px',
    },
  },
  estimateText: {
    color: Color.neutral._90,
    [breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  },
  symbol: {
    color: Color.green._70,
    fontSize: '28px',
    marginRight: '4px',
  },
  icon: {
    height: '52px',
    width: '52px',
  },
  fullWidth: {
    width: '100%',
  },
  divider: {
    width: '100%',
    height: '1px',
    background: Color.neutral._20,
  },
}));

// Call to action button
export interface Cta {
  display: string;
  path?: string;
  additionalContent?: JSX.Element;
  onClick?: () => void;
  loading?: boolean;
}

const instanceOfCta = (object: Cta | JSX.Element): object is Cta => {
  return 'display' in object;
};

interface DashboardHeader {
  text: string;
  iconLocation: string;
}

export interface DashboardCardProps {
  header?: DashboardHeader | null;
  iconLocation: string;
  title: string;
  estimate?: string | number | undefined;
  buttonSize?: 'small' | 'default' | 'large';
  ctaDisabled?: boolean;
  description: string | JSX.Element;
  cta?: Cta | JSX.Element;
  secondaryCta?: Cta;
  estimateDescription?: string;
  linkArgs?: LinkProps;
  dataTestId?: string;
}

export const DashboardCard = ({
  header,
  title,
  description,
  estimate,
  cta,
  ctaDisabled = false,
  secondaryCta,
  iconLocation,
  estimateDescription,
  linkArgs,
  dataTestId,
}: DashboardCardProps) => {
  const classes = useStyles();
  const { app } = useCommonStores();

  const convertCtaToJsx = (cta: Cta | JSX.Element): JSX.Element => {
    if (instanceOfCta(cta)) {
      return (
        <Button
          data-testid='dashboard-card-button'
          label={cta.display}
          onClick={
            cta.path
              ? { location: cta.path, external: true, target: '_self' }
              : () => {
                  cta.onClick && cta.onClick();
                }
          }
          disabled={(!cta.path && !cta.onClick) || ctaDisabled}
          loading={cta.loading}
          variant='outlined'
        />
      );
    } else {
      return cta;
    }
  };

  return (
    <Card dataTestId={dataTestId}>
      {header && (
        <>
          <Content className={classes.headerContent} paddingLeftRight={24}>
            {header.iconLocation && (
              <img
                className={classes.headerIcon}
                src={header.iconLocation}
                alt='Icon'
              />
            )}
            {header.text}
          </Content>
          <Divider />
        </>
      )}
      <Content paddingTopBottom={16} paddingLeftRight={24}>
        {!estimate && (
          <div className={classes.root}>
            <div className={classes.contentContainer}>
              <img className={classes.icon} src={iconLocation} alt='Icon' />
              <div>
                <Text variant='medium'>{title}</Text>
                <Text>
                  {description} <Link {...linkArgs} />
                </Text>
              </div>
            </div>
            <div>{!!cta && convertCtaToJsx(cta)}</div>
          </div>
        )}

        {estimate && (
          <div className={classes.flexRow}>
            {!app.isMobile && (
              <img className={classes.icon} src={iconLocation} alt='Icon' />
            )}
            <div className={classes.fullWidth}>
              <div className={classes.contentContainer}>
                <div>
                  <Text variant='medium'>{title}</Text>
                  <Text>{description}</Text>
                </div>
              </div>
              <div
                className={`${classes.contentContainer} ${classes.lowerContainer}`}
              >
                <div>
                  <div className={classes.estimate}>
                    <span className={classes.symbol}>$</span>
                    {estimate}
                  </div>
                  <div className={classes.estimateText}>
                    {estimateDescription || 'Estimated credit amount'}
                  </div>
                </div>
                <div>{!!cta && convertCtaToJsx(cta)}</div>
              </div>
            </div>
          </div>
        )}
      </Content>
      {secondaryCta && (
        <>
          <Divider />
          <Content flex>{convertCtaToJsx(secondaryCta)}</Content>
        </>
      )}
    </Card>
  );
};
