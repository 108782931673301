import React from 'react';
import { observer } from 'mobx-react';
import {
  Color,
  Expandable,
  FontAwesome,
  Grid,
  Link,
  Text,
} from 'component-library';
import moment from 'moment';
import { useFeatureFlags } from '../../../../../stores/useStores';
import { ProgramNameEnum } from '../../../../../lib/constants';
import { SubscriptionContent } from './mainStreetFeeContent/SubscriptionContent';
import { OnDeliveryContent } from './mainStreetFeeContent/OnDeliveryContent';
import { ProgramData } from '../../../../../lib/interfaces';

interface MainStreetFeeContentProps {
  expandSchedule: boolean;
  hasMinAcvFloor: boolean;
  termStartDate: string;
  monthlyFeeCents: number;
  numFeeMonths: number;
  program: ProgramData;
}

export const MainStreetFeeContent: React.FC<MainStreetFeeContentProps> =
  observer(
    ({
      expandSchedule,
      hasMinAcvFloor,
      termStartDate,
      monthlyFeeCents,
      numFeeMonths,
      program,
    }) => {
      const featureFlags = useFeatureFlags();

      const contentConfig: {
        [key: string]: React.ReactElement;
      } = {
        [ProgramNameEnum.FED_RD_TAX]: (
          <SubscriptionContent
            monthlyFeeCents={monthlyFeeCents}
            numFeeMonths={numFeeMonths}
          />
        ),
        [ProgramNameEnum.FED_DISABLED_ACCESS]: <OnDeliveryContent />,
        [ProgramNameEnum.FED_EMPLOYER_HEALTHCARE]: <OnDeliveryContent />,
      };

      return (
        <Grid.Cell
          columns={12}
          padding={!expandSchedule ? [24, 24, 0, 24] : 24}
          alignSelf='center'
          style={{ borderTop: `1px solid ${Color.neutral._20}` }}
        >
          <Grid columns={12} alignItems='center' gap={24}>
            <Grid.Cell columns={12}>
              {hasMinAcvFloor ? (
                <Text
                  text={
                    'This is our minimum fee. If your final credit ends up being higher than expected when we prepare your final tax documents, we will charge up to 20% of the final credit we help you claim'
                  }
                />
              ) : featureFlags.showCopyWDiscountLanguage ? (
                <Text>
                  We charge 20% of the final credit we help you claim. If our
                  estimate is off, we’ll adjust the fees after we finalize your
                  credit and prepare your tax documents. The above fee may
                  include discounts at time of order form generation. More
                  details can be found in the{' '}
                  <Link
                    external={false}
                    text={'"Manage Subscriptions"'}
                    href={'https://dashboard.mainstreet.com/'}
                  />{' '}
                  section of your MainStreet dashboard.
                </Text>
              ) : (
                <Text
                  text={`We charge 20% of the final tax credits we help you claim. If our estimate is off, we'll adjust our fees after we finalize your credit and prepare your tax documents.`}
                />
              )}
            </Grid.Cell>
            <Grid.Cell columns={12}>
              <Expandable
                expand={expandSchedule}
                showAsExpanded
                restOnExpansion
              >
                <Grid columns={12}>
                  <Grid.Cell
                    columns={1}
                    alignSelf='center'
                    justifySelf='center'
                    padding={[0, 16]}
                  >
                    <FontAwesome name='calendar' variant='regular' size={24} />
                  </Grid.Cell>
                  <Grid.Cell columns={11}>
                    <Text variant='medium'>
                      {moment(termStartDate).format('MMMM YYYY')}
                    </Text>
                    {contentConfig[program.name] || (
                      <SubscriptionContent
                        monthlyFeeCents={monthlyFeeCents}
                        numFeeMonths={numFeeMonths}
                      />
                    )}
                  </Grid.Cell>
                </Grid>
              </Expandable>
            </Grid.Cell>
          </Grid>
        </Grid.Cell>
      );
    },
  );
