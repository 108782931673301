import { Checkbox } from 'component-library';
import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { ServerClient } from 'services/ServerClient';
import { AttestationEventType, CompanyAccessToken } from 'lib/constants';
import { logContext } from 'logging';

interface SurveyAttestationProps {
  hasConfirmedAnswers: boolean;
  eventType: AttestationEventType;
  userEmail: string;
  taxYear: number;
  companyId: number;
  client: ServerClient;
  accessToken?: CompanyAccessToken;
}

export const SaveSurveyAttestation = async ({
  hasConfirmedAnswers,
  eventType,
  userEmail,
  taxYear,
  companyId,
  client,
  accessToken,
}: SurveyAttestationProps): Promise<void> => {
  if (hasConfirmedAnswers) {
    datadogLogs.logger.info(
      `Logging ${taxYear} ${eventType} attestation event.`,
    );
    try {
      if (accessToken) {
        datadogLogs.logger.info(
          `Invited user has confirmed answers for companyId ${companyId}.`,
        );
        // Example:
        // await client.UpdateCompanyEventAttestationPublic(
        //   accessToken,
        //   eventType,
        //   taxYear,
        // )
      } else {
        await client.UpdateCompanyEventAttestation(
          companyId,
          eventType,
          taxYear,
          userEmail,
        );
      }
    } catch (error) {
      datadogLogs.logger.error(
        `Something went wrong when creating an attestation event for ${taxYear} ${eventType}.`,
        logContext({
          error,
        }),
      );
    }
  } else {
    datadogLogs.logger.error(
      `Attestation event for ${taxYear} ${eventType} not recorded.`,
    );
  }
};

const SurveyAttestation = ({
  checked,
  onAttestation,
  className,
  disabled,
}: {
  checked: boolean;
  onAttestation: () => void;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <Checkbox
      className={className}
      options={[
        {
          checked,
          value: 'attestation',
          text: 'I hereby confirm that I have reviewed all pre-filled information, and all data on this page is complete and accurate to the best of my knowledge.',
          disabled,
        },
      ]}
      onChange={onAttestation}
      dataTestId={'survey-attestation'}
    />
  );
};

export default SurveyAttestation;
