import * as React from 'react';
import { useState } from 'react';
import {
  Button,
  FormHelperText,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';

import { CacheSet, CacheGet } from 'services/server';

const useStyles = makeStyles(() => ({
  root: {},
}));

export default function TestCache() {
  const classes = useStyles();

  const [key, setKey] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [result, setResult] = useState<any>('');
  const [error, setError] = useState<string>('');

  const clearFields = () => {
    setResult('');
    setError('');
  };

  const cacheGet = () => {
    clearFields();
    if (!key) {
      setError('Need a key to set.');
      return;
    }

    CacheGet(key).then((res) => {
      if (res.errorMsg) {
        setError(res.errorMsg);
        return;
      }

      console.log(res.data);
      const { value } = res.data!;
      if (!value) {
        setResult('No data.');
        return;
      }

      setResult(`Cache hit: ${value}`);
    });
  };

  const cacheSet = () => {
    clearFields();
    if (!key || !value) {
      setError('Need a key and value to set.');
      return;
    }

    CacheSet(key, value).then((res) => {
      if (res.errorMsg) {
        setError(res.errorMsg);
        return;
      }

      const { key, value } = res.data!;
      setResult(`Cache set, key: ${key}, value: ${value}`);
    });
  };

  return (
    <div className={classes.root}>
      <Typography component='div' variant='h2'>
        Cache Test
      </Typography>
      <TextField
        variant='outlined'
        label='Key'
        value={key}
        onChange={(event) => {
          setKey(event.target.value);
        }}
      />
      <TextField
        variant='outlined'
        label='Value'
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      />
      <Button onClick={cacheGet}>Get</Button>
      <Button onClick={cacheSet}>Set</Button>
      <Typography component='div' variant='body1'>
        {result}
      </Typography>
      <Typography component='div' variant='body1'>
        <FormHelperText error>{error}</FormHelperText>
      </Typography>
    </div>
  );
}
