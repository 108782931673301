import { BaseStore } from 'stores/BaseStore';
import { OnboardingAPI } from './OnboardingAPI';

export class BaseOnboardingStore extends BaseStore {
  public api = new OnboardingAPI(this.rootStore);

  /**
   * Shortcut to the Onboarding namespace
   */
  public get onboarding() {
    return this.rootStore.onboarding;
  }
}
