import { makeStyles } from '@material-ui/core';
import { Card, Flex, Text, Button } from 'component-library';
import { observer } from 'mobx-react';
import React from 'react';
import { SectionType } from 'services/financial/types';
import { useCommonStores } from 'stores/useStores';
import { useEffectOnce } from 'component-library/_helpers/use-utils';

const useStyles = makeStyles({
  linkContainer: {
    borderTop: `1px solid #f0f0f0`,

    '& button': {
      maxWidth: 202,
      padding: '0 16px',
      fontSize: 13,
      height: 36,
      marginTop: 16,
    },
  },
});

export const ChargeBeePaymentForm: React.FC = observer(() => {
  const { app, chargeBee } = useCommonStores();
  const classes = useStyles();

  useEffectOnce(async () => {
    await chargeBee.initialize();
  });

  const handleAddPaymentMethod = async () => {
    await chargeBee.openPortalSession(SectionType.PaymentSources);
  };

  return (
    <Card>
      <Flex
        gap={app.isMobile ? 0 : 16}
        direction={app.isMobile ? 'column' : 'row'}
        padding={[16, 24]}
        justifyContent={app.isMobile ? 'flex-start' : 'space-between'}
        alignItems={app.isMobile ? 'flex-start' : 'center'}
      >
        <Text size={23} variant='medium'>
          Payment Method
        </Text>
      </Flex>
      <Flex
        className={classes.linkContainer}
        padding={[16, 24, 24, 24]}
        direction='column'
      >
        <Text text='Click here to add or edit your payment method.' />
        <Button
          label='Manage Payment Method'
          onClick={handleAddPaymentMethod}
        />
      </Flex>
    </Card>
  );
});
