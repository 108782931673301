import React, { useEffect } from 'react';
import {
  useCommonStores,
  useCompany,
  useFeatureFlags,
  useHistoryStore,
} from 'stores/useStores';
import { observer } from 'mobx-react';
import { LoadingPage } from 'pages/LoadingPage';
import { AuthenticationFailure } from 'components/util/AuthenticationFailure';
import { RouteComponentProps, StaticContext } from 'react-router';
import { History } from 'history';
import { RouteConfiguration } from './RouteConfiguration';
import { Redirect } from 'react-router-dom';
import { Page, SignupStageEnum } from 'lib/constants';
import { LoginPage } from 'pages/login';
import { AccountCreationSteps } from 'lib/interfaces';
import { setDocumentPageTitle } from 'lib/helpers';
import MobileWarning2 from 'pages/MobileWarning2';

export interface RouteRendererProps {
  route: RouteConfiguration;
  props: RouteComponentProps<
    Record<string, string>,
    StaticContext,
    History.PoorMansUnknown
  >;
}
export const RouteRenderer: React.FC<RouteRendererProps> = observer(
  ({ route, props }) => {
    const flags = useFeatureFlags();
    const { auth, app, featureFlags } = useCommonStores();
    const { company, store: companyStore } = useCompany();
    const history = useHistoryStore();

    const showMobileWarning =
      (route.bypassMobilePrint !== true && app.isMobile && !app.isPrint) ||
      (route.path ===
        `/${Page.taxCredits}/expense-classification/:program_id/supplies-services` &&
        (app.isMobile || app.isTablet));

    useEffect(
      () => history.setMatchedParams(props.match.params),
      [history, props.match.params],
    );

    useEffect(() => {
      app.toggleNavBars(
        route.hideSideNav !== true && !showMobileWarning && company.isLoaded,
        route.hideTopNav !== true && !showMobileWarning && company.isLoaded,
      );
    }, [
      route.hideSideNav,
      route.hideTopNav,
      showMobileWarning,
      app,
      company.isLoaded,
    ]);

    // Known redirects
    if (route.redirectPath) {
      return <Redirect to={route.redirectPath} />;
    }

    // Waiting for auth checks to complete
    if (route.authSetupRequired === true && auth.isLoading) {
      return <LoadingPage />;
    }

    // Auth checks
    if (route.authRequired !== false) {
      auth.markAuthRequired();

      if (auth.isLoading || auth.shouldAttemptLogin) {
        return <LoadingPage />;
      } else if (auth.error) {
        return <AuthenticationFailure />;
      }
    }

    // CSRF Token check
    if (route.csrfRequired) {
      auth.enableCSRFToken();

      if (!auth.csrfToken) {
        return <LoadingPage />;
      }
    }

    // COmpany requirement check
    if (route.companyRequired !== false) {
      // Show loading mask while company is loading
      if (companyStore.isFirstLoadingCompany) {
        return <LoadingPage />;
      }
      // Redirect to homepage if there was an error fetching the company
      else if (!company.isLoaded) {
        if (history.pathname === '/') {
          if (auth.isAuth0Enabled) {
            return <AuthenticationFailure />;
          } else {
            return <LoginPage error={history.untrackedQuery.error as string} />;
          }
        }

        return (
          <Redirect
            to={{
              pathname: `/`,
              state: { referrer: history.search },
            }}
          />
        );
      }

      /**
       * There are specific pages a customer should be directed to when accessing the dashboard at '/'
       * These pages are based on their Company.signup_stage and should land them on the appropriate page
       * to either complete their onboarding, or access the dashboard
       */
      const pathsToCheck = [
        Page.selfSignupCompanyDetails,
        Page.accountCreationRoot,
        Page.accountCreationCreditEstimate,
        Page.accountCreationConnectingPayrollAccounting,
      ];
      if (
        company.signupStage === SignupStageEnum.COMPANY_DETAILS_IN_PROGRESS &&
        !pathsToCheck.some((path) => history.pathname.includes(path))
      ) {
        if (flags.showAccountCreationFlow) {
          const isUnifiedAccountCreation = flags.showNewUnifiedAccountCreation;

          if (isUnifiedAccountCreation) {
            if (
              flags.showMstSubscriptionAcceptancePage &&
              company.accountCreationStep(isUnifiedAccountCreation) ===
                AccountCreationSteps.ESTIMATES
            ) {
              return <Redirect to={`/${Page.accountCreationCreditEstimate}`} />;
            } else if (
              company.accountCreationStep(isUnifiedAccountCreation) ===
              AccountCreationSteps.TAXES
            ) {
              return <Redirect to={`/${Page.accountCreationTaxes}`} />;
            } else if (
              company.accountCreationStep(isUnifiedAccountCreation) ===
              AccountCreationSteps.PAYROLL_DATA
            ) {
              return <Redirect to={`/${Page.accountCreationPayrollData}`} />;
            } else if (
              company.accountCreationStep(isUnifiedAccountCreation) ===
              AccountCreationSteps.BUSINESS_ACTIVITIES
            ) {
              return (
                <Redirect to={`/${Page.accountCreationBusinessActivities}`} />
              );
            } else {
              return <Redirect to={`/${Page.accountCreationRoot}`} />;
            }
          } else {
            if (company.accountCreationStep() === AccountCreationSteps.TAXES) {
              return <Redirect to={`/${Page.accountCreationTaxes}`} />;
            } else if (
              company.accountCreationStep() === AccountCreationSteps.CONTACTS
            ) {
              return <Redirect to={`/${Page.accountCreationContacts}`} />;
            } else {
              return <Redirect to={`/${Page.accountCreationRoot}`} />;
            }
          }
        }

        return <Redirect to={`/${Page.selfSignupCompanyDetails}`} />;
      }
    }

    // Feature flag checks
    if (route.featureFlagsRequired !== false && !featureFlags.isReady) {
      return <LoadingPage />;
    }

    // Mobile check
    if (showMobileWarning) {
      return <MobileWarning2 />;
    }

    // Set document Page Title
    if (route.documentPageTitle) {
      setDocumentPageTitle(route.documentPageTitle);
    } else {
      setDocumentPageTitle('');
    }

    return (
      <>{route.render(props.match.params, history.untrackedQuery, history)}</>
    );
  },
);
