import { Content, Divider, Text } from 'component-library';
import React from 'react';

const OtherCreditsHelpDrawer = () => {
  return (
    <Content flex flexDirection={'column'}>
      <Text variant={'medium'}>
        Employee retention credit (Covid gross receipts decline)
      </Text>
      <Divider variant={'no-bottom-margin'} />
      <Text>
        You should be able to get this from their payroll provider. ERC is
        claimed on form 941, and you need to aggregate the total amount claimed
        for the year.{' '}
      </Text>
      <Text>
        If you use Gusto as a payroll system, you can use their calculator to
        determine the amount you claimed for the year here.
      </Text>

      <Text variant={'medium'}>Tax credit for qualified sick leave wages</Text>
      <Divider variant={'no-bottom-margin'} />
      <Text>
        Quarterly amounts can be found on Form 941, Line 5a(i) - Starting April
        1, 2021, the ARPA provides a 100% refundable tax credit for employers
        who pay qualified sick leave, up to 100% of 80 hours of qualified sick
        leave. Please include the credit amount if you claimed any.{' '}
      </Text>

      <Text variant={'medium'}>
        Tax credit for qualified family leave wages
      </Text>
      <Divider variant={'no-bottom-margin'} />
      <Text>
        Quarterly amounts can be found on Form 941, Line 5a(ii) - Starting April
        1, 2021, the ARPA provides a 100% refundable tax credit for employers
        who pay qualified family leave, up to 100% of 10 weeks of qualified
        family leave. Please include the credit amount if you claimed any.{' '}
      </Text>

      <Text variant={'medium'}>Paid family and medical leave credit</Text>
      <Divider variant={'no-bottom-margin'} />
      <Text>
        Quarterly amounts can be found on Form 941, Lines 11b, 11d, 13c, and 13e
        . You may also be able to get this total from your payroll provider.
      </Text>

      <Text variant={'medium'}>Restaurant revitalization fund grants</Text>
      <Divider variant={'no-bottom-margin'} />
      <Text>{`You might have completed an RRF Grant Application online or by mail. Since it's a tax-free grant, these funds do not need to be paid back and will not be treated as taxable income for your 2021 taxes.`}</Text>

      <Text variant={'medium'}>WOTC</Text>
      <Divider variant={'no-bottom-margin'} />
      <Text paddingBottom={32}>
        Page 5884. Your CPA should be able to provide this information quickly.
      </Text>
    </Content>
  );
};

export default OtherCreditsHelpDrawer;
