import React from 'react';
import { Color, Flex, Heading, Image, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  claimBanner: {
    backgroundColor: Color.neutral._10,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 8,
  },
  claimBgAccent: {
    position: 'absolute',
    bottom: '-130px',
    left: '420px',
    backgroundColor: Color.green._60,
    borderRadius: '50%',
    height: '400px',
    width: '400px',
    zIndex: 0,
  },
  bannerContent: {
    maxWidth: '60%',
    position: 'relative',
    zIndex: 1,
  },
  illustration: {
    width: '33%',
  },
  img: {
    position: 'relative',
    width: '100%',
    maxWidth: '80%',
    zIndex: 1,
  },
}));

interface ClaimBannerProps {
  title: string | React.ReactElement | JSX.Element;
  subtitle: string | React.ReactElement | JSX.Element;
  claimBannerClassName?: string;
  claimBgAccentClassName?: string;
  illustrationClassName?: string;
  buttonCta?: React.ReactElement | JSX.Element;
}

export const ClaimBanner: React.FC<ClaimBannerProps> = observer(
  ({
    title,
    subtitle,
    claimBannerClassName,
    claimBgAccentClassName,
    illustrationClassName,
    buttonCta,
  }) => {
    const classes = useStyles();

    return (
      <Flex
        className={
          claimBannerClassName ? claimBannerClassName : classes.claimBanner
        }
        padding={24}
        gap={24}
        justifyContent='space-between'
        alignItems='center'
      >
        <div
          className={
            claimBgAccentClassName
              ? claimBgAccentClassName
              : classes.claimBgAccent
          }
        />
        <Flex direction='column' gap={12} className={classes.bannerContent}>
          <Heading
            color={Color.green._80}
            tag='h4'
            variant='medium'
            size={23}
            marginBottom={0}
          >
            {title}
          </Heading>
          <Text>{subtitle}</Text>
          {buttonCta && buttonCta}
        </Flex>
        <Flex
          className={
            illustrationClassName ? illustrationClassName : classes.illustration
          }
          justifyContent='center'
        >
          <Image
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/images/mst-illustration-credits.svg`}
          />
        </Flex>
      </Flex>
    );
  },
);
