import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'component-library';
import { useDashboardStores, useCommonStores } from 'stores/useStores';

interface NextButtonProps {
  isFinishedRedirectUrl?: string;
}

export const NextButton: React.FC<NextButtonProps> = observer(
  ({ isFinishedRedirectUrl }) => {
    const { modules } = useDashboardStores();
    const { app } = useCommonStores();

    return (
      <Button
        label={isFinishedRedirectUrl ? 'Complete walkthrough' : 'Continue'}
        flexAlignSelf='flex-start'
        onClick={() =>
          isFinishedRedirectUrl
            ? app.history.push('/')
            : modules.setEducationalIndex(modules.educationalCurrentIndex + 1)
        }
        dataTestId='educational-module-continue-btn'
      />
    );
  },
);
