import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

export const YearFormat = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props;

  const maskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: '',
    allowDecimal: false,
    decimalSymbol: '',
    decimalLimit: 0,
    integerLimit: 4,
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const yearMask = createNumberMask(maskOptions);

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={yearMask}
    />
  );
};
