import React, { useState } from 'react';
import StepsContainer from '../../component/StepsContainer';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import { SurveyQuestion } from 'component-library';
import { AnswerType } from 'component-library/dashboard/surveyquestion/SurveyQuestion';
import { Loading } from 'components/util/Loading';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useFeatureFlags, useCommonStores } from 'stores/useStores';
import {
  Page,
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
  SurveyNameEnum,
  AutoqualificationStatusEnum,
} from 'lib/constants';
import { setDocumentPageTitle, useEffectOnce } from 'lib/helpers';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';

const DISQUALIFYING_FEDERAL_GRANTORS = [
  'NIH',
  'DOD',
  'NSF',
  'SBA',
  'DOE',
  'SBIR',
  'OTHER_FEDERAL_GOV_AGENCY',
].map(
  (key) =>
    GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.WHERE_WAS_GRANT_FROM]![
      key
    ],
);

interface RDActivitiesProps {
  taxYear: number;
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
}

export const RDActivities = observer(
  ({ taxYear, pathTo, pathFrom, onNext, onBack }: RDActivitiesProps) => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const { app } = useCommonStores();
    const featureFlags = useFeatureFlags();
    const showQualDqExperience = featureFlags.showQualificationDqExperience;

    const onContinue = () => {
      setButtonLoading(true);
      saveAnswers()
        .then(async ({ autoqualificationStatus }) => {
          setError('');

          if (
            autoqualificationStatus === AutoqualificationStatusEnum.DISQUALIFIED
          ) {
            await app.common.companyStore.refreshCurrentCompany();
            if (showQualDqExperience) {
              app.history.push(
                `/${Page.taxCredits}/${Page.disqualified}/${taxYear}`,
              );
            } else {
              app.history.push(`/`);
            }
            return;
          }

          app.history.push(pathTo);
          onNext();
          setButtonLoading(false);
        })
        .catch((err) => {
          datadogLogs.logger.error(
            '[QUALIFICATION]: updating RD activities responses failed',
            logContext({
              error: err,
              company: app.company,
            }),
          );
          setError('Something went wrong.');
        });
    };

    const onBackStep = () => {
      onBack();
      app.history.push(pathFrom);
    };

    const {
      isSurveyComplete,
      isLoading,
      questionsToRender,
      addSurveyAnswers,
      saveAnswers,
      surveyAnswers,
    } = useSurveyQuestions(
      SurveyNameEnum.RD_CREDIT_AUTOQUAL_ACTIVITIES,
      taxYear,
      {
        shortCircuitConditions: [
          () =>
            Object.keys(questionsToRender).length > 0 &&
            (
              surveyAnswers[GraphCmsQuestionIdEnum.WHERE_WAS_GRANT_FROM] || []
            ).some((grantor: string) =>
              DISQUALIFYING_FEDERAL_GRANTORS.includes(grantor),
            ),
          () =>
            Object.keys(questionsToRender).length > 0 &&
            surveyAnswers[GraphCmsQuestionIdEnum.TIME_TRACKING] ===
              GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.TIME_TRACKING]
                ?.YES,
        ],
      },
    );

    const getAnswerType = (answerType: AnswerType) => {
      switch (answerType) {
        case 'yes_no_or_unknown':
        case 'yes_or_no':
          return 'multiple_choice';

        default:
          return answerType;
      }
    };

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => {
          return (
            <SurveyQuestion
              key={question.id}
              dataTestId={question.id}
              answerType={getAnswerType(question.answerType as AnswerType)}
              text={question.text}
              subtitle={question.subtitle}
              answerOptions={question.answerIDs}
              onChange={(answer) => {
                question.answerValue = answer;
                addSurveyAnswers(question.id, key, [
                  { questionId: question.id, answerValue: answer },
                ]);
              }}
              placeholder={question.placeholder}
              answerValue={question.answerValue}
              tooltip={question.tooltip}
              questionTextSize={app.isMobile ? 15 : 18}
              questionSubtitleSize={app.isMobile ? 13 : 15}
            />
          );
        });
      });

    useEffectOnce(() => setDocumentPageTitle('Qualification - R&D Activities'));

    return (
      <StepsContainer
        onBackStep={onBackStep}
        showContinueButton={isSurveyComplete}
        onContinue={onContinue}
        isLoading={buttonLoading}
        onContinueLabel='Continue'
        stepTitle='Your R&D Activities'
        subtitle='This information helps us understand what kind of R&D your company does.'
        errorMsg={error}
      >
        <Loading loading={isLoading}>
          {!_.isEmpty(questionsToRender) && renderQuestions()}
        </Loading>
      </StepsContainer>
    );
  },
);
