import * as React from 'react';
import { Box, Grid, makeStyles, Typography, Theme } from '@material-ui/core';
import { useCommonStores } from 'stores/useStores';

const useSectionHeaderStyles = makeStyles(({ breakpoints }: Theme) => ({
  topLeftBox: {
    minHeight: '80px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '40px',
    borderBottom: '1px solid #E5E5E5',
    [breakpoints.down('xs')]: {
      padding: '12px 24px',
    },
  },
  topRightBox: {
    minHeight: '80px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1px solid #E5E5E5',
    borderBottom: '1px solid #E5E5E5',
  },
  cardTitle: {
    fontSize: '20px',
    lineHeight: '27px',
    [breakpoints.down('xs')]: {
      fontSize: '15px',
      lineHeight: '1.4',
    },
  },
}));

interface SectionHeaderProps {
  title?: string;
  titleElement?: () => JSX.Element;
  rightSide?: () => JSX.Element;
}

export const SectionHeader = ({
  title,
  titleElement,
  rightSide,
}: SectionHeaderProps) => {
  const classes = useSectionHeaderStyles();
  const { app } = useCommonStores();

  return (
    <Grid container>
      <Grid item xs={rightSide ? (app.isMobile ? 6 : 8) : 12}>
        <Box className={classes.topLeftBox}>
          {title ? (
            <Typography className={classes.cardTitle}>{title}</Typography>
          ) : (
            titleElement && titleElement()
          )}
        </Box>
      </Grid>
      {rightSide && (
        <Grid item xs={app.isMobile ? 6 : 4}>
          <Box className={classes.topRightBox}>{rightSide()}</Box>
        </Grid>
      )}
    </Grid>
  );
};
