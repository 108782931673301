import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Content } from 'component-library';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(({ breakpoints }) => ({
  link: {
    display: 'inline-block',
    textDecoration: 'none',
  },
  contentContainer: {
    height: '100%',
    [breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
  btnGroup: {
    minWidth: 175,
    margin: '0 auto',
    '& button': {
      width: '100%',
    },
  },
  fields: {
    width: '100%',
    maxWidth: 524,
  },
}));

interface ContentContainerProps {
  children: React.ReactNode | JSX.Element;
  pathFrom?: string;
  onNext?: () => void;
  isNextButtonDisabled?: boolean;
  isNextLoading?: boolean;
  onBack?: () => void;
  lastStep?: boolean;
}

export const ContentContainer = ({
  children,
  pathFrom,
  onNext,
  isNextButtonDisabled,
  isNextLoading,
  onBack,
  lastStep,
}: ContentContainerProps) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Content
      flex
      flexDirection='column'
      justifyContent='space-between'
      className={classes.contentContainer}
    >
      <Content
        flex
        flexDirection='column'
        justifyContent='flex-start'
        gap={24}
        paddingBottom={16}
        className={classes.fields}
      >
        {children}
      </Content>
      <Content
        flex
        className={classes.btnGroup}
        paddingLeftRight={0}
        paddingTopBottom={0}
      >
        {pathFrom && onBack && (
          <Button
            label='Previous'
            variant='outlined'
            onClick={() => {
              onBack();
              history.push(pathFrom);
            }}
            dataTestId={'account-creation-previous-button'}
          />
        )}
        {onNext && (
          <Button
            label={lastStep ? 'Finish' : 'Next'}
            onClick={() => onNext()}
            loading={isNextLoading}
            disabled={isNextButtonDisabled}
            dataTestId={
              lastStep
                ? 'account-creation-finished-button'
                : 'account-creation-next-button'
            }
          />
        )}
      </Content>
    </Content>
  );
};
