import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import {
  Page,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationModalStatusEnum,
  QualificationStatusEnum,
  RetirementPlanCreditQuestions,
  RetirementPlanCreditQuestionsToAnswers,
  SurveyNameEnum,
} from 'lib/constants';
import {
  Animate,
  AnswerType,
  AnswerValueType,
  Color,
  Flex,
  Spinner,
  SurveyQuestion,
} from 'component-library';
import { CmsQuestionData } from 'lib/interfaces';
import _ from 'lodash';
import DOMPurify from 'dompurify';
import { useCommonStores, useTaxCreditsStores } from 'stores/useStores';
import { QualificationStatusModal } from '../QualificationStatusModal';
import { useEffectOnce } from 'lib/helpers';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    maxWidth: '80%',
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  surveyListText: {
    '& > ul': {
      margin: 0,
      padding: '0 24px 16px',

      '& > li': {
        paddingBottom: '0 8px 8px',
        fontSize: '15px',
        lineHeight: '24px',
        color: Color.neutral._80,
      },
    },
  },
  dropdownSelect: {
    zIndex: 2,
  },
}));

interface BusinessDetailsProps {
  pathTo: string;
  onNext: () => void;
  taxYear: number;
}

export const BusinessDetails = observer(
  ({ pathTo, onNext, taxYear }: BusinessDetailsProps) => {
    const [shouldRedirectToEstimate, setShouldRedirectToEstimate] =
      useState<boolean>(false);
    const classes = useStyles();
    const history = useHistory();
    const { app, chatbot } = useCommonStores();
    const { surveyFlow } = useTaxCreditsStores();

    const handleOnContinue = () => {
      if (!surveyFlow.qualificationStatus) {
        surveyFlow.updateQualificationStatus(
          QualificationModalStatusEnum.RETIREMENT_ELIGIBLE_1,
        );
        surveyFlow.setShowQualificationStatusModal(true);
      } else if (
        surveyFlow.qualificationStatus &&
        surveyFlow.qualificationStatus.length > 0
      ) {
        surveyFlow.setShowQualificationStatusModal(true);
      }
    };

    const {
      isSurveyComplete,
      isLoading,
      surveyAnswers,
      questionsToRender,
      addSurveyAnswers,
      saveAnswers,
    } = useSurveyQuestions(
      SurveyNameEnum.RETIREMENT_PLAN_BUSINESS_DETAILS_SURVEY,
      taxYear,
    );

    const handleAnswerChange = (
      question: CmsQuestionData,
      answerValue: AnswerValueType,
      key: string,
    ) => {
      question.answerValue = answerValue;
      addSurveyAnswers(question.id, key, [
        { questionId: question.id, answerValue: answerValue },
      ]);
      // save answers per question answered
      if (
        question.answerType === 'multiple_select' ||
        question.answerType === 'multiple_choice' ||
        question.answerType === 'yes_or_no'
      ) {
        saveAnswers && saveAnswers();
      }
    };

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => {
          const subtitleList =
            question.subtitle && question.subtitle.includes('<ul>');
          return (
            <Animate
              key={question.id}
              enter={['fade-in', 'from-bottom']}
              duration={0.5}
              className={`${
                question.answerType === 'dropdown_select'
                  ? classes.dropdownSelect
                  : ''
              }`}
            >
              <SurveyQuestion
                dataTestId={question.id}
                answerType={question.answerType as AnswerType}
                text={question.text}
                subtitle={
                  subtitleList ? (
                    <div
                      className={classes.surveyListText}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(question.subtitle),
                      }}
                    />
                  ) : (
                    question.subtitle
                  )
                }
                answerOptions={question.answerIDs}
                onChange={(answer) => handleAnswerChange(question, answer, key)}
                onBlur={() => saveAnswers && saveAnswers()}
                placeholder={question.placeholder}
                answerValue={question.answerValue}
                tooltip={question.tooltip}
                questionTextSize={app.isMobile ? 15 : 18}
                questionSubtitleSize={app.isMobile ? 13 : 15}
                withCardMargin={false}
                helpLinkText={question.helperText}
                chatBotQuestion={chatbot.isAiQuestionEnabled(
                  question.aiQuestion,
                )}
                chatBotPrompt={() =>
                  chatbot.autoSendAiMessage(question.aiQuestion)
                }
                onHelpLinkClick='https://mainstreet1.my.site.com/help/s/article/Qualified-Retirement-Plans-for-Start-up-Cost-and-Auto-Enrollment-Tax-Credits'
              />
            </Animate>
          );
        });
      });

    const scrollIntoViewOnKeyDown = () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    };

    const handleOnDisqualified = async () => {
      surveyFlow.setModalContinueIsLoading(true);
      // update program stage
      await surveyFlow
        .updateProgramStageStatus(
          ProgramNameEnum.FED_RETIREMENT_CREDIT,
          taxYear,
          ProgramStageEnum.DISQUALIFIED,
          QualificationStatusEnum.DISQUALIFIED,
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT,
        )
        .then(() => {
          history.push('/');
          surveyFlow.setShowQualificationStatusModal(false);
          surveyFlow.setModalContinueIsLoading(false);
          // clear qualification statuses
          surveyFlow.qualificationStatus = null;
        });
    };

    useEffect(() => {
      // scroll page down as user answers each question
      scrollIntoViewOnKeyDown();

      // checking survey answers to display qualification modal
      surveyFlow.checkQualificationStatus(surveyAnswers);

      const hasNoDisqualifyingActivities =
        surveyAnswers[
          RetirementPlanCreditQuestions.NO_DISQUALIFYING_ACTIVITIES
        ];

      // set redirect to estimate page
      if (
        hasNoDisqualifyingActivities ===
        RetirementPlanCreditQuestionsToAnswers[
          RetirementPlanCreditQuestions.NO_DISQUALIFYING_ACTIVITIES
        ].YES
      ) {
        setShouldRedirectToEstimate(true);
      } else if (
        hasNoDisqualifyingActivities ===
        RetirementPlanCreditQuestionsToAnswers[
          RetirementPlanCreditQuestions.NO_DISQUALIFYING_ACTIVITIES
        ].NO
      ) {
        setShouldRedirectToEstimate(false);
      }
    }, [surveyAnswers, surveyFlow]);

    useEffectOnce(
      async () =>
        await surveyFlow.createTaxCreditProgram(
          taxYear,
          ProgramNameEnum.FED_RETIREMENT_CREDIT,
        ),
    );

    return (
      <SurveyFlowContainer
        title='Qualify your company for Retirement Plan Tax Credit'
        onContinue={handleOnContinue}
        isDisabled={!isSurveyComplete}
        dataTestId='retirement-plan-credit'
      >
        <Flex className={classes.container} direction='column' gap={24}>
          {isLoading ? (
            <Spinner size='small' />
          ) : (
            !_.isEmpty(questionsToRender) && renderQuestions()
          )}
          {surveyFlow.qualificationStatus &&
            surveyFlow.qualificationStatus.length > 0 && (
              <QualificationStatusModal
                showModal={surveyFlow.showQualificationStatusModal}
                modalOnClick={async () => {
                  if (
                    surveyFlow.qualificationStatus ===
                    QualificationModalStatusEnum.RETIREMENT_ELIGIBLE_1
                  ) {
                    if (shouldRedirectToEstimate) {
                      surveyFlow.setShowQualificationStatusModal(false);
                      history.push(
                        `/${Page.taxCredits}/${taxYear}/${Page.retirementPlan}/${Page.retirementPlanCreditEstimate}`,
                      );
                    } else {
                      surveyFlow.setShowQualificationStatusModal(false);
                      onNext();
                      history.push(pathTo);
                    }
                  } else {
                    await handleOnDisqualified();
                  }
                }}
                programName={ProgramNameEnum.FED_RETIREMENT_CREDIT}
              />
            )}
        </Flex>
      </SurveyFlowContainer>
    );
  },
);
