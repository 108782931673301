import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  useAccountCreationStores,
  useCommonStores,
  useFeatureFlags,
  useLegacyClients,
  useTaxCreditsStores,
} from 'stores/useStores';
import {
  FloatToDollarString,
  getRetirementEstimatedCreditContext,
  useEffectOnce,
} from 'lib/helpers';
import {
  EligibleCreditEstimates,
  IneligibleCreditEstimates,
  TransitionAnimation,
} from '../component';
import {
  ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
  HealthcarePlanCreditQuestions,
  HealthcarePlanCreditQuestionsToAnswers,
  ProgramNameEnum,
  ProgramStageEnum,
  Programs,
  QualificationStatusEnum,
  RetirementPlanCreditQuestions,
  RetirementPlanCreditQuestionsToAnswers,
} from 'lib/constants';
import confetti from 'canvas-confetti';

export interface AdditionalCredit {
  name: string;
  estimate: string;
}

export const UnifiedCreditEstimates = observer(() => {
  const { companyStore } = useCommonStores();
  const { accountCreation } = useAccountCreationStores();
  const [showEstimates, setShowEstimates] = useState<boolean>(false);
  const transitionDuration = 10;
  const featureFlag = useFeatureFlags();
  const { surveyFlow } = useTaxCreditsStores();
  const { client } = useLegacyClients();

  // Determine additional credits status
  const hasDqRetirementCredit =
    accountCreation.dqRetirementEmployeeCount() ||
    accountCreation.hasHygraphAnswerForQuestionId(
      ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
      RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN,
      RetirementPlanCreditQuestionsToAnswers[
        RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
      ].WE_DO_NOT_OFFER,
    );

  const hasDqHealthCareCredit = accountCreation.hasHygraphAnswerForQuestionId(
    ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
    HealthcarePlanCreditQuestions.DID_YOU_PAY_50PERCENT_HEALTH_INSURANCE,
    HealthcarePlanCreditQuestionsToAnswers[
      HealthcarePlanCreditQuestions.DID_YOU_PAY_50PERCENT_HEALTH_INSURANCE
    ].NO,
  );

  const eligibleFor2020Erc = accountCreation.eligibleForErc(2020);

  const eligibleFor2021Erc = accountCreation.eligibleForErc(2021);

  const isEligibleForDisabledAccessCredit =
    accountCreation.eligibleForDisabledAccessHygraphQuestion(
      ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
    );

  const handleAdditionalCreditsFlags = (): AdditionalCredit[] => {
    const creditInfo = [
      {
        enabled: true,
        title: Programs[ProgramNameEnum.FED_RD_TAX].display,
        summary: '',
        programName: ProgramNameEnum.FED_RD_TAX,
      },
      {
        enabled: true,
        title: Programs[ProgramNameEnum.STATE_RD_CA].display,
        summary: '',
        programName: ProgramNameEnum.STATE_RD_CA,
      },
      {
        enabled: featureFlag.enableStateAzTaxCredit,
        title: Programs[ProgramNameEnum.STATE_RD_AZ].display,
        summary: '',
        programName: ProgramNameEnum.STATE_RD_AZ,
      },
      {
        enabled: featureFlag.enableStateGaTaxCredit,
        title: Programs[ProgramNameEnum.STATE_RD_GA].display,
        summary: '',
        programName: ProgramNameEnum.STATE_RD_GA,
      },
      {
        enabled: featureFlag.enableStateMaTaxCredit,
        title: Programs[ProgramNameEnum.STATE_RD_MA].display,
        summary: '',
        programName: ProgramNameEnum.STATE_RD_MA,
      },
      {
        enabled:
          featureFlag.showDisabilityCreditInEstimates &&
          isEligibleForDisabledAccessCredit,
        title: Programs[ProgramNameEnum.FED_DISABLED_ACCESS].display,
        summary: 'Up to $5,000',
        programName: ProgramNameEnum.FED_DISABLED_ACCESS,
      },
      {
        enabled: featureFlag.showERCCreditInEstimates,
        title: Programs[ProgramNameEnum.ERC].display,
        summary: 'Up to $5,000 per employee',
        programName: ProgramNameEnum.ERC,
      },
      {
        enabled:
          featureFlag.showRetirementCreditInEstimates && !hasDqRetirementCredit,
        title: Programs[ProgramNameEnum.FED_RETIREMENT_CREDIT].display,
        summary: getRetirementEstimatedCreditContext(
          companyStore.company.qualificationQuestionsByYear,
        ),
        programName: ProgramNameEnum.FED_RETIREMENT_CREDIT,
      },
      {
        enabled: featureFlag.showWorkOpportunityCreditInEstimates,
        title: Programs[ProgramNameEnum.WOTC].display,
        summary:
          'The Work Opportunity Tax Credit (WOTC) is a federal tax incentive for hiring individuals facing employment barriers, such as veterans or ex-felons.',
        programName: ProgramNameEnum.WOTC,
      },
      {
        enabled:
          featureFlag.showHealthcarePlanCreditInEstimates &&
          !hasDqHealthCareCredit,
        title: Programs[ProgramNameEnum.FED_EMPLOYER_HEALTHCARE].display,
        summary: 'Up to 50% of qualifying expenses',
        programName: ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
      },
    ];

    const enabledCredits = creditInfo.filter((credit) => credit.enabled);
    return enabledCredits.map((credit) => {
      const programs = companyStore.currentCompany.programs;
      const hasPrefillEstimate = programs.find(
        (p) =>
          p.name === credit.programName &&
          p.taxYear === ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
      );

      if (
        hasPrefillEstimate &&
        hasPrefillEstimate.estimatedTotalCreditCentsOverride
      ) {
        return {
          name: credit.title,
          estimate: FloatToDollarString(
            hasPrefillEstimate.estimatedTotalCreditCentsOverride / 100,
          ),
        };
      } else {
        return {
          name: credit.title,
          estimate: credit.summary,
        };
      }
    });
  };

  const handleConfetti = () => {
    confetti({
      particleCount: 200,
      spread: 100,
      origin: { y: 0.5, x: 0.28 },
    });
  };

  useEffectOnce(async () => {
    const animationTimer = setTimeout(
      () => setShowEstimates(true),
      transitionDuration * 1000,
    );

    const showConfettiOnload = setTimeout(() => {
      if (
        accountCreation.programCreditEstimates !== null &&
        accountCreation.programCreditEstimates.length > 0
      ) {
        handleConfetti();
      }
    }, (transitionDuration + 1) * 1000);

    // DQ programs first
    for (const program of companyStore.company.programs) {
      if (program.taxYear !== 2023) continue;

      if (program.name === ProgramNameEnum.FED_RETIREMENT_CREDIT) {
        if (hasDqRetirementCredit) {
          await updateProgramDQ([program.id]);
        } else {
          // Qualify program for those edge cases when user goes back and change answers
          await updateProgramQualified([program.id]);
        }
      }

      if (program.name === ProgramNameEnum.FED_EMPLOYER_HEALTHCARE) {
        if (hasDqHealthCareCredit) {
          await updateProgramDQ([program.id]);
        } else {
          // Qualify program for those edge cases when user goes back and change answers
          await updateProgramQualified([program.id]);
        }
      }

      if (program.name === ProgramNameEnum.FED_DISABLED_ACCESS) {
        if (!isEligibleForDisabledAccessCredit) {
          await updateProgramDQ([program.id]);
        } else {
          // Qualify program for those edge cases when user goes back and change answers
          await updateProgramQualified([program.id]);
        }
      }
    }

    async function updateProgramDQ(programIdsToUpdate: number[]) {
      await client.UpdatePrograms({
        programIdsToUpdate,
        qualificationStatus: QualificationStatusEnum.DISQUALIFIED,
        stage: ProgramStageEnum.DISQUALIFIED,
        subStage: null,
      });
    }

    async function updateProgramQualified(programIdsToUpdate: number[]) {
      await client.UpdatePrograms({
        programIdsToUpdate,
        qualificationStatus: QualificationStatusEnum.QUALIFICATION_SUBMITTED,
        stage: ProgramStageEnum.QUALIFYING,
        subStage: null,
      });
    }

    // DQ 2020 ERC
    if (!eligibleFor2020Erc) {
      await surveyFlow.updateProgramStageStatus(
        ProgramNameEnum.ERC,
        2020,
        ProgramStageEnum.DISQUALIFIED,
      );
    } else {
      // Qualify program for those edge cases when user goes back and change answers
      await surveyFlow.updateProgramStageStatus(
        ProgramNameEnum.ERC,
        2020,
        ProgramStageEnum.QUALIFYING,
      );
    }

    // DQ 2021
    if (!eligibleFor2021Erc) {
      await surveyFlow.updateProgramStageStatus(
        ProgramNameEnum.ERC,
        2021,
        ProgramStageEnum.DISQUALIFIED,
      );
    } else {
      // Qualify program for those edge cases when user goes back and change answers
      await surveyFlow.updateProgramStageStatus(
        ProgramNameEnum.ERC,
        2021,
        ProgramStageEnum.QUALIFYING,
      );
    }

    // Load displayable credits after DQ'ing credits
    await accountCreation.GetDisplayableCreditEstimates(
      ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
    );

    return () => {
      if (
        accountCreation.totalEstimate !== 0 &&
        accountCreation.programCreditEstimates &&
        accountCreation.programCreditEstimates.length > 0
      ) {
        clearTimeout(animationTimer);
        clearTimeout(showConfettiOnload);
      }
    };
  });

  return (
    <>
      {showEstimates ? (
        <>
          {(accountCreation.programCreditEstimates &&
            accountCreation.programCreditEstimates.length > 0) ||
          handleAdditionalCreditsFlags().length > 0 ? (
            <EligibleCreditEstimates
              handleAdditionalCreditsFlags={handleAdditionalCreditsFlags}
            />
          ) : (
            <IneligibleCreditEstimates />
          )}
        </>
      ) : (
        <TransitionAnimation transitionDuration={transitionDuration} />
      )}
    </>
  );
});
