import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { CmsQuestionData, Document, MSClientResponse } from 'lib/interfaces';
import { SaveAnswersResponse, SurveyAnswer } from 'lib/useSurveyQuestions';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import {
  AnswerType,
  Button,
  Card,
  Color,
  FileHandle,
  Flex,
  Modal,
  SurveyQuestion,
  Text,
} from 'component-library';
import { CompanyContext } from 'pages/CompanyRequired';
import {
  isFileEncrypted,
  isFileSizeWithinLimits,
  useEffectOnce,
} from 'lib/helpers';
import AttentionNeeded from 'components/icons/AttentionNeeded';
import { FileTypes } from 'lib/constants';

const useStyles = makeStyles(() => ({
  manualInputField: {
    '& > div': {
      width: '100%',
      maxWidth: '480px',
    },
  },
  uploadContainer: {
    maxWidth: '720px',
  },
  uploadedDocs: {
    border: `1px solid ${Color.neutral._20}`,
    borderTop: 'none',
  },
}));

const GENERIC_VISUAL_ERROR = `Something went wrong, please try again`;
const UPLOAD_FILE_SIZE_LIMIT = 1024 * 1024 * 20;

interface ERCTaxReturnUploadQuestionProps {
  addSurveyAnswers: (
    questionId: string,
    subGroupName: string,
    answers: SurveyAnswer[],
  ) => void;
  activeQuestion: CmsQuestionData;
  subGroupName: string;
  saveAnswers: () => Promise<SaveAnswersResponse>;
  documentDescription: string;
  type: 'payroll' | 'income';
}

export const ERCTaxReturnUploadQuestion = ({
  addSurveyAnswers,
  activeQuestion,
  subGroupName,
  saveAnswers,
  documentDescription,
  type,
}: ERCTaxReturnUploadQuestionProps) => {
  const classes = useStyles();
  const { client } = useContext(Auth0FeatureContext);
  const { company } = useContext(CompanyContext);
  const [files, setFiles] = useState<Record<string, FileHandle[]>>({});
  const [showEncryptedModal, setShowEncryptedModal] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState<
    Record<string, Document[]>
  >({});
  const [deleteLoading, setDeleteLoading] = useState<{
    [id: number]: boolean;
  }>({});

  const ERCUploadedTaxYear = 2021;

  const uploadTaxReturn = async (
    questionId: string,
    fileHandler: FileHandle,
  ) => {
    const filePromise: Promise<{
      questionId: string;
      fileHandler: FileHandle;
      response: MSClientResponse<{ document: Document }>;
    }> = client
      .UploadCompanyDocument(
        company.id,
        fileHandler,
        fileHandler.name,
        documentDescription,
      )
      .then((response) => ({ questionId, fileHandler, response }))
      .catch((error) => error);

    return filePromise.then((result) => {
      let hasUploaded = true;
      let documentId;

      if (result.response.data) {
        const { document } = result.response.data;
        if (activeQuestion?.subQuestions) {
          const question = activeQuestion.subQuestions.find(
            (subQuestion) => subQuestion.id === questionId,
          );

          if (question) {
            if (Array.isArray(question.answerValue)) {
              question.answerValue.push(document.id.toString());
            } else {
              question.answerValue = [];
              question.answerValue.push(document.id.toString());
            }

            documentId = document.id;

            addSurveyAnswers(questionId, subGroupName, [
              { questionId: questionId, answerValue: question.answerValue },
            ]);
          } else {
            fileHandler.setError(
              result.response.errorMsg || GENERIC_VISUAL_ERROR,
            );
            hasUploaded = false;
          }
        }
      }

      return { hasUploaded, documentId };
    });
  };

  const onFileAdded = async (
    questionId: string,
    fileHandle: FileHandle,
    bypassFileEncryptionValidation: boolean,
  ) => {
    let promise: Promise<void> = Promise.resolve();
    const fileSizeWithinLimits = isFileSizeWithinLimits(fileHandle.file);
    const fileEncrypted = await isFileEncrypted(
      fileHandle,
      'We cannot accept password-protected documents. Please submit a copy of your return without this protection.',
    );

    if (!fileSizeWithinLimits) {
      return;
    }

    if (!bypassFileEncryptionValidation && fileEncrypted) {
      setShowEncryptedModal(true);
      return;
    } else {
      fileHandle.setError('');
    }

    promise = uploadTaxReturn(questionId, fileHandle).then((success) => {
      // store db document id to fileHandle
      fileHandle.documentId = success.documentId;
    });

    promise.then(() => {
      const questionFileList = files[questionId] ? [...files[questionId]] : [];
      const updatedFiles = {
        ...files,
        [questionId]: [...questionFileList, fileHandle],
      };
      setFiles(updatedFiles);

      // update answers if file added
      if (saveAnswers) {
        saveAnswers();
      }
    });
  };

  const onFileRemoved = (questionId: string, file: FileHandle) => {
    const questionFileList = files[questionId] ? [...files[questionId]] : [];
    const fileIndex = questionFileList.findIndex((f) => f.vid === file.vid);
    if (fileIndex >= 0) {
      questionFileList.splice(fileIndex, 1);
    }
    const updatedFiles = {
      ...files,
      [questionId]: questionFileList,
    };
    setFiles(updatedFiles);

    // remove documentId from answerValue array
    if (activeQuestion?.subQuestions) {
      const question = activeQuestion.subQuestions.find(
        (subQuestion) => subQuestion.id === questionId,
      );
      if (question && Array.isArray(question?.answerValue)) {
        if (file.documentId) {
          const documentId = file.documentId;
          const removeId = question.answerValue.findIndex(
            (id) => Number(id) === documentId,
          );

          if (removeId !== -1) {
            question.answerValue.splice(removeId, 1);
          }

          client.DeleteDocument(file.documentId);
        }
      }
    }

    // update answers if file removed
    if (saveAnswers) {
      saveAnswers();
    }
  };

  const getUploadedDocuments = async () => {
    if (activeQuestion && activeQuestion.subQuestions) {
      const res = await client.GetERCUploadedTaxReturn(
        ERCUploadedTaxYear,
        type,
      );
      const filterAnswerValues = activeQuestion.subQuestions.filter((s) =>
        Array.isArray(s.answerValue),
      );
      const documents = res.data?.documents;
      const uploadedDocs: { [key: string]: Document[] } = {};

      if (documents) {
        filterAnswerValues.forEach((question) => {
          if (Array.isArray(question.answerValue)) {
            question.answerValue.forEach((answerId) => {
              documents.forEach((doc) => {
                const docIdString = doc.id.toString();

                if (docIdString === answerId) {
                  if (uploadedDocs[question.id]) {
                    uploadedDocs[question.id] = [
                      ...uploadedDocs[question.id],
                      doc,
                    ];
                  } else {
                    uploadedDocs[question.id] = [doc];
                  }
                }
              });
            });
          }
        });

        setUploadedDocuments(uploadedDocs);
      }
    }
  };

  // this is to delete documents that were already uploaded
  // if customer had already previously visited the company details step
  const deleteDocument = async (
    docId: number,
    questionId: string,
    index: number,
  ) => {
    setDeleteLoading((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));

    await client.DeleteDocument(docId).then(() => {
      const updateDocumentList = uploadedDocuments[questionId].filter(
        (doc) => doc.id !== docId,
      );

      const updatedDocList = {
        ...uploadedDocuments,
        [questionId]: [...updateDocumentList],
      };

      setUploadedDocuments(updatedDocList);
      setDeleteLoading((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));

      // remove documentId from answerValue array
      if (activeQuestion?.subQuestions) {
        const question = activeQuestion.subQuestions.find(
          (subQuestion) => subQuestion.id === questionId,
        );
        if (question && Array.isArray(question?.answerValue)) {
          const removeId = question.answerValue.findIndex(
            (id) => Number(id) === docId,
          );

          if (removeId !== -1) {
            question.answerValue.splice(removeId, 1);
          }
        }
      }

      // update answers if file removed
      if (saveAnswers) {
        saveAnswers();
      }
    });
  };

  // get uploaded documents to show customers
  useEffectOnce(() => getUploadedDocuments());

  return activeQuestion && activeQuestion.subQuestions ? (
    <>
      <Card noMargin>
        <Flex padding={24} direction='column' gap={24}>
          <Flex.Cell>
            <Text text={activeQuestion.text} size={23} variant='medium' />
            {activeQuestion.subtitle && <Text text={activeQuestion.subtitle} />}
          </Flex.Cell>
          {activeQuestion.subQuestions.map((question, index) => {
            return (
              <Flex
                key={`${question.id}-${index}`}
                direction='column'
                className={classes.uploadContainer}
              >
                <SurveyQuestion
                  tooltip={question.tooltip}
                  answerType={question.answerType as AnswerType}
                  placeholder={question.placeholder}
                  answerValue={question.answerValue}
                  text={question.text}
                  subtitle={question.subtitle}
                  withCard={false}
                  acceptedFiletypes={[
                    FileTypes.CSV,
                    FileTypes.PDF,
                    FileTypes.XLS,
                    FileTypes.XLSX,
                  ]}
                  maxFileSize={UPLOAD_FILE_SIZE_LIMIT}
                  onFileAdded={async (file: FileHandle) => {
                    await onFileAdded(question.id, file, false);
                  }}
                  onFileRemoved={(file: FileHandle) =>
                    onFileRemoved(question.id, file)
                  }
                  onFileCancelled={(file: FileHandle) => {
                    onFileRemoved(question.id, file);
                  }}
                  fileUploadFlexDirection='column'
                />
                {uploadedDocuments &&
                  uploadedDocuments[question.id] &&
                  uploadedDocuments[question.id].map((doc, index) => {
                    return (
                      <Flex
                        padding={24}
                        key={`${doc.id}-${index}`}
                        className={classes.uploadedDocs}
                        alignItems='center'
                        justifyContent='space-between'
                        gap={24}
                      >
                        <Flex.Cell>
                          <Text text={doc.name} color={Color.neutral._80} />
                          <Text
                            size={11}
                            text='(Previously uploaded)'
                            variant='italic'
                            color={Color.neutral._60}
                          />
                        </Flex.Cell>
                        <Button
                          onClick={() =>
                            deleteDocument(doc.id, question.id, doc.id)
                          }
                          label={
                            <Text
                              color={Color.semantic.$error50}
                              size={13}
                              variant='medium'
                              text='Remove'
                            />
                          }
                          variant='tertiary'
                          small
                          loading={deleteLoading[doc.id]}
                        />
                      </Flex>
                    );
                  })}
              </Flex>
            );
          })}
        </Flex>
      </Card>
      <Modal
        showModal={showEncryptedModal}
        closeToggle={() => setShowEncryptedModal(false)}
        maxWidth={536}
      >
        <Flex direction='column' alignItems='center' gap={24} padding={32}>
          <AttentionNeeded />
          <Flex.Cell>
            <Text
              variant='medium'
              size={23}
              textAlign='center'
              text='Looks like this file is password protected'
            />
            <Text
              text='Please upload a copy of your return without password protection.'
              textAlign='center'
            />
          </Flex.Cell>
          <Flex gap={16} justifyContent='flex-end'>
            <Button
              variant='outlined'
              label='Close'
              onClick={() => setShowEncryptedModal(false)}
              dataTestId={'close-encrypted-modal'}
            />
          </Flex>
        </Flex>
      </Modal>
    </>
  ) : (
    <></>
  );
};
