import React from 'react';
import { useHistoryStore } from 'stores/useStores';
import { Color, Flex, Link, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { Page } from 'lib/constants';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '66px',
    padding: '9px 16px 9px 16px',
    backgroundColor: `${Color.green._80}`,
    borderBottom: '1px solid black',
    '@media (min-width: 980px)': {
      height: '46px',
      padding: '9px 48px 9px 48px',
    },
  },
  message: {
    color: `${Color.neutral.white}`,
    fontSize: '13px',
    width: '300px',
    '@media (min-width: 980px)': {
      width: 'auto',
      lineHeight: '46px',
    },
  },
  link: {
    color: `${Color.neutral.white}`,
    paddingLeft: '6px',
  },
  comingSoon: {
    width: '108px',
    height: '28px',
    lineHeight: '27px',
    textAlign: 'center',
    color: `${Color.lime._60}`,
    border: `1px solid ${Color.lime._60}`,
    borderRadius: '4px',
    fontSize: '12px',
  },
}));

export const EmployeeBenefitsBanner = () => {
  const classes = useStyles();
  const history = useHistoryStore();

  return (
    <div className={classes.root} data-testid={'employee-benefits-banner'}>
      <Flex direction='row' alignItems='center'>
        <Flex direction='row' alignItems='center'>
          <Text className={classes.message} size={13}>
            Save money all year round with savings tailored for both employers
            and employees.
          </Text>
          <Link
            className={classes.link}
            data-testid='employee-benefits-banner-link'
            size={13}
            underline
            onClick={() => history.push(`/${Page.sharedSavings}`)}
          >
            Learn more
          </Link>
        </Flex>
        <div className={classes.comingSoon}>Coming soon</div>
      </Flex>
    </div>
  );
};
