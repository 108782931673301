import React, { useEffect, useState } from 'react';
import {
  Carousel,
  Color,
  Expandable,
  ExpandableHeader,
  Flex,
  Icon,
  IconEnum,
  Link,
  Text,
} from 'component-library';
import TaxCreditsIcon from '../../../../components/icons/TaxCreditsIcon';
import { makeStyles } from '@material-ui/core';
import SettingsIcon from '../../../../components/icons/SettingsIcon';

const useStyles = makeStyles(() => ({
  carousel: {
    borderRadius: '4px',
    borderLeft: `3px solid ${Color.blue._60}`,
    width: '100%',
  },
  carouselCard: {
    backgroundColor: `${Color.neutral._light_20} !important`,
    height: '100%',
  },
  cardContent: {
    backgroundColor: `${Color.neutral.white}`,
    border: `solid 1px ${Color.neutral._20}`,
    padding: '32px',
  },
  cardMainTitle: {
    fontWeight: 700,
    marginTop: -7,
  },
  cardSubtitle: {
    fontWeight: 500,
    marginTop: -5,
  },
  introDescription: {
    fontSize: 15,
  },
  introDescription2: {
    paddingBottom: '24px',
  },
  header: {
    fontWeight: 700,
  },
  listItems: {
    marginLeft: 25,
    paddingLeft: 0,
    fontSize: '15px',
    '& li': {
      padding: '5px 0px',
    },
  },
  iconContainer: {
    background: Color.neutral._light_20,
    padding: '4px',
    borderRadius: '16px',
    height: '32px',
  },
  researchIcon: {
    paddingLeft: '5px',
  },
  cardHeader: {
    boxShadow:
      '0px 0px 4px rgba(21, 36, 31, 0.04),0px 0px 16px rgba(238, 238, 238, 0.16)',
    border: `1px solid ${Color.neutral._20}`,
  },
}));

interface EducationalCardsProps {
  onCompleteButtonClick: () => void;
}

export const EducationalCards = ({
  onCompleteButtonClick,
}: EducationalCardsProps) => {
  const classes = useStyles();

  const [showEducationalCards, setShowEducationalCards] =
    useState<boolean>(true);
  const [educationalCardsEditMode, setEducationalCardsEditMode] =
    useState<boolean>(true);

  useEffect(() => {
    if (educationalCardsEditMode) {
      setShowEducationalCards(true);
    } else {
      setShowEducationalCards(false);
    }
  }, [educationalCardsEditMode]);

  return (
    <>
      {!showEducationalCards && !educationalCardsEditMode && (
        <ExpandableHeader
          editMode={educationalCardsEditMode}
          setEditMode={setEducationalCardsEditMode}
          title={`What are R&D activities?`}
          subtitle={`R&D activities are activities directly engaging in, directly supervising, and/or directly supporting eligible research.`}
          labelText={'View'}
          dataTestId={`educational-card-header`}
          className={classes.cardHeader}
        />
      )}
      <Expandable expand={showEducationalCards}>
        <Carousel
          visibleItems={1}
          gap={8}
          showButtons={true}
          className={classes.carousel}
          onCompleteButtonClick={() => {
            onCompleteButtonClick();
            setEducationalCardsEditMode(false);
          }}
          dataTestId={'educational-cards-carousel'}
        >
          <Carousel.Card key={1} className={classes.carouselCard}>
            <Flex direction='row' gap={16}>
              <TaxCreditsIcon />
              <Flex direction='column' gap={8}>
                <Text className={classes.header} variant='medium' size={23}>
                  What are R&D activities?
                </Text>
                <Text
                  className={classes.introDescription}
                  size={15}
                  paddingBottom={24}
                >
                  R&D activities are activities directly engaging in, directly
                  supervising, and/or directly supporting eligible research.
                  “Eligible research” is both technical and experimental in
                  nature, seeks to eliminate technical uncertainty in the
                  development or improvement of a product or process, and is
                  useful in the development of a new or improved project.
                </Text>
              </Flex>
            </Flex>
            <Flex direction='column' gap={8} className={classes.cardContent}>
              <Text paddingBottom={24}>
                Companies engaged in the following activities qualify for R&D
                tax credits.
              </Text>
              <Flex direction='row' gap={8}>
                <Flex direction='row' alignItems={'center'} gap={8}>
                  <div className={classes.iconContainer}>
                    <Icon
                      name={IconEnum.research}
                      className={classes.researchIcon}
                    />
                  </div>
                  <Text className={classes.cardSubtitle} size={18}>
                    Direct Research
                  </Text>
                </Flex>
                <Flex direction='row' alignItems={'center'} gap={8}>
                  <div className={classes.iconContainer}>
                    <Icon name={IconEnum.audit} />
                  </div>
                  <Text className={classes.cardSubtitle} size={18}>
                    Direct Supervision
                  </Text>
                </Flex>
                <Flex direction='row' alignItems={'center'} gap={8}>
                  <div className={classes.iconContainer}>
                    <SettingsIcon />
                  </div>
                  <Text className={classes.cardSubtitle} size={18}>
                    Direct Support
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Carousel.Card>
          <Carousel.Card key={2} className={classes.carouselCard}>
            <Flex direction='row' gap={8}>
              <Icon
                name={IconEnum.check_circle_filled}
                color={Color.semantic.$success50}
              />
              <Text
                className={classes.cardMainTitle}
                size={23}
                paddingBottom={16}
              >
                Qualifying R&D Activities
              </Text>
            </Flex>
            <Flex direction='column' gap={8} className={classes.cardContent}>
              <Flex direction='row' alignItems={'center'} gap={8}>
                <div className={classes.iconContainer}>
                  <Icon
                    name={IconEnum.research}
                    className={classes.researchIcon}
                  />
                </div>
                <Text className={classes.cardSubtitle} size={18}>
                  Direct Research
                </Text>
              </Flex>
              <Flex>
                <Text paddingBottom={16}>
                  The IRS regulations define “engaging in direct qualified
                  research” as work that directly contributes to the qualified
                  research.
                </Text>
              </Flex>
              <Flex direction='column'>
                <Text className={classes.cardSubtitle}>
                  <strong>Examples:</strong>
                </Text>
                <ul className={classes.listItems}>
                  <li>
                    Developing functional, design, and test specifications
                  </li>
                  <li>
                    Building new architecture, algorithms, or database
                    management techniques
                  </li>
                  <li>
                    Writing software programs that comprise a new or improved
                    business component
                  </li>
                </ul>
              </Flex>
              <Flex>
                <Link
                  external={true}
                  href='https://www.irs.gov/businesses/audit-guidelines-on-the-application-of-the-process-of-experimentation-for-all-software'
                >
                  More details
                </Link>
              </Flex>
            </Flex>
          </Carousel.Card>
          <Carousel.Card key={3} className={classes.carouselCard}>
            <Flex direction='row' gap={8}>
              <Icon
                name={IconEnum.check_circle_filled}
                color={Color.semantic.$success50}
              />
              <Text
                className={classes.cardMainTitle}
                size={23}
                paddingBottom={16}
              >
                Qualifying R&D Activities
              </Text>
            </Flex>
            <Flex direction='column' gap={8} className={classes.cardContent}>
              <Flex direction='row' alignItems={'center'} gap={8}>
                <div className={classes.iconContainer}>
                  <Icon name={IconEnum.audit} />
                </div>
                <Text className={classes.cardSubtitle} size={18}>
                  Direct Supervision
                </Text>
              </Flex>
              <Flex>
                <Text paddingBottom={16}>
                  Direct supervision is “the immediate supervision (first-line
                  management) of qualified research.”
                </Text>
              </Flex>
              <Flex direction='column'>
                <Text className={classes.cardSubtitle}>
                  <strong>Examples:</strong>
                </Text>
                <ul className={classes.listItems}>
                  <li>Team brainstorms</li>
                  <li>Design reviews</li>
                  <li>Running project status meetings</li>
                  <li>Evaluating the performance of research teams</li>
                </ul>
              </Flex>
            </Flex>
          </Carousel.Card>
          <Carousel.Card key={4} className={classes.carouselCard}>
            <Flex direction='row' gap={8}>
              <Icon
                name={IconEnum.check_circle_filled}
                color={Color.semantic.$success50}
              />
              <Text
                className={classes.cardMainTitle}
                size={23}
                paddingBottom={16}
              >
                Qualifying R&D Activities
              </Text>
            </Flex>
            <Flex direction='column' gap={8} className={classes.cardContent}>
              <Flex direction='row' alignItems={'center'} gap={8}>
                <div className={classes.iconContainer}>
                  <SettingsIcon />
                </div>
                <Text className={classes.cardSubtitle} size={18}>
                  Direct Support
                </Text>
              </Flex>
              <Flex>
                <Text paddingBottom={16}>
                  Direct support can be direct support of qualifying research or
                  direct support of those directly supervising the qualifying
                  research.
                </Text>
              </Flex>
              <Flex direction='column'>
                <Text className={classes.cardSubtitle}>
                  <strong>Examples:</strong>
                </Text>
                <ul className={classes.listItems}>
                  <li>Obtaining estimates of resource requirements</li>
                  <li>Developing requirements or specs</li>
                  <li>
                    Acquiring necessary people, software, hardware, or monetary
                    resources
                  </li>
                  <li>Running benchmark programs to improve and experiment</li>
                  <li>Attending technical and design review meetings</li>
                  <li>
                    Providing feedback to the development team about an a/b test
                  </li>
                </ul>
              </Flex>
            </Flex>
          </Carousel.Card>
          <Carousel.Card key={5} className={classes.carouselCard}>
            <Flex direction='row' gap={8}>
              <Icon
                name={IconEnum.circle_xmark_filled}
                color={Color.semantic.$error50}
              />
              <Text
                className={classes.cardMainTitle}
                size={23}
                paddingBottom={16}
              >
                Non-Qualifying R&D Activities
              </Text>
            </Flex>
            <Flex direction='column' gap={8} className={classes.cardContent}>
              <Flex>
                <Text paddingBottom={16}>
                  Some subset of activities may never qualify for the R&D credit
                  because general administrative services or other services that
                  only indirectly benefit research activities are not considered
                  qualifying activities.
                </Text>
              </Flex>
              <Flex direction='column'>
                <Text className={classes.cardSubtitle}>
                  <strong>Examples:</strong>
                </Text>
                <ul className={classes.listItems}>
                  <li>Most pure HR-type roles</li>
                  <li>
                    Building team job ladders, or interview rubrics, or employee
                    training programs
                  </li>
                  <li>
                    Market research, market testing, or marketing development
                  </li>
                  <li>Research in the social sciences</li>
                  <li>
                    Setting up general company foundations (eg. setting up
                    software or bank accounts)
                  </li>
                  <li>
                    Routine bug fixing, data gathering, or maintenance on
                    existing and stable software products
                  </li>
                </ul>
              </Flex>
            </Flex>
          </Carousel.Card>
        </Carousel>
      </Expandable>
    </>
  );
};
