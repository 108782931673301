import React from 'react';

interface Props {
  className?: string;
}

const DocumentsFolder = ({ className }: Props) => (
  <svg
    width='138'
    height='104'
    viewBox='0 0 138 104'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M114.228 24H40.6666C39.9716 24 39.4082 24.5634 39.4082 25.2584V78.1124C39.4082 78.8074 39.9716 79.3708 40.6666 79.3708H114.228C114.923 79.3708 115.486 78.8074 115.486 78.1124V25.2584C115.486 24.5634 114.923 24 114.228 24Z'
      fill='black'
    />
    <path
      d='M15.8129 40.7056H2.17786C1.48285 40.7056 0.919434 41.269 0.919434 41.9641V44.4809C0.919434 45.1759 1.48285 45.7393 2.17786 45.7393H15.8129C16.5079 45.7393 17.0713 45.1759 17.0713 44.4809V41.9641C17.0713 41.269 16.5079 40.7056 15.8129 40.7056Z'
      fill='#F9F7EE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.17768 41.3348C1.83017 41.3348 1.54847 41.6166 1.54847 41.9641V44.4809C1.54847 44.8284 1.83017 45.1101 2.17768 45.1101H15.8127C16.1602 45.1101 16.4419 44.8284 16.4419 44.4809V41.9641C16.4419 41.6166 16.1602 41.3348 15.8127 41.3348H2.17768ZM0.290039 41.9641C0.290039 40.9215 1.13516 40.0764 2.17768 40.0764H15.8127C16.8552 40.0764 17.7004 40.9215 17.7004 41.9641V44.4809C17.7004 45.5234 16.8552 46.3686 15.8127 46.3686H2.17768C1.13516 46.3686 0.290039 45.5234 0.290039 44.4809V41.9641Z'
      fill='#00283C'
    />
    <path
      d='M135.816 32.5573H97.9C97.205 32.5573 96.6416 33.1207 96.6416 33.8157V36.3326C96.6416 37.0276 97.205 37.591 97.9 37.591H135.816C136.511 37.591 137.075 37.0276 137.075 36.3326V33.8157C137.075 33.1207 136.511 32.5573 135.816 32.5573Z'
      fill='#F9F7EE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M97.8998 33.1865C97.5523 33.1865 97.2706 33.4682 97.2706 33.8157V36.3326C97.2706 36.6801 97.5523 36.9618 97.8998 36.9618H135.816C136.164 36.9618 136.445 36.6801 136.445 36.3326V33.8157C136.445 33.4682 136.164 33.1865 135.816 33.1865H97.8998ZM96.0122 33.8157C96.0122 32.7732 96.8573 31.9281 97.8998 31.9281H135.816C136.859 31.9281 137.704 32.7732 137.704 33.8157V36.3326C137.704 37.3751 136.859 38.2202 135.816 38.2202H97.8998C96.8573 38.2202 96.0122 37.3751 96.0122 36.3326V33.8157Z'
      fill='#00283C'
    />
    <path
      d='M135.822 65.4337H101.077C100.382 65.4337 99.8188 65.9971 99.8188 66.6921V69.209C99.8188 69.904 100.382 70.4674 101.077 70.4674H135.822C136.517 70.4674 137.081 69.904 137.081 69.209V66.6921C137.081 65.9971 136.517 65.4337 135.822 65.4337Z'
      fill='#00A15A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M101.078 66.0629C100.73 66.0629 100.448 66.3446 100.448 66.6921V69.209C100.448 69.5565 100.73 69.8382 101.078 69.8382H135.823C136.17 69.8382 136.452 69.5565 136.452 69.209V66.6921C136.452 66.3446 136.17 66.0629 135.823 66.0629H101.078ZM99.1899 66.6921C99.1899 65.6496 100.035 64.8045 101.078 64.8045H135.823C136.865 64.8045 137.71 65.6496 137.71 66.6921V69.209C137.71 70.2515 136.865 71.0966 135.823 71.0966H101.078C100.035 71.0966 99.1899 70.2515 99.1899 69.209V66.6921Z'
      fill='#102736'
    />
    <path
      d='M53.0749 50.4144H18.3297C17.6347 50.4144 17.0713 50.9778 17.0713 51.6728V54.1896C17.0713 54.8847 17.6347 55.4481 18.3297 55.4481H53.0749C53.7699 55.4481 54.3333 54.8847 54.3333 54.1896V51.6728C54.3333 50.9778 53.7699 50.4144 53.0749 50.4144Z'
      fill='#00A15A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.3295 51.0436C17.982 51.0436 17.7003 51.3253 17.7003 51.6728V54.1897C17.7003 54.5372 17.982 54.8189 18.3295 54.8189H53.0747C53.4222 54.8189 53.7039 54.5372 53.7039 54.1897V51.6728C53.7039 51.3253 53.4222 51.0436 53.0747 51.0436H18.3295ZM16.4419 51.6728C16.4419 50.6303 17.287 49.7852 18.3295 49.7852H53.0747C54.1172 49.7852 54.9623 50.6303 54.9623 51.6728V54.1897C54.9623 55.2322 54.1172 56.0773 53.0747 56.0773H18.3295C17.287 56.0773 16.4419 55.2322 16.4419 54.1897V51.6728Z'
      fill='#102736'
    />
    <path
      d='M53.2822 36.5843H28.1262C27.4089 36.5843 26.8237 37.1631 26.8237 37.8741L39.408 78.0809C39.408 78.7919 39.9932 79.3708 40.7105 79.3708H113.611C114.328 79.3708 114.914 78.7919 114.914 78.0809L102.329 47.973C102.329 47.262 101.744 46.6831 101.027 46.6831H62.3491L57.2022 38.711C56.3464 37.3834 54.8678 36.5843 53.2759 36.5843H53.2822Z'
      fill='#F9F7EE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.1948 37.8741C26.1948 36.8097 27.0677 35.955 28.1265 35.955H53.2762C53.2783 35.955 53.2804 35.955 53.2825 35.955C55.0828 35.9572 56.7591 36.862 57.7311 38.3697M57.7311 38.3697L62.6922 46.0539H101.027C102.04 46.0539 102.882 46.8357 102.954 47.8351L115.543 77.9547V78.0809C115.543 79.1453 114.67 80 113.611 80H40.7108C39.6871 80 38.8373 79.2012 38.782 78.1862L26.1948 37.9703V37.8741M53.2762 37.2135H28.1265C27.7792 37.2135 27.4987 37.4724 27.4583 37.794L40.0375 77.9847V78.0809C40.0375 78.4384 40.335 78.7416 40.7108 78.7416H113.611C113.95 78.7416 114.224 78.496 114.276 78.1863L101.7 48.0992V47.973C101.7 47.6155 101.403 47.3124 101.027 47.3124H62.0067L56.6738 39.0523C55.9355 37.9068 54.6571 37.2135 53.2762 37.2135Z'
      fill='#102736'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.7329 42.8449C33.7329 42.4974 34.0146 42.2157 34.3621 42.2157H54.3271C54.6746 42.2157 54.9563 42.4974 54.9563 42.8449C54.9563 43.1924 54.6746 43.4742 54.3271 43.4742H34.3621C34.0146 43.4742 33.7329 43.1924 33.7329 42.8449Z'
      fill='#102736'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M80.603 64.8674C80.603 64.5199 80.8847 64.2382 81.2322 64.2382H101.203C101.551 64.2382 101.833 64.5199 101.833 64.8674C101.833 65.2149 101.551 65.4966 101.203 65.4966H81.2322C80.8847 65.4966 80.603 65.2149 80.603 64.8674Z'
      fill='#102736'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M81.8862 70.373C81.8862 70.0255 82.1679 69.7438 82.5154 69.7438H102.48C102.828 69.7438 103.11 70.0255 103.11 70.373C103.11 70.7206 102.828 71.0023 102.48 71.0023H82.5154C82.1679 71.0023 81.8862 70.7206 81.8862 70.373Z'
      fill='#102736'
    />
  </svg>
);

export default DocumentsFolder;
