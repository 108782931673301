import React, { useState } from 'react';
import {
  Alert,
  Animate,
  Button,
  Card,
  Chip,
  Color,
  Divider,
  Expandable,
  Flex,
  Icon,
  IconEnum,
  Text,
} from 'component-library';
import { useCommonStores } from '../../../../../stores/useStores';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    margin: '0 auto',
    position: 'relative',
  },
  alert: {
    backgroundColor: Color.blue._10,
    border: `0.8px solid ${Color.blue._60}`,
  },
  title: {
    whiteSpace: 'nowrap',
  },
  iconContainer: {
    width: '55px',
    height: '55px',
    flexShrink: 0,
    flexGrow: 0,
    backgroundColor: Color.neutral._10,
    borderRadius: '50%',
  },
  showAssessmentsBtn: {
    width: '25px',
    padding: '0',
  },
  showAssessmentsIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform .2s',
  },
  assessmentsIconClose: {
    transform: 'rotate(-180deg)',
  },
  assessmentContainer: {
    backgroundColor: Color.neutral._10,
    border: `1px solid ${Color.neutral._20}`,
    borderRadius: '4px',
    margin: '1rem 0',
  },
  sectionStart: {
    marginTop: '1rem',
  },
  requiredChip: {
    marginLeft: '1rem',
  },
  downloadExtensionButton: {
    width: '250px',
    padding: '0',
  },
  ul: {
    margin: '8px 0 0',
    color: Color.neutral._60,
  },
  expandable: {
    marginBottom: '1rem',
  },
}));

export const BoirRequired = () => {
  const classes = useStyles();
  const { app, companyStore } = useCommonStores();
  const [showEducation, setShowEducation] = useState<boolean>(false);

  return (
    <Flex
      direction='column'
      className={classes.root}
      data-testid={'beneficial-ownership'}
    >
      <Flex className={classes.mainContent} direction='column'>
        <Animate enter={'fade-in'}>
          <Flex
            direction='column'
            padding={[40, 24, 80, 24]}
            gap={24}
            dataTestId={'beneficial-ownership-next-steps'}
          >
            <Flex direction='column' gap={8}>
              <Text
                text={
                  'Time to file your Beneficial Ownership Information Report'
                }
                variant='medium'
                size={app.isMobile ? 23 : 32}
              />
            </Flex>

            <Divider variant='no-bottom-margin' />

            <Flex direction='column' gap={40} padding={[0, 0, 40, 0]}>
              <Flex direction='column' gap={24}>
                <Alert
                  type={'none'}
                  className={classes.alert}
                  text={
                    <Flex direction='column' gap={12}>
                      <Text
                        text={`As a valued MainStreet customer, we will help ${companyStore.company.name} file this form for free!`}
                        variant='medium'
                      />
                      <Text
                        text={
                          'Other companies are charging $200+ for this service. Just follow the steps below to submit your report for free!'
                        }
                        size={13}
                        color={Color.neutral._80}
                      />
                    </Flex>
                  }
                  iconColor={Color.blue._60}
                  backgroundColor={Color.blue._10}
                />
                <Text size={18} variant={'bold'}>
                  Your next steps
                </Text>
                <Card noMargin key={`beneficial-ownership-card-one`}>
                  <Flex direction='column' padding={24}>
                    <Flex direction='row' gap={16}>
                      <Flex
                        justifyContent='center'
                        alignItems='center'
                        wrap='wrap'
                        className={classes.iconContainer}
                      >
                        <Icon name={IconEnum.id_card_outline} />
                      </Flex>
                      <Flex
                        justifyContent='space-between'
                        alignItems='center'
                        wrap='wrap'
                      >
                        <Flex>
                          <Text variant={'bold'}>
                            Collect beneficial owners&apos; IDs
                          </Text>
                          <Chip
                            text='Required'
                            variant='hard_failure'
                            textVariant={'medium'}
                            textColor={Color.semantic.$error50}
                            dataTestId='ids-required'
                            showIcon={false}
                            className={classes.requiredChip}
                          />
                        </Flex>
                        <Flex>
                          <Text color={Color.neutral._60}>
                            We recommend collecting all beneficial owners&apos;
                            IDs before starting the report. Expand to learn how
                            to identify your company&apos;s beneficial owners.
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex
                        className={classes.showAssessmentsBtn}
                        justifyContent='center'
                        alignItems='center'
                        wrap='wrap'
                      >
                        <Button
                          className={classes.showAssessmentsBtn}
                          variant='tertiary'
                          onClick={() => setShowEducation(!showEducation)}
                          label={
                            <>
                              <Icon
                                className={`${classes.showAssessmentsIcon} ${
                                  showEducation
                                    ? classes.assessmentsIconClose
                                    : ''
                                }`}
                                name={IconEnum.chevron_down}
                                color={Color.neutral._60}
                              />
                            </>
                          }
                        />
                      </Flex>
                    </Flex>
                    <Expandable
                      expand={showEducation}
                      className={classes.expandable}
                    >
                      <>
                        <Flex
                          direction={'column'}
                          className={classes.assessmentContainer}
                          alignItems='start'
                          justifyContent='center'
                          padding={16}
                        >
                          <Text variant={'bold'}>
                            Who are our beneficial owners?
                          </Text>
                          <Text color={Color.neutral._60}>
                            Beneficial owners are those who own 25%+ of your
                            company, and/or have substantial decision-making
                            power:
                          </Text>

                          <Flex
                            alignItems={'center'}
                            className={classes.sectionStart}
                            gap={4}
                          >
                            <Icon viewBoxSize={18} name={IconEnum.person} />
                            <Text>Individual Owners</Text>
                          </Flex>
                          <Text color={Color.neutral._60}></Text>
                          <ul className={classes.ul}>
                            <li>
                              Individuals who own at least 25% of a company.
                              Ownership includes stocks, profit shares, options,
                              etc.
                            </li>
                            <li>
                              For most MainStreet customers, this would be a
                              co-founder or angel investor.
                            </li>
                            <li>
                              If your company is owned by another, you should
                              include that company&apos;s beneficial owners.
                            </li>
                          </ul>

                          <Flex
                            alignItems={'center'}
                            className={classes.sectionStart}
                            gap={4}
                          >
                            <Icon viewBoxSize={22} name={IconEnum.money_hand} />
                            <Text>Major Investors</Text>
                          </Flex>
                          <Text color={Color.neutral._60}></Text>
                          <ul className={classes.ul}>
                            <li>
                              Financial institutions that own at least 25% of a
                              company. Ownership includes stocks, profit shares,
                              options, etc.
                            </li>
                            <li>
                              For most MainStreet customers, this will be a VC
                              firm.
                            </li>
                            <li>
                              You will only need to provide the name of this
                              institution, not their IDs.
                            </li>
                          </ul>

                          <Flex
                            alignItems={'center'}
                            className={classes.sectionStart}
                            gap={4}
                          >
                            <Icon viewBoxSize={18} name={IconEnum.hammer} />
                            <Text>Decision Makers</Text>
                          </Flex>
                          <Text color={Color.neutral._60}>
                            Decision makers do not need to own any amount of a
                            company. They can be any of the following:
                          </Text>
                          <Text color={Color.neutral._60}></Text>
                          <ul className={classes.ul}>
                            <li>
                              Senior officers (CEO, President, COO, CFO, CPO,
                              etc.)
                            </li>
                            <li>
                              Someone who can appoint or fire a board member or
                              senior officer
                            </li>
                            <li>
                              Non-employees with significant influence over
                              business, finances, and/or structure decisions
                            </li>
                          </ul>
                        </Flex>
                      </>
                    </Expandable>
                  </Flex>
                </Card>
                <Card noMargin key={`beneficial-ownership-card-two`}>
                  <Flex direction='row' gap={16} padding={24}>
                    <Flex
                      justifyContent='center'
                      alignItems='center'
                      wrap='wrap'
                      className={classes.iconContainer}
                    >
                      <Icon name={IconEnum.download_filled} />
                    </Flex>
                    <Flex
                      justifyContent='space-between'
                      alignItems='center'
                      wrap='wrap'
                    >
                      <Flex>
                        <Text variant={'bold'}>
                          Use the MainStreet Chrome extension to file your
                          report
                        </Text>
                        <Chip
                          text='Required'
                          variant='hard_failure'
                          textVariant={'medium'}
                          textColor={Color.semantic.$error50}
                          dataTestId='ids-required'
                          showIcon={false}
                          className={classes.requiredChip}
                        />
                      </Flex>
                      <Flex>
                        <Text color={Color.neutral._60}>
                          Simply log in with your MainStreet account, and our
                          Chrome extension will use your data to help you
                          through the Beneficial Ownership Information Report
                          and other forms.
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex
                      className={classes.downloadExtensionButton}
                      justifyContent='end'
                      alignItems='center'
                      wrap='wrap'
                    >
                      <Button
                        variant='outlined'
                        dataTestId={'download-chrome-extension-boir-review'}
                        onClick={() =>
                          open(
                            'https://chromewebstore.google.com/detail/cofounder/cdjmoojfbmfbdlohfghkfhinacllljjl',
                          )
                        }
                        label={'Download Extension'}
                      />
                    </Flex>
                  </Flex>
                </Card>
              </Flex>
            </Flex>
          </Flex>
        </Animate>
      </Flex>
    </Flex>
  );
};
