import React, { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ darkerNavy }: Theme) => ({
  container: {
    display: 'flex',
    padding: '20px 40px',
    backgroundColor: 'rgba(0,0,0,.02)',
  },
  disclaimer: {
    maxWidth: '18px',
    marginRight: '15px',
    '& img': {
      maxWidth: '100%',
    },
  },
  content: {
    position: 'relative',
    color: darkerNavy,
    fontSize: '14px',
    lineHeight: '1.6',
    '& p': {
      fontWeight: 500,
      margin: '0',
    },
    '& ul': {
      paddingLeft: '15px',
      marginBottom: '0',
    },
  },
}));

export const UploadInstructions: FunctionComponent = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.disclaimer}>
        <img
          src={`${process.env.PUBLIC_URL}/images/disclaimer.png`}
          alt='Upload Disclaimer'
        />
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
