import * as React from 'react';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 400,
    fontSize: 14,
    padding: '20px 0',
    margin: 0,
    alignItems: 'flex-start',
  },
  label: {
    fontWeight: 400,
    fontSize: 14,
  },
  icon: {
    padding: 0,
    marginTop: 4,
    marginRight: 10,
    fontSize: 16,
    color: '#333333',
  },
  iconContainer: {
    padding: 0,
    margin: 0,
  },
}));

interface CheckableItemProps {
  name: string;
  checked: boolean;
  handleChange: (checked: boolean) => void;
}

export const CheckableItem = ({
  name,
  checked,
  handleChange,
}: CheckableItemProps) => {
  const classes = useStyles();

  const onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.checked);
  };

  const checkbox = (
    <Checkbox
      color={'primary'}
      icon={<CheckBoxOutlineBlank className={classes.icon} />}
      checkedIcon={<CheckBoxOutlined className={classes.icon} />}
      checked={checked}
      onChange={onCheck}
      name={name}
      className={classes.iconContainer}
    />
  );

  return (
    <FormControlLabel
      classes={{ root: classes.root, label: classes.label }}
      color={'primary'}
      control={checkbox}
      label={name}
    />
  );
};
