import React from 'react';
import { useTaxCreditsStores } from 'stores/useStores';
import { Page, QualificationStepsIndex } from 'lib/constants';
import {
  ConnectPayroll,
  CreditOverview,
  RDActivities,
  PayrollDetails,
  RDFourPartTest,
} from '../steps';

interface QualificationRoutesInterface {
  exact?: boolean;
  path?: string;
  component: React.ReactElement | JSX.Element;
}

const useRDQualificationRoutes = (
  taxYear: number,
): { RDQualificationRoutes: QualificationRoutesInterface[] } => {
  const { rdQualification } = useTaxCreditsStores();

  return {
    RDQualificationRoutes: [
      {
        exact: true,
        path: `${rdQualification.path}/${taxYear}`,
        component: (
          <ConnectPayroll
            taxYear={taxYear}
            pathTo={`/${Page.taxCreditQualificationRoot}/${taxYear}/${Page.taxCreditRdPayrollDetails}`}
            onNext={() =>
              rdQualification.setCurrentStep(
                QualificationStepsIndex.PAYROLL_DETAILS,
              )
            }
          />
        ),
      },
      {
        path: `${rdQualification.path}/${taxYear}/${Page.taxCreditRdPayrollDetails}`,
        component: (
          <PayrollDetails
            taxYear={taxYear}
            pathTo={`/${Page.taxCreditQualificationRoot}/${taxYear}/${Page.taxCreditRdOverview}`}
            pathFrom={`${rdQualification.path}/${taxYear}`}
            onNext={() =>
              rdQualification.setCurrentStep(QualificationStepsIndex.OVERVIEW)
            }
            onBack={() =>
              rdQualification.setCurrentStep(
                QualificationStepsIndex.CONNECT_PAYROLL,
              )
            }
          />
        ),
      },
      {
        path: `${rdQualification.path}/${taxYear}/${Page.taxCreditRdOverview}`,
        component: (
          <CreditOverview
            taxYear={taxYear}
            pathTo={`/${Page.taxCreditQualificationRoot}/${taxYear}/${Page.taxCreditRdActivities}`}
            pathFrom={`/${Page.taxCreditQualificationRoot}/${taxYear}/${Page.taxCreditRdPayrollDetails}`}
            onNext={() =>
              rdQualification.setCurrentStep(
                QualificationStepsIndex.RD_ACTIVITIES,
              )
            }
            onBack={() =>
              rdQualification.setCurrentStep(
                QualificationStepsIndex.PAYROLL_DETAILS,
              )
            }
          />
        ),
      },
      {
        path: `${rdQualification.path}/${taxYear}/${Page.taxCreditRdActivities}`,
        component: (
          <RDActivities
            taxYear={taxYear}
            pathTo={`/${Page.taxCreditQualificationRoot}/${taxYear}/${Page.taxCreditRd4PartTest}`}
            pathFrom={`/${Page.taxCreditQualificationRoot}/${taxYear}/${Page.taxCreditRdOverview}`}
            onNext={() =>
              rdQualification.setCurrentStep(
                QualificationStepsIndex.RD_4_PART_TEST,
              )
            }
            onBack={() =>
              rdQualification.setCurrentStep(QualificationStepsIndex.OVERVIEW)
            }
          />
        ),
      },
      {
        path: `${rdQualification.path}/${taxYear}/${Page.taxCreditRd4PartTest}`,
        component: (
          <RDFourPartTest
            pathFrom={`/${Page.taxCreditQualificationRoot}/${taxYear}/${Page.taxCreditRdActivities}`}
            onBack={() =>
              rdQualification.setCurrentStep(
                QualificationStepsIndex.RD_ACTIVITIES,
              )
            }
            programTaxYear={taxYear}
          />
        ),
      },
    ],
  };
};

export default useRDQualificationRoutes;
