import React from 'react';
import {
  Button,
  Color,
  Divider,
  Flex,
  FontAwesome,
  Text,
} from 'component-library';
import { observer } from 'mobx-react';
import {
  useCommonStores,
  useFeatureFlags,
  useTaxCreditsStores,
} from 'stores/useStores';
import { Page } from '../../../lib/constants';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  shareAssessmentButton: {
    backgroundColor: `${Color.blue._20}`,
    color: `${Color.blue._60}`,
    '&:hover': {
      color: `${Color.neutral.white}`,
    },
  },
  shareAssessmentButtonTxt: {
    color: `inherit`,
    '&:hover': {
      color: `inherit`,
    },
  },
  icon: {
    color: `inherit !important`,
    '&:hover': {
      color: `inherit !important`,
    },
  },
}));

interface SurveyFlowContainerProps {
  children: React.ReactElement | JSX.Element;
  title: string;
  subtitle?: string;
  onBack?: () => void;
  onContinue: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  dataTestId?: string;
  continueText?: string;
  onSkip?: () => void;
  currentPage?: Page;
}

export const SurveyFlowContainer = observer(
  ({
    children,
    title,
    subtitle,
    onBack,
    onContinue,
    isDisabled,
    isLoading,
    dataTestId,
    continueText,
    currentPage,
    onSkip,
  }: SurveyFlowContainerProps) => {
    const { app } = useCommonStores();
    const { surveyFlow, unifiedTaxCredits } = useTaxCreditsStores();
    const featureFlags = useFeatureFlags();
    const classes = useStyles();
    const { companyStore } = useCommonStores();

    const showSkipButton =
      featureFlags.showSurveySkipButton && !companyStore.accessToken;
    const showShareAssessmentButton =
      !!currentPage &&
      featureFlags.showShareAssessment &&
      !companyStore.accessToken;

    const shareAssessmentLabel = (
      <Flex alignItems={'center'} gap={8}>
        <FontAwesome
          name='fa-regular fa-user-plus'
          size={15}
          className={classes.icon}
        />
        <Text
          text='Share Assessment'
          className={classes.shareAssessmentButtonTxt}
        />
      </Flex>
    );

    return (
      <Flex
        direction='column'
        padding={[40, 24, 80, 24]}
        gap={24}
        dataTestId={dataTestId}
      >
        <Flex direction='column' gap={8}>
          <Text text={title} variant='medium' size={app.isMobile ? 23 : 32} />
          {subtitle && <Text text={subtitle} size={app.isMobile ? 13 : 15} />}
        </Flex>

        <Divider variant='no-bottom-margin' />

        <Flex direction='column' gap={40} padding={[0, 0, 40, 0]}>
          {children}
        </Flex>

        <Flex justifyContent='space-between' gap={40}>
          {onBack ? (
            <Button
              label='Back'
              variant='outlined'
              onClick={onBack}
              dataTestId='survey-back-btn'
            />
          ) : (
            <></>
          )}
          <Flex justifyContent='flex-end' gap={8}>
            {onSkip && showSkipButton && (
              <Button
                label={`Skip - I'll do this later`}
                onClick={onSkip}
                dataTestId='survey-skip-btn'
                variant='tertiary'
                loading={surveyFlow.surveySkipLoading}
              />
            )}
            {showShareAssessmentButton && (
              <Button
                label={shareAssessmentLabel}
                className={classes.shareAssessmentButton}
                onClick={() => {
                  unifiedTaxCredits.setShowShareAssessmentModal(
                    true,
                    currentPage,
                  );
                }}
                dataTestId={'share-assessment-btn'}
              />
            )}
            <Button
              label={continueText || 'Continue'}
              onClick={onContinue}
              disabled={isDisabled}
              loading={isLoading}
              dataTestId='survey-continue-btn'
            />
          </Flex>
        </Flex>
      </Flex>
    );
  },
);
