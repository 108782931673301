import React from 'react';
import { useHistory } from 'react-router-dom';
import { Page } from 'lib/constants';
import { useCompany, useLegacyClients } from 'stores/useStores';
import { observer } from 'mobx-react';
import { useEffectOnce } from 'lib/helpers';

interface GoogleLoginCompletePageProps {
  signup: string | null;
}

export const GoogleLoginCompletePage = observer(
  ({ signup }: GoogleLoginCompletePageProps) => {
    const history = useHistory();
    const { client } = useLegacyClients();
    const { company } = useCompany();

    useEffectOnce(() => {
      client.VerifyGoogleLogin().then(({ data, errorMsg }) => {
        if (errorMsg) {
          const errorToDisplay = `An error happened while logging in with Google: ${errorMsg}`;

          if (signup === 'true') {
            history.push(
              `/${Page.selfSignupPersonInformation}?error=${encodeURIComponent(
                errorToDisplay,
              )}`,
            );
          } else {
            history.push(`/?error=${encodeURIComponent(errorToDisplay)}`);
          }
          return;
        }

        if (data?.company) {
          company.mergeData(data.company);
        }

        if (signup === 'true') {
          history.push(`/${Page.selfSignupCompanyDetails}`);
        } else {
          history.push('/');
        }
      });
    });

    return <></>;
  },
);
