import React, { useState } from 'react';
import {
  Box,
  Collapse,
  Grid,
  Slider as MuiSlider,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import Info from 'components/icons/Info';
import {
  JobGroupEnum,
  JobGroupEnumToString,
  NonRdActivitiesEnumToString,
  NonRdActivitiesForJobGroup,
} from 'lib/constants';

const GRAY = 'rgba(51, 51, 51, 0.8)';

const useStyles = makeStyles((theme: Theme) => ({
  mainBox: {
    padding: '24px 30px',
    background: 'rgba(0, 0, 0, 0.02)',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  rightBox: {
    margin: '40px',
    flex: 5,
  },
  title: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '26px',
    alignContent: 'center',
  },
  subtitle: {
    marginTop: '6px',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '26px',
    alignContent: 'center',
    marginBottom: '8px',
    color: GRAY,
  },
  irsText: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    color: GRAY,
    padding: '0px',
    margin: '0px',
  },
  textField: {
    width: '70px',
    height: '40px',
    backgroundColor: 'white',
  },
  horizontalInstructions: {
    backgroundColor: 'rgba(51, 51, 51, 0.1)',
    padding: '10px 10px 10px 10px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  percent: {
    fontWeight: 300,
    fontSize: '30px',
    textAlign: 'center',
    lineHeight: '27px',
    color: theme.green,
    alignContent: 'center',
    marginBottom: '16px',
  },
  rightSubHeading: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '27px',
    alignContent: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  percentTimeText: {
    width: '80px',
    flexShrink: 0,
    '& input': {
      textAlign: 'right',
    },
  },
  icon: {
    height: '14px',
    width: '14px',
    flexShrink: 0,
    padding: '0px',
    margin: '6px 10px 0px 0px',
  },
  nonRdActivities: {
    marginBottom: '16px',
  },
  nonRdActivitiesTitle: {
    lineHeight: '26px',
    fontWeight: 'normal',
    fontSize: '12px',
  },
}));

export interface ExpenseClassificationSliderProps {
  name: string;
  percent: number;
  setPercent: (percent: number) => void;
  year: number;
  jobGroup: JobGroupEnum;
  lowPercentEstimate: number;
  highPercentEstimate: number;
}

const Slider = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.secondary.main,
    height: 2,
    padding: '15px 0',
  },
  mark: {
    height: '12px',
    width: '3px',
    marginTop: '-5px',
    marginLeft: '-1px',
    backgroundColor: 'currentColor',
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
}))(MuiSlider);

export const ExpenseClassificationSlider = (
  props: ExpenseClassificationSliderProps,
) => {
  const classes = useStyles();

  const nonRdActivities = NonRdActivitiesForJobGroup[props.jobGroup].map(
    (activity) => NonRdActivitiesEnumToString[activity],
  );
  const jobName = JobGroupEnumToString[props.jobGroup];

  const setPercentHelper = (value: number) => {
    if (value < 0 || value > 100) {
      return;
    }
    if (value === props.percent) {
      return;
    }
    props.setPercent(value);
  };

  const handlePercentInput = (event: any) => {
    setPercentHelper(
      event.target.value === '' ? 0 : Number(event.target.value),
    );
  };

  const formatPercentRange = (low: number, high: number) => {
    if (low === high) {
      return `${low}%`;
    }
    return `${low}%–${high}%`;
  };

  const getMarks = (low: number, high: number) => {
    if (low === high) {
      return [{ value: low }];
    }
    return [{ value: low }, { value: high }];
  };
  return (
    <Grid item xs={12} className={classes.mainBox}>
      <Typography className={classes.title}>
        Enter the percentage of {props.name}&apos;s work spent on eligible
        R&amp;D activities in {props.year}
      </Typography>
      <Typography className={classes.subtitle}>
        Most people in this role spend{' '}
        {formatPercentRange(
          props.lowPercentEstimate,
          props.highPercentEstimate,
        )}{' '}
        of their time on eligible R&amp;D activities throughout the year.
      </Typography>
      <div className={classes.nonRdActivities}>
        <TextExpand
          title={
            <Typography
              variant={'caption'}
              className={classes.nonRdActivitiesTitle}
            >
              Examples of common {jobName} activities that are <i>not</i>{' '}
              eligible
            </Typography>
          }
          items={nonRdActivities}
        />
      </div>
      <Box>
        <Grid container alignItems='center'>
          <Grid item xs={1}>
            <TextField
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              value={props.percent || 0}
              onChange={handlePercentInput}
              InputProps={{
                endAdornment: '%',
                className: classes.textField,
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <Box marginLeft={4} marginTop={0.5}>
              <Slider
                valueLabelDisplay='off'
                step={5}
                min={0}
                max={100}
                marks={getMarks(
                  props.lowPercentEstimate,
                  props.highPercentEstimate,
                )}
                value={props.percent || 0}
                onChange={(event, value) => {
                  setPercentHelper(value as number);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {props.percent >= 80 && (
        <Box className={classes.horizontalInstructions}>
          <Info className={classes.icon} />

          <Typography className={classes.irsText}>
            According to the IRS, anyone spending greater than or equal to 80%
            of their time on R&D is allowed to claim 100% of their time on R&D,
            because they were hired with the goal of spending substantially all
            of their time on R&D.
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

const useTextExpandStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: theme.palette.secondary.main,
    marginBottom: '-8px',
    marginLeft: '-8px',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
  title: {
    cursor: 'pointer',
  },
  list: {
    marginLeft: '-8px',
  },
  item: {
    '&::marker': {
      color: GRAY,
    },
  },
  itemText: {
    fontWeight: 'normal',
    fontSize: '12px',
    color: GRAY,
  },
  open: {
    transform: 'rotate(90deg)',
  },
  closed: {
    transform: 'rotate(0)',
  },
}));

const TextExpand = ({ title, items }: { title: any; items: string[] }) => {
  const classes = useTextExpandStyles();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const nonRdActivitiesIconClasses = [
    classes.icon,
    isExpanded ? classes.open : classes.closed,
  ].join(' ');

  return (
    <>
      <div className={classes.title} onClick={() => setIsExpanded(!isExpanded)}>
        <ArrowRightIcon className={nonRdActivitiesIconClasses}></ArrowRightIcon>
        {title}
      </div>
      <Collapse in={isExpanded}>
        <ul className={classes.list}>
          {items.map((activity, index) => (
            <li key={index} className={classes.item}>
              <Typography component={'div'} className={classes.itemText}>
                {activity}
              </Typography>
            </li>
          ))}
        </ul>
      </Collapse>
    </>
  );
};
