import * as React from 'react';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { makeStyles, Theme } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import TestCache from 'components/util/TestCache';

const useStyles = makeStyles(
  ({ breakpoints, boxShadowRegular, pageBackgroundColor }: Theme) => ({
    root: {
      background: pageBackgroundColor,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '72px 0',
    },
    box: {
      background: '#FFF',
      boxShadow: boxShadowRegular,
      display: 'flex',
      flexDirection: 'column',
      padding: '40px',
      alignItems: 'center',
      [breakpoints.down('xs')]: {
        width: '80%',
      },
    },
  }),
);

interface Props {
  search: string;
}

export default function HappyHappyJoyJoy({ search }: Props) {
  const classes = useStyles();
  const { access } = queryString.parse(search);

  const [redirect, setRedirect] = useState<boolean>(false);

  useEffect(() => {
    if (!access || access !== process.env.REACT_APP_HAPPY_ACCESS_CODE) {
      setRedirect(true);
      return;
    }

    // Nothing to see here, redirect always
    // setRedirect(true);
  }, [access]);

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        {/* Put test things here */}
        <TestCache />
      </div>
      {redirect && <Redirect to='/no-match' />}
    </div>
  );
}
