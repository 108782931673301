import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Button,
  Flex,
  Color,
  Heading,
  Icon,
  IconEnum,
  Text,
} from 'component-library';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(({ breakpoints }) => ({
  '@global': {
    [breakpoints.down('xs')]: {
      '.embeddedServiceHelpButton': {
        display: 'none',
      },
    },
  },
  stepContainer: {
    maxWidth: '1200px',
  },
  backBtn: {
    padding: 0,
    alignSelf: 'flex-start',
    color: Color.neutral._80,
    '&:hover': {
      color: Color.neutral._60,
    },
  },
  continueBtn: {
    marginTop: '48px',
    [breakpoints.down('xs')]: {
      marginTop: '0',
    },
  },
}));

interface StepsContainerProps {
  onBackStep?: () => void;
  showContinueButton: boolean;
  onContinue?: () => void;
  onContinueLabel: string | JSX.Element;
  stepTitle: string;
  subtitle?: string;
  lastStep?: boolean;
  errorMsg?: string;
  children: React.ReactElement | JSX.Element;
  isLoading?: boolean;
  continueDisabled?: boolean;
}

const StepsContainer = ({
  onBackStep,
  showContinueButton,
  onContinue,
  onContinueLabel,
  stepTitle,
  subtitle,
  lastStep,
  errorMsg,
  children,
  isLoading,
  continueDisabled,
}: StepsContainerProps) => {
  const classes = useStyles();
  const { app } = useCommonStores();

  return (
    <Flex direction='column'>
      {onBackStep && (
        <Button
          dataTestId='back-button'
          className={classes.backBtn}
          variant='tertiary'
          label={
            <>
              <Icon name={IconEnum.long_left_arrow} />
              <Text text='Back' />
            </>
          }
          onClick={onBackStep}
        />
      )}
      <Heading
        text={stepTitle}
        tag={app.isMobile ? 'h4' : 'h3'}
        marginBottom={8}
      />
      {subtitle && (
        <Text
          text={subtitle}
          size={app.isMobile ? 13 : 15}
          color={Color.neutral._80}
        />
      )}
      <Flex className={classes.stepContainer} direction='column'>
        {children}
        {errorMsg && <Text color={Color.semantic.$error10}>{errorMsg}</Text>}
        {onContinue && showContinueButton && (
          <Flex.Cell alignSelf='flex-end'>
            <Button
              dataTestId='continue-button'
              className={classes.continueBtn}
              label={onContinueLabel}
              onClick={onContinue}
              loading={isLoading}
              variant={lastStep ? 'outlined' : 'contained'}
              disabled={continueDisabled}
            />
          </Flex.Cell>
        )}
      </Flex>
    </Flex>
  );
};

export default StepsContainer;
