import React, { useReducer, useCallback, useContext, useEffect } from 'react';
import {
  usePlaidLink,
  PlaidLinkOptionsWithLinkToken,
  PlaidLinkError,
} from 'react-plaid-link';
import {
  plaidReducer,
  setIsLoading,
  setPlaidLinkToken,
  setPlaidError,
  setConnectedInstitutions,
  setSelectedAccountId,
  setAccounts,
  setDefaultPaymentMethodDetails,
} from './reducer';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { PlaidContext } from './types';
import { DropdownOption } from 'component-library';
import { useIsMounted } from 'component-library/_helpers/use-utils';

export const MSPlaidClientContext = React.createContext<PlaidContext>({
  state: {
    plaidError: null,
    isLoading: true,
    connectedInstitutions: [],
    plaidAccounts: [],
    defaultPaymentMethod: null,
    selectedAccountId: null,
  },
  actions: {
    fetchConnectionDetails: async () => {
      return undefined;
    },
    handleAccountSelect: () => undefined,
    open: () => {
      return;
    },
    setDefaultPaymentMethod: async () => ({ errorMsg: null }),
    getDefaultPaymentMethod: async () => ({ type: '' }),
  },
});

interface MSPlaidClientProviderProps {
  children: React.ReactNode;
  companyId: number;
  includeBalances: boolean;
  isModal?: boolean;
}

/**
 *
 * @param props.children - children component(s) needing to trigger plaidLink flow and select an account
 * @param props.companyId
 * @param props.includeBalances - Whether or not this provider will include balances when fetching ach accounts
 * @returns PlaidLink and account select state management wrapper
 *
 */
export default function MSPlaidClientProvider(
  props: MSPlaidClientProviderProps,
) {
  const [plaidState, dispatch] = useReducer(plaidReducer, {
    plaidError: null,
    isLoading: true,
    plaidLinkToken: null,
    connectedInstitutions: [],
    plaidAccounts: [],
    defaultPaymentMethod: null,
    selectedAccountId: null,
  });

  const auth0Context = useContext(Auth0FeatureContext);

  /**
   * Creates a link token sending the companyId in case they are
   * a returning customer for verification or adding another bank account.
   * @param companyId
   * @param existingLink - whether or not this token is for manual micro deposit verification
   */
  const generatePlaidLinkToken = useCallback(
    async (companyId: number, existingLink = false) => {
      let res;
      if (existingLink) {
        res =
          await auth0Context.plaidClient.generatePlaidLinkTokenForExistingCustomer(
            companyId,
          );
      } else {
        res = await auth0Context.plaidClient.generatePlaidLinkToken(companyId);
      }

      if (res.errorMsg) {
        dispatch(
          setPlaidError({
            plaidError: 'There was an issue connecting to Plaid',
          }),
        );
        return;
      }
      const { linkToken } = res?.data || {};
      if (linkToken) {
        dispatch(setPlaidLinkToken({ linkToken }));
      }
    },
    [dispatch, auth0Context.plaidClient],
  );

  const fetchConnectionDetails = useCallback(
    async (
      companyId = props.companyId,
      includeBalances = props.includeBalances,
      includePermissionRevokedInstitutions = false,
    ) => {
      const res = await auth0Context.plaidClient.getConnectedInstitutions(
        includeBalances,
        includePermissionRevokedInstitutions,
      );

      if (res.errorMsg || res.data === undefined) {
        dispatch(
          setPlaidError({
            plaidError:
              'There was an issue retrieving your connected payment methods',
          }),
        );
        return;
      }

      const connectedInstitutions = res.data;

      // always generate a new link token so one is available to connect a new account on click.
      await generatePlaidLinkToken(companyId);

      // if no accounts found, exit and present with connect a bank account
      if (connectedInstitutions.length === 0) {
        dispatch(setIsLoading({ isLoading: false }));
        return;
      }

      const allAccounts = connectedInstitutions
        .map((inst) =>
          inst.accounts.map((acc) => ({
            ...acc,
            institutionName: inst.name,
          })),
        )
        .flatMap((x) => x);

      dispatch(setIsLoading({ isLoading: false }));

      dispatch(
        setConnectedInstitutions({
          connectedInstitutions,
        }),
      );
      dispatch(setAccounts({ plaidAccounts: allAccounts }));
      return undefined;
    },
    [
      auth0Context.plaidClient,
      generatePlaidLinkToken,
      props.includeBalances,
      props.companyId,
    ],
  );

  /**
   * When the plaid link a customer makes is successful plaidLink will call
   * onSuccess below and deliver the public_token.  This is sent in
   * to exchange for an access_token to store in our DB.
   *
   * Then calling fetchPaymentDetails to populate plaidAccounts for selection.
   */
  const exchangePublicToken = useCallback(
    async (public_token: string, companyId: number) => {
      dispatch(setIsLoading({ isLoading: true }));
      const res = await auth0Context.plaidClient.exchangePublicToken(
        public_token,
        companyId,
      );
      if (res.errorMsg) {
        dispatch(setPlaidError({ plaidError: res.errorMsg }));
        dispatch(setIsLoading({ isLoading: false }));
        return;
      }

      // on successful registration fetch bank information;
      fetchConnectionDetails(companyId, props.includeBalances);
    },
    [fetchConnectionDetails, auth0Context.plaidClient, props.includeBalances],
  );

  /**
   * Retreives default payment method to determine current payment method is either a bank
   * account, a credit card, or none.
   *
   */
  const getDefaultPaymentMethod = React.useCallback(async () => {
    const res = await auth0Context.paymentsClient.getDefaultPaymentMethod();

    if (res.data === undefined || res.errorMsg) {
      dispatch(
        setPlaidError({
          plaidError: `Failed to retreive payment details, please refresh and try again.`,
        }),
      );
    }

    const paymentDetails = res.data;
    let paymentType = '';

    if (paymentDetails?.associatedAccount) {
      paymentType = 'bank';
    } else if (paymentDetails?.associatedCard) {
      paymentType = 'card';
    }
    dispatch(
      setDefaultPaymentMethodDetails({
        defaultPaymentMethod: paymentDetails,
      }),
    );

    return {
      type: paymentType,
    };
  }, [auth0Context.paymentsClient]);

  /**
   * @param paymentMethod
   *
   */
  const setDefaultPaymentMethod = async (
    connectedAccountId: string | null,
    ccPaymentMethodId: string | null,
  ) => {
    const res = await auth0Context.paymentsClient.setDefaultPaymentMethod(
      connectedAccountId,
      ccPaymentMethodId,
    );

    if (res.errorMsg) {
      return { errorMsg: res.errorMsg };
    } else {
      await getDefaultPaymentMethod();
      return { errorMsg: null };
    }
  };

  /**
   * onSuccess - plaid link callback for handling registration of
   * plaid link public_token for an access_token.
   */
  const onSuccess = useCallback(
    async (public_token: string) => {
      exchangePublicToken(public_token, props.companyId);
    },
    [exchangePublicToken, props.companyId],
  );

  /**
   * onExit - plaid link callback for handling errors encountered
   * during plaid link process
   */
  const onExit = useCallback(
    async (error: PlaidLinkError | null) => {
      if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
        await generatePlaidLinkToken(props.companyId);
        dispatch(setIsLoading({ isLoading: false }));
      }
    },
    [generatePlaidLinkToken, props.companyId],
  );

  /**
   * plaid link configuration
   */
  const config: PlaidLinkOptionsWithLinkToken = {
    token: plaidState.plaidLinkToken ?? '',
    onSuccess,
    onExit,
  };

  const { open, ready } = usePlaidLink(config);

  const openPlaidLink = () => {
    if (ready) {
      open();
    }
  };

  /**
   * @param account
   * Dropdown option from account select whose `account.value` is the selected account ID
   * or the string "NEW_ACCOUNT". This will either save the selected account ID in state or
   * will allow the plaid link flow depending on the selected option.
   */
  const handleAccountSelect = async (account: string | DropdownOption) => {
    if (typeof account !== 'string') {
      if (account.value === 'NEW_ACCOUNT') {
        // create a new link token and reset selected account (if previously selected)
        await generatePlaidLinkToken(props.companyId);
      } else {
        // check if a link token for manual verification is needed and set selected account id.
        const selectedAccount = plaidState.plaidAccounts.find(
          (acc) => acc.id === account.value,
        );
        if (
          selectedAccount?.verificationStatus === 'pending_manual_verification'
        ) {
          await generatePlaidLinkToken(props.companyId, true);
        }
      }
      dispatch(setSelectedAccountId({ selectedAccountId: account.value }));
    }
  };

  /**
   * Provided Context state and actions.
   */
  const value: PlaidContext = {
    state: {
      connectedInstitutions: [],
      plaidAccounts: plaidState.plaidAccounts,
      plaidError: plaidState.plaidError,
      isLoading: plaidState.isLoading,
      defaultPaymentMethod: plaidState.defaultPaymentMethod,
      selectedAccountId: plaidState.selectedAccountId,
    },
    actions: {
      open: openPlaidLink,
      fetchConnectionDetails,
      handleAccountSelect,
      setDefaultPaymentMethod,
      getDefaultPaymentMethod,
    },
  };

  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted() && props.isModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isMounted, props.isModal]);

  return (
    <MSPlaidClientContext.Provider value={value}>
      {props.children}
    </MSPlaidClientContext.Provider>
  );
}
