import {
  FormHelperText,
  makeStyles,
  TextField,
  Theme,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import {
  ALL_PAYROLL_SYSTEMS,
  CreditCategoryEnum,
} from 'lib/constants/globalConstants';
import LoadingWidget from 'components/util/LoadingWidget';
import { Formik } from 'formik';
import { CreateCompanySelfSignupRequest, SourcingData } from 'lib/interfaces';
import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import * as yup from 'yup';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import {
  Animate,
  Button,
  Color,
  Heading,
  FontAwesome,
  Link,
  Flex,
} from 'component-library';
import { useCommonStores, useCompany, useFeatureFlags } from 'stores/useStores';
import { useIsMounted } from 'lib/helpers';
import { observer } from 'mobx-react';
import { PasswordChecklist } from 'pages/onboarding/components/PasswordChecklist';
import { usePasswordValidation } from 'lib/usePasswordValidation';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import { useLocation } from 'react-router-dom';

interface WelcomePagePartnerProps {
  partner: string;
}

const useStyles = makeStyles(({ palette, breakpoints }: Theme) => ({
  '@global': {
    '.embeddedServiceHelpButton': {
      display: 'none !important',
    },
  },
  root: {
    display: 'grid',
    height: '100%',
    maxHeight: '100%',
    gridTemplateColumns: `58% 42%`,
    background: `url(${process.env.PUBLIC_URL}/images/welcome-page-background.jpg)`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: '70% 60%',
    backgroundPosition: `0% 130%`,
    [breakpoints.down('sm')]: {
      background: 'none',
      gridTemplateColumns: 'minmax(0, 1fr)',
    },
  },
  signupRoot: {
    width: '100%',
    height: '100%',
    padding: '70px 75px 20px',
    background: Color.neutral._light_20,
    transition: 'opacity 1s',
    [breakpoints.down('sm')]: {
      padding: '32px 0',
    },
  },
  logo: {
    position: 'absolute',
    top: '24px',
    left: '48px',
    width: '106px',
    [breakpoints.down('sm')]: {
      left: '24px',
    },
  },
  leftContainer: {
    display: 'grid',
    height: '100vh',
    minHeight: '600px',
    transition: 'opacity 1s',
    [breakpoints.down('sm')]: {
      padding: '80px 0 40px',
      height: 'auto',
      minHeight: 'auto',
      background: `url(${process.env.PUBLIC_URL}/images/welcome-page-background.jpg)`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: '100% 60%',
      backgroundPosition: `0% 120%`,
    },
  },
  leftInfoContainer: {
    width: '90%',
    maxWidth: '540px',
    alignSelf: 'center',
    justifySelf: 'center',
    [breakpoints.down('sm')]: {
      alignSelf: 'auto',
      justifySelf: 'auto',
      width: '100%',
      maxWidth: '100%',
      padding: '0 24px',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '580px',
    maxWidth: '100%',
    transition: 'opacity 1s',
    opacity: 1,
    [breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '0px',
    },
  },
  titleMessaging: {
    textAlign: 'center',
    width: '100%',
    maxWidth: '580px',
  },
  mainContent: {
    width: '400px',
    maxWidth: '90%',
    textAlign: 'center',
  },
  title: {
    [breakpoints.down('sm')]: {
      fontSize: '24px',
    },
    '@media (max-width:340px)': {
      fontSize: '21px',
      nextButton: {
        width: '360px',
        height: '50px',
        textTransform: 'initial',
        fontSize: '18px',
        fontWeight: 500,
        [breakpoints.down('sm')]: {
          width: '320px',
        },
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  },
  nextButton: {
    width: '360px',
    height: '50px',
    textTransform: 'initial',
    margin: '0 auto 40px',
    fontSize: '18px',
    fontWeight: 500,
    [breakpoints.down('sm')]: {
      maxWidth: '320px',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  buttonRow: {
    marginTop: '40px',
  },
  errorMsg: {
    margin: '5px 0 0',
    fontSize: '12px',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: '20px',
      marginBottom: '-25px',
    },
  },
  formErrorMsg: {
    marginTop: '15px',
    textAlign: 'center',
  },
  textFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 auto',
    [breakpoints.down('sm')]: {
      maxWidth: '320px',
    },
  },
  textField: {
    width: '100%',
    marginTop: '25px',
    background: Color.neutral.white,
    [breakpoints.down('sm')]: {
      height: '20px',
      marginBottom: '20px',
    },
  },
  inputRoot: {
    '& input': {
      width: 'calc(100% - 28px)',
    },
  },
  linkText: {
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:visited': {
      color: palette.secondary.main,
    },
    '&:link': {
      color: palette.secondary.main,
    },
  },
  loadingContainer: {
    marginBottom: '60px',
  },
  agreementContainer: {
    fontSize: '13px',
    marginTop: '30px',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

const getUtmSourcingData = (urlParams: URLSearchParams) => {
  const sourcingData: SourcingData = {
    utmCampaign: '',
    utmMedium: '',
    utmSource: '',
    utmContent: '',
    utmTerm: '',
  };

  const urlKeyMapping: SourcingData = {
    utmCampaign: 'utm_campaign',
    utmMedium: 'utm_medium',
    utmSource: 'utm_source',
    utmContent: 'utm_content',
    utmTerm: 'utm_term',
  };

  Object.keys(sourcingData).forEach((key) => {
    if (key in urlKeyMapping) {
      const urlParamKey = urlKeyMapping[key as keyof SourcingData];
      if (urlParamKey) {
        const urlParamValue = urlParams.get(urlParamKey);
        if (urlParamValue) {
          sourcingData[key as keyof SourcingData] = urlParamValue;
        }
      }
    }
  });
  return sourcingData;
};

export const WelcomePagePartner = observer(
  ({ partner }: WelcomePagePartnerProps) => {
    const isMounted = useIsMounted();
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [formError, setFormError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [showPasswordHelpers, setShowPasswordHelpers] = useState(false);
    const { client } = useContext(Auth0FeatureContext);
    const { auth, app } = useCommonStores();
    const { company } = useCompany();
    const featureFlags = useFeatureFlags();

    const { validatePassword, isValidPassword, passwordValidation } =
      usePasswordValidation();

    interface FormInputs {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      agreeToTerms: boolean;
    }

    const initialValues: FormInputs = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      agreeToTerms: true,
    };

    const location = useLocation();
    const { search } = location;
    const urlParams = new URLSearchParams(search);
    const sourcingData = getUtmSourcingData(urlParams);
    sourcingData.registrationDeviceType = app.isMobile
      ? 'mobile'
      : app.isTablet
      ? 'tablet'
      : 'desktop';

    const { origin } = window.location;
    const pathComponents = location.pathname.toLowerCase().split('/');
    const redirectUri = origin;

    const submit = async (formValues: FormInputs) => {
      if (isMounted()) {
        setFormError('');
        setLoading(true);
      }

      const res = await client.CreateCompanyAndLoginSelfSignup({
        primaryUserEmail: formValues.email,
        primaryUserFirstName: formValues.firstName,
        primaryUserLastName: formValues.lastName,
        adminPassword: formValues.password,
        partnerReferral: getPartnerReferral(),
        referrer: '',
        creditCategories: [] as CreditCategoryEnum[],
        sourcing: sourcingData,
      } as CreateCompanySelfSignupRequest);

      if (res.errorMsg) {
        datadogLogs.logger.error(
          `[WELCOME]: company creation on registration failed. partner: ${partner}`,
          logContext({
            error: res.errorMsg,
            company: app.company,
          }),
        );

        setFormError(res.errorMsg);
        setLoading(false);
        return;
      }

      if (
        partner === 'quickbooks' &&
        featureFlags.doesRedirectQuickbooksCalendly
      ) {
        window.location.href =
          'https://calendly.com/mainstreet-interviews/onboarding';
        return;
      } else {
        await auth.manualLogin(
          formValues.email,
          formValues.password,
          redirectUri,
        );
        if (res.data?.company) {
          company.mergeData(res.data.company);
        }
      }
    };

    const getPartnerReferral = () => {
      if (pathComponents.length > 2 && pathComponents[1] === 'welcome') {
        return pathComponents[2];
      }

      return '';
    };

    const submitOnEnter =
      (runSubmit: () => void) => (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          runSubmit();
        }
      };

    const partnerCompany = getPartnerReferral().replace(/\b\w/g, (char) =>
      char.toUpperCase(),
    );

    useEffect(() => {
      document.title = `MainStreet | ${partnerCompany} Sign up`;
    }, [partner, partnerCompany]);

    return (
      <div className={classes.root}>
        <>
          <div className={classes.leftContainer} style={{ opacity: 1 }}>
            <Flex
              direction='column'
              className={classes.leftInfoContainer}
              dataTestId='welcome-to-mainstreet'
            >
              <Heading
                tag='h4'
                size={app.isTablet ? 15 : 18}
                color={Color.neutral._90}
                variant='medium'
                marginBottom={24}
              >
                The leading small business tax credits solution
              </Heading>
              <Heading
                tag={app.isTablet ? 'h4' : 'h3'}
                color={Color.green._80}
                variant='medium'
                marginBottom={8}
              >
                $150 million saved
              </Heading>
              <Heading
                tag={app.isTablet ? 'h4' : 'h3'}
                color={Color.green._80}
                variant='medium'
                marginBottom={8}
              >
                2,000 small businesses served
              </Heading>
              <Heading
                tag={app.isTablet ? 'h4' : 'h3'}
                color={Color.green._80}
                variant='medium'
                marginBottom={32}
              >
                {ALL_PAYROLL_SYSTEMS.find((p) => p.id === partner)
                  ? `Tailored to ${
                      ALL_PAYROLL_SYSTEMS.find((p) => p.id === partner)
                        ?.displayName
                    } users like you.`
                  : 'Will you be next?'}
              </Heading>
              <Heading variant='medium' size={app.isTablet ? 15 : 18}>
                Join today!
              </Heading>
            </Flex>
          </div>
          <Flex
            direction='column'
            alignItems='center'
            justifyContent='center'
            className={classes.signupRoot}
            style={{ opacity: 1 }}
          >
            <div className={classes.titleMessaging}>
              <Heading
                className={classes.title}
                variant='medium'
                tag={'h3'}
                size={app.isTablet ? 32 : 36}
              >
                Join MainStreet
              </Heading>
            </div>
            <div
              style={{
                transition: 'opacity 1s',
                opacity: 1,
              }}
            >
              <Animate enter={'fade-in'}>
                <div className={classes.box} data-testid={'welcome-page'}>
                  {loading && (
                    <div className={classes.loadingContainer}>
                      <LoadingWidget />
                    </div>
                  )}
                  <div
                    className={classes.mainContent}
                    style={{ display: loading ? 'none' : 'block' }}
                  >
                    <Formik
                      initialValues={initialValues}
                      validateOnChange={false}
                      validateOnBlur={false}
                      onSubmit={(values) => {
                        submit(values);
                      }}
                      validationSchema={yup.object().shape({
                        firstName: yup
                          .string()
                          .required('Please include your first name'),
                        lastName: yup
                          .string()
                          .required('Please include your last name'),
                        email: yup
                          .string()
                          .email('Please enter a valid email')
                          .required('Please include your work email'),
                        password: yup
                          .string()
                          .required('Please enter a password'),
                        agreeToTerms: yup
                          .bool()
                          .oneOf(
                            [true],
                            'You must accept the terms of service',
                          ),
                      })}
                      // eslint-disable-next-line react/no-children-prop
                      children={({
                        values,
                        errors,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <div>
                          <div className={classes.textFieldContainer}>
                            <TextField
                              variant='outlined'
                              className={classes.textField}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                              }}
                              data-testid='welcome_first_name'
                              label='First Name'
                              value={values.firstName}
                              onChange={(event) => {
                                setFieldValue('firstName', event.target.value);
                              }}
                              onKeyDown={submitOnEnter(handleSubmit)}
                            />
                            {errors.firstName && (
                              <FormHelperText
                                className={classes.errorMsg}
                                error
                              >
                                {errors.firstName}
                              </FormHelperText>
                            )}
                          </div>
                          <div className={classes.textFieldContainer}>
                            <TextField
                              variant='outlined'
                              className={classes.textField}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                              }}
                              data-testid='welcome_last_name'
                              label='Last Name'
                              value={values.lastName}
                              onChange={(event) => {
                                setFieldValue('lastName', event.target.value);
                              }}
                              onKeyDown={submitOnEnter(handleSubmit)}
                            />
                            {errors.lastName && (
                              <FormHelperText
                                className={classes.errorMsg}
                                error
                              >
                                {errors.lastName}
                              </FormHelperText>
                            )}
                          </div>

                          <div className={classes.textFieldContainer}>
                            <TextField
                              variant='outlined'
                              className={classes.textField}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                              }}
                              data-testid='welcome_email'
                              label='Company Email'
                              value={values.email}
                              onChange={(event) => {
                                setFieldValue('email', event.target.value);
                              }}
                              onKeyDown={submitOnEnter(handleSubmit)}
                            />
                            {errors.email && (
                              <FormHelperText
                                className={classes.errorMsg}
                                error
                              >
                                {errors.email}
                              </FormHelperText>
                            )}
                          </div>

                          <div className={classes.textFieldContainer}>
                            <TextField
                              variant='outlined'
                              className={classes.textField}
                              type={showPassword ? 'text' : 'password'}
                              data-testid='welcome_password'
                              label='Password'
                              value={values.password}
                              error={!isValidPassword && showPasswordError}
                              onFocus={() => {
                                setShowPasswordHelpers(true);
                              }}
                              onBlur={(event) => {
                                validatePassword(event.target.value, true);
                                setShowPasswordError(true);
                                setShowPasswordHelpers(
                                  values.password.length > 0,
                                );
                              }}
                              onChange={(event) => {
                                setShowPasswordHelpers(true);
                                setShowPasswordError(false);
                                setFieldValue('password', event.target.value);
                                validatePassword(event.target.value);
                              }}
                              onKeyDown={submitOnEnter(handleSubmit)}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <Link
                                      onClick={() => {
                                        setShowPassword(!showPassword);
                                      }}
                                      inheritColor={true}
                                    >
                                      <FontAwesome
                                        name={
                                          showPassword ? 'eye-slash' : 'eye'
                                        }
                                        variant='solid'
                                        size={20}
                                        color={
                                          !isValidPassword && showPasswordError
                                            ? Color.semantic.$error50
                                            : Color.neutral._60
                                        }
                                      />
                                    </Link>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {showPasswordHelpers && (
                              <PasswordChecklist
                                passwordValidation={passwordValidation}
                              />
                            )}
                            {errors.password && (
                              <FormHelperText
                                className={classes.errorMsg}
                                error
                              >
                                {errors.password}
                              </FormHelperText>
                            )}
                          </div>

                          <Typography
                            className={classes.agreementContainer}
                            variant='body1'
                            component='div'
                          >
                            By creating an account, you&apos;re letting us know
                            that you agree to the terms outlined in{' '}
                            <a
                              className={classes.linkText}
                              href='/terms-of-service'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              Terms of Service
                            </a>{' '}
                            and have acknowledged our{' '}
                            <a
                              className={classes.linkText}
                              href='/privacy'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              Privacy Policy
                            </a>
                            .
                          </Typography>
                          {formError && (
                            <FormHelperText
                              className={classes.formErrorMsg}
                              error
                            >
                              {formError}
                            </FormHelperText>
                          )}
                          <div className={classes.buttonRow}>
                            <Button
                              className={classes.nextButton}
                              variant='contained'
                              dataTestId='welcome_submit'
                              onClick={() => {
                                handleSubmit();
                              }}
                              label='Create Estimate'
                              disabled={
                                !isValidPassword ||
                                !values.firstName ||
                                !values.lastName ||
                                !values.email
                              }
                            />
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </Animate>
            </div>
          </Flex>
        </>
        <div className={classes.logo}>
          <img
            src={`${process.env.PUBLIC_URL}/images/logo/partners/mst-${partner}-logo.svg`}
            alt={`MainStreet & ${partner} logo'`}
            onError={({ currentTarget }) => {
              currentTarget.src = `${process.env.PUBLIC_URL}/images/logo/mst-logo-green.svg`;
            }}
          />
        </div>
      </div>
    );
  },
);
