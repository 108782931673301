import React from 'react';
import { Button, Flex, Modal, Text } from 'component-library';
import { observer } from 'mobx-react';
import { Page } from 'lib/constants';
import { useCommonStores } from 'stores/useStores';

interface AcceptedOrderFormModalProps {
  showModal: boolean;
  onClose: () => void;
  multiplePendingOrders: boolean;
  programTaxYear: number;
}

export const AcceptedOrderFormModal: React.FC<AcceptedOrderFormModalProps> = observer(
  ({ showModal, onClose, multiplePendingOrders, programTaxYear }) => {
    const { app } = useCommonStores();
    return (
      <Modal showModal={showModal} closeToggle={onClose} maxWidth={536}>
        <Flex direction='column' gap={16} padding={24}>
          <Text variant='medium' size={18} text="You're all set!" />
          <Text text="We'll review all of your information and let you know if we need to update any of the values on your plan." />
          <Flex gap={16} justifyContent='flex-start'>
            {!multiplePendingOrders && (
              <Button
                label='Back to dashboard'
                onClick={() => {
                  onClose();
                  app.history.push(Page.dashboard);
                }}
              />
            )}
            {multiplePendingOrders && (
              <Button
                variant='outlined'
                label='Other order forms'
                onClick={() =>
                  app.history.push(`/${Page.orderForms}/${programTaxYear}`)
                }
              />
            )}
          </Flex>
        </Flex>
      </Modal>
    );
  },
);
