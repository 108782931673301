import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';

import {
  Alert,
  Button,
  Color,
  Content,
  Heading,
  IconEnum,
  Text,
  SurveyQuestion as Uploader,
  Card,
  CardHeader,
} from 'component-library';
import {
  BusinessApplicationData,
  BusinessApplicationDataDraft,
  // StateEnum,
  // CountryEnum,
  BusinessApplicationResponse,
  MSClientResponse,
  SupportingDocumentData,
} from 'lib/interfaces';
import { CompanyInfoApplication } from '.';
import LoadingWidget from 'components/util/LoadingWidget';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import {
  KycDocuments,
  Page,
  // IndustriesList,
} from 'lib/constants';
import { KycCompanyInfoSummary } from './KycCompanyInfoSummary';
import { CompanyContext } from '../../CompanyRequired';
import {
  getBusinessApplicationDataErrors,
  BusinessApplicationDataIsComplete,
  // IsProd,
} from './helpers';
import { BackButton } from './BackButton';
import { FormRedirects } from 'lib/helpers';
import {
  BankingInformation,
  PlaidIntegrationCard,
} from 'pages/dashboard/integrations/components/plaidIntegration';

const useStyles = makeStyles(() => ({
  root: {
    padding: '32px 80px',
    maxWidth: '896px',
  },
  noXYMargin: {
    marginTop: '0px',
    marginBottom: '0px',
  },
  accreditationAlert: {
    borderTop: 'none',
  },
  greyButton: {
    backgroundColor: Color.neutral._50,
  },
}));

// const mockedCompanyData = {
//   name: 'Green Tea Mochi',
//   establishedOn: new Date('2020-02-02'),
//   phoneNumber: '(123) 212-3212',
//   physicalAddress: {
//     streetLine1: '123 Main Street',
//     streetLine2: undefined,
//     city: 'San Francisco',
//     state: StateEnum.PENNSYLVANIA,
//     country: CountryEnum.UNITED_STATES,
//     postalCode: '82425',
//   },
//   tin: '123212312',
// };

export const AccreditationError =
  'The connected account does not meet the requirements; please connect another account that has at least $5 million in assets.';

export const KycCompanyApplication = () => {
  const classes = useStyles();
  const { company } = useContext(CompanyContext);
  const auth0Context = useContext(Auth0FeatureContext);
  const redirects = new FormRedirects(
    useHistory,
    Page.kycBeneficiaryDetails,
    Page.kycApplication,
  );

  const [companyInfo, setCompanyInfo] = useState<BusinessApplicationDataDraft>({
    name: company?.legalName,
    physicalAddress: {},
  });

  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [editMode, setEditMode] = useState(true);

  const [hasProofOfAccreditation, setHasProofOfAccreditation] = useState<
    boolean | undefined
  >(undefined);
  const [accreditationDocs, setAccreditationDocs] = useState<File[]>([]);
  const [connectedToPlaid, setConnectedToPlaid] = useState(false);
  const [bankingInfo, setBankingInfo] = useState<
    BankingInformation | undefined
  >(undefined);
  const [failedUploadFileNames, setFailedUploadFileNames] = useState<string[]>(
    [],
  );

  const accreditationConnected =
    hasProofOfAccreditation || !!accreditationDocs.length;

  useEffect(() => {
    if (connectedToPlaid) {
      auth0Context.client
        .GetAccreditationStatusViaKYC()
        .then(({ data, errorMsg }) => {
          if (!data || errorMsg) {
            setErrorMessage(
              'There was an issue with loading your accreditation status',
            );
            return;
          }
          const { isAccredited } = data;
          setHasProofOfAccreditation(isAccredited);
        });
    }
  }, [accreditationDocs, auth0Context.client, connectedToPlaid]);

  const getFormErrorsOnSubmission = (): boolean => {
    const missingCompanyInfo = getBusinessApplicationDataErrors({
      businessApplicationData: companyInfo,
      accreditationConnected,
    });

    if (missingCompanyInfo.length > 0) {
      const missingInformation = missingCompanyInfo.join(', ');
      setErrorMessage(
        `Please fill out missing company information: ${missingInformation}`,
      );
      return true;
    }
    setErrorMessage('');
    return false;
  };

  // navigates to the summary card which contains the submit button
  const onSave = () => {
    setErrorMessage('');

    if (
      !BusinessApplicationDataIsComplete(companyInfo, accreditationConnected)
    ) {
      getFormErrorsOnSubmission();
      setEditMode(true);
      return;
    }

    if (editMode) {
      setEditMode(false);
      return;
    }
  };

  const onSubmit = () => {
    setErrorMessage('');

    if (
      !BusinessApplicationDataIsComplete(companyInfo, accreditationConnected)
    ) {
      getFormErrorsOnSubmission();
      setEditMode(true);
      return;
    }

    const submitProofOfFundsDocuments = (
      documents: File[],
      businessApplicationResponse: BusinessApplicationResponse,
    ): boolean => {
      if (_.isEmpty(documents)) {
        return true;
      }
      const documentPromises: Promise<
        MSClientResponse<SupportingDocumentData>
      >[] = [];
      for (const doc of documents) {
        documentPromises.push(
          auth0Context.client.SubmitKycBusinessProofOfFunds(
            businessApplicationResponse.businessApplicationId ?? '',
            businessApplicationResponse.businessEntityId ?? '',
            doc,
          ),
        );
      }
      Promise.all(documentPromises).then((responses) => {
        const failedResponses = responses.filter((r) => r.errorMsg);
        if (!_.isEmpty(failedResponses)) {
          setFailedUploadFileNames(
            failedResponses
              .filter((f) => f?.data?.fileName)
              .map((f) => f.data!.fileName),
          );
          return false;
        }
      });
      return true;
    };

    const businessApplicationData: BusinessApplicationData = {
      ...companyInfo,
      tin: companyInfo.tin.split('-').join(''),
    };

    setContentLoading(true);
    auth0Context.client
      .SubmitKycBusinessData({
        companyId: company.id,
        businessApplicationData,
      })
      .then(({ errorMsg, data }) => {
        if (errorMsg || !data) {
          setErrorMessage('Something went wrong with saving your application.');
          setContentLoading(false);
          return;
        }
        if (
          !submitProofOfFundsDocuments(
            accreditationDocs,
            data.businessApplicationResponse,
          )
        ) {
          setErrorMessage(
            `Failed to upload proof of funds documents: ${failedUploadFileNames.join(
              ', ',
            )}`,
          );
          setContentLoading(false);
          return;
        }

        auth0Context.client
          .SubmitKycAccreditationApplication()
          .then(({ errorMsg, data }) => {
            setContentLoading(false);
            if (errorMsg || !data) {
              setErrorMessage(
                'Something went wrong with submitting your accreditation application.',
              );
              return;
            }

            redirects.Forward();
          });
      });
  };

  useEffect(() => {
    auth0Context.client.GetKycBusinessData().then(({ data, errorMsg }) => {
      setContentLoading(false);
      if (!data) {
        return;
      }

      // A non-404 error has occured
      if (errorMsg) {
        setErrorMessage('Something went wrong loading your previous answers.');
        return;
      }

      setCompanyInfo({
        ...data,
      }); // Will contain IDs that allow us to differentiate an update
    });

    // We need to call an endpoint to gather previously uploaded docs, https://linear.app/mainstreet/issue/TRE-68/get-previously-uploaded-docs
    // setAccreditationDocs([...docs])
  }, [auth0Context.client]);

  if (contentLoading) {
    return <LoadingWidget />;
  }

  return (
    <>
      <Content className={classes.root} paddingBottom={16}>
        <BackButton
          redirect={editMode ? redirects.Backward : () => setEditMode(true)}
        />
        {/* {!IsProd() && (
          <Content paddingLeftRight={0} paddingTopBottom={16}>
            <Button
              label='Mock Data'
              onClick={() => {
                setCompanyInfo(mockedCompanyData);
              }}
            />
          </Content>
        )} */}
        <Heading size={36} text='Company information' tag='h1' />
        <>
          {!editMode ? (
            <KycCompanyInfoSummary
              setEditMode={setEditMode}
              onSubmit={onSubmit}
              companyInfo={companyInfo as BusinessApplicationData}
              connectedToPlaid={connectedToPlaid}
              accredited={accreditationConnected}
              accreditationDocs={accreditationDocs}
              bankingInfo={bankingInfo}
            />
          ) : (
            <>
              <Text
                text="Let's confirm your company details. Please complete the information below."
                size={15}
              />

              <Card>
                <CardHeader title={company.legalName} />
                <Content paddingLeftRight={24}>
                  <CompanyInfoApplication
                    companyInfo={companyInfo}
                    setCompanyInfo={setCompanyInfo}
                  />
                  <Content paddingBottom={16} paddingLeftRight={0}>
                    <Text
                      size={15}
                      text='Accreditation status'
                      variant='medium'
                    />

                    <Text
                      size={15}
                      text='Your company must show that it qualifies as an accredited investor with at least $5 million in assets.'
                    />
                  </Content>
                  <>
                    <PlaidIntegrationCard
                      suggestAccountSwitch={
                        connectedToPlaid && hasProofOfAccreditation === false
                      }
                      setConnectedToPlaid={setConnectedToPlaid}
                      setConnectedBankInfo={setBankingInfo}
                      description={
                        connectedToPlaid && !hasProofOfAccreditation
                          ? 'Connect another bank account showing assets over $5 million'
                          : 'Connect your bank account showing assets over $5 million (preferred method)'
                      }
                      formattedAsQuestionCard={true}
                      confirmationDescription={
                        'Your connected bank account shows more than $5 million in assets.'
                      }
                      connectedToPlaid={connectedToPlaid}
                      setErrorMessage={setErrorMessage}
                    />
                    {connectedToPlaid && hasProofOfAccreditation === false && (
                      <Card className={classes.accreditationAlert} noMargin>
                        <Alert
                          icon={IconEnum.exclamation_triangle}
                          text={AccreditationError}
                          type={'warning'}
                          variant={'in_card'}
                        />
                      </Card>
                    )}
                  </>
                  <Content paddingTopBottom={16} paddingLeftRight={0}>
                    <Text
                      text='- OR -'
                      variant='medium'
                      color={Color.neutral._60}
                    />
                  </Content>
                  <Uploader
                    answerType='file_upload'
                    className={classes.noXYMargin}
                    onFileAdded={(file, allFiles) => {
                      setAccreditationDocs(
                        allFiles.map((fileHandle) => fileHandle.file),
                      );
                      file.setUploadProgress(1, 1, 1);
                    }}
                    onFileCancelled={(e) =>
                      setAccreditationDocs(
                        accreditationDocs.filter(
                          (doc) => doc.name !== e.file.name,
                        ),
                      )
                    }
                    onFileRemoved={(e) =>
                      setAccreditationDocs(
                        accreditationDocs.filter(
                          (doc) => doc.name !== e.file.name,
                        ),
                      )
                    }
                  >
                    <Text size={15} variant='medium'>
                      {KycDocuments.ACCREDITATION_DOCUMENTS.description}
                    </Text>
                    <Text size={15} color={Color.neutral._80}>
                      {KycDocuments.ACCREDITATION_DOCUMENTS.example}
                    </Text>
                  </Uploader>
                </Content>

                {errorMessage && (
                  <Content paddingBottom={24}>
                    <Text size={15} status='failed'>
                      {errorMessage}
                    </Text>
                  </Content>
                )}

                <Content flex>
                  <Button
                    className={
                      !accreditationConnected && !accreditationDocs.length
                        ? classes.greyButton
                        : undefined
                    }
                    label='Save'
                    onClick={() => {
                      onSave();
                    }}
                  />
                </Content>
              </Card>
            </>
          )}
        </>
      </Content>
    </>
  );
};
