import React from 'react';
import { observer } from 'mobx-react';
import { Flex, Heading, Link, Text, TextProps } from 'component-library';
import { useCommonStores } from 'stores/useStores';
import { ContentFrame } from '../../components';

interface QualifyForTaxCreditsProps {
  headingSize: number;
  textFontSize: TextProps['size'];
}

export const QualifyForTaxCredits: React.FC<QualifyForTaxCreditsProps> = observer(
  ({ headingSize, textFontSize }) => {
    const { app } = useCommonStores();

    return (
      <ContentFrame>
        <Flex direction='column' gap={16}>
          <Heading
            text='Qualify for Tax Credits'
            variant='medium'
            size={headingSize}
            marginBottom={0}
          />
          <Text
            size={textFontSize}
            text="To qualify for tax credits with MainStreet, you'll need to answer questions about your business's R&D activities and related expenses. Your answers will help us determine if you qualify for tax credits and the type of tax credits."
          />
          <Text
            size={textFontSize}
            text="If you qualify, we'll generate an initial estimate of your R&D tax credit."
          />
          <Text size={textFontSize}>
            <Link external onClick={() => app.history.push('/')}>
              Link to R&D Assessment resource
            </Link>
          </Text>
        </Flex>
        <Flex direction='column' gap={16}>
          <img
            src={`${process.env.PUBLIC_URL}/images/dashboard/educational-module/images/Qualify.svg`}
            title='Qualify'
          />
        </Flex>
      </ContentFrame>
    );
  },
);
