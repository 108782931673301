import React from 'react';
import { Spinner, Color } from 'component-library';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Color.neutral.white,
  },
}));

export const LoadingPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.container} data-testid='loading-page'>
      <Spinner />
    </div>
  );
};
