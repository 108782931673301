import React from 'react';
import { Color, Text } from 'component-library';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((colors: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  sectionHeader: {
    borderBottom: '1px solid',
    borderBottomColor: colors.lighterGrey,
  },
  subSection: {
    border: '1px solid',
    borderColor: Color.neutral._20,
    backgroundColor: Color.neutral._light_20,
    padding: 16,
    marginBottom: 8,
  },
  list: {
    margin: 0,
    paddingLeft: 24,
    paddingBottom: 8,
  },
}));

export const ExpenseClassificationStepFAQ = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Text
        variant={'medium'}
        className={classes.sectionHeader}
        paddingBottom={8}
      >
        What&apos;s this step?
      </Text>
      <Text paddingBottom={8}>
        At the end of the tax year, complete your R&D assessment to give us
        specifics about your R&D activities and expenses from the last 12
        months. This allows us to refine our estimate and accurately calculate
        your final credit amount.
      </Text>
      <Text paddingBottom={8}>
        We&apos;ll use this information to create the documents needed to file
        with the IRS.
      </Text>

      <Text className={classes.subSection}>
        It&apos;s essential that you complete this step ASAP in order to claim
        your credit.
      </Text>

      <Text
        variant={'medium'}
        className={classes.sectionHeader}
        paddingBottom={8}
      >
        Timeline
      </Text>
      <ul className={classes.list}>
        <li>
          <Text paddingBottom={16}>
            This process should take you around 1 hour to complete.
          </Text>
        </li>
        <li>
          <Text>
            After you finish this assessment, we&apos;ll review all the
            information and generate the necessary documents you&apos;ll need to
            file with the IRS (2–4 weeks).
          </Text>
        </li>
      </ul>
    </div>
  );
};
