import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Link,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Page, ProgramStageEnum, ProgramSubStageEnum } from 'lib/constants';
import AdapterLink from 'components/util/AdapterLink';
import LoadingWidget from 'components/util/LoadingWidget';
import { handleErrorOrDataCallback } from 'lib/helpers';
import { ProgramData } from 'lib/interfaces';
import {
  DocumentAccessLink,
  ProgressiveStepper,
  StepOrElement,
} from 'pages/dashboard/fed-rd-program';
import React, { useEffect, useState } from 'react';
import { GetBenefitStartMonth, UpdateProgram } from 'services/server';
import { ClientReviewHeader } from './ClientReviewHeader';

const useStyles = makeStyles(({ green, palette }: Theme) => ({
  moneyText: {
    color: green,
    fontWeight: 800,
  },
  title: {
    fontSize: '28px',
  },
  error: {
    color: 'red',
    marginTop: 16,
  },
  button: {
    textTransform: 'initial',
    fontWeight: 500,
    height: '50px',
    width: '110px',
    fontSize: '18px',
  },
  link: {
    color: palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:visited': {
      color: palette.primary.main,
    },
  },
  documentsText: {
    fontSize: '14px',
  },
}));

interface ClientReviewProps {
  program: ProgramData;
}

export const ClientReview = ({ program }: ClientReviewProps) => {
  const classes = useStyles();
  const [startMonth, setStartMonth] = useState<number>(0);
  const [error, setError] = useState('');
  const [showDocuments, setShowDocuments] = useState(false);
  const [hideNextButton, setHideNextButton] = useState(false);
  const [loadingStartMonth, setLoadingStartMonth] = useState(true);
  useEffect(() => {
    if (program) {
      GetBenefitStartMonth(program.id).then((res) => {
        setLoadingStartMonth(false);
        handleErrorOrDataCallback(
          res,
          (data) => setStartMonth(data.benefitStartMonth),
          setError,
        );
      });
    }
  }, [program]);

  const handleReviewed = async () => {
    const res = await UpdateProgram(program.id, {
      stage: ProgramStageEnum.FINISHED,
      subStage: ProgramSubStageEnum.WAITING_FOR_FILING_CONF,
    });

    if (res.errorMsg) {
      setError(res.errorMsg);
      return;
    }

    // Force refresh to reload company+program.
    window.location.href = '/';
  };

  const steps = (
    program: ProgramData,
    benefitStartMonthNumber: number,
    showDocuments: boolean,
  ) => {
    const documentStep: StepOrElement = {
      element: (
        <div className={classes.documentsText}>
          You can access all these as well as supporting documentation at any
          time in the{' '}
          <Link
            className={classes.link}
            component={AdapterLink}
            color='inherit'
            to={`/${Page.companyDocs}`}
          >
            Documents
          </Link>{' '}
          section of your MainStreet dashboard.
        </div>
      ),
    };

    const steps: StepOrElement[] = [
      {
        step: {
          icon: '📚',
          title: 'Keep our report for your records',
          description: `We prepared a document that details all qualifying R&D expenses for tax year 2020. You can access it at any time in your Documents page. We've also prepared a document that outlines what material you should keep on hand in the unlikely case of an audit (you're likely already generating the right things during your normal course of work).`,
        },
      },
      {
        step: {
          icon: '📬',
          title: 'Send our documents to your tax preparer',
          description:
            'We prepared IRS Form 6765 and included instructions for your tax preparer on how to properly file it. Send them the link below to get started. They can reach out to us directly if they have any questions.',
        },
      },
    ];

    if (showDocuments) {
      steps.push(documentStep);
    }
    return steps;
  };

  if (loadingStartMonth) {
    return <LoadingWidget />;
  }

  if (error || !startMonth) {
    const genericError =
      'There was an issue loading the information related to your account. Please reach out to support for assistance.';

    return (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <FormHelperText error className={classes.error}>
            {error || genericError}
          </FormHelperText>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box marginTop={3} marginBottom={3}>
      <Grid container spacing={6}>
        <Grid container item xs={12} alignItems='center'>
          <Grid item>
            <img
              alt='Government Building'
              height='45px'
              src='/images/building.svg'
            />
          </Grid>
          <Grid item xs={6} sm={10}>
            <Box marginLeft={2}>
              <ClientReviewHeader currentFedRDProgram={program} />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ProgressiveStepper
            title='Next steps'
            steps={steps(program, startMonth, showDocuments)}
            completeButtonText='Next: Send documents to my tax preparer'
            hideButtonRow={hideNextButton}
            onCompleteHandler={() => {
              setHideNextButton(true);
              setShowDocuments(true);
            }}
          />
        </Grid>

        {showDocuments ? (
          <>
            <Grid item xs={12}>
              <DocumentAccessLink program={program} />
            </Grid>
            <Grid item xs={12}>
              <Button
                color='secondary'
                variant='contained'
                className={classes.button}
                onClick={handleReviewed}
              >
                Done!
              </Button>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Box>
  );
};
