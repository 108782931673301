import React from 'react';
import { Step, StepProps } from '../../TaxCreditProgramSteps';
import { Flex, Button, StepCardStep, Text, Color } from 'component-library';
import {
  Page,
  ProgramActionStageToText,
  ProgramStageEnum,
  ProgramSubStageEnum,
} from '../../../../../lib/constants';
import {
  useCompany,
  useFeatureFlags,
  useLegacyClients,
  useTaxCreditsStores,
} from 'stores/useStores';
import { ExpenseClassificationStepFAQ } from '../../sideDrawer/ExpenseClassificationStepFAQ';
import { ExpenseClassificationSubStep } from './ExpenseClassificationSubStep';
import { makeStyles } from '@material-ui/core';
import { useEffectOnce } from 'lib/helpers';
import { useSkipPayrollStep } from 'lib/useSkipPayrollStep';

const useStyles = makeStyles(() => ({
  ecStepsContainer: {
    border: '1px solid',
    borderColor: Color.neutral._20,
    borderRadius: '8px',
  },
  readyToSubmitButton: {
    marginTop: '8px',
  },
}));

export const stepConfig: Step = {
  name: 'expense_classification',
  number: 1,
  title: 'Complete your year-end assessment',
  dataTestId: 'expense_classification',
  faq: <ExpenseClassificationStepFAQ />,
};

export const ExpenseClassificationStep = ({ federalRDProgram }: StepProps) => {
  const classes = useStyles();
  const { taxCreditsPage } = useTaxCreditsStores();
  const { client } = useLegacyClients();
  const { company } = useCompany();
  const featureFlags = useFeatureFlags();
  const { id, stage, subStage, taxYear } = federalRDProgram;
  const { importPayroll, shouldSkipPayroll } = useSkipPayrollStep(id);

  const actionText = federalRDProgram.actionStage
    ? ProgramActionStageToText[federalRDProgram.actionStage]
    : '';

  const buttonText =
    subStage === ProgramSubStageEnum.EXPENSE_CLASSIFICATION_OVERVIEW
      ? 'Get started'
      : 'Finish R&D Assessment';

  const payroll =
    subStage === ProgramSubStageEnum.EXPENSE_CLASSIFICATION_OVERVIEW;
  const suppliesAndServices =
    subStage === ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EXPENSES;
  const employees =
    subStage === ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EMPLOYEES;
  const companyDetails =
    subStage === ProgramSubStageEnum.EXPENSE_CLASSIFICATION_COMPANY_DETAILS;
  const readyToSubmit =
    subStage === ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT;

  useEffectOnce(async () => payroll && (await importPayroll()));

  const subheaderText = () => {
    if (
      [
        ProgramStageEnum.QUALIFYING,
        ProgramStageEnum.EXPENSE_CLASSIFICATION,
        ProgramStageEnum.DISQUALIFIED,
        // TODO: add CANCELED stage here after GOV-2884 merges
      ].includes(stage)
    ) {
      return `Provide additional information about your ${taxYear} R&D expenses so we can calculate your final R&D credit. You may need ${company.name}'s 5 most recent tax returns.`;
    }

    return `Review and confirm your data so we can finalize your ${taxYear} R&D credit. You may need your ${
      taxYear - 1
    } tax return.`;
  };

  return (
    <StepCardStep
      active={stepConfig.name === stage}
      dataTestId={stepConfig.dataTestId}
      learnMoreOnClick={() =>
        taxCreditsPage.setShowSideDrawer(true, stepConfig.name)
      }
      stepNumber={stepConfig.number}
      title={stepConfig.title}
    >
      <>
        <Text
          tag='p'
          variant='regular'
          paddingBottom={8}
          text={subheaderText()}
        />
        <Flex
          direction='column'
          padding={[16, 24]}
          className={classes.ecStepsContainer}
        >
          <ExpenseClassificationSubStep
            title={'Submit payroll data'}
            text={'This data will save you 20+ minutes on your next steps'}
            link={`/${Page.taxCredits}/expense-classification/${id}/overview`}
            status={payroll && !shouldSkipPayroll ? 'active' : 'completed'}
            dataTestId={'overview'}
            actionText={actionText}
          />
          <ExpenseClassificationSubStep
            title={'Identify major R&D expenses'}
            text={
              'Provide cloud computing and research supply receipts to claim toward your R&D credit'
            }
            link={`/${Page.taxCredits}/expense-classification/${id}/supplies-services`}
            status={
              suppliesAndServices || shouldSkipPayroll
                ? 'active'
                : payroll
                ? 'upcoming'
                : 'completed'
            }
            dataTestId={'suppliesAndServices'}
            actionText={actionText}
          />
          <ExpenseClassificationSubStep
            title={'Define employee R&D contributions'}
            text={
              'Confirm your employees’ wages and identify their R&D efforts'
            }
            link={`/${Page.taxCredits}/expense-classification/${id}/employees`}
            status={
              employees
                ? 'active'
                : payroll || suppliesAndServices
                ? 'upcoming'
                : 'completed'
            }
            dataTestId={'employees'}
            actionText={actionText}
            callout
          />
          <ExpenseClassificationSubStep
            title={'Confirm general company details'}
            text={
              'Verify details about your company’s business structure, history, and unique circumstances'
            }
            link={`/${Page.taxCredits}/expense-classification/${id}/company-details`}
            status={
              companyDetails
                ? 'active'
                : payroll || employees || suppliesAndServices
                ? 'upcoming'
                : 'completed'
            }
            dataTestId={'companyDetails'}
            actionText={actionText}
          />
        </Flex>
        {readyToSubmit && (
          <Button
            small
            flexAlignSelf='flex-start'
            className={classes.readyToSubmitButton}
            label='Submit for review'
            dataTestId='continue-button'
            onClick={async () => {
              const res = await client.UpdateProgramStage({
                programId: id,
                programStage: ProgramStageEnum.MS_REVIEW,
              });

              if (!res.errorMsg) {
                await client.UpdateProgramSubStage({
                  programId: id,
                  programSubStage: ProgramSubStageEnum.REVIEW_IN_PROGRESS,
                });
                window.location.href = `/${Page.taxCredits}`;
              }
            }}
          />
        )}
      </>
    </StepCardStep>
  );
};
