import { makeStyles } from '@material-ui/core';
import { Color, Content } from 'component-library';
import TitleHeader from 'components/TitleHeader';
import React from 'react';

interface OnboardingContainerProps {
  children: React.ReactElement | JSX.Element;
}

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    height: '100vh',
    width: '100%',
    paddingBottom: '80px',
    boxSizing: 'border-box',
  },
  container: {
    padding: '0 24px',
    maxWidth: '1200px',
    width: '100%',
    height: 'calc(100% - 96px)',
    boxSizing: 'border-box',
    [breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));

export const OnboardingContainer = ({ children }: OnboardingContainerProps) => {
  const classes = useStyles();
  return (
    <Content
      flex
      flexDirection={'column'}
      alignItems={'center'}
      bgColor={Color.neutral.white}
      className={classes.root}
      gap={0}
    >
      <TitleHeader title='Getting started' />
      <Content className={classes.container}>{children}</Content>
    </Content>
  );
};
