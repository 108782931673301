import React from 'react';
import { Alert, Button, Card, Color } from 'component-library';
import { animated, TransitionFn } from 'react-spring';
import { makeStyles } from '@material-ui/core';
import { AccountBalanceOverview } from './AccountBalanceOverview';
import { WireInstructions } from './Transfers/WireInstructions';
import { ExpectedPaymentDirection } from 'lib/interfaces';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classnames = require('classnames/bind');

const useStyles = makeStyles(() => ({
  cardContainer: {
    border: `1px solid ${Color.neutral._20}`,
    margin: '0 0 48px 0',
  },
  transferContainer: {
    padding: '16px 24px 16px',
    backgroundColor: Color.neutral._light_20,
    borderTop: `1px solid ${Color.neutral._20}`,
  },
  noTransactionsCard: {
    margin: '20px 0px',
  },
  noTransactionsTitle: {
    color: Color.neutral._90,
  },
  horizontalLine: {
    width: '100%',
    height: '1px',
    backgroundColor: Color.neutral._20,
    margin: '25px 0',
  },
  transferLowerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  transferButtons: {
    display: 'flex',

    '& button:last-child': {
      marginLeft: '16px',
    },
  },
  alertContainer: {
    position: 'absolute',
    bottom: 16,
    right: 100,
  },
}));

export interface BankInfo {
  id: string;
  name: string;
  accountType: string;
  lastFourDigits: string;
}

export interface BalanceInformation {
  principalBalanceCents: number;
  accruedInterestCents: number;
  annualPercentYield: string;
  remainingPendingBalanceCents?: number;
  minimumAllowedBalance?: number;
}

interface AccountBalanceCardProps {
  alertTransitions: TransitionFn<
    boolean,
    {
      opacity: number;
      y: number;
    }
  >;
  balanceInfo?: BalanceInformation;
  companyId: number;
  setShowPaymentDeletedAlert: React.Dispatch<React.SetStateAction<boolean>>;
  openSidedrawer: (direction: ExpectedPaymentDirection) => void;
}

export const AccountBalanceCard = ({
  alertTransitions,
  balanceInfo,
  companyId,
  setShowPaymentDeletedAlert,
  openSidedrawer,
}: AccountBalanceCardProps) => {
  const classes = useStyles();
  const cardClassnames = classnames(classes.cardContainer);
  const zeroBalance = balanceInfo && balanceInfo.principalBalanceCents === 0;

  return (
    <Card className={cardClassnames}>
      <AccountBalanceOverview balanceInfo={balanceInfo} />
      {balanceInfo && (
        <div className={classes.transferContainer}>
          {zeroBalance && <WireInstructions companyId={companyId} />}
          <div className={classes.alertContainer}>
            {alertTransitions((props, item) => {
              return (
                item && (
                  <animated.div
                    style={{
                      ...props,
                    }}
                  >
                    <Alert
                      text='Your transfer was successfully canceled'
                      type='success'
                      actions={{
                        text: 'Dismiss',
                        onClick: () => setShowPaymentDeletedAlert(false),
                      }}
                    />
                  </animated.div>
                )
              );
            })}
          </div>

          {!zeroBalance && (
            <div className={classes.transferButtons}>
              <Button
                label='Transfer In'
                variant='outlined'
                small={true}
                onClick={() => openSidedrawer(ExpectedPaymentDirection.CREDIT)}
              />
              <Button
                label='Transfer Out'
                variant='tertiary'
                small={true}
                onClick={() => openSidedrawer(ExpectedPaymentDirection.DEBIT)}
              />
            </div>
          )}
        </div>
      )}
    </Card>
  );
};
