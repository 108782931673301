import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Flex } from 'component-library';
import {
  FooterSection,
  HeroSection,
  HowItWorksSection,
  IRSGuidelinesSection,
  PartnershipSection,
  StartSavingSection,
  CreditsSection,
  ComingSoon,
} from './sections';
import { PartnerInfo } from 'lib/interfaces';
import { SignUpModal } from './SignUpModal';
import { AlertSection } from './sections/AlertSection';
import DeadlinesSection from './sections/DeadlinesSection';
import { useFeatureFlags } from '../../../../../stores/useStores';

interface PartnerLandingPageProps {
  partner: string;
  referralInfo: PartnerInfo;
}

export const PartnerLandingPage: React.FC<PartnerLandingPageProps> = observer(
  ({ partner, referralInfo }) => {
    const featureFlags = useFeatureFlags();
    useEffect(() => {
      document.title = referralInfo.pageTitle
        ? referralInfo.pageTitle
        : `MainStreet | ${referralInfo.partnerName} Tax Credits`;
    }, [referralInfo.partnerName, referralInfo.pageTitle]);

    return (
      <Flex direction='column'>
        {featureFlags.getShowUrgencyBanner && <AlertSection />}
        <HeroSection partner={partner} referralInfo={referralInfo} />
        <CreditsSection />
        <PartnershipSection referralInfo={referralInfo} />
        {featureFlags.getShowTaxDeadlines && <DeadlinesSection />}
        <HowItWorksSection referralInfo={referralInfo} />
        <IRSGuidelinesSection />
        <ComingSoon />
        <StartSavingSection />
        <FooterSection />
        <SignUpModal />
      </Flex>
    );
  },
);
