import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { EmployeeHeader } from 'pages/dashboard/fed-rd-program';
import { makeStyles } from '@material-ui/core/styles';
import { NonRdEmployee } from 'pages/dashboard/fed-rd-program';
import { SectionCard } from 'components/SectionCard';

const useStyles = makeStyles(() => ({
  textBox: {
    padding: '20px 30px',
  },
  text: {
    fontSize: '12px',
    lineHeight: '16px',
  },
}));

export interface NonRdEmployeeExpenseCardProps {
  nonRdEmployee: NonRdEmployee;
}

export const NonRdEmployeeExpenseCard = ({
  nonRdEmployee,
}: NonRdEmployeeExpenseCardProps) => {
  const classes = useStyles();

  return (
    <>
      <Box height={'20px'} />
      <SectionCard
        style={{ width: '100%', marginBottom: '20px', marginTop: 0 }}
      >
        <EmployeeHeader
          name={nonRdEmployee.name()}
          jobTitle={nonRdEmployee.jobTitle()}
          jobType={nonRdEmployee.jobType()}
          jobCategory={nonRdEmployee.jobGroup()}
          percent={0}
          showRightSide={true}
        />
        <Divider />
        <Box className={classes.textBox}>
          <Typography className={classes.text}>
            This role is not eligible for the R&D credit.
          </Typography>
        </Box>
      </SectionCard>
    </>
  );
};
