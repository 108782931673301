import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { Collapse, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Color } from 'component-library';

import { Card, Text, Divider } from 'component-library';
import { CmsFaqData } from 'lib/interfaces';

const useStyles = makeStyles(() => ({
  container: {
    border: `1px solid ${Color.neutral._20}`,

    '& > div:not(:last-child)': {
      borderBottom: `1px solid ${Color.neutral._20}`,
    },
  },
  faqItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '32px',
  },
  faqQuestion: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  faqDivider: {
    marginBottom: '32px',
  },
  faqAnswer: {
    fontSize: '16px',
    lineHeight: '22px',

    '& > div': {
      padding: '16px 32px 32px',
    },

    '& a': {
      color: Color.blue._60,
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      },
    },

    '& strong': {
      fontWeight: 500,
    },
  },
  arrow: {
    marginLeft: '20px',
    cursor: 'pointer',
    height: '24px',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      fill: Color.neutral._90,
      fontSize: '32px',
    },
  },
}));

interface Props {
  faqs: CmsFaqData[];
  footer: React.ReactNode | JSX.Element;
}

export const FaqListCard = ({ faqs, footer }: Props) => {
  const classes = useStyles();

  const [faqBank, setFaqBank] = useState<CmsFaqData[]>(faqs);

  useEffect(() => {
    faqs.forEach((faq) => {
      faq.active = false;
    });
  }, [faqs]);

  const handleArrowClick = (index: number) => {
    const faqClicked = faqBank[index];
    if (faqClicked) {
      faqClicked.active = !faqClicked.active;
      setFaqBank([...faqBank]);
    }
  };

  const renderFaq = (faq: CmsFaqData, index: number) => {
    return (
      <div key={`faq-${index}`}>
        <div className={classes.faqItem}>
          <Text className={classes.faqQuestion} size={15}>
            {faq.question}
          </Text>
          <div
            className={classes.arrow}
            onClick={() => handleArrowClick(index)}
          >
            {faq.active ? <ExpandLess /> : <ExpandMore />}
          </div>
        </div>
        <Collapse
          className={classes.faqAnswer}
          in={faq.active}
          timeout='auto'
          unmountOnExit
        >
          <Divider className={classes.faqDivider} variant='full-width' />
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(faq.answer!.html!),
            }}
          />
        </Collapse>
      </div>
    );
  };

  return (
    <Card className={classes.container}>
      {faqBank.map(renderFaq)}{' '}
      {footer && <div className={classes.faqItem}>{footer}</div>}
    </Card>
  );
};
