import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { ReferSettings } from 'components/ReferSettings';
import { observer } from 'mobx-react';
import { useCompany, useFeatureFlags, useHistoryStore } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  contentContainer: {
    width: '100%',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 80px',
  },
}));

const Refer = observer(() => {
  const classes = useStyles();
  const { company } = useCompany();
  const flags = useFeatureFlags();
  const history = useHistoryStore();

  useEffect(() => {
    if (!flags.showReferAndEarn) {
      history.push('/');
    }
  }, [flags.showReferAndEarn, history]);

  return (
    <div className={classes.root} data-testid={'refer-page'}>
      <div className={classes.contentContainer}>
        <div className={classes.mainContent}>
          <ReferSettings
            email={company.adminEmail}
            firstName={company.adminName.first}
            lastName={company.adminName.last}
          />
        </div>
      </div>
    </div>
  );
});

export default Refer;
