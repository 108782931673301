import React, { useState } from 'react';
import {
  Alert,
  Button,
  Content,
  SideDrawer,
  TextField,
  Text,
  Flex,
  Dropdown,
  Color,
  Link,
} from 'component-library';
import { FileTypes, RdVendorExpenseType } from 'lib/constants';
import { RdVendorExpense, RdVendorExpenseReceipt } from 'lib/interfaces';
import { FileSelector } from 'components/util/upload/FileSelector';
import File from 'components/icons/File';
import RdConfirmDeleteModal from './RdConfirmDeleteModal';
import { makeStyles } from '@material-ui/core/styles';
import Trash from '../../../../../components/icons/Trash';

const useStyles = makeStyles(() => ({
  fileRow: {
    borderBottom: '1px dashed rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 20px',
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fileTitle: {
    whiteSpace: 'nowrap',
    width: '80%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    opacity: '.8',
  },
  fileIcon: {
    width: '16px',
    marginRight: '20px',
  },
  removeButton: {
    marginLeft: 'auto',
    padding: 0,
  },
  saveButton: {
    marginLeft: 16,
  },
  blueInfo: {
    borderLeft: `solid ${Color.blue._80} !important`,
  },
}));

interface RdSideDrawerVendorProps {
  setIsVendorDrawerOpen: (value: boolean) => void;
  isVendorDrawerOpen: boolean;
  onSubmit: (vendor: RdVendorExpense, files: File[]) => void;
  onDeleteExpense: (vendor: RdVendorExpense) => void;
  selectedVendor: RdVendorExpense;
  setSelectedVendor: React.Dispatch<React.SetStateAction<RdVendorExpense>>;
  amountLabel: string;
  attachedReceipts: RdVendorExpenseReceipt[];
  setAttachedReceipts: React.Dispatch<
    React.SetStateAction<RdVendorExpenseReceipt[]>
  >;
  setIsSaving: (value: boolean) => void;
  isEditingRow: boolean;
  setIsEditingRow: (value: boolean) => void;
  files: File[];
  setFiles: (file: File[]) => void;
  setAttachmentsToDelete: React.Dispatch<React.SetStateAction<number[]>>;
  attachmentsToDelete: number[];
  expenseType: RdVendorExpenseType;
  alertError?: string;
  isSaving?: boolean;
}

const RdSideDrawerVendor = ({
  setIsVendorDrawerOpen,
  isVendorDrawerOpen,
  onSubmit,
  onDeleteExpense,
  selectedVendor,
  setSelectedVendor,
  amountLabel,
  attachedReceipts,
  setAttachedReceipts,
  alertError,
  isSaving,
  setIsSaving,
  isEditingRow,
  setIsEditingRow,
  files,
  setFiles,
  attachmentsToDelete,
  setAttachmentsToDelete,
  expenseType,
}: RdSideDrawerVendorProps) => {
  const classes = useStyles(true);
  const vendors = [
    'Amazon Web Services (AWS)',
    'Google Cloud Platform (GCP)',
    'Microsoft Azure',
    'Other',
  ];
  const otherVendorName = 'Other';
  const otherVendorPresent =
    selectedVendor.name === '' ? false : !vendors.includes(selectedVendor.name);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [otherVendorSelected, setOtherVendorSelected] =
    useState<boolean>(false);
  const cloudExpensesSubtitle = `Most companies upload line items from their bookkeeping software. Others provide screenshots from the vendor, or individual monthly receipts. If you used many vendors, please choose "Other" and write in 'miscellaneous'.`;
  const suppliesExpensesSubtitle = `Most companies highlight line items from their bookkeeping software. Others provide individual receipts. If you used many vendors, you may write 'miscellaneous' as the Vendor Name.`;
  const subTitle =
    expenseType !== RdVendorExpenseType.CLOUD
      ? suppliesExpensesSubtitle
      : cloudExpensesSubtitle;

  const isSaveEnabled = () => {
    // Check for non-truthy name or amount, which disables save in all cases
    if (!selectedVendor.name || !selectedVendor.amountCents) {
      return false;
    }
    // New files being present enables save
    else if (files.length > 0) {
      return true;
    }
    // If "other" vendor is selected without receipts, disable save
    else if (otherVendorSelected && selectedVendor.receipts.length <= 0) {
      return false;
    }
    // In editing mode, require attachments to be present or marked for deletion to enable save
    else if (isEditingRow) {
      return attachedReceipts.length > 0 || attachmentsToDelete.length > 0;
    }

    return true;
  };

  const supplyNameInput = (
    <TextField
      label='Vendor Name'
      value={selectedVendor.name}
      onChange={(event) => {
        const vendorName = event.target.value;
        if (selectedVendor.name !== vendorName) {
          setIsEditingRow(false);
        }
        setSelectedVendor({
          ...selectedVendor,
          name: vendorName,
        });
      }}
    />
  );

  const cloudNameInput = (
    <Dropdown
      dataTestId={'rd-vendor-expense-side-drawer-vendor-name'}
      label={'Vendor Name'}
      placeholder={'Select'}
      value={otherVendorPresent ? otherVendorName : selectedVendor.name}
      options={vendors}
      onInputChange={(vendor) => {
        const vendorName = vendor.toString();
        if (selectedVendor.name !== vendorName) {
          setIsEditingRow(false);
        }

        if (vendorName === otherVendorName) {
          setOtherVendorSelected(true);
        } else {
          setOtherVendorSelected(false);
          setSelectedVendor({
            ...selectedVendor,
            name: vendorName,
          });
        }
      }}
    />
  );

  const otherVendorInput = (
    <>
      <TextField
        dataTestId={'rd-vendor-expense-side-drawer-other-vendor-name'}
        label='Other Vendor'
        placeholder={'Enter other vendor'}
        value={otherVendorPresent ? selectedVendor.name : ''}
        onChange={(event) => {
          const vendorName = event.target.value;
          if (selectedVendor.name !== vendorName) {
            setIsEditingRow(false);
          }
          setSelectedVendor({
            ...selectedVendor,
            name: vendorName,
          });
        }}
      />
      <Alert
        variant={'in_card'}
        type={'info'}
        backgroundColor={Color.blue._10}
        inCardBorder={'left'}
        className={classes.blueInfo}
        text={
          <Flex direction='column'>
            <Text size={15}>
              Please ensure vendor is eligible as cloud computing.
            </Text>
            <Text size={11} color={Color.blue._60} variant={'medium'}>
              <Link
                href='https://mainstreet1.my.site.com/help/s/article/Cloud-Computing-Expenses'
                target='_blank'
                inheritColor
              >
                More details about cloud computing
              </Link>
            </Text>
          </Flex>
        }
      />
    </>
  );

  const vendorAmountInput = (
    <TextField
      dataTestId={'rd-vendor-expense-side-drawer-amount-spend'}
      currencyFormat={true}
      label={amountLabel}
      value={selectedVendor.amountCents / 100}
      onChange={(event) => {
        const value = event.target.value.replace(/,/g, '');
        const amountCents = Number(value) * 100;
        if (selectedVendor.amountCents !== amountCents) {
          setIsEditingRow(false);
        }
        setSelectedVendor({
          ...selectedVendor,
          amountCents,
        });
      }}
    />
  );

  const removeAttachedReceipt = (index: number) => {
    setAttachmentsToDelete((docIds) => {
      return [attachedReceipts[index].documentId, ...docIds];
    });
    setAttachedReceipts((prevState) => {
      // note: don't mutate prevState. It's pass by reference changing it will be a side effect.
      //   The order of setX functions are guaranteed, but not the order in which passed in functions
      //   are evaluated. It could well be that all the functions are evaluated in an undefined
      //   order but the return value is passed to the setX function according to react documentation.
      const temp = [...prevState];
      temp.splice(index, 1);
      return [...temp];
    });
  };

  const vendorAttachedReceipts = attachedReceipts.length > 0 && (
    <Content paddingLeftRight={0} paddingTopBottom={0}>
      {attachedReceipts.map((receipt, index) => {
        const dataTestId = `delete-${receipt.fileName}-${index}`;
        return (
          <div className={classes.fileRow} key={`${receipt.fileName}_${index}`}>
            <File className={classes.fileIcon} />
            <Text className={classes.fileTitle} size={15}>
              {receipt.fileName}
            </Text>
            <Button
              onClick={() => removeAttachedReceipt(index)}
              dataTestId={dataTestId}
              className={classes.removeButton}
              variant='tertiary'
              label={<Trash />}
            />
          </div>
        );
      })}
    </Content>
  );

  const vendorUploadButton: JSX.Element = (
    <FileSelector
      acceptedFiletypes={[
        FileTypes.CSV,
        FileTypes.PDF,
        FileTypes.XLS,
        FileTypes.XLSX,
        FileTypes.GIF,
        FileTypes.JPG,
        FileTypes.PNG,
      ]}
      setFiles={setFiles}
      files={files}
      noPadding
      uniqueKey='vendor-expenses'
    />
  );

  return (
    <>
      <SideDrawer
        dataTestId='vendor'
        drawerContent={
          <>
            <Content gap={32} flexDirection='column' flex>
              {expenseType === RdVendorExpenseType.CLOUD
                ? cloudNameInput
                : supplyNameInput}
              {expenseType === RdVendorExpenseType.CLOUD &&
                (otherVendorPresent || otherVendorSelected) &&
                otherVendorInput}
              {vendorAmountInput}
              {vendorAttachedReceipts}
              {otherVendorPresent && vendorUploadButton}
            </Content>
          </>
        }
        drawerActions={
          <Flex direction='row-reverse'>
            <Button
              disabled={!isSaveEnabled()}
              label='Save'
              loading={isSaving}
              className={classes.saveButton}
              onClick={() => {
                setIsSaving(true);
                onSubmit(
                  {
                    ...selectedVendor,
                  },
                  files,
                );
                setOtherVendorSelected(false);
              }}
            />
            {selectedVendor.id > 0 && (
              <Button
                label='Delete expense'
                variant='outlined'
                type='error'
                onClick={() => {
                  setShowConfirmDelete(true);
                }}
              />
            )}
          </Flex>
        }
        show={isVendorDrawerOpen}
        title={'Add vendor and expenses'}
        subtitle={subTitle}
        closeToggle={() => {
          setIsVendorDrawerOpen(false);
          setSelectedVendor({
            id: 0,
            name: '',
            amountCents: 0,
            programId: 0,
            expenseType: RdVendorExpenseType.CLOUD,
            receipts: [],
          });
          // if SideDrawer closes without saving, remove listed files
          setFiles([]);
          setAttachmentsToDelete([]);
          setAttachedReceipts([]);
        }}
        alertType='alert'
        alertMessage={alertError}
        alertMessagePosition='bottom'
      />
      <RdConfirmDeleteModal
        showConfirmDelete={showConfirmDelete}
        setShowConfirmDelete={setShowConfirmDelete}
        selectedVendor={{ ...selectedVendor }}
        onDelete={onDeleteExpense}
      />
    </>
  );
};

export default RdSideDrawerVendor;
