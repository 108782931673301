import React from 'react';
import { ContentContainer } from '../component/ContentContainer';
import { observer } from 'mobx-react';
import {
  useCompany,
  useAccountCreationStores,
  useHistoryStore,
} from 'stores/useStores';
import { Content, Dropdown, Text, TextField } from 'component-library';
import {
  AccountCreationRequest,
  AccountCreationSteps,
  SurveyQuestionInterface,
  SurveyAnswerTypeEnum,
} from 'lib/interfaces';
import { useEffectOnce } from 'lib/helpers';
import { AdminJobTitleOptions, CompanyDataEnum } from 'lib/constants';

interface ContactsStepProps {
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
}

export const ContactsStep = observer(
  ({ pathTo, pathFrom, onNext, onBack }: ContactsStepProps) => {
    const { company } = useCompany();
    const { accountCreation } = useAccountCreationStores();
    const history = useHistoryStore();

    const onContinue = async () => {
      const {
        adminJobTitle,
        secondaryEmail,
        businessPhoneNumber,
      } = getStepAnswers(accountCreation.ContactsStepQuestions);

      await accountCreation.updateAccount(
        {
          adminJobTitle: adminJobTitle as string,
          secondaryEmail:
            secondaryEmail === '' ? undefined : (secondaryEmail as string),
          businessPhoneNumber: businessPhoneNumber as string,
        },
        AccountCreationSteps.CONTACTS,
      );
      if (!accountCreation.errorMsg) {
        history.push(pathTo);
        onNext();
      }
    };

    const isNextButtonDisabled = accountCreation.enableNextButton(
      accountCreation.ContactsStepQuestions,
    );

    const invalidEmail = accountCreation.errorMsg.includes('Invalid email');

    useEffectOnce(() => accountCreation.setContactsStepQuestions());

    return (
      <ContentContainer
        onNext={onContinue}
        pathFrom={pathFrom}
        onBack={onBack}
        isNextButtonDisabled={isNextButtonDisabled}
        isNextLoading={accountCreation.isNextButtonLoading}
      >
        {accountCreation.ContactsStepQuestions.map((item) => {
          return (
            <Content key={item.id} paddingLeftRight={0} paddingTopBottom={0}>
              <Text variant='medium' paddingBottom={8}>
                {item.question}
              </Text>
              {item.id === CompanyDataEnum.ADMIN_JOB_TITLE ? (
                <Dropdown
                  dataTestId={`account_creation_${CompanyDataEnum.ADMIN_JOB_TITLE}`}
                  value={
                    item.answer
                      ? AdminJobTitleOptions.find(
                          (title) => title.value === item.answer,
                        )?.name
                      : ''
                  }
                  options={AdminJobTitleOptions}
                  placeholder='Please select a role'
                  onInputChange={(str) => {
                    if (typeof str !== 'string') {
                      accountCreation.setInputChange(
                        accountCreation.ContactsStepQuestions,
                        item.question,
                        str.value,
                      );
                    }
                  }}
                />
              ) : (
                <Content flex flexDirection='column' gap={8}>
                  <TextField
                    dataTestId={`account_creation_${item.id}`}
                    value={item.answer}
                    label={
                      item.id === CompanyDataEnum.SECONDARY_EMAIL
                        ? `Your account email: ${company.adminEmail}`
                        : ''
                    }
                    phoneNumber={
                      item.answerType === SurveyAnswerTypeEnum.PHONE_NUMBER
                    }
                    placeholder={item.placeholder}
                    onChange={(event) => {
                      const value = event.target.value;
                      accountCreation.setInputChange(
                        accountCreation.ContactsStepQuestions,
                        item.question,
                        value,
                      );
                    }}
                    error={
                      item.id === CompanyDataEnum.SECONDARY_EMAIL &&
                      accountCreation.errorMsg.length > 0 &&
                      invalidEmail
                    }
                    helperText={
                      item.id === CompanyDataEnum.SECONDARY_EMAIL &&
                      invalidEmail &&
                      'Please enter a valid email'
                    }
                  />
                </Content>
              )}
            </Content>
          );
        })}
      </ContentContainer>
    );
  },
);

const getStepAnswers = (
  stepQuestions: SurveyQuestionInterface[],
): AccountCreationRequest => {
  const adminJobTitle = stepQuestions.find(
    (item) => item.id === CompanyDataEnum.ADMIN_JOB_TITLE,
  )?.answer as string;
  const secondaryEmail = stepQuestions.find(
    (item) => item.id === CompanyDataEnum.SECONDARY_EMAIL,
  )?.answer as string;
  const businessPhoneNumber = stepQuestions.find(
    (item) => item.id === CompanyDataEnum.BUSINESS_PHONE_NUMBER,
  )?.answer as string;

  return {
    adminJobTitle,
    secondaryEmail,
    businessPhoneNumber,
  };
};
