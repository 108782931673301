import { Grid, Flex, Color, Text } from 'component-library';
import {
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationStatusEnum,
} from 'lib/constants';
import React from 'react';
import { ProgressStatus } from './ProgressStatus';
import { ProgressBar } from './ProgressBar';
import { FormattedProgram } from '../../SavingsModule';
import { CentsToDisplayString } from 'lib/helpers';

interface ProgressTrackerProgramProps {
  program: FormattedProgram;
}

export const ProgressTrackerProgram: React.FC<ProgressTrackerProgramProps> = ({
  program,
}) => {
  const {
    stage,
    subStage,
    orderForm,
    qualificationStatus,
    creditAmountCents,
    estimatedTotalCreditCentsOverride,
    programName,
  } = program;
  let step = 0;

  const creditAmount =
    creditAmountCents ||
    estimatedTotalCreditCentsOverride ||
    orderForm?.estimatedTotalCreditCents;

  /**
   * Determine if future program
   */
  // TODO: Future dated programs are for renewals to give expectations that there's
  // something coming to remind you are subscribed for that service.
  const isFutureProgram = false;

  /**
   * Progress Tracker Steps
   */
  const getStarted =
    stage === ProgramStageEnum.QUALIFYING &&
    qualificationStatus === QualificationStatusEnum.QUALIFICATION_IN_PROGRESS;
  const inProgress =
    stage === ProgramStageEnum.QUALIFYING &&
    (qualificationStatus === QualificationStatusEnum.QUALIFICATION_SUBMITTED ||
      qualificationStatus === QualificationStatusEnum.QUALIFIED) &&
    orderForm?.acceptedAt !== null;
  const rdAssessment = stage === ProgramStageEnum.EXPENSE_CLASSIFICATION;
  const msReview = stage === ProgramStageEnum.MS_REVIEW;
  const fileForms = stage === ProgramStageEnum.CLIENT_REVIEW;
  const readyToRedeem =
    stage === ProgramStageEnum.FINISHED &&
    (subStage === ProgramSubStageEnum.READY_TO_REDEEM ||
      subStage === ProgramSubStageEnum.REDEEMING);
  // TODO: Will need to adapt this to appear differently for different tiered customers.
  // https://docs.google.com/document/d/1GFwreCBe8rP9s-3XaaTRRrsgk3HBE66hJBhf-MmcBsA/edit#
  const customerRequirementsComplete =
    stage === ProgramStageEnum.FINISHED ||
    subStage === ProgramSubStageEnum.FILING_UPLOADED;

  const completedProgram = stage === ProgramStageEnum.COMPLETED;

  if (getStarted) {
    step = 0;
  } else if (inProgress) {
    step = 1;
  } else if (rdAssessment) {
    step = 2;
  } else if (msReview) {
    step = 3;
  } else if (fileForms) {
    step = 4;
  } else if (readyToRedeem) {
    step = 5;
  } else if (customerRequirementsComplete) {
    step = 6;
  } else if (completedProgram) {
    step = 7;
  }

  return (
    <Grid columns={8} key={program.id}>
      <Grid.Cell columns={3} alignSelf='center'>
        <Text text={programName} />
      </Grid.Cell>
      <Grid.Cell columns={4}>
        <Flex alignItems='center'>
          <ProgressBar step={step} isFutureProgram={isFutureProgram} />
          <ProgressStatus
            step={step}
            isFutureProgram={isFutureProgram}
            startDate={orderForm?.termStartDate}
          />
        </Flex>
      </Grid.Cell>
      <Grid.Cell
        columns={1}
        justifySelf='flex-end'
        alignSelf='center'
        padding={[0, 0, 0, 16]}
      >
        <Text
          text={CentsToDisplayString(creditAmount || 0)}
          color={
            step === 4 || step === 5 ? Color.neutral._90 : Color.neutral._60
          }
        />
      </Grid.Cell>
    </Grid>
  );
};
