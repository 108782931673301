import { useContext, useState } from 'react';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { FiscalTaxYearHasPayrollGap } from 'lib/helpers';
import { useCompany } from 'stores/useStores';
import { ProgramSubStageEnum } from 'lib/constants';

export const useSkipPayrollStep = (programId: number) => {
  const [shouldSkipPayroll, setShouldSkipPayroll] = useState<boolean>(false);
  const { company } = useCompany();
  const { client } = useContext(Auth0FeatureContext);

  const importPayroll = async () =>
    await client.PayrollImportStatus(company.id, programId).then((res) => {
      if (res) {
        const isConnectedToPayroll = res.isConnected;
        const hasGapInPayroll = FiscalTaxYearHasPayrollGap(
          res.firstPayStatementOfTaxYear,
          res.lastPayStatementOfTaxYear,
          company.fiscalYearEndDate,
        );

        if (isConnectedToPayroll && !hasGapInPayroll) {
          client.UpdateProgramSubStage({
            programId: programId,
            programSubStage:
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EXPENSES,
          });
          setShouldSkipPayroll(true);
        } else {
          setShouldSkipPayroll(false);
        }
      }
    });

  return {
    importPayroll,
    shouldSkipPayroll,
  };
};
