import React from 'react';
import { observer } from 'mobx-react';
import {
  Chip,
  ChipProps,
  Color,
  Divider,
  Flex,
  Icon,
  IconEnum,
  Text,
  Tooltip,
} from 'component-library';
import { useCommonStores, useTaxCreditsStores } from 'stores/useStores';
import {
  AssessmentProgressStatus,
  TaxCreditAssessmentProgramsHygraphId,
  TaxCreditAssessments,
} from 'lib/interfaces';
import { ProgramNameEnum, StatePrograms } from 'lib/constants';

interface AssessmentRowProps {
  index: number;
  credit: TaxCreditAssessments;
  taxYear: string;
}

interface ProgressStatus {
  progress: ChipProps['variant'];
  color: string;
}

export const AssessmentRow = observer(
  ({ index, credit, taxYear }: AssessmentRowProps) => {
    const { unifiedTaxCredits } = useTaxCreditsStores();
    const { companyStore } = useCommonStores();
    const hasStateRdProgram = companyStore.currentCompany.programs.filter((p) =>
      StatePrograms.includes(p.name),
    );

    const getProgressStatus = (
      status: AssessmentProgressStatus,
    ): ProgressStatus => {
      switch (status) {
        case AssessmentProgressStatus.DQ:
          return {
            progress: 'pending',
            color: Color.neutral._60,
          };
        case AssessmentProgressStatus.IN_PROGRESS:
          return {
            progress: 'warning',
            color: Color.semantic.$warning500,
          };
        case AssessmentProgressStatus.MS_REVIEW:
          return {
            progress: 'hard_success',
            color: Color.semantic.$success50,
          };
        default:
          return {
            progress: 'hard_failure',
            color: Color.semantic.$error50,
          };
      }
    };

    return (
      <Flex
        direction='column'
        padding={
          unifiedTaxCredits.assessmentByYear[taxYear].length === index + 1
            ? 24
            : [24, 24, 0, 24]
        }
        gap={24}
      >
        <Flex gap={16} justifyContent='space-between' alignItems='center'>
          <Flex gap={4} basis={580}>
            <Text size={18} variant='medium'>
              {credit.assessmentTitle}
              {credit.id ===
                TaxCreditAssessmentProgramsHygraphId[
                  ProgramNameEnum.FED_RD_TAX
                ] &&
                hasStateRdProgram.length > 0 && (
                  <>
                    <br />
                    <Text tag='small' size={13}>
                      State R&D credits are included in estimated credit
                    </Text>
                  </>
                )}
            </Text>
            {credit.assessmentTooltip && (
              <Tooltip text={credit.assessmentTooltip}>
                <Icon name={IconEnum.question_circle} />
              </Tooltip>
            )}
          </Flex>
          {credit.status && (
            <Flex justifyContent='flex-end' basis={180}>
              <Chip
                text={credit.status}
                variant={getProgressStatus(credit.status).progress}
                showIcon={false}
                textVariant='regular'
                textColor={getProgressStatus(credit.status).color}
                truncate
              />
            </Flex>
          )}
        </Flex>
        {credit.status !== AssessmentProgressStatus.DQ && (
          <Flex gap={16} justifyContent='space-between' alignItems='center'>
            <Flex gap={4} basis={200}>
              <Flex.Cell>
                <Text variant='medium' color={Color.neutral._60} size={13}>
                  Completion time
                </Text>
                <Text>{credit.completionTime} mins</Text>
              </Flex.Cell>
            </Flex>
            {((credit.creditAmount && credit.creditAmountCents !== 0) ||
              credit.estimatedCreditContext) && (
              <Flex justifyContent='flex-end'>
                <Flex.Cell>
                  <Text
                    variant='medium'
                    color={Color.neutral._60}
                    size={13}
                    textAlign='right'
                  >
                    Estimated credit
                  </Text>
                  <Text
                    variant='medium'
                    textAlign='right'
                    color={Color.green._60}
                  >
                    {(credit.creditAmount &&
                      credit.creditAmountCents !== 0 &&
                      credit.creditAmount) ||
                      credit.estimatedCreditContext}
                  </Text>
                </Flex.Cell>
              </Flex>
            )}
          </Flex>
        )}
        {unifiedTaxCredits.assessmentByYear[taxYear].length !== index + 1 && (
          <Divider variant='no-bottom-margin' />
        )}
      </Flex>
    );
  },
);
