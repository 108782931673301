import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core';
import {
  AdminJobTitleOptions,
  BusinessTypes,
  CompanyDetailsEnum,
  IndustriesList,
  Months,
  Page,
  SurveyNameEnum,
  LegacyTaxTypesList,
} from 'lib/constants';
import {
  Button,
  Card,
  Content,
  Dropdown,
  Heading,
  SurveyQuestion,
  Text,
  TextField,
  Color,
} from 'component-library';
import { Formik } from 'formik';
import {
  CompanyDetailsInfo,
  UpdateCompanyDetailsInfoRequest,
} from 'lib/interfaces';
import { isValidYearFounded } from 'lib/validation';
import * as yup from 'yup';
import { CompanyContext } from '../../CompanyRequired';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import { AnswerType } from 'component-library/dashboard/surveyquestion/SurveyQuestion';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';

import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { observer } from 'mobx-react';
import { useCommonStores, useFeatureFlags } from 'stores/useStores';
import { OnboardingContainer } from 'pages/onboarding/components/OnboardingContainer';
import { OnboardingLandingPage } from 'pages/onboarding/components/OnboardingLandingPage';

const useStyles = makeStyles(({ color, breakpoints }: Theme) => ({
  root: {
    maxWidth: '880px',
    paddingBottom: 80,
  },
  instructions: {
    '& > h2': {
      marginBottom: '8px',
    },
    [breakpoints.down('xs')]: {
      margin: '16px 32px 0',
    },
  },
  cardMargin: {
    margin: 0,
  },
  cardMarginTop: {
    marginTop: 24,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [breakpoints.down('xs')]: {
      margin: '0 auto',
    },
  },
  detailRow: {
    display: 'flex',
    '& > p': {
      flex: 1,
    },
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  formErrorMsg: {
    color: Color.semantic.$error50,
    margin: '16px 0 0',
  },
  saveBtn: {
    alignSelf: 'flex-start',
  },
  editBtn: {
    marginTop: 40,
  },
  continueBtn: {
    alignSelf: 'flex-start',
    marginTop: '20px',
    [breakpoints.down('xs')]: {
      marginLeft: '32px',
    },
  },
}));

export const VERIFIABLE_ASSETS_QUESTION_ID = 'cl34xf75pbctg0an4ry4ehilr';
const VERIFIABLE_ASSETS_YES_ANSWER_ID = 'ckucwu5s8qwk80b27ff9obao6';

export const CompanyDetails = observer(() => {
  const { app } = useCommonStores();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const isTreasury = urlParams.get('treasury') === 'true';
  const { client } = useContext(Auth0FeatureContext);
  const featureFlags = useFeatureFlags();
  const { company, setCompany } = useContext(CompanyContext);
  const {
    saveAnswers,
    addSurveyAnswers,
    questionsToRender,
    surveyAnswers,
    isSurveyComplete,
  } = useSurveyQuestions(
    SurveyNameEnum.ONBOARDING_COMPANY_DETAILS,
    app.qualificationTaxYear,
  );

  const isVerifiableAssetsQuestionAnsweredYes =
    surveyAnswers[VERIFIABLE_ASSETS_QUESTION_ID] ===
    VERIFIABLE_ASSETS_YES_ANSWER_ID;

  const initialValues: CompanyDetailsInfo = {
    legalName: company.legalName || '',
    doingBusinessAs: company.name || '',
    website: company.website || '',
    industry: company.industry || '',
    yearFounded: company.yearFounded || 0,
    businessType: company.businessType || '',
    taxType: company.taxType || '',
    fiscalYearEndDate:
      company.fiscalYearEndDate && company.fiscalYearEndDate !== 'N/A'
        ? company.fiscalYearEndDate
        : 'December',
    adminJobTitle: company.adminJobTitle || '',
    secondaryEmail: company.secondaryEmail || '',
    businessPhoneNumber: company.businessPhoneNumber || '',
  };

  const [formError, setFormError] = useState<string>('');
  const [companyDetailsEditable, setCompanyDetailsEditable] = useState<boolean>(
    !company.name,
  );
  const [formValues, setFormValues] =
    useState<CompanyDetailsInfo>(initialValues);
  const [onSubmitLoading, setOnSubmitLoading] = useState<boolean>(false);
  const [showLandingPage, setShowLandingPage] = useState(false);
  const submit = () => {
    setOnSubmitLoading(true);
    saveAnswers()
      .catch(() => {
        datadogLogs.logger.error(
          `Unable to save survey responses for company details onboarding`,
          logContext({ company }),
        );
      })
      .finally(() => {
        const req: UpdateCompanyDetailsInfoRequest = {
          adminJobTitle: formValues.adminJobTitle,
          businessType: formValues!.businessType,
          businessPhoneNumber: formValues!.businessPhoneNumber,
          doingBusinessAs: formValues!.doingBusinessAs,
          fiscalYearEndDate: formValues!.fiscalYearEndDate,
          industry: formValues!.industry,
          legalName: formValues!.legalName,
          qualificationTaxYear: app.qualificationTaxYear,
          secondaryEmail: formValues!.secondaryEmail,
          taxType: formValues!.taxType,
          website: formValues!.website,
          yearFounded: formValues!.yearFounded,
          ...((isTreasury || isVerifiableAssetsQuestionAnsweredYes) && {
            treasuryManagementEnabled: true,
          }),
          surveyAnswers,
        };
        client.UpdateCompanyDetailsInfo(req).then((res) => {
          if (res.errorMsg) {
            setFormError(res.errorMsg);
            return;
          }

          setCompany(res.data!.company);
          setOnSubmitLoading(false);

          if (!isTreasury) {
            history.push(`/${Page.transitionToDashboard}`);
          } else {
            history.push(`/${Page.treasuryManagement}`);
          }
          return;
        });
      });
  };

  const showAdditionalOnboardingQuestions =
    featureFlags.isOnboardingCdAdditionalQuestionsEnabled;
  const showAdditionalQuestions =
    !companyDetailsEditable &&
    questionsToRender &&
    showAdditionalOnboardingQuestions;
  const showContinue =
    !companyDetailsEditable &&
    (showAdditionalQuestions ? isSurveyComplete : true);

  return (
    <OnboardingContainer>
      {!showLandingPage ? (
        <OnboardingLandingPage onSubmit={() => setShowLandingPage(true)} />
      ) : (
        <Content className={classes.root}>
          <div className={classes.instructions}>
            <Heading tag='h2' text='Fill in your company details' />
            <Text paddingBottom={16}>
              Accurate answers to these questions help us expedite the
              qualification&nbsp;process.
            </Text>
          </div>
          <Card className={classes.cardMargin}>
            <Content>
              <Formik
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                  setFormValues({
                    legalName: values.legalName,
                    doingBusinessAs: values.doingBusinessAs,
                    website: values.website,
                    yearFounded: values.yearFounded,
                    businessType: values.businessType,
                    taxType: values.taxType,
                    industry: values.industry,
                    fiscalYearEndDate: values.fiscalYearEndDate,
                    qualificationTaxYear: values.qualificationTaxYear,
                    adminJobTitle: values.adminJobTitle,
                    secondaryEmail: values.secondaryEmail,
                    businessPhoneNumber: values.businessPhoneNumber,
                  });
                  setCompanyDetailsEditable(false);
                }}
                validationSchema={yup.object().shape({
                  legalName: yup
                    .string()
                    .required("Please include your company's legal name"),
                  website: yup
                    .string()
                    .required("Please include your company's website"),
                  yearFounded: yup
                    .number()
                    .test(
                      'Check if Valid Year',
                      'Year incorporated must be a valid year',
                      function (item) {
                        return isValidYearFounded(item);
                      },
                    )
                    .required('Year incorporated must be a valid year'),
                  businessType: yup
                    .string()
                    .required('Please select a business type.'),
                  taxType: yup
                    .string()
                    .required('Please select how your business is taxed.'),
                  industry: yup.string().required('Please select an industry.'),
                  fiscalYearEndDate: yup
                    .string()
                    .test(
                      'Check if Valid Fiscal Year Date',
                      'Please select an end of fiscal year',
                      function (item) {
                        return item && item !== 'N/A';
                      },
                    )
                    .required('Please select an end of fiscal year.'),
                  adminJobTitle: yup
                    .string()
                    .required('Please select your role at the company.'),
                  secondaryEmail: yup
                    .string()
                    .email('Please enter a valid email'),
                  businessPhoneNumber: yup
                    .string()
                    .required(
                      'Please enter a number where we can best reach you.',
                    ),
                })}
                // eslint-disable-next-line react/no-children-prop
                children={({ values, errors, handleSubmit, setFieldValue }) =>
                  companyDetailsEditable ? (
                    <div className={classes.formContainer}>
                      <TextField
                        data-testid='account_info_company_name'
                        label='Legal business name'
                        value={values.legalName}
                        onChange={(event) => {
                          setFieldValue('legalName', event.target.value);
                        }}
                        error={errors!.legalName ? true : false}
                        helperText={errors && errors.legalName}
                      />
                      <TextField
                        data-testid='account_info_company_dba'
                        label='DBA (doing business as; if applicable)'
                        value={values.doingBusinessAs}
                        onChange={(event) => {
                          setFieldValue('doingBusinessAs', event.target.value);
                        }}
                      />
                      <TextField
                        data-testid='account_info_website'
                        label='Website'
                        value={values.website}
                        onChange={(event) => {
                          setFieldValue('website', event.target.value);
                        }}
                        error={errors!.website ? true : false}
                        helperText={errors && errors.website}
                      />
                      <Dropdown
                        name='industry'
                        label='Industry'
                        value={values.industry}
                        options={IndustriesList}
                        onInputChange={(str) => {
                          setFieldValue('industry', str);
                        }}
                        error={errors!.industry ? true : false}
                        helperText={errors && errors.industry}
                      />
                      <TextField
                        data-testid='account_info_year_incorporated'
                        label='Year of incorporation'
                        yearFormat
                        value={
                          values.yearFounded === 0 ? '' : values.yearFounded
                        }
                        onChange={(num) => {
                          const value = num.target.value;
                          if (!value) {
                            setFieldValue('yearFounded', 0);
                            return;
                          }
                          setFieldValue('yearFounded', Number(value));
                        }}
                        error={errors!.yearFounded ? true : false}
                        helperText={errors && errors.yearFounded}
                      />
                      <Dropdown
                        name='businessType'
                        label='Business type'
                        value={values.businessType}
                        options={BusinessTypes}
                        onInputChange={(str) => {
                          setFieldValue('businessType', str);
                        }}
                        error={errors!.businessType ? true : false}
                        helperText={errors && errors.businessType}
                      />
                      <Dropdown
                        name='taxType'
                        label='Company type (for tax purposes)'
                        value={
                          values.taxType &&
                          LegacyTaxTypesList.find(
                            (item) => item.value === values.taxType,
                          )!.name
                        }
                        options={LegacyTaxTypesList}
                        onInputChange={(item) => {
                          if (typeof item !== 'string') {
                            setFieldValue('taxType', item!.value);
                          } else {
                            setFieldValue('taxType', item);
                          }
                        }}
                        error={errors!.taxType ? true : false}
                        helperText={errors && errors.taxType}
                      />
                      <Dropdown
                        name='fiscalYearEndDate'
                        label='End of tax year'
                        value={values.fiscalYearEndDate}
                        options={Months}
                        onInputChange={(str) => {
                          setFieldValue('fiscalYearEndDate', str);
                        }}
                        error={errors!.fiscalYearEndDate ? true : false}
                        helperText={errors && errors.fiscalYearEndDate}
                      />
                      <Dropdown
                        name='adminJobTitle'
                        label='What is your role at the company?'
                        value={
                          values.adminJobTitle &&
                          AdminJobTitleOptions.find(
                            (item) => item.value === values.adminJobTitle,
                          )!.name
                        }
                        options={AdminJobTitleOptions}
                        onInputChange={(str) => {
                          if (typeof str !== 'string') {
                            setFieldValue('adminJobTitle', str!.value);
                          } else {
                            setFieldValue('adminJobTitle', str);
                          }
                        }}
                        error={errors!.adminJobTitle ? true : false}
                        helperText={errors && errors.adminJobTitle}
                      />
                      <TextField
                        data-testid='account_info_secondary_email'
                        label='Add a secondary contact from your company'
                        placeholder='Email address'
                        value={values.secondaryEmail}
                        onChange={(event) => {
                          setFieldValue('secondaryEmail', event.target.value);
                        }}
                        error={errors!.secondaryEmail ? true : false}
                        helperText={errors && errors.secondaryEmail}
                      />
                      <TextField
                        data-testid='account_phone_number'
                        label='Business Phone Number'
                        phoneNumber
                        value={values.businessPhoneNumber}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.toString() === '') {
                            setFieldValue('businessPhoneNumber', '');
                            return;
                          }
                          setFieldValue('businessPhoneNumber', value);
                        }}
                        error={errors!.businessPhoneNumber ? true : false}
                        helperText={errors && errors.businessPhoneNumber}
                      />

                      <Button
                        className={classes.saveBtn}
                        data-testid='account_info_save'
                        variant='outlined'
                        onClick={() => {
                          handleSubmit();
                        }}
                        label='Save'
                      />
                    </div>
                  ) : (
                    <div className={classes.formContainer}>
                      {(
                        Object.keys(initialValues) as Array<
                          keyof typeof CompanyDetailsEnum
                        >
                      ).map((item, i) => {
                        return (
                          <div
                            key={`${i}-${item}`}
                            className={classes.detailRow}
                          >
                            <Text variant='medium'>
                              {CompanyDetailsEnum[item]}
                            </Text>
                            <Text>{values[item]}</Text>
                          </div>
                        );
                      })}
                    </div>
                  )
                }
              />
              {formError && (
                <Text className={classes.formErrorMsg}>
                  {`Error: ${formError}`}
                </Text>
              )}
              {!companyDetailsEditable && (
                <Button
                  className={classes.editBtn}
                  label='Edit'
                  variant='outlined'
                  onClick={() => {
                    setCompanyDetailsEditable(true);
                  }}
                />
              )}
            </Content>
          </Card>
          {showAdditionalQuestions ? (
            Object.keys(questionsToRender).map((key) => {
              return questionsToRender[key].map((question) => (
                <div className={classes.cardMarginTop} key={question.id}>
                  <SurveyQuestion
                    text={question.text}
                    answerType={question.answerType as AnswerType}
                    answerValue={question.answerValue}
                    onChange={(answer) => {
                      question.answerValue = answer;
                      addSurveyAnswers(question.id, key, [
                        { questionId: question.id, answerValue: answer },
                      ]);
                    }}
                    answerOptions={question.answerIDs}
                    withCardMargin={false}
                  />
                </div>
              ));
            })
          ) : (
            <></>
          )}
          {showContinue && (
            <Button
              className={classes.continueBtn}
              label='Continue'
              loading={onSubmitLoading}
              onClick={submit}
            />
          )}
        </Content>
      )}
    </OnboardingContainer>
  );
});
