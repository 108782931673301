import React from 'react';
import { RouteConfiguration } from 'routes/RouteConfiguration';
import { Page } from 'lib/constants';
import ConnectedAccountRedirect from 'pages/ConnectedAccountRedirect';
import { IntegrationsPage } from 'pages/dashboard/integrations';
import { PayrollRipplingIntegration } from 'pages/PayrollRipplingIntegration';
import TokenRequired from 'pages/TokenRequired';
import { useFeatureFlags } from 'stores/useStores';

export const useIntegrationRoutes = (): RouteConfiguration[] => {
  const featureFlags = useFeatureFlags();

  return [
    {
      render: () => <></>,
      path: '/integrations',
      redirectPath: `/${Page.connections}`,
      documentPageTitle: 'Connections',
    },
    {
      path: `/${Page.payrollIntegrationRippling}`,
      render: () => <PayrollRipplingIntegration />,
      hideTopNav: true,
      hideSideNav: true,
      authSetupRequired: true,
      authRequired: false,
      companyRequired: false,
      featureFlagsRequired: false,
      bypassMobilePrint: true,
    },
    {
      path: `/${Page.connectedAccount}`,
      render: (params, query, history) => (
        <ConnectedAccountRedirect search={history.search} />
      ),
    },
    {
      path: `/${Page.emailPayrollCredentials}`,
      render: () => (
        <TokenRequired>
          <IntegrationsPage />
        </TokenRequired>
      ),
      companyRequired: false,
    },
    {
      path: `/${Page.connections}`,
      render: () => <IntegrationsPage />,
      documentPageTitle: 'Connections',
    },
  ];
};
