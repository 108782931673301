import { FileHandle } from 'component-library';
import { MSApiResponse, MSClientResponse } from 'lib/interfaces';
import { ABORT_ERROR } from '../lib/constants';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from '../logging';

const logger = datadogLogs.createLogger('serverGenerics');
const somethingWentWrong = 'An error has occurred.';

/**
 * Returns the combined set of headers with overwrite preference to `headers1`.
 *
 * **Note**: If you are trying to ovewrite a header, remember its name should match (case-sensitive!) between `headers1` and `headers2`.
 *    For example, if headers1 contains 'Authorization' and headers2 contains 'authorization' this would not work.
 *    @TODO GOV-1744 will address this.
 * @param headers1
 * @param headers2
 */
const mergeHeaders = (headers1: any, headers2: any) => {
  return headers1['headers']
    ? { ...headers2.headers, ...headers1.headers }
    : headers2.headers;
};

const logFetchError = (errorMessage: string) => {
  logger.error(`Fetch Error: ${errorMessage}`);
};

const handleNonOkCodeErrors = (
  okCodes: number[],
  json: MSApiResponse<any>,
  path: string,
  methodName: string,
) => {
  if (okCodes.indexOf(json.status) < 0) {
    logFetchError(
      `${methodName} ${path} returned unexpected status code ${
        json.status
      }. Expected status codes ${okCodes.toString()}. Message: ${
        json.errorMsg
      }`,
    );
    return {
      errorMsg: json.errorMsg || somethingWentWrong,
    };
  }
};

const handleFailedFetch = (e: any, path: string, methodType: string) => {
  logFetchError(`${methodType} ${path} failed: ${e}`);
  if (e.name !== ABORT_ERROR && !isFailedToFetch(e)) {
    catchBlockLog(methodType, path, e);
  }

  return {
    errorMsg: somethingWentWrong,
  };
};

/**
 * @deprecated use AbstractWebClient version instead
 */
export function Get<T>(
  path: string,
  okCodes: number[],
  reqOptions?: any,
): Promise<MSClientResponse<T>> {
  const defaultOptions = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    cache: 'no-cache',
  };

  const headers = mergeHeaders(reqOptions || {}, defaultOptions);
  const o = Object.assign({}, defaultOptions, reqOptions || {});
  o.headers = { ...o.headers, ...headers };

  return fetch(path, o)
    .then((res) => {
      return res.json();
    })
    .then((json: MSApiResponse<T>) => {
      const errorMessage = handleNonOkCodeErrors(okCodes, json, path, 'GET');

      return errorMessage || { data: json.data };
    })
    .catch((e: any) => {
      return handleFailedFetch(e, path, 'GET');
    });
}

type BodyParams =
  | string
  | FormData
  | Blob
  | ArrayBufferView
  | ArrayBuffer
  | URLSearchParams
  | ReadableStream<Uint8Array>
  | null
  | undefined;

/**
 * @deprecated use AbstractWebClient version instead
 */
export function Post<T>(
  path: string,
  okCodes: number[],
  body: BodyParams,
  reqOptions?: any,
): Promise<{ res: MSClientResponse<T>; status: number }> {
  const defaultOptions = {
    method: 'post',
    credentials: 'include',
    cache: 'no-cache',
    body,
  };

  const headers = mergeHeaders(reqOptions || {}, defaultOptions);
  const o = Object.assign({}, defaultOptions, reqOptions || {});
  o.headers = { ...o.headers, ...headers };

  return fetch(path, o)
    .then((res) => {
      return res.json();
    })
    .then((json: MSApiResponse<T>) => {
      const payload = handleNonOkCodeErrors(okCodes, json, path, 'POST') || {
        data: json.data,
      };

      return { res: payload, status: json.status };
    })
    .catch((e: any) => {
      const errorMessageObject = handleFailedFetch(e, path, 'POST');

      return {
        res: errorMessageObject,
        status: 0,
      };
    });
}

/**
 * @deprecated use AbstractWebClient version instead
 */
export function Put<T, U>(
  path: string,
  okCodes: number[],
  reqParams: T,
  reqOptions?: any,
): Promise<MSClientResponse<U>> {
  const defaultOptions = {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    cache: 'no-cache',
    body: reqParams ? JSON.stringify(reqParams) : undefined,
  };

  const headers = mergeHeaders(reqOptions || {}, defaultOptions);
  const o = Object.assign({}, defaultOptions, reqOptions || {});
  o.headers = { ...o.headers, ...headers };

  return fetch(path, o)
    .then((res) => {
      return res.json();
    })
    .then((json: MSApiResponse<U>) => {
      const errorMessage = handleNonOkCodeErrors(okCodes, json, path, 'PUT');

      return errorMessage || { data: json.data };
    })
    .catch((e: any) => {
      return handleFailedFetch(e, path, 'PUT');
    });
}

/**
 * @deprecated use AbstractWebClient version instead
 */
export function Delete<T>(
  path: string,
  okCodes: number[],
  reqOptions?: any,
): Promise<MSClientResponse<T>> {
  const defaultOptions = {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    cache: 'no-cache',
  };

  const headers = mergeHeaders(reqOptions || {}, defaultOptions);
  const o = Object.assign({}, defaultOptions, reqOptions || {});
  o.headers = { ...o.headers, ...headers };

  return fetch(path, o)
    .then((res) => {
      return res.json();
    })
    .then((json: MSApiResponse<T>) => {
      const errorMessage = handleNonOkCodeErrors(okCodes, json, path, 'DELETE');

      return errorMessage || { data: json.data };
    })
    .catch((e: any) => {
      return handleFailedFetch(e, path, 'DELETE');
    });
}

type UploadParams = File | FileHandle | string | Blob;

export function Upload<T>(
  method: 'post' | 'put',
  path: string,
  okCodes: number[],
  reqParams: Record<string, UploadParams | UploadParams[]>,
  reqOptions?: RequestInit,
  progress?: (
    visualProgress: number,
    uploadProgress: number,
    downloadProgress: number,
  ) => void,
  abortController?: AbortController,
): Promise<MSClientResponse<T>> {
  const fileHandles: FileHandle[] = [];
  const defaultOptions: RequestInit = {
    headers: {
      'Cache-Control': 'no-cache, no-store, max-age=0',
    },
  };

  // Merge headers with default options
  const headers = mergeHeaders(reqOptions || {}, defaultOptions) as HeadersInit;
  const o = Object.assign({}, defaultOptions, reqOptions || {});
  o.headers = { ...o.headers, ...headers };

  // Build the form data
  const formData = new FormData();
  for (const key in reqParams) {
    const param = reqParams[key];

    if (Array.isArray(param)) {
      param.forEach((value) => {
        if (value instanceof FileHandle) {
          fileHandles.push(value);
          formData.append(key, value.file);
        } else {
          formData.append(key, value);
        }
      });
    } else {
      if (param instanceof FileHandle) {
        fileHandles.push(param);
        formData.append(key, param.file);
      } else {
        formData.append(key, param);
      }
    }
  }

  // Make the actual request
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    let completed = false;
    let uploadProgress = 0;
    let downloadProgress = 0;
    let aborted = false;

    // Progress updates
    const updateProgress = () => {
      /**
       * Visual progress is a combination of the upload & download progress,
       * with the idea being the user experience is not finished until the
       * request completes. This also assumes that in general, more time
       * will be spent on the actual upload (80%) than waiting for a
       * response from the server (20%)
       */
      const visualUpload = uploadProgress * 80;
      const visualDownload = downloadProgress * 20;
      const visualProgress = completed
        ? 1
        : Math.min(
            Math.max((visualUpload + visualDownload) / 100, 0.001),
            0.99,
          );

      fileHandles.forEach((file) =>
        file.setUploadProgress(
          visualProgress,
          uploadProgress,
          downloadProgress,
        ),
      );

      if (progress) {
        progress(visualProgress, uploadProgress, downloadProgress);
      }
    };

    // Listen for user based aborting
    [
      abortController,
      ...fileHandles.map((file) => file.abortController),
    ].forEach((controller) => {
      controller?.signal.addEventListener(`abort`, () => {
        if (!completed) {
          aborted = true;
          xhr.abort();
        }
      });
    });

    // Clear all controllers when abort is triggered
    xhr.addEventListener(`abort`, () => {
      abortController = undefined;
      fileHandles.forEach((file) => file.setAbortController(null));
    });

    // Progress tracking
    xhr.upload.addEventListener(`progress`, (e) => {
      uploadProgress = e.lengthComputable ? e.loaded / e.total : 0;
      updateProgress();
    });
    xhr.addEventListener(`progress`, (e) => {
      downloadProgress = e.lengthComputable ? e.loaded / e.total : 0;
      updateProgress();
    });

    // Response handling
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4 || completed || aborted) {
        return;
      }

      completed = true;
      updateProgress();
      try {
        const json = JSON.parse(xhr.responseText);
        if (okCodes.indexOf(json.status) < 0 || json.errorMsg) {
          resolve({
            errorMsg: json.errorMsg,
          });
        } else {
          resolve({ data: json.data });
        }
      } catch (e) {
        if ((e as any).name !== ABORT_ERROR && !isFailedToFetch(e as any)) {
          catchBlockLog('DELETE', path, e);
        }

        resolve({
          errorMsg: somethingWentWrong,
        });
      }
    };

    // Open the request
    xhr.open(method, path, true);

    // Attach the headers (using fetch based header params)
    if (o.headers) {
      if (Array.isArray(o.headers)) {
        o.headers.forEach((pair) => xhr.setRequestHeader(pair[0], pair[1]));
      } else if (o.headers instanceof Headers) {
        for (const pair of o.headers.entries()) {
          xhr.setRequestHeader(pair[0], pair[1]);
        }
      } else {
        for (const key in o.headers) {
          xhr.setRequestHeader(key, o.headers[key]);
        }
      }
    }

    // Send the request
    xhr.withCredentials = o.credentials !== `omit`;
    xhr.send(formData);
  });
}

const isFailedToFetch = (e: Error) => {
  return e.name === 'TypeError' && e.message === 'Failed to fetch';
};

const catchBlockLog = (method: string, path: string, error: any) => {
  logger.error(
    `Catch block reached in Dashboard Client Path: ${method} ${path}`,
    {
      ...logContext({
        error,
      }),
      path,
      method,
    },
  );
};
