import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Animate, Color, Stepper } from 'component-library';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { animated } from 'react-spring';
import {
  HidePayrollQualificationSteps,
  Page,
  QualificationSteps,
} from 'lib/constants';
import TitleHeader from 'components/TitleHeader';
import useRDQualificationRoutes from './component/useRDQualificationRoutes';
import { observer } from 'mobx-react';
import {
  useFeatureFlags,
  useCommonStores,
  useCompany,
  useTaxCreditsStores,
} from 'stores/useStores';
import { useSpringPageLocationTransition } from 'lib/useSpringPageTransition';
import { useEffectOnce } from 'lib/helpers';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { datadogLogs } from '@datadog/browser-logs';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: '100vh',
    backgroundColor: Color.neutral.white,
  },
  mainContent: {
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
    padding: '0 24px 80px',
    boxSizing: 'border-box',
    position: 'relative',
  },
}));

interface RDQualificationProps {
  taxYear: number;
}

export const RDQualification = observer(({ taxYear }: RDQualificationProps) => {
  const classes = useStyles();
  const location = useLocation();
  const { company } = useCompany();
  const { client } = useContext(Auth0FeatureContext);
  const { rdQualification } = useTaxCreditsStores();
  const { RDQualificationRoutes } = useRDQualificationRoutes(taxYear);
  const { transitions } = useSpringPageLocationTransition(
    location,
    rdQualification.currentStep,
    rdQualification.previousStep,
  );
  const { app } = useCommonStores();
  const featureFlags = useFeatureFlags();
  const hidePayrollStep = featureFlags.hidePayrollStepInQualification;

  useEffectOnce(async () => {
    if (company.misc && !company.misc.autoQualificationLoaded) {
      company.misc = {
        ...company.misc,
        autoQualificationLoaded: true,
      };
      datadogLogs.logger.info(
        `[QUALIFICATION]: Company loaded auto qualification for the first time`,
        {
          companyId: company.id,
        },
      );
      await client.UpdateCompanyMisc({ autoQualificationLoaded: true });
    }
  });

  useEffect(() => {
    rdQualification.setCurrentStepOnLoad(location.pathname, taxYear);
  }, [rdQualification, location.pathname, taxYear]);

  return (
    <div className={classes.container}>
      <Animate enter={['fade-in', 'from-top']}>
        <TitleHeader
          title='Federal R&D credit estimate'
          showExitButton
          onExitText='Only the answers up to the last completed step has been saved'
        />
      </Animate>
      <div className={classes.mainContent}>
        {!app.isMobile && (
          <Animate enter={'fade-in'} delay={0.5} duration={0.75}>
            <Stepper
              steps={
                hidePayrollStep
                  ? HidePayrollQualificationSteps
                  : QualificationSteps
              }
              currentStep={rdQualification.currentStep}
            />
          </Animate>
        )}
        <Animate enter={'fade-in'} delay={1} duration={0.75}>
          {transitions((props, item) => {
            const isRootAndSkipPayrollStep =
              hidePayrollStep &&
              item.pathname ===
                `/${Page.taxCreditQualificationRoot}/${taxYear}`;

            return (
              <animated.div style={props}>
                <Switch location={item}>
                  {isRootAndSkipPayrollStep && (
                    <Redirect
                      to={`/${Page.taxCreditQualificationRoot}/${taxYear}/${Page.taxCreditRdPayrollDetails}`}
                    />
                  )}
                  {RDQualificationRoutes.map((item, key) => {
                    return (
                      <Route
                        exact={item.exact}
                        key={key}
                        path={item.path}
                        render={() => item.component}
                      />
                    );
                  })}
                </Switch>
              </animated.div>
            );
          })}
        </Animate>
      </div>
    </div>
  );
});
