import React from 'react';
import { Flex, Heading, Link, Color, Spinner } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { ProgressTrackerProgram } from './ProgressTrackerProgram';
import { FormattedProgram } from '../../SavingsModule';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    maxWidth: 671,
    padding: '24px 16px 24px 16px',
    borderLeft: `1px solid ${Color.neutral._20}`,
  },
}));

interface ProgressTrackerProps {
  programs: FormattedProgram[];
  activeCredits: number;
}

export const ProgressTracker: React.FC<ProgressTrackerProps> = observer(
  ({ programs, activeCredits }) => {
    const classes = useStyles();

    return (
      <div
        className={classes.container}
        data-testid={'savings-progress-tracker'}
      >
        <Flex
          justifyContent='space-between'
          alignItems='center'
          padding={[0, 0, 16, 0]}
        >
          <Heading
            tag='h4'
            size={15}
            text={`${activeCredits} ${
              activeCredits === 1 ? 'credit' : 'credits'
            } in progress`}
            marginBottom={0}
          />
          <Link href='/tax-credits' size={13} variant={'medium'}>
            View tax credits
          </Link>
        </Flex>
        {programs.length === 0 ? (
          <Spinner size='small' />
        ) : (
          programs.map((program) => (
            <ProgressTrackerProgram key={program.id} program={program} />
          ))
        )}
      </div>
    );
  },
);
