import React from 'react';
import { Dollar, Flex, Heading, Link } from 'component-library';
import { observer } from 'mobx-react';
import { Company } from 'entities/Company';
import { makeStyles } from '@material-ui/core';
import { CentsToDisplayStringNoSymbol } from 'lib/helpers';

const useStyles = makeStyles(() => ({
  viewAllLink: {
    paddingBottom: '17px',
  },
}));

interface CreditsRedeemedProps {
  company: Company;
  estimate: number;
}

export const CreditsRedeemed: React.FC<CreditsRedeemedProps> = observer(
  ({ company, estimate }) => {
    const classes = useStyles();

    return (
      <Flex.Cell grow={4} padding={24} dataTestId={'credits-redeemed'}>
        <Flex justifyContent={'end'} className={classes.viewAllLink}>
          <Link href='/tax-credits' size={13} variant={'medium'}>
            View all tax credits
          </Link>
        </Flex>
        <Heading tag='h4'>
          <Heading text={`${company.name} has redeemed `} tag='span' />
          <Dollar
            tag={'span'}
            value={`${CentsToDisplayStringNoSymbol(estimate)}`}
            variant='medium'
          />
          <Heading text={` in tax credits.`} tag='span' />
        </Heading>
      </Flex.Cell>
    );
  },
);
