import { Dispatch, useContext, useState } from 'react';
import { Auth0FeatureContext } from '../../../../components/util/Auth0Feature';
import { API, DOCUMENTS } from '../../../../lib/constants';
import { Link, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { ServerClient } from '../../../../services/ServerClient';
import { Color, Icon, IconEnum, Text } from 'component-library';

interface DocumentDownloadLinkProps {
  id: number;
  token?: string;
  companyEmail?: string;
  programId?: number;
}

const useStyles = makeStyles(() => ({
  icon: {
    '&:hover, &:focus': {
      cursor: 'pointer',
    },
  },
}));
const handleClick = (
  id: number,
  setErrorMsg: Dispatch<React.SetStateAction<string>>,
  client: ServerClient,
) => {
  client.GetDocumentUrl(id).then((res) => {
    if (res.errorMsg) {
      setErrorMsg(res.errorMsg);
      return;
    }

    if (res.data) {
      window.open(res.data.url, '_blank');
    }
  });
};

export const DocumentDownloadLink = ({
  id,
  token,
  companyEmail,
  programId,
}: DocumentDownloadLinkProps) => {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { client } = useContext(Auth0FeatureContext);
  const classes = useStyles();
  // User is logged in; we can use client which will add the appropriate
  // auth headers.
  if (!token) {
    return (
      <div>
        <p>
          <Icon
            name={IconEnum.download}
            color={Color.blue._60}
            size={24}
            className={classes.icon}
            onClick={() => {
              handleClick(id, setErrorMsg, client);
            }}
          />
        </p>
        {errorMsg && (
          <Text status='failed' size={13}>
            {errorMsg}
          </Text>
        )}
      </div>
    );
  }

  // HACK - Magiclink centric code. This can be cleaned up at a later date, but
  // leaving in for backwards compatibility.
  let query = `?token=${token}&email=${encodeURIComponent(companyEmail || '')}`;
  if (programId) {
    query += `&programId=${programId}`;
  }
  const url = `${
    process.env.REACT_APP_DOCUMENT_LINK_BASE || ''
  }/${API}/${DOCUMENTS}/view/${id}${query}`;
  return (
    <div>
      <p>
        <Link href={url} target='_blank' rel='noopener noreferrer'>
          <Icon
            name={IconEnum.download}
            color={Color.blue._60}
            size={24}
            className={classes.icon}
          />
        </Link>
      </p>
    </div>
  );
};
