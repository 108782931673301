import { runInAction } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import { BaseTaxCreditsStore } from '../BaseTaxCreditsStore';
import { datadogLogs } from '@datadog/browser-logs';
import {
  AddProgramLedgerTxnRequest,
  CarryoverAndAdjustStatusEnum,
  Page,
  PayrollTierEnum,
  ProgramLedgerTxnUpdateRequest,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  TaxInputValueEnum,
} from 'lib/constants';
import { ISODateStringFromDate } from 'lib/helpers';
import {
  FilingStatusQuarter,
  MissedRedemptionLocalStorageData,
  ProgramData,
  QuarterlyForm8974,
  QuarterlyForm8974DocumentUrl,
  QuarterlyInputData,
  Quarters,
} from 'lib/interfaces';

export class RedemptionStore extends BaseTaxCreditsStore {
  public showVerifyFilingDateModal = false;
  public showFilingDateModal = false;
  public showMissedQuarterDeadlineModal = false;
  public showOnTimeToRedeemYourCreditModal = false;
  public isUpdatingFilingDate = false;
  public showMissedDeadlineAlert?: boolean = false;
  public filingStatus: CarryoverAndAdjustStatusEnum =
    CarryoverAndAdjustStatusEnum.PROGRAM_LEDGER_SETUP_REQUIRED;
  public currentFiling?: FilingStatusQuarter;
  public missedFilings?: FilingStatusQuarter[] = [];
  public quarterlyPayrollTaxesForm941: QuarterlyInputData = {};
  public quarterlyFor8974DocumentUrl: QuarterlyForm8974 = {
    currentQuarter: [],
    missedQuarters: [],
  };
  public loadingOnContinue = false;
  private redemptionQuarters = 'redemption-quarters';
  public hasDownloadedForm8974 = false;
  public errorGeneratingDocuments = false;
  public errorHandingLedgerTransactions = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public async checkFilingStatus(tier: PayrollTierEnum) {
    const res = await this.api.GetFilingStatus(tier);
    const companyId = this.rootStore.common.companyStore.currentCompany.id;

    const program =
      this.rootStore.taxcredits.form8974.mostRecentFinishedProgram;

    if (res.errorMsg) {
      datadogLogs.logger.error(
        `Error checking filing status for company: ${companyId}`,
        {
          companyId,
          errorMsg: res.errorMsg,
        },
      );
    }

    if (res.data) {
      const filingStatus = res.data.filingStatus;
      const isAllPayrollProviderSetupCompleted8974True =
        this.rootStore.taxcredits.form8974.programs.every(
          (p) => p.payrollProviderSetupCompleted8974 === true,
        );

      runInAction(() => {
        this.filingStatus = filingStatus;
        if (
          (this.filingStatus === CarryoverAndAdjustStatusEnum.MISSED_FILING ||
            this.filingStatus ===
              CarryoverAndAdjustStatusEnum.PROJECTED_MISSED_FILING) &&
          // check if all payroll setup is completed
          !isAllPayrollProviderSetupCompleted8974True
        ) {
          this.showMissedDeadlineAlert = true;
        } else {
          this.showMissedDeadlineAlert = false;
        }
      });

      // update payrollProviderSetupCompleted8974 to false to allow redemption, if company has redeemed in the past and downloaded previous quarter Form 8974
      // checking if payrollProviderSetupCompleted8974 is true
      if (program && program.payrollProviderSetupCompleted8974) {
        if (
          this.filingStatus === CarryoverAndAdjustStatusEnum.AVAILABLE ||
          this.filingStatus === CarryoverAndAdjustStatusEnum.MISSED_FILING
        ) {
          // update component state payrollProviderSetupCompleted8974 to be false
          runInAction(() => {
            program.payrollProviderSetupCompleted8974 = false;
          });

          const res = await this.client.UpdateProgram(program.id, {
            payrollProviderSetupCompleted8974: false,
            stage: program.stage,
            subStage: program.subStage ?? ProgramSubStageEnum.REDEEMING,
          });

          if (res.errorMsg) {
            datadogLogs.logger.error(
              `Error updating program: ${program.id} - payrollProviderSetupCompleted8974`,
              {
                companyId,
                errorMsg: res.errorMsg,
              },
            );
          }
        }
      }
    }
  }

  public async updateTaxFilingDates(
    programs: ProgramData[],
    filingDates: Map<number, string>,
  ) {
    runInAction(() => (this.isUpdatingFilingDate = true));
    const companyId = this.rootStore.common.companyStore.currentCompany.id;
    const taxIdEIN = this.rootStore.taxcredits.form8974.updatedCompanyTaxId;

    if (taxIdEIN) {
      await this.rootStore.taxcredits.form8974.updateCompanyTaxInfo(taxIdEIN);
    }

    await Promise.all(
      programs.map(async (p) => {
        const programId = p.id;
        const taxYear = p.taxYear;
        const getFilingDate = filingDates.get(taxYear);
        const amountCents = p.creditAmountCents;

        // future proofing when state programs are active
        const statePrograms = [
          ProgramNameEnum.STATE_RD_CA,
          ProgramNameEnum.STATE_RD_AZ,
          ProgramNameEnum.STATE_RD_GA,
          ProgramNameEnum.STATE_RD_MA,
        ];

        const hasStateProgram = this.company.programs.filter(
          (p) => statePrograms.includes(p.name) && p.taxYear === taxYear,
        );

        if (getFilingDate) {
          const taxFilingDate = new Date(getFilingDate);

          // update state programs when fed filing date is updated
          if (hasStateProgram) {
            for (const stateProgram of hasStateProgram) {
              const res = await this.client.UpdateProgramTaxFilingDate({
                programId: stateProgram.id,
                taxFilingDate,
              });

              if (res.errorMsg) {
                datadogLogs.logger.error(
                  `Error updating program: ${stateProgram.name} - ${stateProgram.id} - taxFilingDate`,
                  {
                    companyId,
                    errorMsg: res.errorMsg,
                  },
                );
              }
            }
          }

          await this.updateProgramLedgerTxnValues(
            companyId,
            programId,
            taxFilingDate,
            amountCents,
          );

          if (!this.errorHandingLedgerTransactions) {
            const res = await this.client.UpdateProgramTaxFilingDate({
              programId,
              taxFilingDate,
            });

            if (res.errorMsg) {
              datadogLogs.logger.error(
                `Error updating program: ${p.name} -${p.id} - taxFilingDate`,
                {
                  companyId,
                  errorMsg: res.errorMsg,
                },
              );
            } else {
              // update all client_review program to finished and ready_to_redeem
              await this.rootStore.taxcredits.form8974.updateProgramStage(
                programId,
                ProgramStageEnum.FINISHED,
                ProgramSubStageEnum.REDEEMING,
              );
            }
          }
        }
      }),
    ).then(async () => {
      if (!this.errorHandingLedgerTransactions) {
        const filingQuarterRes =
          await this.client.GetCarryoverAndAdjustForQuarter(companyId);

        if (filingQuarterRes.errorMsg) {
          datadogLogs.logger.error(
            `Error requesting missed filing quarters: ${companyId} - active-carryover`,
            {
              companyId,
              errorMsg: filingQuarterRes.errorMsg,
            },
          );
        } else {
          runInAction(() => {
            // add current or missing filing to Redemption store
            this.currentFiling = filingQuarterRes.data?.currentQuarter;
            this.missedFilings = filingQuarterRes.data?.missedFilings;

            if (filingQuarterRes.data?.status) {
              if (
                filingQuarterRes.data?.status ===
                  CarryoverAndAdjustStatusEnum.MISSED_FILING ||
                filingQuarterRes.data?.status ===
                  CarryoverAndAdjustStatusEnum.PROJECTED_MISSED_FILING
              ) {
                this.filingStatus = filingQuarterRes.data?.status;
                this.setShowMissedQuarterDeadlineModal(true);
              } else {
                this.filingStatus = filingQuarterRes.data?.status;
                this.setShowOnTimeToRedeemYourCreditModal(true);
              }
            }

            if (filingQuarterRes.data?.missedFilings?.length === 0) {
              runInAction(() => (this.errorHandingLedgerTransactions = true));
            }

            this.setShowVerifyFilingDateModal(false);
          });
        }
      } else {
        runInAction(() => (this.isUpdatingFilingDate = false));
      }
    });
  }

  private async updateProgramLedgerTxnValues(
    companyId: number,
    programId: number,
    taxFilingDate: Date,
    amountCents: number,
  ) {
    // convert date to match ProgramLedgerTxn.occurred format
    const updatedFilingDateISODateString = ISODateStringFromDate(taxFilingDate);
    const programLedgerUpdateReq: ProgramLedgerTxnUpdateRequest = {
      occurred: updatedFilingDateISODateString,
    };
    const res = await this.api.UpdateProgramLedgerTxn(
      programId,
      programLedgerUpdateReq,
    );

    if (res.errorMsg) {
      if (res.errorMsg === 'Transaction id is not valid.') {
        // fund the program ledger if row does not exist
        await this.addProgramLedgerTxn(
          companyId,
          programId,
          amountCents,
          updatedFilingDateISODateString,
        );
      } else {
        datadogLogs.logger.error(
          `Error updating program ledger fund entry: ${programId} - occurred`,
          {
            companyId,
            errorMsg: res.errorMsg,
          },
        );

        // handing error states with ledger transactions
        runInAction(() => (this.errorHandingLedgerTransactions = true));
      }
    }
  }

  private async addProgramLedgerTxn(
    companyId: number,
    programId: number,
    amountCents: number,
    occurred: string,
  ) {
    const addProgramLedgerTxnRequest: AddProgramLedgerTxnRequest = {
      companyId,
      programId,
      amountCents,
      occurred,
    };

    const res = await this.api.AddProgramLedgerTxn(
      programId,
      addProgramLedgerTxnRequest,
    );

    if (res.errorMsg) {
      datadogLogs.logger.error(
        `Error adding program ledger fund entry: ${programId} - occurred`,
        {
          companyId,
          errorMsg: res.errorMsg,
        },
      );

      // handing error states with ledger transactions
      runInAction(() => (this.errorHandingLedgerTransactions = true));
    }
  }

  public handleInputValueChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    taxYear: number,
    quarter: Quarters,
    identifier: TaxInputValueEnum,
    isCurrentQuarter?: boolean,
  ) {
    const amount = Number(e.target.value.replace(/,/g, ''));
    const amountToCents = amount * 100;

    let taxYearData = this.quarterlyPayrollTaxesForm941[taxYear];

    runInAction(() => {
      if (!taxYearData) {
        taxYearData = {};
        this.quarterlyPayrollTaxesForm941[taxYear] = taxYearData;
      }

      let quarterData = taxYearData[quarter];

      if (!quarterData) {
        quarterData = {};
        taxYearData[quarter] = quarterData;
      }

      if (!quarterData.hasOwnProperty.call(quarterData, identifier)) {
        quarterData[identifier] = amountToCents;
      } else if (quarterData) {
        quarterData[
          identifier === TaxInputValueEnum.SOCIAL_SECURITY
            ? TaxInputValueEnum.SOCIAL_SECURITY
            : TaxInputValueEnum.MEDICARE
        ] = amountToCents;
      }

      if (isCurrentQuarter) {
        quarterData.current = true;
      } else {
        quarterData.current = false;
      }
    });

    runInAction(() => {
      this.quarterlyPayrollTaxesForm941[taxYear] = taxYearData;
    });
  }

  public async handleEnterTaxValuesOnContinue(programId: number) {
    const currentCompany = this.rootStore.common.companyStore.currentCompany;
    const res = await this.api.EnterTaxValuesForMissedDeadline(
      this.quarterlyPayrollTaxesForm941,
    );
    const quarterlyDocuments: QuarterlyForm8974 = {
      currentQuarter: [],
      missedQuarters: [],
    };

    if (res.errorMsg) {
      runInAction(() => (this.errorGeneratingDocuments = true));
      datadogLogs.logger.error(
        `Error generating missing quarters Form 8974 document urls: /submit-missed-filings`,
        {
          companyId: currentCompany.id,
          errorMsg: res.errorMsg,
        },
      );

      return;
    }

    if (res.data && res.data?.quarters) {
      const quarterResponse = res.data?.quarters;
      const currentQuarter = quarterResponse.currentQuarter;
      const missedQuarters = quarterResponse.missedQuarters;

      if (currentQuarter) {
        const taxYear = currentQuarter.reportingQuarter.year;
        const quarter = currentQuarter.reportingQuarter.quarter;

        const dataStructure: QuarterlyForm8974DocumentUrl = {
          year: taxYear,
          quarter,
          form8974: currentQuarter.documentUrls.form8974,
          form941x: currentQuarter.documentUrls.form941x,
        };

        quarterlyDocuments.currentQuarter.push(dataStructure);
      }

      missedQuarters.forEach((doc) => {
        const taxYear = doc.reportingQuarter.year;
        const quarter = doc.reportingQuarter.quarter;

        const dataStructure: QuarterlyForm8974DocumentUrl = {
          year: taxYear,
          quarter,
          form8974: doc.documentUrls.form8974,
          form941x: doc.documentUrls.form941x,
        };

        quarterlyDocuments.missedQuarters.push(dataStructure);
      });

      // storing in localStorage to resolve /download page on refresh
      const localStorageData: MissedRedemptionLocalStorageData = {
        redemptionQuarters: quarterlyDocuments,
        created: new Date(),
        id: currentCompany.id,
      };

      if (localStorage) {
        localStorage.setItem(
          this.redemptionQuarters,
          JSON.stringify(localStorageData),
        );
      }

      const downloadPageUrl = `/${Page.taxCredits}/${programId}/${Page.missedQuarterlyRedemptionPage}/${Page.quarterlyRedemptionDownloadPage}`;

      runInAction(() => {
        this.quarterlyFor8974DocumentUrl = quarterlyDocuments;
        this.setLoadingOnContinue(false);
        this.app.history.push(downloadPageUrl);
      });
    }
  }

  // check if document download url exist in localStorage
  public checkExistingDocumentURL() {
    const currentCompany = this.rootStore.common.companyStore.currentCompany;

    const localDocumentData = localStorage.getItem(this.redemptionQuarters);

    if (localDocumentData) {
      const parseStorageData = JSON.parse(
        localDocumentData,
      ) as MissedRedemptionLocalStorageData;

      // Possible this key doesn't exist as localStorage isn't actually typed
      const created = parseStorageData.created;

      // check if localStorage documents are older than one quarter
      const localDocumentsOlderThanOneQuarter = created
        ? this.checkIfDateIsOlderThanOneQuarter(parseStorageData.created)
        : null;

      if (parseStorageData.id === currentCompany.id) {
        if (localDocumentsOlderThanOneQuarter) {
          // remove the localStorage data if documents were
          // generated more than one quarter ago
          localStorage.removeItem(this.redemptionQuarters);
        } else {
          runInAction(() => {
            this.quarterlyFor8974DocumentUrl =
              parseStorageData.redemptionQuarters;
          });
        }
      }
    }
  }

  private checkIfDateIsOlderThanOneQuarter(date: Date): boolean {
    const currentDate = new Date();
    const oneQuarterAgo = new Date();

    oneQuarterAgo.setMonth(currentDate.getMonth() - 3);

    return new Date(date).toISOString() < oneQuarterAgo.toISOString();
  }

  /// set show functions
  public setShowVerifyFilingDateModal(show: boolean) {
    runInAction(() => {
      this.showVerifyFilingDateModal = show;
    });
  }

  public setShowFilingDateModal(show: boolean) {
    runInAction(() => {
      this.showFilingDateModal = show;
    });
  }

  public setShowMissedQuarterDeadlineModal(show: boolean) {
    runInAction(() => {
      this.showMissedQuarterDeadlineModal = show;
    });
  }

  public setShowOnTimeToRedeemYourCreditModal(show: boolean) {
    runInAction(() => {
      this.showOnTimeToRedeemYourCreditModal = show;
    });
  }

  public setLoadingOnContinue(show: boolean) {
    runInAction(() => (this.loadingOnContinue = show));
  }

  public setHasDownloadedForm8974(confirmed: boolean) {
    runInAction(() => (this.hasDownloadedForm8974 = confirmed));
  }

  public async confirmedDownloadOnContinue() {
    runInAction(() => this.setLoadingOnContinue(true));
    const currentCompany = this.rootStore.common.companyStore.currentCompany;
    const programs =
      this.rootStore.taxcredits.form8974.programs.length !== 0
        ? this.rootStore.taxcredits.form8974.programs
        : currentCompany.programs;

    const hasFinishedPrograms = programs.filter(
      (p) =>
        p.stage === ProgramStageEnum.FINISHED &&
        p.payrollProviderSetupCompleted8974 === false,
    );

    // update all active programs to redeeming and payrollProviderSetupCompleted8974: true
    if (hasFinishedPrograms.length > 0) {
      hasFinishedPrograms.forEach(async (p) => {
        const programId = p.id;

        if (programId) {
          const res = await this.client.UpdateProgram(programId, {
            payrollProviderSetupCompleted8974: true,
            stage: ProgramStageEnum.FINISHED,
            subStage: ProgramSubStageEnum.REDEEMING,
          });

          if (res.errorMsg) {
            datadogLogs.logger.error(
              `Error updating program: ${programId} - payrollProviderSetupCompleted8974`,
              {
                companyId: currentCompany.id,
                errorMsg: res.errorMsg,
              },
            );
          }
        }
      });
    }

    // on continue , user has checked that they confirmed
    // they have downloaded the 8974 forms, removing documents from localStorage
    const localDocumentData = localStorage.getItem(this.redemptionQuarters);

    if (localDocumentData) {
      localStorage.removeItem(this.redemptionQuarters);
    }

    // making refresh call to be sure UpdateProgram has latest in app
    await this.rootStore.common.companyStore.refreshCurrentCompany();
    await this.rootStore.taxcredits.form8974.refreshPrograms();

    runInAction(() => {
      this.app.history.push(`/${Page.taxCredits}`);
      this.setLoadingOnContinue(false);
    });
  }

  public async onTimeToRedeemContinueUpdates() {
    runInAction(() => {
      this.setLoadingOnContinue(true);
    });

    await this.updateProgramsToCompletePayrollSetup().then(async () => {
      // making refresh call to be sure UpdateProgram has latest in app
      await this.rootStore.taxcredits.form8974.refreshPrograms();
      await this.rootStore.taxcredits.form8974.getRDCreditSummary();

      runInAction(() => {
        this.setLoadingOnContinue(false);
        this.showMissedDeadlineAlert = false;
        this.setShowOnTimeToRedeemYourCreditModal(false);
      });
    });
  }

  public async updateProgramsToCompletePayrollSetup() {
    const companyId = this.rootStore.common.companyStore.currentCompany.id;
    const programs = this.rootStore.taxcredits.form8974.programs;
    const hasFinishedPrograms = programs.filter(
      (p) =>
        p.stage === ProgramStageEnum.FINISHED &&
        p.payrollProviderSetupCompleted8974 === false,
    );

    if (hasFinishedPrograms.length > 0) {
      hasFinishedPrograms.forEach(async (p) => {
        const programId = p.id;
        if (programId) {
          const res = await this.client.UpdateProgram(programId, {
            payrollProviderSetupCompleted8974: true,
            stage: ProgramStageEnum.FINISHED,
            subStage: ProgramSubStageEnum.REDEEMING,
          });

          if (res.errorMsg) {
            datadogLogs.logger.error(
              `Error updating program: ${programId} - payrollProviderSetupCompleted8974`,
              {
                companyId,
                errorMsg: res.errorMsg,
              },
            );
          }
        }
      });

      runInAction(() => {
        this.isUpdatingFilingDate = false;
      });
    }
  }
}
