import React from 'react';
import { Text, Card, Content, Tooltip } from 'component-library';
import {
  makeStyles,
  withStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { QuestionProps, QuestionOptionProps } from 'lib/interfaces';

const useStyles = makeStyles(() => ({
  column: {
    flexDirection: 'column',
    marginTop: 30,
  },
  row: {
    flexDirection: 'row',
    marginTop: 20,
  },
  errorMessage: {
    fontSize: '14px',
    color: '#E25950',
  },
  toolTip: {
    display: 'inline-flex',
    verticalAlign: 'text-bottom',
  },
}));

const StyledRadioGroup = withStyles(() => ({
  root: {
    width: '100%',
    marginRight: 40,
    textTransform: 'none',
  },
}))(RadioGroup);

const StyledToggleButtonGroup = withStyles(() => ({
  grouped: {
    width: 100,
    marginTop: 30,
    marginRight: 16,
    textTransform: 'none',
  },
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles((theme) => ({
  root: {
    width: '112px',
    height: '48px',
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 500,
    '&:not(:first-child)': {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 0,
      color: `${theme.palette.secondary.main}`,
    },
    '&:first-child': {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 0,
      color: `${theme.palette.secondary.main}`,
    },
    '&:hover': {
      backgroundColor: '#CFD4D7',
    },
  },
  selected: {
    border: 'none',
    color: 'white!important',
    backgroundColor: '#102736!important',
  },
}))(ToggleButton);

interface Props {
  question: QuestionProps;
  optionStyle?: 'default' | 'default-row' | 'button';
  optionDirection?: 'column' | 'row';
  withCard?: boolean;
  className?: string;
  errorMessage?: string | null;
  onChange: (event: any, value?: any) => void;
}

export const RadioQuestion = ({
  question,
  optionStyle = 'default',
  optionDirection = 'column',
  withCard,
  className,
  errorMessage,
  onChange,
}: Props) => {
  const classes = useStyles();
  const renderRadioButton = (option: QuestionOptionProps) => {
    const label = option.label;
    const value = option.value;
    return (
      <FormControlLabel
        key={`radio-${label}-${value}`}
        value={value}
        control={<Radio color='primary' />}
        label={label}
      />
    );
  };

  const renderButton = (option: QuestionOptionProps) => {
    const label = option.label;
    const value = option.value;
    return (
      <StyledToggleButton key={`option-${label}-${value}`} value={value}>
        {label}
      </StyledToggleButton>
    );
  };

  const renderQuestion = () => {
    return (
      <>
        <Text size={18} variant='medium'>
          {question.text}&nbsp;
          {question.tooltip && (
            <Tooltip
              className={classes.toolTip}
              text={question.tooltip}
              position='right'
            >
              <InfoOutlinedIcon />
            </Tooltip>
          )}
        </Text>
        {question.subtitle && <Text>{question.subtitle}</Text>}
        {optionStyle === 'default' && (
          <StyledRadioGroup
            className={classes[optionDirection]}
            value={question.value}
            onChange={onChange}
          >
            {question.options?.map(renderRadioButton)}
          </StyledRadioGroup>
        )}
        {optionStyle === 'button' && (
          <StyledToggleButtonGroup
            exclusive
            value={question.value}
            onChange={onChange}
          >
            {question.options?.map(renderButton)}
          </StyledToggleButtonGroup>
        )}
        {errorMessage && (
          <div className={classes.errorMessage}>{errorMessage}</div>
        )}
      </>
    );
  };

  return (
    <>
      {withCard ? (
        <Card className={className}>
          <Content>{renderQuestion()}</Content>
        </Card>
      ) : (
        <div className={className}>{renderQuestion()}</div>
      )}
    </>
  );
};
