import { Gusto8974SideDrawer } from 'pages/dashboard/fed-rd-program/8974/GustoForm8974SideDrawer';
import React from 'react';
import { ProgramData } from 'lib/interfaces';
import { Form8974SideDrawerTypes } from 'lib/constants';
import { observer } from 'mobx-react';
import { useTaxCreditsStores } from 'stores/useStores';
import { RipplingForm8974SideDrawer } from './RipplingForm8974SideDrawer';
import { datadogLogs } from '@datadog/browser-logs';
import { Tier2Form8974SideDrawer } from './Tier2Form8974SideDrawer';
import { Justworks8974SideDrawer } from './JustworksForm8974SideDrawer';

interface Form8974SideDrawerProps {
  program: ProgramData;
  show: boolean;
  onCancel: () => void;
  onComplete: (programId: number) => void;
}

/**
 * Side drawer component for the customer to take action on their Form 8974.
 * Will render the correct variation of the side drawer based on the payroll provider
 * and tier.
 */
export const Tier1OrTier2Form8974SideDrawer = observer(
  ({ program, show, onCancel, onComplete }: Form8974SideDrawerProps) => {
    const { form8974 } = useTaxCreditsStores();

    switch (form8974.sideDrawerType) {
      case Form8974SideDrawerTypes.TIER1_GUSTO:
        return (
          <Gusto8974SideDrawer
            program={program}
            show={show}
            onCancel={onCancel}
            onComplete={onComplete}
          />
        );
      case Form8974SideDrawerTypes.TIER1_RIPPLING:
        return (
          <RipplingForm8974SideDrawer
            program={program}
            show={show}
            onCancel={onCancel}
            onComplete={onComplete}
          />
        );
      case Form8974SideDrawerTypes.TIER1_JUSTWORKS:
        return (
          <Justworks8974SideDrawer
            program={program}
            show={show}
            onCancel={onCancel}
            onComplete={onComplete}
          />
        );
      case Form8974SideDrawerTypes.TIER2:
        return (
          <Tier2Form8974SideDrawer
            program={program}
            show={show}
            onCancel={onCancel}
            onComplete={onComplete}
          />
        );
      default:
        datadogLogs.logger.warn(
          'Could not determine correct 8974 side drawer to return. Returning an empty fragment',
        );
        return <></>;
    }
  },
);
