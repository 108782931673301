import React from 'react';
import { observer } from 'mobx-react';
import { Button, Color, Flex, Modal, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import {
  useCompany,
  useTaxCreditsStores,
} from '../../../../../stores/useStores';
import { setInviteModalText, setInviteModalTitle } from './util';

const useStyles = makeStyles(() => {
  const MIN_HEIGHT = '350px';

  return {
    inviteeModal: {
      minHeight: MIN_HEIGHT,
    },
    image: {
      maxWidth: 100,
    },
    imageContainer: {
      backgroundColor: `${Color.blue._20}`,
      minHeight: MIN_HEIGHT,
      borderRadius: '8px 0 0 8px',
    },
  };
});

interface InviteeIntroModalProps {
  taxYear: number;
}

export const InviteeIntroModal = observer(
  ({ taxYear }: InviteeIntroModalProps) => {
    const classes = useStyles();
    const { unifiedTaxCredits } = useTaxCreditsStores();
    const { company } = useCompany();

    const handleClose = () => {
      unifiedTaxCredits.setShowInviteeModal(false);
    };

    return (
      <Modal
        showModal={unifiedTaxCredits.showInviteeModal}
        closeToggle={handleClose}
        maxWidth={640}
        rounded
      >
        <>
          <Flex
            gap={8}
            direction='column'
            justifyContent='center'
            alignItems='center'
            padding={32}
            className={classes.inviteeModal}
          >
            <img
              className={classes.image}
              src={`${process.env.PUBLIC_URL}/images/unifiedYEA/spreadsheet.svg`}
            />
            <Text textAlign='center' size={18} variant={'bold'}>
              {setInviteModalTitle(
                `${company.adminName.first} ${company.adminName.last}`,
                location.pathname,
                taxYear,
              )}
            </Text>
            <Text textAlign='center' size={15}>
              {setInviteModalText(company.name, location.pathname, taxYear)}
            </Text>
            <Flex justifyContent='center' padding={[16, 0, 0, 0]}>
              <Button label='Continue' onClick={handleClose} />
            </Flex>
          </Flex>
        </>
      </Modal>
    );
  },
);
