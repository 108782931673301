import * as React from 'react';
import { observer } from 'mobx-react';
import { useCommonStores, useFeatureFlags } from 'stores/useStores';
import { Color, Card, CardHeader, Flex, Text, Toggle } from 'component-library';

export const ChatBotSettings = observer(() => {
  const { chatbot } = useCommonStores();
  const featureFlags = useFeatureFlags();

  if (!featureFlags.showAIChatbotSettings) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader noBgColor title='Chat Support' />
      <Flex
        padding={[16, 24]}
        alignItems='center'
        justifyContent='space-between'
      >
        <Text text='Enable/Disable AI chat assistant' />
        <Toggle
          checked={!!chatbot.chatBotSettingEnabled}
          onChange={() =>
            chatbot.toggleChatBotSetting(!chatbot.chatBotSettingEnabled)
          }
          dataTestId='chatbot-toggle'
        />
      </Flex>
      <Flex padding={[0, 24, 16, 24]}>
        <Text
          color={Color.semantic.$error50}
          text={chatbot.chatBotSettingError}
        />
      </Flex>
    </Card>
  );
});
