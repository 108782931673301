import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Cancelable, handleErrorOrDataCallback } from 'lib/helpers';
import { CompanyContext } from 'pages/CompanyRequired';
import {
  LoadingCreditEstimate,
  ManualPayrollEstimate,
} from 'pages/tax-processing/qualify/credit-estimate';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from '../../../../logging';
import TitleHeader from 'components/TitleHeader';
import { Color } from 'component-library';
import { LoadingStatusEnum } from 'lib/constants';
import { useLegacyClients } from 'stores/useStores';

interface ManualPayrollPageProps {
  qualificationTaxYear: number;
  setLoadingError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ManualPayrollPage = ({
  qualificationTaxYear,
  setLoadingError,
}: ManualPayrollPageProps) => {
  const { client } = useLegacyClients();
  const [isLoading, setIsLoading] = useState(true);
  const { setCompany, company: companyContext } = useContext(CompanyContext);
  // If loading status is errored or in_progress, skip first question
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatusEnum | null>(
    null,
  );
  const startTime = new Date().getTime();
  const loadingInterval = Number(
    process.env.REACT_APP_PAYROLL_LOADING_INTERVAL,
  );
  // 30 second max wait time - the server also has a 3 minute wait time
  // but will have been kicked off before the user reaches this page.
  // If it has not completed yet, something has gone wrong and was not
  // caught and we should unblock the user.
  const MAX_WAIT_TIME_MS = 30 * 1000;

  const onLoadingError = (e: string) => {
    setLoadingError(true);
    if (e) {
      setIsLoading(false);
    }
  };

  const loadCreditEstimate = useCallback(
    () =>
      client
        .CheckCreditEstimateLoaded()
        .then((res) => {
          if (
            res?.data?.loadingStatus &&
            (res.data.loadingStatus === LoadingStatusEnum.ERRORED ||
              res.data.loadingStatus === LoadingStatusEnum.IN_PROGRESS)
          ) {
            setLoadingStatus(res.data.loadingStatus);
          }
          return handleErrorOrDataCallback(
            res,
            ({ resourceLink }) => {
              const currentTime = new Date().getTime();
              const timeWaitingMs = currentTime - startTime;

              if (resourceLink) {
                client.CurrentLoggedInCompany().then((newCompany) => {
                  if (newCompany) {
                    setCompany(newCompany);
                    // Logic saying "make the user wait at least 1 second"
                    const minLoadTimeInMs = 1000;
                    const timeToWait = minLoadTimeInMs - timeWaitingMs;
                    setTimeout(() => setIsLoading(false), timeToWait);
                  }
                });
              } else {
                // If it has been too long, assume something has gone wrong and unblock the user.
                if (timeWaitingMs > MAX_WAIT_TIME_MS) {
                  onLoadingError('Something went wrong.');
                  datadogLogs.logger.error(
                    '[CREDIT_ESTIMATE]: Timed out trying to get payroll data',
                    logContext({ company: companyContext }),
                  );
                  return;
                }

                setTimeout(loadCreditEstimate, loadingInterval);
              }
            },
            onLoadingError,
          );
        })
        .catch((error: Error) => onLoadingError(error.message)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    return Cancelable(client.FinchTokenStatus(), (res) => {
      if (res.errorMsg) {
        datadogLogs.logger.error(
          '[CREDIT_ESTIMATE]: failed to retrieve finch token status',
          logContext({ company: companyContext, error: res.errorMsg }),
        );

        onLoadingError(res.errorMsg);
        return;
      }
      const needsLoading = res.data!.hasToken && !res.data!.hasManualToken;
      if (needsLoading) {
        loadCreditEstimate().catch((error: Error) => {
          datadogLogs.logger.error(
            '[CREDIT_ESTIMATE]: failed to load credit estimate',
            logContext({ company: companyContext, error }),
          );
          onLoadingError(error.message);
        });
      } else {
        const currentTime = new Date().getTime();
        const minLoadTimeInMs = 1000;
        const timeToWait = minLoadTimeInMs - (currentTime - startTime);
        setTimeout(() => {
          setIsLoading(false);
        }, timeToWait);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadCreditEstimate]);

  return (
    <OnboardingCreditEstimateDisplay
      isLoading={isLoading}
      loadingStatus={loadingStatus}
      qualificationTaxYear={qualificationTaxYear}
    />
  );
};
const useStyles = makeStyles(({ breakpoints }) => ({
  '@global': {
    [breakpoints.down('xs')]: {
      '.embeddedServiceHelpButton': {
        display: 'none',
      },
    },
  },
  root: {
    background: Color.neutral.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    boxSizing: 'border-box',
  },
  container: {
    width: '100%',
    maxWidth: '1200px',
    padding: '0 24px',
    boxSizing: 'border-box',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
}));

interface DisplayProps {
  isLoading: boolean;
  qualificationTaxYear: number;
  loadingStatus: string | null;
}

export const OnboardingCreditEstimateDisplay = ({
  isLoading,
  qualificationTaxYear,
  loadingStatus,
}: DisplayProps) => {
  const classes = useStyles();
  const { company } = useContext(CompanyContext);

  return (
    <div className={classes.root}>
      <TitleHeader
        title='Estimate'
        onExitText='Only the answers up to the last completed step has been saved'
      />
      <div className={classes.container}>
        <div className={classes.mainContent}>
          {isLoading ? (
            <LoadingCreditEstimate />
          ) : (
            <span data-testid='cep-manual-payroll-estimate'>
              <ManualPayrollEstimate
                company={company!}
                qualificationTaxYear={qualificationTaxYear}
                loadingStatus={loadingStatus}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManualPayrollPage;
