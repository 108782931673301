import React from 'react';

interface Props {
  className?: string;
}

const Dimond = ({ className }: Props) => {
  return (
    <svg
      className={className || ''}
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0001 4.66675L1.66675 10.5018L10 16.3334L18.3334 10.4983L10.0001 4.66675Z'
        fill='#056037'
      />
    </svg>
  );
};

export default Dimond;
