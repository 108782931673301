import React, { useContext } from 'react';
import {
  Alert,
  Card,
  CardHeader,
  CardFooter,
  Color,
  Content,
  DisplayCreditRow,
  IconEnum,
  Spinner,
  Text,
} from 'component-library';
import { Page, PayrollStatus, ProgramCardData } from 'lib/constants';
import useGetProgramRow from './useGetProgramRow';
import useGetProgramCta from './useGetProgramCta';
import { Tier1OrTier2Form8974SideDrawer } from 'pages/dashboard/fed-rd-program/8974/Tier1OrTier2Form8974SideDrawer';
import { CompanyContext } from '../../CompanyRequired';

import { observer } from 'mobx-react';
import { useTaxCreditsStores, useFeatureFlags } from 'stores/useStores';

interface ProgramBundlesProps {
  bundleName: string;
  programs: ProgramCardData[];
}

const ProgramBundles = observer(
  ({ bundleName, programs }: ProgramBundlesProps) => {
    const { form8974 } = useTaxCreditsStores();
    const featureFlags = useFeatureFlags();
    const { loading, programCards, error } = useGetProgramRow(programs);
    const { programCta } = useGetProgramCta(programs);
    const { company } = useContext(CompanyContext);

    // show alert if no payroll connection
    const noPayrollConnection =
      programCta.filter(
        (item) => item.payrollStatus === PayrollStatus.NOT_CONNECTED,
      ).length > 0;
    // show alert if there's a payroll gap
    const payrollGap =
      programCta.filter((item) => item.payrollStatus === PayrollStatus.GAP)
        .length > 0;

    /**
     * Route them to the /documents page if going through the old experience.
     * If going through the new experience, just open the side drawer.
     */
    if (form8974.shouldShow8974CreditRedemptionsCta) {
      const location = form8974.shouldUseDocumentsPageExperience
        ? `/${Page.companyDocs}`
        : undefined;

      const form8974CTA = {
        label: 'Set up credit redemptions',
        location,
        onClick: location
          ? undefined
          : () => {
              form8974.toggleSideDrawer(true);
            },
      };

      /**
       * If there's an actionable program for 8974, and the customer
       * has a supported payroll provider, we add the action to open
       * the 8974 SideDrawer
       *
       */
      programCta.unshift(form8974CTA);
    }

    const primaryOnClick = programCta[0] && {
      location: programCta[0].location,
      onClick: programCta[0].onClick,
    };

    const secondaryOnClick = programCta[1] && {
      location: programCta[1].location,
      onClick: programCta[1].onClick,
    };

    return (
      <>
        <Card noMargin>
          <CardHeader
            title={bundleName}
            icon={IconEnum.high_yield}
            iconColor={Color.green._70}
          />
          <Content paddingTopBottom={8} paddingLeftRight={0}>
            {programs.length !== 0 ? (
              <>
                {loading ? (
                  <Content flex justifyContent='center' paddingTopBottom={24}>
                    <Spinner size='small' />
                  </Content>
                ) : programCards.length !== 0 ? (
                  <>
                    {programCards.map((program, i) => {
                      return (
                        <DisplayCreditRow
                          key={i}
                          programName={program.display}
                          programStatus={program.status}
                          programStatusLabel={program.label}
                          programYear={program.taxYear}
                          statusMoreInfo={program.info}
                          statusMoreInfoOnClick={
                            program.link
                              ? () => window.open(program.link, '_blank')
                              : undefined
                          }
                        />
                      );
                    })}
                  </>
                ) : (
                  error && (
                    <Content flex paddingTopBottom={8} paddingLeftRight={24}>
                      <Text status='failed' text={error} />
                    </Content>
                  )
                )}
              </>
            ) : (
              <DisplayCreditRow programName="No applicable credits at the moment. We're always searching for new savings alternatives behind the scenes and we'll let you know when we find new or interesting opportunities." />
            )}
          </Content>
          {(noPayrollConnection || payrollGap) && (
            <Alert
              text={
                payrollGap
                  ? "We noticed there's a gap in your payroll data. To get an accurate credit estimate, we need you to fix this gap by answering a few more questions."
                  : "We noticed you don't have a payroll system connected. We need this data to estimate your R&D expenses and credits, so we encourage you to connect it now."
              }
              type='warning'
              variant='in_card'
              inCardBorder='top-bottom'
            />
          )}
          {programCta.length !== 0 && (
            <CardFooter
              noBgColor
              variant='primary'
              primaryCtaLabel={programCta[0] && programCta[0].label}
              primaryOnClick={primaryOnClick}
              secondaryCtaLabel={programCta[1] && programCta[1].label}
              secondaryOnClick={secondaryOnClick}
            />
          )}
        </Card>

        {form8974.actionablePrograms.length > 0 &&
          featureFlags.is8974ClientEnabled &&
          !form8974.shouldUseDocumentsPageExperience && (
            <Tier1OrTier2Form8974SideDrawer
              program={form8974.actionablePrograms[0]}
              show={form8974.sideDrawerOpen}
              onCancel={() => form8974.toggleSideDrawer(false)}
              onComplete={(programId) =>
                form8974.sideDrawerOnComplete(programId)
              }
            />
          )}
      </>
    );
  },
);
export default ProgramBundles;
