import { ClaimBanner } from '../../unified-tax-credits/components';
import { Page } from '../../../../../lib/constants';
import { Alert, Card, Color, Flex, Link, Text } from 'component-library';
import React from 'react';
import { useCommonStores } from '../../../../../stores/useStores';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  title: {
    whiteSpace: 'nowrap',
  },
  timelineBtn: {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
  },
  assessmentContainer: {
    backgroundColor: Color.neutral._10,
    border: `1px solid ${Color.neutral._20}`,
    borderRadius: '4px',
    '& > div': {
      borderTop: `1px solid ${Color.neutral._20}`,
    },
    '& > div:first-child': {
      borderTop: 'none',
    },
  },
  list: {
    margin: 0,
    padding: '0 24px',
  },
}));

export const ErcIneligible = () => {
  const classes = useStyles();
  const { companyStore } = useCommonStores();

  return (
    <>
      <ClaimBanner
        title={`You're not eligible for Employee Retention Credits`}
        subtitle={
          <>
            Based on the information you have provided,{' '}
            {companyStore.currentCompany.legalName} is not eligible for our
            Employee Retention Credits. We search for new credit matches every
            day, and we will reach out as soon as we find one for&nbsp;you!
          </>
        }
      />
      <Card noMargin>
        <Flex direction='column' gap={16} padding={24}>
          <Flex justifyContent='space-between' alignItems='center' wrap='wrap'>
            <Flex
              gap={4}
              alignItems='center'
              justifyContent='flex-start'
              className={classes.title}
              basis={315}
            >
              <Text variant='medium' size={23}>
                Earn new credits
              </Text>
            </Flex>
          </Flex>
          <Text paddingBottom={16}>
            You did not qualify for any Employee Retention Credits, but
            MainStreet is here to help you get tax benefits in the future! Here
            are a few things you can do to earn tax&nbsp;credits.
          </Text>

          <div className={classes.assessmentContainer}>
            <Flex direction='column' gap={8} padding={24}>
              <Text variant='medium' size={18}>
                Verify payroll&nbsp;connection
              </Text>
              <Text>
                Visit the <Link href={`/${Page.connections}`}>Connections</Link>{' '}
                page to confirm an active payroll connection. We review this
                data to determine your eligibility for future&nbsp;credits.
              </Text>
            </Flex>
            <Flex direction='column' gap={8} padding={24}>
              <Text variant='medium' size={18}>
                Offer a qualifying retirement&nbsp;plan
              </Text>
              <Text>
                You could start a 401(k) for your employees, and get up to 100%
                of the costs back as a tax&nbsp;credit!
              </Text>
            </Flex>
            <Flex direction='column' gap={8} padding={24}>
              <Text variant='medium' size={18}>
                Verify payroll increase accessibility to your&nbsp;business
              </Text>
              <Text>
                You could get up to $5,000 in credits back for any money you
                spend to meet ADA guidelines for your customers
                or&nbsp;employees.
              </Text>
            </Flex>
          </div>
          <Alert
            backgroundColor={Color.blue._10}
            text={
              <Flex direction='column'>
                <Text size={15}>
                  To learn more about how you can qualify for tax credits, check
                  out our{' '}
                  <Link
                    href='https://mainstreet1.my.site.com/help/s/'
                    target='_blank'
                  >
                    help center
                  </Link>{' '}
                  or talk to one of our experts.
                </Text>
              </Flex>
            }
            type='info'
            variant='in_card'
          />
        </Flex>
      </Card>
    </>
  );
};
