import React from 'react';

interface Props {
  className?: string;
}

const ClipboardAndCalculator = ({ className }: Props) => (
  <svg
    width='97'
    height='96'
    viewBox='0 0 97 96'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M62.0308 23.0699H16.8008V84.1799H62.0308V23.0699Z'
      fill='#0A190C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.3008 23.0699C16.3008 22.7938 16.5246 22.5699 16.8008 22.5699H62.0308C62.3069 22.5699 62.5308 22.7938 62.5308 23.0699V84.1799C62.5308 84.4561 62.3069 84.6799 62.0308 84.6799H16.8008C16.5246 84.6799 16.3008 84.4561 16.3008 84.1799V23.0699ZM17.3008 23.5699V83.6799H61.5308V23.5699H17.3008Z'
      fill='#0A190C'
    />
    <path d='M68.9214 16.1799H23.6914V77.2899H68.9214V16.1799Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.1914 16.1799C23.1914 15.9038 23.4153 15.6799 23.6914 15.6799H68.9214C69.1975 15.6799 69.4214 15.9038 69.4214 16.1799V77.2899C69.4214 77.5661 69.1975 77.7899 68.9214 77.7899H23.6914C23.4153 77.7899 23.1914 77.5661 23.1914 77.2899V16.1799ZM24.1914 16.6799V76.7899H68.4214V16.6799H24.1914Z'
      fill='#0A190C'
    />
    <path
      d='M65.6 72.5099V19.1399H27V62.4299L37.08 72.5099H65.6Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.5 19.1399C26.5 18.8638 26.7239 18.6399 27 18.6399H65.6C65.8761 18.6399 66.1 18.8638 66.1 19.1399V72.5099C66.1 72.786 65.8761 73.0099 65.6 73.0099H37.08C36.9474 73.0099 36.8202 72.9572 36.7264 72.8634L26.6464 62.7834C26.5527 62.6897 26.5 62.5625 26.5 62.4299V19.1399ZM27.5 19.6399V62.2228L37.2871 72.0099H65.1V19.6399H27.5Z'
      fill='#0A190C'
    />
    <path
      d='M36.4609 71.8898V63.0498H27.6309L36.4609 71.8898Z'
      fill='#ACE119'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.169 62.8583C27.2464 62.6716 27.4287 62.5498 27.6309 62.5498H36.4609C36.737 62.5498 36.9609 62.7737 36.9609 63.0498V71.8898C36.9609 72.0921 36.839 72.2744 36.6521 72.3518C36.4652 72.4292 36.2501 72.3863 36.1071 72.2432L27.2771 63.4032C27.1342 63.2601 27.0916 63.0451 27.169 62.8583ZM28.837 63.5498L35.9609 70.6817V63.5498H28.837Z'
      fill='#0A190C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.3001 34.36H34.3301V32.36H46.3001V34.36Z'
      fill='#0A190C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.3001 44.21H34.3301V42.21H46.3001V44.21Z'
      fill='#0A190C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.3001 54.05H34.3301V52.05H46.3001V54.05Z'
      fill='#0A190C'
    />
    <path d='M57.9504 11.8199H34.6504V20.5599H57.9504V11.8199Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34.1504 11.8199C34.1504 11.5438 34.3742 11.3199 34.6504 11.3199H57.9504C58.2265 11.3199 58.4504 11.5438 58.4504 11.8199V20.5599C58.4504 20.8361 58.2265 21.0599 57.9504 21.0599H34.6504C34.3742 21.0599 34.1504 20.8361 34.1504 20.5599V11.8199ZM35.1504 12.3199V20.0599H57.4504V12.3199H35.1504Z'
      fill='#0A190C'
    />
    <path d='M80.2001 30.48H53.5801V65.3H80.2001V30.48Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M53.0801 30.48C53.0801 30.2038 53.3039 29.98 53.5801 29.98H80.2001C80.4762 29.98 80.7001 30.2038 80.7001 30.48V65.3C80.7001 65.5761 80.4762 65.8 80.2001 65.8H53.5801C53.3039 65.8 53.0801 65.5761 53.0801 65.3V30.48ZM54.0801 30.98V64.8H79.7001V30.98H54.0801Z'
      fill='#0A1A0C'
    />
    <path d='M75.8207 35.11H57.9707V41.49H75.8207V35.11Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.4707 35.11C57.4707 34.8338 57.6946 34.61 57.9707 34.61H75.8207C76.0968 34.61 76.3207 34.8338 76.3207 35.11V41.49C76.3207 41.7661 76.0968 41.99 75.8207 41.99H57.9707C57.6946 41.99 57.4707 41.7661 57.4707 41.49V35.11ZM58.4707 35.61V40.99H75.3207V35.61H58.4707Z'
      fill='#0A1A0C'
    />
    <path d='M75.8193 45.4399H72.2793V48.9799H75.8193V45.4399Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M71.7793 45.4399C71.7793 45.1638 72.0032 44.9399 72.2793 44.9399H75.8193C76.0954 44.9399 76.3193 45.1638 76.3193 45.4399V48.9799C76.3193 49.2561 76.0954 49.4799 75.8193 49.4799H72.2793C72.0032 49.4799 71.7793 49.2561 71.7793 48.9799V45.4399ZM72.7793 45.9399V48.4799H75.3193V45.9399H72.7793Z'
      fill='#0A1A0C'
    />
    <path d='M68.6591 45.4399H65.1191V48.9799H68.6591V45.4399Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M64.6191 45.4399C64.6191 45.1638 64.843 44.9399 65.1191 44.9399H68.6591C68.9353 44.9399 69.1591 45.1638 69.1591 45.4399V48.9799C69.1591 49.2561 68.9353 49.4799 68.6591 49.4799H65.1191C64.843 49.4799 64.6191 49.2561 64.6191 48.9799V45.4399ZM65.6191 45.9399V48.4799H68.1591V45.9399H65.6191Z'
      fill='#0A1A0C'
    />
    <path d='M61.5107 45.4399H57.9707V48.9799H61.5107V45.4399Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.4707 45.4399C57.4707 45.1638 57.6946 44.9399 57.9707 44.9399H61.5107C61.7868 44.9399 62.0107 45.1638 62.0107 45.4399V48.9799C62.0107 49.2561 61.7868 49.4799 61.5107 49.4799H57.9707C57.6946 49.4799 57.4707 49.2561 57.4707 48.9799V45.4399ZM58.4707 45.9399V48.4799H61.0107V45.9399H58.4707Z'
      fill='#0A1A0C'
    />
    <path d='M68.6591 51.73H65.1191V55.27H68.6591V51.73Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M64.6191 51.73C64.6191 51.4538 64.843 51.23 65.1191 51.23H68.6591C68.9353 51.23 69.1591 51.4538 69.1591 51.73V55.27C69.1591 55.5461 68.9353 55.77 68.6591 55.77H65.1191C64.843 55.77 64.6191 55.5461 64.6191 55.27V51.73ZM65.6191 52.23V54.77H68.1591V52.23H65.6191Z'
      fill='#0A1A0C'
    />
    <path d='M61.5107 51.73H57.9707V55.27H61.5107V51.73Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.4707 51.73C57.4707 51.4538 57.6946 51.23 57.9707 51.23H61.5107C61.7868 51.23 62.0107 51.4538 62.0107 51.73V55.27C62.0107 55.5461 61.7868 55.77 61.5107 55.77H57.9707C57.6946 55.77 57.4707 55.5461 57.4707 55.27V51.73ZM58.4707 52.23V54.77H61.0107V52.23H58.4707Z'
      fill='#0A1A0C'
    />
    <path d='M68.6591 58.03H65.1191V61.57H68.6591V58.03Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M64.6191 58.03C64.6191 57.7539 64.843 57.53 65.1191 57.53H68.6591C68.9353 57.53 69.1591 57.7539 69.1591 58.03V61.57C69.1591 61.8462 68.9353 62.07 68.6591 62.07H65.1191C64.843 62.07 64.6191 61.8462 64.6191 61.57V58.03ZM65.6191 58.53V61.07H68.1591V58.53H65.6191Z'
      fill='#0A1A0C'
    />
    <path d='M61.5107 58.03H57.9707V61.57H61.5107V58.03Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.4707 58.03C57.4707 57.7539 57.6946 57.53 57.9707 57.53H61.5107C61.7868 57.53 62.0107 57.7539 62.0107 58.03V61.57C62.0107 61.8462 61.7868 62.07 61.5107 62.07H57.9707C57.6946 62.07 57.4707 61.8462 57.4707 61.57V58.03ZM58.4707 58.53V61.07H61.0107V58.53H58.4707Z'
      fill='#0A1A0C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M71.7598 41.49V35.11H72.7598V41.49H71.7598Z'
      fill='#0A1A0C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.8105 41.49V35.11H70.8105V41.49H69.8105Z'
      fill='#0A1A0C'
    />
    <path d='M75.7998 51.73H72.2598V61.56H75.7998V51.73Z' fill='#ACE119' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M71.7598 51.73C71.7598 51.4538 71.9836 51.23 72.2598 51.23H75.7998C76.0759 51.23 76.2998 51.4538 76.2998 51.73V61.56C76.2998 61.8361 76.0759 62.06 75.7998 62.06H72.2598C71.9836 62.06 71.7598 61.8361 71.7598 61.56V51.73ZM72.7598 52.23V61.06H75.2998V52.23H72.7598Z'
      fill='#0A190C'
    />
  </svg>
);

export default ClipboardAndCalculator;
