import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from 'components/util/Button';

const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    backgroundColor: palette.warning.main,
    color: palette.warning.contrastText,
    padding: '20px',
    fontSize: '14px',
    lineHeight: '1.4em',
  },
  text: {},
  button: {
    fontSize: '14px',
    lineHeight: '1.4em',
    padding: 0,
    fontWeight: 500,
  },
}));

export interface MissingEmployeesContractorsWarningProps {
  openModal: () => void;
}

export const MissingEmployeesWarning = ({
  openModal,
}: MissingEmployeesContractorsWarningProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.text}>
        If any of your employees or contractors are missing, please{' '}
        <Button onClick={openModal} variant={'link'} className={classes.button}>
          upload a file
        </Button>{' '}
        with their details. Once you do this, we&apos;ll review and let you know
        when we&apos;re ready for you to complete the process
      </span>
    </div>
  );
};
