import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Color, Divider } from 'component-library';
import { Card } from '@material-ui/core';

const useStyles = makeStyles<Theme, ConnectCardProps>((theme) => ({
  root: {
    marginTop: ({ storeDropdownSelection }) =>
      storeDropdownSelection ? `32px` : 0,
    width: '100%',
    background: Color.neutral.white,
    boxShadow: Color.shadow.low,
    border: `1px solid ${Color.neutral._20}`,
    [theme.breakpoints.down('xs')]: {
      marginTop: '0',
    },
  },
  header: {
    padding: '32px 32px 24px',
  },
  content: {
    padding: ({ noPadding }) => (noPadding ? 0 : '0 32px 24px'),
  },
}));

export interface ConnectCardProps {
  header?: JSX.Element;
  mainContent?: JSX.Element;
  noPadding?: boolean;
  storeDropdownSelection?: boolean;
}

export const ConnectCard = ({
  header,
  mainContent,
  noPadding,
  storeDropdownSelection,
}: ConnectCardProps) => {
  const classes = useStyles({ noPadding, storeDropdownSelection });

  return (
    <Card className={classes.root} square elevation={0}>
      <div className={classes.header}>{header}</div>
      <Divider variant='middle' />
      <div className={classes.content}>{mainContent}</div>
    </Card>
  );
};
