import React from 'react';
import { Button, Callout, Card, Content, Text } from 'component-library';
import { StepsContainer } from '../components';
import { useFeatureFlags, useRootStore } from 'stores/useStores';
import { observer } from 'mobx-react';

interface ExpertReviewProps {
  pathFrom: string;
  pathTo: string;
  onContinue: (step: number) => void;
}

const ExpertReview = observer(({ pathTo, onContinue }: ExpertReviewProps) => {
  const { history } = useRootStore();
  const featureFlags = useFeatureFlags();

  const submit = () => {
    onContinue(6);
    window.location.href = pathTo;
  };

  return (
    <StepsContainer
      showContinueButton
      onContinue={submit}
      onContinueLabel='Back to dashboard'
      lastStep
      stepTitle='Thanks for submitting your info! '
    >
      <Content flex flexDirection='column' gap={24}>
        <Callout title='Keep an eye on your inbox'>
          <>
            <Text paddingBottom={8}>
              Our experts will review everything and let you know if they need
              more information. We&apos;ll then generate the forms your CPA
              needs to claim your R&D credits when filing your income taxes this
              year.{' '}
            </Text>
            <Text tag='span'>
              <Text tag='span' variant='medium'>
                Note:
              </Text>{' '}
              Wait for these forms to arrive and file them jointly with your
              taxes. These are not independent processes.{' '}
            </Text>
          </>
        </Callout>
        <Card noMargin>
          <Content
            paddingTopBottom={16}
            paddingLeftRight={24}
            flex
            alignItems='center'
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/ec-flow-congratulations.png`}
              alt='Expense Classification Complete'
            />
            <Content flex flexDirection='column'>
              <Text variant='medium' size={18}>
                In the meantime, you need to get prepared! <br />
                <Text
                  tag='span'
                  variant='regular'
                  size={15}
                  text="Because it's about to rain money."
                />
              </Text>
              <Button
                flexAlignSelf='flex-start'
                label='Claim your free umbrella!'
                onClick={{
                  location:
                    'https://app.brilliantmade.com/receive/mainstreet-claim-gift',
                  external: true,
                }}
                small
              />
            </Content>
          </Content>
        </Card>
      </Content>
    </StepsContainer>
  );
});

export default ExpertReview;
