import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { CentsToDisplayString } from 'lib/helpers';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { TransactionStateEnum } from 'lib/constants';
import {
  Card,
  Content,
  Text,
  Color,
  SideDrawerProps,
  SideDrawer,
  Button,
} from 'component-library';
import moment from 'moment';
import {
  ExpectedPaymentDirection,
  ModernTreasuryExpectedPayment,
} from 'lib/interfaces';
import classnames from 'classnames/bind';

const useStyles = makeStyles(() => ({
  transactionsTable: {
    backgroundColor: Color.neutral.white,
    borderSpacing: 0,
    '& thead tr': {
      backgroundColor: '#fcfcf5',
      borderBottom: '3px solid' + Color.neutral._20,
    },
    '& tr': {
      display: 'grid',
      gridTemplateColumns: '1.5fr 2.25fr .75fr 1fr .5fr',
      borderBottom: '1px solid' + Color.neutral._20,
      height: '56px',
    },
    '& tbody td': {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
      padding: '0px 20px',
    },
    '& tbody td:nth-child(n+4), & thead th:nth-child(n+4)': {
      display: 'inherit',
      textAlign: 'right',
    },
  },
  stateCircle: {
    '& div': {
      width: '6px',
      height: '6px',
      margin: '0px 5px 0px 0px',
      borderRadius: '50%',
    },
  },
  completedCircle: {
    background: Color.semantic.$success50,
  },
  pendingCircle: {
    background: Color.neutral._60,
  },
  failedCircle: {
    background: Color.semantic.$error50,
  },
  completedText: {
    color: Color.green._70,
  },
  pendingText: {
    color: Color.neutral._60,
  },
  failedText: {
    color: Color.semantic.$error50,
  },
  sortIcon: {
    width: '7px',
    padding: '0px 3px',
  },
}));

interface PendingTransactionsTableProps {
  pendingTransactions: ModernTreasuryExpectedPayment[];
  handleCancel: (id: string) => void;
}

export const PendingTransactionsTable = ({
  pendingTransactions,
  handleCancel,
}: PendingTransactionsTableProps) => {
  const classes = useStyles();
  const maxTransactionsPerPage = 10;
  const pageNumber = 0;

  const [sideDrawerDetails, setSideDrawerDetails] = useState<SideDrawerProps>({
    show: false,
    title: '',
    drawerActions: <></>,
    drawerActionsBorder: false,
    drawerContent: <></>,
    closeToggle: () => {
      setSideDrawerDetails({ ...sideDrawerDetails, show: false });
    },
  });
  const renderTransactionRow = (
    transaction: ModernTreasuryExpectedPayment,
    index: number,
  ) => {
    const date = moment(transaction.created_at).format('MMM DD, YYYY');
    const desc = transaction.description ?? transaction.statement_descriptor;
    const amount =
      transaction.direction === ExpectedPaymentDirection.CREDIT
        ? transaction.amount_upper_bound
        : 0 - transaction.amount_upper_bound;
    const amountString = CentsToDisplayString(amount, 2, 2);
    //class selector for circle color
    const stateClassName = classnames(classes.pendingCircle);
    //class selector for text color
    const stateTextClassName = classnames(classes.pendingText);
    const currentTransaction = pendingTransactions[index];

    return (
      <tr key={currentTransaction.id}>
        <td>{date}</td>
        <td>{desc}</td>
        <td className={classes.stateCircle}>
          <div className={stateClassName} />
          <span className={stateTextClassName}>
            {TransactionStateEnum[TransactionStateEnum.Pending]}
          </span>
        </td>
        <td>{amountString}</td>
        <td>
          <IconButton
            aria-label='more'
            onClick={() => {
              setSideDrawerDetails({
                ...sideDrawerDetails,
                show: true,
                title:
                  currentTransaction.direction ===
                  ExpectedPaymentDirection.DEBIT
                    ? 'Transfer out'
                    : 'Transfer in', // direction === credit
                drawerActions: (
                  <Content flex>
                    <Button
                      label='Close'
                      small={true}
                      onClick={() => {
                        setSideDrawerDetails({
                          ...sideDrawerDetails,
                          show: false,
                        });
                      }}
                    />
                    <Button
                      label='Cancel transfer'
                      small={true}
                      variant='tertiary'
                      onClick={async () => {
                        setSideDrawerDetails({
                          ...sideDrawerDetails,
                          show: false,
                        });
                        await handleCancel(currentTransaction.id);
                      }}
                    />
                  </Content>
                ),
                drawerContent: (
                  <Content>
                    <Content paddingLeftRight={24} paddingTop={24}>
                      <Text
                        text='Pending Transfer'
                        variant='medium'
                        size={15}
                      />
                      <Card>
                        <Content
                          flex
                          justifyContent='space-between'
                          paddingTop={0}
                        >
                          <Text text='Transfer amount' />
                          <Text
                            text={CentsToDisplayString(amount, 2, 2)}
                            variant='medium'
                          />
                        </Content>
                      </Card>
                      <Text
                        color={Color.neutral._80}
                        size={15}
                        text='MainStreet is reaching out to you directly via email to collect bank details before transferring any funds, if this has already happened your funds are on the way.'
                      />
                    </Content>
                  </Content>
                ),
                alertMessage: undefined,
              });
            }}
            aria-haspopup='true'
            aria-controls='long-menu'
          >
            <MoreVertIcon />
          </IconButton>
        </td>
      </tr>
    );
  };

  return (
    <Card>
      <table className={classes.transactionsTable}>
        <tbody>
          {pendingTransactions.length > 0 &&
            pendingTransactions
              .slice(
                pageNumber * maxTransactionsPerPage,
                (pageNumber + 1) * maxTransactionsPerPage,
              )
              .map(renderTransactionRow)}
        </tbody>
      </table>
      <SideDrawer
        show={sideDrawerDetails.show}
        title={sideDrawerDetails.title}
        closeToggle={() =>
          setSideDrawerDetails({ ...sideDrawerDetails, show: false })
        }
        drawerActions={sideDrawerDetails.drawerActions}
        drawerActionsBorder={sideDrawerDetails.drawerActionsBorder}
        mainContentPadding={false}
        drawerContent={sideDrawerDetails.drawerContent}
        alertMessage={sideDrawerDetails.alertMessage}
        alertMessagePosition='bottom'
      />
    </Card>
  );
};
