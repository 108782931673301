import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { animated } from 'react-spring';
import {
  Animate,
  Card,
  Content,
  Color,
  GradientBackground,
  Heading,
  Stepper,
} from 'component-library';
import { AccountCreationSteps } from 'lib/constants';
import { observer } from 'mobx-react';
import { useAccountCreationStores, useCommonStores } from 'stores/useStores';
import { useSpringPageIndexTransition } from 'lib/useSpringPageTransition';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  stepper: {
    width: '400px',
    [breakpoints.down('md')]: {
      width: '380px',
    },
    [breakpoints.down('sm')]: {
      padding: '0px 0px 20px 0px',
      width: '340px',
    },
    [breakpoints.down('xs')]: {
      width: '320px',
    },
  },
  stepContainer: {
    minHeight: 734,
    flexDirection: 'row',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      overflowY: 'auto',
    },
  },
  stepTitle: {
    flex: '0 0 512px',
    boxSizing: 'border-box',
    textAlign: 'center',
    overflow: 'hidden',
    position: 'relative',
    [breakpoints.down('md')]: {
      flex: '0 0 480px',
    },
    [breakpoints.down('sm')]: {
      flex: 'revert',
      overflow: 'initial',
    },
  },
  stepTitleContainer: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      width: '100%',
      padding: '24px',
      boxSizing: 'border-box',
      justifyContent: 'center',
    },
  },
  stepTitleContent: {
    position: 'relative',
    zIndex: 1,
  },
  stepContent: {
    flex: 1,
    [breakpoints.down('sm')]: {
      padding: '40px 24px !important',
    },
  },
  children: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    '& > div': {
      width: '100%',
      height: '100%',
    },
  },
  titleImage: {
    height: 402,
    width: '100%',
  },
  link: {
    display: 'inline-block',
    textDecoration: 'none',
  },
}));

interface AccountStepContainerProps {
  children: React.ReactNode | JSX.Element;
}

export const AccountStepContainer = observer(
  ({ children }: AccountStepContainerProps) => {
    const classes = useStyles();
    const {
      app: { isMobile },
    } = useCommonStores();
    const { accountCreation } = useAccountCreationStores();
    const { transitions } = useSpringPageIndexTransition(
      accountCreation.currentStep,
      accountCreation.previousStep,
      true, // useFade
    );

    const titleSwitch = [
      {
        title: 'your business.',
        color: Color.blue._60,
        gradient: Color.gradient._3,
        image: `${process.env.PUBLIC_URL}/images/account-creation/business-illustration.png`,
      },
      {
        title: 'your contact details.',
        color: Color.purple._70,
        gradient: Color.monotone.purple_simple,
        image: `${process.env.PUBLIC_URL}/images/account-creation/contacts-illustration.png`,
      },
      {
        title: 'your taxes.',
        color: Color.blue._60,
        gradient: Color.monotone.blue_simple,
        image: `${process.env.PUBLIC_URL}/images/account-creation/taxes-illustration.png`,
      },
    ];

    return (
      <Card
        noMargin
        bgColor={Color.neutral.white}
        className={classes.stepContainer}
      >
        <Content
          flex
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          className={classes.stepTitle}
          paddingTopBottom={0}
          paddingLeftRight={0}
        >
          {transitions((props, item) => {
            const index = item - 1;
            return (
              <animated.div
                style={props}
                className={classes.stepTitleContainer}
              >
                <Content className={classes.stepTitleContent}>
                  <Heading tag='h3' variant='medium'>
                    Tell us about <br />
                    <Heading
                      tag='span'
                      variant='medium'
                      text={titleSwitch[index].title}
                      color={titleSwitch[index].color}
                    />
                  </Heading>
                  {!isMobile && (
                    <Animate enter={['fade-in', 'from-bottom']} duration={0.5}>
                      <div className={classes.titleImage}>
                        <img
                          src={titleSwitch[index].image}
                          alt={`Tell us about ${titleSwitch[index].title}`}
                        />
                      </div>
                    </Animate>
                  )}
                </Content>
                <GradientBackground
                  gradient={titleSwitch[index].gradient}
                  gradientOpacity={0.3}
                />
              </animated.div>
            );
          })}
        </Content>
        <Content
          flex
          flexDirection='column'
          alignItems='center'
          paddingTopBottom={40}
          paddingLeftRight={48}
          className={classes.stepContent}
        >
          <Animate enter={['fade-in', 'from-top']} delay={0.75}>
            <Stepper
              className={classes.stepper}
              steps={AccountCreationSteps}
              currentStep={accountCreation.currentStep}
            />
          </Animate>
          <div className={classes.children}>
            <Content
              flex
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              className={classes.contentContainer}
            >
              {children}
            </Content>
          </div>
        </Content>
      </Card>
    );
  },
);
