import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ pageBackgroundColor }: Theme) => ({
  container: {
    backgroundColor: pageBackgroundColor,
    width: '100%',
    minHeight: '100vh',
  },
  content: {
    maxWidth: '1280px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '80px 40px 60px',
  },
  logo: {
    paddingBottom: '40px',
    zIndex: 1,
  },
}));

interface ContainerProps {
  children: React.ReactNode;
}

export const AccountantDocumentsContainer = ({ children }: ContainerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.logo}>
          <img
            src={`${process.env.PUBLIC_URL}/images/logo/mst-logo-green.svg`}
            alt='MainStreet watermark'
            width='177px'
          />
        </div>
        {children}
      </div>
    </div>
  );
};
