import React, { useState } from 'react';
import { Color, Flex, Grid, Spinner, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useCommonStores, useFeatureFlags } from 'stores/useStores';
import TalkToAnExpertButton from 'components/util/TalkToAnExpertButton';
import { ProgramNameEnum, ProgramStageEnum } from 'lib/constants';
import { useEffectOnce } from '../../../../../lib/helpers';
import { TaxCreditsAPI } from '../../../TaxCreditsAPI';
import { ErcComingSoon } from './ErcComingSoon';
import { ErcAssessmentPage } from './ErcAssessmentPage';
import { ErcMsReviewPage } from './ErcMsReviewPage';
import { ErcIneligible } from './ErcIneligible';

const useStyles = makeStyles(() => ({
  root: {
    padding: '40px 40px 80px',
  },
  gridContainer: {
    maxWidth: '1024px',
    width: '100%',
  },
  assessmentContainer: {
    backgroundColor: Color.neutral._10,
    border: `1px solid ${Color.neutral._20}`,
    borderRadius: '4px',
    '& > div': {
      borderTop: `1px solid ${Color.neutral._20}`,
    },
    '& > div:first-child': {
      borderTop: 'none',
    },
  },
  list: {
    margin: 0,
    padding: '0 24px',
  },
  title: {
    whiteSpace: 'nowrap',
  },
  spinner: {
    margin: '0 auto',
  },
}));

export const ERCLandingPage = observer(() => {
  const classes = useStyles();
  const featureFlags = useFeatureFlags();
  const { companyStore } = useCommonStores();
  const [ercEstimate, setErcEstimate] = useState<number | null>(null);
  const [ercEstimatesLoading, setErcEstimatesLoading] = useState<boolean>(true);

  const ercPrograms = companyStore.currentCompany.programs.filter((program) => {
    return (
      program.name === ProgramNameEnum.ERC &&
      program.stage !== ProgramStageEnum.DISQUALIFIED &&
      program.stage !== ProgramStageEnum.CANCELED
    );
  });

  const allErcProgramsDisqualified =
    ercPrograms.filter(
      (program) => program.stage === ProgramStageEnum.DISQUALIFIED,
    ).length === ercPrograms.length;
  const allErcProgramsInReview =
    ercPrograms.filter(
      (program) => program.stage === ProgramStageEnum.MS_REVIEW,
    ).length === ercPrograms.length;

  useEffectOnce(async () => {
    // Sum the estimate overrides
    const ercProgramOverrideCents = ercPrograms.reduce((sum, program) => {
      return (sum += program.estimatedTotalCreditCentsOverride || 0);
    }, 0);

    // Get the persisted credit estimates and sum them
    const api = new TaxCreditsAPI();
    const ercCreditEstimates = await Promise.all(
      ercPrograms.map((program) => {
        return api.GetLatestCreditEstimate(program.id);
      }),
    );

    const ercCreditEstimateCents = ercCreditEstimates.reduce(
      (sum, estimate) => {
        return (sum += estimate.data?.creditEstimate?.estimateCents || 0);
      },
      0,
    );

    // Prefer the persisted credit estimates over the overrides
    setErcEstimate(ercCreditEstimateCents || ercProgramOverrideCents);
    setErcEstimatesLoading(false);
  });

  return (
    <div className={classes.root} data-testid={'erc-landing-page'}>
      <Grid columns={12} className={classes.gridContainer} gap={40}>
        <Grid.Cell columns={12}>
          <Flex justifyContent='space-between' alignItems='center' gap={24}>
            <Text size={40} variant='medium'>
              Employee Retention Credits
            </Text>
            {featureFlags.showTalkToAnExpertButton && <TalkToAnExpertButton />}
          </Flex>
        </Grid.Cell>
        <Grid.Cell columns={8}>
          <Flex direction='column' gap={32}>
            {ercEstimatesLoading ? (
              <Spinner className={classes.spinner} />
            ) : (
              <>
                {featureFlags.showERCQualification ? (
                  <>
                    {allErcProgramsDisqualified && <ErcIneligible />}
                    {allErcProgramsInReview && (
                      <>
                        <ErcMsReviewPage
                          classes={classes}
                          company={companyStore.currentCompany}
                          ercEstimate={ercEstimate}
                        />
                      </>
                    )}
                    {!allErcProgramsDisqualified && !allErcProgramsInReview && (
                      <>
                        <ErcAssessmentPage
                          classes={classes}
                          ercEstimate={ercEstimate}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <ErcComingSoon
                    classes={classes}
                    company={companyStore.currentCompany}
                  />
                )}
              </>
            )}
          </Flex>
        </Grid.Cell>
      </Grid>
    </div>
  );
});
