import { Button } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'lib/copyToClipboard';
import { useCommonStores } from 'stores/useStores';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  copyButton: {
    padding: '12px 30px',
    textTransform: 'initial',
    fontWeight: 500,
    fontSize: '16px',
  },
}));

interface CopyToClipboardButtonProps {
  textToCopy: string;
  onTextCopied: () => void;
}

export const CopyToClipboardButton = observer(
  ({ textToCopy, onTextCopied }: CopyToClipboardButtonProps) => {
    const {
      app: { isMobile },
    } = useCommonStores();
    const classes = useStyles();

    return (
      <Button
        variant='outlined'
        color='secondary'
        onClick={() => CopyToClipboard(textToCopy, onTextCopied)}
        className={classes.copyButton}
        fullWidth={isMobile}
      >
        Copy to clipboard
      </Button>
    );
  },
);
