import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Content,
  Modal,
  ProgressiveStepper,
  Spinner,
  Text,
  SideDrawer,
  Icon,
  IconEnum,
  Flex,
  Color,
} from 'component-library';
import {
  ExpenseClassificationProcessingSteps,
  JobGroupEnum,
  JobGroupEnumToString,
  LoadingStatusEnum,
  Page,
  ProgramSubStageEnum,
  RdActivitiesForJobGroup,
  RdActivityEnum,
  AttestationEventTypeEnum,
} from 'lib/constants';
import { HeaderBar, StepsContainer, SurveyAttestation } from '../components';
import { SaveSurveyAttestation } from '../components/expense-classification/SurveyAttestation';
import {
  CountryEnum,
  EmploymentRecordData,
  EmploymentTypeEnum,
  PayTypeEnum,
  ProgramData,
  UpdateMissingInformationEmploymentRecordRequest,
  WorkDoneData,
  WorkDoneNote,
} from '../../../../lib/interfaces';
import { MemoizedEmployeeTable } from '../components/employee-step/EmployeeTable';
import EmployeeSideDrawer from '../components/employee-step/EmployeeSideDrawer';
import PayrollGapAlert from '../components/employee-step/PayrollGapAlert';
import _ from 'lodash';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from '../../../../logging';
import {
  useCompany,
  useFeatureFlags,
  useRootStore,
  useTaxCreditsStores,
  useCommonStores,
} from 'stores/useStores';
import { observer } from 'mobx-react';
import { EmployeeTableColumns } from './EmployeeTableColumns';
import { EducationalCards } from './EducationalCards';
import { makeStyles } from '@material-ui/core';
import { JobGroupFaq } from './JobGroupFaq';
import { RdActivitiesContainer } from '../components/employee-step/RdActivitiesContainer';
import AttentionNeeded from '../../../../components/icons/AttentionNeeded';
import { useEffectOnce } from 'lib/helpers';

const logger = datadogLogs.createLogger('Employees');

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 80px',
    maxWidth: '1440px',
    position: 'relative',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: '100vh',
    backgroundColor: Color.neutral.white,
  },
  mainContent: {
    maxWidth: '1440px',
    width: '100%',
    margin: '0 auto',
    padding: '0 24px 80px',
    boxSizing: 'border-box',
    position: 'relative',
  },
  /*
  // Very temporary. Position in the new mini-stepper is
  // already accounted for in COREX-858. Opted for quick fix
  // as opposed to spending time on a soon-to-be-overwritten solution.
  */
  helpButtonContainer: {
    position: 'absolute',
    maxWidth: '100%',
    top: '4px',
    paddingRight: '160px',
  },
  list: {
    paddingLeft: 15,
    marginTop: '-10px',
    lineHeight: 1.75,
    letterSpacing: '.10px',
  },
  attestation: {
    paddingTop: 16,
  },
}));

export interface IndividualExpenseClassificationData {
  // Base type for the main EC data type
  name: string | undefined;
  role: string | undefined;
  isContractor: boolean;
  jobGroup: JobGroupEnum | undefined;
  rdActivities?: RdActivityEnum[];
  percentage?: number;
  lowPercentEstimate?: number;
  highPercentEstimate?: number;
  comments: WorkDoneNote[];
  // Employees which are not R&D eligible ( which is based off of their job group) would not have a WorkDone
  // But note: We are excluding employees from the state which are R&D ineligible until GOV-2001 is complete
  workDoneId?: number;
  paidInCents: number | undefined;
  workingFrom: string | undefined;
  country: CountryEnum | undefined;
  id: number;
  isMajorityOwner: boolean;
}

export interface ContractorExpenseClassificationData
  extends IndividualExpenseClassificationData {
  payType?: PayTypeEnum | undefined;
}

export type EmploymentRecordWithWorkDoneData = EmploymentRecordData & {
  // Type which is just the WorkDone fields we care about
  percentage?: number;
  previousPercentage?: number;
  lowPercentEstimate?: number;
  highPercentEstimate?: number;
  workDoneId?: number;
  workDone?: WorkDoneData[];
  comments?: WorkDoneNote[];
};

export interface EmployeeInputData {
  fullName: string;
  taxYear: number;
  jobTitle?: string;
  jobGroup: JobGroupEnum;
  taxablePayCents: number | undefined;
  countryOfResidence: CountryEnum;
  stateOfResidence: string;
  payType?: PayTypeEnum;
  employmentType: EmploymentTypeEnum;
}

interface EmployeesStepProps {
  programId: number;
  onError: () => void;
  hasPayrollGap: boolean;
  possiblePayrollMigration: boolean;
}

interface RowData {
  data: IndividualExpenseClassificationData;
}

export enum EmploymentDisplayType {
  CONTRACTOR = 'contractor',
  EMPLOYEE = 'employee',
  EMPTY = '',
}

const mapToDisplayTableData = (
  employees:
    | IndividualExpenseClassificationData[]
    | ContractorExpenseClassificationData[],
) => {
  return employees.map((employee) => {
    const newEmployee = { ...employee };
    if (employee.jobGroup) {
      newEmployee.jobGroup = JobGroupEnumToString[
        employee.jobGroup
      ] as JobGroupEnum;
    }
    return newEmployee;
  });
};

/**
 * We will show R&D activities in one of two modes:
 * - progressive-disclosure: The user must click through each employee / job group in sequence, and the next one
 * won't be shown until the prior are all completed. This is how the user should experience the R&D activities section
 * the first time through.
 * - comments-expanded: The user will be shown all employees at once, and the ones with comments will be expanded. This
 * is how the user should see the R&D activities section when they are sent back to the EC page to address MS Review
 * comments.
 */
export enum RdActivitiesDisplayModeEnum {
  PROGRESSIVE_DISCLOSURE = 'progressive-disclosure',
  COMMENTS_EXPANDED = 'comments-expanded',
}

/**
 * Indicates whether the individual given employee has comments.
 */
export const EmployeeHasComments = (
  emp: IndividualExpenseClassificationData,
): boolean => {
  return emp.comments.length > 0;
};

/**
 * Indicates whether any employees in the given list have comments.
 */
const employeesHaveComments = (
  employeesList: IndividualExpenseClassificationData[],
) => {
  return employeesList.filter((emp) => EmployeeHasComments(emp)).length > 0;
};

export const EmployeesStep = observer(
  ({
    programId,
    onError,
    hasPayrollGap,
    possiblePayrollMigration,
  }: EmployeesStepProps) => {
    const { client } = useRootStore();
    const { taxCreditsPage } = useTaxCreditsStores();
    const { company } = useCompany();
    const { auth } = useCommonStores();
    const featureFlags = useFeatureFlags();
    const classes = useStyles();
    const [isOnContinueLoading, setIsOnContinueLoading] =
      useState<boolean>(false);

    const [program, setProgram] = useState<ProgramData | null>(null);
    const [hasConfirmedAnswers, setHasConfirmedAnswers] =
      useState<boolean>(false);

    const [employeesTableEditMode, setEmployeesTableEditMode] =
      useState<boolean>(true);
    const [selectedPerson, setSelectedPerson] =
      useState<IndividualExpenseClassificationData | null>(null);
    const [contractorsTableEditMode, setContractorsTableEditMode] =
      useState<boolean>(true);
    const [addDrawerType, setAddDrawerType] = useState<EmploymentDisplayType>(
      EmploymentDisplayType.EMPTY,
    );

    const [loading, setLoading] = useState<boolean>(true);
    const [currentProgressiveStep, setCurrentProgressiveStep] =
      useState<number>(1);
    const [employees, setEmployees] = useState<
      IndividualExpenseClassificationData[]
    >([]);
    const [contractors, setContractors] = useState<
      ContractorExpenseClassificationData[]
    >([]);
    const [jobGroupsComplete, setJobGroupsComplete] = useState<boolean>(false);

    // default doesn't matter since this gets set again after fetching employees
    const [rdActivitiesDisplayMode, setRdActivitiesDisplayMode] =
      useState<RdActivitiesDisplayModeEnum>(
        RdActivitiesDisplayModeEnum.PROGRESSIVE_DISCLOSURE,
      );
    const [reconfirmedEmployeeIds, setReconfirmedEmployeeIds] = useState<
      number[]
    >([]);
    const [doesEmployeeOtherJobGroupExist, setDoesEmployeeOtherJobGroupExist] =
      useState<boolean>(false);
    const [
      doesContractorOtherJobGroupExist,
      setDoesContractorOtherJobGroupExist,
    ] = useState<boolean>(false);
    const [isEmployeeMissingData, setIsEmployeeMissingData] =
      useState<boolean>(false);
    const [isContractorMissingData, setIsContractorMissingData] =
      useState<boolean>(false);
    const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);
    const [showContractorModal, setShowContractorModal] =
      useState<boolean>(false);
    const [showHelpSideDrawer, setShowHelpSideDrawer] =
      useState<boolean>(false);
    const [showMustAddRecordModal, setShowMustAddRecordModal] = useState(false);
    // This state is to monitor whether any percentages changed for any employees or contractors
    const [percentageChanged, setPercentageChanged] = useState<boolean>(false);
    const [showAttentionNeededModal, setShowAttentionNeededModal] =
      useState(false);

    const [showDataImportAlert, setShowDataImportAlert] = useState(false);
    const [dataImportStatus, setDataImportStatus] = useState<
      LoadingStatusEnum | undefined
    >(undefined);

    useEffectOnce(async () => {
      if (featureFlags.showYeaImportStatusAlert) {
        const resp = await client.CheckCreditEstimateLoaded();
        const loadingStatus = resp.data?.loadingStatus;
        setDataImportStatus(loadingStatus as LoadingStatusEnum);

        if (
          loadingStatus &&
          [LoadingStatusEnum.ERRORED, LoadingStatusEnum.IN_PROGRESS].includes(
            loadingStatus as LoadingStatusEnum,
          )
        ) {
          setShowDataImportAlert(true);
        }
      }
    });

    const checkWhetherToShowAttentionNeededModal = () => {
      if (percentageChanged) {
        onContinue();
      } else {
        setShowAttentionNeededModal(true);
      }
    };

    const updateProgramSubStage = useCallback(
      (programSubStage: ProgramSubStageEnum): Promise<string | undefined> => {
        return client
          .UpdateProgramSubStage({
            programId,
            programSubStage: programSubStage,
          })
          .then(({ errorMsg }) => {
            if (errorMsg) {
              console.error('Error: Could not update program substage.');
              return Promise.reject(errorMsg);
            }
          });
      },
      [client, programId],
    );

    // Save WorkDone records
    const onContinue = async () => {
      setIsOnContinueLoading(true);
      if (featureFlags.saveYeaSurveyAttestation) {
        await SaveSurveyAttestation({
          hasConfirmedAnswers,
          eventType:
            AttestationEventTypeEnum.YEAR_END_ASSESSMENT_EMPLOYEES_COMPLETE,
          userEmail: auth.user?.email || company?.adminEmail,
          taxYear: program!.taxYear,
          companyId: company.id,
          client,
        });
      }

      const ers = getRdEligibleEmployees().map((employee) => {
        return {
          id: employee.workDoneId!,
          percentage: employee.percentage,
        };
      });

      const response = await client.UpdateExpenseClassification(ers);
      if (response.errorMsg) {
        logger.error(
          'submitRDChanges UpdateExpenseClassification failed',
          logContext({
            error: response.errorMsg,
            company,
          }),
        );

        // If updating the WorkDone records fails, don't proceed further in this
        // function in order to prevent updating the program stage.
        onError();
        return;
      }

      if (!taxCreditsPage.isYEAEditMode) {
        await updateProgramSubStage(
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_COMPANY_DETAILS,
        );
      }

      window.location.href = `/${Page.taxCredits}`;
      setIsOnContinueLoading(false);
    };

    const createEmployeeOrContractor = async (employee: EmployeeInputData) => {
      const req = {
        ...employee,
        source: 'dashboard',
        rdActivities: RdActivitiesForJobGroup[employee.jobGroup],
      };
      const res = await client.CreateEmploymentRecordForUserCompany(req);

      if (res.data && !res.errorMsg) {
        const { workDoneData, employmentRecordData } = res.data;
        addEmployeeToState([
          {
            ...employmentRecordData,
            workDoneId: workDoneData?.id,
            percentage: workDoneData?.percentage,
            highPercentEstimate: workDoneData?.discussion.percent_estimate_high,
            lowPercentEstimate: workDoneData?.discussion.percent_estimate_low,
          } as EmploymentRecordWithWorkDoneData,
        ]);
      } else {
        const employmentType = employee.employmentType;
        let errorText = 'W2 Employee';
        if (employmentType === EmploymentTypeEnum.CONTRACTOR) {
          errorText = '1099 Contractor';
        }
        throw new Error(`${errorText} could not be created: ${res.errorMsg}`);
      }
    };

    const getEmployeeById = (
      id: number,
    ): IndividualExpenseClassificationData => {
      return employees.find((employee) => id === employee.id)!;
    };

    const getContractorById = (
      id: number,
    ): ContractorExpenseClassificationData => {
      return contractors.find((contractor) => id === contractor.id)!;
    };

    const updateEmployeeOrContractor = async (
      updatedEmployee: EmployeeInputData,
    ) => {
      const updateRequest: UpdateMissingInformationEmploymentRecordRequest = {
        fullName: updatedEmployee.fullName,
        jobGroup: updatedEmployee.jobGroup,
        jobTitle: updatedEmployee.jobTitle,
        countryOfResidence: updatedEmployee.countryOfResidence,
        taxablePayCents: updatedEmployee.taxablePayCents,
        stateOfResidence: updatedEmployee.stateOfResidence,
        payType: updatedEmployee?.payType,
      };
      try {
        await client.UpdateMissingEmployeeInformation(
          selectedPerson!.id,
          updateRequest,
        );

        let checkEmployeeJobGroupForOther;
        let checkEmployeeMissingData;
        let checkContractorJobGroupForOther;
        let checkContractorMissingData;

        if (selectedPerson?.isContractor) {
          const updatedContractors = [...contractors].map((contractor) => {
            if (contractor.id !== selectedPerson!.id) return contractor;
            return {
              ...contractor,
              jobGroup: updatedEmployee.jobGroup,
              name: updatedEmployee.fullName,
              role: updatedEmployee.jobTitle,
              country: updatedEmployee.countryOfResidence,
              workingFrom: updatedEmployee.stateOfResidence,
              paidInCents: updatedEmployee?.taxablePayCents,
              payType: updatedEmployee.payType,
            };
          });

          checkContractorJobGroupForOther = updatedContractors.find(
            (contractor) => contractor.jobGroup === JobGroupEnum.OTHER,
          );
          checkContractorMissingData = updatedContractors.find(
            (contractor) =>
              _.isEmpty(contractor.role) ||
              contractor.paidInCents === 0 ||
              _.isEmpty(contractor.workingFrom),
          );
          setIsContractorMissingData(!!checkContractorMissingData);
          setDoesContractorOtherJobGroupExist(
            !!checkContractorJobGroupForOther,
          );
          setContractors(updatedContractors);
        } else {
          const updatedEmployees = [...employees].map((fullTimeEmployee) => {
            if (fullTimeEmployee.id !== selectedPerson!.id)
              return fullTimeEmployee;
            return {
              ...fullTimeEmployee,
              jobGroup: updatedEmployee.jobGroup,
              name: updatedEmployee.fullName,
              role: updatedEmployee.jobTitle,
              country: updatedEmployee.countryOfResidence,
              workingFrom: updatedEmployee.stateOfResidence,
              paidInCents: updatedEmployee?.taxablePayCents || 0,
            };
          });

          checkEmployeeJobGroupForOther = updatedEmployees.find(
            (employee) => employee.jobGroup === JobGroupEnum.OTHER,
          );
          checkEmployeeMissingData = updatedEmployees.find(
            (employee) =>
              _.isEmpty(employee.role) ||
              employee.paidInCents === 0 ||
              _.isEmpty(employee.workingFrom),
          );
          setIsEmployeeMissingData(!!checkEmployeeMissingData);
          setDoesEmployeeOtherJobGroupExist(!!checkEmployeeJobGroupForOther);
          setEmployees(updatedEmployees);
        }
      } catch (error) {
        logger.error(
          'Failed to update employee',
          logContext({
            error,
            company,
          }),
        );
        throw new Error(`Failed to update employee: ${error}`);
      }
    };

    const addEmployeeToState = useCallback(
      (employmentRecordsToAdd: EmploymentRecordWithWorkDoneData[]) => {
        let checkEmployeeJobGroupForOther = false;
        let checkContractorJobGroupForOther = false;
        let checkEmployeeMissingData = false;
        let checkContractorMissingData = false;

        const contractorsToAdd: ContractorExpenseClassificationData[] = [];
        const w2EmployeesToAdd: IndividualExpenseClassificationData[] = [];
        employmentRecordsToAdd.forEach((employee) => {
          const commonFields = {
            workDoneId: employee.workDoneId,
            name: employee.fullName,
            role: employee.jobTitle,
            jobGroup: employee.jobGroup,
            rdActivities: employee.rdActivities,
            percentage: employee.percentage,
            lowPercentEstimate: employee.lowPercentEstimate,
            highPercentEstimate: employee.highPercentEstimate,
            comments: employee.comments || [],
            id: employee.id,
            paidInCents: employee.taxablePayCents || 0,
            country: employee.countryOfResidence,
            workingFrom: employee?.stateOfResidence,
            isMajorityOwner: employee.isMajorityOwner,
          };

          const checkForMissingData =
            _.isEmpty(commonFields.role) ||
            commonFields.paidInCents === 0 ||
            _.isEmpty(commonFields.workingFrom);

          if (employee.employmentType === 'w2') {
            w2EmployeesToAdd.push({
              ...commonFields,
              isContractor: false,
            });
            if (employee.jobGroup === JobGroupEnum.OTHER) {
              checkEmployeeJobGroupForOther = true;
            }
            if (checkForMissingData) {
              checkEmployeeMissingData = true;
            }
          } else if (employee?.employmentType === '1099') {
            contractorsToAdd.push({
              ...commonFields,
              isContractor: true,
              payType: employee.payType,
            });
            if (employee.jobGroup === JobGroupEnum.OTHER) {
              checkContractorJobGroupForOther = true;
            }
            if (checkForMissingData) {
              checkContractorMissingData = true;
            }
          }
        });
        setDoesEmployeeOtherJobGroupExist(checkEmployeeJobGroupForOther);
        setDoesContractorOtherJobGroupExist(checkContractorJobGroupForOther);
        setIsEmployeeMissingData(checkEmployeeMissingData);
        setIsContractorMissingData(checkContractorMissingData);

        let newEmployees: IndividualExpenseClassificationData[] = [];
        let newContractors: ContractorExpenseClassificationData[] = [];
        setEmployees((prevState) => {
          newEmployees = [...prevState, ...w2EmployeesToAdd];
          return newEmployees;
        });
        setContractors((prevState) => {
          newContractors = [...prevState, ...contractorsToAdd];
          return newContractors;
        });

        const thereAreComments = employeesHaveComments(
          (newEmployees as IndividualExpenseClassificationData[]).concat(
            newContractors as IndividualExpenseClassificationData[],
          ),
        );
        setRdActivitiesDisplayMode(
          thereAreComments
            ? RdActivitiesDisplayModeEnum.COMMENTS_EXPANDED
            : RdActivitiesDisplayModeEnum.PROGRESSIVE_DISCLOSURE,
        );
      },
      [],
    );

    // This is needed to prevent higher steps from closing, if editing lower steps
    const onNextProgressiveStep = (nextStepNumber: number) => {
      if (nextStepNumber > currentProgressiveStep) {
        setCurrentProgressiveStep(nextStepNumber);
      }
    };

    useEffect(() => {
      client.GetProgram(programId).then((programData) => {
        // This pulls employees off of the WD, which means we will not get employees belonging to job groups which
        // never have a WD created. @TODO fix this in GOV-2001
        const employmentRecords =
          (programData.data?.program.workDone as WorkDoneData[])
            .filter((employmentRecord) => employmentRecord !== undefined)
            .map((workDone): EmploymentRecordWithWorkDoneData => {
              return {
                ...workDone.employmentRecord!,
                percentage: workDone.percentage,
                lowPercentEstimate: workDone.discussion.percent_estimate_low,
                highPercentEstimate: workDone.discussion.percent_estimate_high,
                comments: workDone.discussion.notes || [],
                workDoneId: workDone.id,
              };
            }) || [];

        if (employmentRecords.length > 0) {
          addEmployeeToState(employmentRecords);
        }
        if (programData.data?.program) {
          setProgram(programData.data.program);
        }
        setLoading(false);
      });
    }, [client, programId, addEmployeeToState]);

    /**
     * Returns the employees and contractors which have a defined workDoneId.
     * This indicates that they are eligible for R&D and should have their own
     * R&D activities expandable.
     * Note: Get rid of this function in GOV-2001
     */
    const getRdEligibleEmployees =
      (): IndividualExpenseClassificationData[] => {
        return [...employees, ...contractors].filter((emp) => emp.workDoneId);
      };

    /**
     * TODO: This is an ugly function becuase we're loading the employees and the
     * contractors separately. We should load all EmploymentRecords together and
     * then filter employees and contractors where necessary. That would prevent
     * the need to search through two different arrays. (We can probably do this
     * when transitioning to MobX.)
     */
    const setPercent = (id: number, newPercentage: number) => {
      const employee = employees.find((employee) => employee.id === id);
      const contractor = contractors.find((contractor) => contractor.id === id);

      if (newPercentage && percentageChanged === false) {
        setPercentageChanged(true);
      }
      if (employee) {
        employee.percentage = newPercentage;
        setEmployees(employees);
      } else if (contractor) {
        contractor.percentage = newPercentage;
        setContractors(contractors);
      }
    };

    const possibleDuplicatedEmployeesAlertContent = (
      <Text>
        Some employees and contractors in the following tables may be duplicated
        – please disregard this issue for now. We will fix it manually when a
        MainStreet expert reviews your information.
      </Text>
    );

    const warningText = `We recommend that you fill in all the missing information to maximize your credits and simplify your next steps.`;

    return (
      <div className={classes.container}>
        <HeaderBar currentPage={ExpenseClassificationProcessingSteps[0]} />
        <div className={classes.mainContent}>
          <div className={classes.root}>
            <SideDrawer
              show={showHelpSideDrawer}
              title={'Help'}
              closeToggle={() => setShowHelpSideDrawer(false)}
              drawerActions={<></>}
              drawerContent={<JobGroupFaq />}
            />
            <Flex className={classes.helpButtonContainer} justifyContent='end'>
              <Button
                label={
                  <>
                    Help
                    <Icon name={IconEnum.question_circle} />
                  </>
                }
                onClick={() => setShowHelpSideDrawer(true)}
                variant='outlined-gray'
              />
            </Flex>
            <StepsContainer
              showContinueButton={jobGroupsComplete}
              continueDisabled={
                featureFlags.saveYeaSurveyAttestation && !hasConfirmedAnswers
              }
              onContinue={checkWhetherToShowAttentionNeededModal}
              onContinueLabel='Continue'
              stepTitle='R&D Expenses | Employees'
              isLoading={isOnContinueLoading}
            >
              <>
                {showDataImportAlert ? (
                  <Flex padding={[24, 0]}>
                    <Alert
                      text={
                        dataImportStatus === LoadingStatusEnum.ERRORED
                          ? 'It looks like there’s an issue with your payroll connection. Our team is working to fix it now! We’ll send you an email when it’s resolved.'
                          : 'Your payroll data is still importing. Please check back later today to complete this step!'
                      }
                      type={
                        dataImportStatus === LoadingStatusEnum.ERRORED
                          ? 'alert'
                          : 'info'
                      }
                      actions={{
                        text: 'Dismiss',
                        onClick: () => setShowDataImportAlert(false),
                      }}
                    />
                  </Flex>
                ) : (
                  <></>
                )}
                {hasPayrollGap && <PayrollGapAlert />}
                <Content
                  flex
                  flexDirection='column'
                  gap={16}
                  paddingLeftRight={0}
                >
                  {possiblePayrollMigration && (
                    <Alert text={possibleDuplicatedEmployeesAlertContent} />
                  )}
                  <ProgressiveStepper
                    currentStep={currentProgressiveStep}
                    animate
                  >
                    {loading ? (
                      <Content
                        paddingTopBottom={24}
                        flex
                        justifyContent='center'
                      >
                        <Spinner size='small' color='emerald' />
                      </Content>
                    ) : (
                      <MemoizedEmployeeTable
                        tableEditMode={employeesTableEditMode}
                        setTableEditMode={setEmployeesTableEditMode}
                        columns={EmployeeTableColumns('employee')}
                        data={mapToDisplayTableData(employees)}
                        rowOnClick={(value) => {
                          setSelectedPerson(getEmployeeById(value.id));
                          setAddDrawerType(EmploymentDisplayType.EMPLOYEE);
                        }}
                        primaryOnClick={() => {
                          if (
                            doesEmployeeOtherJobGroupExist ||
                            isEmployeeMissingData
                          ) {
                            setShowEmployeeModal(true);
                          } else {
                            setEmployeesTableEditMode(false);
                            onNextProgressiveStep(2);
                          }
                        }}
                        secondaryOnClick={() => {
                          setAddDrawerType(EmploymentDisplayType.EMPLOYEE);
                        }}
                        dataType='employee'
                        doesOtherJobGroupExist={doesEmployeeOtherJobGroupExist}
                      />
                    )}
                    <>
                      {!loading && (
                        <MemoizedEmployeeTable
                          tableEditMode={contractorsTableEditMode}
                          setTableEditMode={setContractorsTableEditMode}
                          columns={EmployeeTableColumns('contractor')}
                          data={mapToDisplayTableData(contractors)}
                          rowOnClick={(value) => {
                            setSelectedPerson(getContractorById(value.id));
                            setAddDrawerType(EmploymentDisplayType.CONTRACTOR);
                          }}
                          primaryOnClick={() => {
                            if ([...employees, ...contractors].length === 0) {
                              // if they don't have at least 1 record in either table, prevent them from continuing
                              setShowMustAddRecordModal(true);
                            } else {
                              if (
                                doesContractorOtherJobGroupExist ||
                                isContractorMissingData
                              ) {
                                setShowContractorModal(true);
                              } else {
                                setContractorsTableEditMode(false);
                                onNextProgressiveStep(3);
                              }
                            }
                          }}
                          secondaryOnClick={() => {
                            setAddDrawerType(EmploymentDisplayType.CONTRACTOR);
                          }}
                          dataType='contractor'
                          doesOtherJobGroupExist={
                            doesContractorOtherJobGroupExist
                          }
                        />
                      )}
                      <Modal
                        showModal={showMustAddRecordModal}
                        closeToggle={() => setShowMustAddRecordModal(false)}
                        backdrop={'static'}
                      >
                        <Flex padding={24} direction='column'>
                          <Flex padding={[0, 48, 0, 0]}>
                            <Text
                              variant='medium'
                              size={18}
                              text='You must have at least one employee or contractor to proceed.'
                            />
                          </Flex>
                          <Flex justifyContent='end' padding={[24, 0, 0, 0]}>
                            <Button
                              label='Continue editing'
                              onClick={() => setShowMustAddRecordModal(false)}
                              dataTestId={'must-add-record-modal-button'}
                            />
                          </Flex>
                        </Flex>
                      </Modal>
                    </>
                    <EducationalCards
                      onCompleteButtonClick={() => onNextProgressiveStep(4)}
                    />
                    <RdActivitiesContainer
                      employees={getRdEligibleEmployees()}
                      setJobGroupsComplete={setJobGroupsComplete}
                      setPercent={setPercent}
                    />
                  </ProgressiveStepper>
                  {featureFlags.saveYeaSurveyAttestation &&
                    jobGroupsComplete && (
                      <SurveyAttestation
                        checked={hasConfirmedAnswers}
                        className={classes.attestation}
                        onAttestation={() =>
                          setHasConfirmedAnswers(!hasConfirmedAnswers)
                        }
                      />
                    )}
                </Content>
              </>
            </StepsContainer>
            <Modal
              showModal={showEmployeeModal}
              closeToggle={() => setShowEmployeeModal(false)}
              backdrop={'static'}
            >
              <>
                <Content paddingTopBottom={24} paddingLeftRight={24}>
                  <Text
                    variant='medium'
                    size={18}
                    text={`There are still employees with incomplete data`}
                  />
                  <br />
                  <Text text={warningText} />
                </Content>
                <Content gap={16} flex paddingLeftRight={24}>
                  <Button
                    label='Continue editing table'
                    onClick={() => {
                      setShowEmployeeModal(false);
                    }}
                    dataTestId={'employee-modal-secondary-action'}
                  />
                  <Button
                    variant='outlined'
                    label='Confirm table'
                    onClick={() => {
                      setEmployeesTableEditMode(false);
                      onNextProgressiveStep(2);
                      setShowEmployeeModal(false);
                    }}
                    dataTestId={'employee-modal-primary-action'}
                  />
                </Content>
              </>
            </Modal>
            <Modal
              showModal={showContractorModal}
              closeToggle={() => setShowContractorModal(false)}
              backdrop={'static'}
            >
              <>
                <Content paddingTopBottom={24} paddingLeftRight={24}>
                  <Text
                    variant='medium'
                    size={18}
                    text={`There are still contractors with incomplete data`}
                  />
                  <br />
                  <Text text={warningText} />
                </Content>
                <Content gap={16} flex paddingLeftRight={24}>
                  <Button
                    label='Continue editing table'
                    onClick={() => {
                      setShowContractorModal(false);
                    }}
                    dataTestId={'contractor-modal-secondary-action'}
                  />
                  <Button
                    variant='outlined'
                    label='Confirm table'
                    onClick={() => {
                      setContractorsTableEditMode(false);
                      onNextProgressiveStep(3);
                      setShowContractorModal(false);
                    }}
                    dataTestId={'contractor-modal-primary-action'}
                  />
                </Content>
              </>
            </Modal>
            <Modal
              showModal={showAttentionNeededModal}
              closeToggle={() => setShowAttentionNeededModal(false)}
              maxWidth={536}
            >
              <Flex
                direction='column'
                alignItems='center'
                gap={16}
                padding={24}
              >
                <AttentionNeeded />
                <Text variant='bold' size={23} text='Attention Needed!' />
                <Text variant='regular' size={15}>
                  <ul className={classes.list}>
                    <li>
                      You haven&apos;t edited any of your employees&apos; R&D
                      contributions!
                    </li>
                    <li>
                      The pre-filled percentages are based on companies like
                      you, and may not fully reflect your R&D efforts.
                    </li>
                    <li>
                      Be sure to review each role&apos;s qualifying activities
                      to confirm how much time your employees spent on R&D.
                    </li>
                  </ul>
                </Text>
                <Flex gap={16} justifyContent='flex-end'>
                  <Button
                    variant='outlined'
                    label='Review'
                    onClick={() => setShowAttentionNeededModal(false)}
                    dataTestId={'attention-needed-modal-secondary-action'}
                  />
                  <Button
                    label={'Proceed'}
                    onClick={() => onContinue()}
                    dataTestId={'attention-needed-modal-primary-action'}
                  />
                </Flex>
              </Flex>
            </Modal>
            {program && (
              <EmployeeSideDrawer
                onAddEmployeeOrContractor={createEmployeeOrContractor}
                onUpdateEmployeeOrContractor={updateEmployeeOrContractor}
                drawerType={addDrawerType}
                setDrawerType={setAddDrawerType}
                selectedPerson={selectedPerson}
                setSelectedPerson={setSelectedPerson}
                programTaxYear={program.taxYear}
              />
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default EmployeesStep;
