import React from 'react';
import { observer } from 'mobx-react';
import { Color, Grid, Text } from 'component-library';
import { SectionCard } from './SectionCard';
import { useCommonStores } from 'stores/useStores';

interface SectionCardHeaderProps {
  sectionTitle: string;
  estimateTotal?: string;
  estimateColor?: string;
}

export const SectionCardHeader: React.FC<SectionCardHeaderProps> = observer(
  ({ sectionTitle, estimateTotal, estimateColor }) => {
    const { app } = useCommonStores();

    return (
      <>
        <Grid.Cell
          columns={9}
          padding={[16, 24, 16, 24]}
          alignSelf='center'
          style={{
            borderRight: estimateTotal
              ? `1px solid ${Color.neutral._20}`
              : undefined,
          }}
        >
          <Text
            variant='medium'
            text={sectionTitle}
            size={app.isSmallDesktop ? 18 : 23}
          />
        </Grid.Cell>
        {estimateTotal ? (
          <Grid.Cell
            columns={3}
            alignSelf='center'
            style={{
              textAlign: 'right',
            }}
            padding={[16, 24, 16, 24]}
          >
            <Text
              color={estimateColor || Color.green._70}
              size={app.isSmallDesktop ? 18 : 23}
              variant='medium'
            >
              {estimateTotal}
            </Text>
          </Grid.Cell>
        ) : (
          <></>
        )}
      </>
    );
  },
);

interface SectionCardWithHeaderProps extends SectionCardHeaderProps {
  children: React.ReactElement | JSX.Element;
  gridMinContent?: boolean;
}

export const SectionCardWithHeader: React.FC<SectionCardWithHeaderProps> = observer(
  ({ children, sectionTitle, estimateTotal, estimateColor, ...props }) => {
    return (
      <SectionCard {...props}>
        <>
          <SectionCardHeader
            sectionTitle={sectionTitle}
            estimateTotal={estimateTotal}
            estimateColor={estimateColor}
          />

          {children}
        </>
      </SectionCard>
    );
  },
);
