import React from 'react';
import { Button, Content, Modal, Text } from 'component-library';
import { RdVendorExpense } from 'lib/interfaces';

interface RdConfirmDeleteModal {
  showConfirmDelete: boolean;
  setShowConfirmDelete: (show: boolean) => void;
  onDelete: (vendor: RdVendorExpense) => void;
  selectedVendor: RdVendorExpense;
}

const RdConfirmDeleteModal = ({
  showConfirmDelete,
  setShowConfirmDelete,
  onDelete,
  selectedVendor,
}: RdConfirmDeleteModal) => {
  return (
    <Modal
      showModal={showConfirmDelete}
      closeToggle={() => setShowConfirmDelete(false)}
    >
      <Content
        paddingTopBottom={24}
        paddingLeftRight={24}
        flex
        flexDirection={'column'}
        gap={8}
      >
        <Text size={18} variant='medium' text='Delete this expense' />
        <Text
          size={15}
          text={`Are you sure you want to delete ${selectedVendor.name}?`}
          paddingBottom={24}
        />
        <Content flex paddingLeftRight={0}>
          <Button
            small={true}
            label='Delete'
            type='error'
            onClick={() => {
              onDelete({ ...selectedVendor });
              setShowConfirmDelete(false);
            }}
            dataTestId={'modal-confirm-delete-button'}
          />
          <Button
            small={true}
            label='Keep expense'
            onClick={() => {
              setShowConfirmDelete(false);
            }}
            dataTestId={'modal-cancel-button'}
            variant='outlined'
          />
        </Content>
      </Content>
    </Modal>
  );
};

export default RdConfirmDeleteModal;
