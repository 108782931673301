import React from 'react';
import { Step, StepProps } from '../../TaxCreditProgramSteps';
import { Button, Content, StepCardStep, Text } from 'component-library';
import { ProgramData } from '../../../../../lib/interfaces';
import { QualifyingStepFAQ } from '../../sideDrawer/QualifyingStepFAQ';
import { useTaxCreditsStores } from '../../../../../stores/useStores';
import { IllustrationCard } from 'pages/dashboard/taxCredits/IllustrationCard';

interface QualifyingStepProps extends StepProps {
  programs: ProgramData[];
}

export const stepConfig: Step = {
  name: 'qualifying',
  number: 0,
  title: 'Estimate your R&D credits',
  dataTestId: 'qualification',
  faq: <QualifyingStepFAQ />,
};

export const QualifyingStep = ({
  federalRDProgram,
  programs,
}: QualifyingStepProps) => {
  const { taxCreditsPage } = useTaxCreditsStores();

  const rdPrograms = programs.filter((program) =>
    program.name.includes('_rd_'),
  );
  const programsWithOrderForms = rdPrograms.filter(
    (program) => program.orderForm,
  );
  const programsWithUnsignedOrderForms = programsWithOrderForms.filter(
    (program) => !program.orderForm?.acceptedAt,
  );

  let text;
  let buttonLabel;
  let linkLocation;

  if (programsWithOrderForms.length) {
    if (programsWithUnsignedOrderForms.length) {
      text =
        'This is the first estimation of how much credits you will be able to get.';
      if (programsWithUnsignedOrderForms.length > 1) {
        buttonLabel = 'Sign order forms';
        // billing/orders/${program.taxYear} goes to order forms index
        linkLocation = `billing/orders/${federalRDProgram.taxYear}`;
      } else {
        buttonLabel = 'Sign order form';
        // billing/order/${program.id} for signing flow for an order form
        linkLocation = `billing/order/${programsWithUnsignedOrderForms[0]?.id}`;
      }
    } else {
      // don't show the button if there are no order forms to sign
      text = undefined;
      buttonLabel = undefined;
      linkLocation = undefined;
    }
  } else {
    text =
      'This is the first estimation of how much credits you will be able to get.';
    buttonLabel = 'Finish qualification';
    linkLocation = `/tax-processing/qualify/${federalRDProgram.taxYear}`;
  }

  return (
    <StepCardStep
      active={stepConfig.name === federalRDProgram.stage}
      dataTestId={stepConfig.dataTestId}
      learnMoreOnClick={() =>
        taxCreditsPage.setShowSideDrawer(true, stepConfig.name)
      }
      stepNumber={stepConfig.number}
      title={stepConfig.title}
    >
      {buttonLabel && linkLocation ? (
        <Content flex flexDirection='column'>
          <>
            <Text paddingBottom={8} text={text} />
            <Button
              flexAlignSelf='flex-start'
              label={buttonLabel}
              onClick={{
                location: linkLocation,
                external: true,
                target: '_self',
              }}
              small
            />
          </>
        </Content>
      ) : (
        <IllustrationCard
          imgSrc={`${process.env.PUBLIC_URL}/images/tax-credit-dashboard-module/all-set.svg`}
          heading={'All set for now!'}
          subHeading={"We'll let you know when it's time for the next step"}
        />
      )}
    </StepCardStep>
  );
};
