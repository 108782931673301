import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { WorkDoneNote } from 'lib/interfaces';
import { ButtonLink } from 'components/util/ButtonLink';
import { Info } from '@material-ui/icons';

const GRAY = 'rgba(51, 51, 51, 0.8)';

const useStyles = makeStyles(() => ({
  avatarContainer: {
    height: '100%',
  },
  initials: {
    fontSize: 18,
    color: 'white',
  },
  commentText: {
    fontSize: '12px',
    lineHeight: '16px',
    marginTop: '10px',
    marginLeft: '2px',
    color: '#333333',
  },
  commentInput: {
    fontSize: '12px',
    backgroundColor: 'white',
  },
  saveButton: {
    margin: '15px 0',
  },
  editButton: {
    marginLeft: '-18px',
  },
  IRSText: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '26px',
    color: GRAY,
    padding: '0px',
    margin: '0px',
  },
  horizontalInstructions: {
    backgroundColor: 'rgba(51, 51, 51, 0.1)',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: '54px',
  },
  icon: {
    height: '14px',
    width: '14px',
    flexShrink: 0,
    padding: '0px',
    margin: '6px 10px 0px 0px',
  },
}));

interface AvatarProps {
  initials: string;
  color: string;
}

const Avatar = ({ initials, color }: AvatarProps) => {
  const classes = useStyles();

  return (
    <Box
      width={40}
      height={40}
      borderRadius='50%'
      bgcolor={color}
      marginLeft={1}
      marginRight={1}
    >
      <Grid
        className={classes.avatarContainer}
        container
        justify='center'
        alignItems='center'
      >
        <Grid item>
          <Typography className={classes.initials}>{initials}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export interface Comment {
  initials: string;
  color: string;
  text: string;
}

export interface CommentsProps {
  comments: WorkDoneNote[];
  userInitials: string;
  currentUserComment: string;
  onUserCommentChange: (newComment: string) => void;
  submit: () => void;
}

export const Comments = ({
  comments,
  userInitials,
  currentUserComment,
  onUserCommentChange,
  submit,
}: CommentsProps) => {
  const classes = useStyles();
  const userColor = '#067B27';
  const msColor = '#043874';

  const [edit, setEdit] = useState<boolean>(currentUserComment === '');

  return (
    <Box maxWidth={720}>
      {comments.map((comment, index) => (
        <Box marginTop={'20px'} key={index}>
          <Grid container>
            <Avatar
              initials={
                comment.user === 'ms' ? 'MS' : userInitials.toUpperCase()
              }
              color={comment.user === 'ms' ? msColor : userColor}
            />
            <Grid item xs={10}>
              <Typography component='div' className={classes.commentText}>
                {comment.text}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}

      {!edit && (
        <Box marginTop={'20px'}>
          <Grid container direction={'row'}>
            <Avatar initials={userInitials.toUpperCase()} color={userColor} />
            <Grid item xs={10}>
              <Typography component='div' className={classes.commentText}>
                {currentUserComment}
              </Typography>
              <Box className={classes.editButton}>
                <ButtonLink text={'Edit'} onClick={() => setEdit(true)} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {edit && (
        <Box marginTop={'20px'}>
          <Grid container>
            <Avatar initials={userInitials.toUpperCase()} color={userColor} />
            <Grid container alignItems='center' item xs={8} direction={'row'}>
              <FormControl fullWidth variant='outlined'>
                <TextField
                  fullWidth
                  size='small'
                  value={currentUserComment}
                  onChange={(event) => onUserCommentChange(event.target.value)}
                  inputProps={{ className: classes.commentInput }}
                  label='type your reply'
                  variant='outlined'
                />
              </FormControl>
              <Button
                variant='outlined'
                color='primary'
                disabled={currentUserComment.trim() === ''}
                onClick={() => {
                  submit();
                  setEdit(false);
                }}
                className={classes.saveButton}
              >
                Save
              </Button>
            </Grid>
            <Box className={classes.horizontalInstructions}>
              <Info className={classes.icon} />

              <Typography className={classes.IRSText}>
                Messages will be sent when you press the “Submit for review”
                button
              </Typography>
            </Box>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
