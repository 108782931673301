import _ from 'lodash';
import { FeatureFlagSet } from '../lib/constants/featureFlagConstants';

export const FeatureFlagOverrideHeader = 'x-feature-flag-override';

/**
 * Helper to turn the local feature flag overrides into a header to be attached
 * to HTTP requests to the API. Works in conjunction with the server side
 * override utilities:
 * http://github.com/MainStreet-Work/monorepo/blob/main/featureFlags/node/src/overrides.ts#L8-L8
 *
 * enabling the client's local overrides to be propagated to the server for the
 * scope of a given request
 */
export const buildFlagOverrideHeader: (
  overrides: FeatureFlagSet,
) => Record<string, string> = (overrides: FeatureFlagSet) => {
  const empty: Record<string, string> = {};

  const overrideVal: Context = Object.entries(overrides).map(([key, val]) => {
    // We only support strings currently!
    const value = typeof val === 'string' ? val : JSON.stringify(val);
    return { key, value };
  });

  if (_.isEmpty(overrideVal)) {
    return empty;
  } else {
    return {
      [FeatureFlagOverrideHeader]: JSON.stringify(overrideVal),
    };
  }
};

interface ContextEntry {
  key: string;
  value: string;
}

type Context = ContextEntry[];
