/**
 * A wrapper to be used with the useEffect() hook to ensure that your hook
 * code only runs when the component is mounted. Example:
 *
 * useEffect(() => {
 *  return withIsMountedCheck((isMounted) => {
 *    if (isMounted()){
 *      setMyState(...)
 *    }
 *  })
 * })
 */
function withIsMountedCheck(
  hookCode: (isMounted: () => boolean) => void,
  abortController?: AbortController,
): () => void {
  const _abortController: AbortController =
    abortController || new AbortController();
  hookCode(() => !_abortController.signal.aborted);
  return () => _abortController.abort();
}

export default withIsMountedCheck;
