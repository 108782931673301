import { Page, ProgramNameEnum } from '../../../../../lib/constants';

export const pageToProgramNameMap: Partial<Record<Page, ProgramNameEnum>> = {
  [Page.retirementPlan]: ProgramNameEnum.FED_RETIREMENT_CREDIT,
  [Page.healthcare]: ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
  [Page.expenseClassificationCompanyDetails]: ProgramNameEnum.FED_RD_TAX,
  [Page.expenseClassificationSuppliesServices]: ProgramNameEnum.FED_RD_TAX,
  [Page.expenseClassificationEmployees]: ProgramNameEnum.FED_RD_TAX,
  [Page.disabledAccess]: ProgramNameEnum.FED_DISABLED_ACCESS,
};

export const surveyNameMapping: Partial<Record<Page, string>> = {
  [Page.assessmentBusinessDetails]: 'General Business Details',
  [Page.retirementPlan]: 'Retirement Plan Credit',
  [Page.healthcare]: 'Small Business Healthcare Tax Credit',
  [Page.expenseClassificationCompanyDetails]: 'R&D Credit - Company Details',
  [Page.expenseClassificationSuppliesServices]:
    'R&D Credit - Supplies and Services',
  [Page.expenseClassificationEmployees]: 'R&D Credit - Employees',
  [Page.disabledAccess]: 'Disabled Access Credit',
};

export const setInviteModalTitle = (
  adminName: string,
  pathname: string,
  taxYear: number,
): string | undefined => {
  const surveyName = surveyNameFromPathName(pathname, taxYear);

  if (surveyName === undefined) {
    return `Hi! ${adminName} asked for your help claiming tax credits with MainStreet.`;
  }

  const surveyNameToTitle: Partial<Record<Page, string>> = {
    [Page.assessmentBusinessDetails]: `Hi! ${adminName} asked for your help claiming tax credits with MainStreet.`,
    [Page.retirementPlan]: `Hi! ${adminName} asked for your help claiming a Retirement Plan Tax Credit with MainStreet.`,
    [Page.healthcare]: `Hi! ${adminName} asked for your help claiming a Healthcare Tax Credit with MainStreet.`,
    [Page.expenseClassificationCompanyDetails]: `Hi! ${adminName} asked for your help claiming an R&D Tax Credit with MainStreet.`,
    [Page.expenseClassificationSuppliesServices]: `Hi! ${adminName} asked for your help claiming an R&D Tax Credit with MainStreet.`,
    [Page.expenseClassificationEmployees]: `Hi! ${adminName} asked for your help claiming an R&D Tax Credit with MainStreet.`,
    [Page.disabledAccess]: `Hi! ${adminName} asked for your help claiming a Disabled Access Tax Credit with MainStreet`,
  };

  return surveyNameToTitle[surveyName];
};

export const setInviteModalText = (
  companyName: string,
  pathname: string,
  taxYear: number,
): string | undefined => {
  const surveyName = surveyNameFromPathName(pathname, taxYear);

  if (surveyName === undefined) {
    return `On the next page, you will be asked to confirm some information about ${companyName}.`;
  }

  const surveyNameToText: Partial<Record<Page, string>> = {
    [Page.assessmentBusinessDetails]: `On the next page, you will be asked to confirm some general information about ${companyName}’s structure and history.`,
    [Page.retirementPlan]: `On the next page, you will be asked to provide some details about ${companyName}’s retirement plan, and how employees participate in that plan.`,
    [Page.healthcare]: `On the next page, you will be asked to provide some details about ${companyName}’s employees and healthcare plan(s).`,
    [Page.expenseClassificationCompanyDetails]: `On the next page, you will be asked to share ${companyName}’s history and tax returns.`,
    [Page.expenseClassificationSuppliesServices]: `On the next page, you will be asked to provide some details about ${companyName}’s R&D projects and expenses during the ${taxYear} tax year.`,
    [Page.expenseClassificationEmployees]: `On the next page, you will be asked to identify how much of ${companyName}’s employees’ and contractors’ time was spent on R&D activities during the ${taxYear} tax year.`,
    [Page.disabledAccess]: `On the next page, you will be asked to provide some information about how ${companyName} invested in accessibility during the ${taxYear} tax year.`,
  };
  return surveyNameToText[surveyName];
};

const surveyNameFromPathName = (
  pathname: string,
  taxYear: number,
): Partial<Page> | undefined => {
  const path = pathname.split(`${taxYear}`)[0];
  const resource = pathname.split(`${taxYear}`)[1];

  if (!path.includes('/tax-credits/assessment/')) return;

  if (resource.length === 0) return Page.assessmentBusinessDetails;
  if (resource.includes(Page.retirementPlan)) return Page.retirementPlan;
  if (resource.includes(Page.healthcare)) return Page.healthcare;
  if (resource.includes(Page.expenseClassificationCompanyDetails))
    return Page.expenseClassificationCompanyDetails;
  if (resource.includes(Page.expenseClassificationSuppliesServices))
    return Page.expenseClassificationSuppliesServices;
  if (resource.includes(Page.expenseClassificationEmployees))
    return Page.expenseClassificationEmployees;
  if (resource.includes(Page.disabledAccess)) return Page.disabledAccess;
};
