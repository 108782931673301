import { BaseNamespace } from 'stores/BaseNamespace';
import {
  OrderFormStore,
  CreditEstimateStore,
  RedemptionStore,
  ERCStore,
  RetirementPlanStore,
} from 'products/tax-credits/stores';
import { Form8974Store } from '../../stores/taxcredits/Form8974Store';
import { RDQualificationStore } from '../../stores/taxcredits/RDQualificationStore';
import { TaxCreditsPageStore } from '../../stores/taxcredits/TaxCreditsPageStore';
import { SurveyFlowStore } from './stores/SurveyFlowStore';
import { UnifiedTaxCreditsStore } from './stores/UnifiedTaxCreditsStore';

export class TaxCreditsNamespace extends BaseNamespace {
  public form8974 = new Form8974Store(this.rootStore);
  public rdQualification = new RDQualificationStore(this.rootStore);
  public taxCreditsPage = new TaxCreditsPageStore(this.rootStore);
  public orderForms = new OrderFormStore(this.rootStore);
  public creditEstimates = new CreditEstimateStore(this.rootStore);
  public redemption = new RedemptionStore(this.rootStore);
  public erc = new ERCStore(this.rootStore);
  public retirement = new RetirementPlanStore(this.rootStore);
  public surveyFlow = new SurveyFlowStore(this.rootStore);
  public unifiedTaxCredits = new UnifiedTaxCreditsStore(this.rootStore);
}
