import React from 'react';
import { Content, Text, TextField } from 'component-library';
import { Address, BusinessApplicationDataDraft } from 'lib/interfaces';
import { AddressInput } from '.';

type CompanyInfoApplicationProps = {
  companyInfo: BusinessApplicationDataDraft;
  setCompanyInfo: React.Dispatch<
    React.SetStateAction<BusinessApplicationDataDraft>
  >;
};

export const CompanyInfoApplication = ({
  companyInfo,
  setCompanyInfo,
}: CompanyInfoApplicationProps) => {
  return (
    <Content paddingBottom={16}>
      <Content paddingTopBottom={16}>
        <Text text='General information' size={15} variant='medium' />
      </Content>

      <Content flex paddingLeftRight={0} paddingBottom={16}>
        <div style={{ flex: 1 }}>
          <TextField
            label='Legal company name'
            small
            value={companyInfo?.name ?? ''}
            onChange={(e) => {
              setCompanyInfo({
                ...companyInfo,
                name: e.target.value,
              });
            }}
          />
        </div>
        <TextField
          label='Company phone'
          placeholder='(000) 000-0000'
          small
          value={companyInfo.phoneNumber}
          phoneNumber={true}
          onChange={(e) => {
            setCompanyInfo({
              ...companyInfo,
              phoneNumber: e.target.value,
            });
          }}
        />
      </Content>

      <Content flex paddingLeftRight={0} paddingBottom={16}>
        <div style={{ flex: 1 }}>
          <TextField
            label='Tax ID/EIN'
            placeholder='XX-XXXXXXX'
            small
            value={companyInfo.tin}
            onChange={(e) => {
              setCompanyInfo({
                ...companyInfo,
                tin: e.target.value,
              });
            }}
            einNumberFormat={true}
          />
        </div>
        <div style={{ flex: 1 }}></div>
      </Content>

      <Content paddingLeftRight={0} paddingTopBottom={16}>
        <Text text='Business address' size={15} variant='medium' />
      </Content>

      <AddressInput
        currentAddressInfo={{ ...companyInfo.physicalAddress }}
        setNewAddress={({
          field,
          newValue,
        }: {
          field: keyof Address;
          newValue: Address[keyof Address];
        }) => {
          setCompanyInfo((companyInfo) => {
            const address: Partial<Address> =
              companyInfo?.physicalAddress ?? {};

            return {
              ...companyInfo,
              physicalAddress: {
                ...address,
                [field]: newValue,
              },
            };
          });
        }}
      />
    </Content>
  );
};
