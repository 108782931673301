import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { ClaimBanner } from './ClaimBanner';
import {
  Alert,
  Button,
  Card,
  Color,
  Divider,
  Expandable,
  Flex,
  Icon,
  IconEnum,
  Image,
  Message,
  SideDrawer,
  Text,
  Toast,
  Tooltip,
} from 'component-library';
import { ActivityTimelineSideDrawer } from './ActivityTimelineSideDrawer';
import { useCommonStores, useTaxCreditsStores } from 'stores/useStores';
import { HasManuallyAcceptedOrderForm, useEffectOnce } from 'lib/helpers';
import {
  AssessmentProgressStatus,
  TaxCreditAssessmentGeneralHygraphId,
} from 'lib/interfaces';
import { ExpectedCreditTypeEnum } from 'lib/constants';
import TaxStatus from './taxStatus/TaxStatus';

const useStyles = makeStyles(() => ({
  title: {
    whiteSpace: 'nowrap',
  },
  timelineBtn: {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
  },
  showBreakdownBtn: {
    height: 'auto',
    padding: '8px 0 0',
  },
  showBreakdownIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform .2s',
  },
  breakdownIconClose: {
    transform: 'rotate(-180deg)',
  },
  reviewInfo: {
    border: `1px solid ${Color.blue._50}`,
    backgroundColor: Color.blue._10,
    borderRadius: '4px',
  },
  infoAlert: {
    padding: `0 !important`,
  },
  creditBreakdown: {
    backgroundColor: Color.neutral._10,
  },
}));

interface ReviewInProgressProps {
  taxYear: number;
}

export const ReviewInProgress = observer(
  ({ taxYear }: ReviewInProgressProps) => {
    const classes = useStyles();
    const { app } = useCommonStores();
    const { unifiedTaxCredits } = useTaxCreditsStores();
    const [toast, setToast] = useState<Message[]>([]);
    const [hasManuallySignedOrderForm, setHasManuallySignedOrderForm] =
      useState(false);
    const [partnerChargePercentage, setPartnerChargePercentage] = useState<
      string | null
    >(null);
    const partnerFinalCreditAmountText =
      unifiedTaxCredits.getPartnerFinalCreditAmountText();

    useEffectOnce(() =>
      setToast([
        {
          text: 'Assessment submitted for review',
          status: 'success',
        },
      ]),
    );

    useEffect(() => {
      const hasManuallyAcceptedOrderForm = async () => {
        setHasManuallySignedOrderForm(
          HasManuallyAcceptedOrderForm(app.company.orderForms),
        );
      };

      hasManuallyAcceptedOrderForm();
    }, []);

    useEffect(() => {
      const fetchPartnerChargePercentage = async () => {
        const percentage = await unifiedTaxCredits.getPartnerChargePercentage();
        setPartnerChargePercentage(percentage);
      };

      fetchPartnerChargePercentage();
    }, [unifiedTaxCredits]);

    return (
      <>
        <ClaimBanner
          title={<>You&apos;re a step closer to claiming your credits!</>}
          subtitle={
            'Stay tuned as we verify the details of your submission. This careful examination is the next crucial step to maximize your tax benefits.'
          }
        />
        <Card noMargin key={`${taxYear}-assessments-in-review`}>
          <Flex direction='column' gap={16} padding={24}>
            <Flex
              justifyContent='space-between'
              alignItems='center'
              wrap='wrap'
            >
              <Flex
                gap={4}
                alignItems='center'
                justifyContent='flex-start'
                className={classes.title}
                basis={315}
              >
                <Text variant='medium' size={18}>
                  {taxYear} Assessment in review
                </Text>

                <Icon
                  name={IconEnum.question_circle}
                  onClick={() =>
                    unifiedTaxCredits.setShowInReviewTitleSideDrawer(true)
                  }
                />
              </Flex>
              <Button
                className={classes.timelineBtn}
                label={
                  <Flex gap={4}>
                    <Icon name={IconEnum.clock_circle} color={Color.blue._60} />{' '}
                    <Text color={Color.blue._60}>View activity timeline</Text>
                  </Flex>
                }
                flexAlignSelf='flex-end'
                onClick={() =>
                  unifiedTaxCredits.setShowTimelineSideDrawer(true)
                }
                variant='tertiary'
                small
              />
            </Flex>
            <Text>
              This review phase involves a thorough check of your submitted
              details against tax credit qualifications and compliance
              requirements.
              <Button
                className={classes.showBreakdownBtn}
                variant='tertiary'
                onClick={() =>
                  unifiedTaxCredits.setShowExpandCreditBreakdown(
                    !unifiedTaxCredits.showExpandCreditBreakdown,
                  )
                }
                label={
                  <>
                    {unifiedTaxCredits.showExpandCreditBreakdown
                      ? 'Hide'
                      : 'Show'}{' '}
                    estimated credits{' '}
                    <Icon
                      className={`${classes.showBreakdownIcon} ${
                        unifiedTaxCredits.showExpandCreditBreakdown
                          ? classes.breakdownIconClose
                          : ''
                      }`}
                      name={IconEnum.chevron_down}
                      color={Color.blue._60}
                    />
                  </>
                }
                flexAlignSelf='flex-start'
              />
            </Text>
            <Expandable expand={unifiedTaxCredits.showExpandCreditBreakdown}>
              {unifiedTaxCredits.assessmentByYear[taxYear] && (
                <Flex
                  direction='column'
                  gap={16}
                  padding={[12, 16]}
                  className={classes.creditBreakdown}
                >
                  {unifiedTaxCredits.assessmentByYear[taxYear].map(
                    (credit, index) => {
                      if (
                        credit.id !==
                          TaxCreditAssessmentGeneralHygraphId.GENERAL_BUSINESS_DETAILS &&
                        (credit.status === AssessmentProgressStatus.MS_REVIEW ||
                          credit.status ===
                            AssessmentProgressStatus.CLIENT_REVIEW)
                      ) {
                        const creditTypeText =
                          credit.creditType ===
                          ExpectedCreditTypeEnum.PAYROLL_TAX
                            ? 'Payroll tax credit'
                            : 'Income tax credit';

                        const tooltipText =
                          credit.creditType ===
                          ExpectedCreditTypeEnum.PAYROLL_TAX
                            ? 'Reduces your quarterly payroll taxes you owe to the IRS. Think of it as a discount on your labor cost.'
                            : 'Reduces the amount of income tax you owe to the IRS. Think of it as a discount on your tax bill.';

                        return (
                          <React.Fragment key={`${credit.id}-${index}`}>
                            <Flex justifyContent='space-between'>
                              <Flex gap={8}>
                                <Text>{credit.assessmentTitle} estimate</Text>
                                <Tooltip text={tooltipText}>
                                  <Text color={Color.purple._70}>
                                    &bull; {creditTypeText}
                                  </Text>
                                </Tooltip>
                              </Flex>
                              <Text variant='medium'>
                                {credit.creditAmount}
                              </Text>
                            </Flex>

                            {index !==
                            unifiedTaxCredits.assessmentByYear[taxYear].length -
                              1 ? (
                              <Divider variant='no-bottom-margin' />
                            ) : (
                              <></>
                            )}
                          </React.Fragment>
                        );
                      }
                    },
                  )}
                </Flex>
              )}
            </Expandable>
            <Flex className={classes.reviewInfo} padding={[16, 12]} gap={12}>
              <Flex.Cell>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/tax-credit-dashboard-module/reviewing-info.svg`}
                />
              </Flex.Cell>
              <Flex direction='column' gap={4}>
                <Text variant='medium' size={18}>
                  Reviewing your information
                </Text>
                <Text size={13}>
                  Our tax credit specialists will review your information and
                  reach out in 2-4 weeks with further questions or a finalized
                  credit. We charge {partnerChargePercentage}{' '}
                  {partnerFinalCreditAmountText}.
                </Text>
              </Flex>
            </Flex>
            {!hasManuallySignedOrderForm && (
              <Alert
                text='We automatically charge you after reviewing and delivering your tax forms.'
                type='info'
                variant='in_card'
              />
            )}
          </Flex>
        </Card>
        <TaxStatus />
        <ActivityTimelineSideDrawer
          taxYear={taxYear.toString()}
          stepIndex={3}
        />
        <SideDrawer
          show={unifiedTaxCredits.showInReviewTitleSideDrawer}
          title={`${taxYear} Assessment in review`}
          closeToggle={() =>
            unifiedTaxCredits.setShowInReviewTitleSideDrawer(false)
          }
          drawerContent={
            <Flex direction='column' gap={16}>
              <Text variant='medium'>What&apos;s this step?</Text>
              <Divider variant='no-bottom-margin' />
              <Text>
                Our team will review all the information you provided to
                carefully calculate your credits.
              </Text>
              <Text paddingBottom={24}>
                It&apos;s not uncommon for a MainStreet specialist to request
                additional details during this time. We&apos;ll alert you if we
                need more information. Please respond quickly to these requests
                so we can complete our review.
              </Text>
              <Text variant='medium'>How long will it take?</Text>
              <Divider variant='no-bottom-margin' />
              <Text>
                This process typically takes between 2 and 4 weeks. You can
                speed this along by promptly responding to any requests for
                further information from our specialists.
              </Text>
            </Flex>
          }
        />
        {unifiedTaxCredits.hasJustCompletedAllAssessments && (
          <Toast
            toasts={toast}
            setToast={setToast}
            onToastDisappear={() =>
              unifiedTaxCredits.setHasJustCompletedAllAssessments(false)
            }
          />
        )}
      </>
    );
  },
);
