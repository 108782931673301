import LoadingWidget from 'components/util/LoadingWidget';
import { handleErrorOrDataCallback } from 'lib/helpers';
import { Document, ProgramData } from 'lib/interfaces';

import React, { useContext, useEffect, useState } from 'react';
import { ValidateAccountantAccessLink } from 'services/server';

import { AccountantDocumentsContainer } from './AccountantDocumentsContainer';
import { AccountantDocuments } from './AccountantDocuments';
import { AccountantDocumentsError } from './AccountantDocumentsError';
import { Auth0FeatureContext } from '../../../../components/util/Auth0Feature';

interface Props {
  programId: number;
  companyEmail: string;
  token: string;
}

export const AccountantDocumentsPage = ({
  programId,
  companyEmail,
  token,
}: Props) => {
  const { client } = useContext(Auth0FeatureContext);

  const [program, setProgram] = useState<ProgramData | undefined>(undefined);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [companyName, setCompanyName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!programId) {
      return;
    }
    ValidateAccountantAccessLink(programId, companyEmail, token).then((res) =>
      handleErrorOrDataCallback(
        res,
        (data) => {
          setProgram(data.program);
          setCompanyName(data.companyName);
        },
        setError,
      ),
    );
  }, [programId, companyEmail, token]);

  useEffect(() => {
    if (program) {
      client
        .GetProgramDocuments(program.id, 'mainstreet', companyEmail, token)
        .then((res) =>
          handleErrorOrDataCallback(
            res,
            (data) => setDocuments(data.documents),
            setError,
          ),
        );
    }
  }, [program, companyEmail, token, client]);

  if (error) {
    return (
      <AccountantDocumentsContainer>
        <AccountantDocumentsError />
      </AccountantDocumentsContainer>
    );
  }

  return program === undefined || documents.length === 0 ? (
    <LoadingWidget />
  ) : (
    <AccountantDocumentsContainer>
      <AccountantDocuments
        program={program}
        documents={documents}
        companyName={companyName}
        token={token}
        companyEmail={companyEmail}
      />
    </AccountantDocumentsContainer>
  );
};
