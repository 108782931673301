import { useTransition } from 'react-spring';
import { History, Location } from 'history';

export const useSpringPageLocationTransition = (
  location: Location<History.PoorMansUnknown>,
  currentStep: number,
  previousStep: number,
  useFade?: boolean,
) => {
  // react-spring page transition based on pathname location
  const transitions = useTransition(location, {
    initial: { opacity: 1 },
    from: {
      opacity: 0,
      transform: useFade
        ? 'translate3d(0%,0,0)'
        : `translate3d(${(currentStep - previousStep) * 100}%,0,0)`,
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)',
    },
    leave: {
      opacity: 0,
      transform: useFade
        ? 'translate3d(0%,0,0)'
        : `translate3d(${(previousStep - currentStep) * 50}%,0,0)`,
      position: 'absolute',
    },
  });

  return { transitions };
};

export const useSpringPageIndexTransition = (
  currentStep: number,
  previousStep: number,
  useFade?: boolean,
) => {
  // react-spring page transition based on index of array
  const transitions = useTransition(currentStep, {
    initial: { opacity: 1 },
    from: {
      opacity: 0,
      transform: useFade
        ? 'translate3d(0%,0,0)'
        : `translate3d(${(currentStep - previousStep) * 100}%,0,0)`,
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)',
    },
    leave: {
      opacity: 0,
      transform: useFade
        ? 'translate3d(0%,0,0)'
        : `translate3d(${(previousStep - currentStep) * 50}%,0,0)`,
      position: 'absolute',
    },
  });

  return { transitions };
};
