import React from 'react';
import { Button, Flex, Link, Text } from 'component-library';
import { useCommonStores, useTaxCreditsStores } from 'stores/useStores';
import { observer } from 'mobx-react';
import {
  ExpectedCreditTypeEnum,
  PayrollTierEnum,
  ALL_PAYROLL_SYSTEMS,
  CarryoverAndAdjustStatusEnum,
  Page,
} from 'lib/constants';
import { ProgramData } from 'lib/interfaces';
import { formatPayrollString, useEffectOnce } from 'lib/helpers';

interface TaxCreditsBalanceCardContentProps {
  program: ProgramData;
}

export const TaxCreditsBalanceCardContent = observer(
  ({ program }: TaxCreditsBalanceCardContentProps) => {
    const { form8974, redemption } = useTaxCreditsStores();
    const { app } = useCommonStores();
    const isPayrollTax =
      program.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX;
    const payrollProvider =
      ALL_PAYROLL_SYSTEMS.find((p) => p.id === form8974.payrollProvider)
        ?.displayName || formatPayrollString(form8974.payrollProvider);

    const isAllPayrollProviderSetupCompleted8974True = form8974.programs.every(
      (p) => p.payrollProviderSetupCompleted8974 === true,
    );

    useEffectOnce(() => {
      redemption.checkExistingDocumentURL();
    });

    // if customer does not check that they have confirmed
    // downloading their generated forms from missed redemption flow
    // notifying them to return to download the forms
    if (
      program &&
      redemption.quarterlyFor8974DocumentUrl.missedQuarters.length > 0 &&
      redemption.filingStatus === CarryoverAndAdjustStatusEnum.REDEEMED &&
      !isAllPayrollProviderSetupCompleted8974True
    ) {
      return (
        <Flex direction='column' padding={[0, 0, 8, 0]} gap={8}>
          <Text
            text={`Please confirm that you have downloaded the generated ${
              redemption.quarterlyFor8974DocumentUrl.missedQuarters.length > 1
                ? 'forms'
                : 'form'
            } now so you can redeem your credits on time.`}
          />
          <Button
            label={'Download'}
            flexAlignSelf='flex-start'
            small
            onClick={() =>
              app.history.push(
                `/${Page.taxCredits}/${program.id}/${Page.missedQuarterlyRedemptionPage}/${Page.quarterlyRedemptionDownloadPage}`,
              )
            }
          />
        </Flex>
      );
    }

    if (!isPayrollTax) {
      /* Still need to determine at what point we should no longer show this message.
       * A Figma mockup exists after this case where the message is hidden,
       * but the requirements for that specific case are not defined.
       * */
      return (
        <Text
          text={`Your credits were redeemed to lower your ${program.taxYear} income tax liability. You can carry any remaining credits forward to offset future taxes!`}
        />
      );
    }

    if (
      form8974.isNotTimeForTier2Or3ToGenerate8974 &&
      (redemption.filingStatus ===
        CarryoverAndAdjustStatusEnum.TAXES_FILED_TOO_RECENTLY ||
        redemption.filingStatus === CarryoverAndAdjustStatusEnum.REDEEMED) &&
      form8974.creditBalanceCents !== 0
    ) {
      return (
        <Text>
          Credits can be redeemed next quarter. We&apos;ll let you know when
          it&apos;s time to download and file the forms.{' '}
          <Link
            variant='medium'
            href={
              'https://mainstreet-help.force.com/help/s/topic/0TO5f000000I96tGAC/quarterly-filing-documents'
            }
            external
          >
            More details
          </Link>
        </Text>
      );
    }

    if (
      (form8974.payrollTier === PayrollTierEnum.TIER_1 ||
        form8974.payrollTier === PayrollTierEnum.TIER_2) &&
      program.payrollProviderSetupCompleted8974
    ) {
      return (
        <Flex direction='column' padding={[0, 0, 8, 0]} gap={8}>
          <Text
            text={`No further actions at this time! Once you've submitted all necessary information to ${payrollProvider}, they'll redeem your credits on your behalf.`}
          />
          <Link
            variant='medium'
            href={
              'https://mainstreet-help.force.com/help/s/topic/0TO5f000000I96tGAC/quarterly-filing-documents'
            }
            external
          >
            More details
          </Link>
        </Flex>
      );
    }

    if (
      form8974.payrollTier === PayrollTierEnum.TIER_3 &&
      isAllPayrollProviderSetupCompleted8974True
    ) {
      return (
        <Flex direction='column' padding={[0, 0, 8, 0]} gap={8}>
          <Text
            text={
              "You've downloaded this quarter's Form 8974. Please remember to file it with the IRS, then come back next quarter to redeem remaining credits."
            }
          />
          <Link
            variant='medium'
            href={
              'https://mainstreet-help.force.com/help/s/topic/0TO5f000000I96tGAC/quarterly-filing-documents'
            }
            external
          >
            More details
          </Link>
        </Flex>
      );
    }

    if (form8974.payrollTier === PayrollTierEnum.TIER_1) {
      return (
        <Flex direction='column' alignItems='start'>
          <Flex padding={[0, 0, 8, 0]}>
            <Text>
              Your payroll system can redeem these credits on the next
              quarter&apos;s payroll taxes.{' '}
              <Link
                variant='medium'
                href={
                  'https://mainstreet-help.force.com/help/s/topic/0TO5f000000I96tGAC/quarterly-filing-documents'
                }
                external
              >
                More details
              </Link>
            </Text>
          </Flex>
          <Button
            label='Set up redemption'
            onClick={() => form8974.toggleSideDrawer(true)}
            small
            dataTestId='redemption-button'
          />
        </Flex>
      );
    }

    if (
      redemption.filingStatus ===
        CarryoverAndAdjustStatusEnum.PROGRAM_LEDGER_SETUP_REQUIRED &&
      form8974.payrollTier === PayrollTierEnum.TIER_3
    ) {
      return (
        <Flex direction='column' alignItems='start'>
          <Flex padding={[0, 0, 8, 0]}>
            <Text>
              We will resume your redemption after you start the claiming
              process for this tax year
            </Text>
          </Flex>
        </Flex>
      );
    }

    // else, must be tier 2 or 3 and ready to generate 8974
    return (
      <Flex direction='column' alignItems='start'>
        <Flex padding={[0, 0, 8, 0]}>
          <Text>
            <Text
              tag='span'
              text={`To redeem your credits for this quarter, you'll need to file a new form with the IRS. ${
                redemption.filingStatus ===
                CarryoverAndAdjustStatusEnum.AVAILABLE
                  ? 'Download Form 8974 now so you can redeem your credits on time.'
                  : ''
              }`}
            />
            <Text tag='span'>
              <Link
                variant='medium'
                href={
                  'https://mainstreet-help.force.com/help/s/topic/0TO5f000000I96tGAC/quarterly-filing-documents'
                }
                external
              >
                More details
              </Link>
            </Text>
          </Text>
        </Flex>
        {form8974.payrollTier !== PayrollTierEnum.INCOME_TIER && (
          <>
            {(redemption.filingStatus ===
              CarryoverAndAdjustStatusEnum.MISSED_FILING ||
              redemption.filingStatus ===
                CarryoverAndAdjustStatusEnum.PROJECTED_MISSED_FILING) &&
            redemption.showMissedDeadlineAlert ? (
              <Button
                label='Set up redemption'
                onClick={() => redemption.setShowVerifyFilingDateModal(true)}
                small
                dataTestId='set-up-redemption-button'
              />
            ) : (
              <Button
                label='Form 8974'
                onClick={() => {
                  form8974.toggleSideDrawer(true);
                  form8974.GetForm8974URL();
                }}
                small
                dataTestId='download-button'
              />
            )}
          </>
        )}
      </Flex>
    );
  },
);
