import React from 'react';
import { RouteConfiguration } from 'routes/RouteConfiguration';
import { Page } from 'lib/constants';
import { DocumentsPage } from 'pages/dashboard/documents';
import { Dashboard } from 'pages/dashboard';
import { useFeatureFlags } from 'stores/useStores';
import HttpErrorPage from 'pages/HttpErrorPage';
import { AccountCreation } from 'pages/account-creation';
import { CompanyDetails } from 'pages/onboarding';
import { UnifiedAccountCreation } from 'products/onboarding/features/unified-account-creation';
import { UnifiedCreditEstimates } from 'products/onboarding/features/unified-account-creation/steps/UnifiedCreditEstimatesStep';
import { PayrollAccountingConnection } from 'products/onboarding/features/unified-account-creation/component';

export const useCoreExperienceRoutes = (): RouteConfiguration[] => {
  const featureFlags = useFeatureFlags();

  return [
    {
      path: `/`,
      render: () => <Dashboard />,
      documentPageTitle: 'Dashboard',
    },
    {
      path: `/${Page.companyDocs}`,
      render: () => <DocumentsPage />,
      documentPageTitle: 'Documents',
    },
    {
      path: `/${Page.accountCreationRoot}`,
      render: () =>
        featureFlags.showAccountCreationFlow ? (
          <>
            {featureFlags.showNewUnifiedAccountCreation ? (
              <UnifiedAccountCreation />
            ) : (
              <AccountCreation />
            )}
          </>
        ) : (
          <HttpErrorPage errorCode={404} />
        ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
      documentPageTitle: 'Account creation',
    },
    {
      path: `/${Page.accountCreationCreditEstimate}`,
      render: () =>
        featureFlags.showAccountCreationFlow ? (
          <UnifiedCreditEstimates />
        ) : (
          <HttpErrorPage errorCode={404} />
        ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
      documentPageTitle: 'Eligible Tax Credits',
    },
    {
      path: `/${Page.accountCreationConnectingPayrollAccounting}`,
      render: () =>
        featureFlags.showAccountCreationFlow ? (
          <PayrollAccountingConnection />
        ) : (
          <HttpErrorPage errorCode={404} />
        ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
      documentPageTitle: 'Eligible Tax Credits',
    },
    {
      path: `/${Page.selfSignupCompanyDetails}`,
      render: () => <CompanyDetails />,
      hideTopNav: true,
      hideSideNav: true,
      bypassMobilePrint: true,
      documentPageTitle: 'Company details',
    },
    // Deprecated Route / Redirects
    {
      path: `/billing`,
      redirectPath: '/',
      render: () => <Dashboard />,
      documentPageTitle: 'Dashboard',
    },
  ];
};
