import { BaseStore } from 'stores/BaseStore';
import { DashboardAPI } from './DashboardAPI';

export class BaseDashboardStore extends BaseStore {
  public api = new DashboardAPI(this.rootStore);

  /**
   * Shortcut to the Dashboard namespace
   */
  public get dashboard() {
    return this.rootStore.dashboard;
  }
}
