import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Animate, Flex, Spinner } from 'component-library';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import { ERCQualifyingQuestions, SurveyNameEnum } from 'lib/constants';
import { ERCTaxReturnUploadQuestion } from '../ERCTaxReturnUploadQuestion';
import _ from 'lodash';
import { useTaxCreditsStores } from 'stores/useStores';
import { SurveyFlowContainer } from 'products/tax-credits/components';

interface UploadPayrollReturnsProps {
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
  isPrefilled: boolean;
}

export const UploadPayrollReturns = observer(
  ({
    pathTo,
    pathFrom,
    onNext,
    onBack,
    isPrefilled,
  }: UploadPayrollReturnsProps) => {
    const { erc } = useTaxCreditsStores();
    const history = useHistory();

    const {
      isSurveyComplete,
      isLoading,
      questionsToRender,
      surveyAnswers,
      addSurveyAnswers,
      saveAnswers,
    } = useSurveyQuestions(SurveyNameEnum.ERC_UPLOAD_PAYROLL_TAX_RETURN, 2021);

    const handleOnContinue = () => {
      onNext();
      history.push(pathTo);
    };

    const handleOnBack = () => {
      onBack();
      history.push(pathFrom);
    };

    const renderQuestionContent = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => {
          return (
            <Animate
              key={question.id}
              enter={['fade-in', 'from-bottom']}
              duration={0.5}
            >
              <ERCTaxReturnUploadQuestion
                key={`${key}-${question.id}`}
                addSurveyAnswers={addSurveyAnswers}
                subGroupName={key}
                activeQuestion={question}
                saveAnswers={saveAnswers}
                documentDescription={`ERC - Upload 2021 payroll tax return`}
                type='payroll'
              />
            </Animate>
          );
        });
      });

    useEffect(() => {
      const q3uploaded2021TaxReturn =
        surveyAnswers[ERCQualifyingQuestions.UPLOAD_Q3_2021];
      const q4uploaded2021TaxReturn =
        surveyAnswers[ERCQualifyingQuestions.UPLOAD_Q4_2021];

      // check if tax return has been uploaded
      if (
        (q3uploaded2021TaxReturn && q3uploaded2021TaxReturn.length === 0) ||
        (q4uploaded2021TaxReturn && q4uploaded2021TaxReturn.length === 0)
      ) {
        erc.setHasUploadedPayrollTaxReturn(false);
      } else {
        erc.setHasUploadedPayrollTaxReturn(true);
      }
    }, [erc, surveyAnswers, saveAnswers]);

    return (
      <SurveyFlowContainer
        title='Upload payroll tax returns'
        subtitle='These forms will be used to generate your final credit documents.'
        onContinue={handleOnContinue}
        onBack={handleOnBack}
        isDisabled={!isSurveyComplete || !erc.hasUploadedPayrollTaxReturn}
        dataTestId='erc-qualify-survey'
      >
        <Flex direction='column' gap={24}>
          {isLoading ? (
            <Spinner size='small' />
          ) : (
            !_.isEmpty(questionsToRender) && renderQuestionContent()
          )}
        </Flex>
      </SurveyFlowContainer>
    );
  },
);
