import * as React from 'react';
import { useState } from 'react';
import {
  makeStyles,
  Button,
  FormHelperText,
  TextField,
} from '@material-ui/core';

import { ValidatedToken } from 'lib/interfaces';
import { ValidatePassword, MIN_PASS_REQS } from 'lib/validation';
import { UpdatePassword } from 'services/server';

const useStyles = makeStyles(() => ({
  root: {},
  inputForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  error: {
    color: 'red',
  },
  textField: {
    margin: '8px',
    width: '300px',
  },
  subtext: {
    margin: '24px 16px',
    maxWidth: '300px',
    color: '#888',
  },
  cta: {
    marginTop: '16px',
    padding: '8px 0',
    width: '300px',
  },
  passwordReq: {
    color: '#888',
    margin: '16px',
  },
}));

interface Props {
  tokenObj: ValidatedToken;
  onComplete: () => void;
}

export default function SetNewPassword({ tokenObj, onComplete }: Props) {
  const classes = useStyles();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [formError, setFormError] = useState<string>('');
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const submit = () => {
    setShowErrors(false);

    const { valid, errorMsg } = ValidatePassword(password, confirmPassword);

    if (!valid) {
      setFormError(errorMsg!);
      setShowErrors(true);
      return;
    }

    setFormError('');

    UpdatePassword({
      adminPassword: password,
      adminPasswordConfirmation: confirmPassword,
      tokenObj: tokenObj,
    }).then((res) => {
      if (!res.success) {
        setFormError(res.errorMsg!);
        return;
      }

      onComplete();
    });
  };

  const submitOnEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      submit();
    }
  };

  return (
    <div>
      <div className={classes.inputForm}>
        <div className={classes.subtext}>
          Resetting the password for {tokenObj.email}
        </div>
        <TextField
          InputLabelProps={{ shrink: true }}
          variant='outlined'
          error={showErrors && (!password || password !== confirmPassword)}
          className={classes.textField}
          label='New Password'
          placeholder='New Password'
          value={password}
          type='password'
          onChange={(event) => setPassword(event.target.value)}
          onKeyDown={submitOnEnter}
        />
        {showErrors && !password ? (
          <FormHelperText error className={classes.error}>
            Please provide a password
          </FormHelperText>
        ) : null}
        <TextField
          InputLabelProps={{ shrink: true }}
          variant='outlined'
          error={
            showErrors && (!confirmPassword || password !== confirmPassword)
          }
          className={classes.textField}
          label='Confirm New Password'
          placeholder='Confirm New Password'
          value={confirmPassword}
          type='password'
          onChange={(event) => {
            if (event.target.value) {
              setFormError('');
            }
            setConfirmPassword(event.target.value);
          }}
          onKeyDown={submitOnEnter}
        />
        {showErrors && !confirmPassword ? (
          <FormHelperText error className={classes.error}>
            Please provide a password
          </FormHelperText>
        ) : null}
        <div className={classes.passwordReq}>
          Password must contain at least:
          <br />
          {MIN_PASS_REQS.join(', ')}
        </div>
        {formError ? (
          <FormHelperText error className={classes.error}>
            {formError}
          </FormHelperText>
        ) : undefined}
        <Button
          className={classes.cta}
          color='primary'
          variant='contained'
          onClick={submit}
        >
          Update Password
        </Button>
      </div>
    </div>
  );
}
