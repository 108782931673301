import React, { createRef, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(({ boxShadowRegular }: Theme) => ({
  title: {
    fontSize: '20px',
  },
  stepTitle: {
    fontSize: '14px',
  },
  stepDescription: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '1.6',
    whiteSpace: 'pre-line',
  },
  button: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
  },
  paper: {
    boxShadow: boxShadowRegular,
  },
}));

export interface Step {
  icon: string;
  title: string;
  description: string;
}

export type StepOrElement = { step?: Step; element?: JSX.Element };

export interface ProgressiveStepperProps {
  title?: string;
  steps: StepOrElement[];
  hideButtonRow?: boolean;
  completeButtonText?: string;
  onCompleteHandler?: () => void;
}

export const ProgressiveStepper = ({
  title,
  steps,
  hideButtonRow,
  completeButtonText = 'Done',
  onCompleteHandler,
}: ProgressiveStepperProps) => {
  const classes = useStyles();
  const [stepsProgressed, setStepsProgressed] = useState<number>(1);
  const [scroll, setScroll] = useState<boolean>(false);

  const stepsComplete = stepsProgressed === steps.length;

  const ref = createRef<HTMLDivElement>();

  const next = () => {
    if (stepsComplete) {
      setScroll(false);
      if (onCompleteHandler) {
        onCompleteHandler();
      }
    } else {
      setScroll(true);
      setStepsProgressed(stepsProgressed + 1);
    }
  };

  useEffect(() => {
    if (scroll && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  });

  return (
    <Paper square className={classes.paper}>
      <Grid container>
        {title && (
          <>
            <Grid item xs={12}>
              <Box
                paddingTop={3}
                paddingBottom={3}
                paddingRight={3}
                paddingLeft={5}
              >
                <Typography className={classes.title}>{title}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}

        {steps.map(({ element, step }, index) => {
          // if last step, don't show Divider
          const isLastStep = index === steps.length - 1;
          if (index < stepsProgressed || hideButtonRow) {
            return (
              <React.Fragment key={index}>
                <Grid item xs={12} key={index}>
                  {!!element && (
                    <Box
                      paddingTop={3}
                      paddingBottom={4}
                      paddingRight={3}
                      paddingLeft={4}
                    >
                      <Grid container>{element}</Grid>
                    </Box>
                  )}
                  {step && (
                    <Box paddingTop={3} paddingBottom={3} paddingRight={3}>
                      <Grid container>
                        <Grid item xs={1}>
                          <Typography variant='h2' align='center'>
                            {step!.icon}
                          </Typography>
                        </Grid>
                        <Grid item xs={11}>
                          <Box paddingBottom={1}>
                            <Typography
                              variant='subtitle1'
                              className={classes.stepTitle}
                            >
                              {step.title}
                            </Typography>
                          </Box>
                          <Typography
                            variant='subtitle2'
                            className={classes.stepDescription}
                          >
                            {step.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Grid>
                {!isLastStep && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </React.Fragment>
            );
          }
          return null;
        })}

        {!hideButtonRow && (
          <Grid item xs={12}>
            <Box paddingLeft='25px' paddingTop='30px' paddingBottom='30px'>
              <Button
                variant='outlined'
                color='secondary'
                className={classes.button}
                onClick={next}
              >
                {stepsComplete ? completeButtonText : 'Next'}
              </Button>
            </Box>
            <div ref={ref} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
