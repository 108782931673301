import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  circleWrapper: {
    height: '100%',
    width: '40px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  number: {
    fontSize: 18,
    fontWeight: 500,
  },
  numberCircle: {
    width: '40px',
    height: '40px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '50%',
    borderColor: '#E5E5E5',
    backgroundColor: 'white',
    marginRight: '20px',
  },
  text: {
    fontSize: 20,
    fontWeight: 500,
  },
  textContainer: {
    display: 'flex',
  },
  subtext: {
    fontSize: 20,
  },
  subtextContainer: {
    marginLeft: '62px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    rowHeight: '50px',
  },
}));

const CheckMark = ({ color }: { color: string }) => (
  <svg width='11' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1 4.5L4 8L10 1'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const InstructionNumber = ({
  number,
  color,
}: {
  number: number;
  color: string;
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.numberCircle} borderColor={color}>
      <Grid container className={classes.circleWrapper}>
        <Grid item>
          <Typography className={classes.number} style={{ color }}>
            {number}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const Instruction = ({
  number,
  color,
  text,
  subtext,
  completed,
}: {
  number: number;
  color: string;
  text: string;
  subtext?: string;
  completed?: boolean;
}) => {
  const classes = useStyles();

  return (
    <Box margin={'25px 0'}>
      <Box className={classes.row}>
        <InstructionNumber number={number} color={color} />
        <Grid container>
          <Grid item xs={10} lg={8} container>
            <Grid item>
              <Typography style={{ color }} className={classes.text}>
                {text}
              </Typography>
            </Grid>
            {completed && (
              <Grid item>
                <Box marginLeft='13px'>
                  <CheckMark color={color} />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
      {subtext && (
        <Grid container className={classes.subtextContainer}>
          <Grid item xs={10} lg={8}>
            <Typography className={classes.subtext}>{subtext}</Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

interface InstructionFields {
  color: string;
  text: string;
  subtext?: string;
}

export interface InstructionsProps {
  instructions: InstructionFields[];
  showSubtext?: boolean;
  instructionsComplete?: number;
}

export const Instructions = ({
  instructions = [],
  instructionsComplete,
  showSubtext = true,
}: InstructionsProps) => {
  return (
    <Box>
      {instructions.map((instruction, index) => {
        if (instructionsComplete && index + 1 <= instructionsComplete) {
          return (
            <Instruction
              key={index}
              completed
              color='#E5E5E5'
              number={index + 1}
              text={instruction.text}
            />
          );
        }

        return (
          <Instruction
            key={index + 1}
            number={index + 1}
            color={instruction.color}
            text={instruction.text}
            subtext={showSubtext ? instruction.subtext : undefined}
          />
        );
      })}
    </Box>
  );
};
