import React from 'react';
import {
  Dropdown,
  DropdownOption,
  Text,
  Icon,
  IconEnum,
  Content,
} from 'component-library';
import { BankAccount } from '@mainstreet/client-models/financial/banking/bankingModels';
import { CapitalizeFirstLetter, CentsToDisplayString } from 'lib/helpers';
import _ from 'lodash';

interface IAccountSelectProps {
  accounts: BankAccount[];
  label: string;
  placeholder: string;
  onAccountSelect: (selectedAccountId: string | DropdownOption) => void;
  selectedAccount: BankAccount | null;
}

/**
 *
 * @param props.accounts BankAccounts[]
 * @param props.label Account select label
 * @param props.placeholder Account select placeholder
 *
 * Maps over BankAccount[] to create select dropdown options that trigger different
 * flows depending on the selected account's verification status.
 * This value is delivered to the Providers account select handler to either
 * set the account id in state, or trigger a plaid link flow.
 *
 * Passes account.id as value if account connection is healthy
 * Passes NEW_ACCOUNT if verification has failed or expired
 */
export default function AccountSelect(props: IAccountSelectProps) {
  const options = props.accounts.map((account) => {
    let pendingText = '';
    if (
      account.verificationStatus === 'pending_automatic_verification' ||
      account.verificationStatus === 'pending_manual_verification'
    ) {
      pendingText = '(Pending)';
    }

    const availableBalanceString =
      _.isNumber(account.availableBalanceCents) &&
      CentsToDisplayString(account.availableBalanceCents, 2, 2);

    return {
      name: `${pendingText} ${
        account?.institutionName || ''
      } - ${CapitalizeFirstLetter(account.accountSubType)} •••${
        account.mask
      }     ${availableBalanceString || ''}`,
      value: account.id,
    };
  });

  // Determine if helper text needs to be shown for either of the pending
  // plaid account verification statuses.
  let helperText = null;
  if (
    props.selectedAccount?.verificationStatus ===
    'pending_automatic_verification'
  ) {
    helperText = (
      <Content flex alignItems='center' gap={8}>
        <Icon name={IconEnum.exclamation_triangle} color={'#734F0B'} />
        <Text size={15} color='#734F0B'>
          This account is pending verification, which takes 1-2 days to
          complete. Please select a different account.
        </Text>
      </Content>
    );
  } else if (
    props.selectedAccount?.verificationStatus === 'pending_manual_verification'
  ) {
    helperText = (
      <Content flex alignItems='center' gap={8}>
        <Icon name={IconEnum.exclamation_triangle} color={'#734F0B'} />
        <Text size={15} color='#734F0B'>
          This account must be manually verified before it can be used.
        </Text>
      </Content>
    );
  } else if (
    props.selectedAccount &&
    _.isNumber(props.selectedAccount?.availableBalanceCents)
  ) {
    helperText = `Account balance: ${CentsToDisplayString(
      props.selectedAccount.availableBalanceCents,
      2,
      2,
    )}`;
  }

  // Add option to re-trigger plaid link flow for adding an institution or account
  const optionsWithPlaidLink = [
    ...options,
    {
      name: 'Add a new bank account',
      value: 'NEW_ACCOUNT',
    },
  ];

  return (
    <>
      <Dropdown
        label={props.label}
        placeholder={props.placeholder}
        options={optionsWithPlaidLink}
        onInputChange={(account) => props.onAccountSelect(account)}
        defaultSelected
        dataTestId='select-bank-account'
        helperText={helperText}
      />
    </>
  );
}
