import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import {
  Animate,
  AnswerType,
  Checkbox,
  Flex,
  Spinner,
  SurveyQuestion,
} from 'component-library';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import { ERCEmployeeDetailsQuestions, SurveyNameEnum } from 'lib/constants';
import { useCommonStores } from 'stores/useStores';
import _ from 'lodash';
import { SurveyFlowContainer } from 'products/tax-credits/components';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    maxWidth: '66%',
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}));

interface EmployeeDetailsProps {
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
  isPrefilled: boolean;
}

export const EmployeeDetails = observer(
  ({ pathFrom, onBack, onNext, pathTo }: EmployeeDetailsProps) => {
    const { app } = useCommonStores();
    const classes = useStyles();
    const [hasNoEmployeeOwners, setHasNoEmployeeOwners] = useState(false);
    const handleEmployeeOwnerToggle = () => {
      if (!hasNoEmployeeOwners) {
        addSurveyAnswers(ERCEmployeeDetailsQuestions.MAJORITY_OWNER, 'main', [
          {
            questionId: ERCEmployeeDetailsQuestions.MAJORITY_OWNER,
            answerValue: undefined,
          },
          {
            questionId: ERCEmployeeDetailsQuestions.RELATED_TO_MAJORITY_OWNER,
            answerValue: undefined,
          },
        ]);
        saveAnswers();
      }

      setHasNoEmployeeOwners(!hasNoEmployeeOwners);
    };

    const {
      isLoading,
      addSurveyAnswers,
      saveAnswers,
      questionsToRender,
      isSurveyComplete,
    } = useSurveyQuestions(SurveyNameEnum.ERC_EMPLOYEE_DETAILS_SURVEY, 2021);

    const history = useHistory();

    const handleOnBack = () => {
      onBack();
      history.push(pathFrom);
    };

    const handleOnContinue = () => {
      onNext();
      history.push(pathTo);
    };

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => (
          <Animate
            key={question.id}
            enter={['fade-in', 'from-bottom']}
            duration={0.5}
          >
            <SurveyQuestion
              key={question.id + hasNoEmployeeOwners}
              dataTestId={question.id}
              answerType={question.answerType as AnswerType}
              text={question.text}
              subtitle={question.subtitle}
              answerOptions={question.answerIDs}
              onChange={(answer) =>
                addSurveyAnswers(question.id, key, [
                  { questionId: question.id, answerValue: answer as string },
                ])
              }
              onBlur={() => saveAnswers && saveAnswers()}
              placeholder={question.placeholder}
              answerValue={question.answerValue}
              tooltip={question.tooltip}
              questionTextSize={app.isMobile ? 15 : 18}
              questionSubtitleSize={app.isMobile ? 13 : 15}
              withCardMargin={false}
              disabled={hasNoEmployeeOwners}
              bottomContent={
                question.id === ERCEmployeeDetailsQuestions.MAJORITY_OWNER ? (
                  <Flex padding={[16, 0, 0, 0]}>
                    <Checkbox
                      onChange={handleEmployeeOwnerToggle}
                      options={[
                        {
                          checked: hasNoEmployeeOwners,
                          text: 'Our business has no employee owners.',
                          value: 'yes',
                        },
                      ]}
                    />
                  </Flex>
                ) : (
                  <div />
                )
              }
            />
          </Animate>
        ));
      });

    return (
      <SurveyFlowContainer
        title='Payroll details'
        onContinue={handleOnContinue}
        onBack={handleOnBack}
        isDisabled={!isSurveyComplete && !hasNoEmployeeOwners}
      >
        <Flex direction='column' gap={24} padding={[24, 0, 40, 0]}>
          <Flex className={classes.container} direction='column' gap={24}>
            {isLoading ? (
              <Spinner size='small' />
            ) : (
              !_.isEmpty(questionsToRender) && renderQuestions()
            )}
          </Flex>
        </Flex>
      </SurveyFlowContainer>
    );
  },
);
