import React, { useState } from 'react';
import { Alert, Button, Content, Modal, Text } from 'component-library';
import { useHistory } from 'react-router-dom';
import { Page } from 'lib/constants';

const PayrollGapAlert = () => {
  const history = useHistory();
  const [showGoToIntegrationModal, setShowGoToIntegrationModal] = useState<
    boolean
  >(false);

  return (
    <>
      <Content paddingBottom={24}>
        <Alert
          actions={{
            onClick: () => setShowGoToIntegrationModal(true),
            text: 'Fix payroll data gap',
          }}
          text="We noticed there's a gap in your payroll data. Please fix this issue before continuing. We saved all your responses until this point, and you will be able to resume from here."
          type='warning'
        />
      </Content>

      <Modal
        showModal={showGoToIntegrationModal}
        closeToggle={() => setShowGoToIntegrationModal(false)}
        maxWidth={536}
      >
        <>
          <Content paddingTopBottom={24} paddingLeftRight={24}>
            <Text
              variant='medium'
              size={18}
              text='Make sure you return to this form later'
              paddingBottom={16}
            />
            <Text>
              After fixing your payroll data gap, make sure you return to finish
              this process. Otherwise, we will not able to not generate the
              documents you need for your R&D credits.
            </Text>
          </Content>
          <Content gap={16} flex paddingLeftRight={24}>
            <Button
              label='I understand'
              onClick={() => {
                setShowGoToIntegrationModal(false);
                history.push(`/${Page.connections}`);
              }}
            />
          </Content>
        </>
      </Modal>
    </>
  );
};

export default PayrollGapAlert;
