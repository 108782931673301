import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ExclamationMark from 'components/icons/ExclamationMark';
import { ConnectSystemError } from 'components/onboarding/ConnectSystemError';
import {
  ConnectFinch,
  ConnectPayrollDisplayProps,
} from 'components/ConnectFinch';
import { ImportType } from '../../../../../lib/interfaces';
import { Button, Color, Flex, Text } from 'component-library';

const useStyles = makeStyles(({ copper }: Theme) => ({
  root: {
    color: copper,
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 500,
  },
  icon: {
    marginRight: '10px',
  },
}));

const ConnectFinchDisplay = ({
  openFinch,
  loading,
  finchError,
}: ConnectPayrollDisplayProps) => {
  return (
    <>
      <Button
        label='Reconnect'
        onClick={openFinch}
        loading={loading}
        flexAlignSelf='flex-start'
      />
      {finchError && <ConnectSystemError systemType='payroll' />}
    </>
  );
};

export interface ExpiredTokenMessageProps {
  service: string;
  onPayrollConnected: () => Promise<void>;
  importType: ImportType;
  taxYear?: number;
}

export const ExpiredTokenMessage = ({
  service,
  onPayrollConnected,
  importType,
  taxYear,
}: ExpiredTokenMessageProps) => {
  const classes = useStyles();

  return (
    <Flex direction='column' gap={24}>
      <Flex gap={4}>
        <ExclamationMark className={classes.icon} />
        <Text size={18} color={Color.semantic.$error50}>
          Payroll connection issue
        </Text>
      </Flex>
      {service !== 'rippling' && (
        <ConnectFinch
          onPayrollConnected={onPayrollConnected}
          connectPayrollDisplay={ConnectFinchDisplay}
          payrollSystem={service ? service : undefined}
          importType={importType}
          taxYear={taxYear}
        />
      )}
    </Flex>
  );
};
