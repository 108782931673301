import React, { useEffect, useState } from 'react';
import { Collapse, FormHelperText, makeStyles, Theme } from '@material-ui/core';
import {
  Button,
  Card,
  Content,
  Divider,
  Notification,
} from 'component-library';
import {
  GetEmployeeMagicLinkPageData,
  SaveEmployeeMagicLinkResponse,
} from 'services/server';
import { Loading } from 'components/util/Loading';
import { CmsQuestionData, QuestionProps } from '../../../lib/interfaces';
import {
  getCmsQuestionOptions,
  getCmsQuestionText,
} from '../../../lib/helpers';
import { SurveyQuestions } from '../../../components/SurveyQuestions';
import { s139Faqs } from './faqs';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useCommonStores } from 'stores/useStores';
import { observer } from 'mobx-react';

const useStyles = makeStyles(
  ({ breakpoints, pageBackgroundColor, palette, darkerNavy }: Theme) => ({
    root: {
      background: pageBackgroundColor,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      minHeight: '100vh',
      padding: '50px 130px',
      boxSizing: 'border-box',
      [breakpoints.down('xs')]: {
        padding: '60px 24px',
      },
    },
    container: {
      width: '100%',
      maxWidth: 980,
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      width: '100%',
      marginBottom: '36px',
    },
    partnership: {
      fontSize: '16px',
      margin: '10px 0 0 0',
      color: palette.primary.main,

      '& b': {
        fontWeight: 500,
      },
    },
    logo: {
      width: '200px',
    },
    title: {
      fontFamily: "'Alliance Platt', serif",
      fontSize: '48px',
      lineHeight: '60px',
      marginBottom: '24px',
      color: darkerNavy,
      [breakpoints.down('xs')]: {
        fontSize: '38px',
        lineHeight: '45px',
      },
    },
    subtitle: {
      fontSize: '20px',
      lineHeight: '30px',
      marginBottom: '24px',
      color: darkerNavy,
      [breakpoints.down('xs')]: {
        fontSize: '18px',
        lineHeight: '25px',
      },
    },
    description: {
      fontSize: '20px',
      lineHeight: '30px',

      '& p': {
        margin: '0 0 20px 0',
      },
    },
    notification: {
      marginBottom: '30px',
      fontSize: '16px',
      fontWeight: 400,
    },
    pageError: {
      fontSize: '18px',
    },
    buttonArea: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'left',
    },
    mobileButtonArea: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    submitButton: {
      textAlign: 'center',
      margin: '20px 0',
      marginRight: '20px',
      width: '180px',
      padding: '0 40px',
      height: '50px',
      fontSize: '18px',
      fontWeight: 500,
      textTransform: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    mobileSubmitButton: {
      textAlign: 'center',
      margin: '20px 0',
      width: '100%',
      height: '50px',
      fontSize: '18px',
      fontWeight: 500,
      textTransform: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    noThanksLinkButton: {
      display: 'inline-block',
      textAlign: 'left',
      background: 'none!important',
      border: 'none',
      color: '#1f6ab0',
      fontWeight: 500,
    },
    mobileNoThanksLink: {
      display: 'block',
      textAlign: 'center',
      background: 'none!important',
      border: 'none',
      color: '#1f6ab0',
      fontWeight: 500,
    },
    faqContainer: {
      maxWidth: '660px',
      marginTop: '60px',
    },
    faqTitle: {
      fontSize: '25px',
      lineHeight: '50px',
      marginBottom: '32px',
      color: darkerNavy,
      [breakpoints.down('xs')]: {
        fontSize: '18px',
        lineHeight: '25px',
      },
    },
    faqItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    faqQuestion: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    faqDivider: {
      margin: '20px 0',
    },
    faqAnswer: {
      fontSize: '16px',
      lineHeight: '22px',
      marginBottom: '20px',
    },
    cardContent: {
      [breakpoints.down('xs')]: {
        padding: '26px 20px',
      },
    },
    arrow: {
      marginLeft: '20px',
      cursor: 'pointer',

      '& svg': {
        fill: '#CFD4D7',
      },
    },
  }),
);

interface PageProps {
  companyId: number;
  employeeId: number;
  tokenPurpose: string;
  token: string;
}

interface EmployeeData {
  companyName: string;
  name: string;
}

interface FaqData {
  question: string;
  answerBody: AnswerBody[];
  active: boolean;
}

interface AnswerBody {
  type: string;
  content: string[];
}

const notificationTitle = 'success';
const notificationDescription = (optedOut: boolean) =>
  optedOut
    ? 'You’ve opted out of Section 139 COVID–19 Reimbursements'
    : 'We received your information';
const successDescription = (optedOut: boolean) =>
  optedOut
    ? 'MainStreet will notify your employer that you have chosen to opt out of Section 139 Reimbursements. '
    : 'MainStreet will send you an email once your employer reviews your reimbursement response.';
const successThankYou = (employeeName: string, optedOut: boolean) =>
  optedOut
    ? `${employeeName}, we’re sad to see you go.`
    : `Thank you ${employeeName}!`;

export const EmployeeS139Page = observer(
  ({ companyId, employeeId, tokenPurpose, token }: PageProps) => {
    const classes = useStyles();
    const {
      app: { isTablet },
    } = useCommonStores();
    const [employeeData, setEmployeeData] = useState<EmployeeData>({
      companyName: '',
      name: '',
    });
    const [employeeDataFetched, setEmployeeDataFetched] = useState<boolean>(
      false,
    );

    const [faqBank, setFaqBank] = useState(s139Faqs);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const [optedOut, setOptedOut] = useState<boolean>(false);
    const [formError, setFormError] = useState<string>('');
    const [pageError, setPageError] = useState<string>('');
    const [unansweredQuestions, setUnansweredQuestions] = useState<boolean>(
      false,
    );
    const [questionData, setQuestionData] = useState<
      Record<string, CmsQuestionData>
    >({});
    const [questionBank, setQuestionBank] = useState<QuestionProps[]>([
      {
        id: 0,
        type: '',
        text: 'fake',
        options: undefined,
        value: '',
        active: true,
        callback: undefined,
        subtitle: '',
      },
    ]);

    const setAnswer = (newValue: string, id: number) => {
      const bank = questionBank.map((q) => {
        return q.id === id ? { ...q, value: newValue, errorText: '' } : q;
      });

      setQuestionBank(bank);
    };

    useEffect(() => {
      GetEmployeeMagicLinkPageData(
        companyId,
        employeeId,
        tokenPurpose,
        token,
      ).then((res) => {
        if (!res.data) {
          setPageError(res.errorMsg ? res.errorMsg : 'Something went wrong');
          setEmployeeDataFetched(true);
          return;
        }

        const { employeeData, questionData } = res.data;
        if (employeeData) {
          setEmployeeData(res.data.employeeData);
        }
        if (questionData) {
          setQuestionData(questionData);
        }
        setEmployeeDataFetched(true);
      });
    }, [employeeDataFetched, companyId, employeeId, tokenPurpose, token]);

    useEffect(() => {
      const bank: QuestionProps[] = Object.keys(questionData).map(
        (questionKey, index) => {
          const question = questionData[questionKey];
          return {
            id: index,
            type: question.answerType,
            text: getCmsQuestionText(question),
            options: getCmsQuestionOptions(question),
            value: '',
            active: true,
            callback: undefined,
            label: question.tooltip,
            subtitle: question.subtitle,
            cmsId: question.id,
            cmsAnswerData: question.answerIDs,
          };
        },
      );

      setQuestionBank(bank);
    }, [questionData]);

    useEffect(() => {
      const unansweredQuestions = questionBank.some(
        (question: QuestionProps) => question.value === '',
      );

      setUnansweredQuestions(unansweredQuestions);
    }, [questionBank]);

    const optOut = () => {
      setOptedOut(true);
      setFormSuccess(true);
    };

    const submitForm = () => {
      if (unansweredQuestions) {
        const bank = questionBank.map((question) => {
          return {
            ...question,
            errorText: question.value === '' ? '*Required Question' : undefined,
          };
        });

        setQuestionBank(bank);
      } else {
        const formValues = questionBank.reduce(
          (newFormValues: { [key: string]: string }, question) => {
            if (question.cmsId && question.value != null) {
              newFormValues[question.cmsId] = question.value.toString();
            }
            return newFormValues;
          },
          {},
        );

        setButtonLoading(true);

        SaveEmployeeMagicLinkResponse(
          companyId,
          employeeId,
          tokenPurpose,
          token,
          formValues,
        ).then((res) => {
          setButtonLoading(false);

          if (res.errorMsg) {
            setFormError('Something went wrong');
            return;
          }

          setFormSuccess(true);
        });
      }
    };

    const handleArrowClick = (index: number) => {
      const faqClicked = faqBank[index];
      if (faqClicked) {
        faqClicked.active = !faqClicked.active;
        setFaqBank([...faqBank]);
      }
    };

    const renderAnswer = (answer: AnswerBody) => {
      return (
        <div key={`${answer.content}`} className={classes.faqAnswer}>
          {answer.type === 'paragraph' && <p>{answer.content}</p>}
          {answer.type === 'list' && (
            <ul>
              {answer.content?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      );
    };

    const renderFaq = (faq: FaqData, index: number) => {
      return (
        <Card key={`faq-${index}`}>
          <Content className={classes.cardContent}>
            <div className={classes.faqItem}>
              <div className={classes.faqQuestion}>{faq.question}</div>
              <div
                className={classes.arrow}
                onClick={() => handleArrowClick(index)}
              >
                {faq.active ? <ExpandLess /> : <ExpandMore />}
              </div>
            </div>
            <Collapse in={faq.active} timeout='auto' unmountOnExit>
              <Divider className={classes.faqDivider} variant='full-width' />
              {faq.answerBody && faq.answerBody.map(renderAnswer)}
            </Collapse>
          </Content>
        </Card>
      );
    };

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.header}>
            <img
              className={classes.logo}
              src={`${process.env.PUBLIC_URL}/images/MainSt_WordmarkGreen.svg`}
              alt='MainStreet logo'
            />
            {employeeData.companyName && (
              <div className={classes.partnership}>
                In partnership with <b>{employeeData.companyName}</b>
              </div>
            )}
          </div>
          {pageError && (
            <FormHelperText className={classes.pageError} error>
              {pageError}
              <div>Please reach out to your account admin for assistance</div>
            </FormHelperText>
          )}

          <Loading loading={!employeeDataFetched}>
            {employeeData.name && (
              <>
                {!formSuccess && (
                  <div>
                    <div className={classes.title}>
                      {employeeData.name}, you’ve been invited you to qualify
                      for reimbursements.
                    </div>
                    <div className={classes.description}>
                      <p>
                        {employeeData.companyName} has invited you to qualify
                        your eligibility for Section 139 COVID–19
                        Reimbursements.
                      </p>
                      <p>
                        This information will be used to calculate
                        reimbursements that qualify. Reimbursements are tax free
                        and wont impact your personal taxes or health insurance
                        in any way.
                      </p>
                      <p>Questions? See our FAQ section below.</p>
                    </div>
                    <SurveyQuestions
                      questionBank={questionBank}
                      setAnswer={setAnswer}
                    />
                    <div
                      className={
                        isTablet ? classes.mobileButtonArea : classes.buttonArea
                      }
                    >
                      <Button
                        className={
                          isTablet
                            ? classes.mobileSubmitButton
                            : classes.submitButton
                        }
                        label='Submit'
                        loading={buttonLoading}
                        onClick={submitForm}
                      />
                      <Button
                        className={classes.noThanksLinkButton}
                        label="No thanks, I'm not interested"
                        onClick={optOut}
                      />
                    </div>
                    {formError && (
                      <FormHelperText error>{formError}</FormHelperText>
                    )}
                  </div>
                )}
              </>
            )}
            {formSuccess && (
              <div>
                <Notification
                  className={classes.notification}
                  label={notificationTitle}
                  text={notificationDescription(optedOut)}
                />
                <div className={classes.title}>
                  {successThankYou(employeeData.name, optedOut)}
                </div>
                <div className={classes.subtitle}>
                  {successDescription(optedOut)}
                </div>
              </div>
            )}

            {faqBank.length > 0 && (
              <div className={classes.faqContainer}>
                <div className={classes.faqTitle}>
                  Frequently Asked Questions - Section 139
                </div>
                {faqBank.map(renderFaq)}
              </div>
            )}
          </Loading>
        </div>
      </div>
    );
  },
);
