import { observer } from 'mobx-react';
import {
  useCompany,
  useHistoryStore,
  useTaxCreditsStores,
} from 'stores/useStores';
import { Color, Link, Text } from 'component-library';
import { Page } from 'lib/constants';
import React from 'react';
import { AccountModuleSection } from './AccountModule';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  providerType: {
    paddingRight: '8px',
  },
}));

const ConnectionsSection = observer(() => {
  const { company } = useCompany();
  const { form8974 } = useTaxCreditsStores();
  const history = useHistoryStore();
  const classes = useStyles();

  const connectButton = (dataTestIdKey: string) => {
    return (
      <Link
        text={'Connect'}
        size={15}
        variant={'medium'}
        onClick={() => history.push(`/${Page.connections}`)}
        dataTestId={`dashboard-account-module-connections-section-connect-btn-${dataTestIdKey}`}
      />
    );
  };

  return (
    <AccountModuleSection title={'Connections'}>
      <Text
        tag={'span'}
        color={Color.neutral._60}
        className={classes.providerType}
      >
        Payroll provider
      </Text>
      {company.linkedPayrollSystem && form8974.payrollProvider ? (
        <Text tag={'span'}>{form8974.payrollProvider}</Text>
      ) : (
        connectButton('payroll')
      )}
    </AccountModuleSection>
  );
});

export default ConnectionsSection;
