import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { RdActivityEnum } from 'lib/constants';
import { AddActivity } from './AddActivity';
import { ButtonLink } from 'components/util/ButtonLink';

const useStyles = makeStyles(() => ({
  addActivityLink: {
    margin: '-6px 0',
  },
}));

interface Props {
  onAddClick: (activityToAdd: RdActivityEnum) => void;
  existingActivities: RdActivityEnum[];
}

export const AddActivityContainer = ({
  onAddClick,
  existingActivities,
}: Props) => {
  const [addMode, setToAddMode] = useState(false);
  const classes = useStyles();

  const displayAddMode = () => setToAddMode(true);
  const hideAddMode = () => setToAddMode(false);

  const addActivity = (activityToAdd: RdActivityEnum) => {
    onAddClick(activityToAdd);
    hideAddMode();
  };

  const content = addMode ? (
    <AddActivity
      onAddClick={addActivity}
      onCancelClick={hideAddMode}
      existingActivities={existingActivities}
    />
  ) : (
    <ButtonLink
      onClick={displayAddMode}
      text={'+ Choose another approved R&D activity'}
    />
  );

  return (
    <Grid container>
      <Grid item xs={12} className={classes.addActivityLink}>
        {content}
      </Grid>
    </Grid>
  );
};
