import React from 'react';
import { Animate, Color, Flex, Grid, Spinner } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  CreditsRedeemed,
  Disqualified,
  GradientBorder,
  PreSaleEmptyState,
  ProgressTracker,
  SavingsCreditSection,
  SavingsFooter,
} from './components';
import {
  useCompany,
  useDashboardStores,
  useTaxCreditsStores,
} from 'stores/useStores';
import { useEffectOnce } from 'lib/helpers';
import { Program } from 'entities/Program';
import { ProgramStageEnum, ProgramSubStageEnum } from 'lib/constants';
import { OrderForm, QualificationStatus } from 'lib/interfaces';
import { SavingsModuleState } from '../../stores';

export interface FormattedProgram {
  stage: ProgramStageEnum | undefined;
  subStage: ProgramSubStageEnum | undefined | null;
  name: string;
  taxYear: number;
  id: number;
  orderForm: OrderForm | undefined;
  creditAmountCents: number;
  qualificationStatus: QualificationStatus | undefined;
  estimatedTotalCreditCentsOverride: number | undefined | null;
  programName?: string;
}

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  savingsContent: {
    backgroundColor: Color.neutral.white,
    border: `1px solid ${Color.neutral._20}`,
    borderRadius: '4px 4px 0 0',
    position: 'relative',
    width: '100%',
    zIndex: 2,
  },
  loaderContent: {
    height: 150,
  },
}));

export const SavingsModule: React.FC = observer(() => {
  const classes = useStyles();
  const { company } = useCompany();
  const { modules } = useDashboardStores();
  const { taxCreditsPage } = useTaxCreditsStores();

  const isLoading = modules.loadingSavingsModule;

  useEffectOnce(async () => {
    taxCreditsPage.addActivePrograms(company.programs as Program[]);
    await taxCreditsPage.formatProgramsForSavingsModule();
    await modules.setActiveProgramsCreditEstimate(
      taxCreditsPage.activePrograms,
    );
    await modules.setSavingsModuleState();
  });

  const savingsModulePrograms = taxCreditsPage.activePrograms;
  const activePrograms = savingsModulePrograms.filter(
    (p) => p.stage !== ProgramStageEnum.COMPLETED,
  );

  const renderSavingsModuleState = () => {
    switch (modules.savingsModuleState) {
      case SavingsModuleState.ACTIVE_PROGRAMS:
        return (
          <ProgressTracker
            programs={taxCreditsPage.formattedPrograms}
            activeCredits={activePrograms.length}
          />
        );

      case SavingsModuleState.DISQUALIFIED:
        return <Disqualified />;

      case SavingsModuleState.CREDITS_REDEEMED:
        return (
          <CreditsRedeemed
            company={company}
            estimate={modules.totalRedeemedCreditEstimate}
          />
        );

      case SavingsModuleState.PRE_SALE:
        return <PreSaleEmptyState />;
    }
  };

  return (
    <>
      {isLoading ? (
        <Flex
          alignItems='center'
          justifyContent='center'
          className={classes.loaderContent}
        >
          <Spinner />
        </Flex>
      ) : (
        <div className={classes.container} data-testid={'savings-module'}>
          <Animate enter={['fade-in']} duration={0.5}>
            <Flex direction='column' className={classes.savingsContent}>
              <Grid columns={12}>
                <Grid.Cell columns={4}>
                  <SavingsCreditSection
                    estimate={modules.activeProgramsCreditEstimate}
                  />
                </Grid.Cell>
                <Grid.Cell columns={8}>{renderSavingsModuleState()}</Grid.Cell>
              </Grid>
              {modules.savingsModuleState !== SavingsModuleState.PRE_SALE && (
                <SavingsFooter />
              )}
            </Flex>
            <GradientBorder />
          </Animate>
        </div>
      )}
    </>
  );
});
