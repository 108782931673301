import { observer } from 'mobx-react';
import React from 'react';
import { Button, Color, Flex, Heading, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  helpBar: {
    height: '106px',
    backgroundColor: Color.neutral._light_20,
    borderRadius: '4px',
  },
  heading: {
    paddingBottom: '4px',
    lineHeight: '30px',
  },
});

export interface ResourceHelpBarProps {
  hasAcceptedOrderForm: boolean;
  emailSales: () => void;
  startChat: () => void;
}

const ResourceHelpBar: React.FC<ResourceHelpBarProps> = observer(
  ({ hasAcceptedOrderForm, emailSales, startChat }: ResourceHelpBarProps) => {
    const classes = useStyles();
    const title = hasAcceptedOrderForm
      ? 'Have a question? Our team can help.'
      : 'Need help getting started?';
    const dataTestId = hasAcceptedOrderForm
      ? 'accepted-order-form'
      : 'not-accepted-order-form';
    const description = hasAcceptedOrderForm
      ? 'Chat with a member of our support team.'
      : "Send an email and we'll be in contact shortly.";

    return (
      <Flex
        className={classes.helpBar}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        padding={24}
      >
        <Flex direction='column' dataTestId={dataTestId}>
          <Heading
            tag='h5'
            variant='medium'
            text={title}
            className={classes.heading}
            noMargin={true}
          />
          <Text color={Color.neutral._80} text={description} />
        </Flex>
        <Button variant='outlined' label='Start chat' onClick={startChat} />
      </Flex>
    );
  },
);

export default ResourceHelpBar;
