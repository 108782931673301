import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Animate,
  Button,
  Color,
  Flex,
  FontAwesome,
  Heading,
  Link,
  Modal,
  Spinner,
  Text,
} from 'component-library';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  FormHelperText,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { PasswordChecklist } from '../../../../../pages/onboarding/components/PasswordChecklist';
import {
  useCommonStores,
  useCompany,
  useDashboardStores,
  useFeatureFlags,
  useOnboardingStores,
} from '../../../../../stores/useStores';
import { CreditCategoryEnum } from '../../../../../lib/constants';
import {
  CreateCompanySelfSignupRequest,
  SourcingData,
} from '../../../../../lib/interfaces';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from '../../../../../logging';
import { Auth0FeatureContext } from '../../../../../components/util/Auth0Feature';
import { useIsMounted } from 'component-library/_helpers/use-utils';
import { usePasswordValidation } from '../../../../../lib/usePasswordValidation';
import { DividerWithText } from '../../../../../components/DividerWithText';
import {
  GoogleSso,
  XeroSso,
} from '../../../../../pages/signup/socialSSOButtons';

const useStyles = makeStyles(({ breakpoints }) => ({
  contentContainer: {
    maxHeight: '700px',
    overflowY: 'auto',
    width: '100%',
    textAlign: 'center',
  },
  textField: {
    width: '100%',
    background: Color.neutral.white,
  },
  inputRoot: {
    '& input': {
      width: 'calc(100% - 28px)',
    },
  },
  errorMsg: {
    margin: '5px 0 0',
    fontSize: '12px',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: '20px',
      marginBottom: '-25px',
    },
  },
  formErrorMsg: {
    marginTop: '15px',
    textAlign: 'center',
  },
  expandableField: {
    width: '100%',
    maxWidth: '320px',
  },
  submitButton: {
    width: '100%',
  },
  orText: {
    color: 'black',
  },
}));

interface FormInputs {
  email: string;
  password: string;
  agreeToTerms: boolean;
}

const getUtmSourcingData = (urlParams: URLSearchParams) => {
  const sourcingData: SourcingData = {
    utmCampaign: '',
    utmMedium: '',
    utmSource: '',
    utmContent: '',
    utmTerm: '',
  };

  const urlKeyMapping: SourcingData = {
    utmCampaign: 'utm_campaign',
    utmMedium: 'utm_medium',
    utmSource: 'utm_source',
    utmContent: 'utm_content',
    utmTerm: 'utm_term',
  };

  Object.keys(sourcingData).forEach((key) => {
    if (key in urlKeyMapping) {
      const urlParamKey = urlKeyMapping[key as keyof SourcingData];
      if (urlParamKey) {
        const urlParamValue = urlParams.get(urlParamKey);
        if (urlParamValue) {
          sourcingData[key as keyof SourcingData] = urlParamValue;
        }
      }
    }
  });
  return sourcingData;
};

interface SignUpModalProps {
  title?: string;
  subtitle?: string;
  cta?: string;
}

export const SignUpModal: React.FC<SignUpModalProps> = observer(
  ({ title, subtitle, cta }) => {
    const classes = useStyles();
    const { modules } = useDashboardStores();
    const featureFlags = useFeatureFlags();
    const { app, auth } = useCommonStores();
    const { company } = useCompany();
    const { initialAccountSetup } = useOnboardingStores();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [showPasswordHelpers, setShowPasswordHelpers] = useState(false);
    const [formError, setFormError] = useState('');
    const { client } = useContext(Auth0FeatureContext);

    const isMounted = useIsMounted();

    const { search } = location;
    const { origin } = window.location;
    const redirectUri = origin;
    const urlParams = new URLSearchParams(search);
    const sourcingData = getUtmSourcingData(urlParams);
    const pathComponents = location.pathname.toLowerCase().split('/');

    const { validatePassword, isValidPassword, passwordValidation } =
      usePasswordValidation();
    const initialValues: FormInputs = {
      email: '',
      password: '',
      agreeToTerms: true,
    };

    const getPartnerReferral = () => {
      if (pathComponents.length > 2 && pathComponents[1] === 'welcome') {
        return pathComponents[2];
      }

      return '';
    };
    const submit = async (formValues: FormInputs) => {
      if (isMounted()) {
        setFormError('');
        initialAccountSetup.setFormLoading(true);
      }

      const res = await client.CreateCompanyAndLoginSelfSignup({
        primaryUserEmail: formValues.email,
        primaryUserFirstName: undefined,
        primaryUserLastName: undefined,
        adminPassword: formValues.password,
        partnerReferral: getPartnerReferral(),
        referrer: '',
        creditCategories: [] as CreditCategoryEnum[],
        sourcing: sourcingData,
      } as CreateCompanySelfSignupRequest);

      if (res.errorMsg) {
        datadogLogs.logger.error(
          `[PARTNER LANDING PAGE]: company creation on registration failed.`,
          logContext({
            error: res.errorMsg,
            company: app.company,
          }),
        );

        setFormError(res.errorMsg);
        initialAccountSetup.setFormLoading(false);
        return;
      }

      await auth.manualLogin(
        formValues.email,
        formValues.password,
        redirectUri,
      );
      if (res.data?.company) {
        company.mergeData(res.data.company);
      }
    };

    const submitOnEnter =
      (runSubmit: () => void) => (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          runSubmit();
        }
      };

    return (
      <Modal
        showModal={modules.showSignUpModal}
        closeToggle={() => modules.toggleSignUpModal(false)}
        maxWidth={400}
        rounded={true}
      >
        <Flex
          direction='column'
          padding={24}
          gap={8}
          className={classes.contentContainer}
        >
          {initialAccountSetup.formLoading ? (
            <Animate enter='fade-in'>
              <Flex
                padding={[16, 0]}
                gap={16}
                direction='column'
                alignItems={'center'}
              >
                <Heading
                  tag={app.isSmallDesktop ? 'h5' : 'h4'}
                  variant='medium'
                  marginBottom={0}
                >
                  Get ready to claim your credits!
                </Heading>
                <Spinner color='emerald' />
              </Flex>
            </Animate>
          ) : (
            <>
              <Text
                size={23}
                variant='medium'
                text={title || 'Create your credit estimate'}
                textAlign={'left'}
              />
              <Text
                size={15}
                text={
                  subtitle ||
                  'Getting started is absolutely risk-free. Discover your potential tax credits today!'
                }
                textAlign={'left'}
                color={Color.neutral._60}
              />
              <Formik
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                  submit(values);
                }}
                validationSchema={yup.object().shape({
                  email: yup
                    .string()
                    .email('Please enter a valid email')
                    .required('Please include your work email'),
                  password: yup.string().required('Please enter a password'),
                  agreeToTerms: yup
                    .bool()
                    .oneOf([true], 'You must accept the terms of service'),
                })}
                // eslint-disable-next-line react/no-children-prop
                children={({ values, errors, handleSubmit, setFieldValue }) => (
                  <Flex
                    direction='column'
                    alignItems='flex-start'
                    padding={[16, 0, 0, 0]}
                    gap={16}
                  >
                    <TextField
                      variant='outlined'
                      className={classes.textField}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      data-testid='welcome_email'
                      label='Enter company email'
                      value={values.email}
                      onChange={(event) => {
                        setFieldValue('email', event.target.value);
                      }}
                      onKeyDown={submitOnEnter(handleSubmit)}
                    />
                    {errors.email && (
                      <FormHelperText className={classes.errorMsg} error>
                        {errors.email}
                      </FormHelperText>
                    )}
                    <TextField
                      variant='outlined'
                      className={classes.textField}
                      type={showPassword ? 'text' : 'password'}
                      data-testid='welcome_password'
                      label='Create a password'
                      value={values.password}
                      error={!isValidPassword && showPasswordError}
                      onFocus={() => {
                        setShowPasswordHelpers(true);
                      }}
                      onBlur={(event) => {
                        validatePassword(event.target.value, true);
                        setShowPasswordError(true);
                        setShowPasswordHelpers(values.password.length > 0);
                      }}
                      onChange={(event) => {
                        setShowPasswordHelpers(true);
                        setShowPasswordError(false);
                        setFieldValue('password', event.target.value);
                        validatePassword(event.target.value);
                      }}
                      onKeyDown={submitOnEnter(handleSubmit)}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Link
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                              inheritColor={true}
                            >
                              <FontAwesome
                                name={showPassword ? 'eye-slash' : 'eye'}
                                variant='solid'
                                size={20}
                                color={
                                  !isValidPassword && showPasswordError
                                    ? Color.semantic.$error50
                                    : Color.neutral._60
                                }
                              />
                            </Link>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {showPasswordHelpers && (
                      <PasswordChecklist
                        passwordValidation={passwordValidation}
                      />
                    )}
                    {errors.password && (
                      <FormHelperText className={classes.errorMsg} error>
                        {errors.password}
                      </FormHelperText>
                    )}
                    {formError && (
                      <FormHelperText className={classes.formErrorMsg} error>
                        {formError}
                      </FormHelperText>
                    )}
                    <Flex gap={24} alignItems='center'>
                      <Button
                        className={classes.submitButton}
                        label={cta || 'Create Estimate'}
                        onClick={() => {
                          handleSubmit();
                        }}
                        flexAlignSelf='flex-start'
                        disabled={!isValidPassword || !values.email}
                      />
                    </Flex>
                  </Flex>
                )}
              />
              {featureFlags.showUnifiedSocialSso && (
                <>
                  <Flex padding={12}>
                    <DividerWithText>
                      <div className={classes.orText}>or</div>
                    </DividerWithText>
                  </Flex>
                  <GoogleSso
                    referrer={''}
                    sourcing={sourcingData}
                    auth={auth}
                    redirectUri={redirectUri}
                  />
                  <XeroSso
                    referrer={''}
                    sourcing={sourcingData}
                    auth={auth}
                    redirectUri={redirectUri}
                  />
                </>
              )}
            </>
          )}
        </Flex>
      </Modal>
    );
  },
);
