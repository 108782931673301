import React, { useContext, useState, useEffect, useRef } from 'react';
import { CompanyContext } from '../../CompanyRequired';
import {
  makeStyles,
  Theme,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import { Card, Content, Notification, Button, Color } from 'component-library';
import {
  EmployeeForEmployeeTable,
  EmployeeMagicLinkStatusEnum,
} from 'lib/interfaces';
import {
  GetEmployeeTableData,
  SaveEmployeePrimaryEmailAddress,
} from 'services/server';
import { Loading } from 'components/util/Loading';
import { EmployeeTokenPurposeEnum } from '../../../lib/constants';

const useStyles = makeStyles(
  ({
    darkerNavy,
    boxBackgroundColor,
    lighterGrey,
    darkBlue,
    emerald,
    yellow,
    lightBlue,
  }: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: '40px 80px',
      maxWidth: '1050px',
    },
    title: {
      fontFamily: "'Alliance Platt', serif",
      margin: '20px 0 30px',
      fontSize: '50px',
      color: Color.neutral._80,
    },
    subtitle: {
      fontSize: '20px',
      lineHeight: '30px',
      marginBottom: '30px',
      color: darkerNavy,
      maxWidth: '800px',
    },
    notification: {
      marginBottom: '30px',
    },
    instructionCard: {
      width: '400px',
      borderBottom: '4px solid ' + emerald,

      '& a': {
        color: darkerNavy,
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
    cardCircle: {
      width: '48px',
      marginBottom: '20px',
    },
    intructionLink: {
      marginBottom: '20px',
    },
    instructionTitle: {
      fontSize: '20px',
      fontWeight: 500,
      marginBottom: '20px',
    },
    instructionSubtitle: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    employeesTable: {
      backgroundColor: boxBackgroundColor,
      borderSpacing: 0,

      '& thead': {
        backgroundColor: '#fcfcf5',
      },

      '& tr': {
        display: 'grid',
        gridTemplateColumns: '1.5fr 1.75fr 1fr 1fr 1fr',
        borderBottom: '1px solid' + lighterGrey,
      },

      '& thead th': {
        padding: '28px 20px 15px',
        fontSize: '16px',
        fontWeight: 500,
        display: 'block',
        textAlign: 'left',
        '&:not(:last-child)': {
          borderRight: '1px solid ' + lighterGrey,
        },
      },
      // Employee icon colors
      '& tbody tr:nth-child(4n+1) td:first-child div div': {
        backgroundColor: yellow,
      },
      '& tbody tr:nth-child(4n+2) td:first-child div div': {
        backgroundColor: emerald,
        color: '#FFFFFF',
      },
      '& tbody tr:nth-child(4n+3) td:first-child div div': {
        backgroundColor: darkBlue,
        color: '#FFFFFF',
      },
      '& tbody tr:nth-child(4n+4) td:first-child div div': {
        backgroundColor: lightBlue,
      },
      '& tbody td': {
        display: 'flex',
        alignItems: 'center',
        minWidth: 0,
        padding: '30px 20px',

        '& > div': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    subheader: {
      fontSize: '10px',
      textTransform: 'uppercase',
      marginTop: '10px',
    },
    nameCell: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
    },
    employeeIcon: {
      width: '44px',
      height: '44px',
      borderRadius: '22px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      marginRight: '10px',
    },
    employeeName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    pill: {
      padding: '7px 20px',
      borderRadius: '100px',
      backgroundColor: '#E5E8E5',
    },
    status: {
      textTransform: 'capitalize',
    },
    green: {
      color: '#FFFFFF',
      backgroundColor: emerald,
    },
    yellow: {
      backgroundColor: yellow,
    },
    grey: {
      backgroundColor: '#E5E8E5',
    },
    link: {
      color: darkBlue,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    bold: {
      fontWeight: 500,
    },
    editButton: {
      width: '12px',
      cursor: 'pointer',
    },
    emailCell: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    email: {
      marginRight: '20px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    textField: {
      marginRight: '20px',
    },
    faqContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    faqIcon: {
      width: '64px',
      marginRight: '20px',
    },
    faqCopyContainer: {
      marginRight: '20px',
    },
    faqTitle: {
      fontSize: '20px',
      fontWeight: 500,
      marginBottom: '10px',
    },
    faqDescription: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    faqButton: {
      flexShrink: 0,
    },
  }),
);
interface PageProps {
  qualificationTaxYear: number;
}
export const EmployeesSickLeaveDashboard = ({
  qualificationTaxYear,
}: PageProps) => {
  const { company } = useContext(CompanyContext);
  const classes = useStyles();

  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [firstMissingEmailIndex, setFirstMissingEmailIndex] = useState<
    number | null
  >(null);
  const [employeesTableData, setEmployeesTableData] = useState<
    EmployeeForEmployeeTable[]
  >([]);
  const [employeesTableDataFetched, setEmployeesTableDataFetched] = useState<
    boolean
  >(false);
  const [inputFieldToggles, setInputFieldToggles] = useState<number[]>([]);
  const [pageError, setPageError] = useState<string>('');

  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    const companyId = company.id;
    GetEmployeeTableData(
      companyId,
      qualificationTaxYear,
      EmployeeTokenPurposeEnum.PAID_SICK_LEAVE,
    ).then((res) => {
      if (!res.data) {
        setPageError(res.errorMsg || 'Something went wrong');
        setEmployeesTableDataFetched(true);
        return;
      }
      setEmployeesTableData(res.data.employees);

      // Check emails
      let missingEmailFound = false;
      res.data.employees.forEach((employee, index) => {
        inputFieldToggles.push(0);
        const email = employee.email;
        if (!email && !missingEmailFound) {
          setShowNotification(true);
          setFirstMissingEmailIndex(index);
          missingEmailFound = true;
        }
      });
      setEmployeesTableDataFetched(true);
    });
    // eslint-disable-next-line
  }, []);

  const getStatusColor = (status: EmployeeMagicLinkStatusEnum) => {
    switch (status) {
      case 'active':
        return 'green';
      case 'incomplete':
        return 'yellow';
      default:
        return 'grey';
    }
  };

  const handleAddEmailClick = (index: number) => {
    inputFieldToggles[index] = 1;
    setInputFieldToggles([...inputFieldToggles]);
  };

  const handleAddEmailChange = (
    email: string,
    employee: EmployeeForEmployeeTable,
  ) => {
    employee.email = email;
  };

  const handleSaveEmailClick = (
    employee: EmployeeForEmployeeTable,
    index: number,
  ) => {
    employeesTableData[index] = employee;
    setEmployeesTableData([...employeesTableData]);
    SaveEmployeePrimaryEmailAddress(
      employee.employmentRecordId,
      employee.email,
    ).then(() => {
      inputFieldToggles[index] = 0;
      setInputFieldToggles([...inputFieldToggles]);
    });
  };

  const renderEmployeeRow = (
    employee: EmployeeForEmployeeTable,
    index: number,
  ) => {
    const employeeName = employee.name;
    const firstInitial = employeeName![0];
    const lastInitialMatch = employeeName!.match(/^(\w+).*\s(\w)\w+$/);
    const lastInitial = lastInitialMatch && lastInitialMatch[2];

    const email = employee.email;

    const daysOffText = !isNaN(employee.vaccineDays)
      ? employee.vaccineDays + ' days'
      : 'N/A';
    const hoursOffText = !isNaN(employee.vaccineHours)
      ? employee.vaccineHours + ' hours'
      : 'N/A';

    const status = employee.status;
    const statusColor = getStatusColor(status);

    return (
      <tr key={`employee-${index}`}>
        <td>
          <div className={classes.nameCell}>
            <div className={classes.employeeIcon}>
              {firstInitial}
              {lastInitial}
            </div>
            <span className={classes.employeeName}>{employeeName}</span>
          </div>
        </td>
        <td>
          {email && inputFieldToggles[index] !== 1 && (
            <div className={classes.emailCell}>
              <a
                className={`${classes.email} ${classes.link}`}
                href={`mailto:${email}`}
              >
                {email}
              </a>
              <img
                className={classes.editButton}
                src={`${process.env.PUBLIC_URL}/images/pen-icon.svg`}
                alt='Edit Icon'
                onClick={() => {
                  handleAddEmailClick(index);
                }}
              />
            </div>
          )}
          {!email && inputFieldToggles[index] !== 1 && (
            <div className={classes.emailCell}>
              <div
                className={`${classes.link} ${classes.bold}`}
                onClick={() => {
                  handleAddEmailClick(index);
                }}
              >
                Add Email
              </div>
            </div>
          )}
          {inputFieldToggles[index] === 1 && (
            <div className={classes.emailCell}>
              <TextField
                className={classes.textField}
                variant='outlined'
                inputMode='email'
                placeholder='Email'
                defaultValue={email}
                size='small'
                onChange={(event) => {
                  const email = event.target.value;
                  handleAddEmailChange(email, employee);
                }}
              />
              <div
                className={`${classes.link} ${classes.bold}`}
                onClick={() => {
                  handleSaveEmailClick(employee, index);
                }}
              >
                Save
              </div>
            </div>
          )}
        </td>
        <td>
          <div className={classes.pill}>{daysOffText}</div>
        </td>
        <td>
          <div className={classes.pill}>{hoursOffText}</div>
        </td>
        <td>
          <div
            className={`${classes.pill} ${classes[statusColor]} ${classes.status}`}
          >
            {status}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>Credits for Paid Sick Leave</div>
      <div className={classes.subtitle}>
        A refundable payroll tax credit that covers wages your employees took
        off for covid-related issues including receiving and recovering from the
        vaccine.
      </div>
      {showNotification && (
        <Notification
          className={classes.notification}
          label='time-sensitive'
          text='One of your employees has missing information we need to contact them about this credit'
          cta={{
            type: 'button',
            text: 'Update Contact Info',
            onClick: () => {
              if (
                tableRef.current &&
                (firstMissingEmailIndex || firstMissingEmailIndex === 0)
              ) {
                const missingEmailRow = tableRef.current
                  .querySelector('tbody')!
                  .querySelectorAll('tr')[firstMissingEmailIndex];
                if (missingEmailRow) {
                  missingEmailRow.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                  });
                }
              }
            },
          }}
        />
      )}
      <Card className={classes.instructionCard}>
        <a href='https://mainstreet-help.force.com/help/s/'>
          <Content>
            <div>
              <img
                className={classes.cardCircle}
                src={`${process.env.PUBLIC_URL}/images/life-raft-icon.svg`}
                alt='Raft Icon'
              />
              <div className={`${classes.link} ${classes.intructionLink}`}>
                Instructions to
              </div>
              <div className={classes.instructionTitle}>
                Redeem sick leave credits
              </div>
              <div className={classes.instructionSubtitle}>
                Redeem credits for paid sick leave from your payroll provider
              </div>
            </div>
          </Content>
        </a>
      </Card>

      <Loading loading={!employeesTableDataFetched}>
        <Card>
          <table className={classes.employeesTable} ref={tableRef}>
            <thead>
              <tr>
                <th>Employees ({employeesTableData.length})</th>
                <th>Email</th>
                <th>
                  Sick Leave<div className={classes.subheader}>Days</div>
                </th>
                <th>
                  Sick Leave<div className={classes.subheader}>Hours</div>
                </th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {employeesTableData.length > 0 &&
                employeesTableData.map(renderEmployeeRow)}
            </tbody>
          </table>
        </Card>
      </Loading>

      {pageError && <FormHelperText error>{pageError}</FormHelperText>}

      <Card>
        <Content className={classes.faqContainer}>
          <img
            className={classes.faqIcon}
            src={`${process.env.PUBLIC_URL}/images/paper-plane-icon.svg`}
            alt='Paper Plane Icon'
          />
          <div className={classes.faqCopyContainer}>
            <div className={classes.faqTitle}>Need help?</div>
            <div className={classes.faqDescription}>
              Learn more about the credit for paid sick leave from MainStreet
            </div>
          </div>
          <Button
            className={classes.faqButton}
            label='View FAQs'
            onClick={() => {
              window.location.href =
                'https://mainstreet-help.force.com/help/s/';
            }}
          />
        </Content>
      </Card>
    </div>
  );
};
