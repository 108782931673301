import React, { useState, useContext } from 'react';
import {
  Content,
  Button,
  Heading,
  RadioGroup,
  Spinner,
  Text,
  TextField,
} from 'component-library';
import { OnboardingQuestions } from '../../../../onboarding/components/OnboardingQuestions';
import { makeStyles, Theme } from '@material-ui/core';
import {
  CompanyData,
  UpdateCompanyManualPayrollEstimateRequest,
} from 'lib/interfaces';
import { getCmsQuestionOptions, getCmsQuestionText } from 'lib/helpers';
import useGetSurveyQuestionsBySurveyName from 'lib/useGetSurveyQuestionsBySurveyName';
import { Page, GraphCmsQuestionIdEnum, SurveyNameEnum } from 'lib/constants';
import { useTaxCreditsStores } from 'stores/useStores';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { LoadingStatusEnum } from 'lib/constants';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  container: {
    margin: '40px 0 120px',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    maxWidth: '880px',
  },
  cardMargin: {
    margin: 0,
  },
  heading: {
    [breakpoints.down('xs')]: {
      padding: '0 32px',
    },
  },
  btnContainer: {
    display: 'flex',
    gap: '16px',
    [breakpoints.down('xs')]: {
      marginLeft: '32px',
    },
  },
}));

interface ManualPayrollEstimateProps {
  company: CompanyData;
  qualificationTaxYear: number;
  loadingStatus: string | null;
}

export const ManualPayrollEstimate = ({
  company,
  qualificationTaxYear,
  loadingStatus,
}: ManualPayrollEstimateProps) => {
  const { rdQualification } = useTaxCreditsStores();
  const { client } = useContext(Auth0FeatureContext);
  const history = useHistory();
  const classes = useStyles();
  const [onSubmitLoading, setOnSubmitLoading] = useState<boolean>(false);
  const [payrollEstimateDollars, setPayrollEstimateDollars] = useState<
    number | null
  >(
    company.misc!.originalPayrollEstimateCents
      ? company.misc!.originalPayrollEstimateCents / 100
      : null,
  );
  const [errorMsg, setErrorMsg] = useState<string>('');

  const skipFirstQuestion =
    loadingStatus === LoadingStatusEnum.ERRORED ||
    loadingStatus === LoadingStatusEnum.IN_PROGRESS;

  const companyQualificationQuestions = company.qualificationQuestionsByYear
    ? company.qualificationQuestionsByYear[qualificationTaxYear] || {}
    : {};

  const {
    qualificationQuestions,
    isLoading: qualificationQuestionsAreLoading,
  } = useGetSurveyQuestionsBySurveyName(SurveyNameEnum.ONBOARDING);

  const [
    cannotFindPayrollInPayrollSystem,
    setCannotFindPayrollInPayrollSystem,
  ] = useState<string | null>(
    companyQualificationQuestions[
      GraphCmsQuestionIdEnum.CANNOT_FIND_PAYROLL_IN_PAYROLL_SYSTEM
    ] || null,
  );
  const cannotFindPayrollInPayrollSystemData =
    qualificationQuestions[
      GraphCmsQuestionIdEnum.CANNOT_FIND_PAYROLL_IN_PAYROLL_SYSTEM
    ];

  const submit = () => {
    setOnSubmitLoading(true);

    const req = {
      payrollEstimateCents: (payrollEstimateDollars || 0) * 100,
      cannotFindPayrollInPayrollSystem: cannotFindPayrollInPayrollSystem,
      qualificationTaxYear: qualificationTaxYear,
      companyId: company.id,
    } as UpdateCompanyManualPayrollEstimateRequest;

    client.UpdateCompanyManualPayrollEstimate(req).then((res) => {
      if (res && res.errorMsg) {
        datadogLogs.logger.error(
          '[CREDIT_ESTIMATE]: UpdateCompanyManualPayrollEstimate failed',
          logContext({
            error: errorMsg,
            company,
          }),
        );
        setErrorMsg(errorMsg);
        return;
      }
      setOnSubmitLoading(false);
      // Refresh the page to re-render with the estimate.
      history.go(0);
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.mainContent}>
        <Heading
          tag='h2'
          text='Just one more thing before we can finish your&nbsp;estimation'
          className={classes.heading}
          dataTestId='manual-payroll-header'
        />
        {!qualificationQuestionsAreLoading && !skipFirstQuestion && (
          <OnboardingQuestions
            question={getCmsQuestionText(cannotFindPayrollInPayrollSystemData)}
            description={
              cannotFindPayrollInPayrollSystemData &&
              cannotFindPayrollInPayrollSystemData!.subtitle
            }
            renderAction={
              <RadioGroup
                name='Cannot find payroll in payroll system'
                options={getCmsQuestionOptions(
                  qualificationQuestions[
                    GraphCmsQuestionIdEnum.CANNOT_FIND_PAYROLL_IN_PAYROLL_SYSTEM
                  ],
                  true,
                )}
                value={cannotFindPayrollInPayrollSystem!}
                onChange={(e) => {
                  setCannotFindPayrollInPayrollSystem(e.target.value);
                }}
              />
            }
          />
        )}
        {qualificationQuestionsAreLoading && !skipFirstQuestion && (
          <Spinner color='emerald' size='small' />
        )}

        {(cannotFindPayrollInPayrollSystem || skipFirstQuestion) && (
          <OnboardingQuestions
            question='Roughly how much did you pay in salaries to US-based employees (W-2 and contractors) through your payroll system last&nbsp;month?'
            description='This can be just a rough estimate (e.g. multiply the number of employees by&nbsp;$8.000).'
            renderAction={
              <TextField
                currencyFormat
                dataTestId='payroll-manual-estimate-input'
                value={payrollEstimateDollars}
                onChange={(event) => {
                  const value = event.target.value.replace(/,/g, '');
                  if (!value) {
                    setPayrollEstimateDollars(null);
                  } else {
                    setPayrollEstimateDollars(Number(value));
                  }
                }}
              />
            }
          />
        )}

        <Content
          flex
          paddingLeftRight={0}
          paddingTopBottom={40}
          alignItems='center'
        >
          <Button
            label='Get estimation'
            loading={onSubmitLoading}
            disabled={!payrollEstimateDollars}
            onClick={submit}
          />
          <Button
            label='Back'
            variant='outlined'
            onClick={() =>
              history.push(
                `${rdQualification.path}/${qualificationTaxYear}/${Page.taxCreditRdOverview}`,
              )
            }
          />
          {errorMsg && <Text text={errorMsg} status='failed' />}
        </Content>
      </div>
    </div>
  );
};
