import { ProgramData } from '../../../../lib/interfaces';
import React, { useState } from 'react';
import { Button, SideDrawer, Checkbox } from 'component-library';
import { useTaxCreditsStores } from '../../../../stores/useStores';
import { makeStyles } from '@material-ui/core';
import { formatPayrollString } from 'lib/helpers';
import { observer } from 'mobx-react';
import Tier2SideDrawerContent from './Tier2SideDrawerContent';
import { ALL_PAYROLL_SYSTEMS } from 'lib/constants';

export interface Tier2Form8974SideDrawerProps {
  program: ProgramData;
  show: boolean;
  onCancel: () => void;
  onComplete: (programId: number) => void;
}

const useStyles = makeStyles(() => ({
  checkbox: {
    '& label': {
      'margin-bottom': 0,
    },
  },
}));

export const Tier2Form8974SideDrawer = observer(
  ({ program, show, onCancel, onComplete }: Tier2Form8974SideDrawerProps) => {
    const classes = useStyles();
    const { form8974 } = useTaxCreditsStores();
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const payrollProvider =
      ALL_PAYROLL_SYSTEMS.find((p) => p.id === form8974.payrollProvider)
        ?.displayName || formatPayrollString(form8974.payrollProvider);

    const updateProgram8974SetupConfirmation = async () => {
      await form8974.handleCompletePayrollProviderSetup(program.id);
      onComplete(program.id);
    };

    return (
      <SideDrawer
        show={show}
        title='Download Form 8974'
        alertMessage={
          'Complete these steps today to receive your funds on time.'
        }
        alertType={'warning'}
        alertMessagePosition={'bottom'}
        closeToggle={onCancel}
        drawerActions={
          <>
            <Button
              label='Finish'
              disabled={!confirmed}
              loading={form8974.payrollProviderConfirmationProcessing}
              onClick={() => updateProgram8974SetupConfirmation()}
              data-testid='finish-button'
            />
          </>
        }
        drawerContent={<Tier2SideDrawerContent />}
        drawerFooterContent={
          <Checkbox
            dataTestId='confirmation-checkbox'
            className={classes.checkbox}
            onChange={() => setConfirmed(!confirmed)}
            options={[
              {
                checked: confirmed,
                text: `I have completed the setup with ${payrollProvider}`,
                value: 'confirmed',
              },
            ]}
          />
        }
      />
    );
  },
);
