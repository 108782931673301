import { Button, Card, Flex, Text } from 'component-library';
import { CentsToDisplayString } from 'component-library/_helpers/helpers';
import { Page } from '../../../../../lib/constants';
import React from 'react';
import { useCommonStores } from '../../../../../stores/useStores';

interface ErcAssessmentPageProps {
  classes: any;
  ercEstimate: number | null;
}

export const ErcAssessmentPage = ({
  classes,
  ercEstimate,
}: ErcAssessmentPageProps) => {
  const { app } = useCommonStores();

  return (
    <Card noMargin>
      <Flex direction='column' gap={16} padding={24}>
        <Flex justifyContent='space-between' alignItems='center' wrap='wrap'>
          <Flex
            gap={4}
            alignItems='center'
            justifyContent='flex-start'
            className={classes.title}
            basis={315}
          >
            {ercEstimate ? (
              <Text
                variant='medium'
                size={23}
                text={`How to claim your estimated ${CentsToDisplayString(
                  ercEstimate,
                )} in credits`}
              />
            ) : (
              <Text
                variant='medium'
                size={23}
                text={`How to claim your credits`}
              />
            )}
          </Flex>
        </Flex>
        <Text>
          We&apos;re excited to help you claim your Employee Retention credits!
          To ensure your credits are fully compliant with all relevant
          regulations, there are three steps you&apos;ll need to take.
        </Text>
        <Button
          label={'Get Started'}
          onClick={() => {
            app.history.push(`/${Page.taxCredits}/${Page.erc}`);
          }}
          flexAlignSelf='flex-start'
        />

        <div className={classes.assessmentContainer}>
          <Flex direction='column' gap={8} padding={24}>
            <Text variant='medium' size={18}>
              Start your credit claim
            </Text>
            <Text>
              Confirm some business details, upload your income and payroll tax
              returns, and verify your payroll information so we can start your
              credit claim today.
            </Text>
          </Flex>
          <Flex direction='column' gap={8} padding={24}>
            <Text variant='medium' size={18}>
              Schedule a call to finalize your credits
            </Text>
            <Text>
              A MainStreet credits specialist will review your information and
              finalize your credits, then walk you through the next steps to
              file.
            </Text>
          </Flex>
          <Flex direction='column' gap={8} padding={24}>
            <Text variant='medium' size={18}>
              File your credits
            </Text>
            <Text>
              We will work with your tax preparer to ensure your credits are
              filed correctly and before the relevant deadlines.
            </Text>
          </Flex>
        </div>
      </Flex>
    </Card>
  );
};
