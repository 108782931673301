import {
  PlaidActionSetIsLoading,
  PlaidActionSetPlaidToken,
  PlaidActionSetPlaidError,
  PlaidActionSetConnectedInsitutions,
  PlaidActionSetSelectedAccountId,
  PlaidActionSetAccounts,
  PlaidActionSetDefaultPaymentMethodDetails,
  PlaidContextState,
  BasePlaidAction,
  PlaidActionSetIsLoadingPayload,
  PlaidActionSetPlaidTokenPayload,
  PlaidActionSetPlaidErrorPayload,
  PlaidActionSetConnectedInstitutionsPayload,
  PlaidActionSetSelectedAccountIdPayload,
  PlaidActionSetAccountsPayload,
  PlaidActionSetDefaultPaymentMethodDetailsPayload,
} from './types';

////////////////////////////////
// ACTIONS
export const setIsLoading = (
  payload: PlaidActionSetIsLoadingPayload,
): PlaidActionSetIsLoading => ({
  type: 'SET_IS_LOADING',
  payload,
});

export const setPlaidLinkToken = (
  payload: PlaidActionSetPlaidTokenPayload,
): PlaidActionSetPlaidToken => ({
  type: 'SET_PLAID_LINK_TOKEN',
  payload,
});

export const setPlaidError = (
  payload: PlaidActionSetPlaidErrorPayload,
): PlaidActionSetPlaidError => ({
  type: 'SET_PLAID_ERROR',
  payload,
});

export const setConnectedInstitutions = (
  payload: PlaidActionSetConnectedInstitutionsPayload,
): PlaidActionSetConnectedInsitutions => ({
  type: 'SET_CONNECTED_INSTITUTIONS',
  payload,
});

export const setAccounts = (
  payload: PlaidActionSetAccountsPayload,
): PlaidActionSetAccounts => ({
  type: 'SET_ACCOUNTS',
  payload,
});

export const setSelectedAccountId = (
  payload: PlaidActionSetSelectedAccountIdPayload,
): PlaidActionSetSelectedAccountId => ({
  type: 'SET_SELECTED_ACCOUNT_ID',
  payload,
});

export const setDefaultPaymentMethodDetails = (
  payload: PlaidActionSetDefaultPaymentMethodDetailsPayload,
): PlaidActionSetDefaultPaymentMethodDetails => ({
  type: 'SET_DEFAULT_PAYMENT_METHOD',
  payload,
});

////////////////////////////////
// REDUCER
export const plaidReducer = (
  state: PlaidContextState,
  action: BasePlaidAction,
): PlaidContextState => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case 'SET_PLAID_LINK_TOKEN':
      return {
        ...state,
        plaidLinkToken: action.payload.linkToken,
      };
    case 'SET_PLAID_ERROR':
      return {
        ...state,
        plaidError: action.payload.plaidError,
      };
    case 'SET_CONNECTED_INSTITUTIONS':
      return {
        ...state,
        connectedInstitutions: action.payload.connectedInstitutions,
      };
    case 'SET_ACCOUNTS':
      return {
        ...state,
        plaidAccounts: action.payload.plaidAccounts,
      };
    case 'SET_SELECTED_ACCOUNT_ID':
      return {
        ...state,
        selectedAccountId: action.payload.selectedAccountId,
      };
    case 'SET_DEFAULT_PAYMENT_METHOD':
      return {
        ...state,
        defaultPaymentMethod: action.payload.defaultPaymentMethod,
      };
    default:
      return state;
  }
};
