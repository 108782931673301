import { Collapse } from '@material-ui/core';
import {
  Button,
  Card,
  Checkbox,
  Color,
  FileHandle,
  FileUpload,
  Flex,
  FontAwesome,
  Link,
  Message,
  StepCardStep,
  Text,
  TextField,
  Toast,
} from 'component-library';
import {
  FileTypes,
  ProgramStageEnum,
  ProgramSubStageEnum,
} from 'lib/constants';
import { CopyToClipboard } from 'lib/copyToClipboard';
import { IsValidDate } from 'lib/helpers';
import { useDocumentAccessLink } from 'lib/useDocumentAccessLink';
import { observer } from 'mobx-react';
import { CompanyContext } from 'pages/CompanyRequired';
import { IsValidEinNumber } from 'pages/kyc/components/helpers';
import React, { useContext, useState } from 'react';
import { useTaxCreditsStores } from '../../../../../stores/useStores';
import { ClientReviewStepFAQ } from '../../sideDrawer/ClientReviewStepFAQ';
import { Step, StepProps } from '../../TaxCreditProgramSteps';
import { ClientReviewStepDocumentList } from './ClientReviewStepDocumentList';
import { FilingDateTextField } from 'products/tax-credits/features/redemption/components';

export const stepConfig: Step = {
  name: 'client_review',
  number: 3,
  title: 'File your forms with the IRS',
  dataTestId: 'client-review',
  faq: <ClientReviewStepFAQ />,
};

export const ClientReviewStep = observer(({ federalRDProgram }: StepProps) => {
  const { company } = useContext(CompanyContext);
  const { taxCreditsPage, form8974 } = useTaxCreditsStores();

  const [didFile, setDidFile] = useState(false);
  const [taxFilingDate, setTaxFilingDate] = useState('');
  const [EIN, setEIN] = useState(company.taxId);
  const [toast, setToast] = useState<Message[]>([]);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const { accessLink, accessLinkError } = useDocumentAccessLink(
    company.id,
    federalRDProgram.id,
  );

  const handleUpdateEIN = (value: string) => {
    setEIN(value);
    form8974.setCompanyTaxId(value);
  };

  const handleOnCopyLink = () => {
    if (accessLinkError) {
      setToast([
        {
          text: 'There was an error while copying your link. Please try again.',
          status: 'error',
        },
      ]);
    }
    if (accessLink) {
      const onCopied = () => {
        setIsLinkCopied(true);
        setToast([
          {
            text: 'Link, copied!',
            status: 'success',
          },
        ]);
      };
      CopyToClipboard(accessLink, onCopied);
    }
  };

  const filingDate = new Date(taxFilingDate);
  const currentDate = new Date();

  const filingDateError =
    !IsValidDate(taxFilingDate) ||
    !taxFilingDate ||
    ![federalRDProgram.taxYear + 1, federalRDProgram.taxYear + 2].includes(
      filingDate.getFullYear(),
    ) ||
    filingDate > currentDate;

  const disableContinue =
    filingDateError ||
    !IsValidEinNumber(EIN ?? '') ||
    !form8974.filesExistInUploadQueue;

  return (
    <StepCardStep
      active={stepConfig.name === federalRDProgram.stage}
      dataTestId={stepConfig.dataTestId}
      learnMoreOnClick={() =>
        taxCreditsPage.setShowSideDrawer(true, stepConfig.name)
      }
      stepNumber={stepConfig.number}
      title={stepConfig.title}
    >
      <>
        <Text
          paddingBottom={8}
          text='R&D credit forms should be submitted — as described below — with corresponding income taxes.'
        />
        <Text
          paddingBottom={8}
          text='Your CPA can securely download all these forms with the following magic link:'
        />
        <Flex padding={[0, 0, 8, 0]}>
          <Link
            onClick={() => CopyToClipboard(accessLink, handleOnCopyLink)}
            target='_blank'
            omitIcon
          >
            <Flex gap={4} alignItems='center'>
              <FontAwesome name='link' size={14} color={Color.blue._60} />
              <Text
                text='Copy link for CPA'
                variant='medium'
                color={Color.blue._60}
              />
            </Flex>
          </Link>
        </Flex>
        <ClientReviewStepDocumentList
          programId={federalRDProgram.id.toString()}
        />
        <Flex padding={[16, 0, 0, 0]}>
          <Checkbox
            onChange={() => setDidFile(!didFile)}
            options={[
              {
                checked: didFile,
                text: 'I have filed my taxes, including R&D credit forms',
                value: 'unused',
              },
            ]}
            fontWeight='regular'
          />
        </Flex>
        <Collapse in={didFile}>
          <Card noMargin>
            <Flex direction='column' padding={24}>
              <Text
                variant='medium'
                size={15}
                text='Provide some extra details'
              />
              <Text
                size={15}
                text='So we can best guide you on next steps.'
                paddingBottom={16}
              />
              <FilingDateTextField
                federalRDProgram={federalRDProgram}
                taxFilingDate={taxFilingDate}
                setTaxFilingDate={setTaxFilingDate}
                filingDateError={filingDateError}
              />
              <Flex padding={[24, 0]}>
                <TextField
                  dataTestId={'ein-field'}
                  helperText='Please confirm the EIN shown on your tax return.'
                  einNumberFormat
                  label='EIN'
                  value={EIN}
                  onChange={(e) => handleUpdateEIN(e.target.value)}
                />
              </Flex>
              <Card noMargin noBorder noBoxShadow maxWidth={544}>
                <FileUpload
                  dataTestId={'tax-form-file-upload'}
                  title='Upload your complete tax return'
                  subtitle='It should also include your Form 6765'
                  flexDirection={'column'}
                  onFileAdded={(file: FileHandle) => {
                    form8974.addFileToBeUploaded(file);
                    file.setUploadProgress(1, 1, 1);
                  }}
                  acceptedFiletypes={FileTypes.PDF}
                  allowFilePassword={false}
                  onFileRemoved={(file: FileHandle) =>
                    form8974.removeFileToBeUploaded(file)
                  }
                  maxFileSize={1024 * 1024 * 30}
                  error={false}
                  errorText={'error'}
                  alignItems='flex-start'
                />
              </Card>
            </Flex>
          </Card>
        </Collapse>
        <Button
          small
          flexAlignSelf='flex-start'
          label='Continue'
          dataTestId='continue-button'
          loading={form8974.taxInfoSubmitting}
          disabled={disableContinue}
          onClick={async () => {
            await form8974.uploadTaxDocsFor8974(federalRDProgram.id);

            if (!form8974.requestError && form8974.taxInfoSubmitted) {
              await form8974.updateProgramStage(
                federalRDProgram.id,
                ProgramStageEnum.FINISHED,
                ProgramSubStageEnum.READY_TO_REDEEM,
              );
            } else {
              setToast([
                {
                  text: 'There was an error while uploading your documents. Please try again.',
                  status: 'error',
                },
              ]);
            }
          }}
        />
        {isLinkCopied && <Toast toasts={toast} setToast={setToast} />}
      </>
    </StepCardStep>
  );
});
