import * as React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useLayoutEffect } from 'react';
import { useCommonStores } from 'stores/useStores';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  root: {
    background: '#fff',
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
  },
  mainContent: {
    margin: '40px',
    width: '100%',
    maxWidth: '600px',
  },
  title: {
    marginTop: '50px',
    fontWeight: 700,
    fontSize: '30px',
    color: '#00152D',
    fontFamily: 'Open Sans',
    textAlign: 'center',
  },
  imgContainer: {
    borderRadius: '50%',
    marginRight: '15px',
    borderColor: '#1A73E8',
    borderWidth: '2px',
    borderStyle: 'solid',
    width: '140px',
    height: '140px',
    marginLeft: '80px',
  },
  img: {
    marginLeft: '30px',
    marginTop: '32px',
  },
}));

export const SelfSignInMobileWarningPage = observer(() => {
  const classes = useStyles();
  const {
    app: { isMobile },
  } = useCommonStores();

  useLayoutEffect(() => {
    if (!isMobile) {
      window.location.href = '/';
    }
  }, [isMobile]);

  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        <div className={classes.imgContainer}>
          <img
            src={`${process.env.PUBLIC_URL}/images/emails.svg`}
            alt={'Email Verification'}
            className={classes.img}
          />
        </div>
        <Typography variant='h1' component='div' className={classes.title}>
          To access the MainStreet dashboard, please use the link included from
          your “Welcome” email using a desktop or laptop computer.
        </Typography>
      </div>
    </div>
  );
});
