import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useFeatureFlags, useTaxCreditsStores } from 'stores/useStores';
import {
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationModalStatusEnum,
  QualificationStatusEnum,
} from 'lib/constants';
import {
  SurveyFlowBillingSummary,
  SurveyFlowContainer,
} from 'products/tax-credits/components';
import { Animate, Flex, Spinner } from 'component-library';
import { QualificationStatusModal } from '../QualificationStatusModal';
import { useEffectOnce } from 'lib/helpers';

interface BillingSummaryProps {
  pathTo: string;
  taxYear: number;
}

interface Summary {
  msFee: number;
  estimateAmount: number;
  totalNet: number;
}

export const BillingSummary = observer(
  ({ pathTo, taxYear }: BillingSummaryProps) => {
    const history = useHistory();
    const featureFlags = useFeatureFlags();
    const { retirement, orderForms, surveyFlow } = useTaxCreditsStores();
    const [billingSummary, setBillingSummary] = useState<Summary>();

    const handleOnContinue = async () => {
      surveyFlow.setModalContinueIsLoading(true);

      // update program stage
      await surveyFlow.updateProgramStageStatus(
        ProgramNameEnum.FED_RETIREMENT_CREDIT,
        taxYear,
        ProgramStageEnum.EXPENSE_CLASSIFICATION,
        QualificationStatusEnum.QUALIFIED,
        ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT,
      );

      // update order form accepted_at
      await retirement.updateOrderForm(taxYear).then(() => {
        history.push(pathTo);
        surveyFlow.setShowQualificationStatusModal(false);
        surveyFlow.setModalContinueIsLoading(false);
        // clear qualification statuses
        surveyFlow.qualificationStatus = null;
      });
    };

    const handleFormAttestation = () => {
      orderForms.setAttestationAccepted();
      retirement.setBillingSummaryModal(
        QualificationModalStatusEnum.BILLING_SUMMARY,
      );
    };

    const isDisabled =
      !orderForms.defaultPaymentMethodV2 ||
      (featureFlags.showOrderFormAttestation &&
        !orderForms.attestationAccepted);

    useEffectOnce(async () => {
      retirement.setLoading(true);
      await retirement.getOrderForm(taxYear).then(() => {
        const orderForm = retirement.billingSummary;

        if (orderForm) {
          const estimateAmount = orderForm.estimatedTotalCreditCents;
          const msFee = orderForm.totalFeeCents;
          const totalNet = estimateAmount - msFee;

          setBillingSummary({
            msFee,
            estimateAmount,
            totalNet,
          });
        }

        retirement.setLoading(false);
      });
    });

    return (
      <>
        <SurveyFlowContainer
          title='Retirement Plan Tax Credit'
          onContinue={() => surveyFlow.setShowQualificationStatusModal(true)}
          isDisabled={isDisabled}
          dataTestId='retirement-plan-credit'
        >
          {!retirement.loading && billingSummary ? (
            <SurveyFlowBillingSummary
              creditTitle={`Final ${taxYear} Retirement Plan Tax Credit`}
              msFee={billingSummary?.msFee}
              creditEstimate={billingSummary?.estimateAmount}
              netAmount={billingSummary?.totalNet}
              handleFormAttestation={handleFormAttestation}
              taxYear={taxYear}
              nonBindingCopy='The credit estimate below is non-binding and represents your final credit amount'
              paymentAlertCopy='You will be charged when we deliver your final credit document.'
            />
          ) : (
            <Flex justifyContent='center' padding={16}>
              <Spinner />
            </Flex>
          )}
        </SurveyFlowContainer>
        {surveyFlow.qualificationStatus && (
          <Animate enter={'fade-in'}>
            <QualificationStatusModal
              modalOnClick={handleOnContinue}
              showModal={surveyFlow.showQualificationStatusModal}
              setModalWidth={560}
              programName={ProgramNameEnum.FED_RETIREMENT_CREDIT}
            />
          </Animate>
        )}
      </>
    );
  },
);
