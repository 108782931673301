import React from 'react';
import { Content, Text, TextField } from 'component-library';

interface InvitationFormProps {
  showEmailError: boolean;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setEmail: (value: string) => void;
  firstName: string;
  lastName: string;
  email: string;
}

export const InvitationForm = ({
  setFirstName,
  setLastName,
  setEmail,
  firstName,
  lastName,
  email,
  showEmailError,
}: InvitationFormProps) => {
  return (
    <Content
      paddingTopBottom={0}
      paddingLeftRight={0}
      flex
      flexDirection='column'
      gap={24}
    >
      <TextField
        label='First Name'
        onChange={(event) => {
          setFirstName(event.target.value);
        }}
        value={firstName}
        dataTestId={'firstName'}
      />
      <TextField
        label='Last Name'
        onChange={(event) => {
          setLastName(event.target.value);
        }}
        value={lastName}
        dataTestId={'lastName'}
      />
      <TextField
        error={showEmailError}
        label='Email'
        onChange={(event) => {
          setEmail(event.target.value);
        }}
        value={email}
        dataTestId={'email'}
        helperText={showEmailError && 'Please provide a valid email'}
      />
      <div>
        <Text size={15} text='Role' />
        <Text size={15} text='Admin' variant='medium' />
      </div>
    </Content>
  );
};
