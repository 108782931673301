import { MonthNameToNumber } from './helpers';

export const isTransitionDateFirstDayOfCurrentFiscalYear = (
  transitionDate: string,
  fiscalYearEndDate: string,
) => {
  const taxYearEndDate = MonthNameToNumber(fiscalYearEndDate); // February (0-based index: 0 = January, 1 = February, etc.)

  // Parse the transition date into a Date object
  const transitionDateObj = new Date(transitionDate);

  // Calculate the first day of the month after the tax year end month
  let firstDayOfNextMonth;
  if (taxYearEndDate === 11) {
    // If tax year ends in December
    firstDayOfNextMonth = new Date(transitionDateObj.getFullYear(), 0, 1); // Next year January 1st
  } else {
    firstDayOfNextMonth = new Date(
      transitionDateObj.getFullYear(),
      taxYearEndDate + 1,
      1,
    );
  }

  // Check if the transitionDate is the same as the first day of the next month
  const isTransitionDateFirstOfNextMonth =
    transitionDateObj.getDate() === firstDayOfNextMonth.getDate() &&
    transitionDateObj.getMonth() === firstDayOfNextMonth.getMonth() &&
    transitionDateObj.getFullYear() === firstDayOfNextMonth.getFullYear();

  return isTransitionDateFirstOfNextMonth;
};
