import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Button, Color, Flex, Icon, IconEnum, Text } from 'component-library';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    right: '1rem',
    bottom: '1rem',
    borderRadius: '40px',
    backgroundColor: Color.green._90,
    '&:hover': {
      backgroundColor: Color.green._80,
    },
    // TODO: Temporary z-index to show on top of existing "Need help?" button which
    // is z-index 999 and outside of our codebase.
    // Will remove with AI-38, we'll only show one or the other.
    zIndex: 1000,
  },
}));

export const ChatButton = observer(() => {
  const classes = useStyles();
  const { chatbot } = useCommonStores();

  return (
    <Button
      className={classes.root}
      label={
        <Flex alignItems='center'>
          <Icon color={Color.neutral.white} name={IconEnum.comment_dots} />
          <Text
            color={Color.neutral.white}
            text='Chat with us'
            variant='medium'
          />
        </Flex>
      }
      onClick={() => chatbot.toggleChatWindow()}
    />
  );
});

export default ChatButton;
