import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Color, Flex, Link, Text } from 'component-library';
import { DateDisplay } from 'component-library/design-system/date';
import { MonthToQuarterString } from '../../../lib/helpers';

const useStyles = makeStyles(() => ({
  keyDate: {
    borderWidth: '1px 0px 0px 0px',
    border: `solid ${Color.neutral._20}`,
  },
}));

export type KeyDateCardType = {
  dataTestId:
    | 'taxFilingDeadlineDate'
    | 'yeaDeadlineDate'
    | 'creditRedemptionDate';
  keyDate: Date;
  text: string;
  linkText?: string;
  url?: URL;
  type: 'taxFilingDeadlineDate' | 'yeaDeadlineDate' | 'creditRedemptionDate';
};
/**
 * Displays KeyDateCards and formats KeyDate
 * */
export const KeyDateCard = observer(
  ({ dataTestId, keyDate, text, linkText, url, type }: KeyDateCardType) => {
    const classes = useStyles();
    const currentYear = new Date().getFullYear();

    const convertDateToQuarter = (date: Date) => {
      const monthName = date.toLocaleString('default', { month: 'long' });
      const quarter = MonthToQuarterString(monthName);

      if (quarter === 'Q1') {
        return `${quarter} ${currentYear + 1}`;
      } else {
        return `${quarter} ${currentYear}`;
      }
    };

    const formatKeyDate = () => {
      if (type === 'creditRedemptionDate') {
        return convertDateToQuarter(keyDate);
      }

      return new Date(keyDate).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    };

    const href = url ? url.href : undefined;

    return (
      <Flex
        dataTestId={dataTestId}
        gap={8}
        direction={'column'}
        className={classes.keyDate}
        padding={16}
      >
        <DateDisplay
          dataTestId={dataTestId && `${dataTestId}-dateDisplay`}
          date={`${formatKeyDate()}`}
          borderColor={Color.neutral._40}
        />
        <Text text={text} />
        {linkText && href && (
          <Link href={href} text={linkText} external={true} />
        )}
      </Flex>
    );
  },
);
