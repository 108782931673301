import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Color, Flex, Text } from 'component-library';
import { Form941QuarterlyInputs } from '../components';
import { FilingStatusQuarter } from 'lib/interfaces';
import { FINCH_PAYROLL_SYSTEMS } from 'lib/constants';
import { formatPayrollString } from 'lib/helpers';

interface QuarterFilingForm941ModuleProps {
  currentQuarterFiling: FilingStatusQuarter | undefined;
  missedQuarterlyFiling: FilingStatusQuarter[];
  payrollProvider: string;
}

const useStyles = makeStyles(() => ({
  container: {
    border: `1px solid ${Color.neutral._20}`,
  },
  instructions: {
    backgroundColor: Color.neutral._light_20,
  },
}));

export const QuarterFilingForm941Module = observer(
  ({
    currentQuarterFiling,
    missedQuarterlyFiling,
    payrollProvider,
  }: QuarterFilingForm941ModuleProps) => {
    const classes = useStyles();
    const payrollProviderDisplay = payrollProvider
      ? FINCH_PAYROLL_SYSTEMS.find((p) => p.id === payrollProvider)
          ?.displayName || formatPayrollString(payrollProvider)
      : 'payroll software ';

    return (
      <Flex direction='column' className={classes.container}>
        <Flex
          className={classes.instructions}
          direction='column'
          padding={24}
          gap={8}
        >
          <Text
            variant='medium'
            size={23}
            text={`You have missed ${
              missedQuarterlyFiling.length
            } filing quarter ${
              missedQuarterlyFiling.length > 1 ? 'deadlines' : 'deadline'
            } to redeem your credit.`}
          />
          <Text
            text={`Input the relevant values for each quarter. You can find the values on Form 941 in your ${payrollProviderDisplay} account. After providing this information, MainStreet will provide you with the necessary documents to redeem your credits.`}
          />
        </Flex>
        {missedQuarterlyFiling.map((filing, index) => {
          const reportingQuarter = filing.reportingQuarter;

          return (
            <Form941QuarterlyInputs
              key={index}
              taxYear={reportingQuarter.year}
              quarter={reportingQuarter.quarter}
              classes={classes}
            />
          );
        })}
        {currentQuarterFiling && (
          <Form941QuarterlyInputs
            taxYear={currentQuarterFiling.reportingQuarter.year}
            quarter={currentQuarterFiling.reportingQuarter.quarter}
            classes={classes}
            current
          />
        )}
      </Flex>
    );
  },
);
