import React from 'react';

interface Props {
  className?: string;
}

const DeskCalendar = ({ className }: Props) => (
  <svg
    width='70'
    height='56'
    viewBox='0 0 70 56'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M64.31 51.6L58.31 9.30005H57.96H6.35H6L0 55.3101H51.96L52.44 51.6H64.31Z'
      fill='#0A1A0C'
    />
    <path d='M68.62 47.6L62.62 5.30005H10.66L16.66 47.6H68.62Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.2825 4.97226C10.3774 4.86287 10.5152 4.80005 10.66 4.80005H62.62C62.869 4.80005 63.0801 4.98328 63.1151 5.22983L69.1151 47.5298C69.1354 47.6733 69.0926 47.8185 68.9976 47.9278C68.9026 48.0372 68.7649 48.1 68.62 48.1H16.66C16.411 48.1 16.2 47.9168 16.165 47.6703L10.165 5.37027C10.1446 5.22684 10.1875 5.08165 10.2825 4.97226ZM11.236 5.80005L17.0941 47.1H68.0441L62.186 5.80005H11.236Z'
      fill='#0A1A0C'
    />
    <path
      d='M56.2699 51.3101H4.30994L10.3099 5.30005H62.2699L56.2699 51.3101Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.81414 5.23539C9.84661 4.98635 10.0588 4.80005 10.3099 4.80005H62.2699C62.4138 4.80005 62.5507 4.86203 62.6457 4.97015C62.7406 5.07826 62.7843 5.22203 62.7657 5.3647L56.7657 51.3747C56.7333 51.6237 56.5211 51.8101 56.2699 51.8101H4.30994C4.16606 51.8101 4.02915 51.7481 3.93422 51.64C3.83929 51.5318 3.79553 51.3881 3.81414 51.2454L9.81414 5.23539ZM10.749 5.80005L4.87938 50.8101H55.8309L61.7005 5.80005H10.749Z'
      fill='#0A1A0C'
    />
    <path
      d='M16.25 51.3101V40.9301L5.87 40.9401L16.25 51.3101Z'
      fill='#ACE119'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.6033 40.5763C16.6971 40.6701 16.7499 40.7974 16.7499 40.9301V51.3101C16.7499 51.5122 16.6281 51.6945 16.4413 51.7719C16.2546 51.8494 16.0395 51.8067 15.8965 51.6638L5.5165 41.2938C5.37347 41.1509 5.33057 40.9359 5.40781 40.749C5.48505 40.5622 5.66722 40.4402 5.86941 40.4401L16.2494 40.4301C16.3821 40.4299 16.5094 40.4825 16.6033 40.5763ZM7.07665 41.4389L15.7499 50.1038V41.4305L7.07665 41.4389Z'
      fill='#0A190C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.81 1C19.4362 1 17.51 2.92614 17.51 5.3C17.51 7.67386 19.4362 9.6 21.81 9.6V10.6C18.8839 10.6 16.51 8.22614 16.51 5.3C16.51 2.37386 18.8839 0 21.81 0C24.7362 0 27.11 2.37386 27.11 5.3H26.11C26.11 2.92614 24.1839 1 21.81 1Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.09 1C47.7162 1 45.79 2.92614 45.79 5.3C45.79 7.67386 47.7162 9.6 50.09 9.6V10.6C47.1639 10.6 44.79 8.22614 44.79 5.3C44.79 2.37386 47.1639 0 50.09 0C53.0162 0 55.39 2.37386 55.39 5.3H54.39C54.39 2.92614 52.4639 1 50.09 1Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M35.95 1C33.5762 1 31.65 2.92614 31.65 5.3C31.65 7.67386 33.5762 9.6 35.95 9.6V10.6C33.0239 10.6 30.65 8.22614 30.65 5.3C30.65 2.37386 33.0239 0 35.95 0C38.8762 0 41.25 2.37386 41.25 5.3H40.25C40.25 2.92614 38.3239 1 35.95 1Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.65 15.39H14.75V13.39H54.65V15.39Z'
      fill='black'
    />
    <path d='M20.63 26.88H13.85L14.63 20.88H21.41L20.63 26.88Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.1342 20.8155C14.1665 20.5664 14.3788 20.38 14.63 20.38H21.41C21.5538 20.38 21.6907 20.442 21.7856 20.55C21.8806 20.6581 21.9243 20.8018 21.9058 20.9445L21.1258 26.9445C21.0934 27.1936 20.8812 27.38 20.63 27.38H13.85C13.7061 27.38 13.5693 27.3181 13.4743 27.21C13.3794 27.1019 13.3356 26.9582 13.3542 26.8155L14.1342 20.8155ZM15.0692 21.38L14.4192 26.38H20.1908L20.8408 21.38H15.0692Z'
      fill='#0A1A0C'
    />
    <path d='M30.98 26.88H24.21L24.99 20.88H31.76L30.98 26.88Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.4941 20.8155C24.5265 20.5664 24.7387 20.38 24.99 20.38H31.76C31.9038 20.38 32.0407 20.442 32.1356 20.55C32.2305 20.6581 32.2743 20.8018 32.2558 20.9445L31.4758 26.9445C31.4434 27.1936 31.2312 27.38 30.98 27.38H24.21C24.0661 27.38 23.9292 27.3181 23.8343 27.21C23.7394 27.1019 23.6956 26.9582 23.7141 26.8155L24.4941 20.8155ZM25.4292 21.38L24.7792 26.38H30.5408L31.1908 21.38H25.4292Z'
      fill='#0A1A0C'
    />
    <path
      d='M41.3399 26.88H34.5599L35.3399 20.88H42.1199L41.3399 26.88Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34.8441 20.8155C34.8765 20.5664 35.0887 20.38 35.3399 20.38H42.1199C42.2638 20.38 42.4007 20.442 42.4956 20.55C42.5905 20.6581 42.6343 20.8018 42.6158 20.9445L41.8358 26.9445C41.8034 27.1936 41.5912 27.38 41.3399 27.38H34.5599C34.4161 27.38 34.2792 27.3181 34.1843 27.21C34.0894 27.1019 34.0456 26.9582 34.0641 26.8155L34.8441 20.8155ZM35.7791 21.38L35.1291 26.38H40.9007L41.5507 21.38H35.7791Z'
      fill='#0A1A0C'
    />
    <path
      d='M51.6899 26.88H44.9199L45.6999 20.88H52.4699L51.6899 26.88Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.2041 20.8155C45.2365 20.5664 45.4487 20.38 45.6999 20.38H52.4699C52.6138 20.38 52.7506 20.442 52.8456 20.55C52.9405 20.6581 52.9843 20.8018 52.9658 20.9445L52.1858 26.9445C52.1534 27.1936 51.9411 27.38 51.6899 27.38H44.9199C44.7761 27.38 44.6392 27.3181 44.5443 27.21C44.4493 27.1019 44.4056 26.9582 44.4241 26.8155L45.2041 20.8155ZM46.1391 21.38L45.4891 26.38H51.2507L51.9007 21.38H46.1391Z'
      fill='#0A1A0C'
    />
    <path d='M19.23 36.36H12.45L13.23 30.36H20.01L19.23 36.36Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.7341 30.2955C12.7665 30.0464 12.9787 29.86 13.23 29.86H20.01C20.1538 29.86 20.2907 29.9219 20.3856 30.03C20.4805 30.1381 20.5243 30.2818 20.5058 30.4244L19.7258 36.4244C19.6934 36.6736 19.4812 36.86 19.23 36.86H12.45C12.3061 36.86 12.1692 36.798 12.0743 36.69C11.9794 36.5819 11.9356 36.4382 11.9541 36.2955L12.7341 30.2955ZM13.6692 30.86L13.0192 35.86H18.7907L19.4407 30.86H13.6692Z'
      fill='#0A1A0C'
    />
    <path
      d='M29.5799 36.36H22.8099L23.5899 30.36H30.3599L29.5799 36.36Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.0941 30.2955C23.1265 30.0464 23.3387 29.86 23.5899 29.86H30.3599C30.5038 29.86 30.6407 29.9219 30.7356 30.03C30.8305 30.1381 30.8743 30.2818 30.8558 30.4244L30.0758 36.4244C30.0434 36.6736 29.8312 36.86 29.5799 36.86H22.8099C22.6661 36.86 22.5292 36.798 22.4343 36.69C22.3394 36.5819 22.2956 36.4382 22.3141 36.2955L23.0941 30.2955ZM24.0291 30.86L23.3791 35.86H29.1407L29.7907 30.86H24.0291Z'
      fill='#0A1A0C'
    />
    <path
      d='M39.9399 36.36H33.1599L33.9399 30.36H40.7199L39.9399 36.36Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.4441 30.2955C33.4765 30.0464 33.6887 29.86 33.9399 29.86H40.7199C40.8638 29.86 41.0006 29.9219 41.0956 30.03C41.1905 30.1381 41.2343 30.2818 41.2157 30.4244L40.4357 36.4244C40.4034 36.6736 40.1911 36.86 39.9399 36.86H33.1599C33.0161 36.86 32.8792 36.798 32.7843 36.69C32.6893 36.5819 32.6455 36.4382 32.6641 36.2955L33.4441 30.2955ZM34.3791 30.86L33.7291 35.86H39.5007L40.1507 30.86H34.3791Z'
      fill='#0A1A0C'
    />
    <path
      d='M50.2899 36.36H43.5199L44.2999 30.36H51.0699L50.2899 36.36Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M43.8041 30.2955C43.8365 30.0464 44.0487 29.86 44.2999 29.86H51.0699C51.2137 29.86 51.3506 29.9219 51.4456 30.03C51.5405 30.1381 51.5843 30.2818 51.5657 30.4244L50.7857 36.4244C50.7533 36.6736 50.5411 36.86 50.2899 36.86H43.5199C43.3761 36.86 43.2392 36.798 43.1442 36.69C43.0493 36.5819 43.0055 36.4382 43.0241 36.2955L43.8041 30.2955ZM44.7391 30.86L44.0891 35.86H49.8507L50.5007 30.86H44.7391Z'
      fill='#0A1A0C'
    />
    <path
      d='M28.1799 45.85H21.4099L22.1899 39.85H28.9699L28.1799 45.85Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.6941 39.7855C21.7265 39.5364 21.9387 39.35 22.1899 39.35H28.9699C29.1139 39.35 29.2509 39.412 29.3458 39.5203C29.4408 39.6286 29.4844 39.7725 29.4656 39.9152L28.6756 45.9152C28.6429 46.164 28.4308 46.35 28.1799 46.35H21.4099C21.2661 46.35 21.1292 46.288 21.0343 46.18C20.9393 46.0719 20.8955 45.9282 20.9141 45.7855L21.6941 39.7855ZM22.6291 40.35L21.9791 45.35H27.7414L28.3998 40.35H22.6291Z'
      fill='#0A1A0C'
    />
    <path
      d='M38.5399 45.85H31.7599L32.5499 39.85H39.3199L38.5399 45.85Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.0542 39.7847C32.0869 39.5359 32.299 39.35 32.5499 39.35H39.3199C39.4637 39.35 39.6006 39.4119 39.6955 39.52C39.7905 39.6281 39.8343 39.7718 39.8157 39.9144L39.0357 45.9144C39.0033 46.1636 38.7911 46.35 38.5399 46.35H31.7599C31.6159 46.35 31.4789 46.2879 31.384 46.1796C31.289 46.0714 31.2454 45.9275 31.2642 45.7847L32.0542 39.7847ZM32.9884 40.35L32.33 45.35H38.1007L38.7507 40.35H32.9884Z'
      fill='#0A1A0C'
    />
    <path
      d='M48.8899 45.85H42.1199L42.8999 39.85H49.6799L48.8899 45.85Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M42.4041 39.7855C42.4364 39.5364 42.6487 39.35 42.8999 39.35H49.6799C49.8239 39.35 49.9609 39.412 50.0558 39.5203C50.1507 39.6286 50.1944 39.7725 50.1756 39.9152L49.3856 45.9152C49.3528 46.164 49.1408 46.35 48.8899 46.35H42.1199C41.976 46.35 41.8392 46.288 41.7442 46.18C41.6493 46.0719 41.6055 45.9282 41.624 45.7855L42.4041 39.7855ZM43.3391 40.35L42.6891 45.35H48.4514L49.1097 40.35H43.3391Z'
      fill='#0A1A0C'
    />
  </svg>
);

export default DeskCalendar;
