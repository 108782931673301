import {
  Card,
  Content,
  Expandable,
  ExpandableHeader,
  SurveyQuestion,
} from 'component-library';
import { AnswerType } from 'component-library/dashboard/surveyquestion/SurveyQuestion';
import {
  hasSubQuestions,
  isQuestionAnswered,
  SaveAnswersResponse,
  SurveyAnswer,
} from 'lib/useSurveyQuestions';
import SurveyQuestionFooter from './SurveyQuestionFooter';
import React, { useEffect, useState } from 'react';
import { CmsQuestionData, GraphCMSAnswerTypeEnum } from 'lib/interfaces';
import SurveyQuestionWithSubQuestions from 'components/SurveyQuestionWithSubQuestions';
import _ from 'lodash';
import { CentsToDisplayString } from 'lib/helpers';
import { ChatBotStore } from '../stores/common/ChatBotStore';

interface ExpandableSurveyQuestionProps {
  questionProps: CmsQuestionData;
  dataTestId?: string;
  onQuestionPropsChange?: (answers: SurveyAnswer[]) => void;
  children?: React.ReactNode | JSX.Element;
  editModeOverride?: boolean;
  setEditModeOverride?: (editMode: boolean) => void;
  onEditModeToggle?: (questionId: string, editMode: boolean) => void;
  helpDrawerContent?: React.ReactNode | JSX.Element;
  helpLinkText?: string;
  onHelpLinkClick?: (() => void) | string;
  customAnswerSubtitle?: string;
  allowButtonDeselect?: boolean;
  saveAnswers?: () => Promise<SaveAnswersResponse>;
  chatbot?: ChatBotStore;
}

// some answerValues are answer ids, some are plain text, some are arrays
export const evaluateAnswerText = (
  questionProps: CmsQuestionData,
): string | undefined => {
  const { answerValue } = questionProps;
  if (Array.isArray(answerValue)) {
    const answers = answerValue.map(
      (answer) =>
        questionProps.answerIDs?.find((entry) => entry.id === answer)?.text,
    );
    return answers.filter((a) => a).join(', ');
  } else if (
    questionProps.answerType === GraphCMSAnswerTypeEnum.CURRENCY &&
    answerValue &&
    _.isNumber(answerValue)
  ) {
    return CentsToDisplayString(answerValue);
  } else {
    return (
      questionProps.answerIDs?.find((entry) => entry.id === answerValue)
        ?.text ??
      answerValue?.toString() ??
      ''
    );
  }
};

const canContinue = (inputValue: number | string | string[]): boolean =>
  Array.isArray(inputValue) ? !_.isEmpty(inputValue) : !!inputValue;

const ExpandableSurveyQuestion = ({
  questionProps,
  dataTestId,
  onQuestionPropsChange,
  children,
  editModeOverride,
  setEditModeOverride,
  onEditModeToggle,
  helpDrawerContent,
  helpLinkText,
  onHelpLinkClick,
  customAnswerSubtitle,
  allowButtonDeselect = true,
  saveAnswers,
  chatbot,
}: ExpandableSurveyQuestionProps) => {
  const { answerValue } = questionProps;
  const [editMode, setEditMode] = useState<boolean>(
    !isQuestionAnswered(questionProps),
  );
  const [hasAnsweredQuestion, setHasAnsweredQuestion] = useState<boolean>(
    isQuestionAnswered(questionProps),
  );

  const [inputValue, setInputValue] = useState<string | number | string[]>(
    answerValue || '',
  );
  const [answerText, setAnswerText] = useState<string | undefined>(
    evaluateAnswerText(questionProps),
  );
  const chatBotQuestion = chatbot
    ? chatbot.isAiQuestionEnabled(questionProps.aiQuestion)
    : undefined;
  const chatBotPrompt = () =>
    chatbot ? chatbot.autoSendAiMessage(questionProps.aiQuestion) : undefined;

  useEffect(() => {
    setAnswerText(evaluateAnswerText(questionProps));
  }, [questionProps, questionProps.answerValue]);

  useEffect(() => {
    if (onEditModeToggle) {
      onEditModeToggle(questionProps.id, editModeOverride || editMode);
    }
  }, [editMode, editModeOverride, onEditModeToggle, questionProps.id]);

  const answerOptions = questionProps.answerIDs?.map((option) => ({
    id: option.id,
    text: option.text,
  }));

  const fontSize = 15;

  const onAnswerChange = (
    questionId: string,
    value: string | number | string[],
  ) => {
    if (onQuestionPropsChange) {
      onQuestionPropsChange([{ questionId, answerValue: value }]);
    }
  };

  const onSubQuestionsComplete = () => {
    if (onQuestionPropsChange) {
      const subQuestionAnswers = questionProps.subQuestions
        ?.filter((sq) => sq.answerValue != null)
        .map((subQuestion) => ({
          questionId: subQuestion.id,
          answerValue: subQuestion.answerValue,
        }));
      if (subQuestionAnswers) {
        onQuestionPropsChange(subQuestionAnswers);
      }
    }
    setEditMode(false);
  };

  const renderQuestion = () =>
    questionProps.answerType === 'text' ||
    questionProps.answerType === 'text_area' ||
    questionProps.answerType === 'number' ||
    questionProps.answerType === 'currency' ||
    questionProps.answerType === 'percentage' ||
    questionProps.answerType === 'date' ||
    questionProps.answerType === 'email' ||
    questionProps.answerType === 'year' ||
    questionProps.answerType === 'multiple_select' ||
    questionProps.answerType === 'ssn' ? (
      <React.Fragment>
        <Content paddingLeftRight={24} paddingTopBottom={24}>
          <SurveyQuestion
            dataTestId={questionProps.id}
            answerType={questionProps.answerType as AnswerType}
            onChange={(val) => setInputValue(val)}
            placeholder={questionProps.placeholder}
            text={questionProps.text}
            answerValue={answerValue}
            answerOptions={answerOptions}
            withCard={false}
            questionTextSize={fontSize}
            questionSubtitleSize={fontSize}
            subtitle={questionProps.subtitle}
            helpLinkText={helpLinkText}
            onHelpLinkClick={onHelpLinkClick}
            onAnswerSelected={() => {
              // auto collapsible on first time answer for everything
              // except multiple choice which requires a save click.
              if (questionProps.answerType !== 'multiple_select') {
                if (saveAnswers && !hasAnsweredQuestion) {
                  saveAnswers();
                  setEditMode(false);
                  setHasAnsweredQuestion(true);
                }
              } else {
                saveAnswers && saveAnswers();
                setHasAnsweredQuestion(true);
              }
            }}
            onBlur={() => saveAnswers && !hasAnsweredQuestion && saveAnswers()}
            chatBotQuestion={chatBotQuestion}
            chatBotPrompt={chatBotPrompt}
          />
        </Content>
        {editMode && (
          <SurveyQuestionFooter
            onClickTestId={`${dataTestId}-continue`}
            ctaDisabled={!canContinue(inputValue)}
            ctaLabel='Save'
            onClick={() => {
              onAnswerChange(questionProps.id, inputValue);
              if (saveAnswers) {
                saveAnswers();
              }
              setEditMode(false);
            }}
          />
        )}
      </React.Fragment>
    ) : (
      // question types that don't require a button to trigger answer state change
      <React.Fragment>
        <Content paddingLeftRight={24} paddingTopBottom={24}>
          <SurveyQuestion
            dataTestId={questionProps.id}
            answerType={questionProps.answerType as AnswerType}
            onChange={(answerValue) => {
              questionProps.answerValue = answerValue;
              onAnswerChange(questionProps.id, answerValue);
            }}
            placeholder={questionProps.placeholder}
            text={questionProps.text}
            answerValue={answerValue}
            answerOptions={answerOptions}
            withCard={false}
            questionTextSize={fontSize}
            questionSubtitleSize={fontSize}
            subtitle={questionProps.subtitle}
            allowButtonDeselect={allowButtonDeselect}
            onAnswerSelected={() => {
              if (saveAnswers) {
                saveAnswers();
                setHasAnsweredQuestion(true);
              }
              // auto collapsible on first time answer
              if (!hasAnsweredQuestion) {
                setEditMode(false);
              }
            }}
            onBlur={() => {
              if (saveAnswers) {
                saveAnswers();
                setHasAnsweredQuestion(true);
              }
              // auto collapsible on first time answer
              if (!hasAnsweredQuestion) {
                setEditMode(false);
              }
            }}
            chatBotQuestion={chatBotQuestion}
            chatBotPrompt={chatBotPrompt}
          />
        </Content>
        {editMode && hasAnsweredQuestion && (
          <SurveyQuestionFooter
            onClickTestId={`${dataTestId}-continue`}
            ctaLabel='Save'
            onClick={() => {
              setEditMode(false);
            }}
          />
        )}
      </React.Fragment>
    );
  const renderQuestionWithSubQuestions = () => {
    return (
      <SurveyQuestionWithSubQuestions
        questionProps={questionProps}
        helpDrawerContent={helpDrawerContent}
        helpLinkText={helpLinkText}
        onHelpLinkClick={onHelpLinkClick}
        onComplete={() => onSubQuestionsComplete()}
        saveAnswers={saveAnswers}
        customAnswerSubtitle={
          editModeOverride ?? editMode ? undefined : customAnswerSubtitle
        }
      />
    );
  };

  return (
    <Card
      noMargin
      className={answerText ? 'blue' : 'green'}
      dataTestId={`expandable-survey-card-${questionProps.id}`}
    >
      {!(editModeOverride ?? editMode) && (
        <ExpandableHeader
          title={questionProps.text}
          subtitle={questionProps.subtitle ?? ''}
          editMode={editModeOverride ?? editMode}
          showAnswerAsSubtitle={customAnswerSubtitle || answerText}
          setEditMode={setEditModeOverride ?? setEditMode}
          fontSize={fontSize}
        />
      )}
      <Expandable expand={editModeOverride ?? editMode}>
        {children ? (
          <>{children}</>
        ) : hasSubQuestions(questionProps) ? (
          renderQuestionWithSubQuestions()
        ) : (
          renderQuestion()
        )}
      </Expandable>
    </Card>
  );
};

export default ExpandableSurveyQuestion;
