import React from 'react';
import { RouteConfiguration } from 'routes/RouteConfiguration';
import { useFeatureFlags } from 'stores/useStores';
import { GetStarted } from 'products/onboarding';
import HttpErrorPage from 'pages/HttpErrorPage';

export const useOnboardingRoutes = (): RouteConfiguration[] => {
  const featureFlags = useFeatureFlags();

  return [
    {
      path: `/get-started`,
      render: () =>
        featureFlags.showUnifiedQualification ? (
          <GetStarted />
        ) : (
          <HttpErrorPage errorCode={404} />
        ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
      documentPageTitle: 'Qualification - Get Started',
    },
  ];
};
