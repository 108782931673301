import { observer } from 'mobx-react';
import {
  useCommonStores,
  useRootStore,
  useTaxCreditsStores,
} from 'stores/useStores';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ExpenseClassificationProcessingSteps,
  Page,
  ProgramSubStageEnum,
} from '../../../../lib/constants';
import {
  ConnectToPayroll,
  HeaderBar,
  OverviewCallout,
  StepsContainer,
  TaxProcessingSteps,
} from '../components';
import {
  Color,
  Content,
  Icon,
  IconEnum,
  Link,
  SideDrawer,
  Spinner,
  Stepper,
  Text,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { Auth0FeatureContext } from '../../../../components/util/Auth0Feature';
import { ProgramData } from '../../../../lib/interfaces';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 80px',
    maxWidth: '1050px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: '100vh',
    backgroundColor: Color.neutral.white,
  },
  mainContent: {
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
    padding: '0 24px 80px',
    boxSizing: 'border-box',
    position: 'relative',
  },
}));

interface OverviewStepProps {
  programId: number;
  hasPayrollGap: boolean;
}

export const OverviewStep = observer(
  ({ programId, hasPayrollGap }: OverviewStepProps) => {
    const classes = useStyles();
    const { history } = useRootStore();
    const { client } = useContext(Auth0FeatureContext);
    const { taxCreditsPage } = useTaxCreditsStores();

    const [showContinueButton, setShowContinueButton] =
      useState<boolean>(false);
    const [showTaxProcessingDrawer, setShowTaxProcessingDrawer] =
      useState<boolean>(false);

    const [doNotUsePayroll, setDoNotUsePayroll] = useState<boolean>(false);
    const [confirmDoThisLater, setConfirmDoThisLater] =
      useState<boolean>(false);
    const [program, setProgram] = useState<ProgramData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { app } = useCommonStores();

    const updateProgramSubStage = useCallback(
      (programSubStage: ProgramSubStageEnum): Promise<string | undefined> => {
        return client
          .UpdateProgramSubStage({
            programId,
            programSubStage: programSubStage,
          })
          .then(({ errorMsg }) => {
            if (errorMsg) {
              console.error('Error: Could not update program substage.');
              return Promise.reject(errorMsg);
            }
          });
      },
      [client, programId],
    );

    // handle logic on continue here
    const onContinue = async () => {
      if (!taxCreditsPage.isYEAEditMode) {
        await updateProgramSubStage(
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EXPENSES,
        );
      }

      app.history.push(`/${Page.taxCredits}`);
    };

    useEffect(() => {
      if (doNotUsePayroll || confirmDoThisLater) {
        setShowContinueButton(true);
      } else {
        setShowContinueButton(false);
      }
    }, [doNotUsePayroll, confirmDoThisLater]);

    useEffect(() => {
      client.GetProgram(programId).then((res) => {
        if (res.errorMsg) {
          console.error('Error: Could not retrieve program.');
          return Promise.reject(res.errorMsg);
        }

        const { program } = res.data!;
        setProgram(program);
        setLoading(false);
      });
    }, [programId, client, history]);

    return (
      <div className={classes.container}>
        <HeaderBar currentPage={ExpenseClassificationProcessingSteps[0]} />
        <div className={classes.mainContent}>
          <div className={classes.root}>
            <StepsContainer
              showContinueButton={showContinueButton}
              onContinue={async () => await onContinue()}
              onContinueLabel='Continue'
              stepTitle={`This is your final ${program?.taxYear} R&D Credit Assessment`}
            >
              <>
                <Text>
                  There are four steps you’ll need to complete in order to
                  receive your R&D credits. Each section will help us understand
                  your R&D-eligible expenses, and account for your unique
                  circumstances. Some of the questions may seem familiar – we
                  are simply ensuring that the information we have on file is
                  still accurate, as things change often in small businesses
                  like yours! After this process, we will generate the forms
                  your CPA needs to claim your R&D credits this year.
                </Text>
                <Link
                  onClick={() => setShowTaxProcessingDrawer(true)}
                  variant='medium'
                  size={15}
                >
                  <Content flex paddingTop={8} gap={0} alignItems='center'>
                    <span>
                      See where this step fits in your R&D Credits experience
                      with MainStreet
                    </span>
                    <Icon
                      name={IconEnum.question_circle}
                      size={20}
                      color={Color.blue._60}
                    />
                  </Content>
                </Link>
                <OverviewCallout />
                {loading ? (
                  <Spinner />
                ) : (
                  <ConnectToPayroll
                    doNotUsePayroll={doNotUsePayroll}
                    confirmDoThisLater={confirmDoThisLater}
                    setDoNotUsePayroll={setDoNotUsePayroll}
                    setConfirmDoThisLater={setConfirmDoThisLater}
                    setShowContinueButton={setShowContinueButton}
                    hasPayrollGap={hasPayrollGap}
                    programTaxYear={program!.taxYear}
                    onAfterManual={async () => await onContinue()}
                  />
                )}
              </>
            </StepsContainer>
            <SideDrawer
              show={showTaxProcessingDrawer}
              title='Your R&D Tax Credits Journey'
              closeToggle={() => setShowTaxProcessingDrawer(false)}
              drawerActions={<></>}
              drawerContent={
                <Stepper
                  currentStep={2}
                  orientation='vertical'
                  steps={TaxProcessingSteps()}
                />
              }
            />
          </div>
        </div>
      </div>
    );
  },
);

export default OverviewStep;
