import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { Text, Color, Flex } from 'component-library';
import moment from 'moment';
import DOMPurify from 'dompurify';

const useStyles = makeStyles(() => ({
  textArea: {
    maxWidth: '90%',
    width: 'fit-content',
    background: Color.neutral._light_40,
    borderRadius: '6px 6px 6px 0px',
  },
  botImage: {
    width: '32px',
    height: '32px',
    borderRadius: '32px',
    background: Color.green._80,
    marginBottom: '22px',
  },
  img: {
    width: '16px',
  },
  innerFlex: {
    width: '90%',
  },
}));

interface ChatMessageProps {
  text: JSX.Element | string;
  createdAt: Date;
}

export default function BotChatMessage({ text, createdAt }: ChatMessageProps) {
  const classes = useStyles();

  const timestamp = moment(createdAt).format('h:mm A');

  return (
    <Flex gap={4} padding={[4, 0]} alignItems='end' justifyContent='start'>
      <Flex
        className={classes.botImage}
        alignItems='center'
        justifyContent='center'
      >
        <img
          className={classes.img}
          src={`${process.env.PUBLIC_URL}/images/logo/mst-logomark-light.svg`}
        />
      </Flex>
      <Flex
        className={classes.innerFlex}
        direction='column'
        alignItems='start'
        justifyContent='start'
      >
        <Flex
          className={classes.textArea}
          direction='column'
          alignItems='start'
          padding={[0, 16]}
        >
          {typeof text === 'string' ? (
            <Text size={13}>
              <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
              />
            </Text>
          ) : (
            <Text text={text} size={13} />
          )}
        </Flex>
        <Text text={timestamp} size={11} color={Color.neutral._60} />
      </Flex>
    </Flex>
  );
}
