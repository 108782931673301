import {
  CircularProgress,
  Button,
  makeStyles,
  ButtonProps,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '180px',
    maxWidth: '190px',
    padding: '0 40px',
    height: '60px',
    fontSize: '18px',
    fontWeight: 500,
    textTransform: 'initial',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

type Props = {
  loading: boolean;
} & ButtonProps;

export const SaveForLaterButton = ({ loading, ...props }: Props) => {
  const classes = useStyles();

  return (
    <Button
      variant='outlined'
      color='secondary'
      className={classes.button}
      disabled={loading}
      {...props}
    >
      Save for later
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Button>
  );
};
