import React, { useEffect } from 'react';
import {
  Animate,
  AnswerType,
  AnswerValueType,
  Color,
  Flex,
  Spinner,
  SurveyQuestion,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import {
  HealthcarePlanCreditQuestions,
  HealthcarePlanCreditQuestionsToAnswers,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationModalStatusEnum,
  QualificationStatusEnum,
  SurveyNameEnum,
} from 'lib/constants';
import { CmsQuestionData } from 'lib/interfaces';
import { useCommonStores, useTaxCreditsStores } from 'stores/useStores';
import _ from 'lodash';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import TitleHeader from 'components/TitleHeader';
import { QualificationStatusModal } from '../retirement-plan/components';
import { useEffectOnce } from 'component-library/_helpers/use-utils';
import DOMPurify from 'dompurify';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    maxWidth: '1200px',
    margin: '0 auto',
    position: 'relative',
  },
  container: {
    maxWidth: '65%',
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  subtitle: {
    fontSize: '15px',
    lineHeight: '24px',
    color: Color.neutral._80,
  },
  hasDropdown: {
    position: 'relative',
    zIndex: 2,
  },
}));

interface SmallBusinessHealthcareProps {
  taxYear: number;
}
export const SmallBusinessHealthcare: React.FC<SmallBusinessHealthcareProps> =
  observer(({ taxYear }) => {
    const classes = useStyles();
    const { app, chatbot } = useCommonStores();
    const { surveyFlow } = useTaxCreditsStores();

    const handleOnContinue = async () => {
      if (
        surveyFlow.qualificationStatus &&
        surveyFlow.qualificationStatus ===
          QualificationModalStatusEnum.HEALTHCARE_DQ
      ) {
        surveyFlow.setShowQualificationStatusModal(true);
      } else {
        surveyFlow.setSurveyContinueLoading(true);

        // get program estimate
        await surveyFlow.getProgramEstimates(
          taxYear,
          ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
        );

        // update program stage
        await surveyFlow
          .updateProgramStageStatus(
            ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
            taxYear,
            ProgramStageEnum.MS_REVIEW,
            QualificationStatusEnum.QUALIFIED,
            ProgramSubStageEnum.REVIEW_IN_PROGRESS,
          )
          .then(() => {
            app.history.push('/');
            surveyFlow.setSurveyContinueLoading(false);
            // clear qualification statuses
            surveyFlow.qualificationStatus = null;
          });
      }
    };

    const {
      isSurveyComplete,
      isLoading,
      surveyAnswers,
      questionsToRender,
      addSurveyAnswers,
      saveAnswers,
    } = useSurveyQuestions(
      SurveyNameEnum.SMALL_BUSINESS_HEALTHCARE_SURVEY,
      taxYear,
    );

    const handleAnswerChange = (
      question: CmsQuestionData,
      answerValue: AnswerValueType,
      key: string,
    ) => {
      if (
        question.id === HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED &&
        answerValue === 0
      ) {
        question.answerValue = answerValue;
        addSurveyAnswers(question.id, key, [
          { questionId: question.id, answerValue: answerValue },
        ]);
      } else {
        if (
          question.id ===
            HealthcarePlanCreditQuestions.RECEIVED_STATE_PREMIUM_SUBSIDIES &&
          answerValue ===
            HealthcarePlanCreditQuestionsToAnswers[
              HealthcarePlanCreditQuestions.RECEIVED_STATE_PREMIUM_SUBSIDIES
            ].NO
        ) {
          addSurveyAnswers(
            HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED,
            key,
            [
              {
                questionId: HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED,
                answerValue: undefined,
              },
            ],
          );

          surveyAnswers[HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED] =
            null;

          question.answerValue = answerValue;
          addSurveyAnswers(question.id, key, [
            { questionId: question.id, answerValue: answerValue },
          ]);
          saveAnswers && saveAnswers();
        } else {
          question.answerValue = answerValue;
          addSurveyAnswers(question.id, key, [
            { questionId: question.id, answerValue: answerValue },
          ]);
          // save answers per question answered
          if (
            question.answerType === 'yes_or_no' ||
            question.answerType === 'dropdown_select'
          ) {
            saveAnswers && saveAnswers();
          }
        }
      }
    };

    const handleOnDisqualified = async () => {
      surveyFlow.setModalContinueIsLoading(true);

      // update program stage
      await surveyFlow
        .updateProgramStageStatus(
          ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
          taxYear,
          ProgramStageEnum.DISQUALIFIED,
          QualificationStatusEnum.DISQUALIFIED,
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT,
        )
        .then(() => {
          app.history.push('/');
          surveyFlow.setShowQualificationStatusModal(false);
          surveyFlow.setModalContinueIsLoading(false);
          // clear qualification statuses
          surveyFlow.qualificationStatus = null;
        });
    };

    useEffect(() => {
      // checking survey answers to display qualification modal
      surveyFlow.checkQualificationStatus(surveyAnswers);
    }, [surveyFlow, surveyAnswers]);

    useEffectOnce(
      async () =>
        await surveyFlow.createTaxCreditProgram(
          taxYear,
          ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
        ),
    );

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => {
          const subtitleHtml =
            question.subtitle && question.subtitle.includes('<br/>');

          const determineZeroDollar =
            (question.id ===
              HealthcarePlanCreditQuestions.TOTAL_AMOUNT_PAID_FOR_HEALTH_INSURANCE ||
              question.id ===
                HealthcarePlanCreditQuestions.TOTAL_EMPLOYEES_WAGES_PAID_IN_TAX_YEAR ||
              question.id ===
                HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED) &&
            question.answerValue === 0;

          return (
            <Animate
              key={question.id}
              enter={['fade-in', 'from-bottom']}
              duration={0.5}
              className={`${
                question.answerType === 'dropdown_select'
                  ? classes.hasDropdown
                  : ''
              }`}
            >
              <SurveyQuestion
                dataTestId={question.id}
                answerType={question.answerType as AnswerType}
                text={question.text}
                subtitle={
                  subtitleHtml ? (
                    <div
                      className={classes.subtitle}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(question.subtitle),
                      }}
                    />
                  ) : (
                    question.subtitle
                  )
                }
                answerOptions={question.answerIDs}
                onChange={(answer) => handleAnswerChange(question, answer, key)}
                onBlur={() => {
                  if (
                    question.id ===
                      HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED &&
                    question.answerValue === 0
                  ) {
                    return;
                  } else {
                    saveAnswers && saveAnswers();
                  }
                }}
                placeholder={question.placeholder}
                answerValue={question.answerValue}
                tooltip={question.tooltip}
                questionTextSize={app.isMobile ? 15 : 18}
                questionSubtitleSize={app.isMobile ? 13 : 15}
                withCardMargin={false}
                helpLinkText={question.helperText}
                chatBotQuestion={chatbot.isAiQuestionEnabled(
                  question.aiQuestion,
                )}
                chatBotPrompt={() =>
                  chatbot.autoSendAiMessage(question.aiQuestion)
                }
                error={determineZeroDollar}
                errorText={determineZeroDollar ? 'This value cannot be $0' : ''}
              />
            </Animate>
          );
        });
      });

    const preventContinueIfZeroDollar =
      surveyAnswers[
        HealthcarePlanCreditQuestions.TOTAL_AMOUNT_PAID_FOR_HEALTH_INSURANCE
      ] === 0 ||
      surveyAnswers[HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED] === 0 ||
      surveyAnswers[
        HealthcarePlanCreditQuestions.TOTAL_EMPLOYEES_WAGES_PAID_IN_TAX_YEAR
      ] === 0;

    return (
      <Flex
        direction='column'
        className={classes.root}
        data-testid={'small-business-healthcare-credit'}
      >
        <Animate enter={['fade-in', 'from-top']}>
          <TitleHeader
            title='Small Business Healthcare Tax Credit'
            showExitButton
            borderBottom
            onCancelExitText='Cancel'
            showCompanyName
          />
        </Animate>

        <Flex className={classes.mainContent} direction='column'>
          <Animate enter={'fade-in'}>
            <SurveyFlowContainer
              title='Qualify your company for Small Business Healthcare Tax Credit'
              onContinue={async () => handleOnContinue()}
              isDisabled={!isSurveyComplete || preventContinueIfZeroDollar}
              isLoading={surveyFlow.surveyContinueLoading}
            >
              <Flex className={classes.container} direction='column' gap={24}>
                {isLoading ? (
                  <Spinner size='small' />
                ) : (
                  !_.isEmpty(questionsToRender) && renderQuestions()
                )}
                {surveyFlow.qualificationStatus &&
                  surveyFlow.qualificationStatus ===
                    QualificationModalStatusEnum.HEALTHCARE_DQ && (
                    <QualificationStatusModal
                      showModal={surveyFlow.showQualificationStatusModal}
                      modalOnClick={async () => {
                        await handleOnDisqualified();
                      }}
                      programName={ProgramNameEnum.FED_EMPLOYER_HEALTHCARE}
                    />
                  )}
              </Flex>
            </SurveyFlowContainer>
          </Animate>
        </Flex>
      </Flex>
    );
  });
