import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import {
  Animate,
  Button,
  Card,
  Checkbox,
  Color,
  Divider,
  Dropdown,
  DropdownOption,
  Expandable,
  Flex,
  FontAwesome,
  Spinner,
  Text,
  TextField,
} from 'component-library';
import CheckmarkAnimated from 'components/icons/CheckmarkAnimated';
import { useCommonStores } from 'stores/useStores';
import {
  AddressToString,
  SnakeToSentenceCase,
  useEffectOnce,
} from 'lib/helpers';
import { CompanyAddress, StateEnum } from 'lib/interfaces';
import { AddressTypeEnum } from 'entities/AddressEntity';
import { SurveyFlowContainer } from 'products/tax-credits/components';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    opacity: 1,
    transition: 'opacity .4s',
  },
  container: {
    maxWidth: '66%',
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  fadeOutContainer: {
    opacity: 0,
  },
  removeButton: {
    marginLeft: 'auto',
  },
}));

interface ConfirmCompanyAddressProps {
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
  isPrefilled: boolean;
}

const addressInputs = {
  streetLine1: '',
  streetLine2: '',
  city: '',
  state: null,
  zipcode: '',
};

export const ConfirmCompanyAddress = observer(
  ({
    pathTo,
    pathFrom,
    onNext,
    onBack,
    isPrefilled,
  }: ConfirmCompanyAddressProps) => {
    const classes = useStyles();
    const { companyStore } = useCommonStores();
    const [pageLoad, setPageLoad] = useState<boolean>(true);

    const [showContinue, setShowContinue] = useState<boolean>(false);
    const [companyAddress, setCompanyAddress] = useState<string>('');
    const [mailingAddress, setMailingAddress] = useState<string>('');

    const [showUpdateCompanyAddress, setShowUpdateCompanyAddress] =
      useState<boolean>(false);

    const [differentAddress, setDifferentAddress] = useState<boolean>(false);
    const [addressCompany, setAddressCompany] =
      useState<CompanyAddress>(addressInputs);
    const [addressMailing, setAddressMailing] =
      useState<CompanyAddress>(addressInputs);

    const history = useHistory();

    const handleOnContinue = () => {
      onNext();
      history.push(pathTo);
    };

    const handleOnBack = () => {
      onBack();
      history.push(pathFrom);
    };

    const renderEnterAddress = (
      title: string,
      address: CompanyAddress,
      addressType: AddressTypeEnum,
    ) => {
      return (
        <Flex direction='column' gap={24} padding={[12, 0, 0, 0]}>
          <Text variant='medium' text={title} size={18} />
          <Flex direction='column' gap={8}>
            {renderEnterAddressInput(address, addressType)}
          </Flex>
          <Button
            label='Confirm'
            onClick={() => handleEnterAddress(addressType)}
            flexAlignSelf='flex-start'
          />
        </Flex>
      );
    };

    const handleAddressOnChange = (
      str: string,
      name: string,
      addressType: AddressTypeEnum,
    ) => {
      switch (addressType) {
        case AddressTypeEnum.BUSINESS:
          setAddressCompany({
            ...addressCompany,
            addressType,
            [name]: str,
          });
          break;
        case AddressTypeEnum.MAILING:
          setAddressMailing({
            ...addressMailing,
            addressType,
            [name]: str,
          });
          break;
      }
    };

    const renderEnterAddressInput = (
      address: CompanyAddress,
      addressType: AddressTypeEnum,
    ) => {
      return Object.entries(address).map((input, index) => {
        const labelString = [
          'Address line 1',
          'Address line 2 (optional)',
          'City',
          'State',
          'Zip/postcode',
        ];

        switch (input[0]) {
          case 'addressType':
            return;
          case 'state':
            return (
              <Dropdown
                key={`${input[0]}-${index}`}
                name={input[0]}
                label={labelString[index]}
                value={input[1]}
                options={Object.keys(StateEnum).map((stateKey) => {
                  return {
                    name: SnakeToSentenceCase(stateKey),
                    value: StateEnum[stateKey as keyof typeof StateEnum],
                  };
                })}
                onInputChange={(str) => {
                  const selected = str as DropdownOption;
                  handleAddressOnChange(selected.value, input[0], addressType);
                }}
              />
            );
          default:
            return (
              <TextField
                key={`${input[0]}-${index}`}
                name={input[0]}
                value={input[1]}
                label={labelString[index]}
                onChange={(e) =>
                  handleAddressOnChange(
                    e.target.value as string,
                    input[0],
                    addressType,
                  )
                }
              />
            );
        }
      });
    };

    const renderEnterDifferentAddress = () => {
      return (
        <Expandable expand={showContinue}>
          <Flex gap={24} padding={[24, 0, 0, 0]} direction='column'>
            <Divider variant='no-bottom-margin' />
            <Checkbox
              dataTestId='confirmation-checkbox'
              onChange={() => setDifferentAddress(!differentAddress)}
              options={[
                {
                  checked: differentAddress,
                  text: `My company address is different from my mailing address`,
                  value: 'confirmed',
                },
              ]}
            />
          </Flex>
        </Expandable>
      );
    };

    const handleEnterAddress = async (addressType: AddressTypeEnum) => {
      if (addressType === AddressTypeEnum.BUSINESS) {
        setCompanyAddress(AddressToString(addressCompany));
        setShowContinue(true);
        setShowUpdateCompanyAddress(false);

        await companyStore.SaveCompanyAddress(addressCompany);
      }
      if (addressType === AddressTypeEnum.MAILING) {
        setMailingAddress(AddressToString(addressMailing));

        await companyStore.SaveCompanyAddress(addressMailing);
      }
    };

    const handleRemoveMailingAddress = async () => {
      if (addressMailing && mailingAddress && differentAddress) {
        setMailingAddress('');
        setAddressMailing(addressInputs);

        await companyStore.DeleteCompanyAddress(AddressTypeEnum.MAILING);
      }
    };

    const canContinue = companyAddress.length > 0 && showContinue;

    useEffectOnce(async () => {
      await companyStore.getCompanyAddresses();

      if (companyStore.companyAddress) {
        setCompanyAddress(AddressToString(companyStore.companyAddress));
      }

      if (companyStore.mailingAddress) {
        setMailingAddress(AddressToString(companyStore.mailingAddress));
        setDifferentAddress(true);
      }

      // finish fetching if company addresses exist
      setPageLoad(false);
    });

    return (
      <SurveyFlowContainer
        title='Confirm company address'
        subtitle='Your business address determines which IRS processing facility receives your credit documents.'
        onContinue={handleOnContinue}
        onBack={handleOnBack}
        isDisabled={!canContinue}
        dataTestId='erc-qualify-survey'
      >
        <Flex className={classes.container} direction='column' gap={24}>
          {pageLoad ? (
            <Spinner />
          ) : (
            <Card noMargin noBoxShadow>
              <Flex direction='column' padding={24}>
                {companyStore.companyAddress ? (
                  <>
                    <Flex direction='column' gap={24}>
                      {!showUpdateCompanyAddress && (
                        <>
                          {showContinue ? (
                            <Text
                              variant='medium'
                              text='Confirmed company address'
                            />
                          ) : (
                            <Text
                              variant='medium'
                              text='Is this your company address?'
                              size={18}
                            />
                          )}
                        </>
                      )}
                      <Flex gap={12} alignItems='center'>
                        {!showUpdateCompanyAddress && (
                          <>
                            {showContinue ? (
                              <Animate enter='fade-in'>
                                <CheckmarkAnimated />
                              </Animate>
                            ) : (
                              <FontAwesome
                                name='map-location-dot'
                                color={Color.neutral._60}
                              />
                            )}
                          </>
                        )}

                        {showUpdateCompanyAddress ? (
                          renderEnterAddress(
                            'What is your company address?',
                            addressCompany,
                            AddressTypeEnum.BUSINESS,
                          )
                        ) : (
                          <Text size={18}>{companyAddress}</Text>
                        )}
                      </Flex>
                    </Flex>
                    {!showUpdateCompanyAddress && (
                      <>
                        {!showContinue ? (
                          <Flex gap={24} padding={[24, 0, 0, 0]}>
                            <Button
                              label='Yes'
                              onClick={() => {
                                setShowContinue(true);
                                setShowUpdateCompanyAddress(false);
                              }}
                              small
                            />
                            <Button
                              label='No'
                              variant='outlined'
                              onClick={() => {
                                setAddressCompany({
                                  streetLine1:
                                    companyStore.companyAddress?.streetLine1 ||
                                    '',
                                  streetLine2:
                                    companyStore.companyAddress?.streetLine2 ||
                                    '',
                                  city: companyStore.companyAddress?.city || '',
                                  state:
                                    companyStore.companyAddress?.state || null,
                                  zipcode:
                                    companyStore.companyAddress?.zipcode || '',
                                });
                                setShowUpdateCompanyAddress(true);
                              }}
                              small
                            />
                          </Flex>
                        ) : (
                          renderEnterDifferentAddress()
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {companyAddress.length > 0 ? (
                      <Flex direction='column' gap={8}>
                        <Text
                          variant='medium'
                          text='Confirmed company address'
                        />
                        <Flex alignItems='center' gap={12}>
                          <Animate enter='fade-in'>
                            <CheckmarkAnimated />
                          </Animate>
                          <Text size={18}>{companyAddress}</Text>
                        </Flex>
                      </Flex>
                    ) : (
                      renderEnterAddress(
                        'What is your company address?',
                        addressCompany,
                        AddressTypeEnum.BUSINESS,
                      )
                    )}
                    {renderEnterDifferentAddress()}
                  </>
                )}

                <Expandable expand={differentAddress && showContinue}>
                  {mailingAddress.length > 0 ? (
                    <Flex alignItems='center' gap={12} padding={[16, 0, 0, 0]}>
                      <Animate enter='fade-in'>
                        <CheckmarkAnimated />
                      </Animate>
                      <Text size={18}>{mailingAddress}</Text>
                      <Button
                        className={classes.removeButton}
                        label={<Text status='failed' text='Remove' />}
                        small
                        variant='tertiary'
                        onClick={() => handleRemoveMailingAddress()}
                      />
                    </Flex>
                  ) : (
                    renderEnterAddress(
                      'Enter company mailing address',
                      addressMailing,
                      AddressTypeEnum.MAILING,
                    )
                  )}
                </Expandable>
              </Flex>
            </Card>
          )}
        </Flex>
      </SurveyFlowContainer>
    );
  },
);
