import React, { useContext, useEffect, useState } from 'react';
import {
  Animate,
  Button,
  Card,
  Color,
  Flex,
  FontAwesome,
  Heading,
  Link,
  Spinner,
  Text,
} from 'component-library';
import { FormHelperText, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import {
  useCommonStores,
  useCompany,
  useOnboardingStores,
} from 'stores/useStores';
import { ALL_PAYROLL_SYSTEMS, CreditCategoryEnum } from 'lib/constants';
import confetti from 'canvas-confetti';
import * as yup from 'yup';
import { Formik } from 'formik';
import { usePasswordValidation } from 'lib/usePasswordValidation';
import { PasswordChecklist } from 'pages/onboarding/components/PasswordChecklist';
import { useIsMounted } from 'component-library/_helpers/use-utils';
import { CreateCompanySelfSignupRequest, SourcingData } from 'lib/interfaces';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import { CentsToDisplayString } from 'lib/helpers';
import { TalkToAnExpertModal } from 'components/TalkToAnExpertModal';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  grid: {
    display: 'grid',
    height: 'calc(100% - 64px)',
    width: '100%',
    maxWidth: '1440px',
    margin: '0 auto',
    gridTemplateColumns: `50% 50%`,
    boxSizing: 'border-box',
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      gap: '24px',
      padding: '24px 0',
      justifyContent: 'flex-end',
      gridTemplateColumns: 'minmax(0, 1fr)',
    },
  },
  rightCol: {
    backgroundColor: 'white',
  },
  customEstimate: {
    position: 'relative',
    overflow: 'hidden',
  },
  confetti: {
    position: 'absolute',
    top: -20,
    left: 8,
    pointerEvents: 'none',
    [breakpoints.down('sm')]: {
      left: -64,
    },

    '& > canvas': {
      width: 500,
    },
  },
  codeEntered: {
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      padding: '24px 0',
      justifyContent: 'flex-start',
      gridTemplateColumns: 'minmax(0, 1fr)',
    },
  },
  textFieldContainer: {
    maxWidth: '400px',
    width: '100%',
    [breakpoints.down('sm')]: {
      maxWidth: '320px',
    },
  },
  textField: {
    width: '100%',
    marginTop: '25px',
    background: Color.neutral.white,
    [breakpoints.down('sm')]: {
      height: '20px',
      marginBottom: '20px',
    },
  },

  inputRoot: {
    '& input': {
      width: 'calc(100% - 28px)',
    },
  },
  errorMsg: {
    margin: '5px 0 0',
    fontSize: '12px',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: '20px',
      marginBottom: '-25px',
    },
  },
  linkText: {
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:visited': {
      color: palette.secondary.main,
    },
    '&:link': {
      color: palette.secondary.main,
    },
  },
  loadingContainer: {
    marginBottom: '60px',
  },
  agreementContainer: {
    fontSize: '13px',
    marginTop: '30px',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  formErrorMsg: {
    marginTop: '15px',
    textAlign: 'center',
  },
}));

const getUtmSourcingData = (urlParams: URLSearchParams) => {
  const sourcingData: SourcingData = {
    utmCampaign: '',
    utmMedium: '',
    utmSource: '',
    utmContent: '',
    utmTerm: '',
  };

  const urlKeyMapping: SourcingData = {
    utmCampaign: 'utm_campaign',
    utmMedium: 'utm_medium',
    utmSource: 'utm_source',
    utmContent: 'utm_content',
    utmTerm: 'utm_term',
  };

  Object.keys(sourcingData).forEach((key) => {
    if (key in urlKeyMapping) {
      const urlParamKey = urlKeyMapping[key as keyof SourcingData];
      if (urlParamKey) {
        const urlParamValue = urlParams.get(urlParamKey);
        if (urlParamValue) {
          sourcingData[key as keyof SourcingData] = urlParamValue;
        }
      }
    }
  });
  return sourcingData;
};

interface PartnerCustomEstimateSignupProps {
  code: string;
  partner: string;
  partnerCompany: string;
}

export const PartnerCustomEstimateSignup: React.FC<PartnerCustomEstimateSignupProps> =
  observer(({ code, partner, partnerCompany }) => {
    const isMounted = useIsMounted();
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [showPasswordHelpers, setShowPasswordHelpers] = useState(false);
    const [formError, setFormError] = useState('');
    const [showCalendly, setShowCalendly] = useState<boolean>(false);

    const { app, auth } = useCommonStores();
    const { company } = useCompany();
    const { initialAccountSetup } = useOnboardingStores();
    const { client } = useContext(Auth0FeatureContext);

    const location = useLocation();
    const { search } = location;
    const urlParams = new URLSearchParams(search);

    const sourcingData = getUtmSourcingData(urlParams);
    const pathComponents = location.pathname.toLowerCase().split('/');
    const { origin } = window.location;
    const redirectUri = origin;

    interface FormInputs {
      email: string;
      password: string;
      agreeToTerms: boolean;
    }

    const initialValues: FormInputs = {
      email: '',
      password: '',
      agreeToTerms: true,
    };

    const { validatePassword, isValidPassword, passwordValidation } =
      usePasswordValidation();

    const getPartnerReferral = () => {
      if (
        pathComponents.length > 2 &&
        pathComponents[1] === 'welcome' &&
        ALL_PAYROLL_SYSTEMS.find((p) => p.id === pathComponents[2])
      )
        return pathComponents[2];

      return '';
    };

    const submit = async (formValues: FormInputs) => {
      if (isMounted()) {
        setFormError('');
        initialAccountSetup.setFormLoading(true);
      }

      const res = await client.CreateCompanyAndLoginSelfSignup({
        primaryUserEmail: formValues.email,
        primaryUserFirstName: undefined,
        primaryUserLastName: undefined,
        adminPassword: formValues.password,
        partnerReferral: getPartnerReferral(),
        referrer: '',
        creditCategories: [] as CreditCategoryEnum[],
        sourcing: sourcingData,
        partnerAccessCode: code,
      } as CreateCompanySelfSignupRequest);

      if (res.errorMsg) {
        datadogLogs.logger.error(
          `[WELCOME]: company creation on registration failed. partner: ${partner}`,
          logContext({
            error: res.errorMsg,
            company: app.company,
          }),
        );

        setFormError(res.errorMsg);
        initialAccountSetup.setFormLoading(false);
        return;
      }

      await auth.manualLogin(
        formValues.email,
        formValues.password,
        redirectUri,
      );
      if (res.data?.company) {
        company.mergeData(res.data.company);
      }
    };

    const submitOnEnter =
      (runSubmit: () => void) => (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          runSubmit();
        }
      };

    useEffect(() => {
      const confettiCanvas = document.createElement('canvas');
      const confettiPosition = document.getElementById('confetti');

      if (confettiPosition) {
        const hasCanvasChild = Array.from(confettiPosition.children).some(
          function (child) {
            return child.nodeName === 'CANVAS';
          },
        );

        // only create if canvas doesn't exist
        if (!hasCanvasChild) {
          confettiPosition.appendChild(confettiCanvas);
        }
      }

      const myConfetti = confetti.create(confettiCanvas, {
        useWorker: true,
      });
      myConfetti({
        particleCount: 70,
        spread: 100,
        startVelocity: 10,
        scalar: 0.4,
        gravity: 0.5,
        origin: { y: 0.5, x: 0.5 },
      });
    }, []);

    return (
      <div className={`${classes.grid} ${classes.codeEntered}`}>
        <Flex
          padding={[0, 24]}
          direction='column'
          justifyContent={app.isSmallDesktop ? 'flex-start' : 'center'}
          alignItems='center'
        >
          <Animate enter='fade-in' delay={0.4}>
            <Card noMargin maxWidth={520}>
              <Flex
                padding={app.isTablet ? 24 : 40}
                direction='column'
                alignItems='center'
                gap={16}
                className={classes.customEstimate}
              >
                <div id='confetti' className={classes.confetti} />
                <Text size={app.isSmallDesktop ? 18 : 23} variant='medium'>
                  Your custom estimate:
                </Text>
                {initialAccountSetup.loadingEstimate ? (
                  <Flex
                    padding={[16, 0]}
                    gap={16}
                    alignItems='center'
                    direction='column'
                  >
                    <Spinner color='emerald' />
                  </Flex>
                ) : (
                  <Heading
                    color={Color.green._60}
                    variant='medium'
                    tag={app.isSmallDesktop ? 'h3' : 'h2'}
                    marginBottom={app.isTablet ? 8 : 12}
                  >
                    {CentsToDisplayString(
                      initialAccountSetup.customEstimate,
                      0,
                      0,
                    )}
                  </Heading>
                )}
                <Text size={app.isSmallDesktop ? 15 : 18}>
                  With your {partnerCompany} data, MainStreet can initially
                  project that your company is eligible for significant tax
                  credits! Continue with MainStreet to find your
                  final&nbsp;estimate!
                </Text>
                <Text size={app.isSmallDesktop ? 15 : 18}>
                  There is no risk; you only pay when you get your&nbsp;forms.
                </Text>
              </Flex>
            </Card>
          </Animate>
        </Flex>
        <Flex
          className={classes.rightCol}
          direction='column'
          justifyContent='center'
          padding={app.isTablet ? [24, 24, 40, 24] : [0, 40, 0, 80]}
          gap={12}
        >
          {initialAccountSetup.formLoading ? (
            <Animate enter='fade-in'>
              <Flex
                padding={[16, 0]}
                gap={16}
                alignItems='center'
                direction='column'
              >
                <Heading
                  tag={app.isSmallDesktop ? 'h5' : 'h4'}
                  variant='medium'
                  marginBottom={0}
                >
                  Get ready to claim your credits!
                </Heading>
                <Spinner color='emerald' />
              </Flex>
            </Animate>
          ) : (
            <Animate enter={['fade-in', 'from-top']} delay={0.25}>
              <Flex direction='column' justifyContent='center' gap={24}>
                <Heading
                  tag={app.isSmallDesktop ? 'h5' : 'h4'}
                  variant='medium'
                  marginBottom={0}
                >
                  You&apos;ve got credits to claim!
                </Heading>
                <Text size={15}>
                  You and other {partnerCompany} customers are eligible to an
                  exclusive opportunity to use the world&apos;s top tax credit
                  platform, MainStreet, to find and claim your tax&nbsp;credits.
                </Text>
                <Text size={15}>
                  MainStreet breaks down 77,000 pages of the IRS tax code into
                  one seamless flow to make it easier than ever to claim
                  tax&nbsp;credits.
                </Text>
                <Text size={15}>
                  Enter your email and create a password to
                  get&nbsp;started&nbsp;today!
                </Text>
              </Flex>
              <Flex gap={32} direction='column'>
                <Formik
                  initialValues={initialValues}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={(values) => {
                    submit(values);
                  }}
                  validationSchema={yup.object().shape({
                    email: yup
                      .string()
                      .email('Please enter a valid email')
                      .required('Please include your work email'),
                    password: yup.string().required('Please enter a password'),
                    agreeToTerms: yup
                      .bool()
                      .oneOf([true], 'You must accept the terms of service'),
                  })}
                  // eslint-disable-next-line react/no-children-prop
                  children={({
                    values,
                    errors,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Flex direction='column' gap={24} alignItems='flex-start'>
                      <div className={classes.textFieldContainer}>
                        <TextField
                          variant='outlined'
                          className={classes.textField}
                          InputProps={{
                            classes: { root: classes.inputRoot },
                          }}
                          data-testid='welcome_email'
                          label='Enter company email'
                          value={values.email}
                          onChange={(event) => {
                            setFieldValue('email', event.target.value);
                          }}
                          onKeyDown={submitOnEnter(handleSubmit)}
                        />
                        {errors.email && (
                          <FormHelperText className={classes.errorMsg} error>
                            {errors.email}
                          </FormHelperText>
                        )}
                        <TextField
                          variant='outlined'
                          className={classes.textField}
                          type={showPassword ? 'text' : 'password'}
                          data-testid='welcome_password'
                          label='Create a password'
                          value={values.password}
                          error={!isValidPassword && showPasswordError}
                          onFocus={() => {
                            setShowPasswordHelpers(true);
                          }}
                          onBlur={(event) => {
                            validatePassword(event.target.value, true);
                            setShowPasswordError(true);
                            setShowPasswordHelpers(values.password.length > 0);
                          }}
                          onChange={(event) => {
                            setShowPasswordHelpers(true);
                            setShowPasswordError(false);
                            setFieldValue('password', event.target.value);
                            validatePassword(event.target.value);
                          }}
                          onKeyDown={submitOnEnter(handleSubmit)}
                          InputProps={{
                            classes: { root: classes.inputRoot },
                            endAdornment: (
                              <InputAdornment position='end'>
                                <Link
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                  inheritColor={true}
                                >
                                  <FontAwesome
                                    name={showPassword ? 'eye-slash' : 'eye'}
                                    variant='solid'
                                    size={20}
                                    color={
                                      !isValidPassword && showPasswordError
                                        ? Color.semantic.$error50
                                        : Color.neutral._60
                                    }
                                  />
                                </Link>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {showPasswordHelpers && (
                          <PasswordChecklist
                            passwordValidation={passwordValidation}
                          />
                        )}
                        {errors.password && (
                          <FormHelperText className={classes.errorMsg} error>
                            {errors.password}
                          </FormHelperText>
                        )}
                      </div>

                      <Flex
                        padding={app.isTablet ? [16, 24, 8, 0] : [0, 40, 0, 0]}
                      >
                        <Text size={13}>
                          By creating an account, you&apos;re letting us know
                          that you agree to the terms outlined in{' '}
                          <a
                            className={classes.linkText}
                            href='/terms-of-service'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            Terms&nbsp;of&nbsp;Service
                          </a>{' '}
                          and have acknowledged our{' '}
                          <a
                            className={classes.linkText}
                            href='/privacy'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            Privacy&nbsp;Policy
                          </a>
                          .
                        </Text>
                      </Flex>
                      {formError && (
                        <FormHelperText className={classes.formErrorMsg} error>
                          {formError}
                        </FormHelperText>
                      )}
                      <Flex gap={32} alignItems='center'>
                        <Button
                          label='Get started'
                          onClick={() => {
                            handleSubmit();
                          }}
                          flexAlignSelf='flex-start'
                          disabled={!isValidPassword || !values.email}
                        />
                        <Button
                          variant='tertiary'
                          onClick={() =>
                            initialAccountSetup.setShowTalkToExpertModal(true)
                          }
                          label={
                            <Text color={Color.blue._60}>
                              Talk to an expert
                            </Text>
                          }
                          small
                        />
                      </Flex>
                    </Flex>
                  )}
                />
              </Flex>
            </Animate>
          )}
        </Flex>
        <TalkToAnExpertModal
          setShowCalendly={setShowCalendly}
          showCalendly={showCalendly}
        />
      </div>
    );
  });
