import React from 'react';
import { observer } from 'mobx-react';
import { Button, Color, Flex, Modal, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import {
  useCommonStores,
  useTaxCreditsStores,
} from '../../../../../stores/useStores';
import { MS_DOMAIN } from '../../../../../lib/constants';

const useStyles = makeStyles(() => {
  const MIN_HEIGHT = '300px';

  return {
    inviteeModal: {
      minHeight: MIN_HEIGHT,
    },
    image: {
      maxWidth: 100,
    },
    imageContainer: {
      backgroundColor: `${Color.blue._20}`,
      minHeight: MIN_HEIGHT,
      borderRadius: '8px 0 0 8px',
    },
  };
});

export const InviteeSuccessModal = observer(() => {
  const classes = useStyles();
  const { unifiedTaxCredits } = useTaxCreditsStores();
  const { companyStore } = useCommonStores();
  const { first, last } = companyStore.company.adminName;

  const handleClose = () => {
    window.location.assign(`https://${MS_DOMAIN}/search`);
    companyStore.removeAccessToken();
    unifiedTaxCredits.setShowInviteeSuccessModal(false);
  };

  return (
    <Modal showModal={unifiedTaxCredits.showInviteeSuccessModel} maxWidth={500}>
      <>
        <>
          <Flex
            gap={8}
            direction='column'
            justifyContent='center'
            alignItems='center'
            padding={32}
            className={classes.inviteeModal}
          >
            <img
              className={classes.image}
              src={`${process.env.PUBLIC_URL}/images/unifiedYEA/check-list.svg`}
            />
            <Text textAlign='center' size={18} variant='bold'>
              You’ve helped {first} {last} earn tax credits!
            </Text>
            <Text textAlign='center' size={15}>
              Thanks for all your hard work. {first} will review the information
              for {companyStore.company.name}. You’re all set!
            </Text>
            <Flex justifyContent='center' padding={[16, 0, 0, 0]}>
              <Button label='Close' onClick={handleClose} />
            </Flex>
          </Flex>
        </>
      </>
    </Modal>
  );
});
