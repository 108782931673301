import React, { createContext } from 'react';
import { EmptyClient } from 'services/ServerClient';
import { usePlaidLink } from 'react-plaid-link';

interface PlaidContextInterface {
  // TODO: - figure out if this is avoidable, given the plaid interface definition.
  // eslint-disable-next-line @typescript-eslint/ban-types
  open: Function;
  error: ErrorEvent | null;
}

export const PlaidContext = createContext({} as PlaidContextInterface);

interface PlaidProviderConfig {
  onPlaidLinkSuccess: () => void;
  onPlaidLinkFail: (errorMsg: string) => void;
  plaidToken: string;
  children: React.ReactNode | JSX.Element;
}

export const PlaidProvider: React.FC<PlaidProviderConfig> = (
  config: PlaidProviderConfig,
) => {
  const onSuccess = React.useCallback(
    (public_token, metadata) => {
      // perform the public_token exchange for the permanent token
      EmptyClient.PlaidRegistration(public_token, metadata).then((res) => {
        const { errorMsg } = res;
        if (errorMsg) {
          config.onPlaidLinkFail(errorMsg);
          return;
        }
        config.onPlaidLinkSuccess();
      });
    },
    [config],
  );

  const plaidLinkConfig: Parameters<typeof usePlaidLink>[0] = {
    token: config.plaidToken,
    onSuccess,
    env: process.env.REACT_APP_PLAID_ENV,
  };

  return (
    <PlaidContext.Provider value={usePlaidLink(plaidLinkConfig)}>
      {config.children}
    </PlaidContext.Provider>
  );
};
