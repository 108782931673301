import { debounce, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import {
  JobGroupEnumToString,
  ProgramStageEnum,
  ProgramSubStageEnum,
} from 'lib/constants';
import { ContinueButton } from 'components/ContinueButton';
import { scrollToRef, visiblePixels } from 'components/util/ScrollUtils';
import {
  CompanyData,
  ProgramData,
  UpdateWorkDoneRequest,
} from 'lib/interfaces';
import {
  CreateJobGroups,
  EmployeeExpenseCard,
  JobGroupHeader,
  JobGroupSideBar,
  JobGroupSideBarType,
  JobGroupType,
  MissingEmployeesWarning,
  MissingEmployeeUpload,
  NonRdEmployee,
  NonRdEmployeeExpenseCard,
  SaveForLaterButton,
} from 'pages/dashboard/fed-rd-program';
import React, {
  createRef,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { UpdateExpenseClassification, UpdateProgram } from 'services/server';

const useStyles = makeStyles(() => ({
  sidebar: {
    position: 'sticky',
    top: '200px',
    padding: '20px',
  },
  sidebarContainer: {
    maxWidth: '400px',
  },
  main: {
    marginTop: '40px',
  },
  buttonContainer: {
    flexDirection: 'column',
  },
}));

const submitButtonUseStyles = makeStyles(() => ({
  buttons: {
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  continueButton: {
    flexShrink: 0,
  },
}));

interface Props {
  company: CompanyData;
  program: ProgramData;
  setProgram: Dispatch<SetStateAction<ProgramData | null>>;
}

interface SubmitButtonProps {
  program: ProgramData;
  setProgram: Dispatch<SetStateAction<ProgramData | null>>;
  needsReview: boolean;
  openModal: () => void;
}

const SubmitContent = ({
  program,
  setProgram,
  needsReview,
  openModal,
}: SubmitButtonProps) => {
  const classes = submitButtonUseStyles();

  const moveToMsReview = () => {
    UpdateProgram(program.id, {
      stage: ProgramStageEnum.MS_REVIEW,
      subStage: ProgramSubStageEnum.REVIEW_IN_PROGRESS,
    }).then((res) => setProgram(res.data!.updatedProgram));
  };

  // scroll to the submit ref when it first shows up
  const submitRef = createRef<HTMLDivElement>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => scrollToRef(submitRef), []);

  const buttonLabel = needsReview
    ? 'Submit for review'
    : 'Submit for accountant review';

  return (
    <>
      <div ref={submitRef} />
      <MissingEmployeesWarning openModal={openModal} />
      <div className={classes.buttons}>
        <div className={classes.continueButton}>
          <ContinueButton onClick={moveToMsReview} label={buttonLabel} />
        </div>
        {!needsReview && (
          <Typography>Make sure all information is accurate.</Typography>
        )}
      </div>
    </>
  );
};

export const RdIndividualContributions = ({
  company,
  program,
  setProgram,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  const moveToAddNewEmployeesSubStage = async () => {
    await UpdateProgram(program.id, {
      stage: ProgramStageEnum.EXPENSE_CLASSIFICATION,
      subStage: ProgramSubStageEnum.ADDITIONAL_EMPLOYEES_TO_BE_ADDED,
    }).then((res) => setProgram(res.data!.updatedProgram));
  };

  const saveEmployee = (update: UpdateWorkDoneRequest) => {
    return UpdateExpenseClassification([update]).then((response) => {
      setProgram((program) => {
        if (response.errorMsg) {
          console.log(response.errorMsg); // todo
          return program;
        }
        program!.workDone = program!.workDone.map((wd) => {
          return wd.id === response.data!.updatedWorkDone[0].id
            ? response.data!.updatedWorkDone[0]
            : wd;
        });
        // Force creating a new object in order to trigger a re-render
        return JSON.parse(JSON.stringify(program));
      });
    });
  };

  const jobGroups = CreateJobGroups(
    program.workDone,
    company.employmentRecords,
    program.taxYear,
  );
  const needsReview = !!jobGroups['Needs Review'];

  /**
   * Job groups is a record, but we need it as an array
   */
  const jobGroupArray = Object.values(jobGroups)
    .filter((jobGroup) => !needsReview || jobGroup.needsReview())
    .sort((a, b) => {
      // alphabetize but leave "other" at the bottom
      if (a.heading().toUpperCase() === 'OTHER') {
        return 1;
      } else if (b.heading().toUpperCase() === 'OTHER') {
        return -1;
      } else {
        return a.heading().toUpperCase() > b.heading().toUpperCase() ? 1 : -1;
      }
    });

  /**
   * We also need employees as an array directly
   */
  const employeeArray = jobGroupArray.flatMap((jobGroup) => {
    return [...jobGroup.employees, ...jobGroup.nonRdEmployees];
  });

  /**
   * Go to the next card. If it is a nonRdEmployee, skip.
   */
  const nextIndex = (
    index: number | null,
    startFromCurrent: boolean,
  ): number | null => {
    if (index !== null && index < employeeArray.length - 1) {
      const next = startFromCurrent ? index : index + 1;
      return employeeArray[next] instanceof NonRdEmployee
        ? nextIndex(next, false)
        : next;
    } else {
      return null;
    }
  };

  /**
   * This is the index of the farthest card the user has completed so far. Null means that the user has completed
   * all cards.
   */
  const [maxIndex, setMaxIndex] = useState<number | null>(nextIndex(0, true));

  const currentEmployee = maxIndex !== null ? employeeArray[maxIndex] : null;

  /** This defines the parameters for the floating sidebar that allows the user to navigate to groups they have
   *  already completed
   */
  const sideBarJobGroups: JobGroupSideBarType = {};
  employeeArray.forEach((employee, index) => {
    const jobGroup = employee.jobGroup();
    const sideBarGroup = sideBarJobGroups[jobGroup];
    if (sideBarGroup === undefined) {
      sideBarJobGroups[jobGroup] = {
        clickable: maxIndex === null || index <= maxIndex,
        nbOfEmployees: 1,
      };
    } else {
      sideBarGroup.nbOfEmployees++;
    }
  });

  /**
   * This is job group in which the user is currently reviewing employees. It is highlighted in the sidebar.
   */
  const [selectedJobGroup, setSelectedJobGroup] = useState<JobGroupType | null>(
    currentEmployee ? currentEmployee.jobGroup() : null,
  );

  /**
   * The initials of the admin are displayed in the comments.
   */
  const initials =
    company.adminName.first.charAt(0) + company.adminName.last.charAt(0);

  /**
   * Refs for scrolling to each job group
   */
  const jobGroupRefs = jobGroupArray.map(() => createRef<HTMLDivElement>());

  /**
   * Helper function for checking if cards have been visited so we can hide them when they haven't been
   */
  const isCardUnvisited = (cardIndex: number | null): boolean => {
    return maxIndex !== null && (cardIndex === null || cardIndex > maxIndex);
  };

  /**
   *  Card should only be set to editable if it is equal to the max index, i.e., the card the user is currently
   *  working on.
   */
  const cardIsEditable = (cardIndex: number): boolean => {
    return maxIndex !== null && cardIndex === maxIndex;
  };

  /**
   * This function is throttled because it runs every time the user scrolls. It detects which job group is currently
   * visible based on which one has the most visible pixels. This job group will be then set as the selected job
   * group so that the it will be shown as the current job group in the floating sidebar.
   */
  const onScroll = debounce(() => {
    const sortedGroups = jobGroupRefs
      .map((ref, index) => {
        return {
          pixelsOnScreen: ref.current ? visiblePixels(ref.current) : 0,
          group: jobGroupArray[index].name,
        };
      })
      .filter(({ pixelsOnScreen }) => pixelsOnScreen > 0)
      .sort((a, b) => (a.pixelsOnScreen < b.pixelsOnScreen ? 1 : -1));
    const selected = sortedGroups[0];
    setSelectedJobGroup(selected ? selected.group : jobGroupArray[0].name);
  }, 50);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  });

  const showSubmitButton =
    jobGroupArray.length === 0 ? false : maxIndex === null;

  const completeCard = (index: number) => {
    const next = nextIndex(index, false);
    if (isCardUnvisited(next)) {
      // timeout is to wait until animation finishes
      setTimeout(() => setMaxIndex(next), needsReview ? 0 : 500);
    }
  };

  const visibleEmployees = employeeArray.filter(
    (employee, index) => maxIndex === null || index <= maxIndex,
  );

  const visibleJobGroups = Array.from(
    new Set(visibleEmployees.map((employee) => employee.jobGroup())),
  );

  return (
    <>
      <Grid container className={classes.main}>
        <Grid item xs={11} lg={8}>
          {visibleJobGroups.map((jobGroup: JobGroupType, index) => {
            return (
              <div ref={jobGroupRefs[index]} key={index}>
                <JobGroupHeader
                  jobGroup={
                    jobGroup === 'Needs Review'
                      ? jobGroup
                      : JobGroupEnumToString[jobGroup]
                  }
                  scrollOnInitialRender={index > 0}
                />
                {visibleEmployees.map((employee, employeeIndex) => {
                  if (employee.jobGroup() === jobGroup) {
                    return employee instanceof NonRdEmployee ? (
                      <NonRdEmployeeExpenseCard nonRdEmployee={employee} />
                    ) : (
                      <EmployeeExpenseCard
                        key={employee.name() + employeeIndex}
                        employee={employee}
                        editMode={!needsReview && cardIsEditable(employeeIndex)}
                        next={() => completeCard(employeeIndex)}
                        save={saveEmployee}
                        adminInitial={initials}
                        scrollOnInitialRender={employeeIndex > 0}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            );
          })}
        </Grid>
        <Hidden mdDown>
          {selectedJobGroup && (
            <Grid item xs={4} className={classes.sidebarContainer}>
              <Box className={classes.sidebar}>
                <JobGroupSideBar
                  jobGroups={sideBarJobGroups}
                  selectedJobGroup={selectedJobGroup}
                  onJobGroupClick={(index) => scrollToRef(jobGroupRefs[index])}
                  needsReview={needsReview}
                />
              </Box>
            </Grid>
          )}
        </Hidden>
        <Grid item xs={11} lg={8}>
          <Box
            display={'flex'}
            marginTop={'30px'}
            className={classes.buttonContainer}
          >
            {showSubmitButton ? (
              <SubmitContent
                program={program}
                setProgram={setProgram}
                needsReview={needsReview}
                openModal={() => setShowUploadModal(true)}
              />
            ) : (
              <SaveForLaterButton
                loading={false}
                onClick={() => history.push('/')}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <MissingEmployeeUpload
        showModal={showUploadModal}
        closeModal={() => setShowUploadModal(false)}
        companyId={company.id}
        onUploadComplete={moveToAddNewEmployeesSubStage}
      />
    </>
  );
};
