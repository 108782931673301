import React from 'react';
import { observer } from 'mobx-react';
import { Flex, Heading, Text, TextProps } from 'component-library';
import { ContentFrame } from '../../components';

interface CompleteRDAssessmentProps {
  headingSize: number;
  textFontSize: TextProps['size'];
}

export const CompleteRDAssessment: React.FC<CompleteRDAssessmentProps> = observer(
  ({ headingSize, textFontSize }) => {
    return (
      <ContentFrame>
        <Flex direction='column' gap={16}>
          <Heading
            text='Complete your R&D Assessment'
            variant='medium'
            size={headingSize}
            marginBottom={0}
          />
          <Text
            size={textFontSize}
            text="At the end of the tax year, we'll request specifics about your R&D activities and expenses from the previous 12 months. "
          />
          <Text
            size={textFontSize}
            text="With the information you provide, we'll refine our estimate, accurately calculate your final credit amount, and prepare all the documents you'll need to file with the IRS."
          />
        </Flex>
        <Flex direction='column' gap={16}>
          <img
            src={`${process.env.PUBLIC_URL}/images/dashboard/educational-module/images/Assessment.svg`}
            title='Assessment'
          />
        </Flex>
      </ContentFrame>
    );
  },
);
