import React from 'react';
import { ClaimBanner } from '../../unified-tax-credits/components';
import { Card, Flex, Link, Text } from 'component-library';
import { Page } from '../../../../../lib/constants';
import { Company } from '../../../../../entities/Company';
import { CentsToDisplayString } from 'component-library/_helpers/helpers';

interface ErcMsReviewPageProps {
  classes: any;
  company: Company;
  ercEstimate: number | null;
}

export const ErcMsReviewPage = ({
  classes,
  company,
  ercEstimate,
}: ErcMsReviewPageProps) => {
  return (
    <>
      <ClaimBanner
        title={`Pending: ${CentsToDisplayString(ercEstimate || 0)} in credits!`}
        subtitle={
          <>
            {company.legalName}, we believe you are eligible for{' '}
            {CentsToDisplayString(ercEstimate || 0)} in Employee Retention
            Credits. A MainStreet credit specialist is reviewing your
            information now, and will finalize your credits on your call.
          </>
        }
      />
      <Card noMargin>
        <Flex direction='column' gap={16} padding={24}>
          <Flex justifyContent='space-between' alignItems='center' wrap='wrap'>
            <Flex
              gap={4}
              alignItems='center'
              justifyContent='flex-start'
              className={classes.title}
              basis={315}
            >
              <Text variant='medium' size={23}>
                Prepare to claim credits
              </Text>
            </Flex>
          </Flex>
          <Text paddingBottom={16}>
            While we work diligently to ensure your credits are compliant with
            all relevant regulations, here&apos;s what you can do to prepare for
            your final steps!
          </Text>

          <div className={classes.assessmentContainer}>
            <Flex direction='column' gap={8} padding={24}>
              <Text variant='medium' size={18}>
                Invite your accountant to MainStreet
              </Text>
              <Text>
                Get a head start on filing your final tax credits. Invite your
                tax preparer from the{' '}
                <Link href={`/${Page.settings}`}>Settings</Link> page, so they
                can interact with us on your&nbsp;behalf.
              </Text>
            </Flex>
            <Flex direction='column' gap={8} padding={24}>
              <Text variant='medium' size={18}>
                Meet with your MainStreet credit specialist
              </Text>
              <Text>
                Finalize your credits with your MainStreet credits specialist.
                Remember to bring your tax preparer! Check your email for timing
                details.
              </Text>
            </Flex>
          </div>
        </Flex>
      </Card>
    </>
  );
};
