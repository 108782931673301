import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Color, Flex, Heading, Image, Text } from 'component-library';
import TalkToAnExpertButton from 'components/util/TalkToAnExpertButton';
import { makeStyles } from '@material-ui/core';
import {
  useCommonStores,
  useDashboardStores,
  useFeatureFlags,
} from 'stores/useStores';
import { useIsMounted } from 'component-library/_helpers/use-utils';
import { PartnerInfo } from 'lib/interfaces';

const useStyles = makeStyles(({ breakpoints, palette, boxShadowDark }) => ({
  staticGradient: {
    background: `url(${process.env.PUBLIC_URL}/images/graphics/green-simple-gradient.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
    zIndex: 0,
    position: 'absolute',
    top: '0',
    left: '0',
    [breakpoints.down('xs')]: {
      backgroundPosition: '20%',
    },
  },
  rootContent: {
    position: 'relative',
    zIndex: 1,
    minHeight: 720,
  },
  navBar: {
    position: 'fixed',
    zIndex: 9999,
  },
  heroSectionContainer: {
    width: '100%',
    padding: 24,
    position: 'relative',
    overflow: 'hidden',
  },
  heroSectionAccess: {
    width: '100%',
    height: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    opacity: 0,
    padding: '80px 0 40px',
    animation: '$fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) forwards',
    [breakpoints.down('sm')]: {
      padding: '40px 0',
    },
  },
  stickyHeader: {
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    position: 'fixed',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 5,
    width: 'calc(100% - 48px)',
    maxWidth: '1440px',
    margin: '0 auto',
    animation: '$fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) forwards',
    [breakpoints.down('xs')]: {
      width: '100%',
      top: '0',
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  msLogo: {
    paddingRight: 24,

    '& > img ': {
      width: '100%',
      maxWidth: 'clamp(100px, 180px, 200px)',
    },
  },
  heroContent: {
    position: 'relative',
    [breakpoints.down('xs')]: {
      paddingTop: 30,
    },
  },
  heroSubheadingAccess: {
    '& > h1': {
      fontSize: 'clamp(40px, 10vw, 46px) !important',
      lineHeight: 'clamp(3rem, 5vw, 4rem)',
    },
    '& > h2': {
      lineHeight: 'clamp(3rem, 3vw, 4rem)',
    },
  },
  partnerLogo: {
    width: '100%',
    maxHeight: '46px',
    padding: '0 8px 0 0',
    [breakpoints.down('xs')]: {
      maxWidth: '50vw',
    },
  },
  partnerLogoLockup: {
    width: '100%',
    maxWidth: 'clamp(320px, 50vw, 480px)',
    [breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  mstLogo: {
    width: '95%',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  signUpContainer: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '320px',
  },
  textField: {
    width: '100%',
    background: Color.neutral.white,
  },

  inputRoot: {
    '& input': {
      width: 'calc(100% - 28px)',
    },
  },
  errorMsg: {
    margin: '5px 0 0',
    fontSize: '12px',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: '20px',
      marginBottom: '-25px',
    },
  },
  linkText: {
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:visited': {
      color: palette.secondary.main,
    },
    '&:link': {
      color: palette.secondary.main,
    },
  },
  formErrorMsg: {
    marginTop: '15px',
    textAlign: 'center',
  },
  expandableField: {
    width: '100%',
    maxWidth: '320px',
  },
  socialProof: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    minHeight: '400px',
    [breakpoints.down('sm')]: {
      minHeight: '500px',
    },
  },
  socialExpanded: {
    minHeight: '490px',
  },
  slide: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    transition: 'opacity 1s ease-in-out',
    width: '100%',
  },
  slideActive: {
    opacity: 1,
  },
  sliderImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'grayscale(1)',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  socialEstimate: {
    backgroundColor: Color.semantic.$success50,
    borderRadius: '10px',
    textAlign: 'center',
    width: 'auto',
    '& > p': {
      whiteSpace: 'nowrap',
    },
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  socialContent: {
    backgroundColor: Color.neutral.white,
    boxShadow: boxShadowDark,
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  flexibleButton: {
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  bold: {
    fontWeight: 600,
  },
}));

interface HeroSectionProps {
  partner?: string;
  referralInfo?: PartnerInfo;
}

interface SocialProofs {
  imageUri: string;
  quote: string;
  name: string;
  position: string;
  totalCreditsFound: string;
}

const SocialProofTestimonials: SocialProofs[] = [
  {
    imageUri: `${process.env.PUBLIC_URL}/images/graphics/natalise.png`,
    quote:
      "MainStreet's services have been easy to use, and their customer service has been exceptional.",
    name: 'Natalise Kalea Robinson',
    position: 'Co-Founder and CEO, Parallel Health',
    totalCreditsFound: '$103,466',
  },
  {
    imageUri: `${process.env.PUBLIC_URL}/images/graphics/jonathan-goldford.png`,
    quote:
      'MainStreet not only ran the process, but they did it much faster than we could otherwise, and they saved us over $10,000 through tax credits.',
    name: 'Jonathan Golford',
    position: 'Co-Founder, Wired Impact',
    totalCreditsFound: '$14,625',
  },
  {
    imageUri: `${process.env.PUBLIC_URL}/images/graphics/sohin-shah.png`,
    quote:
      "If I had to describe MainStreet in one word, it'd be “irreplaceable”.",
    name: 'Sohin Shah',
    position: 'Co-Founder, InstaLend',
    totalCreditsFound: '$356,994',
  },
  {
    imageUri: `${process.env.PUBLIC_URL}/images/graphics/daniel-ternyak.png`,
    quote:
      'MainStreet is really just set it and forget it for me. I know that my payroll tax savings are meaningful and that my runway is extended, and I don’t really have to think about it more than that.',
    name: 'Daniel Ternyak',
    position: 'Founder, ServiceBell',
    totalCreditsFound: '$187,913',
  },
];

export const HeroSection: React.FC<HeroSectionProps> = observer(
  ({ partner, referralInfo }) => {
    const classes = useStyles();
    const isMounted = useIsMounted();
    const { modules } = useDashboardStores();
    const { app } = useCommonStores();
    const [socialProofIndex, setSocialProofIndex] = useState<number>(0);
    const [showGetStarted, setShowGetStarted] = useState<boolean>(false);
    const featureFlags = useFeatureFlags();

    // Split the hero title into two parts based on the first period
    const heroTitleLine1 = referralInfo?.heroTitleLine1
      ? referralInfo.heroTitleLine1
      : 'Maximize Savings for Your Business and Employees';
    const heroTitleLine2 = referralInfo?.heroTitleLine2
      ? referralInfo.heroTitleLine2
      : '';
    const heroDescription = referralInfo?.heroDescription ? (
      referralInfo.heroDescription
    ) : (
      <>
        Our advanced AI-driven platform helps the average business save over
        $200,000 and also empowers employees to unlock additional savings
        opportunities. Join us and start&nbsp;saving&nbsp;today!
      </>
    );

    useEffect(() => {
      const interval: NodeJS.Timeout = setInterval(() => {
        setSocialProofIndex((prevIndex) =>
          prevIndex === SocialProofTestimonials.length - 1 ? 0 : prevIndex + 1,
        );
      }, 8000);

      return () => clearInterval(interval);
    }, []);

    return (
      <div
        className={classes.heroSectionContainer}
        data-testid={'welcome-to-mainstreet'}
      >
        <div className={classes.staticGradient} />
        <Flex
          style={{
            top: !featureFlags.getShowUrgencyBanner
              ? '24px'
              : app.isTablet
              ? '104px'
              : '68px',
          }}
          className={classes.stickyHeader}
          alignItems='center'
          justifyContent='space-between'
          padding={[16, 24]}
        >
          <Flex.Cell className={classes.msLogo}>
            <img
              src={`${process.env.PUBLIC_URL}/images/logo/mst-logo-dark.svg`}
            />
          </Flex.Cell>
          <Flex gap={24} justifyContent='flex-end'>
            {featureFlags.showTalkToAnExpertButton && (
              <TalkToAnExpertButton buttonOutlined />
            )}
            {!app.isMobile && (
              <Button label='Log in' onClick={() => app.history.push('/')} />
            )}
          </Flex>
        </Flex>
        <Flex
          direction='column'
          justifyContent='center'
          className={classes.rootContent}
          padding={app.isMobile ? [80, 0, 40, 0] : [80, 0, 80, 0]}
        >
          <Flex
            className={classes.heroSectionAccess}
            gap={app.isMobile ? 64 : 24}
            justifyContent='space-between'
            alignItems='center'
            direction={app.isTablet ? 'column' : 'row'}
          >
            <Flex direction='column' basis={'55%'}>
              {partner && referralInfo && (
                <Flex
                  alignItems='center'
                  className={classes.partnerLogoLockup}
                  padding={app.isMobile ? [0, 0, 24, 0] : [0, 0, 32, 0]}
                  gap={8}
                >
                  <Flex.Cell>
                    <Image
                      src={referralInfo.partnerLogo.url}
                      className={classes.partnerLogo}
                    />
                  </Flex.Cell>
                  <Flex.Cell>
                    <Image
                      className={classes.mstLogo}
                      src={`${process.env.PUBLIC_URL}/images/logo/mainstreet-x-logo.svg`}
                    />
                  </Flex.Cell>
                </Flex>
              )}
              <Flex
                direction='column'
                gap={24}
                className={classes.heroSubheadingAccess}
              >
                <Heading
                  tag={app.isTablet ? 'h3' : 'h1'}
                  marginBottom={0}
                  color={Color.green._80}
                >
                  {heroTitleLine1}
                </Heading>
                {heroTitleLine2 && (
                  <Heading
                    tag={app.isTablet ? 'h3' : 'h1'}
                    color={Color.green._80}
                    marginBottom={0}
                  >
                    {heroTitleLine2}
                  </Heading>
                )}
                <Flex.Cell>
                  <Text size={18}>{heroDescription}</Text>
                </Flex.Cell>

                <Flex direction='column' gap={24}>
                  <Flex
                    direction={app.isMobile ? 'column' : 'row'}
                    gap={app.isMobile ? 16 : 24}
                    alignItems='center'
                  >
                    <Button
                      dataTestId={`${partner}-partner-get-started-btn`}
                      label={
                        <Text
                          size={15}
                          color={Color.neutral.white}
                          variant='medium'
                        >
                          Create Estimate
                        </Text>
                      }
                      onClick={() => {
                        modules.toggleSignUpModal(isMounted());
                      }}
                      className={classes.flexibleButton}
                    />
                    {featureFlags.showTalkToAnExpertButton && (
                      <TalkToAnExpertButton
                        buttonOutlined
                        className={classes.flexibleButton}
                      />
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              basis={'40%'}
              className={`${classes.socialProof} ${
                showGetStarted ? classes.socialExpanded : ''
              }`}
            >
              {SocialProofTestimonials.map((item, index) => {
                return (
                  <Flex
                    key={`${item.imageUri}-${index}`}
                    className={`${classes.slide} ${
                      index === socialProofIndex ? classes.slideActive : ''
                    }`}
                    direction='column'
                  >
                    <Flex.Cell
                      basis={app.isMobile ? 180 : 280}
                      className={classes.sliderImage}
                      style={{ backgroundImage: `url(${item.imageUri})` }}
                    >
                      <></>
                    </Flex.Cell>
                    <Flex
                      direction='column'
                      gap={24}
                      padding={24}
                      className={classes.socialContent}
                    >
                      <Text size={18}>&quot;{item.quote}&quot;</Text>
                      <Flex
                        gap={40}
                        alignItems='center'
                        direction={app.isMobile ? 'column' : 'row'}
                      >
                        <Flex direction='column' gap={4}>
                          <Text variant='medium' size={18}>
                            {item.name}
                          </Text>
                          <Text>{item.position}</Text>
                        </Flex>
                        <Flex
                          direction='column'
                          className={classes.socialEstimate}
                          padding={12}
                          justifyContent='center'
                        >
                          <Heading
                            tag={app.isSmallDesktop ? 'h4' : 'h3'}
                            color={Color.neutral.white}
                            marginBottom={0}
                            className={classes.bold}
                          >
                            {item.totalCreditsFound}
                          </Heading>
                          <Text color={Color.neutral.white}>
                            Total credit found
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
      </div>
    );
  },
);
