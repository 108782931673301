import {
  CmsAnswerData,
  CmsQuestionData,
  CompanyAddress,
  CompanyData,
  DateInfo,
  Month,
  MSClientResponse,
  OrderForm,
  ProgramData,
  ProgramName,
  QualificationQuestionsByYear,
  QuarterDescription,
  QuestionProps,
} from 'lib/interfaces';
import {
  ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
  BillingScheduleStatusEnum,
  BillingScheduleTypeEnum,
  ExpectedCreditTypeEnum,
  ExperimentalProgramNames,
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
  Months,
  MonthsToQuarter,
  ProgramNameEnum,
  Programs,
  QualificationStatusEnum,
  SubGroupNameEnum,
} from '../lib/constants';
import { Page } from 'lib/constants';
import { History } from 'history';
import { useCallback, useEffect, useRef } from 'react';
import { TIME } from './time';
import { datadogLogs } from '@datadog/browser-logs';
import { FileHandle } from 'component-library';
import { logContext } from 'logging';
import { AddressEntity } from 'entities/AddressEntity';

export const GetQueryValue = (
  val: Array<string> | string | undefined,
): string => {
  if (val === undefined) {
    return '';
  }
  return Array.isArray(val) ? val.filter((x) => !!x)[0] || '' : val;
};

export function Cancelable<T>(p: Promise<T>, f: (val: T) => void): () => void {
  let canceled = false;
  p.then((val) => {
    if (canceled) return;
    f(val);
  });

  return () => {
    canceled = true;
  };
}

export const UpperCaseFirstLetter = (text: string): string => {
  return text ? `${text.charAt(0).toUpperCase()}${text.slice(1)}` : '';
};

export const JoinStringsByCommasAndAnd = (stringArray: string[]) => {
  const formattedArray = stringArray.map((string) => string.replace(/_/g, ' '));

  switch (formattedArray.length) {
    case 0:
      return '';
    case 1:
      return formattedArray[0];
    case 2:
      return formattedArray.join(' and ');
    default: {
      const elements = formattedArray.slice(0, formattedArray.length - 1);
      const lastElement = formattedArray[formattedArray.length - 1];
      return elements.join(', ') + ', and ' + lastElement;
    }
  }
};

export const FloatToDollarString = (dollars: number) => {
  return dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const CurrencyStringToCents = (dollars: string): number => {
  const dollarFloat = parseFloat(dollars.replace(/,/g, '').replace('$', ''));
  if (isNaN(dollarFloat)) {
    return NaN;
  }
  return Math.floor(dollarFloat * 100);
};

export const CentsToDisplayString = (
  amountCents: number,
  min = 0,
  max = 2,
): string => {
  const dollars = amountCents / 100;
  return dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
};

// Always returns two decimals (123.40) unless decimals are zero (100.00 => 100)
export const CentsToDisplayStringNoZeros = (amountCents: number): string => {
  return CentsToDisplayString(amountCents, 2, 2).replace(/\.00/, '');
};

export const CentsToDisplayStringNoSymbol = (amountCents: number) => {
  return CentsToDisplayStringNoZeros(amountCents).replace('$', '');
};

// Takes a 1 indexed month number ex: 1 => January
// Months array is zero index as is JS Date
export const MonthNumberToName = (number: number) => {
  return Months[number - 1];
};

/**
 * Converts the name to a month value between 0/1 and 11/12.
 * If not found returns -1.
 * @param name
 * @param zeroBased
 * @constructor
 */
export const MonthNameToNumber = (name: string, zeroBased = true): number => {
  const upperMonths = Months.map((m) => m.toUpperCase());
  const num = upperMonths.indexOf(name.toUpperCase());
  if (num === -1) {
    return -1;
  }
  return num + (zeroBased ? 0 : 1);
};

export const MonthToQuarterString = (name: string) => {
  return `Q${MonthsToQuarter[name]}`;
};

export const MonthToPreviousQuarterString = (name: string) => {
  return MonthsToQuarter[name] === 1 ? 'Q4' : `Q${MonthsToQuarter[name] - 1}`;
};

export const FirstMonthOfNextQuarterString = (name: string) => {
  switch (name) {
    case 'January':
    case 'February':
    case 'March':
      return 'April';
    case 'April':
    case 'May':
    case 'June':
      return 'July';
    case 'July':
    case 'August':
    case 'September':
      return 'October';
    case 'October':
    case 'November':
    case 'December':
      return 'January';
  }
};

// NOTE: Feels duplicative of QuarterValue.CreateFromDate().Add(1)
export const NextQuarterFromDate = (date: Date): QuarterDescription => {
  const year = date.getFullYear();
  const month = date.getMonth();

  const nextQuarter = Math.floor(month / 3) + 2;

  if (nextQuarter > 4) {
    return { quarter: 1, year: year + 1 };
  }

  return { quarter: nextQuarter, year };
};

// Current Redeemable Quarter is the previous quarter to current date
export const PreviousQuarterFromDate = (date: Date): QuarterDescription => {
  const year = date.getFullYear();
  const month = date.getMonth();

  const previousQuarter = Math.floor(month / 3);

  if (previousQuarter === 0) {
    return { quarter: 4, year: year - 1 };
  }

  return { quarter: previousQuarter, year };
};

export const FirstDayOfNextQuarter = (date: Date): Date => {
  const { quarter, year } = NextQuarterFromDate(date);
  return new Date(year, (quarter - 1) * 3, 1);
};

export const DateFromScheduleDate = (isoStr: string): Date => {
  const components = isoStr.split('-');
  const year = parseInt(components[0], 10);
  const month = parseInt(components[1], 10) - 1;
  const day = parseInt(components[2], 10);

  return new Date(year, month, day);
};

export const handleErrorOrDataCallback = (
  res: MSClientResponse<any>,
  dataCallback: (data?: any) => void,
  setError: (error: string) => void,
) => {
  if (res.errorMsg) {
    setError(res.errorMsg);
    return;
  }

  setError('');

  dataCallback(res.data);
};

// "testSTring hello" --> "Teststring Hello"
export const TitleCase = (text: string): string => {
  return text
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word[0].toUpperCase() + word.substr(1);
    })
    .join(' ');
};

// "testSTring hello" --> "Teststring hello"
export const SentenceCase = (text: string): string => {
  const lowercase = text.toLowerCase();
  return lowercase[0].toUpperCase() + lowercase.substr(1);
};

export const CapitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
};

export const SnakeToSentenceCase = (str: string) => {
  return str.split('_').map(CapitalizeFirstLetter).join(' ');
};

export const UpperCaseDisplay = (text: string): string => {
  return text === 'N/A' ? text : TitleCase(text.replace(/_/g, ' '));
};

export const DisplayCreditEstimate = (
  lowEstimateInCents: number,
  highEstimateInCents: number,
): string => {
  return lowEstimateInCents === highEstimateInCents
    ? CentsToDisplayString(lowEstimateInCents * 100)
    : `${CentsToDisplayString(lowEstimateInCents * 100)} –
    ${CentsToDisplayString(highEstimateInCents * 100)}`;
};

// Insert separator between all elements ([a, b, c], s) => [a, s, b, s, c]
export const SeparateElements = (
  elements: JSX.Element[],
  separator: (key: string) => JSX.Element,
) => {
  return elements.reduce((acc: JSX.Element[], element, index) => {
    const separatorKey = `${element.key || index}-separator`;
    return index === elements.length - 1
      ? acc.concat([element])
      : acc.concat([element, separator(separatorKey)]);
  }, []);
};

// TODO: SRE-1180 (INFRA) re-evaluate this switching logic to see if it's deprecated.
export const IsStagingOrProd = (): boolean => {
  return ['staging', 'prod'].includes(
    process.env.REACT_APP_CONNECTION_NAME || '',
  );
};

/**
 * For a given tax year, find the specified company's corresponding R&D program.
 *
 * @param company
 * @param taxYear
 * @param programName
 */
export const GetCompanyProgramForTaxYear = (
  company: CompanyData,
  taxYear: number,
  { programName } = {
    programName: ProgramNameEnum.FED_RD_TAX,
  },
): ProgramData | undefined => {
  if (!company.programs || company.programs.length === 0) {
    return undefined;
  }

  return company.programs.find(
    (program) => program.name === programName && program.taxYear === taxYear,
  );
};

/**
 * For a given company, determine if any of its program orders have an advance.
 * A company is considered to have an advance when they have a BillingSchedule of
 * type 'funding' and a BillingSchedule status that is not 'canceled'
 *
 * @param company
 * @returns boolean
 */
export const CompanyHasAdvance = (company: CompanyData) => {
  return company.billingSchedules.some(
    (billingSchedule) =>
      billingSchedule.type === BillingScheduleTypeEnum.FUNDING &&
      billingSchedule.status !== BillingScheduleStatusEnum.CANCELLED,
  );
};

/**
 * For a given program, return whether or not it's submitted or qualified.
 *
 * @param program
 */
export const IsProgramSubmittedOrQualified = (
  program: ProgramData | undefined,
): boolean => {
  if (!program || !program.qualificationStatus) {
    return false;
  }

  return (
    program.qualificationStatus ===
      QualificationStatusEnum.QUALIFICATION_SUBMITTED ||
    program.qualificationStatus === QualificationStatusEnum.QUALIFIED
  );
};

export const IsStateRDCreditProgram = (programName: ProgramName) => {
  return programName.includes('state_rd');
};

export const IsRDCreditProgram = (programName: ProgramName) => {
  return programName.includes('_rd_');
};

export const IsERCProgram = (programName: ProgramName) => {
  return programName === ProgramNameEnum.ERC;
};

export const IsExperimentalProgramWithNoQualFlow = (
  programName: ProgramName,
) => {
  return ExperimentalProgramNames.includes(programName);
};

export const handleMultiSelectCheckboxChange = (
  event: any,
  question: QuestionProps,
) => {
  const value = event.target.value;
  const optionsSelected = Array.isArray(question.value) ? question.value : [];
  const selected = optionsSelected.indexOf(value) > -1;
  const noneId =
    GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.CREDITS_SELECTED]?.NONE;
  const none_index = optionsSelected.indexOf(noneId);

  if (!selected) {
    const newSelected = [...optionsSelected, value];
    if (question.callback) {
      if (value === noneId) {
        const noneSelected = [noneId];
        question.callback(noneSelected);
        question.value = noneSelected;
      } else if (none_index > -1) {
        newSelected.splice(none_index, 1);
        question.callback(newSelected);
        question.value = newSelected;
      } else {
        question.callback(newSelected);
        question.value = newSelected;
      }
    }
  } else {
    const selected_index = optionsSelected.indexOf(value);
    optionsSelected.splice(selected_index, 1);
    const newOptionsSelected = [...optionsSelected];
    if (question.callback) {
      question.callback(newOptionsSelected);
      question.value = newOptionsSelected;
    }
  }
};

export const getMultiSelectCheckboxValues = (
  event: any,
  question: QuestionProps,
  noneValue: string,
) => {
  const value = event.target.value;
  const optionsSelected = Array.isArray(question.value) ? question.value : [];
  const selected = optionsSelected.indexOf(value) > -1;
  const none_index = optionsSelected.indexOf(noneValue);
  const none_selected = none_index > -1;
  // If selecting none, clear the array other than none
  if (value === noneValue) {
    question.value = [value];
    return [value];
  }

  if (!selected) {
    const newSelected = [...optionsSelected, value];
    if (none_selected) {
      newSelected.splice(none_index, 1);
    }

    question.value = newSelected;
    return newSelected;
  } else {
    const selected_index = optionsSelected.indexOf(value);
    optionsSelected.splice(selected_index, 1);
    if (none_selected) {
      optionsSelected.splice(none_index, 1);
    }

    question.value = optionsSelected;
    return optionsSelected;
  }
};

export const getCmsQuestionOptions = (
  questionData: CmsQuestionData,
  useName?: boolean,
) => {
  const cmsQuestionAnswers = questionData && questionData.answerIDs;
  const newOptions: any[] =
    cmsQuestionAnswers &&
    cmsQuestionAnswers.map((answer: any) => {
      if (useName) {
        return {
          name: answer.text,
          value: answer.id,
        };
      }
      return {
        label: answer.text,
        value: answer.id,
      };
    });
  return newOptions;
};

export const getCmsQuestionText = (questionData: CmsQuestionData) => {
  const text = questionData && questionData.text;
  return String(text);
};

export const getCmsAnswerText = (
  answerIds: CmsAnswerData[],
  answerText: string,
) => {
  const answer = getCmsAnswer(answerIds, answerText);
  return answer ? String(answer.text) : '';
};

export const getCmsAnswerId = (
  answerIds: CmsAnswerData[],
  answerText: string,
) => {
  const answer = getCmsAnswer(answerIds, answerText);
  return answer ? String(answer.id) : '';
};

export const GetCmsQuestionId = <T extends { [index: string]: string }>(
  GraphCmsQuestionIdEnum: T,
  questionData: QuestionProps,
): keyof T => {
  const keys = Object.keys(GraphCmsQuestionIdEnum).filter(
    (key) => GraphCmsQuestionIdEnum[key] === questionData.cmsId,
  );
  return keys[0];
};

export const getCmsAnswer = (
  answerIds: CmsAnswerData[],
  answerText: string,
) => {
  const answer =
    answerIds &&
    answerIds.find(
      (answer) =>
        String(answer.text) &&
        String(answer.text).toLocaleLowerCase() ===
          answerText.toLocaleLowerCase(),
    );
  return answer;
};

/**
 * Take in a Date or string Date representation and return all display options
 *
 * @param dateInput Date object or date string made from a Date object
 * @returns DateInfo
 */
export const GetDateInfo = (dateInput: string | Date): DateInfo => {
  const dateObj = new Date(dateInput);
  const isoString = dateObj.toISOString();

  const day = parseInt(isoString.split('-')[2], 10);
  const month = parseInt(isoString.split('-')[1], 10);
  const year = parseInt(isoString.split('-')[0], 10);
  const stringDay = isoString.split('-')[2].slice(0, 2);
  const stringMonth = isoString.split('-')[1];
  const stringYear = isoString.split('-')[0];

  return {
    isoStringDate: isoString.split('T')[0],
    isoStringDatetime: `${isoString.split('.')[0]}Z`,
    isoStringFull: isoString,
    day,
    month,
    year,
    monthDayYear: `${stringMonth}/${stringDay}/${stringYear}`,
    yearMonthDay: `${stringYear}/${stringMonth}/${stringDay}`,
    utcDay: dateObj.getUTCDate(), // zero-indexed
    utcMonth: dateObj.getUTCMonth(), // zero-indexed
    utcYear: dateObj.getUTCFullYear(), // zero-indexed
    monthName: Months[dateObj.getUTCMonth()],
  };
};

/**
 * Given an offset, return the DateInfo for the first of the month,
 * offsetMonths in advance.
 *
 * 0 offset months will be the first of the current month
 * 1 offset months will be the first of next month, and so on
 *
 * Note:
 * This may be abstracted into a helper function in the future,
 * but in the meantime should be treated as an OrderCalculator-specific
 * function.
 *
 * @param offsetMonths
 * @returns DateInfo
 */
export const GetStartDate = (
  offsetMonths: number,
  startDate?: Date,
): DateInfo => {
  const dateObj = startDate ? startDate : new Date();

  // Hardcode 1st of the month
  dateObj.setUTCDate(1);

  // Move the month forward by "offset" num months
  dateObj.setUTCMonth(dateObj.getUTCMonth() + offsetMonths);

  // YYYY-MM-DDTHH:MM:SS.sssZ -> YYYY-MM-DD
  const isoString = dateObj.toISOString().split('T')[0];

  return GetDateInfo(isoString);
};

export const IsValidDate = (
  dateInput: Date | undefined | string,
): dateInput is Date => {
  if (!dateInput) return false;
  if (dateInput instanceof Date && isNaN(dateInput.getTime())) return false;
  if (isNaN(new Date(Date.parse(dateInput as string)).getTime())) return false;
  return true;
};

export const IsDateInTheFuture = (dateInput: Date | undefined): boolean => {
  return dateInput ? new Date() < new Date(dateInput) : false;
};

export const getAgeFromTimeStamp = (dateInput: Date | undefined) => {
  if (!dateInput || isNaN(dateInput.getTime())) return undefined;
  const ageDiffMS = Date.now() - dateInput.getTime();
  const ageDate = new Date(ageDiffMS); // milliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const GetPendingOrders = (orders: OrderForm[]) => {
  return orders.filter((order) => order.acceptedAt === null);
};

export const IsDisplayableOrderForm = (orderForm: OrderForm) =>
  IsRDCreditProgram(orderForm.programName) ||
  IsExperimentalProgramWithNoQualFlow(orderForm.programName) ||
  orderForm.acceptedAt;

export const HasManuallyAcceptedOrderForm = (orderForms: OrderForm[]) => {
  return orderForms.some(
    (orderForm) =>
      orderForm.acceptedAt && !orderForm.autoAcceptedTermsAndAgreements,
  );
};

export const IsValidYear = (input: string) => {
  if (input.length === 4) {
    const currentYear = new Date().getFullYear();
    const inputInteger = parseInt(input);
    if (inputInteger > 1900 && inputInteger <= currentYear) {
      return true;
    }
  }
  return false;
};

export const ClosestNumber = (num: number, values: number[]) => {
  if (values.length === 0) {
    return num;
  }
  let dist = Math.abs(num - values[0]);
  let closest = values[0];
  for (let i = 1; i < values.length; i++) {
    const newDist = Math.abs(num - values[i]);
    if (newDist < dist) {
      dist = newDist;
      closest = values[i];
    }
  }
  return closest;
};

export const DollarsToCents = (
  amountDollars: number | undefined | null,
): number | undefined | null => {
  if (amountDollars === undefined) {
    return undefined;
  }
  if (amountDollars === null) {
    return null;
  }
  return Math.floor(amountDollars * 100);
};

export const CentsToDollars = (
  amountCents: number | undefined | null,
): number | undefined => {
  return amountCents !== null && amountCents !== undefined
    ? amountCents / 100
    : undefined;
};

export class FormRedirects {
  private _history: History<History.PoorMansUnknown>;
  private _forwardPage: Page;
  private _backwardPage: Page;

  constructor(
    history: () => History<History.PoorMansUnknown>,
    forwardPage: Page,
    backwardPage: Page,
  ) {
    this._history = history();
    this._forwardPage = forwardPage;
    this._backwardPage = backwardPage;
  }

  public Forward = () => {
    this.goTo(this._forwardPage);
  };

  public Backward = () => {
    this.goTo(this._backwardPage);
  };

  private goTo = (page: Page) => {
    this._history.push(`/${page}`);
  };
}

export const IsEmailValid = (email: string): boolean => {
  const regExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(email);
};

export const IsJsonString = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const IsFirstMonthOfQuarter = (
  month: number,
  isZeroBased = true,
): boolean => {
  if (isZeroBased) {
    month = month + 1;
  }
  return [1, 4, 7, 10].includes(month);
};

// See https://stackoverflow.com/a/59459000
export const GetTypedObjectKeys = Object.keys as <T extends object>(
  obj: T,
) => Array<keyof T>;

const urlsFromStringRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
export const UrlStringsToLinks = (string: string) => {
  const newString = string.replace(urlsFromStringRegex, (match) => {
    return `<a href='${match}' target='_blank'>${match}</a>`;
  });

  return newString;
};

export const getDayDifference = (dateOne: Date, dateTwo: Date) => {
  const timeOne = dateOne.getTime();
  const timeTwo = dateTwo.getTime();
  const diffTime = timeOne - timeTwo;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const delayAction = (millseconds: number, action: () => void) => {
  let timerId: NodeJS.Timeout | null = setTimeout(() => {
    action();
    timerId = null;
  }, millseconds);

  return () => {
    if (timerId) {
      clearTimeout(timerId);
      timerId = null;
    }
  };
};

export const isLastMonthOfFiscalYear = (company: CompanyData): boolean => {
  if (company.fiscalYearEndDate) {
    const currentMonth = new Date().getMonth();
    const fiscalYearMonth = Months[currentMonth];

    return fiscalYearMonth === company.fiscalYearEndDate;
  } else {
    return false;
  }
};

// Used to calculate payroll gap within a company fiscal tax year
export const FiscalTaxYearHasPayrollGap = (
  firstPayStatementOfTaxYear: Date | undefined,
  lastPayStatementOfTaxYear: Date | undefined,
  fiscalTaxYearEndMonth: string | null | undefined,
): boolean => {
  if (
    !fiscalTaxYearEndMonth ||
    !firstPayStatementOfTaxYear ||
    !lastPayStatementOfTaxYear
  ) {
    return false;
  }

  const yearsElapsed =
    lastPayStatementOfTaxYear.getUTCFullYear() -
    firstPayStatementOfTaxYear.getUTCFullYear();
  if (yearsElapsed > 1) {
    throw new Error(
      `A fiscal year cannot be more than 1 year long ${firstPayStatementOfTaxYear} ${lastPayStatementOfTaxYear}`,
    );
  } else if (yearsElapsed < 0) {
    throw new Error(
      `Last pay statement cannot come before first pay statement ${firstPayStatementOfTaxYear} ${lastPayStatementOfTaxYear}`,
    );
  }

  // Check if first pay stub of fiscal tax year
  const getFirstPayStubOfFiscalTaxYearMonth = (): Month => {
    const firstPayStubMonth = firstPayStatementOfTaxYear.getUTCMonth();
    return Months[firstPayStubMonth];
  };

  const getFirstMonthOfFiscalTaxYear = (): string => {
    const firstFiscalTaxYearMonth =
      Months.indexOf(fiscalTaxYearEndMonth as Month) + 1;
    if (firstFiscalTaxYearMonth === Months.length) {
      return Months[0];
    }
    return Months[firstFiscalTaxYearMonth];
  };
  const isFirstPayStubOfFiscalYearStart: boolean =
    getFirstPayStubOfFiscalTaxYearMonth() === getFirstMonthOfFiscalTaxYear();

  // Check if last pay stub is within two months from last month of fiscal tax year
  const lastPayStubOfFiscalTaxYearMonth: Month =
    Months[lastPayStatementOfTaxYear.getUTCMonth()];
  const mostRecentPayStubMonth: number = Months.indexOf(
    lastPayStubOfFiscalTaxYearMonth,
  );
  const lastFiscalTaxYearMonth: number = Months.indexOf(
    fiscalTaxYearEndMonth as Month,
  );
  const isMostRecentPayStubWithinTwoMonthsOfFiscalTaxYearEnd: boolean = [
    lastFiscalTaxYearMonth === 0 ? 11 : lastFiscalTaxYearMonth - 1,
    lastFiscalTaxYearMonth,
  ].includes(mostRecentPayStubMonth);

  return (
    !isFirstPayStubOfFiscalYearStart ||
    !isMostRecentPayStubWithinTwoMonthsOfFiscalTaxYearEnd
  );
};

/**
 * Runs callback only once in the lifecycle of the component it is called in,
 * and gets trigger right after the component is mounted
 * @param callback Callback to trigger once, after component is mounted
 */
export const useEffectOnce = (callback: () => void) => {
  const hasTriggeredCallback = useRef<boolean>(false);

  useEffect(() => {
    if (!hasTriggeredCallback.current) {
      hasTriggeredCallback.current = true;
      callback();
    }
  }, [callback]);
};

/**
 * Runs callback once, [delay] milliseconds after calling component is mounted
 * @param delay Millisends to wait before triggering callback
 * @param callback Callback to be triggered
 */
export const useDelayedEffectOnce = (delay: number, callback: () => void) => {
  const hasTriggeredCallback = useRef<boolean>(false);
  const mountedTime = useRef<number>(0);

  useEffect(() => {
    if (hasTriggeredCallback.current) {
      return;
    }

    if (!mountedTime.current) {
      mountedTime.current = TIME.now();
    }

    const diff = mountedTime.current + delay - TIME.now();
    if (diff > 0) {
      let timerId: NodeJS.Timeout | null = setTimeout(() => {
        if (!hasTriggeredCallback.current) {
          hasTriggeredCallback.current = true;
          timerId = null;
          callback();
        }
      }, diff);

      return () => {
        if (timerId) {
          clearTimeout(timerId);
          timerId = null;
        }
      };
    } else if (!hasTriggeredCallback.current) {
      hasTriggeredCallback.current = true;
      callback();
    }
  }, [delay, callback]);
};

/**
 * Shortcut for indicating if component where method is called is still mounted
 * @returns Function to call for testing if component is mounted or not
 */
export const useIsMounted = () => {
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
};

/**
 * Runs the callback once at the end of the component lifecycle, when it unmounts
 * @param callback Callback to be triggered only once, when the component unmounts
 */
export const useUnmountEffectOnce = (callback: () => void) => {
  const hasTriggeredCallback = useRef<boolean>(false);
  const unmountCallback = useRef<() => void>(callback);
  unmountCallback.current = callback;

  useEffect(() => {
    return () => {
      if (!hasTriggeredCallback.current && unmountCallback.current) {
        hasTriggeredCallback.current = true;
        unmountCallback.current();
      }
    };
  }, []);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface WindowEventReference<K extends keyof WindowEventMap = any> {
  type: K;
  listener: (this: Window, ev: WindowEventMap[K]) => unknown;
}

/**
 * Attach/Detach events to the window based on lifecycle of the calling component
 * @param type Event name to monitor
 * @param listener Callback when event is triggered
 * @param options Any customized options normally passed to addEventListener
 */
export const useWindowEvent = <K extends keyof WindowEventMap>(
  type: K,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listener: (this: Window, ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions,
) => {
  const eventPairRef = useRef<WindowEventReference | null>(null);

  useEffect(() => {
    // Removing existing callback if defined
    if (eventPairRef.current) {
      window.removeEventListener(
        eventPairRef.current.type,
        eventPairRef.current.listener,
      );
    }

    // Attach new callback
    eventPairRef.current = {
      type,
      listener,
    };
    window.addEventListener(type, listener, options);

    // Remove callback when component unmounts
    return () => {
      if (eventPairRef.current) {
        window.removeEventListener(
          eventPairRef.current.type,
          eventPairRef.current.listener,
        );
        eventPairRef.current = null;
      }
    };
  }, [type, listener, options]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface DocumentEventReference<K extends keyof DocumentEventMap = any> {
  type: K;
  listener: (this: Document, ev: DocumentEventMap[K]) => unknown;
}

/**
 * Attach/Detach events to the document based on lifecycle of the calling component
 * @param type Event name to monitor
 * @param listener Callback when event is triggered
 * @param options Any customized options normally passed to addEventListener
 */
export const useDocumentEvent = <K extends keyof DocumentEventMap>(
  type: K,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listener: (this: Document, ev: DocumentEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions,
) => {
  const eventPairRef = useRef<DocumentEventReference | null>(null);

  useEffect(() => {
    // Removing existing callback if defined
    if (eventPairRef.current) {
      document.removeEventListener(
        eventPairRef.current.type,
        eventPairRef.current.listener,
      );
    }

    // Attach new callback
    eventPairRef.current = {
      type,
      listener,
    };
    document.addEventListener(type, listener, options);

    // Remove callback when component unmounts
    return () => {
      if (eventPairRef.current) {
        document.removeEventListener(
          eventPairRef.current.type,
          eventPairRef.current.listener,
        );
        eventPairRef.current = null;
      }
    };
  }, [type, listener, options]);
};

export const taxOffsetText = (program: ProgramData) => {
  let creditTypeText = '';
  switch (program.expectedCreditType) {
    case ExpectedCreditTypeEnum.INCOME_TAX:
    case ExpectedCreditTypeEnum.DEFERRED_INCOME:
      creditTypeText = 'income';
      break;
    default:
      creditTypeText = 'payroll';
      break;
  }
  switch (program.name) {
    case ProgramNameEnum.STATE_RD_AZ:
      return `through a refund or by offsetting your ${
        Programs[program.name].geo
      } ${creditTypeText} taxes.`;
    case ProgramNameEnum.STATE_RD_GA:
      return `through a refund or by offsetting your ${
        Programs[program.name].geo
      } payroll or income taxes.`;
    case ProgramNameEnum.ERC:
      return `by offsetting your ${
        Programs[program.name].geo
      } ${creditTypeText} taxes. The exact timing of your refund will depend on IRS processing times.`;
    default:
      return `by offsetting your ${
        Programs[program.name].geo
      } ${creditTypeText} taxes.`;
  }
};

export const setDocumentPageTitle = (title?: string) => {
  if (title) {
    document.title = `MainStreet \u2013 ${title}`;
  } else {
    document.title = `MainStreet`;
  }
};

export const getAlertTextBySubGroup = (
  subGroupName: SubGroupNameEnum,
): { text: string; subText: string } | null => {
  switch (subGroupName) {
    case SubGroupNameEnum.VENDOR_CLOUD_EXPENSES:
      return {
        text: 'Wherever possible, we’ve pre-filled this info using your payroll data and/or previous work with MainStreet.',
        subText:
          'Please confirm that this data is complete and accurate. It may be helpful to ask your CTO!',
      };

    case SubGroupNameEnum.VENDOR_SUPPLY_EXPENSES:
      return {
        text: 'Wherever possible, we’ve pre-filled this info using your payroll data and/or previous work with MainStreet.',
        subText:
          'Please confirm that this data is complete and accurate. Your accounting software should have the relevant info.',
      };

    case SubGroupNameEnum.VENDOR_PROJECTS:
      return {
        text: 'Wherever possible, we’ve pre-filled this section using the most recent data you provided to MainStreet.',
        subText:
          'Please confirm that this data is complete and accurate. It may be helpful to consider whether you started a new R&D project in 2022.',
      };

    case SubGroupNameEnum.EXTRA_DETAILS:
      return {
        text: 'Wherever possible, we’ve pre-filled this section using your accounting data and/or previous work with MainStreet.',
        subText:
          'Please confirm that this data is complete and accurate. It may be helpful to consider whether your company’s structure or ownership changed in 2022.',
      };

    case SubGroupNameEnum.INCOME_AND_RESEARCH_SPEND:
      return {
        text: 'Wherever possible and relevant, we used your accounting data to find your 2021 gross receipts.',
        subText:
          'Please validate that this number matches the gross receipts on your 2021 tax return, or simply upload it here.',
      };
    default:
      return null;
  }
};

export const beginsWithVowel = (val: string) => {
  return (
    val.length > 0 && ['a', 'e', 'i', 'o', 'u'].includes(val[0].toLowerCase())
  );
};

export const getWarningTextBySubGroup = (
  subGroupName: SubGroupNameEnum,
): { text: string; subText: string } | null => {
  switch (subGroupName) {
    case SubGroupNameEnum.VENDOR_CLOUD_EXPENSES:
      return {
        text: 'Be sure to double-check your cloud computing expenses!',
        subText:
          'You can add new vendors, or click on an inaccurate expense to edit or delete any incorrect data',
      };

    case SubGroupNameEnum.VENDOR_SUPPLY_EXPENSES:
      return {
        text: 'Be sure to double-check your R&D supply expenses!',
        subText:
          'Use this table to add new vendors, or click on an inaccurate expense to edit or delete any incorrect data.',
      };
    default:
      return null;
  }
};

export const getURLSearchParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const dateOnOrAfter = (date: Date, comparisonDate: Date) => {
  return date.getTime() >= comparisonDate.getTime();
};

export const dateBefore = (date: Date, comparisonDate: Date) => {
  return date.getTime() < comparisonDate.getTime();
};

export const base64toFile = (
  base64: string,
  filename: string,
  type: string,
) => {
  const arr = base64.split(',');
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type });
};

export const formatPayrollString = (input: string): string => {
  let formattedString = input.replace(/_/g, ' ');
  formattedString = formattedString.replace(/\b\w/g, (match) =>
    match.toUpperCase(),
  );

  return formattedString;
};

/**
 * Generate an ISO-format, date-only string from the given date, e.g. to be
 * stored as a varchar value in the DB.
 */
export const ISODateStringFromDate = (date: Date): string => {
  return date?.toISOString().substring(0, 10);
};

// FileHandler
export const isFileSizeWithinLimits = (file: File) => {
  const isFileWithinLimits = file.size <= UPLOAD_FILE_SIZE_LIMIT;
  if (!isFileWithinLimits) {
    datadogLogs.logger.info(
      `Attempted to upload a file exceeding the ${UPLOAD_FILE_SIZE_LIMIT} size limit. FileSize: ${file.size}`,
    );
  }
  return isFileWithinLimits;
};

export const isFileEncrypted = async (
  fileHandle: FileHandle,
  errorMsg?: string,
): Promise<boolean> => {
  const { file } = fileHandle;
  try {
    if (file.type === 'application/pdf') {
      const fileContents = await file.text();

      // find the PDF trailer (located near EOF and contains the encryption dictionary used, if any)
      const pdfTrailerStartIndex = fileContents.lastIndexOf('trailer');
      if (pdfTrailerStartIndex > 0) {
        const isEncrypted =
          fileContents.indexOf('/Encrypt', pdfTrailerStartIndex) !== -1;
        if (isEncrypted) {
          fileHandle.setError(
            errorMsg
              ? errorMsg
              : 'The document is password protected. Please reupload it without this protection.',
          );
        }
        return isEncrypted;
      }
    }
  } catch (e) {
    datadogLogs.logger.info(
      `Checking if pdf file is encrypted failed; fileName: ${file.name}`,
      logContext({ error: e }),
    );
  }
  return false;
};

const UPLOAD_FILE_SIZE_LIMIT = 1024 * 1024 * 20;

export const AddressToString = (address: AddressEntity | CompanyAddress) =>
  `${address.streetLine1}${
    address.streetLine2 ? ' - ' + address.streetLine2 : ''
  }, ${address.city}, ${address.state} ${address.zipcode}`;

export const getRetirementEstimatedCreditContext = (
  qualificationQuestionsByYear: QualificationQuestionsByYear | null,
): string =>
  `Up to $${(
    (qualificationQuestionsByYear &&
    qualificationQuestionsByYear[ACCOUNT_CREATION_QUALIFYING_TAX_YEAR]
      ? qualificationQuestionsByYear[ACCOUNT_CREATION_QUALIFYING_TAX_YEAR][
          GraphCmsQuestionIdEnum.NEXT_YEAR_EXPECTED_EMPLOYEE_COUNT
        ] * 1000
      : 0) + 5500
  ).toLocaleString()}`;

export const getCurrentYear = (): number => {
  return new Date().getFullYear();
};
