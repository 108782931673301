import React from 'react';
import { Content, Icon, IconEnum, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    '&:hover': {
      cursor: 'pointer',
    },
    maxWidth: '80px',
  },
}));

interface BackButtonProps {
  redirect: () => void;
}
export const BackButton = (props: BackButtonProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container} onClick={props.redirect}>
      <Content flex flexDirection={'row'} paddingBottom={16}>
        <Icon name={IconEnum.long_left_arrow} />
        <Text size={15}>Back</Text>
      </Content>
    </div>
  );
};
