import { ProgramData, ProgramName } from 'lib/interfaces';
import React, { ReactFragment } from 'react';
import { Flex, Link, Text, Toggle } from 'component-library';
import { ProgramNameEnum } from 'lib/constants';

interface OptOutCardProps {
  program: ProgramData;
  isOptedIn: boolean;
  toggleOptIn: (id: number) => void;
}

type ProgramConfig = {
  title: string;
  description: ReactFragment;
  learnMoreLink?: string;
};

const ProgramConfigs: Partial<Record<ProgramName, ProgramConfig>> = {
  [ProgramNameEnum.FED_RD_TAX]: {
    title: 'Federal R&D Credit',
    description: (
      <>
        You earned this credit by investing in R&D in the U.S.
        <br />
        It will reduce or refund your federal payroll taxes each quarter.
      </>
    ),
    learnMoreLink:
      'https://mainstreet1.my.site.com/help/s/article/Final-Steps-To-Claim-the-R-D-Tax-Credit-Toward-Payroll#Timeline',
  },
  [ProgramNameEnum.STATE_RD_AZ]: {
    title: 'Arizona Research Credit',
    description: (
      <>
        You earned this credit by investing in R&D in the state of Arizona.
        <br />
        It will reduce the Arizona income taxes you owe this year, and/or in
        future years.
      </>
    ),
    learnMoreLink:
      'https://mainstreet1.my.site.com/help/s/article/Understanding-the-Arizona-R-D-Tax-Credit-Qualification-and-Value',
  },
  [ProgramNameEnum.STATE_RD_CA]: {
    title: 'California Research Credit',
    description: (
      <>
        You earned this credit by investing in R&D in the state of California.
        <br />
        It will reduce the California income taxes you owe this year, and/or in
        future years.
      </>
    ),
    learnMoreLink:
      'https://mainstreet1.my.site.com/help/s/article/Understanding-the-California-Research-Credit-Qualification-and-Value',
  },
  [ProgramNameEnum.STATE_RD_GA]: {
    title: 'Georgia Research Credit',
    description: (
      <>
        You earned this credit by investing in R&D in the state of Georgia.
        <br />
        You can choose to reduce either your Georgia payroll taxes or your
        Georgia income taxes with this credit.
      </>
    ),
    learnMoreLink:
      'https://mainstreet1.my.site.com/help/s/article/Understanding-the-Georgia-R-D-Tax-Credit-Qualification-and-Value',
  },
  [ProgramNameEnum.STATE_RD_MA]: {
    title: 'Massachusetts Research Credit',
    description: (
      <>
        You earned this credit by investing in R&D in the state of
        Massachusetts.
        <br />
        It will reduce the Massachusetts income taxes you owe this year, and/or
        in future years.
      </>
    ),
    learnMoreLink:
      'https://mainstreet1.my.site.com/help/s/article/Understanding-the-Massachusetts-R-D-Tax-Credit-Qualification-and-Value',
  },
  [ProgramNameEnum.FED_RETIREMENT_CREDIT]: {
    title: 'Retirement Plan Credit',
    description: (
      <>
        You earned this credit by offering a retirement plan to your employees.
        <br />
        It will reduce the federal income taxes you owe this year, and/or in
        future years.
      </>
    ),
    learnMoreLink:
      'https://mainstreet1.my.site.com/help/s/article/Which-businesses-are-eligible-for-the-Retirement-Plan-Startup-Costs-Tax-CreditWhat-businesses-are-eligible-for-the-Retirement-Plan-Startup-Costs-Tax-Credit',
  },
  [ProgramNameEnum.FED_EMPLOYER_HEALTHCARE]: {
    title: 'Small Business Healthcare Credit',
    description: (
      <>
        You earned this credit by covering at least 50% of your employees
        healthcare insurance premiums.
        <br />
        It will reduce the federal income taxes you owe this year, and/or in
        future years.
      </>
    ),
    learnMoreLink:
      'https://mainstreet1.my.site.com/help/s/article/What-is-the-Small-Business-Health-Care-Tax-Credit',
  },
  [ProgramNameEnum.FED_DISABLED_ACCESS]: {
    title: 'Disabled Access Credit',
    description: (
      <>
        You earned this credit by investing in making your business more
        accessible.
        <br />
        It will reduce the federal income taxes you owe this year, and/or in
        future years.
      </>
    ),
    learnMoreLink:
      'https://mainstreet1.my.site.com/help/s/article/What-is-the-Disabled-Access-Tax-Credit',
  },
};

export const OptOutCard = ({
  program,
  isOptedIn,
  toggleOptIn,
}: OptOutCardProps) => {
  const programConfig = ProgramConfigs[program.name];
  return programConfig ? (
    <>
      <Flex direction='column' padding={[12, 0, 12, 0]}>
        <Flex direction='row' justifyContent='space-between'>
          <Text size={15} paddingBottom={8}>
            {programConfig.title}
          </Text>
          <Toggle
            checked={isOptedIn}
            onChange={() => toggleOptIn(program.id)}
          />
        </Flex>
        <Text size={13} paddingBottom={8}>
          {programConfig.description}
        </Text>
        {programConfig.learnMoreLink && (
          <Link
            size={13}
            external
            href={programConfig.learnMoreLink}
            target='_blank'
          >
            Learn More
          </Link>
        )}
      </Flex>
    </>
  ) : null;
};
