import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  EXPENSE_CLASSIFICATION,
  Page,
  ProgramSubStageEnum,
} from 'lib/constants';
import { useEffectOnce } from 'lib/helpers';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { LoadingPage } from 'pages/LoadingPage';
import { useSkipPayrollStep } from 'lib/useSkipPayrollStep';

const subStageUrls: { [key in ProgramSubStageEnum]?: string } = {
  [ProgramSubStageEnum.EXPENSE_CLASSIFICATION_OVERVIEW]:
    Page.expenseClassificationOverviewPage,
  [ProgramSubStageEnum.EXPENSE_CLASSIFICATION_COMPANY_DETAILS]:
    Page.expenseClassificationCompanyDetails,
  [ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EXPENSES]:
    Page.expenseClassificationSuppliesServices,
  [ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EMPLOYEES]:
    Page.expenseClassificationEmployees,
};

interface ExpenseClassificationRedirectProps {
  programId: number;
}

export const ExpenseClassificationRedirect = ({
  programId,
}: ExpenseClassificationRedirectProps) => {
  const { client } = useContext(Auth0FeatureContext);
  const [subStage, setSubStage] = useState<ProgramSubStageEnum | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { importPayroll, shouldSkipPayroll } = useSkipPayrollStep(programId);

  const path = `/${Page.taxCredits}/${EXPENSE_CLASSIFICATION}/${programId}`;

  useEffectOnce(async () => {
    const programData = await client.GetProgram(programId);
    if (programData.data) {
      if (
        programData.data.program.subStage ===
        ProgramSubStageEnum.EXPENSE_CLASSIFICATION_OVERVIEW
      ) {
        await importPayroll();
      } else {
        setSubStage(programData.data.program.subStage);
      }
    } else {
      setError(true);
    }

    setLoading(false);
  });

  useEffect(() => {
    if (shouldSkipPayroll) {
      setSubStage(ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EXPENSES);
    } else {
      setSubStage(ProgramSubStageEnum.EXPENSE_CLASSIFICATION_OVERVIEW);
    }
  }, [shouldSkipPayroll]);

  if (loading) {
    return <LoadingPage />;
  }

  if (!error && subStage && subStageUrls[subStage]) {
    return <Redirect to={`${path}/${subStageUrls[subStage]}`} />;
  }

  return <Redirect to={`/${Page.taxCredits}`} />;
};
