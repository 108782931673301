import { BaseAPI } from 'api/BaseAPI';
import { API, DASHBOARD, MODULES } from 'lib/constants';
import { ToDoCardEntityData } from './entities/ToDoCardEntity';
import { ResourceCardEntityData } from './entities/ResourceCardEntity';

export class DashboardAPI extends BaseAPI {
  public async GetAllModules() {
    return await this.GET<{
      toDoCards: ToDoCardEntityData[];
      resourceCards: ResourceCardEntityData[];
    }>(`/${API}/${DASHBOARD}/${MODULES}`);
  }

  public async DismissToDoCard(toDoCardId: string) {
    return await this.PUT(
      `/${API}/${DASHBOARD}/${MODULES}/toDoCards/${toDoCardId}/dismiss`,
    );
  }
}
