import DocumentsFolder from '../../../../components/icons/DocumentsFolder';
import { Text } from 'component-library';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export interface EmptyDocumentListProps {
  loading: boolean | undefined;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  iconSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: spacing(5, 0, 5),
  },
}));

export const EmptyDocumentList = ({ loading }: EmptyDocumentListProps) => {
  const classes = useStyles();

  if (!loading) {
    return (
      <div className={classes.iconSection}>
        <DocumentsFolder />
        <Text size={15} tag='p' variant='medium'>
          There are no documents uploaded yet.
        </Text>
      </div>
    );
  } else {
    return <></>;
  }
};
