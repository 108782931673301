import React, { useState } from 'react';
import { Flex, Spinner } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { useEffectOnce } from 'component-library/_helpers/use-utils';
import { useFeatureFlags, useOnboardingStores } from 'stores/useStores';
import { WelcomePagePartner } from 'pages/signup/WelcomePagePartner';
import {
  GenericQuickbooksLandingPage,
  PartnerLandingPage,
  RedAntlerQuickBooksLandingPage,
} from './components';

const useStyles = makeStyles(() => ({
  '@global': {
    '.embeddedServiceHelpButton': {
      display: 'none !important',
    },
  },
  loading: {
    height: '100%',
  },
}));

interface WelcomePartnerPageProps {
  partner: string;
}

export const WelcomePartnerPage: React.FC<WelcomePartnerPageProps> = observer(
  ({ partner }) => {
    const classes = useStyles();
    const [pageLoad, setPageLoad] = useState<boolean>(true);
    const { initialAccountSetup } = useOnboardingStores();
    const featureFlag = useFeatureFlags();
    const location = useLocation();

    // route query params
    const queryParams: URLSearchParams = new URLSearchParams(location.search);
    const code = queryParams.get('code') || '';
    const partnerCompany = partner.replace(/\b\w/g, (char) =>
      char.toUpperCase(),
    );

    useEffectOnce(async () => {
      await initialAccountSetup.getPartnerReferralInfo(partner);
      setPageLoad(false);
    });

    // return red atler design partner landing page
    const showRedAntlerHygraphLandingPage =
      featureFlag.showHygraphPartnerLandingPage;
    // return red antler designed quickbooks landing page
    const showRedAntlerQuickbooksLandingPage =
      featureFlag.showRedAntlerQBLandingPage &&
      partner === 'quickbooks' &&
      featureFlag.showPartnerCodeWelcomePage;
    // return generic quickbooks landing page
    const showGenericQuickbooksLandingPage =
      featureFlag.showPartnerCodeWelcomePage && partner === 'quickbooks';

    if (pageLoad) {
      return (
        <Flex
          justifyContent='center'
          padding={16}
          alignItems='center'
          className={classes.loading}
        >
          <Spinner />
        </Flex>
      );
    }

    if (showRedAntlerQuickbooksLandingPage) {
      return (
        <RedAntlerQuickBooksLandingPage
          queryParams={queryParams}
          code={code}
          partner={partner}
        />
      );
    } else if (showGenericQuickbooksLandingPage) {
      return (
        <GenericQuickbooksLandingPage
          queryParams={queryParams}
          code={code}
          partner={partner}
          partnerCompany={partnerCompany}
        />
      );
    } else if (
      showRedAntlerHygraphLandingPage &&
      initialAccountSetup.referralInfo
    ) {
      return (
        <PartnerLandingPage
          partner={partner}
          referralInfo={initialAccountSetup.referralInfo}
        />
      );
    }

    // return existing old generic partner landing page
    return <WelcomePagePartner partner={partner} />;
  },
);
