import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  QuarterFilingForm941Module,
  QuarterlyRedemptionPageContainer,
} from './components';
import { useTaxCreditsStores } from 'stores/useStores';
import { useEffectOnce } from 'lib/helpers';
import { Animate, Flex, Spinner } from 'component-library';
import { Redirect } from 'react-router-dom';
import { Page } from 'lib/constants';

interface MissedQuarterlyRedemptionPageProps {
  programId: number;
}

export const MissedQuarterlyRedemptionPage = observer(
  ({ programId }: MissedQuarterlyRedemptionPageProps) => {
    const [hasEnteredAllTaxValues, setHasEnteredAllTaxValues] =
      useState<boolean>(false);
    const { form8974, redemption } = useTaxCreditsStores();

    useEffectOnce(async () => {
      // check if documents have already been generated
      await redemption.checkExistingDocumentURL();
      await form8974.getRDCreditSummary();
    });

    const handleEnterTaxValuesOnContinue = async () => {
      if (hasEnteredAllTaxValues) {
        await redemption.handleEnterTaxValuesOnContinue(programId);
      }
    };

    // redirect back to tax credit page
    if (
      redemption.quarterlyFor8974DocumentUrl.missedQuarters.length === 0 &&
      !redemption.missedFilings
    ) {
      return <Redirect to={`/${Page.taxCredits}`} />;
    }

    return (
      <QuarterlyRedemptionPageContainer
        title='Provide payroll tax information'
        infoTitle='Input payroll tax liability values'
        infoContent='We will use these values to generate Form 8974, which you must file for each quarter in order to redeem your R&D credits. If you do not file these forms, you will not receive refunds from the IRS.'
        onContinue={() => {
          redemption.setLoadingOnContinue(true);
          handleEnterTaxValuesOnContinue();
        }}
        onEnterMissedDeadlinePage
        hasEnteredAllTaxValues={hasEnteredAllTaxValues}
        setHasEnteredAllTaxValues={setHasEnteredAllTaxValues}
      >
        {redemption.quarterlyFor8974DocumentUrl.missedQuarters.length > 0 ? (
          <Redirect
            to={`/${Page.taxCredits}/${programId}/${Page.missedQuarterlyRedemptionPage}/${Page.quarterlyRedemptionDownloadPage}`}
          />
        ) : (
          <>
            {redemption.missedFilings && redemption.missedFilings.length > 0 ? (
              <Animate enter={'fade-in'}>
                <QuarterFilingForm941Module
                  missedQuarterlyFiling={redemption.missedFilings}
                  currentQuarterFiling={redemption.currentFiling ?? undefined}
                  payrollProvider={form8974.payrollProvider}
                />
              </Animate>
            ) : (
              <Flex padding={24} justifyContent='center'>
                <Spinner size='small' />
              </Flex>
            )}
          </>
        )}
      </QuarterlyRedemptionPageContainer>
    );
  },
);
