import * as React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useCommonStores, useCompany } from 'stores/useStores';
import ChatButton from './ChatButton';
import { ChatWindow } from './ChatWindow';

const useStyles = makeStyles(() => ({
  '@global': {
    '.embeddedServiceHelpButton': {
      display: (chatbotEnabled: boolean) => (chatbotEnabled ? 'none' : 'block'),
    },
  },
}));

export const ChatBot = observer(() => {
  const { chatbot } = useCommonStores();
  const { company, store } = useCompany();
  useStyles(!chatbot.hideChatBot);

  useEffect(() => {
    if (!store.isFirstLoadingCompany) {
      chatbot.getCompanyChatBotSettings();
    }
  }, [company?.id]);

  // Do not show the chatbot announcement modal for now
  // if (chatbot.showFirstTimeUserModal && !chatbot.hideChatBot) {
  //   return (
  //     <Modal showModal maxWidth={544}>
  //       <ChatBotNewUser />
  //     </Modal>
  //   );
  // }

  if (chatbot.hideChatBot) {
    return <></>;
  }

  if (chatbot.chatWindowOpen) {
    return <ChatWindow />;
  }

  return <ChatButton />;
});
