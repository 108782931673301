import React, { useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Color,
  Divider,
  Expandable,
  Flex,
  Heading,
  Icon,
  IconEnum,
  Link,
  Text,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useCommonStores, useCompany } from 'stores/useStores';
import { IncludedServicesContent } from '../order-form/components';
import { useEffectOnce } from '../../../../lib/helpers';
import { EmptyClient } from 'services/ServerClient';

const useStyles = makeStyles(({ breakpoints }) => ({
  '@global': {
    [breakpoints.down('xs')]: {
      '.embeddedServiceHelpButton': {
        display: 'none',
      },
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 80px',
    maxWidth: '1050px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: '100vh',
  },
  mainContent: {
    maxWidth: '1100px',
    width: '100%',
    margin: '0 auto',
    padding: '48px 80px',
    boxSizing: 'border-box',
    position: 'relative',
  },
  mslogo: {
    maxWidth: '106px',
    paddingRight: '16px',
    borderRight: `1px solid ${Color.neutral._20}`,
  },
  header: {
    borderBottom: `1px solid ${Color.neutral._20}`,
  },
  amountDueContainer: {
    border: `1px solid ${Color.blue._50}`,
    background: Color.blue._20,
    borderRadius: '8px',
  },
  amountDue: {
    borderBottom: `1px dashed ${Color.blue._50}`,
    marginBottom: '8px',
  },
}));

export const Contract = observer(() => {
  const classes = useStyles();
  const [servicesOpen, setServicesOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const { app } = useCommonStores();
  const { company } = useCompany();

  useEffectOnce(
    async () =>
      await EmptyClient.CustomerIoTrackEvent(
        'viewed_unified_mainstreet_terms_modal',
        company.id,
      ),
  );

  return (
    <Flex alignItems='center' className={classes.container}>
      <HeaderBar />
      <Flex
        className={classes.mainContent}
        direction='column'
        data-testid={'order-form'}
      >
        <Heading
          size={32}
          tag='h2'
          text='Review Tax Credits'
          marginBottom={12}
        />
        <Text
          size={15}
          text='Give your company the MainStreet advantage!'
          paddingBottom={24}
        />
        <Flex
          className={classes.amountDueContainer}
          direction='column'
          padding={24}
        >
          <Flex
            className={classes.amountDue}
            justifyContent='space-between'
            alignItems='start'
          >
            <Text size={15} variant='medium' text='Amount due today' />
            <Text size={23} variant='medium' text='$99.45' paddingBottom={8} />
          </Flex>
          <Text size={13} tag='span'>
            You will not be charged yet. We will only charge you when your final
            credits have been delivered, and a{' '}
            <Text size={13} variant='bold' text='20% fee' tag='span' /> will be
            applied to the total credits delivered. See{' '}
            <Link
              external
              href='https://www.mainstreet.com/faq'
              text='terms and conditions'
              omitIcon
            />
            .
          </Text>
        </Flex>
        <Card>
          <div onClick={() => setServicesOpen(!servicesOpen)}>
            <Flex padding={24} justifyContent='space-between'>
              <Text text='Included services' size={15} variant='medium' />
              <Icon
                name={
                  servicesOpen ? IconEnum.chevron_up : IconEnum.chevron_down
                }
                onClick={() => setServicesOpen(!servicesOpen)}
              />
            </Flex>
          </div>
          <Expandable expand={servicesOpen}>
            <Flex padding={[0, 24, 24, 24]}>
              <IncludedServicesContent />
            </Flex>
          </Expandable>
        </Card>
        <Divider />
        <Flex padding={[24, 0]} justifyContent='space-between'>
          <Checkbox
            dataTestId='confirmation-checkbox'
            onChange={() => setConfirmed(!confirmed)}
            options={[
              {
                checked: confirmed,
                text: '',
                value: 'confirmed',
              },
            ]}
          />
          <Text size={13} tag='span'>
            {`By accepting this order, ${company.name} is entering into an agreement with MainStreet Work, Inc governed by these `}
            <Link
              external
              href='https://www.mainstreet.com/faq'
              text='terms'
              omitIcon
            />
            {`, and you confirm you are authorized to do so on behalf of ${company.name}.`}
          </Text>
        </Flex>
        <Flex padding={[12, 0, 0, 0]}>
          <Button
            label='Continue'
            disabled={!confirmed}
            onClick={async () => {
              await EmptyClient.CustomerIoTrackEvent(
                'accepted_unified_mainstreet_terms',
                company.id,
              );
              app.history.push(`/tax-credits`);
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
});

const HeaderBar = () => {
  const classes = useStyles();
  return (
    <Flex className={classes.header} padding={24} gap={16}>
      <img
        className={classes.mslogo}
        src={`${process.env.PUBLIC_URL}/images/logo/mst-logo-green.svg`}
        alt='MainStreet watermark'
      />
      <Text
        variant='medium'
        size={18}
        text='Order Form'
        color={Color.neutral._80}
      />
    </Flex>
  );
};
