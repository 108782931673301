import React from 'react';
import { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import {
  Text,
  TextField,
  Content,
  Color,
  Alert,
  Card,
  CardFooter,
  IconEnum,
  Button,
  Icon,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import {
  CurrencyStringToCents,
  CentsToDisplayStringNoSymbol,
  CentsToDisplayString,
} from 'lib/helpers';
import { ExpectedPaymentDirection } from 'lib/interfaces';
import { BalanceInformation } from '../AccountBalanceCard';

const useStyles = makeStyles(() => ({
  instructionsContainer: {
    paddingTop: '160px',
  },
  instructions: {
    display: 'grid',
    border: '1px solid #CFD4D7',
    background: '#FFFDF2',
    marginBottom: '12px',
  },
  instructionsInner: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    gridRowGap: '12px',
    padding: '10px',
    height: '45px',
  },
  horizontalLine: {
    width: '100%',
    height: '1px',
    backgroundColor: Color.neutral._20,
  },
  strong: {
    fontWeight: 500,
  },
  sidebarContentContainer: {
    position: 'relative',
  },
  wireAmountContainer: {
    position: 'absolute',
    top: '400',
    zIndex: 100,
    width: '100%',
  },
  wireAmount: {
    width: '100%',
  },
  wireAmountWarning: {
    width: '100%',
    marginTop: '10px',
  },
  wireAmountError: {
    backgroundColor: 'transparent !important',
  },
  reviewCard: {
    backgroundColor: Color.neutral._light_20,
  },
  transferTableCell: {
    height: '4rem',
  },
  balanceText: {
    width: '9rem',
  },
  static: {
    backgroundColor: Color.neutral._light_20,
  },
  errorState: {
    color: Color.semantic.$error50,
  },
}));

interface ManualTransferProps {
  balanceInfo?: BalanceInformation;
  companyId: number;
  direction: ExpectedPaymentDirection;
  onPaymentConfirmation: (amountCents: number) => void;
  setCurrentAmountEntered: (amountCents: number | undefined) => void;
  handleGoBack: (() => void) | null;
}

export const ManualTransfer = ({
  balanceInfo,
  companyId,
  direction,
  onPaymentConfirmation,
  setCurrentAmountEntered,
  handleGoBack,
}: ManualTransferProps) => {
  const classes = useStyles();

  interface ManualTransfer {
    account_number: string;
    routing_number: string;
    reference_code: string;
    depository_bank_name: string;
    credit_to: string;
    bank_phone_number: string;
    bank_address: string;
    beneficiary_address: string;
  }

  const [editMode, setEditMode] = useState<boolean>(true);
  const [reviewMode, setReviewMode] = useState<boolean>(false);
  const [wireAmountCents, setWireAmountCents] = useState<string>(
    CentsToDisplayStringNoSymbol(0),
  );
  const [wireInstructions] = useState<ManualTransfer>({
    account_number: '6777648148',
    routing_number: '053112929',
    reference_code: `${companyId}-PN1`,
    depository_bank_name: 'Blue Ridge Bank, N.A.',
    credit_to: 'MainStreet Yield LLC',
    bank_phone_number: '',
    bank_address: '1 East Market Street, Martinsville, VA 24112',
    beneficiary_address: '320 N 3700 W, Unit 22690, Salt Lake City, UT 84122',
  });

  const instructionsLabels: any = {
    depository_bank_name: 'Bank Name',
    account_number: 'Account Number',
    routing_number: 'Routing Number',
    bank_address: 'Bank Address',
    credit_to: 'Credit To',
    beneficiary_address: 'Beneficiary Address',
    reference_code: 'Reference',
  };

  const wireTransitions = useTransition(!editMode, {
    from: { opacity: 0, transform: 'translateY(5%)' },
    enter: { opacity: 1, transform: 'translateY(0%)' },
    leave: { opacity: 0, transform: 'translateY(5%)' },
  });

  const amountTransitions = useTransition(editMode, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const reviewTransitions = useTransition(reviewMode, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  if (!balanceInfo) {
    return null;
  }

  const isCreditTransferInTransaction =
    direction === ExpectedPaymentDirection.CREDIT;
  const isDebitTransferOutTransaction =
    direction === ExpectedPaymentDirection.DEBIT;

  const transferInMessage = isCreditTransferInTransaction && (
    <Text
      text={
        "Enter the amount you'd like to transfer into your account so we know to expect this value"
      }
    />
  );
  const totalBalance =
    balanceInfo.principalBalanceCents + balanceInfo.accruedInterestCents;

  const remainingPendingBalance =
    balanceInfo.remainingPendingBalanceCents ?? totalBalance;
  const currentWithdrawalAmount = isNaN(CurrencyStringToCents(wireAmountCents))
    ? 0
    : CurrencyStringToCents(wireAmountCents);
  const remainingBalanceAfterWithdrawal =
    (remainingPendingBalance ?? totalBalance) - currentWithdrawalAmount;

  const pendingTransferMessage = isDebitTransferOutTransaction && (
    <Text color={Color.neutral._80} size={15}>
      {`Remaining ${
        remainingPendingBalance && remainingPendingBalance > 0
          ? 'pending balance'
          : 'balance'
      }: ${CentsToDisplayString(remainingBalanceAfterWithdrawal, 2, 2)}`}
    </Text>
  );

  const transferAmountTooHigh =
    isDebitTransferOutTransaction &&
    CurrencyStringToCents(wireAmountCents) > remainingPendingBalance;
  const confirmDisabled =
    !wireAmountCents ||
    CurrencyStringToCents(wireAmountCents) <= 0 ||
    transferAmountTooHigh;

  const transferAmountUnderMinBalance =
    balanceInfo.minimumAllowedBalance !== undefined &&
    isDebitTransferOutTransaction &&
    remainingPendingBalance - CurrencyStringToCents(wireAmountCents) <
      balanceInfo.minimumAllowedBalance;
  const showUnderMinBalanceWarning =
    transferAmountUnderMinBalance && !transferAmountTooHigh;

  return (
    <div>
      <Content>
        {amountTransitions((props, item) => {
          return item ? (
            <animated.div style={props}>
              <div className={classes.sidebarContentContainer}>
                {reviewTransitions((props, item) => {
                  return item ? (
                    <animated.div style={props}>
                      <div className={classes.wireAmountContainer}>
                        <Content paddingLeftRight={24}>
                          <Card className={classes.reviewCard}>
                            <Content
                              paddingLeftRight={24}
                              paddingTopBottom={16}
                              flex
                              justifyContent='space-between'
                            >
                              <div>
                                <Text text='Amount' variant='medium' />
                                <Text text={wireAmountCents} />
                              </div>
                              <Button
                                label='Edit'
                                onClick={() => {
                                  setCurrentAmountEntered(undefined);
                                  setReviewMode(false);
                                }}
                                variant='tertiary'
                              />
                            </Content>
                          </Card>
                          <Text
                            text='Transfer summary'
                            variant='medium'
                            paddingBottom={8}
                          />
                          <Card noMargin>
                            <Content
                              className={classes.transferTableCell}
                              paddingLeftRight={16}
                              paddingTopBottom={0}
                              flex
                              justifyContent='space-between'
                              alignItems='center'
                            >
                              <Text text='Transfer amount' />
                              <Text variant='medium' text={wireAmountCents} />
                            </Content>
                          </Card>
                          <Card noMargin>
                            <Content
                              className={classes.transferTableCell}
                              paddingLeftRight={16}
                              paddingTopBottom={0}
                              flex
                              justifyContent='space-between'
                              alignItems='center'
                            >
                              <Text
                                className={classes.balanceText}
                                text='Account balance after transfer'
                              />
                              <Text
                                text={CentsToDisplayString(
                                  Number(remainingBalanceAfterWithdrawal),
                                  2,
                                  2,
                                )}
                              />
                            </Content>
                          </Card>
                        </Content>
                      </div>
                    </animated.div>
                  ) : (
                    <animated.div style={props}>
                      <div className={classes.wireAmountContainer}>
                        <Content paddingLeftRight={24}>
                          <Card className={classes.wireAmount}>
                            <Content
                              paddingTopBottom={24}
                              paddingLeftRight={24}
                            >
                              <Text text='Amount' variant='medium' />
                              {transferInMessage}

                              <TextField
                                dataTestId='amount-field'
                                className={
                                  transferAmountTooHigh
                                    ? classes.errorState
                                    : undefined
                                }
                                value={wireAmountCents}
                                onChange={(e) =>
                                  setWireAmountCents(e.target.value)
                                }
                                currencyFormat
                                error={transferAmountTooHigh}
                                helperText={
                                  (transferAmountTooHigh && (
                                    <Content flex alignItems='center' gap={0}>
                                      <Icon
                                        size={18}
                                        color={Color.semantic.$error50}
                                        name={IconEnum.info_circle}
                                      />
                                      <Text
                                        className={classes.errorState}
                                        size={15}
                                        text='Error: Amount exceeds account balance'
                                      />
                                    </Content>
                                  )) ||
                                  pendingTransferMessage
                                }
                              />
                            </Content>
                            <CardFooter
                              primaryCtaLabel={
                                isCreditTransferInTransaction
                                  ? 'Confirm amount'
                                  : 'Review transfer'
                              }
                              primaryCtaDisabled={confirmDisabled}
                              primaryOnClick={() => {
                                const dollarFloat = parseFloat(
                                  wireAmountCents
                                    .replace(/,/g, '')
                                    .replace('$', ''),
                                );
                                const cents = parseFloat(
                                  (dollarFloat * 100).toFixed(0),
                                );
                                if (isDebitTransferOutTransaction) {
                                  setWireAmountCents(
                                    CentsToDisplayString(cents, 2, 2),
                                  );
                                  setCurrentAmountEntered(cents);
                                  setReviewMode(true);
                                } else {
                                  setWireAmountCents(
                                    CentsToDisplayStringNoSymbol(cents),
                                  );
                                  setEditMode(false);
                                  onPaymentConfirmation(cents);
                                }
                              }}
                              secondaryCtaLabel={
                                handleGoBack ? 'Back' : undefined
                              }
                              secondaryOnClick={
                                handleGoBack ? () => handleGoBack() : undefined
                              }
                            />
                          </Card>
                        </Content>
                        {showUnderMinBalanceWarning && (
                          <Alert
                            className={classes.wireAmountWarning}
                            type='warning'
                            variant='in_card'
                            inCardBorder='top-bottom'
                            icon={IconEnum.exclamation_triangle}
                            text='This transfer will reduce your balance below the minimum investment value of $1 million dollars. If you do not maintain the minimum balance, we reserve the right to close this account and return your funds.'
                          />
                        )}
                      </div>
                    </animated.div>
                  );
                })}
              </div>
            </animated.div>
          ) : (
            <animated.div style={props}>
              <div className={classes.wireAmountContainer}>
                <Card className={`${classes.wireAmount} ${classes.static}`}>
                  <Content
                    flex
                    justifyContent='space-between'
                    paddingTopBottom={24}
                    paddingLeftRight={24}
                  >
                    <div>
                      <Text text='Amount' variant='medium' />
                      <Text
                        text={CentsToDisplayString(
                          CurrencyStringToCents(wireAmountCents),
                          2,
                          2,
                        )}
                      />
                    </div>
                  </Content>
                </Card>
              </div>
            </animated.div>
          );
        })}
      </Content>
      {wireTransitions((props, item) => {
        return (
          item && (
            <animated.div style={props}>
              <Content className={classes.instructionsContainer}>
                <Alert
                  text='To transfer funds into your account, please complete a wire transfer through your bank with the information provided.'
                  variant='in_card'
                  inCardBorder='top-bottom'
                />
                <Content paddingLeftRight={24} paddingTopBottom={24}>
                  <Text
                    text='Bank wire information'
                    paddingBottom={16}
                    variant='medium'
                    size={15}
                  />
                  <div className={classes.instructions}>
                    {Object.keys(instructionsLabels).map((key, index) => {
                      return (
                        <div key={index}>
                          <Content
                            flex
                            alignItems='center'
                            justifyContent='space-between'
                            className={classes.instructionsInner}
                          >
                            <Text size={15}>
                              <>{instructionsLabels[key]}:</>
                            </Text>
                            <Text size={15}>
                              <>
                                <strong className={classes.strong}>
                                  {
                                    wireInstructions[
                                      key as keyof ManualTransfer
                                    ]
                                  }
                                </strong>
                              </>
                            </Text>
                          </Content>
                          <div className={classes.horizontalLine} />
                        </div>
                      );
                    })}
                  </div>
                  <Text
                    variant='regular'
                    text='This is the only place these wire instructions will appear. They will never be emailed or sent to you outside of your dashboard.'
                    size={15}
                    color={Color.neutral._80}
                  />
                </Content>
              </Content>
            </animated.div>
          )
        );
      })}
    </div>
  );
};
