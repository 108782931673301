import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Animate, Grid, Heading, Link, Text } from 'component-library';
import { CompanyContext } from '../../CompanyRequired';
import { TaxCreditsProgramStepper } from './stepper/TaxCreditProgramStepper';
import { ProgramData } from '../../../lib/interfaces';
import { TaxCreditsSideDrawer } from './sideDrawer/TaxCreditsSideDrawer';
import {
  Page,
  PayrollTierEnum,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationStatusEnum,
} from '../../../lib/constants';
import { EmptyTaxCreditsProgramStepper } from './stepper/EmptyTaxProgramStepper';
import {
  useFeatureFlags,
  useTaxCreditsStores,
} from '../../../stores/useStores';
import { observer } from 'mobx-react';
import { useEffectOnce } from '../../../lib/helpers';
import { Loading } from '../../../components/util/Loading';
import { CreditBalanceCard } from './form8974/CreditBalanceCard';
import ClipboardAndCalculator from '../../../components/icons/ClipboardAndCalculator';
import { KeyDates } from './KeyDates';
import { MissedRedemptionModule } from 'products/tax-credits/features/redemption/components';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1250px',
    padding: '40px 40px 80px',
  },
  stepContainer: {
    marginBottom: '36px',
  },
  minimalMargin: {
    margin: '0 0 32px',
  },
  iconSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: spacing(3, 3, 3, 3),
    background: '#F9FAF7',
    border: '1px solid #F0F0F0',
    borderRadius: '4px',
  },
  iconText: {
    margin: spacing(3, 0, 0, 0),
  },
}));

const isFedProgramsComplete = (federalRDPrograms: ProgramData[]) => {
  if (federalRDPrograms.length === 0) return false;

  for (const program of federalRDPrograms) {
    if (
      program.stage !== ProgramStageEnum.FINISHED ||
      (program.stage === ProgramStageEnum.FINISHED &&
        program.subStage !== ProgramSubStageEnum.REDEEMING)
    ) {
      return false;
    }
  }
  return true;
};

export const TaxCreditsPage = observer(() => {
  const classes = useStyles();
  const { company } = useContext(CompanyContext);
  const { form8974, creditEstimates, redemption } = useTaxCreditsStores();
  const programs = form8974.programs;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const featureFlags = useFeatureFlags();

  // We create one stepper per active Federal R&D Program
  const federalRDPrograms = programs
    .filter(
      (program) =>
        program.name === ProgramNameEnum.FED_RD_TAX && isProgramActive(program),
    )
    .sort((a, b) => b.taxYear - a.taxYear);

  const disqualifiedFederalRDPrograms = programs.filter(
    (program) =>
      program.name === ProgramNameEnum.FED_RD_TAX && !isProgramActive(program),
  );

  const allFedProgramsComplete = isFedProgramsComplete(federalRDPrograms);
  // There are DQ'd Fed R&D programs and no active ones
  const allFedProgramsDisqualified =
    disqualifiedFederalRDPrograms?.length && !federalRDPrograms?.length;

  // Extract all program names, organized by year, for chipTitles in each stepper
  const programsByYear: {
    [key: string]: ProgramData[];
  } = {};
  programs.forEach((program) => {
    const programsInYear = programsByYear[program.taxYear] || [];
    programsInYear.push(program);
    programsByYear[program.taxYear] = programsInYear;
  });

  useEffectOnce(async () => {
    await form8974
      .refreshPrograms()
      .then(() => form8974.getRDCreditSummary())
      .then(() => setIsLoading(false));
  });

  useEffect(() => {
    if (federalRDPrograms.length > 0) {
      federalRDPrograms.forEach((program) => {
        const { id, stage, taxYear } = program;
        if (
          stage !== ProgramStageEnum.COMPLETED &&
          creditEstimates.currentAmountByYear.get(taxYear) === undefined
        ) {
          if (
            stage === ProgramStageEnum.EXPENSE_CLASSIFICATION ||
            stage === ProgramStageEnum.MS_REVIEW
          ) {
            creditEstimates.getCreditEstimates(id);
          }

          const areTaxFormsPrepared =
            stage === ProgramStageEnum.CLIENT_REVIEW ||
            stage === ProgramStageEnum.FINISHED;
          if (areTaxFormsPrepared) {
            creditEstimates.getFinalCreditAmount(taxYear, id);
          }
        }
      });
    }
  }, [creditEstimates, federalRDPrograms]);

  const showCreditBalanceCard =
    (form8974.payrollTier !== PayrollTierEnum.TIER_3 ||
      featureFlags.is8974Tier3Enabled) &&
    featureFlags.isTaxCreditsPageCreditBalanceCardEnabled &&
    form8974.balanceCardEnabled &&
    form8974.payrollTier !== PayrollTierEnum.INCOME_TIER;

  if (isLoading) {
    return <Loading loading={true} />;
  } else {
    return (
      <div className={classes.root} data-testid={'tax-credits-page'}>
        <Heading tag='h2' variant='regular'>
          R&D Tax Credits
        </Heading>

        {allFedProgramsDisqualified ? (
          <div className={classes.iconSection}>
            <ClipboardAndCalculator />
            <Text
              size={18}
              tag='p'
              variant='medium'
              className={classes.iconText}
              data-testid={'tax-credits-page-dq-apology'}
            >
              Unfortunately, we could not qualify your business for any R&D
              program at the moment.
            </Text>
          </div>
        ) : (
          <Grid columns={12} gap={24}>
            <Grid.Cell columns={8}>
              {redemption.showMissedDeadlineAlert &&
                featureFlags.showMissedRedemptionAlert && (
                  <MissedRedemptionModule />
                )}
              {allFedProgramsComplete ? (
                <>
                  <Text size={18} tag='p' variant='medium'>
                    Completed credits
                  </Text>
                  <Text size={15} tag='p' variant='regular' paddingBottom={16}>
                    Fully processed credits that make your total earned credits.
                  </Text>
                </>
              ) : (
                <>
                  <Text size={18} tag='p' variant='medium'>
                    Credits in progress
                  </Text>
                  <Text size={15} tag='p' variant='regular' paddingBottom={16}>
                    This page shows information regarding all of your R&D tax
                    credits. You will need to complete a year-end assessment
                    each year to receive your final credits.
                  </Text>
                </>
              )}

              <Text>
                <Link variant='medium' href={`/${Page.taxCredits}`}>
                  Review tax credit instructions
                </Link>
              </Text>

              {federalRDPrograms.map((program) => (
                <TaxCreditsProgramStepper
                  federalRDProgram={program}
                  programs={programsByYear[program.taxYear]}
                  key={`${program.taxYear}-${program.id}`}
                />
              ))}

              {federalRDPrograms.length === 0 && (
                <EmptyTaxCreditsProgramStepper programs={programs} />
              )}
            </Grid.Cell>
            <Grid.Cell columns={4}>
              {showCreditBalanceCard && (
                <Animate enter={'fade-in'} duration={2.0}>
                  <CreditBalanceCard className={classes.minimalMargin} />
                </Animate>
              )}
              {featureFlags.showKeyDatesModule && (
                <KeyDates
                  company={company}
                  federalRDPrograms={federalRDPrograms}
                  defaultExpand={true}
                />
              )}
            </Grid.Cell>

            <TaxCreditsSideDrawer />
          </Grid>
        )}
      </div>
    );
  }
});

/**
 A program is active as long as it is not disqualified. Even programs which are
 in the finished stage and have completed all required setup to generate 8974s/
 redeem credits are considered active for now.
*/
const isProgramActive = (program: ProgramData) => {
  return (
    program.stage !== ProgramStageEnum.DISQUALIFIED &&
    program.qualificationStatus !== QualificationStatusEnum.DISQUALIFIED
  );
};
