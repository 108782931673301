import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 500,
  },
  underline: {
    textDecoration: 'underline',
    '& li': { textDecoration: 'underline' },
  },
  italic: {
    fontStyle: 'italic',
  },
  table: {
    border: '1px solid black',

    '& td': { border: '1px solid black' },
    '& th': { border: '1px solid black', textDecoration: 'underline' },
  },
  policy: {
    padding: '40px',
    fontFamily: 'Balto Web, sans-serif',
  },
}));

export default function Privacy() {
  const classes = useStyles();

  return (
    <div className={classes.policy} id='policy'>
      <h2>MainStreet Privacy Policy</h2>

      <p className={classes.bold}>Effective date: June 2022</p>

      <div>
        <p>
          At MainStreet, we take your privacy seriously. Please read this
          Privacy Policy (this “Policy”) to learn how MainStreet Work, Inc. and
          its affiliates (collectively “MainStreet”) treat your personal data.{' '}
          <span className={classes.bold}>
            By using or accessing our Services in any manner, you acknowledge
            that you accept the practices and policies outlined below, and you
            hereby consent that we will collect, use and share your information
            as described in this Privacy Policy.
          </span>
        </p>

        <p>
          Remember that your use of MainStreet`s Services is at all times
          subject to our{' '}
          <a
            href='https://dashboard.mainstreet.com/terms-of-service?_gl=1*qm1k6h*_ga*MjEwMTAyMzI1Ni4xNjQyNTM0NTA5*_ga_53HLRXCXPG*MTY1MzMyNDU0MS4zNS4wLjE2NTMzMjQ1NDEuMA..&_ga=2.180653154.49633184.1653324542-2101023256.1642534509'
            target='_'
          >
            Terms of Service
          </a>
          , which incorporates this Policy. Any terms we use in this Policy
          without defining them have the definitions given to them in the Terms
          of Service.
        </p>

        <p>
          As noted in the Terms of Service, the Services enable customers of
          MainStreet to review and apply for tax credits, rebates and other
          incentives on their own behalf and on behalf of their contractors, and
          other affiliated individuals. You have been invited to use the
          Services by your employer, contractor or other affiliated entity
          (“Employer”). We will share any information you provide to us with
          your Employer in order to provide the Services.
        </p>

        <p>
          You may print a copy of this Privacy Policy by clicking{' '}
          <a href='#policy' onClick={() => window.print()}>
            here
          </a>
          .{' '}
        </p>
      </div>

      <div>
        <p className={classes.bold}>Privacy Policy Table of Contents</p>
        <ol className={classes.underline}>
          <li>
            <a href='#what-this-privacy-policy-covers'>
              What this Privacy Policy Covers
            </a>
          </li>
          <li>
            <a href='#personal-data'>Personal Data</a>
          </li>

          <ul>
            <li>
              <a href='#categories-of-personal-data-we-collect'>
                Categories of Personal Data We Collect
              </a>
            </li>
            <li>
              <a href='#categories-of-sources-of-personal-data'>
                Categories of Sources of Personal Data
              </a>
            </li>
            <li>
              <a href='#our-commercial-or-business-purposes-for-collection-personal-data'>
                Our Commercial or Business Purposes for Collecting Personal Data
              </a>
            </li>
          </ul>

          <li>
            <a href='#how-we-share-your-personal-data'>
              How We Share Your Personal Data
            </a>
          </li>
          <li>
            <a href='#tracking-tools-and-opt-out'>Tracking Tools and Opt-Out</a>
          </li>
          <li>
            <a href='#data-security-and-retention'>
              Data Security and Retention
            </a>
          </li>
          <li>
            <a href='#personal-data-of-children'>Personal Data of Children</a>
          </li>
          <li>
            <a href='#state-law-privacy-rights'>State Law Privacy Rights</a>
          </li>
          <li>
            <a href='#changes-to-this-policy'>Changes to this Privacy Policy</a>
          </li>
          <li>
            <a href='#contact-information'>Contact Information</a>
          </li>
        </ol>
      </div>

      <div>
        <p
          className={`${classes.underline} + ${classes.bold}`}
          id='what-this-privacy-policy-covers'
        >
          What this Privacy Policy Covers
        </p>
        <p>
          This Privacy Policy covers how we treat Personal Data that we gather
          when you access or use our Services. “Personal Data” means any
          information that identifies or relates to a particular individual and
          includes information referred to as “personally identifiable
          information” or “personal information” under applicable data privacy
          laws, rules or regulations. This Privacy Policy does not cover the
          practices of companies we don’t own or control or people we don’t
          manage.
        </p>
      </div>

      <div>
        <p
          className={`${classes.underline} + ${classes.bold}`}
          id='personal-data'
        >
          Personal Data
        </p>

        <div>
          <div>
            <p
              className={classes.underline}
              id='categories-of-personal-data-we-collect'
            >
              Categories of Personal Data We Collect
            </p>
            <p>
              This chart details the categories of Personal Data that we collect
              and have collected over the past 12 months:
            </p>
            <table className={classes.table}>
              <tr>
                <th>
                  <p className={classes.bold}>Category of Personal Data</p>
                </th>
                <th>
                  <p className={classes.bold}>
                    Examples of Personal Data We Collect
                  </p>
                </th>
                <th>
                  <p className={classes.bold}>
                    Categories of Third Parties With Whom We Share this Personal
                    Data:
                  </p>
                </th>
              </tr>
              <tr>
                <td>
                  <p className={classes.bold}>Profile or Contact Data</p>
                </td>
                <td>
                  <ul>
                    <li>First and last name</li>
                    <li>Email</li>
                    <li>Phone number</li>
                    <li>Social Security Number</li>
                    <li>Unique identifiers such as passwords</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Your Employer</li>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={classes.bold}>
                    Professional or Employment-Related Data
                  </p>
                </td>
                <td>
                  <ul>
                    <li>Salary and benefit information</li>
                    <li>Tax status and elections</li>
                    <li>Job title and employment dates</li>
                    <li>Location of company and employees</li>
                    <li>Corporate structure</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Your Employer</li>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={classes.bold}>Financial and Accounting Data</p>
                </td>
                <td>
                  <ul>
                    <li>Bank account balance</li>
                    <li>Vendor spending</li>
                    <li>Cross receipts</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Your Employer</li>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={classes.bold}>Device/IP Data</p>
                </td>
                <td>
                  <ul>
                    <li>IP address</li>
                    <li>Device ID</li>
                    <li>Domain Server</li>
                    <li>
                      Type of device/operating system/browser used to access the
                      Services
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={classes.bold}>Web Analytics</p>
                </td>
                <td>
                  <ul>
                    <li>Web page interactions</li>
                    <li>
                      Referring webpage/source through which you accessed the
                      Services
                    </li>
                    <li>Non-identifiable request IDs</li>
                    <li>
                      Statistics associated with the interaction between device
                      or browser and the Services
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>

          <div>
            <p
              className={classes.underline}
              id='categories-of-sources-of-personal-data'
            >
              Categories of Sources of Personal Data
            </p>
            <p>
              We collect Personal Data about you from the following categories
              of sources:
            </p>
            <div>
              <div>
                <p className={classes.bold}>You</p>
                <div>
                  <p className={classes.underline}>
                    When you provide such information directly to us.
                  </p>
                  <div>
                    <ul>
                      <li>
                        When you create an account or use our interactive tools
                        and Services.
                      </li>
                      <li>
                        When you voluntarily provide information in free-form
                        text boxes through the Services or through responses to
                        surveys or questionnaires.
                      </li>
                      <li>
                        When you send us an email or otherwise contact us.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <p className={classes.bold}>Third Parties</p>
                <div>
                  <div>
                    <p className={classes.underline}>Your Employer</p>
                    <div>
                      <ul>
                        <li>
                          Your employer may provide us with information related
                          to your employment, salary, benefits, tax status, and
                          other relevant information in connection with using
                          our Services.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <p className={classes.underline}>Vendors</p>
                    <div>
                      <ul>
                        <li>
                          We may use analytics providers to analyze how you
                          interact and engage with the Services, or third
                          parties may help us provide you with customer support.
                        </li>
                        <li>
                          We may use vendors to obtain information to generate
                          leads and create user profiles
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <p className={classes.underline}>Vendors</p>
                    <div>
                      <ul>
                        <li>
                          We may use analytics providers to analyze how you
                          interact and engage with the Services, or third
                          parties may help us provide you with customer support.
                        </li>
                        <li>
                          We may use vendors to obtain information to generate
                          leads and create user profiles
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <p className={classes.underline}>
                      Third-Party Authentication Partner
                    </p>
                    <div>
                      <ul>
                        <li>
                          We may use analytics providers to analyze how you
                          interact and engage with the Services, or third
                          parties may help us provide you with customer support.
                        </li>
                        <li>
                          We may use vendors to obtain information to generate
                          leads and create user profiles
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <p
              className={classes.underline}
              id='our-commercial-or-business-purposes-for-collection-personal-data'
            >
              Our Commercial or Business Purposes for Collecting Personal Data
            </p>
            <div>
              <p className={classes.bold}>
                Providing, Customizing and Improving the Services
              </p>
              <div>
                <ul>
                  <li>
                    Creating and managing your account or other user profiles.
                  </li>
                  <li>
                    Providing you or your Employer with the products, services
                    or information you or they request.
                  </li>
                  <li>
                    Meeting or fulfilling the reason you provided the
                    information to us.
                  </li>
                  <li>Providing support and assistance for the Services.</li>
                  <li>
                    Improving the Services, including testing, research,
                    internal analytics and product development.
                  </li>
                  <li>Doing fraud protection, security and debugging.</li>
                  <li>
                    Conducting KYC for onboarding to our investment platforms.
                  </li>
                </ul>
              </div>

              <div>
                <p className={classes.bold}>Marketing the Services</p>
                <ul>
                  <li>Marketing and selling the Services.</li>
                </ul>
              </div>

              <div>
                <p className={classes.bold}>Corresponding with You</p>
                <ul>
                  <li>
                    Responding to correspondence that we receive from you,
                    contacting you when necessary or requested, and sending you
                    information about MainStreet or the Services.
                  </li>
                  <li>
                    Sending emails and other communications according to your
                    preferences or that display content that we think will
                    interest you.
                  </li>
                </ul>
              </div>

              <div>
                <p className={classes.bold}>
                  Meeting Legal Requirements and Enforcing Legal Terms
                </p>
                <ul>
                  <li>
                    Fulfilling our legal obligations under applicable law,
                    regulation, court order or other legal process, such as
                    preventing, detecting and investigating security incidents
                    and potentially illegal or prohibited activities.
                  </li>
                  <li>
                    Protecting the rights, property or safety of you, MainStreet
                    or another party.
                  </li>
                  <li>Enforcing any agreements with you.</li>
                  <li>
                    Responding to claims that any posting or other content
                    violates third-party rights.
                  </li>
                  <li>Resolving disputes.</li>
                </ul>
              </div>
            </div>

            <p>
              We will not collect additional categories of Personal Data or use
              the Personal Data we collected for materially different, unrelated
              or incompatible purposes without providing you notice.
            </p>
          </div>
        </div>
      </div>

      <div>
        <p
          className={`${classes.underline} + ${classes.bold}`}
          id='how-we-share-your-personal-data'
        >
          How We Share Your Personal Data
        </p>
        <p>
          We disclose your Personal Data to the categories of service providers
          and other parties listed in this section. Depending on state laws that
          may be applicable to you, some of these disclosures may constitute a
          “sale” of your Personal Data. For more information, please refer to
          the state-specific sections below.
        </p>
        <div>
          <ul>
            <li>
              <p>
                <span className={classes.bold}>Your Employer.</span>
                This is your employer, contractor or other affiliated entity who
                invited you to use the Services.
              </p>
            </li>
            <li>
              <p>
                <span className={classes.bold}>Service Providers.</span>
                These parties help us provide the Services or perform business
                functions on our behalf. They include:
              </p>
              <div>
                <ul>
                  <li>
                    <p>Hosting, technology and communication providers.</p>
                  </li>
                  <li>
                    <p>Security and fraud prevention consultants.</p>
                  </li>
                  <li>
                    <p>Support and customer service vendors.</p>
                  </li>
                  <li>
                    <p>
                      Third-party identity verification partnersProcurement
                      Partners. As a part of our Services, we partner with other
                      businesses to provide certain deals and discounts to you.
                      We may need to disclose certain Personal Data to apply
                      such discounts and deals to you.
                    </p>
                  </li>
                  <li>
                    <p>
                      External accountants, lawyers, consultants that help
                      facilitate the provision of Services.
                    </p>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <p>
                <span className={classes.bold}>Analytics Partners.</span>
                These parties provide analytics on web traffic or usage of the
                Services. They include:
              </p>
              <div>
                <ul>
                  <li>
                    <p>
                      Companies that track how users found or were referred to
                      the Services.
                    </p>
                  </li>
                  <li>
                    <p>
                      Companies that track how users interact with the Services.
                    </p>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <p>
                <span className={classes.bold}>
                  Parties You Authorize, Access or Authenticate
                </span>
              </p>
              <div>
                <ul>
                  <li>
                    <p>Third parties you access through the services.</p>
                  </li>
                  <li>
                    <p>Third-party identity verification partners.</p>
                  </li>
                  <li>
                    <p>Other users.</p>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div>
          <div>
            <p className={classes.underline}>Legal Obligations</p>
            <p>
              We may share any Personal Data that we collect with third parties
              in conjunction with any of the activities set forth under “Meeting
              Legal Requirements and Enforcing Legal Terms” in the “Our
              Commercial or Business Purposes for Collecting Personal Data”
              section above.
            </p>
          </div>
          <div>
            <p className={classes.underline}>Business Transfers</p>
            <p>
              All of your Personal Data that we collect may be transferred to a
              third party if we undergo a merger, acquisition, bankruptcy or
              other transaction in which that third party assumes control of our
              business (in whole or in part). Should one of these events occur,
              we will make reasonable efforts to notify you before your
              information becomes subject to different privacy and security
              policies and practices.
            </p>
          </div>
          <div>
            <p className={classes.underline}>Data that is Not Personal Data</p>
            <p>
              We may create aggregated, de-identified or anonymized data from
              the Personal Data we collect, including by removing information
              that makes the data personally identifiable to a particular user.
              We may use such aggregated, de-identified or anonymized data and
              share it with third parties for our lawful business purposes,
              including to analyze, build and improve the Services and promote
              our business, provided that we will not share such data in a
              manner that could identify you.
            </p>
          </div>
        </div>
      </div>

      <div>
        <p
          className={`${classes.underline} + ${classes.bold}`}
          id='tracking-tools-and-opt-out'
        >
          Tracking Tools and Opt-Out
        </p>
        <p>
          The Services use cookies and similar technologies such as pixel tags,
          web beacons, clear GIFs and JavaScript (collectively, “Cookies”) to
          enable our servers to recognize your web browser, tell us how and when
          you visit and use our Services, analyze trends, learn about our user
          base and operate and improve our Services. Cookies are small pieces of
          data– usually text files – placed on your computer, tablet, phone or
          similar device when you use that device to access our Services. We may
          also supplement the information we collect from you with information
          received from third parties, including third parties that have placed
          their own Cookies on your device(s). Please note that because of our
          use of Cookies, the Services do not support “Do Not Track” requests
          sent from a browser at this time.
        </p>
        <p>We use the following types of Cookies:</p>

        <div>
          <ul>
            <li>
              <p>
                <span className={classes.underline}>Essential Cookies.</span>
                Essential Cookies are required for providing you with features
                or services that you have requested. For example, certain
                Cookies enable you to log into secure areas of our Services.
                Disabling these Cookies may make certain features and services
                unavailable.
              </p>
            </li>
            <li>
              <p>
                <span className={classes.underline}>Functional Cookies.</span>
                Functional Cookies are used to record your choices and settings
                regarding our Services, maintain your preferences over time and
                recognize you when you return to our Services. These Cookies
                help us to personalize our content for you, greet you by name
                and remember your preferences (for example, your choice of
                language or region).
              </p>
            </li>
            <li>
              <p>
                <span className={classes.underline}>
                  Performance/Analytical Cookies.
                </span>
                Performance/Analytical Cookies allow us to understand how
                visitors use our Services. They do this by collecting
                information about the number of visitors to the Services, what
                pages visitors view on our Services and how visitors are
                interacting with the pages on the Services.
                Performance/Analytical Cookies also help us measure the
                performance of our advertising campaigns in order to help us
                improve our campaigns and the Services’ content for those who
                engage with our advertising. For example, Google LLC (“Google”)
                uses cookies in connection with its Google Analytics services.
                Google’s ability to use and share information collected by
                Google Analytics about your visits to the Services is subject to
                the Google Analytics Terms of Use and the Google Privacy Policy.
                You have the option to opt-out of Google’s use of Cookies by
                visiting the Google advertising opt-out page at
                www.google.com/privacy_ads.html or the Google Analytics Opt-out
                Browser Add-on at https://tools.google.com/dlpage/gaoptout/.
              </p>
            </li>
          </ul>
        </div>

        <p>
          You can decide whether or not to accept Cookies through your internet
          browser’s settings. Most browsers have an option for turning off the
          Cookie feature, which will prevent your browser from accepting new
          Cookies, as well as (depending on the sophistication of your browser
          software) allow you to decide on acceptance of each new Cookie in a
          variety of ways. You can also delete all Cookies that are already on
          your device. If you do this, however, you may have to manually adjust
          some preferences every time you visit our website and some of the
          Services and functionalities may not work.
        </p>
        <p>
          To explore what Cookie settings are available to you, look in the
          “preferences” or “options” section of your browser’s menu. To find out
          more information about Cookies, including information about how to
          manage and delete Cookies, please visit
          http://www.allaboutcookies.org/.
        </p>
      </div>

      <div>
        <p
          className={`${classes.underline} + ${classes.bold}`}
          id='data-security-and-retention'
        >
          Data Security and Retention
        </p>
        <p>
          We seek to protect your Personal Data from unauthorized access, use
          and disclosure using appropriate physical, technical, organizational
          and administrative security measures based on the type of Personal
          Data and how we are processing that data. You should also help protect
          your data by appropriately selecting and protecting your password
          and/or other sign-on mechanism; limiting access to your computer or
          device and browser; and signing off after you have finished accessing
          your account. Although we work to protect the security of your account
          and other data that we hold in our records, please be aware that no
          method of transmitting data over the internet or storing data is
          completely secure.
        </p>
        <p>
          We retain Personal Data about you for as long as you have an open
          account with us or as otherwise necessary to provide you with our
          Services. In some cases we retain Personal Data for longer, if doing
          so is necessary to comply with our legal obligations, resolve disputes
          or collect fees owed, or is otherwise permitted or required by
          applicable law, rule or regulation. We may further retain information
          in an anonymous or aggregated form where that information would not
          identify you personally.
        </p>
      </div>

      <div>
        <p
          className={`${classes.underline} + ${classes.bold}`}
          id='personal-data-of-children'
        >
          Personal Data of Children
        </p>
        <p>
          As noted in the Terms of Service, we do not knowingly collect or
          solicit Personal Data about children under 13 years of age; if you are
          a child under the age of 13, please do not attempt to register for or
          otherwise use the Services or send us any Personal Data. If we learn
          we have collected Personal Data from a child under 13 years of age, we
          will delete that information as quickly as possible. If you believe
          that a child under 13 years of age may have provided Personal Data to
          us, please contact us at legal@mainstreet.com.
        </p>
      </div>

      <div>
        <p
          className={`${classes.underline} + ${classes.bold}`}
          id='state-law-privacy-rights'
        >
          State Law Privacy Rights
        </p>

        <div>
          <p className={classes.underline}>California Resident Rights</p>
          <p>
            Under California Civil Code Sections 1798.83-1798.84, California
            residents are entitled to contact us to prevent disclosure of
            Personal Data to third parties for such third parties’ direct
            marketing purposes; in order to submit such a request, please
            contact us at legal@mainstreet.com.
          </p>

          <p className={classes.underline}>Nevada Resident Rights</p>
          <p>
            If you are a resident of Nevada, you have the right to opt-out of
            the sale of certain Personal Data to third parties who intend to
            license or sell that Personal Data. You can exercise this right by
            contacting us at support@mainstreet.com with the subject line
            “Nevada Do Not Sell Request” and providing us with your name and the
            email address associated with your account. Please note that we do
            not currently sell your Personal Data as sales are defined in Nevada
            Revised Statutes Chapter 603A.
          </p>
        </div>
      </div>

      <div>
        <p
          className={`${classes.underline} + ${classes.bold}`}
          id='changes-to-this-policy'
        >
          Changes to this Privacy Policy
        </p>
        <p>
          We’re constantly trying to improve our Services, so we may need to
          change this Privacy Policy from time to time, but we will alert you to
          any such changes by placing a notice on the MainStreet website, by
          sending you an email and/or by some other means. Please note that if
          you’ve opted not to receive legal notice emails from us (or you
          haven’t provided us with your email address), those legal notices will
          still govern your use of the Services, and you are still responsible
          for reading and understanding them. If you use the Services after any
          changes to the Privacy Policy have been posted, that means you agree
          to all of the changes. Use of information we collect is subject to the
          Privacy Policy in effect at the time such information is collected.
        </p>
      </div>

      <div>
        <p
          className={`${classes.underline} + ${classes.bold}`}
          id='contact-information'
        >
          Contact Information:
        </p>
        <p>
          If you have any questions or comments about this Privacy Policy, the
          ways in which we collect and use your Personal Data or your choices
          and rights regarding such collection and use, please do not hesitate
          to contact us at:
        </p>
        <div>
          <ul>
            <li>
              <p>https://www.mainstreet.com/</p>
            </li>
            <li>
              <p>support@mainstreet.com</p>
            </li>
            <li>
              <p>105 North 1st Street, #28, San Jose, CA 95113</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
