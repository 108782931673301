import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Divider, Flex, Grid, Text, TextField } from 'component-library';
import { useCommonStores } from 'stores/useStores';
import { SurveyFlowContainer } from 'products/tax-credits/components';

interface ConfirmGrossReceiptsProps {
  pathTo: string;
  onNext: () => void;
  isPrefilled: boolean;
}

const useStyles = makeStyles(() => ({
  gridContainer: {
    maxWidth: '800px',
  },
}));

interface QuarterData {
  year: number;
  quarters: number[];
}

const initialValues: QuarterData[] = [
  {
    year: 2019,
    quarters: [1000046, 1218321, 1422613, 1513780],
  },
  {
    year: 2020,
    quarters: [1245070, 402641, 298982, 615334],
  },
  {
    year: 2021,
    quarters: [304118, 502661, 650081],
  },
];

const initialValuesEmpty: QuarterData[] = [
  {
    year: 2019,
    quarters: [0, 0, 0, 0],
  },
  {
    year: 2020,
    quarters: [0, 0, 0, 0],
  },
  {
    year: 2021,
    quarters: [0, 0, 0, 0],
  },
];

export const ConfirmGrossReceipts = observer(
  ({ pathTo, onNext, isPrefilled }: ConfirmGrossReceiptsProps) => {
    const [tableData, setTableData] = useState<QuarterData[]>(
      isPrefilled ? initialValues : initialValuesEmpty,
    );
    const classes = useStyles();
    const history = useHistory();
    const { app } = useCommonStores();

    const handleOnContinue = () => {
      onNext();
      history.push(pathTo);
    };

    const handleValueChange = (
      year: number,
      quarterIndex: number,
      value: number,
    ) => {
      setTableData((prevData) =>
        prevData.map((data) =>
          data.year === year
            ? {
                ...data,
                quarters: data.quarters.map((quarterValue, index) =>
                  index === quarterIndex ? value : quarterValue,
                ),
              }
            : data,
        ),
      );
    };

    return (
      <SurveyFlowContainer
        title='Confirm gross receipts'
        subtitle='We used your accounting integration wherever possible to pre-fill the amount of gross receipts (revenue) you generated each quarter. Please confirm or edit these values.'
        onContinue={handleOnContinue}
        onBack={() => console.log('Back click')}
        dataTestId='erc-qualify-survey'
      >
        <>
          <Flex className={classes.gridContainer} padding={[24, 0]}>
            <Grid gap={app.isMobile ? 8 : 48}>
              {tableData.map((data) => {
                return (
                  <Grid.Cell columns={4} key={`${data.year}`}>
                    <Flex direction='column' gap={8}>
                      <Text variant='medium' text={data.year} size={23} />
                      <Divider />
                      {data.quarters.map((val, key) => {
                        return (
                          <Flex
                            key={`${data.year}-${key}`}
                            alignItems={app.isMobile ? 'flex-start' : 'center'}
                            gap={8}
                            direction={app.isMobile ? 'column' : 'row'}
                          >
                            <Text
                              text={`Q${key + 1} ${data.year}:`}
                              size={15}
                            />
                            <TextField
                              key={`${key}-${data.year}`}
                              value={val}
                              onChange={(e) =>
                                handleValueChange(
                                  data.year,
                                  key,
                                  Number(e.target.value.replace(/,/g, '')),
                                )
                              }
                              small
                              type='number'
                              currencyFormat
                            />
                          </Flex>
                        );
                      })}
                    </Flex>
                  </Grid.Cell>
                );
              })}
            </Grid>
          </Flex>
        </>
      </SurveyFlowContainer>
    );
  },
);
