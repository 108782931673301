import React from 'react';
import { observer } from 'mobx-react';
import styles from './components.module.scss';
import Flex from './flex';
import Text from './text/text';
import { Color, Heading } from 'component-library';
import { useCommonStores } from 'stores/useStores';

const FooterSection = observer(() => {
  const { app } = useCommonStores();

  return (
    <Flex
      justifyContent={app.isMobile640 ? 'flex-start' : 'center'}
      className={styles.footerSection}
      padding={app.isMobile640 ? [40, 24, 0, 24] : [80, 24, 0, 24]}
    >
      <Flex
        className={styles.footerSectionInner}
        direction='column'
        gap={app.isMobile640 ? 40 : 80}
      >
        <Flex justifyContent='space-between' gap={24}>
          <Flex direction='column' basis={440} gap={32}>
            <Flex.Cell>
              <img
                src={'/images/logo/mst-logomark-light.svg'}
                width={app.isMobile640 ? 64 : 100}
                height={app.isMobile640 ? 40 : 64}
                alt='MainStreet logo'
              />
            </Flex.Cell>
            <Heading tag='h2' color={Color.neutral.white} variant='medium'>
              We&apos;ve got the time to save you&nbsp;money.
            </Heading>
          </Flex>
        </Flex>
        <Flex
          padding={app.isMobile640 ? [24, 0] : [32, 0]}
          alignItems='center'
          justifyContent={app.isMobile640 ? 'center' : 'space-between'}
          direction={app.isMobile640 ? 'column' : 'row'}
          gap={app.isMobile640 ? 8 : 0}
        >
          <Flex justifyContent='flex-start'>
            <Text size={12} color={Color.neutral._60}>
              &#169; 2024 MainStreet Work, Inc. All rights reserved.
            </Text>
          </Flex>
          <Flex
            gap={app.isMobile640 ? 12 : 24}
            alignItems='center'
            justifyContent={app.isMobile640 ? 'flex-start' : 'flex-end'}
            className={styles.footerLinks}
          >
            <Text size={12}>
              <a href={'#'} target='_blank' rel='noreferrer'>
                Privacy Policy
              </a>
            </Text>
            <Text size={12}>
              <a href={'#'} target='_blank' rel='noreferrer'>
                Terms of Service
              </a>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
});

export default FooterSection;
