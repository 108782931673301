import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  JobGroupEnum,
  JobGroupEnumToString,
  JobGroupPercents,
  JobGroupToPercents,
  RdActivitiesForJobGroup,
} from 'lib/constants';
import { SectionCard } from 'components/SectionCard';
import { ButtonLink } from 'components/util/ButtonLink';
import { Loading } from 'components/util/Loading';
import { scrollToRef } from 'components/util/ScrollUtils';
import { UpdateWorkDoneRequest } from 'lib/interfaces';
import {
  Activity,
  ActivityList,
  Comments,
  EmployeeHeader,
  ExpenseClassificationSlider,
} from 'pages/dashboard/fed-rd-program';
import { EmployeeInfo } from 'pages/dashboard/fed-rd-program/components/ExpenseClassificationUtils';
import React, { createRef, useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  ref: {
    height: '20px',
  },
  slider: {
    borderBottom: '1px solid #E5E5E5',
  },
  activityList: {
    borderBottom: '1px solid #E5E5E5',
    paddingLeft: 10,
  },
  buttons: {
    padding: '15px 30px',
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  editButton: {
    marginLeft: '-20px',
  },
  saveButton: {
    margin: '15px 0',
    textTransform: 'initial',
    fontWeight: 500,
    fontSize: '16px',
  },
  saveStatus: {
    fontSize: '14px',
    color: 'red',
    fontWeight: 400,
  },
  jobGroupUpdate: {
    padding: '20px 40px',
    backgroundColor: '#ffeb8a',
    fontSize: '14px',
  },
  jobGroupUpdateText: {
    fontSize: 'inherit',
    fontWeight: 400,
  },
  jobGroupUpdateTextBold: {
    fontSize: 'inherit',
    fontWeight: 500,
  },
}));

export interface EmployeeExpenseCardProps {
  employee: EmployeeInfo;
  editMode: boolean;
  save: (update: UpdateWorkDoneRequest) => Promise<void>;
  next: () => void;
  adminInitial: string;
  scrollOnInitialRender: boolean;
}

export const EmployeeExpenseCard = ({
  employee,
  save,
  next,
  editMode,
  adminInitial,
  scrollOnInitialRender,
}: EmployeeExpenseCardProps) => {
  const classes = useStyles();

  const [edit, setEdit] = useState<boolean>(editMode);
  const [loading, setLoading] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(employee.percent());
  const [jobGroupPercent, setJobGroupPercent] = useState<JobGroupPercents>({
    percent: employee.percent(),
    low: employee.lowPercent(),
    high: employee.highPercent(),
  });
  const [commentDraft, setCommentDraft] = useState<string>('');
  const [jobGroup, setJobGroup] = useState<JobGroupEnum>(employee.jobGroupEnum);
  const [activitiesChecklist, setActivitiesChecklist] = useState<Activity[]>(
    employee.activitiesCheckList(),
  );
  const [jobGroupUpdated, setJobGroupUpdated] = useState<boolean>(false);

  const updateJobGroup = (jobGroup: JobGroupEnum) => {
    setActivitiesChecklist(
      RdActivitiesForJobGroup[jobGroup].map((activity) => {
        return {
          name: activity,
          checked: true,
        };
      }),
    );

    setJobGroupPercent(JobGroupToPercents[jobGroup]);
    setPercent(JobGroupToPercents[jobGroup].percent);
    setJobGroup(jobGroup);
    setJobGroupUpdated(true);
  };

  const activities = activitiesChecklist
    .filter((unChecked) => unChecked.checked)
    .map((activity) => activity.name);

  const ref = createRef<HTMLDivElement>();
  useEffect(() => {
    if (scrollOnInitialRender) {
      ref.current!.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activityListPrompt =
    'Review and add or remove R&D activities done by ' +
    employee.name() +
    ' in ' +
    employee.year();

  const update = (comment: boolean) => {
    if (!comment) {
      setLoading(true);
      scrollToRef(ref);
    }

    save(
      employee.createUpdate(percent, commentDraft, activities, jobGroup),
    ).then(() => {
      if (!employee.hasComments() || comment) {
        next();
      }

      setEdit(false);
      setLoading(false);
      setJobGroupUpdated(false);
      if (percent >= 80) {
        setPercent(100);
      }
    });
  };

  const saveButtonStatus = percent > 0 && activities.length === 0;

  return (
    <>
      <div className={classes.ref} ref={ref} />
      <Box marginBottom={'20px'}>
        <SectionCard style={{ width: '100%', marginTop: 0 }}>
          <EmployeeHeader
            name={employee.name()}
            jobTitle={employee.jobTitle()}
            jobType={employee.jobType()}
            jobCategory={employee.jobGroup()}
            percent={percent}
            showRightSide={!edit}
            setJobGroup={updateJobGroup}
          />
          <Loading loading={loading}>
            {jobGroupUpdated && (
              <Box className={classes.jobGroupUpdate}>
                <Typography
                  component='span'
                  variant='body1'
                  className={classes.jobGroupUpdateTextBold}
                >
                  {`${employee.name()} will be moved to the "${
                    JobGroupEnumToString[jobGroup]
                  }" category `}
                </Typography>
                <Typography
                  component='span'
                  variant='body1'
                  className={classes.jobGroupUpdateText}
                >
                  – that&apos;s where you&apos;ll find them if you want to
                  review their information.
                </Typography>
              </Box>
            )}
            <Box className={classes.activityList}>
              <ActivityList
                activities={activitiesChecklist}
                setActivities={setActivitiesChecklist}
                prompt={activityListPrompt}
                editMode={edit}
              />
            </Box>
            {edit && (
              <Box className={classes.slider}>
                <ExpenseClassificationSlider
                  name={employee.name()}
                  percent={percent}
                  setPercent={setPercent}
                  year={employee.year()}
                  jobGroup={employee.jobGroupEnum()}
                  lowPercentEstimate={jobGroupPercent.low}
                  highPercentEstimate={jobGroupPercent.high}
                />
              </Box>
            )}
            <Box className={classes.buttons}>
              {edit ? (
                <>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={() => update(false)}
                    className={classes.saveButton}
                    disabled={saveButtonStatus}
                  >
                    Save
                  </Button>
                  {saveButtonStatus && (
                    <Typography
                      component='div'
                      variant='body1'
                      className={classes.saveStatus}
                    >
                      If the percentage of time is higher than 0, <br />
                      you need to select at least one eligible activity.
                    </Typography>
                  )}
                </>
              ) : (
                <Box className={classes.editButton}>
                  <ButtonLink text={'Edit'} onClick={() => setEdit(true)} />
                </Box>
              )}
            </Box>
          </Loading>
        </SectionCard>
        {employee.hasComments() && (
          <Comments
            comments={employee.comments()}
            currentUserComment={commentDraft}
            onUserCommentChange={setCommentDraft}
            userInitials={adminInitial}
            submit={() => update(true)}
          />
        )}
      </Box>
    </>
  );
};
