import { useCallback, useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { Callout, Link, Text } from 'component-library';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import {
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
  SurveyNameEnum,
} from 'lib/constants';
import ExpandableSurveyQuestion from 'components/ExpandableSurveyQuestion';
import { makeStyles } from '@material-ui/core';
import { datadogLogs } from '@datadog/browser-logs';
import { CompanyContext } from 'pages/CompanyRequired';
import { logContext } from 'logging';
import { CmsQuestionData } from 'lib/interfaces';
import _ from 'lodash';
import { observer } from 'mobx-react';

export enum PayrollGapReason {
  CHANGED_PROVIDERS = 1,
  DIDNT_ISSUE_PAYMENTS,
  DIDNT_USE_A_PAYROLL_SYSTEM,
  OTHER,
  UNKNOWN,
}

const getPayrollGapReason = (answerValue: string): PayrollGapReason => {
  switch (answerValue) {
    case GraphCmsQuestionIdToAnswers[
      GraphCmsQuestionIdEnum.PAYROLL_GAP_QUESTION_ID
    ].PAYROLL_GAP_ANSWER_ID_USING_DIFFERENT_PAYROLL_SYSTEM:
      return PayrollGapReason.CHANGED_PROVIDERS;
    case GraphCmsQuestionIdToAnswers[
      GraphCmsQuestionIdEnum.PAYROLL_GAP_QUESTION_ID
    ].PAYROLL_GAP_ANSWER_ID_DIDNT_ISSUE_PAYMENTS:
      return PayrollGapReason.DIDNT_ISSUE_PAYMENTS;
    case GraphCmsQuestionIdToAnswers[
      GraphCmsQuestionIdEnum.PAYROLL_GAP_QUESTION_ID
    ].PAYROLL_GAP_ANSWER_ID_DIDNT_USE_A_PAYROLL_SYSTEM:
      return PayrollGapReason.DIDNT_USE_A_PAYROLL_SYSTEM;
    // case GraphCmsQuestionIdToAnswers[
    //   GraphCmsQuestionIdEnum.PAYROLL_GAP_QUESTION_ID
    // ].PAYROLL_GAP_ANSWER_ID_OTHER:
    //   return PayrollGapReason.OTHER;
    // COREX-572: Remove 'Other' keeping this as commented out so we have a reference for answer_id if we need to revert
    default:
      if (answerValue) {
        datadogLogs.logger.warn(
          `Payroll gap survey question had an unexpected answer: ${answerValue}`,
        );
      }
      return PayrollGapReason.UNKNOWN;
  }
};

interface PayrollGapProps {
  taxYear: number;
  payrollGapWasAnsweredWithReason: (reason: PayrollGapReason) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '780px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));

/**
 * Encapsulates the warning to a user that they have a payroll gap, as
 * well as displaying the survey question to allow the user to tell us why they
 * have a gap
 *
 * This also provides a callback
 */
export const PayrollGap = observer(
  ({ taxYear, payrollGapWasAnsweredWithReason }: PayrollGapProps) => {
    const classes = useStyles();
    const { company } = useContext(CompanyContext);
    const [isCalloutExpanded, setIsCalloutExpanded] = useState<boolean>(true);
    const {
      questionsToRender,
      addSurveyAnswers,
      saveAnswers,
      isLoading,
    } = useSurveyQuestions(SurveyNameEnum.PAYROLL_SYSTEM_GAP, taxYear);

    const getPayrollGapQuestion = useCallback(():
      | CmsQuestionData
      | undefined => {
      if (!questionsToRender) {
        return;
      }
      const renderSectionKey = Object.keys(questionsToRender)[0];
      return questionsToRender[renderSectionKey]?.find(
        (question) =>
          question.id === GraphCmsQuestionIdEnum.PAYROLL_GAP_QUESTION_ID,
      );
    }, [questionsToRender]);

    useEffect(() => {
      if (!isLoading) {
        const payrollGapQuestion = getPayrollGapQuestion();
        if (payrollGapQuestion?.answerValue) {
          payrollGapWasAnsweredWithReason(
            getPayrollGapReason(payrollGapQuestion?.answerValue.toString()),
          );
        }
      }
    }, [isLoading, getPayrollGapQuestion, payrollGapWasAnsweredWithReason]);

    const saveAnswer = () => {
      saveAnswers().catch((error) => {
        datadogLogs.logger.error(
          'Error saving payroll gap survey',
          logContext({
            company,
            error,
          }),
        );
      });
    };

    const renderQuestion: () => JSX.Element | undefined = () => {
      const payrollGapQuestion = getPayrollGapQuestion();
      if (payrollGapQuestion) {
        return (
          <ExpandableSurveyQuestion
            questionProps={payrollGapQuestion}
            key={payrollGapQuestion.id}
            onQuestionPropsChange={(answers) => {
              addSurveyAnswers(
                payrollGapQuestion.id,
                Object.keys(questionsToRender)[0],
                answers,
              );
              if (!_.isEmpty(answers) && answers[0].answerValue) {
                const reason = getPayrollGapReason(
                  answers[0].answerValue.toString(),
                );
                payrollGapWasAnsweredWithReason(reason);
                saveAnswer();
              }
            }}
          />
        );
      }
    };

    return (
      <div className={classes.root}>
        <Callout
          hasShowMore
          showMore={isCalloutExpanded}
          showMoreOnClick={() => setIsCalloutExpanded(!isCalloutExpanded)}
          title='Gaps in payroll systems'
        >
          <React.Fragment key='.0'>
            <Text>
              Gaps in payroll systems are pretty common, and they do not
              necessarily mean that there is something wrong with your
              connection. Since we must have complete payroll information to
              calculate your credits, we need you to provide more details about
              the reasons for this gap.
            </Text>
            <br />
            <Text>
              Have any questions about the payroll gap we’ve detected for your
              company? Reach out to{' '}
              <Link variant='medium' href='mailto: support@mainstreet.com'>
                support@mainstreet.com
              </Link>
              .
            </Text>
          </React.Fragment>
        </Callout>
        {renderQuestion()}
      </div>
    );
  },
);
