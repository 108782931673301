import React, { useState } from 'react';
import { Button, Checkbox, SideDrawer } from 'component-library';
import { Form8974Tier3CreditRedemption } from './Form8974Tier3CreditRedemption';
import { observer } from 'mobx-react';
import { useTaxCreditsStores } from 'stores/useStores';
import { makeStyles } from '@material-ui/core';
import { ProgramData } from 'lib/interfaces';

const useStyles = makeStyles(() => ({
  sideDrawerStepper: {
    padding: '24px 0',
  },
  mainContentPadding: {
    padding: '16px',
  },
  fullHeight: {
    height: '100%',
  },
  checkbox: {
    '& label': {
      'margin-bottom': 0,
    },
  },
  alignButton: {
    marginLeft: 'auto',
  },
}));

interface Form8974Tier3SideDrawerProps {
  program: ProgramData;
}

export const Form8974Tier3SideDrawer = observer(
  ({ program }: Form8974Tier3SideDrawerProps) => {
    const { form8974 } = useTaxCreditsStores();
    const classes = useStyles();

    const [confirmed, setConfirmed] = useState(false);

    return (
      <SideDrawer
        alertMessage={
          form8974.form8974GeneratedDocumentUrl &&
          `If you don't complete this step you won't be able to apply your credits.`
        }
        alertType={'warning'}
        alertMessagePosition='bottom'
        show={form8974.sideDrawerOpen}
        title='Form 8974 Instructions'
        mainContentFullHeight={true}
        closeToggle={() => form8974.toggleSideDrawer(false)}
        drawerActionsPadding={form8974.taxInfoSubmitted}
        drawerActions={
          form8974.form8974GeneratedDocumentUrl && (
            <>
              <Button
                className={classes.alignButton}
                label='Finish'
                disabled={!confirmed}
                loading={form8974.payrollProviderConfirmationProcessing}
                onClick={() =>
                  form8974.handleCompletePayrollProviderSetup(program.id)
                }
              />
            </>
          )
        }
        drawerFooterContent={
          form8974.form8974GeneratedDocumentUrl && (
            <Checkbox
              dataTestId='confirmation-checkbox'
              className={classes.checkbox}
              onChange={() => setConfirmed(!confirmed)}
              options={[
                {
                  checked: confirmed,
                  text: `I have downloaded my Form 8974 and I will file it with my payroll taxes.`,
                  value: 'confirmed',
                },
              ]}
            />
          )
        }
        drawerContent={<Form8974Tier3CreditRedemption />}
      />
    );
  },
);
