import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core';
import Analytics from 'components/thirdparty/Analytics';
import ScrollToTop from 'components/util/ScrollToTop';
import { DashboardSideNav } from 'pages/dashboard';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { mainstreetTheme } from 'lib/theme';
import { Elements } from '@stripe/react-stripe-js';
import * as stripeJs from '@stripe/stripe-js';
import { AppErrorBoundary } from './AppErrorBoundary';
import { StoreProvider } from 'stores/StoreContext';
import { observer } from 'mobx-react';
import { Color } from 'component-library';
import { useMainstreetRoutes } from 'routes/MainstreetRoutes';
import { RouteRenderer } from 'routes/RouteRenderer';
import { MediaQueries } from 'components/util/MediaQueries';
import { NavBar } from 'components/NavBar';
import HttpErrorPage from 'pages/HttpErrorPage';
import { ChatBot } from 'components/chatbot/ChatBot';

const useStyles = makeStyles({
  root: {
    backgroundColor: Color.neutral.white,
    height: '100%',
    minHeight: '100vh',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `auto 1fr`,
  },
  centralContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
});

export const App = observer(() => {
  const classes = useStyles();
  const routes = useMainstreetRoutes();

  return (
    <>
      <div className={classes.root}>
        <DashboardSideNav />
        <ChatBot />
        <div className={classes.centralContent}>
          <NavBar />
          <Switch>
            {routes.map((route) => (
              <Route
                key={
                  Array.isArray(route.path) ? route.path.join(':') : route.path
                }
                path={route.path}
                exact={route.exact !== false}
                render={(props) => (
                  <RouteRenderer route={route} props={props} />
                )}
              />
            ))}
            <HttpErrorPage errorCode={404} />
          </Switch>
        </div>
      </div>
      <MediaQueries />
      <Analytics />
      <ScrollToTop />
    </>
  );
});

export interface AppWithContextProps {
  stripePromise: PromiseLike<stripeJs.Stripe | null>;
}

/**
 * Wrap App with various contexts required for the app to function.
 *
 * The purpose of this component is to wrap <App> in context tags which
 * setup required state/providers. Ideally logic is added in <App> or lower
 * in the tree.
 *
 * @see App
 */
const AppWithContext = ({ stripePromise }: AppWithContextProps) => {
  const elementsOptions = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat',
      },
    ],
  };

  return (
    <AppErrorBoundary>
      <ThemeProvider theme={mainstreetTheme}>
        <Elements stripe={stripePromise} options={elementsOptions}>
          <Router>
            <StoreProvider>
              <App />
            </StoreProvider>
          </Router>
        </Elements>
      </ThemeProvider>
    </AppErrorBoundary>
  );
};

export default AppWithContext;
