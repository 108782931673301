import React from 'react';
import {
  Button,
  Flex,
  Link,
  Color,
  Icon,
  IconEnum,
  Text,
} from 'component-library';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  crStepContainer: {
    gridGap: 4,
    gap: 4,
    alignItems: 'flex-start',
  },
  iconContainer: {
    paddingRight: '8px;,',
  },
  link: {
    display: 'flex',
    alignItems: 'end',
    textDecoration: 'none',
  },
  linkText: {
    color: Color.blue._60,
  },
  circleIcon: {
    paddingLeft: '10px',
    paddingTop: '10px',
    width: '39px',
    height: '39px',
    marginLeft: '-8px',
    marginRight: '-8px',
    marginTop: '-6px',
  },
  active: {
    background: Color.neutral._light_20,
    border: '1px solid',
    borderColor: Color.neutral._20,
    borderRadius: '8px',
  },
  completed: {
    color: Color.neutral._60,
  },
  upcoming: {
    color: Color.neutral._40,
  },
  callout: {
    background: Color.blue._20,
  },
}));

interface ClientReviewSubStepProps {
  title: string;
  text: string;
  status: 'active' | 'completed' | 'upcoming';
  onComplete: () => void;
  dataTestId: string;
  callout?: string;
}

export const ClientReviewSubStep = ({
  title,
  text,
  status,
  onComplete,
  dataTestId,
  callout,
}: ClientReviewSubStepProps) => {
  const classes = useStyles();
  const active = status === 'active';
  const showCallout = callout && active;

  return (
    <Flex
      className={`${classes.crStepContainer} ${active ? classes.active : ''}`}
      direction='column'
      padding={showCallout ? [4, 0, 0, 0] : [4, 0]}
      dataTestId={dataTestId}
    >
      <Flex padding={[8, 16]}>
        <div className={classes.iconContainer}>
          {(active || status === 'upcoming') && (
            <Icon
              size={24}
              name={IconEnum.circle}
              className={`${classes.circleIcon} ${
                status === 'upcoming' ? classes.upcoming : ''
              }`}
              color={status === 'upcoming' ? Color.neutral._40 : undefined}
            />
          )}
          {status === 'completed' && (
            <Icon
              size={24}
              name={IconEnum.check_circle_filled}
              color={Color.semantic.$success50}
            />
          )}
        </div>
        <Flex alignItems='center' justifyContent='space-between'>
          <div>
            <Flex alignItems='center' justifyContent='start' gap={4}>
              <Text tag='p' variant='medium' text={title} />
            </Flex>
            {status !== 'completed' && (
              <Text
                size={13}
                tag='p'
                variant='regular'
                color={Color.neutral._80}
              >
                {text}
              </Text>
            )}
          </div>
          {active && (
            <div>
              <Button
                label='Continue'
                onClick={onComplete}
                small
                dataTestId={`${dataTestId}-button`}
              />
            </div>
          )}
        </Flex>
        {status === 'completed' && (
          <Flex alignItems='center' justifyContent='end'>
            <Link
              dataTestId={`${dataTestId}-edit-link`}
              variant='medium'
              text='Review'
              size={15}
              onClick={onComplete}
            />
          </Flex>
        )}
      </Flex>
      {showCallout && (
        <Flex
          className={classes.callout}
          alignItems='center'
          justifyContent='start'
          gap={4}
          padding={[8, 16]}
        >
          <Icon size={24} name={IconEnum.info_circle} color={Color.blue._70} />
          <Text size={13} tag='p' variant='regular' text={callout} />
        </Flex>
      )}
    </Flex>
  );
};
