import React, { useState } from 'react';
import { CmsQuestionData, ProgramData } from 'lib/interfaces';
import { Card } from '@material-ui/core';
import {
  Button,
  CardFooter,
  Content,
  RadioGroup,
  SideDrawer,
  SubQuestion,
  Text,
} from 'component-library';
import { isQuestionAnswered } from 'lib/useSurveyQuestions';
import { renderTaxYearInQuestionText } from '../../../../../lib/useQuestionGroup';

interface ReviewQualificationQuestionsProps {
  questionProps: CmsQuestionData;
  onComplete: () => void;
  program: ProgramData | null;
}

const ReviewQualificationQuestions = ({
  questionProps,
  onComplete,
  program,
}: ReviewQualificationQuestionsProps) => {
  const [
    questionIdToShowInSideDrawer,
    setQuestionIdToShowInSideDrawer,
  ] = useState<string>('');
  const [sideDrawerAnswer, setSideDrawerAnswer] = useState<string>(
    questionProps.answerValue?.toString() ?? '',
  );

  return (
    <Card>
      <SubQuestion
        key={questionProps.id}
        title={questionProps.text}
        subtitle={questionProps.subtitle}
        fontSize={15}
        paddingTopBottom={16}
      />
      <Content paddingTopBottom={8} paddingLeftRight={0}>
        {questionProps.subQuestions &&
          questionProps.subQuestions
            .filter((question) => isQuestionAnswered(question))
            .map((question) => {
              const onAnswerChangeHandler = (value: string): void => {
                setSideDrawerAnswer(value);
              };

              const onSideDrawerSave = (): void => {
                question.answerValue = sideDrawerAnswer;
                setQuestionIdToShowInSideDrawer('');
              };

              const taxYear = program
                ? program.taxYear
                : new Date().getUTCFullYear(); // Every program should have a tax year
              question.text = renderTaxYearInQuestionText(
                question.text,
                taxYear,
              );
              return (
                <React.Fragment key={question.id}>
                  <SubQuestion
                    key={question.id}
                    title={question.text}
                    subtitle={
                      question.answerIDs?.find(
                        (answer) => answer.id === question.answerValue,
                      )?.text ?? ''
                    }
                    setEditMode={() => {
                      setQuestionIdToShowInSideDrawer(question.id);
                      setSideDrawerAnswer(
                        question.answerValue?.toString() ?? '',
                      );
                    }}
                    noBgColor={true}
                  />
                  <SideDrawer
                    closeToggle={() => {
                      setQuestionIdToShowInSideDrawer('');
                      setSideDrawerAnswer('');
                    }}
                    drawerActions={
                      <>
                        <Button
                          label='Save'
                          onClick={() => onSideDrawerSave()}
                          disabled={
                            question.answerValue ===
                            sideDrawerAnswer?.toString()
                          }
                        />
                      </>
                    }
                    drawerContent={
                      <>
                        <Text size={15} variant='medium' paddingBottom={8}>
                          {question.text}&nbsp;
                        </Text>
                        <RadioGroup
                          name={'radio-group'}
                          value={sideDrawerAnswer}
                          gap={32}
                          options={question.answerIDs.map((o) => {
                            return { name: o.text, value: o.id };
                          })}
                          size={16}
                          onChange={(e) => {
                            onAnswerChangeHandler(e.target.value);
                          }}
                        />
                      </>
                    }
                    title='Edit Response'
                    show={questionIdToShowInSideDrawer === question.id}
                  />
                </React.Fragment>
              );
            })}
      </Content>
      <CardFooter
        secondaryCtaLabel='Continue'
        secondaryOnClick={() => onComplete()}
      />
    </Card>
  );
};

export default ReviewQualificationQuestions;
