import React from 'react';
import { Flex, Checkbox } from 'component-library';

interface BogoDealCheckboxProps {
  isChecked: boolean;
  onChange: () => void;
  programTaxYear: number;
}

export const BogoDealCheckbox: React.FC<BogoDealCheckboxProps> = ({
  isChecked,
  onChange,
  programTaxYear,
}) => (
  <Flex>
    <Checkbox
      noMargin
      options={[
        {
          text: `Apply special offer: Waive all my ${programTaxYear} fees when I sign up early for ${
            programTaxYear + 1
          }.`,
          checked: isChecked,
          value: 'opt_in_bogo',
        },
      ]}
      onChange={onChange}
      dataTestId='accept-bogo-checkbox'
    />
  </Flex>
);
