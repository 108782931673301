import * as React from 'react';
import { Button, Content, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Page } from '../lib/constants';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '50%',
    textAlign: 'center',
  },
});

type ErrorPageProps = {
  errorTitle: string;
  errorMsg: string;
  showBackToDashboard?: boolean;
};

const ErrorPage = ({
  errorTitle,
  errorMsg,
  showBackToDashboard = false,
}: ErrorPageProps) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Content
      alignItems='center'
      flex
      flexDirection='column'
      justifyContent='center'
      className={classes.root}
      gap={24}
    >
      <Content gap={8}>
        <Text size={32} variant='medium'>
          {errorTitle}
        </Text>
        <Text>{errorMsg}</Text>
      </Content>
      {showBackToDashboard && (
        <Button
          label='Back to dashboard'
          onClick={() => {
            history.push(`/${Page.dashboard}`);
          }}
        />
      )}
    </Content>
  );
};

export default ErrorPage;
