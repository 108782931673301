import * as React from 'react';
import { makeStyles, Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    margin: '48px',
    maxWidth: '900px',
  },
  backToLink: {
    textDecoration: 'underline',
  },
}));

export const ProducingDocumentation = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Typography component='div' variant='h1'>
          Thank You for Confirming
        </Typography>
        <p>
          Thank you for confirming the qualified research expenses. We will now
          produce the necessary documentation and follow-up shortly with
          instructions on how to file for and use the credit.
        </p>

        <Link className={classes.backToLink} color='inherit' href='/'>
          Back to Home
        </Link>
      </div>
      <div></div>
    </div>
  );
};
