import React from 'react';
import { Text, Content, Color, Card, Button, Link } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { ExpectedPaymentDirection } from 'lib/interfaces';

const useStyles = makeStyles(() => ({
  orText: {
    margin: '24px 0',
  },
  manuallyLink: {
    marginBottom: '8px',
  },
}));

interface TransferOptionsProps {
  direction: ExpectedPaymentDirection;
  setAchTransfer: (enabled: boolean) => void;
}

export const TransferOptions = ({
  direction,
  setAchTransfer,
}: TransferOptionsProps) => {
  const classes = useStyles();

  const isDebitTransferOutTransaction =
    direction === ExpectedPaymentDirection.DEBIT;

  return (
    <div>
      <Content paddingLeftRight={24} paddingTopBottom={24}>
        <Card noMargin>
          <Content paddingLeftRight={24} paddingTopBottom={24}>
            <Text text='Connect your bank account' variant='medium' />
            <Text
              text='This is the easiest way to start funding your MainStreet Yield account.'
              paddingBottom={16}
            />
            <Button
              label='Connect bank account'
              onClick={() => setAchTransfer(true)}
            />
          </Content>
        </Card>
        <Text
          text='— OR —'
          color={Color.neutral._60}
          variant='medium'
          className={classes.orText}
        />
        <Link
          external
          text={`Transfer ${
            isDebitTransferOutTransaction ? 'out' : 'in'
          } manually`}
          variant='medium'
          onClick={() => setAchTransfer(false)}
          className={classes.manuallyLink}
        />
        <Text
          text={
            isDebitTransferOutTransaction
              ? 'Fill out a transfer form and one of our specialists will contact you on the next business day to confirm transfer details.'
              : 'Instructions to complete send a wire transfer directly from your bank.'
          }
        />
      </Content>
    </div>
  );
};
