import React, { useState } from 'react';
import StepsContainer from '../../component/StepsContainer';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import {
  Page,
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
  SurveyNameEnum,
  AutoqualificationStatusEnum,
} from 'lib/constants';
import { SurveyQuestion } from 'component-library';
import { AnswerType } from 'component-library/dashboard/surveyquestion/SurveyQuestion';
import { Loading } from 'components/util/Loading';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useCommonStores, useFeatureFlags } from 'stores/useStores';
import { QualificationQuestions } from 'lib/interfaces';
import { setDocumentPageTitle, useEffectOnce } from 'lib/helpers';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';

interface CreditOverviewProps {
  taxYear: number;
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
}

export const CreditOverview = observer(
  ({ taxYear, pathTo, pathFrom, onNext, onBack }: CreditOverviewProps) => {
    const [error, setError] = useState('');
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const { app } = useCommonStores();
    const featureFlags = useFeatureFlags();
    const showQualDqExperience = featureFlags.showQualificationDqExperience;

    const onContinue = () => {
      setButtonLoading(true);
      saveAnswers()
        .then(async ({ autoqualificationStatus }) => {
          setError('');

          if (
            autoqualificationStatus === AutoqualificationStatusEnum.DISQUALIFIED
          ) {
            await app.common.companyStore.refreshCurrentCompany();
            if (showQualDqExperience) {
              app.history.push(
                `/${Page.taxCredits}/${Page.disqualified}/${taxYear}`,
              );
            } else {
              app.history.push(`/`);
            }
            return;
          }

          app.history.push(pathTo);
          onNext();
          setButtonLoading(false);
        })
        .catch((err) => {
          datadogLogs.logger.error(
            '[QUALIFICATION]: saving answers failed in CreditOverview section',
            logContext({
              error: err,
              company: app.company,
            }),
          );
          setError('Something went wrong.');
        });
    };

    const onBackStep = () => {
      onBack();
      app.history.push(pathFrom);
    };

    const acquiredAnotherCompany = (surveyAnswers: QualificationQuestions) =>
      surveyAnswers[
        GraphCmsQuestionIdEnum.ACQUIRED_ANOTHER_COMPANY_OR_HAS_SUBSIDIARIES
      ] ===
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.ACQUIRED_ANOTHER_COMPANY_OR_HAS_SUBSIDIARIES
      ]?.YES_ACQUIRED_FULL_BUSINESS;

    const majorityOwnerOwnsMoreThanFiftyPercentInAnotherCompany = (
      surveyAnswers: QualificationQuestions,
    ) =>
      surveyAnswers[GraphCmsQuestionIdEnum.ANYONE_OWN_MORE_THAN_50PERCENT] ===
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.ANYONE_OWN_MORE_THAN_50PERCENT
      ]?.YES;

    const {
      isSurveyComplete,
      isLoading,
      questionsToRender,
      addSurveyAnswers,
      getQuestionData,
      saveAnswers,
      surveyAnswers,
    } = useSurveyQuestions(
      SurveyNameEnum.RD_CREDIT_AUTOQUAL_OVERVIEW,
      taxYear,
      {
        shortCircuitConditions: [
          () =>
            Object.keys(questionsToRender).length > 0 &&
            (acquiredAnotherCompany(surveyAnswers) ||
              majorityOwnerOwnsMoreThanFiftyPercentInAnotherCompany(
                surveyAnswers,
              )),
          () =>
            Object.keys(questionsToRender).length > 0 &&
            surveyAnswers[
              GraphCmsQuestionIdEnum.ENTITY_TYPE_TRANSITION_YEAR
            ] === new Date().getFullYear(),
        ],
        showConditionOverrides: {
          [GraphCmsQuestionIdEnum.GREATER_THAN_FIVE_MIL_RECEIPTS]: () => {
            const hasGeneratedRevenueQuestion = getQuestionData(
              GraphCmsQuestionIdEnum.HAS_GENERATED_REVENUE,
            );
            const firstYearOfGrossReceiptsQuestion = getQuestionData(
              GraphCmsQuestionIdEnum.FIRST_YEAR_WITH_INCOME,
            );
            if (
              hasGeneratedRevenueQuestion &&
              hasGeneratedRevenueQuestion.answerValue &&
              firstYearOfGrossReceiptsQuestion &&
              firstYearOfGrossReceiptsQuestion.answerValue
            ) {
              const answeredYesToHasGeneratedRevenue =
                hasGeneratedRevenueQuestion.answerValue ===
                GraphCmsQuestionIdToAnswers[
                  GraphCmsQuestionIdEnum.HAS_GENERATED_REVENUE
                ].YES;
              return (
                answeredYesToHasGeneratedRevenue &&
                firstYearOfGrossReceiptsQuestion.answerValue >= taxYear - 5
              );
            }
            return false;
          },
        },
      },
    );

    // this was added as part of migrating these questions from code to cms and to maintain the same look and feel as the existing implementation,
    // without affecting these questions' appearance in other places.
    const getAnswerType = (answerType: AnswerType) => {
      switch (answerType) {
        case 'yes_no_or_unknown':
        case 'yes_or_no':
          return 'multiple_choice';

        default:
          return answerType;
      }
    };

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => {
          return (
            <SurveyQuestion
              key={question.id}
              dataTestId={question.id}
              answerType={getAnswerType(question.answerType as AnswerType)}
              text={question.text}
              subtitle={question.subtitle}
              answerOptions={question.answerIDs}
              onChange={(answer) => {
                question.answerValue = answer;
                addSurveyAnswers(question.id, key, [
                  { questionId: question.id, answerValue: answer },
                ]);
              }}
              placeholder={question.placeholder}
              answerValue={question.answerValue}
              tooltip={question.tooltip}
              questionTextSize={app.isMobile ? 15 : 18}
              questionSubtitleSize={app.isMobile ? 13 : 15}
            />
          );
        });
      });

    useEffectOnce(() =>
      setDocumentPageTitle('Qualification - Credit Overview'),
    );

    return (
      <StepsContainer
        onBackStep={onBackStep}
        showContinueButton={isSurveyComplete}
        onContinue={onContinue}
        onContinueLabel='Continue'
        isLoading={buttonLoading}
        stepTitle='R&D credit overview'
        subtitle="This information helps us confirm your company's credit estimate."
        errorMsg={error}
      >
        <Loading loading={isLoading}>
          {!_.isEmpty(questionsToRender) && renderQuestions()}
        </Loading>
      </StepsContainer>
    );
  },
);
