import * as React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Color, Divider, Flex, Heading, Text } from 'component-library';
import { observer } from 'mobx-react';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  referralContainer: {
    background: Color.neutral.white,
    boxShadow: '0px 0px 9px rgba(13, 13, 13, 0.08)',
    borderRadius: '4px',
    padding: '24px 24px 8px',
  },
  instructionsContainer: {
    padding: '24px 0px',
  },
  subheaderText: {
    marginBottom: '34px',
  },
  growsurfContainer: {
    width: '100%',
  },
}));

interface Props {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export const ReferSettings = observer(
  ({ email, firstName, lastName }: Props) => {
    const {
      app: { isMobile },
    } = useCommonStores();
    const classes = useStyles();
    const [ID, setID] = useState<string | null>(null);

    return (
      <div>
        <Heading
          tag='h1'
          variant={'medium'}
          text='Get 15% off, for each startup you refer*'
          size={36}
        />
        <Flex direction={'row'} gap={40}>
          <Flex
            direction={'column'}
            alignItems={'flex-start'}
            justifyContent={'center'}
            className={classes.referralContainer}
            gap={24}
          >
            <Flex.Cell padding={[0, 0, 0, 0]}>
              {email == null && ID == null ? (
                <Text>Sign Up</Text>
              ) : (
                <Heading
                  size={18}
                  variant={'medium'}
                  color={Color.neutral._80}
                  noMargin
                >
                  You can share this link
                </Heading>
              )}
            </Flex.Cell>
            <Flex.Cell className={classes.growsurfContainer}>
              <div
                data-grsf-block-form
                data-grsf-email={email}
                data-grsf-first-name={firstName}
                data-grsf-last-name={lastName}
                data-grsf-share-instructions=''
                data-grsf-share-instructions-style="{'margin':'0px','padding': '0px'}"
                data-grsf-copy-link-container-style="{'margin':'0px','padding': '0px'}"
                data-grsf-button-style="{'background-color': '#1F44CC','padding': '0px'}"
              ></div>
            </Flex.Cell>
            <Divider variant={'no-bottom-margin'} />
            <Flex.Cell className={classes.growsurfContainer}>
              {(email || ID) && (
                <Heading size={18} variant={'medium'} color={Color.neutral._80}>
                  Or we can send them an email
                </Heading>
              )}
              <div
                data-grsf-block-invite
                data-grsf-instructions-text=''
                data-grsf-instructions-style="{'margin-top':'0px', 'padding-top': '0px', 'padding-bottom':'0px','margin-bottom':'0px'}"
                data-grsf-button-style="{'background-color': '#1F44CC'}"
                data-grsf-preview-link-text='PREVIEW EMAIL'
                data-grsf-link-style="{'font-size': '13px', 'padding': '10px', 'margin-top': '24px'}"
              ></div>
            </Flex.Cell>
          </Flex>
          <Flex>{!isMobile && <HowReferralWorks />}</Flex>
        </Flex>
      </div>
    );
  },
);

const HowReferralWorks = () => {
  const classes = useStyles();

  const step = (num: number, text: string) => (
    <Flex direction={'row'} gap={16} padding={[0, 0, 24, 0]}>
      <Text variant={'medium'} size={15} color={Color.neutral._80}>
        {num}.
      </Text>
      <Text size={15} variant={'medium'} color={Color.neutral._80}>
        {text}
      </Text>
    </Flex>
  );

  return (
    <Flex
      direction={'column'}
      alignItems={'flex-start'}
      gap={8}
      className={classes.instructionsContainer}
    >
      <Heading
        size={18}
        variant={'medium'}
        marginBottom={16}
        color={Color.neutral._80}
      >
        How it Works
      </Heading>
      <Flex direction={'column'} alignItems={'flex-start'}>
        {step(1, 'Share your referral link with another startup')}
        {step(2, 'They qualify and sign up for a MainStreet program')}
        {step(3, 'When they join, your discount is added to your program!')}
        <Text size={13} color={Color.neutral._80}>
          *Each referral discount is valid for 12 months and activates when a
          new customer successfully signs up for a MainStreet Tax Credits
          program. Referrals are cumulative, and a new 12-month cycle starts
          when each referral signs up. Some restrictions may apply.
        </Text>
      </Flex>
    </Flex>
  );
};
