import { makeStyles, Theme } from '@material-ui/core';
import { Document, DocumentSourceEnum } from 'lib/interfaces';
import * as React from 'react';
import {
  Button,
  Card,
  Content,
  Message,
  Table,
  Text,
  Toast,
} from 'component-library';
import { Loading } from 'components/util/Loading';
import { EmptyDocumentList } from './../EmptyDocumentList';
import { CustomerDocumentUploadDrawer } from '../CustomerDocumentUploadDrawer';
import { useState } from 'react';

export interface DocumentListProps {
  title: string;
  documents: Document[];
  token?: string;
  companyEmail?: string;
  programId?: number;
  loading: boolean;
  columns: any;
  uploadsEnabled?: boolean;
  setDocuments?: (val: Document[]) => void;
  dataTestId?: string;
  source?: DocumentSourceEnum;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  card: {
    margin: spacing(2, 0),
  },
}));

export const DocumentList = ({
  title,
  documents,
  loading,
  columns,
  uploadsEnabled,
  setDocuments,
  dataTestId,
  source,
}: DocumentListProps) => {
  const classes = useStyles();
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = React.useState(false);
  const [toasts, setToasts] = useState<Message[]>([]);

  return (
    <div data-testid={dataTestId}>
      <Content
        flex
        justifyContent='space-between'
        alignItems='center'
        paddingBottom={0}
      >
        <Text size={18} tag='p' variant='medium'>
          {title}
        </Text>
        {uploadsEnabled && (
          <Button
            label='Upload'
            variant='outlined'
            dataTestId={'document-card-upload-button'}
            small={true}
            onClick={() => setIsUploadDrawerOpen(true)}
          />
        )}
      </Content>
      <Loading loading={loading}>
        <Card className={classes.card}>
          {documents.length > 0 ? (
            <Table
              columns={columns}
              data={documents}
              paginationShowResultsLabel
              paginationSizePerPage={5}
              sortBy={[{ id: 'created', desc: true }]}
            />
          ) : (
            <EmptyDocumentList loading={loading} />
          )}
        </Card>
      </Loading>
      <CustomerDocumentUploadDrawer
        isOpen={isUploadDrawerOpen}
        setIsOpen={(open: boolean) => setIsUploadDrawerOpen(open)}
        source={source}
        onSuccessfulUpload={(uploadedDocuments: Document[]): void => {
          if (setDocuments && documents) {
            setToasts((toasts) => [
              ...toasts,
              { text: 'Document(s) uploaded', status: 'success' },
            ]);
            setDocuments(documents.concat(uploadedDocuments));
          }
        }}
      />
      <Toast setToast={setToasts} toasts={toasts} />
    </div>
  );
};
