import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  Content,
  Color,
  Message,
  Text,
  Toast,
} from 'component-library';
import { Document } from 'lib/interfaces';
import { CustomerDocumentUploadDrawer } from './CustomerDocumentUploadDrawer';

const useStyles = makeStyles(({ boxShadowRegular, spacing }: Theme) => ({
  root: {
    boxShadow: boxShadowRegular,
    background: Color.neutral.white,
    margin: spacing(0, 0, 8),
  },
  title: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '27px',
    padding: spacing(4),
  },
  content: {
    padding: (noPadding: boolean) => (noPadding ? 0 : spacing(4)),
  },
}));

export interface DocumentsCardProps {
  title: string;
  noPadding?: boolean;
  uploadsEnabled?: boolean;
  allDocuments?: Document[];
  setAllDocuments?: (val: Document[]) => void;
}

export const DocumentsCard: FunctionComponent<DocumentsCardProps> = ({
  title,
  noPadding,
  uploadsEnabled,
  allDocuments,
  setAllDocuments,
  children,
}) => {
  const classes = useStyles(!!noPadding);
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = React.useState(false);
  const [toasts, setToasts] = useState<Message[]>([]);

  return (
    <>
      <Content
        flex
        justifyContent='space-between'
        alignItems='center'
        paddingBottom={16}
      >
        <Text size={18} variant='medium'>
          {title}
        </Text>
        {uploadsEnabled && (
          <Button
            label='Upload'
            variant='outlined'
            dataTestId={'document-card-upload-button'}
            small={true}
            onClick={() => setIsUploadDrawerOpen(true)}
          />
        )}
      </Content>
      <div className={classes.root}>
        <div className={classes.content}>{children}</div>
      </div>
      <CustomerDocumentUploadDrawer
        isOpen={isUploadDrawerOpen}
        setIsOpen={(open: boolean) => setIsUploadDrawerOpen(open)}
        onSuccessfulUpload={(uploadedDocuments: Document[]): void => {
          if (setAllDocuments && allDocuments) {
            setToasts([{ text: 'Document(s) uploaded', status: 'success' }]);
            setAllDocuments(allDocuments.concat(uploadedDocuments));
          }
        }}
      />
      <Toast setToast={setToasts} toasts={toasts} />
    </>
  );
};
