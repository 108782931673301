import { RootStore } from 'stores/RootStore';
import { AccountCreationStore } from 'stores/accountcreation/AccountCreationStore';

export class AccountCreationNamespace {
  public accountCreation: AccountCreationStore;

  constructor(rootStore: RootStore) {
    this.accountCreation = new AccountCreationStore(rootStore);
  }
}
