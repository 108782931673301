import React, { useState } from 'react';
import { LoadingPage } from './LoadingPage';
import { Modal, Content, Text, Button } from 'component-library';
import {
  Page,
  RIPPLING_IMPORT_TYPE_KEY,
  RIPPLING_REDIRECT_PATHNAME,
  RIPPLING_REDIRECT_SEARCH,
  RIPPLING_TAX_YEAR_KEY,
} from '../lib/constants';
import { observer } from 'mobx-react';
import { useCommonStores, useLegacyClients } from 'stores/useStores';
import { datadogLogs } from '@datadog/browser-logs';
import { ImportType } from '../lib/interfaces';

const LOCAL_STORAGE_KEY_NAME = 'rippling-auth-code';
const logger = datadogLogs.createLogger('PayrollRipplingIntegration');

/**
 * This page handle redirection flow when integrating with rippling. Multiple
 * case should be handled:
 * 1 - The customer is already logged in into MainStreet
 * We fetch a fresh access_token and call our API to register rippling code.
 * We then redirect the customer to the on-boarding flow to complete their
 * application or land into the home page.
 * 2 - The customer is not logged in but does have a MainStreet account
 * We redirect to the welcome page, the customer click on the "Log in here" link.
 * They get redirected to auth0, fill their credential and get redirected to the
 * rippling payroll integration page. We then go back to case 1.
 * 3 - The customer is not logged in and does NOT have a MainStreet account
 * We redirect them to the welcome page, the customer fill the registration form and
 * click "Create account" button. The customer gets redirected to the
 * rippling payroll integration page. We then go back to case 1.
 *
 * @constructor
 */
export const PayrollRipplingIntegration = observer(() => {
  const { client } = useLegacyClients();
  const { auth } = useCommonStores();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const ripplingAppListing =
    process.env.REACT_APP_RIPPLING_APP_LISTING ||
    'https://app.ripplingsandbox.com/apps/PLATFORM/MainStreet';

  const redirectToWelcomePage = React.useCallback(() => {
    const url = new URL(window.location.origin);
    url.pathname = `/${Page.selfSignupWelcome}`;
    url.searchParams.set('redirectPath', `/${Page.payrollIntegrationRippling}`);
    // We need to do a full reload for the welcome page to be rendered properly
    window.location.href = url.toString();
  }, []);

  const initialize = React.useCallback(async () => {
    const getCodeFromUrl = (): string | null => {
      const params = new URL(window.location.href).searchParams;
      // If we have a state param, that means we are being redirected from auth0
      // and we should ignore the code query param being used
      if (params.get('state')) {
        return null;
      }
      // Get rippling code from query param
      return params.get('code');
    };
    const getRipplingCode = (): string | null =>
      getCodeFromUrl() || localStorage.getItem(LOCAL_STORAGE_KEY_NAME);
    const ripplingCode = getRipplingCode();
    if (!ripplingCode) {
      logger.error('Unable to find ripplingCode for payroll integration');
      setError('Click "Finish process" to initiate rippling integration');
      setShowErrorModal(true);
      return;
    }

    if (!auth.isAuthenticated) {
      localStorage.setItem(LOCAL_STORAGE_KEY_NAME, ripplingCode);
      redirectToWelcomePage();
      return;
    }

    try {
      // if we can't read localstorage then assume default with no year.
      const importType = localStorage.getItem(RIPPLING_IMPORT_TYPE_KEY)
        ? (localStorage.getItem(RIPPLING_IMPORT_TYPE_KEY) as ImportType)
        : ImportType.ConnectionsPage;
      const taxYear = localStorage.getItem(RIPPLING_TAX_YEAR_KEY)
        ? Number(localStorage.getItem(RIPPLING_TAX_YEAR_KEY))
        : undefined;

      const res = await client.AddRipplingToken(
        ripplingCode,
        importType,
        taxYear,
      );
      if (res.errorMsg) {
        throw new Error(res.errorMsg);
      }
    } catch (err) {
      logger.error(`Unable to add rippling token`, err as Error);
      setError((err as Error).message);
      setShowErrorModal(true);
      return;
    }

    // Navigate to URL that was saved before we were redirected to Rippling, so we can return to EC flow or qualification flow.
    const url = new URL(window.location.origin);
    if (localStorage.getItem(RIPPLING_REDIRECT_PATHNAME)) {
      url.pathname = `${localStorage.getItem(RIPPLING_REDIRECT_PATHNAME)}`;
      if (localStorage.getItem(RIPPLING_REDIRECT_SEARCH)) {
        url.search = `${localStorage.getItem(RIPPLING_REDIRECT_SEARCH)}`;
      }
    } else {
      // We need to do a full reload for the integration page to be rendered properly
      url.pathname = `/${Page.connections}`;
    }

    window.location.href = url.toString();
  }, [client, auth, redirectToWelcomePage]);

  React.useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <>
      <LoadingPage />
      <Modal
        showModal={showErrorModal}
        closeToggle={() => setShowErrorModal(false)}
        backdrop={'static'}
      >
        <>
          <Content paddingTopBottom={24} paddingLeftRight={24}>
            <Text variant='medium' size={18} text='An error happened' />
            <Text dataTestId='error-message' text={error || ''} />
          </Content>
          <Content gap={16} flex paddingLeftRight={24}>
            <Button
              variant='outlined'
              label='Go to rippling'
              onClick={{
                location: ripplingAppListing,
                external: true,
                target: '_self',
              }}
            />
          </Content>
        </>
      </Modal>
    </>
  );
});
