import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Button,
  Card,
  Color,
  Content,
  Link,
  Modal,
  Stepper,
  Text,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { CompanyContext } from 'pages/CompanyRequired';
import { Page } from 'lib/constants';

import { SignatureFlowModalContent } from './SignatureFlowModalContent';

import { AccreditationStatusEnum, KycApplicationStatus } from 'lib/constants';
import {
  KycApplicationStage,
  PromissoryNote,
} from '../../../../lib/interfaces';
import { IsProd } from 'pages/kyc/components/helpers';

const useStyles = makeStyles(() => ({
  maxWidth: {
    'max-width': '100% !important',
  },
  topMargin: {
    marginTop: '8px',
  },
}));

interface OnboardingCardProps {
  onFinalCompletion: () => void;
}

export const OnboardingCard = ({ onFinalCompletion }: OnboardingCardProps) => {
  const { client } = useContext(Auth0FeatureContext);
  const companyContext = useContext(CompanyContext);
  const classes = useStyles();
  const history = useHistory();

  const [accreditationStatus] = useState<AccreditationStatusEnum | undefined>(
    companyContext.company.accreditationStatus,
  );
  const [kycApplicationStatus, setKycApplicationStatus] = useState<
    KycApplicationStatus | undefined
  >();
  const [kycApplicationStage, setKycApplicationStage] = useState<
    KycApplicationStage | undefined
  >();
  const [promissoryNote, setPromissoryNote] = useState<PromissoryNote>();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isSignModalOpen, setIsSignModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!kycApplicationStatus) {
      client.GetKycApplicationStatus().then(({ data }) => {
        if (data) {
          const {
            kycApplicationStatus,
            kycApplicationStage,
            promissoryNote,
            fixedReturnPercent,
          } = data;
          setKycApplicationStatus(kycApplicationStatus as KycApplicationStatus);
          setKycApplicationStage(kycApplicationStage as KycApplicationStage);
          if (promissoryNote) {
            setPromissoryNote({
              ...promissoryNote,
              fixedReturnPercent,
            });
          }

          if (
            kycApplicationStatus === KycApplicationStatus.APPROVED &&
            accreditationStatus === AccreditationStatusEnum.ACCREDITED
          ) {
            setCurrentStep(promissoryNote ? 3 : 2);
          }
        } else {
          setCurrentStep(1);
        }
      });
    }
  }, [
    client,
    accreditationStatus,
    kycApplicationStatus,
    companyContext.company.id,
    currentStep,
  ]);

  const isRejected = () => {
    return (
      kycApplicationStatus === KycApplicationStatus.REJECTED ||
      accreditationStatus === AccreditationStatusEnum.NOT_ACCREDITED
    );
  };

  const inReview = () => {
    return (
      kycApplicationStatus === KycApplicationStatus.IN_REVIEW ||
      accreditationStatus === AccreditationStatusEnum.IN_REVIEW
    );
  };

  const isAccepted = () => {
    return (
      kycApplicationStatus === KycApplicationStatus.APPROVED &&
      accreditationStatus === AccreditationStatusEnum.ACCREDITED
    );
  };

  const isBusinessSectionCompleted = () => {
    return (
      kycApplicationStage ===
      KycApplicationStage.BUSINESS_ACCREDITATION_APPLICATION_COMPLETED
    );
  };

  const isOwnerSectionCompleted = () => {
    return (
      kycApplicationStage ===
      KycApplicationStage.OWNER_INFORMATION_APPLICATION_COMPLETED
    );
  };

  const getVerificationStepperDetails = () => {
    if (isRejected()) {
      return 'We were not able to verify your information. If you think there was a mistake, please contact our team.';
    } else if (isAccepted()) {
      return (
        <Text size={15}>
          Thank you! Your company information has been verified.
        </Text>
      );
    } else if (isOwnerSectionCompleted()) {
      return (
        <Text size={15}>
          We&apos;re reviewing your company details and owner information.
          Expect to hear from us in the next business day.
        </Text>
      );
    } else {
      return (
        <Content flex flexDirection='column' gap={8}>
          <Text
            text={
              isBusinessSectionCompleted()
                ? 'Please continue verifying your information so we can open your account.'
                : 'This one-time step allows us to verify your company information, owners, and accreditation status all at once.'
            }
            size={15}
          />
          <Content>
            <Button
              onClick={() => {
                if (isBusinessSectionCompleted()) {
                  history.push(`/${Page.kycBeneficiaryDetails}`);
                } else {
                  history.push(`/${Page.kycApplication}`);
                }
              }}
              label={
                isBusinessSectionCompleted()
                  ? 'Continue verification'
                  : 'Start verification'
              }
              small={true}
            />
          </Content>
          <Text
            text='Look out for an email from us once your credentials are reviewed (within one business day).'
            color={Color.neutral._60}
            size={15}
          />
        </Content>
      );
    }
  };

  const getAccountConfigurationDescription = () => {
    if (currentStep === 2) {
      return 'Our team is setting up your account to receive funds and preparing your customized offering documents.';
    }
    if (currentStep > 2) {
      return 'Your account is set up and the offering docs are ready to be signed.';
    }
    return 'Our team will set up your account and prepare it to receive funds.';
  };

  const getAgreementDocumentsDescription = () => {
    const docLinks = [
      {
        text: 'Private Placement Memorandum',
        stagingLink:
          'https://storage.googleapis.com/treasury-service-documents.staging.mainstreet.com/MainStreet%20Yield%20LLC%20-%20Private%20Placement%20Memorandum.pdf',
        productionLink:
          'https://storage.googleapis.com/treasury-service-documents.mainstreet.com/MainStreet%20Yield%20LLC%20-%20Private%20Placement%20Memorandum.pdf',
        description:
          'Detailed description of the investment strategy of the High Yield Account, the Promissory Note terms, and associated risks.',
      },
      {
        text: 'Promissory note',
        stagingLink:
          'https://storage.googleapis.com/treasury-service-documents.staging.mainstreet.com/MainStreet%20Yield%20LLC%20-%20Note%20Investment.pdf',
        productionLink:
          'https://storage.googleapis.com/treasury-service-documents.mainstreet.com/MainStreet%20Yield%20LLC%20-%20Note%20Investment.pdf',
        description:
          'The primary agreement that governs the terms of your investment in the High Yield Account.',
      },
      {
        text: 'Note purchasing agreement',
        stagingLink:
          'https://storage.googleapis.com/treasury-service-documents.staging.mainstreet.com/MainStreet%20Yield%20LLC%20-%20Purchase%20Agreement.pdf',
        productionLink:
          'https://storage.googleapis.com/treasury-service-documents.mainstreet.com/MainStreet%20Yield%20LLC%20-%20Purchase%20Agreement.pdf',
        description:
          'A certificate that proves your participation in the High Yield Account.',
      },
      {
        text: 'LLC agreement',
        stagingLink:
          'https://storage.googleapis.com/treasury-service-documents.staging.mainstreet.com/MainStreet%20Yield%20LLC%20-%20Amended%20and%20Restated%20LLC%20Agreement.pdf',
        productionLink:
          'https://storage.googleapis.com/treasury-service-documents.mainstreet.com/MainStreet%20Yield%20LLC%20-%20Amended%20and%20Restated%20LLC%20Agreement.pdf',
        description:
          'The operating agreement of the legal entity that will receive and invest the funds you contribute to the High Yield Account.',
      },
    ];

    const showDocLinks =
      (isAccepted() || isOwnerSectionCompleted()) && promissoryNote;

    return (
      <Content flex flexDirection='column' gap={8}>
        <Text
          text='Please take a moment to review the offering documents.'
          size={15}
        />
        {showDocLinks && (
          <>
            {docLinks.map(
              ({ text, productionLink, stagingLink, description }) => (
                <div key={text}>
                  <Link
                    external
                    href={IsProd() ? productionLink : stagingLink}
                    size={15}
                    text={text}
                    variant='medium'
                  />
                  <Text
                    text={description}
                    color={Color.neutral._60}
                    size={15}
                  />
                </div>
              ),
            )}
            {currentStep === 3 && (
              <Content>
                <Button
                  label='Continue'
                  onClick={() => setCurrentStep(4)}
                  className={classes.topMargin}
                  small={true}
                />
              </Content>
            )}
          </>
        )}
      </Content>
    );
  };

  const steps = [
    {
      step: isRejected()
        ? 'Company information or owner verification failed'
        : 'Verify your company information and owners',
      description: getVerificationStepperDetails(),
      failed: isRejected(),
      inProgress: inReview(),
      stepCta: isRejected()
        ? {
            href: 'mailto:yieldteam@mainstreet.com',
            text: 'Contact the High Yield Account team for support',
          }
        : undefined,
    },
    {
      step: 'Account configuration',
      description: (
        <Text text={getAccountConfigurationDescription()} size={15} />
      ),
    },
    {
      step: 'Review documents',
      description: getAgreementDocumentsDescription(),
    },
    {
      step: 'Sign documents',
      description: (
        <Content flex flexDirection='column' gap={8}>
          <Text
            text='Once reviewed, please sign the Purchase Agreement to complete the process. Fully executed documents will be available under the Documents tab.'
            size={15}
          />
          {currentStep === 4 && !isSignModalOpen && (
            <Content>
              <Button
                label='Generate signing documents'
                onClick={() => setIsSignModalOpen(true)}
              />
            </Content>
          )}
        </Content>
      ),
    },
  ];

  const getFailedSteps = () => {
    const failedSteps: number[] = [];
    steps.forEach((step, i) => {
      if (step.failed) {
        failedSteps.push(i + 1);
      }
    });

    return failedSteps;
  };

  const getInProgressSteps = () => {
    const inProgressSteps: number[] = [];
    steps.forEach((step, i) => {
      if (step.inProgress) {
        inProgressSteps.push(i + 1);
      }
    });

    return inProgressSteps;
  };

  const handleSuccessfulSigning = () => {
    setCurrentStep(5);
    setIsSignModalOpen(false);
    setTimeout(() => onFinalCompletion(), 4000);
  };

  return (
    <>
      <Card>
        <Content>
          <Text
            variant='regular'
            size={23}
            text="Let's get started! Complete these steps to activate your High Yield Account."
          />

          <Stepper
            className={classes.maxWidth}
            currentStep={currentStep}
            steps={steps}
            orientation='vertical'
            failedSteps={getFailedSteps()}
            inProgressSteps={getInProgressSteps()}
          />

          {promissoryNote && (
            <Modal
              showModal={isSignModalOpen}
              closeToggle={() => setIsSignModalOpen(false)}
            >
              <SignatureFlowModalContent
                promissoryNote={promissoryNote}
                onFinishSuccess={handleSuccessfulSigning}
              />
            </Modal>
          )}
        </Content>
      </Card>
    </>
  );
};
