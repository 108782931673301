import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { DocumentList } from 'pages/dashboard/documents';
import { Document, DocumentSourceEnum, ProgramData } from 'lib/interfaces';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { observer } from 'mobx-react';
import { useEffectOnce } from '../../../../lib/helpers';
import { ClientDocumentsColumns } from './documentList/ClientDocumentsColumns';
import { MainStreetDocumentsColumns } from './documentList/MainStreetDocumentsColumns';
import { ErrorMessage } from '../../../../components/util/ErrorMessage';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5) + 'px',
  },
  cell: {
    padding: '16px',
    whiteSpace: 'unset',
  },
  iconSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: spacing(5, 0, 5),
  },
}));

export interface DocumentsProps {
  programs: ProgramData[];
  token?: string;
  companyEmail?: string;
}

export const Documents = observer(
  ({ programs, token, companyEmail }: DocumentsProps) => {
    const { client } = useContext(Auth0FeatureContext);
    const classes = useStyles();

    const [clientLoading, setClientLoading] = useState<boolean>(true);
    const [mainStreetLoading, setMainStreetLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [clientDocuments, setClientDocuments] = useState<Document[]>([]);
    const [mainStreetDocuments, setMainStreetDocuments] = useState<Document[]>(
      [],
    );

    const refreshClientDocuments = async () => {
      setClientLoading(true);
      const res = await client.GetCompanyDocuments({ source: 'client' });
      setClientLoading(false);
      if (res.errorMsg) {
        setError(res.errorMsg);
        return;
      }
      const documents = res.data?.documents;
      if (documents) {
        setClientDocuments(documents);
      }
    };

    const refreshMainStreetDocuments = async () => {
      setMainStreetLoading(true);
      const res = await client.GetCompanyDocuments({ source: 'mainstreet' });
      setMainStreetLoading(false);
      if (res.errorMsg) {
        setError(res.errorMsg);
        return;
      }
      const documents = res.data?.documents;
      if (documents) {
        setMainStreetDocuments(documents);
      }
    };

    useEffectOnce(async () => {
      await refreshClientDocuments();
      await refreshMainStreetDocuments();
    });

    return (
      <div className={classes.root} data-testid={'mst-documents'}>
        <DocumentList
          dataTestId={'mainstreet-documents'}
          title={'Created by MainStreet'}
          documents={mainStreetDocuments}
          token={token}
          companyEmail={companyEmail}
          loading={mainStreetLoading}
          source={DocumentSourceEnum.MAINSTREET}
          columns={MainStreetDocumentsColumns(
            classes,
            programs,
            token,
            companyEmail,
          )}
        />

        <DocumentList
          dataTestId={'client-documents'}
          title={'Uploaded by you'}
          documents={clientDocuments}
          token={token}
          companyEmail={companyEmail}
          loading={clientLoading}
          source={DocumentSourceEnum.CLIENT}
          columns={ClientDocumentsColumns(classes, token, companyEmail)}
          uploadsEnabled={true}
          setDocuments={setClientDocuments}
        />
        {error && <ErrorMessage error={error} />}
      </div>
    );
  },
);
