import { z } from 'zod';

export enum HYSAActivationStatus {
  ACTIVATED = 'activated',
  IN_REVIEW = 'in_review',
  DEACTIVATED = 'deactivated',
}

export class TMAccount {
  company_id = 0;
  ledger_account_id?: string;
  counterparty_id?: string;
  external_account_id?: string;
  promissory_note_accepted_at?: string;
  activation_status?: HYSAActivationStatus;
}

export interface ModernTreasuryExpectedPayment {
  id: string;
  object: string;
  live_mode: boolean;
  type: string | null | undefined;
  amount_upper_bound: number;
  amount_lower_bound: number;
  direction: 'credit' | 'debit';
  internal_account_id: string;
  currency: string;
  date_upper_bound: string | null | undefined;
  date_lower_bound: string | null | undefined;
  description: string | null | undefined;
  statement_descriptor: string | null | undefined;
  remittance_information: string | null | undefined;
  metadata: {
    promissoryNoteId: string;
  };
  counterparty_id: string;
  transaction_id: string | null | undefined;
  transaction_line_item_id: string | null | undefined;
  status: ExpectedPaymentStatus;
  reconciliation_method: string | null | undefined;
  created_at: string;
  updated_at: string;
}

export enum ExpectedPaymentDirection {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export type TMStepStatus =
  | 'disabled'
  | 'uncompleted'
  | 'in_review'
  | 'rejected'
  | 'ready_to_sign'
  | 'ready_to_fund'
  | 'funded'
  | 'error';

export type ExpectedPaymentStatus =
  | 'created'
  | 'reconciled'
  | 'unreconciled'
  | 'archived';

export const IdempotencyIdRequestSchema = z.object({
  endpoint: z.string().url(),
  method: z.union([z.literal('POST'), z.literal('PUT'), z.literal('DELETE')]),
  parameters: z.array(z.any()),
  // The timestamp (i.e. epoch millis) of the request. Enforce a minimum of one
  // week from the epoch to prevent the window from ever being greater.
  timestamp: z
    .number()
    .min(7 * 24 * 60 * 60 * 1000)
    .default(Date.now()),
  // The window size (in milliseconds) for enforcing idempotency. All equivalent
  // requests submitted within the same window will be idempotent. We enforce a
  // max of one day and default to 10 seconds.
  window: z
    .number()
    .max(24 * 60 * 60 * 1000)
    .default(10 * 1000),
});

export type GenerateIdempotencyIdRequest = z.infer<
  typeof IdempotencyIdRequestSchema
>;

export interface WireInstructionsItems {
  account_number: string;
  routing_number: string;
  reference_code: string;
  depository_bank_name: string;
  credit_to: string;
  bank_phone_number: string;
  bank_address: string;
  beneficiary_address: string;
}
