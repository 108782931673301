import { observer } from 'mobx-react';
import {
  useCompany,
  useLegacyClients,
  useTaxCreditsStores,
  useCommonStores,
  useFeatureFlags,
} from 'stores/useStores';
import _ from 'lodash';
import {
  isQuestionAnswered,
  questionHasAnswer,
  SurveyAnswer,
  useSurveyQuestions,
} from 'lib/useSurveyQuestions';
import {
  CompanyDetailsGraphCmsQuestionIds,
  ExpenseClassificationProcessingSteps,
  GraphCmsQuestionIdEnum,
  Page,
  ProgramNameEnum,
  Programs,
  ProgramSubStageEnum,
  QualificationStatusEnum,
  ResearchTimeGraphCmsQuestionIds,
  SubGroupNameEnum,
  SurveyNameEnum,
  taxInfoQuestionIds,
  AttestationEventTypeEnum,
  ProgramStageEnum,
} from 'lib/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  CmsQuestionData,
  CmsRenderTreeShowCondition,
  CmsRenderTreeShowConditions,
  CmsRenderTreeSubgroup,
  GraphCMSAnswerTypeEnum,
  ProgramData,
} from 'lib/interfaces';
import {
  ExpandableSurveyQuestion,
  HeaderBar,
  StepsContainer,
  SurveyAttestation,
} from '../components';
import { SaveSurveyAttestation } from '../components/expense-classification/SurveyAttestation';
import ReviewQualificationQuestions from '../components/company-details/ReviewQualificationQuestions';
import TotalIncomeGrossReceiptsHelpDrawer from '../components/company-details/TotalIncomeGrossReceiptsHelpDrawer';
import OtherCreditsHelpDrawer from '../components/company-details/OtherCreditsHelpDrawer';
import GeorgiaRdGrossReceiptsHelpDrawer from '../components/company-details/GeorgiaRdGrossReceiptsHelpDrawer';
import { Loading } from 'components/util/Loading';
import { Color, Content, Delay, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { renderTaxYearInQuestionText } from 'lib/useQuestionGroup';
import { useExpenseClassificationAnswerPrefill } from 'lib/useExpenseClassificationAnswerPrefill';
import { IsRDCreditProgram } from 'lib/helpers';
import { getAlertBySubGroup } from '../ExpenseClassification';
import { datadogLogs } from '@datadog/browser-logs';
import TaxReturnUploadQuestion from '../components/company-details/TaxReturnUploadQuestion';

const logger = datadogLogs.createLogger('CompanyDetails');

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 80px',
    maxWidth: '1050px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: '100vh',
    backgroundColor: Color.neutral.white,
  },
  mainContent: {
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
    padding: '0 24px 80px',
    boxSizing: 'border-box',
    position: 'relative',
  },
}));

interface CompanyDetailsStepProps {
  programId: number;
  onError: () => void;
}

interface SubGroupTitle {
  title?: string;
  subtitle?: string;
}

const getCustomQuestionSubtitle = (
  question: CmsQuestionData,
  program: ProgramData,
): string | undefined => {
  if (
    question.id ===
      CompanyDetailsGraphCmsQuestionIds.PRIOR_YEARS_RESEARCH_SPEND &&
    !_.isEmpty(question.subQuestions) &&
    question.subQuestions![0].answerType ===
      GraphCMSAnswerTypeEnum.PERCENTAGE &&
    questionHasAnswer(question)
  ) {
    const subQuestionsWithAnswers =
      question.subQuestions?.filter((subQuestion) =>
        _.isNumber(subQuestion.answerValue),
      ) ?? [];
    return subQuestionsWithAnswers
      .map((subQuestion) => {
        const questionYear = subQuestion.text?.match(/\d{4}/g);
        return questionYear && questionYear.length > 0
          ? `${questionYear[0]}: ${subQuestion.answerValue}% of ${program.taxYear}`
          : null;
      })
      .filter((q) => q)
      .join(' | ');
  }
};

export const CompanyDetailsStep = observer(
  ({ programId, onError }: CompanyDetailsStepProps) => {
    const classes = useStyles();
    const { client } = useLegacyClients();
    const { taxCreditsPage } = useTaxCreditsStores();
    const { company } = useCompany();
    const { auth, chatbot } = useCommonStores();
    const featureFlags = useFeatureFlags();
    const [program, setProgram] = useState<ProgramData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [taxYear, setTaxYear] = useState<number>(0);
    const [renderQuestionOverrides, setRenderQuestionOverrides] = useState<
      { [key: string]: () => boolean } | undefined
    >(undefined);
    const [hasConfirmedAnswers, setHasConfirmedAnswers] =
      useState<boolean>(false);
    const [isOnContinueLoading, setIsOnContinueLoading] =
      useState<boolean>(false);

    const updateProgramSubStage = useCallback(
      (programSubStage: ProgramSubStageEnum): Promise<string | undefined> => {
        return client
          .UpdateProgramSubStage({
            programId,
            programSubStage: programSubStage,
          })
          .then(({ errorMsg }) => {
            if (errorMsg) {
              console.error('Error: Could not update program substage.');
              return Promise.reject(errorMsg);
            }
          });
      },
      [client, programId],
    );

    const isLastQuestionAnsweredAndRendered = (): boolean => {
      const isQuestionRendered = (questionId: string) =>
        _.flatMap(questionsToRender).find((q) => q.id === questionId) ?? false;
      const checkQuestion = (questionId: string) => {
        const question = getQuestionData(questionId);
        return question
          ? isQuestionRendered(questionId) && isQuestionAnswered(question)
          : false;
      };

      const isTaxInfoProvided = () => {
        const manualQuestion = getQuestionData(
          CompanyDetailsGraphCmsQuestionIds.TAX_INFO_MANUAL,
        );
        const uploadQuestion = getQuestionData(
          CompanyDetailsGraphCmsQuestionIds.TAX_INFO_FILE_UPLOAD,
        );
        if (manualQuestion && uploadQuestion) {
          return (
            isQuestionAnswered(manualQuestion) ||
            isQuestionAnswered(uploadQuestion)
          );
        }
        return false;
      };

      if (!_.isEmpty(questionsToRender)) {
        // if we only need 2021 gross receipts and the customer has completed 2021 EC,
        // we won't render the QRE question. So we need to check the manual OR tax upload questions
        if (
          !isQuestionRendered(
            CompanyDetailsGraphCmsQuestionIds.PRIOR_YEARS_RESEARCH_SPEND,
          )
        ) {
          return isTaxInfoProvided();
        }

        /* TODO: Unsure why we accept either of these to show the continue button.
         * If customer uploads file before answering research spend, they can continue
         * without answering the research spend question because this return statement is an OR.
         * At the current time, it is too risky to change this logic, but should be revisted.
         */
        return (
          checkQuestion(
            CompanyDetailsGraphCmsQuestionIds.PRIOR_YEARS_RESEARCH_SPEND,
          ) ||
          checkQuestion(CompanyDetailsGraphCmsQuestionIds.TAX_INFO_FILE_UPLOAD)
        );
      }
      return false;
    };

    /* Check for any federal tax returns and/or manual tax return question answers
     * Find out which years we have the above information for already
     * Filter out tax return questions for those years so we don't ask them again
     */
    const updateTaxQuestionInfo = async () => {
      // search for tax return docs
      const res = await client.GetCompanyDocuments({
        documentType: 'FEDERAL_CORPORATE_INCOME_TAX_RETURN',
      });
      const documents = res.data?.documents;

      const yearFounded = company.yearFounded;
      if (!yearFounded) {
        return;
      }

      let yearAcquiredCompanyFounded = 0;
      let yearEarliestCompanyFounded = 0;
      let earliestYearShown = yearFounded;

      if (company.qualificationQuestionsByYear) {
        const qualificationQuestionYears = Object.keys(
          company.qualificationQuestionsByYear,
        );
        qualificationQuestionYears.sort();

        qualificationQuestionYears.forEach((year) => {
          if (company.qualificationQuestionsByYear) {
            if (
              !yearAcquiredCompanyFounded &&
              company.qualificationQuestionsByYear[Number(year)][
                GraphCmsQuestionIdEnum.YEAR_ACQUIRED_COMPANY_FOUNDED
              ]
            ) {
              yearAcquiredCompanyFounded =
                company.qualificationQuestionsByYear[Number(year)][
                  GraphCmsQuestionIdEnum.YEAR_ACQUIRED_COMPANY_FOUNDED
                ];
            }
            if (
              !yearEarliestCompanyFounded &&
              company.qualificationQuestionsByYear[Number(year)][
                GraphCmsQuestionIdEnum.YEAR_CONTROL_GROUP_COMPANY_FOUNDED
              ]
            ) {
              yearEarliestCompanyFounded =
                company.qualificationQuestionsByYear[Number(year)][
                  GraphCmsQuestionIdEnum.YEAR_CONTROL_GROUP_COMPANY_FOUNDED
                ];
            }
          }
        });

        /* The earliestYearShown is controlled by the following factors:
         * - MIN of yearFounded, year acquired company founded, year earliest company founded
         * - reference for the logic that is replicated in hygraph here: https://mainstreet-com.atlassian.net/browse/GOV-1760
         */
        if (yearAcquiredCompanyFounded > 0) {
          earliestYearShown = Math.min(
            earliestYearShown,
            yearAcquiredCompanyFounded,
          );
        }

        if (yearEarliestCompanyFounded > 0) {
          earliestYearShown = Math.min(
            earliestYearShown,
            yearEarliestCompanyFounded,
          );
        }
      }

      // only show questions for years during and after company was founded
      const applicableQuestionIds = taxInfoQuestionIds.filter(
        (t) => t.year >= earliestYearShown,
      );

      const questionIds: string[] = [];
      const yearsWithExistingData: number[] = [];

      // check for 2021 tax form upload
      if (documents?.some((d) => d.taxYear === 2021)) {
        yearsWithExistingData.push(2021);
      }

      // for all other years, decide if we should check tax form uploads or gross receipts
      const didUploadTaxDocs =
        documents &&
        documents.length > 0 &&
        // omit 2021 from this check because if they only have a form for 2021, we want to check gross receipts
        documents.some((d) => d.taxYear !== 2021);

      if (documents && didUploadTaxDocs) {
        // if there are documents, check which years we have them for
        yearsWithExistingData.push(
          ...documents.map((d) => d.taxYear ?? -1).filter((y) => y > 0),
        );
      } else {
        // if there are no documents, check which years we have gross receipts for
        yearsWithExistingData.push(
          ...company.financialInformation.grossReceipts.map((g) => g.taxYear),
        );
      }

      // Check if we received data from codat for 2021 gross receipts and un-suppress the field
      const getCodat2021Amount =
        company.financialInformation.grossReceipts.find(
          (grossReceipt) =>
            grossReceipt.taxYear === 2021 &&
            grossReceipt.usesCodat &&
            grossReceipt.amountCents > 0,
        )?.amountCents;

      applicableQuestionIds
        .filter((q) => yearsWithExistingData.includes(q.year))
        .forEach((q) => {
          // if doc or gross receipt exists for a year, add that year's Tax Info questions to the list of questions to suppress
          if (q.year === 2021 && getCodat2021Amount) {
            questionIds.push(q.uploadQuestionId, q.researchQuestionId);
          } else {
            questionIds.push(
              q.manualQuestionId,
              q.uploadQuestionId,
              q.researchQuestionId,
            );
          }
        });

      /* For each tax year, we ask 3 questions: manual receipts, tax form upload, and research time
       * If we have 3 questions to suppress for all applicable tax years (up to 5), then we can
       * suppress the entire category
       */
      const allQuestionsSuppressed =
        questionIds.length === Math.min(applicableQuestionIds.length, 5) * 3;

      const subQuestionsToSuppress: { [key: string]: () => boolean } = {};
      questionIds.forEach((questionId) => {
        subQuestionsToSuppress[questionId] = () => false;
      });

      const ecCompletedProgramStages: ProgramStageEnum[] = [
        ProgramStageEnum.MS_REVIEW,
        ProgramStageEnum.CLIENT_REVIEW,
        ProgramStageEnum.FINISHED,
        ProgramStageEnum.COMPLETED,
      ];

      const hasCompleted2021EC =
        company.programs.filter(
          (p) =>
            p.taxYear == 2021 && ecCompletedProgramStages.includes(p.stage),
        ).length > 0;

      if (allQuestionsSuppressed) {
        setRenderQuestionOverrides({
          /* If all applicable subquestions are being suppressed (suppressIncomeResearchQuestions),
           * we'll suppress the entire Income & Research Spend section
           */
          incomeAndResearchSpend: () => false,
        });
      } else {
        setRenderQuestionOverrides({
          /* Otherwise, suppress individual subquestions
           *
           * Additionally, suppress the 2021 QRE question as long as customer completed 2021 EC
           */
          ...subQuestionsToSuppress,
          [ResearchTimeGraphCmsQuestionIds.RESEARCH_2021]: () =>
            !hasCompleted2021EC,
        });
      }
    };

    const surveyName: SurveyNameEnum =
      SurveyNameEnum.EXPENSE_QUALIFICATION_COMPANY_DETAIL;

    const {
      isLoading,
      questionsToRender,
      surveyAnswers,
      addSurveyAnswers,
      addSurveyAnswersWithoutRerender,
      getQuestionData,
      isSurveyComplete,
      saveAnswers,
      getSubGroupRenderData,
    } = useSurveyQuestions(
      surveyName,
      program?.taxYear || new Date().getUTCFullYear() - 1,
      { showConditionOverrides: renderQuestionOverrides },
    );

    useExpenseClassificationAnswerPrefill(
      surveyName,
      program,
      addSurveyAnswersWithoutRerender,
    );

    // Make sure to only refresh program when the ID changes
    const programFetchRef = useRef<number>(-1);
    useEffect(() => {
      if (programFetchRef.current === programId) {
        return;
      }

      programFetchRef.current = programId;
      client
        .GetProgram(programId)
        .then(async (res) => {
          if (res.data) {
            await updateTaxQuestionInfo();
            setProgram(res.data.program);
            setTaxYear(res.data.program.taxYear);
          } else {
            setTaxYear(new Date().getUTCFullYear());
          }
        })
        .finally(() => setLoading(false));
    }, [programId, client]);

    const [reviewComponentEditMode, setReviewComponentEditMode] =
      useState<boolean>(true);
    const [taxFormUploadEditMode, setTaxFormUploadEditMode] =
      useState<boolean>(true);
    const [expandedQuestionIds, setExpandedQuestionIds] = useState<Set<string>>(
      new Set(),
    );

    const handleQuestionExpansion = (
      questionId: string,
      expanded: boolean,
    ): void => {
      if (expandedQuestionIds.has(questionId) && !expanded) {
        setExpandedQuestionIds((prev) => {
          const newState = new Set(prev);
          newState.delete(questionId);
          return newState;
        });
      } else if (!expandedQuestionIds.has(questionId) && expanded) {
        setExpandedQuestionIds((prev) => new Set(prev.add(questionId)));
      }
    };

    const onContinue = async () => {
      setIsOnContinueLoading(true);
      // Submit answers
      await saveAnswers().catch(() => onError());

      if (featureFlags.saveYeaSurveyAttestation) {
        await SaveSurveyAttestation({
          hasConfirmedAnswers,
          eventType:
            AttestationEventTypeEnum.YEAR_END_ASSESSMENT_COMPANY_DETAILS_COMPLETE,
          userEmail: auth.user?.email || company?.adminEmail,
          taxYear: program!.taxYear,
          companyId: company.id,
          client,
        });
      }

      // Move client into Ready to Submit
      if (!taxCreditsPage.isYEAEditMode) {
        await updateProgramSubStage(
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT,
        );
      }

      window.location.href = `/${Page.taxCredits}`;
      setIsOnContinueLoading(false);
    };

    const getSubGroupTitle = (
      showConditions: CmsRenderTreeShowCondition[],
    ): SubGroupTitle => {
      const [operator, condition] = Object.entries(showConditions)[0];
      if (operator in titleByOperator) {
        return titleByOperator[operator](condition);
      }
      return {
        title: '',
      };
    };

    const titleByOperator: Record<string, (...args: any[]) => SubGroupTitle> = {
      hasProgram: ({ programName }: { programName: ProgramNameEnum }) => {
        return {
          title: `Specific details for your ${Programs[programName].display} program.`,
        };
      },

      equals: ({
        questionId,
        answerValue,
      }: {
        questionId: string;
        answerValue: string;
      }) => {
        const question = getQuestionData(questionId as string);
        if (question) {
          const taxYear = program
            ? program.taxYear
            : new Date().getUTCFullYear(); // Every program should have a tax year
          question.text = renderTaxYearInQuestionText(question.text, taxYear);
        }
        const answerText = question?.answerIDs.find(
          (a) => a.id === answerValue,
        )?.text;
        if (question && answerText) {
          return {
            title: `Because you answered "${
              question.answerIDs.find((a) => a.id === answerValue)?.text
            }" to "${question.text}"`,
          };
        }
        return {
          title: '',
        };
      },

      and: (conditions: CmsRenderTreeShowConditions) => {
        // create an array from the conditions object so we can search through it
        const conditionArray = Object.entries(conditions);

        // find the condition with the answer that matches the answer given in the survey
        const condition = conditionArray.find(
          (c: any) =>
            surveyAnswers[c[1]?.equals?.questionId] ===
            c[1]?.equals?.answerValue,
        );

        // return the found condition or default to the first condition
        return getSubGroupTitle(condition ? condition[1] : conditions[0]);
      },

      or: (conditions: CmsRenderTreeShowConditions) => {
        const conditionArray = Object.entries(conditions);
        const condition = conditionArray.find(
          (c: any) =>
            surveyAnswers[c[1]?.equals?.questionId] ===
            c[1]?.equals?.answerValue,
        );
        return getSubGroupTitle(condition ? condition[1] : conditions[0]);
      },
    };

    const titleBySubGroupId = (id: string): SubGroupTitle | null => {
      // HACK - Allow manual setting of title for edge-case subgroups.
      switch (id) {
        case 'extraDetails':
          return {
            title: 'Please confirm some extra details about your company.',
            subtitle:
              'We need to confirm a few more things to accurately generate your tax forms',
          };
        case 'incomeAndResearchSpend':
          return {
            title: `Company's income and research spend`,
            subtitle:
              'We will need this extra information to calculate your credits based on your previous responses.',
          };
        default:
          return null;
      }
    };

    const getTitleBySubGroup = (
      subGroupName: string,
      subGroup: CmsRenderTreeSubgroup | undefined,
    ): SubGroupTitle => {
      if (subGroup) {
        const subTitleById = titleBySubGroupId(subGroupName);
        if (subTitleById) {
          return subTitleById;
        }

        const [operator, conditions] = Object.entries(
          subGroup.showCondition,
        )[0];
        return titleByOperator[operator](conditions);
      }
      return {};
    };

    const onSubQuestionsComplete = (
      question: CmsQuestionData,
      subGroupName: string,
    ) => {
      const subQuestionAnswers = question.subQuestions
        ?.filter((sq) => sq.answerValue != null)
        .map((subQuestion) => ({
          questionId: subQuestion.id,
          answerValue: subQuestion.answerValue!,
        }));
      if (subQuestionAnswers) {
        addSurveyAnswers(question.id, subGroupName, subQuestionAnswers);
      }
      handleQuestionExpansion(question.id, false);
    };

    const renderQualificationQuestionsReviewComponent = (
      question: CmsQuestionData,
      subGroupName: string,
    ) => (
      <ExpandableSurveyQuestion
        questionProps={question}
        editModeOverride={reviewComponentEditMode}
        setEditModeOverride={setReviewComponentEditMode}
        key={question.id}
        saveAnswers={saveAnswers}
        chatbot={chatbot}
      >
        <ReviewQualificationQuestions
          questionProps={question}
          onComplete={() => {
            onSubQuestionsComplete(question, subGroupName);
            setReviewComponentEditMode(false);
          }}
          program={program}
        />
      </ExpandableSurveyQuestion>
    );

    const renderTaxReturnUploadQuestion = (
      question: CmsQuestionData,
      subGroupName: string,
    ) => (
      <ExpandableSurveyQuestion
        questionProps={question}
        editModeOverride={taxFormUploadEditMode}
        setEditModeOverride={setTaxFormUploadEditMode}
        helpDrawerContent={<TotalIncomeGrossReceiptsHelpDrawer />}
        key={question.id}
        saveAnswers={saveAnswers}
        chatbot={chatbot}
      >
        {program && (
          <TaxReturnUploadQuestion
            key={question.id}
            program={program}
            setEditMode={setTaxFormUploadEditMode}
            onComplete={(completedQuestion: CmsQuestionData) => {
              onSubQuestionsComplete(completedQuestion, subGroupName);
              setTaxFormUploadEditMode(false);
            }}
            getQuestionProps={question}
            saveAnswers={saveAnswers}
          />
        )}
      </ExpandableSurveyQuestion>
    );

    const renderQuestionContent = (
      question: CmsQuestionData,
      subGroupName: string,
    ): JSX.Element => {
      question.text = renderTaxYearInQuestionText(question.text, taxYear);

      if (
        question.id === CompanyDetailsGraphCmsQuestionIds.OTHER_CREDIT_CLAIMED
      ) {
        const ERC_ANSWER_ID = 'ckzg00ea07igx0d69e0qgylqf';
        const showERC = !company.programs.some(
          (p) =>
            IsRDCreditProgram(p.name) &&
            p.qualificationStatus === QualificationStatusEnum.QUALIFIED &&
            p.taxYear < 2022,
        );
        question.answerIDs = showERC
          ? question.answerIDs
          : question.answerIDs.filter((a) => a.id !== ERC_ANSWER_ID);
      }

      switch (question.id) {
        case CompanyDetailsGraphCmsQuestionIds.REVIEW_QUALIFICATION_QUESTIONS:
          return renderQualificationQuestionsReviewComponent(
            question,
            subGroupName,
          );

        case CompanyDetailsGraphCmsQuestionIds.TAX_INFO_FILE_UPLOAD:
          return renderTaxReturnUploadQuestion(question, subGroupName);

        case CompanyDetailsGraphCmsQuestionIds.OTHER_CREDITS_CLAIMED_AMOUNT:
          return (
            <ExpandableSurveyQuestion
              questionProps={question}
              helpDrawerContent={<OtherCreditsHelpDrawer />}
              onQuestionPropsChange={(answers: SurveyAnswer[]) =>
                addSurveyAnswers(question.id, subGroupName, answers)
              }
              key={question.id}
              onEditModeToggle={handleQuestionExpansion}
              saveAnswers={saveAnswers}
              chatbot={chatbot}
            />
          );

        case CompanyDetailsGraphCmsQuestionIds.NAICS_CODE:
          return (
            <ExpandableSurveyQuestion
              questionProps={question}
              helpLinkText='Where to find this information'
              onHelpLinkClick='https://www.census.gov/naics/?58967?yearbck=2017'
              onQuestionPropsChange={(answers: SurveyAnswer[]) =>
                addSurveyAnswers(question.id, subGroupName, answers)
              }
              key={question.id}
              onEditModeToggle={handleQuestionExpansion}
              saveAnswers={saveAnswers}
              chatbot={chatbot}
            />
          );

        case CompanyDetailsGraphCmsQuestionIds.GA_RD_GROSS_RECIEPTS:
          return (
            <ExpandableSurveyQuestion
              questionProps={question}
              helpDrawerContent={
                <GeorgiaRdGrossReceiptsHelpDrawer program={program} />
              }
              onQuestionPropsChange={(answers: SurveyAnswer[]) =>
                addSurveyAnswers(question.id, subGroupName, answers)
              }
              key={question.id}
              onEditModeToggle={handleQuestionExpansion}
              saveAnswers={saveAnswers}
              chatbot={chatbot}
            />
          );

        default:
          return (
            <ExpandableSurveyQuestion
              questionProps={question}
              onQuestionPropsChange={(answers: SurveyAnswer[]) =>
                addSurveyAnswers(question.id, subGroupName, answers)
              }
              key={question.id}
              customAnswerSubtitle={
                program
                  ? getCustomQuestionSubtitle(question, program)
                  : undefined
              }
              onEditModeToggle={handleQuestionExpansion}
              saveAnswers={saveAnswers}
              chatbot={chatbot}
            />
          );
      }
    };

    const hasTaxFormUploadQuestion: boolean = Object.keys(
      questionsToRender,
    ).some((subgroup) =>
      questionsToRender[subgroup].find(
        (question) =>
          question.id ===
          CompanyDetailsGraphCmsQuestionIds.TAX_INFO_FILE_UPLOAD,
      ),
    );
    const isTaxFormUploadQuestionBlocking =
      hasTaxFormUploadQuestion && taxFormUploadEditMode;

    const canContinue =
      (isSurveyComplete &&
        expandedQuestionIds.size === 0 &&
        !isTaxFormUploadQuestionBlocking) ||
      isLastQuestionAnsweredAndRendered();
    return (
      <div className={classes.container}>
        <HeaderBar currentPage={ExpenseClassificationProcessingSteps[0]} />
        <div className={classes.mainContent}>
          <div className={classes.root}>
            <StepsContainer
              showContinueButton={canContinue}
              continueDisabled={
                featureFlags.saveYeaSurveyAttestation && !hasConfirmedAnswers
              }
              onContinue={onContinue}
              onContinueLabel='Continue'
              stepTitle='Company Details'
              isLoading={isOnContinueLoading}
            >
              <Loading loading={loading && isLoading}>
                {Object.keys(questionsToRender).map((subGroupName) => {
                  const subGroup = getSubGroupRenderData(subGroupName);
                  let subGroupTitle: SubGroupTitle = {};
                  if (subGroupName !== 'review') {
                    subGroupTitle = getTitleBySubGroup(subGroupName, subGroup);
                  }
                  const alert = getAlertBySubGroup(
                    subGroupName as SubGroupNameEnum,
                  );

                  return (
                    <React.Fragment key={`subgroup-${subGroupName}`}>
                      <Text
                        text={subGroupTitle?.title}
                        size={18}
                        variant='medium'
                      />
                      {subGroupTitle.subtitle && (
                        <Text
                          paddingBottom={alert ? 16 : 4}
                          text={subGroupTitle.subtitle}
                        />
                      )}
                      {alert}
                      <Content
                        flex
                        flexDirection='column'
                        gap={16}
                        paddingTop={16}
                        paddingBottom={32}
                        paddingLeftRight={0}
                      >
                        {questionsToRender[subGroupName]
                          // TAX_INFO_MANUAL is asked as part of another question, so don't ask it again
                          .filter(
                            (question) =>
                              question.id !==
                              CompanyDetailsGraphCmsQuestionIds.TAX_INFO_MANUAL,
                          )
                          .map((question) => {
                            return (
                              <React.Fragment key={question.id}>
                                {taxYear !== 0 && (
                                  <Delay
                                    waitBeforeShow={
                                      surveyAnswers[question.id] ? 450 : 900
                                    }
                                  >
                                    {renderQuestionContent(
                                      question,
                                      subGroupName,
                                    )}
                                  </Delay>
                                )}
                              </React.Fragment>
                            );
                          })}
                      </Content>
                    </React.Fragment>
                  );
                })}
                {featureFlags.saveYeaSurveyAttestation && canContinue && (
                  <SurveyAttestation
                    checked={hasConfirmedAnswers}
                    onAttestation={() =>
                      setHasConfirmedAnswers(!hasConfirmedAnswers)
                    }
                  />
                )}
              </Loading>
            </StepsContainer>
          </div>
        </div>
      </div>
    );
  },
);

export default CompanyDetailsStep;
