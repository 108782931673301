import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { Alert, Text } from 'component-library';
import { useTaxCreditsStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  alert: {
    marginBottom: '32px',
  },
}));

export const AcceptedByAlert: React.FC = observer(() => {
  const classes = useStyles();
  const { orderForms } = useTaxCreditsStores();

  const getAcceptedByText = () => {
    const timestamp = moment(orderForms.acceptedAt)
      .local()
      .format('[on] MMMM D, YYYY [at] h:mma');
    if (!orderForms?.acceptedBy) {
      return `Accepted on ${timestamp}`;
    }

    let acceptedBy = orderForms.acceptedBy.lastName;
    if (orderForms.acceptedBy.firstName) {
      acceptedBy = `${orderForms.acceptedBy.firstName} ${acceptedBy}`;
    }

    if (orderForms.acceptedBy.email) {
      acceptedBy = `${acceptedBy} (${orderForms.acceptedBy.email})`;
    }

    // Final format: '${firstName} ${lastName} (${email}) ${timestamp}'.
    return `${acceptedBy} ${timestamp}`;
  };

  return (
    <Alert
      className={classes.alert}
      dataTestId='accepted-alert-info'
      text={<Text>{getAcceptedByText()}</Text>}
      type='success'
    />
  );
});
