import React, { useContext, useEffect, useState } from 'react';
import { Button, Content, SideDrawer } from 'component-library';
import { RoleAndJobGroupField } from './RoleAndJobGroupField';
import { JobGroupEnum } from '../../../../../lib/constants';
import CountryAndStateGroupField from './CountryAndStateGroupField';
import {
  CountryEnum,
  EmploymentTypeEnum,
  PayTypeEnum,
  StateEnum,
} from '../../../../../lib/interfaces';
import {
  ContractorExpenseClassificationData,
  EmployeeInputData,
  EmploymentDisplayType,
  IndividualExpenseClassificationData,
} from '../../steps/Employees';
import { DetailsSection } from './DetailsSection';
import { AmountPayField } from './AmountPayField';
import ConfirmationModal from './ConfirmationModal';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from '../../../../../logging';
import { CompanyContext } from '../../../../CompanyRequired';

interface EmployeeSideDrawerProps {
  onAddEmployeeOrContractor: (employee: EmployeeInputData) => Promise<void>;
  onUpdateEmployeeOrContractor: (employee: EmployeeInputData) => Promise<void>;
  drawerType: EmploymentDisplayType;
  setDrawerType: (value: EmploymentDisplayType) => void;
  selectedPerson: ContractorExpenseClassificationData | null;
  setSelectedPerson: (
    employee: IndividualExpenseClassificationData | null,
  ) => void;
  programTaxYear: number;
  isUnifiedEmployee?: boolean;
}

export const EmployeeSideDrawer = ({
  onAddEmployeeOrContractor,
  onUpdateEmployeeOrContractor,
  drawerType,
  setDrawerType,
  selectedPerson,
  setSelectedPerson,
  programTaxYear,
  isUnifiedEmployee,
}: EmployeeSideDrawerProps) => {
  const { company } = useContext(CompanyContext);
  const [fullName, setFullName] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [jobGroup, setJobGroup] = useState<JobGroupEnum | undefined>();
  const [amountPaid, setAmountPaid] = useState<number | undefined>();
  const [contractorPayType, setContractorPayType] = useState<
    PayTypeEnum | undefined
  >(PayTypeEnum.VARIABLE);
  const [country, setCountry] = useState<CountryEnum>();
  const [stateOrRegion, setStateOrRegion] = useState<StateEnum>();

  const [errorFullName, setErrorFullName] = useState<boolean>(false);
  const [errorJobGroup, setErrorJobGroup] = useState<boolean>(false);
  const [errorAmountPaid, setErrorAmountPaid] = useState<boolean>(false);
  const [errorStateOrRegion, setErrorStateOrRegion] = useState<boolean>(false);

  const [errorAddingEmployee, setErrorAddingEmployee] =
    useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const usIsNotSelectedCountry = country !== CountryEnum.UNITED_STATES;

  const paidInCentsHasANonZeroValue = !!(
    selectedPerson?.paidInCents && selectedPerson?.paidInCents !== 0
  );

  useEffect(() => {
    if (selectedPerson?.name) {
      setFullName(selectedPerson.name);
    }
    if (selectedPerson?.role) {
      setRole(selectedPerson.role);
    }
    if (selectedPerson?.jobGroup) {
      setJobGroup(selectedPerson.jobGroup);
    }

    if (selectedPerson?.paidInCents) {
      setAmountPaid(selectedPerson.paidInCents);
    }

    if (selectedPerson?.workingFrom) {
      setStateOrRegion(selectedPerson.workingFrom as StateEnum);
    }

    if (selectedPerson?.country) {
      setCountry(selectedPerson.country);
    }

    if (selectedPerson?.payType) {
      setContractorPayType(selectedPerson.payType);
    }

    // clean up
  }, [selectedPerson]);

  const errorMissingInfo =
    errorFullName || errorJobGroup || errorAmountPaid || errorStateOrRegion;

  const checkIsValid = (): boolean => {
    let isValid = true;

    if (!selectedPerson?.name && !fullName) {
      setErrorFullName(true);
      isValid = false;
    }
    if (!paidInCentsHasANonZeroValue && !amountPaid) {
      setErrorAmountPaid(true);
      isValid = false;
    }
    if (!selectedPerson?.jobGroup && !jobGroup) {
      setErrorJobGroup(true);
      isValid = false;
    }
    if (!selectedPerson?.workingFrom && !stateOrRegion) {
      setErrorStateOrRegion(true);
      isValid = false;
    }

    return isValid;
  };

  const setAlertType = () => {
    if (usIsNotSelectedCountry || errorAddingEmployee || errorMissingInfo) {
      return 'alert';
    }
    return 'info';
  };

  const clearFieldsAndErrors = () => {
    setFullName('');
    setRole('');
    setJobGroup(undefined);
    setAmountPaid(undefined);
    setContractorPayType(PayTypeEnum.VARIABLE);
    setCountry(CountryEnum.UNITED_STATES);
    setStateOrRegion(undefined);
    setErrorFullName(false);
    setErrorJobGroup(false);
    setErrorStateOrRegion(false);
    setErrorAmountPaid(false);
    setSelectedPerson(null);
  };

  const areDataEnterInTheForm = () => {
    return (
      fullName !== '' ||
      role !== '' ||
      amountPaid !== undefined ||
      contractorPayType !== undefined ||
      jobGroup !== undefined ||
      stateOrRegion !== undefined
    );
  };

  const isContractorForm = drawerType === EmploymentDisplayType.CONTRACTOR;
  const isEmployeeForm = drawerType === EmploymentDisplayType.EMPLOYEE;
  const isEditMode = !!selectedPerson;

  const setAlertMessage = () => {
    if (usIsNotSelectedCountry) {
      return `Only US-based ${
        isContractorForm ? 'contractors' : 'employees'
      } can be added to this table.`;
    }
    if (errorAddingEmployee) {
      return 'Could not create employee.';
    }
    if (selectedPerson && !errorMissingInfo) {
      return;
    }
    if (isUnifiedEmployee) {
      return 'All fields are required. Any changes you make to information on this table will automatically update data in the Retirement Plan Credit. Please ensure you review that section to confirm all data is correct.';
    }

    return 'All fields are required.';
  };

  const renderTitle = (): string => {
    if (isEditMode) {
      return `Edit ${drawerType}'s details`;
    }
    return `Add ${drawerType}`;
  };

  const closeForm = () => {
    setDrawerType(EmploymentDisplayType.EMPTY);
    clearFieldsAndErrors();
  };

  return (
    <SideDrawer
      show={isContractorForm || isEmployeeForm}
      title={renderTitle()}
      closeToggle={() => {
        if (areDataEnterInTheForm()) {
          setModalIsOpen(true);
        } else {
          closeForm();
        }
      }}
      dataTestId={drawerType}
      alertMessage={setAlertMessage()}
      alertType={setAlertType()}
      alertMessagePosition='bottom'
      drawerContent={
        <>
          <Content gap={32} flexDirection='column' flex>
            <DetailsSection
              errorFullName={errorFullName}
              setErrorFullName={setErrorFullName}
              fullName={fullName}
              setFullName={setFullName}
              drawerType={drawerType}
              selectedPerson={selectedPerson}
              isEditMode={isEditMode}
            />
            <RoleAndJobGroupField
              role={role}
              setRole={setRole}
              jobGroup={jobGroup}
              setJobGroup={setJobGroup}
              showJobGroupError={errorJobGroup}
              setShowJobGroupError={setErrorJobGroup}
              dataTestId={drawerType}
              isEditMode={isEditMode}
            />
            <AmountPayField
              amountPaid={amountPaid}
              contractorPayType={contractorPayType}
              drawerType={drawerType}
              errorAmountPaid={errorAmountPaid}
              isContractorForm={isContractorForm}
              setAmountPaid={setAmountPaid}
              setContractorPayType={setContractorPayType}
              setErrorAmountPaid={setErrorAmountPaid}
              selectedPerson={selectedPerson}
              isEditMode={isEditMode}
              programTaxYear={programTaxYear}
            />
            <CountryAndStateGroupField
              country={country}
              setCountry={setCountry}
              stateOrRegion={stateOrRegion}
              setStateOrRegion={setStateOrRegion}
              showStateOrRegionError={errorStateOrRegion}
              setShowStateOrRegionError={setErrorStateOrRegion}
              selectedPerson={selectedPerson}
              isEditMode={isEditMode}
            />
          </Content>
          {modalIsOpen && (
            <ConfirmationModal
              displayType={drawerType}
              closeSideDrawer={closeForm}
              modalIsOpen={modalIsOpen}
              setModalIsOpen={setModalIsOpen}
            />
          )}
        </>
      }
      drawerActions={
        <Button
          label='Save'
          disabled={usIsNotSelectedCountry || isSubmitting}
          loading={isSubmitting}
          dataTestId={`${drawerType}-side-drawer-save-button`}
          onClick={async () => {
            setErrorAddingEmployee(false);
            if (checkIsValid()) {
              setIsSubmitting(true);

              try {
                const employee: EmployeeInputData = {
                  fullName: fullName,
                  taxYear: programTaxYear,
                  jobTitle: role,
                  jobGroup:
                    jobGroup || selectedPerson?.jobGroup || JobGroupEnum.OTHER,
                  taxablePayCents: amountPaid,
                  countryOfResidence: country!,
                  stateOfResidence: stateOrRegion!,
                  employmentType: isContractorForm
                    ? EmploymentTypeEnum.CONTRACTOR
                    : EmploymentTypeEnum.EMPLOYEE,
                };

                if (isContractorForm) {
                  employee.payType = contractorPayType;
                }

                if (isEditMode) {
                  await onUpdateEmployeeOrContractor(employee);
                } else {
                  await onAddEmployeeOrContractor(employee);
                }
                closeForm();
              } catch (error) {
                setErrorAddingEmployee(true);
                datadogLogs.logger.error(
                  'Error in EmployeeSideDrawer.onClick()',
                  logContext({
                    error,
                    company,
                  }),
                );
              }
              setIsSubmitting(false);
              clearFieldsAndErrors();
            }
          }}
        />
      }
    />
  );
};

export default EmployeeSideDrawer;
