import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { Text, Color, Flex } from 'component-library';
import moment from 'moment';
import DOMPurify from 'dompurify';

const useStyles = makeStyles(() => ({
  textArea: {
    maxWidth: '75%',
    width: 'fit-content',
    background: Color.green._80,
    borderRadius: '6px 6px 0px 6px',
  },
}));

interface ChatMessageProps {
  text: JSX.Element | string;
  createdAt: Date;
}

export default function UserChatMessage({ text, createdAt }: ChatMessageProps) {
  const classes = useStyles();

  const timestamp = moment(createdAt).format('h:mm A');

  return (
    <Flex padding={[4, 0]} direction='column' alignItems='end'>
      <Flex
        className={classes.textArea}
        direction='column'
        alignItems='start'
        padding={[0, 16]}
      >
        {typeof text === 'string' ? (
          <Text size={13} color={Color.neutral.white}>
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
            />
          </Text>
        ) : (
          <Text text={text} size={13} color={Color.neutral.white} />
        )}
      </Flex>
      <Text text={timestamp} size={11} color={Color.neutral._60} />
    </Flex>
  );
}
