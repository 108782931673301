import {
  Dropdown,
  IconEnum,
  TextField,
  Alert,
  Text,
  Content,
} from 'component-library';
import { JobGroupEnum, JobGroupEnumToString } from 'lib/constants';
import React from 'react';
import { EmployeeSideDrawerSection } from './EmployeeSideDrawerSection';
import _ from 'lodash';

export interface RoleAndJobGroupFieldProps {
  role: string;
  setRole: (value: string) => void;
  jobGroup: string | undefined;
  setJobGroup: (value: JobGroupEnum) => void;
  showJobGroupError: boolean;
  setShowJobGroupError: (value: boolean) => void;
  dataTestId?: string;
  isEditMode: boolean;
}

export const RoleAndJobGroupField = ({
  role,
  setRole,
  jobGroup,
  setJobGroup,
  showJobGroupError,
  dataTestId,
  setShowJobGroupError,
  isEditMode,
}: RoleAndJobGroupFieldProps) => {
  const selectedEmployeeHasJobGroupOther = jobGroup === JobGroupEnum.OTHER;
  const jobGroupIsMissingInformation = _.isEmpty(jobGroup) && showJobGroupError;
  const stringTruncationLength = 35;

  return (
    <EmployeeSideDrawerSection title='Role details' gap={0}>
      <>
        <Content gap={16} flex flexDirection={'column'} paddingBottom={8}>
          <TextField
            label='Role'
            name='role'
            onChange={(event) => setRole(event.target.value)}
            value={role}
            dataTestId={`role-${dataTestId}`}
          />
          <Dropdown
            value={
              jobGroup ? JobGroupEnumToString[jobGroup as JobGroupEnum] : ''
            }
            label='Job group (required)'
            name='job-group'
            options={Object.values(JobGroupEnum).map((jobKey) => {
              const jobGroupDisplay =
                JobGroupEnumToString[jobKey.toLowerCase() as JobGroupEnum];
              const jobGroupValue =
                jobGroupDisplay &&
                jobGroupDisplay.length > stringTruncationLength
                  ? jobGroupDisplay.slice(0, stringTruncationLength) + '...'
                  : jobGroupDisplay;

              return {
                name: jobGroupValue,
                value: jobKey,
              };
            })}
            onInputChange={(e: any) => {
              setJobGroup(e.value);
              setShowJobGroupError(e.value === undefined);
            }}
            dataTestId={`jobGroup-${dataTestId}`}
            secondaryLabel={
              jobGroupIsMissingInformation ? 'Missing information' : undefined
            }
            secondaryLabelTheme={
              jobGroupIsMissingInformation ? 'warning' : undefined
            }
          />
        </Content>
        {selectedEmployeeHasJobGroupOther && (
          <>
            <Text size={13} variant={'regular'} paddingBottom={16}>
              We use these categories to estimate the % of time this person
              spends on R&D and maximize the credits you can get.
            </Text>
            <Alert
              type='caution'
              variant={'in_card'}
              inCardBorder={'left'}
              icon={IconEnum.exclamation_triangle}
              text={`If possible, we highly recommend you select an option from the list that at least partially describes this employee's activities.`}
              dataTestId={`job-group-other-dropdown-alert`}
            />
          </>
        )}
      </>
    </EmployeeSideDrawerSection>
  );
};
