import React from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Button, Flex, Link, Modal, Text } from 'component-library';
import { useTaxCreditsStores } from 'stores/useStores';
import Rive from 'rive-react';
import { ERCQualificationStatusEnum } from 'lib/constants';

const useStyles = makeStyles(() => ({
  riveContainer: {
    '& canvas': {
      height: '300px !important',
      marginLeft: '25px',
    },
  },
}));

interface ERCQualificationModalProps {
  onClick: () => void;
}

export const ERCQualificationModal = observer(
  ({ onClick }: ERCQualificationModalProps) => {
    const classes = useStyles();
    const { erc } = useTaxCreditsStores();

    const renderQualStatus = (status: ERCQualificationStatusEnum) => {
      const renderStatusContent = ({
        title,
        subtitle,
        cta,
      }: StatusCopyInterface) => {
        return (
          <>
            <img
              src={`${process.env.PUBLIC_URL}/images/coins.svg`}
              alt='Qualification Icon'
              width={100}
            />
            <Text text={title} variant='medium' textAlign='center' size={23} />
            <Text textAlign='center' text={subtitle} paddingBottom={24} />
            <Button
              label={cta}
              onClick={onClick}
              loading={erc.modalContinueIsLoading}
            />
          </>
        );
      };

      switch (status) {
        case ERCQualificationStatusEnum.DQ_COMPANY_CLAIMED_ERC:
          return renderStatusContent(statusCopy.disqualified_1);
        case ERCQualificationStatusEnum.DQ_COMPANY_FOUNDED_AFTER_2021:
          return renderStatusContent(statusCopy.disqualified_2);
        case ERCQualificationStatusEnum.DQ_COMPANY_FOUNDED_PRIOR_2020:
          return renderStatusContent(statusCopy.disqualified_3);
        case ERCQualificationStatusEnum.QUALIFIED_FOUNDED_IN_2021:
          return renderStatusContent(statusCopy.qualified_1);
        case ERCQualificationStatusEnum.QUALIFIED_FOUNDED_IN_2020:
          return renderStatusContent(statusCopy.qualified_2);
        case ERCQualificationStatusEnum.QUALIFIED_FOUNDED_IN_2019:
          return renderStatusContent(statusCopy.gross_receipts_decline_only);
        case ERCQualificationStatusEnum.QUALIFIED_FOUNDED_BEFORE_2019:
          return renderStatusContent(statusCopy.gross_receipts_decline_only);
        case ERCQualificationStatusEnum.QUALIFIED_MAYBE_QUALIFIED:
          return renderStatusContent(statusCopy.qualified_3);
        case ERCQualificationStatusEnum.NOTIFICATION_COMPANY_TOOK_OUTSIDE_CREDIT:
          return renderStatusContent(statusCopy.notification_1);
        case ERCQualificationStatusEnum.NOTIFICATION_RELATED_ENTITY_FOUNDED_IN_2021:
          return renderStatusContent(statusCopy.notification_2);
        case ERCQualificationStatusEnum.BILLING_SUMMARY:
          return renderStatusContent(statusCopy.billingSummary);
      }
    };

    return (
      <Modal
        showModal={erc.showQualificationStatusModal}
        maxWidth={580}
        dataTestId='erc-qualification-status-modal'
      >
        {erc.loadingEligibility ? (
          <Flex
            padding={24}
            justifyContent='center'
            alignItems='center'
            direction='column'
          >
            <Flex className={classes.riveContainer}>
              <Rive
                src={`${process.env.PUBLIC_URL}/images/rive/searching-for-credits.riv`}
              />
            </Flex>
            <Text
              text='Searching for credits...'
              variant='medium'
              textAlign='center'
              size={23}
            />
          </Flex>
        ) : (
          <Flex
            padding={[24, 24, 40, 24]}
            direction='column'
            gap={16}
            alignItems='center'
          >
            {erc.qualificationStatus &&
              renderQualStatus(erc.qualificationStatus)}
          </Flex>
        )}
      </Modal>
    );
  },
);

interface StatusCopyInterface {
  title: string;
  subtitle: string | JSX.Element;
  cta: string;
}

const statusCopy = {
  disqualified_1: {
    title: 'Congrats on the credit you claimed',
    subtitle: `ERC is a one-time credit, so unfortunately we won't be able to assist you in making an additional ERC claim. The good news is you have already taken advantage of this credit designed to help small businesses like yours.`,
    cta: 'Ok, got it',
  },
  disqualified_2: {
    title: `Sorry, you're ineligible for this credit`,
    subtitle:
      'The good news is that there are several other tax credits you may qualify for! We will keep you informed once we find a better match for your company.',
    cta: 'Ok, got it',
  },
  disqualified_3: {
    title: 'You may be eligible for credit',
    subtitle: `This is a pilot program that does not yet offer all types of the Employee Retention Credit. Based on the company information you have provided, you may be eligible for one of these additional credit options! We will notify you when other credit offerings are available, so we can get you every credit dollar you deserve.`,
    cta: 'Ok, got it',
  },
  notification_1: {
    title: 'Thanks for letting us know',
    subtitle: `We'll ask you a few more questions to confirm your eligibility for this credit. Once we're done, a member of our team will reach out for further information about the government programs you utilized. Please keep an eye on your inbox.`,
    cta: 'Ok, got it',
  },
  notification_2: {
    title: 'Thanks for letting us know',
    subtitle: (
      <>
        <Text tag='span'>
          In the next step, please upload all 2021 tax returns for all entities
          related to your business in 2021. If you do not have these handy, we
          will reach out from{' '}
        </Text>
        <Link href='mailto:support@mainstreet.com' target='_blank' external>
          support@mainstreet.com
        </Link>
        <Text tag='span'>
          {`for further information after you've completed this questionnaire.`}
        </Text>
      </>
    ),
    cta: 'Ok, got it',
  },
  qualified_1: {
    title: 'Congrats! You are eligible for Employee Retention Credits',
    subtitle: `You are eligible for ERC as a Recovery Startup Business. We'll now collect some details from you to calculate your final credit amount.`,
    cta: 'Ok, got it',
  },
  qualified_2: {
    title: 'Congrats! You are eligible for Employee Retention Credits',
    subtitle: `You are eligible for ERC as a Recovery Startup Business. We'll now collect some data from you to calculate your final credit amount for one quarter of 2021. Keep an eye on your inbox to confirm your qualification for additional quarters in 2020 and 2021!`,
    cta: 'Ok, got it',
  },
  qualified_3: {
    title: 'You may be eligible for credit!',
    subtitle: `You meet most of the criteria to be eligible for ERC as a Recovery Startup Business, but we weren't able to automatically verify your average annual gross receipts.  We'll now collect some data from you to calculate your credit amount, then our team will email you for further information to confirm your qualification before finalizing your credit.`,
    cta: 'Ok, got it',
  },
  gross_receipts_decline_only: {
    title: 'You may be eligible for credit!',
    subtitle: `You meet most of the criteria to be eligible for ERC as a result of Gross Receipts Decline, but we weren't able to automatically verify your average annual gross receipts.  We'll now collect some data from you to calculate your credit amount, then our team will email you for further information to confirm your qualification before finalizing your credit.`,
    cta: 'Ok, got it',
  },
  billingSummary: {
    title: `Congrats! It's time to finalize your credits.`,
    subtitle: (
      <>
        <Text tag='span'>
          {`A MainStreet credits specialist is reviewing your information and preparing your Employee Retention Credits now.`}
        </Text>
        <br />
        <br />
        <Text tag='span'>
          {`To finalize your credit, book a 30-minute call with your MainStreet credits specialist. This will ensure your claim is fully compliant with all IRS regulations.`}
        </Text>
      </>
    ),
    cta: 'Book a call to finalize',
  },
};
