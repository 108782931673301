import React from 'react';
import { makeStyles } from '@material-ui/core';
import { CentsToDisplayString } from 'lib/helpers';
import { TransactionStateEnum } from 'lib/constants';
import { Card, Color, Table } from 'component-library';
import moment from 'moment';
import { TransactionsData } from 'lib/interfaces';
import classnames from 'classnames/bind';

const useStyles = makeStyles(() => ({
  transactionsTable: {
    backgroundColor: Color.neutral.white,
    borderSpacing: 0,
    '& tr:not(:last-of-type)': {
      borderBottom: '1px solid' + Color.neutral._20,
      height: '56px',
    },
    '& tbody::before': {
      lineHeight: '0px',
    },
    '& tbody::after': {
      lineHeight: '0px',
    },
  },
  stateCircle: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    fontSize: '14px',
    lineHeight: '20px',
    '& div': {
      width: '6px',
      height: '6px',
      margin: '0px 5px 0px 0px',
      borderRadius: '50%',
    },
  },
  //states
  completedCircle: {
    background: Color.semantic.$success50,
  },
  pendingCircle: {
    background: Color.neutral._60,
  },
  failedCircle: {
    background: Color.semantic.$error50,
  },
  completedText: {
    color: Color.green._70,
  },
  pendingText: {
    color: Color.neutral._60,
  },
  failedText: {
    color: Color.semantic.$error50,
  },
  tableCell: {
    padding: '16px',
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

interface TransactionsTableProps {
  transactionsList: TransactionsData[];
}

export const TransactionsTable = ({
  transactionsList,
}: TransactionsTableProps) => {
  const classes = useStyles();
  const maxTransactionsPerPage = 10;

  const tableColumns = [
    {
      Header: 'Date',
      accessor: 'date',
      width: 150,
      Cell: ({ data }: { data: TransactionsData }) => (
        <div className={classes.tableCell}>
          {moment(data.date).format('MMM DD, YYYY')}
        </div>
      ),
    },
    {
      Header: 'Description',
      width: 300,
      accessor: 'description',
      sortable: false,
    },
    {
      Header: 'State',
      accessor: 'state',
      Cell: ({ data }: { data: TransactionsData }) => {
        const state = data.state;
        //class selector for circle color
        const stateClassName = classnames(
          state === 0 && classes.completedCircle,
          state === 1 && classes.pendingCircle,
          state === 2 && classes.failedCircle,
        );
        //class selector for text color
        const stateTextClassName = classnames(
          state === 0 && classes.completedText,
          state === 1 && classes.pendingText,
          state === 2 && classes.failedText,
        );
        return (
          <div className={classes.stateCircle}>
            <div className={stateClassName} />
            <span className={stateTextClassName}>
              {TransactionStateEnum[state]}
            </span>
          </div>
        );
      },
    },
    {
      Header: 'Amount',
      accessor: 'amountCents',
      textAlignRight: true,
      Cell: ({ data }: { data: TransactionsData }) => (
        <div className={classes.tableCell}>
          {CentsToDisplayString(data.amountCents, 2, 2)}
        </div>
      ),
    },
  ];

  return (
    <Card>
      <Table
        className={classes.transactionsTable}
        data={transactionsList}
        columns={tableColumns}
        paginationSizePerPage={maxTransactionsPerPage}
        paginationShowResultsLabel
      />
    </Card>
  );
};
