import { datadogLogs } from '@datadog/browser-logs';
import { FeatureFlagSet } from '../lib/constants/featureFlagConstants';
import { buildFlagOverrideHeader } from './featureFlagOverride';
import { MSClientResponse, MSApiResponse } from 'lib/interfaces';
import { logContext } from '../logging';
import { ABORT_ERROR } from '../lib/constants';

/**
 * This base client class enables new clients to share some shared things such as Auth0 Context and CSRF
 * when using secure endpoints.
 */
export abstract class AbstractWebClient {
  private accessToken: string | null = null;
  private csrfToken: string | null = null;
  private organizationId: string | null = null;
  private flagOverrides: FeatureFlagSet = {};
  private logger = datadogLogs.createLogger(__filename);
  private SOMETHING_WENT_WRONG = 'An error has occurred.';

  /**
   * @param accessToken Auth0 Access Token for secure endpoints
   * @param csrfToken CSRF token for cross-site request forgery protection.
   */
  constructor(
    accessToken?: string,
    csrfToken?: string,
    organizationId?: string,
  ) {
    this.accessToken = accessToken || null;
    this.csrfToken = csrfToken || null;
    this.organizationId = organizationId || null;
  }

  /**
   * Updates the access token to use across API requests
   * @param token Token to use
   */
  public useAccessToken(token: string | null) {
    this.accessToken = token;
  }

  /**
   * Updates the feature flag overrides set which is propagated across
   * API calls
   *
   * @param featureFlagSet The feature flag set or undefined to clear out the overrides
   */
  public useFlagOverrides(featureFlagSet?: FeatureFlagSet) {
    if (featureFlagSet) {
      this.flagOverrides = featureFlagSet;
    } else {
      this.flagOverrides = {};
    }
  }

  /**
   * Updates the CSRF token to use across API requests
   * @param token Token to use
   */
  public useCsrfToken(token: string | null) {
    this.csrfToken = token;
  }

  /**
   * Updates organization id for use across API requests
   * @param organizationId ID to use
   */
  public useOrganizationId(organizationId: string | null) {
    this.organizationId = organizationId;
  }

  /**
   * Meant to be used as a pipeline (why we made it protected.)
   * call super first, then decorate the result in the subclass override (optional)
   *
   * @param requestOptionsInput
   * @returns The decorated / wrapped request options
   * @deprecated - shared headers are now built in HTTP methods by default (see {@link mergeOptions})
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected addSharedHeaders(
    requestOptionsInput: Record<string, unknown>,
  ): Record<string, unknown> {
    let newHeaders: Record<string, unknown> = {};
    // explode headers
    const { headers, ...rest } = requestOptionsInput;

    // copy current headers to new headers.
    newHeaders = Object.assign(newHeaders, headers);

    newHeaders = Object.assign(newHeaders, {
      ...(this.accessToken && { Authorization: `Bearer ${this.accessToken}` }),
      ...(this.csrfToken && { 'CSRF-Token': this.csrfToken }),
      ...(this.organizationId && { 'X-Organization-Id': this.organizationId }),
      ...buildFlagOverrideHeader(this.flagOverrides),
    });

    // decorate headers and consolidate
    return {
      // copy the headers property
      headers: newHeaders,
      // copy the rest
      ...rest,
    };
  }

  public async Get<T>(
    path: string,
    okCodes = [200],
    reqOptions?: any,
  ): Promise<{ res: MSClientResponse<T>; status: number }> {
    const methodName = 'GET';

    return await this.Fetch<T, undefined>(
      methodName,
      path,
      okCodes,
      undefined,
      reqOptions,
    );
  }

  public async Post<T, U>(
    path: string,
    okCodes = [201],
    body?: U,
    reqOptions?: any,
  ): Promise<{ res: MSClientResponse<T>; status: number }> {
    const methodName = 'POST';

    return await this.Fetch(methodName, path, okCodes, body, reqOptions);
  }

  // updated the whole resource
  public async Put<T, U>(
    path: string,
    okCodes = [200],
    body?: U,
    reqOptions?: any,
  ): Promise<{ res: MSClientResponse<T>; status: number }> {
    const methodName = 'PUT';

    return await this.Fetch(methodName, path, okCodes, body, reqOptions);
  }

  // updates part of a resource
  public async Patch<T, U>(
    path: string,
    okCodes = [200],
    body?: U,
    reqOptions?: any,
  ): Promise<{ res: MSClientResponse<T>; status: number }> {
    const methodName = 'PATCH';

    return await this.Fetch(methodName, path, okCodes, body, reqOptions);
  }

  public async Delete<T>(
    path: string,
    okCodes = [200],
    reqOptions?: any,
  ): Promise<{ res: MSClientResponse<T>; status: number }> {
    const methodName = 'DELETE';

    return await this.Fetch(methodName, path, okCodes, undefined, reqOptions);
  }

  /**
   * fetch wrapper that merges eng provided and default headers, handles error handling,
   * executes fetch call, and types fetch response
   */
  private async Fetch<T, U>(
    methodName: string,
    path: string,
    okCodes: number[],
    body?: U,
    reqOptions?: any,
  ) {
    const o = this.mergeOptions<U>(methodName, body, reqOptions);

    try {
      const res = await fetch(new Request(path, o));
      const json: MSApiResponse<T> = await res.json();

      const payload = this.handleNonOkCodeErrors(
        okCodes,
        json,
        path,
        methodName,
      ) || {
        data: json.data,
      };

      return { res: payload, status: json.status };
    } catch (e) {
      const errorMessageObject = this.handleFailedFetch(e, path, methodName);

      return {
        res: errorMessageObject,
        status: 0,
      };
    }
  }

  private handleNonOkCodeErrors(
    okCodes: number[],
    json: MSApiResponse<any>,
    path: string,
    methodName: string,
  ) {
    if (!okCodes.includes(json.status)) {
      this.logFetchError(
        `${methodName} ${path} returned unexpected status code ${
          json.status
        }. Expected status codes ${okCodes.toString()}. Message: ${
          json.errorMsg
        }`,
      );
      return {
        errorMsg: json.errorMsg || this.SOMETHING_WENT_WRONG,
      };
    }
  }

  private handleFailedFetch = (e: any, path: string, methodType: string) => {
    this.logFetchError(`${methodType} ${path} failed: ${e}`);
    if (!this.isFailedToFetch(e)) {
      this.catchBlockLog(methodType, path, e);
    }

    return {
      errorMsg: this.SOMETHING_WENT_WRONG,
    };
  };

  private logFetchError = (errorMessage: string) => {
    this.logger.error(`Fetch Error: ${errorMessage}`);
  };

  private catchBlockLog = (method: string, path: string, error: any) => {
    this.logger.error(
      `Catch block reached in Dashboard Client Path: ${method} ${path}`,
      {
        ...logContext({
          error,
        }),
        path,
        method,
      },
    );
  };

  private isFailedToFetch = (e: Error) => {
    return (
      !['TypeError', ABORT_ERROR].includes(e.name) &&
      e.message === 'Failed to fetch'
    );
  };

  /**
   * Overrides default options with developer providers options
   * @param methodName
   * @param body (convert to a string if not a sstring already)
   * @param reqOptions ()
   * @returns
   */
  private mergeOptions<U>(
    methodName: string,
    body: U | undefined,
    reqOptions: Record<string, any> | undefined,
  ): Record<string, any> {
    const contentType = !['GET', 'DELETE'].includes(methodName) && {
      'Content-Type': 'application/json',
    };
    const authorization = this.accessToken && {
      Authorization: `Bearer ${this.accessToken}`,
    };
    const csrfToken = this.csrfToken && { 'CSRF-Token': this.csrfToken };
    const xOrganizationId = this.organizationId && {
      'X-Organization-Id': this.organizationId,
    };

    const defaultOptions = {
      method: methodName.toLocaleLowerCase(),
      credentials: 'include',
      cache: 'no-cache',
      headers: {
        ...contentType,
        ...authorization,
        ...csrfToken,
        ...xOrganizationId,
        ...buildFlagOverrideHeader(this.flagOverrides),
      },
    };

    // pulling out headers so that eng-provided headers can override defaults when collision
    const headers = {
      ...defaultOptions.headers,
      ...reqOptions?.headers,
    };

    return {
      ...defaultOptions,
      ...reqOptions,
      body: body
        ? typeof body === 'string'
          ? body
          : JSON.stringify(body)
        : undefined,
      headers,
    };
  }
}
