import {
  Button,
  Card,
  CardFooter,
  Checkbox,
  Content,
  Divider,
  Dropdown,
  DropdownOption,
  Expandable,
  ExpandableHeader,
  Flex,
  Grid,
  Link,
  SideDrawer,
  Table,
  TableColumns,
  Text,
  TextField,
} from 'component-library';
import { RdProject, CreateRdProject } from 'lib/interfaces';
import { RdProjectCategoryEnum } from 'lib/constants';
import React, { useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { useCommonStores, useLegacyClients } from 'stores/useStores';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  textCell: {
    margin: 0,
    padding: '16px',
  },
  sideDrawerButton: {
    marginLeft: '16px',
  },
}));

const descriptionOptions: DropdownOption[] = [
  { name: 'Computer software', value: RdProjectCategoryEnum.COMPUTER_SOFTWARE },
  { name: 'Physical product', value: RdProjectCategoryEnum.PHYSICAL_PRODUCT },
  { name: 'Process', value: RdProjectCategoryEnum.PROCESS },
];

export interface ProjectsTableProps {
  projects: Array<RdProject>;
  setProjects: (projects: RdProject[]) => void;
  programId?: number;
  onContinue: () => void;
}

const RdMultipleProjectsTable = ({
  projects,
  setProjects,
  programId,
  onContinue,
}: ProjectsTableProps) => {
  const classes = useStyles();
  const { client } = useLegacyClients();
  const { companyStore } = useCommonStores();
  const [editMode, setEditMode] = useState(() => projects.length === 0);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState<RdProjectCategoryEnum | null>(
    null,
  );
  const [projectMeetsConditions, setProjectMeetsConditions] = useState(false);
  const [projectEditing, setProjectEditing] = useState<RdProject | undefined>(
    undefined,
  );
  const projectColumns: TableColumns<RdProject>[] = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Description',
      accessor: 'category',
      Cell: (cell) => {
        const opt = descriptionOptions.find(
          (o) => o.value === cell.data.category,
        );
        return (
          <Text
            className={classes.textCell}
            text={opt?.name ?? 'Not answered'}
          />
        );
      },
    },
  ];

  const openSideDrawer = (projectToEdit?: RdProject) => {
    setProjectEditing(projectToEdit);
    setName(projectToEdit?.name || '');
    setProjectMeetsConditions(projectToEdit?.passed4PartTest ?? false);
    if (projectToEdit?.category) {
      setDescription(projectToEdit.category);
    } else {
      setDescription(null);
    }
    setSideDrawerOpen(true);
  };

  const closeSideDrawer = () => {
    setSideDrawerOpen(false);
    setName('');
    setProjectMeetsConditions(false);
    setDescription(null);
    setProjectEditing(undefined);
  };

  const handleAddProject = async () => {
    if (name && description && programId && projectMeetsConditions) {
      const project: CreateRdProject = {
        name,
        category: description as RdProjectCategoryEnum,
        passed4PartTest: projectMeetsConditions,
      };

      try {
        const res = companyStore.accessToken
          ? await client.CreateRDProjectPublic(
              companyStore.accessToken,
              programId,
              project,
            )
          : await client.CreateRDProject(programId, project);

        if (res.errorMsg) {
          datadogLogs.logger.error('Failed to create R&D project', {
            programId,
            errorMsg: res.errorMsg,
            project,
          });
        } else {
          setProjects([...projects, res.data!]);
        }
      } catch (error) {
        datadogLogs.logger.error('Failed to create R&D project', {
          programId,
          error,
          project,
        });
      }

      closeSideDrawer();
    }
  };

  const handleSaveProject = () => {
    if (
      name &&
      description &&
      projectMeetsConditions &&
      projectEditing?.programId &&
      projectEditing?.id
    ) {
      projectEditing.name = name;
      projectEditing.category = description as RdProjectCategoryEnum;
      projectEditing.passed4PartTest = projectMeetsConditions;
      client
        .UpdateRDProject(
          projectEditing.programId,
          projectEditing.id,
          projectEditing,
        )
        .then((res) => {
          if (res.errorMsg) {
            datadogLogs.logger.error('Failed to update R&D project', {
              programId,
              errorMsg: res.errorMsg,
              projectEditing,
            });
          } else {
            const updatedProjects = projects.filter(
              (val) => val.id !== projectEditing.id,
            );
            updatedProjects.push(projectEditing);
            setProjects(updatedProjects);
          }
        })
        .catch((reason) => {
          datadogLogs.logger.error('Failed to update R&D project', {
            programId,
            reason,
            projectEditing,
          });
        });
      closeSideDrawer();
    }
  };

  const handleDeleteProject = () => {
    if (projectEditing?.programId && projectEditing?.id) {
      client
        .DeleteRDProject(projectEditing.programId, projectEditing.id)
        .then((res) => {
          if (res.errorMsg) {
            datadogLogs.logger.error('Failed to delete R&D project', {
              programId,
              errorMsg: res.errorMsg,
              projectEditing,
            });
          } else {
            setProjects(projects.filter((val) => val.id !== projectEditing.id));
          }
        })
        .catch((reason) => {
          datadogLogs.logger.error('Failed to delete R&D project', {
            programId,
            reason,
            projectEditing,
          });
        });
      closeSideDrawer();
    }
  };

  return (
    <>
      <Card>
        <ExpandableHeader
          editMode={editMode}
          setEditMode={setEditMode}
          title='Add all of your major R&D projects'
          subtitle={
            projects ? projects.map((project) => project.name).join(', ') : ''
          }
        />
        <Expandable expand={editMode}>
          <Table
            dataTestId={'rd-projects-table'}
            columns={projectColumns}
            data={projects}
            paginationSizePerPage={5}
            paginationHideSinglePage
            highlightOnHover={true}
            rowOnClick={(data) => openSideDrawer(data)}
          />
          <CardFooter
            primaryCtaLabel='Continue'
            primaryCtaDisabled={projects.length <= 0}
            primaryOnClick={() => {
              setEditMode(false);
              onContinue();
            }}
            secondaryCtaLabel='Add project'
            secondaryOnClick={() => openSideDrawer()}
            secondaryTestId='rd-projects-table-add'
            variant='secondary'
          />
        </Expandable>
      </Card>
      <SideDrawer
        closeToggle={() => closeSideDrawer()}
        drawerActions={
          projectEditing ? (
            <Flex justifyContent='space-between' direction='row-reverse'>
              <Button
                label='Save changes'
                dataTestId={'rd-project-side-drawer-save'}
                onClick={() => handleSaveProject()}
                disabled={
                  !name ||
                  !description ||
                  !projectMeetsConditions ||
                  !projectEditing?.programId ||
                  !projectEditing?.id
                }
              />
              <Button
                label='Delete project'
                dataTestId={'rd-project-side-drawer-delete'}
                type='error'
                disabled={!projectEditing?.programId || !projectEditing?.id}
                onClick={() => handleDeleteProject()}
              />
            </Flex>
          ) : (
            <Flex direction='row-reverse'>
              <Button
                label='Add project'
                dataTestId={'rd-project-side-drawer-add'}
                onClick={handleAddProject}
                disabled={!name || !description || !projectMeetsConditions}
                className={classes.sideDrawerButton}
              />
              <Button
                label='Cancel'
                variant={'outlined'}
                onClick={() => closeSideDrawer()}
              />
            </Flex>
          )
        }
        drawerContent={
          <>
            <Text text='Project details' variant='medium' />
            <Content paddingLeftRight={0} paddingTopBottom={8}>
              <Divider variant='no-bottom-margin' />
            </Content>
            <TextField
              label='Name'
              dataTestId={'rd-project-side-drawer-name'}
              onChange={(event: any) => setName(event.target.value)}
              type='text'
              value={name}
              helperText={`We recommend choosing a project name that describes what you're working on, like ”New internal tool”.`}
            />
            <Content paddingLeftRight={0} paddingTopBottom={24}>
              <Dropdown
                label='Description'
                dataTestId={'rd-project-side-drawer-description'}
                placeholder='Choose from options'
                value={
                  descriptionOptions.find(
                    (o) => o.value === projectEditing?.category,
                  )?.name
                }
                options={descriptionOptions}
                onInputChange={(option: any) => setDescription(option.value)}
              />
            </Content>
            <Content paddingBottom={8}>
              <Text text='4-part test' variant='medium' />
            </Content>
            <Divider />
            <Text
              text={`In order to be considered a business component, your R&D project must meet the IRS's 4-part test requirements:`}
            />
            <Grid padding={8}>
              <Grid.Cell columns={1}>
                <Text text={`1.`} />
              </Grid.Cell>
              <Grid.Cell columns={11}>
                <Text
                  text={`Permitted Purpose - Your project should involve the creation or improvement of a product or process. (e.g. improving performance or quality, building new functionality, etc.)`}
                />
              </Grid.Cell>
            </Grid>
            <Grid padding={8}>
              <Grid.Cell columns={1}>
                <Text text={`2.`} />
              </Grid.Cell>
              <Grid.Cell columns={11}>
                <Text
                  text={`Technical Uncertainty - You should have initial questions about the feasible technical application of your project (e.g. questions related to technical capabilities, quality of design, methodology, etc.)`}
                />
              </Grid.Cell>
            </Grid>
            <Grid padding={8}>
              <Grid.Cell columns={1}>
                <Text text={`3.`} />
              </Grid.Cell>
              <Grid.Cell columns={11}>
                <Text
                  text={`Process of Experimentation - You should be running experiments as you build your project. (e.g. A/B tests, MVPs, prototypes, etc)`}
                />
              </Grid.Cell>
            </Grid>
            <Grid padding={8}>
              <Grid.Cell columns={1}>
                <Text text={`4.`} />
              </Grid.Cell>
              <Grid.Cell columns={11}>
                <Text
                  text={`Technological in Nature - Your project should rely on hard sciences. (e.g. chemistry, biology, physics, computer science, engineering, etc.)`}
                />
              </Grid.Cell>
            </Grid>
            <Content paddingLeftRight={0} paddingTopBottom={24}>
              <Link
                href={
                  'https://mainstreet-help.force.com/help/s/article/What-is-the-4-Part-Test'
                }
                text='More details about the four-part test'
                external
                variant='medium'
              />
            </Content>
          </>
        }
        drawerFooterContent={
          <Checkbox
            options={[
              {
                checked: projectMeetsConditions,
                text: 'To the best of my knowledge, this project meets the conditions of the four-part test.',
                value: 'checked',
              },
            ]}
            onChange={() => setProjectMeetsConditions(!projectMeetsConditions)}
            dataTestId={'rd-project-side-drawer-checkbox'}
          />
        }
        title='Add R&D project'
        show={sideDrawerOpen}
      />
    </>
  );
};

export default RdMultipleProjectsTable;
