import React, { useEffect, useState } from 'react';
import StepsContainer from '../../component/StepsContainer';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import {
  Page,
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
  SupportedProgramTaxYears,
  SurveyNameEnum,
} from 'lib/constants';
import { SurveyQuestion, AnswerValueType } from 'component-library';
import { AnswerType } from 'component-library/dashboard/surveyquestion/SurveyQuestion';
import { observer } from 'mobx-react';
import { Loading } from 'components/util/Loading';
import _ from 'lodash';
import { Cancelable, MonthNameToNumber } from 'lib/helpers';
import {
  CmsQuestionData,
  QualificationQuestions,
  UpdateEmployeeDetailsInfoRequest,
} from 'lib/interfaces';
import {
  useCompany,
  useLegacyClients,
  useCommonStores,
  useFeatureFlags,
} from 'stores/useStores';
import { setDocumentPageTitle, useEffectOnce } from 'lib/helpers';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';

interface PayrollDetailsProps {
  taxYear: number;
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
}

export const getEmployeeDetailsRequest = (
  surveyAnswers: QualificationQuestions,
  doesNotEmployUsBasedSoftwarePeople: boolean,
  taxYear: number,
) => {
  const getFedRdBasePercentage = (): number => {
    const questionId = GraphCmsQuestionIdEnum.PERCENTAGE_OF_COMPANY_TECHNICAL;
    const answer = surveyAnswers[questionId];
    // Temporarily increase our base percentage by 30%.
    switch (answer) {
      case GraphCmsQuestionIdToAnswers[questionId]?.LESS_THAN_50_PERCENT:
        return 2 * 1.3;
      case GraphCmsQuestionIdToAnswers[questionId]?.ROUGHLY_50_PERCENT:
        return 3.5 * 1.3;
      case GraphCmsQuestionIdToAnswers[questionId]?.MORE_THAN_50_PERCENT:
        return 6 * 1.3;
      default:
        return 0;
    }
  };

  const getStartingPayrollMonth = (): number => {
    const questionId = GraphCmsQuestionIdEnum.FIRST_MONTH_OF_PAYROLL;
    let answer = surveyAnswers[questionId];
    if (_.isArray(answer)) {
      answer = answer[0];
    }

    const startingMonthName = Object.keys(
      GraphCmsQuestionIdToAnswers[questionId],
    ).find((key) => GraphCmsQuestionIdToAnswers[questionId][key] === answer);
    if (!startingMonthName) {
      return 0;
    }
    return MonthNameToNumber(startingMonthName);
  };

  const getEmployeeStates = (): string[] => {
    const questionId = GraphCmsQuestionIdEnum.MOST_EMPLOYEE_STATES;
    const answers = surveyAnswers[questionId] as string[];
    if (_.isEmpty(answers)) {
      return [];
    }
    return answers.reduce((result: string[], answer: string) => {
      const stateName = Object.keys(
        GraphCmsQuestionIdToAnswers[questionId],
      ).find((key) => GraphCmsQuestionIdToAnswers[questionId][key] === answer);
      if (stateName) {
        result.push(_.startCase(_.camelCase(stateName)));
      }
      return result;
    }, []);
  };

  return {
    doesEmploySoftwarePeople: !doesNotEmployUsBasedSoftwarePeople,
    mostEmployeeStates: getEmployeeStates(),
    fedRdBasePercentage: doesNotEmployUsBasedSoftwarePeople
      ? 0
      : getFedRdBasePercentage(),
    payrollEstimateCents: doesNotEmployUsBasedSoftwarePeople
      ? 0
      : surveyAnswers[
          GraphCmsQuestionIdEnum.ESTIMATED_EMPLOYEES_PAYROLL_EXPENSES_LAST_MONTH
        ] ?? 0,
    nonPayrollEstimateCents: doesNotEmployUsBasedSoftwarePeople
      ? 0
      : surveyAnswers[
          GraphCmsQuestionIdEnum
            .ESTIMATED_CONTRACTOR_PAYROLL_EXPENSES_LAST_MONTH
        ] ?? 0,
    startingPayrollMonth: getStartingPayrollMonth(),
    qualificationTaxYear: taxYear,
    currentEmployeeCount: doesNotEmployUsBasedSoftwarePeople
      ? 0
      : surveyAnswers[GraphCmsQuestionIdEnum.CURRENT_YEAR_EMPLOYEE_COUNT] ?? 0,
    yearEndEmployeeCount: doesNotEmployUsBasedSoftwarePeople
      ? 0
      : surveyAnswers[
          GraphCmsQuestionIdEnum.NEXT_YEAR_EXPECTED_EMPLOYEE_COUNT
        ] ?? 0,
    creditsSelected:
      surveyAnswers[GraphCmsQuestionIdEnum.CREDITS_SELECTED] || [],
  } as UpdateEmployeeDetailsInfoRequest;
};

export const PayrollDetails = observer(
  ({ taxYear, pathTo, pathFrom, onNext, onBack }: PayrollDetailsProps) => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const { app } = useCommonStores();
    const { client } = useLegacyClients();
    const { company } = useCompany();
    const featureFlags = useFeatureFlags();
    const showQualDqExperience = featureFlags.showQualificationDqExperience;
    const hidePayrollStep = featureFlags.hidePayrollStepInQualification;
    // Handle if linked to payroll or has manual payroll //
    const [hasLinkedPayroll, setHasLinkedPayroll] = useState<boolean | null>(
      null,
    );
    const [hasManualPayroll, setHasManualPayroll] = useState<boolean | null>(
      null,
    );
    const [
      estimatedEmployeePayrollError,
      setEstimatedEmployeePayrollError,
    ] = useState<boolean>(false);

    const onContinue = async () => {
      setButtonLoading(true);
      const req = getEmployeeDetailsRequest(
        surveyAnswers,
        doesNotEmployUsBasedSoftwarePeople,
        taxYear,
      );
      await saveAnswers();
      client.UpdateEmployeeDetailsInfo(req).then(({ errorMsg, data }) => {
        if (errorMsg || !data) {
          datadogLogs.logger.error(
            '[QUALIFICATION]: updating employee details responses failed',
            logContext({
              error: errorMsg,
              company,
            }),
          );
          return;
        }

        company.mergeData(data.company);

        if (data.hasQualifyingPrograms) {
          onNext();
          app.history.push(pathTo);
        } else {
          if (showQualDqExperience) {
            app.history.push(
              `/${Page.taxCredits}/${Page.disqualified}/${taxYear}`,
            );
          } else {
            app.history.push(`/`);
          }
        }
        setButtonLoading(false);
      });
    };

    const onBackStep = () => {
      onBack();
      app.history.push(pathFrom);
    };

    const currentQualifyingTaxYear =
      SupportedProgramTaxYears[SupportedProgramTaxYears.length - 1];

    useEffect(() => {
      return Cancelable(client.FinchTokenStatus(), (res) => {
        if (res.errorMsg) {
          setHasLinkedPayroll(false);
          return;
        }
        setHasLinkedPayroll(res.data!.hasToken);
        setHasManualPayroll(res.data!.hasManualToken);
      });
    }, [hasLinkedPayroll, hasManualPayroll, client]);

    const showEstimatedPayrollExpensesLastMonthQuestion = (): boolean =>
      ((!hasLinkedPayroll || hasManualPayroll) &&
        (hasLinkedPayroll !== null || hasManualPayroll !== null)) ??
      false;

    const {
      isSurveyComplete,
      isLoading,
      questionsToRender,
      addSurveyAnswers,
      surveyAnswers,
      saveAnswers,
    } = useSurveyQuestions(
      SurveyNameEnum.RD_CREDIT_AUTOQUAL_PAYROLL_DETAILS,
      taxYear,
      {
        shortCircuitConditions: [
          () =>
            Object.keys(questionsToRender).length > 0 &&
            doesNotEmployUsBasedSoftwarePeople,
        ],
        showConditionOverrides: {
          [GraphCmsQuestionIdEnum.ESTIMATED_EMPLOYEES_PAYROLL_EXPENSES_LAST_MONTH]: showEstimatedPayrollExpensesLastMonthQuestion,
          [GraphCmsQuestionIdEnum.NEXT_YEAR_EXPECTED_EMPLOYEE_COUNT]: () =>
            taxYear === currentQualifyingTaxYear,
        },
      },
    );

    const doesNotEmployUsBasedSoftwarePeople =
      surveyAnswers[GraphCmsQuestionIdEnum.EMPLOY_US_BASED_PEOPLE] &&
      surveyAnswers[GraphCmsQuestionIdEnum.EMPLOY_US_BASED_PEOPLE] ===
        GraphCmsQuestionIdToAnswers[
          GraphCmsQuestionIdEnum.EMPLOY_US_BASED_PEOPLE
        ]?.NO;

    const qualifyingForCurrentTaxYear = taxYear === currentQualifyingTaxYear;

    const getOverridenQuestionText = (question: CmsQuestionData) => {
      if (
        question.id === GraphCmsQuestionIdEnum.CURRENT_YEAR_EMPLOYEE_COUNT &&
        qualifyingForCurrentTaxYear
      ) {
        return 'How many employees do you currently have?';
      }
      return question.text;
    };

    const errorTextByQuestionId: Partial<Record<
      GraphCmsQuestionIdEnum,
      string
    >> = {
      [GraphCmsQuestionIdEnum.ESTIMATED_EMPLOYEES_PAYROLL_EXPENSES_LAST_MONTH]:
        'You must pay employees in order to qualify for R&D credits.',
    };

    const onChangeByQuestion = (key: string, question: CmsQuestionData) => {
      const defaultOnChange = (answer: AnswerValueType) => {
        question.answerValue = answer;
        addSurveyAnswers(question.id, key, [
          { questionId: question.id, answerValue: answer },
        ]);
      };

      switch (question.id) {
        case GraphCmsQuestionIdEnum.ESTIMATED_EMPLOYEES_PAYROLL_EXPENSES_LAST_MONTH:
          return (answer: AnswerValueType) => {
            const invalidAnswer = answer <= 0 || isNaN(answer as number);
            setEstimatedEmployeePayrollError(invalidAnswer);

            if (invalidAnswer) {
              if (!isNaN(answer as number)) {
                datadogLogs.logger.warn(
                  '[QUALIFICATION]: user inputted value of $0 for estimated employee payroll last month.',
                  logContext({
                    company,
                  }),
                );
              }
              if (question.answerValue) {
                // Overriding to undefined prevents the continue button from appearing.
                question.answerValue = undefined;
                addSurveyAnswers(question.id, key, [
                  { questionId: question.id, answerValue: undefined },
                ]);
              }
              return;
            }

            defaultOnChange(answer);
          };
        default:
          return defaultOnChange;
      }
    };

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => (
          <SurveyQuestion
            key={question.id}
            dataTestId={question.id}
            answerType={question.answerType as AnswerType}
            text={getOverridenQuestionText(question)}
            subtitle={question.subtitle}
            answerOptions={question.answerIDs}
            onChange={onChangeByQuestion(key, question)}
            error={
              question.id ===
              GraphCmsQuestionIdEnum.ESTIMATED_EMPLOYEES_PAYROLL_EXPENSES_LAST_MONTH
                ? estimatedEmployeePayrollError
                : undefined
            }
            errorText={
              errorTextByQuestionId[question.id as GraphCmsQuestionIdEnum]
            }
            placeholder={question.placeholder}
            answerValue={question.answerValue}
            tooltip={question.tooltip}
            questionTextSize={app.isMobile ? 15 : 18}
            questionSubtitleSize={app.isMobile ? 13 : 15}
          />
        ));
      });

    useEffectOnce(() =>
      setDocumentPageTitle('Qualification - Payroll Details'),
    );

    return (
      <StepsContainer
        onBackStep={hidePayrollStep ? undefined : onBackStep}
        showContinueButton={isSurveyComplete}
        onContinue={onContinue}
        onContinueLabel='Continue'
        isLoading={buttonLoading}
        stepTitle='Fill in your payroll details'
        subtitle='This helps us understand which MainStreet services you may be eligible for.'
      >
        <Loading loading={isLoading}>
          {!_.isEmpty(questionsToRender) && renderQuestions()}
        </Loading>
      </StepsContainer>
    );
  },
);
