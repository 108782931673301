import { createMuiTheme } from '@material-ui/core/styles';

interface HelpText {
  color: string;
  weight: number;
}

interface Neutral {
  background: string;
  white: string;
  black: string;
  lines: string;
  cardBright: string;
  cardDefault: string;
  cardOpaque: string;
  cardDisabled: string;
}

interface Primary {
  blue100: string;
  blue400: string;
  blue500: string;
  blue600: string;
  green400: string;
  green500: string;
  green600: string;
  yellow500: string;
}

interface Secondary {
  copper100: string;
  copper500: string;
  bordeaux100: string;
  bordeaux500: string;
  teal100: string;
  teal500: string;
  grape100: string;
  grape500: string;
  navy100: string;
  navy500: string;
  pink100: string;
  pink500: string;
}

interface Semantic {
  info100: string;
  info500: string;
  success100: string;
  success500: string;
  warning100: string;
  warning500: string;
  danger100: string;
  danger300: string;
  danger500: string;
}

interface Greys {
  grey100: string;
  grey200: string;
  grey400: string;
  grey500: string;
  grey600: string;
  grey700: string;
  grey800: string;
}

interface Shadow {
  defaultCardShadow: string;
  raisedCardShadow: string;
  disabledCardShadow: string;
}

interface Color {
  neutral: Neutral;
  primary: Primary;
  secondary: Secondary;
  semantic: Semantic;
  greys: Greys;
  shadow: Shadow;
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    headerFontFamily: string;
    boxShadowRegular: string;
    boxShadowDark: string;
    navBarBoxShadow: string;
    dividerBorder: string;
    dividerBorderLight: string;
    lightNavy: string;
    lightBlue: string;
    emerald: string;
    cream: string;
    navy: string;
    darkBlue: string;
    darkNavy: string;
    darkerNavy: string;
    green: string;
    grey: string;
    lightGrey: string;
    lighterGrey: string;
    copper: string;
    yellow: string;
    pageBackgroundColor: string;
    boxBackgroundColor: string;
    helpText: HelpText;
    color: Color;
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    headerFontFamily?: string;
    boxShadowRegular?: string;
    boxShadowDark?: string;
    navBarBoxShadow?: string;
    dividerBorder?: string;
    dividerBorderLight?: string;
    lightNavy?: string;
    lightBlue?: string;
    emerald?: string;
    navy?: string;
    cream?: string;
    darkBlue?: string;
    darkNavy?: string;
    darkerNavy?: string;
    green?: string;
    grey?: string;
    lightGrey?: string;
    lighterGrey?: string;
    copper?: string;
    yellow?: string;
    pageBackgroundColor?: string;
    boxBackgroundColor?: string;
    helpText?: HelpText;
    color?: Color;
  }
}

// rebrand replace colors
const replaceHex = {
  neutral: {
    _20: '#EBF0EC',
    _light_20: '#F9FAF7',
    _50: '#919992',
    _70: '#5F6660',
    _90: '#0A1A0C',
  },
  blue: {
    _70: '#1F44CC',
    _80: '#15308F',
  },
  error: {
    _50: '#DB2D27',
  },
  success: {
    _50: '#46B256',
  },
  green: {
    _70: '#0B654A',
  },
};

export const mainstreetTheme = createMuiTheme({
  typography: {
    button: {
      fontWeight: 700,
    },
    fontFamily: "'Alliance Text', sans-serif",
    caption: {
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '.20px',
      color: '#102736',
    },
    body1: {
      color: '#102736',
      fontSize: '16px',
      letterSpacing: '.26px',
      fontWeight: 400,
      lineHeight: 1.625,
    },
    h3: {
      color: '#102736',
      fontSize: '16px',
      letterSpacing: '.22px',
      fontWeight: 500,
    },
    subtitle1: {
      color: '#102736',
      fontSize: '16px',
      letterSpacing: '.22px',
      fontWeight: 500,
    },
    h1: {
      color: '#102736',
      fontSize: '32px',
      letterSpacing: '.44px',
      fontWeight: 400,
    },
    h2: {
      color: '#102736',
      fontSize: '20px',
      letterSpacing: '.33px',
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#40525E',
    },
    secondary: {
      main: '#1F6AB0',
    },
    error: {
      main: '#E25950',
      contrastText: '#102736',
    },
    warning: {
      main: '#FFEB8A',
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: '#566774',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '0',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '0',
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#FFF',
      },
    },
  },
  boxShadowRegular:
    '0px 0px 4px rgba(21, 36, 31, 0.04), 0px 0px 16px rgba(238, 238, 238, 0.16)',
  boxShadowDark:
    '0px 0px 6px rgba(21, 36, 31, 0.1), 0px 0px 16px rgba(238, 238, 238, 0.48)',
  navBarBoxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
  dividerBorder: 'solid 2px rgba(35, 53, 66, 0.33)',
  dividerBorderLight: 'solid 1px rgba(151, 151, 151, 0.33)',
  lightNavy: '#13385E',
  lightBlue: '#78BEF1',
  navy: '#233542',
  cream: '#FFFDF2',
  darkBlue: '#1F6AB0',
  darkNavy: '#001D33',
  darkerNavy: '#102736',
  emerald: '#056037',
  green: '#067B27',
  grey: '#7E8A97',
  lightGrey: '#E4E4E4',
  lighterGrey: '#F3F4EF',
  copper: '#B04C1F',
  yellow: '#FFEB8A',
  helpText: {
    color: '#738089',
    weight: 600,
  },
  pageBackgroundColor: '#FFFDF2',
  boxBackgroundColor: '#FFFEF7',
  /**
   * @deprecated use component-library Color instead of mui theme colors
   */
  // Colors
  color: {
    neutral: {
      background: '#FFF',
      black: '#000',
      cardBright: '#fffefa',
      cardDefault: '#FFF',
      cardDisabled: '#ebeae9',
      cardOpaque: replaceHex.neutral._light_20,
      lines: replaceHex.neutral._20,
      white: '#fff',
    },
    primary: {
      blue100: '#dceaf7',
      blue400: '#4084b8',
      blue500: replaceHex.blue._70,
      blue600: replaceHex.blue._80,
      green400: '#2e9f61',
      green500: replaceHex.green._70,
      green600: '#035236',
      yellow500: '#ffeb8a',
    },
    secondary: {
      copper100: '#e9dfe3',
      copper500: '#bd6842',
      bordeaux100: '#f9dcdc',
      bordeaux500: '#4f2034',
      teal100: '#d5e3e9',
      teal500: '#306c84',
      grape100: '#e7e1fa',
      grape500: '#44386b',
      navy100: '#dadff4',
      navy500: '#435391',
      pink100: '#efcddb',
      pink500: '#9d4b6c',
    },
    semantic: {
      danger100: '#FFF',
      danger300: '#bd6e64',
      danger500: replaceHex.error._50,
      info100: '#f6f7f2',
      info500: '#003844',
      success100: '#FFF',
      success500: replaceHex.success._50,
      warning100: '#FFF',
      warning500: '#B8A03C', // temporary
    },
    greys: {
      grey100: '#ebeced',
      grey200: '#cfd4d7',
      grey400: replaceHex.neutral._50,
      grey500: '#849099',
      grey600: replaceHex.neutral._70,
      grey700: replaceHex.neutral._70,
      grey800: replaceHex.neutral._90,
    },
    shadow: {
      defaultCardShadow: '0px 1px 16px rgba(#d7d3bc, 0.32)',
      raisedCardShadow: '0px 8px 24px rgba(#d7d3bc, 0.32)',
      disabledCardShadow: '0px 0px 2px rgba(#d7d3bc, 0.32)',
    },
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.42, 0, 0.58, 1)',
    },
  },
});

/*
Login Actions: --> caption
Help Text: --> caption (local class for fontWeight + color)
TextField Error: --> wrap in FormTextHelper error for color, plus caption
Content Text: --> body1
Section Header: --> h3
Non-underlined Section Header: --> subtitle1
Page Header: --> h1
Nav Header: --> h2
*/
