import React from 'react';
import {
  Button,
  Callout,
  Color,
  Icon,
  IconEnum,
  Link,
  Text,
  TextField,
  Flex,
  Spinner,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useTaxCreditsStores } from 'stores/useStores';
import { TaxInputValueEnum } from 'lib/constants';

const useStyles = makeStyles(() => ({
  horizontalLine: {
    width: '100%',
    height: '1px',
    backgroundColor: Color.neutral._20,
    margin: '8px 0 16px',
  },
  orderedList: {
    margin: '0 auto 24px',
    padding: '24px 24px 24px 36px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    border: `1px solid ${Color.neutral._20}`,
  },
}));

export const Form8974Tier3CreditRedemption = observer(() => {
  const { form8974 } = useTaxCreditsStores();
  const classes = useStyles();

  if (form8974.isLoading) {
    return (
      <Flex
        direction='column'
        justifyContent='center'
        alignItems='center'
        padding={[80, 0, 0, 0]}
      >
        <Flex justifyContent='center' padding={16}>
          <Spinner color='dark-navy' size='small' />
        </Flex>
      </Flex>
    );
  }

  if (form8974.form8974Generating) {
    return (
      <Flex
        direction='column'
        justifyContent='center'
        alignItems='center'
        padding={[80, 0, 0, 0]}
      >
        <Flex justifyContent='center' padding={16}>
          <Spinner color='dark-navy' size='small' />
        </Flex>
        <Text text='Generating Form 8974' variant='medium' size={18} />
      </Flex>
    );
  }

  if (form8974.form8974GeneratedDocumentUrl) {
    return (
      <Flex direction='column'>
        <Text variant='medium' size={15}>
          Next steps
        </Text>
        <div className={classes.horizontalLine}></div>
        <ol className={classes.orderedList}>
          <li>
            <Text size={15}>Download this quarter&apos;s Form 8974 below.</Text>
          </li>
          <li>
            <Text size={15}>
              File Form 8974 along with your quarterly payroll taxes.
            </Text>
          </li>
          <li>
            <Text size={15}>
              If you remit your payroll taxes to the IRS more regularly, you can
              expect a refund check from the IRS in 8-12 weeks.
            </Text>
          </li>
          <li>
            <Text size={15}>
              Rinse + repeat next quarter, until you&apos;ve fully utilized your
              credits.
            </Text>
          </li>
          <Text>
            <Link
              external
              href={`https://mainstreet-help.force.com/help/s/topic/0TO5f000000I96tGAC/quarterly-filing-documents`}
              text='How to file this form with your taxes'
              variant='medium'
            />
          </Text>
        </ol>

        <Button
          label={
            <Flex justifyContent='center'>
              <Icon name={IconEnum.download} color={Color.blue._60} />
              <Text
                text="Download this quarter's Form 8974"
                color={Color.blue._60}
              />
            </Flex>
          }
          onClick={() =>
            window.open(form8974.form8974GeneratedDocumentUrl, '_blank')
          }
          variant='outlined'
        />
      </Flex>
    );
  }

  return (
    <Flex direction='column'>
      <Text variant='medium' size={15}>
        Before you can download your Form 8974, please enter some important
        details about your payroll expenses
      </Text>
      <div className={classes.horizontalLine}></div>
      <TextField
        label='Social security taxes for last quarter'
        value={
          form8974.carryoverWageTaxCents && form8974.carryoverWageTaxCents / 100
        }
        currencyFormat={true}
        type='number'
        onChange={(e) =>
          form8974.handleCurrencyInputValue(
            e,
            TaxInputValueEnum.SOCIAL_SECURITY,
          )
        }
        dataTestId='payroll-expense-question'
      />
      <Flex padding={[24, 0, 24, 0]}>
        <Callout
          title={
            'Find this information on line 5A, column 2 of your 941. Your payroll provider will have this handy.'
          }
        />
      </Flex>
      <TextField
        label='Medicare taxes for last quarter'
        value={form8974.medicareTaxCents && form8974.medicareTaxCents / 100}
        currencyFormat={true}
        type='number'
        onChange={(e) =>
          form8974.handleCurrencyInputValue(e, TaxInputValueEnum.MEDICARE)
        }
        dataTestId='medicare-taxes-question'
      />
      <Flex padding={[24, 0, 48, 0]}>
        <Callout
          title={
            'Find this information on line 5C, column 2 of your 941. Your payroll provider will have this handy.'
          }
        />
      </Flex>
      <Button
        label='Continue'
        variant='outlined'
        disabled={!form8974.generate8974FormEnabled}
        onClick={() => form8974.generateForm8974()}
        dataTestId='continue-button'
      />
    </Flex>
  );
});
