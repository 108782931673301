import React from 'react';
import { observer } from 'mobx-react';
import { useTaxCreditsStores } from 'stores/useStores';
import { Button, Flex, Modal, Text } from 'component-library';
import { EmptyClient } from '../../../../../services/ServerClient';

interface SubmitForReviewModalProps {
  onNext: () => void;
  taxYear?: number;
}

export const SubmitForReviewModal: React.FC<SubmitForReviewModalProps> =
  observer(({ onNext, taxYear }) => {
    const { unifiedTaxCredits } = useTaxCreditsStores();

    const submitForReview = async () => {
      unifiedTaxCredits.setHasJustCompletedAllAssessments(true);
      unifiedTaxCredits.setShowSubmitReviewModal(false);
      if (taxYear) {
        await EmptyClient.UpdateCompanyMisc({
          submittedTaxYears: [taxYear],
        });
      }
      onNext();
    };

    return (
      <Modal
        showModal={unifiedTaxCredits.showSubmitReviewModal}
        maxWidth={540}
        dataTestId='submit-for-review-modal'
      >
        <Flex padding={24} direction='column' gap={16}>
          <Text size={18} variant='medium'>
            Ready to submit for review?
          </Text>
          <Text paddingBottom={24}>
            You are about to submit all the information provided across all
            credit assessments to MainStreet for a review. Please ensure you
            have provided accurate information before submitting as this may
            impact your finalized credit amount.
          </Text>
          <Flex gap={24}>
            <Button
              onClick={submitForReview}
              dataTestId={'submit-yea-for-review'}
              label='Submit for review'
            />
            <Button
              onClick={() => unifiedTaxCredits.setShowSubmitReviewModal(false)}
              label='Cancel'
              variant='outlined'
            />
          </Flex>
        </Flex>
      </Modal>
    );
  });
