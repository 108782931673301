import React from 'react';
import { observer } from 'mobx-react';
import { useCommonStores, useTaxCreditsStores } from 'stores/useStores';
import { Button, Flex, Modal, Text } from 'component-library';
import {
  AssessmentProgressStatus,
  TaxCreditAssessmentGeneralHygraphId,
  TaxCreditAssessmentProgramsHygraphId,
  TaxCreditAssessments,
} from 'lib/interfaces';
import { Page, ProgramNameEnum } from 'lib/constants';

interface IncompleteProgramSurveyModalProps {
  taxYear: number;
  assessments: TaxCreditAssessments[];
}

export const IncompleteProgramSurveyModal: React.FC<IncompleteProgramSurveyModalProps> =
  observer(({ taxYear, assessments }) => {
    const { unifiedTaxCredits } = useTaxCreditsStores();
    const { app } = useCommonStores();
    const assessmentSurveyRootPath = `/${Page.taxCredits}/${Page.assessment}/${taxYear}`;

    const filterDqPrograms = assessments.filter(
      (p) =>
        p.status !== AssessmentProgressStatus.DQ &&
        p.id !== TaxCreditAssessmentGeneralHygraphId.GENERAL_BUSINESS_DETAILS,
    );

    const atLeastOneSurveyNotInMsReview = filterDqPrograms.filter(
      (p) => p.status !== AssessmentProgressStatus.MS_REVIEW,
    );

    const getSurveyUrl = (surveyId: string) => {
      switch (surveyId) {
        case TaxCreditAssessmentProgramsHygraphId[
          ProgramNameEnum.FED_RETIREMENT_CREDIT
        ]:
          return `${assessmentSurveyRootPath}/${Page.retirementPlan}`;
        case TaxCreditAssessmentProgramsHygraphId[
          ProgramNameEnum.FED_EMPLOYER_HEALTHCARE
        ]:
          return `${assessmentSurveyRootPath}/${Page.healthcare}`;
        case TaxCreditAssessmentProgramsHygraphId[
          ProgramNameEnum.FED_DISABLED_ACCESS
        ]:
          return `${assessmentSurveyRootPath}/${Page.disabledAccess}`;
        default:
          return `${assessmentSurveyRootPath}/${Page.expenseClassificationCompanyDetails}`;
      }
    };

    return (
      <Modal
        showModal={unifiedTaxCredits.showIncompleteProgramModal}
        maxWidth={540}
        dataTestId='submit-for-review-modal'
      >
        <Flex padding={24} direction='column' gap={16}>
          <Text size={18} variant='medium'>
            At least one survey is incomplete
          </Text>
          <Text>
            Please complete the following survey to submit for review.
          </Text>

          {atLeastOneSurveyNotInMsReview.map((p, index) => {
            return (
              <Flex key={`${p.id}-${index}`} gap={8} alignItems='center'>
                <Text>• {p.assessmentTitle}:</Text>
                {getSurveyUrl(p.id) && (
                  <Button
                    variant='tertiary'
                    label='Edit'
                    onClick={() => {
                      app.history.push(getSurveyUrl(p.id));
                      unifiedTaxCredits.setShowIncompleteProgramModal(false);
                    }}
                  />
                )}
              </Flex>
            );
          })}
          <Flex gap={24}>
            <Button
              onClick={() =>
                unifiedTaxCredits.setShowIncompleteProgramModal(false)
              }
              label='Cancel'
              variant='outlined'
            />
          </Flex>
        </Flex>
      </Modal>
    );
  });
