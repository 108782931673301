import React from 'react';
import { observer } from 'mobx-react';
import { Color, Grid, Text, Link } from 'component-library';

interface AutorenewalNoticeContentProps {
  autoRenewalCopy?: string;
}

export const AutorenewalNoticeContent = observer(
  ({
    autoRenewalCopy = 'Your MainStreet subscription will automatically renew every year with no action needed on your end. Within 30 days of your renewal, you will be notified of any changes.',
  }: AutorenewalNoticeContentProps) => {
    return (
      <Grid.Cell
        columns={12}
        padding={24}
        alignSelf='center'
        style={{ borderTop: `1px solid ${Color.neutral._20}` }}
      >
        <Grid columns={12} alignItems='center' gap={24}>
          <Grid.Cell columns={12}>
            <Text>
              {autoRenewalCopy} Please contact us at{' '}
              <Link
                variant='medium'
                href='mailto:support@mainstreet.com'
                target='_blank'
                rel='noreferrer'
              >
                support@mainstreet.com
              </Link>
              with any questions.
            </Text>
          </Grid.Cell>
        </Grid>
      </Grid.Cell>
    );
  },
);
