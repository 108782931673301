import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Color,
  Flex,
  FontAwesome,
  Grid,
  Heading,
  Text,
} from 'component-library';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  limitedTimeChip: {
    borderRadius: '20px',
    backgroundColor: Color.lime._20,
  },
}));

interface BogoDealContentProps {
  programTaxYear: number;
}

export const BogoDealContent: React.FC<BogoDealContentProps> = observer(
  ({ programTaxYear }) => {
    const classes = useStyles();
    const { app } = useCommonStores();

    return (
      <Grid
        columns={12}
        backgroundColor={Color.green._80}
        padding={24}
        gap={24}
      >
        <Grid.Cell columns={app.isMobile ? 12 : 8}>
          <Heading
            text={`Get ${programTaxYear} R&D tax credits free of charge`}
            size={app.isSmallDesktop ? 18 : 23}
            color={Color.lime._60}
            noMargin
          />
          <Text
            color={Color.neutral.white}
            text={`When you sign up for your ${
              programTaxYear + 1
            } R&D tax credits with MainStreet.`}
          />
        </Grid.Cell>
        <Grid.Cell
          columns={app.isMobile ? 12 : 4}
          style={{ textAlign: app.isMobile ? 'center' : 'right' }}
        >
          <Flex
            inline
            className={classes.limitedTimeChip}
            padding={[4, 16]}
            alignItems='center'
            gap={8}
          >
            <FontAwesome
              name='timer'
              variant='regular'
              size={13}
              color={Color.green._70}
            />
            <Text
              variant='medium'
              text='For a limited time only'
              color={Color.green._70}
            />
          </Flex>
        </Grid.Cell>
        <Grid.Cell
          columns={app.isMobile ? 12 : 6}
          style={{ backgroundColor: Color.green._70, borderRadius: '8px' }}
          padding={16}
        >
          <Text
            text={`Pay only for your ${programTaxYear + 1} tax program`}
            variant='medium'
            color={Color.neutral.white}
          />
          <Text color={Color.neutral.white}>
            {`You'll get MainStreet's services for your ${programTaxYear} program for free! You'll start paying for ${
              programTaxYear + 1
            } ${
              programTaxYear === 2022
                ? `next month`
                : `in January ${programTaxYear + 1}`
            } based on an estimate from the data you've provided.`}
          </Text>
        </Grid.Cell>
        <Grid.Cell
          columns={app.isMobile ? 12 : 6}
          style={{ backgroundColor: Color.green._70, borderRadius: '8px' }}
          padding={16}
        >
          <Text
            text={`About ${programTaxYear + 1} Estimates`}
            variant='medium'
            color={Color.neutral.white}
          />
          <Text
            text={`Our estimates account for your company's potential to grow throughout the year, so our ${
              programTaxYear + 1
            } estimate could be higher than ${programTaxYear}.`}
            color={Color.neutral.white}
          />
        </Grid.Cell>
        <Grid.Cell columns={12}>
          <Text
            text={`If your company decides to no longer work with MainStreet for ${
              programTaxYear + 1
            } R&D tax credits, this offer will be void. Your company will be responsible for paying the MainStreet fee for ${programTaxYear} R&D tax credits in full.`}
            color={Color.neutral.white}
          />
        </Grid.Cell>
      </Grid>
    );
  },
);
