import * as React from 'react';
import { useContext } from 'react';
import { Flex, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { Documents } from 'pages/dashboard/documents';
import { CompanyContext } from '../../CompanyRequired';
import { observer } from 'mobx-react';
import TalkToAnExpertButton from 'components/util/TalkToAnExpertButton';
import { useFeatureFlags } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    maxWidth: '1050px',
  },
}));

export const DocumentsPage = observer(() => {
  const classes = useStyles();
  const { company } = useContext(CompanyContext);
  const featureFlags = useFeatureFlags();

  return (
    <div className={classes.root} data-testid={'documents-page'}>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        gap={24}
        padding={[0, 0, 24, 0]}
      >
        <Text size={40} variant='medium'>
          Documents
        </Text>
        {featureFlags.showTalkToAnExpertButton && <TalkToAnExpertButton />}
      </Flex>

      <Documents programs={company.programs} />
    </div>
  );
});
