import React from 'react';
import { Text } from 'component-library';
import { makeStyles, Theme } from '@material-ui/core';
import classnames from 'classnames/bind';

const useStyles = makeStyles(({ palette }: Theme) => ({
  error: {
    color: palette.error.main,
    margin: '8px',
  },
}));

export const ErrorMessage: React.FC<{ error: string; className?: string }> = ({
  error,
  className,
}) => {
  const classes = useStyles();

  return (
    <Text className={classnames(classes.error, className)} size={15}>
      {error}
    </Text>
  );
};
