import { useEffect, useRef, useState } from 'react';
import { SurveyNameEnum } from './constants';
import {
  CmsQuestionData,
  CmsQuestionGroupData,
  CmsRenderTree,
  CompanyData,
  QualificationQuestions,
} from './interfaces';
import { SurveyAnswer } from './useSurveyQuestions';
import { useCompany, useLegacyClients } from 'stores/useStores';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';

const hydrateSurveyAnswers = (
  questions: CmsQuestionData[],
  surveyAnswers: QualificationQuestions,
): CmsQuestionData[] =>
  questions.map((question) => ({
    ...question,
    answerValue: surveyAnswers[question.id],
    subQuestions: question.subQuestions?.map((subQuestion) => {
      return { ...subQuestion, answerValue: surveyAnswers[subQuestion.id] };
    }),
  }));

/**
 * parseTemplatedValues strips out dynamic variables the original hygraph question and replaces
 * with the desired string.
 *
 * Example:
 * For hygraph question id ckgij1soo0zrv0a74xmmxz35s at https://app-us-west-2.hygraph.com/0b99f68a0ef140cda6965c2de4ccb2ab/master/content/f3423357ee3941569bcac57ecef80c9d/view/78885f8a278046e7868e872a7865a7f7/clnw7290dv3v80amvfyafnxpu
 *
 * The hygraph question text is Did you make more than $5 million in gross receipts during ${taxYear} tax year?
 * This function will replace ${taxYear} with the taxYear in its local scopes (i.e. that is passed into the function)
 */
const parseTemplatedValues = (
  surveyName: SurveyNameEnum,
  taxYear: number,
  company: CompanyData,
  questions: CmsQuestionData[],
): CmsQuestionData[] => {
  const getQuestionText = (text: string) => {
    const templateVars = text.match(/\${\w+(.\w+)?}/g);
    if (!templateVars) {
      return text;
    }
    let result = text;
    templateVars.forEach((templateVar) => {
      try {
        const varName = templateVar.match(/\${(.*)\}/)?.pop(); // remove templating to extract var name
        // eslint-disable-next-line
        const replacementString = varName && eval(varName); // e.g. evaluates taxYear as the taxYear param passed in

        const pattern = new RegExp(`\\${templateVar}`, 'g');
        result = result.replace(pattern, replacementString ?? '').toString();
      } catch (err) {
        result = text.replace(/\${\w+(.\w+)}\s/g, '');
        datadogLogs.logger.warn(
          `Failed to parse survey ${surveyName} questionText: ${text} templateVar: ${templateVar}`,
          logContext({
            company,
            error: err,
          }),
        );
      }
    });
    return result;
  };

  return questions.map((q) => {
    return {
      ...q,
      text: getQuestionText(q.text),
      subQuestions:
        q.subQuestions && q.subQuestions.length
          ? q.subQuestions.map((sq) => ({
              ...sq,
              text: getQuestionText(sq.text),
            }))
          : q.subQuestions,
    };
  });
};

export const useQuestionGroup = (
  surveyName: SurveyNameEnum,
  taxYear: number,
  surveyAnswers: Record<string, any>,
): {
  questions: CmsQuestionData[];
  graphCmsRenderTree: CmsRenderTree;
  isLoading: boolean;
  errorMsg: string | undefined;
  setQuestionAnswers: (answers: SurveyAnswer[]) => void;
} => {
  const [questions, setQuestions] = useState<CmsQuestionData[]>([]);
  const [graphCmsRenderTree, setGraphCmsRenderTree] = useState<CmsRenderTree>(
    {} as CmsRenderTree,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const { client } = useLegacyClients();
  const { company, store: companyStore } = useCompany();
  const updateGroupDataRef = useRef<
    null | ((questionGroupData: CmsQuestionGroupData) => void)
  >(null);

  /**
   * Question hydration (merging existing answers into question objects) must happen
   * with the more up to date version of the surveyAnswers object. Because we make an
   * API call in the useEffect, the promise callback will always use an older version
   * of surveyAnswers if his hook is rerendered with new data
   *
   * Because of that, this reference wrapper around data hydration is required, in order
   * to ensure the surveyAnswers object is always using the latest one sent with the
   * most recent hook rerender
   *
   * @param questionGroupData Question group data returned from fetch questions API
   */
  updateGroupDataRef.current = (questionGroupData: CmsQuestionGroupData) => {
    const { qualificationQuestions, questionRenderTree } = questionGroupData;

    const parsedQuestionData = parseTemplatedValues(
      surveyName,
      taxYear,
      company.rawData as CompanyData,
      qualificationQuestions,
    );
    const hydratedQuestions = hydrateSurveyAnswers(
      parsedQuestionData,
      surveyAnswers,
    );
    setQuestions(hydratedQuestions);
    setGraphCmsRenderTree(questionRenderTree);
  };

  const surveyFetchRef = useRef<SurveyNameEnum | null>(null);
  useEffect(() => {
    if (!surveyName || surveyFetchRef.current === surveyName) {
      return;
    }
    surveyFetchRef.current = surveyName;

    const getSurveyQuestionGroupBySurveyName = companyStore.accessToken
      ? client.GetSurveyQuestionGroupBySurveyNamePublic
      : client.GetSurveyQuestionGroupBySurveyName;
    if (isLoading) {
      getSurveyQuestionGroupBySurveyName(surveyName)
        .then((res) => {
          if (res.errorMsg) {
            setErrorMsg(res.errorMsg);
            return;
          }

          const questionGroupData = res.data?.questionGroupData;

          if (questionGroupData && updateGroupDataRef.current) {
            updateGroupDataRef.current(questionGroupData);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [isLoading, surveyName, client, companyStore.accessToken]);

  const setQuestionAnswers = (answers: SurveyAnswer[]) => {
    const newState = [...questions];
    answers.forEach((answer) => {
      // a question can appear as both a parent question as well as a subquestion. Handling for each case here
      const question = newState.find(
        (question) => question.id === answer.questionId,
      );
      if (question) {
        question.answerValue = answer.answerValue;
      }

      const questionAsASubQuestion = newState.find(
        (question) =>
          question.subQuestions &&
          question.subQuestions.find((q) => q.id === answer.questionId),
      );
      if (questionAsASubQuestion) {
        const subQuestion = questionAsASubQuestion.subQuestions?.find(
          (question) => question.id === answer.questionId,
        );
        if (subQuestion) {
          subQuestion.answerValue = answer.answerValue;
        }
      }
    });

    setQuestions(newState);
  };

  return {
    questions,
    graphCmsRenderTree,
    isLoading,
    errorMsg,
    setQuestionAnswers,
  };
};

export const renderTaxYearInQuestionText = (
  questionText: string,
  replaceValue: number,
) => {
  return questionText.replace('${taxYear}', String(replaceValue));
};
