import React from 'react';
import { Alert, Button, Color, Flex, IconEnum, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import {
  useCommonStores,
  useDashboardStores,
} from '../../../../../../stores/useStores';
import { CountdownTimer } from '../../util/CountdownTimer';
import { useIsMounted } from 'component-library/_helpers/use-utils';

const useStyles = makeStyles(() => ({
  alertAtTop: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1000, // Ensure it stays on top of other content
    backgroundColor: Color.green._90,
    padding: 8,
  },
  contentWrapper: {
    maxWidth: '1440px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    margin: '0 auto',
    justifyContent: 'space-between',
  },
  timerAndButton: {
    alignItems: 'center',
    gap: '8px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  countdownTimer: {
    justifyContent: 'right',
  },
  buttonStyle: {
    backgroundColor: Color.neutral.white,
    justifyContent: 'left',
  },
}));

export const AlertSection: React.FC = () => {
  const classes = useStyles();
  const { app } = useCommonStores();
  const { modules } = useDashboardStores();
  const isMounted = useIsMounted();

  return (
    <Flex className={classes.alertAtTop} gap={app.isTablet ? 24 : 40}>
      <Flex
        className={classes.contentWrapper}
        direction={app.isTablet ? 'column' : 'row'}
      >
        <Flex basis={'75%'}>
          <Alert
            text={
              <Flex
                alignItems='center'
                justifyContent='center'
                style={{ color: Color.lime._60 }}
              >
                Most tax credits are only available for a limited time - secure
                yours before the tax deadline.
              </Flex>
            }
            type='info'
            icon={IconEnum.clock_circle}
            variant='inline'
            backgroundColor={Color.green._90}
            iconColor={Color.lime._60}
            messageIconGap={4}
          />
        </Flex>

        <Flex direction='row' className={classes.timerAndButton} basis={'25%'}>
          <Flex basis={'50%'}>
            <CountdownTimer
              className={classes.countdownTimer}
              deadline={new Date(2024, 3, 15)}
              textColor={Color.lime._60}
            ></CountdownTimer>
          </Flex>
          <Flex basis={'50%'}>
            <Button
              className={classes.buttonStyle}
              variant={'outlined-gray'}
              label={
                <Flex justifyContent='center'>
                  <Text variant='medium'>Sign up by April 15th</Text>
                </Flex>
              }
              onClick={() => {
                modules.toggleSignUpModal(isMounted());
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
