import {
  makeSubclassObservable,
  MobxObservableAnnotation,
} from 'lib/mobx-utils';
import { runInAction } from 'mobx';
import { BaseStore } from 'stores/BaseStore';
import { RootStore } from '../RootStore';
import jwt_decode from 'jwt-decode';
import {
  Auth0Client,
  GenericError,
  RedirectLoginOptions,
  User,
} from '@auth0/auth0-spa-js';
import { cacheLocation } from 'lib/auth0CacheLocation';
import { AuthUser } from 'entities/AuthUser';
import {
  GetFromSessionStorage,
  RemoveFromSessionStorage,
  SetInSessionStorage,
} from 'lib/sessionStorage';
import { setDatadogUserContext } from 'logging/datadogContext';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import authConstants from 'lib/constants/authConstants';
import { Auth0Error, LogoutOptions, WebAuth } from 'auth0-js';
import { GetOrgAdministrators } from 'lib/interfaces';
import { ORGANIZATION_SESSION_KEY } from 'lib/constants';
import { Auth0Organization, UserJWTSchema } from 'lib/interfaces/user';
import { getErrorMessage } from '@mainstreet/app-core';
// URL checks for Auth params
const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

const ERROR_RETRY_LIST = [
  /**
   * Recoverable errors based on the internal Auth0 list here:
   * https://github.com/auth0/auth0-spa-js/blob/master/src/constants.ts#L55
   */
  'login_required',
  'consent_required',
  'interaction_required',
  'account_selection_required',
  'access_denied',

  /**
   * Invalid state errors can be caused by page refreshes before Auth0 can complete
   * the code/token exchange. Often times, retrying login will fix the issue.
   * https://community.auth0.com/t/invalid-state-on-reload-auth0-callback-url-using-auth0-spa-js-and-angular-8/36469/10
   */
  'Invalid state',
  'invalid_state',
];
const LOGIN_RETRY_LIMIT = 3;
const LOGIN_ATTEMPT_COUNT_KEY = 'ms-auth0-login-attempt-count';

type SocialConnections = 'google-oauth2' | 'Xero';

export class AuthStore extends BaseStore {
  private authClient: Auth0Client | null = null;
  private webAuthClient = new WebAuth({
    domain: authConstants.auth0Domain || '',
    clientID: authConstants.auth0ClientId || '',
    redirectUri: authConstants.redirectUri,
    audience: authConstants.audience,
    scope: authConstants.scope,
  });

  public isCSRFEnabled = false;
  public isAuth0Enabled = authConstants.auth0Enabled();

  public error: Error | null = null;
  public isLoading = true;
  public isAuthRequired = false;
  public isAuthenticated = false;
  public shouldAttemptLogin = false;
  public user: AuthUser | null = null;
  public accessToken: string | null = null;
  public organizationId: string | null = GetFromSessionStorage(
    ORGANIZATION_SESSION_KEY,
  );
  public csrfToken: string | null = null;
  public members: GetOrgAdministrators[] | undefined = [];
  public fetchOrgMembersError: string | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeSubclassObservable(this, {
      // authClient is just a reference to the Auth0 library instance
      authClient: MobxObservableAnnotation.NOT_OBSERVED,
      // webAuthClient is just a reference to the Auth0 library instance
      webAuthClient: MobxObservableAnnotation.NOT_OBSERVED,
      // Login count should not be cached, always want the most current value
      loginAttemptCount: MobxObservableAnnotation.NOT_OBSERVED,
      // hasUrlAuthParams should not be cached, always want the most current URL values
      hasUrlAuthParams: MobxObservableAnnotation.NOT_OBSERVED,
    });
  }

  private get loginAttemptCount(): number {
    return (
      parseInt(GetFromSessionStorage(LOGIN_ATTEMPT_COUNT_KEY) || '0', 10) || 0
    );
  }

  private get hasUrlAuthParams(): boolean {
    return (
      (CODE_RE.test(window.location.search) ||
        ERROR_RE.test(window.location.search)) &&
      STATE_RE.test(window.location.search)
    );
  }

  public async setup() {
    // Quick exit if setup is already in progress
    if (this.authClient) {
      return;
    }
    // Quick exit if auth0 is not enabled
    else if (!this.isAuth0Enabled) {
      // Refresh CSRF token if required
      if (this.isCSRFEnabled) {
        await this.refreshCSRFToken();
      }

      // Refresh company on load (non-auth0 pages use cookies)
      await this.rootStore.common.companyStore.refreshCurrentCompany();

      // Mark loading complete when auth0 is disabled
      if (this.isLoading) {
        runInAction(() => (this.isLoading = false));
      }
      return;
    }

    // Log missing constants
    if (!authConstants.auth0Domain) {
      datadogLogs.logger.error('AuthStore: auth0Domain constant not found');
    } else if (!authConstants.auth0ClientId) {
      datadogLogs.logger.error('AuthStore: auth0ClientId constant not found');
    } else if (!authConstants.audience) {
      datadogLogs.logger.error('AuthStore: audience constant not found');
    } else if (!authConstants.redirectUri) {
      datadogLogs.logger.error('AuthStore: redirectUri constant not found');
    } else if (!authConstants.logoutUrl) {
      datadogLogs.logger.error('AuthStore: logoutUrl constant not found');
    }

    // TODO: Rewire with an updated history wrapper
    const url = new URLSearchParams(window.location.search);

    // Generating the auth client
    this.authClient = new Auth0Client({
      domain: authConstants.auth0Domain || '',
      client_id: authConstants.auth0ClientId || '',
      redirect_uri: authConstants.redirectUri,
      audience: authConstants.audience,
      scope: authConstants.scope,
      useRefreshTokens: true,
      cacheLocation: cacheLocation(),
      invitation: url.get('invitation') || undefined,
      organization: url.get('organization') || undefined,
    });

    let error: Error | null = null;
    let isAuthenticated = false;
    let accessToken: string | null = null;
    let user: User | undefined;

    try {
      // Authorize code on redirect
      if (this.hasUrlAuthParams) {
        const { appState } = await this.authClient.handleRedirectCallback();

        /**
         * Account creation redirect requies a special "/?returnTo=" param for redirect
         * to function through Auth0. This is due to the requirement that the redirectUri
         * parameter match the host origin.
         */
        if (/^\/\?returnTo=([^&]*)$/.exec(appState?.returnTo || '')) {
          const url = new URLSearchParams(appState.returnTo.slice(1));
          this.history.push(url.get('returnTo') || appState.returnTo);
        } else {
          this.history.push(appState?.returnTo || window.location.pathname);
        }
      }
      // Check existing session for logged in user
      else {
        await this.authClient.checkSession();
      }

      // Parse user out of the JWT token first (authentication is determined from user existance)
      user = await this.authClient.getUser();
      isAuthenticated = !!user;

      // Fetch access token last, to mimic auth0-react setup
      accessToken = await this.authClient.getTokenSilently();
    } catch (e) {
      // Reset state assignments on error
      user = undefined;
      isAuthenticated = false;
      accessToken = null;

      // Parse out error message
      if (e instanceof GenericError) {
        error = new Error(e.error);
      } else if (e instanceof Error) {
        error = e;
      } else if (typeof e === 'string') {
        error = new Error(e);
      } else {
        error = new Error(`Something went wrong, please try again.`);
      }

      // Log auth error
      if (!ERROR_RETRY_LIST.includes(error.message)) {
        datadogLogs.logger.error(
          'Auth0 login error',
          logContext({
            stack: error.stack,
            error: error,
          }),
        );
      }
    }

    // Update states based on auth results
    runInAction(() => {
      this.accessToken = accessToken;
      this.error = error;
      this.isAuthenticated = isAuthenticated;
      this.user = user ? new AuthUser(user) : null;
      this.shouldAttemptLogin = false;

      // For unauthenticated users, determine if user should attempt login
      if (!isAuthenticated) {
        // No error reported, should attempt login when required
        if (!this.error) {
          this.shouldAttemptLogin = true;
        }
        // Invalid state error, should attempt login assuming under retry limit
        else if (
          ERROR_RETRY_LIST.includes(this.error.message) &&
          this.loginAttemptCount < LOGIN_RETRY_LIMIT
        ) {
          this.shouldAttemptLogin = true;
        }
      }
    });

    // Signal to any listeners if there is a specific org id to use
    if (this.organizationId) {
      this.rootStore.event.emit('ORGANIZATION_ID', {
        id: this.organizationId,
      });
    }

    // Signal to any listeners when access token is set
    if (accessToken) {
      this.rootStore.event.emit('AUTH_ACCESS_TOKEN', {
        token: accessToken,
      });
    }

    // If user is attempting to access an auth required page, auto trigger login check
    if (this.isAuthRequired) {
      await this.verifyLogin();
    }

    // Refresh CSRF token once access token is aquired
    if (this.isCSRFEnabled && this.accessToken) {
      await this.refreshCSRFToken();
    }

    // Refresh company once authentication completes
    if (this.isAuthenticated) {
      await this.rootStore.common.companyStore.refreshCurrentCompany();
    }

    // Wait until all secondary actions are completed to mark auth setup as finished
    runInAction(() => (this.isLoading = false));

    const authClient = this.authClient;
    const logoutFun = this.logout;

    // Refresh the access token every 15 minutes. If it fails, we log the user out.
    // Relevant Docs: https://auth0.com/docs/authenticate/login/configure-silent-authentication#poll-with-checksession-
    setInterval(async () => {
      const accessToken = await this.logoutIfCheckSessionFails(
        authClient,
        this.rootStore,
        logoutFun,
      );

      if (accessToken === null) {
        logoutFun();
      } else {
        this.accessToken = accessToken;
      }
    }, 900000);
  }

  public markAuthRequired() {
    // Quick exit if requirement is already in process
    if (!this.isAuth0Enabled || this.isAuthRequired) {
      return;
    }

    // Mark auth as required
    runInAction(() => (this.isAuthRequired = true));

    // If authentication already verified, trigger login check
    if (!this.isLoading) {
      this.verifyLogin();
    }
  }

  public async verifyLogin() {
    if (!this.isAuth0Enabled || !this.authClient) {
      return;
    }

    if (this.user?.email) {
      // Mark user in datadog
      setDatadogUserContext(this.user.email);
    }

    // User needs to login again
    if (this.shouldAttemptLogin) {
      SetInSessionStorage(
        LOGIN_ATTEMPT_COUNT_KEY,
        `${this.loginAttemptCount + 1}`,
      );
      await this.forceLogin({
        appState: {
          returnTo: window.location.pathname + window.location.search,
        },
      });
    }
    // User is logged in without errors
    else if (this.isAuthenticated) {
      RemoveFromSessionStorage(LOGIN_ATTEMPT_COUNT_KEY);
    }
  }

  public async forceLogin(options?: RedirectLoginOptions) {
    await this.authClient?.loginWithRedirect(options);
  }

  public async manualLogin(
    username: string,
    password: string,
    redirectUri?: string,
  ) {
    if (!this.isAuth0Enabled) {
      return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const redirectPath = urlParams.get('redirectPath');

    return new Promise<void>((resolve, reject) => {
      this.webAuthClient.login(
        {
          username,
          password,
          responseType: `token`,
          audience: authConstants.audience,
          realm: authConstants.realm,
          scope: authConstants.scope,
          redirectUri: redirectPath
            ? `${window.location.origin}/?returnTo=${encodeURIComponent(
                redirectPath,
              )}`
            : redirectUri,
        },
        (error: null | Auth0Error) => {
          if (error != null) {
            reject(error);
          } else {
            resolve();
          }
        },
      );
    });
  }

  public async socialLogin(connection: SocialConnections, redirectUri: string) {
    const redirectPath = new URLSearchParams(window.location.search).get(
      'redirectPath',
    );
    this.webAuthClient.authorize({
      connection,
      redirectUri: redirectPath
        ? `${window.location.origin}/?returnTo=${encodeURIComponent(
            redirectPath,
          )}`
        : redirectUri,
      responseType: 'token',
    });
  }

  public async changePassword() {
    if (!this.isAuth0Enabled || !this.user) {
      return;
    }

    return new Promise<void>((resolve, reject) => {
      this.webAuthClient.changePassword(
        {
          connection: authConstants.realm,
          email: (this.user as AuthUser).email,
        },
        (error: null | Auth0Error) => {
          if (error != null) {
            reject(error);
          } else {
            resolve();
          }
        },
      );
    });
  }

  public async logout(options?: LogoutOptions) {
    if (this.authClient) {
      RemoveFromSessionStorage(LOGIN_ATTEMPT_COUNT_KEY);
      RemoveFromSessionStorage(ORGANIZATION_SESSION_KEY);
      await this.authClient.logout(options);
    } else {
      window.location.href = options?.returnTo || '/';
    }
  }

  public async fullLogout(options?: LogoutOptions) {
    const isLoggedOut = await this.client.Logout();
    if (isLoggedOut) {
      await this.logout(options);
    }
  }

  public enableCSRFToken() {
    if (this.isCSRFEnabled) {
      return;
    }

    runInAction(() => {
      this.isCSRFEnabled = true;

      if (this.accessToken || !this.isAuth0Enabled) {
        this.refreshCSRFToken();
      }
    });
  }

  public async refreshCSRFToken() {
    const res = await this.client.GetHysaCsrfToken();

    runInAction(() => {
      if (res.data?.csrfToken) {
        this.csrfToken = res.data.csrfToken;
      }
    });

    // Signal new csrf token for use
    await this.rootStore.event.emit('CSRF_TOKEN', {
      token: res.data?.csrfToken,
    });
  }

  public async fetchOrgMembers() {
    const res = await this.client.GetMembers();

    if (res.errorMsg) {
      runInAction(() => {
        this.fetchOrgMembersError = res.errorMsg as string;
      });
      return;
    }

    const admins = res.data?.map((admin) => {
      return { ...admin, role: 'Admin' };
    });
    runInAction(() => {
      this.members = admins;
      this.fetchOrgMembersError = null;
    });
  }

  public setOrganizationId(id: string) {
    runInAction(() => (this.organizationId = id));
    SetInSessionStorage(ORGANIZATION_SESSION_KEY, id);
    this.rootStore.event.emit('ORGANIZATION_ID', { id });
  }

  public maybeSetOrganizationId(id: string) {
    if (!this.organizationId) {
      this.setOrganizationId(id);
    }
  }

  public async logoutIfCheckSessionFails(
    authClient: Auth0Client,
    rootStore: RootStore,
    logoutFun: () => Promise<void>,
  ): Promise<string | null> {
    if (!authClient) {
      return null;
    }
    try {
      const accessToken = await authClient.getTokenSilently();
      if (accessToken) {
        rootStore.event.emit('AUTH_ACCESS_TOKEN', {
          token: accessToken,
        });
      }

      return accessToken;
    } catch (e) {
      datadogLogs.logger.info(
        `AuthStore: logging out becuase we failed to refresh the auth access token: ${getErrorMessage(
          e,
        )}`,
      );
      await logoutFun();
      return null;
    }
  }

  public getUserOrgs(): Auth0Organization[] {
    if (this.accessToken !== null) {
      const claims = UserJWTSchema.parse(jwt_decode(this.accessToken));
      return claims['https://mainstreet.com/user_organizations'] ?? [];
    }
    return [];
  }
}
