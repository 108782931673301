import React from 'react';

interface Props {
  className?: string;
}

const File = ({ className }: Props) => (
  <svg
    className={className}
    width='16'
    height='20'
    viewBox='0 0 16 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6L10 0ZM2 18V2H9V6H14V18H2Z'
      fill='#333333'
      fillOpacity='0.9'
    />
  </svg>
);

export default File;
