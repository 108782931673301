import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import {
  Color,
  Expandable,
  Flex,
  Icon,
  IconEnum,
  Text,
} from 'component-library';
import { KeyDateCard, KeyDateCardType } from './KeyDateCard';
import {
  CompanyData,
  InactiveProgramStages,
  ProgramData,
} from '../../../lib/interfaces';
import { dateBefore, dateOnOrAfter } from '../../../lib/helpers';
import { datadogLogs } from '@datadog/browser-logs';
import { ExpectedCreditTypeEnum } from '../../../lib/constants';
import { companyFyeToTaxDeadline } from './util';
import _ from 'lodash';

const logger = datadogLogs.createLogger('TaxCreditsPage');

const useStyles = makeStyles(() => ({
  header: {
    background: Color.neutral._light_20,
    border: `solid ${Color.neutral._20}`,
    padding: '20px 16px',
  },
  keyDates: {
    borderRadius: '0px 0px 4px 4px',
    borderWidth: '0px 1px 1px 1px',
    border: `solid ${Color.neutral._20}`,
  },
  chip: {
    backgroundColor: Color.purple._20,
    border: `.6px solid ${Color.purple._70}`,
    borderRadius: '100px',
    width: 'fit-content',
    maxWidth: '100%',
    padding: '3px 7px 3px 7px',
    height: 20,
  },
  chipText: {
    color: Color.purple._70,
  },
}));

export interface KeyDatesProps {
  company: CompanyData;
  federalRDPrograms: ProgramData[];
  defaultExpand?: boolean;
}

/**
 *  Displays and sorts key dates for the following:
 *  creditRedemptionDeadline (aka 8974 deadline)                           | e.g. Apr 10, 2023
 *  taxFilingDeadline (currently doesn't take into account any extensions) | e.g. Apr 14, 2023
 *  yeaDeadline                                                            | e.g. Q3 2023
 */
export const KeyDates = observer(
  ({ company, federalRDPrograms, defaultExpand = false }: KeyDatesProps) => {
    const classes = useStyles();
    const [expand, setExpand] = useState(defaultExpand);
    const currentCalendarYear = new Date().getFullYear();

    const latestFedRDProgram = _.maxBy(federalRDPrograms, 'taxYear');
    const currentYear =
      latestFedRDProgram && latestFedRDProgram.taxYear === currentCalendarYear
        ? currentCalendarYear + 1
        : currentCalendarYear;
    // The year is based on the year in which you filed

    const borderRadius = `4px 4px ${expand ? '0px' : '4px'} ${
      expand ? '0px' : '4px'
    }`;
    const borderWidth = `1px 1px ${expand ? '0px' : '1px'} 1px`;
    const headerStyles: React.CSSProperties = {
      borderRadius,
      borderWidth,
    };
    const lastYearFedProgram = federalRDPrograms.filter(
      (fedProgram) =>
        fedProgram.taxYear === currentCalendarYear - 1 &&
        !InactiveProgramStages.includes(fedProgram.stage),
    )[0];

    const calculatedTaxFilingDeadline = companyFyeToTaxDeadline(
      company,
      currentYear,
    );

    /**
     * The creditRedemptionDeadline will always be the quarter after the taxFilingDeadline.
     * Note: While the creditRedemptionDeadline is displayed in quarters (e.g. Q3 2023),
     * this function returns type Date | undefined to facilitate sorting the keyDateCards by keyDate
     * in {@link setKeyDateCards}
     */
    const setCreditRedemptionDeadline = () => {
      let taxFilingDeadline = company.taxFilingDeadline;
      if (taxFilingDeadline === undefined) {
        taxFilingDeadline = calculatedTaxFilingDeadline;
      }

      const deadlineMonth = new Date(taxFilingDeadline).getMonth();

      if (deadlineMonth <= 2) return new Date(`4/1/${currentYear}`);
      else if (deadlineMonth <= 5) return new Date(`7/1/${currentYear}`);
      else if (deadlineMonth <= 8) return new Date(`10/1/${currentYear}`);
      else return new Date(`1/1/${currentYear + 1}`);
    };

    /**
     * The creditRedemptionDeadline is shown when either of the following creditTypes = payroll_tax
     * 1. lastYearFedProgram.filingCreditType,
     * 2. lastYearFedProgram.expectedCreditType,
     * 3. currentYearFedProgram.expectedCreditType,
     * 4. twoYearsAgoFedProgram.filingCreditType,
     * 5. nextYearFedProgram.expectedCreditType,
     *
     * The creditTypes are checked in the order above.
     * If creditType = income_tax or deferred_income the creditRedemptionDeadline is not shown.
     *
     * See here for additional context on the priority listed above:
     * https://docs.google.com/document/d/1PmJ7NLsJuDof_hYjxqoeVNPx0k_U7XEyRxuoirXKDRc/edit#
     * */
    const showCreditRedemptionDeadline = () => {
      const currentYearFedProgram = federalRDPrograms.filter(
        (fedProgram) => fedProgram.taxYear === currentYear,
      )[0];
      const twoYearsAgoFedProgram = federalRDPrograms.filter(
        (fedProgram) => fedProgram.taxYear === currentYear - 2,
      )[0];
      const nextYearFedProgram = federalRDPrograms.filter(
        (fedProgram) => fedProgram.taxYear === currentYear + 1,
      )[0];

      const possibleCreditTypes = [
        lastYearFedProgram?.filingCreditType,
        lastYearFedProgram?.expectedCreditType,
        currentYearFedProgram?.expectedCreditType,
        twoYearsAgoFedProgram?.filingCreditType,
        nextYearFedProgram?.expectedCreditType,
      ];

      const disqualifyingTypes = [
        ExpectedCreditTypeEnum.INCOME_TAX,
        ExpectedCreditTypeEnum.DEFERRED_INCOME,
      ];

      for (const creditType of possibleCreditTypes) {
        if (disqualifyingTypes.includes(creditType)) {
          return false;
        }
        if (creditType === ExpectedCreditTypeEnum.PAYROLL_TAX) {
          return true;
        }
      }

      logger.warn(
        `Couldn't determine program filing type because no federalRDPrograms found for company ${
          company.id
        } for years ${currentYear - 1}, ${currentYear}, ${currentYear + 1}`,
      );
      return false;
    };

    /**
     * yeaDeadline is set based on the company's prevTaxYearFedOrderForm.acceptedAt date
     *
     *                             2023 Example
     --------------------------------------------------------------------------------
     | Scenario | Logic                                  | yeaDeadline              |
     |------------------------------------------------------------------------------|
     |     1    |              acceptedAt <  2/27/23     | 3/10/23                  |
     |------------------------------------------------------------------------------|
     |     2    |   9/27/23 <= acceptedAt                | yeaDeadline is not shown |
     |------------------------------------------------------------------------------|
     |     3    |   n/27/23 <= acceptedAt <  n+1/27/23   | n+2/10/23                |
     |------------------------------------------------------------------------------|
     |     4    | n-1/27/23 <= acceptedAt <    n/27/23   | n+1/10/23                |
     |------------------------------------------------------------------------------|
     *
     * Additional Notes
     * 1. n is the month of the acceptedAt date
     * 2. The logic dynamically updates the year.
     * (e.g. next year all 2023s -> 2024s, the following year all 2024s -> 2025s, etc.)
     * */
    const setYeaDeadline = () => {
      const fedOrderForm = lastYearFedProgram?.orderForm;

      if (!fedOrderForm) {
        logger.warn(
          `No ${currentYear - 1} fedOrderform found for companyId ${
            company.id
          }. Can't determine YEA deadline.`,
        );
        return;
      }
      if (!fedOrderForm.acceptedAt) {
        logger.warn(
          `CompanyId ${company.id}'s ${
            currentYear - 1
          } fedOrderform has no acceptedAt date. Can't determine YEA deadline.`,
        );
        return;
      }
      const acceptedAt = new Date(fedOrderForm.acceptedAt);

      const feb27 = new Date(`2/27/${currentYear}`);
      if (dateBefore(acceptedAt, feb27)) {
        return new Date(`3/10/${currentYear}`);
      }

      const sept27 = new Date(`9/27/${currentYear}`);
      if (dateOnOrAfter(acceptedAt, sept27)) {
        logger.warn(
          `Fed orderForm id ${fedOrderForm.id}'s acceptedAt date ${acceptedAt} is past 9/26/${currentYear}`,
        );
        return;
      }

      const acceptedAtMonth = acceptedAt.getMonth() + 1; // because getMonth() counts Jan as 0

      const the27thOfAcceptedAtMonth = new Date(
        `${acceptedAtMonth}/27/${currentYear}`,
      );
      const the27thOfTheNextMonth = new Date(
        `${acceptedAtMonth + 1}/27/${currentYear}`,
      );

      if (
        dateOnOrAfter(acceptedAt, the27thOfAcceptedAtMonth) &&
        dateBefore(acceptedAt, the27thOfTheNextMonth)
      ) {
        return new Date(`${acceptedAtMonth + 2}/10/${currentYear}`);
      }

      const the27thOfThePrevMonth = new Date(
        `${acceptedAtMonth - 1}/27/${currentYear}`,
      );

      if (
        dateOnOrAfter(acceptedAt, the27thOfThePrevMonth) &&
        dateBefore(acceptedAt, the27thOfAcceptedAtMonth)
      ) {
        return new Date(`${acceptedAtMonth + 1}/10/${currentYear}`);
      }
    };

    /**
     * setKeyDateCards and sorts cards based on keyDates
     * */
    const setKeyDateCards = (): KeyDateCardType[] => {
      const unsortedKeyDateCards: KeyDateCardType[] = [];

      const creditRedemptionKeyDate = setCreditRedemptionDeadline();
      const showCreditRedemptionCard = showCreditRedemptionDeadline();
      if (showCreditRedemptionCard && creditRedemptionKeyDate) {
        const creditRedemptionDate: KeyDateCardType = {
          dataTestId: 'creditRedemptionDate',
          type: 'creditRedemptionDate',
          keyDate: creditRedemptionKeyDate,
          text: 'Redeem credits with your payroll provider',
          linkText: 'How to file Form 8974',
          url: new URL(
            'https://mainstreet1.my.site.com/help/s/article/Final-Steps-To-Claim-the-R-D-Tax-Credit-Toward-Payroll',
          ),
        };

        unsortedKeyDateCards.push(creditRedemptionDate);
      }

      let taxFilingDeadline = company.taxFilingDeadline;
      if (taxFilingDeadline === undefined) {
        taxFilingDeadline = calculatedTaxFilingDeadline;
      }
      const taxFilingDeadlineDateObj = new Date(taxFilingDeadline);

      const taxFilingDeadlineDate: KeyDateCardType = {
        dataTestId: 'taxFilingDeadlineDate',
        type: 'taxFilingDeadlineDate',
        keyDate: new Date(
          currentYear,
          taxFilingDeadlineDateObj.getMonth(),
          taxFilingDeadlineDateObj.getDate(),
        ),
        text: 'Original deadline to file taxes + credits with the IRS',
        linkText: 'How to file Form 6765',
      };

      unsortedKeyDateCards.push(taxFilingDeadlineDate);

      const yeaKeyDate = new Date(taxFilingDeadlineDateObj);
      yeaKeyDate.setDate(yeaKeyDate.getDate() - 1);

      if (yeaKeyDate) {
        const yeaDeadlineDate: KeyDateCardType = {
          dataTestId: 'yeaDeadlineDate',
          type: 'yeaDeadlineDate',
          keyDate: new Date(
            currentYear,
            yeaKeyDate.getMonth(),
            yeaKeyDate.getDate(),
          ),
          text: 'Deadline to complete the Year-End Assessment',
        };

        unsortedKeyDateCards.push(yeaDeadlineDate);
      }

      return unsortedKeyDateCards.sort((a, b) => {
        const aDate = new Date(a.keyDate).getTime();
        const bDate = new Date(b.keyDate).getTime();

        if (aDate < bDate) return -1;
        return 1;
      });
    };

    return (
      <Flex direction={'column'}>
        <div
          data-testid={'keyDatesModule'}
          className={classes.header}
          style={headerStyles}
          onClick={() => setExpand(!expand)}
        >
          <Flex justifyContent={'space-between'}>
            <Flex alignItems={'center'} gap={8}>
              <Text text={'Key dates'} variant={'bold'} size={18} />
              <Flex className={classes.chip} alignItems={'center'}>
                <Text
                  className={classes.chipText}
                  text={'Important'}
                  size={13}
                />
              </Flex>
            </Flex>
            <Icon name={expand ? IconEnum.chevron_up : IconEnum.chevron_down} />
          </Flex>
          <Text text={'Important dates for your credit filing.'} />{' '}
        </div>
        <Expandable expand={expand} className={classes.keyDates}>
          {setKeyDateCards().map((card, i) => {
            const { dataTestId, keyDate, linkText, url, text, type } = card;

            return (
              <KeyDateCard
                key={i}
                dataTestId={dataTestId}
                keyDate={keyDate}
                linkText={linkText}
                url={url}
                text={text}
                type={type}
              />
            );
          })}
        </Expandable>
      </Flex>
    );
  },
);
