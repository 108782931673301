import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import { ERCQualifyingQuestions, SurveyNameEnum } from 'lib/constants';
import {
  Animate,
  AnswerType,
  AnswerValueType,
  Flex,
  Spinner,
  SurveyQuestion,
} from 'component-library';
import _ from 'lodash';
import { ERCTaxReturnUploadQuestion } from '../ERCTaxReturnUploadQuestion';
import { CmsQuestionData } from 'lib/interfaces';
import {
  useCommonStores,
  useCompany,
  useTaxCreditsStores,
} from 'stores/useStores';
import { ERCQualificationModal } from '../ERCQualificationModal';
import { SurveyFlowContainer } from 'products/tax-credits/components';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    maxWidth: '66%',
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}));

interface UploadTaxReturnProps {
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
  isPrefilled: boolean;
}

export const UploadTaxReturn = observer(
  ({ pathTo, pathFrom, onNext, onBack, isPrefilled }: UploadTaxReturnProps) => {
    const classes = useStyles();
    const { app } = useCommonStores();
    const { company } = useCompany();
    const { erc } = useTaxCreditsStores();
    const history = useHistory();

    const checkEligibility = () => {
      if (company && company.yearFounded) {
        erc.checkQualification(company.yearFounded);
        erc.setShowLoadingEligibility(true);
      }
    };

    const handleOnContinue = async () => {
      await erc.qualifyAllPrograms().then(() => {
        onNext();
        history.push(pathTo);
      });
    };

    const handleOnBack = () => {
      onBack();
      history.push(pathFrom);
    };

    const {
      isSurveyComplete,
      isLoading,
      questionsToRender,
      surveyAnswers,
      addSurveyAnswers,
      saveAnswers,
    } = useSurveyQuestions(SurveyNameEnum.ERC_UPLOAD_TAX_RETURN, 2021);

    const handleAnswerChange = (
      question: CmsQuestionData,
      answerValue: AnswerValueType,
      key: string,
    ) => {
      question.answerValue = answerValue;
      addSurveyAnswers(question.id, key, [
        { questionId: question.id, answerValue: answerValue },
      ]);
      // save answers per question answered
      if (
        question.answerType === 'multiple_select' ||
        question.answerType === 'multiple_choice'
      ) {
        saveAnswers && saveAnswers();
      }
    };

    const renderQuestionContent = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => {
          switch (question.id) {
            case ERCQualifyingQuestions.UPLOAD_2021_TAX_RETURN:
              return (
                <Animate
                  key={question.id}
                  enter={['fade-in', 'from-bottom']}
                  duration={0.5}
                >
                  <ERCTaxReturnUploadQuestion
                    key={`${ERCQualifyingQuestions.UPLOAD_2021_TAX_RETURN}-${question.id}`}
                    addSurveyAnswers={addSurveyAnswers}
                    subGroupName={key}
                    activeQuestion={question}
                    saveAnswers={saveAnswers}
                    documentDescription={`ERC - Upload 2021 income tax return`}
                    type='income'
                  />
                </Animate>
              );

            default:
              return (
                <Animate
                  key={question.id}
                  enter={['fade-in', 'from-bottom']}
                  duration={0.5}
                >
                  <SurveyQuestion
                    dataTestId={question.id}
                    answerType={question.answerType as AnswerType}
                    text={question.text}
                    subtitle={question.subtitle}
                    answerOptions={question.answerIDs}
                    onChange={(answer) =>
                      handleAnswerChange(question, answer, key)
                    }
                    onBlur={() => saveAnswers && saveAnswers()}
                    placeholder={question.placeholder}
                    answerValue={question.answerValue}
                    tooltip={question.tooltip}
                    questionTextSize={app.isMobile ? 15 : 18}
                    questionSubtitleSize={app.isMobile ? 13 : 15}
                    withCardMargin={false}
                  />
                </Animate>
              );
          }
        });
      });

    useEffect(() => {
      const uploadTaxReturnSubQuestion =
        surveyAnswers[ERCQualifyingQuestions.SUBQUESTION_2021_TAX_RETURN];

      // check if tax return has been uploaded
      if (
        uploadTaxReturnSubQuestion &&
        uploadTaxReturnSubQuestion.length === 0
      ) {
        erc.setHasUploadedTaxReturn(false);
      } else {
        erc.setHasUploadedTaxReturn(true);
      }
    }, [erc, surveyAnswers, saveAnswers]);

    return (
      <SurveyFlowContainer
        title='Upload tax return'
        onContinue={checkEligibility}
        onBack={handleOnBack}
        isDisabled={!isSurveyComplete || !erc.hasUploadedTaxReturn}
        dataTestId='erc-qualify-survey'
      >
        <>
          <Flex className={classes.container} direction='column' gap={24}>
            {isLoading ? (
              <Spinner size='small' />
            ) : (
              !_.isEmpty(questionsToRender) && renderQuestionContent()
            )}
          </Flex>
          {erc.qualificationStatus && (
            <ERCQualificationModal
              onClick={async () => {
                erc.setModalContinueIsLoading(true);
                await handleOnContinue();

                erc.setModalContinueIsLoading(false);
                erc.setShowQualificationStatusModal(false);
              }}
            />
          )}
        </>
      </SurveyFlowContainer>
    );
  },
);
