import React from 'react';
import { Text } from 'component-library';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((colors: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  sectionHeader: {
    borderBottom: '1px solid',
    borderBottomColor: colors.lighterGrey,
  },
}));

export const QualifyingStepFAQ = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Text
        variant={'medium'}
        className={classes.sectionHeader}
        paddingBottom={8}
      >
        What&apos;s this step?
      </Text>
      <Text paddingBottom={8}>
        To qualify for tax credits with MainStreet, you&apos;ll need to answer
        questions about your business&apos;s R&D activities and related
        expenses. Your answers will help us determine if you qualify for tax
        credits. If you qualify, we&apos;ll generate an initial estimate of your
        R&D tax credit.
      </Text>

      <Text
        variant={'medium'}
        className={classes.sectionHeader}
        paddingBottom={8}
      >
        Why do we need this?
      </Text>
      <Text
        text={`Your final tax credit can only be generated once the tax year closes and you’ve provided full details about your business’s R&D activities. We therefore create an estimated credit amount, based on the information you provide when you qualify.`}
      />
    </div>
  );
};
