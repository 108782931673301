import React from 'react';
import { observer } from 'mobx-react';
import { Flex, Heading, Text, TextProps } from 'component-library';
import { ContentFrame } from '../../components';

interface MainStreetReviewProps {
  headingSize: number;
  textFontSize: TextProps['size'];
}

export const MainStreetReview: React.FC<MainStreetReviewProps> = observer(
  ({ headingSize, textFontSize }) => {
    return (
      <ContentFrame>
        <Flex direction='column' gap={16}>
          <Heading
            text='MainStreet Review'
            variant='medium'
            size={headingSize}
            marginBottom={0}
          />
          <Text
            size={textFontSize}
            text='Our team will review all the information you provided to carefully calculate your credits.'
          />
          <Text
            size={textFontSize}
            text="It's not uncommon for a MainStreet specialist to request additional details during this time. We'll alert you if we need more information. Please respond quickly to these requests so we can complete our review."
          />
        </Flex>
        <Flex direction='column' gap={16}>
          <img
            src={`${process.env.PUBLIC_URL}/images/dashboard/educational-module/images/Review.svg`}
            title='MainStreet Review'
          />
        </Flex>
      </ContentFrame>
    );
  },
);
