import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import LoadingWidget from 'components/util/LoadingWidget';
import {
  Button,
  Card,
  Color,
  Flex,
  Icon,
  IconEnum,
  Text,
} from 'component-library';
import {
  ConnectFinch,
  ConnectPayrollDisplayProps,
} from 'components/ConnectFinch';
import { ImportType } from 'lib/interfaces';
import { ConnectSystemError } from 'components/onboarding/ConnectSystemError';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { TokenContext } from 'pages/TokenRequired';

interface ConnectToPayrollProps {
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
  isPrefilled: boolean;
}

export const ConnectToPayroll = observer(
  ({ pathTo, pathFrom, onNext, onBack }: ConnectToPayrollProps) => {
    const history = useHistory();
    const { client } = useContext(Auth0FeatureContext);
    const { token, email } = useContext(TokenContext);
    const [isConnected, setIsConnected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOnContinue = () => {
      onNext();
      history.push(pathTo);
    };

    const handleOnBack = () => {
      onBack();
      history.push(pathFrom);
    };

    const loadPayrollProviders = (token?: string, email?: string) => {
      setIsLoading(true);
      client.FinchLinkedServices(token, email).then((providers) => {
        if (providers.errorMsg) {
          datadogLogs.logger.error(
            'IntegrationsPage.loadPayrollProviders',
            logContext({
              error: providers.errorMsg,
            }),
          );
        } else if (providers?.data !== undefined) {
          const finchConnectionExists = providers.data.services?.length > 0;
          setIsConnected(finchConnectionExists);
        }

        setIsLoading(false);
      });
    };

    useEffect(() => {
      loadPayrollProviders(token, email);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, token]);

    const ConnectFinchDisplay = ({
      openFinch,
      loading,
      finchError,
    }: ConnectPayrollDisplayProps) => {
      return (
        <Flex padding={[8, 0]} direction='column'>
          <Flex>
            <Button
              label={
                isConnected ? (
                  <Flex>
                    <Icon name={IconEnum.check} color={Color.green._70} />
                    <Text text='Connected' color={Color.green._70} />
                  </Flex>
                ) : (
                  'Connect'
                )
              }
              onClick={openFinch}
              loading={loading}
              variant='outlined'
              type={isConnected ? 'success' : undefined}
              dataTestId={'erc-connect-payroll-button'}
            />
          </Flex>
          {finchError && <ConnectSystemError systemType='payroll' />}
        </Flex>
      );
    };

    return (
      <SurveyFlowContainer
        title='Connect your payroll account'
        onContinue={handleOnContinue}
        onBack={handleOnBack}
        isDisabled={!isConnected}
        dataTestId='erc-qualify-survey'
      >
        {isLoading ? (
          <LoadingWidget />
        ) : (
          <Card maxWidth={640} noMargin>
            <Flex direction='column' padding={24} gap={16}>
              <Flex.Cell>
                <Text
                  variant='medium'
                  size={18}
                  text='Connect to the payroll account you used in 2021'
                  paddingBottom={8}
                />
                <Text text='This connection is required to accurately calculate your tax credits.' />
              </Flex.Cell>
              <ConnectFinch
                onPayrollConnected={() => Promise.resolve(setIsConnected(true))}
                connectPayrollDisplay={ConnectFinchDisplay}
                importType={ImportType.Qualification}
                taxYear={2022}
                payrollSystem='quickbooks'
              />
            </Flex>
          </Card>
        )}
      </SurveyFlowContainer>
    );
  },
);
