import React from 'react';
import { observer } from 'mobx-react';
import {
  Alert,
  Button,
  Color,
  Flex,
  Icon,
  IconEnum,
  Modal,
  Text,
} from 'component-library';
import { useCommonStores } from 'stores/useStores';
import { Page } from 'lib/constants';

interface ExistingCustomerFreeCreditsModalProps {
  showExistingCustomerModal: boolean;
  handleCustomerAcknowledgeFreeCredits: () => Promise<void>;
  isTaxCreditPage?: boolean;
}

export const ExistingCustomerFreeCreditsModal: React.FC<ExistingCustomerFreeCreditsModalProps> =
  observer(
    ({
      showExistingCustomerModal,
      handleCustomerAcknowledgeFreeCredits,
      isTaxCreditPage,
    }) => {
      const { app } = useCommonStores();

      return (
        <Modal
          maxWidth={544}
          showModal={showExistingCustomerModal}
          closeToggle={async () => await handleCustomerAcknowledgeFreeCredits()}
        >
          <Flex direction='column' padding={[0, 0, 24, 0]}>
            <img
              src={`${process.env.PUBLIC_URL}/images/tax-credit-dashboard-module/modal-header-credit-logo.png`}
              alt='MainStreet credits modal header'
            />
            <Flex padding={[24, 32, 0, 32]} gap={12} direction='column'>
              <Text size={23} variant='medium'>
                You&apos;ve unlocked additional credits - FOR&nbsp;FREE! 🎉
              </Text>
              <Text>
                We are pleased to announce the expansion of available credits on
                MainStreet. As a valued customer, we are extending complimentary
                access to these additional credits for the tax year 2023.
              </Text>
              <Flex gap={12}>
                <Icon
                  name={IconEnum.check_circle_filled}
                  color={Color.lime._60}
                />
                <Text>Retirement Plan Tax Credit</Text>
              </Flex>
              <Flex gap={12}>
                <Icon
                  name={IconEnum.check_circle_filled}
                  color={Color.lime._60}
                />
                <Text>Small Business Healthcare Tax Credit</Text>
              </Flex>
              <Flex gap={12}>
                <Icon
                  name={IconEnum.check_circle_filled}
                  color={Color.lime._60}
                />
                <Text>Disabled Access Tax Credit</Text>
              </Flex>
            </Flex>
            <Flex padding={[0, 24, 0, 16]} gap={12} direction='column'>
              <Alert
                text={
                  <Text>
                    These credits have been automatically added to your account.
                    (Eligibility toward new credits not&nbsp;guaranteed).
                  </Text>
                }
                type='info'
                variant='in_card'
              />
              <Button
                onClick={async () => {
                  if (!isTaxCreditPage) {
                    app.history.push(`/${Page.taxCredits}`);
                  }
                  await handleCustomerAcknowledgeFreeCredits();
                }}
                label='Show me'
                flexAlignSelf='flex-end'
              />
            </Flex>
          </Flex>
        </Modal>
      );
    },
  );
