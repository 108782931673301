import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Switch, Route, useLocation } from 'react-router-dom';
import { animated } from 'react-spring';
import { Animate, Color, Flex } from 'component-library';
import TitleHeader from 'components/TitleHeader';
import { observer } from 'mobx-react';
import {
  useAccountCreationStores,
  useLegacyClients,
  useTaxCreditsStores,
} from 'stores/useStores';
import { useSpringPageLocationTransition } from 'lib/useSpringPageTransition';
import { AccountStepContainer, useAccountCreationRoutes } from './component';
import {
  UnifiedAccountCreationStepsIndex,
  Page,
  ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
  PARTNER_REFERRAL_SSU_KEY,
} from 'lib/constants';
import { ProgramsStep } from './steps';
import { AnimatedGradient } from 'products/onboarding/components';
import { OnboardingLandingPage } from './OnboardingLandingPage';
import { useEffectOnce } from 'lib/helpers';
import Cookies from 'js-cookie';
import { getPartnerReferralFromCookie } from 'lib/partnerHelpers';
import { CompanyContext } from 'pages/CompanyRequired';

const useStyles = makeStyles(({ breakpoints }) => ({
  '@global': {
    [breakpoints.down('xs')]: {
      '.embeddedServiceHelpButton': {
        display: 'none',
      },
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Color.neutral.white,
    boxSizing: 'border-box',
    [breakpoints.down('sm')]: {
      height: '100%',
    },
    position: 'relative',
  },
  mainContent: {
    maxWidth: '1200px',
    width: '100%',
    minHeight: 'calc(100vh - 77px)',
    margin: '0 auto',
    boxSizing: 'border-box',
    position: 'relative',
    [breakpoints.down('sm')]: {
      height: '100%',
      padding: 0,
    },
  },
  programsStepPage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0',
    padding: '0',
    zIndex: 1,
  },
  animateContainer: {
    minHeight: '100%',
    width: '100%',
    padding: '40px 0',
    [breakpoints.down('sm')]: {
      padding: '0 0 40px',
    },
  },
}));

export const UnifiedAccountCreation = observer(() => {
  const classes = useStyles();
  const location = useLocation();
  const { accountCreation } = useAccountCreationStores();
  const { accountCreationRoutes } = useAccountCreationRoutes();
  const { client } = useLegacyClients();
  const { company } = useContext(CompanyContext);
  const { transitions } = useSpringPageLocationTransition(
    location,
    accountCreation.currentStep,
    accountCreation.previousStep,
    true, // useFade
  );
  const { unifiedTaxCredits } = useTaxCreditsStores();

  // set partner referral, currently used for Xero single sign up flow.
  useEffectOnce(async () => {
    const partnerReferral = localStorage.getItem(PARTNER_REFERRAL_SSU_KEY);
    if (partnerReferral) {
      await client.UpdateCompanyMisc({ partnerReferral });
      localStorage.removeItem(PARTNER_REFERRAL_SSU_KEY);
    }
  });

  // create all programs
  useEffectOnce(async () => {
    await unifiedTaxCredits.createAllPrograms(
      ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
    );

    // store partnerReferral if partner cookie is set
    const getCookieValue = (cookieName: string): string | undefined => {
      return Cookies.get(cookieName);
    };

    const cookieName = 'mainstreet_partner';
    const cookieValue = getCookieValue(cookieName);

    const hasPartnerReferral = company.misc.partnerReferral
      ? company.misc.partnerReferral.length > 0
      : false;

    if (cookieValue && cookieValue.length > 0 && !hasPartnerReferral) {
      const partnerReferral = getPartnerReferralFromCookie(cookieValue);

      if (partnerReferral) {
        await client.UpdateCompanyMisc({
          partnerReferral: partnerReferral,
        });
      }
    }
  });

  useEffect(() => {
    accountCreation.setUnifiedCurrentStepOnLoad(location.pathname);
  }, [accountCreation, location.pathname]);

  const getContent = () => {
    switch (accountCreation.currentStep) {
      case UnifiedAccountCreationStepsIndex.LANDING_PAGE:
        return (
          <OnboardingLandingPage
            onSubmit={() => accountCreation.getStarted()}
          />
        );
      case UnifiedAccountCreationStepsIndex.PROGRAMS:
        return (
          <ProgramsStep
            pathTo={`/${Page.transitionToDashboard}`}
            onNext={() =>
              accountCreation.setCurrentStep(
                UnifiedAccountCreationStepsIndex.PROGRAMS,
              )
            }
            pathFrom={`/${Page.accountCreationTaxes}`}
          />
        );
      default:
        return (
          <Animate
            enter={'fade-in'}
            delay={0.75}
            duration={0.75}
            className={classes.animateContainer}
          >
            <AccountStepContainer>
              {transitions((props, item) => {
                return (
                  <animated.div style={props}>
                    <Switch location={item}>
                      {accountCreationRoutes.map((item, key) => {
                        return (
                          <Route
                            exact={item.exact}
                            key={key}
                            path={item.path}
                            render={() => item.component}
                          />
                        );
                      })}
                    </Switch>
                  </animated.div>
                );
              })}
            </AccountStepContainer>
          </Animate>
        );
    }
  };

  return (
    <div className={classes.container}>
      <Animate enter={'fade-in'}>
        <AnimatedGradient version='v2' />
      </Animate>

      <Animate enter={['fade-in', 'from-top']}>
        <TitleHeader
          title='Account creation'
          borderBottom
          fullWidth
          showTalkToExpertButton
        />
      </Animate>
      <Flex
        className={
          accountCreation.currentStep ===
          UnifiedAccountCreationStepsIndex.PROGRAMS
            ? classes.programsStepPage
            : classes.mainContent
        }
        alignItems='center'
      >
        {getContent()}
      </Flex>
    </div>
  );
});
