// sourced from:  https://pe.usps.com/text/pub28/28apb.htm
export enum StateEnum {
  ALABAMA = 'AL',
  ALASKA = 'AK',
  AMERICAN_SAMOA = 'AS',
  ARIZONA = 'AZ',
  ARKANSAS = 'AR',
  CALIFORNIA = 'CA',
  COLORADO = 'CO',
  CONNECTICUT = 'CT',
  DELAWARE = 'DE',
  DISTRICT_OF_COLUMBIA = 'DC',
  FEDERATED_STATES_OF_MICRONESIA = 'FM',
  FLORIDA = 'FL',
  GEORGIA = 'GA',
  GUAM = 'GU',
  HAWAII = 'HI',
  IDAHO = 'ID',
  ILLINOIS = 'IL',
  INDIANA = 'IN',
  IOWA = 'IA',
  KANSAS = 'KS',
  KENTUCKY = 'KY',
  LOUISIANA = 'LA',
  MAINE = 'ME',
  MARSHALL_ISLANDS = 'MH',
  MARYLAND = 'MD',
  MASSACHUSETTS = 'MA',
  MICHIGAN = 'MI',
  MINNESOTA = 'MN',
  MISSISSIPPI = 'MS',
  MISSOURI = 'MO',
  MONTANA = 'MT',
  NEBRASKA = 'NE',
  NEVADA = 'NV',
  NEW_HAMPSHIRE = 'NH',
  NEW_JERSEY = 'NJ',
  NEW_MEXICO = 'NM',
  NEW_YORK = 'NY',
  NORTH_CAROLINA = 'NC',
  NORTH_DAKOTA = 'ND',
  NORTHERN_MARIANA_ISLANDS = 'MP',
  OHIO = 'OH',
  OKLAHOMA = 'OK',
  OREGON = 'OR',
  PALAU = 'PW',
  PENNSYLVANIA = 'PA',
  PUERTO_RICO = 'PR',
  RHODE_ISLAND = 'RI',
  SOUTH_CAROLINA = 'SC',
  SOUTH_DAKOTA = 'SD',
  TENNESSEE = 'TN',
  TEXAS = 'TX',
  UTAH = 'UT',
  VERMONT = 'VT',
  VIRGIN_ISLANDS = 'VI',
  VIRGINIA = 'VA',
  WASHINGTON = 'WA',
  WEST_VIRGINIA = 'WV',
  WISCONSIN = 'WI',
  WYOMING = 'WY',
}

export enum StateNoneEnum {
  NONE = 'NONE',
}

// Disgusting hack to make TS extend enums https://stackoverflow.com/a/71255520
export type StateWithNoneEnum = StateEnum | StateNoneEnum;
export const StateWithNoneEnum = { ...StateEnum, ...StateNoneEnum };
