import React from 'react';
import { useTaxCreditsStores } from 'stores/useStores';
import { Page, RetirementPlanStepsIndex } from 'lib/constants';
import { BusinessDetails, NHCEDetails, PayrollConnection } from './steps';
import { CreditEstimate } from './steps/CreditEstimate';
import { BillingSummary } from './steps/BillingSummary';

interface RetirementPlanRoutesInterface {
  exact?: boolean;
  path?: string;
  component: React.ReactElement | JSX.Element;
}

const useRetirementPlanRoutes = (
  taxYear: number,
): {
  RetirementPlanRoutes: RetirementPlanRoutesInterface[];
} => {
  const { retirement } = useTaxCreditsStores();
  const retirementRootPath = `/${Page.taxCredits}/${taxYear}/${Page.retirementPlan}`;

  return {
    RetirementPlanRoutes: [
      {
        exact: true,
        path: retirementRootPath,
        component: (
          <BusinessDetails
            pathTo={`${retirementRootPath}/${Page.retirementPlanPayrollConnect}`}
            onNext={() =>
              retirement.setFlowIndex(
                RetirementPlanStepsIndex.PAYROLL_CONNECTION,
              )
            }
            taxYear={taxYear}
          />
        ),
      },
      {
        path: `${retirementRootPath}/${Page.retirementPlanPayrollConnect}`,
        component: (
          <PayrollConnection
            pathTo={`${retirementRootPath}/${Page.retirementPlanNHCEDetails}`}
            pathFrom={`${retirementRootPath}`}
            onNext={() =>
              retirement.setFlowIndex(RetirementPlanStepsIndex.NHCE_DETAILS)
            }
            onBack={() =>
              retirement.setFlowIndex(RetirementPlanStepsIndex.BUSINESS_DETAILS)
            }
            taxYear={taxYear}
          />
        ),
      },
      {
        path: `${retirementRootPath}/${Page.retirementPlanNHCEDetails}`,
        component: (
          <NHCEDetails
            pathTo={`${retirementRootPath}/${Page.retirementPlanCreditEstimate}`}
            pathFrom={`${retirementRootPath}/${Page.retirementPlanPayrollConnect}`}
            onNext={() =>
              retirement.setFlowIndex(RetirementPlanStepsIndex.CREDIT_ESTIMATE)
            }
            onBack={() =>
              retirement.setFlowIndex(
                RetirementPlanStepsIndex.PAYROLL_CONNECTION,
              )
            }
            taxYear={taxYear}
          />
        ),
      },
      {
        path: `${retirementRootPath}/${Page.retirementPlanCreditEstimate}`,
        component: (
          <CreditEstimate
            pathTo={`${retirementRootPath}/${Page.retirementPlanBillingSummary}`}
            onNext={() =>
              retirement.setFlowIndex(RetirementPlanStepsIndex.BILLING_SUMMARY)
            }
            taxYear={taxYear}
          />
        ),
      },
      {
        path: `${retirementRootPath}/${Page.retirementPlanBillingSummary}`,
        component: <BillingSummary pathTo='/' taxYear={taxYear} />,
      },
    ],
  };
};

export default useRetirementPlanRoutes;
