import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Color, Grid, Text } from 'component-library';
import {
  CentsToDisplayString,
  CentsToDisplayStringNoZeros,
  GetStartDate,
} from 'lib/helpers';
import { OrderForm } from 'lib/interfaces';

interface AdvanceDates {
  date: string;
  details: string;
}

interface CashAdvanceContentProps {
  order: OrderForm;
}

export const CashAdvanceContent: React.FC<CashAdvanceContentProps> = observer(
  ({ order }) => {
    // Advance start date.
    const advanceStartDate = GetStartDate(-1, new Date(order.termStartDate))
      .isoStringDate;
    const advanceDates: AdvanceDates[] = [
      {
        date: `You get cash in ${moment(advanceStartDate).format('MMMM YYYY')}`,
        details: `We'll advance you ${CentsToDisplayString(
          order.monthlyAdvanceCents,
          0,
          0,
        )} on ${moment(advanceStartDate).format('MMMM Do, YYYY')} at 0% APR.`,
      },
      {
        date: `Payback starts ${moment(order.termStartDate).format(
          'MMMM YYYY',
        )}`,
        details: `You'll pay back the cash advance over ${
          order.numPaybackMonths > 1
            ? `${order.numPaybackMonths} months (${CentsToDisplayStringNoZeros(
                order.monthlyPaybackCents,
              )} each month).`
            : `the following months (${CentsToDisplayStringNoZeros(
                order.monthlyPaybackCents,
              )}).`
        }`,
      },
    ];
    return (
      <Grid.Cell
        columns={12}
        padding={24}
        alignSelf='center'
        style={{ borderTop: `1px solid ${Color.neutral._20}` }}
      >
        <Grid columns={12} alignItems='center' gap={24}>
          <Grid.Cell columns={12}>
            <Text>
              We&apos;ll advance you a portion of your credit at 0% APR, because
              cash today is better than cash later.
            </Text>
          </Grid.Cell>
          <Grid.Cell columns={12}>
            {advanceDates.map((date, index) => {
              return (
                <Grid key={index} columns={12} gap={8} padding={[8, 0]}>
                  <Grid.Cell columns={11}>
                    <Text variant='medium' text={date.date} />
                    <Text text={date.details} />
                  </Grid.Cell>
                </Grid>
              );
            })}
          </Grid.Cell>
        </Grid>
      </Grid.Cell>
    );
  },
);
