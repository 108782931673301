import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Color, Flex, Modal, Text } from 'component-library';
import { useFeatureFlags, useCommonStores } from 'stores/useStores';
import { observer } from 'mobx-react';
import TalkToAnExpertButton from './util/TalkToAnExpertButton';

interface StyleProps {
  borderBottom: boolean | undefined;
  showExitButton: boolean | undefined;
}

const useStyles = ({ borderBottom, showExitButton }: StyleProps) => {
  return makeStyles(() => ({
    headerContent: {
      width: '100%',
      position: 'relative',
      backgroundColor: Color.neutral.white,
      borderBottom: borderBottom ? `1px solid ${Color.neutral._20}` : undefined,
    },
    logoContent: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '1200px',
      width: '100%',
      margin: '0 auto',
      padding: '24px',
      gap: '16px',
      boxSizing: 'border-box',
    },

    logoContentFullWidth: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      margin: '0 auto',
      padding: '24px',
      gap: '16px',
      boxSizing: 'border-box',
    },
    exitBtn: {
      padding: 0,
      height: 'auto',
    },
    mslogo: {
      maxWidth: '106px',
    },
    cofounderLogo: {
      maxWidth: '230px',
    },
    title: {
      paddingLeft: '16px',
      borderLeft: `1px solid ${Color.neutral._20}`,
    },
    companyName: {
      marginRight: showExitButton ? '16px' : undefined,
      paddingRight: showExitButton ? '16px' : undefined,
      borderRight: showExitButton
        ? `1px solid ${Color.neutral._20}`
        : undefined,
      whiteSpace: 'nowrap',
    },
  }));
};

interface TitleHeaderProps {
  title?: string;
  showExitButton?: boolean;
  onExitLabel?: string;
  onExitText?: string;
  onCancelExitText?: string;
  borderBottom?: boolean;
  overrideExitModal?: boolean;
  showCompanyName?: boolean;
  onExitLocation?: string;
  onExitCustomUpdate?: () => void;
  fullWidth?: boolean;
  showTalkToExpertButton?: boolean;
  logo?: 'mainstreet' | 'mainstreetAndCofounder';
}

const TitleHeader = observer(
  ({
    title,
    showExitButton,
    onExitLabel = 'Exit',
    onExitText,
    onCancelExitText = 'Keep editing',
    borderBottom = false,
    overrideExitModal = false,
    showCompanyName,
    onExitLocation = '/',
    onExitCustomUpdate,
    fullWidth,
    showTalkToExpertButton,
    logo = 'mainstreet',
  }: TitleHeaderProps) => {
    const classes = useStyles({ borderBottom, showExitButton })();
    const [showExitModal, setShowExitModal] = useState<boolean>(false);
    const featureFlags = useFeatureFlags();
    const showTalkToAnExpertButton =
      featureFlags.showTalkToAnExpertButton && showTalkToExpertButton;
    const { app } = useCommonStores();

    let logoUrl, logoStyles;

    if (logo === 'mainstreetAndCofounder') {
      logoUrl = '/images/mainstreet-cofounder-single-line.svg';
      logoStyles = classes.cofounderLogo;
    } else if (logo === 'mainstreet') {
      logoUrl = '/images/logo/mst-logo-green.svg';
      logoStyles = classes.mslogo;
    } else {
      logoUrl = '/images/logo/mst-logo-green.svg';
      logoStyles = classes.mslogo;
    }

    const handleExit = () => {
      if (overrideExitModal) {
        app.history.push(onExitLocation);
      } else {
        setShowExitModal(true);
      }
    };

    return (
      <>
        <Flex
          justifyContent='space-between'
          alignItems='center'
          className={classes.headerContent}
        >
          <div
            className={
              fullWidth ? classes.logoContentFullWidth : classes.logoContent
            }
          >
            <img
              className={logoStyles}
              src={logoUrl}
              alt='MainStreet watermark'
            />
            {!app.isMobile && title && (
              <Text
                className={classes.title}
                variant='medium'
                size={app.isSmallDesktop ? 15 : 18}
                text={title}
                color={Color.neutral._80}
              />
            )}
          </div>

          <Flex.Cell>
            <Flex alignItems='center' padding={[0, 24]}>
              <Flex gap={24} alignItems='center'>
                {showTalkToAnExpertButton && <TalkToAnExpertButton />}
                {showCompanyName && (
                  <Flex.Cell>
                    <Text
                      text={app.company.name}
                      className={classes.companyName}
                      variant='medium'
                    />
                  </Flex.Cell>
                )}
              </Flex>
              {showExitButton && (
                <Button
                  className={classes.exitBtn}
                  variant='tertiary'
                  label={onExitLabel}
                  onClick={handleExit}
                  dataTestId='title-header-exit-button'
                />
              )}
            </Flex>
          </Flex.Cell>
        </Flex>
        <Modal
          showModal={showExitModal}
          closeToggle={() => setShowExitModal(false)}
          maxWidth={536}
        >
          <Flex direction='column' gap={16} padding={24}>
            <Text
              variant='medium'
              size={18}
              text='Are you sure you want to exit?'
            />
            {onExitText && <Text text={onExitText} />}
            <Flex gap={16} justifyContent='flex-start'>
              <Button
                label='Exit'
                onClick={() => {
                  setShowExitModal(false);
                  if (onExitCustomUpdate) {
                    onExitCustomUpdate();
                  }
                  app.history.push(onExitLocation);
                }}
              />
              <Button
                variant='outlined'
                label={onCancelExitText}
                onClick={() => setShowExitModal(false)}
              />
            </Flex>
          </Flex>
        </Modal>
      </>
    );
  },
);

export default TitleHeader;
