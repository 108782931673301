import React from 'react';
import {
  Flex,
  Heading,
  Text,
  IconEnum,
  Icon,
  Color,
  Button,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  comingSoonContainter: {
    width: 'calc(100% - 48px)',
    maxWidth: '1440px',
    margin: '0 auto',
    borderRadius: '12px',
    padding: 24,
  },
  comingSoonText: { marginBottom: '24px' },
  contentDescription: { marginLeft: '30px', marginBottom: '20px' },
  cooperativeBenefits: {
    backgroundImage: 'url(/images/color-gradient.png)',
    width: '426px',
    height: '520px',
    borderRadius: '8px',
    backgroundSize: 'cover',
    marginRight: '56px',
    paddingTop: '60px',
    '@media (max-width: 1000px)': {
      // This targets screens smaller than 768px
      display: 'none',
    },
  },
  cooperativeBenefitButton: {
    '&:disabled ': {
      color: 'black',
      border: `1px solid gray`,
      borderRadius: '40px',
      fontSize: '16px',
      minWidth: '225px',
      backgroundColor: Color.neutral._10,
    },
  },
}));

const contentSavings = [
  {
    title: 'Continuous Savings',
    description:
      'Unlock tax-free benefits throughout the year, not just during tax season.',
  },
  {
    title: 'Savings for All',
    description:
      'Tailored solutions that benefit both employers and employees, enhancing tax savings across the board.',
  },
  {
    title: 'AI-Powered Insights',
    description:
      'Unlock hidden savings with our advanced AI that scrutinizes the IRS tax code.',
  },
];

const cooperativeBenefitButtons = [
  'Educational Assistance',
  'Remote Work Reimbursements',
  'WiFi & Mobile Coverage',
  'Students Loans Assistance',
  '& more savings opportunities',
];

export const ComingSoon: React.FC = () => {
  const classes = useStyles();
  const { app } = useCommonStores();

  return (
    <Flex className={classes.comingSoonContainter} direction='row'>
      <Flex
        className={classes.cooperativeBenefits}
        direction='column'
        style={{ width: '40%' }}
        alignItems='center'
        gap={32}
      >
        {cooperativeBenefitButtons.map((buttonText, index) => (
          <React.Fragment key={`${buttonText}-${index}`}>
            <Button
              className={classes.cooperativeBenefitButton}
              variant={'outlined'}
              label={
                <>
                  <Icon
                    name={IconEnum.check}
                    color={Color.green._60}
                    size={23}
                  />
                  <p>{buttonText}</p>
                </>
              }
              onClick={(): void => {
                // Placeholder function - no action needed
              }}
              disabled={true}
            ></Button>
          </React.Fragment>
        ))}
      </Flex>
      <Flex direction='column' style={{ width: app.isTablet ? '100%' : '60%' }}>
        <Text
          className={classes.comingSoonText}
          text='COMING SOON'
          color={Color.green._80}
          variant='medium'
        />
        <Heading
          text='MainStreet Evolution: More Savings, More Growth'
          variant='medium'
          size={app.isTablet ? 24 : 32}
        />
        <Text
          size={18}
          text='Introducing MainStreet 2.0 – Expanding your saving opportunities with year-long benefits for you and your employee.'
        />
        <Flex
          direction='column'
          style={{
            paddingTop: '14px',
          }}
          gap={4}
        >
          {contentSavings.map((info, index) => (
            <React.Fragment key={`${info.title}-${index}`}>
              <Flex gap={8}>
                <Icon
                  name={IconEnum.check_in_seal}
                  color={Color.green._60}
                  size={23}
                />
                <Text text={info.title} variant={'medium'} size={18} />
              </Flex>
              <Flex className={classes.contentDescription}>
                <Text>{info.description}</Text>
              </Flex>
            </React.Fragment>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
