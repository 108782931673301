import { KycDocumentInfo } from '../interfaces';

export const APPLICATIONS = 'applications';

export enum KycQuestionGraphCMSIdEnum {
  ORGANIZATION_ASSETS_EXCEED_5_MIL = 'ckucwugl4qy7g0c28q3qg92f6',
  BENEFICIAL_OWNER_MORE_THAN_25_PERCENT = 'ckucwygvkr0s30b71mhmc7ey9',
  OTHERS_WHO_OWN_MORE_THAN_25_PERCENT = 'ckucx00fkqxko0b272af51g6a',
}

export const KYCQuestionToAnswers = {
  [KycQuestionGraphCMSIdEnum.ORGANIZATION_ASSETS_EXCEED_5_MIL]: {
    YES: 'ckucwu5s8qwk80b27ff9obao6',
    NO: 'ckucwu9n4qwky0b276lhoezqx',
  },
  [KycQuestionGraphCMSIdEnum.BENEFICIAL_OWNER_MORE_THAN_25_PERCENT]: {
    YES: 'ckucwvaogqydg0c2837gmu17w',
    NO: 'ckucwvfb4qye40c28xjl8vvhk',
  },
  [KycQuestionGraphCMSIdEnum.OTHERS_WHO_OWN_MORE_THAN_25_PERCENT]: {
    YES: 'ckucwzthkqxjk0b27ra5ihd3e',
    NO: 'ckucwzxcgqz7j0c28afih52if',
  },
};

export const KycDocuments = {
  PHOTO_ID: {
    name: 'Government issued photo ID',
    description: 'Document displays name, ID number, address, issuing country',
    example: `(ex. US driver's license, passport, etc)`,
  } as Readonly<KycDocumentInfo>,
  ACCREDITATION_DOCUMENTS: {
    name: 'Proof of Accreditation',
    description:
      'Manually upload documents demonstrating assets of at least $5 million',
    example:
      'Most recent tax filing, letter from your accountant, or last two months of bank statements',
  } as Readonly<KycDocumentInfo>,
};

export enum KycApplicationStepEnum {
  COMPANY_INFORMATION = 'Company information',
  PERSONAL_INFORMATION = 'Personal information',
  BENEFICAL_OWNERS = 'Company owners',
  SUCCESS = 'Success',
}

export enum AccreditationStatusEnum {
  IN_REVIEW = 'in_review',
  ACCREDITED = 'accredited',
  NOT_ACCREDITED = 'not_accredited',
}

export enum KycApplicationStatus {
  IN_REVIEW = 'in_review', // evaluation begun but not yet completed
  APPROVED = 'approved', // positive result, application approved
  REJECTED = 'rejected', // negative result, application rejected
}
