import { CompanyData, MSClientResponse } from '../lib/interfaces';
import { datadogLogs } from '@datadog/browser-logs';
import { Company } from 'entities/Company';

/**
 * Logs the promise if it fails
 *
 * Returns the promise unchanged, executed for logging only
 */
export function logPromiseFailure<T>(
  p: Promise<T>,
  company?: Partial<CompanyData> | Company | null,
): Promise<T> {
  // Grab stack outside the Promise callback, so we know where in code this happens
  const stack = new Error().stack;

  return p.catch((error) => {
    const context = logContext({ company, stack, error });
    datadogLogs.logger.error(`Failed promise`, context);

    throw error;
  });
}

/**
 * Log the API call if there is a failure. Added for BUG-257. Should eventually
 * be removed in favor of fully implemented network layer failure logging. Also
 * makes sure to log the promise if it fails (our API calls do not fail in the
 * case of an error)
 *
 * Returns the promise unchanged, executed for logging only
 */
export function logApiFailure<T>(
  p: Promise<MSClientResponse<T>>,
  company?: Partial<CompanyData> | Company | null,
): Promise<MSClientResponse<T>> {
  // Grab stack outside the Promise callback, so we know where in code this happens
  const stack = new Error().stack;

  return logPromiseFailure(
    p.then((r) => {
      if (r.errorMsg) {
        const context = logContext({ company, stack });
        datadogLogs.logger.error(
          `Failed call for company. errorMsg: ${r.errorMsg}`,
          context,
        );
      }
      return r;
    }),
  );
}

export interface LogContextArguments {
  company?: Partial<CompanyData> | Company | null;
  stack?: string;
  error?: unknown;
}

/**
 * Takes all the optional parameters we may want to include in our log context
 * such that we can build consistent log contexts
 */
export const logContext = ({ company, stack, error }: LogContextArguments) => {
  const fmtErr: { error: unknown } =
    error instanceof Error
      ? {
          error: {
            stack: error.stack,
            message: error.message,
            name: error.name,
          },
        }
      : { error };

  return {
    companyId: company?.id,
    signupStage: company?.signupStage,
    stack,
    ...fmtErr,
  };
};
