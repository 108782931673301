import React from 'react';

interface Props {
  className?: string;
}

const DollarSign = ({ className }: Props) => (
  <svg
    width='68'
    height='101'
    viewBox='0 0 68 101'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M22.4985 99.4812L22.4314 99.8401H22.7966H29.3657H29.6177L29.6638 99.5924L31.3705 90.4187C48.1256 89.9837 58.4636 78.8481 58.4636 65.1585C58.4636 60.315 56.7342 56.3493 53.513 53.2258C50.3508 50.1594 45.7661 47.9186 40.0095 46.4324L45.6978 17.1322C49.1737 17.9641 52.0287 19.5817 54.2859 21.9695C56.6107 24.4286 58.3147 27.7187 59.3999 31.8426L59.5027 32.2333L59.8492 32.0254L65.3234 28.7409L65.5176 28.6243L65.4611 28.4051C62.8309 18.2131 55.9573 12.5613 46.9112 10.7246L48.8231 1.05883L48.8947 0.696757H48.5256H41.9565H41.7074L41.659 0.941188L39.8448 10.1189C32.7205 10.324 26.2603 12.3588 21.5574 15.9922C16.7938 19.6725 13.844 24.9868 13.844 31.6559C13.844 36.4794 15.177 40.4716 18.1527 43.7128C21.0793 46.9005 25.5696 49.334 31.8615 51.1408L25.623 83.1937C20.5879 82.133 16.8914 79.7088 14.3479 76.3483C11.7508 72.9169 10.3408 68.4886 9.96067 63.4932L9.92642 63.0431L9.5227 63.245L3.61048 66.2011L3.42686 66.2929L3.44391 66.4975C4.32201 77.0348 11.1986 87.602 24.3028 89.8228L22.4985 99.4812ZM51.288 66.5818C51.288 76.1127 43.3375 82.9794 32.6944 83.6649L38.6839 53.0759C42.8921 54.516 46.0119 55.9811 48.0957 57.9997C50.215 60.0528 51.288 62.705 51.288 66.5818ZM21.0196 30.8895C21.0196 26.2906 23.1801 22.764 26.5062 20.3365C29.7496 17.9693 34.1039 16.6479 38.63 16.4279L33.1836 44.6206C28.5232 43.1156 25.5064 41.2834 23.6445 39.07C21.7464 36.8135 21.0196 34.1259 21.0196 30.8895Z'
      stroke='#999999'
      strokeWidth='0.60647'
    />
    <path
      d='M20.1257 99.4799L20.0567 99.8401H20.4235H31.81H32.0597L32.1076 99.595L33.9245 90.297C42.3046 89.6562 49.2181 86.8265 54.0523 82.2532C58.9346 77.6345 61.6743 71.2574 61.6743 63.6257C61.6743 58.2381 59.8379 53.9999 56.5359 50.684C53.2931 47.4275 48.6533 45.0766 42.9998 43.3779L46.5856 25.1322C50.2541 27.4231 52.928 31.4085 54.2907 36.3351L54.3919 36.7009L54.7263 36.5215L66.7697 30.0619L66.9785 29.9499L66.9203 29.7202C64.7282 21.0614 58.8351 13.9036 49.2423 11.2908L51.2674 1.05887L51.3391 0.696757H50.97H39.5835H39.3338L39.2859 0.941839L37.4699 10.2352C29.0459 10.7105 22.3518 13.2627 17.7472 17.2763C13.0951 21.3313 10.595 26.8622 10.595 33.1887C10.595 44.4456 19.561 51.3961 28.9408 54.0971L24.6861 75.9024C18.822 73.3509 15.7771 67.1182 14.9223 59.1046L14.8758 58.6686L14.4843 58.866L1.23654 65.5446L1.04327 65.642L1.07261 65.8565C2.49974 76.2855 9.81226 86.3967 22.0423 89.4709L20.1257 99.4799ZM46.3968 65.7059C46.3968 71.3843 42.2836 75.0883 36.5767 76.4731L40.2428 57.7236C42.1886 58.7048 43.6949 59.7534 44.7296 60.9875C45.8188 62.2868 46.3968 63.805 46.3968 65.7059ZM25.1062 31.9844C25.1062 29.4992 26.0534 27.5319 27.79 26.0716C29.4611 24.6664 31.8811 23.7164 34.9344 23.2571L31.6927 39.884C29.3257 38.8925 27.7039 37.8348 26.6629 36.6058C25.5815 35.3289 25.1062 33.8422 25.1062 31.9844Z'
      stroke='#999999'
      strokeWidth='0.60647'
    />
  </svg>
);

export default DollarSign;
