import React, { useEffect, useState } from 'react';
import {
  Button,
  Content,
  FileHandle,
  SideDrawer,
  Text,
} from 'component-library';
import FileUpload from 'component-library/dashboard/fileupload';
import { CircularProgress, makeStyles, TextField } from '@material-ui/core';
import {
  DocumentForUpload,
  DocumentSourceEnum,
} from '../../../../lib/interfaces';
import { Document } from 'lib/interfaces';
import { observer } from 'mobx-react';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from '../../../../logging';
import { useCompany, useLegacyClients } from '../../../../stores/useStores';
import { FileTypes } from 'lib/constants';

const useStyles = makeStyles(() => ({
  label: {
    paddingTop: '24px',
  },
}));

interface CustomerDocumentUploadDrawerProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onSuccessfulUpload: (uploadedDocuments: Document[]) => void;
  source?: DocumentSourceEnum;
}

export const CustomerDocumentUploadDrawer = observer(
  ({
    isOpen,
    setIsOpen,
    onSuccessfulUpload,
    source,
  }: CustomerDocumentUploadDrawerProps) => {
    const classes = useStyles();
    const { client } = useLegacyClients();
    const { company } = useCompany();
    const [filesToUpload, setFilesToUpload] = useState<FileHandle[]>([]);
    const [fileDescription, setFileDescription] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadDisabled, setIsUploadDisabled] = useState(false);

    useEffect(() => {
      setIsUploadDisabled(
        isUploading ||
          !filesToUpload?.length ||
          !fileDescription?.length ||
          filesToUpload?.some((file) => file.error),
      );
    }, [isUploading, filesToUpload, fileDescription]);

    const handleClose = () => {
      setIsOpen(false);
      setErrorMessage('');
      setIsUploading(false);
      setFilesToUpload([]);
      setFileDescription('');
    };

    const handleSubmit = async () => {
      setErrorMessage('');
      setIsUploading(true);
      const toUploadDocs = filesToUpload.map(
        (fileHandle: FileHandle): DocumentForUpload => ({
          file: fileHandle.file,
          description: fileDescription,
          name: fileHandle.name,
          source,
        }),
      );
      const { errorMsg, data } = await client.UploadCompanyDocuments({
        documents: toUploadDocs,
        emailOps: true,
      });
      setIsUploading(false);
      setFileDescription('');
      if (errorMsg) {
        setErrorMessage(errorMsg);
      } else {
        setIsOpen(false);
        onSuccessfulUpload(data?.documents ?? []);
        setFilesToUpload([]);
      }
    };

    const sidedrawerContent = () => {
      return (
        <Content>
          <FileUpload
            dataTestId={'cust-upload'}
            flexDirection={'column'}
            allowFilePassword={false}
            alignItems={'flex-start'}
            acceptedFiletypes={[
              FileTypes.CSV,
              FileTypes.PDF,
              FileTypes.XLS,
              FileTypes.XLSX,
              FileTypes.DOC,
              FileTypes.DOCX,
            ]}
            onError={(error: Error, file?: FileHandle) => {
              file?.setError('There was a problem uploading this file');
              datadogLogs.logger.error(
                `Error while attempting to upload file ${file?.name}`,
                logContext({
                  company: {
                    id: company.id,
                  },
                  error,
                }),
              );
            }}
            onFileAdded={(file, allFiles) => {
              setFilesToUpload(allFiles);
              file.setUploadProgress(1, 1, 1);
              setErrorMessage('');
            }}
            onFileRemoved={(file, allFiles) => {
              setFilesToUpload(allFiles.filter((f) => f.name !== file.name));
              setErrorMessage('');
            }}
            title={
              <>
                <Text size={15} variant='medium'>
                  Choose a file to upload
                </Text>
                <Text size={15}>Accepted formats: .pdf, .xls, .doc, .docx</Text>
              </>
            }
          />
          <Content flex flexDirection={'column'}>
            <Text size={15} className={classes.label}>
              Description
            </Text>
            <TextField
              id='cust-upload-description'
              multiline
              rowsMax={4}
              rows={4}
              variant='outlined'
              inputProps={{
                maxLength: 140,
                'data-testid': 'cust-upload-description',
              }}
              onChange={(event) => {
                setFileDescription(event.target.value);
              }}
            />
            <Text size={15}>140 characters max</Text>
          </Content>
          {errorMessage && (
            <Content paddingBottom={24}>
              <Text size={15} status='failed'>
                {errorMessage}
              </Text>
            </Content>
          )}
        </Content>
      );
    };

    const uploadActionButtons = () => {
      return (
        <Content flex>
          <Button
            dataTestId={'cust-upload-submit'}
            label={isUploading ? <CircularProgress size={24} /> : 'Upload'}
            disabled={isUploadDisabled}
            small={true}
            onClick={() => handleSubmit()}
          />
          <Button
            dataTestId={'cust-upload-cancel'}
            label='Cancel'
            small={true}
            variant='outlined'
            onClick={() => handleClose()}
          />
        </Content>
      );
    };

    return (
      <SideDrawer
        show={isOpen}
        title={'Upload new file'}
        closeToggle={() => handleClose()}
        drawerActions={uploadActionButtons()}
        drawerActionsBorder={true}
        mainContentPadding={true}
        drawerContent={sidedrawerContent()}
        dataTestId={'cust-upload'}
      />
    );
  },
);
