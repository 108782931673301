import { makeStyles } from '@material-ui/core';
import LoadingWidget from 'components/util/LoadingWidget';
import { handleErrorOrDataCallback, useEffectOnce } from 'lib/helpers';
import { Document } from 'lib/interfaces';

import React, { useContext, useState } from 'react';

import { AccountantDocumentsContainer } from './AccountantDocumentsContainer';
import { AccountantDocuments } from './AccountantDocuments';
import { AccountantDocumentsError } from './AccountantDocumentsError';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { Color, GradientBackground } from 'component-library';
import { observer } from 'mobx-react';
import { AccountantScope } from '../../../../../lib/constants';
import { Program } from '../../../../../entities/Program';

const useStyles = makeStyles(() => ({
  gradient: {
    transform: 'rotate(180deg)',
    zIndex: 0,
  },
}));

interface Props {
  taxYear: number;
  companyEmail: string;
  token: string;
}

export const AccountantDocumentsPage = observer(
  ({ taxYear, companyEmail, token }: Props) => {
    const classes = useStyles();
    const { client } = useContext(Auth0FeatureContext);

    const [documents, setDocuments] = useState<Document[]>([]);
    const [programs, setPrograms] = useState<Program[]>([]);
    const [companyName, setCompanyName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffectOnce(async () => {
      if (!taxYear) {
        return;
      }
      await client
        .ValidateAccountantTaxYearAccessLink(taxYear, companyEmail, token)
        .then((res) =>
          handleErrorOrDataCallback(
            res,
            (data) => {
              setCompanyName(data.companyName);
              setDocuments(data.documents);
              setPrograms(data.programs);
            },
            setError,
          ),
        )
        .finally(() => setLoading(false));
    });

    if (error) {
      return (
        <AccountantDocumentsContainer>
          <AccountantDocumentsError />
        </AccountantDocumentsContainer>
      );
    }

    return loading ? (
      <LoadingWidget />
    ) : (
      <AccountantDocumentsContainer>
        <AccountantDocuments
          taxYear={taxYear}
          documents={documents}
          companyName={companyName}
          token={token}
          companyEmail={companyEmail}
          scopeLevel={AccountantScope.taxYear}
          programs={programs}
        />
        <GradientBackground
          className={classes.gradient}
          gradientOpacity={0.3}
          gradient={Color.gradient._3}
          gradientOffsetTop={20}
        />
      </AccountantDocumentsContainer>
    );
  },
);
