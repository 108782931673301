import { ServerClient } from '../services/ServerClient';
import { useScreenshot } from 'use-react-screenshot';
import {
  DocumentForUpload,
  DocumentSourceEnum,
  DocumentStateEnum,
} from './interfaces';
import { datadogLogs } from '@datadog/browser-logs';
import { base64toFile } from './helpers';
import { createRef } from 'react';

/**
 * Captures a component screenshot and saves the image to the documents table
 */
export const useTakeScreenshot = (
  client: ServerClient,
  filename: string,
  filetype: string,
  description: string,
  programId?: number,
): {
  takeScreenshotAndSave: () => Promise<void>;
  ref: React.RefObject<unknown>;
} => {
  const [image, takeScreenShot] = useScreenshot({
    type: filetype,
    quality: 1.0,
  });
  const ref = createRef();

  const takeScreenshotAndSave = async () => {
    try {
      const screenshotBase64: string = await takeScreenShot(ref.current);

      const screenshotFile = base64toFile(screenshotBase64, filename, filetype);
      const documents: DocumentForUpload[] = [
        {
          name: `${screenshotFile.name}-${new Date().toLocaleDateString(
            undefined,
            {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            },
          )}`,
          description,
          file: screenshotFile,
          state: DocumentStateEnum.HIDDEN,
          source: DocumentSourceEnum.MAINSTREET,
        },
      ];

      await client.UploadCompanyDocuments({
        documents,
        programId: programId?.toString(),
      });
    } catch (e) {
      datadogLogs.logger.error(`Screenshot error: ${e}`);
    }
  };

  return { takeScreenshotAndSave, ref };
};
