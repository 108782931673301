import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import {
  Animate,
  AnswerType,
  AnswerValueType,
  Divider,
  Flex,
  Grid,
  ProgressiveStepper,
  SurveyQuestion,
  Text,
  TextField,
} from 'component-library';
import { useEffectOnce } from 'component-library/_helpers/use-utils';
import { useCommonStores, useTaxCreditsStores } from 'stores/useStores';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import { ERCStepsIndex, Page } from 'lib/constants';

interface PayrollDetailsProps {
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
  isPrefilled: boolean;
}

interface DetailsPanel {
  wotc: string[];
  covid: string[];
}

const prefilledDetails: DetailsPanel = {
  wotc: ['adrain_mitchell'],
  covid: ['price_hackett', 'june_goodwin'],
};

export const PayrollDetails = observer(
  ({ pathTo, pathFrom, onNext, onBack, isPrefilled }: PayrollDetailsProps) => {
    const [stepIndex, setStepIndex] = useState(1);
    const [showContinue, setShowContinue] = useState<boolean>(false);
    const [showStepTwoDetails, setShowStepTwonDetails] =
      useState<boolean>(false);
    const [selectedNames, setSelectedNames] = useState<DetailsPanel>(
      isPrefilled
        ? prefilledDetails
        : {
            wotc: [],
            covid: [],
          },
    );
    const { app } = useCommonStores();
    const { erc } = useTaxCreditsStores();

    const history = useHistory();

    const handleOnContinue = () => {
      if (showStepTwoDetails) {
        if (isPrefilled) {
          erc.setERCFlowIndex(ERCStepsIndex.CREDIT_ESTIMATE);
          history.push(
            `/${Page.taxCredits}/${Page.erc}/${Page.ercCreditEstimate}/?prefilled=true`,
          );
        } else {
          onNext();
          history.push(pathTo);
        }
      } else {
        setShowStepTwonDetails(true);
      }
    };

    const handleOnBack = () => {
      onBack();
      history.push(pathFrom);
    };

    const questionArray = [
      {
        text: 'Select every employee who owns >50% of your company or is related to someone who does.',
        answerType: 'multiple_select',
        answerOptions: [
          { id: 'adrain_mitchell', text: 'Adrain Mitchell' },
          { id: 'price_hackett', text: 'Price Hackett' },
          { id: 'june_goodwin', text: 'June Goodwin' },
        ],
        answerValue: isPrefilled ? ['price_hackett'] : undefined,
      },
      {
        text: 'Please select all employees for whom you claimed the Work Opportunity Tax Credit in 2020 or 2021.',
        answerType: 'multiple_select',
        answerOptions: [
          { id: 'adrain_mitchell', text: 'Adrain Mitchell' },
          { id: 'price_hackett', text: 'Price Hackett' },
          { id: 'june_goodwin', text: 'June Goodwin' },
        ],
        answerValue: isPrefilled ? ['adrain_mitchell'] : undefined,
      },
      {
        text: 'Please select all employees for whom you claimed the COVID-19 Emergency Sick Leave Credit.',
        answerType: 'multiple_select',
        answerOptions: [
          { id: 'adrain_mitchell', text: 'Adrain Mitchell' },
          { id: 'price_hackett', text: 'Price Hackett' },
          { id: 'june_goodwin', text: 'June Goodwin' },
        ],
        answerValue: isPrefilled
          ? ['price_hackett', 'june_goodwin']
          : undefined,
      },
    ];

    const scrollIntoViewOnKeyDown = () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    };

    const onInputChange = (e: AnswerValueType, questionIndex: number) => {
      const nextStep = questionIndex + 2;
      setStepIndex(nextStep);

      if (questionIndex === 1) {
        setSelectedNames((prev) => ({
          ...prev,
          wotc: [...(e as string[])],
        }));
      }

      if (questionIndex === 2) {
        setSelectedNames((prev) => ({
          ...prev,
          covid: [...(e as string[])],
        }));
      }

      if (nextStep - 1 === questionArray.length) {
        setShowContinue(true);
      }

      setTimeout(() => scrollIntoViewOnKeyDown(), 500);
    };

    useEffectOnce(() => {
      if (isPrefilled) {
        setStepIndex(questionArray.length);
        setShowContinue(true);
      }
    });

    return (
      <SurveyFlowContainer
        title='Payroll details'
        onContinue={handleOnContinue}
        onBack={handleOnBack}
        isDisabled={!showContinue}
        dataTestId='erc-qualify-survey'
      >
        <Flex direction='column' gap={24} padding={[24, 0, 40, 0]}>
          {showStepTwoDetails ? (
            <Animate enter='fade-in'>
              <Flex direction='column' gap={40}>
                <Flex direction='column' gap={24}>
                  <Text
                    text='Please indicate the time period for which you claimed the Work Opportunity Tax Credit (WOTC) for each employee.'
                    variant='medium'
                  />
                  <Flex direction='column' gap={16}>
                    {selectedNames.wotc.map((item, index) => {
                      const name = item.split('_').map((word) => {
                        return (
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                        );
                      });
                      return (
                        <Grid gap={24} key={index} alignItems='center'>
                          <Grid.Cell columns={2}>
                            <Text text={name.join(' ')} />
                          </Grid.Cell>
                          <Grid.Cell columns={3}>
                            <TextField
                              label='First paycheck date'
                              key={`${index}-${item}`}
                              value={isPrefilled ? '07/14/2020' : ''}
                              onChange={(e) => console.log(e)}
                              dateFormat
                            />
                          </Grid.Cell>
                          <Grid.Cell columns={3}>
                            <TextField
                              label='Last paycheck date'
                              key={`${index}-${item}`}
                              value={isPrefilled ? '09/14/2020' : ''}
                              onChange={(e) => console.log(e)}
                              dateFormat
                            />
                          </Grid.Cell>
                          {app.isMobile ? <></> : <Grid.Cell columns={4} />}
                        </Grid>
                      );
                    })}
                  </Flex>
                </Flex>
                <Divider variant='no-bottom-margin' />
                <Flex direction='column' gap={24}>
                  <Text
                    text='Please indicate how you claimed the COVID-19 Emergency Sick Leave Tax Credit for each employee.'
                    variant='medium'
                  />
                  <Flex direction='column' gap={16}>
                    {selectedNames.covid.map((item, index) => {
                      const name = item.split('_').map((word) => {
                        return (
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                        );
                      });
                      return (
                        <Grid gap={24} key={index} alignItems='center'>
                          <Grid.Cell columns={2}>
                            <Text text={name.join(' ')} />
                          </Grid.Cell>
                          <Grid.Cell columns={3}>
                            <SurveyQuestion
                              subtitle='Quarter claimed'
                              answerType={'dropdown_select' as AnswerType}
                              answerOptions={[
                                {
                                  id: '01',
                                  text: 'Q2 2020',
                                },
                                {
                                  id: '02',
                                  text: 'Q3 2020',
                                },
                                {
                                  id: '03',
                                  text: 'Q4 2020',
                                },
                                {
                                  id: '04',
                                  text: 'Q1 2021',
                                },
                                {
                                  id: '05',
                                  text: 'Q2 2021',
                                },
                                {
                                  id: '06',
                                  text: 'Q3 2021',
                                },
                              ]}
                              answerValue={
                                isPrefilled && index === 1
                                  ? '04'
                                  : isPrefilled
                                  ? '02'
                                  : ''
                              }
                              onChange={(e) => {
                                console.log(e, index);
                              }}
                              withCardMargin={false}
                              withCard={false}
                            />
                          </Grid.Cell>
                          <Grid.Cell columns={3}>
                            <TextField
                              label='Wages claimed'
                              key={`${index}-${item}`}
                              value={
                                isPrefilled && index === 1
                                  ? '1275'
                                  : isPrefilled
                                  ? '925'
                                  : ''
                              }
                              onChange={(e) => console.log(e)}
                              currencyFormat
                            />
                          </Grid.Cell>
                          {app.isMobile ? <></> : <Grid.Cell columns={4} />}
                        </Grid>
                      );
                    })}
                  </Flex>
                </Flex>
              </Flex>
            </Animate>
          ) : (
            <ProgressiveStepper currentStep={stepIndex} animate>
              {questionArray.map((item, index) => (
                <SurveyQuestion
                  key={index}
                  text={item.text}
                  answerType={item.answerType as AnswerType}
                  answerOptions={item.answerOptions}
                  answerValue={item.answerValue}
                  onChange={(e) => {
                    onInputChange(e, index);
                  }}
                  withCardMargin={false}
                />
              ))}
            </ProgressiveStepper>
          )}
        </Flex>
      </SurveyFlowContainer>
    );
  },
);
