import React from 'react';
import {
  RouteConfiguration,
  UNAUTH_ROUTE_CONFIG,
} from 'routes/RouteConfiguration';
import { Page } from 'lib/constants';
import Privacy from 'pages/Privacy';
import TOS from 'pages/TOS';
import MainStreetGuaranteeTOS from 'pages/MainStreetGuaranteeTOS';
import { useCommonStores } from 'stores/useStores';

export const useLegalRoutes = (): RouteConfiguration[] => {
  const { app } = useCommonStores();

  return [
    {
      path: `/${Page.privacy}`,
      render: () => <Privacy />,
      documentPageTitle: 'Privacy Policy',
      ...UNAUTH_ROUTE_CONFIG,
    },
    {
      path: `/${Page.tos}`,
      render: () => <TOS />,
      documentPageTitle: 'Terms of Service',
      ...UNAUTH_ROUTE_CONFIG,
    },
    {
      path: `/${Page.mstos}`,
      render: () => <MainStreetGuaranteeTOS />,
      documentPageTitle: 'Guarantee & Terms and Condition',
      companyRequired: false,
      hideSideNav: app.isMobile ? true : undefined,
      hideTopNav: app.isMobile ? true : undefined,
      bypassMobilePrint: true,
    },
  ];
};
