import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { ClaimBanner } from './ClaimBanner';
import { Alert, Card, Color, Flex, Link, Text } from 'component-library';
import { useFeatureFlags, useTaxCreditsStores } from 'stores/useStores';
import { Page } from 'lib/constants';

const useStyles = makeStyles(() => ({
  title: {
    whiteSpace: 'nowrap',
  },
  timelineBtn: {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
  },
  assessmentContainer: {
    backgroundColor: Color.neutral._10,
    border: `1px solid ${Color.neutral._20}`,
    borderRadius: '4px',
    '& > div': {
      borderTop: `1px solid ${Color.neutral._20}`,
    },
    '& > div:first-child': {
      borderTop: 'none',
    },
  },
  list: {
    margin: 0,
    padding: '0 24px',
  },
}));

interface TaxCreditPageBodyProps {
  claimBannerTitle: string | React.ReactElement | JSX.Element;
  claimBannerSubtitle: string | React.ReactElement | JSX.Element;
  claimBannerButtonCta?: React.ReactElement | JSX.Element;
  bodyTitle: string;
  bodySubtitle: string | JSX.Element;
}

export const TaxCreditPageBody = observer(
  ({
    claimBannerTitle,
    claimBannerSubtitle,
    claimBannerButtonCta,
    bodyTitle,
    bodySubtitle,
  }: TaxCreditPageBodyProps) => {
    const classes = useStyles();
    const { unifiedTaxCredits } = useTaxCreditsStores();
    const featureFlags = useFeatureFlags();
    const showERCCredit =
      featureFlags.showERCCreditInEstimates &&
      unifiedTaxCredits.hasQualifyingERCProgram();

    return (
      <>
        <ClaimBanner
          title={claimBannerTitle}
          subtitle={claimBannerSubtitle}
          buttonCta={claimBannerButtonCta}
        />
        <Card noMargin>
          <Flex direction='column' gap={16} padding={24}>
            <Flex
              justifyContent='space-between'
              alignItems='center'
              wrap='wrap'
            >
              <Flex
                gap={4}
                alignItems='center'
                justifyContent='flex-start'
                className={classes.title}
                basis={315}
              >
                <Text variant='medium' size={23}>
                  {bodyTitle}
                </Text>
              </Flex>
            </Flex>
            <Text paddingBottom={16}>{bodySubtitle}</Text>
            <div className={classes.assessmentContainer}>
              {showERCCredit && (
                <Flex direction='column' gap={8} padding={24}>
                  <Text variant='medium' size={18}>
                    Employee Retention Credits: Coming soon!
                  </Text>
                  <Text>
                    <Link href={`/${Page.employeeRetentionCredits}`}>
                      Click here
                    </Link>{' '}
                    to learn more.
                  </Text>
                </Flex>
              )}
              <Flex direction='column' gap={8} padding={24}>
                <Text variant='medium' size={18}>
                  Verify payroll&nbsp;connection
                </Text>
                <Text>
                  Visit the{' '}
                  <Link href={`/${Page.connections}`}>Connections</Link> page to
                  confirm an active payroll connection. We review this data to
                  determine your eligibility for future&nbsp;credits.
                </Text>
              </Flex>
              <Flex direction='column' gap={8} padding={24}>
                <Text variant='medium' size={18}>
                  Offer a qualifying retirement&nbsp;plan
                </Text>
                <Text>
                  You could start a 401(k) for your employees, and get up to
                  100% of the costs back as a tax&nbsp;credit!
                </Text>
              </Flex>
              <Flex direction='column' gap={8} padding={24}>
                <Text variant='medium' size={18}>
                  Save money, make employees happy
                </Text>
                <Text>
                  Give your employees more with each paycheck &mdash; you could
                  even save money in the process!{' '}
                  <Link
                    href={'https://mainstreet-work.typeform.com/2023-savings'}
                  >
                    Tell us a bit about your employees
                  </Link>
                  , and we&apos;ll be in touch as we work through our waitlist
                  in the coming months.
                </Text>
              </Flex>
            </div>
            <Alert
              backgroundColor={Color.blue._10}
              text={
                <Flex direction='column'>
                  <Text size={15}>
                    Learn more about{' '}
                    <Link
                      href={
                        'https://mainstreet1.my.site.com/help/s/article/Qualified-Retirement-Plans-for-Start-up-Cost-and-Auto-Enrollment-Tax-Credits'
                      }
                    >
                      qualifying retirement plans
                    </Link>{' '}
                    and{' '}
                    <Link
                      href={
                        'https://mainstreet1.my.site.com/help/s/article/Eligible-Costs-for-Disabled-Access-Tax-Credit'
                      }
                    >
                      {' '}
                      disabled access spending
                    </Link>{' '}
                    in our help center or feel free to call one of our tax
                    specialists at (833) 394-2428
                  </Text>
                </Flex>
              }
              type='info'
              variant='in_card'
            />
          </Flex>
        </Card>
      </>
    );
  },
);
