import { useState, useEffect } from 'react';
import {
  GenerateDocumentAccessLink,
  GetDocumentAccessLink,
} from 'services/server';
import { handleErrorOrDataCallback } from 'lib/helpers';

export const useDocumentAccessLink = (
  companyId: number,
  programId: number,
  taxYear?: number,
): { accessLink: string; accessLinkError: string } => {
  const [accessLink, setAccessLink] = useState('');
  const [accessLinkError, setAccessLinkError] = useState('');

  useEffect(() => {
    if (!companyId || (!programId && !taxYear)) return;

    const dataCallback = (data?: any) => {
      if (data) {
        setAccessLink(data.link);
        return;
      }

      GenerateDocumentAccessLink(companyId, programId, taxYear).then(
        (generateRes) =>
          handleErrorOrDataCallback(
            generateRes,
            (data) => setAccessLink(data!.link),
            setAccessLinkError,
          ),
      );
    };

    GetDocumentAccessLink(companyId, programId, taxYear).then((res) =>
      handleErrorOrDataCallback(res, dataCallback, setAccessLinkError),
    );

    // eslint-disable-next-line
  }, []);

  return { accessLink, accessLinkError };
};
