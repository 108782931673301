const TaxProcessingSteps = () => {
  return [
    {
      step: 'Estimate your credits',
      description: 'Estimate your R&D credit value at a high level.',
    },
    {
      step: 'R&D Assessment',
      description:
        'Provide detailed information about your business and R&D expenses so we can calculate your actual R&D credits.',
    },
    {
      step: 'MainStreet review',
      description:
        'Our R&D specialists will review your information and work with you on further details to generate your R&D tax credit forms.',
    },
    {
      step: 'We generate the forms for you',
      description:
        'You will need to send these forms to your CPA before filing taxes.',
    },
    {
      step: 'Your CPA files your taxes',
      description:
        'They will need to include any R&D credit forms you receive from us.',
    },
    {
      step: 'Apply your credits',
      description:
        'Apply your R&D credits against your payroll and/or income taxes.',
    },
  ];
};

export default TaxProcessingSteps;
