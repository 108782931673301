import React, { useEffect, useContext, useState } from 'react';
import {
  Alert,
  Button,
  Content,
  Flex,
  SideDrawer,
  Text,
  TextField,
} from 'component-library';
import { EmploymentRecordData, EmploymentTypeEnum } from 'lib/interfaces';
import { EmployeeSideDrawerSection } from 'pages/tax-processing/expense-classification/components/employee-step/EmployeeSideDrawerSection';
import ConfirmationModal from 'pages/tax-processing/expense-classification/components/employee-step/ConfirmationModal';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import { CompanyContext } from 'pages/CompanyRequired';
import { EmployerContributionEmployeeInputData } from './util';

interface EmployerContributionSideDrawerProps {
  selectedPerson: EmploymentRecordData | null;
  setSelectedPerson: (employee: EmploymentRecordData | null) => void;
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
  programTaxYear: number;
  createEmploymentRecord: (
    employee: EmployerContributionEmployeeInputData,
  ) => Promise<void>;
  updateEmploymentRecord: (
    employee: EmployerContributionEmployeeInputData,
  ) => Promise<void>;
  isRetirementTable?: boolean;
}

export const EmployerContributionSideDrawer = ({
  selectedPerson,
  setSelectedPerson,
  showDrawer,
  setShowDrawer,
  programTaxYear,
  createEmploymentRecord,
  updateEmploymentRecord,
  isRetirementTable,
}: EmployerContributionSideDrawerProps) => {
  // General State
  const { company } = useContext(CompanyContext);

  // Employee Fields
  const [fullName, setFullName] = useState<string>(
    selectedPerson?.fullName || '',
  );
  const [amountPaid, setAmountPaid] = useState<number | undefined>(
    selectedPerson?.taxablePayCents,
  );
  const [employerContribution, setEmployerContribution] = useState<
    number | undefined
  >(selectedPerson?.employerContribution);
  const [errorFullName, setErrorFullName] = useState<boolean>(false);
  const [errorAmountPaid, setErrorAmountPaid] = useState<boolean>(false);

  // Drawer and Modal
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorAddingEmployee, setErrorAddingEmployee] =
    useState<boolean>(false);

  // Validations
  const isEditMode = !!selectedPerson;
  const isMissingFullName = !selectedPerson?.fullName && isEditMode;
  const isMissingAmountPaid =
    selectedPerson?.taxablePayCents === 0 && isEditMode;

  useEffect(() => {
    if (selectedPerson) {
      setFullName(selectedPerson.fullName);
      setAmountPaid(selectedPerson.taxablePayCents);
      setEmployerContribution(selectedPerson.employerContribution);
    }
  }, [selectedPerson]);

  const isFormDirty = () => {
    let isFormDirty = false;

    if (selectedPerson) {
      isFormDirty =
        fullName !== selectedPerson.fullName ||
        amountPaid !== selectedPerson.taxablePayCents ||
        employerContribution !== selectedPerson.employerContribution;
    } else {
      isFormDirty =
        fullName !== '' ||
        amountPaid !== undefined ||
        employerContribution !== undefined;
    }

    return isFormDirty;
  };

  const isFormValid = (): boolean => {
    let isFormValid = true;

    if (!selectedPerson?.fullName && !fullName) {
      isFormValid = false;
    }

    const isAmountPaidValid = amountPaid ? amountPaid >= 0 : false;
    if (!isAmountPaidValid) {
      isFormValid = false;
    }

    // No Validation for Employer Contribution

    return isFormValid;
  };

  const resetFields = () => {
    setFullName('');
    setAmountPaid(undefined);
    setEmployerContribution(undefined);
    setErrorFullName(false);
    setErrorAmountPaid(false);
  };

  const closeDrawer = () => {
    resetFields();
    setSelectedPerson(null);
    setShowDrawer(false);
  };

  const handleCloseDrawer = () => {
    if (isFormDirty()) {
      setShowModal(true);
    } else {
      closeDrawer();
    }
  };

  const handleFullNameOnChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setErrorFullName(event.target.value.length < 1);
    setFullName(event.target.value);
  };

  const handleAmountPaidOnChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const value = event.target.value.replace(/,/g, '');
    const isAmountPaidValid = value === '' || value === '0';

    setErrorAmountPaid(isAmountPaidValid);
    isAmountPaidValid
      ? setAmountPaid(undefined)
      : setAmountPaid(Number(value) * 100);
  };

  const handleEmployerContributionOnChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const value = event.target.value.replace(/,/g, '');
    const valueIsEmpty = value === '';

    valueIsEmpty
      ? setEmployerContribution(undefined)
      : setEmployerContribution(Number(value) * 100);
  };

  const handleSaveOnClick = async () => {
    setErrorAddingEmployee(false);

    if (isFormValid()) {
      setIsSubmitting(true);

      try {
        const employee: EmployerContributionEmployeeInputData = {
          fullName: fullName,
          taxYear: programTaxYear,
          taxablePayCents: amountPaid,
          employerContribution: employerContribution,
          employmentType: EmploymentTypeEnum.EMPLOYEE,
        };

        if (isEditMode) {
          await updateEmploymentRecord(employee);
        } else {
          await createEmploymentRecord(employee);
        }

        closeDrawer();
      } catch (error) {
        setErrorAddingEmployee(true);
        datadogLogs.logger.error(
          'Error in EmployerContributionSideDrawer.handleSaveOnClick',
          logContext({
            error,
            company,
          }),
        );
      }

      setIsSubmitting(false);
    }
  };

  const drawerActions = (
    <Button
      label='Save'
      disabled={isSubmitting || !isFormValid()}
      loading={isSubmitting}
      onClick={handleSaveOnClick}
      dataTestId='employer-contribution-side-drawer-save-button'
    />
  );

  const title = selectedPerson
    ? 'Edit employee details'
    : 'Add employee details';

  const drawerContent = (
    <>
      <Content gap={32} flexDirection='column' flex>
        <EmployeeSideDrawerSection title='Personal details'>
          <TextField
            name='full-name'
            label='Full name'
            secondaryLabel={
              isMissingFullName ? 'Missing information' : undefined
            }
            secondaryLabelTheme={isMissingFullName ? 'warning' : undefined}
            helperText={errorFullName ? 'Name is required.' : ''}
            value={fullName}
            onChange={(event) => {
              handleFullNameOnChange(event);
            }}
            onBlur={(event) => {
              handleFullNameOnChange(event);
            }}
            error={errorFullName}
            dataTestId={`fullName-employer-contribution}`}
          />
        </EmployeeSideDrawerSection>
        <EmployeeSideDrawerSection title='Tax details'>
          <>
            <TextField
              name='amount-paid'
              label={`${programTaxYear} Employee taxable wages`}
              secondaryLabel={
                isMissingAmountPaid ? 'Missing information' : undefined
              }
              secondaryLabelTheme={isMissingAmountPaid ? 'warning' : undefined}
              helperText={
                errorAmountPaid ? 'Employee taxable wages are required.' : ''
              }
              value={(amountPaid || 0) / 100}
              currencyFormat={true}
              onChange={(event) => {
                handleAmountPaidOnChange(event);
              }}
              onBlur={(event) => {
                handleAmountPaidOnChange(event);
              }}
              error={errorAmountPaid}
              dataTestId={`amountPaid-employer-contribution}`}
            />
            <TextField
              name='employer-contribution'
              label={`${programTaxYear} Employer contribution`}
              helperText='This amount should not include employee deductions.'
              value={
                employerContribution !== undefined
                  ? (employerContribution || 0) / 100
                  : ''
              }
              currencyFormat={true}
              onChange={(event) => {
                handleEmployerContributionOnChange(event);
              }}
              dataTestId={'employerContribution-employer-contribution'}
            />
            <Alert
              backgroundColor='#f7f9ff'
              text={
                <Flex direction='column'>
                  <Text size={15}>
                    Please be aware that contributions directed towards Defined
                    Benefit Pension Plans (e.g., Pension) do not qualify for the
                    Employer Contribution Credit.
                  </Text>
                  <Text size={15}>
                    Kindly exclude such contributions from the employer&apos;s
                    contribution calculation.
                  </Text>
                </Flex>
              }
              type='info'
              variant='card'
            />
          </>
        </EmployeeSideDrawerSection>
      </Content>
      {showModal && (
        <ConfirmationModal
          displayType='employer-contribution'
          closeSideDrawer={closeDrawer}
          modalIsOpen={showModal}
          setModalIsOpen={setShowModal}
        />
      )}
    </>
  );

  return (
    <SideDrawer
      show={showDrawer}
      title={title}
      closeToggle={handleCloseDrawer}
      dataTestId={'employer-contribution-side-drawer'}
      alertMessage={
        isRetirementTable
          ? 'Important: Any changes you make to information on this table will automatically update data in the R&D Employees section. Please ensure you review that section again to confirm all data is correct.'
          : undefined
      }
      alertType='info'
      alertMessagePosition='bottom'
      drawerContent={drawerContent}
      drawerActions={drawerActions}
    />
  );
};
