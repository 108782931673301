import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Animate, Flex } from 'component-library';
import { PartnerCustomEstimateSignup } from './PartnerCustomEstimateSignup';
import { PartnerAccessCodeEntry } from './PartnerAccessCodeEntry';
import { useEffectOnce } from 'component-library/_helpers/use-utils';
import { useCommonStores, useOnboardingStores } from 'stores/useStores';
import { Page } from 'lib/constants';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    width: '100%',
    height: '100%',
    background: `url(${process.env.PUBLIC_URL}/images/welcome-page-background.jpg)`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: 'clamp(200px, 50%, 960px)',
    backgroundPosition: `0% 100%`,
    [breakpoints.down('sm')]: {
      backgroundSize: '100%',
    },
  },
  logoBar: {
    width: '100%',
    maxWidth: '1440px',
    margin: '0 auto',
    '& > img': {
      maxHeight: '40px',
      maxWidth: '236px',
    },
  },
}));

interface GenericQuickbooksLandingPageProps {
  queryParams: URLSearchParams;
  code: string;
  partner: string;
  partnerCompany: string;
}

export const GenericQuickbooksLandingPage: React.FC<GenericQuickbooksLandingPageProps> =
  observer(({ queryParams, code, partner, partnerCompany }) => {
    const classes = useStyles();
    const { initialAccountSetup } = useOnboardingStores();
    const { app } = useCommonStores();

    useEffectOnce(async () => {
      if (code.length > 0) {
        await initialAccountSetup.getAccessCodeEstimate(queryParams, partner);
        if (initialAccountSetup.codeNotFound) {
          // redirect back to partner page to enter correct code
          app.history.push(`/${Page.selfSignupWelcome}/${partner}`);
          // set textfield with code not found
          initialAccountSetup.enterCode = code;
        }
      }
    });

    return (
      <div className={classes.root} data-testid={'welcome-to-mainstreet'}>
        <Flex className={classes.logoBar} padding={[24, 24, 0, 24]}>
          <Animate enter={'fade-in'}>
            <img
              src={`${process.env.PUBLIC_URL}/images/logo/partners/${partner}-x-mainstreet.svg`}
              alt={`MainStreet & ${partner} logo'`}
              onError={({ currentTarget }) => {
                currentTarget.src = `${process.env.PUBLIC_URL}/images/logo/mst-logo-green.svg`;
              }}
              style={{ maxWidth: 'clamp(260px, 20vw, 320px)' }}
            />
          </Animate>
        </Flex>
        {code.length > 0 ? (
          <PartnerCustomEstimateSignup
            code={code}
            partner={partner}
            partnerCompany={partnerCompany}
          />
        ) : (
          <PartnerAccessCodeEntry
            queryParams={queryParams}
            partner={partner}
            partnerCompany={partnerCompany}
          />
        )}
      </div>
    );
  });
