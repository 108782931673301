import { IPlaidClient, LinkToken } from '../types';
import { MSClientResponse } from 'lib/interfaces';
import { AbstractWebClient } from 'services/AbstractWebClient';
import { APIV1 } from 'lib/constants';
import { Get, Post } from 'services/serverGenerics';
import { BankingInstitution } from '@mainstreet/client-models/financial/banking/bankingModels';

// base api route for plaid operations
const PLAID_CONNECTION_ROUTE = 'plaidconnection';

/**
 * MSPlaidClient
 *
 * Used for all interaction with v1 financial plaidBankingConnection router.
 * Recieves `this.addSharedHeaders()` from AbstractWebClient to enable
 * Auth token.
 *
 * Accessed by consuming Auth0Feature context and calling
 * it with `auth0Context.plaidClient.SomePlaidMethod`.
 */
export default class MSPlaidClient extends AbstractWebClient
  implements IPlaidClient {
  /**
   *
   * @param publicToken from plaid link flow after customer finishes entering their bank information.
   * @param companyId
   * @returns `{ data: { errorMsg } | undefined }`.  If no error message, you can then use the
   * `auth0Context.payments.GetPaymentDetails` and receive all connected institutions/accounts.
   */
  public async exchangePublicToken(
    publicToken: string,
    companyId: number,
  ): Promise<MSClientResponse<void>> {
    const requestBody = {
      publicToken,
      companyId: `${companyId}`,
    };

    try {
      const { res } = await Post<undefined>(
        `/${APIV1}/${PLAID_CONNECTION_ROUTE}/exchangepublictoken`,
        [200],
        JSON.stringify(requestBody),
        this.addSharedHeaders({
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      );

      if (res.errorMsg) {
        return { errorMsg: res.errorMsg };
      } else {
        return { data: undefined };
      }
    } catch (err) {
      return {
        errorMsg:
          'Failed to link your account to Plaid. Please try again or reach out to support@mainstreet.com for help',
      };
    }
  }

  /**
   *
   * @param companyId
   * @returns `{ data: { linkToken: string }}` which enables plaidLink `open` to trigger the customers
   * plaidLink flow.
   */
  // TODO: Create 2nd GenerateLinkToken function for those who already have an access_token.
  // and are awaiting some sort of verification of their account from plaid
  public async generatePlaidLinkToken(
    companyId: number,
  ): Promise<MSClientResponse<LinkToken>> {
    try {
      const { res } = await Post<LinkToken>(
        `/${APIV1}/${PLAID_CONNECTION_ROUTE}/linktoken`,
        [200],
        JSON.stringify({ companyId: `${companyId}` }),
        this.addSharedHeaders({
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      );
      if (res.errorMsg) {
        return { errorMsg: res.errorMsg };
      } else {
        return { data: res.data };
      }
    } catch (err) {
      return {
        errorMsg:
          'Failed to fetch Plaid token. Please refresh and try again or reach out to support@mainstreet.com for help.',
      };
    }
  }

  /**
   *
   * @param companyId
   * @returns `{ data: { linkToken: string }}` which enables plaidLink `open` to trigger the customers
   * plaidLink flow.
   */
  public async generatePlaidLinkTokenForExistingCustomer(
    companyId: number,
  ): Promise<MSClientResponse<LinkToken>> {
    try {
      const { res } = await Post<LinkToken>(
        `/${APIV1}/${PLAID_CONNECTION_ROUTE}/linktokenupdate`,
        [200],
        JSON.stringify({ companyId: `${companyId}` }),
        this.addSharedHeaders({
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      );
      if (res.errorMsg) {
        return { errorMsg: res.errorMsg };
      } else {
        return { data: res.data };
      }
    } catch (err) {
      return {
        errorMsg:
          'Failed to fetch Plaid token. Please refresh and try again or reach out to support@mainstreet.com for help.',
      };
    }
  }

  /**
   *
   * @param companyId
   * @returns Get all connected `BankInstitutions` and
   * their associated accounts.`  Include balances and revoked
   * institutions as optional query parameters.
   */
  public async getConnectedInstitutions(
    includeBalances?: boolean,
    includePermissionRevokedInstitutions?: boolean,
  ): Promise<MSClientResponse<BankingInstitution[]>> {
    try {
      const res = await Get<BankingInstitution[]>(
        `/${APIV1}/${PLAID_CONNECTION_ROUTE}/connectedinstitutions?includeBalances=${includeBalances}&includePermissionRevokedInstitutions=${includePermissionRevokedInstitutions}`,
        [200],
        this.addSharedHeaders({
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      );

      if (res.errorMsg) {
        return { errorMsg: res.errorMsg };
      } else {
        return { data: res.data };
      }
    } catch (err) {
      return {
        errorMsg:
          'Failed to fetch account info. Please try again or reach out to support@mainstreet.com for help',
      };
    }
  }

  public async disconnectInstitution(): Promise<MSClientResponse<void>> {
    return Promise.reject('Feature Not Implemented');
  }
}
