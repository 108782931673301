import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SentenceCase } from 'lib/helpers';

const useStyles = makeStyles(() => {
  return {
    continue: {
      minWidth: '180px',
      maxWidth: '320px',
      padding: '0 40px',
      height: '50px',
      fontSize: '18px',
      fontWeight: 500,
      textTransform: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
  };
});

type Props = {
  label?: string;
} & ButtonProps;

export const ContinueButton = ({ label = 'Continue', ...props }: Props) => {
  const classes = useStyles();

  return (
    <Button
      color='secondary'
      variant='contained'
      className={classes.continue}
      {...props}
    >
      {SentenceCase(label)}
    </Button>
  );
};
