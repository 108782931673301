import { ProgramData } from 'lib/interfaces';
import {
  ExpectedCreditTypeEnum,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
} from 'lib/constants';

export const isProgramAffectedBy8974 = (program: Partial<ProgramData>) =>
  program.name === ProgramNameEnum.FED_RD_TAX &&
  program.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX;

export const isProgramWaitingFor8974UserConfirmations = (
  program: Partial<ProgramData>,
) =>
  isProgramAffectedBy8974(program) &&
  (program.stage === ProgramStageEnum.CLIENT_REVIEW ||
    (program.stage === ProgramStageEnum.FINISHED &&
      program.subStage === ProgramSubStageEnum.WAITING_FOR_FILING_CONF)) &&
  !program.taxFilingDate;
