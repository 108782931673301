import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Color, Text } from 'component-library';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  headerContent: {
    position: 'relative',
    boxShadow: `inset 0px -1px 0px ${Color.neutral._20}`,
  },
  logoContent: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
    padding: '32px 24px',
    gap: '16px',
    boxSizing: 'border-box',
  },
  saveInvite: {
    display: 'flex',
    gap: '16px',
    position: 'absolute',
    top: '36px',
    right: '48px',

    '& button': {
      padding: 0,
      height: 'auto',
    },
  },
  inviteBtn: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    paddingRight: '16px',
    borderRight: `1px solid ${Color.neutral._20}`,
    '& > a': {
      padding: 0,
      height: 'auto',
    },
  },
  mslogo: {
    maxWidth: '106px',
    paddingRight: '16px',
    borderRight: `1px solid ${Color.neutral._20}`,
  },
}));

interface HeaderBarProps {
  title: string;
}

export const HeaderBar = ({ title }: HeaderBarProps) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.headerContent}>
      <div className={classes.logoContent}>
        <img
          className={classes.mslogo}
          src={`${process.env.PUBLIC_URL}/images/MainSt_WordmarkGreen.svg`}
          alt='MainStreet watermark'
        />
        <Text
          variant='medium'
          size={18}
          text={title}
          color={Color.neutral._80}
        />
      </div>
      <div className={classes.saveInvite}>
        <Button
          variant='tertiary'
          label='Exit'
          onClick={() => history.push('/')}
        />
      </div>
    </div>
  );
};
