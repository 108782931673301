import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '14px',
    height: '14px',
    background: '#FFE600',
    border: '1px solid #FFA800',
    borderRadius: '50%',
  },
}));

export interface ActionRequiredCircleProps {
  marginRight?: string;
}

export const ActionRequiredCircle = ({
  marginRight,
}: ActionRequiredCircleProps) => {
  const classes = useStyles();

  return <Box marginRight={marginRight} className={classes.root} />;
};
