import React from 'react';
import { observer } from 'mobx-react';
import {
  Color,
  Expandable,
  Flex,
  Grid,
  Heading,
  Text,
} from 'component-library';
import { BogoDealContent } from './BogoDealContent';
import {
  useCommonStores,
  useTaxCreditsStores,
  useFeatureFlags,
} from 'stores/useStores';
import { BogoDealCheckbox } from './BogoDealCheckbox';
import { BogoDealSectionCardHeader } from './BogoDealSectionCardHeader';
import { SectionCardHeader, SectionCard } from '../SectionCard';

interface BogoDealSectionCardProps {
  children: React.ReactElement | JSX.Element;
  mainStreetFee: string;
  programTaxYear: number;
}

export const BogoDealSectionCard: React.FC<BogoDealSectionCardProps> = observer(
  ({ children, mainStreetFee, programTaxYear }) => {
    const { app } = useCommonStores();
    const { orderForms } = useTaxCreditsStores();
    const featureFlags = useFeatureFlags();

    const forceBogoDealByTaxYear: { [key: number]: boolean } = {
      2022: featureFlags.force2022BOGODeal,
      2023: featureFlags.force2023BOGODeal,
    };

    return (
      <SectionCard>
        <>
          <Grid columns={12}>
            {orderForms.optInBOGO ||
            (orderForms.isReceipt && orderForms.hasAcceptedBOGO) ? (
              <BogoDealSectionCardHeader
                mainStreetFee={mainStreetFee}
                isSmallDesktop={app.isSmallDesktop}
              />
            ) : (
              <SectionCardHeader
                sectionTitle='Mainstreet fee'
                estimateTotal={mainStreetFee}
                estimateColor={Color.neutral._90}
              />
            )}
            {children}

            {/* Card footer. */}
            {!(
              orderForms.isReceipt || forceBogoDealByTaxYear[programTaxYear]
            ) && (
              <Grid.Cell
                padding={[16, 24]}
                columns={12}
                style={{ backgroundColor: Color.neutral._light_20 }}
              >
                <BogoDealCheckbox
                  isChecked={orderForms.optInBOGO}
                  onChange={() => orderForms.toggleOptInBOGO()}
                  programTaxYear={programTaxYear}
                />
              </Grid.Cell>
            )}
            {/* End card footer. */}
          </Grid>
          {!orderForms.isReceipt && !orderForms.hasAcceptedBOGO && (
            <Expandable expand={orderForms.optInBOGO ?? false}>
              <BogoDealContent programTaxYear={programTaxYear} />
            </Expandable>
          )}

          {orderForms.hasAcceptedBOGO && (
            <Grid columns={12} backgroundColor={Color.green._80}>
              <Grid.Cell columns={12} padding={24}>
                <Flex gap={16} direction='column'>
                  <Heading
                    text={`You’re getting all ${programTaxYear} R&D tax credits free of charge.`}
                    size={app.isSmallDesktop ? 18 : 23}
                    color={Color.lime._60}
                    noMargin
                  />
                  <Text
                    color={Color.neutral.white}
                    text={`Since your company signed up for ${
                      programTaxYear + 1
                    } R&D tax credits with MainStreet.`}
                  />
                  <Text
                    color={Color.neutral.white}
                    text={`If your company decides to no longer work with MainStreet for ${
                      programTaxYear + 1
                    } R&D tax credits, this offer will be void. Your company will be responsible for paying the MainStreet fee for ${programTaxYear} R&D tax credits in full.`}
                  />
                </Flex>
              </Grid.Cell>
            </Grid>
          )}
        </>
      </SectionCard>
    );
  },
);
