import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { LoginWidget } from 'components/LoginWidget';
import StagingBanner from 'components/util/StagingBanner';
import { Color } from 'component-library';

const useStyles = makeStyles(() => ({
  root: {
    background: Color.neutral.white,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginContainer: { margin: '24px 0', maxWidth: '600px' },
  loginBanner: {
    position: 'fixed',
    top: '0',
    width: '100%',
  },
}));

interface LoginProps {
  error: string | null;
}

export const LoginPage = ({ error }: LoginProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid='login-page'>
      <div className={classes.loginBanner}>
        <StagingBanner />
      </div>
      <div className={classes.loginContainer}>
        <LoginWidget error={error} />
      </div>
    </div>
  );
};
