import React, { useEffect } from 'react';
import {
  Alert,
  Animate,
  AnswerType,
  AnswerValueType,
  Color,
  Flex,
  Spinner,
  SurveyQuestion,
  Text,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import {
  BeneficialOwnershipQuestions,
  BeneficialOwnershipQuestionsToAnswers,
  RetirementPlanCreditQuestions,
  SurveyNameEnum,
} from 'lib/constants';
import { CmsQuestionData } from 'lib/interfaces';
import {
  useCommonStores,
  useFeatureFlags,
  useLegacyClients,
  useTaxCreditsStores,
} from 'stores/useStores';
import _ from 'lodash';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import DOMPurify from 'dompurify';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    margin: '0 auto',
    position: 'relative',
  },
  hasDropdown: {
    position: 'relative',
    zIndex: 2,
  },
  surveyListText: {
    '& > ul': {
      margin: 0,
      padding: '0 24px 16px',

      '& > li': {
        paddingBottom: '0 8px 8px',
        fontSize: '15px',
        lineHeight: '24px',
        color: Color.neutral._80,
      },
    },
  },
  alert: {
    backgroundColor: Color.blue._10,
    border: `0.8px solid ${Color.blue._60}`,
  },
}));

export const BoirBusinessDetails = observer(() => {
  const classes = useStyles();
  const { app, chatbot, companyStore } = useCommonStores();
  const { surveyFlow } = useTaxCreditsStores();
  const featureFlags = useFeatureFlags();
  const { client } = useLegacyClients();

  const surveyName: SurveyNameEnum =
    SurveyNameEnum.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT;

  const {
    isSurveyComplete,
    isLoading,
    surveyAnswers,
    questionsToRender,
    addSurveyAnswers,
    saveAnswers,
    addSurveyAnswersWithoutRerender,
  } = useSurveyQuestions(surveyName, new Date().getFullYear(), undefined);

  // Extract pre-fill answer from the URL, if it exists
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const prefillAnswerId = queryParams.get('answer');

  // Update survey with the pre-fill answer extracted from the URL
  useEffect(() => {
    if (prefillAnswerId) {
      // Check that the answer matches "Yes" to eligible conditions question:
      const eligibleConditionsQuestionId =
        BeneficialOwnershipQuestions.DO_ANY_ELIGIBLE_CONDITIONS_APPLY;
      const answerId =
        BeneficialOwnershipQuestionsToAnswers[eligibleConditionsQuestionId].YES;

      if (prefillAnswerId === answerId) {
        // addSurveyAnswers(eligibleConditionsQuestionId, 'qualification', [
        //   { questionId: eligibleConditionsQuestionId, answerValue: answerId },
        // ]);
        addSurveyAnswersWithoutRerender([
          { questionId: eligibleConditionsQuestionId, answerValue: answerId },
        ]);

        client.UpdateCompanyWithSurveyResponsePublic(
          // @ts-ignore TS not correctly handling that value exists within ternery
          companyStore.accessToken,
          surveyName,
          {
            taxYear: new Date().getFullYear(),
            qualificationQuestions: surveyAnswers,
          },
        );
      }
    }
  }, [isLoading]);

  useEffect(() => {
    surveyFlow.checkQualificationStatus(surveyAnswers);
  }, [surveyFlow, surveyAnswers]);

  const handleOnContinue = async () => {
    app.history.push('/assessments/beneficial-ownership/review');
  };

  const handleAnswerChange = (
    question: CmsQuestionData,
    answerValue: AnswerValueType,
    key: string,
  ) => {
    question.answerValue = answerValue;
    addSurveyAnswers(question.id, key, [
      { questionId: question.id, answerValue: answerValue },
    ]);
    // save answers per question answered
    if (
      question.answerType === 'multiple_select' ||
      question.answerType === 'multiple_choice' ||
      question.answerType === 'yes_or_no' ||
      question.answerType === 'dropdown_select'
    ) {
      saveAnswers && saveAnswers();
    }
  };

  const renderQuestions = () =>
    Object.keys(questionsToRender).map((key) => {
      return questionsToRender[key].map((question) => {
        const subtitleList =
          question.subtitle && question.subtitle.includes('<ul>');
        return (
          <Animate
            key={question.id}
            enter={['fade-in', 'from-bottom']}
            duration={0.5}
            className={`${
              question.answerType === 'dropdown_select'
                ? classes.hasDropdown
                : ''
            }`}
          >
            <SurveyQuestion
              dataTestId={question.id}
              answerType={question.answerType as AnswerType}
              text={question.text}
              subtitle={
                subtitleList ? (
                  <div
                    className={classes.surveyListText}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(question.subtitle),
                    }}
                  />
                ) : (
                  question.subtitle
                )
              }
              answerOptions={question.answerIDs}
              onChange={(answer) => handleAnswerChange(question, answer, key)}
              onBlur={() => saveAnswers && saveAnswers()}
              placeholder={question.placeholder}
              answerValue={question.answerValue}
              tooltip={question.tooltip}
              questionTextSize={app.isMobile ? 15 : 18}
              questionSubtitleSize={app.isMobile ? 13 : 15}
              withCardMargin={false}
              helpLinkText={question.helperText}
              chatBotQuestion={chatbot.isAiQuestionEnabled(question.aiQuestion)}
              chatBotPrompt={() =>
                chatbot.autoSendAiMessage(question.aiQuestion)
              }
              onHelpLinkClick='https://mainstreet1.my.site.com/help/s/article/Retirement-Plan-Startup-Cost'
              error={
                question.id ===
                  RetirementPlanCreditQuestions.HOW_MUCH_SPENT_STARTUP_COST &&
                question.answerValue === 0
              }
              errorText={
                question.id ===
                  RetirementPlanCreditQuestions.HOW_MUCH_SPENT_STARTUP_COST &&
                question.answerValue === 0
                  ? 'This value cannot be $0'
                  : ''
              }
            />
          </Animate>
        );
      });
    });

  const survey = (
    <>
      {isLoading ? (
        <Spinner size='small' />
      ) : (
        !_.isEmpty(questionsToRender) && renderQuestions()
      )}
    </>
  );

  return (
    <Flex
      direction='column'
      className={classes.root}
      data-testid={'beneficial-ownership'}
    >
      <Flex className={classes.mainContent} direction='column'>
        <Animate enter={'fade-in'}>
          <SurveyFlowContainer
            title='You likely need to file a Beneficial Ownership Information Report'
            onContinue={async () => await handleOnContinue()}
            isDisabled={!isSurveyComplete}
            isLoading={surveyFlow.surveyContinueLoading}
            continueText={'Submit'}
          >
            <Flex direction='column' gap={24}>
              <Alert
                type={'none'}
                className={classes.alert}
                text={
                  <Flex direction='column' gap={12}>
                    <Text
                      text={`As a valued MainStreet customer, we will help ${companyStore.company.name} file this form for free!`}
                      variant='medium'
                    />
                    <Text
                      text={
                        'Please answer a few more questions to confirm whether you’ll need to submit this report at this time.'
                      }
                      size={13}
                      color={Color.neutral._80}
                    />
                  </Flex>
                }
                iconColor={Color.blue._60}
                backgroundColor={Color.blue._10}
              />
              {survey}
            </Flex>
          </SurveyFlowContainer>
        </Animate>
      </Flex>
    </Flex>
  );
});
