import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core';
import {
  Animate,
  Button,
  Color,
  Divider,
  Dollar,
  Flex,
  Heading,
  Image,
  Text,
} from 'component-library';
import {
  useCommonStores,
  useCompany,
  useLegacyClients,
  useFeatureFlags,
} from 'stores/useStores';
import { ManualPayrollPage } from 'pages/onboarding';
import { CentsToDisplayStringNoSymbol } from 'lib/helpers';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import { ProgramCreditEstimate } from 'lib/interfaces';
import { AT_MS_DOMAIN, Page, Programs } from 'lib/constants';
import { Loading } from 'components/util/Loading';
import { useHistory } from 'react-router-dom';

interface CreditEstimateProps {
  taxYear: number;
}

const useStyles = makeStyles(({ breakpoints, boxShadowDark }: Theme) => ({
  root: {
    display: 'grid',
    height: '100%',
    gridTemplateColumns: `45% 55%`,
    background: `url(${process.env.PUBLIC_URL}/images/dashboard/get-started/get-started-bg-gradient.png)`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: '100% 100%',
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  leftContainer: {
    width: '90%',
    maxWidth: '480px',
    alignSelf: 'center',
    justifySelf: 'flex-end',
    [breakpoints.down('sm')]: {
      width: '100%',
      padding: '24px',
    },
  },
  rightContainer: {
    paddingTop: '24px',
    background: 'white',
    width: '85%',
    maxWidth: '600px',
    alignSelf: 'center',
    justifySelf: 'center',
    boxShadow: boxShadowDark,
    [breakpoints.down('sm')]: {
      width: '90%',
      padding: '0 24px',
    },
  },
  alert: {
    backgroundColor: Color.neutral._light_20,
    paddingLeft: '20px',
  },
  continueBtn: {
    margin: '-10px 20px 0px 10px',
  },
  loading: {
    alignSelf: 'center',
  },
}));

export const CreditEstimate = observer(({ taxYear }: CreditEstimateProps) => {
  const { app } = useCommonStores();
  const classes = useStyles();
  const { company } = useCompany();
  const { client } = useLegacyClients();
  const featureFlags = useFeatureFlags();
  const history = useHistory();
  const [programCreditEstimates, setProgramCreditEstimates] = useState<
    Array<ProgramCreditEstimate>
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isContinueBtnLoading, setIsContinueBtnLoading] = useState(false);
  const [error, setError] = useState('');

  // Used in manual payroll to assist in determining whether
  // manual payroll component needs to render
  const [loadingError, setLoadingError] = useState<boolean>(false);

  // Render manualpayroll component by default to check for loading error
  const [showManualPayrollEstimate, setShowManualPayrollEstimate] = useState<
    boolean
  >(true);

  const byYear = company.misc!.averageMonthlyPayrollCentsByYear || {};

  const payrollEstimateInCents =
    byYear[new Date().getUTCFullYear()] ||
    byYear[new Date().getUTCFullYear() - 1] ||
    company.misc!.originalPayrollEstimateCents;

  useEffect(() => {
    const isUnifiedQualification = featureFlags.showUnifiedQualification;
    const shouldShowManualPayrollEstimate =
      (loadingError && !company.misc!.originalPayrollEstimateCents) ||
      !payrollEstimateInCents;
    setShowManualPayrollEstimate(
      !isUnifiedQualification && shouldShowManualPayrollEstimate,
    );
  }, [loadingError]);

  useEffect(() => {
    if (taxYear) {
      client.GetDisplayableCreditEstimates(taxYear).then((res) => {
        if (res.errorMsg || !res.data) {
          datadogLogs.logger.error(
            '[CREDIT_ESTIMATE]: failed to retrieve displayable credit estimate',
            logContext({ company, error: res.errorMsg }),
          );
          return;
        }

        const { programCreditEstimates } = res.data;
        if (programCreditEstimates.length) {
          setProgramCreditEstimates(programCreditEstimates);
          setIsLoading(false);
        } else {
          datadogLogs.logger.error(
            `[CREDIT_ESTIMATE]: retrieved no displayable credit estimates for ${taxYear} taxYear`,
            logContext({ company, error: res.errorMsg }),
          );
          app.history.push(Page.dashboard);
        }
      });
    }
  }, [taxYear]);

  const onSubmit = () => {
    setIsContinueBtnLoading(true);
    client.UpdateCreditEstimate({ programTaxYear: taxYear }).then((res) => {
      setIsContinueBtnLoading(false);
      if (res && res.errorMsg) {
        setError(
          `Error generating order form, please reach out to support${AT_MS_DOMAIN} for assistance.`,
        );

        datadogLogs.logger.error(
          '[CREDIT_ESTIMATE]: Error generating order form',
          logContext({ company, error }),
        );
        return;
      }
      history.push(`/${Page.orderForms}/${taxYear}`);
    });
  };

  const totalCreditEstimate = programCreditEstimates.reduce(
    (result, current) => result + current.high,
    0,
  );

  const individualProgramEstimates = () =>
    programCreditEstimates.length && programCreditEstimates.length === 1
      ? oneProgramEstimate(programCreditEstimates[0])
      : twoProgramEstimate(programCreditEstimates);

  const oneProgramEstimate = (programEstimate: ProgramCreditEstimate) => (
    <Flex.Cell dataTestId={'credit-estimate-individual-program'}>
      <Text
        variant={'regular'}
        size={18}
        text={Programs[programEstimate.name].displayShort}
        textAlign={'center'}
      />
      <Text size={18} textAlign={'center'}>
        <Dollar
          variant={'medium'}
          value={CentsToDisplayStringNoSymbol(programEstimate.high)}
          tag='span'
        />
        <Text variant={'bold'} tag={'span'} text={'*'} size={18} />
      </Text>
    </Flex.Cell>
  );

  const twoProgramEstimate = (programEstimates: ProgramCreditEstimate[]) => (
    <>
      {oneProgramEstimate(programEstimates[0])}
      <img src={`${process.env.PUBLIC_URL}/images/plus-sign.svg`} alt='+' />
      {oneProgramEstimate(programEstimates[1])}
    </>
  );

  return (
    <>
      {showManualPayrollEstimate ? (
        <ManualPayrollPage
          qualificationTaxYear={taxYear}
          setLoadingError={setLoadingError}
        />
      ) : (
        <div className={classes.root}>
          <Flex direction='column' className={classes.leftContainer}>
            <Heading
              size={app.isMobile ? 24 : 32}
              color={Color.neutral._80}
              variant='regular'
              noMargin
            >
              {company.name} is matched with tax credits!
            </Heading>
          </Flex>
          {isLoading ? (
            <div className={classes.loading}>
              <Loading loading={isLoading} />
            </div>
          ) : (
            <Animate
              enter={['fade-in', 'from-bottom']}
              className={classes.rightContainer}
            >
              <Flex direction='column' gap={24} padding={[16, 0]}>
                <Flex
                  direction={'column'}
                  alignItems={'center'}
                  dataTestId={'credit-estimate-summary'}
                >
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/dashboard/educational-module/icons/qualify.svg`}
                    width={100}
                  />
                  <Text
                    variant={'bold'}
                    size={18}
                    text={`${company.name} is eligible for up to`}
                    textAlign={'center'}
                  />
                  <Text size={32}>
                    <Dollar
                      variant={'medium'}
                      value={CentsToDisplayStringNoSymbol(totalCreditEstimate)}
                      tag='span'
                    />
                    <Text variant={'bold'} tag={'span'} text={'*'} size={32} />
                  </Text>
                  <Text
                    variant='regular'
                    color={Color.neutral._80}
                    size={15}
                    text={`across ${
                      programCreditEstimates.length === 1
                        ? 'one tax credit'
                        : 'two tax credits'
                    }`}
                  />
                </Flex>
                <Divider variant={'no-bottom-margin'} />
                <Flex
                  direction={'row'}
                  justifyItems={'flex-end'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  gap={48}
                >
                  {individualProgramEstimates()}
                </Flex>
                <Flex className={classes.alert} gap={8} padding={16}>
                  <Text text={'*'} tag={'span'} size={13} />
                  <Text
                    size={13}
                    tag={'span'}
                    text={
                      "Estimates are non-binding, and may change based on your company's growth and R&D activities throughout the year."
                    }
                  />
                </Flex>
                <Flex
                  direction={'row'}
                  justifyContent={'flex-end'}
                  padding={[0, 16]}
                >
                  <Button
                    label={'Continue'}
                    onClick={onSubmit}
                    loading={isContinueBtnLoading}
                    flexAlignSelf={'flex-end'}
                    className={classes.continueBtn}
                  />
                  {error && (
                    <>
                      <Button
                        label='Back to dashboard'
                        dataTestId='error-back-to-dashboard'
                        flexAlignSelf={'flex-end'}
                        onClick={() => history.push(`/${Page.dashboard}`)}
                      />
                    </>
                  )}
                </Flex>
                {error && (
                  <Text text={error} status='failed' textAlign={'center'} />
                )}
              </Flex>
            </Animate>
          )}
        </div>
      )}
    </>
  );
});
