import * as React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ palette }: Theme) => ({
  link: {
    color: palette.secondary.main,
    textDecoration: 'none',
    textTransform: 'none',
    fontWeight: 400,
    '&:hover': {
      textDecoration: 'underline',
      background: 'none',
    },
  },
}));

interface ButtonLinkProps {
  text: string;
  onClick: () => void;
}

export const ButtonLink = ({ text, onClick }: ButtonLinkProps) => {
  const classes = useStyles();

  return (
    <Button className={classes.link} onClick={onClick}>
      {text}
    </Button>
  );
};
