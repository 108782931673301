import * as React from 'react';
import ErrorPage from './ErrorPage';

interface ErrorMsgDictionary {
  [key: number]: string;
}

const errorMsgDictionary: ErrorMsgDictionary = {
  404: "We couldn't find the page you were looking for.",
  500: 'Something went wrong. If this persists, please reach out to support@mainstreet.com for assistance.',
};

type HttpErrorProps = {
  errorCode?: number;
  errorMsg?: string;
};

const HttpErrorPage = ({ errorCode, errorMsg }: HttpErrorProps) => {
  // If we have no value, display 500 as it is more generic than a 404
  if (errorCode && !errorMsgDictionary[errorCode]) {
    errorCode = 500;
  }

  // if no code was given at all, default to 404
  if (!errorCode) {
    errorCode = 404;
  }

  return (
    <ErrorPage
      errorTitle={String(errorCode)}
      errorMsg={errorMsg || errorMsgDictionary[errorCode]}
    />
  );
};

export default HttpErrorPage;
