import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Collapse,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { SectionCard } from 'components/SectionCard';

const useStyles = makeStyles({
  title: {
    fontSize: '14px',
  },
  description: {
    marginTop: '10px',
    fontSize: '14px',
    marginLeft: '26px',
  },
  clickable: {
    cursor: 'pointer',
  },
});

const RightArrow = () => (
  <svg
    width='10'
    height='12'
    viewBox='0 0 10 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.57084 5.14251C9.21818 5.53091 9.21818 6.46909 8.57084 6.85749L1.5145 11.0913C0.847971 11.4912 -4.81311e-07 11.0111 -4.47334e-07 10.2338L-7.72026e-08 1.76619C-4.3226e-08 0.988895 0.847971 0.508782 1.5145 0.908697L8.57084 5.14251Z'
      fill='#333333'
    />
  </svg>
);

export interface StepExpanderProps {
  steps: { title: string; description: string }[];
}

export const StepExpander = ({ steps }: StepExpanderProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(steps.map(() => false));

  const toggleExpansionByIndex = (stepIndex: number) => {
    setExpanded(
      expanded.map((item, expandedIndex) =>
        expandedIndex === stepIndex ? !item : item,
      ),
    );
  };

  return (
    <SectionCard square style={{ marginTop: 0 }}>
      <Grid container>
        {steps.map((step, stepIndex) => {
          return (
            <div key={stepIndex}>
              <Grid
                item
                xs={12}
                className={classes.clickable}
                onClick={() => toggleExpansionByIndex(stepIndex)}
              >
                <Box padding={2}>
                  <Grid container alignItems='center'>
                    <Grid item>
                      <Box marginRight={2}>
                        <RightArrow />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.title}>
                        {step.title}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Collapse
                    in={expanded[stepIndex]}
                    timeout='auto'
                    unmountOnExit
                  >
                    <Box marginTop={1}>
                      <Typography className={classes.description}>
                        {step.description}
                      </Typography>
                    </Box>
                  </Collapse>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
            </div>
          );
        })}
      </Grid>
    </SectionCard>
  );
};
