import React from 'react';
import { Text } from 'component-library';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((colors: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  sectionHeader: {
    borderBottom: '1px solid',
    borderBottomColor: colors.lighterGrey,
  },
}));

export const ClientReviewStepFAQ = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Text
        variant={'medium'}
        className={classes.sectionHeader}
        paddingBottom={8}
      >
        What&apos;s this step?
      </Text>
      <Text paddingBottom={8}>
        After receiving your R&D credit forms from MainStreet, you{' '}
        <strong>must</strong> file them with your business income taxes.
      </Text>
      <Text paddingBottom={8}>
        Be sure to give your tax preparer a heads up that this form is coming!
        It&apos;s the only way to redeem your credits.
      </Text>

      <Text
        variant={'medium'}
        className={classes.sectionHeader}
        paddingBottom={8}
      >
        Timeline
      </Text>
      <Text>
        You&apos;ll need to file Form 6765 alongside your business income taxes.
      </Text>
      <Text>
        Most of our customers like you file between March and April 15th.
      </Text>
    </div>
  );
};
