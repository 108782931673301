import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Color } from 'component-library';

const useStyles = makeStyles(() => ({
  checkmark: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'block',
    strokeWidth: 5,
    stroke: Color.semantic.$success50,
    strokeMiterlimit: 10,
    boxShadow: 'inset 0px 0px 0px #7ac142',
    animation:
      'fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both',
  },
  checkmarkCircle: {
    strokeDasharray: 166,
    strokeDashoffset: 166,
    strokeWidth: 5,
    strokeMiterlimit: 10,
    stroke: Color.semantic.$success50,
    fill: 'none',
    animation: 'stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },
  checkmarkCheck: {
    transformOrigin: '50% 50%',
    strokeDasharray: 48,
    strokeDashoffset: 48,
    animation: 'stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards',
  },
  '@global': {
    '@keyframes stroke': {
      '100%': {
        strokeDashoffset: 0,
      },
    },
    '@keyframes scale': {
      '0%': {
        transform: 'none',
      },
      '50%': {
        transform: 'scale3d(1.1, 1.1, 1)',
      },
      '100%': {
        transform: 'none',
      },
    },
    '@keyframes fill': {
      '100%': {
        boxShadow: 'inset 0px 0px 0px 30px #fff',
      },
    },
  },
}));

interface Props {
  className?: string;
}

const CheckmarkAnimated = ({ className }: Props) => {
  const classes = useStyles();

  return (
    <svg
      className={`${className} ${classes.checkmark} `}
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 52 52'
    >
      <circle
        className={classes.checkmarkCircle}
        cx='26'
        cy='26'
        r='25'
        fill='none'
      />
      <path
        className={classes.checkmarkCheck}
        fill='none'
        d='M14.1 27.2l7.1 7.2 16.7-16.8'
      />
    </svg>
  );
};

export default CheckmarkAnimated;
