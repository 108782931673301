import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import {
  Button,
  Flex,
  Text,
  Color,
  Link,
  Card,
  CardHeader,
  Content,
  IconEnum,
  Icon,
} from 'component-library';
import TalkToAnExpertButton from '../../../../components/util/TalkToAnExpertButton';
import { useFeatureFlags } from '../../../../stores/useStores';
import { ClaimBanner } from '../unified-tax-credits/components';

const useStyles = makeStyles(() => ({
  root: {
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: '1024px',
    boxSizing: 'border-box',
  },
  headerText: {
    whiteSpace: 'nowrap',
  },
  comingSoonButton: {
    border: `2px solid ${Color.green._50}`,
    borderRadius: '4px',
    '& > p': {
      textTransform: 'uppercase',
      color: Color.green._60,
    },
  },
  comingSoonText: {
    font: 'bold',
  },
  talkToAnExpertButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  claimBanner: {
    backgroundColor: '#e4f6e9',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 8,
  },
  claimBgAccent: {
    position: 'absolute',
    bottom: '-100px',
    left: '620px',
    backgroundColor: Color.green._60,
    borderRadius: '50%',
    height: '400px',
    width: '400px',
    zIndex: 0,
  },
  claimIllustration: {
    width: '28%',
  },
  claimButton: {},
  claimButtonLink: {
    color: 'white',
  },
  contentHeader: {
    backgroundColor: 'white',
  },
  contentDescription: { marginLeft: '30px' },
}));

export const SharedSavings = observer(() => {
  const classes = useStyles();
  const featureFlags = useFeatureFlags();

  const contentSavings = [
    {
      title: 'Educational Assistance',
      description:
        'Offer your team up to $5,250 annually in tax-free reimbursements for education, including student loans.',
    },
    {
      title: 'Remote Work Reimbursements',
      description:
        "Support your employees' home office needs with tax-free reimbursements for rent or mortgage interest, utilities, and more!",
    },
    {
      title: 'Connectivity Coverage',
      description:
        'Keep your team connected with tax-free payments for cell phone and Wi-Fi use, ensuring seamless communication.',
    },
    // {
    //   title: 'Affordable Credits',
    //   description:
    //     'Alleviate the burden of childcare costs with pre-tax spending and reimbursements, making life easier for working parents.',
    // },
    // {
    //   title: 'Charitable Giving',
    //   description:
    //     'Encourage and facilitate pre-tax charitable donations, adding purpose and engagement within your team.',
    // },
    // {
    //   title: 'Family Planning Support',
    //   description:
    //     'Access comprehensive family planning services with privacy and ease, offering significant lifetime savings.',
    // },
    {
      title: 'Professional Development',
      description:
        "Invest in your team's growth with tax-free access to upskilling resources, from software subscriptions to certifications.",
    },
    {
      title: 'And more!',
      description: 'The opportunities are endless with Shared Savings.',
    },
  ];

  return (
    <div className={classes.root} data-testid={'shared-savings'}>
      <Flex gap={16} alignItems={'center'}>
        <Text size={32} variant='medium' className={classes.headerText}>
          Shared Savings
        </Text>
        <Flex.Cell
          basis={240}
          justifySelf='center'
          className={classes.comingSoonButton}
          padding={[4, 12]}
        >
          <Text variant='medium' textAlign='center'>
            Coming soon
          </Text>
        </Flex.Cell>
        <Flex justifyContent={'flex-end'}>
          {' '}
          {featureFlags.showTalkToAnExpertButton && <TalkToAnExpertButton />}
        </Flex>
      </Flex>
      <Flex>
        {' '}
        <ClaimBanner
          title={<>Take home 5% more&nbsp;cash</>}
          subtitle={
            <>
              Give you and your employees the most out of every paycheck.
              Cost-neutral to your business to increase everyone&apos;s pay.
              It&apos;s a&nbsp;no-brainer.
            </>
          }
          claimBannerClassName={classes.claimBanner}
          claimBgAccentClassName={classes.claimBgAccent}
          illustrationClassName={classes.claimIllustration}
          buttonCta={
            <>
              <Button
                label={
                  <>
                    <Link
                      className={classes.claimButtonLink}
                      href={'https://mainstreet-work.typeform.com/2024-savings'}
                      external={true}
                    >
                      Sign up for early access
                    </Link>
                  </>
                }
                onClick={(): void => {
                  // Placeholder function - no action needed
                }}
                flexAlignSelf={'flex-start'}
              ></Button>
            </>
          }
        />
      </Flex>
      <Flex>
        <Card>
          <CardHeader
            title='Shared Savings coming your way'
            icon={
              <img
                src={`${process.env.PUBLIC_URL}/images/circle-dollar.svg`}
                alt='Icon'
              />
            }
            className={classes.contentHeader}
          />
          <Content flex paddingTopBottom={24} paddingLeftRight={24}>
            <Flex direction={'column'} gap={12}>
              {contentSavings.map((info, index) => (
                <React.Fragment key={index}>
                  <Flex alignItems={'center'} gap={8}>
                    <Icon
                      name={IconEnum.check}
                      color={Color.green._60}
                      size={23}
                    />
                    <Text text={info.title} variant={'medium'} size={18} />
                  </Flex>
                  <Flex className={classes.contentDescription}>
                    <Text>{info.description}</Text>
                  </Flex>
                </React.Fragment>
              ))}
            </Flex>
          </Content>
        </Card>
      </Flex>
      <Flex>
        <Text size={15}>
          Ready to transform your savings experience?&nbsp;&nbsp;
        </Text>
        <Link
          href={'https://mainstreet-work.typeform.com/2024-savings'}
          external={true}
        >
          Sign up now for early access
        </Link>
      </Flex>
    </div>
  );
});
