import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ActionRequiredPill } from 'pages/dashboard/integrations';
import { Text } from 'component-library';

const useStyles = makeStyles(({ darkerNavy }: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: darkerNavy,
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 400,
    color: darkerNavy,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));

export interface ConnectCardHeaderProps {
  title: string;
  subtitle?: string;
  actionRequired?: boolean;
}

export const ConnectCardHeader = (props: ConnectCardHeaderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Text variant='medium' size={18}>
          {props.title}
        </Text>
        {props.subtitle && <Text>{props.subtitle}</Text>}
      </div>
      {props.actionRequired && <ActionRequiredPill />}
    </div>
  );
};
