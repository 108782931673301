import React, { useContext, useEffect, useState } from 'react';
import { Card, Flex, Link, Text } from 'component-library';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { Document } from 'lib/interfaces';

interface ClientReviewStepDocumentListProps {
  programId: string;
}

export const ClientReviewStepDocumentList = ({
  programId,
}: ClientReviewStepDocumentListProps) => {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [documents, setDocuments] = useState<Document[]>([]);

  const { client } = useContext(Auth0FeatureContext);

  useEffect(() => {
    client
      .GetProgramDocuments(parseInt(programId, 10), 'mainstreet')
      .then((res: any) => {
        if (res.errorMsg) {
          setErrorMsg(res.errorMsg);
        }
        if (res.data) {
          const { documents } = res.data;
          if (documents.length > 0) {
            setDocuments(documents);
          }
        }
      });
  }, [client, programId, errorMsg]);

  const onClick = (id: number) => {
    client.GetDocumentUrl(id).then((res) => {
      if (res.errorMsg) {
        setErrorMsg(res.errorMsg);
        return;
      }

      if (res.data) {
        window.open(res.data.url, '_blank');
      }
    });
  };

  const renderDocument = (doc: Document, i: number) => {
    if (doc) {
      const lastDoc = i === documents.length - 1;
      return (
        <div key={i}>
          <Link
            onClick={() => onClick(doc.id)}
            text={doc.name || undefined}
            external
            variant='medium'
          />
          <Text
            text={doc.description}
            paddingBottom={lastDoc ? undefined : 16}
          />
        </div>
      );
    }
  };

  if (errorMsg) {
    return <Text status='failed' text={errorMsg} />;
  }

  return (
    <Card noMargin>
      <Flex direction='column' padding={24}>
        {documents.map((doc, i) => renderDocument(doc, i))}
      </Flex>
    </Card>
  );
};
