/**
 * This file contains deprecated aliases to the ServerClients methods. It exists
 * to aid in the transition to the new class. DO NOT add new methods here, rather
 * add them as instance methods to the class definitions
 */
import Stripe from 'stripe';
import {
  EmployeeTokenPurposeEnum,
  GraphCmsFaqGroupIdEnum,
  IssuedSurveyStatusEnum,
  ProductTypeEnum,
  SignupStageEnum,
  SurveyNameEnum,
} from 'lib/constants';
import {
  AcceptedByData,
  ClientUpdateProgramRequest,
  CmsFaqData,
  CompanyData,
  CreateCompanySelfSignupRequest,
  CreditCategory,
  CreditEstimateSummary,
  Document,
  EmployeeForEmployeeTable,
  FinancialInformation,
  FinchPayrollProvider,
  ImportType,
  IssuedSurvey,
  MagicMoneyDataOverrides,
  MSClientResponse,
  OrderForm,
  ProgramCreditEstimate,
  ProgramData,
  ProgramRdQA,
  ProjectData,
  SurveyResponse,
  UpdateCompanyDetailsInfoRequest,
  UpdateCompanyManualPayrollEstimateRequest,
  UpdateCompanyOperationsInfoRequest,
  UpdateCompanyPayrollSystemRequest,
  UpdateCompanyRequest,
  UpdateCompanyTaxInfoRequest,
  UpdateCompanyWithGapPayrollRequest,
  UpdateEmployeeDetailsInfoRequest,
  UpdatePasswordRequest,
  UpdateProjectRequest,
  UpdateWorkDoneRequest,
  ValidatedToken,
  WorkDoneData,
} from 'lib/interfaces';
import { EmptyClient } from './ServerClient';
import { StripePaymentMethod } from 'components/payments/v2/stripe/types';

// ========== Company ==========

/**
 * @deprecated Use the ServerClient version
 */
export const CreateCompanyAndLoginSelfSignup = async (
  req: CreateCompanySelfSignupRequest,
): Promise<MSClientResponse<{ company: CompanyData }>> =>
  EmptyClient.CreateCompanyAndLoginSelfSignup(req);

/**
 * @deprecated Use the ServerClient version
 */
export const MoveCompanyToNextOnboardingStep = async (
  nextStep: SignupStageEnum,
): Promise<MSClientResponse<{ company: CompanyData }>> =>
  EmptyClient.MoveCompanyToNextOnboardingStep(nextStep);

/**
 * @deprecated Use the ServerClient version
 */
export const LoginWithGoogle = () => EmptyClient.LoginWithGoogle();

/**
 * @deprecated Use the ServerClient version
 */
export const VerifyGoogleLogin = (): Promise<
  MSClientResponse<{ company: CompanyData }>
> => EmptyClient.VerifyGoogleLogin();

/**
 * @deprecated Use the ServerClient version
 */
export const UpdateCompany = (
  req: UpdateCompanyRequest,
): Promise<MSClientResponse<{ company: CompanyData; updated: boolean }>> =>
  EmptyClient.UpdateCompany(req);

/**
 * @deprecated Use the ServerClient version
 */
export const UpdateCompanyOperationsInfo = (
  req: UpdateCompanyOperationsInfoRequest,
): Promise<
  MSClientResponse<{
    company: CompanyData;
    hasQualifyingPrograms: boolean;
  }>
> => EmptyClient.UpdateCompanyOperationsInfo(req);

/**
 * @deprecated Use the ServerClient version
 */
export const GetDisplayableCreditEstimates = (
  taxYear: number,
): Promise<
  MSClientResponse<{
    programCreditEstimates: ProgramCreditEstimate[];
    hasQualifyingRdPrograms: boolean;
  }>
> => EmptyClient.GetDisplayableCreditEstimates(taxYear);

/**
 * @deprecated Use the ServerClient version
 */
export const OptedInSelection = (
  programId: number,
  selection: Record<ProductTypeEnum, { optedIn: boolean }>,
): Promise<
  MSClientResponse<{
    creditEstimateSummary: CreditEstimateSummary;
  }>
> => EmptyClient.OptedInSelection(programId, selection);

/**
 * @deprecated Use the ServerClient version
 */
export const MagicMoneyEstimates = (
  taxYear: number,
  overrides: MagicMoneyDataOverrides,
): Promise<
  MSClientResponse<{
    creditEstimateSummary: CreditEstimateSummary;
  }>
> => EmptyClient.MagicMoneyEstimates(taxYear, overrides);

/**
 * @deprecated Use the ServerClient version
 */
export const UpdateCompanyTaxInfo = (
  req: UpdateCompanyTaxInfoRequest,
): Promise<
  MSClientResponse<{
    company: CompanyData;
  }>
> => EmptyClient.UpdateCompanyTaxInfo(req);

/**
 * @deprecated Use the ServerClient version
 */
export const RequestPayroll = (
  payrollSystem: string,
  token?: string,
  email?: string,
): Promise<MSClientResponse<{ company: CompanyData }>> =>
  EmptyClient.RequestPayroll(payrollSystem, token, email);

/**
 * @deprecated Use the ServerClient version
 */
export const PostValidatePassword = (
  password: string,
): Promise<MSClientResponse<{ valid: boolean; errorMsg?: string }>> =>
  EmptyClient.PostValidatePassword(password);

/**
 * @deprecated Use the ServerClient version
 */
export const AddToWaitlist = (
  email: string,
  creditCategories: CreditCategory[],
  firstName: string,
  lastName: string,
  companyName: string,
): Promise<MSClientResponse<object>> =>
  EmptyClient.AddToWaitlist(
    email,
    creditCategories,
    firstName,
    lastName,
    companyName,
  );

/**
 * @deprecated Use the ServerClient version
 */
export const UpdateCompanyPayrollSystem = (
  req: UpdateCompanyPayrollSystemRequest,
): Promise<
  MSClientResponse<{
    company: CompanyData;
  }>
> => EmptyClient.UpdateCompanyPayrollSystem(req);

// ========== Session ==========

/**
 * @deprecated Use the ServerClient version
 */
export const Login = (
  email: string,
  password: string,
): Promise<MSClientResponse<{ company: CompanyData }>> =>
  EmptyClient.Login(email, password);

/**
 * @deprecated Use the ServerClient version
 */
export const Logout = (): Promise<boolean> => EmptyClient.Logout();

/**
 * @deprecated Use the ServerClient version
 */
export const CurrentLoggedInCompany = async (): Promise<CompanyData | null> =>
  EmptyClient.CurrentLoggedInCompany();

/**
 * @deprecated Use the ServerClient version
 */
export const UpdatePassword = (
  req: UpdatePasswordRequest,
): Promise<{ success: boolean; errorMsg?: string }> =>
  EmptyClient.UpdatePassword(req);

// ========== Token ==========

/**
 * @deprecated Use the ServerClient version
 */
export const GenerateResetPasswordEmail = async (
  email: string,
): Promise<{ errorMsg?: string }> =>
  EmptyClient.GenerateResetPasswordEmail(email);

/**
 * @deprecated Use the ServerClient version
 */
export const ValidateResetPassToken = async (
  props: ValidatedToken,
): Promise<MSClientResponse<{ valid: boolean }>> =>
  EmptyClient.ValidateResetPassToken(props);

// ========== Documents ==========

/**
 * @deprecated Use the ServerClient version
 */
export const UploadCompanyDocument = (
  companyId: number,
  file: File | Blob,
  name: string,
  desc: string,
): Promise<MSClientResponse<{ document: Document }>> =>
  EmptyClient.UploadCompanyDocument(companyId, file, name, desc);

// ========== Qualification Flow ==========

/**
 * @deprecated Use the ServerClient version
 */
export const UpdateCompanyWithSurveyResponse = (
  companyId: number,
  surveyName: SurveyNameEnum,
  surveyResponse: SurveyResponse,
): Promise<MSClientResponse<{ updatedCompany: CompanyData }>> =>
  EmptyClient.UpdateCompanyWithSurveyResponse(
    companyId,
    surveyName,
    surveyResponse,
  );

// ========== Billing ==========

/**
 * @deprecated Use the ServerClient version
 */
export const GetDefaultPaymentMethod = (
  stripeCustomerId: string,
  signal?: AbortSignal,
): Promise<
  MSClientResponse<{ defaultPaymentMethod: StripePaymentMethod | null }>
> => EmptyClient.GetDefaultPaymentMethod(stripeCustomerId, signal);

/**
 * @deprecated Use the ServerClient version
 */
export const GetPaymentMethods = (
  stripeCustomerId: string,
  signal?: AbortSignal,
): Promise<MSClientResponse<{ paymentMethods: StripePaymentMethod[] }>> =>
  EmptyClient.GetPaymentMethods(stripeCustomerId, signal);

/**
 * @deprecated Use the ServerClient version
 */
export const SetDefaultPaymentMethod = (
  stripeCustomerId: string,
  paymentMethodId: string,
): Promise<
  MSClientResponse<{ defaultPaymentMethod: StripePaymentMethod | null }>
> => EmptyClient.SetDefaultPaymentMethod(stripeCustomerId, paymentMethodId);

/**
 * @deprecated Use the ServerClient version
 */
export const GetInvoices = (
  stripeCustomerId: string,
  signal?: AbortSignal,
): Promise<MSClientResponse<{ invoices: Stripe.Invoice[] }>> =>
  EmptyClient.GetInvoices(stripeCustomerId, signal);

/**
 * @deprecated Use the ServerClient version
 */
export const SetupPaymentIntent = (
  stripeCustomerId: string,
): Promise<MSClientResponse<{ secret: string }>> =>
  EmptyClient.SetupPaymentIntent(stripeCustomerId);

/**
 * @deprecated Use the ServerClient version
 */
export const DeletePaymentMethod = (
  paymentMethodId: string,
): Promise<MSClientResponse<object>> =>
  EmptyClient.DeletePaymentMethod(paymentMethodId);

/**
 * @deprecated Use the ServerClient version
 */
export const GetProgramsWithBillingSchedules = (
  companyId: number,
  signal?: AbortSignal,
): Promise<
  MSClientResponse<{
    programs: ProgramData[];
  }>
> => EmptyClient.GetProgramsWithBillingSchedules(companyId, signal);

/**
 * @deprecated Use the ServerClient version
 */
export const CreateStripeOauthState = (
  companyId: number,
): Promise<MSClientResponse<{ state: string }>> =>
  EmptyClient.CreateStripeOauthState(companyId);

/**
 * @deprecated Use the ServerClient version
 */
export const GetConnectedAccountDashboardLink = (
  companyId: number,
): Promise<
  MSClientResponse<{
    url: string;
  }>
> => EmptyClient.GetConnectedAccountDashboardLink(companyId);

/**
 * @deprecated Use the ServerClient version
 */
export const GetConnectedAccountDetails = (
  companyId: number,
): Promise<
  MSClientResponse<{
    bankName: string | null;
    hasDebitCardLinked: boolean;
  }>
> => EmptyClient.GetConnectedAccountDetails(companyId);

/**
 * @deprecated Use the ServerClient version
 */
export const InitiateStripePayout = (
  companyId: number,
  amountCents: number,
): Promise<MSClientResponse<object>> =>
  EmptyClient.InitiateStripePayout(companyId, amountCents);

/**
 * @deprecated Use the ServerClient version
 */
export const GetAdvanceCreditAvailableBalance = (
  companyId: number,
): Promise<
  MSClientResponse<{
    balanceCents: number;
  }>
> => EmptyClient.GetAdvanceCreditAvailableBalance(companyId);

// ========== Order ==========

/**
 * @deprecated Use the ServerClient version
 */
export const GetOrCreateOrderFormData = (
  programId: number,
): Promise<
  MSClientResponse<{
    order: OrderForm;
  }>
> => EmptyClient.GetOrCreateOrderFormData(programId);

/**
 * @deprecated Use the ServerClient version
 */
export const AcceptOrderForm = (
  programId: number,
  acceptedBy: AcceptedByData,
): Promise<MSClientResponse<object>> =>
  EmptyClient.AcceptOrderForm(programId, acceptedBy);

/**
 * @deprecated Use the ServerClient version
 */
export const GetBenefitStartMonth = (
  programId: number,
): Promise<
  MSClientResponse<{
    benefitStartMonth: number;
  }>
> => EmptyClient.GetBenefitStartMonth(programId);

/**
 * @deprecated Use the ServerClient version
 */
export const GetOrdersByYear = (
  taxYear: number,
): Promise<
  MSClientResponse<{
    orderForms: OrderForm[];
  }>
> => EmptyClient.GetOrdersByYear(taxYear);

// ========== Program ==========

/**
 * @deprecated Use the ServerClient version
 */
export const GetProgram = (
  programId: string | number,
): Promise<
  MSClientResponse<{
    program: ProgramData;
  }>
> => EmptyClient.GetProgram(programId);

/**
 * @deprecated Use the ServerClient version
 */
export const UpdateProgram = (
  programId: string | number,
  req: ClientUpdateProgramRequest,
): Promise<
  MSClientResponse<{
    updatedProgram: ProgramData;
  }>
> => EmptyClient.UpdateProgram(programId, req);

// ========== Project ==========
/**
 * @deprecated Use the ServerClient version
 */
export const UpdateProject = (
  projectId: number,
  req: UpdateProjectRequest,
): Promise<
  MSClientResponse<{
    project: ProjectData;
  }>
> => EmptyClient.UpdateProject(projectId, req);

/**
 * @deprecated Use the ServerClient version
 */
export const UpdateExpenseClassification = (
  updates: UpdateWorkDoneRequest[],
): Promise<
  MSClientResponse<{
    updatedWorkDone: WorkDoneData[];
  }>
> => EmptyClient.UpdateExpenseClassification(updates);

// ======== RD Verification =======

/**
 * @deprecated Use the ServerClient version
 */
export const UpdateGeneralRDQuestions = async (
  programId: number,
  programRdQA: ProgramRdQA[],
  financialInformation: FinancialInformation,
  moveToNextStep: boolean,
): Promise<MSClientResponse<{ programData: ProgramData }>> =>
  EmptyClient.UpdateGeneralRDQuestions(
    programId,
    programRdQA,
    financialInformation,
    moveToNextStep,
  );

// ================ Config ========================

/**
 * @deprecated Use the ServerClient version
 */
export const GetConfigFromTable = (
  name: string,
): Promise<MSClientResponse<{ name: string; data: any }>> =>
  EmptyClient.GetConfigFromTable(name);

// ================ Service Tokens ========================

/**
 * @deprecated Use the ServerClient version
 */
export const StoreFinchServiceToken = async (
  code: string,
  importType: ImportType,
  token?: string,
  email?: string,
  taxYear?: number,
): Promise<MSClientResponse<{ isManualToken: boolean }>> =>
  EmptyClient.StoreFinchServiceToken(code, importType, token, email, taxYear);

/**
 * @deprecated Use the ServerClient version
 */
export const FinchLinkedServices = (
  token?: string,
  email?: string,
): Promise<
  MSClientResponse<{
    services: FinchPayrollProvider[];
    expiredServices: FinchPayrollProvider[];
  }>
> => EmptyClient.FinchLinkedServices(token, email);

/**
 * @deprecated Use the ServerClient version
 */
export const FinchTokenStatus = (): Promise<
  MSClientResponse<{ hasManualToken: boolean; hasToken: boolean }>
> => EmptyClient.FinchTokenStatus();

// ================ Payroll Admin Tokens ========================
/**
 * @deprecated Use the ServerClient version
 */
export const GetPayrollAdminLink = (
  companyId: number,
): Promise<MSClientResponse<{ link: string }>> =>
  EmptyClient.GetPayrollAdminLink(companyId);

/**
 * @deprecated Use the ServerClient version
 */
export const GeneratePayrollAdminLink = (
  companyId: number,
): Promise<MSClientResponse<{ link: string }>> =>
  EmptyClient.GeneratePayrollAdminLink(companyId);

/**
 * @deprecated Use the ServerClient version
 */
export const SendPayrollAdminLink = (
  companyId: number,
  email: string,
  link: string,
): Promise<MSClientResponse<object>> =>
  EmptyClient.SendPayrollAdminLink(companyId, email, link);

/**
 * @deprecated Use the ServerClient version
 */
export const ValidatePayrollAdminLink = (
  token: string,
  email: string,
): Promise<
  MSClientResponse<{
    payrollSystem: string;
    companyName: string;
  }>
> => EmptyClient.ValidatePayrollAdminLink(token, email);

// ================ Document Access Link ========================
/**
 * @deprecated Use the ServerClient version
 */
export const GetDocumentAccessLink = (
  companyId: number,
  programId: number,
  taxYear?: number,
): Promise<MSClientResponse<{ link: string }>> =>
  EmptyClient.GetDocumentAccessLink(companyId, programId, taxYear);

/**
 * @deprecated Use the ServerClient version
 */
export const GenerateDocumentAccessLink = (
  companyId: number,
  programId: number,
  taxYear?: number,
): Promise<MSClientResponse<{ link: string }>> =>
  EmptyClient.GenerateDocumentAccessLink(companyId, programId, taxYear);

// ==== Cache testing =====

/**
 * @deprecated Use the ServerClient version
 */
export const CacheGet = (
  key: string,
): Promise<MSClientResponse<{ value: string | null }>> =>
  EmptyClient.CacheGet(key);

/**
 * @deprecated Use the ServerClient version
 */
export const CacheSet = (
  key: string,
  value: string,
): Promise<MSClientResponse<{ key: string; value: string }>> =>
  EmptyClient.CacheSet(key, value);

// ==== Accountant Link Access ====
/**
 * @deprecated Use the ServerClient version
 */
export const ValidateAccountantAccessLink = (
  programId: number,
  email: string,
  token: string,
): Promise<
  MSClientResponse<{
    programData: ProgramData;
    companyName: string;
  }>
> => EmptyClient.ValidateAccountantAccessLink(programId, email, token);

// ====== Coupons ======

/**
 * @deprecated Use the ServerClient version
 */
export const UnusedCoupon = (
  intent: string,
): Promise<MSClientResponse<{ success: boolean }>> =>
  EmptyClient.UnusedCoupon(intent);

/**
 * @deprecated Use the ServerClient version
 */
export const GetCompanyIssuedSurvey = (
  issuedSurveyId: number,
): Promise<MSClientResponse<{ issuedSurvey: IssuedSurvey }>> =>
  EmptyClient.GetCompanyIssuedSurvey(issuedSurveyId);

/**
 * @deprecated Use the ServerClient version
 */
export const UpdateCompanyIssuedSurvey = (
  companyId: number,
  surveyName: SurveyNameEnum,
  status: IssuedSurveyStatusEnum,
): Promise<MSClientResponse<object>> =>
  EmptyClient.UpdateCompanyIssuedSurvey(companyId, surveyName, status);

// ====== Employee Magic Links ======

/**
 * @deprecated Use the ServerClient version
 */
export const GetEmployeeMagicLinkPageData = (
  companyId: number,
  employeeId: number,
  tokenPurpose: string,
  token: string,
): Promise<
  MSClientResponse<{
    questionData?: Record<string, any>;
    employeeData: { name: string; companyName: string };
  }>
> =>
  EmptyClient.GetEmployeeMagicLinkPageData(
    companyId,
    employeeId,
    tokenPurpose,
    token,
  );

/**
 * @deprecated Use the ServerClient version
 */
export const SaveEmployeeMagicLinkResponse = (
  companyId: number,
  employeeId: number,
  tokenPurpose: string,
  token: string,
  employeeResponse: Record<string, any>,
): Promise<MSClientResponse<object>> =>
  EmptyClient.SaveEmployeeMagicLinkResponse(
    companyId,
    employeeId,
    tokenPurpose,
    token,
    employeeResponse,
  );

/**
 * @deprecated Use the ServerClient version
 */
export const GetEmployeeTableData = (
  companyId: number,
  taxYear: number,
  purpose: EmployeeTokenPurposeEnum,
): Promise<MSClientResponse<{ employees: EmployeeForEmployeeTable[] }>> =>
  EmptyClient.GetEmployeeTableData(companyId, taxYear, purpose);

/**
 * @deprecated Use the ServerClient version
 */
export const SaveEmployeePrimaryEmailAddress = (
  employmentRecordId: number,
  primaryEmailAddress: string,
): Promise<MSClientResponse<object>> =>
  EmptyClient.SaveEmployeePrimaryEmailAddress(
    employmentRecordId,
    primaryEmailAddress,
  );

/**
 * @deprecated Use the ServerClient version
 */
export const UpdateCompanyDetailsInfo = (
  req: UpdateCompanyDetailsInfoRequest,
): Promise<
  MSClientResponse<{
    company: CompanyData;
    hasQualifyingPrograms: boolean;
  }>
> => EmptyClient.UpdateCompanyDetailsInfo(req);

/**
 * @deprecated Use the ServerClient version
 */
export const UpdateEmployeeDetailsInfo = (
  req: UpdateEmployeeDetailsInfoRequest,
): Promise<
  MSClientResponse<{
    company: CompanyData;
    hasQualifyingPrograms: boolean;
  }>
> => EmptyClient.UpdateEmployeeDetailsInfo(req);

/**
 * @deprecated Use the ServerClient version
 */
export const UpdateCompanyManualPayrollEstimate = (
  req: UpdateCompanyManualPayrollEstimateRequest,
): Promise<MSClientResponse<object>> =>
  EmptyClient.UpdateCompanyManualPayrollEstimate(req);

/**
 * @deprecated Use the ServerClient version
 */
export const UpdateCompanyWithGapPayroll = (
  req: UpdateCompanyWithGapPayrollRequest,
): Promise<MSClientResponse<object>> =>
  EmptyClient.UpdateCompanyWithGapPayroll(req);

// ====== FAQs ======
/**
 * @deprecated Use the ServerClient version
 */
export const GetFaqsByFaqGroupId = (
  groupId: GraphCmsFaqGroupIdEnum,
): Promise<
  MSClientResponse<{
    faqData: CmsFaqData[];
  }>
> => EmptyClient.GetFaqsByFaqGroupId(groupId);

// ===== Plaid =======
/**
 * @deprecated Use the ServerClient version
 */
export const PlaidLinkToken: () => Promise<
  MSClientResponse<{ link_token: string }>
> = () => EmptyClient.GeneratePlaidLinkToken();

// ====== High Yield Savings Account ======
/**
 * @deprecated Use the ServerClient version
 */
export const HysaRegister = (
  public_token: string,
  metadata: any,
): Promise<MSClientResponse<unknown>> =>
  EmptyClient.PlaidRegistration(public_token, metadata);
