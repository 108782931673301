import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Switch, Route, useLocation } from 'react-router-dom';
import { animated } from 'react-spring';
import { Animate, Color } from 'component-library';
import TitleHeader from 'components/TitleHeader';
import { observer } from 'mobx-react';
import { useAccountCreationStores } from 'stores/useStores';
import { useSpringPageLocationTransition } from 'lib/useSpringPageTransition';
import { OnboardingLandingPage } from 'pages/onboarding/components/OnboardingLandingPage';
import { AccountStepContainer } from './component/AccountStepContainer';
import { useAccountCreationRoutes } from './component/useAccountCreationRoutes';
import { AccountCreationStepsIndex, Page } from 'lib/constants';
import { ProgramsStep } from './steps';

const useStyles = makeStyles(({ breakpoints }) => ({
  '@global': {
    [breakpoints.down('xs')]: {
      '.embeddedServiceHelpButton': {
        display: 'none',
      },
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100vh',
    backgroundColor: Color.neutral.white,
    boxSizing: 'border-box',
    [breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  mainContent: {
    maxWidth: '1200px',
    width: '100%',
    height: 730,
    margin: '0 auto',
    padding: '0 24px',
    boxSizing: 'border-box',
    position: 'relative',
    [breakpoints.down('sm')]: {
      height: '100%',
      padding: 0,
    },
  },
  programsStepPage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0',
    padding: '0',
    zIndex: 1,
  },
  animateContainer: { height: '100%' },
  overlay: {
    position: 'absolute',
    zIndex: 99,
    width: 'calc(100% - 48px)',
    height: '100%',
    pointerEvents: 'none',

    '& > div': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      padding: '0 24px',
      backgroundColor: Color.neutral.white,
      boxSizing: 'border-box',
      boxShadow: Color.shadow.low,
    },
  },
}));

export const AccountCreation = observer(() => {
  const classes = useStyles();
  const location = useLocation();
  const { accountCreation } = useAccountCreationStores();
  const { accountCreationRoutes } = useAccountCreationRoutes();
  const { transitions } = useSpringPageLocationTransition(
    location,
    accountCreation.currentStep,
    accountCreation.previousStep,
    true, // useFade
  );

  useEffect(() => {
    accountCreation.setCurrentStepOnLoad(location.pathname);
  }, [accountCreation, location.pathname]);

  const getContent = () => {
    switch (accountCreation.currentStep) {
      case AccountCreationStepsIndex.LANDING_PAGE:
        return (
          <OnboardingLandingPage
            onSubmit={() => accountCreation.getStarted()}
          />
        );
      case AccountCreationStepsIndex.PROGRAMS:
        return (
          <ProgramsStep
            pathTo={`/${Page.transitionToDashboard}`}
            onNext={() =>
              accountCreation.setCurrentStep(AccountCreationStepsIndex.PROGRAMS)
            }
            pathFrom={`/${Page.accountCreationTaxes}`}
          />
        );
      default:
        return (
          <Animate
            enter={'fade-in'}
            delay={0.75}
            duration={0.75}
            className={classes.animateContainer}
          >
            <AccountStepContainer>
              {transitions((props, item) => {
                return (
                  <animated.div style={props}>
                    <Switch location={item}>
                      {accountCreationRoutes.map((item, key) => {
                        return (
                          <Route
                            exact={item.exact}
                            key={key}
                            path={item.path}
                            render={() => item.component}
                          />
                        );
                      })}
                    </Switch>
                  </animated.div>
                );
              })}
            </AccountStepContainer>
          </Animate>
        );
    }
  };

  return (
    <div className={classes.container}>
      <Animate enter={['fade-in', 'from-top']}>
        <TitleHeader title='Getting started' />
      </Animate>
      <div
        className={
          accountCreation.currentStep === AccountCreationStepsIndex.PROGRAMS
            ? classes.programsStepPage
            : classes.mainContent
        }
      >
        {accountCreation.fadeOut && (
          <Animate
            className={classes.overlay}
            enter='fade-in'
            exit='fade-out'
            exitDelay={0.75}
            duration={0.25}
            unmountOnExit
          >
            <div />
          </Animate>
        )}
        {getContent()}
      </div>
    </div>
  );
});
