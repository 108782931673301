import { Button, Text, Color } from 'component-library';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { PaymentMethodType, SelectionStatus } from 'components/PaymentMethod';
import { BillingDefaultPaymentMethod } from '@mainstreet/client-models/financial/payments/paymentsModels';
import { BankAccount } from '@mainstreet/client-models/financial/banking/bankingModels';

const useStyles = makeStyles({
  button: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

interface DisplayControlProps {
  paymentMethodState: {
    paymentType: PaymentMethodType;
    selectionStatus: SelectionStatus;
    addNewCard: boolean;
  };
  handleChangeSelection: () => void;
  handleChangePaymentType: () => void;
  cancelAddNewCard: () => void;
  defaultPaymentMethod: BillingDefaultPaymentMethod | null | undefined;
  plaidAccounts: BankAccount[];
}

/**
 * Manages the control text and functionality in the upper right hand corner of the card
 * to allow switching between cards and banks, and for canceling out of certain actions.
 */
export default function DisplayControl(props: DisplayControlProps) {
  const classes = useStyles();
  const bankIsDefault = props.defaultPaymentMethod?.associatedAccount;
  const cardIsDefault = props.defaultPaymentMethod?.associatedCard;
  if (props.paymentMethodState.selectionStatus === 'default') {
    if (props.paymentMethodState.paymentType === 'bank' && !bankIsDefault) {
      // If displaying "Add a bank account", provide option to switch to using credit cards
      return (
        <Button
          className={classes.button}
          label={
            <Text
              text='Pay with a credit card instead'
              variant='medium'
              color={Color.blue._60}
            />
          }
          variant='tertiary'
          onClick={props.handleChangePaymentType}
          dataTestId='change-to-credit-card'
        />
      );
    } else if (
      props.paymentMethodState.paymentType === 'bank' &&
      bankIsDefault
    ) {
      // If displaying bank as a default payment method, provide "change" text to go to the
      // account select dropdown.
      return (
        <Button
          className={classes.button}
          label={<Text text='Change' variant='medium' color={Color.blue._60} />}
          variant='tertiary'
          onClick={props.handleChangeSelection}
          dataTestId='change-ach-account'
        />
      );
    }
    if (props.paymentMethodState.paymentType === 'card' && !cardIsDefault) {
      // If initial "Add a card" view is displayed, provide ability to switch to bank account
      return (
        <Button
          className={classes.button}
          label={
            <Text
              text='Pay using a bank account instead'
              variant='medium'
              color={Color.blue._60}
            />
          }
          variant='tertiary'
          onClick={props.handleChangePaymentType}
          dataTestId='change-to-bank-account'
        />
      );
    } else if (
      props.paymentMethodState.paymentType === 'card' &&
      cardIsDefault
    ) {
      // If displaying a card as current default payment method, provide "Change" text to
      // go to select a card dropdown and "Add another card" option.
      return (
        <Button
          className={classes.button}
          label={<Text text='Change' variant='medium' color={Color.blue._60} />}
          variant='tertiary'
          onClick={props.handleChangeSelection}
          dataTestId='change-credit-card'
        />
      );
    }
  } else if (props.paymentMethodState.selectionStatus === 'selecting') {
    if (props.paymentMethodState.paymentType === 'bank') {
      // If viewing bank selector view, provide "pay with credit card" option.
      return (
        <Button
          className={classes.button}
          label={
            <Text
              text='Pay with a credit card instead'
              variant='medium'
              color={Color.blue._60}
            />
          }
          variant='tertiary'
          onClick={props.handleChangePaymentType}
          dataTestId='change-to-credit-card'
        />
      );
    } else if (props.paymentMethodState.paymentType === 'card') {
      if (props.paymentMethodState.addNewCard) {
        // if Currently in view to add a new card, provide "Cancel" text to revert back
        // to Select a card view.
        return (
          <Button
            className={classes.button}
            label={
              <Text text='Cancel' variant='medium' color={Color.blue._60} />
            }
            variant='tertiary'
            onClick={props.cancelAddNewCard}
            dataTestId='cancel-add-card'
          />
        );
      } else {
        return (
          <Button
            className={classes.button}
            label={
              <Text
                text='Pay using a bank account instead'
                variant='medium'
                color={Color.blue._60}
              />
            }
            variant='tertiary'
            onClick={props.handleChangePaymentType}
            dataTestId='change-to-bank-account'
          />
        );
      }
    }
  }

  return (
    <Button
      className={classes.button}
      label={
        <Text
          text='Pay with a credit card instead'
          variant='medium'
          color={Color.blue._60}
        />
      }
      variant='tertiary'
      onClick={props.handleChangePaymentType}
      dataTestId='change-to-credit-card'
    />
  );
}
