import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import { Flex } from 'component-library';
import { ConnectPayroll } from 'products/onboarding/features/qualification/components';
import { useOnboardingStores } from 'stores/useStores';
import { useEffectOnce } from 'component-library/_helpers/use-utils';
import { TokenContext } from 'pages/TokenRequired';
import { ImportType } from 'lib/interfaces';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    maxWidth: '80%',
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}));

interface PayrollConnectionProps {
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
  taxYear: number;
}

export const PayrollConnection = observer(
  ({ pathTo, pathFrom, onNext, onBack, taxYear }: PayrollConnectionProps) => {
    const classes = useStyles();
    const history = useHistory();
    const { qualification } = useOnboardingStores();
    const { token, email } = useContext(TokenContext);

    const handleOnContinue = () => {
      onNext();
      history.push(pathTo);
    };

    const handleOnBack = () => {
      onBack();
      history.push(pathFrom);
    };

    useEffectOnce(async () => {
      await qualification.setPayrollSystems(email, token, taxYear);
    });

    const isDisabled = !qualification.connectPayrollDoThisLater;

    return (
      <SurveyFlowContainer
        title='Connect your payroll account'
        subtitle='The easiest way for us to gather relevant information is directly from your payroll data.'
        onContinue={handleOnContinue}
        onBack={handleOnBack}
        isDisabled={isDisabled}
        dataTestId='retirement-plan-credit'
      >
        <Flex className={classes.container} direction='column' gap={24}>
          <ConnectPayroll
            importType={ImportType.RetirementPlanQualification}
            taxYear={taxYear}
            setDoThisLater={() => {
              qualification.setConnectPayrollDoThisLater(true);
              handleOnContinue();
            }}
            subtitle='A connection to this account will allow us to access the number of NHCEs in your company.'
            doThisLaterModalTitle='You are not connecting your payroll?'
            doThisLaterModalContent='If you do not connect your payroll system, later in this process you will need to add all plan participants manually. We highly recommend connecting your payroll now.'
          />
        </Flex>
      </SurveyFlowContainer>
    );
  },
);
