import React from 'react';
import { Button, Flex, Link, Divider } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';

const useStyles = makeStyles({
  button: {
    minWidth: 67,
    borderRadius: 2,
    height: 36,
  },
});

interface IsLink {
  location?: string;
  onClick?: () => void;
  external?: boolean;
}

export interface SurveyQuestionFooterProps {
  ctaLabel?: string;
  onClick?:
    | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | IsLink;
  ctaDisabled?: boolean;
  onClickTestId?: string;
  dataTestId?: string;
}

const SurveyQuestionFooter: React.FC<SurveyQuestionFooterProps> = observer(
  ({ ctaLabel, onClick, ctaDisabled, onClickTestId, dataTestId, ...props }) => {
    const styles = useStyles();
    return (
      <>
        <Divider variant='no-bottom-margin' />
        <Flex
          direction='row-reverse'
          alignItems='center'
          padding={[16, 24]}
          dataTestId={dataTestId}
          {...props}
        >
          {typeof onClick === 'object' ? (
            <Link
              variant='medium'
              external={onClick.external}
              href={onClick.location}
              onClick={onClick.onClick}
            >
              {ctaLabel}
            </Link>
          ) : (
            ctaLabel &&
            onClick && (
              <Button
                label={ctaLabel}
                className={styles.button}
                onClick={onClick}
                dataTestId={onClickTestId}
                disabled={ctaDisabled}
              />
            )
          )}
        </Flex>
      </>
    );
  },
);

export default SurveyQuestionFooter;
