import { runInAction } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import { BaseDashboardStore } from '../../dashboard/BaseDashboardStore';
import {
  Page,
  ProgramNameEnum,
  QualificationModalStatusEnum,
  RetirementPlanStepsIndex,
} from 'lib/constants';
import { OrderForm, RetirementEstimateResponse } from 'lib/interfaces';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';

export class RetirementPlanStore extends BaseDashboardStore {
  public loading = false;
  public flowCurrentIndex = 0;
  public flowPrevIndex = 0;

  public creditEstimates: RetirementEstimateResponse | null = null;
  public billingSummary: OrderForm | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public setLoading(bool: boolean) {
    runInAction(() => (this.loading = bool));
  }

  public setFlowIndex(step: number) {
    runInAction(() => {
      if (this.flowCurrentIndex !== this.flowPrevIndex) {
        this.flowPrevIndex = this.flowCurrentIndex;
      }
      this.flowCurrentIndex = step;
    });
  }

  public setCurrentStepOnLoad(pathname: string, taxYear: number) {
    const taxYearString = taxYear.toString();
    const retirementRootPath = `/${Page.taxCredits}/${taxYearString}/${Page.retirementPlan}`;

    runInAction(() => {
      switch (pathname) {
        case retirementRootPath:
          this.setFlowIndex(RetirementPlanStepsIndex.BUSINESS_DETAILS);
          break;
        case `${retirementRootPath}/${Page.retirementPlanPayrollConnect}`:
          this.setFlowIndex(RetirementPlanStepsIndex.PAYROLL_CONNECTION);
          break;
        case `${retirementRootPath}/${Page.retirementPlanNHCEDetails}`:
          this.setFlowIndex(RetirementPlanStepsIndex.NHCE_DETAILS);
          break;
        case `${retirementRootPath}/${Page.retirementPlanCreditEstimate}`:
          this.setFlowIndex(RetirementPlanStepsIndex.CREDIT_ESTIMATE);
          break;
        case `${retirementRootPath}/${Page.retirementPlanBillingSummary}`:
          this.setFlowIndex(RetirementPlanStepsIndex.BILLING_SUMMARY);
          break;
      }
    });
  }

  public setBillingSummaryModal(status: QualificationModalStatusEnum) {
    runInAction(() =>
      this.rootStore.taxcredits.surveyFlow.updateQualificationStatus(status),
    );
  }

  public async getRetirementEstimates(taxYear: number) {
    const { programs } = this.company;
    const retirementProgram = programs.find(
      (p) =>
        p.name === ProgramNameEnum.FED_RETIREMENT_CREDIT &&
        p.taxYear === taxYear,
    );

    if (retirementProgram) {
      const res = await this.client.GetRetirementCreditEstimate(
        retirementProgram.id,
      );

      if (res.data && res.data?.estimates) {
        const creditEstimate = res.data?.estimates;

        // update program credit_amount_cents
        await this.client.UpdateProgramV1(retirementProgram.id, {
          creditAmountCents: creditEstimate.totalEstimate.amount,
        });

        // create program order form
        await this.client.CreateOrderForm(retirementProgram.id);

        runInAction(() => (this.creditEstimates = creditEstimate));
      }

      if (res.errorMsg) {
        datadogLogs.logger.error(
          `[RETIREMENT PLAN CREDIT_ESTIMATE]: Error retrieving credit estimates for program_id: ${retirementProgram.id}`,
          logContext({
            company: this.company,
            error: res.errorMsg,
          }),
        );
      }
    }
  }

  public async getOrderForm(taxYear: number) {
    const { programs } = this.company;
    const retirementProgram = programs.find(
      (p) =>
        p.name === ProgramNameEnum.FED_RETIREMENT_CREDIT &&
        p.taxYear === taxYear,
    );

    if (retirementProgram) {
      const res = await this.client.GetOrderForm(retirementProgram.id);

      if (res.data) {
        const orderForm = res.data;
        runInAction(() => (this.billingSummary = orderForm));
      }

      if (res.errorMsg) {
        datadogLogs.logger.error(
          `[ORDER_FORM]: Error getting order form for program_id: ${retirementProgram.id}`,
          logContext({
            company: this.company,
            error: res.errorMsg,
          }),
        );
      }
    }
  }

  public async updateOrderForm(taxYear: number) {
    const { programs } = this.company;
    const retirementProgram = programs.find(
      (p) =>
        p.name === ProgramNameEnum.FED_RETIREMENT_CREDIT &&
        p.taxYear === taxYear,
    );

    const updateReq: Partial<OrderForm> = {
      acceptedAt: new Date(),
    };

    if (retirementProgram) {
      const res = await this.client.UpdateOrderForm(
        retirementProgram.id,
        updateReq,
      );

      if (res.data) {
        console.log(res.data, 'data');
      }

      if (res.errorMsg) {
        datadogLogs.logger.error(
          `[ORDER_FORM]: Error updating order form for program_id: ${retirementProgram.id}`,
          logContext({
            company: this.company,
            error: res.errorMsg,
          }),
        );
      }
    }
  }
}
