import { ProgramName } from 'lib/interfaces';
import { ProgramNameEnum, ProgramStageEnum } from 'lib/constants';
import { CompanyData } from './interfaces/globalInterfaces';
import {
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
} from 'lib/constants';
import _ from 'lodash';

interface Program {
  name: ProgramName;
  stage: ProgramStageEnum;
  taxYear: number;
}

export const GetPayrollGapYearToCheck = (
  programs: Program[],
): number | undefined => {
  const payrollGapStages = [
    ProgramStageEnum.EXPENSE_CLASSIFICATION,
    ProgramStageEnum.MS_REVIEW,
  ];

  const fedRdPrograms = programs.filter(
    (program) => program.name === ProgramNameEnum.FED_RD_TAX,
  );
  if (!fedRdPrograms) {
    return;
  }
  const fedRdProgramsToCheckForPayrollGap = fedRdPrograms.filter((program) =>
    payrollGapStages.includes(program.stage),
  );
  return _.max(
    fedRdProgramsToCheckForPayrollGap.map(
      (fedRDProgram) => fedRDProgram.taxYear,
    ),
  );
};

// Payroll gap question NOT nswered or answer is another connection
export const HaveNotAnswerPayrollQuestionOrAnswerIsAnotherConnection = (
  company: CompanyData,
  programId: number | undefined,
): boolean => {
  if (!company || !programId) {
    return false;
  }

  const fedRDProgramTaxYear = company.programs.find(
    (program) => program.id === programId,
  )?.taxYear;

  if (!fedRDProgramTaxYear) {
    return false;
  }

  const qualificationQuestionObj = company?.qualificationQuestionsByYear ?? {};
  const companyQualificationQuestion =
    qualificationQuestionObj[fedRDProgramTaxYear] || {};

  const hasPayrollGapQuestionAnswer =
    companyQualificationQuestion[
      GraphCmsQuestionIdEnum.PAYROLL_GAP_QUESTION_ID
    ] || false;

  const ifPayrollGapQuestionIsDifferentPayrollSystem =
    hasPayrollGapQuestionAnswer ===
    GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.PAYROLL_GAP_QUESTION_ID]
      .PAYROLL_GAP_ANSWER_ID_USING_DIFFERENT_PAYROLL_SYSTEM;

  // return true if have NOT answered payroll gap question OR if answered with another connection
  return (
    !hasPayrollGapQuestionAnswer || ifPayrollGapQuestionIsDifferentPayrollSystem
  );
};
