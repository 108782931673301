import React, { useState } from 'react';
import { Card, Button, Color } from 'component-library';
import {
  makeStyles,
  Theme,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { CentsToDisplayString } from 'lib/helpers';
import { ContingencyText } from './ContingencyText';

const useStyles = makeStyles(({ emerald, color, transitions }: Theme) => ({
  root: {
    border: `1px solid rgba(0,0,0,0)`,
    margin: '15px 0',
  },
  rootSelected: {
    border: `1px solid ${emerald}`,
  },
  iconTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 24,
    minWidth: 24,
    marginRight: 20,
  },
  header: {
    background: Color.neutral.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 30px',
    borderBottom: `1px solid ${Color.neutral._40}`,
  },
  body: {
    padding: '30px',
    display: 'flex',
    lineHeight: 1.25,
  },
  amountRangeContainer: {
    marginRight: 20,
    width: 175,
    flexShrink: 0,
  },
  amountRangeText: {
    color: emerald,
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 5,
  },
  contingentFooter: {
    borderTop: `1px solid ${Color.neutral._40}`,
    background: Color.neutral._light_20,
    padding: '10px 30px',
    height: 'auto',
    overflow: 'hidden',
    fontSize: 12,
    alignItems: 'center',
  },
  footerTextAndIcon: {
    color: `${Color.neutral._80}`,
    display: 'flex',
    alignItems: 'center',
  },
  changeCommitment: {
    color: Color.blue._50,
    cursor: 'pointer',
    marginLeft: 44,
  },
  contingentExpand: {
    overflow: 'hidden',
    transition: transitions.create(['max-height'], {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.complex,
    }),
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 200,
  },
  buttonGroupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${Color.neutral._40}`,
    marginTop: 20,
    paddingTop: 10,
  },
  emphasis: {
    fontWeight: 500,
    fontSize: 14,
  },
  contingentExpandContent: {
    marginTop: 20,
  },
  contingentButtonGroup: {
    margin: '10px 0',
  },
  grey700Text: {
    color: Color.neutral._80,
  },
}));

interface SelectionCardProps {
  amountRange: { low: number; high: number; description: string };
  icon: JSX.Element;
  title: string;
  description: string;
  defaultValue: boolean;
  contingentBanner: {
    text: string;
    expanded?: JSX.Element;
    expandedHeight?: number;
    onSubmit?: () => Promise<void>;
  };
  onChange: (checked: boolean) => Promise<void>;
}

export const SelectionCard = ({
  amountRange,
  icon,
  title,
  description,
  defaultValue,
  contingentBanner,
  onChange,
}: SelectionCardProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);
  const [showExpando, setShowExpando] = useState(false);
  const [expandoLoading, setExpandoLoading] = useState(false);

  const [changeLoading, setChangeLoading] = useState(false);

  const updateHelper = async (checked: boolean) => {
    setValue(checked);
    setChangeLoading(true);
    onChange(checked).finally(() => setChangeLoading(false));
  };

  const { expanded, expandedHeight, onSubmit } = contingentBanner;

  if (amountRange.high === 0) {
    return <></>;
  }

  return (
    <Card
      className={
        value ? `${classes.root} ${classes.rootSelected}` : classes.root
      }
    >
      <div className={classes.header}>
        <div className={classes.iconTitleContainer}>
          <div className={classes.icon}>{icon}</div>
          <div>{title}</div>
        </div>
        {changeLoading ? (
          <CircularProgress size={32} />
        ) : (
          <Checkbox
            checked={value}
            onChange={(e) => updateHelper(e.target.checked)}
            style={{ color: '#056037' }}
          />
        )}
      </div>
      <div className={classes.body}>
        <div className={classes.amountRangeContainer}>
          <div className={classes.amountRangeText}>
            {CentsToDisplayString(amountRange.low)}–
            {CentsToDisplayString(amountRange.high)}
          </div>
          <div>
            <p>{amountRange.description}</p>
          </div>
        </div>
        <div>
          {description.split('\n').map((str, index) => (
            <p key={index}>{str}</p>
          ))}
        </div>
      </div>
      <div className={classes.contingentFooter}>
        <ContingencyText
          showIcon={!showExpando}
          text={contingentBanner.text}
          className={classes.grey700Text}
        />

        {expanded && !showExpando && (
          <span
            className={classes.changeCommitment}
            onClick={() => setShowExpando(!showExpando)}
          >
            Change Commitment
          </span>
        )}
        {expanded && (
          <div
            className={classes.contingentExpand}
            style={{
              maxHeight: showExpando ? expandedHeight ?? 2000 : 0,
            }}
          >
            <div
              className={classes.contingentExpandContent}
              style={{
                visibility: showExpando ? undefined : 'hidden',
              }}
            >
              {expanded}
              <div className={classes.buttonGroupContainer}>
                <div className={classes.emphasis}>
                  Your commitment affects the estimate range above
                </div>
                <div className={classes.buttonGroup}>
                  <Button
                    className={classes.contingentButtonGroup}
                    label='Save'
                    loading={expandoLoading}
                    onClick={() => {
                      if (onSubmit) {
                        setExpandoLoading(true);
                        onSubmit().finally(() => {
                          setExpandoLoading(false);
                          setShowExpando(false);
                        });
                      } else {
                        setShowExpando(false);
                      }
                    }}
                  />
                  <Button
                    className={classes.contingentButtonGroup}
                    variant='outlined'
                    label='Cancel'
                    onClick={() => {
                      setShowExpando(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default SelectionCard;
