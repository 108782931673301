import {
  Button,
  Color,
  Expandable,
  Flex,
  Icon,
  IconEnum,
  Link,
  Text,
} from 'component-library';
import { Page } from '../../../../../../lib/constants';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: '4px',
  },
  showAssessmentsBtn: {
    height: 'auto',
    padding: '8px 0 0',
  },
  showAssessmentsIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform .2s',
  },
  assessmentsIconClose: {
    transform: 'rotate(-180deg)',
  },
  expandableContent: {
    background: Color.neutral._10,
    marginTop: '8px',
  },
  rdSectionTitle: {
    marginRight: '16px',
  },
}));

interface RdStatusExpandableProps {
  taxYear: number;
}

// TODO: The status of each individual step is hardcoded to "complete" for now.
//       This will need to be updated once the survey skip functionality is built
//       and we have the ability to query the Span table for statuses.
export const RdStatusExpandable = ({ taxYear }: RdStatusExpandableProps) => {
  const classes = useStyles();
  const [showRdAssessments, setShowRdAssessments] = useState<boolean>(false);

  const editMessage = 'Edit';

  return (
    <>
      <Button
        className={classes.showAssessmentsBtn}
        variant='tertiary'
        onClick={() => setShowRdAssessments(!showRdAssessments)}
        label={
          <>
            {showRdAssessments ? 'Hide' : 'Show'} all R&D sections{' '}
            <Icon
              className={`${classes.showAssessmentsIcon} ${
                showRdAssessments ? classes.assessmentsIconClose : ''
              }`}
              name={IconEnum.chevron_down}
              color={Color.blue._60}
            />
          </>
        }
        flexAlignSelf='flex-start'
      />
      <Expandable expand={showRdAssessments}>
        <Flex direction='column' className={classes.expandableContent}>
          <Flex
            direction='row'
            justifyContent='space-between'
            padding={[12, 16]}
          >
            <Flex>
              <Text className={classes.rdSectionTitle}>
                R&D Company Details
              </Text>
              <Text>&bull; Completed</Text>
            </Flex>
            <Flex alignItems='center' justifyContent='end' basis='content'>
              <Icon
                name={IconEnum.pen}
                color={Color.blue._60}
                className={classes.icon}
              />
              <Link
                href={`/${Page.taxCredits}/${Page.assessment}/${taxYear}/${Page.expenseClassificationCompanyDetails}`}
              >
                {editMessage}
              </Link>
            </Flex>
          </Flex>
          <Flex
            direction='row'
            justifyContent='space-between'
            padding={[12, 16]}
          >
            <Flex>
              <Text className={classes.rdSectionTitle}>
                R&D Supplies and Services
              </Text>
              <Text>&bull; Completed</Text>
            </Flex>
            <Flex alignItems='center' justifyContent='end' basis='content'>
              <Icon
                name={IconEnum.pen}
                color={Color.blue._60}
                className={classes.icon}
              />
              <Link
                href={`/${Page.taxCredits}/${Page.assessment}/${taxYear}/${Page.rdSuppliesAndServices}`}
              >
                {editMessage}
              </Link>
            </Flex>
          </Flex>
          <Flex
            direction='row'
            justifyContent='space-between'
            padding={[12, 16]}
          >
            <Flex>
              <Text className={classes.rdSectionTitle}>R&D Employees</Text>
              <Text>&bull; Completed</Text>
            </Flex>
            <Flex alignItems='center' justifyContent='end' basis='content'>
              <Icon
                name={IconEnum.pen}
                color={Color.blue._60}
                className={classes.icon}
              />
              <Link
                href={`/${Page.taxCredits}/${Page.assessment}/${taxYear}/${Page.employees}`}
              >
                {editMessage}
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Expandable>
    </>
  );
};
