import { runInAction, toJS } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { Programs, ToDoCardsCardType, ToDoCardIdEnum } from 'lib/constants';
import { ProgramData } from 'lib/interfaces';
import { CentsToDisplayStringNoZeros } from 'lib/helpers';
import { BaseEntity } from 'entities/BaseEntity';
import { ToDoAlertTypeEnum } from 'component-library';

export interface ToDoCardEntityData {
  alertTypes: ToDoAlertTypeEnum;
  cardTypeId: ToDoCardsCardType;
  description: string;
  label: string;
  primaryCtaLink: string;
  primaryCtaText: string;
  priority: number;
  secondaryCtaAsDismissible: boolean;
  secondaryCtaLink: string;
  secondaryCtaText: string;
  title: string;
  id: ToDoCardIdEnum;
}

export class ToDoCardEntity extends BaseEntity {
  public alertTypes: ToDoAlertTypeEnum = '' as ToDoAlertTypeEnum;
  public cardTypeId: ToDoCardsCardType = '' as ToDoCardsCardType;
  public description = '';
  public label = '';
  public primaryCtaLink = '';
  public primaryCtaText = '';
  public priority = 0;
  public secondaryCtaAsDismissible = false;
  public secondaryCtaLink = '';
  public secondaryCtaText = '';
  public title = '';
  public id: ToDoCardIdEnum = '' as ToDoCardIdEnum;

  constructor(data?: Partial<ToDoCardEntityData>, program?: ProgramData) {
    super();
    makeSubclassObservable(this);

    if (data) {
      this.mergeData(data, program);
    }
  }

  public mergeData(data: Partial<ToDoCardEntityData>, program?: ProgramData) {
    const orderFormAmountCents: number =
      program?.orderForm?.estimatedTotalCreditCents ?? 0;

    // used for templateVars
    const taxYear = program?.taxYear;
    const dollarAmount = CentsToDisplayStringNoZeros(orderFormAmountCents);
    const state = program?.name ? Programs[program.name]?.geo : '';

    const getQuestionText = (text: string) => {
      if (!program) {
        return text;
      }
      const templateVars = text.match(/\${\w+(.\w+)?}/g);
      if (!templateVars) {
        return text;
      }
      let result = text;
      templateVars.forEach((templateVar) => {
        try {
          const varName = templateVar.match(/\${(.*)\}/)?.pop();
          // eslint-disable-next-line
          const replacementString = varName && eval(varName);
          const pattern = new RegExp(`\\${templateVar}`, 'g');
          result = result.replace(pattern, replacementString ?? '').toString();
        } catch (err) {
          result = text.replace(/\${\w+(.\w+)}\s/g, '');
        }
      });
      return result;
    };

    runInAction(() => {
      this.alertTypes = data.alertTypes || ('' as ToDoAlertTypeEnum);
      this.cardTypeId = data.cardTypeId || ('' as ToDoCardsCardType);
      this.description = data.description || '';
      this.label = data.label ?? '';
      this.primaryCtaLink =
        (data.primaryCtaLink && getQuestionText(data.primaryCtaLink)) || '';
      this.primaryCtaText = data.primaryCtaText || '';
      this.priority = data.priority || 0;
      this.secondaryCtaAsDismissible = data.secondaryCtaAsDismissible || false;
      this.secondaryCtaLink = data.secondaryCtaLink || '';
      this.secondaryCtaText = data.secondaryCtaText || '';
      this.title = (data.title && getQuestionText(data.title)) || '';
      this.id = data.id || ('' as ToDoCardIdEnum);
    });
  }

  public toJS(): ToDoCardEntityData {
    const clone = toJS(this);

    return {
      alertTypes: clone.alertTypes as ToDoAlertTypeEnum,
      cardTypeId: clone.cardTypeId as ToDoCardsCardType,
      description: clone.description,
      label: clone.label,
      primaryCtaLink: clone.primaryCtaLink,
      primaryCtaText: clone.primaryCtaText,
      priority: clone.priority,
      secondaryCtaAsDismissible: clone.secondaryCtaAsDismissible,
      secondaryCtaLink: clone.secondaryCtaLink,
      secondaryCtaText: clone.secondaryCtaText,
      title: clone.title,
      id: clone.id as ToDoCardIdEnum,
    };
  }

  public toJSON(): ToDoCardEntityData {
    return this.toJS();
  }
}
