import { Dropdown, DropdownOption } from 'component-library';
import React from 'react';
import { GetCardInfo } from 'lib/cardInfo';
import { StripePaymentMethod } from './types';

interface CreditCardSelectProps {
  connectedCards: StripePaymentMethod[];
  handleSelectCard: (account: string | DropdownOption) => void;
}

export default function CreditCardSelect(props: CreditCardSelectProps) {
  const options = props.connectedCards.map((item) => {
    return {
      name: `${GetCardInfo(item.card?.brand || '').display} ending in ${
        item.card?.last4
      }`,
      value: item.id,
    };
  });
  return (
    <Dropdown
      options={options}
      label='Select a payment method'
      onInputChange={(account) => props.handleSelectCard(account)}
      defaultSelected
      dataTestId='select-credit-card'
    />
  );
}
