import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  useAccountCreationStores,
  useCommonStores,
  useHistoryStore,
} from 'stores/useStores';
import {
  Flex,
  Heading,
  CardFooter,
  Text,
  Color,
  FontAwesome,
  Link,
} from 'component-library';
import { useEffectOnce } from 'lib/helpers';
import { AccountCreationStepsIndex } from 'lib/constants';
import { ProgramTaxYearSelectEnum } from 'lib/interfaces';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  container: {
    height: '100%',
    paddingBottom: '80px',
    '& button': {
      padding: '12px 15px',
    },
  },
  headerContainer: {
    maxWidth: '543px',
    marginTop: '135px',
    marginBottom: '16px',

    '& p': {
      marginTop: '24px',
    },

    [breakpoints.down('sm')]: {
      marginTop: '60px',
    },
  },
  header: {
    weight: '700px',
    textAlign: 'center',
    marginBottom: '24px',
  },
  questionCard: {
    maxWidth: '580px',
    width: '100%',
    cursor: 'pointer',
    padding: '24px',
    backgroundColor: Color.neutral.white,
    marginTop: '24px',
    border: `1px solid ${Color.neutral._20}`,
    boxShadow:
      '0px 0px 6px rgba(21, 36, 31, 0.1), 0px 0px 16px rgba(238, 238, 238, 0.48)',
    borderRadius: '4px',
    '&  p': {
      fontWeight: 500,
    },
  },
  icon: {
    lineHeight: '24px',
    marginRight: '16px',
  },
  footer: {
    backgroundColor: Color.neutral.white,
    justifyContent: 'space-around !important',

    '& button': {
      maxWidth: '96px',
      width: '100%',
    },
  },
}));

interface ProgramsStepProps {
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
}

export const ProgramsStep = observer(
  ({ pathTo, pathFrom, onNext }: ProgramsStepProps) => {
    const classes = useStyles();
    const { accountCreation } = useAccountCreationStores();
    const { rootStore } = useCommonStores();
    const history = useHistoryStore();

    const selected = accountCreation.ProgramStepQuestions.find(
      (item) => item.selected,
    );

    const onContinue = async () => {
      if (!accountCreation.errorMsg && selected) {
        await accountCreation.handleTaxYearProgramSelect(
          selected.text,
          selected.taxYear,
        );

        // Ensure Dashboard has up to date 2023 program data if new program created for 2023
        if (selected.taxYear === 2023) {
          await rootStore.common.companyStore.refreshCurrentCompany();
        }
        history.push(pathTo);
        onNext();
      }
    };

    const onBack = () => {
      history.push(pathFrom);
      accountCreation.setCurrentStep(AccountCreationStepsIndex.TAXES);
    };

    useEffectOnce(() => accountCreation.setProgramStepQuestions());

    return (
      <>
        <Flex
          className={classes.container}
          direction='column'
          alignItems='center'
          dataTestId='program-step-container'
        >
          <Flex.Cell className={classes.headerContainer} alignSelf='center'>
            <Heading size={36} variant='medium' className={classes.header}>
              What year are you looking to claim credits for?
            </Heading>
            <Text text='Select which applies:' textAlign='center' />
          </Flex.Cell>
          {accountCreation.ProgramStepQuestions.map((item) => {
            return (
              <div
                key={item.text}
                className={classes.questionCard}
                onClick={() =>
                  accountCreation.updateProgramStepQuestion(item.text)
                }
                role='button'
                tabIndex={0}
              >
                <Flex alignItems='flex-start'>
                  {selected?.text === item.text ? (
                    <FontAwesome
                      variant='regular'
                      name='circle-dot'
                      size={12}
                      className={classes.icon}
                    />
                  ) : (
                    <FontAwesome
                      variant='regular'
                      name='circle'
                      size={12}
                      className={classes.icon}
                    />
                  )}
                  <Text>
                    <b>{item.taxYear}: </b>
                    {item.text}{' '}
                    {item.text ===
                      ProgramTaxYearSelectEnum.DISASTER_OR_EXTENSION_2022 && (
                      <Link
                        href='https://www.irs.gov/newsroom/tax-relief-in-disaster-situations'
                        external
                      >
                        {' '}
                        Learn more
                      </Link>
                    )}
                  </Text>
                </Flex>
              </div>
            );
          })}
        </Flex>
        <CardFooter
          className={classes.footer}
          primaryCtaLabel='Next'
          primaryOnClick={onContinue}
          primaryTestId='account-creation-next-button'
          secondaryTestId='account-creation-previous-button'
          primaryCtaDisabled={selected === undefined}
          secondaryCtaLabel='Back'
          secondaryOnClick={onBack}
          variant='quaternary'
        />
      </>
    );
  },
);
