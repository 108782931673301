import { PlaidProvider } from './PlaidProvider';
import ExclamationMark from '../../../../../components/icons/ExclamationMark';
import { PlaidConnectButton } from './PlaidConnectButton';
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: '10px',
  },
}));

interface BankingInformation {
  accountName: string;
  lastFourDigits: string;
}

interface ManualMicroDepositVerificationProps {
  loading: boolean;
  plaidToken: string;
  bankInfo: BankingInformation;
  onSuccess: React.Dispatch<void>;
  onError: React.Dispatch<string>;
}

export const ManualMicroDepositVerification = (
  props: ManualMicroDepositVerificationProps,
) => {
  const classes = useStyles();

  return (
    <PlaidProvider
      plaidToken={props.plaidToken}
      onPlaidLinkSuccess={props.onSuccess}
      onPlaidLinkFail={props.onError}
    >
      <ExclamationMark className={classes.icon} />
      {`Awaiting manual micro-deposit verification for account ${props.bankInfo.accountName} ${props.bankInfo.lastFourDigits}`}
      <PlaidConnectButton loading={props.loading} labelName={'Verify'} />
    </PlaidProvider>
  );
};
