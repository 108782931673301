import React from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Flex, Link, Text } from 'component-library';
import { SurveyFlowContainer } from 'products/tax-credits/components';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    maxWidth: '66%',
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}));

interface ReceiveCreditsProps {
  pathTo: string;
  onNext: () => void;
  isPrefilled: boolean;
}

export const ReceiveCredits = observer(
  ({ pathTo, onNext, isPrefilled }: ReceiveCreditsProps) => {
    const classes = useStyles();
    const history = useHistory();

    const handleOnContinue = () => {
      onNext();
      history.push(pathTo);
    };

    return (
      <SurveyFlowContainer
        title='Next step: Receive credits'
        onContinue={handleOnContinue}
        dataTestId='erc-qualify-survey'
      >
        <Flex
          className={classes.container}
          direction='column'
          gap={24}
          padding={[12, 0, 40, 0]}
        >
          <Text size={18}>
            Our team is reviewing your information now to finalize your Employee
            Retention Credits. We may reach out from{' '}
            <Link href='mailto:support@mainstreet.com' target='_blank'>
              support@mainstreet.com
            </Link>{' '}
            with further questions.
          </Text>
          <Text size={18}>
            After we provide your credit documents, we will assist with filing
            them to the IRS. You can then expect refund checks from the IRS,
            mailed directly to you!
          </Text>
          <Text variant='medium' size={18}>
            In the meantime, sit back, relax, and get excited about your
            credits!
          </Text>
        </Flex>
      </SurveyFlowContainer>
    );
  },
);
