import React from 'react';
import { GovernmentProgramBundle, ProgramCardData } from 'lib/constants';
import ProgramBundles from './ProgramBundles';

interface ProgramCardsProps {
  cards: ProgramCardData[];
}

export const ProgramCards = ({ cards }: ProgramCardsProps) => {
  const govCards = cards.filter(({ name }) =>
    GovernmentProgramBundle.includes(name),
  );

  return (
    <>
      <ProgramBundles bundleName='Tax Credits' programs={govCards} />
    </>
  );
};
