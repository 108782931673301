import {
  CompanyData,
  ProgramData,
  QualificationQuestions,
  RenderTreeShowCondition,
} from 'lib/interfaces';
import { isAnswerValid } from 'lib/useSurveyQuestions';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import _ from 'lodash';
import { isTransitionDateFirstDayOfCurrentFiscalYear } from './surveyQuestionCustomConditions';

export type CustomShowCondition = RenderTreeShowCondition | (() => boolean);

export const evaluateShowCondition = (
  surveyAnswers: QualificationQuestions,
  company: CompanyData,
  customShowCondition: CustomShowCondition,
) =>
  _.isFunction(customShowCondition)
    ? customShowCondition()
    : evaluate(surveyAnswers, company, customShowCondition);

const evaluate = (
  surveyAnswers: QualificationQuestions,
  company: CompanyData,
  showCondition: RenderTreeShowCondition,
): boolean => {
  if (showCondition == null) {
    return true;
  }

  if (typeof showCondition === 'boolean') {
    return showCondition;
  }

  const operator = Object.keys(showCondition)[0];
  if (!(operator in showConditionOperators)) {
    datadogLogs.logger.warn(
      `An unsupported showCondition operator (${operator}) was used`,
      logContext({ company }),
    );
    return true;
  }

  const condition = showCondition[operator];
  if (Array.isArray(condition)) {
    // PLATFROM TODO: Refactor this, it is very dangerous, and can be hard to debug given the resulting function signatures differ greatly.
    return showConditionOperators[operator]({
      surveyAnswers,
      company,
      conditions: condition.map((cp) =>
        evaluateShowCondition(surveyAnswers, company, cp),
      ),
    });
  } else {
    return showConditionOperators[operator]({
      surveyAnswers,
      company,
      condition,
    });
  }
};

// PLATFORM TODO: Refactor to use Company
const showConditionOperators: Record<string, (...args: any[]) => boolean> = {
  answered: ({ surveyAnswers, condition }): boolean =>
    isAnswerValid(surveyAnswers[condition.questionId]),
  notAnswered: ({ surveyAnswers, condition }): boolean =>
    !isAnswerValid(surveyAnswers[condition.questionId]),
  equals: ({ surveyAnswers, condition }): boolean =>
    surveyAnswers[condition.questionId] === condition.answerValue,
  includes: ({ surveyAnswers, condition }): boolean =>
    surveyAnswers[condition.questionId]?.includes(condition.answerValue) ??
    false,
  doesNotInclude: ({ surveyAnswers, condition }): boolean =>
    !surveyAnswers[condition.questionId]?.includes(condition.answerValue),
  gt: ({ surveyAnswers, condition }): boolean => {
    const questionResponse = surveyAnswers[condition.questionId];
    return !!(questionResponse && questionResponse > condition.answerValue);
  },
  lt: ({ surveyAnswers, condition }): boolean => {
    const questionResponse = surveyAnswers[condition.questionId];
    return !!(questionResponse && questionResponse < condition.answerValue);
  },
  quotientLessThanTarget: ({ surveyAnswers, condition }): boolean => {
    const dividend = surveyAnswers[condition.dividendQuestionId];
    const divisor = surveyAnswers[condition.divisorQuestionId];
    if (dividend && divisor) {
      return dividend / divisor < condition.target;
    }
    return false;
  },
  or: ({ conditions }): boolean => conditions.some(Boolean),
  and: ({ conditions }): boolean => conditions.every(Boolean),
  hasProgram: ({ company, condition }): boolean =>
    !!company.programs.find(
      (p: ProgramData) => p.name === condition.programName,
    ),
  foundedBefore: ({ company, condition }): boolean =>
    company?.yearFounded
      ? parseInt(company.yearFounded) < parseInt(condition.answerValue)
      : true,
  companyType: ({ company, condition }): boolean =>
    company?.taxType ? company.taxType === condition.companyTaxType : false,
  isNotAdminJobTitle: ({ company, condition }): boolean =>
    company.adminJobTitle !== condition.answerValue,
  hasPartnerAccessCode: ({ company, condition }): boolean =>
    company?.misc?.partnerAccessCode
      ? !!company?.misc?.partnerAccessCode === condition.answerValue
      : false,
  isTransitionDateNotFirstDayOfCurrentFiscalYear: ({
    company,
    condition,
    surveyAnswers,
  }): boolean =>
    !isTransitionDateFirstDayOfCurrentFiscalYear(
      surveyAnswers[condition.questionId],
      company.fiscalYearEndDate,
    ),
  differenceGreaterThan: ({ surveyAnswers, condition }): boolean => {
    const minuend = parseInt(surveyAnswers[condition.minuend]) || 0;
    const subtrahend = parseInt(surveyAnswers[condition.subtrahend]) || 0;
    const difference = minuend - subtrahend;

    return !!(difference > parseInt(condition.greaterThan));
  },
};
