import { runInAction } from 'mobx';
import { BaseStore } from 'stores/BaseStore';
import { makeSubclassObservable } from 'lib/mobx-utils';
import {
  HidePayrollQualificationStepsIndex,
  Page,
  QualificationStepsIndex,
} from 'lib/constants';
import { RootStore } from 'stores/RootStore';

export class RDQualificationStore extends BaseStore {
  public path = `/${Page.taxCreditQualificationRoot}`;
  public currentStep: number = QualificationStepsIndex.CONNECT_PAYROLL;
  public previousStep = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public setCurrentStep(step: number) {
    runInAction(() => {
      if (this.currentStep !== this.previousStep) {
        this.previousStep = this.currentStep;
      }
      this.currentStep = step;
    });
  }

  public setCurrentStepOnLoad(pathname: string, taxYear: number) {
    const hidePayroll = this.featureFlags.hidePayrollStepInQualification;
    runInAction(() => {
      switch (pathname) {
        case `/${Page.taxCreditQualificationRoot}/${taxYear}`:
          this.setCurrentStep(QualificationStepsIndex.CONNECT_PAYROLL);
          break;
        case `/${Page.taxCreditQualificationRoot}/${taxYear}/${Page.taxCreditRdPayrollDetails}`:
          this.setCurrentStep(
            hidePayroll
              ? HidePayrollQualificationStepsIndex.PAYROLL_DETAILS
              : QualificationStepsIndex.PAYROLL_DETAILS,
          );
          break;
        case `/${Page.taxCreditQualificationRoot}/${taxYear}/${Page.taxCreditRdOverview}`:
          this.setCurrentStep(
            hidePayroll
              ? HidePayrollQualificationStepsIndex.OVERVIEW
              : QualificationStepsIndex.OVERVIEW,
          );
          break;
        case `/${Page.taxCreditQualificationRoot}/${taxYear}/${Page.taxCreditRdActivities}`:
          this.setCurrentStep(
            hidePayroll
              ? HidePayrollQualificationStepsIndex.RD_ACTIVITIES
              : QualificationStepsIndex.RD_ACTIVITIES,
          );
          break;
        case `/${Page.taxCreditQualificationRoot}/${taxYear}/${Page.taxCreditRd4PartTest}`:
          this.setCurrentStep(
            hidePayroll
              ? HidePayrollQualificationStepsIndex.RD_4_PART_TEST
              : QualificationStepsIndex.RD_4_PART_TEST,
          );
          break;
      }
    });
  }
}
