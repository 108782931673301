import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
} from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  Page,
  ProgramStageEnum,
  ProgramSubStageEnum,
  ExpenseClassificationFlowSteps,
  ExpenseClassificationProcessingSteps,
  SubGroupNameEnum,
} from 'lib/constants';
import { Color, Stepper, Alert, Flex, Text } from 'component-library';
import { useTransition, animated } from 'react-spring';
import {
  Overview,
  CompanyDetails,
  RDExpenses,
  Employees,
  Review,
} from './steps';
import HttpErrorPage from 'pages/HttpErrorPage';
import ErrorPage from 'pages/ErrorPage';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { CompanyContext } from 'pages/CompanyRequired';
import { HeaderBar } from './components';
import { ProgramData } from 'lib/interfaces';

import withIsMountedCheck from 'components/util/WithIsMountedCheck';
import {
  FiscalTaxYearHasPayrollGap,
  getAlertTextBySubGroup,
  getWarningTextBySubGroup,
} from 'lib/helpers';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import { HaveNotAnswerPayrollQuestionOrAnswerIsAnotherConnection } from 'lib/payrollGap';
import { FeatureFlagProviderContext } from '@mainstreet/feature-flags-react';
import {
  FeatureFlagNameEnum,
  FeatureFlagValueEnum,
} from 'lib/constants/featureFlagConstants';
import { useFeatureFlags } from '../../../stores/useStores';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: '100vh',
    backgroundColor: Color.neutral.white,
  },
  errorPage: {
    justifyContent: 'center',
  },
  mainContent: {
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
    padding: '0 24px 80px',
    boxSizing: 'border-box',
    position: 'relative',
  },
}));

interface ProcessingFlowInterface {
  exact?: boolean;
  path?: string;
  component: React.ReactElement | JSX.Element;
}

interface ExpenseClassificationProps {
  programId: string;
}

export const ExpenseClassification = ({
  programId,
}: ExpenseClassificationProps) => {
  const classes = useStyles();
  const { client } = useContext(Auth0FeatureContext);
  const { company } = useContext(CompanyContext);
  const featureFlags = useFeatureFlags();

  const history = useHistory();
  const location = useLocation();
  const path = `/${Page.expenseClassificationOverview}/${programId}`;

  const { getFlag } = useContext(FeatureFlagProviderContext);
  const [isNewExpenseClassification, setIsNewExpenseClassification] = useState<
    boolean
  >(false);
  const [isPayrollGapFeatureFlagOn, setIsPayrollGapFeatureFlagOn] = useState<
    boolean
  >(false);

  const [program, setProgram] = useState<ProgramData | null>(null);

  const [currentStep, setCurrentStep] = useState<number>(
    ExpenseClassificationFlowSteps.OVERVIEW,
  );
  const [previousStep, setPreviousStep] = useState<number>(0);

  const onError = () => setShowError(true);

  const onBackStep = useCallback(() => setCurrentStep(currentStep - 1), [
    currentStep,
    setCurrentStep,
  ]);
  const onNextStep = useCallback((step) => setCurrentStep(step), [
    setCurrentStep,
  ]);

  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const [hasPayrollGap, setHasPayrollGap] = useState<boolean>(false);
  const [possiblePayrollMigration, setPossiblePayrollMigration] = useState<
    boolean
  >(false);

  const checkForPayrollGap = useCallback(
    (program: ProgramData) => {
      const parseProgramId = parseInt(programId, 10);

      client
        .PayrollImportStatus(company.id, program.taxYear)
        .then((res) => {
          if (res) {
            const hasMissingPayStatements = FiscalTaxYearHasPayrollGap(
              res.firstPayStatementOfTaxYear,
              res.lastPayStatementOfTaxYear,
              company.fiscalYearEndDate,
            );

            const notAnsweredPayrollQuestionOrAnsweredWithDiffConnection = HaveNotAnswerPayrollQuestionOrAnswerIsAnotherConnection(
              company,
              parseProgramId,
            );

            const hasMigration = res.possiblePayrollMigration;

            setHasPayrollGap(
              hasMissingPayStatements &&
                !hasMigration &&
                notAnsweredPayrollQuestionOrAnsweredWithDiffConnection,
            );
            setPossiblePayrollMigration(res.possiblePayrollMigration);
          }
        })
        .catch((err) => {
          datadogLogs.logger.error(
            'Could not retrieve PayrollImportStatus during Expense Classification',
            logContext({
              company,
              error: err,
            }),
          );
        });
    },
    [client, company, programId],
  );

  const handleUpdateProgramSubStage = useCallback(
    (programSubStage: ProgramSubStageEnum): Promise<string | undefined> => {
      return client
        .UpdateProgramSubStage({
          programId,
          programSubStage: programSubStage,
        })
        .then(({ errorMsg }) => {
          if (errorMsg) {
            console.error('Error: Could not update program substage.');
            return Promise.reject(errorMsg);
          }
        });
    },
    [client, programId],
  );

  const ProcessingFlowSteps: ProcessingFlowInterface[] = [
    {
      exact: true,
      path: path,
      component: program ? (
        <Overview programId={program.id} hasPayrollGap={hasPayrollGap} />
      ) : (
        <></>
      ),
    },
    {
      path: `${path}/${Page.expenseClassificationCompanyDetails}`,
      component: program ? (
        <CompanyDetails programId={program.id} onError={onError} />
      ) : (
        <></>
      ),
    },
    {
      path: `${path}/${Page.expenseClassificationSuppliesServices}`,
      component: program ? (
        <RDExpenses programId={program.id} onError={onError} />
      ) : (
        <></>
      ),
    },
    {
      path: `${path}/${Page.expenseClassificationEmployees}`,
      component: program ? (
        <Employees
          programId={program.id}
          onError={onError}
          hasPayrollGap={hasPayrollGap}
          possiblePayrollMigration={possiblePayrollMigration}
        />
      ) : (
        <></>
      ),
    },
    {
      path: `${path}/${Page.expenseClassificationSubmitReview}`,
      component: (
        <Review
          pathTo={`/${Page.taxCredits}`}
          pathFrom={`${path}/${Page.expenseClassificationEmployees}`}
          onContinue={onNextStep}
        />
      ),
    },
    {
      component: <HttpErrorPage errorCode={404} />,
    },
  ];

  const transitions = useTransition(location, {
    initial: { opacity: 1 },
    from: {
      opacity: 0,
      transform: `translate3d(${(currentStep - previousStep) * 100}%,0,0)`,
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)',
    },
    leave: {
      opacity: 0,
      transform: `translate3d(${(previousStep - currentStep) * 50}%,0,0)`,
      position: 'absolute',
    },
  });

  useEffect(() => {
    if (currentStep !== previousStep) {
      setPreviousStep(currentStep);
    }
  }, [currentStep, previousStep]);

  useEffect(() => {
    return withIsMountedCheck((isMounted) => {
      if (isMounted()) {
        setIsNewExpenseClassification(
          getFlag?.(FeatureFlagNameEnum.NEW_EXPENSE_CLASSIFICATION_FLOW) ===
            FeatureFlagValueEnum.ON,
        );
        setIsPayrollGapFeatureFlagOn(
          getFlag?.(FeatureFlagNameEnum.GOV_CONNECT_PAYROLL_GAP) ===
            FeatureFlagValueEnum.ON,
        );

        const AllowedStage = [
          ProgramStageEnum.EXPENSE_CLASSIFICATION,
          ProgramStageEnum.MS_REVIEW,
        ];

        const currentCompanyMatchProgram = company?.programs.find(
          (program) => program.id === Number(programId),
        );

        if (!currentCompanyMatchProgram) {
          // Redirect if current company does not match program id
          setShouldRedirect(true);
        }

        if (currentCompanyMatchProgram && currentCompanyMatchProgram?.stage) {
          if (AllowedStage.indexOf(currentCompanyMatchProgram.stage) === -1) {
            setShouldRedirect(true);
          }
        }
      }

      client.GetProgram(programId).then((res) => {
        if (res.errorMsg) {
          // Error handling??
          console.error(res.errorMsg);
        }

        if (res.data) {
          if (isMounted()) {
            setProgram(res.data.program);
          }
        }

        if (isPayrollGapFeatureFlagOn && res.data?.program) {
          checkForPayrollGap(res.data.program);
        }
      });
    });
  }, [
    company,
    client,
    programId,
    getFlag,
    checkForPayrollGap,
    isPayrollGapFeatureFlagOn,
  ]);

  useLayoutEffect(
    useCallback(() => {
      const currentProgramSubStage = company?.programs.find(
        (program) => program.id === Number(programId),
      )?.subStage;

      // Handles redirect and hard refresh while in step
      if (
        currentProgramSubStage ===
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_OVERVIEW &&
        location.pathname !== `${path}`
      ) {
        history.push(`${path}`);
        setCurrentStep(ExpenseClassificationFlowSteps.OVERVIEW);
      } else if (location.pathname === `${path}`) {
        setCurrentStep(ExpenseClassificationFlowSteps.OVERVIEW);
      }

      // Handles redirect and hard refresh while in step
      if (
        currentProgramSubStage ===
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_COMPANY_DETAILS &&
        location.pathname !==
          `${path}/${Page.expenseClassificationCompanyDetails}`
      ) {
        history.push(`${path}/${Page.expenseClassificationCompanyDetails}`);
        setCurrentStep(ExpenseClassificationFlowSteps.COMPANY_DETAILS);
      } else if (
        location.pathname ===
        `${path}/${Page.expenseClassificationCompanyDetails}`
      ) {
        setCurrentStep(ExpenseClassificationFlowSteps.COMPANY_DETAILS);
      }

      // Handle redirect to R&D expenses
      if (
        currentProgramSubStage ===
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EXPENSES &&
        location.pathname !==
          `${path}/${Page.expenseClassificationSuppliesServices}`
      ) {
        history.push(`${path}/${Page.expenseClassificationSuppliesServices}`);
        setCurrentStep(ExpenseClassificationFlowSteps.RD_EXPENSES);
      } else if (
        location.pathname ===
        `${path}/${Page.expenseClassificationSuppliesServices}`
      ) {
        setCurrentStep(ExpenseClassificationFlowSteps.RD_EXPENSES);
      }

      // Handle redirect to R&D employees
      if (
        currentProgramSubStage ===
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EMPLOYEES &&
        location.pathname !== `${path}/${Page.expenseClassificationEmployees}`
      ) {
        history.push(`${path}/${Page.expenseClassificationEmployees}`);
        setCurrentStep(ExpenseClassificationFlowSteps.RD_EMPLOYEES);
      } else if (
        location.pathname === `${path}/${Page.expenseClassificationEmployees}`
      ) {
        setCurrentStep(ExpenseClassificationFlowSteps.RD_EMPLOYEES);
      }

      // Handle redirect to submit review
      if (
        currentProgramSubStage === ProgramSubStageEnum.REVIEW_IN_PROGRESS &&
        location.pathname !==
          `${path}/${Page.expenseClassificationSubmitReview}`
      ) {
        history.push(`${path}/${Page.expenseClassificationSubmitReview}`);
        setCurrentStep(ExpenseClassificationFlowSteps.MS_REVIEW);
      } else if (
        location.pathname ===
        `${path}/${Page.expenseClassificationSubmitReview}`
      ) {
        setCurrentStep(ExpenseClassificationFlowSteps.MS_REVIEW);
      }
    }, [company, history, location.pathname, path, programId]),
    [],
  );

  const renderExpenseClassificationContent = () => (
    <>
      {!isNewExpenseClassification ? (
        <div className={`${classes.container} ${classes.errorPage}`}>
          <HttpErrorPage errorCode={404} />
        </div>
      ) : shouldRedirect ? (
        <Redirect to='/' />
      ) : showError ? (
        <div className={`${classes.container} ${classes.errorPage}`}>
          <ErrorPage
            errorTitle="We've encountered a technical problem"
            errorMsg={`We are working on this issue. Please return in one business day to complete this form.`}
            showBackToDashboard
          />
        </div>
      ) : (
        <div className={classes.container}>
          <HeaderBar
            currentPage={ExpenseClassificationProcessingSteps[currentStep - 2]}
          />
          <div className={classes.mainContent}>
            <Stepper
              steps={ExpenseClassificationProcessingSteps}
              currentStep={currentStep}
            />
            {transitions((props, item) => {
              return (
                <animated.div style={props}>
                  <Switch location={item}>
                    {ProcessingFlowSteps.map((item, key) => {
                      return (
                        <Route
                          exact={item.exact}
                          key={key}
                          path={item.path}
                          render={() => item.component}
                        />
                      );
                    })}
                  </Switch>
                </animated.div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );

  if (!program) {
    datadogLogs.logger.warn(
      `Program is not found `,
      logContext({
        company,
      }),
    );
  }

  const currentYear = new Date().getFullYear();
  const taxYear = program?.taxYear ? program.taxYear : 0;
  const isTaxYearSupported = taxYear > 0 && currentYear > taxYear;

  if (program && !isTaxYearSupported) {
    datadogLogs.logger.warn(
      `ProgramId ${program.id} attempted to classify expenses but ${program.taxYear} is an unsupported tax year`,
      logContext({
        company,
      }),
    );
  }

  return program ? (
    !isTaxYearSupported ? ( // TODO: Should we remove this error message and condition all together?
      <div className={`${classes.container} ${classes.errorPage}`}>
        <ErrorPage
          errorTitle={`We currently don't support classifying expenses for the year, ${taxYear}.`}
          errorMsg={`In the meantime, if you need further guidance, please get in touch with us at support@mainstreet.com.`}
          showBackToDashboard
        />
      </div>
    ) : (
      renderExpenseClassificationContent()
    )
  ) : (
    <></>
  );
};

export const getAlertBySubGroup = (
  subGroupName: SubGroupNameEnum,
): JSX.Element | null => {
  const alertText = getAlertTextBySubGroup(subGroupName);
  if (!alertText) {
    return null;
  }

  return (
    <Alert
      text={
        <Flex direction='column'>
          <Text text={alertText.text} variant='medium' />
          <Text text={alertText.subText} size={13} color={Color.neutral._80} />
        </Flex>
      }
      iconColor={Color.blue._60}
    />
  );
};

export const getWarningBySubGroup = (
  subGroupName: SubGroupNameEnum,
): JSX.Element | null => {
  const warningText = getWarningTextBySubGroup(subGroupName);
  if (!warningText) {
    return null;
  }

  return (
    <Alert
      text={
        <Flex direction='column' gap={12}>
          <Text text={warningText.text} variant='medium' />
          <Text
            text={warningText.subText}
            size={13}
            color={Color.neutral._80}
          />
        </Flex>
      }
      backgroundColor={Color.semantic.$warning10}
      inCardBorder='left'
      type='caution'
      variant={'in_card'}
    />
  );
};
