import { observer } from 'mobx-react';
import {
  useCommonStores,
  useDashboardStores,
  useFeatureFlags,
  useTaxCreditsStores,
} from '../../stores/useStores';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Flex,
  Heading,
  Modal,
  Text,
  Card,
  Color,
  Alert,
  Button,
  Expandable,
  Icon,
  IconEnum,
  Callout,
  Content,
} from 'component-library';
import { ChargeBeePaymentForm } from './ChargeBeePaymentForm';
import { AddPaymentMethod } from '../../products/tax-credits/features/order-form/components';
import { FloatToDollarString } from '../../lib/helpers';
import { EmptyClient } from '../../services/ServerClient';
import { ProgramData } from '../../lib/interfaces';
import { reaction } from 'mobx';
import LoadingWidget from '../util/LoadingWidget';
import { ExpectedCreditTypeEnum, Programs } from 'lib/constants';
import { datadogLogs } from '@datadog/browser-logs';
import { useHistory } from 'react-router-dom';

const logger = datadogLogs.createLogger('UnifiedCreditPaymentModal');

const useStyles = makeStyles(() => ({
  contentContainer: {
    height: 'calc(100vh - 48px)',
    maxHeight: '700px',
    overflowY: 'auto',
  },
  callout: {
    backgroundColor: Color.semantic.$warning10,
  },
  estimatedCreditContainer: {
    gap: '24px',
  },
  estimatedCreditCard: {
    width: '50%',
    height: '126px',
    padding: '20px 24px 20px 24px',
    gap: '16px',
    backgroundColor: Color.blue._10,
    borderColor: Color.blue._70,
    borderRadius: '4px',
    marginTop: '0px',
  },
  estimatedCreditTitle: {
    color: Color.blue._60,
  },
  estimatedCreditNote: {
    color: '#3D3D3D',
  },
  individualCreditContainer: { width: '100%' },
  individualCreditContent: { width: '100%' },
  individualCreditName: { marginRight: '5px' },
  individualCreditPlan: {
    color: '#AA22A5',
  },
  individualCreditAmount: { width: '25%' },
  showCreditButton: {
    padding: 0,
    margin: 0,
    height: '24px',
  },
  creditContainer: {
    backgroundColor: Color.neutral._10,
  },
  freeText: {
    color: Color.green._60,
    size: 14,
    fontWeight: 600,
  },
}));

interface UnifiedCreditPaymentModalProps {
  onNext: () => void;
  taxYear: number;
}

export const UnifiedCreditPaymentModal = observer(
  ({ taxYear, onNext }: UnifiedCreditPaymentModalProps) => {
    const { app, companyStore } = useCommonStores();
    const { modules } = useDashboardStores();
    const { unifiedTaxCredits } = useTaxCreditsStores();
    const classes = useStyles();
    const history = useHistory();
    const featureFlags = useFeatureFlags();
    const showChargebeePaymentMethod = featureFlags.showChargebeePaymentMethod;
    const [programData, setProgramData] = useState<{
      programs: ProgramData[];
      totalCreditDollars: number;
      totalFeeDollars: number;
      feePercentage: number;
    }>({
      programs: [],
      totalCreditDollars: 0,
      totalFeeDollars: 0,
      feePercentage: 0,
    });
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [paymentMethod, setPaymentMethod] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [rdExpand, setRdExpand] = useState(false);
    const [nonRdExpand, setNonRdExpand] = useState(false);
    const [partnerChargePercentage, setPartnerChargePercentage] = useState<
      string | null
    >(null);
    const partnerFinalCreditAmountText =
      unifiedTaxCredits.getPartnerFinalCreditAmountText();
    const guidelineReferral =
      companyStore.currentCompany.misc?.partnerReferral?.toLocaleLowerCase() ===
      'guideline';
    const handleError = (errorLog: string) => {
      logger.error(errorLog);
      modules.toggleShowUnifiedCreditPaymentModal(false);
      setShowErrorModal(true);
    };

    useEffect(() => {
      const dispose = reaction(
        () => modules.showUnifiedCreditPaymentModal,
        async (showModal) => {
          if (showModal) {
            setPaymentMethod(
              app.common.companyStore.currentCompany.paymentMethodOnFile,
            );
            EmptyClient.GetFinalPaymentTotal(taxYear)
              .then((res) => {
                if (res.data && res.data?.programs.length !== 0) {
                  setProgramData({
                    // removing null/undefined values from programs array
                    programs: res.data.programs.flatMap((p) => (p ? [p] : [])),
                    totalCreditDollars: res.data.totalCreditDollars,
                    totalFeeDollars: res.data.totalFeeDollars,
                    feePercentage: res.data.feePercentage,
                  });
                  setLoading(false);
                } else {
                  handleError(
                    `Error fetching payment total and the program length total is zero, Object: ${JSON.stringify(
                      res.data,
                    )}`,
                  );
                }
              })
              .catch((error) => {
                handleError(`Error fetching payment total: ${error}`);
              });
          }
        },
      );

      // Cleanup the reaction when the component unmounts
      return () => dispose();
    }, []); // Empty dependency array ensures this effect runs only once

    useEffect(() => {
      const fetchPartnerChargePercentage = async () => {
        const percentage = await unifiedTaxCredits.getPartnerChargePercentage();
        setPartnerChargePercentage(percentage);
      };

      fetchPartnerChargePercentage();
    }, [unifiedTaxCredits]);

    const checkPaymentMethodAndSubmit = async () => {
      await app.common.companyStore.refreshCurrentCompany();
      if (app.common.companyStore.currentCompany.paymentMethodOnFile) {
        modules.toggleShowUnifiedCreditPaymentModal(false);
        onNext();
      } else {
        setError('Please add a payment method');
      }
    };

    const creditsWithFees = programData.programs.map((p) => {
      const taxTypeText =
        p.expectedCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX
          ? '(Payroll tax credit)'
          : '(Income tax credit)';

      const creditAmount =
        p.creditAmountCents === 0
          ? p.orderForm?.estimatedTotalCreditCents ?? 0
          : p.creditAmountCents;
      let fee = creditAmount * programData.feePercentage;

      // for R&D programs, show the monthly fee
      if (Programs[p.name].rd) {
        fee /= 12;
      }

      // build the display strings for fee amounts
      const feeString = FloatToDollarString(fee / 100);

      return {
        ...p,
        fee,
        feeString,
        taxTypeText,
        programName: p.name,
      };
    });

    const rdCredits = creditsWithFees.filter((p) => Programs[p.name].rd);
    const nonRdCredits = creditsWithFees.filter((p) => !Programs[p.name].rd);

    // number to be displayed in the "+ n others" text
    const numOtherNonRdCredits = nonRdCredits.length - 1;
    const numOthersText =
      numOtherNonRdCredits > 0
        ? `+ ${numOtherNonRdCredits} other${
            numOtherNonRdCredits > 1 ? 's' : ''
          }`
        : '';

    const rdCreditMonthlyFee = rdCredits.reduce((acc, p) => acc + p.fee, 0);
    const rdCreditTotalFeeText = FloatToDollarString(rdCreditMonthlyFee / 100);
    const nonRdCreditTotalFee = nonRdCredits.reduce((acc, p) => {
      if (
        guidelineReferral &&
        p.name === 'federal_retirement_plans_tax_program'
      ) {
        return acc;
      }
      return acc + p.fee;
    }, 0);
    const nonRdCreditTotalFeeText = FloatToDollarString(
      nonRdCreditTotalFee / 100,
    );

    return (
      <>
        <Modal
          dataTestId='unified-credit-payment-modal'
          showModal={modules.showUnifiedCreditPaymentModal}
          closeToggle={() => {
            modules.toggleShowUnifiedCreditPaymentModal(false);
          }}
          maxWidth={680}
          rounded
        >
          <Flex
            direction='column'
            padding={24}
            gap={16}
            className={classes.contentContainer}
          >
            {loading ? (
              <LoadingWidget />
            ) : (
              <>
                <Flex direction='column'>
                  <Heading tag='h4' variant='medium' marginBottom={4}>
                    You’re a step away from getting your forms!
                  </Heading>
                  <Text size={13} color={Color.neutral._80}>
                    Your final credit amount will be calculated when preparing
                    your forms. Once your forms are finalized and delivered, you
                    will be charged within 5 business days.
                  </Text>
                </Flex>
                <Flex
                  direction='row'
                  className={classes.estimatedCreditContainer}
                >
                  <Card className={classes.estimatedCreditCard} noMargin>
                    <Flex direction='column'>
                      <Text
                        variant='medium'
                        size={13}
                        className={classes.estimatedCreditTitle}
                      >
                        TOTAL ESTIMATED CREDIT
                      </Text>
                      <Text variant='medium' size={32}>
                        {FloatToDollarString(programData.totalCreditDollars)}
                      </Text>
                      <Text size={11} className={classes.estimatedCreditNote}>
                        Estimated amount you are eligible for
                      </Text>
                    </Flex>
                  </Card>
                  <Card className={classes.estimatedCreditCard} noMargin>
                    <Flex direction='column'>
                      <Text
                        variant='medium'
                        size={13}
                        className={classes.estimatedCreditTitle}
                      >
                        ESTIMATED MAINSTREET FEE
                      </Text>
                      <Text variant='medium' size={32}>
                        {FloatToDollarString(programData.totalFeeDollars)}
                      </Text>
                      <Text size={11} className={classes.estimatedCreditNote}>
                        We charge {partnerChargePercentage}{' '}
                        {partnerFinalCreditAmountText}
                      </Text>
                    </Flex>
                  </Card>
                </Flex>

                <Flex padding={[0, 24]} direction='column'>
                  {rdCredits.length > 0 && (
                    <>
                      <Flex padding={[0, 0, 4, 0]}>
                        <Flex direction='column'>
                          <Flex gap={8}>
                            <Text
                              variant='medium'
                              text='R&D Credits'
                              size={15}
                            />
                            <Text size={15} color={Color.purple._70}>
                              &bull; Monthly charge
                            </Text>
                          </Flex>
                          <Text
                            text='You will be billed this estimated amount monthly for the next 12 months'
                            size={13}
                            color={Color.neutral._60}
                          />
                          <Button
                            onClick={() => setRdExpand(!rdExpand)}
                            variant='tertiary'
                            className={classes.showCreditButton}
                            label={
                              <Flex gap={4}>
                                <Text
                                  size={13}
                                  color={Color.blue._60}
                                  variant='medium'
                                  text={
                                    rdExpand
                                      ? 'Hide credit breakdown'
                                      : 'Show credit breakdown'
                                  }
                                />
                                <Icon
                                  size={20}
                                  color={Color.blue._60}
                                  name={
                                    rdExpand
                                      ? IconEnum.chevron_up
                                      : IconEnum.chevron_down
                                  }
                                />
                              </Flex>
                            }
                          />
                        </Flex>
                        <Text variant='medium' size={23}>
                          {rdCreditTotalFeeText}
                        </Text>
                      </Flex>
                      <Expandable expand={rdExpand}>
                        <Flex
                          gap={8}
                          direction='column'
                          className={classes.creditContainer}
                        >
                          {rdCredits.map((c) => (
                            <Flex
                              key={c.id}
                              justifyContent='space-between'
                              padding={[8, 16]}
                            >
                              <Flex gap={8}>
                                <Text
                                  text={Programs[c.name].display}
                                  size={15}
                                />
                                <Text
                                  text={c.taxTypeText}
                                  variant='italic'
                                  size={13}
                                  color={Color.neutral._80}
                                />
                              </Flex>
                              <Text
                                text={c.feeString}
                                size={15}
                                variant='medium'
                              />
                            </Flex>
                          ))}
                        </Flex>
                      </Expandable>
                    </>
                  )}

                  {nonRdCredits.length > 0 && (
                    <>
                      <Flex padding={[16, 0, 4, 0]}>
                        <Flex direction='column'>
                          <Flex gap={8}>
                            <Text
                              variant='medium'
                              text={Programs[nonRdCredits[0].name].display}
                              size={15}
                            />
                            {numOthersText && (
                              <Text
                                text={numOthersText}
                                size={15}
                                color={Color.neutral._60}
                              />
                            )}
                            <Text size={15} color={Color.purple._70}>
                              &bull; One time payment
                            </Text>
                          </Flex>
                          <Text
                            text='You will be billed this estimated amount in full'
                            size={13}
                            color={Color.neutral._60}
                          />
                          <Button
                            onClick={() => setNonRdExpand(!nonRdExpand)}
                            variant='tertiary'
                            className={classes.showCreditButton}
                            label={
                              <Flex gap={4}>
                                <Text
                                  size={13}
                                  color={Color.blue._60}
                                  variant='medium'
                                  text={
                                    nonRdExpand
                                      ? 'Hide credit breakdown'
                                      : 'Show credit breakdown'
                                  }
                                />
                                <Icon
                                  size={20}
                                  color={Color.blue._60}
                                  name={
                                    nonRdExpand
                                      ? IconEnum.chevron_up
                                      : IconEnum.chevron_down
                                  }
                                />
                              </Flex>
                            }
                          />
                        </Flex>
                        <Text variant='medium' size={23}>
                          {nonRdCreditTotalFeeText}
                        </Text>
                      </Flex>
                      <Expandable expand={nonRdExpand}>
                        <Flex
                          gap={8}
                          direction='column'
                          className={classes.creditContainer}
                        >
                          {nonRdCredits.map((c) => (
                            <Flex
                              key={c.id}
                              justifyContent='space-between'
                              padding={[8, 16]}
                            >
                              <Flex gap={8}>
                                <Text
                                  text={Programs[c.name].display}
                                  size={15}
                                />
                                <Text
                                  text={c.taxTypeText}
                                  variant='italic'
                                  size={13}
                                  color={Color.neutral._80}
                                />
                              </Flex>
                              <Text
                                text={
                                  c.programName ===
                                    'federal_retirement_plans_tax_program' &&
                                  guidelineReferral ? (
                                    <Flex direction='row' gap={8}>
                                      <Text className={classes.freeText}>
                                        FREE
                                      </Text>{' '}
                                      <s>{c.feeString}</s>
                                    </Flex>
                                  ) : (
                                    c.feeString
                                  )
                                }
                                size={15}
                                variant='medium'
                              />
                            </Flex>
                          ))}
                        </Flex>
                      </Expandable>
                    </>
                  )}

                  <Flex padding={[16, 0]}>
                    <Callout
                      className={classes.callout}
                      title={
                        <Text
                          text={`The credit estimates above are non-binding and do not represent your final credit amount. If our estimates are off, we’ll adjust our fees after we finalize your credit.`}
                          size={13}
                          variant='medium'
                        />
                      }
                      color={Color.semantic.$warning50}
                      customTitleIcon={IconEnum.exclamation_triangle}
                    />
                  </Flex>
                </Flex>

                <Card noMargin>
                  {showChargebeePaymentMethod ? (
                    <ChargeBeePaymentForm />
                  ) : (
                    <AddPaymentMethod
                      showBankNotice={false}
                      setPaymentMethod={setPaymentMethod}
                      isModal
                    />
                  )}
                </Card>
                {error && (
                  <Flex>
                    <Alert
                      text={error}
                      variant={'in_card'}
                      type={'caution'}
                      inCardBorder={'left'}
                    />
                  </Flex>
                )}
                {paymentMethod && (
                  <Flex justifyContent='flex-end' gap={16}>
                    <Button
                      label={'Submit'}
                      onClick={async () => {
                        await checkPaymentMethodAndSubmit();
                      }}
                    />
                  </Flex>
                )}
              </>
            )}
          </Flex>
        </Modal>
        <Modal
          showModal={showErrorModal}
          closeToggle={() => setShowErrorModal(false)}
          backdrop={'static'}
        >
          <>
            <Content paddingTopBottom={24} paddingLeftRight={24}>
              <Text
                variant='medium'
                size={18}
                text='Problem loading information'
              />
              <Text dataTestId='error-message'>
                We had an issue loading your data. Please refresh the page to
                try again. If this issue persists, please contact{' '}
                <a href='mailto:support@mainstreet.com'>
                  support@mainstreet.com
                </a>
                .
              </Text>
            </Content>
            <Content
              gap={16}
              flex
              paddingLeftRight={24}
              alignItems={'center'}
              justifyContent={'center' as any}
            >
              <Button
                variant='outlined'
                label='Retry'
                flexAlignSelf={'center'}
                onClick={{
                  location: history.location.pathname,
                  external: true,
                  target: '_self',
                }}
              />
            </Content>
          </>
        </Modal>
      </>
    );
  },
);
