import React from 'react';
import { useState } from 'react';
import { Card, Content } from 'component-library';
import { YearFormat } from 'components/YearFormat';
import {
  makeStyles,
  Theme,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { QuestionProps } from 'lib/interfaces';
import { InputFormatTypeEnum } from '../../lib/constants';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  cardInfo: {
    fontSize: '20px',
    lineHeight: '32px',
    [breakpoints.down('xs')]: {
      fontSize: '18px',
      lineHeight: '25px',
    },
  },
  textField: {
    marginTop: '30px',
    width: '300px',
    [breakpoints.down('xs')]: {
      width: '250px',
    },

    '& p': {
      margin: '4px 0 0 0',
    },
    checkboxContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      marginTop: '30px',
    },
  },
}));

interface Props {
  question: QuestionProps;
  type: 'number_checkbox';
  format?: InputFormatTypeEnum;
  withCard?: boolean;
  className?: string;
  errorMessage?: string | null;
  onChange: (value: string) => void;
}

export const NumberCheckboxQuestion = ({
  question,
  format,
  withCard,
  className,
  errorMessage,
  onChange,
}: Props) => {
  const classes = useStyles();

  const RenderQuestion = () => {
    const [checked, setChecked] = useState<boolean>(question.value === '0');
    const [textValue, setTextValue] = useState<string>(() =>
      typeof question.value === 'string' ? question.value : '',
    );

    const onCheckChange = (_: any, checked: boolean) => {
      setChecked(checked);
      if (checked) {
        onChange('0');
      } else {
        onChange(textValue);
      }
    };

    const onTextChange = (event: any) => {
      setTextValue(event.target.value);
      onChange(event.target.value);
    };

    return (
      <>
        <Typography className={classes.cardInfo}>{question.text}</Typography>
        {format === InputFormatTypeEnum.YEAR && (
          <TextField
            className={classes.textField}
            variant='outlined'
            inputMode='numeric'
            label={question.label}
            value={textValue}
            InputProps={{
              inputComponent: YearFormat as any,
            }}
            onChange={onTextChange}
            error={!!errorMessage}
            helperText={errorMessage}
            disabled={checked}
          />
        )}
        <div>
          <FormControlLabel
            label="I'm not sure"
            control={
              <Checkbox
                color='primary'
                checked={checked}
                name="I'm not sure"
                onChange={onCheckChange}
              />
            }
          />
        </div>
      </>
    );
  };

  return (
    <div className={className}>
      {withCard ? (
        <Card>
          <Content>
            <RenderQuestion />
          </Content>
        </Card>
      ) : (
        <RenderQuestion />
      )}
    </div>
  );
};
