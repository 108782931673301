import { observer } from 'mobx-react';
import {
  useCommonStores,
  useFeatureFlags,
  useHistoryStore,
} from 'stores/useStores';
import { useEffectOnce } from 'lib/helpers';
import { Link, Text } from 'component-library';
import { Page } from 'lib/constants';
import React from 'react';
import { AccountModuleSection } from 'pages/dashboard/components/AccountModule/AccountModule';

const SettingsSection = observer(() => {
  const { auth } = useCommonStores();
  const history = useHistoryStore();
  const featureFlags = useFeatureFlags();
  const enableAuth0Invites = featureFlags.enableAuth0Invites;

  useEffectOnce(async () => await auth.fetchOrgMembers());

  return (
    <AccountModuleSection title={'Settings'}>
      <Text>
        {auth.members?.length ?? 0} member
        {auth.members?.length === 1 ? '' : 's'}
      </Text>
      <>
        {enableAuth0Invites && (
          <Link
            text={'Invite'}
            size={15}
            variant={'medium'}
            dataTestId={'dashboard-account-module-settings-section-invite-btn'}
            onClick={() => history.push(`/${Page.settings}?invite=1`)}
          />
        )}
      </>
    </AccountModuleSection>
  );
});

export default SettingsSection;
