import React from 'react';

interface Props {
  className?: string;
  color?: 'gray' | 'green';
}

const MagicCredits = ({ className, color }: Props) => {
  if (color === 'gray') {
    return (
      <svg
        width='34'
        height='27'
        viewBox='0 0 34 27'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M25.6209 20.3343H27.8334C28.0544 20.3343 28.2664 20.2465 28.4226 20.0902C28.5789 19.9339 28.6667 19.7219 28.6667 19.5009V17.2895C28.6667 17.0706 28.6237 16.8538 28.5399 16.6516C28.4562 16.4494 28.3334 16.2656 28.1787 16.1108L16.125 4.05666L12.3907 7.79104L24.4422 19.8457C24.597 20.0006 24.7807 20.1234 24.983 20.2072C25.1852 20.2911 25.402 20.3342 25.6209 20.3343ZM11.2136 6.61396L14.948 2.87437L12.7709 0.698851C12.5359 0.466348 12.2186 0.335937 11.8881 0.335938C11.5575 0.335938 11.2402 0.466348 11.0052 0.698851L9.03129 2.67125C8.79911 2.90536 8.66883 3.22173 8.66883 3.55146C8.66883 3.88118 8.79911 4.19755 9.03129 4.43166L11.2136 6.61396ZM7.00004 19.5009C7.00004 19.7219 7.08784 19.9339 7.24412 20.0902C7.4004 20.2465 7.61236 20.3343 7.83337 20.3343H22.573L20.073 17.8343H7.83337C7.61236 17.8343 7.4004 17.9221 7.24412 18.0783C7.08784 18.2346 7.00004 18.4466 7.00004 18.6676V19.5009ZM32 7.00094H21.4271L23.9271 9.50094H31.1667V24.5009H2.83337V9.50094H11.7433L11.212 8.96969L10.0349 7.7926L9.24327 7.00094H2.00004C1.55801 7.00094 1.13409 7.17653 0.821529 7.48909C0.508969 7.80165 0.333374 8.22557 0.333374 8.6676L0.333374 25.3343C0.333374 25.7763 0.508969 26.2002 0.821529 26.5128C1.13409 26.8253 1.55801 27.0009 2.00004 27.0009H32C32.4421 27.0009 32.866 26.8253 33.1786 26.5128C33.4911 26.2002 33.6667 25.7763 33.6667 25.3343V8.6676C33.6667 8.22557 33.4911 7.80165 33.1786 7.48909C32.866 7.17653 32.4421 7.00094 32 7.00094V7.00094ZM7.83337 16.1676H18.4063L15.9063 13.6676H7.83337C7.61236 13.6676 7.4004 13.7554 7.24412 13.9117C7.08784 14.068 7.00004 14.2799 7.00004 14.5009V15.3343C7.00004 15.5553 7.08784 15.7672 7.24412 15.9235C7.4004 16.0798 7.61236 16.1676 7.83337 16.1676Z'
          fill='#102736'
        />
      </svg>
    );
  }

  return (
    <svg
      className={className || ''}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.8967 18.6674H24.6667C24.8436 18.6674 25.0131 18.5971 25.1382 18.4721C25.2632 18.3471 25.3334 18.1775 25.3334 18.0007V16.2315C25.3334 16.0564 25.299 15.883 25.232 15.7212C25.165 15.5595 25.0668 15.4124 24.943 15.2886L15.3001 5.64528L12.3126 8.63278L21.9538 18.2765C22.0776 18.4004 22.2246 18.4987 22.3864 18.5658C22.5482 18.6328 22.7216 18.6673 22.8967 18.6674ZM11.3709 7.69112L14.3584 4.69945L12.6167 2.95903C12.4287 2.77303 12.175 2.6687 11.9105 2.6687C11.646 2.6687 11.3922 2.77303 11.2042 2.95903L9.62508 4.53695C9.43934 4.72424 9.33512 4.97734 9.33512 5.24112C9.33512 5.50489 9.43934 5.75799 9.62508 5.94528L11.3709 7.69112ZM8.00008 18.0007C8.00008 18.1775 8.07032 18.3471 8.19534 18.4721C8.32037 18.5971 8.48994 18.6674 8.66675 18.6674H20.4584L18.4584 16.6674H8.66675C8.48994 16.6674 8.32037 16.7376 8.19534 16.8626C8.07032 16.9877 8.00008 17.1572 8.00008 17.334V18.0007ZM28.0001 8.0007H19.5417L21.5417 10.0007H27.3334V22.0007H4.66675V10.0007H11.7947L11.3697 9.5757L10.428 8.63403L9.79467 8.0007H4.00008C3.64646 8.0007 3.30732 8.14118 3.05727 8.39122C2.80722 8.64127 2.66675 8.98041 2.66675 9.33403L2.66675 22.6674C2.66675 23.021 2.80722 23.3601 3.05727 23.6102C3.30732 23.8602 3.64646 24.0007 4.00008 24.0007H28.0001C28.3537 24.0007 28.6928 23.8602 28.9429 23.6102C29.1929 23.3601 29.3334 23.021 29.3334 22.6674V9.33403C29.3334 8.98041 29.1929 8.64127 28.9429 8.39122C28.6928 8.14118 28.3537 8.0007 28.0001 8.0007V8.0007ZM8.66675 15.334H17.1251L15.1251 13.334H8.66675C8.48994 13.334 8.32037 13.4043 8.19534 13.5293C8.07032 13.6543 8.00008 13.8239 8.00008 14.0007V14.6674C8.00008 14.8442 8.07032 15.0137 8.19534 15.1388C8.32037 15.2638 8.48994 15.334 8.66675 15.334Z'
        fill='#056037'
      />
    </svg>
  );
};

export default MagicCredits;
