import React, { useEffect, useState } from 'react';
import { Animate, Flex } from 'component-library';
import {
  useCommonStores,
  useLegacyClients,
  useTaxCreditsStores,
} from '../../../../../stores/useStores';
import { makeStyles } from '@material-ui/core';
import {
  BeneficialOwnershipQuestions,
  BeneficialOwnershipQuestionsToAnswers,
  QualificationModalStatusEnum,
  SurveyNameEnum,
} from '../../../../../lib/constants';
import { BoirNotNow } from './BoirNotNow';
import { BoirRequired } from './BoirRequired';
import { useSurveyQuestions } from '../../../../../lib/useSurveyQuestions';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    margin: '0 auto',
    position: 'relative',
  },
}));

export const BoirNextSteps = observer(() => {
  const classes = useStyles();
  const { surveyFlow } = useTaxCreditsStores();
  const { companyStore } = useCommonStores();
  const [loading, setLoading] = useState(true);
  const [hasBoirDq, setHasBoirDQ] = useState<boolean>();
  const [hasPrefillBoirDq, setHasPrefillBoirDQ] = useState<boolean>();
  const { client } = useLegacyClients();

  const surveyName: SurveyNameEnum =
    SurveyNameEnum.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT;

  const { surveyAnswers, isLoading, addSurveyAnswersWithoutRerender } =
    useSurveyQuestions(surveyName, new Date().getFullYear(), undefined);

  // Extract pre-fill answer from the URL, if it exists
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const prefillAnswerId = queryParams.get('answer');

  // Update survey with the pre-fill answer extracted from the URL
  useEffect(() => {
    if (prefillAnswerId) {
      // Check that the answer matches "No" to eligible conditions question:
      const eligibleConditionsQuestionId =
        BeneficialOwnershipQuestions.DO_ANY_ELIGIBLE_CONDITIONS_APPLY;
      const answerId =
        BeneficialOwnershipQuestionsToAnswers[eligibleConditionsQuestionId].NO;

      if (prefillAnswerId === answerId) {
        setHasPrefillBoirDQ(true);
        addSurveyAnswersWithoutRerender([
          { questionId: eligibleConditionsQuestionId, answerValue: answerId },
        ]);
        client.UpdateCompanyWithSurveyResponsePublic(
          // @ts-ignore TS not correctly handling that value exists within ternery
          companyStore.accessToken,
          surveyName,
          {
            taxYear: new Date().getFullYear(),
            qualificationQuestions: surveyAnswers,
          },
        );
      }
    }
  }, [isLoading]);

  useEffect(() => {
    surveyFlow.checkQualificationStatus(surveyAnswers);

    setLoading(false);

    setHasBoirDQ(
      hasPrefillBoirDq ||
        !!(
          surveyFlow.qualificationStatus &&
          surveyFlow.qualificationStatus ===
            QualificationModalStatusEnum.BENEFICIAL_OWNERSHIP_DQ
        ),
    );
  }, [surveyFlow, surveyAnswers, hasPrefillBoirDq]);

  return (
    <Flex
      direction='column'
      className={classes.root}
      data-testid={'beneficial-ownership'}
    >
      <Flex className={classes.mainContent} direction='column'>
        {!loading && (
          <Animate enter={'fade-in'}>
            {hasBoirDq ? <BoirNotNow /> : <BoirRequired />}
          </Animate>
        )}
      </Flex>
    </Flex>
  );
});
