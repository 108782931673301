import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { FormHelperText, makeStyles, Typography } from '@material-ui/core';
import { Button } from 'component-library';
import { Formik } from 'formik';
import { CompanyContext } from './CompanyRequired';
import { useHistory } from 'react-router-dom';
import { Page, SurveyNameEnum } from '../lib/constants';
import { UpdateCompanyWithSurveyResponse } from '../services/server';
import useGetSurveyQuestionsBySurveyName from '../lib/useGetSurveyQuestionsBySurveyName';
import { QuestionProps } from '../lib/interfaces';
import { getCmsQuestionOptions, getCmsQuestionText } from '../lib/helpers';
import LoadingWidget from '../components/util/LoadingWidget';
import { SurveyQuestions } from '../components/SurveyQuestions';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 80px',
    maxWidth: '1050px',
  },
  header: {
    fontFamily: "'Alliance Platt', serif",
    margin: '20px 0 85px',
    fontSize: '50px',
  },
  nextPageButton: {
    margin: '20px 0',
    width: '180px',
    padding: '0 40px',
    height: '50px',
    fontSize: '18px',
    fontWeight: 500,
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  formErrorMsg: {
    marginTop: '15px',
    textAlign: 'center',
    marginLeft: '-180px',
  },
}));

interface DisasterReliefQualifyingBusinessFlow2Props {
  qualificationTaxYear: number;
}

export default function DisasterReliefQualifyingBusinessFlow({
  qualificationTaxYear,
}: DisasterReliefQualifyingBusinessFlow2Props) {
  const classes = useStyles();
  const history = useHistory();
  const { company, setCompany } = useContext(CompanyContext);
  const { qualificationQuestionsByYear } = company;
  const [continueDisabled, setContinueDisabled] = useState<boolean>(false);
  const [questionBank, setQuestionBank] = useState<QuestionProps[]>([
    {
      id: 0,
      type: '',
      text: 'fake',
      options: undefined,
      value: '',
      active: true,
      callback: undefined,
      subtitle: '',
    },
  ]);

  const {
    errorMsg,
    isLoading,
    qualificationQuestions,
  } = useGetSurveyQuestionsBySurveyName(
    SurveyNameEnum.SECTION_139_QUALIFICATION_V2,
  );

  useEffect(() => {
    const bank: QuestionProps[] = Object.keys(qualificationQuestions).map(
      (questionKey, index) => {
        const question = qualificationQuestions[questionKey];
        const { qualificationQuestionsByYear } = company;
        const answer =
          qualificationQuestionsByYear != null
            ? qualificationQuestionsByYear[qualificationTaxYear][questionKey]
            : '';

        return {
          id: index,
          type: question.answerType,
          text: getCmsQuestionText(question),
          options: getCmsQuestionOptions(question),
          value: answer,
          active: true,
          callback: undefined,
          label: question.tooltip,
          subtitle: question.subtitle,
          cmsId: question.id,
          cmsAnswerData: question.answerIDs,
        };
      },
    );

    setQuestionBank(bank);
  }, [company, qualificationQuestions, qualificationTaxYear]);

  useEffect(() => {
    const unansweredQuestions = questionBank.reduce(
      (acc: boolean, question: QuestionProps) => acc || question.value === '',
      false,
    );
    setContinueDisabled(unansweredQuestions);
  }, [questionBank]);

  const setAnswer = (newValue: string, id: number) => {
    const bank = questionBank.map((q) => {
      return q.id === id ? { ...q, value: newValue } : q;
    });

    setQuestionBank(bank);
  };

  const saveAnswers = () => {
    const formValues = questionBank.reduce(
      (
        newFormValues: { [key: string]: string | number | Array<any> },
        question,
      ) => {
        if (question.cmsId && question.value != null) {
          newFormValues[question.cmsId] = question.value;
        }
        return newFormValues;
      },
      {},
    );

    const surveyResponse = {
      taxYear: qualificationTaxYear,
      qualificationQuestions: formValues,
    };

    setCompany({
      ...company,
      qualificationQuestionsByYear: {
        ...qualificationQuestionsByYear,
        [qualificationTaxYear]: { ...formValues },
      },
    });

    UpdateCompanyWithSurveyResponse(
      company.id,
      SurveyNameEnum.SECTION_139_QUALIFICATION_V2,
      surveyResponse,
    );
  };

  const employeesPagePath = `/${Page.employees139Dashboard}/`;

  const submit = () => {
    saveAnswers();
    history.push(employeesPagePath);
  };

  return (
    <div className={classes.root}>
      {isLoading ? (
        <LoadingWidget />
      ) : (
        <Formik
          initialValues={{}}
          onSubmit={() => {
            submit();
          }}
          // eslint-disable-next-line react/no-children-prop
          children={({ handleSubmit }) => (
            <div className={classes.mainContent}>
              <Typography variant='h1' className={classes.header}>
                Finalize your Section 139 Relief Plan
              </Typography>
              <SurveyQuestions
                questionBank={questionBank}
                setAnswer={setAnswer}
              />
              <Button
                className={classes.nextPageButton}
                label='CONTINUE'
                disabled={continueDisabled}
                onClick={() => {
                  handleSubmit();
                }}
              />
            </div>
          )}
        />
      )}

      {errorMsg && (
        <FormHelperText className={classes.formErrorMsg} error>
          {errorMsg}
        </FormHelperText>
      )}
    </div>
  );
}
