import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Animate,
  Button,
  Color,
  Flex,
  Grid,
  Heading,
  Text,
} from 'component-library';
import { AnimatedGradient } from 'products/onboarding/components';
import { useCommonStores, useCompany } from 'stores/useStores';
import TitleHeader from 'components/TitleHeader';
import { Page } from 'lib/constants';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: Color.neutral.white,
    position: 'relative',
  },
  mainContent: {
    width: '100%',
    minHeight: 'calc(100vh - 64px)',
    position: 'relative',
    zIndex: 1,
    display: 'flex',
  },
  content: {
    opacity: 1,
    transition: 'opacity .4s',
  },
  container: {
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',

    '& span': {
      lineHeight: '1.4em !important',
    },
  },
}));

export const IneligibleCreditEstimates = observer(() => {
  const classes = useStyles();
  const { app } = useCommonStores();
  const { company } = useCompany();

  const onContinue = () => {
    app.history.push(`/${Page.accountCreationConnectingPayrollAccounting}`);
  };

  return (
    <Animate enter={'fade-in'} duration={0.25} className={classes.wrapper}>
      <AnimatedGradient />
      <Animate enter={['fade-in', 'from-top']}>
        <TitleHeader borderBottom />
      </Animate>
      <Animate className={classes.mainContent} enter={'fade-in'} delay={0.25}>
        <Flex className={classes.content} alignItems='center'>
          <Grid
            className={classes.container}
            columns={12}
            padding={app.isMobile ? [24, 24, 48, 24] : [0, 24, 48, 24]}
            gap={app.isMobile ? 16 : 0}
            justifyContent='center'
          >
            {app.isMobile ? <></> : <Grid.Cell columns={2} />}
            <Grid.Cell columns={app.isMobile ? 12 : 8} justifySelf='center'>
              <Heading tag={app.isMobile ? 'h4' : 'h3'}>
                We couldn&apos;t identify any eligible credits for{' '}
                {company.name} at this time
              </Heading>
              <Flex
                padding={app.isMobile ? [0, 24] : [0, 40]}
                justifyContent='center'
                direction='column'
                gap={32}
              >
                <Text size={app.isMobile ? 15 : 18}>
                  More information will help us find more credits in the future
                  and we will check every day on your behalf to find all the
                  savings available to your business.
                </Text>
                <Button
                  label='Continue'
                  onClick={onContinue}
                  flexAlignSelf='center'
                />
              </Flex>
            </Grid.Cell>
            {app.isMobile ? <></> : <Grid.Cell columns={2} />}
          </Grid>
        </Flex>
      </Animate>
    </Animate>
  );
});
