import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Alert,
  Animate,
  Button,
  Checkbox,
  Color,
  Divider,
  Flex,
  Text,
} from 'component-library';
import TitleHeader from 'components/TitleHeader';
import { PleaseWaitModal } from './PleaseWaitModal';
import { useCompany, useTaxCreditsStores } from 'stores/useStores';
import { useEffectOnce } from 'lib/helpers';

interface QuarterlyRedemptionPageContainerProps {
  children: React.ReactElement | JSX.Element;
  title: string;
  infoTitle: string;
  infoContent: string;
  onContinue: () => void;
  onDownloadPage?: boolean;
  onEnterMissedDeadlinePage?: boolean;
  hasEnteredAllTaxValues?: boolean;
  setHasEnteredAllTaxValues?: (bool: boolean) => void;
  showExitButton?: boolean;
}

const useStyles = makeStyles(() => ({
  mainContainer: {
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
  },
  checkbox: {
    maxWidth: '700px',
    '& label': {
      'margin-bottom': 0,
    },
  },
}));

export const QuarterlyRedemptionPageContainer = observer(
  ({
    children,
    title,
    infoTitle,
    infoContent,
    onContinue,
    onDownloadPage,
    onEnterMissedDeadlinePage,
    hasEnteredAllTaxValues = false,
    setHasEnteredAllTaxValues,
    showExitButton = true,
  }: QuarterlyRedemptionPageContainerProps) => {
    const { form8974, redemption } = useTaxCreditsStores();
    const { company } = useCompany();
    const classes = useStyles();

    useEffectOnce(() => form8974.fetchCompanyPayrollProvider(company.id));

    return (
      <Animate enter={'fade-in'}>
        <TitleHeader
          title='Missed quarterly redemption'
          showExitButton={showExitButton}
          borderBottom
          onCancelExitText='Cancel'
        />
        <Flex
          direction='column'
          padding={[40, 24, 80, 24]}
          className={classes.mainContainer}
          gap={24}
        >
          <Text text={title} variant='medium' size={32} />
          <Alert
            text={
              <Flex direction='column'>
                <Text variant='medium' size={15}>
                  {infoTitle}
                </Text>
                <Text size={15}>{infoContent}</Text>
              </Flex>
            }
            type='info'
            variant='card'
            backgroundColor={Color.blue._10}
          />
          <Divider variant='no-bottom-margin' />

          {children}

          {onDownloadPage && redemption.setHasDownloadedForm8974 && (
            <>
              <Divider variant='no-bottom-margin' />
              <Checkbox
                dataTestId='confirmation-checkbox'
                className={classes.checkbox}
                onChange={() =>
                  redemption.setHasDownloadedForm8974(
                    !redemption.hasDownloadedForm8974,
                  )
                }
                options={[
                  {
                    checked: redemption.hasDownloadedForm8974,
                    text: `I hereby confirm I have downloaded the forms above, and will follow all instructions on this screen to redeem my credits.`,
                    value: 'confirmed',
                  },
                ]}
              />
            </>
          )}

          {onEnterMissedDeadlinePage && setHasEnteredAllTaxValues && (
            <>
              <Divider variant='no-bottom-margin' />
              <Checkbox
                dataTestId='confirmation-checkbox'
                className={classes.checkbox}
                onChange={() =>
                  setHasEnteredAllTaxValues(!hasEnteredAllTaxValues)
                }
                options={[
                  {
                    checked: hasEnteredAllTaxValues,
                    text: `I hereby confirm that I have entered all correct values in each quarter(s) for social security and medicare taxes, and have reviewed all payroll tax values to generate Form 8974.`,
                    value: 'confirmed',
                  },
                ]}
              />
            </>
          )}
          <Button
            dataTestId={
              onDownloadPage
                ? 'quarterly-redemption-8974-continue-button'
                : 'quarterly-redemption-941-continue-button'
            }
            label='Continue'
            onClick={onContinue}
            disabled={
              redemption.hasDownloadedForm8974
                ? !redemption.hasDownloadedForm8974
                : !hasEnteredAllTaxValues
            }
            flexAlignSelf='flex-end'
            loading={redemption.loadingOnContinue}
          />
        </Flex>
        {redemption.loadingOnContinue && onEnterMissedDeadlinePage && (
          <Animate enter='fade-in'>
            <PleaseWaitModal />
          </Animate>
        )}
      </Animate>
    );
  },
);
