import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { logContext } from 'logging';
import _ from 'lodash';

enum DatadogLogContextKeys {
  COMPANY_ID = 'companyId',
  USER_EMAIL = 'userEmail',
  PARTNER_REFERRAL = 'partnerReferral',
  HAS_PAYROLL_CONNECTED = 'hasPayrollConnected',
  NEW_CUSTOMER = 'newCustomer',
  EXISTING_CUSTOMER = 'existingCustomer',
}

/**
 * Sets the logger and RUM context for a given optional user. Only
 * setting if the provided email is defined AND non-empty
 */
export function setDatadogUserContext(userEmail?: string) {
  try {
    datadogLogs.removeLoggerGlobalContext(DatadogLogContextKeys.USER_EMAIL);
    datadogRum.removeUser();

    if (userEmail) {
      if (!_.isEmpty(userEmail)) {
        datadogLogs.addLoggerGlobalContext(
          DatadogLogContextKeys.USER_EMAIL,
          userEmail,
        );

        datadogRum.setUser({
          email: userEmail,
        });
      } else {
        datadogLogs.logger.warn(
          'Skipping user email context set due to empty provided string',
          logContext({
            stack: new Error().stack,
          }),
        );
      }
    }
  } catch (e) {
    datadogLogs.logger.error(
      'Unable to set Datadog user context',
      logContext({
        error: e,
      }),
    );
  }
}

/**
 * Ensure that we can filter Datadog logs and RUM sessions by company. To
 * be called whenever the current company changes
 */
export function setDatadogCompanyContext(
  companyId?: number,
  options?: {
    partnerReferral?: string;
    hasPayrollConnected?: boolean;
    newCustomer?: boolean;
    existingCustomer?: boolean;
  },
) {
  try {
    datadogLogs.removeLoggerGlobalContext(DatadogLogContextKeys.COMPANY_ID);
    datadogRum.removeRumGlobalContext(DatadogLogContextKeys.COMPANY_ID);
    datadogLogs.removeLoggerGlobalContext(
      DatadogLogContextKeys.PARTNER_REFERRAL,
    );
    datadogRum.removeRumGlobalContext(DatadogLogContextKeys.PARTNER_REFERRAL);
    datadogLogs.removeLoggerGlobalContext(
      DatadogLogContextKeys.HAS_PAYROLL_CONNECTED,
    );
    datadogRum.removeRumGlobalContext(
      DatadogLogContextKeys.HAS_PAYROLL_CONNECTED,
    );
    datadogLogs.removeLoggerGlobalContext(DatadogLogContextKeys.NEW_CUSTOMER);
    datadogRum.removeRumGlobalContext(DatadogLogContextKeys.NEW_CUSTOMER);
    datadogLogs.removeLoggerGlobalContext(
      DatadogLogContextKeys.EXISTING_CUSTOMER,
    );
    datadogRum.removeRumGlobalContext(DatadogLogContextKeys.EXISTING_CUSTOMER);

    if (companyId) {
      datadogLogs.addLoggerGlobalContext(
        DatadogLogContextKeys.COMPANY_ID,
        companyId,
      );
      datadogRum.addRumGlobalContext(
        DatadogLogContextKeys.COMPANY_ID,
        companyId,
      );
    }

    if (options?.partnerReferral) {
      datadogLogs.addLoggerGlobalContext(
        DatadogLogContextKeys.PARTNER_REFERRAL,
        options.partnerReferral,
      );
      datadogRum.addRumGlobalContext(
        DatadogLogContextKeys.PARTNER_REFERRAL,
        options.partnerReferral,
      );
    }

    if (options?.hasPayrollConnected) {
      datadogLogs.addLoggerGlobalContext(
        DatadogLogContextKeys.HAS_PAYROLL_CONNECTED,
        options.hasPayrollConnected,
      );
      datadogRum.addRumGlobalContext(
        DatadogLogContextKeys.HAS_PAYROLL_CONNECTED,
        options.hasPayrollConnected,
      );
    }

    if (options?.newCustomer) {
      datadogLogs.addLoggerGlobalContext(
        DatadogLogContextKeys.NEW_CUSTOMER,
        options.newCustomer,
      );
      datadogRum.addRumGlobalContext(
        DatadogLogContextKeys.NEW_CUSTOMER,
        options.newCustomer,
      );
    }

    if (options?.existingCustomer) {
      datadogLogs.addLoggerGlobalContext(
        DatadogLogContextKeys.EXISTING_CUSTOMER,
        options.existingCustomer,
      );
      datadogRum.addRumGlobalContext(
        DatadogLogContextKeys.EXISTING_CUSTOMER,
        options.existingCustomer,
      );
    }
  } catch (e) {
    datadogLogs.logger.error(
      'Unable to set Datadog context',
      logContext({
        error: e,
        company: { id: companyId },
      }),
    );
  }
}
