import React, { useContext, useState } from 'react';
import {
  Button,
  Color,
  Content,
  Link,
  Text,
  TextField,
} from 'component-library';
import { Auth0FeatureContext } from '../../../../components/util/Auth0Feature';
import AnvilSignatureFrame from '@anvilco/react-signature-frame';
import { makeStyles } from '@material-ui/core/styles';
import {
  PromissoryNote,
  SignPromissoryNoteReqBody,
} from '../../../../lib/interfaces';
import { CentsToDisplayString } from 'lib/helpers';
import { YIELD_TEAM_EMAIL } from 'lib/constants/treasuryManagementConstants';
import LoadingWidget from '../../../../components/util/LoadingWidget';

enum SignatureFlowStepEnum {
  CONFIRMATION = 'confirmation',
  SIGNATURE = 'signature',
  SUCCESS = 'success',
}

interface SignatureFlowModalProps {
  promissoryNote: PromissoryNote;
  onFinishSuccess: () => void;
}
const useStyles = makeStyles(() => ({
  root: {
    width: '536px',
  },
}));

export const SignatureFlowModalContent = ({
  promissoryNote,
  onFinishSuccess,
}: SignatureFlowModalProps) => {
  const classes = useStyles();
  const { client } = useContext(Auth0FeatureContext);
  const { fixedReturnPercent, originalPrincipalAmountCents } = promissoryNote;
  const [currentStep, setCurrentStep] = useState<string>(
    SignatureFlowStepEnum.CONFIRMATION,
  );
  const [legalName, setLegalName] = useState<string | undefined>(undefined);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [signUrl, setSignUrl] = useState<string | undefined>(undefined);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const [contentLoading, setContentLoading] = useState<boolean>(false);

  const isConfirmButtonEnabled = legalName !== undefined && title !== undefined;

  const handleConfirmAmount = async () => {
    const signerReq: SignPromissoryNoteReqBody = {
      signerName: legalName!,
      signerTitle: title!,
    };
    setContentLoading(true);
    const { data, errorMsg } = await client.CreatePromissoryNoteSignUrl(
      signerReq,
    );
    setContentLoading(false);
    if (!data?.signatureURL || errorMsg) {
      setErrorMsg('There was an issue with submitting your signature request.');
      return;
    }
    setSignUrl(data.signatureURL);
    setCurrentStep('signature');
  };

  const handleSignSuccess = async () => {
    setCurrentStep('success');
    const signatureSuccessReq: SignPromissoryNoteReqBody = {
      signerName: legalName!,
      signerTitle: title!,
    };

    await client.SavePromissoryNoteSignatureSuceess(signatureSuccessReq);
  };

  const handleFinish = () => {
    onFinishSuccess();
  };

  const handleSignError = () => {
    console.log('Anvil failed us, you must not.');
  };

  return (
    <>
      {contentLoading && (
        <>
          <LoadingWidget />
          <br />
          <br />
          <br />
        </>
      )}
      {!contentLoading && currentStep === SignatureFlowStepEnum.CONFIRMATION && (
        <Content
          className={classes.root}
          paddingLeftRight={24}
          paddingTopBottom={24}
          flex
          flexDirection={'column'}
        >
          <Text
            size={18}
            text='Investment confirmation'
            variant='medium'
            paddingBottom={8}
          />
          <Text
            size={15}
            text="Please confirm your investment and signatory details below. You're one step away from putting your funds to work!"
          />
          <Content>
            <Content>
              <Content flex alignItems={'center'}>
                <Text size={15} text={`Amount:  `} variant='medium' />
                <Text
                  size={18}
                  text={CentsToDisplayString(originalPrincipalAmountCents)}
                  variant='medium'
                />
              </Content>
              <Text
                color={Color.neutral._80}
                size={15}
                text={
                  <>
                    If this amount is incorrect, email us at{' '}
                    <Link
                      href={`mailto:${YIELD_TEAM_EMAIL}`}
                      text={YIELD_TEAM_EMAIL}
                    />
                  </>
                }
              />
            </Content>

            <Content paddingLeftRight={0} paddingTopBottom={8}>
              <Content flex alignItems={'center'}>
                <Text size={15} text='Yield:' variant='medium' />
                <Text
                  size={18}
                  text={`${((fixedReturnPercent || 0) / 100).toLocaleString(
                    'en',
                    {
                      style: 'percent',
                      minimumFractionDigits: 1,
                    },
                  )} APY`}
                  variant='medium'
                />
              </Content>
              <Text
                color={Color.neutral._80}
                size={15}
                text='Applicable yield may vary over time.'
              />
            </Content>
          </Content>
          <TextField
            label='Signatory full legal name'
            name='Signatory full legal name'
            onChange={(event) => setLegalName(event.target.value)}
            placeholder=''
            value={legalName}
            disableBrowserAutofill={true}
          />
          <TextField
            label='Signatory title'
            name='Signatory title'
            onChange={(event) => setTitle(event.target.value)}
            placeholder=''
            value={title}
            disableBrowserAutofill={true}
          />
          {true && <Text size={15} status='failed' text={errorMsg} />}
          <Button
            label='Confirm and continue'
            onClick={handleConfirmAmount}
            disabled={!isConfirmButtonEnabled}
            flexAlignSelf={'flex-start'}
          />
        </Content>
      )}
      {!contentLoading && currentStep === SignatureFlowStepEnum.SIGNATURE && (
        <AnvilSignatureFrame
          signURL={signUrl}
          onError={handleSignError}
          onFinishSigning={handleSignSuccess}
          scroll='smooth'
          enableDefaultStyles={false}
          style={{
            minWidth: 536,
            maxWidth: 890,
            width: '80vw',
            height: '85vh',
            border: 'none',
          }}
        />
      )}
      {!contentLoading && currentStep === SignatureFlowStepEnum.SUCCESS && (
        <Content
          paddingLeftRight={24}
          paddingTopBottom={24}
          flex
          flexDirection={'column'}
        >
          <Text
            size={18}
            text='Signature submitted'
            variant='medium'
            paddingBottom={8}
          />
          <Text
            size={15}
            text='Congratulations! Your High Yield Account is active and ready to receive funds. Please complete your wire within five days. Your signed documents will be available in your Documents tab shortly.'
            paddingBottom={8}
          />
          <Button
            label='View wire instructions'
            onClick={handleFinish}
            flexAlignSelf={'flex-start'}
          />
        </Content>
      )}
    </>
  );
};
