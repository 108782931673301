import React from 'react';
import { makeStyles } from '@material-ui/core';
import { CreditCard } from '@mainstreet/client-models/financial/payments/paymentsModels';
import { GetCardInfo } from 'lib/cardInfo';

const useStyles = makeStyles({
  cardContainer: {
    height: '60px',
    display: 'flex',
    alignItems: 'center',
  },
  cardIconContainer: {
    display: 'flex',
  },
  cardIcon: {
    width: '50px',
    height: '35px',
  },
  cardText: {
    marginLeft: '15px',
  },
});

interface CardDisplayProps {
  card: CreditCard;
}

export default function CardDisplay(props: CardDisplayProps) {
  const classes = useStyles();
  return (
    <div className={classes.cardContainer} data-testid='credit-card-display'>
      <div>
        <img
          className={classes.cardIcon}
          src={GetCardInfo(props.card.brand).icon}
          alt={props.card.brand}
        />
      </div>
      <div className={classes.cardText}>
        {GetCardInfo(props.card.brand).display} ending in {props.card.last4}
      </div>
    </div>
  );
}
