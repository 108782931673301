import React from 'react';
import { Button, CardHeader, Color, Content, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import {
  PersonApplicationData,
  PersonApplicationDataDraft,
} from 'lib/interfaces';
import { GetDateInfo } from 'lib/helpers';
import { ValidateAndFormatPhone } from 'lib/validation';

const useStyles = makeStyles(() => ({
  textButtons: {
    height: '0px',
  },
}));

type KycBeneficiaryInfoSummaryProps = {
  beneficiaries: PersonApplicationDataDraft[];
  personInfo: PersonApplicationData;
  previouslySubmitted: boolean;
  removeBeneficiary: () => void;
  setEditMode: () => void;
};

export const KycBeneficiaryInfoSummary = ({
  beneficiaries,
  personInfo,
  previouslySubmitted,
  removeBeneficiary,
  setEditMode,
}: KycBeneficiaryInfoSummaryProps) => {
  const classes = useStyles();
  const {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    tin,
    physicalAddress,
    phoneNumber,
    title,
    ownershipPercentage,
  } = personInfo;

  const {
    streetLine1,
    streetLine2,
    city,
    state,
    postalCode,
    country,
  } = physicalAddress;

  const formattedAddress = [
    streetLine1,
    [streetLine2, city, state].join(', '),
    postalCode,
    country,
  ].join(' ');

  const { monthDayYear } = {
    ...(dateOfBirth && GetDateInfo(dateOfBirth)),
  };

  const formattedName = [firstName, middleName, lastName]
    .filter((namePart) => namePart)
    .join(' ');

  const summaryData = [
    ['Full name', formattedName],
    ['Date of birth', monthDayYear],
    ['Home address', formattedAddress],
    ['Phone number', ValidateAndFormatPhone(phoneNumber)],
    ['Ownership percentage', `${ownershipPercentage.toString()}%`],
    ['Title', title],
  ];

  // we display SSN under DOB if this is first time customer is submitting application
  if (!previouslySubmitted) {
    const getSocialSecurity = (showLastFour: boolean) => {
      const format = '•••  -  ••  -  ';
      const lastFour = showLastFour ? tin.split('-')[2] : '••••';
      return format + lastFour;
    };

    summaryData.splice(1, 0, [
      'Social Security Number',
      getSocialSecurity(true),
    ]);
  }

  return (
    <>
      <CardHeader>
        <Text text={formattedName + ' - ' + title} size={18} variant='medium' />

        <Content flex alignItems='center' gap={0}>
          <Button
            className={classes.textButtons}
            label={<Text text='Edit' color={Color.blue._60} variant='medium' />}
            onClick={() => setEditMode()}
            variant='tertiary'
          />
          |
          <Button
            className={classes.textButtons}
            label={
              <Text
                text='Delete'
                color={
                  beneficiaries.length > 1 ? Color.pink._70 : Color.neutral._60
                }
                variant='medium'
              />
            }
            onClick={() => {
              if (beneficiaries.length > 1) {
                removeBeneficiary();
              }
            }}
            variant='tertiary'
          />
        </Content>
      </CardHeader>

      <div style={{ paddingBottom: 20 }} />
      {summaryData.map((datum) => (
        <Content flex key={datum[0]}>
          <div style={{ minWidth: '20vw' }}>
            <Text text={datum[0]} size={15} variant='medium' />
          </div>
          <Text text={datum[1]} size={15} />
        </Content>
      ))}
    </>
  );
};
