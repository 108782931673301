import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import {
  Animate,
  AnswerType,
  AnswerValueType,
  Flex,
  Spinner,
  SurveyQuestion,
} from 'component-library';
import { RetirementPlanCreditQuestions, SurveyNameEnum } from 'lib/constants';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import _ from 'lodash';
import { CmsQuestionData } from 'lib/interfaces';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    maxWidth: '70%',
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}));

interface NHCEDetailsProps {
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
  taxYear: number;
}

export const NHCEDetails = observer(
  ({ pathTo, pathFrom, onNext, onBack, taxYear }: NHCEDetailsProps) => {
    const classes = useStyles();
    const history = useHistory();
    const { app, chatbot } = useCommonStores();

    const handleOnContinue = () => {
      onNext();
      history.push(pathTo);
    };

    const handleOnBack = () => {
      onBack();
      history.push(pathFrom);
    };

    const {
      isSurveyComplete,
      isLoading,
      surveyAnswers,
      questionsToRender,
      addSurveyAnswers,
      saveAnswers,
    } = useSurveyQuestions(
      SurveyNameEnum.RETIREMENT_PLAN_NHCE_DETAILS_SURVEY,
      taxYear,
    );

    const handleAnswerChange = (
      question: CmsQuestionData,
      answerValue: AnswerValueType,
      key: string,
    ) => {
      question.answerValue = answerValue;
      addSurveyAnswers(question.id, key, [
        { questionId: question.id, answerValue: answerValue },
      ]);
      // save answers per question answered
      if (
        question.answerType === 'multiple_select' ||
        question.answerType === 'multiple_choice' ||
        question.answerType === 'yes_or_no'
      ) {
        saveAnswers && saveAnswers();
      }
    };

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => (
          <Animate
            key={question.id}
            enter={['fade-in', 'from-bottom']}
            duration={0.5}
          >
            <SurveyQuestion
              dataTestId={question.id}
              answerType={question.answerType as AnswerType}
              text={question.text}
              subtitle={question.subtitle}
              answerOptions={question.answerIDs}
              onChange={(answer) => handleAnswerChange(question, answer, key)}
              onBlur={() => saveAnswers && saveAnswers()}
              placeholder={question.placeholder}
              answerValue={question.answerValue}
              tooltip={question.tooltip}
              questionTextSize={app.isMobile ? 15 : 18}
              questionSubtitleSize={app.isMobile ? 13 : 15}
              withCardMargin={false}
              helpLinkText={question.helperText}
              chatBotQuestion={chatbot.isAiQuestionEnabled(question.aiQuestion)}
              chatBotPrompt={() =>
                chatbot.autoSendAiMessage(question.aiQuestion)
              }
              onHelpLinkClick='https://mainstreet1.my.site.com/help/s/article/Retirement-Plan-Startup-Cost'
              error={
                question.id ===
                  RetirementPlanCreditQuestions.HOW_MUCH_SPENT_STARTUP_COST &&
                question.answerValue === 0
              }
              errorText={
                question.id ===
                  RetirementPlanCreditQuestions.HOW_MUCH_SPENT_STARTUP_COST &&
                question.answerValue === 0
                  ? 'This value cannot be $0'
                  : ''
              }
            />
          </Animate>
        ));
      });

    const scrollIntoViewOnKeyDown = () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    };

    useEffect(() => scrollIntoViewOnKeyDown(), [surveyAnswers]);

    const preventContinueIfZeroDollar =
      surveyAnswers[
        RetirementPlanCreditQuestions.HOW_MUCH_SPENT_STARTUP_COST
      ] === 0;

    return (
      <SurveyFlowContainer
        title='Determining Non-highly Compensated Employees'
        onContinue={handleOnContinue}
        onBack={handleOnBack}
        isDisabled={!isSurveyComplete || preventContinueIfZeroDollar}
        dataTestId='retirement-plan-credit'
      >
        <Flex className={classes.container} direction='column' gap={24}>
          {isLoading ? (
            <Spinner size='small' />
          ) : (
            !_.isEmpty(questionsToRender) && renderQuestions()
          )}
        </Flex>
      </SurveyFlowContainer>
    );
  },
);
