import React from 'react';
import { Text, Card, Content } from 'component-library';
import { CurrencyFormat } from 'components/CurrencyFormat';
import { YearFormat } from 'components/YearFormat';
import { makeStyles, Theme, TextField } from '@material-ui/core';

import { QuestionProps } from 'lib/interfaces';
import { InputFormatTypeEnum } from '../../../src/lib/constants';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  textField: {
    marginTop: '30px',
    width: '300px',
    [breakpoints.down('xs')]: {
      width: '250px',
    },

    '& p': {
      margin: '4px 0 0 0',
    },
  },
  cardDetails: {
    fontSize: '14px',
    fontWeight: 400,
  },
}));

interface Props {
  question: QuestionProps;
  type: 'text' | 'textarea' | 'number';
  format?: InputFormatTypeEnum;
  withCard?: boolean;
  className?: string;
  errorMessage?: string | null;
  onChange: (event: any) => void;
  subtitle?: string;
}

export const InputQuestion = ({
  question,
  type,
  format,
  withCard,
  className,
  errorMessage,
  onChange,
}: Props) => {
  const classes = useStyles();

  const renderQuestion = () => {
    return (
      <>
        <Text size={18} variant='medium'>
          {question.text}
        </Text>
        {question.subtitle && <Text size={15}>{question.subtitle}</Text>}
        {type === 'number' && format === InputFormatTypeEnum.CURRENCY && (
          <TextField
            className={classes.textField}
            variant='outlined'
            inputMode='numeric'
            label={question.label}
            value={question.value}
            InputProps={{
              inputComponent: CurrencyFormat as any,
            }}
            onChange={onChange}
            error={!!errorMessage}
            helperText={errorMessage}
          />
        )}
        {type === 'number' && format === InputFormatTypeEnum.YEAR && (
          <TextField
            className={classes.textField}
            variant='outlined'
            inputMode='numeric'
            label={question.label}
            value={question.value}
            InputProps={{
              inputComponent: YearFormat as any,
            }}
            onChange={onChange}
            error={!!errorMessage}
            helperText={errorMessage}
          />
        )}
        {type === 'number' && !format && (
          <TextField
            className={classes.textField}
            variant='outlined'
            inputMode='numeric'
            label={question.label}
            value={question.value}
            onChange={onChange}
            error={!!errorMessage}
            helperText={errorMessage}
          />
        )}
        {type === 'text' && (
          <TextField
            className={classes.textField}
            variant='outlined'
            label={question.label}
            value={question.value}
            onChange={onChange}
            error={!!errorMessage}
            helperText={errorMessage}
          />
        )}
        {type === 'textarea' && (
          <TextField
            className={classes.textField}
            variant='outlined'
            multiline
            rows={4}
            style={{ width: '80%' }}
            label={question.label}
            value={question.value}
            onChange={onChange}
            error={!!errorMessage}
            helperText={errorMessage}
          />
        )}
      </>
    );
  };

  return (
    <>
      {withCard ? (
        <Card className={className}>
          <Content>{renderQuestion()}</Content>
        </Card>
      ) : (
        <div className={className}>{renderQuestion()}</div>
      )}
    </>
  );
};
