import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { SurveyFlowCreditEstimate } from 'products/tax-credits/components';
import { useFeatureFlags, useTaxCreditsStores } from 'stores/useStores';
import { useEffectOnce } from 'lib/helpers';
import {
  RetirementResponseCredit,
  RetirementResponseCreditDisplay,
} from 'lib/interfaces';
import {
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationModalStatusEnum,
  QualificationStatusEnum,
} from 'lib/constants';
import { Animate } from 'component-library';
import { QualificationStatusModal } from '../QualificationStatusModal';

interface CreditEstimateProps {
  pathTo: string;
  onNext: () => void;
  taxYear: number;
}

interface Credits {
  name: string;
  estimateAmount: number;
}

export const CreditEstimate = observer(
  ({ pathTo, onNext, taxYear }: CreditEstimateProps) => {
    const history = useHistory();
    const { retirement, surveyFlow } = useTaxCreditsStores();
    const [credits, setCredits] = useState<Credits[]>([]);
    const featureFlags = useFeatureFlags();

    const handleOnContinue = () => {
      if (featureFlags.hideRetirementPlanOrderForm) {
        retirement.setBillingSummaryModal(
          QualificationModalStatusEnum.BILLING_SUMMARY,
        );
        surveyFlow.setShowQualificationStatusModal(true);
      } else {
        onNext();
        history.push(pathTo);
      }
    };

    const handleAcceptCreditEstimate = async () => {
      surveyFlow.setModalContinueIsLoading(true);

      // update program stage
      await surveyFlow
        .updateProgramStageStatus(
          ProgramNameEnum.FED_RETIREMENT_CREDIT,
          taxYear,
          ProgramStageEnum.MS_REVIEW,
          QualificationStatusEnum.QUALIFIED,
          ProgramSubStageEnum.REVIEW_IN_PROGRESS,
        )
        .then(() => {
          history.push('/');
          surveyFlow.setShowQualificationStatusModal(false);
          surveyFlow.setModalContinueIsLoading(false);
          // clear qualification statuses
          surveyFlow.qualificationStatus = null;
        });
    };

    const getTitleName = (name: string) => {
      switch (name) {
        case RetirementResponseCredit.STARTUP_COST:
          return RetirementResponseCreditDisplay[
            RetirementResponseCredit.STARTUP_COST
          ];
        case RetirementResponseCredit.AUTO_ENROLLMENT:
          return RetirementResponseCreditDisplay[
            RetirementResponseCredit.AUTO_ENROLLMENT
          ];
        case RetirementResponseCredit.TOTAL:
          return RetirementResponseCreditDisplay[
            RetirementResponseCredit.TOTAL
          ];
      }
    };

    useEffectOnce(async () => {
      await retirement.getRetirementEstimates(taxYear).then(() => {
        if (retirement.creditEstimates) {
          const estimates = Object.entries(retirement.creditEstimates)
            .map((entry) => ({
              name: getTitleName(entry[0]) as string,
              estimateAmount: entry[1].amount,
            }))
            .filter(
              (estimate) =>
                estimate.name !==
                RetirementResponseCreditDisplay[RetirementResponseCredit.TOTAL],
            );

          setCredits(estimates);
        }
      });
    });

    return (
      <>
        <SurveyFlowCreditEstimate
          handleOnContinue={handleOnContinue}
          creditName='Retirement Plan Credits'
          credits={credits}
          showNonBindingAlert={false}
          eligibleCreditSubtitle={`across ${credits.length} tax credits`}
        />
        {featureFlags.hideRetirementPlanOrderForm &&
          surveyFlow.qualificationStatus && (
            <Animate enter={'fade-in'}>
              <QualificationStatusModal
                modalOnClick={handleAcceptCreditEstimate}
                showModal={surveyFlow.showQualificationStatusModal}
                setModalWidth={560}
                programName={ProgramNameEnum.FED_RETIREMENT_CREDIT}
              />
            </Animate>
          )}
      </>
    );
  },
);
