import { RootStore } from './RootStore';

export interface BaseNamespace {
  setup?(): Promise<void>;
  teardown?(): Promise<void>;
}

export abstract class BaseNamespace {
  public rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    if (this.setup) {
      this.rootStore.event.on('SETUP', this.setup.bind(this));
    }

    if (this.teardown) {
      this.rootStore.event.on('TEARDOWN', this.teardown.bind(this));
    }
  }
}
