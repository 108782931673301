import React from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { animated } from 'react-spring';
import { Animate, Flex } from 'component-library';
import TitleHeader from 'components/TitleHeader';
import { BannerCarousel, FooterNav } from './components';
import { useSpringPageIndexTransition } from 'lib/useSpringPageTransition';
import { useDashboardStores, useCommonStores } from 'stores/useStores';
import {
  WelcomeToMainStreet,
  QualifyForTaxCredits,
  CompleteRDAssessment,
  MainStreetReview,
  FileFormsIRS,
  UseYourCredits,
} from './components/steps';

const useStyles = makeStyles(({ breakpoints }) => ({
  '@global': {
    '.embeddedServiceHelpButton': {
      display: 'none',
    },
  },
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    maxWidth: '984px',
    margin: '0 auto',
    padding: 'clamp(64px, 10vw, 100px) clamp(24px, 5vw, 40px) 48px',
    position: 'relative',
  },
  animateContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    [breakpoints.down('sm')]: {
      gridGap: '16px',
    },
  },
}));

export const EducationalModule: React.FC = observer(() => {
  const classes = useStyles();
  const { modules } = useDashboardStores();
  const { app } = useCommonStores();
  const headingSize = app.isMobile ? 18 : 23;
  const textFontSize = app.isMobile ? 13 : 15;

  const { transitions } = useSpringPageIndexTransition(
    modules.educationalCurrentIndex,
    modules.educationalPrevIndex,
  );

  const renderSteps = [
    <WelcomeToMainStreet key='welcome-to-mainstreet' />,
    <QualifyForTaxCredits
      key='qualify-for-tax-credits'
      headingSize={headingSize}
      textFontSize={textFontSize}
    />,
    <CompleteRDAssessment
      key='complete-rd-assessment'
      headingSize={headingSize}
      textFontSize={textFontSize}
    />,
    <MainStreetReview
      key='mainstreet-review'
      headingSize={headingSize}
      textFontSize={textFontSize}
    />,
    <FileFormsIRS
      key='file-forms-irs'
      headingSize={headingSize}
      textFontSize={textFontSize}
    />,
    <UseYourCredits
      key='use-your-credits'
      headingSize={headingSize}
      textFontSize={textFontSize}
    />,
  ];

  return (
    <Flex
      direction='column'
      className={classes.root}
      data-testid={'educational-module'}
    >
      <Animate enter={['fade-in', 'from-top']}>
        <TitleHeader
          showExitButton
          overrideExitModal
          onCancelExitText='Cancel'
        />
      </Animate>
      <Animate enter={'fade-in'}>
        <BannerCarousel />
      </Animate>
      <Flex className={classes.mainContent} justifyContent='center'>
        {transitions((props, item) => {
          return (
            <animated.div className={classes.animateContainer} style={props}>
              {renderSteps[item]}
            </animated.div>
          );
        })}
      </Flex>
      {modules.educationalCurrentIndex !== 0 && <FooterNav />}
    </Flex>
  );
});
