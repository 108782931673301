import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Content, Link, Text } from 'component-library';
import { ExpectedCreditTypeEnum } from 'lib/constants';

const useStyles = makeStyles(() => ({
  stepList: {
    margin: 0,
    paddingLeft: '24px',
    '& li': {
      paddingBottom: '16px',
    },
  },
}));

interface ReviewProcessStepProps {
  creditType?: ExpectedCreditTypeEnum;
}

export const ReviewProcessStep = ({ creditType }: ReviewProcessStepProps) => {
  const classes = useStyles();
  const defaultText =
    'If you are claiming your R&D credit against payroll tax liability, you will additionally need to file Form 8974 on a quarterly basis. The process will vary depending on which payroll system you use.';

  const bulletTextDict = {
    [ExpectedCreditTypeEnum.PAYROLL_TAX]: `After filing this form, you will need to file a 8974 Form each quarter to use your credits towards your payroll. Don't worry, we will let you know when it's time to file this form and help you complete the process.`,
    [ExpectedCreditTypeEnum.INCOME_TAX]:
      'After filing this form your credits will be automatically applied to your next income tax liabilities.',
    [ExpectedCreditTypeEnum.UNDETERMINED]: defaultText,
    [ExpectedCreditTypeEnum.DEFERRED_INCOME]: defaultText,
  };
  const bulletText = creditType ? bulletTextDict[creditType] : defaultText;
  return (
    <Content flex flexDirection='column'>
      <ul className={classes.stepList}>
        <li>
          <Text
            tag='span'
            text='You should first file the 6765 Form with your taxes'
          />
        </li>
        <li>
          <Text
            tag='span'
            text={
              <>
                {bulletText}
                <br />
                <br />
                <Link
                  text='More about how R&D tax credits work'
                  href='https://mainstreet-help.force.com/help/s/topic/0TO5f000000I96tGAC/quarterly-filing-documents'
                  variant='medium'
                  external
                />
              </>
            }
          />
        </li>
      </ul>
    </Content>
  );
};
