import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Color,
  Divider,
  Flex,
  GradientBackground,
  Link,
} from 'component-library';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '12px',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75vh',
    width: '100vw',
    textAlign: 'center',
  },
  img: {
    width: '80%',
    maxWidth: '300px',
    marginBottom: '40px',
  },
  title: {
    fontSize: '24px',
  },
  subtitle: {
    margin: '0 37px',
    fontSize: '15px',
    lineHeight: '24px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
  },
  headerTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  verticalLine: {
    height: '30px',
    width: '2px',
    backgroundColor: '#F0F0F0',
    margin: '0 12px',
  },
  subtitleText: {
    fontSize: '16px',
    color: 'grey',
  },
}));

export default function MobileWarning2() {
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid='mobile-warning'>
      <Flex
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        className={classes.header}
      >
        <h2 className={classes.headerTitle}>MainStreet</h2>
        <div className={classes.verticalLine}></div>
      </Flex>
      <Divider variant='full-width' />
      <Flex className={classes.content}>
        <img
          className={classes.img}
          src={`${process.env.PUBLIC_URL}/images/no-mobile-device.svg`}
          alt='Mobile Device'
          width={150}
          height={150}
        />
        <h1 className={classes.title}>
          Access full features on
          <br /> desktop
        </h1>
        <div className={classes.subtitle}>
          For the complete experience and to access all features, please visit
          us at{' '}
          <Link href='https://dashboard.mainstreet.com'>
            dashboard.mainstreet.com
          </Link>{' '}
          on your desktop. <br />
          We can&apos;t wait to help you discover more <br />
          savings!
        </div>
      </Flex>
      <GradientBackground gradient={Color.gradient._3} gradientOpacity={0.15} />
    </div>
  );
}
