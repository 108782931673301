import {
  FormHelperText,
  makeStyles,
  TextField,
  Theme,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { CreditCategoryEnum, Page } from 'lib/constants/globalConstants';
import LoadingWidget from 'components/util/LoadingWidget';
import { DividerWithText } from 'components/DividerWithText';
import { Formik } from 'formik';
import { CreateCompanySelfSignupRequest, SourcingData } from 'lib/interfaces';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GoogleButton from 'react-google-button';
import * as yup from 'yup';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import {
  Animate,
  Button,
  Color,
  Text,
  Heading,
  FontAwesome,
  Link,
  Flex,
} from 'component-library';
import {
  useCommonStores,
  useCompany,
  useFeatureFlags,
  useHistoryStore,
} from 'stores/useStores';
import { useIsMounted } from 'lib/helpers';
import { observer } from 'mobx-react';
import { PasswordChecklist } from 'pages/onboarding/components/PasswordChecklist';
import { usePasswordValidation } from 'lib/usePasswordValidation';
import { SetInSessionStorage } from 'lib/sessionStorage';
import { UTM_SOURCING_DATA_KEY } from 'stores/accountcreation/AccountCreationStore';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import { XeroSso, GoogleSso } from './socialSSOButtons';

const useStyles = makeStyles(({ palette, breakpoints, emerald }: Theme) => ({
  '@global': {
    '.embeddedServiceHelpButton': {
      display: 'none !important',
    },
  },
  root: {
    display: 'grid',
    height: '100%',
    maxHeight: '100%',
    gridTemplateColumns: `58% 42%`,
    background: `url(${process.env.PUBLIC_URL}/images/welcome-page-background.jpg)`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: '70% 60%',
    backgroundPosition: `0% 130%`,
    [breakpoints.down('sm')]: {
      background: 'none',
      gridTemplateColumns: 'minmax(0, 1fr)',
    },
  },
  signupRoot: {
    width: '100%',
    height: '100%',
    padding: '70px 75px 20px',
    background: Color.neutral._light_20,
    transition: 'opacity 1s',
    [breakpoints.down('sm')]: {
      padding: '32px 0',
    },
  },
  logo: {
    position: 'absolute',
    top: '24px',
    left: '48px',
    width: '106px',
    [breakpoints.down('sm')]: {
      left: '24px',
    },
  },
  leftContainer: {
    display: 'grid',
    height: '100vh',
    minHeight: '600px',
    transition: 'opacity 1s',
    [breakpoints.down('sm')]: {
      padding: '80px 0 40px',
      height: 'auto',
      minHeight: 'auto',
      background: `url(${process.env.PUBLIC_URL}/images/welcome-page-background.jpg)`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: '100% 60%',
      backgroundPosition: `0% 120%`,
    },
  },
  leftInfoContainer: {
    width: '90%',
    maxWidth: '540px',
    alignSelf: 'center',
    justifySelf: 'center',
    [breakpoints.down('sm')]: {
      alignSelf: 'auto',
      justifySelf: 'auto',
      width: '100%',
      maxWidth: '100%',
      padding: '0 24px',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '580px',
    maxWidth: '100%',
    transition: 'opacity 1s',
    opacity: 1,
    [breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '0px',
    },
  },
  watermark: {
    width: '175px',
    height: '26px',
    marginLeft: '5px',
    marginBottom: '5px',
    [breakpoints.down('sm')]: {
      width: '130px',
      height: '20px',
    },
  },
  titleMessaging: {
    textAlign: 'center',
    width: '100%',
    maxWidth: '580px',
  },
  mainContent: {
    width: '400px',
    maxWidth: '90%',
    textAlign: 'center',
  },
  title: {
    [breakpoints.down('sm')]: {
      fontSize: '24px',
    },
    '@media (max-width:340px)': {
      fontSize: '21px',
      nextButton: {
        width: '360px',
        height: '50px',
        textTransform: 'initial',
        fontSize: '18px',
        fontWeight: 500,
        [breakpoints.down('sm')]: {
          width: '320px',
        },
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  },
  nextButton: {
    width: '360px',
    height: '50px',
    textTransform: 'initial',
    margin: '0 auto 40px',
    fontSize: '18px',
    fontWeight: 500,
    [breakpoints.down('sm')]: {
      maxWidth: '320px',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  buttonRow: {
    marginTop: '40px',
  },
  errorMsg: {
    margin: '5px 0 0',
    fontSize: '12px',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: '20px',
      marginBottom: '-25px',
    },
  },
  formErrorMsg: {
    marginTop: '15px',
    textAlign: 'center',
  },
  agreeErrorMsg: {
    marginLeft: '100px',
    [breakpoints.down('sm')]: {
      marginLeft: '30px',
    },
  },
  textFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 auto',
    [breakpoints.down('sm')]: {
      maxWidth: '320px',
    },
  },
  textField: {
    width: '100%',
    marginTop: '25px',
    background: Color.neutral.white,
    [breakpoints.down('sm')]: {
      height: '20px',
      marginBottom: '20px',
    },
  },
  inputRoot: {
    '& input': {
      width: 'calc(100% - 28px)',
    },
  },
  linkText: {
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:visited': {
      color: palette.secondary.main,
    },
    '&:link': {
      color: palette.secondary.main,
    },
  },
  passwordTip: {
    fontSize: '12px',
    marginTop: '10px',
    color: '#022435',
    textAlign: 'left',
    maxWidth: '360px',
    margin: '0 auto',
    [breakpoints.down('sm')]: {
      maxWidth: '320px',
      marginTop: '30px',
    },
  },
  loadingContainer: {
    marginBottom: '60px',
  },
  agreementContainer: {
    fontSize: '13px',
    marginTop: '30px',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  login: {
    textAlign: 'center',
    transition: 'opacity 1s',
  },
  moneyBack: {
    color: emerald,
    fontWeight: 700,
  },
  discount: {
    justifyContent: 'center',
    maxWidth: '780px',
    marginTop: '20px',
    lineHeight: 1.2,
    fontWeight: 700,
  },
  subheading: {
    gridGap: '4px',
    gap: '4px',
    paddingTop: 16,
  },
  newWatermark: {
    marginLeft: '8px',
    marginBottom: 0,
  },
  googleButton: {
    backgroundColor: '#1F6AB0 !important',
    margin: '40px auto',
    boxShadow: 'none !important',
    [breakpoints.down('sm')]: {
      margin: '20px auto',
    },
  },
  prequal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '580px',
    maxWidth: '100%',
    transition: 'opacity 1s',
    padding: '32px',
    opacity: 1,
  },
  prequalHeader: {
    textAlign: 'center',
  },
  questionCard: {
    border: `1px solid ${Color.neutral._20}`,
    background: Color.neutral.white,
    padding: `16px`,
    width: '100%',
  },
  questionCardButton: {
    width: '96px',
  },
  disqualification: {
    display: 'grid',
    padding: '20px',
    textAlign: 'center',
    width: '100%',
    maxWidth: '700px',
    height: '100%',
    alignItems: 'center',
    justifyItems: 'center',
    gridColumn: '1 / span 2',
    alignSelf: 'center',
    justifySelf: 'center',
    [breakpoints.down('sm')]: {
      gridColumn: '1',
      maxWidth: '100%',
      background: `url(${process.env.PUBLIC_URL}/images/welcome-page-background.jpg)`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: '120% 55%',
      backgroundPosition: `0% 120%`,
    },
  },
  disqualificationGoBack: {
    paddingTop: '42px',
  },
  socialLogins: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
}));

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    location: Location;
  }
}

const getUtmSourcingData = (urlParams: URLSearchParams) => {
  const sourcingData: SourcingData = {
    utmCampaign: '',
    utmMedium: '',
    utmSource: '',
    utmContent: '',
    utmTerm: '',
  };

  const urlKeyMapping: SourcingData = {
    utmCampaign: 'utm_campaign',
    utmMedium: 'utm_medium',
    utmSource: 'utm_source',
    utmContent: 'utm_content',
    utmTerm: 'utm_term',
  };

  Object.keys(sourcingData).forEach((key) => {
    if (key in urlKeyMapping) {
      const urlParamKey = urlKeyMapping[key as keyof SourcingData];
      if (urlParamKey) {
        const urlParamValue = urlParams.get(urlParamKey);
        if (urlParamValue) {
          sourcingData[key as keyof SourcingData] = urlParamValue;
        }
      }
    }
  });
  return sourcingData;
};

export const WelcomePage = observer(() => {
  const isMounted = useIsMounted();
  const classes = useStyles();
  const history = useHistoryStore();
  const featureFlags = useFeatureFlags();
  const [loading, setLoading] = useState<boolean>(false);
  const [referral, setReferral] = useState<string>('');
  const [formError, setFormError] = useState('');
  const [fadeOutPreQual, setFadeOutPreQual] = useState(false);
  const [fadeOutLastStep, setFadeOutLastStep] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showDisqualification, setShowDisqualification] = useState(false);
  const [isShowingDisqualification, setIsShowingDisqualification] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showPasswordHelpers, setShowPasswordHelpers] = useState(false);

  const { client } = useContext(Auth0FeatureContext);
  const { auth, app } = useCommonStores();
  const { company } = useCompany();
  const showAccountCreationFlow = featureFlags.showAccountCreationFlow;
  const showSocialLogins = auth.isAuth0Enabled;

  const { validatePassword, isValidPassword, passwordValidation } =
    usePasswordValidation();

  interface FormInputs {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    agreeToTerms: boolean;
  }

  const initialValues: FormInputs = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    agreeToTerms: true,
  };

  const getReferrerId = () => '';

  const location = useLocation();
  const { search } = location;
  const { origin } = window.location;
  const urlParams = new URLSearchParams(search);
  const partner = urlParams.get('partner');
  const sourcingData = getUtmSourcingData(urlParams);
  sourcingData.registrationDeviceType = app.isMobile
    ? 'mobile'
    : app.isTablet
    ? 'tablet'
    : 'desktop';

  const { utmMedium } = sourcingData;
  const isTreasury = urlParams.get('treasury') === 'true';
  const redirectPath = urlParams.get('redirectPath') || '';

  const isAdDiscount = utmMedium === 'Partnerships' && !partner;

  const isPartnerDiscount = utmMedium === 'Partnerships' && partner;

  const redirectUri =
    (redirectPath && `${origin}${redirectPath}`) ||
    (isTreasury && `${origin}/${Page.treasuryManagement}`) ||
    origin;

  const submit = async (formValues: FormInputs) => {
    if (isMounted()) {
      setFormError('');
      setLoading(true);
    }

    const res = await client.CreateCompanyAndLoginSelfSignup({
      primaryUserEmail: formValues.email,
      primaryUserFirstName: formValues.firstName,
      primaryUserLastName: formValues.lastName,
      adminPassword: formValues.password,
      referrer: referral,
      creditCategories: [] as CreditCategoryEnum[],
      sourcing: sourcingData,
    } as CreateCompanySelfSignupRequest);

    if (res.errorMsg) {
      datadogLogs.logger.error(
        '[WELCOME]: company creation on registration failed',
        logContext({
          error: res.errorMsg,
          company: app.company,
        }),
      );

      setFormError(res.errorMsg);
      setLoading(false);
      return;
    }

    await auth.manualLogin(formValues.email, formValues.password, redirectUri);

    if (res.data?.company) {
      company.mergeData(res.data.company);
    }

    if (redirectPath) {
      history.push(redirectPath);
    } else if (isTreasury) {
      history.push(`/${Page.selfSignupCompanyDetails}/?treasury=true`);
    } else if (showAccountCreationFlow) {
      history.push(`/${Page.accountCreationRoot}`);
    } else {
      history.push(`/${Page.selfSignupCompanyDetails}`);
    }
  };

  const onGoogleSignup = async () => {
    SetInSessionStorage(
      UTM_SOURCING_DATA_KEY,
      JSON.stringify({
        referrer: referral,
        sourcing: sourcingData,
      }),
    );
    await auth.socialLogin('google-oauth2', redirectUri);
  };

  const submitOnEnter =
    (runSubmit: () => void) => (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        runSubmit();
      }
    };

  const forceLogin = () => {
    if (auth.isAuth0Enabled) {
      auth.forceLogin({ redirectUri });
    } else {
      history.push('/');
    }
  };

  const onShowSignupForm = () => {
    setFadeOutLastStep(false);
    setFadeOutPreQual(true);
    setTimeout(() => {
      history.push(`/${Page.selfSignupCreateAccount}${history.search}`);
      setShowSignupForm(true);
    }, 1000);
  };

  const onShowDisqualification = () => {
    setIsShowingDisqualification(true);
    setFadeOutLastStep(false);
    setFadeOutPreQual(true);
    setTimeout(() => {
      history.push(`/${Page.selfSignupDisqualify}${history.search}`);
      setShowDisqualification(true);
    }, 1000);
  };

  const onShowPreQual = () => {
    setFadeOutLastStep(true);

    setTimeout(() => {
      history.push(`/${Page.selfSignupWelcome}${history.search}`);
      setShowSignupForm(false);
      setShowDisqualification(false);

      setTimeout(() => {
        setIsShowingDisqualification(false);
        setFadeOutPreQual(false);
      }, 15);
    }, 1000);
  };

  return (
    <div className={classes.root}>
      {showDisqualification ? (
        <div
          className={classes.disqualification}
          style={{
            transition: 'opacity 1s',
            opacity: `${fadeOutLastStep ? 0 : 1}`,
          }}
        >
          <Animate
            enter={['fade-in', 'from-bottom']}
            dataTestId='prequal-disqualification'
          >
            <Heading tag={app.isTablet ? 'h4' : 'h3'} variant='medium'>
              Your company likely doesn&apos;t qualify for R&D Tax Credits.
            </Heading>
            <Animate enter={'fade-in'} delay={1}>
              <Text size={18} paddingBottom={8}>
                Although we&apos;re working on other solutions, we&apos;re
                focused on providing the best service only to businesses that
                qualify for R&D tax credits.
              </Text>
              <Link
                external
                size={13}
                variant='medium'
                href='https://mainstreet-help.force.com/help/s/article/What-doeWhat-does-R-D-mean-s-R-D-mean'
              >
                What does R&D mean?
              </Link>
              <Text size={13} className={classes.disqualificationGoBack}>
                Believe you made a mistake?
              </Text>
              <Link
                size={13}
                onClick={onShowPreQual}
                variant='medium'
                dataTestId='disqualification-go-back'
              >
                Go back and change your response
              </Link>
            </Animate>
          </Animate>
        </div>
      ) : (
        <>
          <div
            className={classes.leftContainer}
            style={{ opacity: `${isShowingDisqualification ? 0 : 1}` }}
          >
            <Flex direction='column' className={classes.leftInfoContainer}>
              <Heading
                tag='h4'
                size={app.isTablet ? 15 : 18}
                color={Color.neutral._90}
                variant='medium'
                marginBottom={24}
              >
                The leading small business tax credits solution
              </Heading>
              <Heading
                tag={app.isTablet ? 'h4' : 'h3'}
                color={Color.green._80}
                variant='medium'
                marginBottom={8}
              >
                $150 million saved
              </Heading>
              <Heading
                tag={app.isTablet ? 'h4' : 'h3'}
                color={Color.green._80}
                variant='medium'
                marginBottom={8}
              >
                2,000 small businesses served
              </Heading>
              <Heading
                tag={app.isTablet ? 'h4' : 'h3'}
                color={Color.green._80}
                variant='medium'
                marginBottom={32}
              >
                Will you be next?
              </Heading>
              <Heading variant='medium' size={app.isTablet ? 15 : 18}>
                Join today!
              </Heading>
            </Flex>
          </div>
          <Flex
            direction='column'
            alignItems='center'
            justifyContent='center'
            className={classes.signupRoot}
            style={{ opacity: `${isShowingDisqualification ? 0 : 1}` }}
          >
            <div className={classes.titleMessaging}>
              <Heading
                className={classes.title}
                variant='medium'
                tag={'h3'}
                size={app.isTablet ? 32 : 36}
              >
                Join MainStreet
              </Heading>
              {isAdDiscount && (
                <Typography
                  variant='subtitle1'
                  component='div'
                  className={classes.discount}
                >
                  {"You're getting "}
                  <Box display='inline' className={classes.moneyBack}>
                    25% off
                  </Box>
                  {' any fees charged by MainStreet'}
                </Typography>
              )}
              {isPartnerDiscount && (
                <Typography
                  variant='subtitle1'
                  component='div'
                  className={classes.discount}
                >
                  {`  Thanks to ${partner}, you're getting `}
                  <Box display='inline' className={classes.moneyBack}>
                    25% off
                  </Box>{' '}
                  any fees charged by MainStreet
                </Typography>
              )}
            </div>
            {(featureFlags.hidePrequalQuestions ? true : showSignupForm) ? (
              <div
                style={{
                  transition: 'opacity 1s',
                  opacity: `${fadeOutLastStep ? 0 : 1}`,
                }}
              >
                <Animate enter={'fade-in'}>
                  <div className={classes.box} data-testid={'welcome-page'}>
                    {loading && (
                      <div className={classes.loadingContainer}>
                        <LoadingWidget />
                      </div>
                    )}
                    <div
                      className={classes.mainContent}
                      style={{ display: loading ? 'none' : 'block' }}
                    >
                      {showSocialLogins && (
                        <div
                          data-testid={'social-sso'}
                          className={classes.socialLogins}
                        >
                          {featureFlags.showUnifiedSocialSso ? (
                            <>
                              <GoogleSso
                                referrer={referral}
                                sourcing={sourcingData}
                                auth={auth}
                                redirectUri={redirectUri}
                              />
                              <XeroSso
                                referrer={referral}
                                sourcing={sourcingData}
                                auth={auth}
                                redirectUri={redirectUri}
                              />
                            </>
                          ) : (
                            <GoogleButton
                              label='Sign up with Google'
                              className={classes.googleButton}
                              onClick={onGoogleSignup}
                            />
                          )}
                          <DividerWithText>or</DividerWithText>
                        </div>
                      )}
                      <Formik
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={(values) => {
                          submit(values);
                        }}
                        validationSchema={yup.object().shape({
                          firstName: yup
                            .string()
                            .required('Please include your first name'),
                          lastName: yup
                            .string()
                            .required('Please include your last name'),
                          email: yup
                            .string()
                            .email('Please enter a valid email')
                            .required('Please include your work email'),
                          password: yup
                            .string()
                            .required('Please enter a password'),
                          agreeToTerms: yup
                            .bool()
                            .oneOf(
                              [true],
                              'You must accept the terms of service',
                            ),
                        })}
                        // eslint-disable-next-line react/no-children-prop
                        children={({
                          values,
                          errors,
                          handleSubmit,
                          setFieldValue,
                        }) => (
                          <div>
                            <div className={classes.textFieldContainer}>
                              <TextField
                                variant='outlined'
                                className={classes.textField}
                                InputProps={{
                                  classes: { root: classes.inputRoot },
                                }}
                                data-testid='welcome_first_name'
                                label='First Name'
                                value={values.firstName}
                                onChange={(event) => {
                                  setFieldValue(
                                    'firstName',
                                    event.target.value,
                                  );
                                }}
                                onKeyDown={submitOnEnter(handleSubmit)}
                              />
                              {errors.firstName && (
                                <FormHelperText
                                  className={classes.errorMsg}
                                  error
                                >
                                  {errors.firstName}
                                </FormHelperText>
                              )}
                            </div>
                            <div className={classes.textFieldContainer}>
                              <TextField
                                variant='outlined'
                                className={classes.textField}
                                InputProps={{
                                  classes: { root: classes.inputRoot },
                                }}
                                data-testid='welcome_last_name'
                                label='Last Name'
                                value={values.lastName}
                                onChange={(event) => {
                                  setFieldValue('lastName', event.target.value);
                                }}
                                onKeyDown={submitOnEnter(handleSubmit)}
                              />
                              {errors.lastName && (
                                <FormHelperText
                                  className={classes.errorMsg}
                                  error
                                >
                                  {errors.lastName}
                                </FormHelperText>
                              )}
                            </div>

                            <div className={classes.textFieldContainer}>
                              <TextField
                                variant='outlined'
                                className={classes.textField}
                                InputProps={{
                                  classes: { root: classes.inputRoot },
                                }}
                                data-testid='welcome_email'
                                label='Company Email'
                                value={values.email}
                                onChange={(event) => {
                                  setFieldValue('email', event.target.value);
                                }}
                                onKeyDown={submitOnEnter(handleSubmit)}
                              />
                              {errors.email && (
                                <FormHelperText
                                  className={classes.errorMsg}
                                  error
                                >
                                  {errors.email}
                                </FormHelperText>
                              )}
                            </div>

                            <div className={classes.textFieldContainer}>
                              <TextField
                                variant='outlined'
                                className={classes.textField}
                                type={showPassword ? 'text' : 'password'}
                                data-testid='welcome_password'
                                label='Password'
                                value={values.password}
                                error={!isValidPassword && showPasswordError}
                                onFocus={() => {
                                  setShowPasswordHelpers(true);
                                }}
                                onBlur={(event) => {
                                  validatePassword(event.target.value, true);
                                  setShowPasswordError(true);
                                  setShowPasswordHelpers(
                                    values.password.length > 0,
                                  );
                                }}
                                onChange={(event) => {
                                  setShowPasswordHelpers(true);
                                  setShowPasswordError(false);
                                  setFieldValue('password', event.target.value);
                                  validatePassword(event.target.value);
                                }}
                                onKeyDown={submitOnEnter(handleSubmit)}
                                InputProps={{
                                  classes: { root: classes.inputRoot },
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <Link
                                        onClick={() => {
                                          setShowPassword(!showPassword);
                                        }}
                                        inheritColor={true}
                                      >
                                        <FontAwesome
                                          name={
                                            showPassword ? 'eye-slash' : 'eye'
                                          }
                                          variant='solid'
                                          size={20}
                                          color={
                                            !isValidPassword &&
                                            showPasswordError
                                              ? Color.semantic.$error50
                                              : Color.neutral._60
                                          }
                                        />
                                      </Link>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {showPasswordHelpers && (
                                <PasswordChecklist
                                  passwordValidation={passwordValidation}
                                />
                              )}
                              {errors.password && (
                                <FormHelperText
                                  className={classes.errorMsg}
                                  error
                                >
                                  {errors.password}
                                </FormHelperText>
                              )}
                            </div>

                            <Typography
                              className={classes.agreementContainer}
                              variant='body1'
                              component='div'
                            >
                              By creating an account, you&apos;re letting us
                              know that you agree to the terms outlined in{' '}
                              <a
                                className={classes.linkText}
                                href='/terms-of-service'
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                Terms of Service
                              </a>{' '}
                              and have acknowledged our{' '}
                              <a
                                className={classes.linkText}
                                href='/privacy'
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                Privacy Policy
                              </a>
                              .
                            </Typography>
                            {formError && (
                              <FormHelperText
                                className={classes.formErrorMsg}
                                error
                              >
                                {formError}
                              </FormHelperText>
                            )}
                            <div className={classes.buttonRow}>
                              <Button
                                className={classes.nextButton}
                                variant='contained'
                                dataTestId='welcome_submit'
                                onClick={() => {
                                  handleSubmit();
                                }}
                                label='Create Estimate'
                                disabled={
                                  !isValidPassword ||
                                  !values.firstName ||
                                  !values.lastName ||
                                  !values.email
                                }
                              />
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </Animate>
              </div>
            ) : (
              <Flex
                direction='column'
                alignItems='center'
                style={{
                  width: '100%',
                  transition: 'opacity 1s',
                  opacity: `${fadeOutPreQual ? 0 : 1}`,
                }}
              >
                <Text size={15} className={classes.login}>
                  Already have an account?{' '}
                  <Link onClick={forceLogin} className={classes.linkText}>
                    Log in instead
                  </Link>
                </Text>
                <div
                  className={classes.prequal}
                  data-testid={'welcome-page-pre-qual'}
                >
                  <Flex direction='column' alignItems='center' gap={16}>
                    <Heading
                      tag='h5'
                      variant='medium'
                      className={classes.prequalHeader}
                    >
                      Do any of these statements apply to your company?
                    </Heading>
                    <div className={classes.questionCard}>
                      <Text size={13} variant='medium'>
                        We conduct research and development (R&D) that will
                        improve products, services, or internal processes.
                      </Text>
                    </div>
                    <div className={classes.questionCard}>
                      <Text size={13} variant='medium'>
                        We conduct research and development (R&D) on something
                        that could fail.
                      </Text>
                    </div>
                    <div className={classes.questionCard}>
                      <Text size={13} variant='medium'>
                        We experiment on new features, test hypotheses, and
                        iterate on new processes.
                      </Text>
                    </div>
                    <div className={classes.questionCard}>
                      <Text size={13} variant='medium'>
                        We work on projects within technical fields like
                        computer science, engineering, or physical/biological
                        sciences.
                      </Text>
                    </div>
                    <Flex
                      padding={[16, 0, 0, 0]}
                      justifyContent={'center'}
                      gap={24}
                    >
                      <Button
                        dataTestId='pre-qual-yes'
                        className={classes.questionCardButton}
                        variant='outlined'
                        label={'Yes'}
                        onClick={onShowSignupForm}
                      />
                      <Button
                        dataTestId='pre-qual-no'
                        className={classes.questionCardButton}
                        variant='outlined'
                        label={'No'}
                        onClick={onShowDisqualification}
                      />
                    </Flex>
                  </Flex>
                </div>
              </Flex>
            )}
          </Flex>
        </>
      )}
      <div className={classes.logo}>
        <img
          src={`${process.env.PUBLIC_URL}/images/logo/mst-logo-green.svg`}
          alt='MainStreet watermark'
        />
      </div>
    </div>
  );
});
