import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import {
  Animate,
  Card,
  Heading,
  BarStepper,
  Flex,
  Text,
} from 'component-library';
import { UnifiedAccountCreationSteps } from 'lib/constants';
import { observer } from 'mobx-react';
import { useAccountCreationStores, useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(({ breakpoints, boxShadowDark }: Theme) => ({
  stepper: {
    width: '400px',
    [breakpoints.down('md')]: {
      width: '380px',
    },
    [breakpoints.down('sm')]: {
      padding: '0px 0px 20px 0px',
      width: '340px',
    },
    [breakpoints.down('xs')]: {
      width: '320px',
    },
  },
  stepContainer: {
    backgroundColor: 'transparent',
    gap: '40px',
    minHeight: 734,
    flexDirection: 'row',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      overflowY: 'auto',
    },
  },
  stepTitle: {
    flex: '0 0 512px',
    boxSizing: 'border-box',
    textAlign: 'center',
    position: 'relative',
    [breakpoints.down('md')]: {
      flex: '0 0 480px',
    },
    [breakpoints.down('sm')]: {
      flex: 'revert',
      overflow: 'initial',
    },
  },
  stepTitleContainer: {
    backgroundColor: 'transparent',
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      width: '100%',
      padding: '24px',
      boxSizing: 'border-box',
      justifyContent: 'center',
    },
  },
  stepTitleContent: {
    position: 'sticky',
    top: '80px',
    zIndex: 1,
  },
  stepContent: {
    backgroundColor: 'rgba(255,255,255, .95)',
    boxShadow: boxShadowDark,
    borderRadius: '4px',
    flex: 1,
    [breakpoints.down('sm')]: {
      padding: '40px 24px !important',
    },
  },
  children: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    '& > div': {
      width: '100%',
      height: '100%',
    },
  },
  titleImage: {
    '& > img': {
      width: '100%',
      maxWidth: 536,
    },
  },
  link: {
    display: 'inline-block',
    textDecoration: 'none',
  },
}));

interface AccountStepContainerProps {
  children: React.ReactNode | JSX.Element;
}

export const AccountStepContainer = observer(
  ({ children }: AccountStepContainerProps) => {
    const classes = useStyles();
    const {
      app: { isMobile },
    } = useCommonStores();
    const { accountCreation } = useAccountCreationStores();

    return (
      <Card noMargin noBorder noBoxShadow className={classes.stepContainer}>
        {!isMobile && (
          <Flex
            direction='column'
            alignItems='center'
            justifyContent='flex-start'
            className={classes.stepTitle}
          >
            <Flex
              className={classes.stepTitleContent}
              direction='column'
              padding={[48, 32, 0, 32]}
            >
              <Heading tag={'h3'} variant='medium'>
                We&apos;ve got the time to save you&nbsp;money
              </Heading>
              <Text
                text={
                  'We analyze your business against multiple tax credit programs to help you find applicable benefits.'
                }
              />
              {!isMobile && (
                <Animate enter={['fade-in', 'from-bottom']} duration={0.5}>
                  <div className={classes.titleImage}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/account-creation/business-illustration.png`}
                      alt={`We've got the time to save you money`}
                    />
                  </div>
                </Animate>
              )}
            </Flex>
          </Flex>
        )}
        <Flex
          direction='column'
          alignItems='start'
          padding={[0, 48, 40, 48]}
          className={classes.stepContent}
        >
          <Animate enter={['fade-in', 'from-top']} delay={0.75}>
            <BarStepper
              className={classes.stepper}
              steps={UnifiedAccountCreationSteps}
              currentStep={accountCreation.currentStep}
            />
          </Animate>
          <div className={classes.children}>
            <Flex
              direction='column'
              justifyContent='space-between'
              alignItems='center'
              className={classes.contentContainer}
            >
              {children}
            </Flex>
          </div>
        </Flex>
      </Card>
    );
  },
);
