import React from 'react';
import {
  Content,
  Text,
  Heading,
  Button,
  Icon,
  IconEnum,
  Card,
  Color,
} from 'component-library';
import { makeStyles } from '@material-ui/core/styles';
import { Page } from 'lib/constants';
import { useHistory } from 'react-router-dom';
import { FormRedirects } from 'lib/helpers';
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
  },
  container: {
    padding: '40px 80px',
    width: '916px',
  },
  card: {
    borderLeft: '3px solid #003844',
  },
  bulletList: {
    padding: '0px 0px 0px 32px',
    margin: '0px',
  },
  bulletPoints: {
    paddingLeft: '16px',
  },
  listItem: {
    margin: '4px 8px',
    lineHeight: '24px',
    display: 'list-item',
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  button: {
    maxWidth: '170px',
  },
  description: {
    paddingLeft: '2px',
    paddingBottom: '4px',
  },
  title: {
    paddingBottom: '4px',
  },
  icon: {
    paddingRight: '8px',
  },
}));

export const KycApplicationLandingPage = () => {
  const classes = useStyles();

  const redirects = new FormRedirects(
    useHistory,
    Page.kycCompanyDetails,
    Page.dashboard,
  );

  interface ListItem {
    title: string;
    icon: IconEnum;
    description?: string;
    subpoints: string[];
  }
  const bulletedList: ListItem[] = [
    {
      title: 'Company Information',
      icon: IconEnum.house_building,
      subpoints: [
        'Date and State of incorporation',
        'Company phone',
        'Tax ID / EIN',
        'Business address',
        'Proof of assets (via bank connection or uploaded documents)',
      ],
    },
    {
      title: 'Company owners and/or co-founder(s)',
      icon: IconEnum.id_card,
      description: `A company owner is a person who owns 25% or more of your company. If no individual owns more than 25%, someone with financial control over the company (for example, the CEO or CFO).`,
      subpoints: [
        'Full name and date of birth',
        'Citizenship and SSN',
        'Current address and phone number',
        'Ownership percentage',
      ],
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Heading size={36} text={`Let's get started`} />
        <Text
          color={Color.neutral._80}
          text='This will take approximately 5–10 minutes to complete.'
        />
        <Card className={classes.card}>
          <Content
            flex
            gap={8}
            paddingBottom={16}
            paddingLeft={16}
            paddingTop={16}
          >
            <Text text="Here's what you will need:" variant='medium' />
          </Content>
          {bulletedList.map(({ title, description, icon, subpoints }) => {
            return (
              <Content key={title} paddingLeft={48}>
                <Content
                  key={title}
                  flex
                  flexDirection='row'
                  alignItems='center'
                  gap={0}
                  className={classes.title}
                >
                  <Icon name={icon} size={15} className={classes.icon} />
                  <Text text={title} size={15} variant='medium' />
                </Content>
                {description && (
                  <Text
                    text={description}
                    size={15}
                    className={classes.description}
                  />
                )}
                <ul className={classes.bulletList}>
                  {subpoints.map((text, index) => {
                    return (
                      <li
                        key={`bulleted-list-item-${index}`}
                        className={classes.listItem}
                      >
                        <Text text={text} size={15} />
                      </li>
                    );
                  })}
                </ul>
              </Content>
            );
          })}
        </Card>

        <Content paddingTop={8}>
          <Button
            label={'Start verification'}
            className={classes.button}
            onClick={redirects.Forward}
          />
        </Content>
      </div>
    </div>
  );
};
