import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Button,
  Content,
  Color,
  Flex,
  Icon,
  IconEnum,
  Modal,
  Text,
  Tooltip,
} from 'component-library';
import { useHistory } from 'react-router-dom';
import { Page, ExpenseClassificationProcessingSteps } from 'lib/constants';
import { useFeatureFlags } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  headerContent: {
    position: 'relative',
  },
  logoContent: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
    padding: '32px 24px',
    gap: '16px',
    boxSizing: 'border-box',
  },
  saveInvite: {
    display: 'flex',
    gap: '16px',
    position: 'absolute',
    top: '36px',
    right: '48px',

    '& button': {
      padding: 0,
      height: 'auto',
    },
  },
  inviteBtn: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    paddingRight: '16px',
    borderRight: `1px solid ${Color.neutral._20}`,
    '& > a': {
      padding: 0,
      height: 'auto',
    },
  },
  mslogo: {
    maxWidth: '106px',
    paddingRight: '16px',
    borderRight: `1px solid ${Color.neutral._20}`,
  },
}));

interface HeaderBarProps {
  currentPage: string;
}

const HeaderBar = ({ currentPage }: HeaderBarProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [showExitModal, setShowExitModal] = useState<boolean>(false);
  const featureFlags = useFeatureFlags();
  const showNewMSLogo = featureFlags.showNewMainStreetLogo;
  const enableAuth0Invites = featureFlags.enableAuth0Invites;

  const logoUrl = showNewMSLogo
    ? `${process.env.PUBLIC_URL}/images/logo/mst-logo-green.svg`
    : `${process.env.PUBLIC_URL}/images/MainSt_WordmarkGreen.svg`;

  return (
    <>
      <div className={classes.headerContent}>
        <div className={classes.logoContent}>
          <img
            className={classes.mslogo}
            src={logoUrl}
            alt='MainStreet watermark'
          />
          <Text
            variant='medium'
            size={18}
            text='R&D Assessment'
            color={Color.neutral._80}
          />
        </div>
        <div className={classes.saveInvite}>
          {enableAuth0Invites && (
            <div className={classes.inviteBtn}>
              <Button
                variant='tertiary'
                label='Invite to edit'
                onClick={{ location: `/${Page.settings}`, external: true }}
              />
              <Tooltip position='top' text='Invite someone to edit'>
                <Icon color={Color.neutral._60} name={IconEnum.info_circle} />
              </Tooltip>
            </div>
          )}
          <Button
            variant='tertiary'
            label='Exit'
            onClick={() => {
              if (
                !currentPage ||
                currentPage === ExpenseClassificationProcessingSteps[3]
              ) {
                history.push('/');
              } else {
                setShowExitModal(true);
              }
            }}
          />
        </div>
      </div>
      <Modal
        showModal={showExitModal}
        closeToggle={() => setShowExitModal(false)}
        maxWidth={536}
      >
        <>
          <Content paddingTopBottom={24} paddingLeftRight={24}>
            <Text
              variant='medium'
              size={18}
              text='Are you sure you want to exit?'
              paddingBottom={16}
            />
            <Text>
              Only the answers up to the last completed step will be saved
            </Text>
          </Content>
          <Flex padding={24} justifyContent='flex-end' gap={16}>
            <Button
              label='Keep editing'
              onClick={() => setShowExitModal(false)}
              variant='outlined'
            />
            <Button
              label='Exit'
              onClick={() => {
                setShowExitModal(false);
                history.push('/tax-credits');
              }}
            />
          </Flex>
        </>
      </Modal>
    </>
  );
};

export default HeaderBar;
