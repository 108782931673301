import React from 'react';
import { Text, Dollar } from 'component-library';
import { makeStyles, Theme } from '@material-ui/core';
import { CentsToDisplayStringNoSymbol } from 'lib/helpers';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(({ emerald, breakpoints }: Theme) => ({
  totalCredits: {
    backgroundColor: emerald,
    padding: '48px',
    minHeight: '400px',
    flex: 2,
    [breakpoints.down('sm')]: {
      padding: '24px',
      minHeight: 'auto',
    },
  },
  totalHeading: {
    color: 'white',
    '& > span': {
      fontSize: '38px',
      fontWeight: 500,
      lineHeight: '1.6 !important',
    },
  },
}));

interface CreditsEstimateTotalBenefitsProps {
  ifOnlyDisasterRelief: boolean;
  hasDisasterRelief: boolean;
  totalBenefitsLow: number;
  totalBenefitsHigh: number;
}

export const CreditsEstimateTotalBenefits = ({
  ifOnlyDisasterRelief,
  hasDisasterRelief,
  totalBenefitsLow,
  totalBenefitsHigh,
}: CreditsEstimateTotalBenefitsProps) => {
  const classes = useStyles();
  const lowEstimate =
    totalBenefitsLow && CentsToDisplayStringNoSymbol(totalBenefitsLow);
  const highEstimate =
    totalBenefitsHigh && CentsToDisplayStringNoSymbol(totalBenefitsHigh);
  const { app } = useCommonStores();

  return (
    <div
      className={classes.totalCredits}
      data-testid={'credits-estimate-total-benefits'}
    >
      <Text size={app.isMobile ? 23 : 32} className={classes.totalHeading}>
        {!ifOnlyDisasterRelief && hasDisasterRelief ? (
          <>
            We think you and your employees are eligible for a total benefit of{' '}
          </>
        ) : !ifOnlyDisasterRelief ? (
          <>We think your business is eligible for </>
        ) : (
          <>We think your employees are eligible for a total benefit of </>
        )}
        <br />
        {lowEstimate && highEstimate && (
          <>
            <Dollar variant='medium' value={lowEstimate} tag='span' />–
            <Dollar variant='medium' value={highEstimate} tag='span' />
          </>
        )}
      </Text>
    </div>
  );
};
