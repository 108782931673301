import React from 'react';
import { Text, Dollar, Spinner, Tooltip, Color } from 'component-library';
import { makeStyles } from '@material-ui/core';

import { CentsToDisplayString } from 'lib/helpers';
import { BalanceInformation } from './AccountBalanceCard';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classnames = require('classnames/bind');

const useStyles = makeStyles(() => ({
  accountContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '0 0 1px',
  },
  balanceContainer: { padding: '32px 32px 24px' },
  interestContainer: {
    borderLeft: `1px solid ${Color.neutral._20}`,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  interestData: {
    paddingLeft: '32px',
  },
  interestDescription: {
    width: '400px',
    marginTop: '20px',
  },
  verticalLine: {
    position: 'absolute',
    left: 0,
    width: '1px',
    height: '100%',
    backgroundColor: Color.neutral._20,
  },
  green: {
    color: Color.green._70,
  },
  balanceSpinner: {
    margin: '10px auto',
  },
  tooltip: {
    verticalAlign: 'sub',
    color: '#67747D',
    display: 'inline-flex',
  },
  infoIcon: {
    fontSize: '18px',
    marginLeft: '2px',
  },
}));

interface Props {
  balanceInfo?: BalanceInformation;
  className?: string;
}

export const AccountBalanceOverview = ({ balanceInfo, className }: Props) => {
  const classes = useStyles();
  const cardClassnames = classnames(classes.accountContainer, className);

  return (
    <>
      {balanceInfo ? (
        <>
          <div className={cardClassnames}>
            <div className={classes.balanceContainer}>
              <Text size={15}>Account balance</Text>
              <Dollar
                value={CentsToDisplayString(
                  balanceInfo!.principalBalanceCents +
                    balanceInfo!.accruedInterestCents,
                  2,
                  2,
                ).replace('$', '')}
                variant='medium'
                tag='h2'
              />
            </div>
            <div className={classes.interestContainer}>
              <div className={classes.interestData}>
                <Text size={15}>Accrued interest</Text>
                <Text variant='medium' size={23} color={Color.green._70}>
                  {CentsToDisplayString(
                    balanceInfo!.accruedInterestCents,
                    2,
                    2,
                  )}
                </Text>
              </div>
              <div className={classes.interestData}>
                <Text size={15}>Principal amount</Text>
                <Text variant='medium' size={23}>
                  {CentsToDisplayString(
                    balanceInfo!.principalBalanceCents,
                    2,
                    2,
                  )}
                </Text>
              </div>
              <div className={classes.interestData}>
                <Text size={15}>
                  <>
                    APY
                    <Tooltip
                      className={classes.tooltip}
                      text='Applicable yield may vary over time.'
                      position='right'
                    >
                      <InfoOutlinedIcon className={classes.infoIcon} />
                    </Tooltip>
                  </>
                </Text>
                <Text variant='medium' size={23}>
                  {`${balanceInfo!.annualPercentYield}%`}
                </Text>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Spinner size='small' className={classes.balanceSpinner} />
      )}
    </>
  );
};
