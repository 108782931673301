import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Color, Flex, Link, Text } from 'component-library';
import { QuarterlyForm8974DocumentUrl } from 'lib/interfaces';
import { Form8974QuarterlyDownloads } from './Form8974QuarterlyDownloads';
import { FINCH_PAYROLL_SYSTEMS, PayrollTierEnum } from 'lib/constants';
import { CentsToDisplayString, formatPayrollString } from 'lib/helpers';

interface QuarterFilingForm8974ModuleProps {
  quarterlyForm8974: QuarterlyForm8974DocumentUrl[];
  currentQuarter?: boolean;
  payrollTier?: string;
  payrollProvider?: string;
  creditBalance?: number;
}

const useStyles = makeStyles(() => ({
  container: {
    border: `1px solid ${Color.neutral._20}`,
  },
  instructions: {
    backgroundColor: Color.neutral._light_20,
  },
  list: {
    paddingLeft: '16px',
    margin: 0,
    lineHeight: '1.8',
  },
}));

export const QuarterFilingForm8974Module = observer(
  ({
    quarterlyForm8974,
    currentQuarter,
    payrollTier,
    payrollProvider,
    creditBalance,
  }: QuarterFilingForm8974ModuleProps) => {
    const classes = useStyles();
    const payrollProviderDisplay = payrollProvider
      ? FINCH_PAYROLL_SYSTEMS.find((p) => p.id === payrollProvider)
          ?.displayName || formatPayrollString(payrollProvider)
      : 'payroll software ';

    const renderTier2CurrentContent = (creditBalance: number) => {
      const urlString = payrollProviderDisplay.replace(/ /g, '-');

      return (
        <>
          <Text
            variant='medium'
            size={23}
            text='Current quarter filing instructions:'
          />
          <ol className={classes.list}>
            <li>
              <Text text='Download Form 8974 for this current quarter. This form is distinct from the ones you just downloaded, and must be filed a different way.' />
            </li>
            <li>
              <Text
                text={`Follow this link to set up automatic redemption with ${payrollProviderDisplay}.`}
              />
            </li>
            <li>
              <Text
                text={`${payrollProviderDisplay} will ensure your remaining ${CentsToDisplayString(
                  creditBalance,
                )} in credits is redeemed against your payroll taxes.`}
              />
            </li>
          </ol>
          <Text>
            <Link
              href={`https://mainstreet1.my.site.com/help/s/article/How-to-File-Your-Form-8974-with-${urlString}`}
              external
            >
              How to file Form 8974 with {payrollProviderDisplay}
            </Link>
          </Text>
          <Text size={13}>
            <strong>Note:</strong> If you use a PEO, please work with them to
            file your previous quarters&apos; forms before completing the step
            below.
          </Text>
        </>
      );
    };

    const tier3FilingLinks = () => {
      switch (payrollProvider) {
        case 'zenefits':
          return 'https://mainstreet1.my.site.com/help/s/article/How-to-File-Your-Form-8974-with-Zenefits';
        case 'insperity':
          return 'https://mainstreet1.my.site.com/help/s/article/How-to-File-Your-Form-8974-with-Insperity';
        case 'quickbooks':
          return 'https://mainstreet1.my.site.com/help/s/article/How-to-File-Your-Form-8974-with-QuickBooks';
        default:
          return 'https://mainstreet1.my.site.com/help/s/article/How-to-Manually-File-Your-Form-8974';
      }
    };

    return (
      <Flex direction='column' className={classes.container}>
        <Flex
          className={classes.instructions}
          direction='column'
          padding={24}
          gap={8}
        >
          {currentQuarter ? (
            <>
              {payrollTier === PayrollTierEnum.TIER_2 && creditBalance ? (
                renderTier2CurrentContent(creditBalance)
              ) : (
                <>
                  <Text
                    variant='medium'
                    size={23}
                    text='Current quarter filing instructions:'
                  />
                  <ol className={classes.list}>
                    <li>
                      <Text text='Download Form 8974 for this current quarter.' />
                    </li>
                    <li>
                      <Text tag='span' text='Follow this link ' />
                      <Link
                        text='How to file Form 8974 with your taxes'
                        external
                        href={tier3FilingLinks()}
                      />
                      <Text
                        tag='span'
                        text='for instructions on how to file -- this will be slightly different from how you file forms for previous quarters.'
                      />
                    </li>
                    <li>
                      <Text text="Rinse + repeat next quarter, until you've fully utilized your credit." />
                    </li>
                  </ol>
                  <Text size={13}>
                    <strong>Note:</strong> If you regularly remit payroll tax
                    payments, you can expect a refund from the IRS 8-12 weeks
                    after filing. If you make only quarterly payroll tax
                    payments, Form 8974 will reduce the amount you&apos;ll need
                    to pay with this filing.
                  </Text>
                </>
              )}
            </>
          ) : (
            <>
              <Text
                variant='medium'
                size={23}
                text='Missed quarter filing instructions:'
              />
              <ol className={classes.list}>
                <li>
                  <Text text='Download Form 8974 and the draft Form 941-X below for each quarter. These documents are unique to each quarter.' />
                </li>
                <li>
                  <Text tag='span' text='Follow this link ' />
                  <Text
                    tag='span'
                    text='for instructions on how to file these forms. '
                  />
                  <Link
                    text='How to file with the IRS'
                    external
                    href='https://mainstreet1.my.site.com/help/s/article/How-to-File-a-Late-8974-if-You-Elected-To-Claim-the-Payroll-Credit'
                  />
                </li>
                <li>
                  <Text text='The IRS will send you a refund check! Due to ongoing IRS delays, this may take 12+ weeks.' />
                </li>
              </ol>
            </>
          )}
        </Flex>
        {quarterlyForm8974.map((download, index) => (
          <Form8974QuarterlyDownloads
            key={index}
            taxYear={download.year}
            quarter={download.quarter}
            classes={classes}
            current={currentQuarter}
            form8974Url={download.form8974}
            form941XUrl={download.form941x}
          />
        ))}
      </Flex>
    );
  },
);
