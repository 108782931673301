import React from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Alert, Animate, Color, Flex, Spinner, Text } from 'component-library';
import {
  useCompany,
  useFeatureFlags,
  useTaxCreditsStores,
} from 'stores/useStores';
import moment from 'moment';
import {
  SectionCard,
  SectionCardWithHeader,
} from 'products/tax-credits/features/order-form/components/SectionCard';
import {
  AddPaymentMethod,
  SummaryContent,
} from 'products/tax-credits/features/order-form/components';
import { CentsToDisplayString, useEffectOnce } from 'lib/helpers';
import { OrderFormAttestation } from 'products/tax-credits/features/order-form/components/OrderFormFooter/OrderFormAttestation';
import { ERCQualificationModal } from '../ERCQualificationModal';
import { ERCQualificationStatusEnum } from 'lib/constants';
import { SurveyFlowContainer } from 'products/tax-credits/components';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '900px',
    width: '100%',
  },
  date: {
    display: 'inline-block',
    marginBottom: '8px',
  },
}));

interface BillingSummaryProps {
  pathTo: string;
  isPrefilled: boolean;
}

export const BillingSummary = observer(
  ({ pathTo, isPrefilled }: BillingSummaryProps) => {
    const classes = useStyles();
    const { company } = useCompany();
    const featureFlags = useFeatureFlags();
    const { erc, orderForms } = useTaxCreditsStores();

    const handleOnContinue = async () => {
      erc.setModalContinueIsLoading(true);
      await erc.moveAllProgramsToMsReview().then(() => {
        window.location.replace(pathTo);
        erc.setShowQualificationStatusModal(false);
        erc.setModalContinueIsLoading(false);
      });
    };

    const handleFormAttestation = () => {
      orderForms.setAttestationAccepted();
      erc.setBillingSummaryModal(ERCQualificationStatusEnum.BILLING_SUMMARY);
    };

    const msFeeAmount = erc.creditEstimate
      ? CentsToDisplayString(erc.creditEstimate?.feeAmount, 0, 0)
      : '';
    const estimateTotalAmount = erc.creditEstimate
      ? CentsToDisplayString(erc.creditEstimate?.estimateAmount, 0, 0)
      : '';
    const netTotalAmount = erc.creditEstimate
      ? CentsToDisplayString(
          erc.creditEstimate?.estimateAmount - erc.creditEstimate?.feeAmount,
          0,
          0,
        )
      : '';

    const isDisabled =
      !orderForms.defaultPaymentMethodV2 ||
      (featureFlags.showOrderFormAttestation &&
        !orderForms.attestationAccepted);

    useEffectOnce(async () => {
      await erc.getERCEstimate();
    });

    return (
      <SurveyFlowContainer
        title='Employee Retention Credit'
        onContinue={() => erc.setShowQualificationStatusModal(true)}
        isDisabled={isDisabled}
        dataTestId='erc-qualify-survey'
      >
        <Flex className={classes.container} direction='column' gap={24}>
          <Text>
            <Text tag='span' variant='medium' className={classes.date}>
              Expected billing date:
            </Text>{' '}
            {moment(new Date()).add(4, 'months').format('MMMM D, YYYY')}
          </Text>
          <Alert
            backgroundColor={Color.blue._10}
            text={
              <Flex direction='column'>
                <Text size={15}>
                  This credit estimate is non-binding. Your final credit may
                  differ based on your company&apos;s unique facts and
                  circumstances.
                </Text>
              </Flex>
            }
            type='info'
            variant='in_card'
          />
          {erc.creditEstimate ? (
            <SectionCardWithHeader sectionTitle='Summary'>
              <SummaryContent
                creditTitle={'2021 Employee Retention Credit'}
                mainStreetFee={msFeeAmount}
                estimateTotal={estimateTotalAmount}
                netBenefit={netTotalAmount}
                programTaxYear={2021}
              />
            </SectionCardWithHeader>
          ) : (
            <Flex justifyContent='center' padding={24}>
              <Spinner />
            </Flex>
          )}
          <SectionCard>
            <>
              <AddPaymentMethod showBankNotice={false} />
              <Alert
                backgroundColor={Color.blue._10}
                text={
                  <Flex direction='column'>
                    <Text size={15}>
                      You will not be charged yet. Charges will be applied when
                      you are expected to receive your refund(s) from the IRS.
                      Your billing date is currently set to four months from
                      today, and may change with notice as IRS timelines evolve.
                    </Text>
                  </Flex>
                }
                type='info'
                variant='in_card'
              />
            </>
          </SectionCard>
          <OrderFormAttestation
            companyName={company.legalName}
            checked={orderForms.attestationAccepted}
            onChange={handleFormAttestation}
            showAttestationCheckbox={featureFlags.showOrderFormAttestation}
          />
          {erc.qualificationStatus && (
            <Animate enter={'fade-in'}>
              <ERCQualificationModal onClick={handleOnContinue} />
            </Animate>
          )}
        </Flex>
      </SurveyFlowContainer>
    );
  },
);
