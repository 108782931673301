import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { useTaxCreditsStores } from 'stores/useStores';
import { Color, Flex, Heading, Image, Modal, Text } from 'component-library';

const useStyles = makeStyles(() => ({
  modalBackground: {
    position: 'relative',
    backgroundColor: Color.blue._20,
  },
  modalWhite: {
    backgroundColor: 'white',
  },
  loadingBar: {
    position: 'absolute',
    bottom: '-1px',
    left: 0,
    width: 0,
    height: '5px',
    backgroundColor: Color.blue._40,
    animation: 'loadingBar 5s linear',
  },
  '@global': {
    '@keyframes loadingBar': {
      '0%': {
        width: 0,
      },
      '100%': {
        width: '100%',
      },
    },
  },
}));

interface AccumulatedModalProps {
  programName: string;
  subtitle: string;
}

export const AccumulatedModal = observer(
  ({ programName, subtitle }: AccumulatedModalProps) => {
    const classes = useStyles();
    const { unifiedTaxCredits } = useTaxCreditsStores();

    return (
      <Modal showModal={unifiedTaxCredits.showAccumulatedModal} maxWidth={560}>
        <Flex alignItems='center' className={classes.modalBackground}>
          <Flex justifyContent='center' padding={[0, 24]} basis={250}>
            <Image
              src={`${process.env.PUBLIC_URL}/images/tax-credit-dashboard-module/all-set.svg`}
              width={150}
            />
          </Flex>
          <Flex
            direction='column'
            padding={[80, 24]}
            className={classes.modalWhite}
          >
            {unifiedTaxCredits.surveyAccumulated?.creditAmountCents === 0 ? (
              <>
                <Heading tag='h4'>
                  You&apos;re eligible for the {programName} credit, but your
                  calculated credit is{' '}
                  {unifiedTaxCredits.surveyAccumulated?.creditAmount}.
                </Heading>
                <Text>
                  Please consider revisiting your responses to confirm
                  they&apos;re accurate and complete.
                </Text>
              </>
            ) : (
              <>
                <Heading tag='h4'>
                  {programName} credit adds{' '}
                  <Heading tag='span' color={Color.green._60} variant='medium'>
                    {unifiedTaxCredits.surveyAccumulated?.creditAmount}{' '}
                  </Heading>
                  to your total credit!
                </Heading>
                <Text>{subtitle}</Text>
              </>
            )}
          </Flex>
          <div className={classes.loadingBar} />
        </Flex>
      </Modal>
    );
  },
);
