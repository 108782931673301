import { useCallback, useEffect, useState } from 'react';
import {
  Page,
  PayrollStatus,
  ProgramCardData,
  ProgramNameEnum,
  ProgramStageEnum,
  QualificationStatusEnum,
  TAXYEAR2021,
} from 'lib/constants';
import { FiscalTaxYearHasPayrollGap } from 'lib/helpers';
import _ from 'lodash';
import {
  GetPayrollGapYearToCheck,
  HaveNotAnswerPayrollQuestionOrAnswerIsAnotherConnection,
} from 'lib/payrollGap';
import {
  useCompany,
  useFeatureFlags,
  useLegacyClients,
} from 'stores/useStores';

interface ProgramCardCta {
  label: string;
  location?: string;
  onClick?: () => void;
  payrollStatus?: PayrollStatus;
}

const useGetProgramCta = (
  programs: ProgramCardData[],
): { programCta: ProgramCardCta[] } => {
  const [programCta, setProgramCta] = useState<ProgramCardCta[]>([]);

  const { company } = useCompany();
  const { client } = useLegacyClients();
  const featureFlags = useFeatureFlags();
  const showConnectPayrollGapCTA = featureFlags.isGovConnectPayrollGapEnabled;
  const redirectToTaxCreditsPage =
    featureFlags.isTaxCreditsPageCreditBalanceCardEnabled;

  const programCtas = useCallback(
    (fedRDPrograms: ProgramCardData[]) => {
      const hasOrderFormToSign = programs.filter(
        (program) => program.orderForm?.acceptedAt === null,
      );
      // Federal R&D Program specific CTA
      fedRDPrograms.forEach((program) => {
        if (
          program.stage === ProgramStageEnum.EXPENSE_CLASSIFICATION &&
          program.taxYear === TAXYEAR2021
        ) {
          setProgramCta((prevState) => [
            ...prevState,
            {
              label: 'Classify expenses',
              location: `/${Page.expenseClassificationOverview}/${program.id}`,
            },
          ]);
        } else if (
          program.stage === ProgramStageEnum.CLIENT_REVIEW &&
          program.taxYear === 2021 // hard code year for now as the latest fed rd program would show 2022
        ) {
          setProgramCta((prevState) => [
            ...prevState,
            {
              label: 'Review tax credit forms',
              location: redirectToTaxCreditsPage
                ? `/${Page.taxCredits}`
                : `/${Page.fedRdProgram}/${program.id}`,
            },
          ]);
        } else if (
          program.qualificationStatus ===
          QualificationStatusEnum.QUALIFICATION_IN_PROGRESS
        ) {
          setProgramCta((prevState) => [
            ...prevState,
            {
              label: `Qualify for ${program.taxYear}`,
              location: `/${Page.taxCreditQualificationRoot}/${program.taxYear}`,
            },
          ]);
        }
      });

      // If any programs have order forms to sign
      if (hasOrderFormToSign.length > 0) {
        hasOrderFormToSign.forEach((program) => {
          setProgramCta((prevState) => [
            ...prevState,
            {
              label: `Sign ${program.taxYear} order`,
              location: `${Page.orderForms}/${program.taxYear}`,
            },
          ]);
        });
      }
    },
    [programs],
  );

  // Check if company has payroll connection
  const payrollRelatedCTAs = useCallback(
    (fedRDPrograms: ProgramCardData[]) => {
      const taxYearToCheckForPayrollGap = GetPayrollGapYearToCheck(
        fedRDPrograms,
      );

      if (taxYearToCheckForPayrollGap && company.rawData) {
        const programId = fedRDPrograms.find(
          (program) => program.taxYear === taxYearToCheckForPayrollGap,
        )?.id;

        const notAnsweredPayrollQuestionOrAnsweredWithDiffConnection = HaveNotAnswerPayrollQuestionOrAnswerIsAnotherConnection(
          company.rawData,
          programId,
        );

        client
          .PayrollImportStatus(company.id, taxYearToCheckForPayrollGap)
          .then((res) => {
            if (res) {
              const hasGapInPayroll = FiscalTaxYearHasPayrollGap(
                res.firstPayStatementOfTaxYear,
                res.lastPayStatementOfTaxYear,
                company.fiscalYearEndDate,
              );

              const hasMigration = res.possiblePayrollMigration;

              if (!res.isConnected) {
                setProgramCta((prevState) => [
                  ...prevState,
                  {
                    label: 'Connect payroll system',
                    location: `/${Page.connections}`,
                    payrollStatus: PayrollStatus.NOT_CONNECTED,
                  },
                ]);
              } else if (
                hasGapInPayroll &&
                notAnsweredPayrollQuestionOrAnsweredWithDiffConnection &&
                !hasMigration
              ) {
                setProgramCta((prevState) => [
                  ...prevState,
                  {
                    label: 'Fix payroll gap',
                    location: `/${Page.connections}`,
                    payrollStatus: PayrollStatus.GAP,
                  },
                ]);
              }
            }
          });
      }
    },
    [client, company],
  );

  useEffect(() => {
    const fedRDPrograms = programs.filter(
      (program) => program.name === ProgramNameEnum.FED_RD_TAX,
    );

    if (showConnectPayrollGapCTA) {
      payrollRelatedCTAs(fedRDPrograms);
    }
    programCtas(fedRDPrograms);
  }, [payrollRelatedCTAs, showConnectPayrollGapCTA, programCtas, programs]);

  const programCtaList = _.uniqBy(programCta, 'label');

  return { programCta: programCtaList };
};

export default useGetProgramCta;
