import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  GustoRipplingRedemptionDownloadStep,
  QuarterFilingForm8974Module,
  QuarterlyRedemptionPageContainer,
} from './components';
import { useCompany, useTaxCreditsStores } from 'stores/useStores';
import { useEffectOnce } from 'lib/helpers';
import { Flex, Spinner } from 'component-library';
import { Redirect } from 'react-router-dom';
import { Page } from 'lib/constants';

interface QuarterlyRedemptionDownloadPageProps {
  programId: number;
}

export const QuarterlyRedemptionDownloadPage = observer(
  ({ programId }: QuarterlyRedemptionDownloadPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { form8974, redemption } = useTaxCreditsStores();
    const { company } = useCompany();

    useEffectOnce(async () => {
      setIsLoading(true);
      await redemption.checkExistingDocumentURL();
      await form8974
        .refreshCompanyCreditBalance()
        .then(() => setIsLoading(false));
    });

    const handleOnContinue = async () => {
      await redemption.confirmedDownloadOnContinue();
    };

    // Tier 1 specific instructions
    const isGusto = form8974.payrollProvider === 'gusto';
    const isRippling = form8974.payrollProvider === 'rippling';

    const isAllPayrollProviderSetupCompleted8974True = company.programs.every(
      (p) => p.payrollProviderSetupCompleted8974 === true,
    );

    if (
      redemption.quarterlyFor8974DocumentUrl.missedQuarters.length === 0 &&
      isAllPayrollProviderSetupCompleted8974True
    ) {
      return <Redirect to={`/${Page.taxCredits}`} />;
    }

    return (
      <QuarterlyRedemptionPageContainer
        title='Download forms'
        infoTitle='Follow the filing instructions below'
        infoContent="Please note that the instructions to file for previous quarters differ from the instructions to file for this quarter. If you don't complete all actions within this step, you won't be able to redeem your credits."
        onContinue={handleOnContinue}
        onDownloadPage
        showExitButton={false}
      >
        <>
          <QuarterFilingForm8974Module
            quarterlyForm8974={
              redemption.quarterlyFor8974DocumentUrl.missedQuarters
            }
          />
          {(isGusto || isRippling) && form8974.creditBalanceCents > 0 && (
            <GustoRipplingRedemptionDownloadStep
              isGusto={isGusto}
              isRippling={isRippling}
              creditBalance={form8974.creditBalanceCents}
            />
          )}
          {!isGusto && !isRippling && (
            <>
              {isLoading ? (
                <Flex padding={24} justifyContent='center'>
                  <Spinner size='small' />
                </Flex>
              ) : (
                <>
                  {form8974.payrollProvider &&
                    form8974.payrollTier &&
                    redemption.quarterlyFor8974DocumentUrl.currentQuarter
                      .length > 0 && (
                      <QuarterFilingForm8974Module
                        quarterlyForm8974={
                          redemption.quarterlyFor8974DocumentUrl.currentQuarter
                        }
                        currentQuarter
                        payrollProvider={form8974.payrollProvider}
                        payrollTier={form8974.payrollTier}
                        creditBalance={form8974.creditBalanceCents}
                      />
                    )}
                </>
              )}
            </>
          )}
        </>
      </QuarterlyRedemptionPageContainer>
    );
  },
);
