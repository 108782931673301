import React from 'react';
import { SideDrawer, Stepper } from 'component-library';
import { observer } from 'mobx-react';
import {
  useCommonStores,
  useLegacyClients,
  useTaxCreditsStores,
} from 'stores/useStores';
import {
  ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
  AssessmentSurveyStepsIndex,
  Page,
  ProgramNameEnum,
  ProgramSubStageEnum,
} from 'lib/constants';
import {
  AssessmentProgressStatus,
  TaxCreditAssessmentProgramsHygraphId,
} from 'lib/interfaces';
import { useEffectOnce } from 'lib/helpers';

interface AllSectionsSideDrawerProps {
  taxYear: number;
  stepIndex: number;
}

export const AllSectionsSideDrawer = observer(
  ({ taxYear, stepIndex }: AllSectionsSideDrawerProps) => {
    const { unifiedTaxCredits } = useTaxCreditsStores();
    const { app } = useCommonStores();
    const { client } = useLegacyClients();
    const assessmentSurveyRootPath = `/${Page.taxCredits}/${Page.assessment}/${taxYear}`;
    const taxYearAssessments =
      unifiedTaxCredits.assessmentByYear[ACCOUNT_CREATION_QUALIFYING_TAX_YEAR];

    const hasCompletedStep = (programName: ProgramNameEnum): boolean => {
      const findProgramAssessment = taxYearAssessments?.find(
        (p) => p.id === TaxCreditAssessmentProgramsHygraphId[programName],
      );

      if (findProgramAssessment) {
        if (
          findProgramAssessment.status ===
            AssessmentProgressStatus.IN_PROGRESS ||
          findProgramAssessment.status === AssessmentProgressStatus.MS_REVIEW
        ) {
          return true;
        }
      }

      return false;
    };

    const isStillInProgress = (programName: ProgramNameEnum) => {
      const findProgramAssessment = taxYearAssessments?.find(
        (p) => p.id === TaxCreditAssessmentProgramsHygraphId[programName],
      );

      if (findProgramAssessment) {
        if (
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED ===
          findProgramAssessment.subStage
        ) {
          return true;
        }
      }
      return undefined;
    };

    const allowEditForRdSubStage = (
      programName: ProgramNameEnum,
      subStageName: ProgramSubStageEnum,
    ) => {
      const findProgramAssessment = taxYearAssessments?.find(
        (p) => p.id === TaxCreditAssessmentProgramsHygraphId[programName],
      );

      if (
        findProgramAssessment &&
        findProgramAssessment.subStage &&
        ProgramNameEnum.FED_RD_TAX === programName
      ) {
        // Allow editing for all Previous Pages of RD only
        const editableSubStages = [
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT,
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EMPLOYEES,
        ];

        if (editableSubStages.includes(findProgramAssessment.subStage)) {
          return true;
        }

        // Allow customer to edit Company Details only.
        if (
          subStageName ===
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_COMPANY_DETAILS
        ) {
          // Define an array of sub-stages where editing is allowed when subStageName is COMPANY_DETAILS.
          const allowedSubStages = [
            ProgramSubStageEnum.EXPENSE_CLASSIFICATION_COMPANY_DETAILS,
            ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED,
          ];

          // Check if the program assessment's subStage is in the array of allowed sub-stages.
          if (allowedSubStages.includes(findProgramAssessment.subStage)) {
            return true;
          }
        }

        // Allow customer to edit Company Details + Supplies and Services only.
        if (
          (subStageName ===
            ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EXPENSES ||
            subStageName ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_COMPANY_DETAILS) &&
          findProgramAssessment.subStage ===
            ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EXPENSES
        ) {
          return true;
        }
      }
      return false;
    };

    useEffectOnce(async () => {
      await unifiedTaxCredits.getTaxCreditAssessments(
        ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
      );
    });

    return (
      <SideDrawer
        show={unifiedTaxCredits.showAllSectionsDrawer}
        closeToggle={() => unifiedTaxCredits.setShowAllSectionsDrawer(false)}
        dataTestId={'all-section-side-drawer'}
        drawerContent={
          <Stepper
            currentStep={stepIndex}
            orientation='vertical'
            steps={[
              {
                step: 'Connect Payroll Account',
                description: ' ',
                stepCta:
                  stepIndex > AssessmentSurveyStepsIndex.CONNECT_PAYROLL
                    ? {
                        text: 'Edit',
                        onClick: async () => {
                          app.history.push(
                            `${assessmentSurveyRootPath}/${Page.rdConnectPayroll}`,
                          );
                          unifiedTaxCredits.setFlowIndex(
                            AssessmentSurveyStepsIndex.CONNECT_PAYROLL,
                          );
                          unifiedTaxCredits.setShowAllSectionsDrawer(false);
                        },
                      }
                    : undefined,
              },
              {
                step: 'General Business Details',
                description: ' ',
                stepCta:
                  stepIndex > AssessmentSurveyStepsIndex.BUSINESS_DETAILS ||
                  unifiedTaxCredits.getGeneralBusinessStatus() ===
                    AssessmentProgressStatus.MS_REVIEW
                    ? {
                        text: 'Edit',
                        onClick: async () => {
                          await client.SetBusinessDetailsHistoricalDataPrefill(
                            taxYear,
                          );
                          window.location.href = assessmentSurveyRootPath;
                          unifiedTaxCredits.setFlowIndex(
                            AssessmentSurveyStepsIndex.BUSINESS_DETAILS,
                          );
                          unifiedTaxCredits.setShowAllSectionsDrawer(false);
                        },
                      }
                    : undefined,
              },
              {
                step: 'Retirement Plan Credit',
                description: ' ',
                stepCta:
                  stepIndex > AssessmentSurveyStepsIndex.RETIREMENT_PLAN ||
                  hasCompletedStep(ProgramNameEnum.FED_RETIREMENT_CREDIT)
                    ? {
                        text: 'Edit',
                        onClick: () => {
                          app.history.push(
                            `${assessmentSurveyRootPath}/${Page.retirementPlan}`,
                          );
                          unifiedTaxCredits.setFlowIndex(
                            AssessmentSurveyStepsIndex.RETIREMENT_PLAN,
                          );
                          unifiedTaxCredits.setShowAllSectionsDrawer(false);
                        },
                      }
                    : undefined,
                stillInProgress: isStillInProgress(
                  ProgramNameEnum.FED_RETIREMENT_CREDIT,
                ),
              },
              {
                step: 'Small Business Healthcare Credit',
                description: ' ',
                stepCta:
                  stepIndex >
                    AssessmentSurveyStepsIndex.SMALL_BUSINESS_HEALTHCARE ||
                  hasCompletedStep(ProgramNameEnum.FED_EMPLOYER_HEALTHCARE)
                    ? {
                        text: 'Edit',
                        onClick: () => {
                          app.history.push(
                            `${assessmentSurveyRootPath}/${Page.healthcare}`,
                          );
                          unifiedTaxCredits.setFlowIndex(
                            AssessmentSurveyStepsIndex.SMALL_BUSINESS_HEALTHCARE,
                          );
                          unifiedTaxCredits.setShowAllSectionsDrawer(false);
                        },
                      }
                    : undefined,
                stillInProgress: isStillInProgress(
                  ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
                ),
              },
              {
                step: 'R&D Company Details',
                description: ' ',
                stepCta: allowEditForRdSubStage(
                  ProgramNameEnum.FED_RD_TAX,
                  ProgramSubStageEnum.EXPENSE_CLASSIFICATION_COMPANY_DETAILS,
                )
                  ? {
                      text: 'Edit',
                      onClick: () => {
                        app.history.push(
                          `${assessmentSurveyRootPath}/${Page.expenseClassificationCompanyDetails}`,
                        );
                        unifiedTaxCredits.setFlowIndex(
                          AssessmentSurveyStepsIndex.RD_COMPANY_DETAILS,
                        );
                        unifiedTaxCredits.setShowAllSectionsDrawer(false);
                      },
                    }
                  : undefined,
                stillInProgress: isStillInProgress(ProgramNameEnum.FED_RD_TAX),
              },
              {
                step: 'R&D Supplies & Services',
                description: ' ',
                stepCta: allowEditForRdSubStage(
                  ProgramNameEnum.FED_RD_TAX,
                  ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EXPENSES,
                )
                  ? {
                      text: 'Edit',
                      onClick: () => {
                        app.history.push(
                          `${assessmentSurveyRootPath}/${Page.expenseClassificationSuppliesServices}`,
                        );
                        unifiedTaxCredits.setFlowIndex(
                          AssessmentSurveyStepsIndex.RD_SUPPLIES_SERVICES,
                        );
                        unifiedTaxCredits.setShowAllSectionsDrawer(false);
                      },
                    }
                  : undefined,
                stillInProgress: isStillInProgress(ProgramNameEnum.FED_RD_TAX),
              },
              {
                step: 'R&D Employees',
                description: ' ',
                stepCta: allowEditForRdSubStage(
                  ProgramNameEnum.FED_RD_TAX,
                  ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EMPLOYEES,
                )
                  ? {
                      text: 'Edit',
                      onClick: () => {
                        app.history.push(
                          `${assessmentSurveyRootPath}/${Page.expenseClassificationEmployees}`,
                        );
                        unifiedTaxCredits.setFlowIndex(
                          AssessmentSurveyStepsIndex.RD_EMPLOYEES,
                        );
                        unifiedTaxCredits.setShowAllSectionsDrawer(false);
                      },
                    }
                  : undefined,
                stillInProgress: isStillInProgress(ProgramNameEnum.FED_RD_TAX),
              },
              {
                step: 'Disabled Access Credit',
                description: ' ',
                stepCta:
                  stepIndex > AssessmentSurveyStepsIndex.DISABLED_ACCESS ||
                  hasCompletedStep(ProgramNameEnum.FED_DISABLED_ACCESS)
                    ? {
                        text: 'Edit',
                        onClick: () => {
                          app.history.push(
                            `${assessmentSurveyRootPath}/${Page.disabledAccess}`,
                          );
                          unifiedTaxCredits.setFlowIndex(
                            AssessmentSurveyStepsIndex.DISABLED_ACCESS,
                          );
                          unifiedTaxCredits.setShowAllSectionsDrawer(false);
                        },
                      }
                    : undefined,
                stillInProgress: isStillInProgress(
                  ProgramNameEnum.FED_DISABLED_ACCESS,
                ),
              },
              {
                step: 'Assessment Review',
                description: ' ',
                stepCta: unifiedTaxCredits.isAllAssessmentReadyForReview(
                  ACCOUNT_CREATION_QUALIFYING_TAX_YEAR,
                )
                  ? {
                      text: 'Edit',
                      onClick: async () => {
                        app.history.push(
                          `${assessmentSurveyRootPath}/${Page.unifiedAssessmentReview}`,
                        );
                        unifiedTaxCredits.setFlowIndex(
                          AssessmentSurveyStepsIndex.REVIEW,
                        );
                        unifiedTaxCredits.setShowAllSectionsDrawer(false);
                      },
                    }
                  : undefined,
              },
            ]}
            noVerticalContainerPadding
          />
        }
        subtitle='You can edit previous sections below'
        title='Assessment Sections'
      />
    );
  },
);
