import { TextField } from 'component-library';
import React from 'react';
import {
  IndividualExpenseClassificationData,
  EmploymentDisplayType,
} from '../../steps/Employees';
import { EmployeeSideDrawerSection } from './EmployeeSideDrawerSection';

interface DetailsSectionProps {
  errorFullName: boolean;
  setErrorFullName: (isError: boolean) => void;
  fullName: string;
  setFullName: (name: string) => void;
  drawerType: EmploymentDisplayType;
  selectedPerson: IndividualExpenseClassificationData | null;
  isEditMode: boolean;
}

export const DetailsSection = ({
  errorFullName,
  setErrorFullName,
  fullName,
  setFullName,
  drawerType,
  selectedPerson,
  isEditMode,
}: DetailsSectionProps) => {
  const isMissingInformation = !selectedPerson?.name && isEditMode;

  return (
    <EmployeeSideDrawerSection title='Personal details'>
      <TextField
        error={errorFullName}
        helperText={errorFullName ? 'Name is required' : ''}
        label='Full name'
        name='full-name'
        onChange={(event) => {
          setErrorFullName(event.target.value.length < 1);
          setFullName(event.target.value);
        }}
        value={fullName}
        dataTestId={`fullName-${drawerType}`}
        secondaryLabel={
          isMissingInformation ? 'Missing information' : undefined
        }
        secondaryLabelTheme={isMissingInformation ? 'warning' : undefined}
      />
    </EmployeeSideDrawerSection>
  );
};
