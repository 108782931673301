import React from 'react';
import { observer } from 'mobx-react';
import { Flex, Link, Checkbox, Text } from 'component-library';
import { Page } from 'lib/constants';

interface OrderFormAttestationProps {
  companyName: string;
  checked: boolean;
  onChange: () => void;
  showAttestationCheckbox: boolean;
}

export const OrderFormAttestation: React.FC<OrderFormAttestationProps> =
  observer(({ companyName, checked, onChange, showAttestationCheckbox }) => {
    return (
      <Flex padding={[0, 0, 32, 0]}>
        {showAttestationCheckbox && (
          <Checkbox
            dataTestId='order-form-attestation'
            onChange={onChange}
            options={[
              {
                checked,
                value: '',
                text: '',
              },
            ]}
          />
        )}
        <Text>
          By accepting this order, {companyName} is entering into an agreement
          with MainStreet Work, Inc governed by these{' '}
          <Link href={`/${Page.tos}`} target='_blank' rel='noopener noreferrer'>
            terms
          </Link>
          , and you confirm you are authorized to do so on behalf of{' '}
          {companyName}.
        </Text>
      </Flex>
    );
  });
