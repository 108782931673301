import React from 'react';

interface Props {
  className?: string;
}

const AttentionNeeded = ({ className }: Props) => (
  <svg
    className={className}
    width='96'
    height='96'
    viewBox='0 0 96 96'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M61.6699 22.77H22.1799V77.39H61.6699V22.77Z' fill='#0A190C' />
    <path
      d='M21.6799 22.77C21.6799 22.4938 21.9038 22.27 22.1799 22.27H61.6699C61.9461 22.27 62.1699 22.4938 62.1699 22.77V77.39C62.1699 77.6661 61.9461 77.89 61.6699 77.89H22.1799C21.9038 77.89 21.6799 77.6661 21.6799 77.39V22.77ZM22.6799 23.27V76.89H61.1699V23.27H22.6799Z'
      fill='#0A190C'
    />
    <path d='M27.73 17.22V71.84H67.22V27.54L56.9 17.22H27.73Z' fill='white' />
    <path
      d='M27.23 17.22C27.23 16.9439 27.4538 16.72 27.73 16.72H56.9C57.0326 16.72 57.1598 16.7727 57.2535 16.8664L67.5735 27.1864C67.6673 27.2802 67.72 27.4074 67.72 27.54V71.84C67.72 72.1161 67.4961 72.34 67.22 72.34H27.73C27.4538 72.34 27.23 72.1161 27.23 71.84V17.22ZM28.23 17.72V71.34H66.72V27.7471L56.6929 17.72H28.23Z'
      fill='#0A190C'
    />
    <path d='M57.54 17.86V26.91L66.58 26.9L57.54 17.86Z' fill='#ACE119' />
    <path
      d='M57.3487 17.3981C57.5355 17.3207 57.7506 17.3634 57.8936 17.5064L66.9336 26.5464C67.0765 26.6894 67.1193 26.9043 67.0421 27.0911C66.9648 27.2779 66.7827 27.3998 66.5806 27.4L57.5406 27.41C57.4079 27.4101 57.2806 27.3575 57.1867 27.2637C57.0928 27.17 57.04 27.0427 57.04 26.91V17.86C57.04 17.6578 57.1619 17.4755 57.3487 17.3981ZM58.04 19.0671V26.4094L65.3743 26.4013L58.04 19.0671Z'
      fill='#0A190C'
    />
    <path d='M57.5399 39.89H37.4099V37.89H57.5399V39.89Z' fill='#0A190C' />
    <path d='M47.4699 48.61H37.4099V46.61H47.4699V48.61Z' fill='#0A190C' />
    <path d='M47.4699 57.34H37.4099V55.34H47.4699V57.34Z' fill='#0A190C' />
    <path
      d='M64.7005 53.722L61.9004 56.5221L80.568 75.1897L83.3682 72.3896L64.7005 53.722Z'
      fill='white'
    />
    <path
      d='M61.5469 56.8757C61.3517 56.6804 61.3517 56.3638 61.5469 56.1686L64.3471 53.3684C64.5423 53.1732 64.8589 53.1732 65.0542 53.3684L83.7218 72.0361C83.917 72.2313 83.917 72.5479 83.7218 72.7432L80.9216 75.5433C80.7264 75.7386 80.4098 75.7386 80.2145 75.5433L61.5469 56.8757ZM62.6076 56.5221L80.5681 74.4826L82.6611 72.3896L64.7006 54.4291L62.6076 56.5221Z'
      fill='#0A190C'
    />
    <path
      d='M63.56 66.12C69.3921 66.12 74.12 61.3921 74.12 55.56C74.12 49.7279 69.3921 45 63.56 45C57.7279 45 53 49.7279 53 55.56C53 61.3921 57.7279 66.12 63.56 66.12Z'
      fill='#ACE119'
    />
    <path
      d='M63.27 45.03C57.714 45.03 53.21 49.534 53.21 55.09C53.21 60.646 57.714 65.15 63.27 65.15C68.8259 65.15 73.33 60.646 73.33 55.09C73.33 49.534 68.8259 45.03 63.27 45.03ZM52.21 55.09C52.21 48.9817 57.1617 44.03 63.27 44.03C69.3782 44.03 74.33 48.9817 74.33 55.09C74.33 61.1983 69.3782 66.15 63.27 66.15C57.1617 66.15 52.21 61.1983 52.21 55.09Z'
      fill='#0A190C'
    />
    <path
      d='M76.3749 64.4588L72.6414 68.1924L83.8419 79.3929L87.5754 75.6594L76.3749 64.4588Z'
      fill='white'
    />
    <path
      d='M72.2879 68.5459C72.0926 68.3506 72.0926 68.0341 72.2879 67.8388L76.0214 64.1053C76.2167 63.91 76.5332 63.91 76.7285 64.1053L87.9291 75.3058C88.1243 75.5011 88.1243 75.8177 87.9291 76.013L84.1956 79.7465C84.0003 79.9417 83.6837 79.9417 83.4884 79.7465L72.2879 68.5459ZM73.3485 68.1924L83.842 78.6858L86.8684 75.6594L76.375 65.1659L73.3485 68.1924Z'
      fill='#0A190C'
    />
    <path
      d='M63.752 56.724H62.324L62.024 51H64.052L63.752 56.724ZM62 58.728C62 58.36 62.1 58.104 62.3 57.96C62.5 57.808 62.744 57.732 63.032 57.732C63.312 57.732 63.552 57.808 63.752 57.96C63.952 58.104 64.052 58.36 64.052 58.728C64.052 59.08 63.952 59.336 63.752 59.496C63.552 59.648 63.312 59.724 63.032 59.724C62.744 59.724 62.5 59.648 62.3 59.496C62.1 59.336 62 59.08 62 58.728Z'
      fill='black'
    />
  </svg>
);

export default AttentionNeeded;
