import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { BankAccount } from '@mainstreet/client-models/financial/banking/bankingModels';
import { Button } from 'component-library';
import { MSPlaidClientContext } from 'lib/financial/MSPlaidClientProvider';
import { IPaymentMethodState } from 'components/PaymentMethod';
import PlaidConnectBtn from './PlaidConnectBtn';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
  },
});

interface ACHButtonsProps {
  selectedAccount: null | BankAccount;
  enableSaveButton: boolean;
  setPaymentMethodState: React.Dispatch<
    React.SetStateAction<IPaymentMethodState>
  >;
  setDefaultIsLoading: boolean;
}

export default function ACHButtons(props: ACHButtonsProps) {
  const classes = useStyles();
  const { state, actions } = useContext(MSPlaidClientContext);
  const { selectedAccount } = props;

  const setDefaultPaymentMethod = async () => {
    props.setPaymentMethodState((prev) => ({
      ...prev,
      setDefaultIsLoading: true,
    }));
    await actions.setDefaultPaymentMethod(state.selectedAccountId, null);
    props.setPaymentMethodState((prev) => ({
      ...prev,
      selectionStatus: 'default',
      setDefaultIsLoading: false,
    }));
  };

  let actionBtn = null;

  if (selectedAccount?.verificationStatus === 'pending_manual_verification') {
    actionBtn = (
      <Button
        label='Complete Verification'
        onClick={actions.open}
        dataTestId='complete-manual-verification-btn'
      />
    );
  } else if (props.enableSaveButton) {
    actionBtn = (
      <Button
        label='Set as default'
        onClick={setDefaultPaymentMethod}
        loading={props.setDefaultIsLoading}
        disabled={!props.enableSaveButton}
        dataTestId='set-default-bank-btn'
      />
    );
  } else if (state.selectedAccountId === 'NEW_ACCOUNT') {
    actionBtn = (
      <PlaidConnectBtn isLoading={state.isLoading} open={actions.open} />
    );
  } else if (
    state.defaultPaymentMethod === null &&
    state.plaidAccounts.length === 0
  ) {
    actionBtn = (
      <PlaidConnectBtn isLoading={state.isLoading} open={actions.open} />
    );
  }
  return <div className={classes.buttonContainer}>{actionBtn}</div>;
}
