import { runInAction } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import { BaseStore } from 'stores/BaseStore';
import { Program } from 'entities/Program';
import {
  ProgramNameEnum,
  ProgramStageEnum,
  Programs,
  QualificationStatusEnum,
} from 'lib/constants';
import { FormattedProgram } from 'products/dashboard';
import _ from 'lodash';
import { getURLSearchParam } from 'lib/helpers';

export class TaxCreditsPageStore extends BaseStore {
  public activePrograms: Program[] = [];
  public showSideDrawer = false;
  public sideDrawerStep: string | undefined;

  public formattedPrograms: FormattedProgram[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public isActiveProgram(program: Program) {
    const activeProgramStages = [
      ProgramStageEnum.QUALIFYING,
      ProgramStageEnum.EXPENSE_CLASSIFICATION,
      ProgramStageEnum.MS_REVIEW,
      ProgramStageEnum.CLIENT_REVIEW,
      ProgramStageEnum.FINISHED,
      // Note:  We don't need to include ProgramStageEnum.COMPLETED because that should not count as an active program. Once a program is in the completed stage it has been fully redeemed and is no longer active.
    ];

    if (program.stage) {
      return (
        activeProgramStages.includes(program.stage) &&
        program.qualificationStatus !== QualificationStatusEnum.DISQUALIFIED
      );
    }
  }

  public addActivePrograms(programs: Program[]) {
    runInAction(() => {
      this.activePrograms = programs.filter((program) =>
        this.isActiveProgram(program),
      );
    });
  }

  public setShowSideDrawer(showSideDrawer: boolean, step?: string) {
    this.showSideDrawer = showSideDrawer;
    this.sideDrawerStep = step;
  }

  public async formatProgramsForSavingsModule() {
    const combinedPrograms: FormattedProgram[] = [];
    // Required so combining estimates doesn't mutate order forms held in activePrograms
    // Filter out all prior year programs for now
    const programsCopy = this.activePrograms.map((program) =>
      _.cloneDeep(program),
    );

    // Need to ensure federal programs are iterated over before any state programs
    // to keep name combination conventions correct
    programsCopy.sort((a, b) =>
      a.name.charCodeAt(0) > b.name.charCodeAt(0) ? 1 : -1,
    );

    for (const program of programsCopy) {
      const { name, taxYear } = program;

      if (
        program.name === ProgramNameEnum.FED_RD_TAX ||
        program.name === ProgramNameEnum.STATE_RD_CA
      ) {
        const existingProgramTaxYear = combinedPrograms.find(
          (program) =>
            program.taxYear === taxYear &&
            program.name === ProgramNameEnum.FED_RD_TAX,
        );

        if (existingProgramTaxYear) {
          if (name === ProgramNameEnum.STATE_RD_CA) {
            const fedEstimate =
              await this.rootStore.dashboard.modules.calculateCreditSavingsEstimate(
                existingProgramTaxYear,
              );
            const stateEstimate =
              await this.rootStore.dashboard.modules.calculateCreditSavingsEstimate(
                program,
              );

            existingProgramTaxYear.creditAmountCents =
              fedEstimate + stateEstimate;
            const federalName = existingProgramTaxYear.programName?.replace(
              'R&D',
              '',
            );

            existingProgramTaxYear.programName = `${federalName} and California R&D`;
          }
        } else {
          const fedProgramEstimate =
            await this.rootStore.dashboard.modules.calculateCreditSavingsEstimate(
              program,
            );
          const updatedName = `${_.startCase(
            _.toLower(name.split('_')[0]),
          )} R&D`;
          combinedPrograms.push({
            stage: program.stage,
            subStage: program.subStage,
            name,
            taxYear: program.taxYear,
            id: program.id,
            orderForm: program.orderForm,
            creditAmountCents: fedProgramEstimate,
            qualificationStatus: program.qualificationStatus,
            estimatedTotalCreditCentsOverride:
              program.estimatedTotalCreditCentsOverride,
            programName: `${taxYear} ${updatedName}`,
          });
        }
      } else {
        const programEstimate =
          await this.rootStore.dashboard.modules.calculateCreditSavingsEstimate(
            program,
          );

        combinedPrograms.push({
          stage: program.stage,
          subStage: program.subStage,
          name,
          taxYear: program.taxYear,
          id: program.id,
          orderForm: program.orderForm,
          creditAmountCents: programEstimate,
          qualificationStatus: program.qualificationStatus,
          estimatedTotalCreditCentsOverride:
            program.estimatedTotalCreditCentsOverride,
          programName: `${taxYear} ${Programs[name].displayShort}`,
        });
      }
    }

    runInAction(() => {
      this.formattedPrograms = combinedPrograms;
    });
  }

  public get isYEAEditMode() {
    return getURLSearchParam('edit');
  }
}
