import { observer } from 'mobx-react';
import { SetInSessionStorage } from '../../../lib/sessionStorage';
import { UTM_SOURCING_DATA_KEY } from '../../../stores/accountcreation/AccountCreationStore';
import { SsoButton } from './SsoButton';
import React from 'react';
import { SsoProps } from './SsoProps';

export const GoogleSso = observer(
  ({ referrer, sourcing, auth, redirectUri }: SsoProps) => {
    const onGoogleSignup = async () => {
      SetInSessionStorage(
        UTM_SOURCING_DATA_KEY,
        JSON.stringify({
          referrer,
          sourcing,
        }),
      );
      await auth.socialLogin('google-oauth2', redirectUri);
    };

    return (
      <SsoButton
        onClick={onGoogleSignup}
        src={`${process.env.PUBLIC_URL}/images/sso_logos/google_logo.png`}
        text={'Sign up with Google'}
        dataTestId={'google-sso'}
      />
    );
  },
);
