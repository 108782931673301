import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import File from 'components/icons/File';
import Trash from 'components/icons/Trash';

const useStyles = makeStyles(({ palette, darkerNavy }: Theme) => ({
  root: {
    width: '100%',
    padding: (noPadding: boolean) => (noPadding ? 0 : '20px 40px 0 40px'),
  },
  fileRow: {
    borderBottom: '1px dashed rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '20px 0',
    '&:first-child': {
      padding: '0 0 20px',
    },
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fileTitle: {
    fontSize: '14px',
    fontWeight: 400,
    color: darkerNavy,
    whiteSpace: 'nowrap',
    width: '80%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    opacity: '.8',
  },
  fileIcon: {
    width: '16px',
    marginRight: '20px',
  },
  removeButton: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: '0',
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    marginLeft: 'auto',
  },
  fileInput: {
    // hide input to use label instead for accessibility
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  fileInputLabel: {
    padding: '12px 24px',
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'none',
    color: '#1F6AB0',
    border: '1px solid rgba(31, 106, 176, 0.5)',
    boxSizing: 'border-box',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      border: '1px solid #1F6AB0',
      backgroundColor: 'rgba(31, 106, 176, 0.04)',
    },
  },
  fileInputLinkLabel: {
    padding: '20px 0 0 0',
    fontSize: '14px',
    fontWeight: 400,
    textTransform: 'none',
    color: palette.secondary.main,
    minWidth: '64px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  uploadButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '20px',
  },
  supportFormat: {
    fontSize: '14px',
    color: darkerNavy,
    opacity: '.5',
    fontStyle: 'italic',
    fontWeight: 400,
  },
}));

interface FileInputProps {
  acceptedFiletypes: string[];
  selectFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  uploadMore: boolean;
  showSupportedFormats?: boolean;
  uniqueKey?: string; // required if using multiple file inputs on the same page
}

export const FileInput = ({
  acceptedFiletypes,
  selectFile,
  uploadMore,
  showSupportedFormats = true,
  uniqueKey,
}: FileInputProps) => {
  const classes = useStyles(false);

  return (
    <>
      <input
        className={classes.fileInput}
        multiple={true}
        id={`file-input-${uniqueKey ?? ''}`}
        type='file'
        onChange={selectFile}
        accept={acceptedFiletypes.join(',')}
        data-testid='file-upload-input'
      />
      {uploadMore ? (
        <label
          htmlFor={`file-input-${uniqueKey ?? ''}`}
          className={classes.fileInputLinkLabel}
          data-testid='file-input-upload-more'
        >
          + Upload more
        </label>
      ) : (
        <div className={classes.uploadButtonContainer}>
          <label
            htmlFor={`file-input-${uniqueKey ?? ''}`}
            className={classes.fileInputLabel}
            data-testid='file-input-upload-files-btn'
          >
            Upload file(s)
          </label>
          {showSupportedFormats && (
            <p className={classes.supportFormat}>
              Supported formats: .csv, .xlsx, .pdf
            </p>
          )}
        </div>
      )}
    </>
  );
};

export interface FileUploadProps {
  acceptedFiletypes: string[];
  setFiles: (files: File[]) => void;
  files: File[];
  noPadding?: boolean;
  showSupportedFormats?: boolean;
  uniqueKey?: string;
}

export const FileSelector = ({
  acceptedFiletypes,
  files,
  setFiles,
  noPadding,
  showSupportedFormats = true,
  uniqueKey,
}: FileUploadProps) => {
  const classes = useStyles(!!noPadding);

  const filesExist = files.length > 0;

  const selectFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    setFiles([...files, ...selectedFiles]);
  };

  const deleteFile = (index: number) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  return (
    <div className={classes.root}>
      {files.map((file, index) => {
        return (
          <div className={classes.fileRow} key={`${file.name}_${index}`}>
            <File className={classes.fileIcon} />
            <div className={classes.fileTitle}>{file.name}</div>
            <button
              onClick={() => deleteFile(index)}
              className={classes.removeButton}
              data-testid='file-upload-delete-btn'
            >
              <Trash />
            </button>
          </div>
        );
      })}
      <div className={classes.bottomRow}>
        <FileInput
          acceptedFiletypes={acceptedFiletypes}
          selectFile={selectFiles}
          uploadMore={filesExist}
          showSupportedFormats={showSupportedFormats}
          uniqueKey={uniqueKey}
        />
      </div>
    </div>
  );
};
