import { observer } from 'mobx-react';
import React from 'react';
import { Button, Text, Color, Flex, Image } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { Page } from 'lib/constants';

const useStyles = makeStyles({
  container: {
    backgroundColor: Color.neutral._light_20,
    borderLeft: `1px solid ${Color.neutral._20}`,
  },
  image: {
    width: '100%',
  },
  text: {
    paddingBottom: '16px',
  },
  button: {
    width: '94px',
  },
});

export const PreSaleEmptyState: React.FC = observer(() => {
  const classes = useStyles();

  return (
    <Flex.Cell
      grow={4}
      padding={24}
      dataTestId={'pre-sale-state'}
      className={classes.container}
    >
      <Flex direction='row' gap={24} alignItems='center'>
        <Flex.Cell grow={1}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/dashboard/savings/empty-state-image.svg`}
            alt='How tax credits work'
            dataTestId='savings-empty-state-image'
            className={classes.image}
          />
        </Flex.Cell>
        <Flex.Cell grow={2}>
          <Text
            variant='medium'
            size={18}
            color={Color.neutral._90}
            text='Simplifying tax credits for you'
          />
          <Text
            size={15}
            color={Color.neutral._60}
            text='Learn more about the process and what to expect with MainStreet.'
            className={classes.text}
          />
          <Button
            dataTestId='How-it-works'
            label={'How it works'}
            className={classes.button}
            variant='inline'
            onClick={{
              location: `/${Page.educationalOnboarding}`,
              target: '_self',
            }}
            small={true}
          />
        </Flex.Cell>
      </Flex>
    </Flex.Cell>
  );
});
