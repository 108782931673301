import { CacheLocation } from '@auth0/auth0-spa-js';
import * as _ from 'lodash';

const LOCAL_STORAGE_KEY = 'auth0.cache_location';
const SEARCH_PARAM = 'auth0_cache_location';
const All: CacheLocation[] = ['localstorage', 'memory'];
const DEFAULT: CacheLocation = 'memory';
const CLEAR_CACHE_COMMAND = 'clear';

/**
 * localstorage does not work with Google sign in thus we need to use memory storage in
 * most instances. However, for automated tests such as QA Wolf to be able to
 * bypass auth we need to use localstorage for ~reasons~
 *
 * Allows overriding the applications cache config using the following syntax:
 * ?auth0_cache_location=memory // Auth0 to use memory storage
 * ?auth0_cache_location=localstorage // Auth0 to use localstorage
 * ?auth0_cache_location=clear // Remove all configs and use default (memory)
 */
export const cacheLocation: (location?: URL) => CacheLocation = (
  _location?: URL,
) => {
  const location = _location ?? new URL(window.location.href);
  if (overridesAllowed()) {
    handleClearCache(location);
    return getFromUrl(location) || configStore.get() || DEFAULT;
  } else {
    return DEFAULT;
  }
};

const fromString: (s: string) => CacheLocation | undefined = (s: string) => {
  return _.find<CacheLocation>(All, (elem) => elem === s);
};

const handleClearCache = (url: URL) => {
  const doClear = url.searchParams.get(SEARCH_PARAM) === CLEAR_CACHE_COMMAND;
  if (doClear) {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }
};

const getFromUrl: (url: URL) => CacheLocation | undefined = (url: URL) => {
  const fetch = url.searchParams.get(SEARCH_PARAM);
  if (fetch) {
    const fromUrl = fromString(fetch);
    if (fromUrl) {
      configStore.set(fromUrl);
    }
    return fromUrl;
  }
};

const configStore = {
  get: () => {
    const fetch = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (fetch) {
      return fromString(fetch);
    }
  },
  set: (c: CacheLocation) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, c);
  },
};

const overridesAllowed = () => {
  return process.env.REACT_APP_CONNECTION_NAME !== 'prod';
};
