import { observer } from 'mobx-react';
import {
  useCommonStores,
  useFeatureFlags,
  useTaxCreditsStores,
} from '../../stores/useStores';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Alert,
  Button,
  Callout,
  Card,
  Color,
  Content,
  Divider,
  Expandable,
  Flex,
  FontAwesome,
  Heading,
  Icon,
  IconEnum,
  Link,
  Message,
  Modal,
  Text,
  Toast,
} from 'component-library';
import { ChargeBeePaymentForm } from './ChargeBeePaymentForm';
import { AddPaymentMethod } from '../../products/tax-credits/features/order-form/components';
import { FloatToDollarString } from '../../lib/helpers';
import { EmptyClient } from '../../services/ServerClient';
import { ProgramData } from '../../lib/interfaces';
import LoadingWidget from '../util/LoadingWidget';
import {
  ExpectedCreditTypeEnum,
  ProgramNameEnum,
  Programs,
  ProgramStageEnum,
  StatePrograms,
} from 'lib/constants';
import { datadogLogs } from '@datadog/browser-logs';
import { useHistory } from 'react-router-dom';
import TitleHeader from 'components/TitleHeader';
import { OptOutModal } from 'products/tax-credits/features/unified-tax-credits/components/OptOutModal';

const logger = datadogLogs.createLogger('UnifiedCreditPaymentPage');

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '1116px',
    margin: '0 auto',
  },
  callout: {
    backgroundColor: Color.semantic.$warning10,
    width: '100%',
  },
  estimatedCreditCard: {
    backgroundColor: Color.blue._10,
    borderColor: Color.blue._50,
    border: '1px solid',
    borderRadius: '8px',
  },
  estimatedNetCard: {
    backgroundColor: Color.neutral._10,
  },
  creditContainer: {
    backgroundColor: Color.neutral._10,
  },
  pointer: {
    cursor: 'pointer',
  },
  creditBreakdownCard: {
    margin: '20px 0 0 0',
  },
  strikeThroughText: {
    textDecoration: 'line-through',
    color: '#737373',
  },
  unlinkIcon: {
    marginRight: '4px',
  },
  freeText: {
    color: Color.green._60,
    size: 14,
    fontWeight: 600,
  },
}));

interface UnifiedCreditPaymentPageProps {
  onNext: () => void;
  onBack: () => void;
  taxYear: number;
}

interface CreditWithFee extends ProgramData {
  fee: number;
  feeString: string;
  taxTypeText: string;
}

interface PaymentData {
  programs: ProgramData[];
  totalCredit: number;
  totalFee: number;
  feePercentage: number;
}

export const UnifiedCreditPaymentPage = observer(
  ({ taxYear, onNext, onBack }: UnifiedCreditPaymentPageProps) => {
    const { app, companyStore } = useCommonStores();
    const { unifiedTaxCredits, surveyFlow } = useTaxCreditsStores();
    const classes = useStyles();
    const history = useHistory();
    const featureFlags = useFeatureFlags();
    const showChargebeePaymentMethod = featureFlags.showChargebeePaymentMethod;
    const [programData, setProgramData] = useState<PaymentData>({
      programs: [],
      totalCredit: 0,
      totalFee: 0,
      feePercentage: 0,
    });
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [paymentMethod, setPaymentMethod] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [rdExpand, setRdExpand] = useState(true);
    const [partnerChargePercentage, setPartnerChargePercentage] = useState<
      string | null
    >(null);
    const [showOptOutModal, setShowOptOutModal] = useState<boolean>(false);
    const [triggerServices, setTriggerServices] = useState<boolean>(false);
    const [toast, setToast] = useState<Message[]>([]);

    const isGuidelinePartner =
      companyStore.currentCompany.misc?.partnerReferral === 'guideline';

    const handleError = (errorLog: string) => {
      logger.error(errorLog);
      setShowErrorModal(true);
    };

    useEffect(() => {
      setPaymentMethod(
        app.common.companyStore.currentCompany.paymentMethodOnFile,
      );
      EmptyClient.GetFinalPaymentTotal(taxYear)
        .then((res) => {
          if (res.data && res.data?.programs.length !== 0) {
            setProgramData({
              // removing null/undefined values from programs array
              programs: res.data.programs.flatMap((p) => (p ? [p] : [])),
              totalCredit: res.data.totalCreditDollars,
              totalFee: res.data.totalFeeDollars,
              feePercentage: res.data.feePercentage,
            });
            setLoading(false);
          } else {
            handleError(
              `Error fetching payment total and the program length total is zero, Object: ${JSON.stringify(
                res.data,
              )}`,
            );
          }
        })
        .catch((error) => {
          handleError(`Error fetching payment total: ${error}`);
        });
    }, [triggerServices]);

    useEffect(() => {
      const fetchPartnerChargePercentage = async () => {
        const percentage = await unifiedTaxCredits.getPartnerChargePercentage();
        setPartnerChargePercentage(percentage);
      };

      fetchPartnerChargePercentage();
    }, [unifiedTaxCredits]);

    const checkPaymentMethodAndSubmit = async () => {
      await app.common.companyStore.refreshCurrentCompany();
      if (app.common.companyStore.currentCompany.paymentMethodOnFile) {
        await surveyFlow.completeAssessment(taxYear);
        unifiedTaxCredits.setHasJustCompletedAllAssessments(true);
        await EmptyClient.UpdateCompanyMisc({
          submittedTaxYears: [taxYear],
        });
        onNext();
      } else {
        setError('Please add a payment method');
      }
    };

    const hasCanceledPrograms = !!programData.programs.find((program) => {
      return program.stage === ProgramStageEnum.CANCELED;
    });

    const creditsWithFees = programData.programs.map((p) => {
      const creditAmount =
        p.creditAmountCents === 0
          ? p.orderForm?.estimatedTotalCreditCents ?? 0
          : p.creditAmountCents;
      let fee = creditAmount * programData.feePercentage;

      // for R&D programs, show the monthly fee
      if (Programs[p.name].rd) {
        fee /= 12;
      }

      // build the display strings for fee amounts
      const feeString = FloatToDollarString(fee / 100);

      let taxTypeText =
        p.expectedCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX
          ? 'Reduces federal payroll taxes'
          : 'Reduces federal income taxes';

      if (StatePrograms.includes(p.name)) {
        if (p.name === ProgramNameEnum.STATE_RD_GA) {
          taxTypeText = 'Reduces Georgia payroll taxes';
        } else {
          taxTypeText = `Reduces ${Programs[p.name].geo} income taxes`;
        }
      }
      taxTypeText = `(${taxTypeText})`;

      return {
        ...p,
        fee,
        feeString,
        taxTypeText,
      };
    });

    const rdCredits = creditsWithFees.filter((p) => Programs[p.name].rd);
    const nonRdCredits = creditsWithFees.filter((p) => !Programs[p.name].rd);

    // number to be displayed in the "+ n others" text
    const numOtherNonRdCredits = nonRdCredits.length - 1;
    const numOthersText =
      numOtherNonRdCredits > 0
        ? `+ ${numOtherNonRdCredits} other${
            numOtherNonRdCredits > 1 ? 's' : ''
          }`
        : '';

    const rdCreditMonthlyFee = rdCredits.reduce((acc, program) => {
      return program.stage !== ProgramStageEnum.CANCELED
        ? acc + program.fee
        : acc;
    }, 0);
    const rdCreditTotalFeeText = FloatToDollarString(rdCreditMonthlyFee / 100);
    const nonRdCreditTotalFee = nonRdCredits.reduce((acc, program) => {
      return program.stage !== ProgramStageEnum.CANCELED
        ? acc + program.fee
        : acc;
    }, 0);
    const nonRdCreditTotalFeeText = FloatToDollarString(
      nonRdCreditTotalFee / 100,
    );

    // Section for controlling how nonRD credits fees display
    // These functions are used for updating:
    // - MainStreet Fee
    // - Total NonCredit Fee
    // - Individual Credit Fee

    const retirementCredit = nonRdCredits.find((credit) => {
      return (
        credit.name === ProgramNameEnum.FED_RETIREMENT_CREDIT &&
        credit.stage !== ProgramStageEnum.CANCELED &&
        credit.stage !== ProgramStageEnum.DISQUALIFIED
      );
    });

    const mainstreetFeeNumberDisplay = (paymentData: PaymentData) => {
      let strikeThrough = false;
      let displayDifference = false;

      const totalFee = paymentData.totalFee;
      let differenceAmount = 0;
      if (isGuidelinePartner && retirementCredit) {
        strikeThrough = true;
        displayDifference = true;
        differenceAmount = retirementCredit?.fee / 100; // Using this amount due to the logic in server
      }

      return (
        <>
          {displayDifference && (
            <Text size={18} variant='medium'>
              {FloatToDollarString(totalFee)}
              &nbsp;&nbsp;
            </Text>
          )}
          <Text
            text={FloatToDollarString(totalFee + differenceAmount)}
            size={18}
            variant='medium'
            className={(strikeThrough && classes.strikeThroughText) || ''}
          />
        </>
      );
    };

    const mainNumberText = (amount: number, nonRdCredits: CreditWithFee[]) => {
      if (isGuidelinePartner) {
        if (retirementCredit) {
          const amountLeft = amount - retirementCredit.fee;
          return FloatToDollarString(amountLeft / 100);
        }
      }
      return FloatToDollarString(amount / 100);
    };

    const subNumberDisplay = (c: CreditWithFee) => {
      let strikeThrough = c.stage === ProgramStageEnum.CANCELED;
      let displayFree = false;

      if (
        isGuidelinePartner &&
        c.name === ProgramNameEnum.FED_RETIREMENT_CREDIT
      ) {
        strikeThrough = true;
        displayFree = true;
      }

      return (
        <>
          {displayFree && <Text className={classes.freeText}>FREE&nbsp;</Text>}
          <Text
            text={c.feeString}
            size={15}
            variant='medium'
            className={(strikeThrough && classes.strikeThroughText) || ''}
          />
        </>
      );
    };

    return (
      <div>
        <TitleHeader borderBottom fullWidth />
        <Flex
          className={classes.container}
          direction='column'
          padding={[32, 0, 80, 0]}
          gap={16}
        >
          {loading ? (
            <LoadingWidget />
          ) : (
            <>
              <Flex direction='column'>
                <Heading tag='h3' variant='medium' marginBottom={4}>
                  You’re a step away from getting your forms!
                </Heading>
                <Text size={15} paddingBottom={16} color={Color.neutral._80}>
                  Your final credit amount will be calculated when preparing
                  your forms. Once your forms are finalized and delivered, you
                  will be charged within 5 business days.
                </Text>
              </Flex>
              <Flex
                padding={[16, 24]}
                direction='row'
                className={classes.estimatedCreditCard}
                alignItems='center'
              >
                <Flex direction='column'>
                  <Text variant='medium' size={18}>
                    Total Estimated Credit
                  </Text>
                  <Text size={13}>Estimated amount you are eligible for</Text>
                </Flex>
                <Text variant='medium' size={32}>
                  {FloatToDollarString(programData.totalCredit)}
                </Text>
              </Flex>
              <Flex direction='row' alignItems='center' padding={[4, 24]}>
                <Flex direction='column'>
                  <Text size={18}>
                    MainStreet Fee ({partnerChargePercentage})
                  </Text>
                  <Text size={13}>
                    We charge {partnerChargePercentage} of the final tax credits
                    we help you claim
                  </Text>
                </Flex>
                {mainstreetFeeNumberDisplay(programData)}
              </Flex>
              <Flex
                direction='row'
                alignItems='center'
                padding={[16, 24]}
                className={classes.estimatedNetCard}
              >
                <Flex direction='column'>
                  <Text size={18}>Estimated net benefit</Text>
                </Flex>
                <Text variant='medium' size={18} color={Color.green._70}>
                  {FloatToDollarString(
                    programData.totalCredit - programData.totalFee,
                  )}
                </Text>
              </Flex>

              <Card className={classes.creditBreakdownCard}>
                <Flex padding={24} direction='column'>
                  <div
                    className={classes.pointer}
                    onClick={() => setRdExpand(!rdExpand)}
                  >
                    <Flex justifyContent='space-between' alignItems='center'>
                      <Text
                        variant='medium'
                        size={18}
                        text='Full fee breakdown'
                      />
                      <Icon
                        size={20}
                        name={
                          rdExpand ? IconEnum.chevron_up : IconEnum.chevron_down
                        }
                      />
                    </Flex>
                  </div>
                  <Expandable expand={rdExpand}>
                    <>
                      <Flex padding={[24, 0, 0, 0]}>
                        <Divider />
                      </Flex>
                      {rdCredits.length > 0 && (
                        <>
                          <Flex padding={[0, 0, 4, 0]}>
                            <Flex direction='column'>
                              <Flex gap={8}>
                                <Text
                                  variant='medium'
                                  text='R&D Credits'
                                  size={15}
                                />
                                <Text size={15} color={Color.purple._70}>
                                  &bull; Monthly charge
                                </Text>
                              </Flex>
                              <Text
                                text='You will be billed this estimated amount monthly for the next 12 months'
                                size={13}
                                color={Color.neutral._60}
                              />
                            </Flex>
                            <Text variant='medium' size={23}>
                              {rdCreditTotalFeeText}
                            </Text>
                          </Flex>
                          <Flex
                            gap={8}
                            direction='column'
                            className={classes.creditContainer}
                          >
                            {rdCredits.map((c) => (
                              <Flex
                                key={c.id}
                                justifyContent='space-between'
                                padding={[8, 16]}
                              >
                                <Flex gap={8}>
                                  <Text
                                    text={Programs[c.name].display}
                                    size={15}
                                    className={
                                      (c.stage === ProgramStageEnum.CANCELED &&
                                        classes.strikeThroughText) ||
                                      ''
                                    }
                                  />
                                  {c.stage === ProgramStageEnum.CANCELED ? (
                                    <Text
                                      text='(Opted out)'
                                      variant='italic'
                                      size={13}
                                      color={Color.neutral._60}
                                    />
                                  ) : (
                                    <Text
                                      text={c.taxTypeText}
                                      variant='italic'
                                      size={13}
                                      color={Color.neutral._80}
                                    />
                                  )}
                                </Flex>
                                <Text
                                  text={c.feeString}
                                  size={15}
                                  variant='medium'
                                  className={
                                    (c.stage === ProgramStageEnum.CANCELED &&
                                      classes.strikeThroughText) ||
                                    ''
                                  }
                                />
                              </Flex>
                            ))}
                          </Flex>
                        </>
                      )}

                      {nonRdCredits.length > 0 && (
                        <>
                          <Flex padding={[32, 0, 4, 0]}>
                            <Flex direction='column'>
                              <Flex gap={8}>
                                <Text
                                  variant='medium'
                                  text={Programs[nonRdCredits[0].name].display}
                                  size={15}
                                />
                                {numOthersText && (
                                  <Text
                                    text={numOthersText}
                                    size={15}
                                    color={Color.neutral._60}
                                  />
                                )}
                                <Text size={15} color={Color.purple._70}>
                                  &bull; One time payment
                                </Text>
                              </Flex>
                              <Text
                                text='You will be billed this estimated amount in full'
                                size={13}
                                color={Color.neutral._60}
                              />
                            </Flex>
                            <Text variant='medium' size={23}>
                              {mainNumberText(
                                nonRdCreditTotalFee,
                                nonRdCredits,
                              )}
                            </Text>
                          </Flex>
                          <Flex
                            gap={8}
                            direction='column'
                            className={classes.creditContainer}
                          >
                            {nonRdCredits.map((c) => (
                              <Flex
                                key={c.id}
                                justifyContent='space-between'
                                padding={[8, 16]}
                              >
                                <Flex gap={8}>
                                  <Text
                                    text={Programs[c.name].display}
                                    size={15}
                                    className={
                                      (c.stage === ProgramStageEnum.CANCELED &&
                                        classes.strikeThroughText) ||
                                      ''
                                    }
                                  />
                                  {c.stage === ProgramStageEnum.CANCELED ? (
                                    <Text
                                      text='(Opted out)'
                                      variant='italic'
                                      size={13}
                                      color={Color.neutral._60}
                                    />
                                  ) : (
                                    <Text
                                      text={c.taxTypeText}
                                      variant='italic'
                                      size={13}
                                      color={Color.neutral._80}
                                    />
                                  )}
                                </Flex>
                                {subNumberDisplay(c)}
                              </Flex>
                            ))}
                          </Flex>
                          <Flex direction='row' padding={[16, 0, 0, 0]}>
                            <Link
                              size={13}
                              onClick={() => setShowOptOutModal(true)}
                            >
                              <>
                                <FontAwesome
                                  name='link-slash'
                                  size={16}
                                  color='#1A45DB'
                                  className={classes.unlinkIcon}
                                />
                                {hasCanceledPrograms
                                  ? 'Adjust credit selections'
                                  : 'Click here to opt out of any credit(s)'}
                              </>
                            </Link>
                            <Toast toasts={toast} setToast={setToast} />
                          </Flex>
                        </>
                      )}
                    </>
                  </Expandable>
                </Flex>
              </Card>

              <Flex padding={[8, 0, 16, 0]}>
                <Callout
                  className={classes.callout}
                  title={
                    <Text
                      text={`The credit estimates above are non-binding and do not represent your final credit amount. If our estimates are off, we’ll adjust our fees after we finalize your credit.`}
                      size={13}
                      variant='medium'
                    />
                  }
                  color={Color.semantic.$warning50}
                  customTitleIcon={IconEnum.exclamation_triangle}
                />
              </Flex>

              <Card noMargin>
                {showChargebeePaymentMethod ? (
                  <ChargeBeePaymentForm />
                ) : (
                  <AddPaymentMethod
                    showBankNotice={false}
                    setPaymentMethod={setPaymentMethod}
                  />
                )}
              </Card>
              {error && (
                <Flex>
                  <Alert
                    text={error}
                    variant={'in_card'}
                    type={'caution'}
                    inCardBorder={'left'}
                  />
                </Flex>
              )}
              <Flex>
                <Flex justifyContent='flex-start' gap={16}>
                  <Button
                    variant={'outlined'}
                    label={'Back'}
                    onClick={() => {
                      onBack();
                    }}
                  />
                </Flex>
                <Flex justifyContent='flex-end' gap={16}>
                  <Button
                    label={'Submit'}
                    disabled={!paymentMethod}
                    onClick={async () => {
                      await checkPaymentMethodAndSubmit();
                    }}
                  />
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
        <Modal
          showModal={showErrorModal}
          closeToggle={() => setShowErrorModal(false)}
          backdrop={'static'}
        >
          <>
            <Content paddingTopBottom={24} paddingLeftRight={24}>
              <Text
                variant='medium'
                size={18}
                text='Problem loading information'
              />
              <Text dataTestId='error-message'>
                We had an issue loading your data. Please refresh the page to
                try again. If this issue persists, please contact{' '}
                <a href='mailto:support@mainstreet.com'>
                  support@mainstreet.com
                </a>
                .
              </Text>
            </Content>
            <Content
              gap={16}
              flex
              paddingLeftRight={24}
              alignItems={'center'}
              justifyContent={'center' as any}
            >
              <Button
                variant='outlined'
                label='Retry'
                flexAlignSelf={'center'}
                onClick={{
                  location: history.location.pathname,
                  external: true,
                  target: '_self',
                }}
              />
            </Content>
          </>
        </Modal>
        <OptOutModal
          showModal={showOptOutModal}
          taxYear={taxYear}
          closeModal={() => setShowOptOutModal(false)}
          triggerServices={() => setTriggerServices(!triggerServices)}
          setToast={setToast}
        />
      </div>
    );
  },
);
